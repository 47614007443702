import { AdAssetTypes } from 'constants/media';
import { useAppDispatch } from 'index';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Breadcrumbs, Button, Heading, Modal, Stack, TextStyle } from '@shopify/polaris';

import {
  addToSelectedMedia,
  mediaPickerClose,
  MediaPickerInternalData,
} from '../../ducks/contentHub/mediaPicker';
import { type RootState } from '../../reducers/RootType';
import CardThumbnail from './assets/CardThumbnail';
import ContentHub from './ContentHub';
import { MediaItemType } from '@tw/types';

type MediaPickerProps = {};

type MediaPickerContextProps = {
  selectedMedia: MediaItemType[];
  selectMedia: any;
};

export const MediaPickerContext = React.createContext<Partial<MediaPickerContextProps>>({});

const MediaPicker: FC<MediaPickerProps> = () => {
  const mediaPickerData: MediaPickerInternalData = useSelector(
    (state: RootState) => state.mediaPickerData,
  );

  const selectMedia = (med: MediaItemType, isSelected: boolean) => {
    if (allowMultiple) {
      isSelected
        ? dispatch(addToSelectedMedia(med, isSelected))
        : mediaPickerData.selectedMedia?.length === mediaPickerData.maxFiles
          ? showMaxHitBanner()
          : dispatch(addToSelectedMedia(med, isSelected));
    } else {
      dispatch(addToSelectedMedia(med, isSelected));
    }
  };

  const contextProps: MediaPickerContextProps = {
    selectedMedia: mediaPickerData?.selectedMedia,
    selectMedia: (media, isSelected) => selectMedia(media, isSelected),
  };

  // const [selectedMedia, setSelectedMedia] = useState<MediaItem[]>([]);
  const [showMaxHit, setShowMaxHit] = useState(false);
  const [mediaToEdit, setMediaToEdit] = useState<MediaItemType>();
  const dispatch = useAppDispatch();
  const { allowTypes } = mediaPickerData || {};

  const allowMultiple = mediaPickerData.maxFiles > 1;

  const onClose = async () => {
    dispatch(mediaPickerClose());
    setMediaToEdit(undefined);
  };

  const showMaxHitBanner = () => {
    setShowMaxHit(true);
    setTimeout(function () {
      setShowMaxHit(false);
    }, 3000);
  };
  return mediaPickerData.isOpen ? (
    <MediaPickerContext.Provider value={contextProps}>
      <div>
        <Modal
          limitHeight={false}
          footer={
            !mediaToEdit ? (
              <div className="media-picker-footer">
                <div className="selected-preview">
                  {/* {mediaPickerData.selectedMedia.length > 0 ? (
                    <Stack>
                      {mediaPickerData.selectedMedia.map((med) => {
                        return (
                          <Stack.Item>
                            <div className="rounded-md media">
                              <CardThumbnail media={med} />
                            </div>
                          </Stack.Item>
                        );
                      })}
                    </Stack>
                  ) : (
                    <TextStyle variation="subdued">No Media Selected</TextStyle>
                  )} */}
                </div>

                <div className="footer-actions">
                  <div className="footer-message">
                    <TextStyle variation="negative">
                      {showMaxHit ? (
                        <span>Can't select more than {mediaPickerData.maxFiles} files</span>
                      ) : (
                        ''
                      )}
                    </TextStyle>
                  </div>
                  <div>
                    <div className="footer-buttons">
                      <Button
                        primary
                        disabled={mediaPickerData.selectedMedia.length === 0}
                        onClick={() => {
                          mediaPickerData.onSelect(mediaPickerData.selectedMedia);
                          onClose();
                        }}
                      >
                        Select
                      </Button>
                      {/* <Button
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
          large={true}
          title={
            !mediaToEdit ? (
              <div className="flex flex-col justify-center gap-4">
                <Heading>
                  Select {allowTypes.map((t) => AdAssetTypes[t].label).join('/')}
                  {allowMultiple && (
                    <TextStyle variation="subdued">
                      &nbsp;(maximum {mediaPickerData.maxFiles} files)
                    </TextStyle>
                  )}
                </Heading>
                {allowMultiple && (
                  <div className=" text-gray-500 text-xl">
                    {mediaPickerData.selectedMedia.length} selected
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-4">
                <Breadcrumbs breadcrumbs={[{ onAction: () => setMediaToEdit(undefined) }]} />
                <Heading>Edit {mediaToEdit.name}</Heading>
              </div>
            )
          }
          open={true}
          onClose={onClose}
        >
          <div style={{ height: '80vh' }} className="media-picker-modal">
            <Modal.Section>
              <ContentHub isMediaPicker={true} />
            </Modal.Section>
          </div>
        </Modal>
      </div>
    </MediaPickerContext.Provider>
  ) : (
    <></>
  );
};

export default MediaPicker;
