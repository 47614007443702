import { MediaItemType } from '@tw/types';

export type ProductItemType = {
  id: string;
  name: string;
  tags: string[];
  media: MediaItemType[];
  stock?: number;
  variants: any[];
  cover_images: MediaItemType[];
  handle: string;
  [key: string]: any;
};

export const emptyProductMediaItem = {
  id: '',
  name: '',
  tags: [],
  media: [],
  stock: 0,
  variants: [],
  cover_images: [],
  handle: '',
};
