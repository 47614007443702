import { useState, useContext, useEffect } from 'react';
import { TextField, Tooltip } from '@shopify/polaris';
import { saveProductAnalyticsForecast } from 'ducks/productAnalytics';
import { CreativesCockpitContext } from '../context';
import { useAppDispatch } from 'index';
import './CreativeTable.scss';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

type CreativeTextFieldProps = {
  creative: any;
};

export const getObjToSave = (itemType, creative, value) => {
  const id = itemType === 'products' ? Number(creative.id) : creative.id;

  return { type: itemType, value: Number.parseInt(value), id };
};
const CreativeTextField: React.FC<CreativeTextFieldProps> = ({ creative }) => {
  const [value, setValue] = useState('');
  const disabled = creative.metrics.leadTime === -1;
  const { itemType } = useContext(CreativesCockpitContext);
  const dispatch = useAppDispatch();
  const defaultValue = useSelector(
    (state: RootState) => state.productAnalytics.forecastMetadata.defaultValue,
  );

  useEffect(() => {
    setValue(creative.metrics.leadTime ?? defaultValue ?? 0);
  }, [creative, defaultValue, itemType]);
  return (
    <>
      <div className="text-secondary-text text-lg flex items-center gap-2 creative-cell justify-center h-6">
        <Tooltip
          content={disabled ? 'Cannot edit lead time for a snoozed product' : 'Set lead time'}
        >
          <div className="w-32">
            <TextField
              label=""
              autoComplete="off"
              disabled={disabled}
              onBlur={() => {
                if (creative.metrics.leadTime === Number(value)) return;
                const objToSave = getObjToSave(itemType, creative, value);
                dispatch(saveProductAnalyticsForecast(objToSave));
              }}
              value={Math.max(Number(value), 0).toString()}
              onChange={(e) => {
                setValue(e);
              }}
              min={0}
              type="number"
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default CreativeTextField;
