import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import axiosInstance from 'utils/axiosInstance';
import { callInitialImport } from 'utils/callInitialImport';
import { callUpdateToday } from 'utils/callUpdateToday';

import db from '../utils/DB';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import {
  INIT_SHOP,
  IntegrationSaveErrors,
  SET_OPTIMISTIC_IMPORTING_FOR_SERVICE,
} from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { minimizeSectionOnPress } from './summary';
import { getOnboarding } from './shop';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;
const KNO_SERVICE_ID = 'kno';

export const KNO_CONNECT_ON_PRESS = 'KNO_CONNECT_ON_PRESS';
export const knoConnectOnPress = () => {
  return {
    type: KNO_CONNECT_ON_PRESS,
  };
};

export const TOGGLE_KNO_CONFIG_SCREEN_OPEN = 'TOGGLE_KNO_CONFIG_SCREEN_OPEN';
export const toggleKnoConfigScreenOpen = () => ({
  type: TOGGLE_KNO_CONFIG_SCREEN_OPEN,
});

export const KNO_CONFIG_SAVE = 'KNO_CONFIG_SAVE';
export const knoConfigSave = () => {
  return {
    type: KNO_CONFIG_SAVE,
  };
};

export function knoConfigSavePress({ shopId, knoClientId, knoClientSecret }, setSaveError) {
  return (dispatch) => {
    if (knoClientId === knoClientSecret) {
      setSaveError(IntegrationSaveErrors.UNIQUE_ERROR);
      return;
    }
    dispatch(knoClientIdSave(knoClientId));
    dispatch(knoClientSecretSave(knoClientSecret));
    return db()
      .update({
        knoClientId: knoClientId,
        knoClientSecret: knoClientSecret,
      })
      .then(async (json) => {
        try {
          const register = await registerWebhook(shopId);
          dispatch(getOnboarding(shopId));
          switch (register.status) {
            case 200:
              setSaveError(undefined);
              dispatch(knoConfigSave());
              toggleSaveIndicator();
              dispatch(startImportingKnoData());
              dispatch(knoClientIdSave(knoClientId));
              break;
            case 401:
              return db()
                .update({
                  knoClientId: firestore.FieldValue.delete(),
                  knoClientSecret: firestore.FieldValue.delete(),
                })
                .then(() => {
                  setSaveError(IntegrationSaveErrors.KNO_INVALID_CLIENT);
                });
            default:
              setSaveError(IntegrationSaveErrors.CONNECTION_ERROR);
          }
          dispatch(minimizeSectionOnPress(KNO_SERVICE_ID, false));
        } catch (e) {
          setSaveError(IntegrationSaveErrors.CONNECTION_ERROR);
        }
      });
  };
}

async function registerWebhook(shopId: string) {
  const url = `v2/kno/webhook/register`;

  try {
    return await axiosInstance.post(url, { shopDomain: shopId });
  } catch (e) {
    console.log(e);
    return e;
  }
}

export function startImportingKnoData() {
  return (dispatch) => {
    callInitialImport(KNO_SERVICE_ID);
    dispatch(setOptimisticImporting(KNO_SERVICE_ID));
    window.scrollTo(0, 0);
  };
}

export function knoDisconnect(shopId: string) {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    const url = `v2/kno/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('knoClientSecret'));
        dispatch(knoDisconnectDone());
      });
  };
}

export const KNO_DISCONNECT_DONE = 'KNO_DISCONNECT_DONE';
export function knoDisconnectDone() {
  return {
    type: KNO_DISCONNECT_DONE,
  };
}

export const KNO_CLIENTID_SAVE = 'KNO_CLIENTID_SAVE';
export function knoClientIdSave(knoClientId) {
  return {
    type: KNO_CLIENTID_SAVE,
    payload: knoClientId,
  };
}

export const KNO_CLIENTSECRET_SAVE = 'KNO_CLIENTSECRET_SAVE';
export function knoClientSecretSave(knoClientSecret) {
  return {
    type: KNO_CLIENTSECRET_SAVE,
    payload: knoClientSecret,
  };
}

const knoConnectConfigIsOpen = (state = false, action) => {
  switch (action.type) {
    case KNO_CONNECT_ON_PRESS:
      return true;
    case TOGGLE_KNO_CONFIG_SCREEN_OPEN:
      return !state;
    case KNO_CONFIG_SAVE:
      return false;
    default:
      return state;
  }
};

const isKnoConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.knoClientSecret;
    case KNO_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === KNO_SERVICE_ID || state;
    default:
      return state;
  }
};

const knoClientId = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.knoClientId || state;
    case KNO_CLIENTID_SAVE:
      return action.payload;
    case KNO_DISCONNECT_DONE:
      return '';
    default:
      return state;
  }
};

const knoClientSecret = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.knoClientSecret || state;
    case KNO_CLIENTSECRET_SAVE:
      return action.payload;
    case KNO_DISCONNECT_DONE:
      return '';
    default:
      return state;
  }
};

export const reducers = {
  isKnoConnected,
  knoConnectConfigIsOpen,
  knoClientId,
  knoClientSecret,
};
