import { CDPActionFilterActions, CDPSegmentFilterType } from '@tw/types/module/services/cdp';

const QUERY_TRANSLATION: { [key in CDPActionFilterActions | CDPSegmentFilterType]?: string } = {
  [CDPSegmentFilterType.ACTION]: 'Performed an action',
  [CDPSegmentFilterType.USER_PROPERTY]: 'Have certain characteristics',
  [CDPSegmentFilterType.SEGMENT_RELATION]: 'Are part of segment',

  [CDPActionFilterActions.MADE_PURCHASE]: 'Made a purchase',
  [CDPActionFilterActions.CLICKED_AD]: 'Clicked an ad',
  [CDPActionFilterActions.VISITED_URL]: 'Visited a URL',
  [CDPActionFilterActions.STARTED_SUBSCRIPTION]: 'Started Subscription',
  [CDPActionFilterActions.UPDATED_SUBSCRIPTION]: 'Updated Subscription',
  [CDPActionFilterActions.CANCELLED_SUBSCRIPTION]: 'Cancelled Subscription',
};

export function getOptionLabel(option: string) {
  return QUERY_TRANSLATION[option] || option;
}
