import React from 'react';

const AdrollIconBase64 = ({ small }) => (
  <img
    style={{ height: small ? 15 : 20 }}
    alt="Adroll icon"
    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0ibGF5ZXIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNTAgMTUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNTAgMTUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMwREJERkY7fQo8L3N0eWxlPg0KPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS41LCAxLjUpIj4NCiAgPHBhdGggY2xhc3M9InN0MCIgZD0iTTE0Nyw3My42YzAtMzAuMS0yMy43LTU0LjYtNTQuNi01NC42Yy0yMC41LDAtMzguMywxMS4zLTQ3LjYsMjhsMjYuNiwyNi42YzAtMTEuNiw5LjgtMjEuMSwyMS4xLTIxLjEKCSAgYzExLjksMCwyMSw5LjQsMjEsMjEuMWMwLDExLjYtOS4yLDIxLjEtMjEsMjEuMWwtMC4xLDB2MEg0N2MtMTEuMywwLTIwLjQsOS4xLTIwLjQsMjAuNHYxMy4xaDY1LjdDMTIzLjEsMTI4LjEsMTQ3LDEwMy43LDE0Nyw3My42CgkgICIvPg0KPC9nPg0KPC9zdmc+"
  />
);

export default AdrollIconBase64;
