import React, { useContext } from 'react';
import { Page } from '@shopify/polaris';
import { PixelInstallationStoreSetup } from '../../../attribution/PixelInstallationStoreSetup';
import { PixelInstallation } from '../../../attribution/PixelInstallation';
import { WizardContext } from '../../WizardContext';

export const WelcomeInstallShopifyPixel: React.FC = () => {
  const { nextStep } = useContext(WizardContext);

  return (
    <div id="welcome-install-shopify-pixel" className="flex flex-col items-center w-full h-full">
      <img
        src="/left-side-navigator/pixel-icon.svg"
        alt="pixel-icon"
        className="w-[100px] mb-[40px]"
      />
      <p className="step-title">Install the Triple Whale Pixel</p>
      <p className="step-description mb-[20px]">
        The Triple Whale Pixel is essential for collecting data about your website visitors and
        customer journeys. It should be installed on every page you want to track.
      </p>
      <Page>
        <div className="pixel-settings-page">
          <div className="border border-solid border-gray-200 rounded-lg w-full">
            <PixelInstallationStoreSetup></PixelInstallationStoreSetup>
          </div>
          <div className="border border-solid border-gray-200 rounded-lg mt-[40px]">
            <PixelInstallation></PixelInstallation>
          </div>
        </div>
      </Page>
      <button className="step-button" onClick={nextStep}>
        Next
      </button>
    </div>
  );
};
