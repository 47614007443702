import moment from '@tw/moment-cached';
import { TimeFrame } from '@tw/types/module/services/insights';

export const SET_INSIGHTS_TIMEFRAME = 'SET_INSIGHTS_TIMEFRAME';
export const SET_INSIGHTS_DATERANGE = 'SET_INSIGHTS_DATERANGE';
export const SET_INSIGHTS_EVENTS_CONTEXT = 'SET_INSIGHTS_EVENTS_CONTEXT';

export const setInsightsTimeframe = (timeframe: TimeFrame) => {
  return {
    type: SET_INSIGHTS_TIMEFRAME,
    timeframe,
  };
};

export const setInsightsDateRange = (start: moment.Moment, end: moment.Moment) => {
  return {
    type: SET_INSIGHTS_DATERANGE,
    start,
    end,
  };
};

export const setInsightsEventsContext = (context: string) => {
  return {
    type: SET_INSIGHTS_EVENTS_CONTEXT,
    context,
  };
};
