import { BqColumn } from './columns/types';
import { BqTable, CustomColumn } from './tables';
import { getQueryWithoutPivot } from './utils';
import { initialComments } from '../constants';
import { $tabs } from '$stores/willy/$tables';
import { formatSqlSafely } from 'components/Willy/utils/willyUtils';
import { $dialect } from '$stores/$user';
import { buildQueryFromClickhouseSchema } from './buildQueryClickhouse';

export const buildQueryFromSchema = (table: BqTable, column: BqColumn, parentColumn?: BqColumn) => {
  const dialect = $dialect.get();
  if (dialect === 'clickhouse') {
    return buildQueryFromClickhouseSchema(table, column, parentColumn);
  }
  const tabId = $tabs.get().find((t) => t.active)?.model?.id as string;

  const isBasicMetric = column.type === 'numeric';
  const isFormula = column.type === 'formula';
  const isMetric = isBasicMetric || isFormula;
  const isParameter = column.type === 'parameter';
  const agg = column.agg || 'sum';

  let currentQuery = $tabs.get().find((t) => t.model?.id === tabId)?.query;
  if (!currentQuery) {
    currentQuery = '';
  }
  currentQuery = currentQuery.replace(initialComments, '');
  const fullQueryLower = currentQuery.toLowerCase();
  // const isBlendedStats = table.id === 'blended_stats_table';
  // if (isBlendedStats) {
  //   // start from after preparedTable
  //   currentQuery = currentQuery.replace(
  //     formatSqlSafely(preparedBlendedStatsTable),
  //     ''
  //   );
  // }
  const isUnnestedField =
    parentColumn?.type === 'record repeated' || column.type === 'repeated string';
  const requiresUnnest = isUnnestedField && !fullQueryLower.includes('unnest');
  const unnestingColumn = parentColumn || column;
  const isPivoted = fullQueryLower.includes('pivot');
  let pivotString = '';
  if (isPivoted) {
    ({ pivotString, queryWithoutPivot: currentQuery } = getQueryWithoutPivot(currentQuery));
  }
  const alias = (column as CustomColumn).isCustomColumn ? column.id : '';
  let query = '';
  let field = column.name + alias;
  if (isBasicMetric) {
    field = `${agg}(${column.name}) ${column.name}`;
  } else if (isParameter) {
    field = `@${column.id} ${column.name}`;
  }

  if (!currentQuery) {
    let parameter = '';
    if (isParameter) {
      parameter = `${column.id}=@${column.id}`;
    }
    const table_name = table.type !== 'table_function' ? table.id : `${table.id}(${parameter})`;
    query = `
        select ${field},
        from ${table_name} 
        ${requiresUnnest ? `, UNNEST(${unnestingColumn.name}) ${unnestingColumn.name}` : ''}

        where event_date = DATE_SUB(current_date(), INTERVAL 1 DAY)
        ${
          isMetric || isParameter
            ? ''
            : `group by ${
                column.name.includes(' as ') ? column.name.split(' as ')[1] : column.name
              }`
        }
      `;
  } else {
    const beforeWhere = currentQuery.slice(0, currentQuery.toLowerCase().indexOf('where'));
    const fieldExists = beforeWhere.includes(field);
    if (fieldExists) {
      // remove field from query
      query = query.trimEnd();
      query = currentQuery.replaceAll(`${field},`, '');
      if (query.endsWith(field)) {
        // remove trailing field
        query = query.slice(0, -field.length);
        query = query.trimEnd();
      }

      if (query.endsWith('group by')) {
        query = query.replaceAll('group by', '');
      }
      if (query.endsWith(',')) {
        // remove trailing comma
        query = query.slice(0, -1);
      }
    } else {
      // inject before 'from' using splice
      const fromIndex = currentQuery.toLowerCase().indexOf('from\n');
      const beforeFrom = currentQuery.slice(0, fromIndex);
      let orderByIndex = fullQueryLower.indexOf('order by');
      if (orderByIndex === -1) {
        orderByIndex = fullQueryLower.length;
      }
      let afterFrom = currentQuery.slice(fromIndex, orderByIndex);
      const afterOrderBy = currentQuery.slice(orderByIndex);
      const hasGroupBy = currentQuery.toLowerCase().includes('group by');
      const groupByString = hasGroupBy ? ',' : 'group by';
      if (requiresUnnest) {
        afterFrom = afterFrom.replace(
          table.id,
          `${table.id}, UNNEST(${unnestingColumn.name}) ${unnestingColumn.name}`,
        );
      }

      if (isParameter) {
        const isEmptyFunc = afterFrom.includes(`${table.id} ()`);
        afterFrom = afterFrom.replace(
          `${table.id} (`,
          `${table.id} (${column.id}='${column.options?.[0]?.value}'${isEmptyFunc ? '' : ','}`,
        );
      }
      query = `
          ${beforeFrom} ${field},
          ${afterFrom} ${isMetric || isParameter ? '' : `${groupByString} ${column.name}`}
          ${afterOrderBy}
        `;
    }
  }

  // if (isBlendedStats) {
  //   query = `${preparedBlendedStatsTable} ${query}`;
  // }
  if (isPivoted) {
    query = `select * from (${query}) ${pivotString}`;
  }

  const formattedQuery = formatSqlSafely(query);

  return formattedQuery;
};
