import SinglePixel from 'components/pixel/SinglePixel';
import { DisplayText, Modal } from '@shopify/polaris';
import OrderLowResolutionMark from '../pixel/OrderLowResolutionMark';

export const CustomerJourney = ({ loading, order, journey, isOpen, campaign, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      loading={loading}
      iFrameName="pixel-modal"
      title={
        !loading && (
          <div className="pixel-modal-title">
            <div className="pixel-title">
              <p className="pixel-campaign-name">{campaign.name}</p>
              <DisplayText size="large">
                {order?.name}
                <OrderLowResolutionMark order={order} journey={journey}></OrderLowResolutionMark>
              </DisplayText>
            </div>
          </div>
        )
      }
    >
      <div>
        <SinglePixel loading={loading} order={order} journey={journey} />
      </div>
    </Modal>
  );
};
