import { $dialect } from '$stores/$user';
import { $v3_0_Enabled } from '$stores/$v3_0_Enabled';
import { providers, providersConnected } from 'ducks/shopIntegrations';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../reducers/RootType';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../$stores/willy/$subscription';

export const useSendIntercomShopData = () => {
  const formatToIntercom = (integrations, isBrokenKey) => {
    function formatKey(key) {
      // Split based on '-', capitalize each word, and join with space
      return (
        'mkt_' +
        key
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') +
        (isBrokenKey ? ' Connected Properly' : '')
      );
    }

    const formattedData = {};

    for (const key in integrations) {
      formattedData[formatKey(key)] = integrations[key];
    }

    return formattedData;
  };
  const hasRechargeInCrossbeam = useSelector((state: RootState) => state.hasRechargeInCrossbeam);
  const shop = useSelector((state: RootState) => state.shop);
  const subscription = useSelector((state: RootState) => state.subscription);
  const isFreeShop = useStoreValue($isFreeShop);
  const isSubscriptionLoading = useSelector((state: RootState) => state.isSubscriptionLoading);
  const shopProviders = useSelector(providers);
  const sensoryIntegrationsLoaded = useSelector(
    (state: RootState) => state.sensory.sensoryIntegrationsLoaded,
  );
  // const plannerStatus = useSelector((state: RootState) => state.forecasting.plannerStatus);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const user = useSelector((state: RootState) => state.user) as any;
  const { hasPpsData } = useSelector((state: RootState) => state.ppsStatus);
  const hasMobileApp = useSelector((state: RootState) => state.hasMobileApp);
  const { segmentsList } = useSelector((state: RootState) => state.CDPSegments);
  const utmStatusVal = useSelector((state: RootState) => state.utmStatusVal);
  const { hubSpotCompany }: any = useSelector((state: RootState) => state.hubspot);
  useEffect(() => {
    if (window.Intercom && sensoryIntegrationsLoaded && !isSubscriptionLoading) {
      try {
        const providersArray = Object.entries(shopProviders).reduce(
          (acc, [providerId, providerProps]) => {
            return [...acc, { ...providerProps, providerId }];
          },
          [] as any,
        );

        const filtered = providersArray.filter((x) => x.providerType !== 'none' && !x.isBeta);

        const integrationsConnected = filtered.reduce((acc, x) => {
          return { ...acc, [x.providerId]: !!x.isConnected };
        }, {});

        // const integrationsBroken = filtered
        //   .filter((x) => x.isConnected)
        //   .reduce((acc, x) => {
        //     return {
        //       ...acc,
        //       [x.providerId]: !(
        //         !!x.providerStatus?.errorMessage && x.providerStatus?.status !== 'disconnected'
        //       ),
        //     };
        //   }, {});

        const integrationsConnectedToSend = formatToIntercom(integrationsConnected, false);
        //const integrationsConnectedProperlyToSend = formatToIntercom(integrationsBroken, true);

        const numberOfSyncedSegments =
          segmentsList.filter(
            (s) =>
              s && s.integrationsSyncDetails && s.integrationsSyncDetails.some((i) => i.isSynced),
          ).length || 0;

        const intercomDataToSend = {
          app_id: 'ogniecxz',
          email: user?.email,
          'mkt_Account Type': user?.accountType,
          mkt_GMV: shop?.annualRevenue?.revenue ?? -1,
          mkt_MRR: subscription?.total_price ?? -1,
          'mkt_Last seen': user?.lastVisit
            ? new Date(user.lastVisit.seconds * 1000).toISOString().split('T')[0]
            : 'N/A',
          mkt_Pinned: user?.shops?.[currentShopId]?.ui?.summary?.pinned?.tiles ?? [],
          'mkt_Mobile App': hasMobileApp,
          'mkt_Nov2023 SCDP Sync': !!numberOfSyncedSegments,
          'mkt_Subscription New Package':
            subscription?.items?.some((item) => item.product_type === 'package') ?? false,
          'mkt_Subscription Billing Interval':
            subscription?.items?.find((item) => item.product_type === 'package')
              ?.billing_interval ?? 'N/A',
          'mkt_Subscription Created At': subscription?.created_at
            ? new Date(subscription.created_at).toISOString().split('T')[0]
            : 'N/A',
          'mkt_Subscription Package':
            subscription?.items?.find((item) => item.product_type === 'package')?.product_name ??
            'N/A',
          'mkt_Subscription Status': subscription?.status ?? 'N/A',
          'mkt_Subscription Start Date': subscription?.current_period_start
            ? new Date(subscription.current_period_start).toISOString().split('T')[0]
            : 'N/A',
          'mkt_Package Purchase Date': subscription?.items?.find(
            (item) => item.product_type === 'package',
          )?.subscription_item_created_at
            ? new Date(
                subscription?.items?.find(
                  (item) => item.product_type === 'package',
                )?.subscription_item_created_at,
              )
                .toISOString()
                .split('T')[0]
            : 'N/A',
          'mkt_Has Post Purchase Survey': hasPpsData,
          'mkt_Has Recharge In Crossbeam': hasRechargeInCrossbeam,
          'mkt_7 day sessions': shop?.shopUsage?.seven_day_sessions ?? -1,
          'mkt_Stripe Subscription Status': !!shop?.subscriptionActive,
          'mkt_Tenure Cohort': shop?.shopUsage?.tenure_cohort ?? 'N/A',
          'mkt_UTM Ratio': utmStatusVal,
          // 'mkt_Has Planner': plannerStatus === 'ready' || plannerStatus === 'in-progress',
          'mkt_First Subscription Start Date':
            hubSpotCompany?.properties?.first_subscription_start_date,
          'mkt_Customer Success Manager': hubSpotCompany.csmName,
          'mkt_Api Last Updated': new Date().toISOString().split('T')[0],
          'mkt_Founders Dash': shop?.subscriptionSource === 'tw' ? true : false,
          ...integrationsConnectedToSend,
          // ...integrationsConnectedProperlyToSend,
          'mkt_Has Meta Sonar Enabled': shop?.capi_settings?.meta?.isEnabled ?? false,
          'mkt_Has Klaviyo Sonar Enabled': shop?.capi_settings?.klaviyo?.isEnabled ?? false,
          'mkt_First Meeting Held Date': hubSpotCompany?.properties?.first_meeting_held_date,
          'mkt_First Meeting Booked Date': hubSpotCompany?.properties?.first_meeting_booked_date,
          'mkt_CP Meeting Creation Date': hubSpotCompany?.properties?.meeting_creation_date_cp__c
            ? new Date(hubSpotCompany?.properties?.meeting_creation_date_cp__c)
                .toISOString()
                .split('T')[0]
            : '',
          'mkt_TW Platform': $v3_0_Enabled.get() ? '3.0' : '2.0',
          'mkt_User Role': user?.shops?.[currentShopId]?.roles?.[0],
          mkt_clickhouse: $dialect.get() === 'clickhouse',
        };
        window.Intercom('update', intercomDataToSend);
      } catch (e) {
        console.error(`Error sending data to Intercom: ${e}`);
      }
    }
  }, [
    subscription,
    shop,
    hasRechargeInCrossbeam,
    sensoryIntegrationsLoaded,
    shopProviders,
    isSubscriptionLoading,
    isFreeShop,
    user,
    currentShopId,
    hasPpsData,
    hasMobileApp,
    utmStatusVal,
    segmentsList,
    hubSpotCompany,
  ]);
};
