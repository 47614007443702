import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { services } from '@tw/types/module/services';
import { Badge, Skeleton, Text, Icon, IconName, ActionIcon, Menu } from '@tw/ui-components';
import { WillyUserAvatar } from './WillyUserAvatar';
import { Timestamp } from 'utils/DB';
import { WillyDataSequence } from 'components/Willy/types/willyTypes';
import moment from 'moment';
import { useStoreValue } from '@tw/snipestate';
import { $shopUsers } from '$stores/$users';
import { chatActions } from 'utils/dataLayer/constants';
import { Role } from '$stores/willy/$globalDashboardRoles';
import { genericEventLogger } from 'utils/dataLayer';
import { analyticsEvents } from 'utils/dataLayer/constants';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { WillySimpleText } from './WillySimpleText';

export type SequenceListItem = {
  id: string;
  user: string;
  userName?: string;
  finishedAt: string;
  read: boolean;
  title: string;
  content: string;
  outputFile?: string | null;
  outputTitle?: string | null;
  outputDescription?: string | null;
  outputInsight?: string | null;
  providers?: string[];
  roles?: string[];
};

type SequenceListCardProps = {
  item: SequenceListItem;
  index: number;
  onItemClick: (item: any) => void;
  workflowCategories?: Role[];
};

const momentFormat = 'MMM D, YYYY h:mm A';

export const formatSequenceItemDate = (date: string | typeof Timestamp | Date) => {
  if (!date) return '';

  if (date instanceof Timestamp) {
    return moment(date.toDate()).format(momentFormat);
  }

  if (date instanceof Date) {
    return moment(date).format(momentFormat);
  }

  if (typeof date === 'string') {
    return moment(new Date(date)).format(momentFormat);
  }

  return '';
};

export const formatTextForDisplay = (text: string) => {
  return (
    text
      // Remove headers
      .replace(/^#+\s+(.*$)/gm, '')
      // Remove horizontal rules
      .replace(/^[-_*]{3,}\s*$/gm, '')
      // Remove unordered list markers but keep content
      .replace(/^[-*+]\s+(.*$)/gm, '$1')
      // Remove ordered list markers but keep content
      .replace(/^\d+\.\s+(.*$)/gm, '$1')
      // Remove blockquote markers but keep content
      .replace(/^>\s+(.*$)/gm, '$1')
      // Remove table rows (including headers and separators)
      .replace(/^\|.*\|.*$/gm, '')
      // Remove table alignment row
      .replace(/^\|-+\|.*$/gm, '')
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, '')
      // Remove inline code
      .replace(/`.*?`/g, '')
      // Remove bold/strong formatting but keep content
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      // Remove empty lines at the start
      .replace(/^\s+/, '')
      // Collapse multiple newlines into single space
      .replace(/\n+/g, ' ')
      .trim()
  );
};

export const SequenceListCard: React.FC<SequenceListCardProps> = ({
  item,
  index,
  onItemClick,
  workflowCategories,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { loading: loadingUsers } = useStoreValue($shopUsers);
  const [opened, setOpened] = useState(false);
  const isSmall = useIsSmall();

  const roles = useMemo(() => {
    const matchedRoles =
      item?.roles
        ?.map((role) => workflowCategories?.find((category) => category.id === role))
        .filter(Boolean) ?? [];

    return matchedRoles.length > 0 ? matchedRoles.slice(0, isSmall ? 1 : 2) : null;
  }, [item?.roles, workflowCategories, isSmall]);

  const providers = useMemo(() => {
    const icons = item?.providers
      ?.map((provider) => {
        const service = services[provider];
        if (!service || !service?.smallIcon) return null;
        return { id: provider, ...service };
      })
      .filter(Boolean);

    return isSmall ? icons?.slice(0, 4) : icons;
  }, [item?.providers, isSmall]);

  const ProviderIcons = useMemo(() => {
    return providers?.map((provider) => {
      if (!provider) return null;
      return <Icon key={provider?.id} name={provider?.smallIcon as IconName} size={18} />;
    });
  }, [providers]);

  const menuOptions = useMemo(() => {
    const actionSection = {
      items: [
        {
          content: 'View Details',
          onAction: () => {
            const params = new URLSearchParams(search);
            params.set('from', 'feed');
            navigate({
              pathname: `/workflows/${item.id}`,
              search: params.toString(),
            });
            setOpened(false);
          },
        },
        {
          content: 'Edit Workflow',
          onAction: () => {
            const params = new URLSearchParams(search);
            params.set('from', 'feed');
            navigate({
              pathname: `/workflows/create/${item.id}`,
              search: params.toString(),
            });
            setOpened(false);
          },
        },
        {
          content: 'Run Workflow',
          onAction: () => {
            const params = new URLSearchParams(search);
            params.set('from', 'feed');
            params.set('run', 'true');
            navigate({
              pathname: `/workflows/create/${item.id}`,
              search: params.toString(),
            });

            setOpened(false);

            genericEventLogger(analyticsEvents.CHAT, {
              action: chatActions.RUN_SEQUENCE,
              id: item.id,
              text: item.title || '',
              conversationId: item.id,
            });
          },
        },
      ],
    };

    return [actionSection];
  }, [item.id, item.title, navigate, search]);

  return (
    <div
      className="cursor-pointer group bg-white sm:hover:bg-gray-100 p-6.5 sm:p-4 sm:rounded-md w-full shadow sm:shadow-none"
      key={item.id + index}
      onClick={() => onItemClick(item)}
    >
      <div className="flex justify-between items-start gap-4 w-full border-b border-gray-100">
        <div className="flex justify-between items-start gap-4 w-full flex-grow-1">
          <div className="w-full">
            <div className="gap-2 mb-2">
              <div className="flex justify-between gap-2">
                <div>
                  {(!item.read || roles?.length) && (
                    <div className="flex flex-wrap items-center gap-2 mb-4 max-w-full">
                      {providers && providers?.length > 0 && (
                        <div className="flex sm:hidden items-center gap-2">{ProviderIcons}</div>
                      )}

                      {!item.read && (
                        <div className="flex-shrink-0">
                          <Badge color="one.6" fw="400" radius="sm" uppercase={false}>
                            <Text fz={11} fw={500} color="white" truncate="end">
                              New
                            </Text>
                          </Badge>
                        </div>
                      )}

                      {roles?.map((role) => (
                        <div key={role?.id}>
                          <Badge radius="sm" uppercase={false} color={role?.labelColor}>
                            <Text
                              fz={11}
                              fw={500}
                              color={role?.badgeColor ?? 'white'}
                              truncate="end"
                            >
                              {role?.name}
                            </Text>
                          </Badge>
                        </div>
                      ))}
                    </div>
                  )}
                  <Text size="lg" fw="600" color="black">
                    {item.outputTitle || item.title}
                  </Text>
                </div>
                <div>
                  <Menu
                    opened={opened}
                    onClose={() => setOpened(false)}
                    closeOnItemClick={false}
                    withinPortal={false}
                  >
                    <Menu.Target>
                      <div className="sm:invisible sm:group-hover:visible">
                        <ActionIcon
                          icon="three-dots"
                          iconSize={18}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpened(!opened);
                          }}
                        />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {menuOptions.map((section, i) => (
                        <div key={i}>
                          {section.items.map((item, j) => {
                            const { content, onAction, ...rest } = item;

                            return (
                              <Menu.Item
                                key={j}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onAction();
                                }}
                                {...rest}
                              >
                                <Text size="sm" weight={500}>
                                  {content}
                                </Text>
                              </Menu.Item>
                            );
                          })}
                        </div>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </div>
            </div>
            <Text size="md">
              <span className="line-clamp-2 overflow-hidden">
                <WillySimpleText
                  text={formatTextForDisplay(
                    item.outputInsight ?? item.outputDescription ?? item.content ?? '',
                  )}
                  smallText={isSmall}
                />
              </span>
            </Text>
            <div className="flex justify-between w-full sm:items-center gap-2 mt-6.5">
              <div className="flex items-center gap-4 overflow-hidden">
                {loadingUsers ? (
                  <div className="flex gap-4 mt-2">
                    <Skeleton width="32px" height="32px" radius="100%" />
                    <Skeleton width="100px" height="32px" />
                  </div>
                ) : item.userName ? (
                  <div className="flex items-center gap-4 overflow-hidden">
                    <WillyUserAvatar userId={item.user} size="small" round={true} />
                    <div>
                      <Text size="sm" fw="600">
                        <span className="line-clamp-1 overflow-hidden">{item.userName}</span>
                      </Text>
                      <Text size="xs" color="gray.5" fw="400">
                        <span className="line-clamp-2 overflow-hidden">
                          {formatSequenceItemDate(item.finishedAt)}
                        </span>
                      </Text>
                    </div>
                  </div>
                ) : null}
                {item.outputTitle && item.outputTitle !== item.title && (
                  <div className="flex flex-col border-solid border-0 border-l border-gray-400 pl-4">
                    <Text size="sm" fw="600">
                      <span className="line-clamp-2 overflow-hidden text-ellipsis">
                        {item.title}
                      </span>
                    </Text>
                    <Text size="xs" color="gray.5" fw="400">
                      Workflow
                    </Text>
                  </div>
                )}
              </div>

              {providers && providers?.length > 0 && (
                <div className="hidden sm:flex items-center gap-2">{ProviderIcons}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
