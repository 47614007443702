export const TreeVerticalLine: React.FC = () => {
  return (
    <div className="flex justify-center">
      <div className="w-[2px] bg-gray-300 h-[15px]" />
    </div>
  );
};

export const TreeVerticalLineFull: React.FC = () => {
  return (
    <div className="flex justify-center h-full w-full">
      <div className="bg-gray-300 h-full w-[2px]"></div>
    </div>
  );
};
