import React from 'react';
const PinterestIconBase64 = ({ small }) => (
  <img
    src="
  data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDUuODYgNDQ1Ljg2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2NiMjAyNzt9LmNscy0ye2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9InBpbnRlcmVzdCI+PGNpcmNsZSBpZD0iYmFja2dyb3VuZCIgY2xhc3M9ImNscy0xIiBjeD0iMjIyLjkzIiBjeT0iMjIyLjkzIiByPSIyMjIuOTMiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0yNDAuNTUsMjg3Yy0xNi41MS0xLjIyLTIzLjQ0LTktMzYuMzgtMTYuNDMtNy4xMiwzNS40LTE1LjgxLDY5LjMzLTQxLjU2LDg3LjA1LTgtNTMuNDksMTEuNjctOTMuNjcsMjAuNzgtMTM2LjMyLTE1LjUzLTI0LjgsMS44Ny03NC43MiwzNC42My02Mi40MkMyNTguMzUsMTc0LDE4My4xMSwyNTEsMjMzLjYyLDI2MC42N2M1Mi43MywxMC4wNSw3NC4yNi04Ni43Nyw0MS41NS0xMTguMjYtNDcuMjQtNDUuNDctMTM3LjUzLTEtMTI2LjQzLDY0LjA1LDIuNzEsMTUuOTIsMjAsMjAuNzUsNi45Myw0Mi43LTMwLjI0LTYuMzUtMzkuMjYtMjktMzguMS01OS4xMiwxLjg3LTQ5LjM2LDQ2Ljc3LTgzLjkyLDkxLjgtODguNyw1Ni45NS02LDExMC40LDE5LjgzLDExNy43OCw3MC42M0MzMzUuNDUsMjI5LjMxLDMwMS40NCwyOTEuNCwyNDAuNTUsMjg3WiIvPjwvZz48L2c+PC9zdmc+
  "
    style={{ height: small ? 15 : 20 }}
    alt="Pinterest icon"
  />
);

export default PinterestIconBase64;
