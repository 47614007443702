import { GRADIENT_CHART_COLORS } from 'constants/general';
import { formatNumber } from 'utils/formatNumber';
import { WillyMetric } from '../types/willyTypes';
import { startCase } from 'lodash';

export function formatText(text: string, withoutTrailingParameters = false) {
  if (!text || typeof text !== 'string') {
    return '';
  }

  const textUntilFirstParameter = text.toString().split('_(');
  if (withoutTrailingParameters && textUntilFirstParameter.length > 1) {
    text = textUntilFirstParameter[0];
  }

  const transformed = text
    .toString()
    .split(/[_-]/)
    .map((word) => {
      if (
        ['cpm', 'ctr', 'cpc', 'cpa', 'cpv', 'roi', 'roas', 'aov', 'cr', 'cv', 'cpcv'].includes(word)
      ) {
        return word.toUpperCase();
      }
      return startCase(word);
    })
    .join(' ');

  return transformed;
}

export function formatValue(
  value: number,
  metricName: string,
  metrics: WillyMetric[],
  currency: string,
): string {
  if (!metrics) return value.toString();

  const metric = metrics.find((m) => m.key === metricName);

  if (!isNaN(value)) {
    return formatNumber(value, {
      style: metric?.format ?? 'decimal',
      currency,
      minimumFractionDigits: metric?.minimumFractionDigits ?? 0,
      maximumFractionDigits: metric?.toFixed ?? 2,
    });
  }

  return formatText(value.toString());
}

export function formatColor(color: string, dark = false, metrics?: WillyMetric[]) {
  const gradientColor = GRADIENT_CHART_COLORS.find(
    (c) => color?.includes(c.name) || color?.includes(c.start) || color?.includes(c.stop),
  );
  if (gradientColor) {
    // return dark ? gradientColor.start : gradientColor.stop;
    return gradientColor.start;
  }
  const metric = metrics?.find((m) => m.colorName && color.includes(m.colorName));
  if (metric) {
    return metric.color;
  }

  return color ?? 'black';
}
