import { UserRolesTypes } from '@tw/types';

export type UserRoleMap = {
  [role in UserRolesTypes]: {
    id: role;
    label: string;
  };
};

const USER_ROLES: UserRoleMap = {
  ad_buyer: {
    id: 'ad_buyer',
    label: 'Ad Buyer',
  },
  agency_owner: {
    id: 'agency_owner',
    label: 'Agency Owner',
  },
  brand_owner: {
    id: 'brand_owner',
    label: 'Brand Owner',
  },
  marketing_manager: {
    id: 'marketing_manager',
    label: 'Marketing Manager',
  },
  retention_marketer: {
    id: 'retention_marketer',
    label: 'Retention Marketer',
  },
  operations: {
    id: 'operations',
    label: 'Operations',
  },
  finance_accounting: {
    id: 'finance_accounting',
    label: 'Finance/Accounting',
  },
  Inventory_supply_chain: {
    id: 'Inventory_supply_chain',
    label: 'Inventory/Supply Chain',
  },
};

export default USER_ROLES;
