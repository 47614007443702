import { CustomerActivity, CustomerActivityParams } from '@tw/types/module/services/insights';
import moment from '@tw/moment-cached/module/timezone';
import axiosInstance from 'utils/axiosInstance';

const PAGE_MONTHS = 6;

export const getActivityLog = async (
  shopId: string,
  shopifyCustomerId: string,
  startDate: moment.Moment,
  stopOnDate: moment.Moment,
): Promise<{ activities: CustomerActivity[]; endDate: moment.Moment }> => {
  const url = `/v2/insights/customer-profile/activities`;
  const activities: CustomerActivity[] = [];

  let start = startDate;
  let end;

  while (activities.length === 0 && moment(stopOnDate).isBefore(start)) {
    end = moment(start.clone().subtract(PAGE_MONTHS, 'month'));

    const requestParams: CustomerActivityParams = {
      shopId,
      shopifyCustomerId,
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    };

    const { data } = await axiosInstance.post<null, { data: CustomerActivity[] }>(
      url,
      requestParams,
    );

    console.info('getActivityLog done', {
      customerId: shopifyCustomerId,
      startDate: requestParams.startDate,
      endDate: requestParams.endDate,
      activities: data.length,
    });

    activities.push(...data);
    start = moment(end.clone().subtract(1, 'day'));
  }

  return {
    activities: activities,
    endDate: end || stopOnDate,
  };
};
