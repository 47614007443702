import React, { useCallback, useState } from 'react';
import { AttributionData } from 'types/attribution';
import { MetricsKeys } from 'types/metrics';
import { formatNumber } from 'utils/formatNumber';
import { metrics } from 'constants/metrics/metrics';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';
import Subscriptions from './Subscriptions';

type SubscriptionsButtonProps = {
  item: AttributionData;
  metricKey: MetricsKeys;
  showSubscriptionsButton?: boolean;
};

export const SubscriptionsButton: React.FC<SubscriptionsButtonProps> = ({
  item,
  metricKey,
  showSubscriptionsButton: showSubscriptionsButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currency = useStoreValue($currency);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const metric = metrics[metricKey]!;
  const value = formatNumber((item[metricKey] ?? item.metrics?.[metricKey]) || 0, {
    style: metric.format,
    currency,
    minimumFractionDigits:
      typeof metric.minimumFractionDigits !== 'undefined'
        ? metric.minimumFractionDigits
        : metric.toFixed,
    maximumFractionDigits: metric.toFixed,
  });

  return (
    <div className="roas-table-cell">
      <span
        key={`Subscriptions-button-${item.id}`}
        className={`${item.shouldHighlight ? 'highlight-realtime-attribution' : ''} ${
          showSubscriptionsButton ? 'cursor-pointer text-blue-700' : ''
        }`}
        onClick={() => {
          if (showSubscriptionsButton) {
            toggleIsOpen();
          }
        }}
      >
        {value}
      </span>

      {isOpen && <Subscriptions item={item} onClose={toggleIsOpen} metricKey={metricKey} />}
    </div>
  );
};
