export const campaignStopLoss = {
  status: 'inactive',
  level: 'campaign',
  name: 'Campaign Stop Loss (aka Sinking Ship)',
  conditions: {
    all: [
      {
        any: [
          {
            fact: 'campaign',
            path: '$.spend',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThanInclusive',
            value: {
              fact: 'campaignBudget',
              params: {
                factor: '2.5',
              },
            },
          },
        ],
      },
      {
        any: [
          {
            fact: 'campaign',
            path: '$.pixelRoas',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'lessThan',
            value: {
              fact: 'account',
              path: '$.pixelRoas',
              params: {
                factor: '0.75',
                period: 7,
                unit: 'day',
              },
            },
          },
        ],
      },
    ],
  },
  event: {
    type: 'decrease_budget',
    params: {
      valueType: 'percentage',
      value: '20',
    },
    value: '',
  },
  frequency: '1 days',
  runAt: '00:00',
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
};
export const campaignScaler = {
  status: 'inactive',
  level: 'campaign',
  name: 'Campaign Scaler (aka Set Sail)',
  conditions: {
    all: [
      {
        any: [
          {
            fact: 'campaign',
            path: '$.spend',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThanInclusive',
            value: {
              fact: 'campaignBudget',
              params: {
                factor: '2.5',
              },
            },
          },
        ],
      },
      {
        any: [
          {
            fact: 'campaign',
            path: '$.pixelRoas',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThan',
            value: {
              fact: 'account',
              path: '$.pixelRoas',
              params: {
                period: 7,
                unit: 'day',
                factor: '1',
              },
            },
          },
        ],
      },
    ],
  },
  event: {
    type: 'increase_budget',
    params: {
      level: 'campaign',
      valueType: 'percentage',
      value: '20',
    },
  },
  frequency: '1 days',
  runAt: '00:00',
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
};
export const adsetStopLoss = {
  status: 'inactive',
  level: 'adset',
  name: 'Adset Stop Loss (aka Sinking Ship)',
  conditions: {
    all: [
      {
        any: [
          {
            fact: 'adset',
            path: '$.spend',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThanInclusive',
            value: {
              fact: 'adsetBudget',
              params: {
                factor: '2.5',
              },
            },
          },
        ],
      },
      {
        any: [
          {
            fact: 'adset',
            path: '$.pixelRoas',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'lessThan',
            value: {
              fact: 'account',
              path: '$.pixelRoas',
              params: {
                period: 7,
                unit: 'day',
                factor: '1',
              },
            },
          },
        ],
      },
    ],
  },
  event: {
    type: 'decrease_budget',
    params: {
      level: 'adset',
      valueType: 'percentage',
      value: '20',
    },
  },
  frequency: '1 days',
  runAt: '00:00',
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
};
export const adsetScaler = {
  status: 'inactive',
  level: 'adset',
  name: 'Adset Scaler (aka Set Sail)',
  conditions: {
    all: [
      {
        any: [
          {
            fact: 'adset',
            path: '$.spend',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThanInclusive',
            value: {
              fact: 'adsetBudget',
              params: {
                factor: '2.5',
              },
            },
          },
        ],
      },
      {
        any: [
          {
            fact: 'adset',
            path: '$.pixelRoas',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThan',
            value: {
              fact: 'account',
              path: '$.pixelRoas',
              params: {
                period: 7,
                unit: 'day',
                factor: '1',
              },
            },
          },
        ],
      },
    ],
  },
  event: {
    type: 'increase_budget',
    params: {
      level: 'adset',
      valueType: 'percentage',
      value: '20',
    },
  },
  frequency: '1 days',
  runAt: '00:00',
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
};
export const adStopLossAkaSinkingShip = {
  status: 'inactive',
  level: 'ad',
  name: 'Ad Stop Loss (aka Sinking Ship)',
  conditions: {
    all: [
      {
        any: [
          {
            fact: 'ad',
            path: '$.spend',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'greaterThanInclusive',
            value: {
              fact: 'account',
              path: '$.pixelAov',
              params: {
                factor: '3',
                period: 3,
                unit: 'day',
              },
            },
          },
        ],
      },
      {
        any: [
          {
            fact: 'ad',
            path: '$.pixelRoas',
            params: {
              period: 3,
              unit: 'day',
            },
            operator: 'lessThan',
            value: {
              fact: 'account',
              path: '$.pixelRoas',
              params: {
                factor: '0.75',
                period: 7,
                unit: 'day',
              },
            },
          },
        ],
      },
    ],
  },
  event: {
    type: 'pause',
  },
  frequency: '1 days',
  runAt: '00:00',
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
};
export const adStopLossAkaLeakyBucket = {
  status: 'inactive',
  level: 'ad',
  name: 'Ad Stop Loss (aka Leaky Bucket)',
  conditions: {
    all: [
      {
        any: [
          {
            fact: 'ad',
            path: '$.spend',
            params: {
              period: 30,
              unit: 'day',
            },
            operator: 'greaterThanInclusive',
            value: {
              fact: 'account',
              path: '$.pixelAov',
              params: {
                factor: '3',
                period: 7,
                unit: 'day',
              },
            },
          },
        ],
      },
      {
        any: [
          {
            fact: 'ad',
            path: '$.pixelPurchases',
            params: {
              period: 30,
              unit: 'day',
            },
            operator: 'equal',
            value: '0',
          },
        ],
      },
    ],
  },
  event: {
    type: 'pause',
  },
  frequency: '1 days',
  runAt: '00:00',
  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
};
