import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'index';
import {
  SENSORY_SAVING_INTEGRATION_CHANGES,
  connectInt,
  deleteInt,
  disconnectInt,
  reconnectInt,
} from 'ducks/sensory';
import { Box, Switch, confirm, Text, Tooltip, Menu, Flex } from '@tw/ui-components';
import { IntegrationStatusIcon } from './IntegrationStatusIcon';
import { useAppSelector } from 'reducers/RootType';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { ConnectionMenuItem } from 'routes/integrations/types/ConnectionMenuItem';

export const IntegrationItem = ({ integration }) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const [provider, setProvider] = useState<any>(null);
  const providers = useAppSelector((state) => state.sensory.providers);
  useEffect(() => {
    const isChecked = ['ready', 'pending', 'backfill', 'error', 'retryableError'].includes(
      integration.integration_status,
    );
    const pr = providers.find((x) => x.id === integration.provider_id);
    setProvider(pr);
    setChecked(isChecked);
  }, [integration, providers]);

  const handleConnect = useCallback(async () => {
    try {
      if (
        await confirm({
          title: <Text weight="bold">{'Connect Account'}</Text>,
          message: (
            <Text pb={'md'}>
              {
                'Are you sure you want to connect this account? Data will begin flowing into Triple Whale.'
              }
            </Text>
          ),
        })
      ) {
        dispatch(async () => {
          setChecked(false);
          dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES });
          if (integration.integration_status == 'disconnected')
            await dispatch(reconnectInt(integration));
          else {
            const newInt = {
              ...integration,
              policy_id: 'default',
              settings: providers
                .find((x) => x.id === integration.provider_id)
                ?.settings?.map(((setting) => ({ [setting.field]: setting.defaultValue })) ?? {}),
            };
            await dispatch(connectInt(newInt));
          }
        });
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error connecting ${integration.integration_id} account: ${err.message}`);
    }
  }, [dispatch, integration, providers]);

  const handleDisconnect = useCallback(async () => {
    try {
      if (
        await confirm({
          title: <Text weight="bold">{'Pause Account'}</Text>,
          message: (
            <Text pb={'md'}>
              {
                'Are you sure you want to pause this account? Data will no longer be pulled into Triple Whale.'
              }
            </Text>
          ),
        })
      ) {
        dispatch(async () => {
          setChecked(false);
          dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES });
          await dispatch(disconnectInt(integration));
        });
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error connecting ${integration.integration_id} account: ${err.message}`);
    }
  }, [dispatch, integration]);

  const handleDelete = useCallback(async () => {
    try {
      if (
        await confirm({
          title: <Text weight="bold">{'Delete Account'}</Text>,
          message: (
            <Text pb={'md'}>
              {
                'Are you sure you want to delete this account? All historical data will be deleted from Triple Whale.'
              }
            </Text>
          ),
        })
      ) {
        dispatch(async () => {
          setChecked(false);
          dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES });
          await dispatch(deleteInt(integration.integration_id));
        });
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error connecting ${integration.integration_id} account: ${err.message}`);
    }
  }, [dispatch, integration.integration_id]);

  const menuActions: ConnectionMenuItem[] = useMemo(() => {
    const items: ConnectionMenuItem[] = [];

    if (integration.integration_id) {
      items.push({
        content: 'Delete Account',
        onClick: handleDelete,
      });
      if (integration.integration_status == 'disconnected') {
        items.push({
          content: 'Reconnect Account',
          onClick: handleConnect,
        });
      } else {
        items.push({
          content: 'Pause Account',
          onClick: handleDisconnect,
        });
      }
    }
    if (!integration.integration_id) {
      items.push({
        content: 'Connect Account',
        onClick: handleConnect,
      });
    }

    return items;
  }, [
    handleConnect,
    handleDelete,
    handleDisconnect,
    integration.integration_id,
    integration.integration_status,
  ]);

  return (
    <Flex pt="sm" justify={'space-between'}>
      <Flex>
        <Box mr={'sm'} hiddenFrom={provider?.choose_account ? '' : 'xs'}>
          <Switch
            variant="simple"
            checked={checked}
            onChange={async () => {
              const handle = checked ? handleDisconnect : handleConnect;
              await handle();
            }}
          ></Switch>
        </Box>
        <Text fz="sm" maw="65%" weight={400} lineClamp={1}>
          {integration.provider_account_name}
        </Text>

        <IntegrationStatusIcon
          status={integration.integration_status ?? 'unknown'}
        ></IntegrationStatusIcon>
      </Flex>
      <Flex hiddenFrom={provider?.choose_account ? '' : 'xs'}>
        <Menu>
          <Menu.Target>
            <span>
              <MobileVerticalDotsMajor className="block w-8 cursor-pointer fill-gray-600 dark:fill-gray-300" />
            </span>
          </Menu.Target>
          <Menu.Dropdown>
            {menuActions.map((action, i) => {
              return (
                <Menu.Item key={i} onClick={action.onClick}>
                  {action.content}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Flex>
  );
};
