import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';

export const AOV_SEGMENTS: OptionDescriptor[] = [
  {
    value: '<50',
    label: '<50',
  },
  {
    value: '50-100',
    label: '50-100',
  },
  {
    value: '>100+',
    label: '>100+',
  },
];

export const TOTAL_SPEND_SEGMENTS: OptionDescriptor[] = [
  {
    value: '<50K',
    label: '<50K',
  },
  {
    value: '>50K+',
    label: '>50K+',
  },
];

export const TW_BENCHMARKS_AOV_SEGMENT = 'TW_BENCHMARKS_AOV_SEGMENT';
export const TW_BENCHMARKS_SPEND_SEGMENT = 'TW_BENCHMARKS_SPEND_SEGMENT';
