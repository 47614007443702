import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';

import {
  ActionList,
  Button,
  FormLayout,
  Icon,
  Modal,
  Pagination,
  Popover,
  Spinner,
  TextContainer,
  TextField,
  TextStyle,
} from '@shopify/polaris';

import { ContentHubContext } from '../ContentHub';
import UploadMediaCards from './UploadMediaCards';
import UploadMediaTable from './UploadMediaTable';
import {
  AddImageMajor,
  ChevronRightMinor,
  DeleteMinor,
  EditMinor,
  FolderMajor,
  ReturnMinor,
  UploadMajor,
} from '@shopify/polaris-icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from 'index';
import { mediaPicker } from 'ducks/contentHub/mediaPicker';
import { changeContentHubFolder, openContentHubUploadMedia } from 'ducks/actions';
import { MediaItemType } from '@tw/types';

const RESULTS_PER_PAGE = 12;

type UploadMediaCollectionProps = {};

const UploadMediaCollection: React.FC<UploadMediaCollectionProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { displayMode, reloadData, setReloadData, isMediaPicker } = useContext(ContentHubContext);
  const shopInitiated = useSelector((state: RootState) => state.shopInitiated);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const hasContentHubWithFolders = useSelector(
    (state: RootState) => state.hasContentHubWithFolders,
  );
  const [folders, setFolders] = useState<any[]>([]);
  const [nonexistingFolder, setNonexisting] = useState(false);
  const [files, setFiles] = useState<MediaItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [pageNum, setPageNum] = useState<number>(0);
  const [currentFolderPath, setCurrentFolderPath] = useState<string | null>();
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [showFolderActions, setShowFolderActions] = useState(false);
  const [newFolder, setNewFolder] = useState('');
  const [renamedFolder, setRenamedFolder] = useState('');
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [showRenameFolder, setShowRenameFolder] = useState(false);
  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  // const [reload, setReload] = useState(false);

  const switchFolder = (folder) => {
    navigate({
      pathname: `/creative-hub/uploaded${folder}`,
      search: location.search,
    });
  };

  useEffect(() => {
    if (isMediaPicker || !hasContentHubWithFolders) {
      setCurrentFolderPath('/');
    }
    //had to use window.location because location is decoded and we need the encoded version
    let folderPath = window.location.href.match(/creative-hub\/uploaded(.*)/);
    if (folderPath && folderPath[1]) {
      let cleanedFolderPath = folderPath[1].replace(/\?.+/, '');
      console.log(cleanedFolderPath);
      setCurrentFolderPath(cleanedFolderPath);
      dispatch(changeContentHubFolder(cleanedFolderPath));
    }
  }, [location, isMediaPicker, hasContentHubWithFolders, dispatch]);

  const loadData = useCallback(async () => {
    setNonexisting(false);
    if (currentFolderPath) {
      // setData([]);
      setFolders([]);
      setFiles([]);
      if (!pageNum) {
        setPageNum(0);
      }
      const url = `/v2/media/get-folder-contents`;
      setLoading(true);
      try {
        const res = await axiosInstance.post(url, {
          shopId: currentShopId,
          page: pageNum.toString(),
          folderPath: currentFolderPath,
          sorted: hasContentHubWithFolders,
        });

        let data = res.data;
        let formatted = data.map((d) => {
          return { ...d, id: d._id };
        });
        let folders = formatted.filter((d) => d.media_type === 'folder');
        let files = formatted.filter((d) => d.media_type !== 'folder');
        setHasNext(data?.length >= RESULTS_PER_PAGE);
        setFolders(folders);
        setFiles(files);
        // setData(formatted);
        setLoading(false);
        setReloadData?.(false);
      } catch (e) {
        if ((e.data = 'Folder does not exist')) {
          setNonexisting(true);
          setLoading(false);
        }
        console.log(e);
      }
    }
  }, [currentShopId, pageNum, setReloadData, currentFolderPath, hasContentHubWithFolders]);

  useEffect(() => {
    if (!!reloadData) {
      setPageNum(0);
      loadData();
    }
  }, [loadData, reloadData]);

  useEffect(() => {
    if (shopInitiated) {
      loadData();
    }
  }, [loadData, shopInitiated]);

  useEffect(() => {
    if (!currentFolderPath) {
      return;
    }
    let path = currentFolderPath;
    let breadcrumbs: any = [];
    while (!!path) {
      let lastPart = path.match(/[^\/]*\/$/g)?.[0];
      if (lastPart) {
        let newPath = path.replace(lastPart, '');
        let name = lastPart;
        name = name.replace('/', '');
        name = !!name ? name : 'Media Library';
        breadcrumbs.push({ name: decodeURI(name), path: path });
        path = newPath;
      } else {
        breadcrumbs.push({ name: 'Media Library', path: path });
        path = '';
      }
    }
    breadcrumbs = breadcrumbs.reverse();
    setBreadcrumbs(breadcrumbs);
  }, [currentFolderPath]);

  const toggleActive = useCallback(() => setShowFolderActions((active) => !active), []);

  // useEffect(()=>{
  //     setNoSpacesError(newFolder.indexOf(' ') >= 0);
  // },[newFolder])

  const createFolder = () => {
    let url = `/v2/media/create-folder`;
    axiosInstance
      .post(url, { shopId: currentShopId, folderPath: currentFolderPath, folderName: newFolder })
      .then((res) => {
        loadData();
        setShowNewFolder(false);
        setNewFolder('');
      });
  };

  const renameFolder = async () => {
    let url = `/v2/media/rename-folder`;
    axiosInstance
      .post(url, { shopId: currentShopId, folderPath: currentFolderPath, newFolderName: newFolder })
      .then((res) => {
        setCurrentFolderPath(res.data);
        setShowRenameFolder(false);
        setNewFolder('');
      });
  };

  const uploadMediaToFolder = () => {
    dispatch(openContentHubUploadMedia(currentFolderPath || '/'));
  };

  return (
    <div className="flex flex-col gap-4">
      {hasContentHubWithFolders && (
        <div className="flex flex-col justify-between">
          <p className="font-bold leading-none flex items-center gap-1">
            {breadcrumbs.map((b, i) => {
              return (
                <>
                  {' '}
                  <span>
                    {i !== 0 && (
                      <ChevronRightMinor className="w-5 h-5 fill-logo flex items-center" />
                    )}
                  </span>
                  {breadcrumbs.length > 1 && i === breadcrumbs.length - 1 ? (
                    <div>
                      <Popover
                        sectioned
                        preferredAlignment="left"
                        active={showFolderActions}
                        activator={
                          <Button onClick={toggleActive} plain>
                            {b.name}
                          </Button>
                        }
                        autofocusTarget="first-node"
                        onClose={toggleActive}
                      >
                        <ActionList
                          actionRole="menuitem"
                          sections={[
                            {
                              title: 'Folder options',
                              items: [
                                {
                                  content: 'Create folder',
                                  icon: FolderMajor,
                                  onAction: () => {
                                    toggleActive();
                                    setShowNewFolder(true);
                                  },
                                },
                                {
                                  content: 'Rename folder',
                                  icon: EditMinor,
                                  onAction: () => {
                                    toggleActive();
                                    setShowRenameFolder(true);
                                  },
                                },
                                {
                                  content: 'Upload media to folder',
                                  icon: AddImageMajor,
                                  onAction: () => {
                                    toggleActive();
                                    uploadMediaToFolder();
                                  },
                                },
                                // {
                                //   destructive: true,
                                //   content: 'Delete folder and all contents',
                                //   icon: DeleteMinor,
                                //   onAction: () => {toggleActive();setShowDeleteFolder(true)}

                                // },
                              ],
                            },
                          ]}
                        />
                      </Popover>
                    </div>
                  ) : breadcrumbs.length == 1 ? (
                    <div>
                      <Popover
                        preferredAlignment="left"
                        sectioned
                        active={showFolderActions}
                        activator={
                          <span onClick={toggleActive} className="primary-text cursor-pointer">
                            {b.name}
                          </span>
                        }
                        autofocusTarget="first-node"
                        onClose={toggleActive}
                      >
                        <ActionList
                          actionRole="menuitem"
                          sections={[
                            {
                              title: 'Folder options',
                              items: [
                                {
                                  content: 'Create folder',
                                  icon: FolderMajor,
                                  onAction: () => {
                                    toggleActive();
                                    setShowNewFolder(true);
                                  },
                                },
                                {
                                  content: 'Upload media to folder',
                                  icon: AddImageMajor,
                                },
                              ],
                            },
                          ]}
                        />
                      </Popover>
                    </div>
                  ) : (
                    <span
                      className="primary-text cursor-pointer"
                      onClick={() => switchFolder(b.path)}
                    >
                      {b.name}
                    </span>
                  )}
                </>
              );
            })}
          </p>
        </div>
      )}
      {loading && (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {!loading && !nonexistingFolder && (
        <div className="h-[73vh] flex flex-col overflow-auto gap-6.5">
          {folders.length > 0 && hasContentHubWithFolders && (
            <div className="flex flex-col gap-4">
              <h2 className="uppercase text-gray-500 font-semibold border-b border-solid border-gray-400 border-0">
                Folders
              </h2>
              <div className="grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] auto-rows-[75px]">
                {folders.map((f, i) => {
                  return (
                    <div
                      className="rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white cursor-pointer flex gap-4 items-center"
                      onClick={() => switchFolder(`${f.fs_path}${f.fs_name}/`)}
                    >
                      <div className="flex">
                        <Icon source={FolderMajor} color="subdued" />
                      </div>
                      <span>{decodeURI(f.fs_name)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {files.length > 0 ? (
            <div className="flex flex-col gap-4">
              {folders.length > 0 && hasContentHubWithFolders && (
                <h2 className="uppercase text-gray-500 font-semibold border-b border-solid border-gray-400 border-0">
                  Files
                </h2>
              )}
              <UploadMediaCards data={files} />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center p-5">
              <h2 className="uppercase text-gray-500 font-semibold border-b border-solid border-gray-400 border-0">
                No Media Found
              </h2>
            </div>
          )}
        </div>
      )}
      {nonexistingFolder && (
        <div className="w-full h-full flex items-center justify-center">
          <h2 className="uppercase text-gray-500 font-semibold border-b border-solid border-gray-400 border-0">
            Folder does not exist
          </h2>
        </div>
      )}
      {(folders.length > 0 || files.length > 0) && !nonexistingFolder && (
        <div className="flex items-center mt-auto justify-center">
          <Pagination
            hasPrevious={pageNum > 0}
            onPrevious={() => {
              setPageNum((p) => p - 1);
            }}
            hasNext={hasNext}
            onNext={() => {
              setPageNum((p) => p + 1);
            }}
            label={`Page ${pageNum + 1}`}
          />
        </div>
      )}
      <Modal open={showNewFolder} title="Create new folder" onClose={() => setShowNewFolder(false)}>
        <div className="p-6">
          <FormLayout>
            <TextField
              label="New folder"
              value={newFolder}
              onChange={setNewFolder}
              autoComplete="off"
            />
            {/* {noSpacesError && <p className='text-red-500'>Folder name cannot contain spaces</p>} */}
            <Button
              size="slim"
              disabled={newFolder.length < 1}
              onClick={() => {
                createFolder();
                setShowNewFolder(false);
              }}
            >
              Create Folder
            </Button>
          </FormLayout>
        </div>
      </Modal>
      <Modal
        open={showRenameFolder}
        title="Rename folder"
        onClose={() => setShowRenameFolder(false)}
      >
        <div className="p-6">
          <FormLayout>
            <TextField
              label="New folder"
              value={newFolder}
              onChange={setNewFolder}
              autoComplete="off"
            />
            {/* {noSpacesError && <p className='text-red-500'>Folder name cannot contain spaces</p>} */}
            <Button
              size="slim"
              disabled={newFolder.length < 1}
              onClick={() => {
                renameFolder();
                setShowNewFolder(false);
              }}
            >
              Rename Folder
            </Button>
          </FormLayout>
        </div>
      </Modal>
      <Modal
        open={showDeleteFolder}
        title="Delete folder"
        onClose={() => setShowDeleteFolder(false)}
      >
        <div className="p-6">
          <p className="pb-10">Delete folder and all contents? This action cannot be undone.</p>
          <Button destructive>Delete</Button>
        </div>
      </Modal>
    </div>
  );
};

export default UploadMediaCollection;
