import { AppShell, Box, Stack } from '@tw/ui-components';
import { TopSection } from './TopSection';
import { LinksSection } from './LinksSection';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';

export const SettingsNav = () => {
  return (
    <Box w="100%" overflow="auto" withScrollbar={false}>
      <Stack w="100%" h="100vh">
        <AppShell.Section mah={60} p="lg" borderBottom={TOP_BAR_BORDER_VAR}>
          <TopSection />
        </AppShell.Section>

        <AppShell.Section grow px="md">
          <LinksSection />
        </AppShell.Section>
      </Stack>
    </Box>
  );
};
