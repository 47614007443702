import { $currentShopId } from '$stores/$shop';
import { getAppConfigManager } from '$stores/AppConfigManager';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { ActionIcon, Flex, Icon, Text } from '@tw/ui-components';
import { $lastSettingsRoute, $navOpen } from 'components/Nav/stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export const TopSection = () => {
  const isSmall = useIsSmall();

  const goBackToMainNav = () => {
    // save the current route in settings as last settings route
    $lastSettingsRoute.set(window.location.pathname);

    const currentShopId = $currentShopId.get();
    // go back to most recent page in regular view
    if (currentShopId) {
      const configManager = getAppConfigManager();
      configManager.gotoLastSavedRouteForVersion({ shopId: currentShopId });
    }

    // since there is a different navigation for 3.0, need to close drawer when going back to main page
    const version = $activeAppVersion.get();
    if (isSmall && version === '3.0') $navOpen.set(false);
  };

  return (
    <Flex justify="space-between" align="center" cursor="pointer" onClick={goBackToMainNav}>
      <Flex gap="xs" align="center">
        <Icon name="caret-left" height={13} width={8} />
        <Text fz={16} fw={500}>
          Settings
        </Text>
      </Flex>
      {isSmall && (
        <ActionIcon
          variant="transparent"
          icon="close"
          iconSize={12}
          onClick={(e) => {
            e.stopPropagation();
            $navOpen.set(false);
          }}
        />
      )}
    </Flex>
  );
};
