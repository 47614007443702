import { $activeAppVersion } from '$stores/nav-config-stores';
import { useStoreValue } from '@tw/snipestate';
import { Flex, Size } from '@tw/ui-components';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { CurrentDateRangePopover } from './CurrentDateRangePopover';
import { ComparisonDateRangePopover } from './ComparisonDateRangePopover';

type MainDatePickerProps = {
  showGroupByButton?: boolean;
  disabled?: boolean;
  showCompareButton?: boolean;
  earliestDateProp?: string | Date | null;
  unSupportedOptions?: DatePickerTimePeriods[];
  skipLoadOnCompareChange?: boolean;
  isWillyDashboard?: boolean;
  buttonSize?: Exclude<Size, 0>;
};

export const MainDatePicker: React.FC<MainDatePickerProps> = ({
  showGroupByButton,
  disabled = false,
  showCompareButton = false,
  earliestDateProp = '',
  unSupportedOptions = [],
  skipLoadOnCompareChange,
  isWillyDashboard = false,
  buttonSize = 'sm',
}) => {
  const activeAppVersion = useStoreValue($activeAppVersion);

  return (
    <Flex align="center" gap="sm" wrap={activeAppVersion !== '2.0' ? undefined : 'wrap'}>
      <CurrentDateRangePopover
        earliestDateProp={earliestDateProp}
        disabled={disabled}
        isWillyDashboard={isWillyDashboard}
        buttonSize={buttonSize}
        unSupportedOptions={unSupportedOptions}
        showGroupByButton={showGroupByButton}
      />
      {showCompareButton && (
        <ComparisonDateRangePopover
          earliestDateProp={earliestDateProp}
          disabled={disabled}
          isWillyDashboard={isWillyDashboard}
          buttonSize={buttonSize}
        />
      )}
    </Flex>
  );
};
