export const ATTRIBUTION_MODEL_CHANGED = 'ATTRIBUTION_MODEL_CHANGED';
export const DATE_MODEL_CHANGED = 'DATE_MODEL_CHANGED';
export const ADD_ONE_DAY_VIEW = 'ADD_ONE_DAY_VIEW';
export const WRAP_TABLE_LINES = 'WRAP_TABLE_LINES';
export const SHOW_ACTIVE_CAMPAIGNS_ONLY = 'SHOW_ACTIVE_CAMPAIGNS_ONLY';
export const ATTRIBUTION_WINDOW_CHANGED = 'ATTRIBUTION_WINDOW_CHANGED';
export const ATTRIBUTION_SUBSCRIPTION_CHANGED = 'ATTRIBUTION_SUBSCRIPTION_CHANGED';
export const PPS_LOOK_BACK_WINDOW_CHANGED = 'PPS_LOOK_BACK_WINDOW_CHANGED';
export const ATTRIBUTION_LAST_REFRESH = 'ATTRIBUTION_LAST_REFRESH';
export const ATTRIBUTION_CHART_OPEN_CHANGED = 'ATTRIBUTION_CHART_OPEN_CHANGED';
export const DEFAULT_SOURCE_CHANGED = 'DEFAULT_SOURCE_CHANGED';
export const SELECTED_COLUMNS_CHANGED = 'SELECTED_COLUMNS_CHANGED';
export const ATTRIBUTION_FREE_SEARCH_CHANGED = 'ATTRIBUTION_FREE_SEARCH_CHANGED';
export const ATTRIBUTION_SUMMARY_ADD_SOURCE = 'ATTRIBUTION_SUMMARY_ADD_SOURCE';
export const ATTRIBUTION_SUMMARY_REMOVE_SOURCE = 'ATTRIBUTION_SUMMARY_REMOVE_SOURCE';
export const ATTRIBUTION_SUMMARY_EXTRA_SOURCES = 'ATTRIBUTION_SUMMARY_EXTRA_SOURCES';
export const SHOW_TOTAL_IMPACT_MODAL = 'SHOW_TOTAL_IMPACT_MODAL';
export const USE_NEW_MODELS = 'USE_NEW_MODELS';
export const ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND = 'ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND';
export const USE_NEXUS = 'USE_NEXUS';
export const INIT_ATTRIBUTION = 'INIT_ATTRIBUTION';
export const RUN_WORKFLOW = 'RUN_WORKFLOW';
