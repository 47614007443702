import { $store } from '@tw/snipestate';
import { WorkflowStep } from 'components/Willy/types/willyTypes';

type SequenceBuilderSequencesStoreType = {
  steps?: WorkflowStep[];
  conversationId?: string;
};

export const $sequenceBuilderSequences = $store<SequenceBuilderSequencesStoreType>({});

export const setSequenceSteps = (props: { conversationId?: string; steps?: WorkflowStep[] }) => {
  $sequenceBuilderSequences.set((x) => {
    return {
      ...x,
      conversationId: props.conversationId,
      steps: props.steps,
    };
  });
};
