import { $createModal } from '$stores/willy/$createModal';
import { Icon, Text } from '@tw/ui-components';
import { CreateDashboardModal } from './CreateDashboardModal';

export function CreateDashSection() {
  return (
    <>
      <div
        onClick={() => $createModal.set({ opened: true })}
        className="px-4 py-2 flex items-center gap-2 -my-[16px] bg-blue-600 hover:bg-blue-700 cursor-pointer"
      >
        <Icon name="plus" color={'white'} width={8} />
        <Text fz="12" color={'white'}>
          Create
        </Text>
      </div>
      <CreateDashboardModal />
    </>
  );
}
