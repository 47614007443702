import moment from '@tw/moment-cached';
import {
  CDPActionFilter,
  CDPSegmentFilterType,
  CDPSegmentQuery,
  CDPUserPropertyFilter,
  Segment,
  SegmentSync,
  SegmentType,
  UN_SUPPORTED_ACTIONS,
  UN_SUPPORTED_PROPERTIES,
} from '@tw/types/module/services/cdp';

export type SegmentDataView = {
  id: string;
  name: string;
  description?: string;
  query?: CDPSegmentQuery;
  stringSize?: string;
  size?: number;
  updatedAt?: string;
  orders?: string;
  revenue?: string;
  historicalLTV30Days?: string;
  historicalLTV60Days?: string;
  historicalLTV90Days?: string;
  historicalLTV180Days?: string;
  historicalLTV365Days?: string;
  isCurentlyBuilding: boolean;
  type: SegmentType;
  integrationsSyncDetails: SegmentSync[];
  hasActiveSyncs: boolean;
  label?: string;
  hasUnSupportedProperty?: boolean;
};

export function getSegmentDataView(segment: Segment, currency?: string): SegmentDataView {
  return {
    id: segment.id,
    name: segment.name,
    description: segment.description,
    query: segment.type === SegmentType.LOOKALIKE ? undefined : segment.query,
    isCurentlyBuilding: !segment.lastInstance,
    integrationsSyncDetails: segment.integrationsSyncDetails || [],
    hasActiveSyncs: segment.integrationsSyncDetails?.some((i) => i.isSynced) || false,
    type: segment.type,
    label:
      segment.type === SegmentType.RFM || segment.type === SegmentType.LOOKALIKE ? 'AI' : undefined,
    ...(segment.lastInstance && {
      stringSize: (segment.lastInstance.customersCount || 0).toLocaleString(),
      size: segment.lastInstance.customersCount,
      updatedAt: moment(segment.lastInstance.createdAt).format('LLL'),
      orders: (segment.lastInstance.ordersCount || 0).toLocaleString(),
      revenue: (segment.lastInstance.revenue || 0).toLocaleString(undefined, {
        style: 'currency',
        currency,
      }),
      historicalLTV30Days: (segment.lastInstance.historicalLTV30Days || 0).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
          style: 'currency',
          currency,
        },
      ),
      historicalLTV60Days: (segment.lastInstance.historicalLTV60Days || 0).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
          style: 'currency',
          currency,
        },
      ),
      historicalLTV90Days: (segment.lastInstance.historicalLTV90Days || 0).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
          style: 'currency',
          currency,
        },
      ),
      historicalLTV180Days: (segment.lastInstance.historicalLTV180Days || 0).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
          style: 'currency',
          currency,
        },
      ),
      historicalLTV365Days: (segment.lastInstance.historicalLTV365Days || 0).toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
          style: 'currency',
          currency,
        },
      ),
      hasUnSupportedProperty:
        UN_SUPPORTED_PROPERTIES.some((property) => {
          return segment.query?.filters.some((f) => {
            return f.filters.some((filter) => {
              return (
                (filter as CDPActionFilter | CDPUserPropertyFilter)?.definition?.property
                  ?.property === property
              );
            });
          });
        }) ||
        UN_SUPPORTED_ACTIONS.some((action) => {
          return segment.query?.filters.some((f) => {
            return f.filters.some((filter) => {
              if (filter?.type == CDPSegmentFilterType.ACTION) {
                return (filter as CDPActionFilter)?.definition?.action?.type === action;
              }
            });
          });
        }),
    }),
  };
}
