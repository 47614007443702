const PinIconBase64 = () => (
  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABICAYAAABCzyOOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAQqADAAQAAAABAAAASAAAAACiHZm2AAACv0lEQVR4Ae2aPWsVQRSGz5l7CzFeERIwYBMsUojaijGFIqQQLKMWJtjoHxAbG1cQBHt7Kwtj5x8QhKSRYCE2gihesRAsTOKNVzPHWUmxm2J3PvYMiu9WO3fed+ac556d/STCBgIgAAIgAAIgAAIgAAIgAAIgAAIgkIkAa88zOL14S0QepMzDTLc31p7eTxmjzWvaBMn9PVpLHYPJrKeO0eZXBzHZn3hJxD/bAmnqt33590G8f/5o25X2q6ZEW/qGmy9WvrRokrvVK6KMUIjexkbKzO9ivSG+LCCY+ENIUFWtMH+strX2s4AQstEg2NqhVvLVcbOAYOp9qk4asu8Ojc8h+lhtFhDG2B+xAVrhaG/InFlAMPeiT5/MEu3960Ds/LLRybhT7zgkoVhtlooQQ73YAK3I/lhviC8LCBaZDAmqpmWeqrWVGllAUEIySRADoOUBITb6X3VXpUcC8omW5gFBPBMbIQufiPWG+PKAEDoZElRVK0yzM2ev7av+prGvDsI9lHG3GnQ8OniR3tfxSL0q1EEcOnPlqINxIBqEM1qyCyl+H686iJ0OknBnjgs+yaRo1EGwlYspAZZeIT41OL8cfy3iEYAqiMMLSxPuecI5jziaJW6d4O+jq82itF5VEFub28sk0smKL0I30lJtdquBKIrCsNDN5un9e4Xk2GB+cd7fEaaMvhlqm2Z9PHXZ/YvX23Qh/Wx5ejx88zjE46tVecFTLmyyNXrtlrlp30B8dWx4bmN1Jfldyd75VA4NB+GhBoQyeFdl9/Ym0UVbpSJ8Azs4d6mw1t4p9caYu99WnxS+3q51KhXRdZA5xgOIXcoAARD1Aw4VgYpARdQJoCLqPLBGoCJQEXUCqIg6D6wRuzz6dS55W2zkmXsy++eLGLfvPkPEBgIgAAIgAAIgAAIgAAIgAAIgAAIg8B8Q+A2Aw5JUznMbHgAAAABJRU5ErkJggg=="
    style={{ height: 25 }}
    className="darkmode-white-filter"
    alt="Pin icon"
  />
);

export default PinIconBase64;
