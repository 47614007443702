import '@shopify/polaris/build/esm/styles.css?inline';
import 'react-nestable/dist/styles/index.css';

import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { appStarted } from 'ducks/shop';
import { initAuth } from './ducks/auth';
import rootReducer from './reducers';
import { $redux } from '$stores/$redux';

const snipestateMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  const updatedState = store.getState();
  $redux.set(updatedState);

  return result;
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(
      snipestateMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

store.dispatch(appStarted());
store.dispatch(initAuth());
