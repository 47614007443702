import React, { useEffect, useRef } from 'react';
import './DraggableResizable.scss';

type DraggableResizableType = {
  parentSelector: string;
  elementSelector: string;
  barSelector: string;
  resizerSelector: string;
};

export class DraggableResizable {
  parent: HTMLElement | null;
  element: HTMLElement | null;
  bar: HTMLElement | null;
  resizers: NodeListOf<HTMLElement>;
  isResizing: boolean;
  currentResizer: HTMLElement | null;
  prevX: number = 0;
  prevY: number = 0;
  isInitialized: boolean = false;
  boundMousedownDrag: (e: MouseEvent) => void;
  boundMousedownResize: (e: MouseEvent) => void;
  boundResize: () => void;
  resizeTimeout: NodeJS.Timeout | null;

  constructor({
    parentSelector,
    elementSelector,
    barSelector,
    resizerSelector,
  }: DraggableResizableType) {
    this.parent = document.querySelector(parentSelector);
    this.element = document.querySelector(elementSelector);
    this.bar = document.querySelector(barSelector);
    this.resizers = document.querySelectorAll(resizerSelector);

    this.isResizing = false;
    this.currentResizer = null;

    this.mousemoveDrag = this.mousemoveDrag.bind(this);
    this.mouseupDrag = this.mouseupDrag.bind(this);
    this.mousemoveResize = this.mousemoveResize.bind(this);
    this.mouseupResize = this.mouseupResize.bind(this);

    this.boundMousedownDrag = this.mousedownDrag.bind(this);
    this.boundMousedownResize = this.mousedownResize.bind(this);
    this.boundResize = this.updateElementSizeAndPosition.bind(this);
    this.resizeTimeout = null;
  }

  init() {
    if (!this.isInitialized) {
      this.initDrag();
      this.initResize();
      this.initBodyResize();
      this.isInitialized = true;
    }
  }

  initDrag() {
    this.bar?.removeEventListener('mousedown', this.boundMousedownDrag);
    this.bar?.addEventListener('mousedown', this.boundMousedownDrag);
  }

  mousedownDrag(e: MouseEvent) {
    if (this.parent) {
      this.mouseupDrag();
      window.addEventListener('mousemove', this.mousemoveDrag);
      window.addEventListener('mouseup', this.mouseupDrag);

      this.prevX = e.clientX;
      this.prevY = e.clientY;
    }
  }

  mousemoveDrag(e: MouseEvent) {
    if (!this.isResizing && this.element && this.parent) {
      const rect = this.element.getBoundingClientRect();
      const parentRect = this.parent.getBoundingClientRect();

      const rectW = rect.width;
      const rectH = rect.height;

      let newX = this.prevX - e.clientX;
      let newY = this.prevY - e.clientY;
      let newLeft = rect.left - newX - parentRect.left;
      let newTop = rect.top - newY - parentRect.top;

      // Constrain movement within parent bounds
      if (newLeft < 0) newLeft = 0;
      if (newLeft + rectW > parentRect.width) newLeft = parentRect.width - rectW;
      if (newTop < 0) newTop = 0;
      if (newTop + rectH > parentRect.height) newTop = parentRect.height - rectH;

      if (rect.height + newTop < parentRect.bottom) {
        this.element.classList.add('rounded');
      } else {
        this.element.classList.remove('rounded');
      }

      this.element.style.left = newLeft + 'px';
      this.element.style.top = newTop + 'px';

      this.prevX = e.clientX;
      this.prevY = e.clientY;
    }
  }

  mouseupDrag() {
    window.removeEventListener('mousemove', this.mousemoveDrag);
    window.removeEventListener('mouseup', this.mouseupDrag);
  }

  initResize() {
    this.resizers.forEach((resizer) => {
      resizer.removeEventListener('mousedown', this.boundMousedownResize);
      resizer.addEventListener('mousedown', this.boundMousedownResize);
    });
  }

  mousedownResize(e: MouseEvent) {
    this.currentResizer = e.target as HTMLElement;
    this.isResizing = true;

    this.prevX = e.clientX;
    this.prevY = e.clientY;

    window.addEventListener('mousemove', this.mousemoveResize);
    window.addEventListener('mouseup', this.mouseupResize);
  }

  mousemoveResize(e: MouseEvent) {
    if (this.isResizing && this.element && this.currentResizer && this.parent) {
      const body = document.body.getBoundingClientRect();
      const rect = this.element.getBoundingClientRect();
      const parentRect = this.parent.getBoundingClientRect();
      let newWidth = rect.width;
      let newHeight = rect.height;
      let newLeft = rect.left - parentRect.left;
      let newTop = rect.top - parentRect.top;

      const deltaX = e.clientX - this.prevX;
      const deltaY = e.clientY - this.prevY;

      if (this.currentResizer.classList.contains('se')) {
        newWidth += deltaX;
        newHeight += deltaY;
      } else if (this.currentResizer.classList.contains('sw')) {
        newWidth -= deltaX;
        newHeight += deltaY;
        newLeft += deltaX;
      } else if (this.currentResizer.classList.contains('ne')) {
        newWidth += deltaX;
        newHeight -= deltaY;
        newTop += deltaY;
      } else if (this.currentResizer.classList.contains('nw')) {
        newWidth -= deltaX;
        newHeight -= deltaY;
        newLeft += deltaX;
        newTop += deltaY;
      }

      // Constrain resizing within parent bounds
      newWidth = Math.min(newWidth, body.width - parentRect.left);
      newHeight = Math.min(newHeight, body.height - parentRect.top);
      newLeft = Math.max(0, Math.min(newLeft, parentRect.width - newWidth));
      newTop = Math.max(0, Math.min(newTop, parentRect.height - newHeight));

      // Apply new dimensions and positions
      this.element.style.width = `${newWidth}px`;
      this.element.style.height = `${newHeight}px`;
      this.element.style.left = `${newLeft}px`;
      this.element.style.top = `${newTop}px`;

      this.prevX = e.clientX;
      this.prevY = e.clientY;
    }
  }

  mouseupResize() {
    window.removeEventListener('mousemove', this.mousemoveResize);
    window.removeEventListener('mouseup', this.mouseupResize);
    this.isResizing = false;
  }

  debouncedResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateElementSizeAndPosition();
    }, 300);
  }

  initBodyResize() {
    window.addEventListener('resize', this.debouncedResize.bind(this));
  }

  updateElementSizeAndPosition() {
    if (this.element && this.parent) {
      const rect = this.element.getBoundingClientRect();
      const parentRect = this.parent.getBoundingClientRect();

      // Constrain within parent bounds
      let newLeft = Math.max(
        0,
        Math.min(rect.left - parentRect.left, parentRect.width - rect.width),
      );
      let newTop = Math.max(
        0,
        Math.min(rect.top - parentRect.top, parentRect.height - rect.height),
      );

      this.element.style.left = `${newLeft}px`;
      this.element.style.top = `${newTop}px`;
      this.element.style.width = `${Math.min(rect.width, parentRect.width - newLeft)}px`;
      this.element.style.height = `${Math.min(rect.height, parentRect.height - newTop)}px`;
    }
  }

  unmount() {
    this.mouseupResize();
    this.mouseupDrag();

    window.removeEventListener('resize', this.debouncedResize);
    this.bar?.removeEventListener('mousedown', this.boundMousedownDrag);
    this.resizers.forEach((resizer) => {
      resizer.removeEventListener('mousedown', this.boundMousedownResize);
    });

    if (this.element) {
      this.element.style.width = '';
      this.element.style.height = '';
      this.element.style.left = '';
      this.element.style.top = '';
    }

    this.isInitialized = false;
  }
}
