export const projectId = 'shofifi';

export default {
  apiKey: 'AIzaSyA8u9F2mgVXJymrXApBAyRijNn9-z60Xio',
  authDomain: 'shofifi.firebaseapp.com',
  databaseURL: 'https://shofifi.firebaseio.com',
  projectId,
  storageBucket: 'shofifi.appspot.com',
  messagingSenderId: '1072436220726',
  appId: '1:1072436220726:web:6b056c3c2c44710a6f9997',
  measurementId: 'G-ZG3XHCNW3L',
};
