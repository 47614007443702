import { useMemo, useState } from 'react';
import { willyOperators } from './constants';
import {
  BqColumn,
  FilterOperator,
  WillyOperatorItem,
} from '@tw/willy-data-dictionary/module/columns/types';
import { Select } from '@tw/ui-components';

type WillyDynamicFieldOperatorProps = {
  restrictedOperators?: FilterOperator[];
  onChange: (newOperator: FilterOperator) => void;
  operator?: FilterOperator;
  column?: BqColumn;
  hasOptions?: boolean;
  allowMultiple?: boolean;
  disabled?: boolean;
  withinPortal?: boolean;
};

export const WillyDynamicFieldOperator: React.FC<WillyDynamicFieldOperatorProps> = ({
  column,
  restrictedOperators,
  operator,
  onChange,
  hasOptions,
  allowMultiple,
  disabled,
  withinPortal = false,
}) => {
  const operatorOptions = useMemo(() => {
    const allOptions = Object.values(willyOperators);
    const forSingle = allOptions.filter((op) => op.onlyInSingle);
    const forMulti = allOptions.filter((op) => op.onlyInMulti);

    let optionsToShow = allOptions.filter(
      (op) => !op.onlyInSingle && !op.onlyInMulti && op.applyInTypes.includes('all'),
    );

    if (!hasOptions) {
      optionsToShow = [...optionsToShow, ...forSingle];
    }

    // if (options && column?.multiSelect) {
    if (allowMultiple) {
      optionsToShow = [...optionsToShow, ...forMulti];
    }

    // if (parameter.sourceType === 'forecast') {
    if (restrictedOperators?.length) {
      optionsToShow = [willyOperators['=']];
    }

    optionsToShow = optionsToShow.filter((op) => {
      if (!column?.type) {
        return true;
      }
      return op.applyInTypes.includes(column.type) || op.applyInTypes.includes('all');
    });

    optionsToShow = optionsToShow.map((op) => {
      if (op.value !== '=' && op.value !== '!=') {
        return op;
      }
      return {
        ...op,
        label: changeNumericLabelForNumeric(op),
      };
    });

    return optionsToShow;
  }, [column?.type, hasOptions, restrictedOperators, allowMultiple]);

  return (
    <div>
      <Select
        searchable
        styles={{
          input: { width: '100px' },
        }}
        data={operatorOptions}
        comboboxProps={{
          withinPortal: !!withinPortal,
          styles: {
            dropdown: {
              minWidth: 'max-content',
            },
            options: {
              minWidth: 'max-content',
            },
          },
        }}
        value={operator || '='}
        disabled={disabled}
        onChange={(value) => {
          if (value === null) {
            return;
          }

          onChange(value as FilterOperator);
        }}
      />
    </div>
  );
};

function changeNumericLabelForNumeric(option: WillyOperatorItem<'=' | '!='>) {
  switch (option.value) {
    case '=':
      return 'Equal';
    case '!=':
      return 'Not equal';
    default:
      return option.label;
  }
}
