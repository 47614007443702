import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { $observer } from '@tw/snipestate';
import { FAVORITE_PROMPTS_COLLECTION } from 'components/Willy/constants';
import { WillyPrompt } from 'components/Willy/types/willyTypes';
import _db, { toArray } from 'utils/DB';

export const $favoritePrompts = $observer<WillyPrompt[]>([], (get, set) => {
  const shopId = get($currentShopId);
  const userId = get($userId);
  if (!shopId || !userId) return;

  return _db()
    .collection(FAVORITE_PROMPTS_COLLECTION)
    .onSnapshot((querySnapshot) => {
      const data = toArray<WillyPrompt>(querySnapshot);

      set(data);
    });
});
