import axiosInstance from '../../utils/axiosInstance';
import { combineReducers, Reducer } from 'redux';
import { ShopifySegment, ShopifySegmentType } from '../../types/shopify';
import { shopifySegmentSources } from '../../utils/allActiveOrderSegmentMetrics';

export const RECEIVE_SHOPIFY_SEGMENTS = 'RECEIVE_SHOPIFY_SEGMENTS';
export const FETCHING_SHOPIFY_SEGMENTS = 'FETCHING_SHOPIFY_SEGMENTS';
export const receiveShopifySegments = (shopifySegments) => ({
  type: RECEIVE_SHOPIFY_SEGMENTS,
  shopifySegments,
});

export const getShopifySegments =
  (salesBySegmentIncludeListToSend?: any[]) => async (dispatch, getState) => {
    const { currentShopId, shop } = getState();
    dispatch({ type: FETCHING_SHOPIFY_SEGMENTS });
    let body = { shopId: currentShopId } as any;
    body.includeList = shopifySegmentSources
      .map((x) => ({ id: x, type: ShopifySegmentType.SOURCES }))
      .concat(salesBySegmentIncludeListToSend ?? shop?.salesBySegmentIncludeList ?? []);
    const segments = await axiosInstance.post('/v2/shopify/mongo/get-segments', body);
    dispatch(receiveShopifySegments(segments.data));
  };

const segments: Reducer<ShopifySegment> = (
  state = {
    [ShopifySegmentType.PAYMENT_GATEWAY_COSTS]: [],
    [ShopifySegmentType.CUSTOMER_TAGS]: [],
    [ShopifySegmentType.SOURCES]: [],
    [ShopifySegmentType.ORDER_TAGS]: [],
    [ShopifySegmentType.TW]: [],
    [ShopifySegmentType.SHIPPING_PROFILES]: [],
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return action.shopifySegments;
    default:
      return state;
  }
};

const loadingSegments: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case FETCHING_SHOPIFY_SEGMENTS:
      return true;
    case RECEIVE_SHOPIFY_SEGMENTS:
      return false;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  loadingSegments,
  segments,
});
