import { logUgradeEvent } from '$stores/willy/$upgradePopupManager';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import {
  $mobyUpgradeSplitTest,
  $plans,
  $shopSubscription,
  MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW,
} from '$stores/willy/$subscription';
import { upgradeCtaActions } from 'utils/dataLayer';
import { $userId } from '$stores/$user';
import { $currentShopId } from '$stores/$shop';
import { useStoreValue } from '@tw/snipestate';
import { userDb } from '../../../utils/DB';

export const useStartFreeTrial = (source) => {
  const { data: plans } = useStoreValue($plans);
  const [loading, setLoading] = useState(true);
  const { dayTrial } = useStoreValue($mobyUpgradeSplitTest);
  useEffect(() => {
    if (!plans.length) setLoading(false);
  }, [plans.length]);

  const startFreeTrial = useCallback(async () => {
    logUgradeEvent('chat', upgradeCtaActions.START_FREE_TRIAL);

    try {
      setLoading(true);
      const res = await axiosInstance.post(
        `/v2/subscription-manager/subscriptions/add-plan-preview`,
        {
          shopId: $currentShopId.get(),
          metadata: { userId: $userId.get() },
          asUser: true,
          start: moment().startOf('day').format('YYYY-MM-DD'),
          end: moment().add(dayTrial, 'days').endOf('day').format('YYYY-MM-DD'),
          prices: [MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW],
        },
      );
      await userDb().set(
        { shops: { [$currentShopId.get()!]: { trialBannerClosed: false } } },
        { merge: true },
      );
      $shopSubscription.set(res.data);
      toast.success('Moby free trial started successfully');
    } catch (error) {
      toast.error('Failed to start Moby free trial');
    } finally {
      setLoading(false);
    }
  }, [dayTrial]);
  return { startFreeTrial, loading };
};
