import { exportCsvToGoogleSheets } from 'ducks/googleSheets';
import { useAppDispatch } from 'index';
import { formatAndExportCsv } from '../utils/formatAndExportCsv';

interface IexportToCsvOrGoogleSheets {
  data: any[];
  isExportCsv: boolean;
  columns: any[];
  fileName: string;
  sheetsAccount?: string;
}
export const useExport = () => {
  const dispatch = useAppDispatch();
  const exportToCsvOrGoogleSheets = (params: IexportToCsvOrGoogleSheets) => {
    const googleCSVdata = formatAndExportCsv(params);
    const { isExportCsv, fileName } = params;
    if (!isExportCsv) {
      const report = {
        values: googleCSVdata,
        title: fileName,
        sheetsAccount: params.sheetsAccount,
      };
      dispatch(exportCsvToGoogleSheets(report));
    }
  };
  return exportToCsvOrGoogleSheets;
};
