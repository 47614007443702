import { useEffect, useRef } from 'react';

/**
 * @description Runs a given callback until the `done` method provided in the callback is called
 */
export function useEffectUntil(callback: (done: () => void) => any) {
  const done = useRef(false);

  useEffect(() => {
    if (done.current) return;
    callback(() => (done.current = true));
  }, [callback]);
}
