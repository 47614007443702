import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { $store } from '@tw/snipestate';

/** Store containing loction state of react router */
export const $navigate = $store<ReturnType<typeof useNavigate> | undefined>();

/** Hook used to sync react router location with $location store */
export function useConnectNavigate$() {
  const navigate = useNavigate();

  useEffect(() => {
    $navigate.set(() => navigate);
  }, [navigate]);
}
