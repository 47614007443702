import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';

export function useMutableQueryParams() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const queryObj = useMemo<Partial<Record<string, string>>>(
    () => Object.fromEntries(params),
    [params],
  );

  const set = useCallback(
    (key: string, val: unknown) => {
      if (!key) return;
      params.set(key, String(val));
      navigate(
        {
          pathname: window.location.pathname,
          search: params.toString(),
          hash: window.location.hash,
        },
        { replace: true },
      );
    },
    [navigate, params],
  );

  const replace = useCallback(
    (obj: Record<string, unknown>) => {
      for (const key in obj) {
        if (key) params.set(key, String(obj[key]));
      }
      navigate(
        {
          pathname: window.location.pathname,
          search: params.toString(),
          hash: window.location.hash,
        },
        { replace: true },
      );
    },
    [navigate, params],
  );

  const remove = useCallback(
    (...keys: string[]) => {
      if (!keys) return;
      keys.forEach((key) => params.delete(key));
      navigate(
        {
          pathname: window.location.pathname,
          search: params.toString(),
          hash: window.location.hash,
        },
        { replace: true },
      );
    },
    [navigate, params],
  );

  return [queryObj, { set, replace, remove, search }] as const;
}
