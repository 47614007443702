import { TextContainer } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

const DisconnectFromKnoExplanation = () => {
  const clientId = useSelector((state: RootState) => state.knoClientId);
  const clientSecret = useSelector((state: RootState) => state.knoClientSecret);

  return clientId && clientSecret ? (
    <div className={'mb-5'}>
      <TextContainer>
        <p>Note: You can reconnect back again later</p>
        <p>
          Client ID:
          <br /> {clientId}
        </p>
        <p>
          Client Secret:
          <br /> {clientSecret}
        </p>
      </TextContainer>
    </div>
  ) : null;
};

export default DisconnectFromKnoExplanation;
