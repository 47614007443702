import { Drawer } from '@tw/ui-components';
import { $navOpen } from '../../stores';
import { useCallback } from 'react';
import { useWritableStore } from '@tw/snipestate';

export const MobileNavWrapper = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useWritableStore($navOpen);

  const cb = useCallback(() => setIsNavOpen(false), [setIsNavOpen]);

  return (
    <Drawer
      withCloseButton={false}
      size={300}
      bg="named.2"
      opened={isNavOpen}
      onClose={cb}
      keepMounted
    >
      {children}
    </Drawer>
  );
};
