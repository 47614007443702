import { AOV_SEGMENTS, TOTAL_SPEND_SEGMENTS } from 'constants/benchmarks';
import INDUSTRIES from 'constants/industries';
import {
  changeAovSegment,
  changeIndustry,
  changeSpendSegment,
  getBenchmarksStats,
} from 'ducks/businessIntelligence';
import { useAppDispatch } from 'index';
import { useEffect } from 'react';

import {
  aovSegmentTypes,
  revenueSegmentTypes,
} from '@tw/types/module/services/business-intelligence';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';

import DropDown from './ltv/DropDown';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import amplitude from 'amplitude-js';
import { useDarkMode } from 'dark-mode-control';

export const BenchmarksFilterBar = () => {
  const dispatch = useAppDispatch();
  const { industry, aovSegment, spendSegment } = useSelector(
    (state: RootState) => state.benchmarks,
  );

  const mainDatePicker = useSelector((state: RootState) => state.mainDatePickerSelectionRange);
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);

  const darkMode = useDarkMode();

  useEffect(() => {
    if (!mainDatePicker) {
      return;
    }
    dispatch(getBenchmarksStats({ industry, aovSegment, spendSegment }));
  }, [industry, aovSegment, spendSegment, dispatch, mainDatePicker, groupStatsBy]);

  return (
    <div className="flex items-center gap-4 flex-wrap">
      <DropDown
        showFilter
        theme={darkMode ? 'dark' : 'light'}
        options={Object.values(INDUSTRIES)
          .sort((a, b) => (a.label > b.label ? 1 : -1))
          .map((i) => ({ value: i.id, label: i.label }))}
        title="Industry: "
        value={industry}
        handleSelect={(industry: IndustryTypes) => {
          dispatch(changeIndustry(industry));
          amplitude.getInstance().logEvent('Benchmarks: industry Changed', {
            industry: industry,
          });
        }}
      />
      <DropDown
        theme={darkMode ? 'dark' : 'light'}
        options={[{ label: 'All', value: null }, ...Object.values(AOV_SEGMENTS)]}
        title="AOV: "
        value={aovSegment}
        handleSelect={(segment: aovSegmentTypes) => {
          dispatch(changeAovSegment(segment));
          amplitude.getInstance().logEvent('Benchmarks: aov segment Changed', {
            aovSegment: segment,
          });
        }}
      />
      <DropDown
        theme={darkMode ? 'dark' : 'light'}
        options={[{ label: 'All', value: null }, ...Object.values(TOTAL_SPEND_SEGMENTS)]}
        title="SPEND: "
        value={spendSegment}
        handleSelect={(segment: revenueSegmentTypes) => {
          dispatch(changeSpendSegment(segment));
          amplitude.getInstance().logEvent('Benchmarks: spend segment Changed', {
            spendSegment: segment,
          });
        }}
      />
    </div>
  );
};
