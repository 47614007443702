import React, { useContext, useEffect, useState } from 'react';
import './steps.scss';
import { Link } from '@shopify/polaris';
import { WizardContext } from '../WizardContext';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/RootType';
import {
  fetchSurveyData,
  postPurchaseSurveyGetConfig,
  postPurchaseSurveyRefresh,
} from 'ducks/postPurchaseSurvey';
import { SurveyQuestionsResponse } from '@tw/types';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'index';
import { StepCompletionInfo } from 'types/WizardData';
import { UseSetupProgressArgs } from 'routes/allShopsPinnedSections/hooks/useSetupProgress';
import { Button } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../../../$stores/willy/$subscription';
import { InstallData } from 'pages/PostPurchaseSurvey/PostPurchaseSurvey';

export const WelcomeInstallPPS: React.FC = (props) => {
  const { nextStep } = useContext(WizardContext);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [hasSurvey, setHasSurvey] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFreeShop = useStoreValue($isFreeShop);
  const msp = useAppSelector((state) => state.msp);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if no questionId (never been installed yet), we fetch the default config
    dispatch(postPurchaseSurveyGetConfig(0));
  }, [dispatch]);

  useEffect(() => {
    fetchSurveyData();
  }, []);

  const installPPS = async () => {
    setIsLoading(true);
    try {
      if (hasSurvey && !isActivated) {
        nextStep();
        return;
      } else {
        const surveyId = await enableSurvey(); //create survey
        if (['shopify', 'bigcommerce'].includes(msp)) {
          await activateSurvey(msp as 'shopify' | 'bigcommerce', surveyId); //activate survey
        }
        nextStep();
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong while installing the survey', err);
    } finally {
      setIsLoading(false);
    }
  };

  const enableSurvey = async () => {
    try {
      const url = `/v2/survey/enable`;
      await axiosInstance.post(url, { shop: currentShopId });
      const data = await fetchSurveyData();
      return data?.surveyId;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSurveyData = async () => {
    try {
      const url = `v2/survey/question?shop=${currentShopId}`;
      const { data } = await axiosInstance.get<
        SurveyQuestionsResponse & {
          ff: boolean;
          installData: InstallData;
        }
      >(url);
      if (data?.surveyId) {
        dispatch(postPurchaseSurveyRefresh());
        setHasSurvey(true);
        return data;
      } else {
        setHasSurvey(false);
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const activateSurvey = async (type: Required<InstallData>['type'], surveyId) => {
    try {
      const url = `/v2/survey/activate`;
      const body = {
        shopId: currentShopId,
        surveyId: surveyId,
        installType: type,
      };

      const result = await axiosInstance.post(url, body);
      if (result.status < 300) {
        setIsActivated(true);
      } else {
        throw new Error('Error while activating the post-purchase survey');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <p className="step-title" style={{ paddingTop: '2.5rem' }}>
        Install Triple Whale's Post-Purchase Survey?
      </p>
      {!isFreeShop ? (
        <p className="step-description">
          In just a one click you can automatically install a post-purchase survey to help improve
          your attribution using real customer feedback. Once enabled, survey responses will be
          available right in your Triple Whale account and you will unlock Triple Whale's&nbsp;
          <Link
            onClick={() => {
              window.open(
                'https://kb.triplewhale.com/en/articles/7128379-the-total-impact-attribution-model',
              );
            }}
          >
            Total Impact
          </Link>
          &nbsp; model.
        </p>
      ) : (
        <p className="step-description">
          In just a one click you can automatically installa post-purchase survey to help real
          customer feedback about which ads are leading them to purchase. Once enabled, survey
          responses will be available right inside Triple Whale.
        </p>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '1.5rem',
        }}
      >
        <img
          src={'/wizards/pps_example.png'}
          alt="pps-image"
          style={{
            maxWidth: '40%',
            maxHeight: '40%',
            border: '1px solid #dfe3e8',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        ></img>
      </div>
      {hasSurvey && !isActivated && (
        <p style={{ paddingTop: '2rem' }}>This shop already has a post-purchase survey enabled.</p>
      )}
      <Button
        my="xl"
        loading={isLoading}
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          installPPS();
        }}
      >
        {!hasSurvey && !isActivated ? `Install Post-Purchase Survey & Continue` : `Next`}
      </Button>
      <p className="step-description">
        Installing the survey will automatically install the survey above on your store’s
        confirmation page and immediately begin collecting responses. You can modify, customize, and
        uninstall the survey later from the Triple Whale settings.
      </p>

      <p style={{ paddingTop: '1rem' }}>Using a 3rd-party checkout?</p>
      <Link
        onClick={() => {
          window.open(
            'https://kb.triplewhale.com/en/articles/6486354-triple-whale-post-purchase-survey#h_7ceb1f5fad',
          );
        }}
      >
        View the manual installation instructions
      </Link>
    </div>
  );
};

export function usePPSConnectionInfo(args: UseSetupProgressArgs): StepCompletionInfo {
  const { isFromPod, shopDomain } = args;
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const dispatch = useAppDispatch();
  const shop = isFromPod && shopDomain ? shopDomain : currentShopId;

  useEffect(() => {
    dispatch(fetchSurveyData(shop));
  }, [dispatch, shop]);
  const { surveyData } = useSelector((state: RootState) => state.postPurchaseSurvey);

  const status = !!surveyData?.surveyId ? 'complete' : 'not-started';
  const text = status === 'complete' ? 'Installed' : 'Not Installed';

  return { status, text };
}

export const PPSInstallationIndication: React.FC = (args) => {
  const { status, text } = usePPSConnectionInfo(args);
  return (
    <div className={status === 'complete' ? 'text-green' : 'text-red-400'}>
      <span>{text}</span>
    </div>
  );
};
