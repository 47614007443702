import { useState } from 'react';
import { toggleLauncherVisibility } from 'utils/intercom';

import { Flex, FormattedColor, Icon, Tooltip } from '@tw/ui-components';

export function GetHelp({
  iconSize = 20,
  iconColor,
}: {
  iconSize?: number;
  iconColor?: FormattedColor;
}) {
  const [forcedIntercom, setForcedIntercom] = useState(false);

  return (
    <div id="intercom-help-icon">
      <Flex align="center" justify="center" w="100%" h="100%">
        <Tooltip
          label={forcedIntercom ? 'Hide help' : 'Get help'}
          onClick={() => {
            if (!forcedIntercom) {
              setForcedIntercom(true);
              toggleLauncherVisibility(true);
            } else {
              setForcedIntercom(false);
              toggleLauncherVisibility(false);
            }
          }}
        >
          <div className="flex cursor-pointer">
            <Icon size={iconSize} name="question-mark" color={iconColor} />
          </div>
        </Tooltip>
      </Flex>
    </div>
  );
}
