import { Text } from '@tw/ui-components';

export interface SummarySectionSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  icon: () => JSX.Element;
  label: string;
  value: string;
}

export function SummarySectionSelectItem({ icon: Icon, label }: SummarySectionSelectItemProps) {
  return (
    <div className="flex items-center justify-start gap-4">
      <Icon />
      <Text size="sm" fw={500}>
        {label}
      </Text>
    </div>
  );
}
