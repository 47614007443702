import React, { Fragment } from 'react';
import { KeyboardEventHandler, MouseEventHandler, useMemo, useState } from 'react';
import { $store, useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { getMainElementDbRef, visibleLength } from 'components/Willy/utils/willyUtils';
import { isMobileApp } from 'utils/Device';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { ReactComponent as ArrowBend } from 'icons/arrow-bend.svg';
import {
  ActionIcon,
  Badge,
  Icon,
  IconName,
  Menu,
  Text,
  Tooltip,
  useDisclosure,
} from '@tw/ui-components';
import { useGetDashboardDropdownOptions } from './useGetDashboardDropdownOptions';
import { $filteredDashboardsBySearch } from './stores';
import { emojiIsLetter } from '../WillyDashDescription';
import { useAppSelector } from 'reducers/RootType';
import { $sequencesUnreadReportsPerDashboard } from '$stores/willy/$sequences';

const checkOverflow = (el: Element) =>
  el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

const $editedDashboard = $store<string | undefined>(undefined);

export const WillyDashOptionsList = React.memo(
  ({ dashboard, isFavorites }: { dashboard: WillyDashboardElement; isFavorites: boolean }) => {
    const truncSize = useAppSelector((state) => state.willy.willySideMenuWidth - 100);
    const [menuOpen, menuOpenActions] = useDisclosure(false);
    const [menuVisible, menuVisibleActions] = useDisclosure(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [editedDashboard, setEditedDashboard] = useWritableStore($editedDashboard);
    const sequencesUnreadReportsPerDashboard = useStoreValue($sequencesUnreadReportsPerDashboard);
    const ref = useMemo(() => getMainElementDbRef(dashboard), [dashboard]);
    const filteredDashboards = useComputedValue($filteredDashboardsBySearch, (d) =>
      d.filter((d) => !d.isFavorite),
    );
    const displayedDashboardName =
      dashboard.name + (dashboard.isHide ? ' - (deleted, only admins)' : '') || 'Unnamed Dashboard';

    const dropdownOptions = useGetDashboardDropdownOptions(dashboard, filteredDashboards);

    const handleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
      if (dashboard.id === editedDashboard) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    const handleDoubleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (dashboard.id === editedDashboard) return;
      if (windowSize.isSmall || isMobileApp) return;
      setEditedDashboard(dashboard.id);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLSpanElement> = (e) => {
      if (dashboard.id === editedDashboard) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    const handleKeyUp: KeyboardEventHandler<HTMLSpanElement> = (e) => {
      if (dashboard.id === editedDashboard) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    const handleMouseEnter: MouseEventHandler<HTMLSpanElement> = (e) => {
      const el = e.currentTarget;
      if (el) setIsOverflowing(checkOverflow(el));
    };

    return (
      <div className="block w-full group/dashboard-label">
        <span
          className="flex items-center gap-2"
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onMouseEnter={menuVisibleActions.open}
          onMouseLeave={menuVisibleActions.close}
        >
          <span className="whitespace-nowrap overflow-hidden text-ellipsis inline-flex items-center gap-2">
            {dashboard.isCustomView && (
              <span className="flex-shrink-0 flex items-center mr-2">
                <ArrowBend fill="var(--gray-light)" width={16} />
              </span>
            )}

            {dashboard.emoji && (
              <div className="flex items-center justify-center mr-2">
                {emojiIsLetter(dashboard.emoji) ? (
                  <div
                    className={`flex items-center justify-center cursor-pointer bg-gray-100 w-[16px] h-[16px] text-gray-400 rounded-sm text-[10px] font-semibold`}
                  >
                    {visibleLength(dashboard.emoji) > 1 ? (
                      <Icon name={dashboard.emoji as IconName} size={14} />
                    ) : (
                      dashboard.emoji
                    )}
                  </div>
                ) : visibleLength(dashboard.emoji) > 1 ? (
                  <Icon name={dashboard.emoji as IconName} />
                ) : (
                  dashboard.emoji
                )}
              </div>
            )}

            <span
              className="truncate"
              contentEditable={editedDashboard === dashboard.id}
              suppressContentEditableWarning={true}
              ref={(el) => {
                if (!el) return;
                if (editedDashboard === dashboard.id) {
                  const range = document.createRange();
                  range.selectNodeContents(el);
                  const selection = window.getSelection();
                  selection?.removeAllRanges();
                  selection?.addRange(range);

                  el.focus();
                } else {
                  el.blur();
                }
              }}
              onMouseEnter={handleMouseEnter}
              onBlur={(e) => {
                ref.set({ name: e.target.textContent }, { merge: true });
                setEditedDashboard(undefined);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.blur();
                }
              }}
            >
              {!isOverflowing ? (
                <Text fz="sm" c="gray.8" truncate maw={truncSize} fw={500}>
                  {displayedDashboardName}
                </Text>
              ) : (
                <Tooltip label={displayedDashboardName}>
                  <Text fz="sm" c="gray.8" fw={500} maw={truncSize}>
                    {displayedDashboardName}
                  </Text>
                </Tooltip>
              )}
            </span>
          </span>
          {dashboard.isFavorite && !isFavorites && (
            <Icon name="rating-star" size={18} color="yellow.4" />
          )}
          <span className="ml-auto">
            {!!sequencesUnreadReportsPerDashboard[dashboard.id] && (
              <div className="text-white text-[10px] w-[18px] h-[18px] flex items-center justify-center rounded-full bg-[var(--mantine-color-one-4)]">
                {sequencesUnreadReportsPerDashboard[dashboard.id] || 0}
              </div>
            )}
          </span>
          <span
            className={`inline-flex items-center ${
              menuVisible
                ? 'sm:group-hover/dashboard-label:opacity-100'
                : 'opacity-0 pointer-events-none'
            } transition-opacity`}
          >
            <Menu opened={menuOpen} onClose={menuOpenActions.close} shadow="md" display="flex">
              <Menu.Target>
                <span className="inline-flex items-center">
                  <ActionIcon
                    icon="menu-vertical"
                    variant="transparent"
                    color="gray.5"
                    iconSize={25}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      menuOpenActions.toggle();
                    }}
                  />
                </span>
              </Menu.Target>
              <Menu.Dropdown style={{ opacity: menuOpen ? 1 : 0 }}>
                {dropdownOptions.map((section, i) => (
                  <div key={i}>
                    {section.items.map(({ onDisabledWrapper: Wrapper, ...item }, j) => {
                      if (!Wrapper) {
                        return (
                          <Fragment key={j}>
                            <Menu.Item {...item}>{item?.content}</Menu.Item>
                          </Fragment>
                        );
                      }

                      return (
                        <Wrapper
                          key={j}
                          target={(accessLimit) => {
                            return (
                              <Menu.Item
                                {...item}
                                disabled={!!accessLimit && accessLimit !== 'can-access'}
                              >
                                {item?.content}
                              </Menu.Item>
                            );
                          }}
                        />
                      );
                    })}
                    {i < dropdownOptions.length - 1 && <Menu.Divider />}
                  </div>
                ))}
              </Menu.Dropdown>
            </Menu>
          </span>
        </span>
      </div>
    );
  },
);
