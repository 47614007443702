import { Header, Box, useWindowSize, Flex } from '@tw/ui-components';
import {
  BASE_HEADER_HEIGHT,
  GRID_BREAKPOINTS,
  TOP_BAR_BG_VAR,
  TOP_BAR_BORDER_VAR,
  TOP_BAR_HEIGHT_VAR,
} from './constants';
import { $topBarHeight, useActiveRoute } from '$stores/nav-config-stores';
import TopBreadcrumbs from 'components/TopBreadcrumbs';
import MonitoringIncidents from 'components/MonitoringIncidents';
import { MobileTopSection } from './MobileTopSection';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { windowSize } from 'utils/classes/WindowSizeObserver';

export const PodsViewTopBar: React.FC = () => {
  const { activeRoute, isLocked } = useActiveRoute();
  const InnerHeader = !isLocked ? activeRoute?.header ?? null : null;
  const size = useWindowSize('width', GRID_BREAKPOINTS);

  const setInnerHeaderRef = useResizeObserver((entries) => {
    if (!entries[0]) return;

    const { blockSize: height } = entries[0].contentBoxSize[0];
    const padding = height > 0 ? 24 : 0;
    const numberOfRows = windowSize.isSmall ? 2 : 1;
    const innerHeight = height + padding;

    $topBarHeight.set(BASE_HEADER_HEIGHT * numberOfRows + innerHeight);
  });

  return (
    <Header h={TOP_BAR_HEIGHT_VAR} bg={TOP_BAR_BG_VAR} borderBottom={TOP_BAR_BORDER_VAR}>
      {size === 'mobile' && (
        <Box p="xs" borderBottom={TOP_BAR_BORDER_VAR}>
          <MobileTopSection />
        </Box>
      )}
      <Flex justify="space-between" direction="column">
        <Flex
          h={BASE_HEADER_HEIGHT}
          mah={BASE_HEADER_HEIGHT}
          justify="space-between"
          p="sm"
          gap="sm"
          align="center"
          borderBottom={TOP_BAR_BORDER_VAR}
        >
          <TopBreadcrumbs />
          <div
            style={{
              padding: '2px',
              overflowY: 'hidden',
              overflowX: 'auto',
            }}
          >
            {size === 'desktop' && InnerHeader && <InnerHeader />}
          </div>
        </Flex>
        <Flex
          ref={setInnerHeaderRef}
          {...(size !== 'desktop' && { p: 'sm' })}
          justify={size === 'desktop' ? 'flex-end' : 'flex-start'}
        >
          {size !== 'desktop' && InnerHeader && <InnerHeader />}
        </Flex>
      </Flex>
      <MonitoringIncidents />
    </Header>
  );
};
