import { MetricsKeys } from 'types/metrics';
import {
  ChartTypeDict,
  MessageTypesDictionary,
  Category,
  WillyToolMap,
  ForecastAlgorithms,
  WillyOperatorDict,
  stepActionType,
  shelfCategoryType,
  WillyDashboardElement,
} from './types/willyTypes';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

import { ReactComponent as Chart } from 'components/Willy/icons/chart.svg';
import { ReactComponent as Funnel } from 'components/Willy/icons/funnel.svg';
import { ReactComponent as Pie } from 'components/Willy/icons/pie.svg';
import { ReactComponent as Map } from 'components/Willy/icons/map.svg';
import { ReactComponent as Table } from 'components/Willy/icons/table.svg';
import { ReactComponent as Text } from 'components/Willy/icons/text.svg';
import { ReactComponent as Tile } from 'components/Icons/tile.svg';
import { ReactComponent as Card } from 'components/Icons/card.svg';
import { ReactComponent as DecorativeTile } from 'components/Willy/icons/tileView.svg';
import { ReactComponent as DecorativeCard } from 'components/Willy/icons/cardView.svg';
import { ReactComponent as DecorativeTable } from 'components/Willy/icons/tableView.svg';
import { ReactComponent as DecorativeLineChart } from 'components/Willy/icons/lineChart.svg';
import { ReactComponent as DecorativeBarChart } from 'components/Willy/icons/barChart.svg';
import { ReactComponent as DecorativeStackedBarChart } from 'components/Willy/icons/stackedBarChart.svg';
import { ReactComponent as DecorativeHorizontalBarChart } from 'components/Willy/icons/horizontalBarChart.svg';
import { ReactComponent as DecorativeAreaChart } from 'components/Willy/icons/areaChart.svg';
import { ReactComponent as ScatterChart } from 'components/Willy/icons/scatter.svg';
import { ReactComponent as DecorativePieView } from 'components/Willy/icons/pieView.svg';
import { ReactComponent as DecorativeText } from 'components/Willy/icons/textView.svg';
import { ReactComponent as ScatterChartIcon } from 'components/Willy/icons/scatter-icon.svg';

import { AxisDomainItem } from 'recharts/types/util/types';
import { colors, Icon, IconName, NumRange } from '@tw/ui-components';
import { BqTable } from 'pages/FreeQuery/dataStuff/tables';
import { Timestamp } from 'utils/DB';
import { Dialect } from '@tw/types';

const iconClass =
  'w-8 h-8 flex items-center fill-gray-500 dark:fill-gray-300 text-gray-500 dark:text-gray-300';

const defaultHeight = 27;
const defaultSmallHeight = 10;
const defaultMinHeight = 17;

const smallMinWidth = 2;
const minWidth = 4;

export const MAX_WIDGETS_PER_DASHBOARD = 50;

export const iconColorMap = {
  'facebook-circle': '#3b5998',
  'google-ads': '#4285f4',
  outbrain: '#f2f2f2',
  microsoft: '#f65314',
  mountain: '#007cb6',
  criteo: '#d26d00',
  shopify: '#96bf48',
};

export const messageTypes: MessageTypesDictionary = {
  text: {
    id: 'text',
    title: 'Text',
    icon: 'text',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultSmallHeight,
    widgetDefaultHeight: defaultSmallHeight,
    decorativeIcon: <DecorativeText />,
  },
  tile: {
    id: 'tile',
    title: 'Tile',
    icon: 'tiles-view',
    widgetMinWidth: 6,
    widgetMinHeight: 14,
    widgetDefaultHeight: defaultSmallHeight,
    decorativeIcon: <DecorativeTile />,
  },
  card: {
    id: 'card',
    title: 'Card',
    icon: 'card-view',
    widgetMinWidth: 6,
    widgetMinHeight: 14,
    widgetDefaultHeight: defaultSmallHeight,
    decorativeIcon: <DecorativeCard />,
  },
  summaryBox: {
    id: 'summaryBox',
    title: 'Tile',
    icon: 'tiles-view',
    widgetMinWidth: 6,
    widgetMinHeight: 14,
    widgetDefaultHeight: defaultSmallHeight,
  },
  table: {
    id: 'table',
    title: 'Table',
    icon: 'table-view',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultMinHeight,
    widgetDefaultHeight: defaultHeight,
    decorativeIcon: <DecorativeTable />,
  },
  chart: {
    id: 'chart',
    title: 'Chart',
    icon: 'combo-chart',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultMinHeight,
    widgetDefaultHeight: defaultHeight,
  },
  pie: {
    id: 'pie',
    title: 'Pie',
    icon: 'pie',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultMinHeight,
    widgetDefaultHeight: defaultHeight,
    decorativeIcon: <DecorativePieView />,
  },
  funnel: {
    id: 'funnel',
    title: 'Funnel',
    icon: 'combo-chart',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultMinHeight,
    widgetDefaultHeight: defaultHeight,
  },
  map: {
    id: 'map',
    title: 'Map',
    icon: 'location',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultMinHeight,
    widgetDefaultHeight: defaultHeight,
    decorativeIcon: <Map />,
  },
  json: {
    id: 'json',
    title: 'JSON',
    icon: 'code-bracket',
    widgetMinWidth: minWidth,
    widgetMinHeight: defaultMinHeight,
    widgetDefaultHeight: defaultMinHeight,
  },
};

export const chartTypes: ChartTypeDict = {
  bar: {
    title: 'Bar',
    id: 'bar',
    icon: 'bar-chart',
    decorativeIcon: <DecorativeBarChart />,
  },
  'stacked-bar': {
    title: 'Stacked Bar',
    id: 'stacked-bar',
    icon: 'full-stacked-bar-chart',
    decorativeIcon: <DecorativeStackedBarChart />,
  },
  'horizontal-bar': {
    title: 'Horizontal Bar',
    id: 'horizontal-bar',
    icon: 'horizontal-bar-chart',
    decorativeIcon: <DecorativeHorizontalBarChart />,
  },
  line: {
    title: 'Line',
    id: 'line',
    icon: 'line-chart',
    decorativeIcon: <DecorativeLineChart />,
  },
  area: {
    title: 'Area',
    id: 'area',
    icon: 'area-chart',
    decorativeIcon: <DecorativeAreaChart />,
  },
  scatter: {
    title: 'Scatter',
    id: 'scatter',
    icon: 'scatter-chart',
    decorativeIcon: <ScatterChart />,
  },
};

export const metricsForFunnelChart: MetricsKeys[][] = [
  ['impressions', 'clicks'],
  ['impressions', 'clicks', 'purchases'],
  ['impressions', 'clicks', 'pixelPurchases'],
  ['pixelVisitors', 'pixelUniqueVisitors'],
  ['pixelVisitors', 'pixelUniqueVisitors', 'pixelUniqueAtc'],
  ['pixelVisitors', 'pixelUniqueVisitors', 'pixelUniqueAtc', 'pixelPurchases'],
];

export const NONE_X_KEY = '[NONE]';

export const MAX_ITEMS_PER_PAGE = 50;

export const MOBILE_MAX_ITEMS_PER_PAGE = 10;

export const MENU_SIZE = 66;

export const SM_MENU_SIZE = 115;

export const categories: Category[] = [
  {
    id: 'meta',
    title: 'Meta',
  },
  {
    id: 'google',
    title: 'Google',
  },
  {
    id: 'shopify',
    title: 'Shopify',
  },
  {
    id: 'cogs',
    title: 'COGS',
  },
  {
    id: 'handling_fees',
    title: 'Handling Fees',
  },
  {
    id: 'payment_gateways',
    title: 'Payment Gateways',
  },
  {
    id: 'shipping',
    title: 'Shipping',
  },
  {
    id: 'custom_expenses',
    title: 'Custom Expenses',
  },
  {
    id: 'cdp',
    title: 'CDP',
  },
  {
    id: 'blended',
    title: 'Blended',
  },
  {
    id: 'customer_journeys',
    title: 'Customer Journeys',
  },
  {
    id: 'products',
    title: 'Products',
  },
  {
    id: 'cohorts',
    title: 'Cohorts',
  },
  {
    id: 'ltv',
    title: 'LTV',
  },
  {
    id: 'sales_cycle',
    title: 'Sales Cycle',
  },
  {
    id: 'product_journey',
    title: 'Product Journey',
  },
  {
    id: 'amazon',
    title: 'Amazon',
  },
  {
    id: 'web_analytics',
    title: 'Web Analytics',
  },
  {
    id: 'benchmarks',
    title: 'Benchmarks',
  },
  {
    id: 'tiktok',
    title: 'TikTok',
  },
  {
    id: 'post_purchase_survey',
    title: 'Post Purchase Survey',
  },
  {
    id: 'blended_metrics',
    title: 'Blended Metrics',
  },
  {
    id: 'forecasting',
    title: 'Forecasting',
  },
  {
    id: 'product_analytics',
    title: 'Product Analytics',
  },
  {
    id: 'cart_analysis',
    title: 'Cart Analysis',
  },
  {
    id: 'handing_fees',
    title: 'Handing Fees',
  },
  {
    id: 'custom_expenses',
    title: 'Custom Expenses',
  },
  {
    id: 'general',
    title: 'General',
  },
  {
    id: 'web',
    title: 'Web',
  },
];

export const QUESTIONS = {
  shopify: [
    'Give me my number of orders over the last 7 days from Shopify.',
    'Give me my number of new customer orders over the last 7 days from Shopify.',
    'How many items did I sell over the last 7 days from Shopify?',
    'What was the percentage of new customers vs percentage of returning customers over the last 7 days from Shopify?',
    'Give me my total revenue over the last 7 days from Shopify.',
    'Give me my new customer revenue over the last 7 days from Shopify.',
    'Give me my returning customer revenue over the last 7 days from Shopify.',
    'Give me my number of orders refunded over the last 7 days from Shopify.',
    'Give me my value of refunds over the last 7 days from Shopify.',
    'Give me my number of orders over the last 7 days from Shopify and break them out by US state.',
    'Give me my number of orders over the last 7 days from Shopify and break them out by country.',
    'Give me my number of new customer orders vs returning customer orders over the last 7 days from Shopify and break them out by country.',
    'Give me my new customer orders from Shopify and break them out by day over the last 30 days.',
    'Give me my new customer orders from Shopify and break them out by discount code for the last 30 days.',
    'Give me my total discount amount for Shopify over the last 30 days.',
    'How many new customer orders came from New York over the last 30 days?',
    'What are my top 10 products sold over the last 30 days for Shopify?',
    'What are my top 10 SKUs sold over the last 30 days for Shopify?',
    'How many orders were above $100 in the last 30 days from Shopify?',
    'What is my refund value percentage rate last 7 days from Shopify?',
    'How many new customers made purchases from us this year from Shopify?',
    'What was my monthly Average Order Value (AOV) from Shopify for each of the past 12 months?',
    'Which week had the highest Average Order Value (AOV) on Shopify over the last 12 months?',
    'Give me a list of all of my orders from yesterday in a table for Shopify.',
    'What is the most common hour of the day for placing orders over the last 30 days on Shopify?',
    'What is the average number of items per order over the last 7 days from Shopify?',
    'Give me the total revenue from Shopify over the last 7 days.',
    'What is the conversion rate from visits to orders on Shopify over the last 7 days?',
    'How does the average order value (AOV) from new customers compare to returning customers over the last 30 days on Shopify?',
    'What is the total shipping cost for orders over the last 30 days from Shopify?',
    'Give me the total sales tax collected from Shopify orders over the last 30 days.',
    'What are the most used discount codes over the last 30 days on Shopify? Show percentage too.',
    'How many orders were placed via mobile devices vs desktop devices over the last 7 days from Shopify?',
    'What is the day of the week with the highest number of orders over the last 30 days on Shopify?',
    'What is the returning customer rate over the last 90 days on Shopify?',
    'How many customers made repeat purchases within 30 days of their first purchase over the last 6 months on Shopify?',
    'What is the total revenue generated from customers who made their first purchase more than a year ago, over the last 30 days on Shopify?',
    'How many orders included a gift card as a payment method over the last 30 days on Shopify?',
    'Give me the total revenue from international orders (outside the US) over the last 30 days on Shopify.',
    'How many orders were placed by customers who clicked through from a social media ad over the last 30 days on Shopify?',
  ],
  meta: [
    'What is my spend for Meta Ads over the last 12 months by month?',
    'What is my ROAS for Meta Ads over the last 12 months by month?',
    'What is my CPA for Meta Ads over the last 7 days?',
    'What is my NC CPA for Meta Ads over the last 7 days?',
    'What is my AOV for Meta Ads over the last 7 days?',
    'What is my Conversion Rate (CVR) for Meta Ads over the last 7 days?',
    'What is my Click-Through Rate (CTR) for Meta Ads over the last 7 days?',
    'What is my CPM for Meta Ads over the last 7 days?',
    'How many orders came from Meta Ads over the last 7 days?',
    'How many new customer orders came from Meta over the last 7 days?',
    'What was my new customer revenue from Meta Ads over the last 7 days?',
    'How many sessions came from Meta Ads over the last 7 days?',
    'How many visitors came from Meta Ads over the last 7 days?',
    'How many new visitors came from Meta Ads over the last 7 days?',
    'Give me my spend, ROAS, NC ROAS, CPA, NC CPA, CTR, CVR, and CPM from Meta Ads over the last 7 days by day in a table.',
    'Give me my total ad spend and ROAS, over the last 12 weeks and break it out by week in a chart.',
    'Give me my top 10 spending Meta ads over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, copy, and images.',
    'Give me my top 10 spending Meta ad sets over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, CPA, and AOV.',
    'Give me my top 10 spending Meta campaigns over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, CPA, and AOV.',
    "Give me a table with all of my Meta campaigns that contain the name 'Prospecting' in them and give me the spend, ROAS, CPM, and CTR.",
    "Give me a chart of my Meta Ads campaigns that contain the name 'Prospecting' and give me the spend and ROAS using the 'last click' model and a 7 day attribution window.",
    'Give me Meta Ads ROAS over the last 7 days and break it out by attribution model.',
    'Give me Meta Ads ROAS over the last 7 days and break it out by attribution window.',
    'Give me my Customer Lifetime Value (LTV) from Meta Ads for January 2024.',
    'Give me a list of my discount codes used from my Meta Ads over the last 30 days.',
    'Give me a list of my order tags used from my Meta Ads over the last 30 days and the number of times that the tags were in the orders.',
    'Give me a list of products sold by Meta Ads over the last 30 days to new customers.',
    'Give me a list of SKUs and the number of times they were sold by Meta Ads over the last 30 days to new customers.',
    'What is the total refund value from orders that came from Meta Ads over the last 30 days?',
    'Can you give me my total spend and images vs video spend for Meta Ads over the last 30 days?',
    'Give me my ROAS over the last 12 weeks on Meta Ads by the day of the week.',
    'Give me my ROAS over the last 12 weeks on Meta Ads by hour of the day.',
    'How many add to carts came from Meta Ads last week?',
    'What was my new customer Conversion Rate (CVR) on Meta Ads last week?',
    'What was my percentage of new customers vs percentage of returning customers over the last 7 days that came from Meta Ads?',
    'What is the average session duration for visitors coming from Meta Ads over the last 30 days?',
    'Give me the bounce rate for Meta Ads traffic over the last 30 days.',
    'What is the pixel conversion rate for Meta Ads over the last 30 days?',
    'Give me the new customer conversion rate for Meta Ads over the last 30 days.',
    'What is the difference in total order revenue vs. channel-reported conversion value for Meta Ads over the last 30 days?',
    'What is the cost per new visitor from Meta Ads over the last 30 days?',
    'Show me the cost per visitor for Meta Ads traffic over the last 30 days.',
    'What percentage of visitors from Meta Ads were new visitors over the last 30 days?',
    'What is the pixel new visitor percent for Meta Ads traffic over the last 30 days?',
    'Show me the average page views per session for visitors coming from Meta Ads over the last 30 days.',
    "Give me the New Customer ROAS for Meta Ads campaigns containing 'Prospecting' in the campaign name over the last 30 days.",
    "Give me the ROAS for Meta Ads campaigns containing 'Retargeting' in the campaign name over the last 30 days.",
  ],
  google: [
    'What is my spend for Google Ads over the last 12 months by month?',
    'What is my ROAS for Google Ads over the last 12 months by month?',
    'What is my CPA for Google Ads over the last 7 days?',
    'What is my NC CPA for Google Ads over the last 7 days?',
    'What is my AOV for Google Ads over the last 7 days?',
    'What is my Conversion Rate (CVR) for Google Ads over the last 7 days?',
    'What is my Click-Through Rate (CTR) for Google Ads over the last 7 days?',
    'What is my CPM for Google Ads over the last 7 days?',
    'How many orders came from Google Ads over the last 7 days?',
    'How many new customer orders came from Google over the last 7 days?',
    'What was my new customer revenue from Google Ads over the last 7 days?',
    'How many sessions came from Google Ads over the last 7 days?',
    'How many visitors came from Google Ads over the last 7 days?',
    'How many new visitors came from Google Ads over the last 7 days?',
    'Give me my spend, ROAS, NC ROAS, CPA, NC CPA, CTR, CVR, and CPM from Google Ads over the last 7 days by day in a table.',
    'Give me my total ad spend and ROAS, over the last 12 weeks and break it out by week in a chart.',
    'Give me my top 10 spending Google ads over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, copy, and images.',
    'Give me my top 10 spending Google ad sets over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, CPA, and AOV.',
    'Give me my top 10 spending Google campaigns over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, CPA, and AOV.',
    "Give me a table with all of my Google campaigns that contain the name 'Brand' in them and give me the spend, ROAS, CPM, and CTR.",
    "Give me a chart of my Google Ads campaigns that contain the name 'Brand' and give me the spend and ROAS using the 'last click' model and a 7 day attribution window.",
    'Give me Google Ads ROAS over the last 7 days and break it out by attribution model.',
    'Give me Google Ads ROAS over the last 7 days and break it out by attribution window.',
    'Give me my Customer Lifetime Value (LTV) from Google Ads for January 2024.',
    'Give me a list of my discount codes used from my Google Ads over the last 30 days.',
    'Give me a list of my order tags used from my Google Ads over the last 30 days and the number of times that the tags were in the orders.',
    'Give me a list of products sold by Google Ads over the last 30 days to new customers.',
    'Give me a list of SKUs and the number of times they were sold by Google Ads over the last 30 days to new customers.',
    'What is the total refund value from orders that came from Google Ads over the last 30 days?',
    'Can you give me my total spend and images vs video spend for Google Ads over the last 30 days?',
    'Give me my ROAS over the last 12 weeks on Google Ads by the day of the week.',
    'Give me my ROAS over the last 12 weeks on Google Ads by hour of the day.',
    'What was my % of new customers vs % of returning customers over the last 7 days that came from Google Ads?',
    'How many add to carts came from Google Ads last week?',
    'What was my new customer Conversion Rate (CVR) on Google Ads last week?',
    'What was my percentage of new customers vs percentage of returning customers over the last 7 days that came from Google Ads?',
    'What is the average session duration for visitors coming from Google Ads over the last 30 days?',
    'Give me the bounce rate for Google Ads traffic over the last 30 days.',
    'What is the pixel conversion rate for Google Ads over the last 30 days?',
    'Give me the new customer conversion rate for Google Ads over the last 30 days.',
    'What is the difference in total order revenue vs. channel-reported conversion value for Google Ads over the last 30 days?',
    'What is the cost per new visitor from Google Ads over the last 30 days?',
    'Show me the cost per visitor for Google Ads traffic over the last 30 days.',
    'What percentage of visitors from Google Ads were new visitors over the last 30 days?',
    'What is the pixel new visitor percent for Google Ads traffic over the last 30 days?',
    'Show me the average page views per session for visitors coming from Google Ads over the last 30 days.',
    "Give me the New and Returning Customer Revenue for Google Ads campaigns that contain 'PMAX' in the campaign name over the last 30 days.",
    "Give me the Spend, ROAS, and CPA for Google Ads campaigns that contain 'Brand' in the campaign name over the last 30 days.",
    'What is the total number of clicks from Google Ads over the last 30 days?',
    'Show me the cost per click (CPC) for Google Ads over the last 30 days.',
    'Show me the conversion value delta for Google Ads over the last 30 days, comparing total order revenue and channel-reported conversion value.',
  ],
  tiktok: [
    'What is my spend for TikTok Ads over the last 12 months by month?',
    'What is my ROAS for TikTok Ads over the last 12 months by month?',
    'What is my CPA for TikTok Ads over the last 7 days?',
    'What is my NC CPA for TikTok Ads over the last 7 days?',
    'What is my AOV for TikTok Ads over the last 7 days?',
    'What is my Conversion Rate (CVR) for TikTok Ads over the last 7 days?',
    'What is my Click-Through Rate (CTR) for TikTok Ads over the last 7 days?',
    'What is my CPM for TikTok Ads over the last 7 days?',
    'How many orders came from TikTok Ads over the last 7 days?',
    'How many new customer orders came from TikTok over the last 7 days?',
    'What was my new customer revenue from TikTok Ads over the last 7 days?',
    'How many sessions came from TikTok Ads over the last 7 days?',
    'How many visitors came from TikTok Ads over the last 7 days?',
    'How many new visitors came from TikTok Ads over the last 7 days?',
    'Give me my spend, ROAS, NC ROAS, CPA, NC CPA, CTR, CVR, and CPM from TikTok Ads over the last 7 days by day in a table.',
    'Give me my total ad spend and ROAS, over the last 12 weeks and break it out by week in a chart.',
    'Give me my top 10 spending TikTok ads over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, copy, and images.',
    'Give me my top 10 spending TikTok ad sets over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, CPA, and AOV.',
    'Give me my top 10 spending TikTok campaigns over the last 7 days and give me the spend, ROAS, NC ROAS, CPM, CTR, CPA, and AOV.',
    "Give me a table with all of my TikTok campaigns that contain the name 'Brand' in them and give me the spend, ROAS, CPM, and CTR.",
    "Give me a chart of my TikTok Ads campaigns that contain the name 'Brand' and give me the spend and ROAS using the 'last click' model and a 7 day attribution window.",
    'Give me TikTok Ads ROAS over the last 7 days and break it out by attribution model.',
    'Give me TikTok Ads ROAS over the last 7 days and break it out by attribution window.',
    'Give me my Customer Lifetime Value (LTV) from TikTok Ads for January 2024.',
    'Give me a list of my discount codes used from my TikTok Ads over the last 30 days.',
    'Give me a list of my order tags used from my TikTok Ads over the last 30 days and the number of times that the tags were in the orders.',
    'Give me a list of products sold by TikTok Ads over the last 30 days to new customers.',
    'Give me a list of SKUs and the number of times they were sold by TikTok Ads over the last 30 days to new customers.',
    'What is the total refund value from orders that came from TikTok Ads over the last 30 days?',
    'Can you give me my total spend and images vs video spend for TikTok Ads over the last 30 days?',
    'Give me my ROAS over the last 12 weeks on TikTok Ads by the day of the week.',
    'Give me my ROAS over the last 12 weeks on TikTok Ads by hour of the day.',
    'What was my % of new customers vs % of returning customers over the last 7 days that came from TikTok Ads?',
    'How many add to carts came from TikTok Ads last week?',
    'What was my new customer Conversion Rate (CVR) on TikTok Ads last week?',
    'What was my percentage of new customers vs percentage of returning customers over the last 7 days that came from TikTok Ads?',
    'What is the average session duration for visitors coming from TikTok Ads over the last 30 days?',
    'Give me the bounce rate for TikTok Ads traffic over the last 30 days.',
    'What is the pixel conversion rate for TikTok Ads over the last 30 days?',
    'Give me the new customer conversion rate for TikTok Ads over the last 30 days.',
    'What is the difference in total order revenue vs. channel-reported conversion value for TikTok Ads over the last 30 days?',
    'What is the cost per new visitor from TikTok Ads over the last 30 days?',
    'Show me the cost per visitor for TikTok Ads traffic over the last 30 days.',
    'What percentage of visitors from TikTok Ads were new visitors over the last 30 days?',
    'What is the pixel new visitor percent for TikTok Ads traffic over the last 30 days?',
    'Show me the average page views per session for visitors coming from TikTok Ads over the last 30 days.',
    "Give me the New and Returning Customer Revenue for Google Ads campaigns that contain 'Shop' in the campaign name over the last 30 days.",
    "Give me the Spend, ROAS, and CPA for Google Ads campaigns that contain 'vsa' in the campaign name over the last 30 days.",
    'What is the total number of clicks from TikTok Ads over the last 30 days?',
    'Show me the cost per click (CPC) for TikTok Ads over the last 30 days.',
    'Show me the conversion value delta for TikTok Ads over the last 30 days, comparing total order revenue and channel-reported conversion value.',
  ],
  amazon: [
    'How much revenue came from Amazon over the last 30 days?',
    'How many orders came from Amazon over the last 30 days?',
    'How many items did I sell on Amazon over the last 30 days?',
    'What was my AOV from Amazon in the last 7 days?',
    'What products did I sell over the last 30 days on Amazon?',
    'What was my ROAS, CPS and clicks on Amazon ads in the last 30 days?',
    'How much did I spend on Amazon ads in the last 30 days?',
    'How many purchases came on Amazon ads in the last 30 days?',
    'Give me my Amazon orders vs my Shopify orders over the last 12 months by month in a chart.',
    'Top products sold on Amazon last week.',
    'Performance metrics for Amazon ads in the last 30 days.',
    'Amazon ad spend and ROAS for last week.',
    'Comparison of Amazon orders vs. Shopify orders for the current year.',
    'Comparative analysis of AOV between Amazon and Shopify sales in the last quarter.',
    'What were my top selling products on Amazon last week.',
  ],
  post_purchase_survey: [
    'What are my top post-purchase survey responses for the last 7 days?',
    'How many responses have I gotten from my post-purchase survey in the last 7 days?',
    'Give me the top responses and their percentage of total responses from the post-purchase survey for the last 30 days.',
    'What was the response rate for the post-purchase survey in the last month?',
  ],
  blended_metrics: [
    'What is my blended ROAS over the last 90 days?',
    'What is my blended NC CPA over the last 30 days?',
    'What is my blended CPA over the last 7 days?',
    'What is my net profit over the last 7 days?',
    'What is my gross profit over the last 7 days?',
    'What is my net margin over the last 7 days?',
    'What is my blended MER over the last 7 days?',
    'What are my total ad spend and sales over the last 12 months by month?',
    'What is my blended ROAS and net profit over the last 12 months by month?',
    'What is my blended CPA and total sales over the last 14 weeks by week?',
    'Show me my blended CPA by month for the current year.',
  ],
  web_analytics: [
    'Show me my sessions, conversion rate per channel for yesterday. Put channels with the most sessions at the top.',
    'Show me my sessions, bounce rate, Avg time on site, and Avg number of pages visited for my top pages yesterday.',
    'Show me my sessions, orders, revenue, AOV, and conversion rate for my top pages yesterday.',
    'Show me my sessions, orders, revenue, AOV, and conversion rate per device for the last 30 days.',
    'Show me my sessions, orders, revenue, AOV, and conversion rate per country for the last 7 days.',
    'Show me my conversion rate, sessions, and add to carts by channel for the last 7 days.',
    'How do the session page views totals compare for the last 7 days vs the prior 7 days?',
    'What is the breakdown of sessions by device type?',
    'Which browsers contribute to the highest order revenue?',
    'Rank countries by order revenue and new users for the last quarter.',
    'What are the top cities by sessions and bounce rate?',
    'What is the average time on site per session for mobile vs. desktop users?',
    'What are the peak hours of site traffic by event hour, and order revenue?',
    'What is the ratio of new users to total sessions by month for the last 12 months?',
  ],
  forecasting: [
    'What is MAPE in a forecast? What is a good MAPE?',
    'What is Historic Revenue Forecast OOS?',
    'Explain the upper and lower confidence intervals in a forecast.',
    'Forecast my total revenue for the next 6 months.',
    'Forecast by total spend for the next 6 months.',
    'Forecast my new customer revenue for the next 6 months.',
    'Forecast my ROAS for the next 6 months.',
  ],
  product_analytics: [
    'Give me my products and SKUs that sold the most as well as the inventory levels.',
    'Give me my Spend and revenue per product for my top products for the last 30 days.',
    'Give me my CPA per product for the last 30 days.',
    'What is the detailed product analytics data for variants for the last 7 days including metrics such as revenue, orders, fulfillment costs, customers, ROAS, CPA, AOV, and contribution margin per item?',
    'What percentage of my orders for the last 30 days used a discount code?',
    'What percentage of my new customer orders for the last 30 days used a discount code?',
    'Show me a list of my products with the lowest Conversion Rate (CVR).',
    'What are my top 25 products by number of items sold for the past 30 days? Show me the total contribution margin for each. Exclude $0 products.',
    "Show me a list of my products by product sold where the customers' last click was from Google Ads that the campaign name had 'pmax' in it.",
    'What are my top 25 products by contribution margin for the past 30 days sorted by quantity of purchases per product?',
  ],
  product_journey: [
    'For new customers that purchase their first order in January 2024, what are the products they most often purchase in the next 3 orders?',
    'For new customers that make their first order in December 2023, what are the next products they most often purchase in the next 3 orders?',
    'For new customers that make their first order in May 2023, what are the next products they most often purchase in the next 3 orders?',
    'What are the top-selling products this month?',
    'What is the lifetime value (LTV) of new customers who made their first purchase in the last 365 days, grouped by the first product they purchased, and how many new customers purchased each product during that period?',
  ],
  cart_analysis: [
    'What are my top products bought together over the last 90 days?',
    'What are my top products bought together over the last 90 days? Minimum of 3 products bought together.',
  ],
  cdp: [
    'Give me a list of customers that came from Meta Ads over the last 7 days.',
    'Give me a list of customers that came from Meta Ads and ordered more than once over the last 180 days.',
    'Give me a list of customers that have an AOV of over $100.',
    'Give me a list of new customers that click on a Meta Ads ad more than 2 times over the last 60 days.',
    'Give me a list of customers that came from Google Ads over the last 7 days.',
    "Give me a list of customers that came from Meta Ads where the name contains 'Prospecting' over the last 7 days.",
    "Give me a list of customers that came from Google Ads where the name contains 'Brand' over the last 7 days.",
    'Give me a list of customers that came from Klaviyo over the last 7 days.',
    'Give me a list of customers that came from Meta Ads over the last 7 days and give me the number of items per order, total revenue, and emails.',
  ],
  cohorts: [
    'Show me a Cohort analysis of new customers acquired last year.',
    'Show me a cohort table for the last full 12 months where each cohort is defined by the new customer revenue acquired in each month and each column represent the subsequent month and the additional revenue made by that cohort per month.',
    'Show me a cohort table for the last full 12 months where each cohort is defined by the Customer count acquired in each month and each column represent the subsequent month and the Customer count made by that cohort per month.',
    'Show me a cohort table for the last full 12 months where each cohort is defined by the Revenue acquired in each month and each column represent the subsequent month and the revenue made by that cohort per month.',
    'Show me a cohort table for the last full 12 weeks where each cohort is defined by the new customer revenue acquired in each month and each column represent the subsequent weeks and the additional revenue made by that cohort in each subsequent week.',
    'Show me a cohort table for the last full 12 weeks where each cohort is defined by the Customer count acquired in each week and each column represent the subsequent week and the Customer count made by that cohort per week.',
    'Show me a cohort table for the last full 12 weeks where each cohort is defined by the Revenue acquired in each week and each column represent the subsequent week and the revenue made by that cohort per week.',
    'Show me a cohort table for the last full 12 months where each cohort is defined by the new customer revenue acquired in each month from Meta Ads using the Triple Attribution model and each column represent the subsequent month and cumulatively sums the additional revenue made by that cohort in each subsequent month.',
    'Show me a cohort table for the last full 12 months where each cohort is defined by the new customer revenue acquired in each month from Google Ads using the triple attribution model and each column represent the subsequent month and the additional revenue made by that cohort per month.',
    'Show me a cohort table for the last full 12 months where each cohort is defined by the new customers acquired in each month who purchased XYZ product in their first order and each column represent the subsequent month and the additional revenue made by that cohort per month.',
  ],
  ltv: [
    'Give me my LTV for customers that came in January 2024 by channel.',
    'Give me my LTV for customers that came in January 2024 by order tag.',
    'Give me my LTV for new customers from January 2024 broken out by channel.',
    'Give me my LTV for customers that came in January 2024 by channel for Meta Ads vs Google Ads.',
  ],
  sales_cycle: [
    'What is the average time between the first and second order of new customers that purchased in January 2024?',
    'What is the average time between the second and third order of new customers that purchased in January 2024?',
    'What is the average time between the second and third order of new customers that purchased in January 2024 for people that came from Meta Ads in the first order?',
    "What is the average time between the second and third order of new customers that purchased in January 2024 and their first order came from Meta Ads? Only show me customers who purchased the 'Top Knot' in their first order.",
  ],
  customer_journey: [
    'What is the average number of days between a first visit and a first purchase?',
    'What is the average number of days between a first visit and a first purchase for customers coming from Meta Ads?',
    'What is the average number of days between a first visit and a first purchase for customers coming from Google Ads?',
    'Give me a list of new customers that clicked on a Meta ad and also clicked on a Google ad over the last 60 days.',
    'Give me a list of customers that came from FB in their first purchase and ordered at least 1 more time over the last 180 days.',
  ],
  cogs: [
    'How much did I pay for COGS over the last 30 days?',
    'What are the products that cost me the most in fulfillment costs over the last 30 days?',
    'What percentage of our total revenue is being spent on COGS?',
    'Show me our COGS and total revenue by month for the last 12 months.',
  ],
  handing_fees_: [
    'What is the average handling fee per unit for the top products?',
    'Which products have the highest handling fees?',
    'How much did I spend on handling fees over the last 30 days?',
  ],
  payment_gateways: [
    'How much did I spend on payment gateways over the last 30 days?',
    'What are the top payment gateways providers over the last 30 days?',
  ],
  shipping_: [
    'How much did I pay on shipping over the last 30 days?',
    'How much did I pay on shipping over the last 30 days and break it out by country?',
  ],
  custom_expenses_: ['How much were my total custom expenses in the last 30 days?'],
  benchmarks: [
    'Give me the industry benchmarks for Meta ROAS and CPM in the clothing industry broken out by GMV tier.',
    'Give me the industry benchmarks for Google CPC and CPA in the clothing industry broken out by GMV tier.',
    'Give me the industry benchmarks for Meta ROAS broken out by industry and GMV tier.',
    'Give me the industry benchmarks for Google CPM broken out by industry and for the 10M+ GMV segment.',
    'Give me the industry benchmarks for Meta CPM and ROAS in the clothing industry, specifically for stores with a GMV exceeding $10 million over the last 7 days.',
    'Give me the industry benchmarks for Google CPM and ROAS in the clothing industry, specifically for stores with a GMV exceeding $1 million over the last 7 days.',
    'Give me the industry benchmarks for Meta AOV and CTR in the clothing industry, specifically for stores with a GMV exceeding $1 million over the last 7 days.',
    'Give me the industry benchmarks for CPC, CPM, CTR, for Meta for the last 30 days.',
    'Give me the industry benchmarks for ROAS, CPA, CVR, and AOV for Meta for the last 30 days.',
    'Give me the industry benchmarks for CPA, CPC, CPM, CVR, CTR, ROAS, and AOV for Google for the last 30 days.',
    'Give me the industry benchmarks for CPA, CPC, CPM, CVR, CTR, ROAS, and AOV for TikTok for the last 30 days.',
    'Show me industry benchmarks for CTR, ROAS, CPM and CPC for Meta Ads for shops in the apparel industry, with an AOV of >$100.',
    'How does my Meta CPM compare to industry benchmarks for the last 7 days, broken down by day.',
    'Show me the industry benchmark Meta CPM comparison between shops with an AOV less than $100 vs shops with AOV greater than $100.',
    'Show me the industry benchmarks for Google ROAS broken out by GMV tier.',
    'Show me my Meta ROAS and CPA over the last 6 months compared to industry benchmarks Meta ROAS and CPA.',
    'Show me my Google Ads CPC for the last 60 days compared to the industry benchmark Google CPC.',
    'Give me the industry benchmarks for Meta ROAS and CPM in the Electronics industry broken out by GMV tier.',
    'Give me the industry benchmarks for Google CPC and CPA in the pet supplies industry broken out by GMV tier.',
    'Give me the industry benchmarks for TikTok ROAS broken out by industry and GMV tier.',
    'Give me the industry benchmarks for Meta CPM and ROAS in the fashion accessories industry for brands MV exceeding $10 million over the last 7 days.',
    'How does my Meta AOV compared to industry benchmarks for the last 7 days, broken down by day.',
    'Show me the industry benchmark Meta ROAS comparison between shops with an AOV less than $100 vs shops with AOV greater than $100.',
    'Show me the industry benchmarks for Google CPA broken out by GMV tier.',
    'Show me my Meta CPM and CPC over the last 6 months compared to industry benchmarks Meta ROAS and CPA.',
    'Show me my TikTok CPM and CPC over the last 6 months compared to industry benchmarks Meta ROAS and CPA.',
  ],
  general: [
    'What strategies can we implement to improve conversion rates on our website?',
    'Brainstorm innovative ideas for a customer loyalty program that encourages repeat purchases.',
    'What are effective methods to reduce shopping cart abandonment on our platform?',
    'How can we optimize our mobile app to enhance the shopping experience?',
    'Explore ways to improve product discovery on our site for a more personalized user experience.',
    'What are the key elements of a streamlined checkout process that could reduce customer drop-off?',
    'Discuss creative email marketing strategies to re-engage past customers.',
    'Identify tactics for using social media platforms to drive sales effectively.',
    'How can we better utilize customer feedback to improve our product offerings?',
    'Strategies for addressing and learning from negative online reviews.',
    'Brainstorm ways to optimize inventory management for efficiency and cost-effectiveness.',
    'What sustainable practices can we adopt in our ecommerce operations?',
    'Identify and discuss challenges in expanding our ecommerce business internationally.',
    'How can we identify and target new customer segments effectively?',
    'Discuss ways to build and maintain a strong brand image online.',
    'Brainstorm strategies for successful collaborations with influencers.',
    'Discuss the potential of augmented reality in enhancing online shopping experiences.',
    'What types of content can we create to engage our audience and attract more visitors?',
    'Develop promotional strategies tailored for different seasons and holidays.',
    'How can we enhance our customer service to build stronger relationships?',
    'Identify effective cross-selling and upselling techniques for our online platform.',
    'Discuss how we can adapt our business strategy in response to changing consumer behaviors.',
    'What cybersecurity measures should we implement to protect our customers and business?',
    'Explore the use of virtual reality in providing immersive shopping experiences.',
  ],
  web: [
    'Find recent articles discussing emerging consumer trends in ecommerce for 2024.',
    "Look up the latest technologies being adopted in ecommerce and how they're impacting the industry.",
    'Search for dashboards or articles on the current state and future predictions of the global ecommerce market.',
    'Find case studies of successful social media marketing campaigns by ecommerce brands.',
    'Search for how ecommerce brands are implementing sustainability in their business models and operations.',
    'Look for tools and software used for analyzing customer reviews and sentiment in ecommerce.',
    'Find recent articles or studies on best practices in user experience design for ecommerce websites.',
    'Search for the latest effective SEO strategies specifically for ecommerce sites.',
    'Find the most recent legal regulations and compliance requirements affecting ecommerce businesses.',
    'Research the most effective advertising channels for ecommerce brands in the current year.',
    'Search for the latest trends and best practices in influencer marketing for ecommerce.',
    'Look up recent best practices for optimizing ecommerce platforms for mobile users.',
    'Search for successful strategies and tips for managing cross-border ecommerce operations.',
    'Look for innovative customer loyalty program examples in the ecommerce industry.',
    'Search for case studies or articles on effective logistics and supply chain models in ecommerce.',
    'Research the latest cybersecurity solutions and best practices for ecommerce sites.',
    'Look for the latest techniques and strategies for optimizing conversion rates on ecommerce platforms.',
    'Search for marketing strategies and campaign ideas for ecommerce during the holiday season.',
    'Find recent advancements in personalization techniques for online shopping experiences.',
    'Research innovative customer service strategies being used in the ecommerce industry.',
    'Look for training resources and professional development courses for ecommerce professionals.',
    'Search for articles discussing growth opportunities and strategies for ecommerce in emerging markets.',
    'Search for the newest payment gateway technologies and their impact on ecommerce.',
    'Find examples of ecommerce platforms integrating virtual reality to enhance shopping experiences.',
    'Look up recent innovations in ecommerce business models.',
    'Search for the latest trends in packaging for ecommerce and their environmental impact.',
    'Find studies or articles on ROI for different digital marketing strategies in ecommerce.',
    'Search for recent success stories of start-ups in the ecommerce sector.',
    'Look for examples of ecommerce sites using augmented reality for product visualization.',
    'Find recent developments in the use of chatbots for customer engagement in ecommerce.',
    'Search for comparisons and reviews of the latest ecommerce analytics tools.',
    'Research the differences between multichannel and omnichannel retailing strategies in ecommerce.',
    'Look up how blockchain technology is being implemented in ecommerce.',
    'Search for best practices in making ecommerce websites more accessible.',
    'Find the latest regulations and best practices for customer data protection in ecommerce.',
    'Research the growth and emerging trends in social commerce.',
    'Look up current trends and future predictions for voice shopping in ecommerce.',
    'Search for effective pricing strategies and models for ecommerce businesses.',
    'Find articles on how the rollout of 5G technology is impacting ecommerce.',
    'Research applications of AI in inventory management for ecommerce.',
    'Search for case studies demonstrating successful personalization strategies in ecommerce.',
    'Look up recent studies on the psychology behind online buying behavior.',
    'Find sustainable and eco-friendly fulfillment solutions for ecommerce businesses.',
    'Search for innovative marketing strategies for Cyber Monday sales.',
    'Look for best practices and trends in content marketing within the ecommerce industry.',
    'Research the challenges and solutions in global shipping and logistics for ecommerce.',
  ],
};

export const PINNED_SECTION_ID = 'pinnedSection';

export const DEFAULT_AXIS_DOMAIN: Record<'min' | 'max', AxisDomainItem> = {
  min: 0,
  max: 'dataMax',
};

export const GREEN = '#D1FAEE';
export const RED = '#FEE2E2';
export const groupAutoColumnId = 'ag-Grid-AutoColumn';

export const entityIdColumns = ['campaign_id', 'ad_set_id', 'ad_id'];
export const columnsForCampaigns = ['campaign_id', 'campaign_name', 'campaign_status'];
export const columnsForAdsets = ['ad_set_id', 'ad_set_name', 'ad_set_status'];
export const columnsForAds = ['ad_id', 'ad_name', 'ad_image_url', 'video_url', 'ad_status'];

export const FINISHED_PROGRESS_MESSAGE = 'Finished';

export const willyToolMap: WillyToolMap = {
  TextToSQL: {
    title: 'Warehouse',
    toolName: 'TextToSQL',
    icon: 'sql',
    color: 'one.5',
  },
  Searching: {
    toolName: 'Searching',
    title: 'WEB',
    icon: 'globe-new',
    color: 'one.5',
  },
  TextToPython: {
    toolName: 'TextToPython',
    title: 'Text to Python',
    icon: 'python',
    color: 'one.5',
  },
  Forecasting: {
    toolName: 'Forecasting',
    title: 'Forecasting',
    icon: 'ai-chat-spark',
    color: 'one.5',
  },
  MarketingMixModel: {
    toolName: 'MarketingMixModel',
    title: 'Asking the MMM',
    icon: 'sql',
    color: 'one.5',
  },
  GenerateInsights: {
    toolName: 'GenerateInsights',
    title: 'Generate Insights',
    icon: 'ai-chat-spark',
    color: 'one.5',
  },
};

export const CHAT_WITH_DASH_QUESTIONS = [
  'Based on this data, give me actionable recommendations',
  'Based on this data, give me insights for how to scale my business',
  'Based on this data, give me an analysis of what is working and what is not',
];

export const EDITOR_QUESTIONS = [
  'Explain this SQL query to me',
  'Based on this data, give me actionable recommendations',
];

export const INPUT_SEARCH_MAX_LENGTH = 5;

export const FORECAST_FAKE_TABLE: BqTable = {
  id: 'forecastTable',
  name: 'forecast Table',
  columns: [
    {
      id: 'algorithm',
      name: 'algorithm',
      title: 'Algorithm',
      type: 'string',
      description: '',
      options: ForecastAlgorithms.map((a) => {
        return {
          label: a,
          value: a,
        };
      }),
    },
    {
      id: 'horizon',
      name: 'horizon',
      title: 'Horizon',
      type: 'numeric',
    },
  ],
};

export const DEFAULT_DIALECT: Dialect = 'clickhouse';

export const willyOperators: WillyOperatorDict = {
  '=': {
    value: '=',
    label: 'Is',
    description: 'Equal to',
    applyInTypes: ['all'],
  },
  '!=': {
    value: '!=',
    label: 'Is not',
    description: 'Not equal to',
    applyInTypes: ['all'],
  },
  '>': {
    value: '>',
    label: 'Greater than',
    description: 'Greater than',
    onlyInSingle: true,
    applyInTypes: ['numeric', 'date', 'formula', 'timestamp'],
  },
  '>=': {
    value: '>=',
    label: 'Greater than or equal to',
    description: 'Greater than or equal to',
    onlyInSingle: true,
    applyInTypes: ['numeric', 'date', 'formula', 'timestamp'],
  },
  '<': {
    value: '<',
    label: 'Less than',
    description: 'Less than',
    onlyInSingle: true,
    applyInTypes: ['numeric', 'date', 'formula', 'timestamp'],
  },
  '<=': {
    value: '<=',
    label: 'Less than or equal to',
    description: 'Less than or equal to',
    onlyInSingle: true,
    applyInTypes: ['numeric', 'date', 'formula', 'timestamp'],
  },
  IN: {
    value: 'IN',
    label: 'In',
    description: 'In',
    onlyInMulti: true,
    applyInTypes: ['all'],
  },
  NOT_IN: {
    value: 'NOT_IN',
    label: 'Not in',
    description: 'Not in',
    onlyInMulti: true,
    applyInTypes: ['all'],
  },
  BETWEEN: {
    value: 'BETWEEN',
    label: 'Between',
    description: 'Between',
    onlyInSingle: true,
    applyInTypes: ['numeric', 'date', 'formula', 'timestamp'],
  },
  CONTAINS: {
    value: 'CONTAINS',
    label: 'Contains',
    description: 'Contains',
    onlyInSingle: true,
    applyInTypes: ['string'],
  },
  NOT_CONTAINS: {
    value: 'NOT_CONTAINS',
    label: 'Does not contain',
    description: 'Does not contain',
    onlyInSingle: true,
    applyInTypes: ['string'],
  },
  STARTS_WITH: {
    value: 'STARTS_WITH',
    label: 'Starts with',
    description: 'Starts with',
    onlyInSingle: true,
    applyInTypes: ['string'],
  },
  ENDS_WITH: {
    value: 'ENDS_WITH',
    label: 'Ends with',
    description: 'Ends with',
    onlyInSingle: true,
    applyInTypes: ['string'],
  },
};

export const FAVORITE_PROMPTS_COLLECTION = 'favorite_prompts';

export const STANDARD_DASHBOARDS: WillyDashboardElement[] = [
  {
    link: '/summary',
    name: 'Summary',
    id: 'summary',
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/attribution/all',
    name: 'Pixel All',
    id: 'pixel-all',
    dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/attribution/ads',
    name: 'Pixel Ads',
    id: 'pixel-ads',
    dependsOnFFSystem: FeatureFlag.PIXEL_LIVE_ORDERS_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/attribution/email',
    name: 'Pixel Email / SMS',
    id: 'pixel-email',
    dependsOnFFSystem: FeatureFlag.PIXEL_EMAIL_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/attribution/social',
    name: 'Pixel Organic',
    id: 'pixel-social',
    dependsOnFFSystem: FeatureFlag.PIXEL_ORGANIC_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/attribution/affluencer-hub/facebook-ads',
    name: 'Affluencer Hub - Meta',
    id: 'affluencer-hub',
    dependsOnFFSystem: FeatureFlag.PIXEL_AFFLUENCER_HUB_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/creative-cockpit',
    name: 'Creative Cockpit',
    id: 'creative-cockpit',
    dependsOnFFSystem: FeatureFlag.CC_CREATIVE_ANALYSIS_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/insights/cohorts',
    name: 'Cohorts',
    id: 'cohorts',
    dependsOnFFSystem: FeatureFlag.INSIGHT_GENERAL_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
  {
    link: '/product-analytics',
    name: 'Product Analytics',
    id: 'product-analytics',
    dependsOnFFSystem: FeatureFlag.PRODUCT_ANALYTICS_FF,
    widgetIds: [],
    history: [],
    type: 'dashboard',
    description: '',
    canEdit: false,
    isGlobal: true,
    isCustomView: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  },
];

export const AVAILABLE_LLM_MODELS = [
  'default',
  'chatgpt-4o-latest',
  'gpt-4o-mini',
  'gpt-4-turbo',
  'gpt-3.5-turbo-0125',
  'custom',
] as const;

export type LLMModel = (typeof AVAILABLE_LLM_MODELS)[number];

export const SQL_MODELS = [
  'default',
  'vertexai-llama3-405b-instruct-maas',
  'groq-llama-3.1-405b-reasoning',
  'groq-llama-3.1-70b-versatile',
  'groq-llama-3.1-8b-instant',
  'groq-llava-v1.5-7b-4096-preview',
  'gemini-1.0-pro-001',
  'gemini-1.0-pro-002',
  'gemini-1.5-flash-001',
  'gemini-flash-experimental',
  'gemini-pro-experimental',
  'gemini-experimental',
  'claude-3-5-sonnet@20240620',
  'claude-3-5-sonnet-v2@20241022',
  'claude-3-opus@20240229',
  'claude-3-haiku@20240307',
  'gpt-4o',
  'gpt-4o-mini',
  'o1-preview',
  'o1-mini',
  'custom',
] as const;

export type SQLModel = (typeof SQL_MODELS)[number];

export const FORECAST_MODELS = [
  'default',
  'chronos-bolt-base',
  'chronos-bolt-small',
  'chronos-bolt-tiny',
  'chronos-t5-small',
];

export type ForecastModel = (typeof FORECAST_MODELS)[number];

export type ModelInfo<T extends SQLModel | ForecastModel> = {
  value: T;
  label: string;
  description?: string;
  roles?: string[];
};

export const FORECAST_MODELS_INFO: ModelInfo<ForecastModel>[] = [
  {
    value: 'default',
    label: 'Auto',
    description: 'The model will be selected automatically based on the complexity of the query',
  },
  { value: 'chronos-bolt-base', label: 'Chronos Bolt Base', roles: ['admin'] },
  { value: 'chronos-bolt-small', label: 'Chronos Bolt Small', roles: ['admin'] },
  { value: 'chronos-bolt-tiny', label: 'Chronos Bolt Tiny', roles: ['admin'] },
  { value: 'chronos-t5-small', label: 'Chronos T5 Small', roles: ['admin'] },
];

export const SQL_MODELS_INFO: ModelInfo<SQLModel>[] = [
  {
    value: 'default',
    label: 'Auto',
    description: 'The model will be selected automatically based on the complexity of the query',
  },
  { value: 'o1-preview', label: 'Smarter', description: 'Slower, better for complex queries' },
  { value: 'vertexai-llama3-405b-instruct-maas', label: 'VertexAI Llama3 405B', roles: ['admin'] },
  { value: 'groq-llama-3.1-405b-reasoning', label: 'Groq Llama3 405B Reasoning', roles: ['admin'] },
  { value: 'groq-llama-3.1-70b-versatile', label: 'Groq Llama3 70B Versatile', roles: ['admin'] },
  { value: 'groq-llama-3.1-8b-instant', label: 'Groq Llama3 8B Instant', roles: ['admin'] },
  { value: 'groq-llava-v1.5-7b-4096-preview', label: 'Groq Llava v1.5 7B', roles: ['admin'] },
  { value: 'gemini-1.0-pro-001', label: 'Gemini 1.0 Pro 001', roles: ['admin'] },
  { value: 'gemini-1.0-pro-002', label: 'Gemini 1.0 Pro 002', roles: ['admin'] },
  { value: 'gemini-1.5-flash-001', label: 'Gemini 1.5 Flash 001', roles: ['admin'] },
  { value: 'gemini-flash-experimental', label: 'Gemini Flash Experimental', roles: ['admin'] },
  { value: 'gemini-pro-experimental', label: 'Gemini Pro Experimental', roles: ['admin'] },
  { value: 'gemini-experimental', label: 'Gemini Experimental', roles: ['admin'] },
  {
    value: 'claude-3-5-sonnet-v2@20241022',
    label: 'Claude 3.5 Sonnet v2 20241022',
    roles: ['admin'],
  },
  { value: 'claude-3-5-sonnet@20240620', label: 'Claude 3.5 Sonnet 20240620', roles: ['admin'] },
  { value: 'claude-3-opus@20240229', label: 'Claude 3.0 Opus 20240229', roles: ['admin'] },
  { value: 'claude-3-haiku@20240307', label: 'Claude 3.0 Haiku 20240307', roles: ['admin'] },
  { value: 'gpt-4o', label: 'GPT-4o', roles: ['admin'] },
  { value: 'gpt-4o-mini', label: 'GPT-4o Mini', roles: ['admin'] },
  { value: 'o1-mini', label: 'O1 Mini', roles: ['admin'] },
  { value: 'custom', label: 'Custom', roles: ['admin'] },
];

export const userFilterOptions = [
  { value: 'all', label: 'All Chats' },
  { value: 'user', label: 'Only My Chats' },
] as const;

export const WILLY_SEQUENCE_STEP_CATEGORIES: shelfCategoryType[] = [
  {
    id: 'tool',
    name: 'Tools',
    icon: 'player',
    color: 'green',
    tooltip: 'Access the full suite of tools from Moby to build powerful, data-driven workflows.',
  },
  {
    id: 'rule',
    name: 'Rules',
    icon: 'settings',
    color: 'teal',
    tooltip:
      'Set conditions and if-else logic to control workflow actions, perfect for tasks like anomaly detection.',
  },

  {
    id: 'destination',
    name: 'Destinations',
    icon: 'share',
    color: 'two',
    tooltip: `Choose where to send your workflow's results, such as Email, dashboards, or google sheets and more.`,
  },
  {
    id: 'subsequences',
    name: 'Run Subworkflows',
    icon: 'pods-view',
    color: 'purple',
    tooltip: 'Execute multiple workflows within a single process to gather diverse data points.',
  },
  {
    id: 'preload',
    name: 'Preload Data',
    icon: 'board',
    color: 'pink',
    tooltip: 'Import data from your Triple Whale Dashboards to use in your current workflow.',
  },
];

export const WILLY_SEQUENCE_STEP_OPTIONS: stepActionType[] = [
  {
    name: 'Auto',
    color: 'green',
    icon: 'influencers',
    type: 'tool',
    examplePrompts: [
      'Summarize the insights about my top products for this quarter.',
      'Give me a breakdown of new versus returning customers in this quarter.',
      'Tell me which days of the week have the highest order volume.',
      'What was the average order value in the last 90 days?',
      'Show insights on customer behavior based on recent purchases.',
    ],
    toolName: 'auto',
    category: 'tool',
    tooltip: 'Automate task execution based on predefined triggers or conditions.',
  },
  {
    name: 'Text to SQL',
    color: 'teal',
    icon: 'sequence-db',
    type: 'tool',
    toolName: 'TextToSQL',
    examplePrompts: [
      'Pull my store’s revenue for the last 30 days.',
      'Retrieve the top 10 products sold in the last quarter.',
      'Fetch customer data who made a purchase in the last 7 days.',
      'Show me the total number of orders for the year 2023.',
      'Give me the customer journey over the last quarter.',
    ],
    category: 'tool',
    tooltip: 'Convert natural language queries into SQL commands to interact with your data.',
    hasSettings: true,
  },
  {
    name: 'Insights, Recommendation and Summarization',
    color: 'one',
    icon: 'insights',
    type: 'insights',
    examplePrompts: [
      'Summarize the insights about my top products for this quarter.',
      'Give me a breakdown of new versus returning customers in this quarter.',
      'Tell me which days of the week have the highest order volume.',
      'What was the average order value in the last 90 days?',
      'Show insights on customer behavior based on recent purchases.',
    ],
    hasSettings: true,
    category: 'tool',
    tooltip:
      ' Generate insights, provide recommendations, and summarize data for quick decision-making.',
  },
  {
    toolName: 'Searching',
    name: 'Web Search',
    color: 'purple',
    icon: 'globe-new',
    type: 'tool',
    examplePrompts: [
      'Find the latest trends in e-commerce for 2024.',
      'Look for articles on best practices for increasing conversion rates in online stores.',
      'Search for the top 5 marketing strategies used by e-commerce brands this year.',
      'Find case studies on successful Meta ad campaigns.',
      'Search for recent news about Shopify’s partnership with social media platforms.',
    ],
    category: 'tool',
    tooltip: 'Retrieve relevant web data directly into your workflow for further analysis.',
  },
  {
    toolName: 'Forecasting',
    name: willyToolMap.Forecasting.title,
    color: 'pink',
    icon: 'star-plus',
    type: 'tool',
    examplePrompts: [
      'Predict next month’s sales based on the last 12 months of data.',
      'Forecast my blended ROAS for the upcoming week.',
      'Estimate the total revenue for the next quarter.',
      'What is the expected customer growth rate in the next 6 months?',
      'Project my ad spend for the next 3 months using historical data.',
    ],
    category: 'tool',
    tooltip: 'Predict future trends using historical data to inform strategic planning.',
    hasSettings: true,
  },
  {
    toolName: 'TextToPython',
    name: willyToolMap.TextToPython.title,
    color: 'red',
    icon: 'search-code',
    type: 'tool',
    examplePrompts: [
      'Analyze the conversion rates using my last dataset and generate a predictive model.',
      'Write code to generate a report of new customers by region.',
      'Interpret the sales trend from my dataset and suggest improvements.',
      'Use Python to calculate the average time between repeat purchases.',
      'Run an analysis script to find outliers in the purchase data.',
    ],
    category: 'tool',
    tooltip:
      'Translate natural language into Python code for advanced data analysis. Translate natural language into Python code for advanced data analysis. For example, you can ask it to "find the correlation between sales and marketing spend"',
    hasSettings: true,
  },
  {
    toolName: 'MarketingMixModel',
    name: 'MMM',
    color: 'red',
    icon: 'line-chart',
    type: 'tool',
    category: 'tool',
    tooltip: '(Marketing Mix Modeling) Analyze marketing performance and optimize future spending.',
    hasSettings: true,
  },
  {
    name: 'Run Subworkflows',
    color: 'red',
    icon: 'pods-view',
    type: 'subSequence',
    examplePrompts: [],
    category: 'subsequences',
  },
  {
    name: 'Preload Data From Dashboard',
    color: 'orange',
    icon: 'board',
    type: 'preloadData',
    category: 'preload',
  },
  {
    name: 'Preload Data From Run',
    icon: 'channels-major',
    color: 'orange',
    type: 'preloadRuns',
    category: 'preload',
  },
  {
    name: 'GPT Rule',
    color: 'yellow',
    icon: 'edit-settings',
    type: 'rule',
    examplePrompts: [
      'If the new customers percentage is greater than 10%.',
      'Is the ROAS for the last 30 days greater than 10%.',
      'My total revenue in the last 30 days went up by 20% compared to the previous 30 days.',
      'The number of new customers in the last 30 days is greater than 100.',
    ],
    category: 'rule',
    tooltip:
      'Use GPT-based logic to define dynamic conditions and responses within your workflow. It utilizes inputs from the data already present in the workflow for more complex decision-making.',
  },
  {
    name: 'Rule',
    color: 'red',
    icon: 'settings',
    type: 'condition',
    category: 'rule',
    tooltip:
      'Set standard if-else conditions to control the flow of your workflow based on specific criteria or data changes.',
  },
  {
    name: 'Send to Dashboard',
    color: 'teal',
    icon: 'board',
    type: 'sendToDashboard',
    category: 'destination',
  },
  {
    name: 'Send to Email',
    color: 'red',
    icon: 'mail',
    type: 'sendToEmail',
    category: 'destination',
  },
  {
    name: 'Send to Slack',
    color: 'yellow',
    icon: 'slack',
    type: 'sendToSlack',
    category: 'destination',
  },
  {
    name: 'Send to Google Sheet',
    color: 'red',
    icon: 'google-sheets',
    type: 'sendToGoogleSheet',
    category: 'destination',
  },
  {
    name: 'Sync to Warehouse',
    color: 'red',
    icon: 'db',
    type: 'sendToWarehouse',
    category: 'destination',
    ff: FeatureFlag.WAREHOUSE_SYNC_OUT_FF,
    examplePrompts: ['SELECT * FROM ads_table WHERE event_date = today() - 1'],
  },
  {
    name: 'Send to Webhook',
    color: 'red',
    icon: 'globe-new',
    type: 'sendToWebhook',
    category: 'destination',
  },
  {
    name: 'Run SQL',
    color: 'red',
    icon: 'db',
    type: 'runQuery',
    category: 'tool',
    examplePrompts: ['SELECT SUM(spend) FROM ads_table WHERE event_date = today() - 1'],
    tooltip:
      'Directly execute SQL queries to interact with your databases for custom data extraction.',
  },
];

// export const WILLY_SEQUENCE_TRIGGER_OPTIONS: triggerActionType[] = [
//   {
//     id: 'time',
//     name: 'On a schedule',
//     icon: 'clock',
//     color: 'yellow',
//     type: 'trigger',
//   },
//   {
//     id: 'rules',
//     name: 'On a condition',
//     icon: 'settings',
//     color: 'orange',
//     type: 'trigger',
//   },
// ];
