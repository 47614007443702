import { Box, BoxProps } from '@tw/ui-components';
import { PropsWithChildren, forwardRef } from 'react';

export type ConditionalWrapperProps = PropsWithChildren & {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  as?: BoxProps['as'];
};

/**
 * @description Used for situations where you know you want to render a component, but you don't know
 * if you want to render it within another component unless a certain condition is true.
 *
 * @example
 * Instead of:
 * ```tsx
 * return (
 *  {condition ? (
 *    <SomeWrapper>
 *      <Comp />
 *    </SomeWrapper>
 *  ) : (
 *    <Comp />
 *  )}
 * )
 * ```
 *
 * You can do:
 * ```tsx
 * return (
 *  <ConditionalWrapper
 *    condition={isThisTrue}
 *    wrapper={(children) => <SomeWrapper>{children}</SomeWrapper>}
 *  >
 *    <Comp />
 *  </ConditionalWrapper>
 * )
 * ```
 *
 * So basically if the condition is true, the callback function provided in the `wrapper` prop
 * will be run and will be used to render the JSX instead.  Otherwise, regular children are used.
 */
export const ConditionalWrapper = forwardRef<HTMLElement, ConditionalWrapperProps>(
  ({ condition, wrapper, children, as = 'div' }, ref) => {
    return (
      <Box as={as} ref={ref}>
        {condition ? wrapper(children) : children}
      </Box>
    );
  },
);
