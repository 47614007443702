import React, { useCallback, useState } from 'react';

import { Button, FormLayout, Icon, Modal, Spinner, TextField, Toast } from '@shopify/polaris';
import { DuplicateMinor, LinkMinor } from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';
import copyToClipboard from 'utils/copyToClipboard';
import { useAppDispatch } from 'index';
import { toggleSharePopup } from 'ducks/creativesCockpit/creativesCockpit';

type Props = {
  isOpen: boolean;
  type: ServicesIds;
  id?: string;
  shareUrl: string;
  creatingLink: boolean;
};

export const ShareLink: React.FC<Props> = ({ isOpen, shareUrl, creatingLink }) => {
  const dispatch = useAppDispatch();

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const toggleCopiedToClipboard = useCallback(() => setCopiedToClipboard((active) => !active), []);

  return (
    <>
      <Modal
        open={isOpen}
        title="Share a Creative Report Card"
        onClose={() => {
          dispatch(toggleSharePopup(false));
        }}
      >
        <Modal.Section>
          <FormLayout>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ margin: '0 5px 0 0' }}>
                <Icon source={LinkMinor} />
              </span>
              <p>Link to this Report Card</p>
            </div>
            <div>
              {creatingLink && <Spinner size="small" />}
              {!creatingLink && (
                <>
                  <TextField value={shareUrl} label="" onChange={() => {}} autoComplete="off" />
                  <div style={{ marginTop: '5px' }}>
                    <Button
                      onClick={() => {
                        copyToClipboard(shareUrl);
                        toggleCopiedToClipboard();
                      }}
                      icon={DuplicateMinor}
                    ></Button>{' '}
                  </div>
                </>
              )}{' '}
            </div>
          </FormLayout>
        </Modal.Section>
      </Modal>
      {copiedToClipboard && <Toast content="Link Copied" onDismiss={toggleCopiedToClipboard} />}
    </>
  );
};
