export enum SignUpStep {
  Email = 1,
  AccountType,
  PersonalDetails,
  ConfirmEmail,
  // ChooseSalesPlatform,
  ConnectStore,
  ChoosePlan,
  PaymentDetails,
  OrderSummary,
  Welcome,
}

export enum SignUpStepFree {
  Email = 1,
  AccountType,
  PersonalDetails,
  ConfirmEmail,
  // ChooseSalesPlatform,
  ConnectStore,
}
