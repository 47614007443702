import { $derived, $mutableDerived, $mutableObserver, ReadableStore } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { $isAdminClaim, $user, $userId, $userRoles } from '$stores/$user';
import { User } from 'components/UserProfileManagment/User/constants';
import { getDashContext } from 'components/Willy/dashContext';
import {
  WillyDashboardAccessType,
  WillyDashboardElement,
  WillyDashboardPermission,
} from 'components/Willy/types/willyTypes';
import { emptyObject } from 'utils/emptyObject';
// import { FieldValue } from 'utils/DB';
import { DashboardMemberData, MemberData } from './types';
import axiosInstance from 'utils/axiosInstance';
import { emptyArray } from 'utils/emptyArray';
import { $shopUsers } from '$stores/$users';

interface DashboardApi {
  $dashboard: ReadableStore<WillyDashboardElement | undefined>;
  updateDashboard: <T extends keyof WillyDashboardElement, R = Record<T, WillyDashboardElement[T]>>(
    fields: Partial<R>,
  ) => Promise<void>;
}

export type WillyDashPermissionsModalStore = {
  opened: boolean;
  loading: boolean;
};

const permRanks: Record<WillyDashboardPermission, number> = {
  none: 0,
  viewer: 1,
  editor: 2,
  owner: 3, // always has to be highest
};

export class DashboardPermissionsManager {
  private static instance: DashboardPermissionsManager | null = null;

  public static getInstance(_dashboardApi: DashboardApi) {
    if (!this.instance) this.instance = new DashboardPermissionsManager(_dashboardApi);
    return this.instance;
  }

  public static computeDashPerms(
    generalAccessType?: WillyDashboardAccessType,
    generalPermission?: WillyDashboardPermission,
    userPermissions?: WillyDashboardElement['userPermissions'],
    userRoles: readonly string[] = $userRoles.get(),
    userId = $userId.get(),
  ) {
    const isTwAdmin = $isAdminClaim.get();
    // existing user that has access will override general always?
    const isShopOwner = userRoles.includes('owner');
    const isShopAdmin = userRoles.includes('admin');
    const userAccess: WillyDashboardPermission | undefined = !userId
      ? 'none'
      : userPermissions?.[userId];

    const canView = (() => {
      if (isShopOwner || isShopAdmin || userAccess === 'owner' || isTwAdmin) return true;

      // open by default from dashboard level unless general access is specified
      switch (generalAccessType) {
        case 'owner':
          return false; // access type is owner and user isn't owner
        case 'shop':
          return userAccess !== 'none'; // user already must be part of shop, so allow as long as user isn't overridden
        case 'invite':
          return !!userAccess && userAccess !== 'none'; // user access must explicitly exist in map, and user can't be none
        default:
          return generalPermission !== 'none' && !!userAccess && userAccess !== 'none';
      }
    })();

    const canEdit =
      isTwAdmin ||
      isShopOwner ||
      isShopAdmin ||
      userAccess === 'owner' ||
      (userAccess === 'editor' && generalAccessType !== 'owner') ||
      (generalAccessType === 'shop' && generalPermission === 'editor');

    const canDelete = isShopOwner || userAccess === 'owner' || isTwAdmin;

    const canEditPerms = isShopOwner || isShopAdmin || isTwAdmin || userAccess === 'owner';

    return {
      canView,
      canEdit,
      canDelete,
      canEditPerms,
    };
  }

  private constructor(private _dashboardApi: DashboardApi) {}

  public readonly $shopUsers = $shopUsers;

  private readonly $shopUsersPending = $derived((get) => get(this.$shopUsers).loading);

  public readonly $modal = $mutableObserver<WillyDashPermissionsModalStore>(
    {
      opened: false,
      loading: true,
    },
    (get, set) => {
      const loading = get(this.$shopUsersPending);
      set({ ...get(), loading });
    },
  );

  private calculateUsersWithAccess(
    dashboard = this.dashboard,
    data = this.$shopUsers.get().data,
  ): DashboardMemberData[] {
    return (data || [])
      .filter((u) => this.checkUserHasSpecialConfig(u.id))
      .map((u) => ({ ...u, permission: this.getUserPermissions(dashboard)[u.id] }))
      .sort((a, b) => permRanks[b.permission] - permRanks[a.permission]);
  }

  public readonly $usersWithAccess = $mutableDerived((get) => {
    const dashboard = get(this._dashboardApi.$dashboard);
    const data = get(this.$shopUsers).data || [];
    return this.calculateUsersWithAccess(dashboard, data);
  });

  public readonly $generalAccess = $derived(
    (get) => get(this._dashboardApi.$dashboard)?.generalAccess,
  );

  public get dashboard() {
    return this._dashboardApi.$dashboard.get();
  }

  public getUserPermissions(
    dashboard: WillyDashboardElement | undefined = this.dashboard,
  ): Record<string, WillyDashboardPermission> {
    return dashboard?.userPermissions || emptyObject<Record<string, WillyDashboardPermission>>();
  }

  /** Checks to see if user has a unique behavior, which makes us treat them different than other users */
  public checkUserHasSpecialConfig(userId: string) {
    return userId in this.getUserPermissions();
  }

  public computeDashPerms() {
    const userPermissions = this.dashboard?.userPermissions;
    const generalAccessType = this.dashboard?.generalAccess?.type;
    const generalPermission = this.dashboard?.generalAccess?.permission;
    const userId = $userId.get();
    const userRoles = $userRoles.get();

    return DashboardPermissionsManager.computeDashPerms(
      generalAccessType,
      generalPermission,
      userPermissions,
      userRoles,
      userId,
    );
  }

  /** // TODO: Needs endpoint for sending emails */
  public async inviteUsers() {}

  /** Can be used to assign users specialized behaviors that don't follow the dashboard's general config */
  public async updateUsersConfig(userPermissions: Record<string, WillyDashboardPermission>) {
    const prevUsersWithAccess = this.$usersWithAccess.get();

    try {
      this.$modal.set((x) => ({ ...x, loading: true }));
      await this._dashboardApi.updateDashboard({ userPermissions });
      this.$usersWithAccess.set(this.calculateUsersWithAccess());
    } catch (err) {
      console.error('updateUsers error:>>', err);
      this.$usersWithAccess.set(prevUsersWithAccess); // revert in case of error
    } finally {
      this.$modal.set((x) => ({ ...x, loading: false }));
    }
  }

  /** Removes users from having unique configs. Brings them back to general dashboard config. */
  public async removeUsersFromConfig(...userIdsToRemove: string[]) {
    const prevUsersWithAccess = this.$usersWithAccess.get();

    try {
      const dashboard = this._dashboardApi.$dashboard.get();
      const existingUserPermissions: Record<string, WillyDashboardPermission> | undefined =
        dashboard?.userPermissions;
      if (!existingUserPermissions) return;

      this.$modal.set((x) => ({ ...x, loading: true }));

      // Since we moved update dashboard doc to backend, this code is buggy
      // const updatedUserPermissions: Record<
      //   string,
      //   WillyDashboardPermission | ReturnType<(typeof FieldValue)['delete']>
      // > = { ...existingUserPermissions };
      // for (const userId of userIdsToRemove) {
      //   updatedUserPermissions[userId] = FieldValue.delete();
      // }

      const existingUserPermissionWithoutUsersToRemove: Record<string, WillyDashboardPermission> =
        {};
      for (const [userId, permission] of Object.entries(existingUserPermissions)) {
        if (!userIdsToRemove.includes(userId)) {
          existingUserPermissionWithoutUsersToRemove[userId] = permission;
        }
      }
      await this._dashboardApi.updateDashboard({
        userPermissions: existingUserPermissionWithoutUsersToRemove,
      });
      this.$usersWithAccess.set((x) => x.filter((u) => !userIdsToRemove.includes(u.uid)));
    } catch (err) {
      console.error('updateUsers error:>>', err);
      this.$usersWithAccess.set(prevUsersWithAccess); // revert in case of error
    } finally {
      this.$modal.set((x) => ({ ...x, loading: false }));
    }
  }

  public async updateGeneralAccess(generalAccess: WillyDashboardElement['generalAccess']) {
    if (!generalAccess) return;

    try {
      this.$modal.set((x) => ({ ...x, loading: true }));
      await this._dashboardApi.updateDashboard({ generalAccess });
    } catch (err) {
      console.error('updateGeneralAccess error:>>', err);
    } finally {
      this.$modal.set((x) => ({ ...x, loading: false }));
    }
  }
}

export function getDashPermsManager() {
  return DashboardPermissionsManager.getInstance(getDashContext());
}
