import React, { ReactNode, useCallback, useMemo } from 'react';
import { $currentShopId } from '$stores/$shop';
import { $user, $userEmail } from '$stores/$user';
import { openDashSettingModal } from '$stores/willy/$dashSettingsModal';
import { Icon, type FormattedColor, Tooltip, Text } from '@tw/ui-components';
import { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { clearLastViewedDashboard } from 'components/Willy/utils/lastViewedDashboard';
import { copyDashboard, deleteMainElement } from 'components/Willy/utils/willyUtils';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addFavoriteDashboard, removeFavoriteDashboard } from '$stores/willy/$favoriteDashboards';
import { useStoreValue } from '@tw/snipestate';
import {
  LimitedAccess,
  LimitedAccessTooltip,
} from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { $createDashboardAccess } from '$stores/willy/$shopDashboards';
import { CreditBadge } from '../CreditBadge';
import { updateChangeFolderModal } from './ChangeCategoryModal';

export type DashboardDropdownOption = {
  content: string | JSX.Element;
  onClick: (...args: any) => void;
  leftSection: JSX.Element;
  color?: FormattedColor;
  onDisabledWrapper?: React.FC<{ target: (accessLimit?: LimitedAccess) => JSX.Element }>;
};

/**
 * @description Returns a list of actions that the user can perform on a given dashoard.
 */
export function useGetDashboardDropdownOptions(
  dashboard: WillyDashboardElement,
  dashboards: WillyDashboardElement[],
) {
  const params = useParams<{ dashboardId: string }>();
  const navigate = useNavigate();
  const userEmail = useStoreValue($userEmail);

  const duplicateDashboard = useCallback(
    async (dashboardId: string) => {
      const shopId = $currentShopId.get();
      const user = $user.get();
      if (!shopId || !user) {
        return;
      }
      const newId = await copyDashboard(
        dashboardId,
        shopId,
        user.email || '',
        user,
        true,
        { name: dashboards.find((x) => x.id === dashboardId)?.name + ' (Copy)' },
        !!dashboards.find((x) => x.id === dashboardId)?.isCustomView,
      );
      toast.success('Dashboard duplicated');
      navigate(`/dashboards/${newId}`);
    },
    [dashboards, navigate],
  );

  return useMemo((): { items: DashboardDropdownOption[] }[] => {
    type Falsey = false | undefined | null | 0 | '';
    type Section = { items: (DashboardDropdownOption | Falsey)[] };

    const sections: Section[] = [
      {
        items: [
          dashboard.canEdit &&
            dashboard.id === params.dashboardId && {
              content: 'Dashboard Settings and Details',
              onClick: () => openDashSettingModal(),
              leftSection: <Icon name="edit" size={14} />,
            },
          {
            content: (
              <div className={'flex flex-row justify-between items-center gap-3'}>
                <div>Duplicate</div>
                <CreditBadge dashboard={dashboard} />
              </div>
            ),
            onClick: () => duplicateDashboard(dashboard.id),
            leftSection: <Icon name="duplicate" size={14} />,
            onDisabledWrapper: ({ target }) => (
              <LimitedAccessTooltip target={target} $store={$createDashboardAccess} />
            ),
          },
          !dashboard.isCustomView && {
            content: 'Move to Folder',
            onClick: (e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              updateChangeFolderModal({ opened: true, dashboard });
            },
            leftSection: <Icon name="group" size={14} />,
          },
          !dashboard.isCustomView && {
            content: dashboard.isFavorite ? 'Remove From Favorites' : 'Add To Favorites',
            onClick: async () => {
              if (!userEmail) return;

              dashboard.isFavorite
                ? await removeFavoriteDashboard(dashboard.id)
                : await addFavoriteDashboard(dashboard.id);
            },
            leftSection: <Icon name="rating-star" size={14} />,
          },
        ],
      },
      {
        items: [
          dashboard?.canDelete && {
            content: 'Delete ' + (dashboard.isCustomView ? 'View' : 'Dashboard'),
            color: 'red.6',
            onClick: async () => {
              const res = await deleteMainElement(dashboard);
              if (!res) return;

              const redirect = dashboard.isCustomView
                ? `/dashboards/${dashboard.isCustomViewOfDashboardId}`
                : `/dashboards/new`;

              navigate(redirect);

              clearLastViewedDashboard();
            },
            leftSection: <Icon name="delete" color="red.6" size={20} />,
          },
        ],
      },
    ];

    // finally only take the items that are supposed to be visible
    return sections.map((s) => {
      return {
        ...s,
        items: s.items.filter((i): i is DashboardDropdownOption => !!i),
      };
    });
  }, [dashboard, duplicateDashboard, navigate, userEmail, params.dashboardId]);
}
