import { ActionIcon, Button, Icon, MultiSelect, Select, Text } from '@tw/ui-components';
import { WorkflowStepBase, WorkflowStepCondition } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $allRules, $willyRulePopup } from '../WillyRules/$stores';
import { createNewRule, updateRule } from '../WillyRules/utils';

type FlowLoadDataStepProps = {
  step: WorkflowStepCondition & WorkflowStepBase;
  stepChange: (newPrompt: WorkflowStepCondition & WorkflowStepBase) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowRuleStep: React.FC<FlowLoadDataStepProps> = ({
  step,
  stepChange,
  readOnly,
  setIsPristine,
}) => {
  const allRules = useStoreValue($allRules);
  const { rules } = step;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-2">
        <Text fz={14} fw={500}>
          Conditions
        </Text>
        {rules.map((rule, i) => (
          <div key={`rule-${i}`} className="flex flex-col gap-6.5">
            {i !== 0 && (
              <Text size="sm">
                <span className="lowercase">{rule.condition}</span>{' '}
                <span>all of the following conditions passed:</span>
              </Text>
            )}
            <div>
              <div className="flex items-center gap-2">
                <div className="flex-grow">
                  <MultiSelect
                    disabled={readOnly}
                    description="All conditions in a row must be met for the workflow to continue."
                    renderOption={(item) => {
                      return (
                        <div className="flex items-center gap-4 justify-between w-full">
                          {item.checked && <Icon name="check-thin" />}
                          <div className="flex-auto">{item.option.label}</div>
                          <div>
                            <ActionIcon
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                const rule = allRules.find((r) => r.id === item.option.value);
                                if (!rule) {
                                  return;
                                }

                                $willyRulePopup.set({
                                  isOpen: true,
                                  rule: rule,
                                  query: rule.query,
                                  ruleSaved: async (rule) => {
                                    try {
                                      await updateRule(rule);
                                    } catch (e) {
                                      console.error(e);
                                    }
                                  },
                                });
                              }}
                              icon="edit"
                            />
                          </div>
                        </div>
                      );
                    }}
                    data={[
                      ...allRules.map((r) => {
                        return {
                          value: r.id,
                          label: r.name,
                        };
                      }),
                    ]}
                    onChange={(v) => {
                      stepChange({
                        ...step,
                        rules: rules.map((r, j) => {
                          if (i !== j) {
                            return r;
                          }
                          return { ...r, ruleIds: v as string[] };
                        }),
                      });
                      setIsPristine(false);
                    }}
                    value={rule.ruleIds}
                  />
                </div>
                <ActionIcon
                  icon="delete"
                  onClick={() => {
                    readOnly
                      ? () => {}
                      : stepChange({
                          ...step,
                          rules: rules.filter((_, j) => j !== i),
                        });
                    setIsPristine(false);
                  }}
                />
              </div>
            </div>
            <div>
              <Button
                size="xs"
                disabled={readOnly}
                onClick={() => {
                  $willyRulePopup.set({
                    isOpen: true,
                    query: null,
                    rule: null,
                    ruleSaved: async (rule) => {
                      await createNewRule(rule);
                      stepChange({
                        ...step,
                        rules: rules.map((r, j) => {
                          if (i !== j) {
                            return r;
                          }
                          return { ...r, ruleIds: [...r.ruleIds, rule.id] };
                        }),
                      });
                      setIsPristine(false);
                    },
                  });
                }}
              >
                Create New Rule
              </Button>
            </div>
          </div>
        ))}
        <div
          className={`flex text-blue-600 font-medium text-[14px] items-center mt-5 ${!readOnly && 'cursor-pointer'}`}
          onClick={() => {
            readOnly
              ? () => {}
              : stepChange({
                  ...step,
                  rules: [...rules, { condition: 'OR', ruleIds: [] }],
                });
            setIsPristine(false);
          }}
        >
          <Icon name="plus-1" color="inherit" size={24} />
          Add Condition
        </div>
      </div>
    </div>
  );
};
