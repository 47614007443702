import { $dashItemDrawer, closeDashItemDrawer } from '$stores/willy/$mobileDashItemDrawer';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { MobileDrawer } from '@tw/ui-components';
import { WillyDashItem } from './WillyDashItem';
import { WillyFieldElement, WillyWidgetElement } from '../types/willyTypes';
import { useMemo, useState } from 'react';
import { useDashContext } from '../dashContext';
import { useWillyDashDnd } from '../hooks/useWillyDashDnd';
import { emptyArray, fieldIsWidget } from '../utils/willyUtils';
import useDashLayout from '../hooks/useDashLayout';

export const MobileWillyDashItemDrawer = () => {
  const { $fields, $widgets, $dashboard, $dashboardId, $isGlobal } = useDashContext();
  const { layout, setLayout } = useDashLayout();
  const dashboard = useStoreValue($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const isGlobalDashboard = useStoreValue($isGlobal);
  const [
    {
      opened,
      activeItemId,
      setEditMetricModalOpen,
      localFiles,
      setLocalFiles,
      updateFiles,
      updateDashWidgetData,
      openChatWithQuery,
      openSqlWithWidget,
    },
  ] = useWritableStore($dashItemDrawer);

  const widgets = useComputedValue($widgets, (w) => {
    return Array.isArray(w) ? w : emptyArray<WillyWidgetElement>();
  });

  const { items } = useWillyDashDnd({
    dashboard,
    widgets,
    fields: $fields.get(),
    layout,
    setLayout,
  });

  const [editingFields, setEditingFields] = useState<string[]>([]);

  const activeItem = useMemo(
    () => items.find((item) => item.id === activeItemId),
    [activeItemId, items],
  );

  return (
    <MobileDrawer title="Section" onClose={() => closeDashItemDrawer()} opened={!!opened}>
      {!!activeItem && (
        <WillyDashItem
          key={activeItem.id}
          id={activeItem.id}
          widget={fieldIsWidget(activeItem) ? activeItem : null}
          field={fieldIsWidget(activeItem) ? null : activeItem}
          isDnd={true}
          dashboard={dashboard}
          dashboardId={dashboardId}
          isGlobalDashboard={isGlobalDashboard}
          secondaryToolbarOpen={true}
          setEditMetricModalOpen={setEditMetricModalOpen}
          editingFields={editingFields}
          setEditingFields={setEditingFields}
          localFiles={localFiles}
          setLocalFiles={setLocalFiles}
          updateFiles={updateFiles}
          breakdownMode={(activeItem as WillyWidgetElement).breakdownMode}
          updateDashWidgetData={updateDashWidgetData}
          openChatWithQuery={openChatWithQuery}
          openSqlWithWidget={openSqlWithWidget}
          inMobileDrawer={true}
        />
      )}
    </MobileDrawer>
  );
};
