import { getToken, onMessage } from 'firebase/messaging';
import { app } from 'initializers/firebase.initializer';
import { firebaseMessageKey } from '../config';
import firebase from 'firebase/compat/app';
import { isMobileApp } from './Device';
import { isSupported, getMessaging } from 'firebase/messaging';
import Bowser from 'bowser';

export const getFireBaseMessagingToken = async (setTokenFound, user, isFromMobileApp) => {
  const hasFirebaseMessagingSupport = await isSupported();
  const firebaseMessaging = hasFirebaseMessagingSupport ? getMessaging(app) : null;
  // alert(hasFirebaseMessagingSupport);
  if (firebaseMessaging && !isFromMobileApp) {
    return getToken(firebaseMessaging, { vapidKey: firebaseMessageKey })
      .then((currentToken) => {
        if (currentToken) {
          const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
          setTokenFound(true);
          setTokenToUser(user, currentToken, browser?.name);
          // console.log(currentToken);
        } else {
          setTokenFound(false);
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
      });
  }
};

const setTokenToUser = async (user, token, browser) => {
  try {
    if (browser === 'Chrome') {
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ firebaseMessagingTokenChrome: token }, { merge: true });
    } else if (browser === 'Firefox') {
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ firebaseMessagingTokenFirefox: token }, { merge: true });
    }
    //TODO:remove this when we have set up settings for push notifications. this is just setting the token as the last browser used
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .set({ firebaseMessagingToken: token }, { merge: true });
  } catch (error) {
    console.error(error);
  }
};

export const onMessageListener = async () =>
  new Promise(async (resolve) => {
    const hasFirebaseMessagingSupport = await isSupported();
    const firebaseMessaging = hasFirebaseMessagingSupport ? getMessaging(app) : null;
    if (firebaseMessaging) {
      onMessage(firebaseMessaging, (payload) => {
        resolve(payload);
      });
    }
  });
