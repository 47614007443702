type TreeHorizontalLineProps = {
  isOnlySibling: boolean;
  isFirstSibling: boolean;
  isLastSibling: boolean;
  depth: number;
};

export const TreeHorizontalLine: React.FC<TreeHorizontalLineProps> = ({
  isOnlySibling,
  isFirstSibling,
  isLastSibling,
  depth,
}) => {
  return (
    <>
      {!isOnlySibling && (
        <>
          {depth !== 0 && (
            <div className={`w-full flex ${isLastSibling ? 'flex-row-reverse' : ''}`}>
              <div
                className={`w-1/2 h-[2px] ${!isFirstSibling && !isLastSibling ? 'bg-gray-300' : ''}`}
              ></div>
              <div className="w-1/2 h-[2px] bg-gray-300 "></div>
            </div>
          )}
        </>
      )}
    </>
  );
};
