import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { cp } from 'fs';
import { SelectableInfluencer } from 'types/Influencers';
import axiosInstance from 'utils/axiosInstance';

const firestore = firebase.firestore;

export const saveShopDiscountCode = async (discountCode, shopId) => {
  try {
    const doc = await firestore()
      .collection('shops')
      .doc(shopId)
      .collection('discount_codes')
      .doc(discountCode.code)
      .set(discountCode, { merge: true });

    return doc;
  } catch (e) {
    console.log('error', JSON.stringify(e));
  }
};

export const getPriceRulesAndDiscounts = async (shopId, shopifyAccessToken, shopTimezone) => {
  const priceRulesResponse = await axiosInstance.post('/v2/shopify/get-discount-codes', {
    data: {
      shopDomain: shopId,
      shopData: { shopifyAccessToken: shopifyAccessToken, timezone: shopTimezone },
    },
  });

  let price_rules = priceRulesResponse.data.priceRules;

  console.log(price_rules);
  return price_rules;
};

export const getDiscountCodes = (priceRules) => {
  let discounts: Array<any> = [];
  if (priceRules) {
    discounts = priceRules.map((priceRule) => {
      return priceRule.discounts.map((discount) => {
        return { code: discount.code, type: priceRule.value_type };
      });
    });
  }
  return discounts.flat();
};

export const createDiscountCode = async (data) => {
  try {
    const response = await axiosInstance.post('v2/shopify/create-discount-code', data);
    if (response.data.error) {
      return { error: response.data.error };
    }
    return response.data;
  } catch (e) {
    console.log('There was an error creating the discount');
    return { error: 'There was an error creating the discount' };
  }
};
