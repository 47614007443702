export function getMockGraph() {
  const graph = [
    {
      date: '2023-03-02T00:00:00-05:00',
      actual: 1.2,
      modelUpper: 1.4,
      modelLower: 1,
    },
    {
      date: '2023-03-03T00:00:00-05:00',
      actual: 1.1,
      modelUpper: 1.3,
      modelLower: 1.1,
    },
    {
      date: '2023-03-04T00:00:00-05:00',
      actual: 1.2,
      modelUpper: 1.33,
      modelLower: 1.11,
    },
    {
      date: '2023-03-05T00:00:00-05:00',
      actual: 1.3,
      modelUpper: 1.38,
      modelLower: 1.22,
    },
    {
      date: '2023-03-06T00:00:00-05:00',
      actual: 1.22,
      modelUpper: 1.4,
      modelLower: 1,
    },
    {
      date: '2023-03-07T00:00:00-05:00',
      actual: 1.25,
      modelUpper: 1.45,
      modelLower: 1.15,
    },
    {
      date: '2023-03-08T00:00:00-05:00',
      actual: 0.8,
      modelUpper: 1.4,
      modelLower: 1.1,
      anomalyScore: 0.8,
    },
  ];
  // sleep for 1 second
  // await new Promise(resolve => setTimeout(resolve, 500));
  return graph;
}

export function getMockSpendGraph() {
  return [
    {
      date: '2023-03-09T00:00:00-05:00',
      actual: 188,
      modelUpper: 225.055291717624485,
      modelLower: 162.944708282375515,
    },
    {
      date: '2023-03-09T01:00:00-05:00',
      actual: 150,
      modelUpper: 188.055291717624485,
      modelLower: 133.944708282375515,
    },
    {
      date: '2023-03-09T02:00:00-05:00',
      actual: 100,
      modelUpper: 133.055291717624485,
      modelLower: 77.944708282375515,
    },
    {
      date: '2023-03-09T03:00:00-05:00',
      actual: 100,
      modelUpper: 133.055291717624485,
      modelLower: 77.944708282375515,
    },
    {
      date: '2023-03-09T04:00:00-05:00',
      actual: 90,
      modelUpper: 133.055291717624485,
      modelLower: 77.944708282375515,
    },
    {
      date: '2023-03-09T05:00:00-05:00',
      actual: 100,
      modelUpper: 133.055291717624485,
      modelLower: 77.944708282375515,
    },
    {
      date: '2023-03-09T06:00:00-05:00',
      actual: 120,
      modelUpper: 133.055291717624485,
      modelLower: 77.944708282375515,
    },
    {
      date: '2023-03-09T07:00:00-05:00',
      actual: 150,
      modelUpper: 188.055291717624485,
      modelLower: 100.944708282375515,
    },
    {
      date: '2023-03-09T08:00:00-05:00',
      actual: 200,
      modelUpper: 225.055291717624485,
      modelLower: 162.944708282375515,
    },
    {
      date: '2023-03-09T09:00:00-05:00',
      actual: 220,
      modelUpper: 228.055291717624485,
      modelLower: 172.944708282375515,
    },
    {
      date: '2023-03-09T10:00:00-05:00',
      actual: 200,
      modelUpper: 229.055291717624485,
      modelLower: 167.944708282375515,
    },
    {
      date: '2023-03-09T11:00:00-05:00',
      actual: 210,
      modelUpper: 235.055291717624485,
      modelLower: 169.944708282375515,
    },
    {
      date: '2023-03-09T12:00:00-05:00',
      actual: 200,
      modelUpper: 225.055291717624485,
      modelLower: 162.944708282375515,
    },
    {
      date: '2023-03-09T13:00:00-05:00',
      actual: 220,
      modelUpper: 229.055291717624485,
      modelLower: 180.944708282375515,
    },
    {
      date: '2023-03-09T14:00:00-05:00',
      actual: 200,
      modelUpper: 225.055291717624485,
      modelLower: 162.944708282375515,
    },
    {
      date: '2023-03-09T15:00:00-05:00',
      actual: 180,
      modelUpper: 225.055291717624485,
      modelLower: 172.944708282375515,
    },
    {
      date: '2023-03-09T16:00:00-05:00',
      actual: 400,
      modelUpper: 225.055291717624485,
      modelLower: 162.944708282375515,
      anomalyScore: 0.9,
    },
  ];
}
