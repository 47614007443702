import React from 'react';
import { lazyLogger, analyticsEvents, lazyActions } from './dataLayer';

// https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
export const lazyWithRetries: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      const retryTimes = 2;
      for (let i = 0; i < retryTimes; i++) {
        // backoff factor of 2 each retry
        await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));
        // this assumes that the exception will contain this specific text with the url of the module
        // if not, the url will not be able to parse and we'll get an error on that
        // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
        const url = new URL(
          error.message.replace('Failed to fetch dynamically imported module: ', '').trim(),
        );
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        url.searchParams.set('t', `${+new Date()}`);

        try {
          return await import(
            /* @vite-ignore */
            url.href
          ).then((imported) => {
            lazyLogger(analyticsEvents.LAZY, {
              action: lazyActions.MODULE_RETRY_SUCCESS,
              message: `Retry #${i} succeeded!`,
              file: url.href,
            });

            return imported;
          });
        } catch (e) {
          lazyLogger(analyticsEvents.LAZY, {
            action: lazyActions.MODULE_RETRY_FAILED,
            message: `Failed to retry #${i} time(s), trying again...`,
            file: url.href,
            error: e,
          });
        }
      }

      lazyLogger(analyticsEvents.LAZY, {
        action: lazyActions.MODULE_RETRY_FATAL,
        message: `Could not retry after ${retryTimes} times, giving up!`,
      });

      throw error;
    }
  };

  return React.lazy(retryImport);
};

export default lazyWithRetries;
