import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import { $derived, $observer, $store } from '@tw/snipestate';

import { $ffStore } from 'feature-flag-system';
import { firestoreRef, toArray } from 'utils/DB';
import { WillyDashboardElement } from '../../components/Willy/types/willyTypes';
import {
  $isAdminClaim,
  $isTwGlobalDashboardCreatorClaim,
  $isTwSuperAdmin,
  $userEmail,
  $userId,
} from '../$user';
import { $shopWithSensory } from '../$shopWithSensory';
import { services } from '@tw/types/module/services';
import { $globalDashboardsStatistics } from './$globalDashboardsStatistics';
import { $msp } from '$stores/$shop';
import { isLocal, isProd } from '@tw/constants';

const $globalDashboardsRequest = $observer(
  { loading: true, data: [] as WillyDashboardElement[], error: null as string | null },
  async (get, set) => {
    if (!get($userId) || !get($ffStore).isReady) return;
    set({ ...get(), loading: true });

    try {
      const data = await firestoreRef().collection('willy_global_dashboards').get();

      set({
        error: null,
        loading: false,
        data: toArray(data).sort((a, b) => (a.name < b.name ? -1 : 1)),
      });
    } catch (err) {
      const message =
        typeof err === 'object' && 'message' in err && typeof err.message === 'string'
          ? err.message
          : 'Error caught trying to fetch global dashboards';

      if (isLocal || !isProd) {
        console.error('Error caught trying to fetch global dashboards', err);
      }

      set({ ...get(), loading: false, error: message });
    }
  },
);

const $privateGlobalDashboards = $observer(
  { data: [] as WillyDashboardElement[], loading: true, error: null as string | null },
  (get, set) => {
    const ffComputer = get($ffStore);
    if (!ffComputer.isReady) return;

    const { allowList: allowedDashboardIds } = ffComputer.getConfigById(FeatureFlag.TEMPLATES_FF);
    const isAdminClaim = get($isAdminClaim);
    const isTwGlobalDashboardCreatorClaim = get($isTwGlobalDashboardCreatorClaim);
    const isTwSuperAdmin = get($isTwSuperAdmin);
    const globalDashboardsStatistics = get($globalDashboardsStatistics);
    const shopMsp = get($msp);
    const { loading, data: snapshotData } = get($globalDashboardsRequest);
    if (loading) return;

    set({ ...get(), loading: true });

    const data = snapshotData.reduce((acc, d) => {
      if (!isAdminClaim && d.isBeta) return acc;
      if (!isTwGlobalDashboardCreatorClaim && d.isHide) return acc;

      let isProviderLocked = false;
      const email = get($userEmail);
      const { providers, providersBlockingCombination, msps } = d;
      let isTemplateAvailableToMsp = true;
      if (!!msps?.length && shopMsp) {
        isTemplateAvailableToMsp = msps.includes(shopMsp);
      }
      if (!!providers?.length) {
        const providersIsConnected = providers.map(
          (x) => services[x]?.getIsConnected?.(get($shopWithSensory)) ?? true,
        );
        const providersIsAvailable = providers.map((x) => {
          const provider = services[x];
          if (shopMsp && !!provider?.msps?.length) {
            return !!provider?.msps?.includes(shopMsp);
          }
          return true;
        });

        if (providersBlockingCombination === 'NONE') {
          isProviderLocked = false;
        } else if (providersBlockingCombination === 'OR') {
          isProviderLocked = providersIsConnected.every((x) => !x);
        } else {
          isProviderLocked = providersIsConnected.includes(false);
        }
        isTemplateAvailableToMsp = !providersIsAvailable.includes(false);
      }

      if (!isTemplateAvailableToMsp) return acc;

      const pkgMap = ffComputer.ffPackagesConfig;
      const packages: FeatureFlagConfigKey[] = [];
      const defaultPackages: FeatureFlagConfigKey[] = [];
      if (pkgMap) {
        for (let pkgName in pkgMap) {
          const _pkgName = pkgName as FeatureFlagConfigKey;

          const packageVal = pkgMap?.[_pkgName]?.[FeatureFlag.TEMPLATES_FF]?.value;
          const defaultPackageVal =
            pkgMap?.[_pkgName]?.[FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF]?.value;

          if (Array.isArray(packageVal) && (packageVal as any[]).includes(d.id)) {
            packages.push(_pkgName);
          }
          if (Array.isArray(defaultPackageVal) && (defaultPackageVal as any[]).includes(d.id)) {
            defaultPackages.push(_pkgName);
          }
        }
      }

      acc.push({
        ...d,
        isGlobal: true,
        isCustomView: false,
        type: 'dashboard',
        installCount: globalDashboardsStatistics[d.id]?.installed ?? 0,
        packages,
        defaultPackages,
        canView: true,
        canEdit: d.templateTwEmailsPermission?.includes(email ?? '') ?? false,
        canDelete: isTwSuperAdmin,
        isLocked: !!(allowedDashboardIds.length && !allowedDashboardIds.includes(d.id)),
        isProviderLocked,
      });

      return acc;
    }, [] as WillyDashboardElement[]);

    set({ ...get(), loading: false, data });
  },
);

export const $globalDashboards = $derived((get) => get($privateGlobalDashboards).data);

export const $globalDashboardIds = $derived(
  (get) => new Set(get($globalDashboards).map((d) => d.id)),
);
export const $loadingGlobalDashboards = $derived((get) => get($privateGlobalDashboards).loading);

export const $displayFeaturedTemplates = $store(true);
