import { Text } from '@tw/ui-components';
import './BubbleToggleButton.scss';

import React, { FC } from 'react';
import { Icon, IconProps, ActionIcon } from '@tw/ui-components';

interface BubbleToggleButtonProps {
  active: boolean;
  onClick: (...args: any) => any;
  label?: string;
  icon?: IconProps['name'];
  id?: string;
}

const BubbleToggleButton: FC<BubbleToggleButtonProps> = (props: BubbleToggleButtonProps) => {
  const { label, onClick, icon, active, id } = props;

  return (
    <>
      {label && (
        <div
          id={id}
          onClick={onClick}
          role="button"
          className={`flex items-center BubbleToggleButton rounded-[30px] py-[0.625rem] px-[0.9375rem]`}
          style={{
            backgroundColor: active ? 'var(--tw-logo-color)' : 'unset',
            color: active ? 'white' : '#062940',
          }}
        >
          {label}
        </div>
      )}
      {icon && (
        <ActionIcon
          radius="round"
          color="one.5"
          variant={active ? 'filled' : 'transparent'}
          onClick={onClick}
          icon={<Icon name={icon} color={active ? 'white' : undefined} />}
        />
      )}
    </>
  );
};

export default BubbleToggleButton;
