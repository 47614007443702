import {
  Checkbox,
  Group,
  useCombobox,
  Combobox,
  extractCSSColor,
  Icon,
  IconName,
} from '@tw/ui-components';
import React, { useMemo, useState } from 'react';
import classes from '../../SummarySectionPicker/SummarySectionPicker.module.scss';
import { useDarkMode } from '../../../dark-mode-control';
import { services } from '@tw/types/module/services';
import { ChevronDownMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { RootState } from '@reduxjs/toolkit/dist/query';
import { useAppSelector } from 'reducers/RootType';

export type Props = {
  values: string[];
  setValues: (prev: string[]) => void;
  targetClassName?: string;
};

export const ProviderComboBox: React.FC<Props> = ({ values, setValues, targetClassName }) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });
  const [search, setSearch] = useState('');
  const darkMode = useDarkMode();
  const handleValueSelect = (val: string) => {
    setValues(values.includes(val) ? values.filter((v) => v !== val) : [...values, val]);
  };

  const servicesMem = useMemo(() => Object.values(services), []);
  const shopMsp = useAppSelector((state) => state.msp);

  const options = servicesMem
    .filter((x) => x.providerType === 'data')
    .filter((x) => !x.isBeta)
    .filter((x) => x.name.toLowerCase().includes(search.toLowerCase()))
    .filter((x) => {
      return x?.msps?.includes(shopMsp) || x?.msps?.length === 0;
    })
    .map((item) => (
      <Combobox.Option value={item.id} key={item.id} active={values.includes(item.id)}>
        <Group gap="sm">
          <Checkbox
            checked={values.includes(item.id)}
            onChange={() => {}}
            aria-hidden
            tabIndex={-1}
          />
          <Icon name={item.smallIcon as IconName} />
          <span>{item.name}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <div
          onClick={() => {
            combobox.toggleDropdown();
          }}
          className={`flex items-center cursor-pointer text-gray-800 font-medium ${
            targetClassName ?? ''
          }`}
        >
          <div className={'flex items-center gap-3'}>
            {values.length === 0 && <div>All Integrations</div>}
            {values.length > 0 && (
              <>
                {values.length === 1 && (
                  <div className={'flex items-center gap-2'}>
                    <Icon
                      name={
                        (servicesMem.find((x) => x.id === values[0])?.smallIcon as IconName) ??
                        ('triple-whale-logo' as IconName)
                      }
                    />
                    <div>{servicesMem.find((x) => x.id === values[0])?.name ?? ''}</div>
                  </div>
                )}
                {values.length > 1 && (
                  <div className={'flex items-center gap-2'}>
                    <div className={'relative'}>
                      <Icon
                        name={
                          (servicesMem.find((x) => x.id === values[0])?.smallIcon as IconName) ??
                          ('triple-whale-logo' as IconName)
                        }
                      />
                      <div className={'absolute top-3.5 left-3.5'}>
                        <Icon
                          name={
                            (servicesMem.find((x) => x.id === values[1])?.smallIcon as IconName) ??
                            ('triple-whale-logo' as IconName)
                          }
                        />
                      </div>
                    </div>
                    <div className={'ml-4'}>
                      {servicesMem.find((x) => x.id === values[0])?.name ?? ''} ,{' '}
                      {servicesMem.find((x) => x.id === values[1])?.name ?? ''}
                    </div>
                    {values.length > 2 && <div>and {values.length - 2} others</div>}
                  </div>
                )}
              </>
            )}
            <div
              className={`flex items-center text-gray-500 w-5 h-5 cursor-pointer transition-transform  ${
                combobox.dropdownOpened ? 'rotate-180 origin-center' : ''
              }`}
            >
              <Icon name={'chevron-down'} />
            </div>
          </div>
        </div>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown miw={240}>
        <Combobox.Search
          classNames={{
            input: classes.search,
          }}
          styles={{
            input: {
              backgroundColor: extractCSSColor(darkMode ? 'gray.6' : 'white'),
              borderBottom: `0.5px solid ${extractCSSColor(darkMode ? 'gray.3' : 'gray.4')}`,
              fontWeight: 500,
            },
          }}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search Integrations"
        />
        <Combobox.Group label="All Integrations">
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Group>
      </Combobox.Dropdown>
    </Combobox>
  );
};
