import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, FormLayout } from '@shopify/polaris';
import {
  customMetricOnCreate,
  toggleCustomMetricModal,
  customMetricOnEdit,
  customMetricOnDelete,
} from 'ducks/customMetrics';
import { addTileToSection, sectionTilesOrderChanged } from 'ducks/summary';
import { selectAllMetrics, selectAllSections } from 'utils/selectors';
import { DOLLAR_TYPE } from '@tw/types/module/SummaryMetrics';
import { useAppDispatch } from 'index';
import { ExpressionBuilder } from './ExpressionBuilder';
import { confirmationModal } from 'ducks/confirmationModal';
import { CustomMetricsContext } from './CustomMetricsContext';
import {
  getExpressionByElements,
  getStatsByExpressionElements,
  parseDeprecatedCustomMetric,
  parseExpression,
  validateExpressionElements,
} from './utils';
import { ExpressionElement, StatItem, ElementTypes, CustomMetric } from './types';
import { CustomMetricsSettings } from './CustomMetricsSettings';

import './style.scss';
import { SummarySectionIds } from 'types/SummarySection';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { RootState } from 'reducers/RootType';

export const AddCustomMetricModal = () => {
  const { isModalOpen, customMetricsType, initialData } = useSelector(
    (state: RootState) => state.customMetrics,
  );
  const sourceCategory = useAttributionActivePage();
  const rawSections = useSelector(selectAllSections);
  const allMetrics = useSelector(selectAllMetrics);
  const [metricTitle, setMetricTitle] = useState<string>('');
  const [isMetricTitleError, setIsMetricTitleError] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [metricDescription, setMetricDescription] = useState<string>('');
  const [pendingSave, setPendingSave] = useState<boolean>(false);
  const [pendingDelete, setPendingDelete] = useState<boolean>(false);
  const [section, setSection] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [customMetricId, setCustomMetricId] = useState();
  const [metricType, setMetricType] = useState(DOLLAR_TYPE);
  const [expressionElements, setExpressionElements] = React.useState<ExpressionElement[]>([]);
  const [stats, setStats] = React.useState<StatItem[]>([]);
  const [tag, setTag] = React.useState<any>('');
  const { shouldNotBeSeen: isCustomMetricsBlocked } = useFeatureFlag(FeatureFlag.CUSTOM_METRICS_FF);

  useEffect(() => {
    if (!initialData?.stat && !initialData?.expression) return;
    const {
      title,
      description,
      section,
      stat,
      id,
      metricType,
      expression,
      stats,
      tag = '',
    } = initialData;
    const [expressionElements, statsList] = expression
      ? parseExpression(expression, stats, allMetrics)
      : parseDeprecatedCustomMetric(stat, allMetrics);

    setStats(statsList);
    setExpressionElements(expressionElements);
    setMetricTitle(title);
    setTag(tag);
    setMetricDescription(description);
    setSection(section);
    setIsEdit(true);
    setCustomMetricId(id);
    setMetricType(metricType);
  }, [initialData]);

  useEffect(() => {
    if (expressionElements?.length > 0) setError('');
  }, [expressionElements]);

  const reset = useCallback(() => {
    setExpressionElements([]);
    setStats([]);
    setMetricTitle('');
    setTag('');
    setMetricDescription('');
    setPendingSave(false);
    setPendingDelete(false);
    setSection(undefined);
    setIsEdit(false);
    setCustomMetricId(undefined);
    setMetricType(DOLLAR_TYPE);
    setError('');
    setIsMetricTitleError(false);
    setTag('');
  }, []);

  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    const [isValid, error] = validateExpressionElements(expressionElements);
    if ((!isValid || metricTitle === '') && !tag) {
      setError(error);
      setIsMetricTitleError(metricTitle === '');
      return;
    }
    setPendingSave(true);
    const expression = getExpressionByElements(expressionElements);
    const statList = getStatsByExpressionElements(expressionElements, stats);
    const customMetric: CustomMetric = {
      title: metricTitle,
      description: metricDescription || '',
      stats: statList,
      metricType,
      expression,
      source: sourceCategory,
      tag: tag,
    };
    if (section) {
      customMetric.section = section;
    }
    if (isEdit) {
      customMetric.id = customMetricId;
    }
    const saveFunc = isEdit ? customMetricOnEdit : customMetricOnCreate;
    const newMetricId = await dispatch(saveFunc(customMetric));
    if (section) {
      const sectionObj = rawSections.find((x) => x.id === section);
      if (sectionObj) await dispatch(addTileToSection(section, newMetricId, sectionObj.tiles));
    }
    if (!isEdit) {
      const customMetricsId: SummarySectionIds = 'customMetrics';
      await dispatch(addTileToSection(customMetricsId, newMetricId, []));
    }

    reset();
    dispatch(toggleCustomMetricModal({ isModalOpen: false }));
  };

  const deleteCustomMetricClick = async () => {
    dispatch(
      confirmationModal({
        title: 'Delete Custom Metric',
        onConfirm: handleDelete,
        text: `Are you sure you want to delete "${metricTitle}" Custom Metric?`,
      }),
    );
  };

  const handleDelete = async () => {
    setPendingDelete(true);
    await dispatch(customMetricOnDelete(customMetricId));
    reset();
    dispatch(toggleCustomMetricModal({ isModalOpen: false }));
    setPendingDelete(false);
  };

  const handleClose = () => {
    dispatch(toggleCustomMetricModal({ isModalOpen: false }));
    reset();
  };

  const contextProps = {
    expressionElements: expressionElements,
    stats: stats,
    metricTitle: metricTitle,
    metricDescription: metricDescription,
    metricType: metricType,
    section: section,
    isMetricTitleError: isMetricTitleError,
    type: customMetricsType,
    tag: tag,
    setExpressionElements: (items: ExpressionElement[]) => {
      setExpressionElements([...items]);
    },
    setMetricTitle: (title: string) => {
      setMetricTitle(title);
    },
    setMetricDescription: (description: string) => {
      setMetricDescription(description);
    },
    setMetricType: (type: string) => {
      setMetricType(type);
    },
    setSection: (section: any) => {
      setSection(section);
    },
    setIsMetricTitleError: (isError: boolean) => {
      setIsMetricTitleError(isError);
    },
    setTag: (tag: string | string[]) => {
      setTag(tag);
    },
    removeExpressionElement: (id: any) => {
      const index = expressionElements.findIndex((s) => s.id === id);
      if (index > -1) {
        expressionElements.splice(index, 1);
      }

      setExpressionElements([...expressionElements]);
    },
    updateStatItem: (id: any, metric: any) => {
      const index = expressionElements.findIndex((s) => s.id === id);
      if (index > -1) {
        let statSaved: Boolean = false;
        while (!statSaved) {
          const key: string = `field_${Math.floor(Math.random() * 100)}#`;
          const metricData = metric.metric;
          const indexStat = stats.findIndex((s) => s.key === key);
          if (indexStat == -1) {
            setStats([...stats, { key: key, metric: metricData }]);
            const item: ExpressionElement = {
              ...expressionElements[index],
              value: key,
              title: metric.label,
              type: metricData.statObjectKey ? ElementTypes.NESTED_STAT : ElementTypes.STAT,
            };
            expressionElements[index] = item;
            setExpressionElements([...expressionElements]);
            statSaved = true;
          }
        }
      }
    },
    updateIntegerItem: (id: any, value: number) => {
      const index = expressionElements.findIndex((s) => s.id === id);
      if (index > -1) {
        const item: ExpressionElement = {
          ...expressionElements[index],
          value: value,
        };
        expressionElements[index] = item;
        setExpressionElements([...expressionElements]);
      }
    },
  };

  return (
    <CustomMetricsContext.Provider value={contextProps}>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        title={`No-Code Metric Builder`}
        primaryAction={
          !isCustomMetricsBlocked
            ? {
                onAction: handleSubmit,
                content: 'Apply',
                loading: pendingSave,
                disabled: isMetricTitleError || error !== '',
                id: `apply_custom_metric_${customMetricsType}`,
              }
            : undefined
        }
        secondaryActions={
          isEdit
            ? [
                {
                  onAction: deleteCustomMetricClick,
                  content: 'Delete',
                  loading: pendingDelete,
                },
              ]
            : undefined
        }
        footer={
          isCustomMetricsBlocked ? (
            <LockedFeatureIndicator featureFlag={FeatureFlag.CUSTOM_METRICS_FF} />
          ) : undefined
        }
      >
        <Modal.Section>
          <FormLayout>
            <CustomMetricsSettings></CustomMetricsSettings>
            <div className="bg-gray-200 h-[1px]"></div>
            <div className="text-xl">
              1. Click on an element or drag it to the editor<br></br>
              2. Select a metric from the drop down<br></br>
              3. Add an operator<br></br>
              4. Add more element(s)<br></br>
              {error != '' && <div className="text-red-500"> {error}</div>}
            </div>
            <ExpressionBuilder></ExpressionBuilder>
          </FormLayout>
        </Modal.Section>
      </Modal>
    </CustomMetricsContext.Provider>
  );
};
