import { $derived, $mutableDerived, $observer, $store, ReadableStore } from '@tw/snipestate';
import { Shop } from '@tw/types';
import { isInIframe, isProduction } from 'config';
import { $redux } from './$redux';
import { $dialect, $userId } from './$user';
import _db, { userDb } from 'utils/DB';
import { $globalConfig } from './$globalConfig';
import Cookies from 'utils/Cookies';

const getInitialData = () => {
  const search = new URLSearchParams(location.search);
  const shopDomain = search.get('shop-domain') || search.get('shop-id') || null;
  let initialData = shopDomain ?? Cookies.get('currentShopId');

  if (isInIframe) {
    const idParam = new URLSearchParams(window.location.search).get('id');
    initialData = idParam?.length ? idParam : null;
  }

  if (initialData) {
    Cookies.set('currentShopId', initialData);
  }

  return initialData;
};

// make sure to clear active accounts when a new url is entered into the site
window.addEventListener('beforeunload', () => {
  if ($userId.get()) {
    userDb().update({ activeAccounts: [] });
  }
});

/** Only use as a readable store, because to properly set this, other things need to be done */
export const $currentShopId: ReadableStore<string | null> = $store(getInitialData());

export async function set_$currentShopId(shopId: string | null) {
  //@ts-ignore
  $currentShopId.set(shopId);

  if (!shopId) {
    console.log('clearing current shop id');
    Cookies.remove('currentShopId');
  } else {
    Cookies.set('currentShopId', shopId + '');
  }
  // userDb depends on user existing in state.
  // want to try replacing using userDb with $user store and having db
  // track all side effects
  if ($userId.get()) {
    await userDb().update({ activeAccounts: [] });
  }
}

const ssrShopData: Partial<Shop> | undefined = window.twShop;

export const $shop = $observer<Partial<Shop>>(
  ssrShopData || { shopName: '', subscription: {} },
  async (get, set) => {
    const userId = get($userId);
    const currentShopId = get($currentShopId);
    if (!currentShopId || !userId) return;

    // continue to listen for updates
    return _db(currentShopId).onSnapshot((shopDataSnapshot) => {
      set(shopDataSnapshot.data() || get());
    });
  },
);

export const $isSaas = $derived((get) => get($shop).msp === 'stripe');
export const $msp = $derived((get) => get($shop).msp);
export const $mspConnected = $derived((get) => !!get($shop).mspConnected);
export const $timezone = $derived((get) => get($shop).timezone);
export const $industry = $derived((get) => get($shop).industry);
export const $subscriptionActive = $derived((get) => get($shop).subscriptionActive);
export const $createdAt = $derived((get) => get($shop).createdAt);
export const $pixelActive = $derived((get) => get($shop).pixel);

export const $dontShowDeletedDashboard = $derived(
  (get) => get($shop).dontShowDeletedDashboard ?? false,
);

export const $isBetaShop = $derived(
  (get) =>
    !!get($shop)['isBetaShop'] || !isProduction || window.location.search?.includes('beta=1'),
);

const $shopSwitcherAccountList = $derived((get) => get($redux)?.shopSwitcherAccountList || null);
export const $activeAccounts = $derived((get) => {
  const currentShopId = get($currentShopId);
  const userId = get($userId);
  if (!currentShopId || !userId) return null;

  const shopSwitcherAccountList = get($shopSwitcherAccountList) || [];
  const savedShops = [...new Set([...shopSwitcherAccountList, currentShopId])];
  return savedShops;
});

export const $shopClickHouseInSummary = $derived((get) => !!get($shop).useClickhouse);
export const $shopSummaryWillyWay = $derived((get) => !!get($shop).summaryWilly);
export const $useSummaryWillyWay = $mutableDerived((get) => {
  const shopSummaryWillyWay = get($shopSummaryWillyWay);
  const globalConfig = get($globalConfig);
  const dialect = get($dialect);
  const msp = get($msp);
  if (msp !== 'shopify') return true;
  const forceBQ = !!globalConfig.find((x) => x.id === 'force2OnBQ')?.state;

  const forceBQPixelPage = get($forceBQPixelPage);

  if (forceBQPixelPage) return false;

  if (forceBQ) {
    return false;
  }

  if (globalConfig.find((x) => x.id === 'willyWay')?.state) {
    return true;
  } else {
    return shopSummaryWillyWay ?? false;
  }
});

export const $shopCurrency = $derived((get) => get($shop).currency ?? 'USD');
const $currencySetByUser = $derived((get) => {
  const currencySetByUser = get($shop).currencySetByUser;
  return currencySetByUser ?? null;
});

export const $currency = $mutableDerived((get) => {
  const shopCurrency = get($shopCurrency);
  const currencySetByUser = get($currencySetByUser);
  return currencySetByUser ?? shopCurrency;
});

export const $forceBQPixelPage = $derived((get) => !!get($shop).forceBQPixelPage);
export const $shopPixelWillyWay = $derived((get) => !!get($shop).pixelWilly);
export const $usePixelWillyWay = $mutableDerived((get) => {
  const shopPixelWillyWay = get($shopPixelWillyWay);
  const globalConfig = get($globalConfig);
  const dialect = get($dialect);
  const forceBQPixelPage = get($forceBQPixelPage);
  const msp = get($msp);

  if (msp !== 'shopify') return true;

  if (forceBQPixelPage) return false;

  const forceBQ = !!globalConfig.find((x) => x.id === 'force2OnBQ')?.state;

  if (forceBQ) {
    return false;
  }

  if (globalConfig.find((x) => x.id === 'fullyCHPixel')?.state || dialect === 'clickhouse') {
    return true;
  } else {
    return shopPixelWillyWay ?? false;
  }
});

export const $useClickHouseInSummary = $mutableDerived((get) => {
  const shopClickHouseInSummary = get($shopClickHouseInSummary);
  const globalConfig = get($globalConfig);
  if (globalConfig.find((x) => x.id === 'adsOnClickhouse')?.state) {
    return true;
  } else {
    return shopClickHouseInSummary ?? false;
  }
});

export const $shopForceSharded = $derived((get) => get($shop).forceSharded ?? null);
export const $shopForceNoSharded = $derived((get) => get($shop).forceNoSharded ?? null);
export const $forceSharded = $mutableDerived((get) => {
  const shopForceSharded = get($shopForceSharded);
  const shopForceNoSharded = get($shopForceNoSharded);
  const globalConfig = get($globalConfig);

  if (shopForceNoSharded === true) {
    return false;
  }
  if (globalConfig.find((x) => x.id === 'forceNoSharded')?.state) {
    return false;
  }

  if (globalConfig.find((x) => x.id === 'forceSharded')?.state) {
    return true;
  } else {
    return shopForceSharded ?? false;
  }
});

export const $shopUseNewYoutubeAlgo = $derived((get) => !!get($shop).useNewYoutubeAlgo);
export const $useNewYoutubeAlgo = $mutableDerived((get) => {
  const shopUseNewYoutubeAlgo = get($shopUseNewYoutubeAlgo);
  return shopUseNewYoutubeAlgo ?? false;
});
export const $shopUseNewPixelTable = $derived((get) => !!get($shop).useNewPixelTable);
export const $useNewPixelTable = $mutableDerived((get) => {
  const shopUseNewPixelTable = get($shopUseNewPixelTable);
  return shopUseNewPixelTable ?? false;
});

export const $mobyUserIds = $derived((get) => get($shop).mobyUserIds ?? null);

export const $shopPackageVersion = $derived((get) => get($shop).packageVersion);
