import { $store } from '@tw/snipestate';
import { WillyBaseMainElement, WillyWidgetElement } from 'components/Willy/types/willyTypes';

export type DashSettingsDefaultData = {
  defaultName?: string;
  defaultDescription?: string;
  defaultIsDynamic?: boolean;
  defaultFile?: File;
};

type DashSettingsModalStore = {
  opened: boolean;
  newDashboard?: boolean;
  newGlobalDashboard?: boolean;
  globalDashboardSourceForNewDashboard?: WillyBaseMainElement;
  widgetData?: WillyWidgetElement[];
  defaultData?: DashSettingsDefaultData;
  conversationId?: string;
  advancedSettingsOpened?: boolean;
};

export const $dashSettingsModal = $store<DashSettingsModalStore>({
  opened: false,
  newDashboard: false,
  newGlobalDashboard: false,
});

export const openDashSettingModal = (
  newDashboard = false,
  newGlobalDashboard = false,
  globalDashboardSourceForNewDashboard?: WillyBaseMainElement,
  widgetData?: WillyWidgetElement[],
  defaultData?: DashSettingsDefaultData,
  conversationId?: string,
  advancedSettingsOpened?: boolean,
) => {
  $dashSettingsModal.set({
    opened: true,
    newDashboard,
    newGlobalDashboard,
    globalDashboardSourceForNewDashboard,
    widgetData,
    defaultData,
    conversationId,
    advancedSettingsOpened: advancedSettingsOpened,
  });
};

export const toggleAdvancedSettings = () => {
  $dashSettingsModal.set((x) => ({ ...x, advancedSettingsOpened: !x.advancedSettingsOpened }));
};

export const closeDashSettingsModal = () => {
  $dashSettingsModal.set({ opened: false, advancedSettingsOpened: false });
};
