import { WillyWidget } from 'components/Willy/WillyWidget';
import _db, { Timestamp, firestoreRef } from 'utils/DB';
import {
  Conversation,
  WorkflowStep,
  WillyBaseMainElement,
  WillyDataSequence,
  DialectWithBoth,
  WorkflowResponse,
  Message,
  WorkflowStepSendToGoogleSheet,
  HistoryItem,
  WillyDataSequenceTaggedMetric,
  WillyDataSequencePixelSettings,
  WorkflowStepVariable,
} from '../types/willyTypes';
import axiosInstance from 'utils/axiosInstance';
import {
  detectDynamicParameters,
  extractVariablesFromWhereClause,
  getConversationDialect,
  takeSampleOfData,
  removeUndefinedFields,
  cleanToolResults,
} from './willyUtils';
import { Dialect } from '@tw/types';
import { DEFAULT_DIALECT, MAX_ITEMS_PER_PAGE } from '../constants';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { isValidUrl } from './isValidUrl';
import { v4 as uuidV4 } from 'uuid';
import { WorkflowStepBase } from '../types/willyTypes';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { replaceQueryIdsWithWidgets } from './replaceQueryIdsWithWidgets';

export type EditNewSequenceProps = {
  baseMainElement: Partial<WillyBaseMainElement>;
  messages: WorkflowStep[];
  dialect?: DialectWithBoth;
  featureFlagConfigs?: any[];
  ffConfigsToRemoveDashId?: any[];
  featureFlagDefaultCopyConfigs?: any[];
  ffConfigsDefaultToRemoveDashId?: any[];
  additionalShopIds?: string[];
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
};

export type CreateNewSequenceProps = {
  baseMainElement: WillyBaseMainElement;
  shopId: string;
  userId: string;
  messages: WorkflowStep[];
  conversationId?: string;
  dialect?: DialectWithBoth;
  featureFlagConfigs?: any[];
  ffConfigsToRemoveDashId?: any[];
  featureFlagDefaultCopyConfigs?: any[];
  ffConfigsDefaultToRemoveDashId?: any[];
  additionalShopIds?: string[];
  taggedMetrics?: WillyDataSequenceTaggedMetric[];
  pixelSettings?: WillyDataSequencePixelSettings;
};

export async function createNewSequence(props: CreateNewSequenceProps) {
  const {
    baseMainElement,
    shopId,
    userId,
    conversationId,
    dialect,
    messages,
    additionalShopIds,
    taggedMetrics,
    pixelSettings,
  } = props;
  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    createdAt,
    updatedAt,
  } = baseMainElement;

  let conversationData: Conversation | null = null;
  let conversationDialect: Dialect | null = null;
  let steps: WorkflowStep[] | undefined = [];

  if (conversationId) {
    const conversation = await _db().collection('conversations').doc(conversationId).get();
    if (!conversation.exists) {
      return {
        error: 'Conversation not found',
      };
    }
    conversationData = conversation.data() as Conversation;

    const { data } = await axiosInstance.post<
      any,
      {
        data: {
          steps?: WorkflowStep[];
          error?: string;
        };
      }
    >('/v2/sequences/create-messages-from-conversation', {
      shopId,
      conversationId,
    });
    const { error } = data;
    ({ steps } = data);
    if (error || !steps) {
      return {
        error: 'Could not create messages from conversation',
      };
    }

    conversationDialect = getConversationDialect(conversationData);
  } else {
    steps = messages;
  }

  let newSequence: WillyDataSequence = {
    id: id,
    name: name,
    description: description ?? '',
    updatedAt,
    isGlobal: isGlobal,
    type: 'sequence',
    canEdit: false,
    steps,
    createdAt,
    emoji: emoji,
    user: userId,
    image: image ?? '',
    v: conversationData?.v ?? 6,
    dialect: conversationDialect ?? dialect ?? DEFAULT_DIALECT,
    additionalShopIds: additionalShopIds,
    providers,
    roles,
  };

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      providersBlockingCombination,
      category,
      isBeta,
      isHide,
      taggedMetrics: taggedMetrics,
    };
    if (pixelSettings) {
      newSequence = {
        ...newSequence,
        pixelSettings: pixelSettings,
      };
    }
  }

  try {
    // const ref = isGlobal
    //   ? firestoreRef().collection('global_data_sequences')
    //   : _db(shopId).collection('data_sequences');

    const { data: res } = await axiosInstance.post<WillyDataSequence>(
      '/v2/sequences/create-workflow',
      {
        shopId,
        sequence: newSequence,
        isGlobal,
      },
    );

    // await ref.doc(id).set(newSequence, { merge: true });

    return {
      success: true,
      message: 'Sequence created successfully',
      conversationData,
      sequence: newSequence,
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not save sequence',
    };
  }
}

export const duplicateSequence = async (sequence: WillyDataSequence) => {
  const newSequence: WillyDataSequence = {
    ...sequence,
    id: uuidV4(),
    name: `(Copy) ${sequence.name}`,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  };

  const sanitizedSequence = cleanToolResults(removeUndefinedFields(newSequence));

  try {
    await _db()
      .collection('data_sequences')
      .doc(sanitizedSequence.id)
      .set(sanitizedSequence, { merge: true });

    return {
      id: sanitizedSequence.id,
      conversationData: sanitizedSequence,
      success: true,
      message: 'Sequence duplicated successfully',
    };
  } catch (e) {
    console.error(e);
    return {
      error: 'Could not duplicate sequence',
    };
  }
};

export const editSequence = async (seq: EditNewSequenceProps, seqId) => {
  const {
    baseMainElement,
    dialect,
    messages,
    featureFlagConfigs,
    ffConfigsToRemoveDashId,
    featureFlagDefaultCopyConfigs,
    ffConfigsDefaultToRemoveDashId,
    additionalShopIds,
    taggedMetrics,
    pixelSettings,
  } = seq;
  const {
    id,
    name,
    description,
    isGlobal,
    emoji,
    image,
    isBeta,
    isHide,
    roles,
    category,
    providers,
    providersBlockingCombination,
    createdAt,
    updatedAt,
    msps,
  } = baseMainElement;
  const ref = isGlobal
    ? firestoreRef().collection('global_data_sequences')
    : _db().collection('data_sequences');
  let newSequence: Partial<WillyDataSequence> = {
    description: description ? description : '',
    updatedAt: Timestamp.now(),
    name: name,
    emoji: emoji,
    image: image ?? '',
    steps: messages,
    dialect: dialect ?? 'clickhouse',
    reportPrompt: '',
    additionalShopIds,
    providers,
    roles,
  };

  // if (publish) {
  //   newSequence = {
  //     ...newSequence,
  //     isDraft: false,
  //   };
  // }

  if (isGlobal) {
    newSequence = {
      ...newSequence,
      providersBlockingCombination: providersBlockingCombination,
      category: category,
      isBeta: isBeta,
      isHide: isHide,
      dialect: dialect,
      msps: msps,
      taggedMetrics: taggedMetrics,
    };
    if (pixelSettings) {
      newSequence = {
        ...newSequence,
        pixelSettings: pixelSettings,
      };
    }
  }
  try {
    const updated = await ref.doc(seqId).set(newSequence, { merge: true });
    if (isGlobal) {
      await Promise.all([
        updateDashboardForFFConfigs(
          {
            configs: featureFlagConfigs,
            mergeStrategy: 'merge',
            dashboardId: seqId,
          },
          FeatureFlag.TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: ffConfigsToRemoveDashId,
            mergeStrategy: 'delete',
            dashboardId: seqId,
          },
          FeatureFlag.TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: seq.featureFlagDefaultCopyConfigs,
            mergeStrategy: 'merge',
            dashboardId: seqId,
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        ),
        updateDashboardForFFConfigs(
          {
            configs: ffConfigsDefaultToRemoveDashId,
            mergeStrategy: 'delete',
            dashboardId: seqId,
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        ),
      ]);
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const deleteDraft = async (draftId, isGlobal) => {
  if (!draftId) {
    return;
  }
  const ref = isGlobal
    ? firestoreRef().collection('global_data_sequences')
    : _db().collection('data_sequences');
  try {
    await ref.doc(draftId).delete();
  } catch (e) {
    console.error(e);
  }
};

export const validateSequenceSteps = (sequenceSteps: WorkflowStep[]) => {
  const stepValid = !sequenceSteps.some((step) => isStepInvalid(step, false));
  return stepValid;
};

export function getMessagesFromHistory(history: HistoryItem[]): Message[] {
  return history?.map((h) => {
    return {
      id: h.messageId,
      question: h.originalQuestion,
      ...h,
    };
  });
}

export function getMessagesFromWorkflowResponse(
  response: WorkflowResponse,
  dialect: Dialect,
  isPrev?: boolean,
): Message[] {
  if (response.stepType === 'tool' || response.stepType === 'runQuery') {
    return (
      response.res?.messages
        .filter((message) => {
          if (message.role === 'user') {
            return message.text.length > 0;
          } else if (message.role === 'assistant') {
            return message.text.length > 0;
          } else {
            return message.toolResults || message.text.length > 0;
          }
        })
        .map((message) => {
          return {
            ...message,
            id: message.messageId,
            dialect: dialect,
            question: message.originalQuestion,
            text: message.text,
          };
        }) || []
    );
  } else if (response.stepType === 'insights') {
    const children = replaceQueryIdsWithWidgets(response.richText ?? '', dialect);
    const res: Message[] = [];
    res.push({
      id: uuidV4(),
      role: 'assistant',
      text: !!children ? '' : response.text,
      children,
      error: response.error ?? undefined,
    });
    return res;
  } else if (response.stepType === 'preloadData') {
    const all = response[isPrev ? 'prevData' : 'data']?.flatMap((c) => c) ?? [];

    return all.map<Message>((m) => {
      if (m.dataType === 'forecast' || m.dataType === 'mmm') {
        return {
          id: uuidV4(),
          role: 'assistant',
          toolResults: {
            name: 'Forecasting',
            message: m,
          },
        };
      }

      return {
        id: uuidV4(),
        role: 'assistant',
        question: m.question,
        toolResults: {
          name: 'TextToSQL',
          nlqResponse: m,
          isSqlOnly: false,
          error: !!m.error,
          errorForInterface: m.error,
          sqlGenerated: m.generatedQuery,
        },
      };
    });
  } else {
    return [
      {
        id: response.stepId,
        role: 'assistant',
        text: response.status === 'error' ? response.error ?? '' : response.text ?? '',
        error: response.error ?? undefined,
      },
    ];
  }
}

export function isStepInvalid(step: WorkflowStep, validateDynamicParams = true): string | null {
  if (step.stepType === 'subSequence') {
    if (!step.sequenceIds || step.sequenceIds.length === 0) {
      return 'Sub-workflow must have at least one sequence';
    }
  } else if (step.stepType === 'preloadData') {
    if (!step.dashboardId || step.dashboardId.length === 0) {
      return 'Preload data step must have a report id';
    }
  } else if (step.stepType === 'tool' || step.stepType === 'insights' || step.stepType === 'rule') {
    const dynamicParams = detectDynamicParametersInStep(step);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (!step.text || step.text.length === 0) {
      return 'Step text is required';
    }
  } else if (step.stepType === 'sendToDashboard') {
    if (!step.dashboardId || step.dashboardId.length === 0) {
      return 'Send to report step must have a report id';
    }
  } else if (step.stepType === 'sendToEmail') {
    const dynamicParams = detectDynamicParametersInStep(step);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in step "${step.title || step.stepType}", assign a value to it before running the step`;
    }
    if (!step.email || step.email.length === 0) {
      return 'Email is required';
    }
  } else if (step.stepType === 'sendToSlack') {
    // no validation needed yet?
  } else if (step.stepType === 'condition') {
    if (!step.rules || step.rules.length === 0) {
      return 'Condition must have at least one rule';
    }
    if (!step.rules.every((rule) => rule.ruleIds?.length > 0)) {
      return 'Each rule must have at least one rule id';
    }
  } else if (step.stepType === 'sendToGoogleSheet') {
    return validateSendToGoogleSheetStep(step);
  } else if (step.stepType === 'sendToWarehouse') {
    if (!step.providerId) {
      return 'Provider id is required in warehouse step';
    }
    if (!step.integrationId) {
      return 'Integration id is required in warehouse step';
    }
    if (!step.tableId) {
      return 'Table id is required in warehouse step';
    }
    if (step.useDedicatedQuery && (!step.query || step.query.length === 0)) {
      return 'Query is required if you check "Use dedicated query"';
    }
  } else if (step.stepType === 'runQuery') {
    const params = extractVariablesFromWhereClause(step.query);
    if (params?.length && !Object.keys(step.queryParams || {}).length) {
      return `${params.join(', ')} declared in query but not provided in query params`;
    }
    const dynamicParams = detectDynamicParametersInStep(step);
    const dynamicParamsWithoutValue = dynamicParams?.filter((p) => p.value.length === 0);
    if (validateDynamicParams && dynamicParamsWithoutValue?.length) {
      return `${dynamicParamsWithoutValue.map((p) => `${p.key}`).join(', ')} declared in query but not provided in query params`;
    }
    if (!step.query || step.query.length === 0) {
      return 'Query is required in run query step';
    }
  } else if (step.stepType === 'sendToWebhook') {
    if (!step.url || step.url.length === 0) {
      return 'Url is required in send to webhook step';
    }
    if (!isValidUrl(step.url)) {
      return 'Url is invalid in send to webhook step';
    }
  }

  return null;
}

export function validateSendToGoogleSheetStep(step: WorkflowStepSendToGoogleSheet): string | null {
  if (!step.sheetsAccount) {
    return 'Google Sheets account is required';
  }
  if (!step.spreadsheetId) {
    return 'Spreadsheet is required';
  }
  if (!step.worksheetId) {
    return 'Worksheet is required';
  }
  if (!step.spreadsheetName) {
    return 'Spreadsheet name is required';
  }
  if (!step.worksheetName) {
    return 'Worksheet name is required';
  }

  if (step.ssNameError) {
    return 'Spreadsheet name is invalid';
  }

  if (step.wsNameError) {
    return 'Worksheet name is invalid';
  }

  return null;
}

export function detectDynamicParametersInStep(step: WorkflowStep): WorkflowStepVariable[] | null {
  const { variables = [] } = step;

  const removeBrackets = (text: string) =>
    text.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, p1) => p1.replace(/\s+/g, ''));

  if (step.stepType === 'tool') {
    let all = detectDynamicParameters(step.text);

    let code: string | undefined = undefined;
    if (step.toolToUse === 'TextToSQL' && step.toolPreload?.name === 'TextToSQL') {
      code = step.toolPreload.generatedQuery;
    } else if (step.toolToUse === 'TextToPython' && step.toolPreload?.name === 'TextToPython') {
      code = step.toolPreload.pythonCode;
    }

    const fromCode = detectDynamicParameters(code ?? '');
    if (fromCode) {
      if (all) {
        all.push(...fromCode);
      } else {
        all = fromCode;
      }
    }

    return (
      all?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'insights') {
    const res = detectDynamicParameters(step.text);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'rule') {
    const res = detectDynamicParameters(step.text);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'sendToEmail') {
    const res = detectDynamicParameters(step.email);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  } else if (step.stepType === 'runQuery') {
    const res = detectDynamicParameters(step.query);
    return (
      res?.map((v) => {
        const key = removeBrackets(v);
        return {
          key,
          value: variables.find((v2) => v2.key === key)?.value ?? '',
        };
      }) ?? null
    );
  }
  return null;
}

export function createNewStep(step: Partial<WorkflowStep>, userEmail?: string) {
  let newStep: WorkflowStep;
  const id = uuidV4();

  const { stepType } = step;

  const baseStep: WorkflowStepBase = {
    createdAt: step.createdAt ?? new Date().toISOString(),
    id: step.id ?? id,
    title: step.title ?? '',
    variables: [],
    runInParallel: step.runInParallel ?? false,
  };

  if (stepType === 'tool') {
    newStep = {
      ...baseStep,
      text: '',
      stepType,
      preserveHistory: true,
    };
    if (!!step.toolToUse) {
      newStep = {
        ...newStep,
        toolToUse: step.toolToUse,
      };
    }
  } else if (stepType === 'preloadData') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      dashboardId: '',
      date: DatePickerTimePeriods.TODAY,
      previousDate: 'none',
      filters: null,
    };
  } else if (stepType === 'preloadRuns') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      runIds: [],
    };
  } else if (stepType === 'subSequence') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      sequenceIds: [],
    };
  } else if (stepType === 'rule') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
    };
  } else if (stepType === 'condition') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      rules: [],
    };
  } else if (stepType === 'insights') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      text: '',
      model: '',
      outputType: 'text',
    };
  } else if (stepType === 'sendToEmail') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      email: userEmail ?? '',
      formats: [],
    };
  } else if (stepType === 'sendToSlack') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      formats: [],
    };
  } else if (stepType === 'sendToDashboard') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      dashboardId: '',
    };
  } else if (stepType === 'sendToGoogleSheet') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      spreadsheetId: '',
      spreadsheetName: '',
      ssNameError: false,
      worksheetId: '',
      worksheetName: '',
      wsNameError: false,
      sheetsAccount: '',
    };
  } else if (stepType === 'sendToWarehouse') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      providerId: '',
      integrationId: '',
      tableId: '',
      useDedicatedQuery: false,
      query: '',
      queryParams: {},
    };
  } else if (stepType === 'sendToWebhook') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      url: '',
      headers: [],
    };
  } else if (stepType === 'runQuery') {
    newStep = {
      ...baseStep,
      stepType: stepType,
      query: '',
      queryParams: {},
    };
  } else {
    return null;
  }
  return newStep;
}

export function editStep(stepToUpdate: WorkflowStep, newStep: WorkflowStep) {
  const varsInStep = detectDynamicParametersInStep(newStep);
  const oldVariables = (stepToUpdate.variables || []).filter((v) =>
    varsInStep?.some((v2) => v2.key === v.key),
  );
  const newVariables = newStep.variables || [];
  const variables = [
    ...oldVariables.map((v) => ({
      ...v,
      value: newVariables.find((v2) => v2.key === v.key)?.value ?? v.value,
    })),
    ...newVariables.filter((v) => !oldVariables.some((v2) => v2.key === v.key)),
  ];

  let uniqueVariables = variables.filter(
    (v, i, self) => i === self.findIndex((v2) => v2.key === v.key),
  );

  if (!varsInStep) {
    uniqueVariables = [];
  }

  stepToUpdate = {
    ...stepToUpdate,
    title: newStep.title ?? stepToUpdate.title ?? '',
    variables: uniqueVariables || [],
    runInParallel: newStep.runInParallel ?? stepToUpdate.runInParallel ?? false,
  };
  if (newStep.stepType === 'tool') {
    return {
      ...stepToUpdate,
      text: newStep.text,
      preserveHistory: !!newStep.preserveHistory,
      toolPreload: newStep.toolPreload ?? null,
    };
  } else if (newStep.stepType === 'insights') {
    return {
      ...stepToUpdate,
      text: newStep.text,
      model: newStep.model || null,
      outputType: newStep.outputType || 'text',
    };
  } else if (newStep.stepType === 'rule') {
    return {
      ...stepToUpdate,
      text: newStep.text,
    };
  } else if (newStep.stepType === 'preloadData') {
    return {
      ...stepToUpdate,
      dashboardId: newStep.dashboardId,
      date: newStep.date,
      previousDate: newStep.previousDate,
      filters: newStep.filters || null,
    };
  } else if (newStep.stepType === 'condition') {
    return {
      ...stepToUpdate,
      rules: newStep.rules,
    };
  } else if (newStep.stepType === 'subSequence') {
    return {
      ...stepToUpdate,
      sequenceIds: newStep.sequenceIds,
    };
  } else if (newStep.stepType === 'sendToDashboard') {
    return {
      ...stepToUpdate,
      dashboardId: newStep.dashboardId,
    };
  } else if (newStep.stepType === 'sendToEmail') {
    return {
      ...stepToUpdate,
      email: newStep.email,
      formats: newStep.formats || [],
    };
  } else if (newStep.stepType === 'sendToSlack') {
    return {
      ...stepToUpdate,
      formats: newStep.formats || [],
    };
  } else if (newStep.stepType === 'sendToGoogleSheet') {
    return {
      ...stepToUpdate,
      spreadsheetId: newStep.spreadsheetId,
      spreadsheetName: newStep.spreadsheetName,
      ssNameError: newStep.ssNameError,
      worksheetId: newStep.worksheetId,
      worksheetName: newStep.worksheetName,
      wsNameError: newStep.wsNameError,
      sheetsAccount: newStep.sheetsAccount,
    };
  } else if (newStep.stepType === 'sendToWarehouse') {
    return {
      ...stepToUpdate,
      providerId: newStep.providerId,
      integrationId: newStep.integrationId,
      tableId: newStep.tableId,
      useDedicatedQuery: newStep.useDedicatedQuery,
      query: newStep.query,
      queryParams: newStep.queryParams || {},
    };
  } else if (newStep.stepType === 'sendToWebhook') {
    return {
      ...stepToUpdate,
      url: newStep.url || '',
      headers: newStep.headers || [],
    };
  } else if (newStep.stepType === 'runQuery') {
    return {
      ...stepToUpdate,
      query: newStep.query,
      queryParams: newStep.queryParams,
    };
  } else if (newStep.stepType === 'preloadRuns') {
    return {
      ...stepToUpdate,
      runIds: newStep.runIds,
    };
  }

  return stepToUpdate;
}

export function getParentRuleId(stepId: string, type: 'passed' | 'failed') {
  return `${stepId}_${type}`;
}

export function cutWorkflowResponse(result: WorkflowResponse | null): WorkflowResponse | null {
  if (!result) {
    return null;
  }
  if (result.stepType !== 'tool' && result.stepType !== 'runQuery') {
    return result;
  }
  if (!result.res?.messages?.length) {
    return result;
  }

  return {
    ...result,
    res: {
      ...result.res,
      messages: result.res.messages.map((msg) => {
        if (msg.role !== 'tool') {
          return msg;
        }
        if (!msg.toolResults) {
          return msg;
        }
        if (msg.toolResults.name === 'TextToSQL') {
          return {
            ...msg,
            toolResults: {
              ...msg.toolResults,
              nlqResponse: {
                ...msg.toolResults.nlqResponse,
                dataColumns: msg.toolResults.nlqResponse?.dataColumns || { x: [], y: [] },
                data: takeSampleOfData(msg.toolResults.nlqResponse?.data, MAX_ITEMS_PER_PAGE),
              },
            },
          };
        }
        return msg;
      }),
    },
  };
}
