import { BaseSummaryMetric, SummaryMetrics } from '@tw/types/module/SummaryMetrics';
import { MetricBreakdownsDictionary, OutOfSummaryMetricsDictionary } from './types';

export const OutOfSummaryMetricIds = [
  'grossRevenue',
  // 'totalGrossSales',
  'totalShippingPrice',
  'totalDiscounts',
  'totalAmazonShippingDiscount',
  'expenses',
  'cost',
  'amazonCogs',
  'amazonHandlingFees',
  'totalAmazonFees',
  'totalAmazonShippingPrice',
  'allOrders',
  'new_customers',
  'allSales',
  'totalSales',
  'shopifyAovNetSales',
  'totalTaxes',
  'totalRefundTaxes',
  'blendedAdsWithoutCustom',
  'facebookClicks',
  'facebookImpressions',
  'facebookOutboundClicks',
  'facebookSpend_costPerOutboundClicks',
  'pixelPageViews',
  'pixelTimeOnSite',
  'pixelBounces',
  'tiktokClicks',
  'pinterestTotalClicks',
] as const;

const OutOfSummaryMetrics: OutOfSummaryMetricsDictionary = {
  grossRevenue: {
    id: 'grossRevenue',
    metricId: 'GrossRevenue',
    title: 'Gross Revenue',
    type: 'currency',
  },
  // totalGrossSales: {
  //   id: 'totalNewGrossSales',
  //   metricId: 'totalNewGrossSales',
  //   title: 'Product Revenue',
  //   type: 'currency',
  // },
  totalShippingPrice: {
    id: 'totalShippingPrice',
    metricId: 'totalShippingPrice',
    title: 'Shipping Revenue',
    type: 'currency',
    icon: 'shopify',
  },
  totalDiscounts: {
    id: 'totalDiscounts',
    metricId: 'totalDiscounts',
    title: 'Discount',
    type: 'currency',
    icon: 'shopify',
  },
  totalAmazonShippingDiscount: {
    id: 'totalAmazonShippingDiscount',
    metricId: 'totalAmazonShippingDiscount',
    title: 'Amazon Shipping Discount',
    type: 'currency',
    icon: 'amazon',
  },
  expenses: {
    id: 'expenses',
    metricId: 'expenses',
    title: 'Expenses',
    type: 'currency',
  },
  cost: {
    id: 'cost',
    metricId: 'cost',
    title: 'Cost',
    type: 'currency',
  },
  shopifyAovNetSales: {
    id: 'shopifyAovNetSales',
    metricId: 'shopifyAovNetSales',
    title: 'Net sales orders value',
    type: 'currency',
    icon: 'shopify',
  },
  totalTaxes: {
    id: 'totalTaxes',
    metricId: 'totalTaxes',
    title: 'Total Order Taxes',
    type: 'currency',
    icon: 'shopify',
  },
  totalRefundTaxes: {
    id: 'totalRefundTaxes',
    metricId: 'totalRefundTaxes',
    title: 'Total Returned Taxes',
    type: 'currency',
    icon: 'shopify',
  },
  amazonCogs: {
    id: 'amazonCogs',
    metricId: 'amazonCogs',
    title: 'Amazon COGS',
    icon: 'amazon',
    type: 'currency',
  },
  amazonHandlingFees: {
    id: 'amazonHandlingFees',
    metricId: 'amazonHandlingFees',
    title: 'Amazon Handling Fees',
    icon: 'amazon',
    type: 'currency',
  },
  totalAmazonFees: {
    id: 'totalAmazonFees',
    metricId: 'totalAmazonFees',
    title: 'Amazon Fees',
    icon: 'amazon',
    type: 'currency',
  },
  totalAmazonShippingPrice: {
    id: 'totalAmazonShippingPrice',
    metricId: 'totalAmazonShippingPrice',
    title: 'Amazon Shipping',
    icon: 'amazon',
    type: 'currency',
  },
  allOrders: {
    id: 'allOrders',
    metricId: 'allOrders',
    title: 'Orders',
    type: 'decimal',
  },
  new_customers: {
    id: 'new_customers',
    metricId: 'new_customers',
    title: 'New Customers',
    type: 'decimal',
  },
  allSales: {
    id: 'allSales',
    metricId: 'allSales',
    title: 'Sales',
    type: 'currency',
  },
  blendedAdsWithoutCustom: {
    id: 'blendedAdsWithoutCustom',
    metricId: 'blendedAdsWithoutCustom',
    title: 'Ad Spend',
    type: 'currency',
  },
  facebookClicks: {
    id: 'facebookClicks',
    metricId: 'facebookClicks',
    title: 'Clicks',
    type: 'decimal',
    icon: 'facebook',
  },
  facebookImpressions: {
    id: 'facebookImpressions',
    metricId: 'facebookImpressions',
    title: 'Impressions',
    type: 'decimal',
    icon: 'facebook',
  },
  facebookOutboundClicks: {
    id: 'facebookOutboundClicks',
    metricId: 'facebookOutboundClicks',
    title: 'Outbound Clicks',
    type: 'decimal',
    icon: 'facebook',
  },
  facebookSpend_costPerOutboundClicks: {
    id: 'facebookSpend_costPerOutboundClicks',
    metricId: 'facebookSpend_costPerOutboundClicks',
    title: 'Spend / CPOC',
    type: 'currency',
    icon: 'facebook',
  },
  pixelPageViews: {
    id: 'pixelPageViews',
    metricId: 'pixelPageViews',
    title: 'Page Views',
    type: 'decimal',
    icon: 'pixel',
  },
  pixelTimeOnSite: {
    id: 'pixelTimeOnSite',
    metricId: 'pixelTimeOnSite',
    title: 'Time on Site',
    type: 'duration',
    icon: 'pixel',
  },
  pixelBounces: {
    id: 'pixelBounces',
    metricId: 'pixelBounces',
    title: 'Bounces',
    type: 'decimal',
    icon: 'pixel',
  },
  tiktokClicks: {
    id: 'tiktokClicks',
    metricId: 'tiktokClicks',
    title: 'Clicks',
    type: 'decimal',
    icon: 'tiktok',
  },
  pinterestTotalClicks: {
    id: 'pinterestTotalClicks',
    metricId: 'pinterestTotalClicks',
    title: 'Clicks',
    type: 'decimal',
    icon: 'pinterest',
  },
};

export const AllMetrics = { ...SummaryMetrics, ...OutOfSummaryMetrics };

export const MetricsBreakdownsDictionary: MetricBreakdownsDictionary = {
  cogs: {
    breakdowns: [
      {
        metricId: 'totalProductCostsOrders',
        summaryMetric: AllMetrics.cogsOrders,
        operator: '+',
      },
      {
        metricId: 'totalCostsRefunds',
        summaryMetric: AllMetrics.cogsRefunds,
        operator: '+',
      },
    ],
  },
  taxes: {
    breakdowns: [
      {
        metricId: 'totalTaxes',
        summaryMetric: AllMetrics.totalTaxes,
        operator: '+',
      },
      {
        metricId: 'totalRefundTaxes',
        summaryMetric: AllMetrics.totalRefundTaxes,
        operator: '+',
      },
    ],
  },
  totalNetProfit: {
    breakdowns: [
      {
        metricId: 'grossRevenue',
        summaryMetric: AllMetrics.grossRevenue,
        operator: '+',
      },
      {
        metricId: 'expenses',
        summaryMetric: AllMetrics.expenses,
        operator: '-',
      },
    ],
  },
  expenses: {
    breakdowns: [
      {
        metricId: 'blendedAds',
        operator: '+',
        summaryMetric: AllMetrics.blendedAds,
      },
      {
        metricId: 'cost',
        summaryMetric: AllMetrics.cost,
        operator: '+',
      },
    ],
  },
  blendedAds: {
    breakdowns:
      SummaryMetrics.blendedAds.relatedMetrics?.map((metric) => ({
        metricId: SummaryMetrics[metric].metricId,
        summaryMetric: SummaryMetrics[metric],
        operator: '+',
      })) ?? [],
    amazonBreakdowns: [
      {
        metricId: 'amazonAds',
        operator: '+',
        summaryMetric: AllMetrics.amazonAds,
      },
    ],
  },
  cost: {
    breakdowns: [
      {
        metricId: 'totalProductCosts',
        summaryMetric: AllMetrics.cogs,
        operator: '+',
      },
      {
        metricId: 'totalPaymentGatewayCosts',
        summaryMetric: AllMetrics.paymentGateways,
        operator: '+',
      },
      { metricId: 'totalShippingCosts', summaryMetric: AllMetrics.shipping, operator: '+' },
      {
        metricId: 'totalCustomNonAdSpends',
        summaryMetric: AllMetrics.totalCustomNonAdSpends,
        operator: '+',
      },
      {
        metricId: 'totalHandlingFees',
        summaryMetric: AllMetrics.handlingFees,
        operator: '+',
      },
      { metricId: 'totalTaxes', operator: '+', summaryMetric: AllMetrics.taxes },
    ],
    amazonBreakdowns: [
      {
        metricId: 'amazonCogs',
        summaryMetric: AllMetrics.amazonCogs,
        operator: '+',
      },
      {
        metricId: 'amazonHandlingFees',
        summaryMetric: AllMetrics.amazonHandlingFees,
        operator: '+',
      },
      {
        metricId: 'totalAmazonFees',
        summaryMetric: AllMetrics.totalAmazonFees,
        operator: '+',
      },
      {
        metricId: 'totalAmazonSalesTaxes',
        summaryMetric: AllMetrics.amazonSalesTaxes,
        operator: '+',
      },
      {
        metricId: 'totalAmazonShippingPrice',
        summaryMetric: AllMetrics.totalAmazonShippingPrice,
        operator: '+',
      },
    ],
  },
  grossRevenue: {
    breakdowns: [
      {
        metricId: 'totalNewGrossSales',
        summaryMetric: AllMetrics.grossSales,
        operator: '+',
      },
      {
        metricId: 'totalShippingPrice',
        summaryMetric: AllMetrics.totalShippingPrice,
        operator: '+',
      },
      { metricId: 'totalTaxes', operator: '+', summaryMetric: AllMetrics.taxes },
      { metricId: 'totalDiscounts', summaryMetric: AllMetrics.totalDiscounts, operator: '-' },
      {
        metricId: 'totalRefunds',
        summaryMetric: AllMetrics.totalRefunds,
        operator: '-',
      },
    ],
    amazonBreakdowns: [
      {
        metricId: 'totalAmazonProductItemPrice',
        summaryMetric: AllMetrics.amazonProductItemPrice,
        operator: '+',
      },
      {
        metricId: 'totalAmazonShippingDiscount',
        summaryMetric: AllMetrics.totalAmazonShippingDiscount,
        operator: '+',
      },
    ],
  },
  shopifyCpa: {
    breakdowns: [
      {
        metricId: 'blendedAds',
        operator: '/',
        summaryMetric: AllMetrics.blendedAds,
      },
      {
        metricId: 'allOrders',
        operator: '+',
        summaryMetric: AllMetrics.allOrders,
      },
    ],
  },
  allOrders: {
    breakdowns: [
      {
        metricId: 'totalOrders',
        operator: '+',
        summaryMetric: AllMetrics.shopifyOrders,
      },
    ],
    amazonBreakdowns: [
      {
        metricId: 'totalAmazonOrders',
        summaryMetric: AllMetrics.amazonOrders,
        operator: '+',
      },
    ],
  },
  newCustomersCpa: {
    breakdowns: [
      {
        metricId: 'blendedAds',
        operator: '/',
        summaryMetric: AllMetrics.blendedAds,
      },
      {
        metricId: 'new_customers',
        summaryMetric: AllMetrics.new_customers,
        operator: '/',
      },
    ],
  },
  allSales: {
    breakdowns: [
      {
        metricId: 'totalSales',
        summaryMetric: AllMetrics.sales,
        operator: '+',
      },
    ],
    amazonBreakdowns: [
      {
        metricId: 'totalAmazonProductItemPrice',
        summaryMetric: AllMetrics.amazonProductItemPrice,
        operator: '+',
      },
    ],
  },
  roas: {
    breakdowns: [
      {
        metricId: 'allSales',
        summaryMetric: AllMetrics.allSales,
        operator: '+',
      },
      {
        metricId: 'blendedAds',
        operator: '/',
        summaryMetric: AllMetrics.blendedAds,
      },
    ],
  },
  newCustomersRoas: {
    breakdowns: [
      {
        metricId: 'newCustomerSales',
        summaryMetric: AllMetrics.newCustomerSales,
        operator: '+',
      },
      {
        metricId: 'blendedAds',
        summaryMetric: AllMetrics.blendedAds,
        operator: '/',
      },
    ],
  },
  blendedSales: {
    breakdowns: [
      {
        metricId: 'totalSales',
        summaryMetric: AllMetrics.sales,
        operator: '+',
      },
      {
        metricId: 'totalAmazonProductItemPrice',
        summaryMetric: AllMetrics.amazonProductItemPrice,
        operator: '+',
      },
    ],
  },
  //facebook
  facebookRoas: {
    breakdowns: [
      {
        metricId: 'facebookConversionValue',
        summaryMetric: AllMetrics.facebookConversionValue,
        operator: '+',
      },
      {
        metricId: 'fb_ads_spend',
        summaryMetric: AllMetrics.facebookAds,
        operator: '/',
      },
    ],
  },
  facebookConversionValue: {
    breakdowns: [
      {
        metricId: 'facebookWebConversionValue',
        operator: '+',
        summaryMetric: AllMetrics.facebookWebConversionValue,
      },
      {
        metricId: 'facebookMetaConversionValue',
        operator: '+',
        summaryMetric: AllMetrics.facebookMetaConversionValue,
      },
    ],
  },
  facebookCpc: {
    breakdowns: [
      {
        metricId: 'fb_ads_spend',
        summaryMetric: AllMetrics.facebookAds,
        operator: '+',
      },
      {
        metricId: 'facebookClicks',
        summaryMetric: AllMetrics.facebookClicks,
        operator: '/',
      },
    ],
  },
  //need /1000 to impressions
  facebookCpm: {
    breakdowns: [
      {
        metricId: 'fb_ads_spend',
        summaryMetric: AllMetrics.facebookAds,
        operator: '+',
      },
      {
        metricId: 'facebookImpressions',
        summaryMetric: AllMetrics.facebookImpressions,
        operator: '/',
      },
    ],
  },
  facebookCpoc: {
    breakdowns: [
      {
        metricId: 'fb_ads_spend',
        summaryMetric: AllMetrics.facebookAds,
        operator: '+',
      },
      {
        metricId: 'facebookOutboundClicks',
        summaryMetric: AllMetrics.facebookOutboundClicks,
        operator: '/',
      },
    ],
  },
  //need *100
  facebookCtr: {
    breakdowns: [
      {
        metricId: 'facebookClicks',
        summaryMetric: AllMetrics.facebookClicks,
        operator: '+',
      },
      {
        metricId: 'facebookImpressions',
        summaryMetric: AllMetrics.facebookImpressions,
        operator: '/',
      },
    ],
  },
  facebookSpend_costPerOutboundClicks: {
    breakdowns: [
      {
        metricId: 'fb_ads_spend',
        summaryMetric: AllMetrics.facebookAds,
        operator: '+',
      },
      {
        metricId: 'facebookCostPerOutboundClick',
        summaryMetric: AllMetrics.facebookCpoc,
        operator: '/',
      },
    ],
  },
  facebookRevenuePerClick: {
    breakdowns: [
      {
        metricId: 'facebookConversionValue',
        summaryMetric: AllMetrics.facebookConversionValue,
        operator: '+',
      },
      {
        metricId: 'facebookSpend_costPerOutboundClicks',
        summaryMetric: AllMetrics.facebookSpend_costPerOutboundClicks,
        operator: '/',
      },
    ],
  },
  facebookPurchases: {
    breakdowns: [
      {
        metricId: 'facebookMetaPurchases',
        summaryMetric: AllMetrics.facebookMetaPurchases,
        operator: '+',
      },
      {
        metricId: 'facebookWebPurchases',
        summaryMetric: AllMetrics.facebookWebPurchases,
        operator: '+',
      },
    ],
  },
  facebookCpa: {
    breakdowns: [
      {
        metricId: 'fb_ads_spend',
        summaryMetric: AllMetrics.facebookAds,
        operator: '+',
      },
      {
        metricId: 'facebookPurchases',
        summaryMetric: AllMetrics.facebookPurchases,
        operator: '/',
      },
    ],
  },
  //shopify
  shopifyAov: {
    breakdowns: [
      {
        metricId: 'shopifyAovNetSales',
        summaryMetric: AllMetrics.shopifyAovNetSales,
        operator: '+',
      },
      {
        metricId: 'totalOrdersWithAmount',
        summaryMetric: AllMetrics.shopifyOrdersWithAmount,
        operator: '/',
      },
    ],
  },
  shopifyAovIncludeZero: {
    breakdowns: [
      {
        metricId: 'shopifyAovNetSales',
        summaryMetric: AllMetrics.shopifyAovNetSales,
        operator: '+',
      },
      {
        metricId: 'totalOrders',
        summaryMetric: AllMetrics.shopifyOrders,
        operator: '/',
      },
    ],
  },
  shopifyAovNetSales: {
    breakdowns: [
      {
        metricId: 'totalSales',
        operator: '+',
        summaryMetric: AllMetrics.sales,
      },
      {
        metricId: 'totalShippingPrice',
        summaryMetric: AllMetrics.totalShippingPrice,
        operator: '-',
      },
      {
        metricId: 'totalTaxes',
        summaryMetric: AllMetrics.taxes,
        operator: '-',
      },
    ],
  },

  //need *100
  newCustomersPercent: {
    breakdowns: [
      {
        metricId: 'new_customers',
        summaryMetric: AllMetrics.new_customers,
        operator: '/',
      },
      {
        metricId: 'totalOrders',
        summaryMetric: AllMetrics.shopifyOrders,
        operator: '/',
      },
    ],
  },
  //web analytics (pixel)
  //need *100
  pixelConversionRate: {
    breakdowns: [
      {
        metricId: 'pixelPurchases',
        summaryMetric: AllMetrics.pixelPurchases,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  pixelAvgPagesPerSession: {
    breakdowns: [
      {
        metricId: 'pixelPageViews',
        summaryMetric: AllMetrics.pixelPageViews,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  pixelAvgSessionDuration: {
    breakdowns: [
      {
        metricId: 'pixelTimeOnSite',
        summaryMetric: AllMetrics.pixelTimeOnSite,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  //need *100
  pixelBounceRate: {
    breakdowns: [
      {
        metricId: 'pixelBounces',
        summaryMetric: AllMetrics.pixelBounces,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  //need *100
  pixelPercentNewVisitors: {
    breakdowns: [
      {
        metricId: 'pixelNewVisitors',
        summaryMetric: AllMetrics.pixelNewVisitors,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  //need *100
  pixelPercentAtc: {
    breakdowns: [
      {
        metricId: 'pixelUniqueSessionsAtc',
        summaryMetric: AllMetrics.pixelUniqueSessionsAtc,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  pixelCostPerAtc: {
    breakdowns: [
      {
        metricId: 'blendedAds',
        summaryMetric: AllMetrics.blendedAds,
        operator: '+',
      },
      {
        metricId: 'pixelUniqueSessionsAtc',
        summaryMetric: AllMetrics.pixelUniqueSessionsAtc,
        operator: '/',
      },
    ],
  },
  pixelCostPerSession: {
    breakdowns: [
      {
        metricId: 'blendedAds',
        summaryMetric: AllMetrics.blendedAds,
        operator: '+',
      },
      {
        metricId: 'pixelVisitors',
        summaryMetric: AllMetrics.pixelVisitors,
        operator: '/',
      },
    ],
  },
  //google
  googleRoas: {
    breakdowns: [
      {
        metricId: 'googleConversionValue',
        summaryMetric: AllMetrics.googleConversionValue,
        operator: '+',
      },
      {
        metricId: 'ga_adCost',
        summaryMetric: AllMetrics.googleAds,
        operator: '/',
      },
    ],
  },
  totalGoogleAdsCtr: {
    breakdowns: [
      {
        metricId: 'totalGoogleAdsClicks',
        summaryMetric: AllMetrics.totalGoogleAdsClicks,
        operator: '+',
      },
      {
        metricId: 'totalGoogleAdsImpressions',
        summaryMetric: AllMetrics.totalGoogleAdsImpressions,
        operator: '/',
      },
    ],
  },
  //need *100
  googleAllCpa: {
    breakdowns: [
      {
        metricId: 'ga_adCost',
        summaryMetric: AllMetrics.googleAds,
        operator: '+',
      },
      {
        metricId: 'ga_all_transactions_adGroup',
        summaryMetric: AllMetrics.ga_all_transactions_adGroup,
        operator: '/',
      },
    ],
  },
  //need /1000 to impressions
  totalGoogleAdsCpm: {
    breakdowns: [
      {
        metricId: 'ga_adCost',
        summaryMetric: AllMetrics.googleAds,
        operator: '+',
      },
      {
        metricId: 'totalGoogleAdsImpressions',
        summaryMetric: AllMetrics.totalGoogleAdsImpressions,
        operator: '/',
      },
    ],
  },
  totalAllRoas: {
    breakdowns: [
      {
        metricId: 'ga_all_transactionsRevenue_adGroup',
        summaryMetric: AllMetrics.ga_all_transactionsRevenue_adGroup,
        operator: '+',
      },
      {
        metricId: 'ga_adCost',
        summaryMetric: AllMetrics.googleAds,
        operator: '/',
      },
    ],
  },
  googleCpa: {
    breakdowns: [
      {
        metricId: 'ga_adCost',
        summaryMetric: AllMetrics.googleAds,
        operator: '+',
      },
      {
        metricId: 'ga_transactions_adGroup',
        summaryMetric: AllMetrics.ga_transactions_adGroup,
        operator: '/',
      },
    ],
  },
  //tiktok
  tiktokRoas: {
    breakdowns: [
      {
        metricId: 'tiktokConversionValue',
        summaryMetric: AllMetrics.tiktokConversionValue,
        operator: '+',
      },
      {
        metricId: 'tiktokSpend',
        summaryMetric: AllMetrics.tiktokAds,
        operator: '/',
      },
    ],
  },
  //need /1000 to impressions
  tiktokCpm: {
    breakdowns: [
      {
        metricId: 'tiktokSpend',
        summaryMetric: AllMetrics.tiktokAds,
        operator: '+',
      },
      {
        metricId: 'tiktokImpressions',
        summaryMetric: AllMetrics.tiktokImpressions,
        operator: '/',
      },
    ],
  },
  tiktokCpc: {
    breakdowns: [
      {
        metricId: 'tiktokSpend',
        summaryMetric: AllMetrics.tiktokAds,
        operator: '+',
      },
      {
        metricId: 'tiktokClicks',
        summaryMetric: AllMetrics.tiktokClicks,
        operator: '/',
      },
    ],
  },
  //need *100
  tiktokCtr: {
    breakdowns: [
      {
        metricId: 'tiktokClicks',
        summaryMetric: AllMetrics.tiktokClicks,
        operator: '+',
      },
      {
        metricId: 'tiktokImpressions',
        summaryMetric: AllMetrics.tiktokImpressions,
        operator: '/',
      },
    ],
  },
  tiktokCpa: {
    breakdowns: [
      {
        metricId: 'tiktokSpend',
        summaryMetric: AllMetrics.tiktokAds,
        operator: '+',
      },
      {
        metricId: 'tiktokPurchases',
        summaryMetric: AllMetrics.tiktokPurchases,
        operator: '/',
      },
    ],
  },
  //klaviyo
  //need *100
  klaviyoPlacedOrderTotalPriceFlowsPercent: {
    breakdowns: [
      {
        metricId: 'totalKlaviyoPlacedOrderTotalPriceFlows',
        summaryMetric: AllMetrics.klaviyoPlacedOrderTotalPriceFlows,
        operator: '+',
      },
      {
        metricId: 'totalSales',
        summaryMetric: AllMetrics.sales,
        operator: '/',
      },
    ],
  },
  //need *100
  klaviyoPlacedOrderTotalPriceCampaignsPercent: {
    breakdowns: [
      {
        metricId: 'totalKlaviyoPlacedOrderTotalPriceCampaigns',
        summaryMetric: AllMetrics.klaviyoPlacedOrderTotalPriceCampaigns,
        operator: '+',
      },
      {
        metricId: 'totalSales',
        summaryMetric: AllMetrics.sales,
        operator: '/',
      },
    ],
  },
  klaviyoPlacedOrderSales: {
    breakdowns: [
      {
        metricId: 'totalKlaviyoPlacedOrderTotalPriceFlows',
        summaryMetric: AllMetrics.klaviyoPlacedOrderTotalPriceFlows,
        operator: '+',
      },
      {
        metricId: 'totalKlaviyoPlacedOrderTotalPriceCampaigns',
        summaryMetric: AllMetrics.klaviyoPlacedOrderTotalPriceCampaigns,
        operator: '+',
      },
    ],
  },
  //need *100
  klaviyoSalesPercent: {
    breakdowns: [
      {
        metricId: 'klaviyoPlacedOrderSales',
        summaryMetric: AllMetrics.klaviyoPlacedOrderSales,
        operator: '+',
      },
      {
        metricId: 'totalSales',
        summaryMetric: AllMetrics.sales,
        operator: '/',
      },
    ],
  },
  klaviyoNetNewSubscribers: {
    breakdowns: [
      {
        metricId: 'totalKlaviyoSubscribedToList',
        summaryMetric: AllMetrics.klaviyoNewSubscribed,
        operator: '+',
      },
      {
        metricId: 'totalKlaviyoUnsubscribed',
        summaryMetric: AllMetrics.klaviyoUnsubscribed,
        operator: '-',
      },
    ],
  },
  //microsoft
  bingRoas: {
    breakdowns: [
      {
        metricId: 'bingConversionValue',
        summaryMetric: AllMetrics.bingConversionValue,
        operator: '+',
      },
      {
        metricId: 'bingAdSpend',
        summaryMetric: AllMetrics.bingAdSpend,
        operator: '/',
      },
    ],
  },
  //need *100
  bingCtr: {
    breakdowns: [
      {
        metricId: 'bingClicks',
        summaryMetric: AllMetrics.bingClicks,
        operator: '+',
      },
      {
        metricId: 'bingImpressions',
        summaryMetric: AllMetrics.bingImpressions,
        operator: '/',
      },
    ],
  },
  bingCpa: {
    breakdowns: [
      {
        metricId: 'bingAdSpend',
        summaryMetric: AllMetrics.bingAdSpend,
        operator: '+',
      },
      {
        metricId: 'bingConversions',
        summaryMetric: AllMetrics.bingConversions,
        operator: '/',
      },
    ],
  },
  //need /1000 to impressions
  bingCpm: {
    breakdowns: [
      {
        metricId: 'bingAdSpend',
        summaryMetric: AllMetrics.bingAdSpend,
        operator: '+',
      },
      {
        metricId: 'bingImpressions',
        summaryMetric: AllMetrics.bingImpressions,
        operator: '/',
      },
    ],
  },
  //influencer
  influencerRoas: {
    breakdowns: [
      {
        metricId: 'influencerConversionValue',
        summaryMetric: AllMetrics.influencerConversionValue,
        operator: '+',
      },
      {
        metricId: 'influencerSpend',
        summaryMetric: AllMetrics.influencerSpend,
        operator: '/',
      },
    ],
  },
  //amazon ads
  amazonROAS: {
    breakdowns: [
      {
        metricId: 'amazonAdsConversionValue',
        summaryMetric: AllMetrics.amazonAdsConversionValue,
        operator: '+',
      },
      {
        metricId: 'amazonAds',
        summaryMetric: AllMetrics.amazonAds,
        operator: '/',
      },
    ],
  },
  amazonCPC: {
    breakdowns: [
      {
        metricId: 'amazonAds',
        summaryMetric: AllMetrics.amazonAds,
        operator: '+',
      },
      {
        metricId: 'amazonClicks',
        summaryMetric: AllMetrics.amazonClicks,
        operator: '/',
      },
    ],
  },
  //amazon stats
  amazonNetSales: {
    breakdowns: [
      {
        metricId: 'totalAmazonProductItemPrice',
        summaryMetric: AllMetrics.amazonProductItemPrice,
        operator: '+',
      },
      {
        metricId: 'totalAmazonShippingPrice',
        summaryMetric: AllMetrics.totalAmazonShippingPrice,
        operator: '-',
      },
      {
        metricId: 'totalAmazonShippingDiscount',
        summaryMetric: AllMetrics.totalAmazonShippingDiscount,
        operator: '+',
      },
      {
        metricId: 'totalAmazonSalesTaxes',
        summaryMetric: AllMetrics.amazonSalesTaxes,
        operator: '-',
      },
    ],
  },
  amazonExpenses: {
    breakdowns: [
      {
        metricId: 'totalAmazonFees',
        summaryMetric: AllMetrics.totalAmazonFees,
        operator: '+',
      },
      {
        metricId: 'amazonAds',
        summaryMetric: AllMetrics.amazonAds,
        operator: '+',
      },
      {
        metricId: 'amazonCogs',
        summaryMetric: AllMetrics.amazonCogs,
        operator: '+',
      },
      {
        metricId: 'amazonHandlingFees',
        summaryMetric: AllMetrics.amazonHandlingFees,
        operator: '+',
      },
    ],
  },
  amazonNetProfit: {
    breakdowns: [
      {
        metricId: 'amazonNetSales',
        summaryMetric: AllMetrics.amazonNetSales,
        operator: '+',
      },
      {
        metricId: 'amazonExpenses',
        summaryMetric: AllMetrics.amazonExpenses,
        operator: '-',
      },
    ],
  },
  //need *100
  amazonMER: {
    breakdowns: [
      {
        metricId: 'amazonAds',
        summaryMetric: AllMetrics.amazonAds,
        operator: '+',
      },
      {
        metricId: 'amazonNetSales',
        summaryMetric: AllMetrics.amazonNetSales,
        operator: '/',
      },
    ],
  },
  amazonCPA: {
    breakdowns: [
      {
        metricId: 'amazonAds',
        summaryMetric: AllMetrics.amazonAds,
        operator: '+',
      },
      {
        metricId: 'totalAmazonOrders',
        summaryMetric: AllMetrics.amazonOrders,
        operator: '/',
      },
    ],
  },
  //pinterest
  pinterestRoas: {
    breakdowns: [
      {
        metricId: 'pinterestConversionValue',
        summaryMetric: AllMetrics.pinterestConversionValue,
        operator: '+',
      },
      {
        metricId: 'pinterestSpend',
        summaryMetric: AllMetrics.pinterestAds,
        operator: '/',
      },
    ],
  },
  pinterestCpc: {
    breakdowns: [
      {
        metricId: 'pinterestSpend',
        summaryMetric: AllMetrics.pinterestAds,
        operator: '+',
      },
      {
        metricId: 'pinterestTotalClicks',
        summaryMetric: AllMetrics.pinterestTotalClicks,
        operator: '/',
      },
    ],
  },
  //need /1000 to impressions
  pinterestCpm: {
    breakdowns: [
      {
        metricId: 'pinterestSpend',
        summaryMetric: AllMetrics.pinterestAds,
        operator: '+',
      },
      {
        metricId: 'pinterestImpressions',
        summaryMetric: AllMetrics.pinterestImpressions,
        operator: '/',
      },
    ],
  },
  //need *100
  pinterestCtr: {
    breakdowns: [
      {
        metricId: 'pinterestTotalClicks',
        summaryMetric: AllMetrics.pinterestTotalClicks,
        operator: '+',
      },
      {
        metricId: 'pinterestImpressions',
        summaryMetric: AllMetrics.pinterestImpressions,
        operator: '/',
      },
    ],
  },
  //snapchat
  snapchatRoas: {
    breakdowns: [
      {
        metricId: 'totalSnapchatConversionPurchasesValue',
        summaryMetric: AllMetrics.snapchatConversionValue,
        operator: '+',
      },
      {
        metricId: 'totalSnapchatSpend',
        summaryMetric: AllMetrics.snapchatAds,
        operator: '/',
      },
    ],
  },
  //need *100
  snapchatCpm: {
    breakdowns: [
      {
        metricId: 'totalSnapchatSpend',
        summaryMetric: AllMetrics.snapchatAds,
        operator: '+',
      },
      {
        metricId: 'snapchat_impressions',
        summaryMetric: AllMetrics.snapchatImpressions,
        operator: '/',
      },
    ],
  },
  snapchatCpa: {
    breakdowns: [
      {
        metricId: 'totalSnapchatSpend',
        summaryMetric: AllMetrics.snapchatAds,
        operator: '+',
      },
      {
        metricId: 'totalSnapchatConversionPurchases',
        summaryMetric: AllMetrics.snapchatConversionPurchases,
        operator: '/',
      },
    ],
  },
  //recharge
  //need to check, need *100
  rechargeSubscriptionSalesPercentage: {
    breakdowns: [
      {
        metricId: 'rechargeTotalSubscriptionSales',
        summaryMetric: AllMetrics.rechargeTotalSubscriptionSales,
        operator: '+',
      },
      {
        metricId: 'totalSales',
        summaryMetric: AllMetrics.sales,
        operator: '/',
      },
    ],
  },
  //twitter
  twitterRoas: {
    breakdowns: [
      {
        metricId: 'twitterConversionValue',
        summaryMetric: AllMetrics.twitterConversionValue,
        operator: '+',
      },
      {
        metricId: 'twitterSpend',
        summaryMetric: AllMetrics.twitterAds,
        operator: '/',
      },
    ],
  },
  //need /1000 to impressions
  twitterCpm: {
    breakdowns: [
      {
        metricId: 'twitterSpend',
        summaryMetric: AllMetrics.twitterAds,
        operator: '+',
      },
      {
        metricId: 'twitterImpressions',
        summaryMetric: AllMetrics.twitterImpressions,
        operator: '/',
      },
    ],
  },
  twitterCpa: {
    breakdowns: [
      {
        metricId: 'twitterSpend',
        summaryMetric: AllMetrics.twitterAds,
        operator: '+',
      },
      {
        metricId: 'twitterPurchases',
        summaryMetric: AllMetrics.twitterPurchases,
        operator: '/',
      },
    ],
  },
};

// cashTurnover: {
//   metricId: 'cashTurnover',
//   summaryMetricId: 'cashTurnover',
//   operator: '-',
//   breakdowns: [
//     {
//       metricId: 'totalSales',
//       operator: '+',
//     },
//     { metricId: 'totalShippingCosts', summaryMetricId: 'shipping', operator: '-' },
//     {
//       metricId: 'totalPaymentGatewayCosts',
//       summaryMetricId: 'paymentGateways',
//       operator: '-',
//     },
//     {
//       metricId: 'totalCustomSpends',
//       summaryMetricId: 'customSpends',
//       operator: '-',
//     },
//     {
//       metricId: 'totalRefunds',
//       operator: '-',
//       summaryMetricId: 'totalRefunds',
//     },
//     {
//       metricId: 'blendedAdsWithoutCustom',
//       operator: '-',
//     },
//     { metricId: 'totalTaxes', operator: '-', summaryMetricId: 'taxes' },
//   ],
// },
// mer: {
//   metricId: 'mer',
//   operator: '/',
//   summaryMetricId: 'mer',
//   breakdowns: [
//     {
//       metricId: 'blendedAds',
//       title: 'Ad Spend',
//       operator: '+',
//       summaryMetricId: 'blendedAds',
//       breakdowns: SummaryMetrics.blendedAds.relatedMetrics?.map((metric) => ({
//         metricId: SummaryMetrics[metric].metricId,
//         title: SummaryMetrics[metric].title,
//         summaryMetricId: metric,
//         operator: '+',
//       })),
//       amazonBreakdowns: [
//         {
//           metricId: 'amazonAds',
//           operator: '+',
//           title: 'Amazon Ads',
//           summaryMetricId: 'amazonAds',
//         },
//       ],
//     },
//     {
//       metricId: 'allSales',
//       title: 'Sales',
//       type: 'currency',
//       operator: '+',
//       breakdowns: [
//         {
//           metricId: 'totalSales',
//           title: 'Shopify Sales',
//           operator: '+',
//           type: 'currency',
//           icon: 'shopify',
//         },
//       ],
//       amazonBreakdowns: [
//         {
//           metricId: 'totalAmazonProductItemPrice',
//           summaryMetricId: 'amazonProductItemPrice',
//           operator: '+',
//         },
//       ],
//     },
//   ],
// },
