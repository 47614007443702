import { Anchor, Button, Flex, Icon, IconName, Modal, Text } from '@tw/ui-components';
import { MessageTypes, WillyDashboardElement, WillyMetric } from '../types/willyTypes';
import { WillySearch } from '../dashboardManagment/WillyDashSearch';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWillyDndMetrics } from '../hooks/useWillyDndMetrics';
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragHandle } from '../../../icons/drag-handle.svg';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';
import { Portal } from 'components/Portal';

type ColumnsSelectorProps = {
  open: boolean;
  type: MessageTypes;
  metrics: WillyMetric[];
  queryId?: string;
  dashboard?: WillyDashboardElement;
  metricsChanged: (queryId: string, metrics: WillyMetric[]) => Promise<void>;
  setOpen: (val: boolean) => void;
};

export const ColumnsSelector: React.FC<ColumnsSelectorProps> = ({
  open,
  type,
  metrics,
  queryId,
  dashboard,
  metricsChanged,
  setOpen,
}) => {
  const [filteredMetrics, setFilteredMetrics] = useState<WillyMetric[]>(metrics);
  const [activeMetrics, setActiveMetrics] = useState<WillyMetric[]>(metrics);

  const awaitedSetMetrics = useCallback(
    async (id: string, metrics: WillyMetric[]) => {
      setActiveMetrics(metrics);
    },
    [setActiveMetrics],
  );

  const { sensors, handleDragStart, handleDragEnd, activeId } = useWillyDndMetrics({
    queryId,
    metrics: activeMetrics,
    metricsChanged: awaitedSetMetrics,
  });

  const groupedMetrics = useMemo(() => {
    return Object.groupBy(filteredMetrics, (m) => {
      if (m.isDimension) {
        return 'Dimensions';
      }
      return 'Measures';
    });
  }, [filteredMetrics]);

  useEffect(() => {
    setActiveMetrics(metrics);
  }, [metrics]);

  useEffect(() => {
    setFilteredMetrics(activeMetrics);
  }, [activeMetrics]);

  return (
    <Modal
      title={type === 'tile' ? 'Metrics selector' : 'Column selector'}
      opened={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex flex-col gap-4">
        <WillySearch allSections={metrics} setFilteredSections={setFilteredMetrics} />
        <DndContext
          id={`willy-legend-${queryId}`}
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
        >
          <SortableContext items={metrics?.map((x) => x.key) ?? []} strategy={rectSortingStrategy}>
            <div className="flex flex-col gap-4">
              <Text>Include</Text>
              {Object.entries(groupedMetrics)
                .sort((a, b) => (a[0] === 'Measures' ? 1 : -1))
                .flatMap(([key, value]) => {
                  return (
                    <div key={key} className="flex flex-col gap-4">
                      <Text>{key}</Text>
                      {value.filter((x) => x.active !== false).length === 0 && (
                        <Text size="sm" color="gray.5">
                          No {key.toLowerCase()}
                        </Text>
                      )}
                      {value
                        .filter((x) => x.active !== false)
                        .map((metric) => {
                          return (
                            <WillyMetricItem
                              key={metric.key}
                              metric={metric}
                              metrics={activeMetrics}
                              metricsOnChange={(metrics) => {
                                setActiveMetrics(metrics);

                                // logic inverted for active
                                genericEventLogger(analyticsEvents.DASHBOARDS, {
                                  action: metric.active
                                    ? dashboardsActions.HIDE_COLUMN
                                    : dashboardsActions.SHOW_COLUMN,
                                  metric_name: metric.name,
                                  metric_key: metric.key,
                                  dashboard_id: dashboard?.id,
                                  dashboard_name: dashboard?.name,
                                  template_id: dashboard?.globalDashboardId,
                                  template_name: dashboard?.globalDashboardId && dashboard?.name,
                                });
                              }}
                              queryId={queryId}
                              filteredMetrics={filteredMetrics}
                            />
                          );
                        })}
                    </div>
                  );
                })}
              {/* ?.filter((x) => {
                //   if (type === 'tile') {
                //     return !x.isDimension;
                //   }
                //   return true;
                // })
                // .filter((x) => x.active !== false)
                // .map((metric) => {
                //   return (
                //     <WillyMetricItem
                //       key={metric.key}
                //       metric={metric}
                //       metrics={activeMetrics}
                //       metricsOnChange={(metrics) => {
                //         setActiveMetrics(metrics);

                //         // logic inverted for active
                //         genericEventLogger(analyticsEvents.DASHBOARDS, {
                //           action: metric.active
                //             ? dashboardsActions.HIDE_COLUMN
                //             : dashboardsActions.SHOW_COLUMN,
                //           metric_name: metric.name,
                //           metric_key: metric.key,
                //           dashboard_id: dashboard?.id,
                //           dashboard_name: dashboard?.name,
                //           template_id: dashboard?.globalDashboardId,
                //           template_name: dashboard?.globalDashboardId && dashboard?.name,
                //         });
                //       }}
                //       queryId={queryId}
                //       filteredMetrics={filteredMetrics}
                //     />
                //   );
                // })}
                */}
              <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>
            </div>
          </SortableContext>
          <Portal>
            <DragOverlay>
              {activeId ? (
                <WillyMetricItem
                  metric={metrics.find((x) => x.key === activeId)!}
                  metrics={activeMetrics}
                  metricsOnChange={() => {}}
                  queryId={queryId}
                  filteredMetrics={filteredMetrics}
                />
              ) : null}
            </DragOverlay>
          </Portal>
        </DndContext>
        <Text>
          <span>More Sections</span>
        </Text>
        {Object.entries(groupedMetrics)
          .sort((a, b) => (a[0] === 'Measures' ? 1 : -1))
          .flatMap(([key, value]) => {
            return (
              <div key={key} className="flex flex-col gap-4">
                <Text>{key}</Text>
                {value.filter((x) => x.active === false).length === 0 && (
                  <Text size="sm" color="gray.5">
                    No more {key.toLowerCase()} to add
                  </Text>
                )}
                {value
                  .filter((x) => x.active === false)
                  .map((metric) => {
                    return (
                      <WillyMetricItem
                        key={metric.key}
                        metric={metric}
                        metrics={activeMetrics}
                        metricsOnChange={(metrics) => {
                          setActiveMetrics(metrics);

                          // logic inverted for active
                          genericEventLogger(analyticsEvents.DASHBOARDS, {
                            action: metric.active
                              ? dashboardsActions.HIDE_COLUMN
                              : dashboardsActions.SHOW_COLUMN,
                            metric_name: metric.name,
                            metric_key: metric.key,
                            dashboard_id: dashboard?.id,
                            dashboard_name: dashboard?.name,
                            template_id: dashboard?.globalDashboardId,
                            template_name: dashboard?.globalDashboardId && dashboard?.name,
                          });
                        }}
                        queryId={queryId}
                        filteredMetrics={filteredMetrics}
                      />
                    );
                  })}
              </div>
            );
          })}
        {/* .filter((x) => x.active === false)
          .map((metric) => {
            return (
              <WillyMetricItem
                key={metric.key}
                metric={metric}
                metrics={activeMetrics}
                metricsOnChange={(metrics) => {
                  setActiveMetrics(metrics);

                  // logic inverted for active
                  genericEventLogger(analyticsEvents.DASHBOARDS, {
                    action: metric.active
                      ? dashboardsActions.HIDE_COLUMN
                      : dashboardsActions.SHOW_COLUMN,
                    metric_name: metric.name,
                    metric_key: metric.key,
                    dashboard_id: dashboard?.id,
                    dashboard_name: dashboard?.name,
                    template_id: dashboard?.globalDashboardId,
                    template_name: dashboard?.globalDashboardId && dashboard?.name,
                  });
                }}
                queryId={queryId}
                filteredMetrics={filteredMetrics}
              />
            );
          })} */}
      </div>

      <Modal.Footer>
        <Flex justify="space-between" gap="md">
          <Button
            variant="white"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              setOpen(false);
              if (!queryId) {
                return;
              }
              metricsChanged(queryId, activeMetrics);
            }}
          >
            Apply
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

const WillyMetricItem: React.FC<{
  metric: WillyMetric;
  metrics: WillyMetric[];
  queryId?: string;
  metricsOnChange: (v: WillyMetric[]) => void;
  filteredMetrics?: WillyMetric[];
}> = ({ metric, metrics, queryId, metricsOnChange, filteredMetrics }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: metric.key,
  });
  const style: React.CSSProperties = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? '100' : 'auto',
      opacity: isDragging ? 0.3 : 1,
      touchAction: 'manipulation',
    }),
    [isDragging, transform, transition],
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex items-center gap-2 space-between align-items-center"
    >
      <div className={'flex gap-4 items-center align-items-center justify-center'}>
        <div>
          <Anchor
            size="sm"
            onClick={async () => {
              const newMetrics = metrics.map((m) => {
                if (m.key === metric.key) {
                  return {
                    ...m,
                    active: !m.active,
                    hiddenBecauseHasNoData: m.hiddenBecauseHasNoData
                      ? false
                      : !!m.hiddenBecauseHasNoData,
                  };
                }
                return m;
              });
              metricsOnChange(newMetrics);
            }}
          >
            <Icon
              name={metric.active === false ? 'plus' : 'minus'}
              size={metric.active === false ? 10 : 15}
              color={'gray.5'}
            />
          </Anchor>
        </div>
        <Icon name={(metric.icon as IconName) || 'triple-whale-logo'} />
        <Text size="md">{metric.name}</Text>
      </div>
      {metric.active !== false && filteredMetrics?.length === metrics?.length && (
        <div
          {...listeners}
          {...attributes}
          className="text-logo flex w-6 h-6 mt-[-2px] cursor-grab transition-opacity ml-auto"
        >
          <DragHandle />
        </div>
      )}
    </div>
  );
};
