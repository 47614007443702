import { Tooltip } from '@shopify/polaris';
import { Skeleton } from '@tw/ui-components';
import { ReactComponent as Arrow } from 'icons/arrow.svg';
import { formatNumber } from 'utils/formatNumber';

type WillyComparisonValueProps = {
  deltaDirection?: 1 | 0 | -1;
  deltaPercentage?: number;
  previousValue?: string;
  loading?: boolean;
};

export const WillyComparisonValue: React.FC<WillyComparisonValueProps> = ({
  deltaDirection,
  deltaPercentage,
  previousValue,
  loading,
}) => {
  if (previousValue === undefined) {
    return null;
  }

  if (loading) {
    return <Skeleton width={50} height={10} />;
  }
  return (
    <div
      className={`text-lg sm:text-base font-normal inline-flex items-center gap-1 ${
        deltaDirection === 1
          ? 'text-negative'
          : deltaDirection === -1
            ? 'text-green'
            : 'text-secondary-text dark:text-gray-100'
      }`}
    >
      {deltaPercentage !== undefined && (
        <Arrow
          style={{ transform: deltaPercentage > 0 ? '' : 'rotate(180deg)' }}
          width={14}
          height={14}
        />
      )}
      {deltaPercentage !== undefined && (
        <Tooltip content={previousValue} dismissOnMouseOut>
          <span className="font-medium">
            {formatNumber(deltaPercentage, {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).replace('-', '')}
          </span>
        </Tooltip>
      )}
    </div>
  );
};
