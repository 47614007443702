import { Modal } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { clearShippingProfileData } from 'ducks/shipping';
import { RootState } from 'reducers/RootType';
import { useSelector } from 'react-redux';
import { Zones } from './Zones';
import { Rates } from './Rates';
import { useState } from 'react';

export const ShippingProfile: React.FC<any> = ({ isOpen, setIsOpen }) => {
  const shippingProfileData = useSelector((state: RootState) => state.shippingProfileData);
  const [page, setPage] = useState<'zones' | 'rates'>('zones');
  const dispatch = useAppDispatch();

  return (
    <Modal
      onClose={() => {
        setIsOpen(false);
        dispatch(clearShippingProfileData());
        setPage('zones');
      }}
      opened={isOpen}
      title={`${shippingProfileData?.edit ? 'Edit' : 'Create'} Shipping Profile`}
      size="lg"
    >
      {page === 'zones' && <Zones setPage={setPage} />}
      {page === 'rates' && <Rates setPage={setPage} setIsOpen={setIsOpen} />}
    </Modal>
  );
};
