import { Text } from '@tw/ui-components';
import { WillySimpleText } from '../WillySimpleText';
import { InsightsWillyWidget } from '../InsightsWillyWidget';
import { Dialect } from '../types/willyTypes';
import { Headline } from '../Headline';

export function replaceQueryIdsWithWidgets(insights: string, dialect: Dialect) {
  if (!insights) {
    return null;
  }
  // Match all occurrences of [shortcode]<JSON_HERE>[/shortcode] where JSON_HERE is any JSON string
  // I'll take the JSON_HERE part and parse it as JSON
  // then I'll deal with the json inside the loop
  const regex = /\[shortcode\](.*?)\[\/shortcode\]/gs;
  // Split the insights string into parts
  const parts: React.ReactNode[] = [];

  let lastIndex = 0;
  let float: 'float-left' | 'float-right' = 'float-left';

  let match: RegExpExecArray | null;
  let isFirstHeadline = true;

  while ((match = regex.exec(insights)) !== null) {
    // Add text before the current match
    if (match.index > lastIndex) {
      parts.push(
        <div key={`text-${lastIndex}-${match.index}`} className="w-full">
          <WillySimpleText text={insights.slice(lastIndex, match.index)} />
        </div>,
      );
    }

    // Add the Widget component with the extracted question ID
    try {
      const json = JSON.parse(match[1]);
      if (json.type === 'headline') {
        parts.push(
          <div
            key={json.headline}
            className={`${float} relative z-[1] h-98 overflow-auto w-full @2xl/insights:w-[50%] my-6.5 ${
              float === 'float-left' ? 'mr-14' : 'ml-14'
            } ${isFirstHeadline ? 'flex !w-full justify-center' : 'block'}`}
          >
            <Headline headline={json.headline} isFirst={isFirstHeadline} />
          </div>,
        );
        isFirstHeadline = false;
      } else if (json.type === 'question') {
        const queryId = json.id;
        const question = json.question;
        const dashboardId = json.dashboardId;
        parts.push(
          <div
            key={queryId}
            className={`${float} block relative z-[1] h-98 overflow-auto w-full @2xl/insights:w-[50%] my-6.5 ${
              float === 'float-left' ? 'mr-14' : 'ml-14'
            }`}
          >
            <InsightsWillyWidget
              queryId={queryId}
              dialect={dialect}
              question={question}
              dashboardId={dashboardId}
            />
          </div>,
        );
      }
    } catch (error) {
      console.error('Error parsing JSON', error);
    }

    float = Math.random() < 0.5 ? 'float-left' : 'float-right';

    // Update the lastIndex
    lastIndex = regex.lastIndex;
  }

  // Add remaining text after the last match
  if (lastIndex < insights.length) {
    parts.push(
      <div key={`text-${lastIndex}-${insights.length}`} className="w-full">
        <WillySimpleText text={insights.slice(lastIndex)} />
      </div>,
    );
  }

  return <div className="@container/insights">{parts}</div>;
}
