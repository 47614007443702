import { DOLLAR_TYPE } from '@tw/types/module/SummaryMetrics';
import React from 'react';
import { ExpressionElement, StatItem } from './types';
import { CustomMetricsType } from 'ducks/customMetrics';

export const CustomMetricsContext = React.createContext<{
  expressionElements: ExpressionElement[];
  stats: StatItem[];
  metricTitle: string;
  metricDescription: string;
  metricType: string;
  section: any;
  isMetricTitleError: boolean;
  type?: string;
  tag?: any;
  setExpressionElements: (items: ExpressionElement[]) => void;
  removeExpressionElement: (item: any) => void;
  updateStatItem: (id: any, metric: any) => void;
  updateIntegerItem: (id: any, newValue: number) => void;
  setMetricTitle: (title: string) => void;
  setMetricDescription: (description: string) => void;
  setMetricType: (type: string) => void;
  setSection: (section: any) => void;
  setIsMetricTitleError: (isError: boolean) => void;
  setTag: (tag: string | string[]) => void;
}>({
  expressionElements: [],
  stats: [],
  metricTitle: '',
  metricDescription: '',
  metricType: DOLLAR_TYPE,
  section: undefined,
  isMetricTitleError: false,
  type: CustomMetricsType.Summary,
  tag: '',
  setExpressionElements: (items) => {},
  removeExpressionElement: (id) => {},
  updateStatItem: (id, metric) => {},
  updateIntegerItem: (id, newValue) => {},
  setMetricTitle: (title) => {},
  setMetricDescription: (description) => {},
  setMetricType: (type) => {},
  setSection: (section) => {},
  setIsMetricTitleError: (isError) => {},
  setTag: (tag) => {},
});
