import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useIsFromSignupInvitation = (): boolean => {
  const { search, pathname } = useLocation();

  return useMemo(() => {
    const invitationId = new URLSearchParams(search).get('i');
    return pathname.includes('signup-invitation') && !!invitationId;
  }, [search, pathname]);
};
