import { BqColumn, OptionsObj } from 'pages/FreeQuery/dataStuff/columns/types';
import { WillyParameter } from './types/willyTypes';
import { capitalize } from 'lodash';
import { Icon, Text } from '@tw/ui-components';
import { useCallback, useMemo } from 'react';
import { FilterOperator, WillyOperatorItem } from '@tw/willy-data-dictionary/module/columns/types';
import { WillyDynamicFieldOperator } from './WillyDynamicFieldOperator';
import { WillyDynamicFieldValue } from './WillyDynamicFieldValue';

type WillyDynamicFieldFilterProps = {
  parameters: WillyParameter[];
  parameter: WillyParameter;
  options: OptionsObj[];
  column?: BqColumn;
  loadingOptions?: boolean;
  parametersChanged: (parameters: WillyParameter[]) => void;
  close: () => void;
  setEditedParameterModalOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

export const WillyDynamicFieldFilter: React.FC<WillyDynamicFieldFilterProps> = ({
  parameters,
  parameter,
  options,
  column,
  loadingOptions,
  parametersChanged,
  setEditedParameterModalOpen,
  close,
}) => {
  const parametersChange = useCallback(
    (parameters: WillyParameter[], shouldClose?: boolean) => {
      parametersChanged(parameters);
      if (shouldClose) {
        close();
      }
    },
    [parametersChanged, close],
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <div
          className="cursor-pointer flex items-center gap-2"
          onClick={(e) => {
            e.stopPropagation();
            setEditedParameterModalOpen(true);
            close();
          }}
        >
          <Icon size={12} name="edit" color="gray.5" />
          <Text size="xs" color="gray.5">
            Edit Filter
          </Text>
        </div>
      </div>
      <div className="flex items-center gap-2 flex-shrink-0">
        <div className="dark:text-gray-300 whitespace-nowrap max-w-44 relative">
          {/* <div className="absolute top-0 left-0 w-full h-full z-10" /> */}
          <span className="truncate max-w-full px-4 text-[14px] block">
            {column?.title || parameter.column.replace(/_/g, ' ').replace(/\w+/g, capitalize)}
          </span>
        </div>
        <WillyDynamicFieldOperator
          column={column}
          operator={parameter.operator}
          disabled={parameter?.isQueryParameter}
          allowMultiple={options && column?.multiSelect}
          hasOptions={!!options.length}
          restrictedOperators={parameter.sourceType === 'forecast' ? ['='] : undefined}
          onChange={(operator) => {
            const newParameter: WillyParameter = {
              ...parameter,
              operator: operator as FilterOperator,
            };
            const newParameters = parameters.map((p) => {
              if (p.column === parameter.column) {
                return newParameter;
              }
              return p;
            });
            parametersChange(newParameters);
          }}
        />

        <WillyDynamicFieldValue
          operator={parameter.operator || '='}
          column={column}
          value={parameter.value}
          datePickerMode="date"
          options={options}
          loadingOptions={loadingOptions}
          valueChange={(value) => {
            const newParameter: WillyParameter = {
              ...parameter,
              value,
            };
            const newParameters = parameters.map((p) => {
              if (p.column === parameter.column) {
                return newParameter;
              }
              return p;
            });
            parametersChange(newParameters, true);
          }}
        />
      </div>
    </div>
  );
};
