const KlaviyoIconBase64 = ({ small, light = false }) => (
  <img
    alt="Klaviyo icon"
    src={`data:image/svg+xml;base64,${
      light
        ? 'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNjMuNTYgMTA1LjI0Ij48ZGVmcz48c3R5bGU+LmJ7ZmlsbDojZmZmYWYzO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYiIgZD0iTTE1My42OCwwSDBWMTA1LjI0SDE1My42OGwtMzAuMzgtNTIuNjJMMTUzLjY4LDBaIi8+PGc+PHBhdGggY2xhc3M9ImIiIGQ9Ik0xNTguNjgsMTAyLjE2djMuMDloLS4zMnYtMy4wOWgtMS4xOXYtLjI5aDIuNzF2LjI5aC0xLjE5WiIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMTYxLjg0LDEwNS4yNGwtMS4xNS0yLjkxdjIuOTFoLS4zMXYtMy4zN2guNDVsMS4xNSwyLjkzLDEuMTUtMi45M2guNDN2My4zN2gtLjMxdi0yLjkxbC0xLjE1LDIuOTFoLS4yNloiLz48L2c+PC9zdmc+'
        : 'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNjMuNTYgMTA1LjI0Ij48ZGVmcz48c3R5bGU+LmJ7ZmlsbDojMjMyNDI2O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYiIgZD0iTTE1My42OCwwSDBWMTA1LjI0SDE1My42OGwtMzAuMzgtNTIuNjJMMTUzLjY4LDBaIi8+PGc+PHBhdGggY2xhc3M9ImIiIGQ9Ik0xNTguNjgsMTAyLjE2djMuMDloLS4zMnYtMy4wOWgtMS4xOXYtLjI5aDIuNzF2LjI5aC0xLjE5WiIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMTYxLjg0LDEwNS4yNGwtMS4xNS0yLjkxdjIuOTFoLS4zMXYtMy4zN2guNDVsMS4xNSwyLjkzLDEuMTUtMi45M2guNDN2My4zN2gtLjMxdi0yLjkxbC0xLjE1LDIuOTFoLS4yNloiLz48L2c+PC9zdmc+'
    }`}
    style={{ height: small ? 10 : 12 }}
    className="darkmode-white-filter"
  />
);

export default KlaviyoIconBase64;
