import { IconName, Loader } from '@tw/ui-components';
import { Fragment } from 'react';
import { WillyPrompt } from '../types/willyTypes';
import { PromptTile } from '../PromptTile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PromptCategory, Topic } from '../useSamplePrompts';

export const PROMPT_SUBCATS: { name: string; icon?: IconName }[] = [
  { name: 'Featured', icon: 'stars' },
  { name: 'All Prompts' },
  { name: 'Popular' },
  { name: 'Saved Prompts' },
];

export type PromptSubCategory = {
  subCategory: string;
  prompts: WillyPrompt[];
};

export type PromptsCategory = {
  name: string;
  subCategories: { name: string; icon?: IconName }[];
};

type PromptLibrayPageProps = {
  onSelectPrompt: (value: WillyPrompt) => void;
  promptsToShow: WillyPrompt[];
  hasMore: boolean;
  loadNextPage: () => void;
  promptTopics: Topic[];
  loadingPrompts: boolean;
  promptCategories: PromptCategory[];
};

export const PromptLibrayPage: React.FC<PromptLibrayPageProps> = ({
  onSelectPrompt,
  promptsToShow,
  hasMore,
  loadNextPage,
  promptTopics,
  loadingPrompts,
  promptCategories,
}) => {
  return (
    <>
      <InfiniteScroll
        scrollableTarget="prompt-library"
        scrollThreshold={0.95}
        dataLength={promptsToShow.length}
        hasMore={hasMore}
        next={() => loadNextPage()}
        loader={
          <div className="flex items-center justify-center h-12">
            <Loader size="sm" />
          </div>
        }
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-[30px] @3xl:!px-40 ">
          {promptsToShow.map((prompt, i) => {
            return (
              <Fragment key={`${prompt.id}-${i}`}>
                <PromptTile
                  prompt={prompt}
                  onSuggestionClick={(prompt) => onSelectPrompt(prompt)}
                  promptTopics={promptTopics}
                  promptCategories={promptCategories}
                />
              </Fragment>
            );
          })}
        </div>
      </InfiniteScroll>
      {loadingPrompts && (
        <div className="flex items-center justify-center h-12">
          <Loader size="sm" />
        </div>
      )}
    </>
  );
};
