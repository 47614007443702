import { Checkbox, Flex, Icon, Text } from '@tw/ui-components';
import { FC } from 'react';

type ItemInList = {
  id: string;
  name: string;
  credits?: number;
  emoji?: JSX.Element;
};

type DowngradeCreditsProps = {
  title: string;
  list: ItemInList[];
  selectedList: string[];
  setSelectedList: (list: string[]) => void;
  creditsToDowngrade: number;
};

export const DowngradeCredits: FC<DowngradeCreditsProps> = ({
  title,
  list,
  selectedList,
  setSelectedList,
  creditsToDowngrade,
}) => {
  return (
    <Flex direction="column" gap="md" pb="lg">
      <Text weight={500} color="gray.7">
        {title}
      </Text>
      {creditsToDowngrade > 0 && (
        <Text color="gray.5" size="sm">
          {`${selectedList.length}/${creditsToDowngrade} selected`}
        </Text>
      )}
      <Flex direction="column" gap="md" mah={300} overflow="auto" pr="md">
        {list.map((item) => (
          <Flex
            key={item.id}
            direction="row-reverse"
            gap="xs"
            align="center"
            justify="space-between"
          >
            {creditsToDowngrade > 0 && item.credits && (
              <div className="flex-auto flex justify-end">
                <Text size="md" color="gray.6">
                  {`${item.credits} credit${item.credits > 1 ? 's' : ''}`}
                </Text>
              </div>
            )}
            <div className="flex-grow">
              <Flex align="center" gap="xs">
                {creditsToDowngrade === 0 ? (
                  <Icon name="close" size={14} color="red.6" />
                ) : (
                  <Checkbox
                    checked={selectedList.includes(item.id)}
                    disabled={
                      selectedList.length === creditsToDowngrade && !selectedList.includes(item.id)
                    }
                    onChange={(checked) => {
                      const newList = checked
                        ? [...selectedList, item.id]
                        : selectedList.filter((id) => id !== item.id);
                      setSelectedList(newList);
                    }}
                  />
                )}
                {item.emoji && item.emoji}
                <Text size="md" color="gray.6">
                  {item.name ?? 'Untitled Board'}
                </Text>
              </Flex>
            </div>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
