import { $topBarHeight } from '$stores/nav-config-stores';
import { useEffect } from 'react';
import { SummarySection } from 'types/SummarySection';
import { $activeSummarySection } from './stores';
import { useStoreValue } from '@tw/snipestate';

/** Keeps selected summary section in SummarySectionPicker always in sync with the scroll */
export function useSummaryScroll(sections: SummarySection[]) {
  const topBarHeight = useStoreValue($topBarHeight);

  useEffect(() => {
    if (sections.length <= 0) return;

    const scrollableElement = document.querySelector(`#app-scrollable-wrapper`);
    if (!scrollableElement) return;

    function handleScroll() {
      try {
        for (let i = 0; i < sections.length; i++) {
          const section = sections[i];
          const previousSection = sections[i - 1];
          const sectionElement = document.querySelector(`.anchor_${section.id}`);
          if (sectionElement) {
            const sectionTop = sectionElement.getBoundingClientRect().top;
            if (sectionTop > topBarHeight + 120 && previousSection) {
              $activeSummarySection.set(previousSection.id);
              return;
            }
          }
        }
        $activeSummarySection.set(sections[sections.length - 1].id);
      } catch (e) {
        console.error(e);
      }
    }

    scrollableElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll);
    };
  }, [sections, topBarHeight]);
}
