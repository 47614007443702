import { Editor } from '@monaco-editor/react';
import { useDarkMode } from 'dark-mode-control';
import { convertDataToJson } from './utils/willyUtils';
import { NlqResponse, WillyMetric } from './types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';

type WillyJsonProps = {
  nlqData: NlqResponse;
};

export const WillyJson: React.FC<WillyJsonProps> = ({ nlqData }) => {
  const doDarkMode = useDarkMode();

  return nlqData?.data ? (
    <Editor
      language="json"
      height="100%"
      className="rounded overflow-hidden"
      defaultLanguage="json"
      defaultValue={JSON.stringify(convertDataToJson(nlqData.data), null, 2)}
      theme={doDarkMode ? 'vs-dark' : 'light'}
      value={JSON.stringify(convertDataToJson(nlqData.data), null, 2)}
      options={{
        readOnly: true,
      }}
      onMount={(editor, monaco) => {}}
    />
  ) : (
    <div className="flex w-full h-full justify-center items-center">
      <AlanLoaderGray />
    </div>
  );
};
