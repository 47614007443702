import { Flex, IconName, Loader, Tabs, Text, Title } from '@tw/ui-components';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { WillyPrompt } from '../types/willyTypes';
import { WillySearchInput } from '../WillySearchInput';
import { PromptCategorySelect } from '../PromptCategorySelect';
import { PromptTile } from '../PromptTile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { otherColorSets, useSamplePrompts } from '../useSamplePrompts';
import { PromptLibrayPage } from './PromptLibraryPage';
import { useStoreValue } from '@tw/snipestate';
import { $globalSequences, $loadingGlobalSequences } from '$stores/willy/$sequences';
import useDebounce from 'utils/useDebounce';
import { WorkflowTemplates } from './WorkflowTemplates';
import { $globalDashboardRoles } from '$stores/willy/$globalDashboardRoles';
import { MixTemplates } from './MixTemplates';
import { useLocation, useNavigate } from 'react-router-dom';

export const PROMPT_SUBCATS: { name: string; icon?: IconName }[] = [
  { name: 'Featured', icon: 'stars' },
  { name: 'All Prompts' },
  { name: 'Popular' },
  { name: 'Saved Prompts' },
];

export type PromptSubCategory = {
  subCategory: string;
  prompts: WillyPrompt[];
};

export type PromptsCategory = {
  name: string;
  subCategories: { name: string; icon?: IconName }[];
};

const tabs = [
  {
    id: 'all',
    label: 'All',
    content: ({
      onSelectPrompt,
      promptsToShow,
      hasMore,
      loadNextPage,
      promptTopics,
      loadingPrompts,
      loadingGlobalWorkflows,
      filteredWorkflows,
      workflowCategories,
      promptCategories,
    }) => (
      <MixTemplates
        onSelectPrompt={onSelectPrompt}
        promptTopics={promptTopics}
        promptsToShow={promptsToShow}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        loadingPrompts={loadingPrompts}
        loadingGlobalWorkflows={loadingGlobalWorkflows}
        filteredWorkflows={filteredWorkflows}
        workflowCategories={workflowCategories}
        promptCategories={promptCategories}
      />
    ),
  },
  {
    id: 'prompt_library',
    label: 'Prompts',
    content: ({
      onSelectPrompt,
      promptsToShow,
      hasMore,
      loadNextPage,
      promptTopics,
      loadingPrompts,
      promptCategories,
    }) => (
      <PromptLibrayPage
        onSelectPrompt={onSelectPrompt}
        promptTopics={promptTopics}
        promptsToShow={promptsToShow}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        loadingPrompts={loadingPrompts}
        promptCategories={promptCategories}
      />
    ),
  },
  {
    id: 'workflows',
    label: 'Workflows',
    content: ({ loadingGlobalWorkflows, filteredWorkflows, workflowCategories }) => (
      <WorkflowTemplates
        loadingGlobalWorkflows={loadingGlobalWorkflows}
        filteredWorkflows={filteredWorkflows}
        workflowCategories={workflowCategories}
      />
    ),
  },
];

type WillyTemplateLibraryProps = {
  onSelectPrompt: (value: WillyPrompt) => void;
};

export const WillyTemplateLibrary: React.FC<WillyTemplateLibraryProps> = ({ onSelectPrompt }) => {
  //sample prompts
  const {
    promptsToShow,
    topics,
    activePromptCategory,
    setActivePromptCategory,
    searchPrompt,
    setSearchPrompt,
    loadingPrompts,
    hasMore,
    loadNextPage,
    categories,
  } = useSamplePrompts();

  const [searchWorkflows, setSearchWorflows] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchWorkflows, 1000);

  const _onSearchChange = (val) => {
    setSearchPrompt(val);
    setSearchWorflows(val);
  };

  //workflows
  const globalSequences = useStoreValue($globalSequences);
  const filteredWorkflows = useMemo(() => {
    return globalSequences.filter((workflow) => {
      return workflow.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  }, [debouncedSearchTerm, globalSequences]);
  const loadingGlobalWorkflows = useStoreValue($loadingGlobalSequences);
  const workflowCategories = useStoreValue($globalDashboardRoles);

  const workflowCatsWithColors = workflowCategories.map((role, i) => {
    const colorSet = otherColorSets[i % otherColorSets.length];
    return {
      ...role,
      color: colorSet.color,
      labelColor: colorSet.labelColor,
      badgeColor: colorSet.badgeColor,
    };
  });

  //tabs
  const { search } = useLocation();
  const navigate = useNavigate();

  const activeTab: string | null = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get('tab');
    return tab || 'all';
  }, [search]);

  const currentTab = useMemo(() => tabs.find((t) => t.id === activeTab) || tabs[0], [activeTab]);
  return (
    <div
      className="max-w-[85%] m-auto flex flex-col gap-[25px] bg-white z-10  overflow-hidden h-full"
      id="prompt-library"
    >
      <Flex direction="column" gap="sm">
        <Title order={5} fw={600} color="gray.8">
          Template Library
        </Title>
        <Text fz={18}>
          Explore prompts and workflows that unlock even more powerful ways to use chat.
        </Text>
      </Flex>
      <div className="pb-[20px]">
        <Tabs
          value={activeTab}
          onChange={(tab) => {
            navigate({ pathname: '/chat/templates', search: `?tab=${tab}` }, { replace: true });
            window.scrollTo(0, 0);
          }}
        >
          <Tabs.List>
            {tabs.map((tab) => {
              return (
                <Tabs.Tab key={tab.id} value={tab.id}>
                  {tab.label}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
        </Tabs>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        {activeTab === 'prompt_library' && (
          <div className="flex  md:w-[400px] ">
            <PromptCategorySelect
              activePromptCategory={activePromptCategory}
              setActivePromptCategory={setActivePromptCategory}
              promptTopics={topics}
              categories={categories}
            />
          </div>
        )}
        <WillySearchInput
          value={searchPrompt}
          onChange={_onSearchChange}
          placeholder="Search Prompts"
          className="!p-0 overflow-visible w-full"
        />
      </div>
      <div className="overflow-auto no-scrollbar h-full">
        {currentTab.content({
          onSelectPrompt,
          promptsToShow,
          hasMore,
          loadNextPage,
          promptTopics: topics,
          loadingPrompts,
          filteredWorkflows,
          loadingGlobalWorkflows,
          workflowCategories: workflowCatsWithColors,
          promptCategories: categories,
        })}
      </div>
    </div>
  );
};
