import './AddEditInfluencer.scss';

import React, { FC, useEffect, useState } from 'react';
import { influencer, SelectableInfluencer } from 'types/Influencers';
import { emptyInfluencer } from 'utils/influencers';

import { Card, Modal } from '@shopify/polaris';

import InfluencerForm from '../InfluencerForm/InfluencerForm';

interface NewInfluencerProps {
  addInfluencer: Function;
  isOpen: boolean;
  onClose: () => void;
  editMode: boolean;
  influencerEdit: influencer;
  editSubmit: (i: influencer) => {};
}

const AddEditInfluencer: FC<NewInfluencerProps> = ({
  addInfluencer,
  isOpen,
  onClose,
  editMode,
  influencerEdit,
  editSubmit,
}) => {
  const [influencer, setInfluencer] = useState<SelectableInfluencer>(emptyInfluencer());

  useEffect(() => {
    editMode ? setInfluencer(influencerEdit) : setInfluencer(emptyInfluencer());
  }, [editMode, influencerEdit]);

  const handleAdd = async () => {
    const success = await addInfluencer(influencer);
    if (success) setInfluencer(emptyInfluencer());
  };

  // useEffect(() => {
  //   if (isOpen) {
  //     setInfluencer({ ...influencer, links: [{ id: '', campaign: '', source: '', link: '' }] });
  //   }
  // }, [isOpen, influencer]);

  return (
    <Modal
      open={isOpen}
      title={editMode ? `Edit ${influencerEdit.name}` : 'Add New Affluencer'}
      onClose={() => onClose()}
    >
      <InfluencerForm
        influencer={influencer}
        onChange={setInfluencer}
        onSubmit={editMode ? editSubmit : handleAdd}
        onCancel={() => onClose()}
        editMode={editMode}
      />
    </Modal>
  );
};

export default AddEditInfluencer;
