import { Fragment } from 'react/jsx-runtime';
import { WillyDataSequence } from '../types/willyTypes';
import { WorkflowTemplateTile } from './WorkflowTemplateTile';
import { Role } from '$stores/willy/$globalDashboardRoles';
import { Loader } from '@tw/ui-components';

type WorkflowTemplatesProps = {
  loadingGlobalWorkflows: boolean;
  filteredWorkflows: WillyDataSequence[];
  workflowCategories: Role[];
};

export const WorkflowTemplates: React.FC<WorkflowTemplatesProps> = ({
  loadingGlobalWorkflows,
  filteredWorkflows,
  workflowCategories,
}) => {
  return (
    <>
      {loadingGlobalWorkflows ? (
        <div className="flex justify-center py-10">
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pb-[30px] @3xl:!px-40 ">
          {filteredWorkflows.map((workflow) => {
            return (
              <Fragment key={workflow.id}>
                <WorkflowTemplateTile workflow={workflow} workflowCategories={workflowCategories} />
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};
