import { BqColumn } from './dataStuff/columns/types';
import React, { useEffect, useMemo, useState } from 'react';
import { $activeFilterBuilder } from './FreeQuery';
import { useActiveQueryAI, useActiveTables } from './Schema';
import { Button, Flex, SegmentedControl, Tooltip } from '@tw/ui-components';
import { applyFilters } from './dataStuff/applyFilters';
import { createSqlFromAI } from './dataStuff/ai/useAISqlBuilderForFilter';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/RootType';
import { getSocket } from '../../components/Willy/WillySocket';
import { WillySocket } from '../../components/Willy/types/willyTypes';
import { $tabs } from '$stores/willy/$tables';
import { BqTable } from './dataStuff/tables';
import { FilterBuilder } from './FilterBuilder/FilterBuilder';
import { FilterGroup, FilterRow } from '@tw/willy-data-dictionary/module/columns/types';
import { useStoreValue } from '@tw/snipestate';

export type FilterBuilderType = {
  selectedTableId?: string;
  isPaneOpen: boolean;
  viewPort?: number;
  lastFilter?: FilterGroup[];
  filter?: FilterGroup[];
};

export const setFilterBuilder = (
  val: FilterBuilderType | ((oldFilterBuilderType: FilterBuilderType) => FilterBuilderType),
) => {
  if (typeof val === 'function') {
    $tabs.set((old) => {
      return old.map((t) => {
        if (t.active) {
          return {
            ...t,
            filterBuilder: val(t.filterBuilder),
          };
        }
        return t;
      });
    });
  } else {
    $tabs.set((old) => {
      return old.map((t) => {
        if (t.active) {
          return {
            ...t,
            filterBuilder: val,
          };
        }
        return t;
      });
    });
  }
};

export const useActiveFilterBuilder = () => useStoreValue($activeFilterBuilder);

export const useIsFilteredByColumn = (tableId: string, column: BqColumn) => {
  const filterBuilder = useActiveFilterBuilder();
  return useMemo(() => {
    if (!filterBuilder.filter || filterBuilder.selectedTableId !== tableId) return false;
    return filterBuilder.filter.some((row) => {
      return row.filters.some((r) => r.column.id === column.id);
    });
  }, [column.id, filterBuilder, tableId]);
};

export const useSelectedColumns = () => {
  const filterBuilder = useActiveFilterBuilder();
  const tables = useActiveTables();
  const [columns, setColumns] = useState<BqColumn[]>([]);
  useEffect(() => {
    if (!filterBuilder.selectedTableId) return;
    setColumns(
      tables
        .find((t) => t.id === filterBuilder.selectedTableId)
        ?.columns.filter((x) => !['record repeated'].includes(x.type)) ?? [],
    );
  }, [filterBuilder.selectedTableId, tables]);
  return columns;
};

export const useSelectedTable = () => {
  const filterBuilder = useActiveFilterBuilder();
  const tables = useActiveTables();
  const [table, setTable] = useState<BqTable>();
  useEffect(() => {
    if (!filterBuilder.selectedTableId) return;
    setTable(tables.find((t) => t.id === filterBuilder.selectedTableId));
  }, [filterBuilder.selectedTableId, tables]);
  return table;
};

export const addFilterColumn = (tableId: string, column: BqColumn) => {
  setFilterBuilder((old) => {
    // Clone the outer array
    let newFilter = old.filter ? [...old.filter] : [];

    // Create a new FilterRow
    const newFilterRow: FilterRow = {
      column: column,
      // Initialize other properties as needed
    };

    if (newFilter.length > 0) {
      // If there's already an array, add to the last one
      newFilter[newFilter.length - 1] = {
        name: 'Filter',
        enabled: true,
        filters: [...newFilter[newFilter.length - 1].filters, newFilterRow],
      };
    } else {
      // If filter is empty, initialize it with a new array containing the new FilterRow
      newFilter = [{ enabled: true, name: 'Filter', filters: [newFilterRow] }];
    }

    // Return the updated state
    return {
      ...old,
      isPaneOpen: true,
      selectedTableId: tableId,
      filter: newFilter,
    };
  });
};

export const removeFilterColumnById = (columnId) => {
  setFilterBuilder((old) => {
    // Clone the outer array and filter each inner array
    const newFilter = old.filter
      ? (old.filter.map((group) => ({
          ...group,
          filters: group.filters.filter((filterRow) => filterRow.column.id !== columnId),
        })) as FilterGroup[])
      : [];

    // Remove any empty arrays
    const filteredNewFilter = newFilter.filter((g) => g.filters.length > 0);

    // Return the updated state
    return {
      ...old,
      filter: filteredNewFilter,
    };
  });
};

export const resetAllFilters = () => {
  setFilterBuilder((old) => {
    return {
      ...old,
      filter: [],
    };
  });
};

const saveFilters = (
  filters: FilterGroup[],
  shopId: string,
  willySocket: WillySocket,
  useBasicFilter: boolean,
) => {
  const lastFilter = $activeFilterBuilder.get().lastFilter;
  setFilterBuilder((old) => {
    return {
      ...old,
      lastFilter: filters,
    };
  });
  if (useBasicFilter) {
    applyFilters(
      filters.filter((x) => x.enabled).map((x) => x.filters),
      lastFilter?.filter((x) => x.enabled).map((x) => x.filters) ?? [],
    );
  } else {
    createSqlFromAI(shopId, willySocket);
  }
};

export const SqlFilterBuilder = () => {
  const columns = useSelectedColumns();
  const table = useSelectedTable();
  const filterBuilder = useActiveFilterBuilder();
  const shopId = useSelector((state: RootState) => state.currentShopId);
  const willySocket = useMemo(() => getSocket(), []);
  const activeQueryAI = useActiveQueryAI();
  const [isApplyDisable, setIsApplyDisable] = useState(true);
  const [useBasicFilter, setUseBasicFilter] = useState(true);

  return (
    <div>
      <SegmentedControl
        value={useBasicFilter.toString()}
        data={[
          {
            label: (
              <Tooltip label={`Faster. Some filters may not be supported.`}>
                <span>Basic</span>
              </Tooltip>
            ),
            value: 'true',
          },
          {
            label: (
              <Tooltip
                label={
                  'Uses an AI model. Can support more filter types. Takes some time to execute'
                }
              >
                <span>Advanced</span>
              </Tooltip>
            ),
            value: 'false',
          },
        ]}
        onChange={(val) => setUseBasicFilter(val === 'true')}
      />
      <FilterBuilder
        table={table!}
        onFilterChanged={(filter) =>
          setFilterBuilder((old) => {
            return {
              ...old,
              filter: filter,
            };
          })
        }
        onFilterValidChanged={(valid) => setIsApplyDisable(!valid)}
        columns={columns}
        filter={filterBuilder.filter}
      />
      <div>
        <Flex justify={'end'} align={'center'}>
          <Button
            disabled={isApplyDisable}
            loading={activeQueryAI?.loading === true}
            onClick={() => saveFilters(filterBuilder.filter!, shopId, willySocket, useBasicFilter)}
          >
            Apply
          </Button>
        </Flex>
      </div>
    </div>
  );
};
