import { useEffect } from 'react';
import { Page } from '@shopify/polaris';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import Cohorts from 'components/Insights/Cohorts/Cohorts';
import { useAppDispatch } from 'index';
import { setInsightsEventsContext } from 'ducks/insights/actions';
import { analyticsEvents } from 'utils/dataLayer';
import { InAppContextBanner } from 'components/InAppContextBanner';
import LockedPageTemplate from 'pages/Attribution/LockedPageTemplate';
import firstImage from './assets/retention.png';
import secondSectionImage from './assets/reports.png';
import quateImage from './assets/quote.png';
import logo from './assets/logo.png';

const CohortsPage = computeFeatureFlags(
  FeatureFlag.INSIGHT_GENERAL_FF,
  () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(setInsightsEventsContext(analyticsEvents.COHORTS));
    }, [dispatch]);

    return (
      <Page fullWidth>
        <InAppContextBanner page="cohorts" />
        <Cohorts />
      </Page>
    );
  },
  () => (
    // <UpgradePageFallBack
    //   InAppContextBannerPage={'cohorts'}
    //   InAppContextBannerEnabled={true}
    //   featureFlag={FeatureFlag.INSIGHT_GENERAL_FF}
    // />
    <LockedPageTemplate
      badgeText="Retention"
      bannerPageName="cohorts"
      mainTitle="Track customer retention and LTV over time"
      mainDescription="Understand your customer behavior patterns, make informed decisions, and identify valuable customer groups for retention"
      descriptionMaxWidth="400px"
      firstImage={firstImage}
      secondTitle="Filter cohorts for deeper insights"
      secondSectionImage={secondSectionImage}
      sections={[
        {
          subtitle: '',
          description:
            'Customize your cohorts by timeframe, key metric, and any segment from your segment builder',
        },
      ]}
      quote="I love the cohort analysis tool. Prior to having Triple Whale, doing cohort analysis was a very annoying process in terms of crunching the data, but Triple Whale does it immediately for you by filtering for different segments of customers."
      quoteAuthor="Ray Li"
      quoteAuthorTitle="CEO, SENE"
      logo={logo}
      quateImage={quateImage}
    />
  ),
);

export default CohortsPage;
