import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import { ItoggleInventoryAlert } from 'types/attribution';

export const toggleInventoryAlert = async ({
  shopId,
  skuId,
  value,
}: ItoggleInventoryAlert): Promise<any> => {
  try {
    const payload = { type: 'variants', value, id: skuId };
    await axiosInstance.post('/v2/shopify/mongo/save-products-forecast-metadata', {
      shopId,
      payload,
    });
    toast.success(
      'Success! Inventory alert has been updated. You can un-snooze this sku alert in Product Analytics',
    );
  } catch (error) {
    console.error(error);
    toast.error('Hm, something went wrong. Please try again later.');
  }
};
