import { ActionIcon, Anchor, Popover, Size } from '@tw/ui-components';
import { getDashPermsManager } from '../permissions-management/DashboardPermissionsManager';
import { useDashContext } from 'components/Willy/dashContext';
import { useDashCopyLink } from 'components/Willy/hooks/useDashCopyLink';
import { useStoreValue } from '@tw/snipestate';
import { FC } from 'react';

type ShareDashboardCTAProps = {
  activator?: (onClick: () => void) => React.ReactNode;
};

export const ShareDashboardCTA: FC<ShareDashboardCTAProps> = ({ activator }) => {
  const { $dashboard } = useDashContext();
  const dashboard = useStoreValue($dashboard);
  const { justCopied, handleCopyLink } = useDashCopyLink(dashboard?.id);

  const openPermsModal = () => {
    getDashPermsManager().$modal.set((x) => ({
      ...x,
      opened: !x.opened,
    }));
  };

  return !!dashboard?.canEditPerms ? (
    activator ? (
      activator(openPermsModal)
    ) : (
      <ActionIcon
        icon="share"
        onClick={openPermsModal}
        variant="activatorWithHover"
        size={30 as Size}
      />
    )
  ) : (
    <Popover position="right-end">
      <Popover.Target>
        <div>
          {activator ? (
            activator(() => {})
          ) : (
            <ActionIcon icon="share" variant="activatorWithHover" size={30 as Size} />
          )}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <div>
          <Anchor as="button" onClick={handleCopyLink}>
            {justCopied ? 'Link copied!' : 'Copy link'}
          </Anchor>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
