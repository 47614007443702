import { useEffect, useMemo, useState } from 'react';
import { RootState, useAppSelector } from 'reducers/RootType';
import { Box, LoadingOverlay, Modal, Text } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import {
  sensoryGetProviderAccounts,
  closeSensoryAccountsConfigScreen,
  sensoryGetProviderSettings,
} from 'ducks/sensory';
import { ConnectionDetails } from './ConnectionDetails';
import { IntegrationsSettings } from './IntegrationsSettings';
import { useSelector } from 'react-redux';
import { Provider } from '@tw/types/module/services/account-manager';
// style
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useDarkMode } from 'dark-mode-control';

export const ManageConnectionModal = () => {
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();
  const darkMode = useDarkMode();
  const sensoryAccountConfigScreenIsOpen = useAppSelector(
    (state) => state.sensory.sensoryAccountConfigScreenIsOpen,
  );
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData as Provider,
  );
  const sensoryProviderConfigLoading = useAppSelector(
    (state) => state.sensory.sensoryProviderConfigLoading,
  );

  const menuItems = useMemo(() => {
    const _menuItems = [{ name: 'Connection Details', component: <ConnectionDetails /> }];
    if (sensoryProviderConfigData?.settings) {
      _menuItems.push({ name: 'Settings', component: <IntegrationsSettings /> });
    }
    return _menuItems;
  }, [sensoryProviderConfigData]);

  const [selectedItem, setSelectedItem] = useState(menuItems[0]);

  useEffect(() => {
    (async () => {
      if (!sensoryAccountConfigScreenIsOpen) return;
      try {
        setSelectedItem(menuItems[0]);
        await dispatch(sensoryGetProviderAccounts());
        await dispatch(sensoryGetProviderSettings());
      } catch (err) {}
    })();
  }, [sensoryAccountConfigScreenIsOpen, dispatch, menuItems]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <Modal
      size="xl"
      title={
        <Text p={'md'} weight={500}>
          {` Manage ${sensoryProviderConfigData.name} Connection`}{' '}
        </Text>
      }
      opened={sensoryAccountConfigScreenIsOpen}
      onClose={() => dispatch(closeSensoryAccountsConfigScreen())}
      headerBorder={true}
      padding={0}
      closeButtonProps={{ mr: 'sm' }}
      fullScreen={isSmall}
    >
      <LoadingOverlay visible={sensoryProviderConfigLoading} />
      <Box display={isSmall ? 'unset' : 'flex'} h={isSmall ? '100%' : '400'}>
        <Box p={0} m={0} borderRight={TOP_BAR_BORDER_VAR} w={isSmall ? '100%' : '30%'}>
          {menuItems.map((item) => (
            <Box
              key={item.name}
              onClick={() => handleItemClick(item)}
              bg={selectedItem.name === item.name ? (darkMode ? 'gray.6' : 'one.0') : 'transparent'}
              cursor="pointer"
              w={'100%'}
            >
              <Text fz="sm" p="xs" weight={500}>
                {item.name}
              </Text>
            </Box>
          ))}
        </Box>
        <Box p="xs" w={isSmall ? '100%' : '70%'} overflow={'scroll'}>
          {menuItems.find((item) => item.name == selectedItem.name)
            ? selectedItem.component
            : menuItems[0].component}
        </Box>
      </Box>
    </Modal>
  );
};
