import { SalesPlatform } from '@tw/types/module/services';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector } from '../../../reducers/RootType';
import { WizardContext } from '../WizardContext';
import SalesPlatformIcon from '../../SalesPlatform/SalesPlatformIcon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SalesPlatformName } from '../../SalesPlatform/SalesPlatformName';
import { UseSetupProgressArgs } from '../../../routes/allShopsPinnedSections/hooks/useSetupProgress';
import { StepCompletionInfo } from '../../../types/WizardData';
import {
  saveIntegrationSettings,
  SENSORY_PROVIDER_CONFIG_DATA,
  sensoryGetProviderAccounts,
  sensoryGetProviderSettings,
} from '../../../ducks/sensory';
import { useAppDispatch } from '../../../index';
import { IntegrationIndividualSettings } from '../../../routes/integrations/components/ManageConnectionModal/IntegrationsSettings';
import { Provider } from '@tw/types/module/services/account-manager';
import { Box, Button, LoadingOverlay, Text } from '@tw/ui-components';
import { toast } from 'react-toastify';
import { setOnboardingStatus } from '../../../ducks/shop';
import { useMspConnectedInfo } from './mspConnect/WelcomeConnectMainSalesPlatfrom';

export const SalesPlatformSettingsIndication: React.FC<any> = ({
  mspName,
  shopDomain,
  isFromPod,
  mspConnected,
}) => {
  const { status, text } = useMspConfiguredInfo({ isFromPod, mspConnected });
  return (
    <div
      className={
        status === 'complete'
          ? 'text-green'
          : status === 'in-progress'
            ? 'text-orange-400'
            : 'text-red-400'
      }
    >
      <span>{text}</span>
    </div>
  );
};

export function useMspConfiguredInfo({
  isFromPod,
  mspConnected,
}: UseSetupProgressArgs): StepCompletionInfo {
  const providersIntegrations = useAppSelector((state) => state.sensory.providerAccounts) as any[];
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData as Provider,
  );

  //Since this is a MSP we are assuming there is only one integration connected
  const currentIntegration = providersIntegrations.find(
    (int) => int.provider_id == sensoryProviderConfigData.id && int.integration_id,
  );

  const text = !currentIntegration
    ? 'Loading...'
    : !!currentIntegration?.settings
      ? 'Done!'
      : 'Not Configured';
  const status: StepCompletionInfo['status'] = !currentIntegration
    ? 'in-progress'
    : !!currentIntegration?.settings
      ? 'complete'
      : 'not-started';

  return { status, text };
}

export const SetupSalesPlatformTitle: React.FC<any> = ({ mspName }) => (
  <span>
    Setup <SalesPlatformName mspName={mspName} />
  </span>
);

export const WelcomeSetupMSP: React.FC = () => {
  const msp: SalesPlatform = useSelector((state: RootState) => state.msp);
  const { nextStep } = useContext(WizardContext);
  const [currentSettings, setCurrentSettings] = useState<any>();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [showWarn, setShowWarn] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData as Provider,
  );
  const sensoryProviderConfigLoading = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigLoading,
  );
  const sensoryProviderSettingLoading = useSelector(
    (state: RootState) => state.sensory.sensoryProviderSettingLoading,
  );
  const providersIntegrations = useAppSelector((state) => state.sensory.providerAccounts) as any[];
  const sensoryProviderInfo = useSelector((state: RootState) => state.sensory.providers);

  //Since this is a MSP we are assuming there is only one integration connected
  const currentIntegration = providersIntegrations.find(
    (int) => int.provider_id == sensoryProviderConfigData.id && int.integration_id,
  );

  useEffect(() => {
    if (sensoryProviderConfigLoading || sensoryProviderSettingLoading || !currentIntegration) {
      setIsPageLoading(true);
    } else {
      setIsPageLoading(false);
    }
  }, [currentIntegration, sensoryProviderConfigLoading, sensoryProviderSettingLoading]);

  const next = useCallback(async () => {
    try {
      await dispatch(saveIntegrationSettings(currentIntegration, currentSettings, false));
      nextStep();
    } catch (e) {
      toast.error(e);
    }
  }, [currentSettings, dispatch, currentIntegration, nextStep]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-[40px]">
        <SalesPlatformIcon customHeight={100} />
      </div>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <p className="step-title">
        Setup your <SalesPlatformName /> Store
      </p>
      <LoadingOverlay visible={isPageLoading} />
      {!isPageLoading && (
        <div>
          {sensoryProviderConfigData?.settings ? (
            <Box>
              <IntegrationIndividualSettings
                onCurrentChanged={(curr) => {
                  setCurrentSettings(curr);
                }}
                integration={currentIntegration}
                configData={sensoryProviderConfigData}
                showHeader={false}
              ></IntegrationIndividualSettings>
            </Box>
          ) : (
            <Text>Settings are not available for this {msp} store</Text>
          )}
        </div>
      )}

      <Button
        className={`step-button ${isPageLoading ? 'disabled' : ''}`}
        onClick={next}
        disabled={isPageLoading}
      >
        Next
      </Button>
      <span className="mt-[12px] text-[12px] text-[#C63E24]" style={{ opacity: showWarn ? 1 : 0 }}>
        In order to proceed with setup, a configured the <SalesPlatformName /> is required.
      </span>
    </div>
  );
};
