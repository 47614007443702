import { Modal, Text, Box, LoadingOverlay } from '@tw/ui-components';
import { CustomerActivityLog } from './CustomerActivityLog';
import moment from '@tw/moment-cached/module/timezone';

export type CustomerProfileModalProps = {
  onClose?: () => void;
  loading?: boolean;
  shopifyCustomerId?: string;
  customerName?: string;
  customerCreatedDate?: Date;
  startDate?: Date;
};
export const CustomerProfileModal: React.FC<CustomerProfileModalProps> = ({
  onClose,
  shopifyCustomerId,
  customerName,
  customerCreatedDate,
  loading = false,
  startDate = moment().toDate(),
}) => {
  return (
    <Modal
      headerBorder={true}
      opened={true}
      onClose={() => {
        onClose?.();
      }}
      zIndex={999}
      size="lg"
      title={
        loading ? null : (
          <>
            <Text size="xl" weight={600}>
              {customerName}
            </Text>
            <Text size="sm" c="gray.6" opacity={customerCreatedDate ? 1 : 0}>
              Customer since {moment(customerCreatedDate).format('D MMM, YYYY')}
            </Text>
          </>
        )
      }
    >
      <Box pt="md" pos="relative" mih={100}>
        <LoadingOverlay visible={loading} />
        {!loading && shopifyCustomerId && (
          <CustomerActivityLog
            shopifyCustomerId={shopifyCustomerId}
            startDate={startDate}
            customerEarliestDate={customerCreatedDate}
          />
        )}
      </Box>
    </Modal>
  );
};
