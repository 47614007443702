import { useCallback, useEffect, useState } from 'react';
import { WillyMetric, WillyWidgetElement } from './types/willyTypes';
import { Button, Modal, Tooltip } from '@tw/ui-components';
import { FreeQuery } from 'pages/FreeQuery/FreeQuery';
import { createNewPopupQuery } from './utils/willyUtils';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';
import { genericEventLogger, analyticsEvents, dashboardsActions } from 'utils/dataLayer';

type WillyCustomQueryModalProps = {
  metric: WillyMetric;
  opened: boolean;
  queryVars?: string[];
  errorSavingQuery: string;
  onClose: () => void;
  querySaved: (
    query: Omit<WillyWidgetElement, 'queryId'>,
    variables?: Record<string, any>,
  ) => Promise<boolean>;
};

export const WillyCustomQueryModal: React.FC<WillyCustomQueryModalProps> = ({
  metric,
  opened,
  queryVars,
  errorSavingQuery,
  onClose,
  querySaved,
}) => {
  const [query, setQuery] = useState<Omit<WillyWidgetElement, 'queryId'>>();
  const [saving, setSaving] = useState(false);
  const [queryVarsValues, setQueryVarsValues] = useState<Record<string, any>>({});
  const { shouldNotBeSeen: isSQLBlocked } = useFeatureFlag(FeatureFlag.SQL_FF);

  const queryChanged = useCallback(
    (queryStr: string) => {
      if (!queryStr) {
        return;
      }
      const oldQuery = metric.popupWidget || createNewPopupQuery(queryStr, metric.name);
      setQuery({
        ...oldQuery,
        queries: [{ ...oldQuery.queries?.[0]!, query: queryStr }],
      });
    },
    [metric.name, metric.popupWidget],
  );

  useEffect(() => {
    if (metric.popupWidget) {
      setQuery(metric.popupWidget);
    } else {
      const newQuery = createNewPopupQuery('', metric.name);
      setQuery(newQuery);
    }
  }, [metric.name, metric.popupWidget]);

  return (
    <Modal.Root size="xl" centered fullScreen opened={opened} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content className="!flex flex-col w-full h-full">
        <Modal.Header>
          <Modal.Title>
            {!!metric.popupWidget ? 'Edit' : 'Add'} query {!!metric.popupWidget ? 'of' : 'to'}{' '}
            <span className="font-semibold">{metric.name}</span>
          </Modal.Title>
          <Button variant="white" onClick={onClose}>
            Close
          </Button>
        </Modal.Header>
        <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
        <Modal.Body className="flex flex-auto">
          <div className="flex w-full">
            <div className="w-full flex flex-col flex-auto">
              <FreeQuery
                initialQuery={query?.queries?.[0]?.query || ''}
                openNewTab={true}
                queryChanged={queryChanged}
                queryVars={queryVars}
                queryVarsValuesChanged={setQueryVarsValues}
              />
              <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>

              <div className="my-auto pt-4 w-full flex">
                <div className="ml-auto flex flex-col gap-2">
                  <Tooltip label={isSQLBlocked ? 'Upgrade to access SQL' : ''}>
                    <Button
                      // disabled={!query?.query}
                      disabled={isSQLBlocked}
                      variant="primary"
                      loading={saving}
                      onClick={async () => {
                        if (!query) {
                          return;
                        }
                        setSaving(true);
                        const res = await querySaved(query, queryVarsValues);
                        setSaving(false);

                        genericEventLogger(analyticsEvents.DASHBOARDS, {
                          action: dashboardsActions.EDIT_QUERY,
                          query: query.queries?.[0]?.query,
                          metric: metric.name,
                        });

                        if (res) {
                          onClose();
                        }
                      }}
                    >
                      Update Query
                    </Button>
                  </Tooltip>
                  {!!errorSavingQuery && <div className="text-red-500">{errorSavingQuery}</div>}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
