import { ReactComponent as WhaleIcon } from '../../Icons/whale-icon.svg';

import './steps.scss';
import Lottie from 'react-lottie-player';
import { useContext } from 'react';
import { WizardContext } from '../WizardContext';

const Welcome: React.FC = () => {
  const { nextStep } = useContext(WizardContext);

  return (
    <div className="flex flex-col items-center flex-1 mt-[-20px]">
      <div className="w-full flex justify-center relative">
        <Lottie
          loop
          play
          path={'/lotties/confetti.json'}
          style={{ width: '40%', minWidth: '350px', maxWidth: '100vw' }}
          rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
        />
        <div className="w-[160px] h-[160px] bg-logo rounded-full flex items-center justify-center absolute top-[50%] translate-y-[-50%]">
          <WhaleIcon className="fill-white w-[70%] mt-[20px]" />
        </div>
      </div>

      <p className="step-title">Welcome to Triple Whale!</p>
      <p className="step-description">
        You’re just minutes away from transforming your data into profitable growth. There are a few
        things that need to be set up first. Ready to get started?
      </p>
      <button className="step-button" onClick={nextStep}>
        Get started
      </button>
    </div>
  );
};

export default Welcome;
