import { ActivityProperties, CustomerActivityType } from '@tw/types/module/services/insights';
import { Flex, Text } from '@tw/ui-components';

export const PPSInfo: React.FC<{
  properties: ActivityProperties[CustomerActivityType.PostPurchaseSurveySubmitted];
}> = ({ properties }) => {
  return (
    <Flex gap="xs" direction="column">
      {properties.questions?.map(({ question, answer }, index) => (
        <Text key={index}>
          <Text c="gray.6" size="sm">
            Question:
          </Text>
          <Text c="gray.7" size="sm" weight={500}>
            “{question}”
          </Text>

          <Text c="gray.6" size="sm">
            Answer:
          </Text>
          <Text c="gray.7" size="sm" weight={500}>
            {answer}
          </Text>
        </Text>
      ))}
    </Flex>
  );
};
