import { useColorScheme } from '@tw/ui-components';

/**
 * @description THE source of truth for whether app is in dark mode.
 *
 * ---
 * For those interested in reasons behind the implementation of this function, there are 3 things to keep in mind:
 * - Since syncing with Mantine **is an effect**, it means that if we used the $darkMode here instead of the
 *   useColorScheme hook, we'd have an issue where things that aren't Mantine will update quickly,and things that
 *   are will update a second later.  It's important to use the useColorScheme hook from Mantine as the source of
 *   truth, since Mantine components will always be last to change.
 * - Also, each time we use the useDarkModeStore hook, we're adding a new listener to the $darkMode. Using
 *   useColorScheme is therefore more optimized than adding more and more listeners to the $darkMode.
 * - Finally, if you ask why not just use `useLayoutEffect` in `useInitDarkModeConnection` instead of `useEffect`,
 *   and still use the $darkMode hook as the active source of truth, the answer is that useLayoutEffect is
 *   noticeably slower/blocking in this specific case.
 */
export const useDarkMode = () => useColorScheme().colorScheme === 'dark';
