import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { clamp } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import { Box, Timeline, Title, Text, Grid, Container, Flex, Button } from '@tw/ui-components';
import { CustomerActivity } from '@tw/types/module/services/insights';
import { RootState } from 'reducers/RootType';
import { ActivityContent, ActivityIcon, ActivityTitle } from './ActivityElements';
import { getActivityLog } from './utils';

const MIN_EVENTS_FOR_INITIAL_LOAD = 8;

export type CustomerActivityLogProps = {
  shopifyCustomerId: string;
  startDate: Date;
  customerEarliestDate?: Date;
};
export const CustomerActivityLog: React.FC<CustomerActivityLogProps> = ({
  shopifyCustomerId,
  startDate,
  customerEarliestDate,
}) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [lastLoadedDate, setLastLoadedDate] = useState<Date>(startDate);
  const [activities, setActivities] = useState<CustomerActivity[]>([]);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState<boolean>(false);
  const [initialLoadEnded, setInitialLoadEnded] = useState<boolean>(false);
  const earliestDate = useSelector((state: RootState) => state.earliestDate);

  const stopOnDate = useMemo(() => {
    return customerEarliestDate ? moment(customerEarliestDate).subtract(6, 'month') : earliestDate;
  }, [customerEarliestDate, earliestDate]);

  const hasMoreToLoad: boolean = useMemo(() => {
    return moment(stopOnDate).isBefore(moment(lastLoadedDate).subtract(1, 'day'));
  }, [stopOnDate, lastLoadedDate]);

  const skeletonLinesCount = useMemo(() => {
    if (!isLoadingMoreData) return 0;

    return clamp(5 - activities.length, 3, 5);
  }, [activities.length, isLoadingMoreData]);

  const initialLoad = useCallback(async () => {
    setIsLoadingMoreData(true);
    const initialActivities: CustomerActivity[] = [];
    let start = moment(startDate);
    let end;

    while (
      initialActivities.length < MIN_EVENTS_FOR_INITIAL_LOAD &&
      moment(earliestDate).isBefore(end)
    ) {
      const { activities, endDate } = await getActivityLog(
        currentShopId,
        shopifyCustomerId,
        start,
        earliestDate,
      );

      initialActivities.push(...activities);
      start = moment(endDate.clone().subtract(1, 'day'));
      end = endDate;

      setActivities(initialActivities);
    }

    setLastLoadedDate(end);
    setIsLoadingMoreData(false);
    setInitialLoadEnded(true);
  }, [currentShopId, earliestDate, shopifyCustomerId, startDate]);

  const loadNextPeriod = useCallback(async () => {
    setIsLoadingMoreData(true);
    const start = moment(lastLoadedDate).subtract(1, 'day');
    const { activities, endDate } = await getActivityLog(
      currentShopId,
      shopifyCustomerId,
      start,
      stopOnDate,
    );

    setActivities((prevActivities) => [...prevActivities, ...activities]);
    setIsLoadingMoreData(false);
    setLastLoadedDate(endDate.toDate());
  }, [currentShopId, lastLoadedDate, shopifyCustomerId, stopOnDate]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <>
      <Title order={6} weight={600} mb="md">
        Activity Log
      </Title>
      <Container
        pt="sm"
        px="unset"
        mx="-10px"
        mah="65vh"
        h="65vh"
        display="flex"
        style={{ overflow: 'auto', flexDirection: 'column' }}
      >
        <Box px="lg">
          <Flex gap="sm" direction="column">
            <Timeline
              bulletSize={20}
              lineWidth={1}
              bulletVariant="transparent"
              loading={skeletonLinesCount > 0}
              skeletonLines={skeletonLinesCount}
            >
              {activities.map((activity, index) => (
                <Timeline.Item
                  key={index}
                  bullet={<ActivityIcon activity={activity} />}
                  title={
                    <Grid>
                      <Grid.Col span="auto" pt={0} style={{ overflow: 'hidden' }}>
                        <Text truncate>
                          <ActivityTitle activity={activity} />
                        </Text>
                      </Grid.Col>
                      <Grid.Col span="content" pt={0}>
                        <Text size="xs" c="gray.5" weight={400}>
                          {moment(activity.eventDate).format('MMM D, YYYY, h:mm:ss A')}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  }
                >
                  <ActivityContent activity={activity} />
                </Timeline.Item>
              ))}
            </Timeline>

            {initialLoadEnded && hasMoreToLoad && (
              <Flex justify="center">
                <Button
                  onClick={loadNextPeriod}
                  disabled={isLoadingMoreData}
                  size="xs"
                  variant="secondary"
                >
                  Load more events
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      </Container>
    </>
  );
};
