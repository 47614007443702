import { Button, Modal, OptionList, TextStyle } from '@shopify/polaris';
import {
  pinterestAccountsSelectOnChange,
  pinterestAccountsConfigSavePress,
  togglePinterestAccountsConfigScreenOpen,
  pinterestConnectOnPress,
} from 'ducks/pinterest';
import { useAppSelector } from 'reducers/RootType';
import { useAppDispatch } from 'index';

export default function PinterestAccountsConfig() {
  const dispatch = useAppDispatch();

  const pinterestConfigScreenIsOpen = useAppSelector((state) => state.pinterestConfigScreenIsOpen);
  const pinterestRemoteAccounts = useAppSelector((state) => state.pinterestRemoteAccounts);
  const pinterestAccountSearch = useAppSelector((state) => state.pinterestAccountSearch);

  return (
    <Modal
      title={
        pinterestRemoteAccounts?.length ? 'Select Pinterest Ad Account' : 'Connect to Pinterest'
      }
      open={pinterestConfigScreenIsOpen}
      onClose={() => dispatch(togglePinterestAccountsConfigScreenOpen())}
      primaryAction={{
        content: 'Save',
        onAction: () => dispatch(pinterestAccountsConfigSavePress()),
        disabled: !pinterestRemoteAccounts?.filter?.((acc) => acc.selected)?.length,
      }}
      sectioned
    >
      {pinterestRemoteAccounts?.length > 0 && (
        <OptionList
          onChange={(selected) => {
            var accounts = pinterestRemoteAccounts.slice();
            accounts.forEach((acc) => (acc.selected = selected.includes(acc.id)));

            dispatch(pinterestAccountsSelectOnChange(accounts));
          }}
          options={(pinterestAccountSearch.length && pinterestRemoteAccounts
            ? pinterestRemoteAccounts.filter(
                (a) =>
                  a.name.toLowerCase().indexOf(pinterestAccountSearch.toLowerCase()) >= 0 ||
                  String(a.id).indexOf(pinterestAccountSearch) >= 0,
              )
            : pinterestRemoteAccounts
          ).map((account) => {
            return {
              value: account.id,
              label: account.name,
            };
          })}
          selected={pinterestRemoteAccounts.filter((acc) => acc.selected).map((acc) => acc.id)}
          allowMultiple
        />
      )}
      {!pinterestRemoteAccounts?.length && (
        <>
          <Modal.Section>
            <TextStyle variation="strong">
              No ads accounts found for this Pinterest account, you can try to connect to different
              account
            </TextStyle>
          </Modal.Section>
          <Modal.Section>
            <Button primary onClick={() => pinterestConnectOnPress()}>
              Connect to Pinterest
            </Button>
          </Modal.Section>
        </>
      )}
    </Modal>
  );
}
