import {
  OptionsObj as _OptionsObj,
  BqColumn as _BqColumn,
  AggregationFunction,
} from '@tw/willy-data-dictionary/module/columns/types';

export const EVENT_DATE = 'event_date';

export type BqColumn = _BqColumn & {
  selected?: boolean;
  expanded?: boolean;
  docId?: string;
  pivot?: boolean;
  agg?: AggregationFunction;
};

export type OptionsObj = _OptionsObj & { disabled?: boolean };
