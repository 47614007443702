import { $derived } from '@tw/snipestate';
import { $prefersDarkMode } from './$prefersDarkMode';
import { $pathHasDarkMode } from './$pathHasDarkMode';

/**
 * Store used to keep track if user was forced out of dark mode or not.
 * This helps us know when we should switch the user back to dark mode.
 */
export const $forcedFromDark = $derived((get) =>
  get($prefersDarkMode) ? !get($pathHasDarkMode) : false,
);
