import { Flex } from '@tw/ui-components';
import { WillyDashDescription } from '../WillyDashDescription';
import { RefreshDashboardButton } from './RefreshDashboardButton';
import { MetricsCurrentlyFiltered } from 'components/MetricsCurrentlyFiltered';

type WillyDashboardMobileTopHeaderProps = {};

export const WillyDashboardMobileTopHeader: React.FC<WillyDashboardMobileTopHeaderProps> = ({}) => {
  return (
    <Flex gap="sm" align="center">
      <MetricsCurrentlyFiltered />
      <RefreshDashboardButton />
      <WillyDashDescription />
      <div className="h-[30px] w-[1px] bg-[var(--mantine-color-named2-3)]" />
    </Flex>
  );
};
