import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Loader, Button, Text } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $userId } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { WillySelect } from './dashboardManagment/WillySelect';
import moment from 'moment';
import { $combinedSequences } from '$stores/willy/$combinedSequences';
import { $shopUsers } from '$stores/$users';
import { formatSequenceItemDate, SequenceListCard, SequenceListItem } from './SequenceListCard';
import { $globalDashboardRoles } from '$stores/willy/$globalDashboardRoles';

const filterOptions = [
  { value: 'all', label: 'All Workflows' },
  { value: 'user', label: 'My Workflows' },
];

const secondaryFilterOptions = [
  { value: 'all', label: 'All Types' },
  { value: 'report', label: 'Dashboards' },
  { value: 'workflow', label: 'Workflows' },
];

const sortOptions = [
  { value: 'recent', label: 'Most Recent' },
  { value: 'oldest', label: 'Oldest First' },
];

const ITEMS_PER_PAGE = 10;

export const SequencesAllList = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const userId = useStoreValue($userId);
  const { data: shopUsers } = useStoreValue($shopUsers);
  const combinedSequences = useStoreValue($combinedSequences);
  const workflowCategories = useStoreValue($globalDashboardRoles);

  // Get default values from URL params
  const params = new URLSearchParams(search);
  const defaultFilter = params.get('inboxFilter') || filterOptions[0].value;
  const defaultSecondaryFilter =
    params.get('inboxSecondaryFilter') || secondaryFilterOptions[0].value;
  const defaultSort = params.get('inboxSort') || sortOptions[0].value;

  const [filter, setFilter] = useState(defaultFilter);
  const [secondaryFilter, setSecondaryFilter] = useState(defaultSecondaryFilter);
  const [sort, setSort] = useState(defaultSort);
  const [freeSearch, setFreeSearch] = useState('');
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);

  const hasSearchFilterOrSort = useMemo(() => {
    return (
      freeSearch !== '' ||
      filter !== filterOptions[0].value ||
      secondaryFilter !== secondaryFilterOptions[0].value ||
      sort !== sortOptions[0].value
    );
  }, [freeSearch, filter, secondaryFilter, sort]);

  const updateUrlParams = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(search);
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigate, search],
  );

  const handleFilterChange = (value: string) => {
    setFilter(value);
    updateUrlParams('inboxFilter', value);
  };

  const handleSecondaryFilterChange = (value: string) => {
    setSecondaryFilter(value);
    updateUrlParams('inboxSecondaryFilter', value);
  };

  const handleSortChange = (value: string) => {
    setSort(value);
    updateUrlParams('inboxSort', value);
  };

  const handleClearAll = () => {
    setFreeSearch('');
    setFilter(filterOptions[0].value);
    setSecondaryFilter(secondaryFilterOptions[0].value);
    setSort(sortOptions[0].value);
    navigate({ pathname: location.pathname });
  };

  const searchFiltered = useFilteredItems(combinedSequences, freeSearch, ['title', 'content']);

  const filteredItems = useMemo(() => {
    let filtered = searchFiltered;

    if (filter === 'user') {
      filtered = filtered.filter((item) => item.user === userId);
    }

    if (secondaryFilter !== 'all') {
      filtered = filtered.filter((item) => {
        if (secondaryFilter === 'report') {
          return item.isReport;
        }

        return !item.isReport;
      });
    }

    return filtered;
  }, [searchFiltered, filter, secondaryFilter, userId]);

  const sortedItems: SequenceListItem[] = useMemo(() => {
    return filteredItems
      .sort((a, b) => {
        const aTime = moment(formatSequenceItemDate(a?.finishedAt as string)).unix();
        const bTime = moment(formatSequenceItemDate(b?.finishedAt as string)).unix();

        return sort === 'recent' ? bTime - aTime : aTime - bTime;
      })
      .map((item) => {
        const user = shopUsers.find((x) => x.id === item.user);
        return { ...item, userName: user?.display };
      });
  }, [filteredItems, shopUsers, sort]);

  const paginatedItems = useMemo(() => {
    return sortedItems.slice(0, displayCount);
  }, [sortedItems, displayCount]);

  const hasMoreItems = useMemo(() => {
    return displayCount < sortedItems.length;
  }, [displayCount, sortedItems.length]);

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMoreItems) {
          setDisplayCount((prev) => prev + ITEMS_PER_PAGE);
        }
      },
      {
        root: scrollContainerRef.current,
        threshold: 0.1,
      },
    );

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [hasMoreItems]);

  useEffect(() => {
    setDisplayCount(ITEMS_PER_PAGE);
  }, [filter, secondaryFilter, freeSearch, sort]);

  return (
    <div
      ref={scrollContainerRef}
      className="flex flex-col sm:max-w-[800px] h-full no-scrollbar overflow-y-scroll m-auto"
    >
      <div className="hidden sm:block p-8 sm:pt-12">
        <div className="w-full flex flex-col lg:flex-row gap-6.5">
          <div className="w-full flex items-center gap-4">
            <WillySearchInput
              value={freeSearch}
              onChange={(v) => setFreeSearch(v)}
              placeholder="Search All Workflows"
              className="!p-0 overflow-visible w-full"
            />
            {hasSearchFilterOrSort && (
              <div className="flex-shrink-0">
                <Button variant="white" onClick={handleClearAll}>
                  Clear
                </Button>
              </div>
            )}
          </div>
          <div className="flex items-center lg:justify-between flex-shrink-0 gap-4">
            <WillySelect data={filterOptions} value={filter} onChange={handleFilterChange} />
            {/* <WillySelect
              data={secondaryFilterOptions}
              value={secondaryFilter}
              onChange={handleSecondaryFilterChange}
            /> */}
            <WillySelect data={sortOptions} value={sort} onChange={handleSortChange} />
          </div>
        </div>
      </div>

      <div className="overflow-auto flex-grow sm:p-4">
        {sortedItems.length === 0 ? (
          <div className="p-4">No workflows found</div>
        ) : (
          <div className="flex flex-col gap-6.5 sm:gap-4">
            {paginatedItems.map((item, i) => (
              <SequenceListCard
                item={item}
                index={i}
                key={item.id + i}
                workflowCategories={workflowCategories}
                onItemClick={() => {
                  navigate({
                    pathname: `/workflows/view/${item.id}`,
                    search: item.outputFile
                      ? `?fileName=${item.outputFile.includes('http') ? btoa(item.outputFile) : item.outputFile}&from=feed`
                      : '?from=feed',
                  });
                }}
              />
            ))}

            {hasMoreItems && (
              <div ref={loadMoreRef} className="flex items-center justify-center h-12">
                <Loader size="sm" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
