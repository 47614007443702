import { useSelector } from 'react-redux';
import { RootState } from '../reducers/RootType';
import { useAppDispatch } from '../index';
import { Button, Text, Modal, Flex } from '@tw/ui-components';
import { closeRedirectNotice } from 'ducks/shopIntegrations';

export const AuthRedirectNotice = () => {
  const dispatch = useAppDispatch();
  const redirectNoticeIsOpen = useSelector(
    (state: RootState) => state.integrations.redirectNoticeIsOpen,
  );

  const providerName = useSelector(
    (state: RootState) => state.integrations.redirectNoticeProviderName,
  );

  return (
    <Modal
      opened={redirectNoticeIsOpen}
      title={<Text>Connect {providerName}</Text>}
      onClose={() => dispatch(closeRedirectNotice())}
      headerBorder={true}
    >
      <Text pt="md">{`You are being redirected to ${providerName} to connect your account...`}</Text>
      <Modal.Footer>
        <Flex justify="right">
          <Button variant="secondary" onClick={() => dispatch(closeRedirectNotice())}>
            Cancel
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
