import { influencer, SelectableInfluencer } from 'types/Influencers';

type Actions =
  | { type: 'add'; influencers: Array<influencer> | any }
  | { type: 'replace_all'; influencers: Array<influencer> | any }
  | { type: 'replace'; influencer: influencer }
  | { type: 'add_creatives'; influencer: influencer; creatives: Array<any> }
  | { type: 'delete'; influencer: influencer }
  | { type: 'toggle_select'; influencer: SelectableInfluencer }
  | { type: 'toggle_active'; influencer: SelectableInfluencer };

export const influencerReducer = (influencers: Array<influencer>, action: Actions) => {
  switch (action.type) {
    case 'add':
      const addInfluencers = action.influencers.map((influencer) => ({
        ...influencer,
        selected: false,
      }));
      return [...influencers, ...addInfluencers];
    case 'replace_all':
      const updatedInfluencers = action.influencers.map((influencer) => ({
        ...influencer,
        selected: influencer.selected || false,
      }));
      return updatedInfluencers;
    case 'replace':
      const influencerIndex = influencers.findIndex((inf) => inf.id === action.influencer.id);
      return [
        ...influencers.slice(0, influencerIndex),
        {
          ...action.influencer,
          selected: false,
        },
        ...influencers.slice(influencerIndex + 1),
      ];
    case 'delete':
      const index = influencers.findIndex((infl) => infl.id === action.influencer.id);
      let newInfluencers = [...influencers];
      newInfluencers.splice(index, 1);
      return newInfluencers;
    case 'toggle_select':
      const inf_index = influencers.findIndex((infl) => infl.id === action.influencer.id);
      return [
        ...influencers.slice(0, inf_index),
        {
          ...action.influencer,
          selected: !action.influencer.selected,
          active: true,
        },
        ...influencers.slice(inf_index + 1),
      ];
    case 'toggle_active':
      const sel_index = influencers.findIndex((infl) => infl.id === action.influencer.id);
      return [
        ...influencers.slice(0, sel_index),
        {
          ...action.influencer,
          active: !action.influencer.active,
        },
        ...influencers.slice(sel_index + 1),
      ];
  }
};
