import { type RootState } from 'reducers/RootType';
import { AnyAction, combineReducers, Dispatch } from 'redux';

import {
  Question,
  PostPurchaseSurveyQuestionConfig,
  PostPurchaseSurveyConfigResponse,
} from '@tw/types';
import axiosInstance from 'utils/axiosInstance';
import { AxiosResponse } from 'axios';
import moment from '@tw/moment-cached';

export const POST_PURCHASE_SURVEY_QUESTION_DATA_RECEIVED =
  'POST_PURCHASE_SURVEY_QUESTION_DATA_RECEIVED';
export const POST_PURCHASE_SURVEY_CONFIG_RECEIVED = 'POST_PURCHASE_SURVEY_CONFIG_RECEIVED';
export const POST_PURCHASE_SURVEY_CONFIG_LOADING = 'POST_PURCHASE_SURVEY_CONFIG_LOADING';
export const POST_PURCHASE_SURVEY_QUESTION_DATA_LOADING =
  'POST_PURCHASE_SURVEY_QUESTION_DATA_LOADING';
export const POST_PURCHASE_SURVEY_REFRESH = 'POST_PURCHASE_SURVEY_REFRESH';
export const FETCH_SURVEY_DATA = 'FETCH_SURVEY_DATA';

export const fetchSurveyData = (shopId: string) => {
  return async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(`v2/survey/question?shop=${shopId}`);
      return dispatch({
        type: FETCH_SURVEY_DATA,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const postPurchaseSurveyGetConfig = (surveyId: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: POST_PURCHASE_SURVEY_CONFIG_LOADING });
    const url = `v2/survey/${surveyId}/config`;
    const { data } = await axiosInstance.get<any, AxiosResponse<PostPurchaseSurveyConfigResponse>>(
      url,
    );
    // ensure "other" option being at the bottom
    for (const page of data.config) {
      const { options } = page;
      const otherOptionIndex = options.findIndex((opt) => opt.value === 'other');
      // if other option exists but is not the last one, re-order
      if (otherOptionIndex > -1 && options[otherOptionIndex]?.order < 100) {
        const newOptions = [
          ...options.slice(0, otherOptionIndex),
          ...options.slice(otherOptionIndex + 1),
          {
            ...options[otherOptionIndex],
            order: 100,
          },
        ];
        page.options = newOptions;
      }
    }
    dispatch({ type: POST_PURCHASE_SURVEY_CONFIG_RECEIVED, config: data });
  };
};

export const postPurchaseSurveyGetQuestionData = (
  questionId: number,
  { start, end }: { start: string; end: string },
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: POST_PURCHASE_SURVEY_QUESTION_DATA_LOADING });
    const url = `v2/survey/question/${questionId}/answers`;
    const { data: question } = await axiosInstance.get<any, AxiosResponse<Question>>(url, {
      params: { start, end },
    });
    dispatch({ type: POST_PURCHASE_SURVEY_QUESTION_DATA_RECEIVED, question });
  };
};

export const getPurchaseSurveyGetQuestionData = async (
  questionId: number,
  { start, end }: { start: string; end: string },
) => {
  const url = `v2/survey/question/${questionId}/fullAnswers`;
  const { data: question } = await axiosInstance.get<any, AxiosResponse<Question>>(url, {
    params: { start, end },
  });
  return question;
};

export const postPurchaseSurveyRefresh = () => ({
  type: POST_PURCHASE_SURVEY_REFRESH,
  payload: moment(),
});

const postPurchaseSurveyQuestionData = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case POST_PURCHASE_SURVEY_QUESTION_DATA_RECEIVED:
      return action.question;

    default:
      return state;
  }
};

const postPurchaseSurveyConfig = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case POST_PURCHASE_SURVEY_CONFIG_RECEIVED:
      return action.config;
    default:
      return state;
  }
};

const postPurchaseSurveyQuestionConfigLoading = (state = false, action: AnyAction) => {
  switch (action.type) {
    case POST_PURCHASE_SURVEY_CONFIG_RECEIVED:
      return false;
    case POST_PURCHASE_SURVEY_CONFIG_LOADING:
      return true;

    default:
      return state;
  }
};

const postPurchaseSurveyQuestionDataLoading = (state = false, action: AnyAction) => {
  switch (action.type) {
    case POST_PURCHASE_SURVEY_QUESTION_DATA_RECEIVED:
      return false;
    case POST_PURCHASE_SURVEY_QUESTION_DATA_LOADING:
      return true;

    default:
      return state;
  }
};

const postPurchaseSurveyLastUpdate = (state = moment(), action) => {
  switch (action.type) {
    case POST_PURCHASE_SURVEY_REFRESH:
      return action.payload;
    default:
      return state;
  }
};

const postPurchasefetchSurveyData = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SURVEY_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  question: postPurchaseSurveyQuestionData,
  questionDataLoading: postPurchaseSurveyQuestionDataLoading,
  config: postPurchaseSurveyConfig,
  configLoading: postPurchaseSurveyQuestionConfigLoading,
  lastUpdate: postPurchaseSurveyLastUpdate,
  surveyData: postPurchasefetchSurveyData,
});
