import Api from 'utils/Api';
import axiosInstance from 'utils/axiosInstance';

export const PIXEL_HEALTH_RECEIVED = 'PIXEL_HEALTH_RECEIVED';
export const LOADING_PIXEL_HEALTH = 'LOADING_PIXEL_HEALTH';

const pixelStatsEndpoint = 'https://us-central1-triplewhale-pixel.cloudfunctions.net/health';

export const loadPixelHealth = () => {
  return (dispatch, shopId, start, end) => {
    dispatch({ type: LOADING_PIXEL_HEALTH });
    fetch(
      `${pixelStatsEndpoint}?shop=${shopId}${start && end ? `&start=${start}&end=${end}` : ''}`,
    ).then((val) => {
      val.json().then((data) => {
        dispatch({ type: PIXEL_HEALTH_RECEIVED, payload: { pixelStats: data[0] } });
      });
    });
    axiosInstance
      .post('v2/pixel/pixel-installation/checkStatus', { shopDomain: shopId })
      .then((pixelInstallStatus) => {
        dispatch({
          type: PIXEL_HEALTH_RECEIVED,
          payload: { pixelInstallationStatus: pixelInstallStatus },
        });
      });
  };
};

const pixelHealth = (state = { loading: true }, action) => {
  switch (action.type) {
    case LOADING_PIXEL_HEALTH:
      if (state.loading) {
        return state;
      }
      return { ...state, loading: true };
    case PIXEL_HEALTH_RECEIVED:
      return { ...state, ...action.payload, loading: false };
    default:
      return state;
  }
};

export const reducers = {
  pixelHealth,
};
