const MicrosoftIconSVG = ({ small }) => (
  <svg
    width={small ? '15' : '20'}
    height={small ? '15' : '20'}
    viewBox="0 0 600 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M285.103 285.103H0V0H285.103V285.103Z" fill="#F1511B" />
    <path d="M599.889 285.103H314.791V0H599.889V285.103Z" fill="#80CC28" />
    <path d="M285.095 600H0V314.9H285.095V600Z" fill="#00ADEF" />
    <path d="M599.889 600H314.791V314.9H599.889V600Z" fill="#FBBC09" />
  </svg>
);

export default MicrosoftIconSVG;
