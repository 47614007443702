import { useAppDispatch } from 'index';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { Spinner } from '@shopify/polaris';
import { updateSelectedStatsServcies } from 'ducks/actions';
import { ServicesIds } from '@tw/types/module/services';
import { loadingNewStats } from 'ducks/newStats';
import { confirmationModal, confirmationModalClose } from 'ducks/confirmationModal';
import { Checkbox, Text } from '@tw/ui-components';
import { InfoPopover } from './Willy/WillyWidgetEditor/InfoPopover';

export const StatsFilterBar = () => {
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const { industry, aovSegment, spendSegment } = useContext(SummaryContext);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const selectedStatsServcies: ServicesIds[] = useSelector(
    (state: RootState) => state.selectedStatsServcies,
  );
  const UpdateVal = useCallback(
    (val) => {
      setIsChecked(val);
      dispatch(
        loadingNewStats({
          tripleStats: true,
        } as any),
      );
      setIsLoading(true);
      const services: ServicesIds[] = val ? ['amazon'] : [];
      dispatch(updateSelectedStatsServcies(services));
    },
    [dispatch],
  );

  const confirmUpdateIncludeAmazon = useCallback(
    async (val) => {
      dispatch(
        confirmationModal({
          onConfirm: () => UpdateVal(val),
          onCancel: () => dispatch(confirmationModalClose()),
          text: `Are you sure you want to ${val ? 'include' : 'exclude'} Amazon Data?`,
          confirmButtonText: 'Yes',
          title: `Confirm ${val ? 'Include' : 'Exclude'} Amazon Data`,
        }),
      );
    },
    [UpdateVal, dispatch],
  );

  useEffect(() => {
    setIsChecked((selectedStatsServcies || []).includes('amazon'));
    dispatch(
      loadingNewStats({
        ['tripleStats']: false,
      } as any),
    );
    setIsLoading(false);
  }, [selectedStatsServcies, mainDatePickerSelectionRange, dispatch]);
  const tooltipText =
    'By checking this box, Amazon data will be factored into the following metrics in the "Custom Metrics" section: Net Profit, Blended ROAS, Blended Ad Spend, NC-ROAS';
  return (
    <>
      <div className="flex items-center gap-4 flex-wrap">
        {isLoading && <Spinner size="small" />}
        <Checkbox
          label={
            <Text size="sm" weight={500} color="named2.0">
              Include Amazon Data
            </Text>
          }
          checked={isChecked}
          onChange={confirmUpdateIncludeAmazon}
        />
        <InfoPopover description={tooltipText} />
        {/*<AmazonNotice />*/}
      </div>
    </>
  );
};
