import { Tooltip } from '@shopify/polaris';
import { CancelMinor } from '@shopify/polaris-icons';
import TWImage from 'components/library/TWImage/TWImage';
import React, { useEffect, useState } from 'react';
import { formatNumber } from 'utils/formatNumber';
import { ReactComponent as ArchiveMajor } from '../../icons/archive_major.svg';
import { toggleInventoryAlert } from '../../utils/Attribution/adInventoryUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { Loader } from '@tw/ui-components';
import { DraggableProvider, useDraggable } from 'components/DraggableProvider/DraggableProvider';
interface AttributionSkusModalProps {
  showAdSkusModal: { show: boolean; skuData: any[] };
  setShowAdSkusModal: React.Dispatch<React.SetStateAction<{ show: boolean; skuData: any[] }>>;
}

const Handle = ({ campaignName }) => {
  const { startDrag } = useDraggable();
  return (
    <div className={`text-center cursor-grab active:cursor-grabbing`}>
      <div onMouseDown={startDrag}>
        <ArchiveMajor width={42} height={42} />
      </div>
      <div className={`min-w-[unset] flex items-center gap-4 px-6.5 pr-[45px]`}>
        <div className={`text-[24px] leading-[30px] font-medium w-full text-center`}>
          <span className={`pl-10`}>Inventory Notice</span>
        </div>
      </div>
      <div className={`text-center text-[#6F95B3] p-6`}>{campaignName}</div>
    </div>
  );
};

const AttributionSkusModal = (props: AttributionSkusModalProps) => {
  const {
    showAdSkusModal: { show, skuData },
    setShowAdSkusModal,
  } = props;
  const [savingInventoryAlert, setSavingInventoryAlert] = React.useState<boolean>(false);
  const [refreshOnClose, setRefreshOnClose] = React.useState<boolean>(false);
  const [displaySkuData, setDisplaySkuData] = useState<any[]>(skuData);
  const shopId = useSelector((state: RootState) => state.currentShopId);
  const campaignName = skuData[0]?.campaignName;
  const showInventory = skuData.some((sku) => sku.type !== 'out');

  useEffect(() => {
    setDisplaySkuData(skuData);
  }, [skuData]);

  if (!displaySkuData) return null;

  const disableAlert = async ({ shopId, skuId, value }) => {
    setSavingInventoryAlert(true);
    await toggleInventoryAlert({ shopId, skuId, value });
    setDisplaySkuData((prev) => {
      return prev.map((sku) => {
        if (sku.skuId === skuId) {
          return {
            ...sku,
            hideSku: true,
          };
        }
        return sku;
      });
    });
    setRefreshOnClose(true);
    setSavingInventoryAlert(false);
  };

  const onClose = () => {
    setShowAdSkusModal({ show: false, skuData: [] });
    if (refreshOnClose) {
      window.location.reload();
    }
  };

  const renderNumberValue = (value, style) =>
    formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style,
    });

  const colHeaderClassNames = `table-cell z-10 sticky top-0 align-middle px-2 text-center bg-[#1A3046]`;
  const columns = ['SKU', 'Sell Out Date', 'Sold Last 30 Days', 'Avg Per Day', ''];
  if (showInventory) {
    columns.splice(1, 0, 'Inventory');
  }
  return (
    <DraggableProvider
      usingDragger={true}
      initialPosition="center center"
      className="draggable-card scroll-smooth bg-primary  outline outline-1 outline-light-blue/10 
        shadow-slate-800 rounded-lg shadow-xl text-white relative  py-6.5
        max-h-[calc(80vh-50px)] max-w-[calc(80vw-50px)] overflow-auto gap-6.5"
    >
      {' '}
      <CancelMinor
        className={`absolute right-0 top-0 mt-2 mr-2 w-12 h-12 fill-[#C0D6EA] 
                  cursor-pointer p-1 rounded-md outline outline-1 outline-light-blue/10 
                  hover:outline-light-blue/20 hover:bg-[#c0d6ea]/5 
                  hover:shadow-[1px_6px_9px_0_rgba(0,0,0,0.13)]`}
        onClick={onClose}
      />
      <div>
        <Handle {...{ campaignName }} />

        <div className={`px-6.5`}>
          <div className={`tw-scroll h-[300px] overflow-auto `}>
            <div className={`table align-middle w-full`}>
              <div className={`text-[#6F95B3] text-[14px] font-medium table-row h-[40px]`}>
                {columns.map((name, index) => {
                  const classNames =
                    name === ''
                      ? `${colHeaderClassNames} rounded-[0_4px_4px_0]`
                      : `${colHeaderClassNames} ${index === 0 ? 'rounded-[4px_0_0_4px]' : ''}`;
                  return (
                    <div key={name + '_' + index} className={classNames}>
                      {name}
                    </div>
                  );
                })}
              </div>
              <div className={`table-row-group`}>
                {displaySkuData?.map((item, index) => {
                  const {
                    avg30Days,
                    total30Days,
                    image,
                    productTitle,
                    title,
                    sku,
                    skuId,
                    dateStockRunsOut,
                    daysInStock,
                    itemsInInventory,
                    hideSku,
                  } = item;

                  const textColor = savingInventoryAlert || hideSku ? 'text-gray-600 italic' : '';
                  return (
                    <div key={skuId} className={`${textColor} h-[50px] table-row text-[14px]`}>
                      <div
                        className={`overflow-hidden text-ellipsis whitespace-nowrap table-cell align-middle px-4 rounded-[4px_0_0_4px] ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        <Tooltip
                          content={productTitle}
                          preferredPosition={`above`}
                          dismissOnMouseOut
                        >
                          <div className={`flex-shrink-0 flex items-center`}>
                            <TWImage
                              src={image}
                              alt={productTitle}
                              className={`rounded-md flex items-center`}
                              width={36}
                              height={36}
                            />
                            <span className={`text-xl text-ellipsis overflow-hidden pl-4`}>
                              {title} - {sku}
                            </span>
                          </div>
                        </Tooltip>
                      </div>

                      {showInventory && (
                        <div
                          className={`table-cell align-middle px-2 text-center ${
                            index % 2 ? 'bg-white/4' : ''
                          }`}
                        >
                          {itemsInInventory}
                        </div>
                      )}
                      <div
                        className={`table-cell align-middle px-2 text-center ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        {' '}
                        <div
                          title={`${daysInStock ?? 0} days of stock on hand`}
                          className="flex items-center gap-3"
                        >
                          <div
                            className={`w-3 h-3 rounded-full ${
                              itemsInInventory === 0 ? 'bg-red-600' : 'bg-yellow-500'
                            }`}
                          />{' '}
                          {dateStockRunsOut ?? 'Out of stock'}
                        </div>
                      </div>
                      <div
                        className={`table-cell align-middle px-4 text-center rounded-[0_4px_4px_0] ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        {renderNumberValue(total30Days, 'decimal')}
                      </div>
                      <div
                        className={`table-cell align-middle px-4 text-center rounded-[0_4px_4px_0] ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        {renderNumberValue(avg30Days, 'decimal')}
                      </div>
                      <div
                        className={`table-cell align-middle px-4 text-center rounded-[0_4px_4px_0] ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        <div
                          className={`flex justify-center ${
                            !hideSku
                              ? 'cursor-pointer text-blue-500 hover:text-blue-600 active:text-blue-800 '
                              : textColor
                          } `}
                          onClick={() => {
                            !hideSku && disableAlert({ shopId, skuId, value: -1 });
                          }}
                        >
                          {savingInventoryAlert ? (
                            <div className="min-w-[111px] pl-8  ">
                              <Loader />
                            </div>
                          ) : (
                            <>{`${!hideSku ? "Don't Remind Me" : 'Ignored'}`}</>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DraggableProvider>
  );
};

export default AttributionSkusModal;
