import { ShopIntegrationStatusEnum } from '@tw/types/module/types/ShopProviders';
import { Box, Flex } from '@tw/ui-components';
type Props = {
  status: ShopIntegrationStatusEnum | string;
};
export const IntegrationStatusIcon: React.FC<Props> = ({ status }) => {
  const integrationStatusMap: Record<
    ShopIntegrationStatusEnum | string,
    { colorClass: string; title: string }
  > = {
    ready: { colorClass: 'green', title: 'Active' },
    retryableError: { colorClass: 'green', title: 'Active' },
    error: { colorClass: 'red-500', title: 'Error' },
    pending: { colorClass: 'orange-400', title: 'Pending' },
    backfill: { colorClass: 'blue-500', title: 'Backfill' },
    disconnected: { colorClass: 'gray-400', title: 'Disconnected' },
    paused: { colorClass: 'gray-400', title: 'Paused' },
    unknown: { colorClass: 'gray-400', title: 'Inactive' },
    deleted: { colorClass: 'gray-400', title: 'Deleted' },
    active: { colorClass: 'green', title: 'Active' },
    inactive: { colorClass: 'gray-400', title: 'Inactive' },
  };

  return (
    <Flex align="center">
      <div
        className={`w-3 h-3 rounded-full mb-2 ml-5 mr-3 bg-${integrationStatusMap[status].colorClass}`}
      ></div>
      <span className={`text-[14px] mb-2 text-${integrationStatusMap[status].colorClass}`}>
        {integrationStatusMap[status].title}
      </span>
    </Flex>
  );
};
