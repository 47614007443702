import TWImage from 'components/library/TWImage/TWImage';
import { BaseColumn } from 'components/library/TWTable/types';
import { metrics } from 'constants/metrics/metrics';
import allServices from 'constants/services';
import { useContext } from 'react';
import Select from 'react-select';
import { SelectableInfluencer } from 'types/Influencers';
import { MetricsKeys } from 'types/metrics';
import copyToClipboard from 'utils/copyToClipboard';
import { formatNumber } from 'utils/formatNumber';
import { filterCreatives } from 'utils/influencers';
import { DeleteMinor, PlayCircleMajor, QuestionMarkMinor } from '@shopify/polaris-icons';
import { ReactComponent as InfluencerIcon } from '../../../icons/affluencer-hub.svg';
import { ReactComponent as Edit } from '../../../icons/influencer_edit.svg';
import { ReactComponent as LineChartIcon } from '../../../icons/line-chart.svg';
import { ReactComponent as Link } from '../../../icons/link.svg';
import { InfluencersContext } from '../context';
import { OrdersButton } from 'components/attribution-new/OrdersButton';
import PixelIconSVG from 'components/PixelIconSVG';
import { Checkbox, Title, Tooltip } from '@tw/ui-components';
import DropDown from 'components/ltv/DropDown';
import { UNMATCHED_ROWS_ID } from 'constants/attribution';

export type AffluencerColumnsKeys =
  | 'select'
  | 'influencer'
  | 'discount_code'
  | 'creative'
  | 'url'
  | 'source'
  | 'affluencerSpend';

const baseColumns: BaseColumn<SelectableInfluencer, AffluencerColumnsKeys>[] = [
  {
    key: 'select',
    name: 'Select',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    isFixed: true,
    Heading: () => (
      <div>
        <LineChartIcon />
      </div>
    ),
    Value: (influencer) => {
      const { toggleSelected } = useContext(InfluencersContext);

      return (
        <Checkbox checked={influencer.selected} onChange={() => toggleSelected!(influencer)} />
      );
    },
  },
  {
    key: 'influencer',
    name: 'Influencer',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    isFixed: true,
    Heading: () => <div style={{ width: '200px' }}>Affluencer</div>,
    Value: (influencer) => {
      const { editInfluencer, deleteInfluencer } = useContext(InfluencersContext);
      return (
        <div className="flex gap-4 items-center w-80 table-preview group">
          <TWImage
            style={{
              opacity: influencer.active ? '1' : '0.4',
            }}
            className="w-full h-full object-cover rounded-md cursor-pointer"
            wrapperClass="flex-shrink-0 w-14 h-14"
            src={
              influencer.profile_photo ? influencer.profile_photo.url : '../profile_placeholder.png'
            }
            alt={influencer.name || 'creative-asset'}
          />
          <p className="font-medium" style={{ overflowWrap: 'anywhere' }}>
            {influencer.id === UNMATCHED_ROWS_ID ? 'Unmatched' : influencer.name}
          </p>

          <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center gap-4">
            <Edit
              className="cursor-pointer text-light-primary w-8 h-8"
              onClick={() => editInfluencer!(influencer)}
            />
            <div className="flex items-center">
              <DeleteMinor
                width={18}
                height={18}
                className="cursor-pointer fill-light-primary"
                onClick={() => {
                  const res = window.confirm(`Are you sure you want to delete ${influencer.name}?`);
                  if (res) {
                    deleteInfluencer!(influencer);
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    },
    // Total: (totals, metadata) => {
    //   const { filteredRows } = metadata || {};
    //   return typeof filteredRows?.length === 'number' ? (
    //     <div className="px-4 py-2 flex items-center">
    //       (
    //       {'Totals'}
    //       )
    //     </div>
    //   ) : (
    //     <></>
    //   );
    // },
  },
  {
    key: 'discount_code',
    name: 'Discount Codes',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    isFixed: true,
    Heading: () => <div style={{ width: '100px' }}> {'Discount codes'}</div>,
    Value: (influencer) => {
      return (
        <div>
          {(influencer?.discountCodes || []).map((code) => {
            return (
              <div className="flex items-center justify-center">
                <p style={{ wordBreak: 'break-word' }}>{code.code}</p>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    key: 'creative',
    name: 'Creative',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    isFixed: true,
    Heading: () => {
      const { setTableCreativeFilterBy, tableCreativeFilterBy } = useContext(InfluencersContext);
      const options = [
        { label: 'Image + Video', value: 'all' },
        { label: 'Image', value: 'photo' },
        { label: 'Video', value: 'video' },
      ];

      return (
        <div style={{ textAlign: 'left', minWidth: '160px' }}>
          <DropDown
            options={options}
            value={tableCreativeFilterBy}
            handleSelect={(selected) => setTableCreativeFilterBy!(selected)}
          />
        </div>
      );
    },
    Value: (influencer) => {
      const { showLightbox, tableCreativeFilterBy } = useContext(InfluencersContext);
      if (influencer.creatives && influencer.creatives.length) {
        const filteredCreatives = filterCreatives(influencer.creatives, tableCreativeFilterBy!);
        const hasMore = filteredCreatives.length > 3;
        const moreElem = hasMore ? (
          <span className="font-bold text-white text-3xl cursor-pointer">
            +{filteredCreatives.length - 3}
          </span>
        ) : (
          <></>
        );

        return (
          <div className="flex gap-2">
            {filteredCreatives.slice(0, 3).map((creative, i) => {
              return (
                <TWImage
                  key={i}
                  className="w-full h-full object-cover rounded-md cursor-pointer"
                  wrapperClass="w-32 h-32"
                  src={creative.thumbnail || creative.url}
                  onClick={() => showLightbox!(filteredCreatives, i)}
                  customImgStyle={i === 2 && hasMore ? { filter: 'brightness(0.7)' } : {}}
                  icon={
                    creative.type === 'video' ? (
                      <div className="fill-white cursor-pointer flex gap-2">
                        <PlayCircleMajor className="text-light-blue" width={24} height={24} />
                        {i === 2 ? moreElem : <></>}
                      </div>
                    ) : i === 2 ? (
                      moreElem
                    ) : (
                      <></>
                    )
                  }
                />
              );
            })}
          </div>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    key: 'url',
    name: 'URL',
    sortable: false,
    dataType: 'text',
    isDefault: true,
    isFixed: true,
    Heading: () => <div style={{ width: '300px' }}>Links</div>,
    Value: (influencer) => {
      const { copiedToClipboard, toggleCopiedToClipboard, setCopiedToClipboard } =
        useContext(InfluencersContext);
      if (influencer.links?.length) {
        return (
          <div>
            {influencer.links?.map((link, i) => {
              return (
                <div className="flex gap-5 align-middle items-center">
                  <Title size={'medium'}>
                    {link.campaign || 'Default'} | {link.source}
                  </Title>
                  <Tooltip label={copiedToClipboard ? 'Copied!' : 'Copy to Clipboard'}>
                    <div className="link flex items-center justify-center h-6">
                      <Link
                        width={14}
                        height={14}
                        className="cursor-pointer text-light-primary"
                        onMouseLeave={() => setCopiedToClipboard!(false)}
                        onClick={() => {
                          copyToClipboard(link?.link || link?.url);
                          toggleCopiedToClipboard!();
                        }}
                      ></Link>
                    </div>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        );
      }
      return <></>;
    },
  },
];

const metricColumns: BaseColumn<SelectableInfluencer, MetricsKeys>[] = Object.values(metrics)
  .filter((m) => m.showInInfluencersHub)
  .map((metric) => ({
    key: metric.key,
    name: metric.label,
    isDefault: true,
    sortable: true,
    isFixed: false,
    dataType: 'text',
    Heading: () => {
      return (
        <div className="header-wrapper flex items-center gap-4">
          {metric.key === 'spend' ? (
            <InfluencerIcon width={18} height={18} />
          ) : (
            <PixelIconSVG small={true} />
          )}
          {metric.key === 'spend' ? 'Affluencer Spend' : metric.shortLabel}
          {metric.key !== 'spend' && (
            <span className="absolute left-0 top-0">
              <Tooltip label={metric.Tip({ sourceId: 'ads' })}>
                <span>
                  <QuestionMarkMinor width={14} height={14} className="opacity-50" />
                </span>
              </Tooltip>
            </span>
          )}
        </div>
      );
    },
    Value: (influencer) => {
      const { currency } = useContext(InfluencersContext);
      const valueStr = formatNumber(
        influencer.metrics ? influencer.metrics![metric.key] || 0 : influencer?.[metric?.key] || 0,
        {
          style: metric.format,
          currency,
          maximumFractionDigits: metric.toFixed,
          minimumFractionDigits: metric.toFixed,
        },
      );
      return [
        'pixelPurchases',
        'pixelConversionValue',
        'pixelRoas',
        'pixelNcPurchases',
        'pixelNcRoas',
      ].includes(metric.key) ? (
        <>
          {
            <div className={'flex items-center justify-center'}>
              <OrdersButton
                item={
                  {
                    serviceId: 'influencers',
                    ...influencer,
                    name: influencer.name!,
                    id: influencer.name || influencer.id,
                    unmatchedIds: influencer.unmatchedIds,
                    ...influencer.metrics,
                    entity: 'campaign',
                  } as any
                }
                metricKey={metric.key}
                showRoasButton={false}
                showOrdersButton={true}
              />
            </div>
          }
        </>
      ) : (
        <div className="flex items-center justify-center">{valueStr}</div>
      );
    },
    // Total: (totals, metadata) => {
    //   const { currency = 'USD', mainDatePickerSelectionRange, filteredRows } = metadata || {};
    //   const value = metric?.calculateSum?.(filteredRows || []) || 0;
    //   let val = (
    //     <>
    //       {
    //         formatNumber(value, {
    //           currency,
    //           style: metric.format,
    //           minimumFractionDigits: typeof metric.minimumFractionDigits !== 'undefined' ? metric.minimumFractionDigits : metric.toFixed,
    //           maximumFractionDigits: metric.toFixed,
    //         })
    //       }
    //     </>
    //   );

    //   const valueColor =
    //     (metric.key !== 'pixelProfit' && metric.key !== 'pixelCvDelta') || !value
    //       ? 'text-inherit'
    //       : value < 0
    //         ? 'text-negative'
    //         : value > 0
    //           ? 'text-green'
    //           : 'text-inherit';

    //   if (
    //     metric.key === 'pixelUniqueVisitors' &&
    //     mainDatePickerSelectionRange?.start.isBefore(UNIQUE_VISITORS_RELEASE_DATE)
    //   ) {
    //     val = (
    //       <Tooltip content="Unique visitors is available from September 6 2022">
    //         <span>
    //           <QuestionMarkMinor width={14} height={14} className="opacity-50" />
    //         </span>
    //       </Tooltip>
    //     );
    //   }
    //   return (
    //     <div className={'px-4 py-2 flex items-center justify-center ' + valueColor}>{val}</div>
    //   );
    // },
  }));

export const columns = [...baseColumns, ...metricColumns];
