import { Card, ProgressBar, TextStyle } from '@shopify/polaris';
import moment from '@tw/moment-cached/module/timezone';
import React from 'react';
import { ClientBaseChannel } from 'types/services';

type BackgroundImportingProps = {
  service: ClientBaseChannel<any>;
  count: number;
  total: number;
  onDone?: any;
  job?: any;
};

const BackgroundImporting: React.FC<BackgroundImportingProps> = ({
  service,
  count,
  total,
  onDone,
  job,
}) => {
  const safeValue = !isFinite((count / total) * 100) ? 100 : (count / total) * 100;

  let diff: moment.Duration | null = null;
  if (job?.createdAt) {
    moment.tz.setDefault('Etc/UTC');
    const elapsedTime = moment(job.updatedAt).utc().diff(moment(job.createdAt), 'seconds');
    const secondsPerDay = elapsedTime / count;
    const remainingTime = secondsPerDay * (job.total - job.currentIndex);
    const now = moment();
    const nowPlusRemainingTime = moment().add(remainingTime, 'seconds');
    diff = moment.duration(nowPlusRemainingTime.diff(now));
  }
  return (
    <Card
      sectioned
      title={
        <TextStyle variation="subdued">
          {[service.icon].map((I: any) => (
            <I small key={I} />
          ))}{' '}
          {service.title}
        </TextStyle>
      }
    >
      <ProgressBar size="small" progress={safeValue} />
      {diff !== null && (
        <TextStyle variation="subdued">
          <span>Remaining time: </span>
          {diff.asSeconds() <= 0 && 'Unknown'}
          {diff.asSeconds() > 0 && diff.humanize()}
        </TextStyle>
      )}
    </Card>
  );
};

export default BackgroundImporting;
