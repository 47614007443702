import amplitude from 'amplitude-js';
import CreativeCard from 'components/CreativesCockpit/CreativeCard/CreativeCard';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import { ColumnsSelector } from 'components/library/TWTable/ColumnSelector';
import DropDown from 'components/ltv/DropDown';
import {
  CreativeTypesElements,
  TW_CREATIVE_COLUMNS,
  TW_CREATIVE_PAGE_SIZE,
  TW_CREATIVE_SORT_BY,
} from 'constants/creativeCockpit';
import { TW_PROD_ANALYTICS_COLUMNS, TW_PROD_ANALYTICS_SORT_BY } from 'constants/productAnalytics';
import { metrics as METRICS } from 'constants/metrics/metrics';
import { FC, useContext, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SortingDimension } from 'types/creativeCockpit';

import { Layout, SortDirection, Spinner, Stack } from '@shopify/polaris';
import { NotificationMajor, SettingsMinor } from '@shopify/polaris-icons';
import { ReactComponent as ExportMinor } from 'icons/export_minor.svg';
import { CreativesCockpitContext } from '../context';
import { columnsToAddOnlyInProductAnalytics } from '../CreativeTable/columns';
import CreativeTable from '../CreativeTable/CreativeTable';
import SettingsButton from 'components/SettingsButton';
import { DefaultLeadTimeModal } from 'components/ProductAnalytics/DefaultLeadTimeModal';
import GoogleSheetsIconBase64 from 'components/GoogleSheetsIconBase64';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useExport } from 'hooks/useExport';
import { useAppDispatch } from 'index';
import { GOOGLE_SHEETS_CSV_SAVED } from 'ducks/googleSheets';
import { ExportModal, GoogleSheetsExportModal } from 'components/ProductAnalytics/ExportModal';
import ActiveCurrencyButton from 'components/ActiveCurrencyButton';
import { useColumnsWithCustomMetrics } from './useColumnsWithCustomMetrics';
import { Icon, Text } from '@tw/ui-components';

interface CreativeDashboardProps {
  isShareMode?: boolean;
  sortableColumns?: string[];
  viewFilters?: JSX.Element;
  viewOptions?: JSX.Element;
}

const CreativeDashboard: FC<CreativeDashboardProps> = ({
  isShareMode = false,
  sortableColumns,
  viewFilters,
  viewOptions,
}) => {
  const {
    type,
    serviceId,
    creatives,
    segments,
    sortBy,
    sortDirection,
    loadingCreatives,
    totalCreativesNumber,
    pageSize = TW_CREATIVE_PAGE_SIZE,
    selectedColumns,
    dashboardView,
    setDashboardView,
    serviceName,
    setPageSize,
    setSortBy,
    setSortDirection,
    setPageIndex,
    setSelectedColumns,
    customMetricsType,
  } = useContext(CreativesCockpitContext);
  const { isGoogleSheetsConnected, isSavingGoogleCSV } =
    useSelector((state: RootState) => state) || {};

  const exportSheet = useExport();
  const dispatch = useAppDispatch();
  const [leadTimeModalOpen, setLeadTimeModalOpen] = useState<boolean>(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [sheetsExportModalOpen, setSheetsExportModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [sheetsAccount, setSheetsAccount] = useState<string>('');
  const ID_TAG = type === 'product' ? 'Product-Analytics' : 'Creative-Cockpit';
  const PAGE_NAME = type === 'product' ? 'Product Analytics' : 'Creative Cockpit';
  const STORAGE_KEY_COLS = type === 'product' ? TW_PROD_ANALYTICS_COLUMNS : TW_CREATIVE_COLUMNS;
  const STORAGE_KEY_SORT_BY = type === 'product' ? TW_PROD_ANALYTICS_SORT_BY : TW_CREATIVE_SORT_BY;
  const isCreativeCockpitUI = !!type && !['product', 'sku'].includes(type);

  const metrics = Object.values(METRICS).filter((metric) => {
    return (
      metric.allowOrderBy &&
      (!metric.showInServices ||
        metric.showInServices?.includes(serviceId!) ||
        (columnsToAddOnlyInProductAnalytics.includes(metric.key) && type === 'product')) &&
      (metric.showInCreativeTable?.includes(type!) ||
        metric.showInCreativeTable?.includes('all')) &&
      (!sortableColumns || sortableColumns.includes(metric.key))
    );
  });

  const availableColumns = useColumnsWithCustomMetrics(type!, serviceId!, customMetricsType!);

  const exportCsvParams = useMemo(
    () => ({
      data:
        type !== 'Segments'
          ? (creatives?.slice(-pageSize) as any)
          : (segments?.slice(-pageSize) as any),
      isExportCsv: modalTitle?.includes('CSV'),
      columns: availableColumns,
      keyMap: {
        campaign: 'campaignName',
        adset: 'adsetName',
        type: (data) => {
          return CreativeTypesElements[data.assetType]?.label;
        },
      },
      fileName,
      sheetsAccount,
    }),
    [availableColumns, creatives, fileName, modalTitle, pageSize, segments, sheetsAccount, type],
  );

  const onCloseExportModal = () => {
    setExportModalOpen(false);
    setSheetsExportModalOpen(false);
    dispatch({ type: GOOGLE_SHEETS_CSV_SAVED, payload: '' });
  };

  const settingsItems = [
    {
      content: (
        <Stack.Item>
          <ActiveCurrencyButton theme="none" />
        </Stack.Item>
      ),
      accessibilityLabel: 'Currency',
    },
    {
      content: 'Export CSV',
      icon: ExportMinor,
      onAction: () => {
        setModalTitle('Export CSV');
        setExportModalOpen(true);
        setLeadTimeModalOpen(false);
      },
      accessibilityLabel: 'Export CSV',
    },
    ...(isGoogleSheetsConnected
      ? [
          {
            content: 'Google Sheets',
            icon: GoogleSheetsIconBase64,
            onAction: () => {
              setModalTitle('Export Google Sheets');
              setSheetsExportModalOpen(true);
              setLeadTimeModalOpen(false);
            },
            accessibilityLabel: 'Google Sheets',
          },
        ]
      : []),
    ...(type === 'sku' || type === 'product'
      ? [
          {
            content: 'Set Lead Time',
            icon: NotificationMajor,
            onAction: () => {
              setExportModalOpen(false);
              setLeadTimeModalOpen(true);
            },
          },
        ]
      : []),
  ];

  const pageLimitElement = (
    <>
      {dashboardView !== 'card' && (
        <DropDown
          disabled={isShareMode}
          options={[
            { label: '12', value: 12 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
          ]}
          value={pageSize || 10}
          handleSelect={(val: number) => {
            amplitude.getInstance().logEvent(`${PAGE_NAME}: Data View Rows Per Page Changed`, {
              rowsperpage: val,
            });
            setPageSize!(val);
            localStorage.setItem(TW_CREATIVE_PAGE_SIZE, val.toString());
          }}
        />
      )}
    </>
  );

  const sortTableElement = (
    <>
      {dashboardView === 'card' && (
        <div className="flex gap-4 items-center">
          <Text>Sort By</Text>
          <DropDown
            disabled={isShareMode}
            showFilter
            options={metrics.map((metric) => ({
              label: metric.label,
              value: metric.key,
            }))}
            value={sortBy || 'roas'}
            handleSelect={(val: SortingDimension) => {
              amplitude.getInstance().logEvent(`${PAGE_NAME}: Data View Order Changed`, {
                metric: val,
                order: sortDirection,
              });
              setSortBy!(val);
              localStorage.setItem(STORAGE_KEY_SORT_BY, val);
            }}
          />
          <div className="flex gap-4 items-center">
            <DropDown
              disabled={isShareMode}
              options={[
                { label: 'Ascending', value: 'ascending' },
                { label: 'Descending', value: 'descending' },
              ]}
              value={sortDirection || 'ascending'}
              handleSelect={(val: SortDirection) => {
                amplitude.getInstance().logEvent(`${PAGE_NAME}: Data View Order Changed`, {
                  metric: sortBy,
                  order: val,
                });
                setSortDirection!(val);
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="CreativeDashboard relative" data-testid="CreativeTable">
      {loadingCreatives && (
        <div className="loading-creatives-wrapper">
          <div className="loading-creatives"></div>
        </div>
      )}
      <ExportModal
        showModal={exportModalOpen}
        fileName={fileName}
        setFileName={setFileName}
        title={modalTitle}
        onSave={() => exportSheet(exportCsvParams)}
        loading={isSavingGoogleCSV}
        onClose={onCloseExportModal}
      />
      <GoogleSheetsExportModal
        showModal={sheetsExportModalOpen}
        title={fileName}
        setTitle={setFileName}
        onSave={() => exportSheet(exportCsvParams)}
        loading={isSavingGoogleCSV}
        onClose={onCloseExportModal}
        sheetsAccount={sheetsAccount}
        setSheetsAccount={setSheetsAccount}
      />
      <DefaultLeadTimeModal
        showModal={leadTimeModalOpen}
        onClose={() => setLeadTimeModalOpen(false)}
      />
      <Layout>
        <Layout.Section fullWidth>
          <div className="flex gap-[12px] flex-wrap items-center">
            {viewFilters}
            {!!viewOptions === false && (
              <>
                {sortTableElement}
                {pageLimitElement}
              </>
            )}

            {/* Table/Grid view */}
            <div className="flex ml-auto">
              <BubbleToggleButton
                onClick={() => {
                  setDashboardView('table');
                  setPageIndex?.(0);
                  amplitude.getInstance().logEvent(`${PAGE_NAME}: Data View Type Changed`, {
                    dataviewtype: 'table',
                  });
                }}
                active={dashboardView === 'table'}
                icon="table-view"
              />
              {type !== 'Segments' && (
                <BubbleToggleButton
                  onClick={() => {
                    setDashboardView('card');
                    amplitude
                      .getInstance()
                      .logEvent(`${PAGE_NAME}: Data View Type Changed`, { dataviewtype: 'card' });
                  }}
                  active={dashboardView === 'card'}
                  icon="tiles-view"
                />
              )}
            </div>

            {/* Columns Selector */}
            <div
              id={`att-${ID_TAG}-column-selector`}
              className={`${isShareMode ? 'share-mode' : ''}`}
            >
              <ColumnsSelector
                columns={availableColumns}
                selectedColumns={selectedColumns!}
                setSelectedColumns={setSelectedColumns as any}
                customMetricsType={customMetricsType}
                icon={<Icon name="columns-3-major" size={20} />}
                storageKey={STORAGE_KEY_COLS}
                title={
                  !isShareMode ? (
                    <span className="flex items-center gap-2">
                      <SettingsMinor width={20} height={20} />
                      <span>Select Metrics</span>
                    </span>
                  ) : (
                    ''
                  )
                }
              />
            </div>

            {/* Settings Button */}
            {(dashboardView === 'table' || isCreativeCockpitUI) && (
              <SettingsButton items={settingsItems} />
            )}
          </div>

          {!!viewOptions === true && (
            <div
              className={`flex flex-wrap items-center justify-end gap-4 pt-[16px] mt-[16px] border-t border-solid border-[#D1D5DB] border-0 ${
                isShareMode ? 'share-mode' : ''
              }`}
            >
              {viewOptions}
              <div className="ml-auto"></div>
              {sortTableElement}
              {pageLimitElement}
            </div>
          )}
        </Layout.Section>

        {dashboardView === 'card' && (
          <Layout.Section>
            <InfiniteScroll
              dataLength={creatives?.length || 0}
              next={() => setPageIndex?.((index) => index + 1)}
              loader={
                <div className="flex justify-center items-center">
                  <Spinner size="small" />
                </div>
              }
              hasMore={creatives!.length > 0 && totalCreativesNumber! > creatives!.length}
              scrollThreshold={0.9}
              height={'85vh'}
              endMessage={
                <p className="flex items-center justify-center p-4 no-more-orders-msg">
                  We didn't find any {totalCreativesNumber! > 0 ? 'more ' : ''} {serviceName}{' '}
                  {CreativeTypesElements[type!]?.label}
                  {type === 'product' ? 's ' : ' creatives '}
                  for this date range
                </p>
              }
            >
              <div className="creative-cockpit-cards grid gap-6.5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 justify-items-stretch">
                {creatives!.map((creative, i) => {
                  return (
                    <div className="creative-cockpit-card" key={i}>
                      <CreativeCard key={i} creative={{ ...creative }} />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </Layout.Section>
        )}
        {dashboardView === 'table' && (
          <Layout.Section>
            <CreativeTable />
          </Layout.Section>
        )}
      </Layout>
    </div>
  );
};

export default CreativeDashboard;
