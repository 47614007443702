import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Heading, Modal, OptionList } from '@shopify/polaris';
import {
  googleAdsAccountsConfigSavePress,
  googleAdsDisconnect,
  toggleGoogleAdsConfigScreenOpen,
} from 'ducks/googleAds';

import firebase from 'firebase/compat/app';
import { useAppSelector } from 'reducers/RootType';
import { useAppDispatch } from 'index';
const firestore = firebase.firestore;

export default function GoogleAdsAccountsConfig() {
  const dispatch = useAppDispatch();
  const [accounts, setAccounts] = useState<string[]>([]);
  const [utmBotState, setUtmBotState] = useState(true);
  const googleAdsConfigScreenIsOpen = useAppSelector((state) => state.googleAdsConfigScreenIsOpen);
  const customersList = useAppSelector((state) => state.googleAdsAccounts);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const idsList = Object.keys(customersList);
  const noAccounts = idsList?.length === 0;
  const renderTitle = () => {
    if (noAccounts) return 'No available accounts';
    if (idsList?.length > 0) return 'Please select google ads account';
    return 'Connect to GoogleAds';
  };

  const updateUtmBotFirestore = useCallback(
    async (val) => {
      try {
        await firestore()
          .collection('shops')
          .doc(currentShopId)
          .set(
            {
              utm_bot: {
                ga: {
                  status: val,
                  last_change: firestore.FieldValue.serverTimestamp(),
                },
              },
            },
            { merge: true },
          );
        setUtmBotState(val);
      } catch (err) {
        console.log(err);
      }
    },
    [currentShopId],
  );

  useEffect(() => {
    if (googleAdsConfigScreenIsOpen) {
      updateUtmBotFirestore(true);
    }
  }, [googleAdsConfigScreenIsOpen, updateUtmBotFirestore]);

  return (
    <Modal
      title={renderTitle()}
      open={googleAdsConfigScreenIsOpen}
      onClose={() => dispatch(toggleGoogleAdsConfigScreenOpen())}
      primaryAction={{
        content: noAccounts ? 'Disconnect' : 'Save',
        onAction: () => {
          if (noAccounts) {
            dispatch(googleAdsDisconnect());
            dispatch(toggleGoogleAdsConfigScreenOpen());
          } else {
            dispatch(
              googleAdsAccountsConfigSavePress(
                accounts.reduce((a, v) => ({ ...a, [v]: customersList[v] }), {}),
              ),
            );
          }
        },
        disabled: !accounts.length && !noAccounts,
      }}
      sectioned
    >
      {idsList?.length > 0 ? (
        <>
          <OptionList
            onChange={(selected) => setAccounts(selected)}
            options={idsList.map((account) => ({
              value: account,
              label: `${account}: ${customersList[account].descriptive_name}`,
            }))}
            selected={accounts}
            allowMultiple
          />
          <div className="p-5">
            <Checkbox
              label={
                <>
                  <Heading>Automatically Update Tracking Parameters</Heading>
                  <p>
                    Automatically apply Triple Tracking parameters to your campaigns and adgroups.
                    This ensures the Triple Pixel will track your ads.
                  </p>
                </>
              }
              checked={utmBotState}
              onChange={async (val) => {
                await updateUtmBotFirestore(val);
              }}
            />
          </div>
        </>
      ) : (
        <div>
          You do not have any available accounts within this Ads account. Please connect to another
          Google Ads account.{' '}
        </div>
      )}
    </Modal>
  );
}
