import { updateCurrentShopId } from 'ducks/auth';
import { AppDispatch } from 'index';
import { useNavigate } from 'react-router';

export const goToPodsView = (navigate: ReturnType<typeof useNavigate>, dispatch: AppDispatch) => {
  dispatch(updateCurrentShopId());
  navigate('/pods-view');
  // IMPORTANT - must reload to "clean" all INIT_SHOP reducers
  window.location.reload();
};
