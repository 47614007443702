import { SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { SummarySection } from 'types/SummarySection';

export const getDefaultShownTiles = (
  isFreeShop: boolean,
  currentSection?: SummarySection | null,
): SummaryMetricIdsTypes[] | undefined => {
  if (!currentSection) return [];

  const { defaultFreeConfig, tiles } = currentSection;
  if (!tiles && !isFreeShop) return undefined;

  if (isFreeShop && defaultFreeConfig?.tilesToShow) {
    return defaultFreeConfig.tilesToShow;
  }
  return tiles || [];
};

export const getDefaultHiddenTiles = (
  isFreeShop: boolean,
  currentSection: SummarySection | null,
): SummaryMetricIdsTypes[] | undefined => {
  if (!currentSection) return [];

  const { defaultFreeConfig, tiles, hiddenTiles } = currentSection;
  if (!hiddenTiles && !isFreeShop) return undefined;

  if (isFreeShop && defaultFreeConfig?.tilesToHide) {
    return defaultFreeConfig.tilesToHide;
  }

  const combinedTiles = (tiles || []).concat(hiddenTiles || []);
  if (isFreeShop && defaultFreeConfig?.tilesToShow) {
    return combinedTiles.filter((tile) => !defaultFreeConfig.tilesToShow?.includes(tile));
  }

  // it's the responsibility of other functions to filter tiles user is currently seeing
  return combinedTiles || [];
};
