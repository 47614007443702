import posthog from 'posthog-js';
import { isStaging, isProduction, posthogKey } from 'config';
import { isInIframe } from '../config';

if (!isInIframe && (isProduction || isStaging)) {
  posthog.init(posthogKey, {
    api_host: 'https://app.posthog.com',
    capture_pageview: false,
  });
}
