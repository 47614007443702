import { MediaItemType } from '@tw/types';
import { type RootState } from 'reducers/RootType';
import { MediaType } from 'types/general';

const noop = (media) => {
  console.log(media);
};

export interface MediaPickerData {
  onSelect: (media) => void | Promise<void>;
  text?: string;
  confirmButtonText?: string;
  title?: string;
  maxFiles: number;
  preSelected?: MediaItemType[];
  allowTypes: MediaType[];
  modal?: boolean;
  selectedMedia: MediaItemType[];
}

export interface MediaPickerInternalData extends MediaPickerData {
  isOpen: boolean;
  currentShop: string;
}

const initState: MediaPickerInternalData = {
  isOpen: false,
  text: '',
  title: '',
  onSelect: noop,
  currentShop: '',
  maxFiles: 1,
  allowTypes: [],
  modal: false,
  selectedMedia: [],
};
export const OPEN_MEDIA_PICKER = 'OPEN_MEDIA_PICKER';
export const CLOSE_MEDIA_PICKER = 'CLOSE_MEDIA_PICKER';
export const CHANGE_SELECTED_MEDIA = 'CHANGE_SELECTED_MEDIA';

export const mediaPicker = (data: MediaPickerData) => (dispatch, getState: () => RootState) => {
  const { currentShopId } = getState();
  dispatch({
    type: OPEN_MEDIA_PICKER,
    data: { ...data, isOpen: true, currentShop: currentShopId },
  });
};

export const mediaPickerClose = () => (dispatch) => {
  dispatch({
    type: CLOSE_MEDIA_PICKER,
  });
};

export const addToSelectedMedia = (media: MediaItemType, isSelected: boolean) => {
  return (dispatch, getState) => {
    const { selectedMedia, maxFiles } = getState().mediaPickerData;
    let newMedias: any;
    if (maxFiles === 1) {
      newMedias = [media];
    } else {
      if (isSelected) {
        newMedias = selectedMedia.filter((m) => m.id !== media.id);
      } else {
        newMedias = [...selectedMedia, media];
      }
    }

    dispatch({
      type: CHANGE_SELECTED_MEDIA,
      selected_medias: newMedias,
    });
  };
};

export const changeSelectedMedia = (data) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_MEDIA,
      payload: data,
    });
  };
};

const mediaPickerData = (state: MediaPickerInternalData = initState, action) => {
  switch (action.type) {
    case OPEN_MEDIA_PICKER:
      return action.data;
    case CHANGE_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: action.selected_medias,
      };
    case CLOSE_MEDIA_PICKER:
      return initState;
    default:
      return state;
  }
};

export const reducers = { mediaPickerData };
