import { PixelSnippet } from '../../attribution/PixelSnippet';
import { Icon, Spinner } from '@shopify/polaris';
import { PIXEL_MANUAL_INSTALL } from '../../../constants/knowledgeBaseLinks';
import { ExternalMinor } from '@shopify/polaris-icons';

export const ThirdPartyCheckoutInstall: React.FC<{ snippet: any }> = ({ snippet }) => {
  return (
    <>
      <p className="text-[15px] bolder mb-[5px]">
        Install the Headless Snippet on all pages you wish to track
      </p>
      <p className="text-[14px] regular">
        To attribute orders that are placed through a third-party or custom checkout, install the
        snippet below on your order confirmation page, making sure to replace the email variable
        with your customer’s email address.
      </p>
      <div className="my-[20px]" style={{ height: '320px' }}>
        {snippet ? (
          <PixelSnippet snippetCode={snippet.thirdPartyCheckout} lang="html" />
        ) : (
          <div className="text-center pt-[120px] pb-[10px]">
            <Spinner size="small" />
          </div>
        )}
      </div>

      <p className="text-[13px] regular mt-[20px] mb-[30px]">
        <span>
          For more additional information about installation on specific third-party checkouts,
          including Recharge, Upscribe, Checkify, and Better Cart, visit our
        </span>
        <a href={PIXEL_MANUAL_INSTALL} target="_blank" className="text-secondary-text ml-[5px]">
          knowledge base article.
          <span className="text-icon w-[13px]">
            <Icon source={ExternalMinor} />
          </span>
        </a>
      </p>
    </>
  );
};
