import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Popover,
  Tooltip,
  ActionList,
  ActionListItemDescriptor,
  ActionListSection,
} from '@shopify/polaris';

type DropDownActionListProps = {
  items: ActionListItemDescriptor[];
  sections?: ActionListSection[];
  onActionAnyItem?: () => void;
  activator: React.ReactElement;
  hint?: string;
  disabled?: boolean;
  togglePopover?: (active: boolean) => void;
  onChange?: (value: any) => void;
};

const DropDownActionList: React.FC<DropDownActionListProps> = (props) => {
  const { items, sections, onActionAnyItem, activator, hint, togglePopover, disabled, onChange } =
    props;
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () =>
      setPopoverActive((popoverActive) => {
        if (togglePopover) {
          togglePopover(!popoverActive);
        }

        return !popoverActive;
      }),
    [togglePopover],
  );

  useEffect(() => {
    if (onChange) onChange(popoverActive);
  }, [popoverActive]);

  const act = useMemo(() => {
    return React.cloneElement(activator, {
      onClick: () => {
        if (!disabled) {
          togglePopoverActive();
        }
      },
      style: { cursor: 'pointer' },
    });
  }, [activator, disabled, togglePopoverActive]);

  return (
    <Popover
      zIndexOverride={99999999999999}
      active={popoverActive}
      activator={hint ? <Tooltip content={hint}>{act}</Tooltip> : act}
      onClose={togglePopoverActive}
    >
      <ActionList
        items={items}
        sections={sections}
        onActionAnyItem={() => {
          togglePopoverActive();
          if (onActionAnyItem) {
            onActionAnyItem();
            // togglePopoverActive();
          }
        }}
      />
    </Popover>
  );
};

export default DropDownActionList;
