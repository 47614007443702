import { $observer } from '@tw/snipestate';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { MemberData } from 'components/Willy/dashboardManagment/permissions-management/types';

interface ShopUser extends MemberData {
  display: string;
}

export const $shopUsers = $observer(
  { loading: true, data: [] as ShopUser[], error: null as string | null },
  async (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    const data = get().data;
    if (!shopId || !userId || data.length > 0) return;

    try {
      const { data } = await axiosInstance.get(
        `/v2/account-manager/shops/users/${shopId}?noFilter=true`,
      );

      set({
        error: null,
        loading: false,
        data: data?.map((x) => ({
          ...x,
          id: x.id,
          display: x.name,
        })),
      });
    } catch (error) {
      console.error('Error fetching shop users:', error);
      const message = 'message' in error ? error.message : 'Error fetching shop users';
      set({ ...get(), error: message, loading: false });
    }
  },
);
