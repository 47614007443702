import amplitude from 'amplitude-js';
import { isProduction, amplitudeKey } from 'config';
import { $currentShopId } from '$stores/$shop';

if (isProduction) {
  amplitude.getInstance().init(amplitudeKey);
  amplitude.getInstance().setGroup('shopDomain', $currentShopId.get());
  amplitude
    .getInstance()
    .setGroup(
      'Is Triple Whale Employee',
      localStorage.userEmail?.endsWith('triplewhale.com')?.toString() || 'false',
    );
}
