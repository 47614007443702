import { Fragment, useMemo } from 'react';
import { Flex, Text, Tooltip, Icon as TwIcon } from '@tw/ui-components';
import { NavLink } from 'react-router-dom';
import { useFilterMenu } from '../../../hooks/useFilterMenu';
import { $navigationSections, useActiveRoute } from '$stores/nav-config-stores';
import store, { useAppDispatch } from 'index';
import { changeWillySideMenuWidth } from 'ducks/willy';
import { useStoreValue } from '@tw/snipestate';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { MoreSectionPopover } from './MoreSectionPopover';
import { NavSection } from 'constants/routes/types';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';

export const MainLinksSection = () => {
  const navigationSections = useStoreValue($navigationSections);
  const alanSections = useFilterMenu(navigationSections, 'alan');
  const { top, bottom } = useMemo(() => {
    return alanSections.reduce(
      (acc, s) => {
        if (s.routes.some((r) => r.url === '/get-started')) acc.bottom.push(s);
        else acc.top.push(s);
        return acc;
      },
      { top: [] as NavSection[], bottom: [] as NavSection[] },
    );
  }, [alanSections]);

  return (
    <>
      <LinksSection sections={top} />

      <MoreSectionPopover />

      {!!bottom.length && (
        <>
          <div className="my-4 -mx-[15px]" style={{ borderBottom: TOP_BAR_BORDER_VAR }} />
          <LinksSection sections={bottom} />
        </>
      )}
    </>
  );
};

function LinksSection({ sections }: { sections: NavSection[] }) {
  const dispatch = useAppDispatch();
  const { activeRoute, activeSection } = useActiveRoute();

  return (
    <Flex direction="column" align="center" gap="xs">
      {sections.map((section, i) => {
        const { icon: Icon, title, routes, spread, rawIconName } = section;

        if (spread !== false) {
          return (
            <Fragment key={(title || '') + i}>
              {routes
                .filter((r) => !r.hidden)
                .map((r) => {
                  const selected = activeRoute === r;
                  const icon = typeof r.icon === 'function' && r.icon?.({ selected });

                  return (
                    <NavLink
                      className="flex flex-col items-center cursor-pointer mb-2 no-underline"
                      to={{ pathname: r.url, search: window.location.search }}
                      key={`nav-link-sm-${r.url}-${i}`}
                      onClick={(e) => {
                        const clickCount = e.detail;
                        const willySideMenuWidth = store.getState().willy.willySideMenuWidth;
                        if (clickCount < 2 || willySideMenuWidth > 0) return;
                        dispatch(changeWillySideMenuWidth(250));
                      }}
                    >
                      {icon && <Flex>{icon}</Flex>}
                      <ConditionalWrapper
                        condition={!!r.shortLabel && r.label !== r.shortLabel}
                        wrapper={(x) => <Tooltip label={r.label}>{x}</Tooltip>}
                      >
                        <Text fz={12} weight={500} ta="center">
                          {r.shortLabel || r.label}
                        </Text>
                      </ConditionalWrapper>
                    </NavLink>
                  );
                })}
            </Fragment>
          );
        }

        return (
          <NavLink
            className="flex flex-col items-center cursor-pointer mb-2 no-underline"
            to={{ pathname: routes[0].url }}
            key={`nav-link-sm-${routes[0].label}-${i}`}
            onClick={(e) => {
              const clickCount = e.detail;
              const willySideMenuWidth = store.getState().willy.willySideMenuWidth;
              if (clickCount < 2 || willySideMenuWidth > 0) return;
              dispatch(changeWillySideMenuWidth(250));
            }}
          >
            {Icon ? (
              <Icon width={28} height={28} selected={activeSection?.title === title} />
            ) : (
              rawIconName && <TwIcon name={rawIconName} />
            )}
            <Text fz={12} weight={500}>
              {title}
            </Text>
          </NavLink>
        );
      })}
    </Flex>
  );
}
