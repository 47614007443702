import { Button, Modal } from '@shopify/polaris';
import { useState, useEffect, useCallback } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { connectToShopify } from 'utils/connectToShopify';

const ReconnectToShopify = () => {
  var [showPopup, setShowPopup] = useState(false);
  var currentShopId = useAppSelector((store) => store.currentShopId);
  var shop = useAppSelector((store) => store.shop);
  const shopifyScopes = useAppSelector((state) => state.shopifyScopesFromApi);
  const user = useAppSelector((state) => state.user);
  const checkStoresScopes = useCallback(() => {
    // if (!shop.pixel) return;
    if (currentShopId && user.uid && shopifyScopes) {
      const connected =
        shopifyScopes.includes('read_themes') && shopifyScopes.includes('write_themes');
      if (!connected) {
        setShowPopup(true);
      }
    }
  }, [currentShopId, user.uid, shopifyScopes, shop.pixel]);

  useEffect(() => {
    checkStoresScopes();
  }, [checkStoresScopes]);

  return (
    <>
      <Modal
        title={'Activate Your Pixel'}
        titleHidden={false}
        open={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <Modal.Section>
          <div className="add_user_popup" style={{ textAlign: 'center' }}>
            <h4 style={{ marginBottom: '20px' }}>
              In order to activate your Triple Pixel, please reconnect with Shopify.
            </h4>
            <Button onClick={() => connectToShopify(currentShopId, true)}>
              Click here to continue
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};
export default ReconnectToShopify;
