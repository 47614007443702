import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { Modal } from '@shopify/polaris';

export const FloppyWhaleModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const floppyWhaleModalOpen = useSelector((state: RootState) => state.floppyWhaleModalOpen);
  return (
    <Modal
      onIFrameLoad={() => setLoading(false)}
      loading={loading}
      title
      titleHidden
      iFrameName="floppy-whale-iframe"
      src="https://floppy-whale.triplewhale.com/"
      open={floppyWhaleModalOpen}
      onClose={() =>
        dispatch({
          type: 'FLOPPY_WHALE_MODAL_CLOSED',
        })
      }
    />
  );
};
