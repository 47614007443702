import { BqColumn } from '../dataStuff/columns/types';
import React, { useCallback, useEffect, useRef } from 'react';
import { ActionIcon, Anchor, Button, Flex, Icon, Text, Tooltip } from '@tw/ui-components';
import { FilterComparator } from '@tw/types/src/services/insights/filters/FilterComparator';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '../../../utils/usePrevious';
import { FilterGroupComp } from './FilterGroupComp';
import { BqTable } from '../dataStuff/tables';
import { ServicesIds } from '@tw/types/module/services';
import { FilterGroup, FilterRow } from '@tw/willy-data-dictionary/module/columns/types';

type FilterBuilderProps = {
  columns: BqColumn[];
  filter?: FilterGroup[];
  table: BqTable;
  onFilterChanged: (filter: FilterGroup[]) => void;
  onFilterValidChanged: (isValid: boolean) => void;
  relatedProvider?: ServicesIds;
};

export const FilterBuilder: React.FC<FilterBuilderProps> = ({
  filter = [],
  onFilterChanged,
  onFilterValidChanged,
  columns,
  table,
  relatedProvider,
}) => {
  const isFilterValidRef = useRef<boolean | null>(null);
  const isNoValue = useCallback((filterRow: FilterRow) => {
    if (
      filterRow.comparator === FilterComparator.IS_SET ||
      filterRow.comparator === FilterComparator.IS_NOT_SET ||
      filterRow.comparator === FilterComparator.OVER_ALL_TIME
    ) {
      return false;
    }

    if (
      filterRow.comparator === FilterComparator.UNDER ||
      filterRow.comparator === FilterComparator.OVER
    ) {
      return !filterRow.value || !filterRow.unit;
    }

    if (
      filterRow.comparator === FilterComparator.IS_IN ||
      filterRow.comparator === FilterComparator.IS_NOT
    ) {
      return !filterRow.value?.length;
    }

    if (filterRow.comparator === FilterComparator.BETWEEN) {
      return !filterRow.value1 || !filterRow.value2;
    }

    if (filterRow.comparator === FilterComparator.WITHIN) {
      return !filterRow.value1 || !filterRow.value2 || !filterRow.unit;
    }

    return !filterRow.value;
  }, []);

  const addOrStatement = useCallback(
    (column: BqColumn[]) => {
      const filterChanged = ((old) => {
        // Clone the outer array
        let newFilter = old ? [...old] : [];

        // Create a new FilterRow
        const newFilterRow: FilterRow = {
          column: column[0],
          // Initialize other properties as needed
        };

        // If filter is empty, initialize it with a new array containing the new FilterRow
        newFilter = [
          ...newFilter,
          { name: `Filter #${newFilter.length + 1}`, enabled: true, filters: [newFilterRow] },
        ];

        // Return the updated state
        return newFilter;
      })(filter);
      onFilterChanged(filterChanged);
    },
    [filter, onFilterChanged],
  );

  const prevFilter = usePrevious(filter);

  useEffect(() => {
    if (prevFilter && filter && !isEqual(prevFilter, filter)) {
      onFilterChanged?.(filter);
    }
  }, [filter, onFilterChanged, prevFilter]);

  useEffect(() => {
    const isInvalid = (() => {
      if (!filter || filter.length === 0) return false;
      return filter.some((filterGroup) =>
        filterGroup.filters.some((filterRow) => isNoValue(filterRow)),
      );
    })();
    const currentIsValid = !isInvalid;
    // Call isFilterValid only if the validity has changed
    if (isFilterValidRef.current !== currentIsValid) {
      onFilterValidChanged?.(currentIsValid);
      isFilterValidRef.current = currentIsValid;
    }
  }, [filter, onFilterValidChanged, isNoValue]);

  return (
    <div>
      <Flex justify={'space-between'} align={'center'}>
        <Text>Filters</Text>
        <Tooltip label={!columns || columns.length === 0 ? `Table is empty` : ``}>
          <Anchor
            as={'button'}
            size="sm"
            disabled={!columns || columns.length === 0}
            onClick={() => addOrStatement(columns)}
          >
            <Flex align={'center'} gap={'xs'}>
              <Icon
                name={'plus'}
                width={10}
                color={!columns || columns.length === 0 ? 'gray.5' : `one.5`}
              />
              <Text color={!columns || columns.length === 0 ? 'gray.5' : `one.5`}>New Filter</Text>
            </Flex>
          </Anchor>
        </Tooltip>
      </Flex>
      <div className={'mt-5'}>
        {filter?.map((filterGroup, index) => (
          <div key={`${index}-parent`}>
            <FilterGroupComp
              table={table}
              onFilterChanged={onFilterChanged}
              filter={filter}
              key={`${index}-parent`}
              group={filterGroup}
              index={index}
              columns={columns}
              relatedProvider={relatedProvider}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
