import { $isSimpleUser } from '$stores/$user';
import {
  $currentRevenue,
  $isFreeShop,
  $isUserHasMobyPlanPreviewBefore,
  $mobyUpgradeSplitTest,
  MOBY_PRODUCT_ID,
} from '$stores/willy/$subscription';
import {
  logUgradeEvent,
  openBookDemoModal,
  openFreeTrialPopup,
  type UpgradeEventSource,
} from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { useNavigate } from 'react-router-dom';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';
import { useAppSelector } from '../../../reducers/RootType';
import { SELECTED_PRODUCT_PARAM } from '../../UserProfileManagment/Shop/ChangePlan/constants';
import { useCallback, useEffect, useState } from 'react';

export const useUpgradeButton = (source: UpgradeEventSource, isMoby = false) => {
  const [buttonText, setButtonText] = useState<string>('');
  const [linkText, setLinkText] = useState<string>('');

  const navigate = useNavigate();
  const isFreeShop = useStoreValue($isFreeShop);
  const isSimpleUser = useStoreValue($isSimpleUser);
  const { dayTrial, isOnlyUpgrade, isFromSplitTest } = useStoreValue($mobyUpgradeSplitTest);
  const isUserHasMobyPlanPreviewBeforeValue = useStoreValue($isUserHasMobyPlanPreviewBefore);
  const shopRevenue = useStoreValue($currentRevenue);
  const isAboveMillion = shopRevenue > 1000000;

  const action = useCallback(() => {
    if (isMoby) {
      if (isOnlyUpgrade || isUserHasMobyPlanPreviewBeforeValue) {
        openFreeTrialPopup(source, true);
      } else {
        openFreeTrialPopup(source, false);
      }
    } else {
      if (isFreeShop) {
        isAboveMillion ? openBookDemoModal('pricing') : navigate('/store-settings/plans');
      } else {
        navigate('/store-settings/plans');
      }
    }
  }, [
    isAboveMillion,
    isFreeShop,
    isMoby,
    isOnlyUpgrade,
    isUserHasMobyPlanPreviewBeforeValue,
    navigate,
    source,
  ]);

  useEffect(() => {
    if (isMoby) {
      if (isOnlyUpgrade || isUserHasMobyPlanPreviewBeforeValue) {
        setButtonText('Purchase Moby seats now');
        setLinkText(`Upgrade for full access to Moby`);
      } else {
        setButtonText('Start Free Trial');
        setLinkText(`Start a ${dayTrial}-day free trial with Moby`);
      }
    } else {
      if (isFreeShop) {
        setButtonText(isAboveMillion ? 'Book a Demo' : isSimpleUser ? 'View Plans' : 'Upgrade');
        setLinkText(isAboveMillion ? 'book a demo' : 'upgrade');
      } else {
        setButtonText('Upgrade');
        setLinkText(`Upgrade`);
      }
    }
  }, [
    dayTrial,
    isAboveMillion,
    isFreeShop,
    isMoby,
    isOnlyUpgrade,
    isSimpleUser,
    isUserHasMobyPlanPreviewBeforeValue,
    navigate,
  ]);

  return { linkText, action, buttonText };
};
