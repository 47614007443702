import { EditRule, RuleEventParams, StrategyPayload } from 'components/RulesEngine/types';
import moment from '@tw/moment-cached';
import axiosInstance from 'utils/axiosInstance';

export async function getStrategies(params: any): Promise<any> {
  const url = `/v2/rules-engine/strategies?shop=${params.shop}`;
  try {
    const { data } = await axiosInstance.get<any>(url, params);

    let rules = data.map((x) => ({
      ...x,
    }));

    return {
      rulesEngine: rules,
    };
  } catch (e) {
    return {
      rulesEngine: [],
    };
  }
}

export async function getTWStrategies(params: any): Promise<any> {
  const res = await axiosInstance.get<any>(`v2/rules-engine/tw-strategies?shop=${params.shop}`);
  return res.data;
}

export async function saveStrategies(params: StrategyPayload): Promise<any> {
  console.log('start save Strategies');
  const url = `/v2/rules-engine/strategy`;
  try {
    const { data } = await axiosInstance.post<any>(url, { data: [params] });
    return {
      data,
    };
  } catch (e) {
    console.error(e);
    return {
      data: [],
    };
  }
}

export async function checkHealth(shopId): Promise<any> {
  const { data } = await axiosInstance.post<any>('/v2/rules-engine/check-health', { shop: shopId });
  return {
    data,
  };
}

export async function simulateRule(shop, rule: EditRule): Promise<any> {
  const params: RuleEventParams = rule.event.params || {};
  params.level = rule.level;
  params.mode = 'logOnly';
  rule.event['params'] = params;

  const { data } = await axiosInstance.post<any>('/v2/rules-engine/simulate-rule', {
    data: { shop: shop, receivedAt: moment().format(), rules: [rule] },
  });

  return data;
}

export async function getNextRun(shop, rule: any, shopTimezone): Promise<any> {
  const params = { shop: shop, rule: rule, timezone: shopTimezone };
  const { data } = await axiosInstance.post<any>('/v2/rules-engine/get-next-run', params);
  return {
    data,
  };
}
