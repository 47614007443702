import GoogleSheetsAccountSelector, {
  GoogleSheetsSpreadsheetSelector,
  GoogleSheetsWorksheetSelector,
} from '../../GoogleSheetsAccountSelector';

type FlowSendToGoogleSheetStepProps = {
  onSave: (args: {
    sheetsAccount?: string;
    spreadsheetId?: string;
    spreadsheetName?: string;
    ssNameError?: boolean;
    worksheetId?: string;
    worksheetName?: string;
    wsNameError?: boolean;
  }) => void;
  readOnly: boolean;
  sheetsAccount?: string;
  spreadsheetId?: string;
  spreadsheetName?: string;
  worksheetId?: string;
  worksheetName?: string;
};
export const FlowSendToGoogleSheetStep = ({
  onSave,
  readOnly,
  sheetsAccount,
  spreadsheetId,
  spreadsheetName,
  worksheetId,
  worksheetName,
}: FlowSendToGoogleSheetStepProps) => {
  return (
    <div>
      <GoogleSheetsAccountSelector
        value={sheetsAccount ?? ''}
        onChange={(v) => {
          onSave({
            sheetsAccount: v ?? '',
            spreadsheetId: 'create_new',
            spreadsheetName: '',
            worksheetId: 'create_new',
            worksheetName: '',
          });
        }}
        disabled={readOnly}
      />
      <GoogleSheetsSpreadsheetSelector
        sheetsAccount={sheetsAccount ?? ''}
        id={spreadsheetId ?? ''}
        name={spreadsheetName ?? ''}
        onChange={({ id, name, error }) => {
          let worksheetId: string | null = null,
            worksheetName: string | null = null;
          if (spreadsheetId !== id) {
            if (id === 'create_new') {
              name = '';
            }
            worksheetId = 'create_new';
            worksheetName = '';
          }
          onSave({
            ssNameError: error ?? false,
            ...(id != null && { spreadsheetId: id }),
            ...(name != null && { spreadsheetName: name }),
            ...(worksheetId != null && { worksheetId }),
            ...(worksheetName != null && { worksheetName }),
          });
        }}
        disabled={readOnly}
      />
      <GoogleSheetsWorksheetSelector
        spreadsheetId={spreadsheetId ?? ''}
        sheetsAccount={sheetsAccount ?? ''}
        id={worksheetId ?? ''}
        name={worksheetName ?? ''}
        onChange={({ id, name, error }) => {
          if (worksheetId !== id && id === 'create_new') {
            name = '';
          }
          onSave({
            wsNameError: error ?? false,
            ...(id != null && { worksheetId: id }),
            ...(name != null && { worksheetName: name }),
          });
        }}
        disabled={readOnly}
      />
    </div>
  );
};
