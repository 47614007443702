import { Avatar } from '@shopify/polaris';
import { Tooltip } from '@tw/ui-components';
import React, { useCallback, useMemo, useState, useSyncExternalStore } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $isAdminClaim } from '../../$stores/$user';
import copyToClipboard from '../../utils/copyToClipboard';
import { $shopUsers } from '$stores/$users';
import { useAvatarImage } from '$stores/$avatars';

type WillyUserAvatarProps = {
  avatarSource?: string;
  userName?: string;
  userId?: string;
  loading?: boolean;
  round?: boolean;
  shadow?: boolean;
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
};

export const WillyUserAvatar: React.FC<WillyUserAvatarProps> = ({
  userName,
  userId,
  round,
  shadow,
  size = 'medium',
}) => {
  const isAdmin = useStoreValue($isAdminClaim);
  const imageURL = useAvatarImage(userId);
  const [forceAvatar, setForceAvatar] = useState(false);
  const email = useSyncExternalStore(
    $shopUsers.subscribe,
    useCallback(() => {
      if (!userId) return '';
      return $shopUsers.get()?.data.find((u) => u.id === userId)?.email || '';
    }, [userId]),
  );

  const acronymsName = useMemo(() => {
    return `${userName?.charAt(0).toUpperCase() || ''}`;
  }, [userName]);

  return (
    <div
      onClick={() => isAdmin && copyToClipboard(email, false)}
      className={`${round ? 'rounded-full' : 'rounded-[0.4rem] overflow-hidden'}`}
    >
      <Tooltip label={`${userName} ${isAdmin ? `(${email})` : ''}`} position="right">
        <div className={`${shadow ? 'shadow-md' : ''} rounded-full`}>
          {!imageURL || forceAvatar ? (
            <Avatar
              size={size}
              shape={round ? 'round' : 'square'}
              name={userName}
              initials={acronymsName}
            />
          ) : (
            <div className="rounded-full">
              <div className="shadow-md rounded-full">
                <span
                  aria-label={userName}
                  role="img"
                  className={`Polaris-Avatar Polaris-Avatar--size${
                    size.charAt(0).toUpperCase() + size.slice(1)
                  } Polaris-Avatar--imageHasLoaded Polaris-Avatar--shape${
                    round ? 'Round' : 'Square'
                  }`}
                >
                  <img
                    alt={userName}
                    src={imageURL}
                    onError={() => setForceAvatar(true)}
                    className="Polaris-Avatar__Image"
                    role="presentation"
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
