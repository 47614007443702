import { AnyAction, combineReducers, Reducer } from 'redux';
import moment from '@tw/moment-cached';
import { TimeFrame } from '@tw/types/module/services/insights';

import {
  SET_INSIGHTS_DATERANGE,
  SET_INSIGHTS_EVENTS_CONTEXT,
  SET_INSIGHTS_TIMEFRAME,
} from './actions';

const timeframe: Reducer<TimeFrame> = (state = TimeFrame.MONTH, action) => {
  switch (action.type) {
    case SET_INSIGHTS_TIMEFRAME:
      return action.timeframe;
    default:
      return state;
  }
};

const dateRange: Reducer<{ start: moment.Moment; end: moment.Moment } | null> = (
  state = null,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_INSIGHTS_DATERANGE:
      return { start: action.start, end: action.end };
    default:
      return state;
  }
};

const eventContext: Reducer<string | null> = (state = null, action: AnyAction) => {
  switch (action.type) {
    case SET_INSIGHTS_EVENTS_CONTEXT:
      return action.context;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  timeframe,
  dateRange,
  eventContext,
});
