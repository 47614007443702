import { SET_ADD_MODAL_SECTION, SET_EDIT_MODAL_SECTION } from 'ducks/constants';

const initialState = {
  isAddModalSection: false,
  editSection: {
    id: null,
    title: null,
    tiles: null,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADD_MODAL_SECTION:
      return {
        ...state,
        isAddModalSection: action.payload,
      };
    case SET_EDIT_MODAL_SECTION:
      return {
        ...state,
        editSection: action.payload,
      };
    default:
      return state;
  }
};
