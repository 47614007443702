import { Badge, Flex, Icon, Tooltip, Text, Button } from '@tw/ui-components';
import { WillyDashImage } from '../WillyDashImage';
import { WillyBaseMainElement } from 'components/Willy/types/willyTypes';
import { useNavigate } from 'react-router-dom';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { ReactComponent as AddedToBoard } from 'components/Icons/checkmark.svg';
import { useStoreValue } from '@tw/snipestate';
import { $dashboardCredits } from '$stores/willy/$credits';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { useCallback, useMemo } from 'react';
import {
  isGlobalDashboardExistsInShop,
  shopLevelDashComingFromTemplate,
} from 'components/Willy/dashContext';
import { useUpgradeButton } from 'components/UpgradePlan/components/useUpgradeButton';
import { GetTemplateButton } from './GetTemplateButton';

type TemplateCardProps = {
  dashboard: WillyBaseMainElement;
  closeCreateModal?: () => void;
  variant?: 'default' | 'short';
};

export const TemplateCard: React.FC<TemplateCardProps> = ({
  dashboard,
  closeCreateModal,
  variant = 'default',
}) => {
  const navigate = useNavigate();
  const { credits, creditsLeft } = useStoreValue($dashboardCredits);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const { action, buttonText } = useUpgradeButton('template_preview');

  const existInShop = useMemo(() => {
    if (dashboard) {
      return isGlobalDashboardExistsInShop(dashboard);
    }
  }, [dashboard]);

  const previewDashboard = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (dashboard.type === 'sequence') {
      const { id } = shopLevelDashComingFromTemplate(dashboard) || { id: dashboard.id };
      if (!id) {
        return;
      }
      navigate({ pathname: `/workflows/create/${dashboard.id}`, search: searchParams.toString() });
    } else {
      navigate({ pathname: `/dashboards/${dashboard!.id}`, search: window.location.search });
    }
    closeCreateModal?.();
  }, [closeCreateModal, dashboard, navigate]);

  const connectProvider = useCallback(() => {
    navigate('/integrations');
  }, [navigate]);

  return (
    <div
      // onClick={async () => {
      //   navigate({
      //     pathname: `/templates/${dashboard.id}`,
      //     search: window.location.search,
      //   });
      //   closeCreateModal?.();
      // }}
      className="relative cursor-pointer w-full h-full flex flex-col gap-2 border border-solid border-gray-200 rounded-lg shadow-sm bg-white hover:bg-[var(--mantine-color-gray-0)] hover:shadow-md transition duration-300 ease-in-out overflow-hidden group"
      key={dashboard.id}
    >
      <div
        className="hidden absolute top-0 left-0 z-10 w-full h-full group-hover:flex justify-center"
        style={{ background: 'rgba(40, 40, 40, 0.30)' }}
      ></div>
      <div className="z-[12] absolute top-[14px] left-[14px]">
        {dashboard.isLocked && (
          <LockedFeatureIndicator
            featureFlag={FeatureFlag.TEMPLATES_FF}
            iconOnly
            iconSize={26}
            iconBorderColor="#C3DDFF"
          />
        )}
      </div>
      <div className="z-[11] absolute top-[14px] right-[14px]">
        {!dashboard.isLocked && credits !== Infinity && (
          <Badge color={dashboard.credits ? 'one.0' : 'green.1'} uppercase={false}>
            <Text fw={500} size={'xs'} color={dashboard.credits ? 'one.6' : 'green.6'}>
              {dashboard.credits
                ? `${dashboard.credits} Credit${dashboard.credits > 1 ? 's' : ''}`
                : 'Free'}
            </Text>
          </Badge>
        )}
        {dashboard.isBeta && (
          <Badge color={'one.0'}>
            <Text fw={500} size={'xs'} color={'one.5'}>
              Beta
            </Text>
          </Badge>
        )}
      </div>

      <div className="relative overflow-hidden">
        <div className="absolute hidden group-hover:flex left-1/2 transform -translate-x-1/2  z-[12] w-fit h-full gap-6.5 items-center justify-center flex-col">
          {(isTwGlobalDashboardCreatorClaim ||
            dashboard.type === 'sequence' ||
            (!dashboard.isLocked && !dashboard.isProviderLocked)) && (
            <>
              {/* TODO: This code for sequences used to be used with templates, so leaving accessLimit source the same as templates for now */}
              {(dashboard.type === 'dashboard' ||
                (dashboard.type === 'sequence' && !existInShop)) && (
                <GetTemplateButton template={dashboard} onClick={closeCreateModal} />
              )}
              {((dashboard.type === 'dashboard' && !dashboard.isLocked) ||
                isTwGlobalDashboardCreatorClaim ||
                (dashboard.type === 'sequence' && existInShop)) && (
                <Button
                  variant="activator"
                  disabled={
                    creditsLeft < (dashboard.credits ?? 0) && !isTwGlobalDashboardCreatorClaim
                  }
                  onClick={previewDashboard}
                  fullWidth
                >
                  Preview
                </Button>
              )}
            </>
          )}
          {dashboard.isLocked && dashboard.type !== 'sequence' && (
            <>
              {
                <Button onClick={action} fullWidth>
                  {buttonText}
                </Button>
              }
            </>
          )}
          {dashboard.isProviderLocked && dashboard.type !== 'sequence' && (
            <Button onClick={connectProvider} fullWidth>
              Connect Provider
            </Button>
          )}
        </div>
        <WillyDashImage dashboard={dashboard} />
      </div>
      <div className="flex flex-col gap-2 p-4 sm:px-8 flex-grow mb-2">
        <Flex gap="md" align="start" justify="space-between">
          <Text weight="500" lh="1.15">
            {dashboard.name}
          </Text>
          <div className={'flex justify-center items-center gap-3'}>
            <Flex gap="sm" align={'center'}>
              <Tooltip label={'Users'}>
                <Flex justify={'center'} align={'center'}>
                  <Icon size={14} color={'gray.4'} name={'user-single'} />
                  <Text size="sm" ml={2}>
                    {dashboard.installCount}
                  </Text>
                </Flex>
              </Tooltip>
            </Flex>
            {dashboard.existsInStore && (
              <Tooltip
                label={`Added To Your ${dashboard.type === 'dashboard' ? 'Dashboards' : 'Workflows'} `}
              >
                <div className={'ml-3'}>
                  <AddedToBoard width="16" />
                </div>
              </Tooltip>
            )}
          </div>
        </Flex>
        <Flex gap="sm" align="center" justify="space-between">
          <Text size="xs">by {dashboard.users?.[0] || 'Triple Whale'}</Text>
        </Flex>
        {variant !== 'short' && (
          <div className="flex flex-col justify-between w-full h-full gap-4">
            <p className="line-clamp-4 flex-1 mt-4">
              <Text weight="500" size="sm">
                {dashboard.description}
              </Text>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
