import { $derived, $effect } from '@tw/snipestate';
import { $colorScheme, extractCSSColor } from '@tw/ui-components';
import { userDb } from 'utils/DB';
import { $prefersDarkMode } from './$prefersDarkMode';
import { $forcedFromDark } from './$forcedFromDark';
import { $preferedColorScheme } from './$preferedColorScheme';
import { TOP_BAR_BG, TOP_BAR_BORDER } from 'components/TopBar/constants';
import { $user } from '../../$stores/$user';

/**
 * Global source of truth for whether or not user has dark mode.
 *
 * ---
 * For efficiency reasons, use the exposed `useDarkMode` hook when
 * wanting to determine if dark mode is on in the application.
 */
export const $darkMode = $derived((get) => {
  const forcedFromDark = get($forcedFromDark);
  if (forcedFromDark) return false;

  const prefersDarkMode = get($prefersDarkMode);
  return prefersDarkMode;
});

//
// EVENT HANDLING
//
// handle saving in db
$effect(async (_, get) => {
  const colorScheme = get($preferedColorScheme);
  try {
    // only set prefered color scheme if darkMode changed because of a user
    // action and not if it changed automatically b/c of path for example
    // also need to make sure user exists before attempting to call userDb
    if ($forcedFromDark.get() || !$user.get().uid) return;
    await userDb().set({ colorScheme }, { merge: true });
  } catch (err) {
    console.error('db sync error:>>', err);
  }
});

$effect((_, get) => {
  const darkMode = get($darkMode);

  // this is what changes dark mode for @tw/ui-components
  $colorScheme.set(darkMode ? 'dark' : 'light');

  // set class for tailwind
  if (darkMode) document.body.classList.add('dark');
  else document.body.classList.remove('dark');

  // nav border and top bar styles
  document.documentElement.style.setProperty(
    TOP_BAR_BG,
    extractCSSColor(darkMode ? 'gray.8' : 'white'),
  );
  document.documentElement.style.setProperty(
    TOP_BAR_BORDER,
    `1px solid ${darkMode ? '#262D3B' : '#DFE4EB'}`,
  );
});
