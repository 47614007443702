import { Button, ButtonGroup, DatePicker, Popover, Text, TextField } from '@shopify/polaris';
import { CircleDisableMinor, DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import { getCurrencySymbol } from '@tw/constants';
import TWTable from 'components/library/TWTable/TWTable';
import { BaseColumn } from 'components/library/TWTable/types';
import DropDown from 'components/ltv/DropDown';
import { confirmationModal } from 'ducks/confirmationModal';
import { useAppDispatch } from 'index';
import moment from 'moment-timezone';
import { FC, useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { SelectableInfluencer, influencerExpense, isTimeBasedExpense } from 'types/Influencers';
import { influencersDb } from 'utils/DB';
import { convertDateToMoment } from 'utils/convertDateToMoment';
import { v4 as uuid } from 'uuid';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../../$stores/$shop';

type Props = {
  affluencer: SelectableInfluencer;
  affluencerExpenses: influencerExpense[];
  setAffluencerExpenses: (expenses: influencerExpense[]) => void;
};

export const InfluencerExpenses: FC<Props> = ({
  affluencer,
  affluencerExpenses,
  setAffluencerExpenses,
}) => {
  const currency = useStoreValue($shopCurrency);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState<string>();
  const [endDatePickerOpen, setEndDatePickerOpen] = useState<string>();

  const orderBasedExpenses = useMemo(() => {
    return affluencerExpenses.filter(
      (expense) => expense.type === 'order_based' || expense.spend_type === 'per_order',
    );
  }, [affluencerExpenses]);

  const timeBasedExpenses = useMemo(() => {
    return affluencerExpenses.filter(
      (expense) => expense.type === 'time_based' || expense.spend_type === 'fixed',
    );
  }, [affluencerExpenses]);

  const createNewExpense = useCallback(
    async (type: influencerExpense['type']) => {
      const id = uuid();
      const newExpense: influencerExpense =
        type === 'order_based'
          ? {
              id: id,
              type: type,
              amount: 0,
              amount_type: 'percentage',
              start: moment().format('YYYY-MM-DD'),
              end: '',
              influencer_id: affluencer?.id as string,
            }
          : {
              id: id,
              type: type,
              amount: 0,
              amount_type: 'currency',
              start: moment().format('YYYY-MM-DD'),
              end: '',
              recurring: 'one_time',
              recurring_times: 1,
              influencer_id: affluencer?.id as string,
            };
      setAffluencerExpenses([...affluencerExpenses, newExpense]);
    },
    [affluencer?.id, affluencerExpenses, setAffluencerExpenses],
  );

  const updateExpense = useCallback(
    async (id: string, expense: Partial<influencerExpense>) => {
      const index = affluencerExpenses.findIndex((expense) => expense.id === id);
      const currExpense = affluencerExpenses[index];
      const newExpenses: any[] = [
        ...affluencerExpenses.slice(0, index),
        {
          ...currExpense,
          ...expense,
        },
        ...affluencerExpenses.slice(index + 1),
      ];
      setAffluencerExpenses(newExpenses);
    },
    [affluencerExpenses, setAffluencerExpenses],
  );

  const deleteExpense = useCallback(
    async (id: string) => {
      if (!affluencer.id) return;
      await influencersDb().doc(affluencer.id.toString()).collection('expenses').doc(id).delete();
      const index = affluencerExpenses.findIndex((expense) => expense.id === id);
      const newExpenses = [
        ...affluencerExpenses.slice(0, index),
        ...affluencerExpenses.slice(index + 1),
      ];
      setAffluencerExpenses(newExpenses);
    },
    [affluencer.id, affluencerExpenses, setAffluencerExpenses],
  );

  const getRecurringValue = (row) => {
    let value = row.recurring || row.recurring_cadence;
    value = value?.includes('week') ? 'week' : value?.includes('month') ? 'month' : 'one_time';
    return value;
  };

  return (
    <div className="flex flex-col gap-14">
      <div className="flex flex-col gap-6.5">
        <div className="flex items-center justify-between gap-4">
          <Text as="p" variant="bodyMd" fontWeight="bold">
            Order-based Expenses
          </Text>
          <PlusMinor
            className="w-10 h-10 cursor-pointer"
            onClick={() => {
              createNewExpense('order_based');
            }}
          />
        </div>
        <div>
          <TWTable
            data={orderBasedExpenses}
            noDataNode={[[<p>No order-based expenses</p>]]}
            padding="5px"
            dark={false}
            columns={
              [
                {
                  key: 'remove',
                  name: 'remove',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => '',
                  Value: (row) => <RemoveExpense onRemoveExpense={() => deleteExpense(row.id)} />,
                },
                {
                  key: 'amount',
                  name: 'Amount',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'Amount',
                  Value: (row) => (
                    <TextField
                      type="number"
                      min={0}
                      max={row.amount_type === 'currency' ? 1000000 : 100}
                      value={row.amount?.toString()}
                      onChange={(value) => updateExpense(row.id, { amount: parseInt(value) })}
                      autoComplete="off"
                      label
                      labelHidden
                    />
                  ),
                },
                {
                  key: 'amount_type',
                  name: 'Amount Type',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => '',
                  Value: (row) => (
                    <div className="flex items-center justify-center" style={{ minWidth: '250px' }}>
                      <ButtonGroup segmented>
                        <Button
                          pressed={row.amount_type === 'currency'}
                          onClick={() => {
                            updateExpense(row.id, { amount_type: 'currency' });
                          }}
                        >
                          {
                            (
                              <span className=" whitespace-nowrap">
                                {getCurrencySymbol(currency)} per order
                              </span>
                            ) as any
                          }
                        </Button>
                        <Button
                          pressed={row.amount_type === 'percentage'}
                          onClick={() => {
                            updateExpense(row.id, { amount_type: 'percentage' });
                          }}
                        >
                          {(<span className="whitespace-nowrap">% of order</span>) as any}
                        </Button>
                      </ButtonGroup>
                    </div>
                  ),
                },
                {
                  key: 'start_date',
                  name: 'From',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'From',
                  Value: (row) => {
                    return (
                      <DatePickerRow
                        row={row}
                        datePickerOpen={startDatePickerOpen}
                        setDatePickerOpen={setStartDatePickerOpen}
                        onChange={updateExpense}
                        prop="start"
                      />
                    );
                  },
                },
                {
                  key: 'end_date',
                  name: 'Until',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'Until',
                  Value: (row) => {
                    return (
                      <DatePickerRow
                        row={row}
                        datePickerOpen={endDatePickerOpen}
                        setDatePickerOpen={setEndDatePickerOpen}
                        onChange={updateExpense}
                        prop="end"
                      />
                    );
                  },
                },
              ] as BaseColumn<influencerExpense, any>[]
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-6.5">
        <div className="flex items-center justify-between gap-4">
          <Text as="p" variant="bodyMd" fontWeight="bold">
            Time-based Expenses
          </Text>
          <PlusMinor
            className="w-10 h-10 cursor-pointer"
            onClick={() => {
              createNewExpense('time_based');
            }}
          />
        </div>
        <div>
          <TWTable
            data={timeBasedExpenses}
            dark={false}
            noDataNode={[[<p>No time-based expenses</p>]]}
            padding="5px"
            columns={
              [
                {
                  key: 'remove',
                  name: 'remove',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => '',
                  Value: (row) => <RemoveExpense onRemoveExpense={() => deleteExpense(row.id)} />,
                },
                {
                  key: 'amount',
                  name: 'Amount',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'Amount',
                  Value: (row) => (
                    <TextField
                      type="number"
                      min={0}
                      max={row.amount_type === 'currency' ? 1000000 : 100}
                      value={row.amount?.toString() || '0'}
                      onChange={(value) => updateExpense(row.id, { amount: parseInt(value) })}
                      autoComplete="off"
                      label
                      labelHidden
                    />
                  ),
                },
                {
                  key: 'recurring',
                  name: 'Recurring',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'Recurring',
                  Value: (row) => {
                    const isTime = isTimeBasedExpense(row);
                    if (!isTime) return <span className="whitespace-nowrap">One Time</span>;
                    return (
                      <div className="flex items-center justify-center gap-2">
                        <DropDown
                          value={getRecurringValue(row)}
                          options={[
                            { label: 'None', value: 'one_time' },
                            { label: 'Weekly', value: 'week' },
                            { label: 'Monthly', value: 'month' },
                          ]}
                          handleSelect={(value) => {
                            const obj: any = { recurring: value };
                            if (value === 'one_time') {
                              obj.recurring_times = 0;
                            } else {
                              obj.recurring_times = 1;
                            }
                            updateExpense(row.id, obj);
                          }}
                        />
                        {(row?.recurring !== 'one_time' ||
                          row?.recurring_cadence != 'one_time') && (
                          <div className="flex items-center gap-2">
                            <Text as="p" variant="bodyMd" fontWeight="semibold">
                              Every
                            </Text>
                            <span className="affluencer-expense-input">
                              <TextField
                                align="center"
                                selectTextOnFocus
                                min={0}
                                max={
                                  row?.recurring?.includes('week') ||
                                  row?.recurring_cadence?.includes('week')
                                    ? 52
                                    : 12
                                }
                                value={
                                  row?.recurring_times?.toString() ||
                                  row?.recurring_interval_length?.toString() ||
                                  '0'
                                }
                                onChange={(value) => {
                                  if (value === '') {
                                    updateExpense(row.id, { recurring_times: 0 });
                                    return;
                                  }
                                  updateExpense(row.id, { recurring_times: parseInt(value) });
                                }}
                                autoComplete="off"
                                label
                                labelHidden
                              />
                            </span>
                            <Text as="p" variant="bodyMd" fontWeight="semibold">
                              {row.recurring === 'weekly' ? 'weeks' : 'months'}
                            </Text>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  key: 'start_date',
                  name: 'From',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'From',
                  Value: (row) => {
                    return (
                      <DatePickerRow
                        row={row}
                        datePickerOpen={startDatePickerOpen}
                        setDatePickerOpen={setStartDatePickerOpen}
                        onChange={updateExpense}
                        prop="start"
                      />
                    );
                  },
                },
                {
                  key: 'end_date',
                  name: 'Until',
                  sortable: false,
                  dataType: 'text',
                  Heading: () => 'Until',
                  Value: (row) => {
                    return (
                      <DatePickerRow
                        row={row}
                        datePickerOpen={endDatePickerOpen}
                        setDatePickerOpen={setEndDatePickerOpen}
                        onChange={updateExpense}
                        prop="end"
                      />
                    );
                  },
                },
              ] as BaseColumn<influencerExpense, any>[]
            }
          />
        </div>
      </div>
    </div>
  );
};

type DatePickerRowProps = {
  datePickerOpen: string | undefined;
  setDatePickerOpen: React.Dispatch<React.SetStateAction<string | undefined>>;
  row: influencerExpense;
  prop: 'start' | 'end';
  onChange: (id: string, data: Partial<influencerExpense>) => void;
};

const DatePickerRow: FC<DatePickerRowProps> = ({
  datePickerOpen,
  setDatePickerOpen,
  row,
  prop,
  onChange,
}) => {
  const [month, setMonth] = useState<number>(
    row[prop] ? moment(row[prop]).toDate().getMonth() : moment().toDate().getMonth(),
  );
  const [year, setYear] = useState<number>(
    row[prop] ? moment(row[prop]).toDate().getFullYear() : moment().toDate().getFullYear(),
  );
  const activator = (
    <div
      onClick={() => {
        setDatePickerOpen((x) => (x ? undefined : row.id));
      }}
    >
      <Text as="p" variant="bodyMd">
        {row[prop] ? moment(row[prop]).format('ll') : 'None'}
      </Text>
    </div>
  );

  return (
    <div className="cursor-pointer min-w-[90px]">
      <Popover
        sectioned
        active={datePickerOpen === row.id}
        activator={activator}
        onClose={() => {
          setDatePickerOpen(undefined);
        }}
      >
        {prop === 'end' && (
          <Popover.Pane fixed sectioned>
            <div className="flex items-center gap-2">
              <Text as="p" variant="bodySm" fontWeight="semibold">
                <span
                  className="cursor-pointer flex items-center gap-2"
                  onClick={() => {
                    setDatePickerOpen(undefined);
                    onChange(row.id, { [prop]: null });
                  }}
                >
                  <CircleDisableMinor className="w-6 h-6" />
                  No end date
                </span>
              </Text>
            </div>
          </Popover.Pane>
        )}
        <Popover.Pane fixed sectioned>
          <DatePicker
            id={'spend_date'}
            disableDatesBefore={
              prop === 'end' ? moment(row.start).subtract(1, 'day').toDate() : undefined
            }
            disableDatesAfter={prop === 'start' && row.end ? moment(row.end).toDate() : undefined}
            month={month}
            onMonthChange={(month, year) => {
              setMonth(month);
              setYear(year);
            }}
            year={year}
            allowRange={false}
            onChange={(date) => {
              const newDate = convertDateToMoment(date[prop]).startOf('day').format('YYYY-MM-DD');
              setDatePickerOpen(undefined);
              onChange(row.id, { [prop]: newDate });
            }}
            selected={row[prop] ? moment(row[prop]).toDate() : undefined}
          />
        </Popover.Pane>
      </Popover>
    </div>
  );
};

const RemoveExpense: FC<any> = (props) => {
  const { onRemoveExpense } = props;
  const dispatch = useAppDispatch();
  return (
    <div className="flex items-center justify-center">
      <Button
        size="slim"
        plain
        icon={DeleteMinor}
        onClick={() => {
          dispatch(
            confirmationModal({
              title: 'Delete Expense',
              text: 'Are you sure you want to delete this expense?',
              onCancel: () => {},
              onConfirm: onRemoveExpense,
              confirmButtonText: 'Delete',
            }),
          );
        }}
      />
    </div>
  );
};
