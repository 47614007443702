import { openReportSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useStoreValue } from '@tw/snipestate';
import { Flex, Icon, IconName, Text } from '@tw/ui-components';
import { useDashContext } from 'components/Willy/dashContext';
import { WillyEmoji } from 'components/Willy/types/emojiTypes';
import { visibleLength } from 'components/Willy/utils/willyUtils';
import { useMemo } from 'react';

export const MobileDashboardSelectorOpener = () => {
  const { $dashboard, $standardDashboard } = useDashContext();
  const standardDashboard = useStoreValue($standardDashboard);
  const dashboard = useStoreValue($dashboard);
  const emoji = useMemo<WillyEmoji>(() => {
    if (dashboard?.emoji && !standardDashboard) return dashboard.emoji;
    return '🐳';
  }, [dashboard?.emoji, standardDashboard]);

  return (
    <div className="inline-flex gap-2 items-center">
      <h1
        className={`font-medium text-2xl dark:text-[white] outline-none whitespace-nowrap flex items-center ${
          standardDashboard ? '' : dashboard?.canEdit ? 'cursor-pointer' : ''
        } `}
        onClick={() => {
          openReportSelectorDrawer();
        }}
      >
        <Flex gap={'xs'} justify={'center'} align={'center'}>
          {!!dashboard?.isCustomView && <Text>Private board:</Text>}
          <div className="flex items-center justify-center ">
            {standardDashboard && standardDashboard.icon ? (
              <standardDashboard.icon />
            ) : visibleLength(emoji) > 1 ? (
              <Icon name={emoji as IconName} />
            ) : (
              emoji
            )}
          </div>
          {standardDashboard ? standardDashboard.label : dashboard?.name || 'Untitled Board'}
          <Icon name="up-down-arrows" size={12} color="gray.4" />
        </Flex>
      </h1>
    </div>
  );
};
