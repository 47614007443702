import { TextField } from '@shopify/polaris';

export type DropdownFilterProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
};

export const DropdownFilter: React.FC<DropdownFilterProps> = ({ filter, setFilter }) => {
  return (
    <div className="tw-input-inside-drop-down">
      <TextField
        clearButton
        placeholder="Search"
        value={filter}
        onChange={setFilter}
        label=""
        labelHidden
        onClearButtonClick={() => setFilter('')}
        autoComplete="off"
      />
    </div>
  );
};
