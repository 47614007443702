import { metrics as allMetrics } from '@tw/constants/module/Metrics/allMetrics';
import _ from 'lodash';
import {
  EditRule,
  EditRuleExpression,
  EditStrategy,
  Rule,
  RuleExpression,
  StrategyPayload,
  weekDays,
} from './types';

export function mapConditionsForSave(condition, maxPeriod = 0) {
  let conditionsCopy = { ...condition };
  if (conditionsCopy.path?.includes('Budget') || conditionsCopy.path?.includes('blended')) {
    conditionsCopy.fact = conditionsCopy.path;
    delete conditionsCopy.path;
  }
  //same for value
  if (
    typeof conditionsCopy.value === 'object' &&
    (conditionsCopy.value?.path?.includes('Budget') ||
      conditionsCopy.value?.path?.includes('blended'))
  ) {
    conditionsCopy.value.fact = conditionsCopy.value.path;
    delete conditionsCopy.value.path;
  }
  if ('formulaMode' in conditionsCopy) {
    delete conditionsCopy.formulaMode;
  }
  if (conditionsCopy.isFilter) {
    conditionsCopy.params = { ...conditionsCopy.params, period: maxPeriod };
  }

  conditionsCopy.value =
    allMetrics[conditionsCopy.path]?.format === 'percent'
      ? conditionsCopy.value / 100
      : conditionsCopy.value;
  if (conditionsCopy.path) {
    conditionsCopy.path = !conditionsCopy.path.includes('$')
      ? '$.' + conditionsCopy.path
      : conditionsCopy.path;
  }
  if (conditionsCopy?.value?.path) {
    conditionsCopy.value.path = !conditionsCopy.value.path.includes('$')
      ? '$.' + conditionsCopy.value.path
      : conditionsCopy.value.path;
  }

  delete conditionsCopy.showError;
  delete conditionsCopy.showMetricError;
  delete conditionsCopy.showFormulaError;
  delete conditionsCopy.showFormulaMetricError;
  delete conditionsCopy.showTimeError;
  delete conditionsCopy.showOperatorError;
  delete conditionsCopy.showValueError;
  delete conditionsCopy.showFormulaTimeError;
  return { ...conditionsCopy };
}

export function mapConditionsForEdit(condition) {
  let conditionCopy = { ...condition };
  if (conditionCopy.fact.includes('Budget')) {
    conditionCopy.path = conditionCopy.fact;
    let fact = conditionCopy.fact.split('Budget')[0];
    conditionCopy.fact = fact;
  }
  if (conditionCopy.fact.includes('blended')) {
    conditionCopy.path = conditionCopy.fact;
    let fact = 'account';
    conditionCopy.fact = fact;
  }
  //same for value
  if (typeof conditionCopy.value === 'object' && conditionCopy?.value?.fact?.includes('Budget')) {
    conditionCopy.value.path = conditionCopy.value.fact;
    conditionCopy.value.fact = conditionCopy.value.fact.split('Budget')[0];
  }
  if (typeof conditionCopy.value === 'object' && conditionCopy?.value?.fact?.includes('blended')) {
    conditionCopy.value.path = conditionCopy.value.fact;
    conditionCopy.value.fact = 'account';
  }
  conditionCopy.value =
    allMetrics[conditionCopy.path]?.format === 'percent'
      ? conditionCopy.value * 100
      : conditionCopy.value;
  conditionCopy.path = (conditionCopy.path as string).replace('$.', '');
  return { ...conditionCopy };
}

export function getMaxPeriod(conditions) {
  const periods = reduceRulesConditionsWith((acc: Array<number>, r) => {
    acc.push(r.params?.period || 0);
    return acc;
  })(conditions, []);

  const maxPeriod = Math.max(...periods);
  return maxPeriod;
}

type Conditions<T extends RuleExpression | EditRuleExpression> = T extends EditRuleExpression
  ? EditRule['conditions']
  : Rule['conditions'];

export function mapRulesConditionsWith<T extends RuleExpression | EditRuleExpression>(
  callback: (e: T) => T,
) {
  return (conditions: Conditions<T>): Conditions<T> => {
    let newConditions = { all: [] };
    for (const group of conditions?.all || []) {
      const { all, any, ...rest } = group;
      let newGroup = { ...rest, ...(any && { any: [] }), ...(all && { all: [] }) };
      for (const condition of group?.any || []) {
        let newCondition = callback(condition as T);
        newGroup.any = [...(newGroup.any as any), newCondition] as any;
      }
      for (const condition of group?.all || []) {
        let newCondition = callback(condition as T);
        newGroup.all = [...(newGroup.all as any), newCondition] as any;
      }
      newConditions.all = [...(newConditions.all as any), newGroup] as any;
    }
    return newConditions;
  };
}

export function reduceRulesConditionsWith<T extends RuleExpression | EditRuleExpression>(
  callback: (acc: any, e: T) => any,
) {
  return (conditions: Conditions<T>, initialValue: any) => {
    let accumulator = initialValue;
    for (const group of conditions?.all || []) {
      const { all, any, ...rest } = group;
      for (const condition of group?.any || []) {
        accumulator = callback(accumulator, condition as T);
      }
      for (const condition of group?.all || []) {
        accumulator = callback(accumulator, condition as T);
      }
    }
    return accumulator;
  };
}

export const getConditionsLength = (conditions) => {
  const conditionsLength = conditions.all.reduce((length, group) => {
    length += group?.any?.filter((cond) => !cond.isFilter).length || 0;
    return length;
  }, 0);
  return conditionsLength || 0;
};

export const getTWRules = (strategies) => {
  const twRules = strategies ? strategies.filter((strategy) => strategy.tw_rules) : [];
  return twRules;
};

export const cleanUpForSave = function (shopRule: EditStrategy): StrategyPayload {
  if (shopRule.tw_rules) {
    delete shopRule.versions;
  }

  if (shopRule.versions && !shopRule.version) {
    shopRule.version = shopRule.versions[0];
    delete shopRule.versions;
  }

  if (shopRule.version && shopRule.version.rules[0]) {
    let rules = shopRule.version.rules.map((rule: Rule) => {
      let event = { ...rule.event };

      if (rule.daysOfWeek?.includes(-1)) {
        rule.daysOfWeek = weekDays.map((d) => +d.value).filter((d) => d >= 0);
      }
      rule.daysOfWeek = _.sortBy(rule.daysOfWeek);
      delete rule.nextRunForClient;

      const maxPeriod = getMaxPeriod(rule.conditions);
      const mappedConditions = mapRulesConditionsWith((condition) =>
        mapConditionsForSave(condition, maxPeriod),
      )(rule.conditions);
      return { ...rule, conditions: mappedConditions, event };
    });
    shopRule.version.rules = rules as Rule[];
  }
  for (const rule of shopRule.version?.rules || []) {
    delete rule?._id;
  }

  return shopRule as StrategyPayload;
};
