import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector } from 'reducers/RootType';
import { isInIframe } from 'config';
import { useIsFromSignupInvitation } from './useIsFromSignupInvitation';
import { WELCOME_WIZARD_BASE_PATH } from 'components/WizardWalkthrough/constants';

/**
 * @description All of these conditions represent the user after the signup flow inside the app and
 * not in the iframe when you don't want to show the topbar, the right sidebar, and the left sidebar.
 */
export const useIsUserAfterSignup = (): boolean => {
  const { pathname } = useLocation();

  const isFromSignupInvitation = useIsFromSignupInvitation();
  const isLoggedIn = useAppSelector((state) => state.isLoggedIn);
  const isEmailVerified = useAppSelector((state) => state.isEmailVerified);

  return useMemo(() => {
    return (
      !isInIframe &&
      isLoggedIn &&
      !isFromSignupInvitation &&
      isEmailVerified &&
      !(pathname.includes('signup') || pathname.includes('signin')) &&
      !pathname.includes(WELCOME_WIZARD_BASE_PATH) &&
      !pathname.includes('oauth2') &&
      pathname !== '/payment' &&
      !pathname.includes('shopify-app-install')
    );
  }, [pathname, isFromSignupInvitation, isEmailVerified, isLoggedIn]);
};
