import { $store } from '@tw/snipestate';

export const $reportSelectorDrawer = $store({
  opened: false,
});

export const openReportSelectorDrawer = () => {
  $reportSelectorDrawer.set({ opened: true });
};

export const closeReportSelectorDrawer = () => {
  $reportSelectorDrawer.set({ opened: false });
};

export const $sequenceSelectorDrawer = $store({
  opened: false,
});

export const openSequenceSelectorDrawer = () => {
  $sequenceSelectorDrawer.set({ opened: true });
};

export const closeSequenceSelectorDrawer = () => {
  $sequenceSelectorDrawer.set({ opened: false });
};

export const $chatSelectorDrawer = $store({
  opened: false,
});

export const openChatSelectorDrawer = () => {
  $chatSelectorDrawer.set({ opened: true });
};

export const closeChatSelectorDrawer = () => {
  $chatSelectorDrawer.set({ opened: false });
};
