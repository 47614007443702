import {
  GENERATIVE_AI_POPUP_OPENED,
  GENERATIVE_AI_POPUP_CLOSED,
  TRIGGER_RELOAD_CONTENT_HUB_TAB,
  FINISHED_RELOAD_CONTENT_HUB_TAB,
  GENERATIVE_AI_CHANGE_ENTITY,
  UPDATE_CUSTOM_EXPENSE_CATEGORIES,
  DURATION_FILTERS_VALUE_CHANGED,
  GENERATIVE_LOADING_AD_INFO,
  GENERATIVE_LOADING_AD_INFO_FINISHED,
  GENERATIVE_LOADING_PRODUCT_INFO,
  GENERATIVE_LOADING_PRODUCT_INFO_FINISHED,
  AD_PREVIEW_POPUP_OPENED,
  AD_PREVIEW_POPUP_CLOSED,
  CHANGE_CONTENT_HUB_SEARCH_TERM,
  OPEN_UPLOAD_MEDIA,
  CLOSE_UPLOAD_MEDIA,
  ADD_BRAND_COLOR,
  REMOVE_BRAND_COLOR,
  ADD_PROMPT,
  REMOVE_PROMPT,
  PIN_PROMPT,
  UNPIN_PROMPT,
  CHANGE_PROMPT_ORDER,
  INIT_PROMPTS,
  SET_SHOP_BILLING_INTERVAL,
  INIT_MULTIPLE_SHOPS_BILLING_INTERVAL,
  ADD_BRAND_FONT,
  REMOVE_BRAND_FONT,
  TOGGLE_BULK_ADBUILDER,
  COPY_TO_CLIPBOARD_ACTIVE,
  COPY_TO_CLIPBOARD_INACTIVE,
  CHANGE_CONTENT_HUB_FOLDER,
  PPS_STATUS,
  CHANGE_AD_BUILDER_SOURCE,
  ONLINE,
  OFFLINE,
  HIDE_OFFLINE_BANNER,
  CUSTOM_SPEND_GOOGLE_SHEET_RECEIVED,
  IMPORT_CUSTOM_SPENDS_GS_MODAL_TOGGLE,
  IMPORT_COSTS_ON_CLOSE,
  IMPORT_COSTS_ON_PRESS,
  SET_ACTIVE_APP,
} from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { isProduction } from 'config';
import moment from '@tw/moment-cached/module/timezone';
import { AnyAction, combineReducers, Reducer } from 'redux';
import {
  BackgroundDiffusionPopupData,
  SummaryPopupMetadata,
  GenerativeAiGeneratePopUpData,
  GenerativeAiPreGeneratePopUpData,
  GenerativeCopyPopupData,
  ShopPrompt,
  ShopBillingIntervalMap,
} from 'types/general';
import { getQueryPrevStringStartEnd, getQueryStringStartEnd } from 'utils/getQueryStringStartEnd';

import { AbstractChart } from '@tw/types';

import { APP_STARTED, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { reducers as shopifySalesBySegmentModal } from '../ducks/shopify/salesBySegmentModal';
import { reducers as adSegmentations } from '../ducks/serviceSegmentations';
import { reducers as amazon } from '../ducks/amazon';
import { reducers as bing } from '../ducks/bing';
import { reducers as externalApps } from '../ducks/apps';
import { reducers as attribution } from '../ducks/attribution/reducers';
import { reducers as affluencerHub } from '../ducks/affluencerHub/reducers';
import { reducers as auth } from '../ducks/auth';
// import { reducers as blendedMetrics } from '../ducks/blendedMetrics';
import { reducers as benchmarks } from '../ducks/businessIntelligence';
import { reducers as customers } from '../ducks/customers';
import { reducers as shopifyGeneral } from '../ducks/shopify/shopify';
import { RECEIVE_SHOPIFY_SEGMENTS, reducers as shopify } from '../ducks/shopify/newShopify';
import { reducers as confirmationModal } from '../ducks/confirmationModal';
import { reducers as mediaPicker } from '../ducks/contentHub/mediaPicker';
import { reducers as creativeCockpit } from '../ducks/creativesCockpit/creativesCockpit';
import { reducers as auditSegments } from '../components/RulesEngine/Audit/utils';
import { customMetrics, loadingCustomMetrics } from '../ducks/customMetrics';

import { reducers as sensory } from '../ducks/sensory';

import { reducers as customSections } from '../ducks/customSections';
import { reducers as enquirelabs } from '../ducks/enquirelabs';
import { reducers as facebook } from '../ducks/facebook';
import { reducers as customerJourneys } from '../ducks/customerJourneys/reducers';
import { reducers as insights } from '../ducks/insights/reducers';
import { reducers as insightsFilter } from '../ducks/insights/filterReducers';
// import { reducers as forecasting } from '../ducks/forecasting';
import { reducers as hubspot } from '../ducks/hubSpot';
import {
  ADD_NEW_CUSTOM_SPEND_CATEGORY,
  CHANGE_ACTIVE_PAGE,
  CLEAR_INITIAL_DATA,
  COGS_UPLOAD_DIALOG_CANCEL_ON_PRESS,
  COMPARE_DATE_PICKER_SELECTED_OPTION,
  CUSTOM_EXPENSE_CALCULATE_COGS,
  CUSTOM_SPEND_ON_DELETE,
  CUSTOM_SPEND_SAVE,
  CUSTOM_SPENDS_UPLOAD_DIALOG_CANCEL_ON_PRESS,
  DATES_TO_COMPARE,
  EDIT_CUSTOM_SPEND,
  HIDE_CUSTOM_SPEND_BANNER,
  HIDE_GENERAL_ERROR_TOAST,
  HIDE_PROMPT,
  IMPORT_CUSTOM_SPENDS_TABLE_ON_PRESS,
  IMPORT_PRODUCTS_TABLE_ON_PRESS,
  IMPORTING_DETECTED,
  IMPORTING_NEW_INFRA_DETECTED,
  INIT_SHOP,
  INIT_UI,
  INITIALIZING,
  LIGHTBOX_CLOSE,
  LIGHTBOX_OPEN,
  LOADING_CUSTOM_SPENDS,
  LOADING_ORDERS,
  LOADING_REFUNDS,
  MAIN_DATE_PICKER_SELECTION_CHANGE,
  MAIN_DATE_PICKER_SPECIAL_PERIOD_CHANGE,
  MOBILE_NOTIFICATIONS_PERMISSION_GIVEN,
  NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE,
  NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE,
  NEW_CUSTOM_SPEND_WINDOW_TOGGLE,
  NEW_METRIC_BASED_CUSTOM_SPEND_WINDOW_TOGGLE,
  RECEIVE_CUSTOM_SPENDS,
  RECEIVE_ORDERS,
  RECEIVE_REFUNDS,
  REMOVE_ALERT,
  SERVICE_FINISHED_IMPORTING,
  SET_CUSTOM_SPENDS_FLAG,
  SET_EARLIEST_DATE,
  SHOW_GENERAL_ERROR_TOAST,
  SHOW_PROMPT,
  SUMMARY_BOX_ON_PRESS,
  SUMMARY_CHART_POPUP_IS_OPEN_TOGGLE,
  SUMMARY_IS_EXPANDED_TOGGLE,
  SUMMARY_ON_REFRESH,
  TOGGLE_TOPBAR_USER_MENU,
  TOPBAR_MOBILE_NAVIGATION_CLOSE,
  TOPBAR_MOBILE_NAVIGATION_TOGGLE,
  UPDATE_CUSTOM_EXPENSE_ATTRIBUTION_TYPE,
  UPDATE_SHOP,
  IS_NAVIGATION_OPEN,
  GENERATE_COPY_AI_POPUP_OPENED,
  GENERATE_COPY_AI_POPUP_CLOSED,
  GENERATE_COPY_LOADING_AD_INFO,
  GENERATE_COPY_LOADING_AD_INFO_FINISHED,
  GENERATE_COPY_CHANGE_ENTITY,
  SET_GENERATED_TEXT,
  UNSET_GENERATED_TEXT,
  BACKGROUND_DIFFUSION_POPUP_OPENED,
  BACKGROUND_DIFFUSION_POPUP_CLOSED,
} from 'ducks/constants';
import { reducers as productAnalytics } from '../ducks/productAnalytics';
import { reducers as googleAds } from '../ducks/googleAds';
import { reducers as googleSheets } from '../ducks/googleSheets';
import { reducers as gorgias } from '../ducks/gorgias';
import { reducers as inventory } from '../ducks/inventory';
import { reducers as klaviyo } from '../ducks/klaviyo';
import { reducers as kno } from '../ducks/kno';
import { reducers as ltv } from '../ducks/ltv';
import { reducer as modalCustomSectionReducer } from '../ducks/modalCustomSectionReducer';
import { reducers as newStats } from '../ducks/newStats';
import { reducers as onboarding } from '../ducks/onboarding';
// import { reducers as shopOrders } from '../ducks/orders';
import { reducers as orderTags } from '../ducks/shopify/orderTags';
import { reducers as shopifySegmentSources } from '../ducks/shopify/shopifySegmentSources';
import { reducers as paymentGateways } from '../ducks/paymentGateways';
import { reducers as pinterest } from '../ducks/pinterest';
import { reducers as pixel } from '../ducks/pixel';
import { reducers as pixelHealth } from '../ducks/pixelHealth';
import { reducers as postPurchaseSurvey } from '../ducks/postPurchaseSurvey';
import { reducers as products } from '../ducks/products';
import { reducers as reports } from '../ducks/reports';
import { reducers as rulesEngine } from '../ducks/rulesEngine/reducers';
import { reducers as shipping } from '../ducks/shipping';
import { reducers as shop } from '../ducks/shop';
import { reducers as signupJourney } from '../ducks/signupJourney';
import { reducers as sitePlan } from '../ducks/sitePlan';
import { reducers as slack } from '../ducks/slack';
import { reducers as snapchat } from '../ducks/snapchat';
import { reducers as stats } from '../ducks/stats';
import { reducers as subscription } from '../ducks/subscription';
import { reducers as summary } from '../ducks/summary';
import { reducers as tiktok } from '../ducks/tiktok';
import { reducers as twitter } from '../ducks/twitter';
import { reducers as tiles } from '../ducks/tiles';
import { reducer as tilesModalSettingsReducer } from '../ducks/tilesModalSettingsReducer';
import { reducers as userProfile } from '../ducks/user';
import { reducers as currentPod } from '../ducks/workspace';
import { reducers as workspaceStats } from '../ducks/WorkspaceStats';
import { reducers as shopServices } from '../ducks/shopServices';
import { reducers as CDPSegments } from '../ducks/cdp/segments';
import { reducers as lighthouse } from '../ducks/lighthouse';
import { reducers as notificationSettings } from '../ducks/notificationSettings';
import { reducers as activityFeed } from '../ducks/activityFeed';
import { reducers as welcomeWizard } from '../ducks/welcomeWizard';
import { reducers as Ui } from '../ducks/UiDuck';
import { reducers as integrations } from '../ducks/shopIntegrations';
import { BuiltAdType } from 'constants/creativeCockpit';
import { uniqBy } from 'lodash';
import { reducers as mobileApp } from '../ducks/mobileApp';
import { GET_CURRENT_USER, ON_AUTH_STATE_CHANGED } from 'ducks/constants';
import {
  reducers as willy,
  willySummaryBox,
  willySummaryWidget,
  willyTempSummaryBox,
} from '../ducks/willy';
import { reducers as monitoringIncidents } from '../ducks/monitoringIncidents';
import { reducers as apiKeys } from '../ducks/apiKeys';
import { baseURL } from 'config';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';

const isLoggedIn = (state = false, action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED:
      return !!action.user;
    default:
      return state;
  }
};

const loggedInUserId = (state = '', action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED:
      return action?.user?.uid ?? state;
    default:
      return state;
  }
};

const EMAIL_VERIFIED = 'EMAIL_VERIFIED';

export const emailVerified = () => ({
  type: EMAIL_VERIFIED,
});

const isEmailVerified = (state = false, action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED:
      return action?.user?.emailVerified ?? state;
    case EMAIL_VERIFIED:
      return true;
    default:
      return state;
  }
};

const isNavigationOpen = (state = false, action) => {
  switch (action.type) {
    case IS_NAVIGATION_OPEN:
      return action.payload;
    default:
      return state;
  }
};

const isLightboxOpen = (state = { open: false, data: [] }, action) => {
  switch (action.type) {
    case LIGHTBOX_OPEN:
      return { open: true, data: action.data };
    case LIGHTBOX_CLOSE:
      return { open: false, data: [] };
    default:
      return state;
  }
};

const loadingOrders = (state = false, action) => {
  switch (action.type) {
    case LOADING_ORDERS:
      return true;
    case RECEIVE_ORDERS:
      return false;
    default:
      return state;
  }
};

const loadingRefunds = (state = false, action) => {
  switch (action.type) {
    case LOADING_REFUNDS:
      return true;
    case RECEIVE_REFUNDS:
      return false;
    default:
      return state;
  }
};

const loadingCustomSpends = (state = true, action) => {
  switch (action.type) {
    case LOADING_CUSTOM_SPENDS:
      return true;
    case RECEIVE_CUSTOM_SPENDS:
      return false;
    default:
      return state;
  }
};

const customSpendCategories = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
    case UPDATE_SHOP:
      return action.customSpendCategories || state;
    case ADD_NEW_CUSTOM_SPEND_CATEGORY:
      return [...state, action.category];
    case UPDATE_CUSTOM_EXPENSE_CATEGORIES:
      return [...state, ...action.categories];
    default:
      return state;
  }
};

const customExpenseAttributionType = (state = 'daily', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action?.customExpenseAttributionType ?? 'daily';
    case UPDATE_CUSTOM_EXPENSE_ATTRIBUTION_TYPE:
      return action.payload;
    default:
      return state;
  }
};

const customExpenseSmartAttribution = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case UPDATE_SHOP:
      return action?.customExpenseSmartAttribution ?? {};
    default:
      return state;
  }
};

const orders = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_ORDERS:
      return action.orders;
    default:
      return state;
  }
};

const refunds = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_REFUNDS:
      return action.refunds;
    default:
      return state;
  }
};

const loadingShippingCosts = (state = true, action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return false;
    default:
      return state;
  }
};

const customSpends = (state: any[] = [], action) => {
  var newState, customSpend;
  switch (action.type) {
    case RECEIVE_CUSTOM_SPENDS:
      return action.customSpends.filter((x) => x?.isActive !== false) || [];
    case CUSTOM_SPEND_SAVE:
      customSpend = state.findIndex((x) => x.id === action.customSpend?.id);
      newState = state.slice();
      if (customSpend >= 0) newState[customSpend] = action.customSpend;
      else newState = state.slice().concat([action.customSpend]);
      return newState;
    case CUSTOM_SPEND_ON_DELETE:
      newState = state.filter((customSpend) => customSpend.id !== action.customSpend.id);
      return newState;
    default:
      return state;
  }
};
const customSpendsFlag = (state = false, action) => {
  switch (action.type) {
    case SET_CUSTOM_SPENDS_FLAG:
      return action.payload;
    default:
      return state;
  }
};

const customSpendsGoogleSheet = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_SPEND_GOOGLE_SHEET_RECEIVED:
      return action.sheet ?? {};
    default:
      return state;
  }
};

const hideCustomSpendBanner = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { hideCustomSpendBanner } = action;
      return !!hideCustomSpendBanner;
    case HIDE_CUSTOM_SPEND_BANNER:
      return true;
    default:
      return state;
  }
};
const initialCustomSpendData = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_SPEND_SAVE:
      return {};
    case EDIT_CUSTOM_SPEND:
      return action.payload;
    case CLEAR_INITIAL_DATA:
      return {};
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED:
      return false;
    // case INITIALIZING:
    //     return true;
    default:
      return state;
  }
};

const userEmail: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED:
      return action.user?.email ?? '';
    default:
      return state;
  }
};

const firebaseUser = (state = null, action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED:
      return action.user ?? state;
    default:
      return state;
  }
};

const loadingStore = (state = false, action) => {
  switch (action.type) {
    case INITIALIZING:
      return true;
    case INIT_SHOP:
      return false;
    default:
      return state;
  }
};

const shopInitiated = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return true;
    default:
      return state;
  }
};

const topbarUserMenuIsOpen = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_TOPBAR_USER_MENU:
      return !state;

    default:
      return state;
  }
};

const newCustomSpendWindowIsOpen = (state = false, action) => {
  switch (action.type) {
    case NEW_CUSTOM_SPEND_WINDOW_TOGGLE:
      return !state;
    default:
      return state;
  }
};

const newMetricBasedCustomSpendWindowIsOpen = (state = false, action) => {
  switch (action.type) {
    case NEW_METRIC_BASED_CUSTOM_SPEND_WINDOW_TOGGLE:
      return !state;
    default:
      return state;
  }
};
const importCustomSpendsGSModalOpen = (state = false, action) => {
  switch (action.type) {
    case IMPORT_CUSTOM_SPENDS_GS_MODAL_TOGGLE:
      return !state;
    default:
      return state;
  }
};

const topbarMobileNavigationIsOpen = (state = false, action) => {
  switch (action.type) {
    case TOPBAR_MOBILE_NAVIGATION_TOGGLE:
      return !state;
    case TOPBAR_MOBILE_NAVIGATION_CLOSE:
      return false;
    default:
      return state;
  }
};

const importProductsTableIsOpen = (state = false, action) => {
  switch (action.type) {
    case COGS_UPLOAD_DIALOG_CANCEL_ON_PRESS:
      return false;
    case IMPORT_PRODUCTS_TABLE_ON_PRESS:
      return !state;

    default:
      return state;
  }
};

const isMspUninstalled = (
  state = false,
  //state = null,
  action,
) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.mspUninstalled;
    default:
      return state;
  }
};

const isStripeBased: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.isStripeBased;
    default:
      return state;
  }
};

const durationFiltersValue = (state = 6, action) => {
  switch (action.type) {
    case DURATION_FILTERS_VALUE_CHANGED:
      return action.payload;
    default:
      return state;
  }
};

// const mainDatePickerSelectionRangeDateObject = (
//   state: any = {},
//   //state = null,
//   action
// ) => {
//   switch (action.type) {
//     case INIT_SHOP:
//       // https://stackoverflow.com/a/53652131/1206613

//       state.start = new Date(
//         moment().startOf('day').toDate().toLocaleString('en-US', {
//           timeZone: moment().tz(),
//         })
//       );
//       state.end = new Date(
//         moment().endOf('day').toDate().toLocaleString('en-US', {
//           timeZone: moment().tz(),
//         })
//       );
//       return state;

//     default:
//       return state;
//   }
// };

const mainDatePickerSelectionRange = (
  state: any = null,
  action: AnyAction,
): { id: string; start: moment.Moment; end: moment.Moment } | null => {
  const { start, end } = getQueryStringStartEnd();
  switch (action.type) {
    case INIT_SHOP:
      return (
        (state as any) || {
          id: 'today',
          start: Object.freeze(moment(start).startOf('day')),
          end: Object.freeze(moment(end).endOf('day')),
        }
      );
    case MAIN_DATE_PICKER_SELECTION_CHANGE:
      return {
        id: action.range.id,
        start: Object.freeze(moment(action.range.start).startOf('day')),
        end: Object.freeze(moment(action.range.end).endOf('day')),
      };

    default:
      return state as any;
  }
};

const mainDatePickerSpecialPeriod = (state = null, action) => {
  switch (action.type) {
    case MAIN_DATE_PICKER_SPECIAL_PERIOD_CHANGE:
      return action.period;
    default:
      return state;
  }
};

// TODO: This needs to be extracted from Willy - shouldn't be part of regular redux
// extract to normal store
// REFACTOR
const datesToCompare: Reducer<{ start: string; end: string; id: PreviousPeriodIds }> = (
  state = { start: moment().format(), end: moment().format(), id: 'default' },
  action,
) => {
  switch (action.type) {
    // case INIT_UI:
    //   const searchParams = new URLSearchParams(location.search);
    //   const { compareDatePickerSelectedOption, shops } = action;

    //   let prevId =
    //     (searchParams.get(PREV_DATE_ID) as PreviousPeriodIds) || compareDatePickerSelectedOption;
    //   const compareSelectedId = prevId;
    //   const timezone = shops?.[$currentShopId.get()!]?.timezone;
    //   if (!compareSelectedId || compareSelectedId === 'none' || !timezone) {
    //     return state;
    //   }

    //   if (prevId === 'custom') {
    //     const { start, end } = getQueryPrevStringStartEnd();
    //     return {
    //       start: start ?? state.start,
    //       end: end ?? state.start,
    //       id: prevId as PreviousPeriodIds,
    //     };
    //   }

    //   const { start, end } = getQueryStringStartEnd();

    //   const defaultCompareOption: PreviousPeriodIds = !!compareSelectedId
    //     ? compareSelectedId
    //     : 'default';

    //   if (defaultCompareOption === 'previousPeriod' || defaultCompareOption === 'default') {
    //     const { start: prevStart, end: endPrev } = getPrevDates({
    //       start: moment.tz(start, timezone || '').startOf('day'),
    //       end: moment.tz(end, timezone || '').endOf('day'),
    //     });
    //     pushDateToQS($navigate.get(), prevStart, endPrev, false);
    //     return {
    //       start: prevStart.format(),
    //       end: endPrev.format(),
    //       id: defaultCompareOption,
    //     };
    //   }

    //   const period = mapFromPeriodIdToTimeUnit[defaultCompareOption];

    //   if (!period) {
    //     return state;
    //   }

    //   const prevStart = moment.tz(start, timezone).subtract(1, period).startOf('day');
    //   const prevEnd = moment.tz(end, timezone).subtract(1, period).endOf('day');

    //   pushDateToQS($navigate.get(), prevStart, prevEnd, false);

    //   return {
    //     start: prevStart.format(),
    //     end: prevEnd.format(),
    //     id: defaultCompareOption,
    //   };
    case DATES_TO_COMPARE:
      const finalStart = action?.period?.start
        ? moment(action.period.start).startOf('day')
        : moment(state.start);
      const finalEnd = action?.period?.end
        ? moment(action.period.end).endOf('day')
        : moment(state.end);
      return {
        start: finalStart.format(),
        end: finalEnd.format(),
        id: action.period.id,
      };

    default:
      return state;
  }
};

const summaryIsExpanded = (state = true, action) => {
  switch (action.type) {
    case SUMMARY_IS_EXPANDED_TOGGLE:
      return !state;

    default:
      return state;
  }
};

const summaryChartPopupIsOpen = (state = false, action) => {
  switch (action.type) {
    case SUMMARY_CHART_POPUP_IS_OPEN_TOGGLE:
      return !state;
    case SUMMARY_BOX_ON_PRESS:
      return true;
    default:
      return state;
  }
};

const summaryCurrentPopupChart: Reducer<
  ({ data: AbstractChart[] } & SummaryPopupMetadata) | null
> = (state = null, action) => {
  switch (action.type) {
    case SUMMARY_BOX_ON_PRESS:
      return action.chartSettings;

    default:
      return state;
  }
};

const newCustomSpendStartDateMonth = (state = moment().month(), action) => {
  switch (action.type) {
    case NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE:
      return action.month;
    default:
      return state;
  }
};

const newCustomSpendStartDateYear = (state = moment().year(), action) => {
  switch (action.type) {
    case NEW_CUSTOM_SPEND_START_DATE_PICKER_MONTH_CHANGE:
      return action.year;
    default:
      return state;
  }
};
const newCustomSpendEndDateMonth = (state = moment().month(), action) => {
  switch (action.type) {
    case NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE:
      return action.month;
    default:
      return state;
  }
};

const newCustomSpendEndDateYear = (state = moment().year(), action) => {
  switch (action.type) {
    case NEW_CUSTOM_SPEND_END_DATE_PICKER_MONTH_CHANGE:
      return action.year;
    default:
      return state;
  }
};

const summaryLastRefresh: Reducer<moment.Moment> = (state = moment(), action) => {
  switch (action.type) {
    case SUMMARY_ON_REFRESH:
      return action.timestamp;
    default:
      return state;
  }
};

const earliestDate = (state = new Date(), action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.earliestDate && action.earliestDate?.toDate
        ? action.earliestDate.toDate()
        : state;
    case UPDATE_SHOP:
      return action.earliestDate && action.earliestDate?.toDate
        ? action.earliestDate.toDate()
        : state;
    case SET_EARLIEST_DATE:
      return action.earliestDate || state;
    default:
      return state;
  }
};

const hasMobileApp = (state = false, action) => {
  switch (action.type) {
    case INIT_UI:
      return !!action.notificationsToken;
    case MOBILE_NOTIFICATIONS_PERMISSION_GIVEN:
      return true;
    default:
      return state;
  }
};

const shopTimezone: Reducer<string> = (state = 'America/New_York', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.timezone ? action.timezone : state;
    default:
      return state;
  }
};

const useRealTimeCogsStatsCalculation: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.useRealTimeCogsStatsCalculation ?? true;
    default:
      return state;
  }
};

const importCostsModalIsOpen = (state = false, action) => {
  switch (action.type) {
    case IMPORT_COSTS_ON_CLOSE:
      return false;
    case IMPORT_COSTS_ON_PRESS:
      return !state;
    default:
      return state;
  }
};

const importCustomSpendsTableIsOpen = (state = false, action) => {
  switch (action.type) {
    case CUSTOM_SPENDS_UPLOAD_DIALOG_CANCEL_ON_PRESS:
      return false;
    case IMPORT_CUSTOM_SPENDS_TABLE_ON_PRESS:
      return !state;

    default:
      return state;
  }
};

const promptSettings = (state = null, action) => {
  switch (action.type) {
    case SHOW_PROMPT:
      return action.promptSettings;
    case HIDE_PROMPT:
      return null;
    default:
      return state;
  }
};

const networkOnline = (state = true, action) => {
  switch (action.type) {
    case ONLINE:
      return true;
    case OFFLINE:
      return false;
    default:
      return state;
  }
};

const showOfflineBanner = (state = false, action) => {
  switch (action.type) {
    case HIDE_OFFLINE_BANNER:
      return false;
    case ONLINE:
      return false;
    case OFFLINE:
      return true;
    default:
      return state;
  }
};

const shopCreatedAt = (state = null, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { createdAt } = action;
      return createdAt?.toDate ? moment(createdAt.toDate()) : moment();
    default:
      return state;
  }
};

const shopIcon: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { icon, shopId } = action;
      return icon || `${baseURL}/v2/media/shop-icon/${shopId}`;
    default:
      return state;
  }
};

const isNewShopAfterBlockNetProfitRelease = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { createdAt } = action;
      const date = createdAt?.toDate
        ? moment(createdAt.toDate())
        : moment('2023-08-02T00:00:00.000Z');
      return date.isSameOrAfter(moment('2023-08-02T00:00:00.000Z'));
    default:
      return state;
  }
};

const isShowCreativeDashboard = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { isShowCreativeDashboard } = action;
      return !!isShowCreativeDashboard;
    default:
      return state;
  }
};

const isBetaShop = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { isBetaShop } = action;
      return !!isBetaShop || !isProduction || window.location.search?.includes('beta=1');
    default:
      return state;
  }
};

const rulesEngineTester = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      var { rulesEngineTester } = action;
      return !!rulesEngineTester;
    default:
      return state;
  }
};

const hasLighthouse: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      const { lighthouse } = action.user;
      return (
        (lighthouse?.hasLighthouse === undefined ? true : lighthouse) ||
        !isProduction ||
        window.location.search?.includes('lh=1')
      );
    default:
      return state;
  }
};

const hasPps: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    // case GET_CURRENT_USER:
    //   const { hasPps } = action.user;
    //   return hasPps || !isProduction || window.location.search?.includes('pps=1') || state
    // case INIT_SHOP:
    //   const { hasPps: hasPpsFromShop } = action;
    //   return hasPpsFromShop || state
    default:
      return state;
  }
};

type PpsStatus = {
  hasPpsData?: boolean;
  hasAllValidSampleSize?: boolean;
  invalidSampleSizeDates: string[];
  missingDates: string[];
  status: string;
};

const ppsStatus: Reducer<PpsStatus> = (
  state = { invalidSampleSizeDates: [], missingDates: [], status: '' },
  action,
) => {
  switch (action.type) {
    case PPS_STATUS:
      return action.payload;
    default:
      return state;
  }
};

const urlParams = new URLSearchParams(window.location.search);

const shopifyMongoIsCalculated = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { shopifyMongoIsCalculated } = action;
      const urlCogs = urlParams.get('mongoNewCalc') === 'true';
      return !!shopifyMongoIsCalculated || urlCogs;
    default:
      return state;
  }
};

const pixelShowDirect = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { pixelShowDirect } = action;
      return !!pixelShowDirect || window.location.search?.includes('direct=1');
    default:
      return state;
  }
};

const hasNexus = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { hasNexus } = action;
      return !!hasNexus || window.location.search?.includes('nexus=1');
    default:
      return state;
  }
};

const canUpdateAd = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { canUpdateAd } = action;
      return !!canUpdateAd;
    default:
      return state;
  }
};

const canCreateAd = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const { canCreateAd } = action;
      return !!canCreateAd;
    default:
      return state;
  }
};
const hasContentHub = (state = true, action) => {
  switch (action.type) {
    // case INIT_SHOP:
    //   if (window.location.search?.includes('ch=1')) {
    //     return true;
    //   }
    //   const { hasContentHub } = action;
    //   return !!hasContentHub;
    default:
      return state;
  }
};

const hasContentHubWithFolders = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return window.location.search?.includes('chf=1');
    //   const { hasContentHub } = action;
    //   return !!hasContentHub;
    // eslint-disable-next-line
    default: // eslint-disable-next-line
      return state;
  }
};

const dbShop = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action;
    default:
      return state;
  }
};

const isProd = (state = true, action) => {
  switch (action.type) {
    case APP_STARTED:
      return isProduction;
    default:
      return state;
  }
};

const shopLoading = (state = true, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return false;
    default:
      return state;
  }
};

const brandColors: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.brandColors ?? [];
    case ADD_BRAND_COLOR:
      return [...state, action.color];
    case REMOVE_BRAND_COLOR:
      return state.filter((color) => color !== action.color);
    default:
      return state;
  }
};

const brandFonts: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.brandFonts ?? [];
    case ADD_BRAND_FONT:
      return [...state, action.font];
    case REMOVE_BRAND_FONT:
      return state.filter((font) => font !== action.font) || [];
    default:
      return state;
  }
};

const shopPrompts: Reducer<ShopPrompt[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_PROMPTS:
      return action.prompts ?? [];
    case ADD_PROMPT:
      return uniqBy([action.prompt, ...state], (prompt) => prompt.id);
    case REMOVE_PROMPT:
      return state.filter((prompt) => prompt.id !== action.prompt.id);
    case PIN_PROMPT:
      return state.map((prompt) => {
        if (prompt.id === action.prompt.id) {
          return { ...prompt, pinned: true };
        } else {
          return prompt;
        }
      });
    case UNPIN_PROMPT:
      return state.map((prompt) => {
        if (prompt.id === action.prompt.id) {
          return { ...prompt, pinned: false };
        } else {
          return prompt;
        }
      });
    case CHANGE_PROMPT_ORDER:
      return action.prompts;
    default:
      return state;
  }
};

const importingServices: Reducer<Record<string, any>> = (state = {}, action) => {
  switch (action.type) {
    case IMPORTING_DETECTED:
      return action.services;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return {
        ...state,
        [action.serviceId]: { days: 1 },
      };
    default:
      return state;
  }
};

const importingNewInfraServices: Reducer<Record<string, any>> = (state = {}, action) => {
  switch (action.type) {
    case IMPORTING_NEW_INFRA_DETECTED:
      return action.services;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return {
        ...state,
        [action.serviceId]: { days: 1 },
      };
    default:
      return state;
  }
};

const justImportedServices = (state = null, action) => {
  switch (action.type) {
    case SERVICE_FINISHED_IMPORTING:
      return action.id;
    default:
      return state;
  }
};

const anyImportingNow = (state = false, action) => {
  switch (action.type) {
    case IMPORTING_DETECTED:
      return !!Object.keys(action.services).length;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return true;
    default:
      return state;
  }
};

const anyImportingNowNewInfra = (state = false, action) => {
  switch (action.type) {
    case IMPORTING_NEW_INFRA_DETECTED:
      return !!Object.keys(action.services).length;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return true;
    default:
      return state;
  }
};

const isOneDay = (state = true, action) => {
  switch (action.type) {
    case INIT_SHOP: {
      const { start, end } = getQueryStringStartEnd();
      if (start && end) {
        return moment(start).isSame(moment(end), 'day');
      } else {
        return state;
      }
    }
    case MAIN_DATE_PICKER_SELECTION_CHANGE:
      const { start, end } = action.range;
      return moment(start).isSame(moment(end), 'day');
    default:
      return state;
  }
};

const isOneYear = (state = true, action) => {
  switch (action.type) {
    case INIT_SHOP: {
      const { start, end } = getQueryStringStartEnd();
      if (start && end) {
        return moment(start).isSame(moment(end), 'year');
      } else {
        return state;
      }
    }
    case MAIN_DATE_PICKER_SELECTION_CHANGE:
      var { start, end } = action.range;
      return moment(start).isSame(moment(end), 'year');
    default:
      return state;
  }
};

const datePickerRangeYearsDifference = (state = true, action) => {
  switch (action.type) {
    case INIT_SHOP: {
      const { start, end } = getQueryStringStartEnd();
      if (start && end) {
        return moment(start).year() - moment(end).year();
      } else {
        return state;
      }
    }
    case MAIN_DATE_PICKER_SELECTION_CHANGE:
      var { start, end } = action.range;
      return moment(start).year() - moment(end).year();
    default:
      return state;
  }
};

const showGeneralErrorToast = (state = false, action) => {
  switch (action.type) {
    case SHOW_GENERAL_ERROR_TOAST:
      return true;
    case HIDE_GENERAL_ERROR_TOAST:
      return false;
    default:
      return state;
  }
};
const generalErrorToastMsg = (state = '', action) => {
  switch (action.type) {
    case SHOW_GENERAL_ERROR_TOAST:
      return action.msg;
    case HIDE_GENERAL_ERROR_TOAST:
      return '';
    default:
      return state;
  }
};

const allAlerts = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case UPDATE_SHOP:
      return action.alerts || {};
    case REMOVE_ALERT:
      //   let newState = state[page][alertType].filter(m => m!== msg)
      //   state[page][alertType]=newState
      return state;
    default:
      return state;
  }
};

const floppyWhaleModalOpen = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case 'FLOPPY_WHALE_MODAL_CLOSED':
      return false;
    case 'FLOPPY_WHALE_MODAL_OPENED':
      return true;
    default:
      return state;
  }
};

const generativeAiPopupOpen = (state = false, action) => {
  switch (action.type) {
    case GENERATIVE_AI_POPUP_OPENED:
      return true;
    case GENERATIVE_AI_POPUP_CLOSED:
      return false;
    default:
      return state;
  }
};

const backgroundDiffusionOpen = (state = false, action) => {
  switch (action.type) {
    case BACKGROUND_DIFFUSION_POPUP_OPENED:
      return true;
    case BACKGROUND_DIFFUSION_POPUP_CLOSED:
      return false;
    default:
      return state;
  }
};

const backgroundDiffusionPopupData: Reducer<Partial<BackgroundDiffusionPopupData>> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case BACKGROUND_DIFFUSION_POPUP_OPENED:
      return action.payload;
    case BACKGROUND_DIFFUSION_POPUP_CLOSED:
      return {};
    default:
      return state;
  }
};

const adPreviewOpen = (state = false, action) => {
  switch (action.type) {
    case AD_PREVIEW_POPUP_OPENED:
      return true;
    case AD_PREVIEW_POPUP_CLOSED:
      return false;
    default:
      return state;
  }
};

const adPreviewPopupData: Reducer<Partial<BuiltAdType[]>> = (state = [], action) => {
  switch (action.type) {
    case AD_PREVIEW_POPUP_OPENED:
      return action.payload;
    case AD_PREVIEW_POPUP_CLOSED:
      return [];
    default:
      return state;
  }
};

const generateCopyPopupOpen = (state = false, action) => {
  switch (action.type) {
    case GENERATE_COPY_AI_POPUP_OPENED:
      return true;
    case GENERATE_COPY_AI_POPUP_CLOSED:
      return false;
    default:
      return state;
  }
};

const generativeCopyLoadingAdInfo = (state = false, action) => {
  switch (action.type) {
    case GENERATE_COPY_LOADING_AD_INFO:
      return true;
    case GENERATE_COPY_LOADING_AD_INFO_FINISHED:
      return false;
    default:
      return state;
  }
};

const generativeCopyAdInfo = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_COPY_LOADING_AD_INFO:
      return action.payload;
    case GENERATE_COPY_LOADING_AD_INFO_FINISHED:
      return {};
    default:
      return state;
  }
};

const generativeAiLoadingAdInfo = (state = false, action) => {
  switch (action.type) {
    case GENERATIVE_LOADING_AD_INFO:
      return true;
    case GENERATIVE_LOADING_AD_INFO_FINISHED:
      return false;
    default:
      return state;
  }
};

const generativeAiAdInfo = (state = {}, action) => {
  switch (action.type) {
    case GENERATIVE_LOADING_AD_INFO:
      return action.payload;
    case GENERATIVE_LOADING_AD_INFO_FINISHED:
      return {};
    default:
      return state;
  }
};

const generativeAiLoadingProductInfo = (state = false, action) => {
  switch (action.type) {
    case GENERATIVE_LOADING_PRODUCT_INFO:
      return true;
    case GENERATIVE_LOADING_PRODUCT_INFO_FINISHED:
      return false;
    default:
      return state;
  }
};

const generativeAiProductInfo = (state = {}, action) => {
  switch (action.type) {
    case GENERATIVE_LOADING_PRODUCT_INFO:
      return action.payload;
    case GENERATIVE_LOADING_PRODUCT_INFO_FINISHED:
      return {};
    default:
      return state;
  }
};

const adAssetSource = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_AD_BUILDER_SOURCE:
      return action.payload;
    default:
      return state;
  }
};

const isBulkAdBuilder = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_BULK_ADBUILDER:
      return action.payload;
    default:
      return state;
  }
};

const contentHubSearchTerm = (state = '', action) => {
  switch (action.type) {
    case CHANGE_CONTENT_HUB_SEARCH_TERM:
      return action.payload;
    default:
      return state;
  }
};

const contentHubFolderOpen = (state = '/', action) => {
  switch (action.type) {
    case CHANGE_CONTENT_HUB_FOLDER:
      return action.payload;
    default:
      return state;
  }
};

const contentHubUploadMediaOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_UPLOAD_MEDIA:
      return true;
    case CLOSE_UPLOAD_MEDIA:
      return false;
    default:
      return state;
  }
};

const contentHubUploadMediaToFolder = (state = '/', action) => {
  switch (action.type) {
    case OPEN_UPLOAD_MEDIA:
      return action.payload;
    case CLOSE_UPLOAD_MEDIA:
      return state;
    default:
      return state;
  }
};

const triggerReloadContentHubTab = (state = false, action) => {
  switch (action.type) {
    case TRIGGER_RELOAD_CONTENT_HUB_TAB:
      return true;
    case FINISHED_RELOAD_CONTENT_HUB_TAB:
      return false;
    default:
      return state;
  }
};

const generativeAiPopupData: Reducer<
  Partial<GenerativeAiGeneratePopUpData | GenerativeAiPreGeneratePopUpData>
> = (state = {}, action) => {
  switch (action.type) {
    case GENERATIVE_AI_POPUP_OPENED:
      return action.payload;
    case GENERATIVE_AI_CHANGE_ENTITY:
      return action.payload;
    case GENERATIVE_AI_POPUP_CLOSED:
      return {};
    default:
      return state;
  }
};

const generateCopyPopupData: Reducer<Partial<GenerativeCopyPopupData>> = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_COPY_AI_POPUP_OPENED:
      return action.payload;
    case GENERATE_COPY_CHANGE_ENTITY:
      return action.payload;
    case GENERATE_COPY_AI_POPUP_CLOSED:
      return {};
    default:
      return state;
  }
};

const selectedGeneratedText: Reducer<any> = (state = {}, action) => {
  switch (action.type) {
    case SET_GENERATED_TEXT:
      return action.payload;
    case UNSET_GENERATED_TEXT:
      return {};
    default:
      return state;
  }
};

const billingIntervalsPerShop: Reducer<ShopBillingIntervalMap> = (state = {}, action: any = {}) => {
  switch (action.type) {
    case INIT_MULTIPLE_SHOPS_BILLING_INTERVAL: {
      let emptyPayload: boolean = true;

      for (const shopId in action.payload) {
        emptyPayload = false;
        state[shopId] = action.payload[shopId];
      }

      state = emptyPayload ? state : { ...state };
      return state;
    }
    case SET_SHOP_BILLING_INTERVAL: {
      const { shopUrl, billingInterval } = action.payload;
      return { ...state, [shopUrl]: billingInterval };
    }
    default: {
      return state;
    }
  }
};

const copyToClipboardActive: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case COPY_TO_CLIPBOARD_ACTIVE:
      return true;
    case COPY_TO_CLIPBOARD_INACTIVE:
      return false;
    default:
      return state;
  }
};

const hasActivityFeed: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      const { activityFeed } = action.user;
      return (
        activityFeed?.hasActivityFeed || !isProduction || window.location.search?.includes('af=1')
      );
    default:
      return state;
  }
};

const hasPixelActivityFeed: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      const { hasPixelInActivityFeed } = action.user;
      return hasPixelInActivityFeed || !isProduction || window.location.search?.includes('pf=1');
    default:
      return state;
  }
};

export default combineReducers({
  ...klaviyo,
  ...summary,
  ...auth,
  ...subscription,
  ...facebook,
  ...gorgias,
  ...enquirelabs,
  ...kno,
  ...products,
  ...paymentGateways,
  ...orderTags,
  ...shopifySegmentSources,
  ...snapchat,
  ...stats,
  ...reports,
  ...inventory,
  ...tiktok,
  ...twitter,
  ...customSections,
  ...onboarding,
  ...slack,
  ...googleSheets,
  // ...shopOrders,
  ...tiles,
  ...pixel,
  ...pinterest,
  ...pixelHealth,
  ...googleAds,
  ...newStats,
  ...confirmationModal,
  ...shopifyGeneral,
  ...amazon,
  // forecasting,
  hubspot,
  ...bing,
  ...shipping,
  isLightboxOpen,
  auditSegments,
  ltv,
  willySummaryBox,
  willyTempSummaryBox,
  willySummaryWidget,
  customMetrics,
  loadingCustomMetrics,
  tileModalSettings: tilesModalSettingsReducer,
  modalCustomSection: modalCustomSectionReducer,
  isOneDay,
  isOneYear,
  summaryLastRefresh,
  topbarUserMenuIsOpen,
  mainDatePickerSelectionRange,
  // mainDatePickerSelectionRangeDateObject,
  orders,
  loading,
  loadingOrders,
  topbarMobileNavigationIsOpen,
  importProductsTableIsOpen,
  summaryIsExpanded,
  summaryCurrentPopupChart,
  summaryChartPopupIsOpen,
  isLoggedIn,
  isEmailVerified,
  loggedInUserId,
  customSpends,
  initialCustomSpendData,
  customSpendsFlag,
  hideCustomSpendBanner,
  newCustomSpendWindowIsOpen,
  newCustomSpendStartDateMonth,
  newCustomSpendStartDateYear,
  isMspUninstalled,
  isStripeBased,
  newCustomSpendEndDateMonth,
  newCustomSpendEndDateYear,
  loadingCustomSpends,
  customSpendCategories,
  loadingRefunds,
  refunds,
  earliestDate,
  importCostsModalIsOpen,
  importCustomSpendsTableIsOpen,
  hasMobileApp,
  userEmail,
  firebaseUser,
  shopTimezone,
  useRealTimeCogsStatsCalculation,
  promptSettings,
  networkOnline,
  showOfflineBanner,
  shopCreatedAt,
  isNewShopAfterBlockNetProfitRelease,
  isShowCreativeDashboard,
  shopLoading,
  loadingShippingCosts,
  importingServices,
  importingNewInfraServices,
  anyImportingNow,
  anyImportingNowNewInfra,
  datePickerRangeYearsDifference,
  justImportedServices,
  newMetricBasedCustomSpendWindowIsOpen,
  mainDatePickerSpecialPeriod,
  datesToCompare,
  // blendedMetrics,
  shopify,
  loadingStore,
  shopInitiated,
  isBetaShop,
  rulesEngineTester,
  hasLighthouse,
  hasPps,
  shopifyMongoIsCalculated,
  pixelShowDirect,
  hasNexus,
  canUpdateAd,
  canCreateAd,
  hasContentHub,
  dbShop,
  shopServices,
  isProd,
  showGeneralErrorToast,
  generalErrorToastMsg,
  allAlerts,
  ...userProfile,
  ...workspaceStats,
  currentPod,
  signupJourney,
  adSegmentations,
  creativeCockpit,
  ...workspaceStats,
  ...sitePlan,
  ...shop,
  benchmarks,
  attribution,
  affluencerHub,
  rulesEngine,
  postPurchaseSurvey,
  floppyWhaleModalOpen,
  productAnalytics,
  customExpenseAttributionType,
  customExpenseSmartAttribution,
  shopifySalesBySegmentModal,
  generativeAiPopupOpen,
  generativeAiPopupData,
  generativeAiLoadingAdInfo,
  generativeAiAdInfo,
  externalApps,
  sensory,
  ...mediaPicker,
  triggerReloadContentHubTab,
  isNavigationOpen,
  CDPSegments,
  customers,
  lighthouse,
  shopIcon,
  durationFiltersValue,
  generateCopyPopupOpen,
  generativeCopyLoadingAdInfo,
  generateCopyPopupData,
  generativeCopyAdInfo,
  selectedGeneratedText,
  generativeAiLoadingProductInfo,
  generativeAiProductInfo,
  adAssetSource,
  backgroundDiffusionOpen,
  backgroundDiffusionPopupData,
  adPreviewPopupData,
  adPreviewOpen,
  billingIntervalsPerShop,
  welcomeWizard,
  contentHubSearchTerm,
  contentHubUploadMediaOpen,
  contentHubUploadMediaToFolder,
  Ui,
  brandColors,
  brandFonts,
  shopPrompts,
  isBulkAdBuilder,
  mobileApp,
  willy,
  copyToClipboardActive,
  contentHubFolderOpen,
  ppsStatus,
  hasContentHubWithFolders,
  customerJourneys,
  activityFeed,
  insights,
  insightsFilter,
  hasActivityFeed,
  hasPixelActivityFeed,
  ...monitoringIncidents,
  notificationSettings,
  apiKeys,
  importCustomSpendsGSModalOpen,
  customSpendsGoogleSheet,
  integrations,
});
