import { Button, confirm } from '@tw/ui-components';
import { WillySearchInput } from 'components/Willy/WillySearchInput';
import { useState } from 'react';
import _db from 'utils/DB';
import { editor } from 'monaco-editor';
import { Monaco } from '@monaco-editor/react';
import { useFilteredItems } from 'components/Willy/hooks/useFilteredItems';
import { ItemsWithActions } from './ItemsWithActions';
import {
  $allRules,
  $willyRulePopup,
  defaultWillyRulePopupState,
} from 'components/Willy/WillyRules/$stores';
import { createNewTab } from './createNewTab';
import { $tables, $tabs } from '$stores/willy/$tables';
import { SAVED_RULES_COLLECTION } from './constants';
import { EditorInstance } from 'components/Willy/types/willyTypes';
import { createNewRule } from 'components/Willy/WillyRules/utils';
import { useStoreValue, useWritableStore } from '@tw/snipestate';

type RulesProps = {
  editorRef?: editor.IStandaloneCodeEditor;
  monacoRef?: Monaco;
};

export const Rules: React.FC<RulesProps> = ({ editorRef, monacoRef }) => {
  const [rules, setRules] = useWritableStore($allRules);
  const tables = useStoreValue($tables);
  const [tabs, setTabs] = useWritableStore($tabs);

  const [freeSearch, setFreeSearch] = useState('');

  const filteredRules = useFilteredItems(rules, freeSearch, ['name']);

  return (
    <div>
      <WillySearchInput
        value={freeSearch}
        onChange={(v) => setFreeSearch(v)}
        placeholder="Search rules"
      />

      <div className="p-4">
        <Button
          variant="white"
          onClick={() => {
            $willyRulePopup.set({
              isOpen: true,
              rule: null,
              query: '',
              ruleSaved: async (rule) => {
                await createNewRule(rule);
                $willyRulePopup.set(() => defaultWillyRulePopupState);
              },
            });
          }}
        >
          Create new rule
        </Button>
      </div>

      <ItemsWithActions
        items={filteredRules}
        setItems={setRules}
        onItemClicked={(rule) => {
          if (!editorRef || !monacoRef) {
            return;
          }
          const newTab = createNewTab(
            {
              editorRef,
              monacoRef,
              index: tabs.length + 1,
              language: 'sql',
              schema: tables,
              active: true,
              initialCode: rule.query,
              name: rule.name,
              savedQueryType: 'rule',
              queryId: rule.id,
            },
            setTabs,
          );

          setTabs((old) => {
            if (old.some((x) => x.queryId === rule.id)) {
              return old.map((x) => ({
                ...x,
                active: x.queryId === rule.id,
              }));
            }

            return old
              .map<EditorInstance>((x) => ({
                ...x,
                active: false,
              }))
              .concat(newTab);
          });
        }}
        actions={[
          {
            content: 'Edit',
            helpText: 'Edit this rule',
            onItemAction: (rule) => {
              $willyRulePopup.set({
                isOpen: true,
                rule,
                query: rule.query,
                ruleSaved: async (rule) => {
                  await createNewRule(rule);
                  $willyRulePopup.set(() => defaultWillyRulePopupState);
                },
              });
            },
          },
          {
            content: 'Delete',
            // disabledItem: (rule) => !!rule.isGlobal,
            // helpTextItem: (rule) =>
            //   rule.isGlobal ? 'Global rule cannot be deleted' : '',
            onItemAction: async (rule) => {
              const confirmed = await confirm({
                message: 'Are you sure you want to delete this rule?',
              });
              if (confirmed) {
                await _db().collection(SAVED_RULES_COLLECTION).doc(rule.id).delete();
              }
            },
          },
        ]}
      />
    </div>
  );
};
