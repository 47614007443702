import React, {
  createContext,
  MouseEventHandler,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

type Position = {
  x?: number;
  y?: number;
};
type DraggableContextType = {
  position: Position;
  dragged: boolean; // was the element dragged yet
  startDrag: MouseEventHandler<any>;
};
type InitialXPosition = 'center' | 'right' | 'left';
type InitialYPosition = 'center' | 'top' | 'bottom';
export type InitialPositionOption = `${InitialXPosition | number} ${InitialYPosition | number}`;

interface IDraggableProvider {
  initialPosition?: InitialPositionOption;
  className?: string;
  style?: any;
  usingDragger?: boolean;
  children: React.ReactNode;
}

// PRIVATE GLOBALS
const DraggableContext = createContext<DraggableContextType | null>(null);
let HIGHEST_Z_INDEX: number = 600;

export const useDraggable = (): DraggableContextType => {
  const context: DraggableContextType | null = useContext(DraggableContext);
  if (!context) throw new Error('"useDraggable" must be used in a DraggableProvider');
  return context;
};

export const DraggableProvider: React.FC<IDraggableProvider> = ({
  initialPosition,
  className,
  style,
  usingDragger = true,
  children,
}) => {
  // STATES
  const [position, setPosition] = useState<Position>({});
  const [dragged, setDragged] = useState<boolean>(false);

  // REFS
  const isDragging = useRef<boolean>(false);
  const initialOffset = useRef<Position>({});
  const draggedRef = useRef<HTMLElement>();

  // HANDLERS
  const startDrag: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();

    // only run this on left click
    if (e.buttons !== 1) return;

    isDragging.current = true;
    if (!draggedRef.current) return;

    // update z index of this ref to be highest
    draggedRef.current.style.zIndex = (++HIGHEST_Z_INDEX).toString();

    // update initial offset position
    const { x, y } = draggedRef.current.getBoundingClientRect();
    initialOffset.current.x = x - e.clientX;
    initialOffset.current.y = y - e.clientY;
  };

  // EFFECTS
  // init mouse move event listener
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      // sanity checks
      if (
        !isDragging.current ||
        initialOffset.current?.x === undefined ||
        initialOffset.current?.y === undefined ||
        !draggedRef.current
      )
        return;

      // mark as having been dragged
      if (!dragged) setDragged(true);

      // boundary checks
      const { offsetWidth, offsetHeight } = draggedRef.current;
      let x: number = e.clientX + initialOffset.current.x;
      let y: number = e.clientY + initialOffset.current.y;
      x = Math.max(0, Math.min(x, window.innerWidth - offsetWidth));
      y = Math.max(0, Math.min(y, window.innerHeight - offsetHeight));

      setPosition({ x, y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.addEventListener('mousemove', handleMouseMove);
  }, [dragged]);

  // init mouse up event listener
  useEffect(() => {
    const handleMouseUp = () => (isDragging.current = false);
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.addEventListener('mouseup', handleMouseUp);
  }, []);

  // change DOM element based on position state
  useEffect(() => {
    if (!draggedRef.current) return;
    if (position.x !== undefined) draggedRef.current.style.left = `${position.x}px`;
    if (position.y !== undefined) draggedRef.current.style.top = `${position.y}px`;
  }, [position.x, position.y]);

  // initializes element to initialPosition and zIndex
  useLayoutEffect(() => {
    if (!initialPosition || !draggedRef.current) return;

    // z index
    draggedRef.current.style.zIndex = (++HIGHEST_Z_INDEX).toString();

    // position
    const [xPos, yPos]: string[] = initialPosition.split(' ');
    const isNum = (x: string): boolean => !isNaN(Number(x));

    const xMap: Record<InitialXPosition, number> = {
      center: window.innerWidth / 2 - draggedRef.current.offsetWidth / 2,
      right: window.innerWidth - draggedRef.current.offsetWidth,
      left: 0,
    };
    const yMap: Record<InitialYPosition, number> = {
      center: window.innerHeight / 2 - draggedRef.current.offsetHeight / 2,
      bottom: window.innerHeight - draggedRef.current.offsetHeight,
      top: 0,
    };

    const x = xMap[xPos] ? xMap[xPos] : isNum(xPos) ? Number(xPos) : 0;
    const y = yMap[yPos] ? yMap[yPos] : isNum(yPos) ? Number(yPos) : 0;

    setPosition({ x, y });
  }, [initialPosition]);

  // CONTEXT VALUES
  const value: DraggableContextType = {
    position,
    dragged,
    startDrag,
  };

  // OPTIONAL PROPS
  const optionalProps: any = {};
  if (!usingDragger) optionalProps.onMouseDown = startDrag;

  return (
    <DraggableContext.Provider value={value}>
      <div
        style={{ ...style, position: 'fixed' }}
        className={className}
        ref={draggedRef}
        {...optionalProps}
      >
        {children}
      </div>
    </DraggableContext.Provider>
  );
};
