import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Icon, Autocomplete, TextContainer } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { omit } from 'lodash';
import { useDebounce } from 'utils/useDebounce.v2';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';

interface FilterFieldAutocompleteProps {
  onSelectSearchResult: (selectedOption: any) => void;
  searchFunc: (searchStr: string) => Promise<{ label: any; value: any }[]>;
  placeholder?: string;
  initVal?: string;
  debounceMsTime?: number;
  onSearchValueChanges?: (searchStr: string) => void;
  initialOptions?: OptionDescriptor[];
  emptyStateMessage?: string;
  disabled?: boolean;
}

export const FilterFieldAutocomplete: React.FC<FilterFieldAutocompleteProps> = ({
  onSelectSearchResult,
  searchFunc,
  placeholder,
  initVal,
  debounceMsTime = 300,
  onSearchValueChanges = undefined,
  initialOptions = [],
  emptyStateMessage = 'Could not find any results',
  disabled = false,
}) => {
  const deselectedOptions = useMemo(() => {
    return initialOptions || [];
  }, [initialOptions]);

  const [inputValue, setInputValue] = useState(initVal || '');
  const [options, setOptions] = useState<OptionDescriptor[]>(deselectedOptions);
  const [loading, setLoading] = useState(false);
  const [isInputFromOptions, setIsInputFromOptions] = useState(false);
  const isInitialRender = useRef(true);

  useDebounce(
    () => {
      if (!isInitialRender.current && !inputValue) {
        onSelectSearchResult({ value: '', label: '' });
      }
      isInitialRender.current = false;
      if (!inputValue || isInputFromOptions) return;

      const asyncFunc = async () => {
        setLoading(true);
        const results = await searchFunc(inputValue);

        if (results) {
          setOptions(
            results
              .filter((x) => x)
              .map((option) => ({
                ...option,
                value: `${option.value}${option.label}`,
                originalValue: option.value,
              })),
          );
        }
        setLoading(false);
      };
      asyncFunc();
    },
    debounceMsTime,
    [inputValue, isInputFromOptions],
  );

  useEffect(() => {
    setOptions(deselectedOptions);
    setInputValue(initVal || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFunc]);

  const updateText = useCallback(
    (value: string) => {
      setIsInputFromOptions(false);
      setInputValue(value);
      if (onSearchValueChanges) onSearchValueChanges(value);
      if (value === '') setOptions(deselectedOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateSelection = useCallback(
    (selected) => {
      if (disabled) {
        return;
      }
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => option.value === selectedItem);
        return matchedOption;
      })[0];
      onSelectSearchResult({
        ...omit(selectedValue, 'originalValue'),
        value: selectedValue.originalValue || selectedValue.value,
      });
      setIsInputFromOptions(true);
      setInputValue(selectedValue.label);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, disabled],
  );

  const handleClearButtonClick = () => {
    setInputValue('');
  };

  const emptyState = (
    <>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>{emptyStateMessage}</TextContainer>
      </div>
    </>
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      autoComplete={disabled ? 'off' : 'on'}
      label="Tags"
      labelHidden
      value={inputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder={placeholder || 'Search'}
      clearButton
      onClearButtonClick={handleClearButtonClick}
      disabled={disabled}
    />
  );

  return (
    <Autocomplete
      options={disabled ? [] : options}
      selected={[]}
      onSelect={updateSelection}
      textField={textField}
      preferredPosition="mostSpace"
      loading={loading}
      emptyState={inputValue && emptyState}
    />
  );
};
