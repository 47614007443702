import { useMemo } from 'react';
import { services } from '@tw/types/module/services';
import { AdProps } from '@tw/types/module/services/insights';
import { Grid, Image } from '@tw/ui-components';
import { ActivityDetail } from './ActivityDetail';

export const AdInfo: React.FC<{
  properties: AdProps;
  showImage?: boolean;
}> = ({ properties, showImage = true }) => {
  const sourceText = useMemo(() => {
    if (!properties.source) return;
    return services[properties.source]?.name || properties.source;
  }, [properties.source]);

  return (
    <Grid>
      {showImage && properties.adImgUrl && (
        <Grid.Col span="content">
          <Image shadowBorderSize="sm" width={80} src={properties.adImgUrl} />
        </Grid.Col>
      )}

      <Grid.Col span="auto" style={{ overflow: 'hidden' }}>
        {sourceText && <ActivityDetail label="Source">{sourceText}</ActivityDetail>}
        {properties.campaignName && (
          <ActivityDetail label="Ad Campaign">{properties.campaignName}</ActivityDetail>
        )}
        {properties.adSetName && (
          <ActivityDetail label="Ad Set">{properties.adSetName}</ActivityDetail>
        )}
        {properties.adName && <ActivityDetail label="Ad">{properties.adName}</ActivityDetail>}{' '}
      </Grid.Col>
    </Grid>
  );
};
