import TWImage from 'components/library/TWImage/TWImage';
import _ from 'lodash';
import { FC, useState } from 'react';
import SERVICES from '../../constants/services';

interface Image {
  src: string;
  alt: string;
  id: string;
  serviceId?: string;
}
interface IPicturesGalleryProps {
  numImgsToDisplay?: number;
  w?: string | number;
  h?: string | number;
  overlap?: boolean;
  images: Image[];
}
const PicturesGallery: FC<IPicturesGalleryProps> = ({
  numImgsToDisplay = 6,
  w = '100px',
  h = '100px',
  overlap = false,
  images,
}) => {
  const [isImgLoading, setIsImgLoading] = useState<boolean>(true);
  const imgsToDisplay = _(images).uniqBy('src').slice(0, numImgsToDisplay).value() as Image[];

  if (!imgsToDisplay?.length) return null;

  const remainingImgs: number = images?.length - imgsToDisplay?.length;
  const width: string = typeof w === 'number' ? `${w}px` : w;
  const height: string = typeof h === 'number' ? `${h}px` : h;

  const displayGallery = imgsToDisplay?.map((img: Image, index: number) => {
    const marginLeft: number = index === 0 ? -3 : -5;
    const overlapStyle: {} = {
      left: `${index * 10}px`,
      zIndex: -index,
      marginLeft: `${marginLeft}px`,
      border: '0.5px solid #e1e5e9',
    };
    const overlapClass: string = `${index !== 0 ? 'ml-' + marginLeft : ''} left-${
      index * 10
    } z-${-index}`;
    const title: string = `${overlap ? images.length + ' total' : img?.alt}`;
    const isLastImg = index === imgsToDisplay.length - 1;
    return (
      <div key={index} className={`relative ${overlap ? '' : 'pr-2'}`} title={title}>
        <TWImage
          src={img?.src ?? ''}
          alt={img?.alt ?? ''}
          customImgStyle={{
            width: width,
            height: height,
            borderRadius: '0.5rem',
            ...(overlap ? overlapStyle : {}),
          }}
          wrapperClass={`w-[${width}] h-[${height}] rounded, ${overlap ? overlapClass : ''}`}
          onLoadingChange={(loading) => {
            isLastImg && setIsImgLoading(loading);
          }}
        />
        {!isImgLoading && !isLastImg && !overlap && img?.serviceId && (
          <div className="bottom-3 right-3 absolute flex items-center">
            {SERVICES[img?.serviceId]?.icon?.(true)}
          </div>
        )}
        {images?.length > 3 && remainingImgs > 0 && isLastImg && !isImgLoading && (
          <div
            className={`w-[${width}] h-[${height}] ${
              overlap ? '-ml-2 text-1xl' : 'text-3xl mb-2'
            } absolute inset-0 flex items-center justify-center 
                        text-white font-bold bg-black bg-opacity-50 rounded`}
          >
            +{remainingImgs}
          </div>
        )}
      </div>
    );
  });
  return <div className={`grid grid-cols-3 gap-4'`}>{displayGallery}</div>;
};

export default PicturesGallery;
