import { ActionIcon, Icon, Modal, Title, Text, Button, Card, Avatar } from '@tw/ui-components';
import { VideoPlayer } from 'components/GetStarted/VideoPlayer';
import review1Profile from 'components/Icons/review_1_profile.png';
import review2Profile from 'components/Icons/review_2_profile.png';
import { $store, useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  $freeTrialOrUpgradeModal,
  closeFreeTrialPopup,
  openUnlockMobyModal,
} from '$stores/willy/$upgradePopupManager';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStartFreeTrial } from './useStartFreeTrial';
import { $mobyUpgradeSplitTest, MOBY_PRODUCT_ID } from '../../../$stores/willy/$subscription';
import { useTimeout } from '../../../utils/useTimeout';
import { SELECTED_PRODUCT_PARAM } from '../../UserProfileManagment/Shop/ChangePlan/constants';
import { useNavigate } from 'react-router-dom';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from '../../../utils/dataLayer';
import { useCallback } from 'react';

const reviews: any[] = [];

// const reviews = [
//   {
//     image: review1Profile,
//     name: 'Jack Kavanagh',
//     title: 'Director of Strategy, Shopanova',
//     review:
//       "Our team didn't have time for a traditional forecasting exercise, so we turned to Moby. It took just five minutes. We just asked, 'Hey Moby, can you forecast our revenue for the next six months?' And guess what? Moby's forecast was within two percent accuracy.",
//   },
//   {
//     image: review2Profile,
//     name: 'Scott Mast',
//     title: 'Sio Beauty',
//     review:
//       'The new platform has been incredible in helping with our business, we have automated our daily report with Moby, saving us valuable time every morning. Power is always in the numbers and Triple Whale always has the numbers ready.',
//   },
// ];

export const MobyUpgradePlanModal = () => {
  const { opened, upgradeOnly, source } = useStoreValue($freeTrialOrUpgradeModal);
  const isSmall = useIsSmall();
  const { startFreeTrial, loading } = useStartFreeTrial(source);
  const navigate = useNavigate();
  const { dayTrial } = useStoreValue($mobyUpgradeSplitTest);

  const click = useCallback(() => {
    if (upgradeOnly) {
      navigate(`/store-settings/plans?${SELECTED_PRODUCT_PARAM}=${MOBY_PRODUCT_ID}`);
    } else {
      startFreeTrial();
      closeFreeTrialPopup(false);
      setTimeout(() => {
        openUnlockMobyModal();
      }, 3000);
    }
    closeFreeTrialPopup(false);
  }, [navigate, startFreeTrial, upgradeOnly]);

  return (
    <Modal.Root
      size="90vw"
      maw="1140px"
      zIndex={999}
      centered
      opened={opened}
      onClose={() => closeFreeTrialPopup()}
      p={0}
    >
      <Modal.Overlay />
      <Modal.Content mah="90vh" p={0}>
        <div className="flex justify-between p-[30px] pb-[10px] items-center">
          <Modal.Title>
            <div className="text-blue-600 uppercase font-semibold text-xl	">
              {`${upgradeOnly ? 'Upgrade to Moby' : ' Moby Free Trial'}`}
            </div>
          </Modal.Title>

          <Modal.CloseButton />
        </div>

        <Modal.Body p={0}>
          <div className={` h-full overflow-hidden flex flex-col `}>
            <div className="flex flex-col lg:flex-row gap-5 bg-white p-[30px] pt-0">
              <div className="w-full lg:w-1/2 flex flex-col gap-8">
                <Text fw={700} fz={isSmall ? 24 : 36} color="gray.8">
                  {'Meet Moby, your AI-powered data analyst'}
                </Text>
                <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                  Moby understands your data, analyzes real-time metrics, and delivers instant
                  insights. Whether it's industry trends or specific company questions, Moby
                  accelerates your growth.
                </Text>
                {!upgradeOnly && (
                  <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                    {`For ${dayTrial} days, enjoy exclusive features only available with Moby:`}
                  </Text>
                )}
                <div className="flex flex-col gap-5">
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      AI-powered visualizations, dashboards, and insights
                    </Text>
                  </div>
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      A prompt library with powerful use cases
                    </Text>
                  </div>
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      Fast, accurate forecasts in seconds
                    </Text>
                  </div>
                  <div className="flex gap-5 items-center">
                    <Icon name="circle-check" color="named.6" size={17} />
                    <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                      Answers to key product questions from Triple Whale’s knowledge base
                    </Text>
                  </div>
                </div>
                <div className="w-[400px]">
                  <Button loading={loading} variant="primary" fullWidth onClick={click}>
                    {upgradeOnly ? 'Upgrade' : `Start Free Trial`}
                  </Button>
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <VideoPlayer rounded source="https://youtu.be/hChMtiv4AlA" />
              </div>
            </div>
            <div className="bg-light-gray flex gap-8 p-[30px]">
              {reviews.map((review) => {
                return (
                  <div className="w-1/2 mih-[216px] overflow-scroll no-scrollbar" key={review.name}>
                    <Card shadow="md" w="100%" h="100%">
                      <div className="flex gap-5 h-full">
                        <Avatar>
                          <img src={review.image} alt="profile" width="36" />
                        </Avatar>
                        <div className="flex flex-col justify-between h-full">
                          <Text fw={500} color="black" fz={16}>
                            {'"'}
                            {review.review}
                            {'"'}
                          </Text>
                          <div className="flex flex-col">
                            <Text fw={500} color="black" fz={14}>
                              {review.name}
                            </Text>
                            <Text fw={500} color="gray.5" fz={14}>
                              {review.title}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
