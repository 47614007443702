import axiosInstance from 'utils/axiosInstance';

export const uploadMedia = async (
  file: File,
  shopId: string,
  serviceId: string,
  source: string,
  progressCB: (progress: number, fileName: string) => void,
  user?: string,
  customId?: string,
  tags?: string,
  fs_path?: string,
  version_base?: string | null,
  from_ad_image?: any,
) => {
  const reqData = new FormData();
  reqData.append('file', file, file.name);
  reqData.append('shop', shopId);
  reqData.append('source', source);
  reqData.append('serviceId', serviceId);
  if (fs_path) {
    reqData.append('fs_path', fs_path);
  }
  if (customId) {
    reqData.append('customId', customId);
  }
  if (tags?.length) {
    reqData.append('tags', tags);
  }
  if (version_base) {
    reqData.append('version_base', version_base);
  }
  if (from_ad_image) {
    reqData.append('from_ad_image', from_ad_image);
  }
  if (user) {
    reqData.append('user', user);
  }

  return axiosInstance.post('/v2/media/upload', reqData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      let progress = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));
      progressCB(progress, file.name);
    },
    timeout: 300000,
  });
};

export const assetMediaPrefix = `media-assets/`;
