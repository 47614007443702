import React from 'react';

import { Frame, ContextualSaveBar } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

const ActionBanner = () => {
  const { actionBannerConfig } = useSelector((state: RootState) => state.Ui);
  return !actionBannerConfig ? null : (
    <div className="h-0">
      <Frame>
        <ContextualSaveBar
          fullWidth
          message={actionBannerConfig.title}
          saveAction={{
            content: 'Apply',
            onAction: actionBannerConfig.onApplay,
          }}
        />
      </Frame>
    </div>
  );
};

export default ActionBanner;
