import TWImage from 'components/library/TWImage/TWImage';
import TWTable from 'components/library/TWTable/TWTable';
import { BaseColumn } from 'components/library/TWTable/types';

type mediaColumnKeys = 'url' | 'name' | 'created_at' | 'filesize' | 'usedIn';

type mediaRow = {
  url: string;
  media_type: string;
  id: string;
  name: string;
  serviceId: string;
  created_at: string;
  useIn: string;
};

type ProductMediaTableProps = {
  data: any[];
  setOpenProduct: (prod) => void;
};

const ProductMediaTable: React.FC<ProductMediaTableProps> = ({ data, setOpenProduct }) => {
  const columns: BaseColumn<mediaRow, mediaColumnKeys>[] = [
    {
      key: 'url',
      name: 'Url',
      dataType: 'text',
      sortable: false,
      Heading: () => '',
      Value: (med) => {
        return (
          <TWImage
            src={med.url}
            className="w-full h-full object-cover rounded-md"
            wrapperClass="w-20 h-20 cursor-pointer"
          />
        );
      },
    },
    {
      key: 'name',
      name: 'Name',
      dataType: 'text',
      sortable: false,
      Heading: () => <div style={{ width: '100%' }}>Name</div>,
      Value: (med) => {
        return med.name;
      },
    },
    {
      key: 'created_at',
      name: 'Created at',
      dataType: 'text',
      sortable: false,
      Heading: () => <div style={{ width: '100%' }}>Date Added</div>,
      Value: (med) => {
        return med.created_at;
      },
    },
    {
      key: 'filesize',
      name: 'File size',
      dataType: 'text',
      sortable: false,
      Heading: () => <div style={{ width: '100%' }}>File Size</div>,
      Value: (med) => {
        return med.created_at;
      },
    },
    {
      key: 'usedIn',
      name: 'Used In',
      dataType: 'text',
      sortable: false,
      Heading: () => <div style={{ width: '100%' }}>Currently in use</div>,
      Value: (med) => {
        return med.created_at;
      },
    },
  ];
  return <TWTable columns={columns} data={data} padding="0" stickyHeader />;
};

export default ProductMediaTable;
