import { Text, Flex, Icon, IconName } from '@tw/ui-components';
import { forwardRef } from 'react';

export type PopoverLinkProps = {
  rawIconName?: IconName;
  label: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const PopoverLink = forwardRef<HTMLDivElement, PopoverLinkProps>(
  ({ rawIconName, label, onClick }, ref) => {
    return (
      <Flex ref={ref} gap="sm" align="center" onClick={onClick}>
        {!!rawIconName && <Icon name={rawIconName} />}
        <Text size="sm">{label}</Text>
      </Flex>
    );
  },
);
