import { combineReducers } from 'redux';
import ConfirmationModal from '../routes/allShopsPinnedSections/ConfirmationModal';

const noop = () => {};

export interface ConfirmationModalData {
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  text: string;
  confirmButtonText?: string;
  title?: string;
}

export interface ConfirmationModalInternalData extends ConfirmationModalData {
  isOpen: boolean;
}

const initState = { isOpen: false, text: '', title: '', onConfirm: noop, onCancel: noop };
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';

export const confirmationModal = (data: ConfirmationModalData) => (dispatch) => {
  dispatch({
    type: OPEN_CONFIRMATION_MODAL,
    data: { ...data, isOpen: true },
  });
};

export const confirmationModalClose = () => (dispatch) => {
  dispatch({
    type: CLOSE_CONFIRMATION_MODAL,
  });
};

const confirmationModalData = (state: ConfirmationModalInternalData = initState, action) => {
  switch (action.type) {
    case OPEN_CONFIRMATION_MODAL:
      return action.data;
    case CLOSE_CONFIRMATION_MODAL:
      return initState;
    default:
      return state;
  }
};

export const reducers = { confirmationModalData };
