import { useAppSelector } from 'reducers/RootType';
import { LighthouseTypesDictionary } from 'utils/lighthouse';
import { LighthouseClientNotification } from './types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { useAppDispatch } from 'index';
import { lighthousePopupOpen } from 'ducks/lighthouse';

type LighthousePopupProps = {};

const LighthousePopup: React.FC<LighthousePopupProps> = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const isInitRef = useRef(true);

  const { popupItem, isPopupOpen } = useAppSelector((state) => state.lighthouse);
  const currentShopId = useAppSelector((state) => state.currentShopId);

  const docId = useMemo(() => {
    return popupItem?.docId;
  }, [popupItem]);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(search);
      const notificationId = params.get('notification-id');
      if (notificationId && notificationId !== 'null' && notificationId !== 'undefined') {
        const { data } = await axiosInstance.post('/v2/lighthouse/get-notification-by-doc-id', {
          docId: notificationId,
          shopId: currentShopId,
        });

        const notification = LighthouseTypesDictionary[data.type].transformer(data);
        dispatch(lighthousePopupOpen(notification));
        isInitRef.current = false;
      }
    })();
  }, [search, dispatch, currentShopId]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (!isPopupOpen && params.get('notification-id') && !isInitRef.current) {
      params.delete('notification-id');

      navigate(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        { replace: true },
      );
    } else if (isPopupOpen && !params.get('notification-id')) {
      params.set('notification-id', docId);
      navigate(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        { replace: true },
      );
    }
  }, [isPopupOpen, navigate, search, docId]);

  return isPopupOpen ? (
    <>
      {LighthouseTypesDictionary[(popupItem as LighthouseClientNotification).type].Details(
        popupItem,
      )}
    </>
  ) : null;
};

export default LighthousePopup;
