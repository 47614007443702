import { Button, Menu } from '@tw/ui-components';
import { FORECAST_MODELS, FORECAST_MODELS_INFO, ForecastModel } from './constants';
import { analyticsEvents, genericEventLogger, chatActions } from 'utils/dataLayer';

type WillyMainChatForecastModelSelectorProps = {
  activeForecastModel: ForecastModel;
  setActiveForecastModel: (model: ForecastModel) => void;
};

export const WillyMainChatForecastModelSelector: React.FC<
  WillyMainChatForecastModelSelectorProps
> = ({ activeForecastModel, setActiveForecastModel }) => {
  const forecastModelsByKey = FORECAST_MODELS_INFO.reduce((acc, model) => {
    acc[model.value] = model;
    return acc;
  }, {});

  return (
    <Menu withArrow shadow="md" closeOnItemClick={true}>
      <Menu.Target>
        <Button variant="white" size="xs" tt="capitalize">
          Forecast: {forecastModelsByKey[activeForecastModel]?.label ?? activeForecastModel}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {FORECAST_MODELS.map((model) => (
          <Menu.Item
            key={model}
            onClick={() => {
              setActiveForecastModel(model);
              genericEventLogger(analyticsEvents.CHAT, {
                action: chatActions.TOGGLE_CHAT_FORECAST_MODEL,
                model,
              });
            }}
            bg={activeForecastModel === model ? 'named2.4' : undefined}
          >
            <div className="flex items-center gap-2">
              <div className="flex-auto">{model}</div>
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
