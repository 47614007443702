import { useMemo } from 'react';

import { useState } from 'react';

import { RawNlqData, WillyMetric } from './types/willyTypes';
import {
  convertDataToJson,
  keyIsDimension,
  keyIsImage,
  keyIsPixel,
  keyIsService,
  keyIsVideo,
} from './utils/willyUtils';
import {
  Card,
  Image,
  Skeleton,
  Group,
  Text,
  Tooltip,
  ActionIcon,
  Pagination,
} from '@tw/ui-components';
import allServices from 'constants/services';
import { formatNumber } from 'utils/formatNumber';
import ImageVideoLightbox from 'components/Lightbox';
import { MAX_ITEMS_PER_PAGE } from './constants';

type WillyCardsProps = {
  loading?: boolean;
  loadingPreviousPeriod?: boolean;
  rawData: RawNlqData;
  previousPeriodRawData?: RawNlqData;
  metrics: WillyMetric[];
  currency: string;
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
  isDnd: boolean;
};

type CardData = {
  data: Record<string, string | number | null>;
  previousData: Record<string, string | number | null> | undefined;
};

export const WillyCards: React.FC<WillyCardsProps> = (props) => {
  const {
    loading,
    loadingPreviousPeriod,
    rawData,
    previousPeriodRawData,
    metrics,
    currency,
    totalCount,
    page,
    setPage,
    isDnd,
  } = props;

  const [imageInModal, setImageInModal] = useState<string | undefined>(undefined);
  const [videoInModal, setVideoInModal] = useState<string | undefined>(undefined);
  const data: CardData[] = useMemo(() => {
    const res = convertDataToJson(rawData);
    const previousData = previousPeriodRawData
      ? convertDataToJson(previousPeriodRawData)
      : undefined;
    return res.map((d, i) => ({ data: d, previousData: previousData?.[i] }));
  }, [rawData, previousPeriodRawData]);

  return (
    <div className={`@container ${isDnd ? 'max-h-[calc(100vh-14rem)] overflow-y-auto' : ''}`}>
      <div className="grid grid-cols-1 @sm:grid-cols-2 @3xl:grid-cols-3 @5xl:grid-cols-4 gap-4 @sm:gap-8 p-4 @sm:p-8 w-full">
        {data.map((d, i) => {
          return (
            <WillyCard
              key={d.data.name + '_' + i}
              metrics={metrics}
              currency={currency}
              data={d}
              setImageInModal={setImageInModal}
              setVideoInModal={setVideoInModal}
              loading={loading}
              loadingPreviousPeriod={loadingPreviousPeriod}
            />
          );
        })}

        {(!!imageInModal || !!videoInModal) && (
          <ImageVideoLightbox
            data={
              imageInModal
                ? [{ url: imageInModal, type: 'photo', altTag: imageInModal }]
                : videoInModal
                  ? [{ url: videoInModal, type: 'video', altTag: videoInModal }]
                  : []
            }
            startIndex={0}
            showResourceCount={false}
            onCloseCallback={() => {
              setImageInModal(undefined);
              setVideoInModal(undefined);
            }}
            onNavigationCallback={() => {}}
          />
        )}
      </div>
      <div className="sticky bottom-0 left-0 right-0 bg-white z-10">
        <div className="flex gap-4 w-full justify-end px-4 h-16 items-center border-t border-b-0 border-l-0 border-r-0 border-solid border-[var(--border-color,#e0e0e0)]">
          <Text size="sm">Total Rows: {totalCount}</Text>
          <Pagination
            total={Math.ceil((totalCount || 0) / MAX_ITEMS_PER_PAGE)}
            value={page}
            onChange={setPage}
            size="xs"
            disabled={!!totalCount && totalCount <= MAX_ITEMS_PER_PAGE}
          />
        </div>
      </div>
    </div>
  );
};

type WillyCardProps = {
  metrics?: WillyMetric[];
  data: CardData;
  currency: string;
  setImageInModal: React.Dispatch<React.SetStateAction<string | undefined>>;
  setVideoInModal: React.Dispatch<React.SetStateAction<string | undefined>>;
  loading?: boolean;
  loadingPreviousPeriod?: boolean;
};

const WillyCard: React.FC<WillyCardProps> = ({
  metrics,
  data,
  currency,
  setImageInModal,
  setVideoInModal,
  loading,
  loadingPreviousPeriod,
}) => {
  const imageField = Object.entries(data.data).find(
    ([key, value]) => keyIsImage(key) && !!value,
  )?.[0];
  const videoField = Object.entries(data.data).find(
    ([key, value]) => keyIsVideo(key) && !!value,
  )?.[0];
  const dimensionData = useMemo(() => {
    if (loading) {
      return Object.fromEntries(Array.from({ length: 3 }, (_, i) => [`metric_${i}`, '']));
    }
    return Object.fromEntries(
      Object.entries(data.data).filter(([key]) => keyIsDimension(key, metrics)),
    );
  }, [data, metrics, loading]);

  const metricData = useMemo(() => {
    if (loading) {
      return Object.fromEntries(Array.from({ length: 5 }, (_, i) => [`metric_${i}`, '']));
    }
    return Object.fromEntries(
      Object.entries(data.data).filter(([key]) => !keyIsDimension(key, metrics)),
    );
  }, [data, metrics, loading]);

  const previousMetricData = useMemo(() => {
    if (loadingPreviousPeriod || !data.previousData) {
      return undefined;
    }
    return Object.fromEntries(
      Object.entries(data.previousData).filter(([key]) => !keyIsDimension(key, metrics)),
    );
  }, [data, metrics, loadingPreviousPeriod]);

  return (
    <Card shadow="sm" padding="lg" radius="md" key={data.data.name} overflow="hidden">
      {!!imageField && (
        <Card.Section
          onClick={() => setImageInModal(data.data[imageField]?.toString())}
          className="cursor-pointer mb-4 relative"
        >
          {loading && <Skeleton width="100%" height="300px" />}
          {!loading && (
            <Image
              src={data.data[imageField]?.toString()}
              mah={300}
              mih={300}
              alt={Object.values(data.data)[0]?.toString()}
            />
          )}
          {!!videoField && (
            <div className="absolute inset-0 flex items-center justify-center">
              <ActionIcon
                icon="play-circle"
                size="xl"
                iconSize={36}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setVideoInModal(data.data[videoField]?.toString());
                }}
              />
            </div>
          )}
        </Card.Section>
      )}

      <Card.Section
        p="md"
        className="border-solid border-b border-t-0 border-r-0 border-l-0 border-gray-200"
      >
        <WillyCardSection
          currency={currency}
          metrics={metrics}
          data={dimensionData}
          imageField={imageField}
          loading={loading}
          loadingPreviousPeriod={loadingPreviousPeriod}
        />
      </Card.Section>

      <Card.Section p="md">
        <WillyCardSection
          currency={currency}
          metrics={metrics}
          data={metricData}
          previousData={previousMetricData}
          imageField={imageField}
          loading={loading}
          loadingPreviousPeriod={loadingPreviousPeriod}
        />
      </Card.Section>
    </Card>
  );
};

type WillyCardSectionProps = {
  currency: string;
  metrics?: WillyMetric[];
  data: CardData['data'];
  previousData?: CardData['previousData'];
  imageField: string | undefined;
  loading?: boolean;
  loadingPreviousPeriod?: boolean;
};

export const WillyCardSection: React.FC<WillyCardSectionProps> = ({
  currency,
  metrics,
  data,
  previousData,
  imageField,
  loading,
  loadingPreviousPeriod,
}) => {
  return (
    <>
      {Object.entries(data)
        .filter(([key]) => key !== imageField)
        .map(([key, value]) => {
          const metric = metrics?.find((m) => m.key === key);

          if (!metric?.active) {
            return null;
          }

          const isService = keyIsService(key);
          const service = allServices[value || ''];
          const isPixel = keyIsPixel(key);

          let valueStr = value?.toString();
          if (!metric?.isDimension && value !== null) {
            valueStr = formatNumber(+value, {
              maximumFractionDigits: metric?.toFixed ?? 2,
              minimumFractionDigits: metric?.minimumFractionDigits ?? 2,
              currency,
              style: metric?.format ?? 'decimal',
            });
          }

          const previousValueStr = previousData?.[key]
            ? formatNumber(+previousData[key], {
                maximumFractionDigits: metric?.toFixed ?? 2,
                minimumFractionDigits: metric?.minimumFractionDigits ?? 2,
                currency,
                style: metric?.format ?? 'decimal',
              })
            : undefined;

          const isDimension = metric?.isDimension;

          const valueIsNegative = metric?.valueIsNegative;

          let diffIsNegative: boolean | null = null;
          if (valueIsNegative && !!previousData?.[key] && !isDimension) {
            diffIsNegative = +(previousData?.[key] || 0) < +(value || 0);
          } else if (!valueIsNegative && !!previousData?.[key] && !isDimension) {
            diffIsNegative = +(value || 0) < +(previousData?.[key] || 0);
          }

          return (
            <Group justify="space-between" mb="xs" gap="xs" key={key} wrap="nowrap" align="start">
              <div className="flex items-center flex-auto gap-2 overflow-hidden text-ellipsis">
                {isPixel && (
                  <div className="not-prose flex">{allServices.pixel?.icon?.({ small: true })}</div>
                )}
                {loading && <Skeleton width="100px" height="10px" />}
                {!loading && (
                  <Text size="xs" fw={800}>
                    <Tooltip label={metric?.name || key}>
                      <div className="line-clamp-1">{metric?.name || key}</div>
                    </Tooltip>
                  </Text>
                )}
              </div>
              {isService && !!service && !!service.icon && (
                <div className="flex items-center gap-2">
                  <span className="not-prose flex">{service.icon({ small: false })}</span>
                  <Text size="xs" fw={800}>
                    <Tooltip label={service.name}>
                      <div className="line-clamp-1">{service.name}</div>
                    </Tooltip>
                  </Text>
                </div>
              )}

              {loading && <Skeleton width="100px" height="10px" />}

              {(!isService || !service) && !loading && (
                <div
                  className={`flex gap-2 flex-auto text-ellipsis justify-end ${!isDimension ? 'whitespace-nowrap' : 'max-w-[60%] overflow-hidden'}`}
                >
                  <Text
                    size="xs"
                    color={
                      isDimension
                        ? undefined
                        : diffIsNegative === null
                          ? undefined
                          : diffIsNegative
                            ? 'red.6'
                            : 'green.6'
                    }
                  >
                    <Tooltip label={valueStr}>
                      <div
                        className="text-right line-clamp-3"
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        {valueStr}
                      </div>
                    </Tooltip>
                  </Text>
                  {!!loadingPreviousPeriod && <Skeleton width="100px" height="10px" />}
                  {!!previousValueStr && !loadingPreviousPeriod && (
                    <Text size="xs" color="gray.4">
                      ({previousValueStr})
                    </Text>
                  )}
                </div>
              )}
            </Group>
          );
        })}
    </>
  );
};
