import animationData from '../lotties/alan-loader.json';
import Lottie, { LottieProps } from 'react-lottie-player';
import { ReactComponent as AlanIcon } from 'components/Icons/alan-icon.svg';
import { ReactComponent as AlanIconInherit } from 'components/Icons/alan-icon-inherit.svg';

export const AlanLoaderGray: React.FC<any> = (props: any) => {
  return (
    <AlanIconInherit
      className={`w-[150px] max-w-[100%] h-auto animate-pulse text-[transparent] fill-gray-300 dark:fill-gray-600 ${props?.className}`}
    />
  );
};

export const AlanLoaderLite: React.FC<any> = (props: any) => {
  return (
    <AlanIcon
      className={`w-[150px] max-w-[100%] h-auto animate-pulse text-[transparent] ${props?.className}`}
    />
  );
};

export const AlanLoader: React.FC<LottieProps & { lite?: boolean }> = (props) => {
  const { style = { width: 150, height: 150 }, className, lite = true } = props;
  return lite ? (
    <AlanLoaderLite />
  ) : (
    <Lottie
      loop
      play
      animationData={animationData}
      style={style}
      className={className}
      rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
    />
  );
};

export default AlanLoader;
