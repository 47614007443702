import { zipWith } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Line, LineChart, Tooltip, TooltipProps } from 'recharts';
import { type RootState } from 'reducers/RootType';
import { formatNumber } from 'utils/formatNumber';

import { BaseSummaryMetric } from '@tw/types/module/SummaryMetrics';
import { AbstractChart } from '@tw/types';

import BaseChart from './library/BaseChart/BaseChart';
import { RoundingOptionsMapper } from 'ducks/summary';
//TODO: remove this after fix custom spend bug
// NOTE: If this ever needs to be uncommented, better to move it into a different file
// There's a circular dep between MetricPopup and this file because of it.
// import { metricsRelatedToCustomSpend } from './MetricPopup';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../$stores/$shop';

type ChartComparisonProps = {
  data: AbstractChart[];
  previousPeriodData: AbstractChart[];
  metric?: BaseSummaryMetric<any>;
};

const ChartComparison: React.FC<ChartComparisonProps> = ({ data, previousPeriodData, metric }) => {
  const isOneDay = useSelector((state: RootState) => state.isOneDay);
  const isOneYear = useSelector((state: RootState) => state.isOneYear);
  const currency = useStoreValue($currency);
  const isSmall = useIsSmall();
  const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const previousPeriodDates = useSelector((state: RootState) => state.previousPeriodDates);
  const {
    cumulative: isMetricChartCumulative = false,
    yAxisStartAuto: isMetricChartYAxisStartAuto = false,
  } = useSelector((state: RootState) => state.summaryMetricsChartConfig[metric?.id]) ?? {};

  const { start, end } = mainDatePickerSelectionRange || { start: null, end: null };
  const { start: prevPeriodStart, end: prevPeriodEnd } = previousPeriodDates;

  const [currentChartXAxis, setCurrentChartXAxis] = useState<number>();
  const [prevChartXAxis, setPrevChartXAxis] = useState<number>();
  const [currentPeriodDate, setCurrentPeriodDate] = useState<string>();
  const [previousPeriodDate, setPreviousPeriodDate] = useState<string>();

  let chartData: AbstractChart[] = zipWith(data, previousPeriodData, (current, previous) => {
    return {
      x: current?.x,
      xPrev: previous?.x,
      y: current?.y || 0,
      z: previous?.y,
    };
  });

  useEffect(() => {
    if (!start || !end || !prevPeriodStart || !prevPeriodEnd) {
      return;
    }
    const isCurrentAndPreviousTheSameYear = isOneYear && prevPeriodEnd?.isSame?.(start, 'year');
    let prevPeriodDate = '';
    let currPeriodDate = '';
    const year = start?.year() ?? moment().year();
    const prevYear = moment(prevPeriodStart)?.year() ?? moment().year();
    if (isOneDay) {
      currPeriodDate =
        typeof currentChartXAxis !== 'undefined'
          ? moment(start).hour(currentChartXAxis).format('MMM DD, LT')
          : moment(start).format('MMM DD');
      prevPeriodDate =
        typeof currentChartXAxis !== 'undefined'
          ? moment(prevPeriodStart).hour(currentChartXAxis).format('MMM DD, LT')
          : moment(prevPeriodStart).format('MMM DD');
    } else {
      if (currentChartXAxis) {
        currPeriodDate = `${moment(start)
          .year(year)
          .dayOfYear(currentChartXAxis)
          .format(isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY')}`;
        prevPeriodDate = `${moment(prevPeriodStart)
          .year(prevYear)
          .dayOfYear(typeof prevChartXAxis !== 'undefined' ? prevChartXAxis : currentChartXAxis)
          .format(isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY')}`;
      } else {
        currPeriodDate = `${moment(start).format('MMM DD')} - ${moment(end).format(
          isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
        )}`;
        prevPeriodDate = `${moment(prevPeriodStart).format('MMM DD')} - ${moment(
          prevPeriodEnd,
        ).format(isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY')}`;
      }
    }

    setCurrentPeriodDate(currPeriodDate);
    setPreviousPeriodDate(prevPeriodDate);
  }, [
    currentChartXAxis,
    end,
    isOneDay,
    isOneYear,
    prevChartXAxis,
    prevPeriodEnd,
    prevPeriodStart,
    start,
  ]);

  const SummaryChartComparisonTooltip: React.FC<
    TooltipProps<any, any> & { metric?: BaseSummaryMetric<any> }
  > = (props) => {
    const { payload, active, metric } = props;
    const { valueToFixed, type } = metric || {};
    const { x, xPrev, y, z } = payload?.[0]?.payload || {};
    const currency = useStoreValue($currency);
    const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);

    const currencyRounding = useCallback(
      (metric) => {
        if (metric?.type === 'currency') {
          return RoundingOptionsMapper[defaultRoundingOption];
        }
        return null;
      },
      [defaultRoundingOption],
    );

    useEffect(() => {
      if (active && typeof x !== 'undefined' && typeof y !== 'undefined') {
        setCurrentChartXAxis(x);
        setPrevChartXAxis(xPrev);
      } else {
        setCurrentChartXAxis(undefined);
        setPrevChartXAxis(undefined);
      }
    }, [active, x, xPrev, y, z]);

    if (!active) return null;

    return (
      <div className="bg-white p-4 flex flex-col gap-4 rounded">
        {payload?.map((item, i) => (
          <div className="flex items-center gap-4" key={`${item.dataKey}-${i}`}>
            <span
              className="grid grid-cols-2 grid-rows-2 border border-solid rounded-md overflow-hidden"
              style={{ borderColor: '#1877F2' }}
            >
              <span className="w-2 h-2" style={{ backgroundColor: '#1877F2' }}></span>
              <span
                className="w-2 h-2"
                style={{ backgroundColor: item.dataKey === 'z' ? '#10F1A7' : '#1877F2' }}
              ></span>
              <span
                className="w-2 h-2"
                style={{ backgroundColor: item.dataKey === 'z' ? '#10F1A7' : '#1877F2' }}
              ></span>
              <span className="w-2 h-2" style={{ backgroundColor: '#1877F2' }}></span>
            </span>
            <span className="font-bold">
              {item.dataKey === 'z' ? previousPeriodDate : currentPeriodDate}
            </span>
            <span>
              {formatNumber(
                (metric?.type === 'percent' ? item.value / 100 : item.value) *
                  currencyConversionRate,
                {
                  style: type || 'decimal',
                  currency,
                  minimumFractionDigits: currencyRounding(metric) ?? valueToFixed,
                  maximumFractionDigits: currencyRounding(metric) ?? valueToFixed,
                },
              )}
            </span>
            {/*{*/}
            {/*  //TODO: remove this condition after fix custom spend bug*/}
            {/*  (!metricsRelatedToCustomSpend.includes(metric?.metricId) || isOneDay) && (*/}
            {/*    <span>*/}
            {/*      {formatNumber(*/}
            {/*        (metric?.type === 'percent' ? item.value / 100 : item.value) **/}
            {/*          currencyConversionRate,*/}
            {/*        {*/}
            {/*          style: type,*/}
            {/*          currency,*/}
            {/*          minimumFractionDigits: currencyRounding(metric) ?? valueToFixed,*/}
            {/*          maximumFractionDigits: currencyRounding(metric) ?? valueToFixed,*/}
            {/*        }*/}
            {/*      )}*/}
            {/*    </span>*/}
            {/*  )*/}
            {/*}*/}
          </div>
        ))}
      </div>
    );
  };

  return (
    <BaseChart
      ChartType={LineChart}
      data={chartData}
      height={200}
      wrapperStyle={{ padding: '0 2rem 0 0', borderRadius: '0 0 8px 8px' }}
      margin={{ top: 30, left: 0, bottom: 30, right: 10 }}
      xAxis={[
        {
          tickFormatter: (value: string) => {
            if (isOneDay) {
              return moment().hour(+value).minutes(0).format('HH:mm');
            }
            return moment().dayOfYear(+value).format('MMM D');
          },
          dy: 20,
          dataKey: 'x',
        },
      ]}
      yAxis={[
        {
          tickFormatter: (value, index) => {
            if (index % 2 !== 0) {
              return '';
            }
            return +value < 1000 ? value : +value / 1000 + 'K';
          },
          dx: 0,
          domain: [isMetricChartYAxisStartAuto || metric?.isCumulativeMetric ? 'auto' : 0, 'auto'],
        },
      ]}
    >
      <defs>
        <linearGradient id={`line-summary-popup-gradient`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0.2" stopColor={'#10F1A7'} stopOpacity={1} />
          <stop offset="1" stopColor={'#1877F2'} stopOpacity={1} />
        </linearGradient>
      </defs>
      <Line
        stroke={`url(#line-summary-popup-gradient)`}
        dataKey="y"
        strokeWidth={isSmall ? 1 : 2}
        activeDot={false}
        dot={false}
      />
      <Line
        stroke={`url(#line-summary-popup-gradient)`}
        dataKey="z"
        strokeWidth={isSmall ? 1 : 2}
        strokeDasharray="3,3"
        activeDot={false}
        dot={false}
      />
      <Tooltip content={<SummaryChartComparisonTooltip metric={metric} />} />
    </BaseChart>
  );
};

export default ChartComparison;
