import { CreativeTypes } from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';
import { CustomMetricsType } from 'ducks/customMetrics';
import { useMemo } from 'react';
import { filterColumns } from './filterColumns';
import { selectCustomMetricsColumns } from 'utils/customMetrics';
import { useSelector } from 'react-redux';

export const useColumnsWithCustomMetrics = (
  creativeType: CreativeTypes,
  serviceId: ServicesIds,
  customMetricsType: CustomMetricsType,
) => {
  const customMetricsColumns = useSelector(selectCustomMetricsColumns(customMetricsType, false));

  const columns = useMemo(() => {
    return filterColumns(creativeType, serviceId).concat(
      customMetricsColumns.map((column: any) => ({ ...column, showInTypes: [creativeType] })),
    );
  }, [creativeType, customMetricsColumns, serviceId]);

  return columns;
};
