export const KB_BASE_URL = 'https://kb.triplewhale.com/en/';
export const PIXEL_MANUAL_INSTALL =
  'https://kb.triplewhale.com/en/articles/6192354-triple-pixel-manual-implementation';
export const PIXEL_3RD_PARTY_INSTALL =
  'https://kb.triplewhale.com/en/articles/6266255-adding-the-triple-pixel-to-3rd-party-landing-pages-or-a-headless-shopify-store';
export const PIXEL_RECOMMENDED_UTMS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel';
export const PIXEL_RECOMMENDED_UTMS_UPDATE_WARN =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#:~:text=*Warning%3A%20Updating%20the%20URL%20Parameters%20on%20your%20ads%20will%20send%20the%20ad%20into%20a%20%22processing%22%20phase.%20The%20processing%20phase%20can%20take%20up%20to%201%20hour%20and%20will%20pause%20spend%20on%20your%20ads%20while%20they%27re%20being%20processed%20by%20Facebook';
export const PIXEL_RECOMMENDED_UTMS_FACEBOOK_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_2489efa553';
export const PIXEL_RECOMMENDED_UTMS_TWITTER_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_d19b81a6cd';
export const PIXEL_RECOMMENDED_UTMS_TIKTOK_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_fe23deef3e';
export const PIXEL_RECOMMENDED_UTMS_GOOGLE_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_a3f86cd3a6';
export const PIXEL_RECOMMENDED_UTMS_SNAPCHAT_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_ebae32d46c';
export const PIXEL_RECOMMENDED_UTMS_PINTEREST_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_51698ab6c3';
export const PIXEL_RECOMMENDED_UTMS_KLAVIYO_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_5ba674f8d1';
export const PIXEL_RECOMMENDED_UTMS_BING_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_648500503e';
export const PIXEL_RECOMMENDED_UTMS_CRITEO_ADS =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel';
export const PIXEL_RECOMMENDED_UTMS_SMSBUMP =
  'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel';
export const ADS_AUTOMATIC_TRACKING_PARAMETERS =
  'https://kb.triplewhale.com/en/articles/6589378-automatically-add-triple-whale-tracking-parameters-to-your-ads';
export const ADS_AUTOMATIC_TRACKING_PARAMETERS_GOOGLE_ADS =
  'https://kb.triplewhale.com/en/articles/6589378-automatically-add-triple-whale-tracking-parameters-to-your-ads#h_b1edb4aba6';
export const CONNECTING_TO_FAIRING =
  'https://kb.triplewhale.com/en/articles/6377642-connecting-to-fairing-formerly-enquirelabs';
export const KNO_INTEGRATION =
  'https://kb.triplewhale.com/en/articles/6580807-integrating-kno-with-triple-whale';
export const RULES_ENGINE_STRATEGY =
  'https://kb.triplewhale.com/en/articles/6723886-triple-whale-rules-engine#h_86587e1e61';
export const POST_PURCHASE_SURVEY =
  'https://kb.triplewhale.com/en/articles/6486354-triple-whale-post-purchase-survey';
export const PIXEL_ON_POST_PURCHASE_UPSELL =
  'https://kb.triplewhale.com/en/articles/6192354-triple-pixel-manual-implementation#h_3e5ce52ad8';
