import { Icon, IconName } from '@tw/ui-components';
import { switchStore } from 'ducks/auth';
import { $currentShopId } from '$stores/$shop';
import { resetStats } from 'ducks/newStats';
import { AppDispatch } from 'index';
import { capitalize, truncate } from 'lodash';
import { toast } from 'react-toastify';

export function isPodsView(pathname: string = window.location.pathname) {
  return ['/pods-view', '/pod-settings', '/all-shops-admin'].some((route) =>
    pathname.includes(route),
  );
}

export function extractIcon(icon?: React.ReactNode | IconName): React.ReactNode | null {
  if (typeof icon === 'string') return <Icon name={icon as IconName} size={20} />;
  return icon ?? null;
}

export function getFormattedShopName(
  shopName,
  options: { capitalize?: boolean; length?: number } = { capitalize: false, length: 17 },
) {
  let trimmedShopName = shopName?.replace('.myshopify.com', '');
  if (options?.capitalize) trimmedShopName = capitalize(trimmedShopName);
  return truncate(trimmedShopName, { length: options.length });
}

export function goToStore(dispatch: AppDispatch, shopId = $currentShopId.get() ?? undefined) {
  if (!shopId) {
    toast.info('Please choose a store');
    return;
  }
  dispatch(resetStats());
  dispatch(switchStore(shopId, true));
}
