import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * @description Utility hook to check if current `location.pathname`
 * matches (**by using `includes`**) path/s that are provided
 */
export function usePathMatch(path: string | string[]): boolean {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (typeof path === 'string') {
      return pathname.includes(path);
    }

    return path.some((path) => pathname.includes(path));
  }, [pathname, path]);
}
