import React, { useEffect, useMemo, useState } from 'react';
import axiosInstance from '../../../../utils/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers/RootType';
import { ProviderAuth, SensoryProvider } from '@tw/types/module/sensory';
import { Spinner } from '@shopify/polaris';
import { GenericUrlConnect } from './GenericUrlConnect';
import { connectToSensoryBasicAuth, connectToSensory } from '../../../../utils/connectToSensory';
import SalesPlatformIcon from '../../../SalesPlatform/SalesPlatformIcon';
import { SalesPlatformName } from '../../../SalesPlatform/SalesPlatformName';
import { useAppDispatch } from '../../../../index';
import { SET_MSP_CONNECTED } from '../../../../ducks/constants';
import { Box, Flex, Modal, TextInput } from '@tw/ui-components';
import { getShopName } from '@tw/constants/module/utils';
import SALES_PLATFORMS from 'constants/SalesPlatform';

export const SensoryConnect = ({ providerId, nextEnabled, resetContinueWarn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [authConfigMethods, setAuthConfigMethods] = useState<ProviderAuth[]>([]);
  const [fields, setFields] = React.useState<
    {
      value: any;
      field: string;
      label?: string;
      valueType: 'string' | 'number' | 'boolean';
    }[]
  >([]);
  const [isAuthSettingsModalOpen, setIsAuthSettingsModalOpen] = useState<boolean>(false);
  const [basicAuthNoteForModal, setBasicAuthNoteForModal] = useState<string | null>(null);

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  useEffect(() => {
    if (nextEnabled) {
      nextEnabled(!isLoading);
    }
  }, [isLoading, nextEnabled]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const providers = (
        await axiosInstance.get(`/v2/account-manager/integrations/providers/${currentShopId}`)
      )?.data as any[];

      const _provider = providers.find((p) => p.id === providerId) ?? ({} as SensoryProvider);
      setAuthConfigMethods(_provider.auth_config);
      setFields(
        (_provider.auth_config?.find((x) => x.auth_method == 'basic_auth')?.fields as any) ?? [],
      );
      setIsLoading(false);
    })();
  }, [currentShopId, providerId]);

  const fieldsToSend = useMemo(() => {
    return fields.reduce((acc, x) => ({ ...acc, [x.field]: x.value }), {});
  }, [currentShopId, fields, providerId]);
  return (
    <>
      {authConfigMethods
        .sort((a, b) => (a.primary === b.primary ? 0 : a.primary ? -1 : 1))
        .map((authConfig, index) => (
          <React.Fragment key={index}>
            {!isLoading && authConfig.auth_method === 'oauth' && (
              <GenericUrlConnect
                connectAction={() => connectToSensory(getShopName(currentShopId), providerId)}
                resetContinueWarn={resetContinueWarn}
                isPrimary={authConfig.primary}
              />
            )}

            {!isLoading && authConfig.auth_method === 'basic_auth' && (
              <div>
                {authConfig.primary ? (
                  <div className=" bg-[#F5F7FA] py-[25px] px-[34px] ">
                    <ConnectBasicAuthForm
                      fields={fields}
                      setFields={setFields}
                      currentShopId={currentShopId}
                      providerId={providerId}
                      fieldsToSend={fieldsToSend}
                      note={authConfig.note}
                    />
                  </div>
                ) : (
                  <Box mt={'sm'}>
                    <span
                      className="text-logo cursor-pointer underline"
                      onClick={async () => {
                        setIsAuthSettingsModalOpen(true);
                        setBasicAuthNoteForModal(authConfig.note ?? null);
                      }}
                    >
                      Alternative Connection Available
                    </span>
                  </Box>
                )}
              </div>
            )}

            {isLoading && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Spinner accessibilityLabel="App is loading" size="large" />
              </div>
            )}
          </React.Fragment>
        ))}
      <Modal
        opened={isAuthSettingsModalOpen}
        title={`Connect to ${SALES_PLATFORMS[providerId].title} via API Key`}
        onClose={() => {
          setIsAuthSettingsModalOpen(false);
          setBasicAuthNoteForModal(null);
        }}
        centered
        zIndex={1000}
      >
        <ConnectBasicAuthForm
          fields={fields}
          setFields={setFields}
          currentShopId={currentShopId}
          providerId={providerId}
          fieldsToSend={fieldsToSend}
          onClose={() => {
            setIsAuthSettingsModalOpen(false);
            setBasicAuthNoteForModal(null);
          }}
          note={basicAuthNoteForModal}
        />
      </Modal>
    </>
  );
};

const ConnectBasicAuthForm = ({
  fields,
  setFields,
  currentShopId,
  providerId,
  fieldsToSend,
  onClose = () => {},
  note,
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    const res = await connectToSensoryBasicAuth(currentShopId, providerId, fieldsToSend);
    if (res) {
      if (onClose) onClose();
      dispatch({ type: SET_MSP_CONNECTED });
    }
  };

  return (
    <>
      {fields.map((f, idx) => (
        <div className="mb-5" key={idx}>
          <TextInput
            label={f.label}
            value={f.value}
            placeholder={f.field}
            type={f.valueType}
            required
            onChange={(val) => {
              const list = fields.slice();
              list[idx] = { ...list[idx], value: val };
              setFields(list);
            }}
          />
        </div>
      ))}
      {note && (
        <div className="Polaris-TextContainer" dangerouslySetInnerHTML={{ __html: note }}></div>
      )}
      <div className="step-page-wrapper">
        <button
          disabled={fields.some((x) => !x.value)}
          className={`mt-5 step-action-button !rounded-[8px] py-[15px] px-[30px] flex items-center gap-[15px] ${
            fields.some((x) => !x.value) ? 'disabled' : ''
          }`}
          onClick={handleSubmit}
        >
          <SalesPlatformIcon />
          <span className="font-semibold text-[18px]">
            Connect to <SalesPlatformName />
          </span>
        </button>
      </div>
    </>
  );
};
