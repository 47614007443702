import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import axiosInstance from 'utils/axiosInstance';

type UpdateDashboardToFFConfigParams = {
  dashboardId: string;
  configs?: FeatureFlagConfigKey[];
  mergeStrategy?: 'replace' | 'merge' | 'delete';
};

export async function updateDashboardForFFConfigs(
  params: UpdateDashboardToFFConfigParams,
  featureFlag: FeatureFlag,
) {
  try {
    const { dashboardId, configs, mergeStrategy = 'merge' } = params;
    if (!configs?.length) return;

    await axiosInstance.patch('v2/subscription-manager/features/update-ff-configs', {
      configs,
      featureFlag,
      mergeStrategy,
      newValue: [dashboardId],
    });
  } catch (err) {
    console.error('addDashboardToFFConfig error:>>', err);
  }
}
