import {
  ActivityProperties,
  AdProps,
  CustomerActivityType,
} from '@tw/types/module/services/insights';
import { Image, Grid } from '@tw/ui-components';
import { ActivityDetail } from './ActivityDetail';
import { useMemo } from 'react';
import { AdInfo } from './AdInfo';

export const ViewedPage: React.FC<{
  properties: ActivityProperties[CustomerActivityType.ViewedPage];
}> = ({ properties }) => {
  const isReferrer = useMemo(() => {
    return (properties.source as string) === 'tw_referrer';
  }, [properties.source]);

  return (
    <Grid>
      {properties.pageType === 'product' && properties.productTitle && (
        <Grid.Col span="content">
          <Image shadowBorderSize="sm" width={80} src={properties.imgUrl} />
        </Grid.Col>
      )}

      <Grid.Col span="auto" style={{ overflow: 'hidden' }}>
        {isReferrer ? (
          <ActivityDetail label="Referrer">{properties.referrer}</ActivityDetail>
        ) : (
          <AdInfo properties={properties as AdProps} showImage={false} />
        )}
      </Grid.Col>
    </Grid>
  );
};
