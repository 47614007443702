import { $currentPlanName } from '$stores/willy/$subscription';
import { useStoreValue } from '@tw/snipestate';
import { Alert, Box, Button, ButtonProps, Icon, Text } from '@tw/ui-components';
import { useUpgradeButton } from 'components/UpgradePlan/components/useUpgradeButton';
import { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { analyticsEvents, genericEventLogger } from 'utils/dataLayer';

export function useGrowthUpgradeButton() {
  const navigate = useNavigate();
  const buttonText = 'Upgrade';
  const action = () => {
    navigate('/store-settings/plans');
  };
  return { action, buttonText };
}

export type PlanName = string & {};

export type UpgradeCTAOption = {
  text: ReactNode;
  buttonText?: string;
  action: () => unknown;
};

export type CustomMessage = string | Partial<Record<PlanName, string>>;

export function useUpgradeCTAOptions(customMessage?: CustomMessage) {
  const currentPlanName = useStoreValue($currentPlanName);
  const { buttonText, action } = useUpgradeButton('template_library');
  const growth = useGrowthUpgradeButton();

  return useMemo(() => {
    if (!currentPlanName) return null;

    const config: Record<PlanName, UpgradeCTAOption> = {
      'Founders Dash': {
        text:
          typeof customMessage === 'string'
            ? customMessage
            : customMessage?.['Founders Dash'] ||
              'Ready to go beyond this free plan? Upgrade to premium features.',
        buttonText: buttonText,
        action: action,
      },
      Growth: {
        text:
          typeof customMessage === 'string'
            ? customMessage
            : customMessage?.['Growth'] || "You don't have access to this feature on this plan.",
        buttonText: growth.buttonText,
        action: growth.action,
      },
    };

    return config[currentPlanName];
  }, [buttonText, action, growth, currentPlanName, customMessage]);
}

export type PlanUpgradeCTAProps = {
  withIcon?: boolean;
  buttonProps?: ButtonProps;
  customMessages?: CustomMessage;
};

/** Cta to upgrade for plans Growth and below */
export function PlanUpgradeCTA({
  withIcon = false,
  buttonProps,
  customMessages,
}: PlanUpgradeCTAProps) {
  const options = useUpgradeCTAOptions(customMessages);
  const currentPlanName = useStoreValue($currentPlanName);
  const gmv = 0;
  const pathname = window.location.pathname;

  const logEvent = useCallback(
    (action: string, callToAction: string) => {
      const eventPayload = {
        action,
        gmv,
        plan: currentPlanName || 'unknown',
        pathname,
        call_to_action: callToAction,
      } as Record<string, any>;
      eventPayload.location = 'in_nav_bar';
      genericEventLogger(analyticsEvents.UPGRADE, eventPayload);
    },
    [gmv, currentPlanName, pathname],
  );

  if (!options) return null;

  return (
    <Alert color="one.2" variant="light">
      <div className="flex flex-col items-center m-[5px] mt-[0px]">
        {withIcon && (
          <Box mb="sm">
            <Icon name="circle-arrow-up-1" size={30} color="one.5" />
          </Box>
        )}
        <Text c="one.7" fz="sm" fw={500} ta="center" mb="sm">
          {options.text}
        </Text>

        <Button
          fullWidth
          size="xs"
          {...buttonProps}
          onClick={() => {
            logEvent('hit_call_to_action', options.buttonText || 'Upgrade');
            options.action();
          }}
        >
          {options.buttonText}
        </Button>
      </div>
    </Alert>
  );
}
