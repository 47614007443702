import { NotificationsTypesTypes } from 'components/Lighthouse/types';

export const AccountNotificationPlatorms = ['browser', 'mobile'];
export const ShopNotificationPlatorms = ['lighthouse', 'email', 'slack'];
export const NotificationPlatorms = [...AccountNotificationPlatorms, ...ShopNotificationPlatorms];

export const notificationsTypes: NotificationsTypesTypes = [
  // {
  //   id: 'anomaly',
  //   name: 'Anomalies',
  //   availablePlatforms: ['lighthouse', 'email', 'slack', 'mobile', 'browser'],
  //   topics: [
  //     {
  //       id: 'spend',
  //       name: 'Ad Spend Anomaly',
  //     },
  //     {
  //       id: 'pixelConversionRate',
  //       name: 'Pixel Conversion Rate Anomaly',
  //     },
  //     {
  //       id: 'orders',
  //       name: 'Orders Anomaly',
  //     },
  //     {
  //       id: 'blendedRoas',
  //       name: 'Blended ROAS Anomaly',
  //     },
  //     {
  //       id: 'cpm',
  //       name: 'CPM Anomaly',
  //     },
  //     {
  //       id: 'ctr',
  //       name: 'CTR Anomaly',
  //     },
  //   ],
  //   customSettings: [
  //     {
  //       type: 'threshold',
  //       step: 1,
  //       name: 'Anomaly Moderation',
  //       min: 1,
  //       max: 5,
  //       default: 1,
  //       subtext:
  //         'Use the slider below to determine how reactionary our anomaly detection should be. Sliding to the left means we’ll notify you of the slightest variations in data. Sliding to the right means we’ll only notify you of drastic variances in the data.',
  //     },
  //   ],
  // },
  // {
  //   id: 'activities',
  //   name: 'Activities',
  //   availablePlatforms: ['email', 'slack', 'browser', 'mobile'],
  //   topics: [
  //     {
  //       id: 'klaviyo_campaign_sent',
  //       name: 'Klaviyo Campaign Sent',
  //     },
  //     {
  //       id: 'shopify_product_in_stock',
  //       name: 'Shopify Product Back in Stock',
  //     },
  //     {
  //       id: 'shopify_product_out_of_stock',
  //       name: 'Shopify Product Out of Stock',
  //     },
  //     {
  //       id: 'integration_disconnected',
  //       name: 'Integration Disconnected',
  //     },
  //   ],
  // },
  {
    id: 'forecasting',
    name: 'Planner',
    availablePlatforms: ['email'],
    topics: [
      {
        id: 'set_monthly_goals',
        name: 'Set Monthly Goals Reminder',
      },
      {
        id: 'pacing_update',
        name: 'Pacing Update',
      },
      {
        id: 'new_forecast_available',
        name: 'New Forecast Available',
      },
    ],
  },
  // {
  //   id: 'klaviyo',
  //   name: 'Klaviyo',
  //   availablePlatforms: ['lighthouse', 'email', 'slack', 'mobile', 'browser'],
  //   topics: [
  //     {
  //       id: 'campaign_sent',
  //       name: 'Campaign Sent',
  //     },
  //   ],
  // },
  // {
  //   id: 'generative',
  //   availablePlatforms: ['lighthouse'],
  //   name: 'Generative AI',
  //   topics: [
  //     {
  //       id: 'ads',
  //       name: 'New Generated Ads',
  //     },
  //   ],
  // },
  // {
  //   id: 'inventory',
  //   name: 'Inventory',
  //   availablePlatforms: ['lighthouse'],
  //   topics: [
  //     {
  //       id: 'out',
  //       name: 'Out of Stock',
  //     },
  //     {
  //       id: 'lead_time',
  //       name: 'Low Inventory',
  //     },
  //   ],
  //   customSettings: [
  //     {
  //       type: 'threshold',
  //       name: 'Inventory Impact Threshold',
  //       step: 1,
  //       min: 0,
  //       minLabel: '0%',
  //       max: 100,
  //       maxLabel: '100%',
  //       default: 75,
  //       subtext:
  //         'Adjust the slider below to determine the minimum revenue impact per product you want out of stock notifications.',
  //     },
  //   ],
  // },
  // {
  //   id: 'metrics-report',
  //   name: 'Total Impact',
  //   availablePlatforms: ['lighthouse'],
  //   topics: [
  //     {
  //       id: 'total_impact',
  //       name: 'Total Impact',
  //     },
  //   ],
  // },
  // {
  //   id: 'rules-report',
  //   name: 'Rules Report',
  //   availablePlatforms: ['lighthouse'],
  //   topics: [
  //     {
  //       id: 'facebook-ads',
  //       name: 'Facebook Ads',
  //     },
  //   ],
  // },
  // {
  //   id: 'cdp-audience',
  //   name: 'AI Audience',
  //   availablePlatforms: ['lighthouse'],
  //   topics: [
  //     {
  //       id: 'create',
  //       name: 'Create',
  //     },
  //     {
  //       id: 'update',
  //       name: 'Update',
  //     },
  //   ],
  // },
  // {
  //   id: 'opportunity-report',
  //   name: 'Opportunity Report',
  //   availablePlatforms: ['lighthouse'],
  //   topics: [
  //     {
  //       id: 'opportunity-report',
  //       name: 'Opportunity Report',
  //     },
  //   ],
  // },
  // {
  //   id: 'data-stories',
  //   name: 'Data Stories',
  //   availablePlatforms: ['lighthouse'],
  //   topics: [
  //     {
  //       id: 'data-stories',
  //       name: 'Daily',
  //     },
  //   ],
  // },
];
