import { lighthousePopupClose } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import { LighthouseClientNotification } from './types';
import { MagicMajor } from '@shopify/polaris-icons';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useState } from 'react';
import { Loader } from '@tw/ui-components';
import { useDraggable } from 'components/DraggableProvider/DraggableProvider';
import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { Tooltip } from '@shopify/polaris';

const translate = '-translate-y-[60px]';

export const LighthouseDataStory: React.FC<LighthouseClientNotification> = ({
  extraData,
  Icon,
}) => {
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [dataStory, setDataStory] = useState(extraData?.dataStory);
  const [loading, setLoading] = useState(false);
  const [showFullReport, setShowFullReport] = useState(false);

  const reGenerateStory = async () => {
    try {
      setLoading(true);
      const data = (
        await axiosInstance.post('/v2/lighthouse/improve-data-story', {
          shopId: currentShopId,
          story: dataStory,
        })
      ).data;
      setDataStory(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <DraggableModal
      onClose={() => {
        dispatch(lighthousePopupClose());
      }}
      open={true}
      width="medium"
      style={{ backgroundColor: '#11283E' }}
      className="p-6.5"
    >
      <div className="relative font-serif min-h-[540px] flex flex-col text-white">
        <div className={`!rounded-none sm:!rounded flex flex-col flex-auto`}>
          <div className="flex flex-col gap-14">
            <div className="flex flex-col gap-4 text-[20px] font-bold relative italic">
              <div className="flex items-center gap-8 pr-14">
                <div className="border border-solid border-[#2E4E65] rounded-[7px] p-2 flex items-center justify-center bg-[#062940]">
                  {Icon(40)}
                </div>
                {extraData?.titleStory}
              </div>
            </div>
            {loading && (
              <div className="flex flex-col gap-8 justify-center items-center h-100">
                <Loader />
              </div>
            )}
            {!loading && (
              <div className="whitespace-pre-wrap text-[16px] leading-[22px] max-h-[400px] overflow-auto tw-scroll flex justify-center gap-4 sm:px-[40px]">
                <Tooltip content="Re-Write">
                  <MagicMajor
                    width={26}
                    className="border border-solid rounded-md p-[4px] border-[#bbbfc3b3] fill-white cursor-pointer"
                    onClick={reGenerateStory}
                  />
                </Tooltip>
                <span>{dataStory}</span>
              </div>
            )}
          </div>
          <div className="mt-auto pt-6.5">
            <p className="text-white">
              This story is based on{' '}
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  setShowFullReport((showFullReport) => !showFullReport);
                }}
              >
                {extraData?.originalTitle}
              </span>{' '}
              report
            </p>
            {showFullReport && (
              <p className="p-4 mt-6.5 rounded bg-[#0D1F31] whitespace-pre-line">
                {extraData?.originalMessage}
              </p>
            )}
            {/* <div className='flex items-center gap-6.5'>
                <div onClick={() => {

                }}>
                  Share via Email
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

const Handle = () => {
  const { startDrag } = useDraggable();

  return (
    <div className={`flex items-center justify-center w-full ${translate}`}>
      {/* <img onMouseDown={startDrag} src="/data-stories-icon.svg" width={105} alt="data-stories" className="" /> */}
    </div>
  );
};
