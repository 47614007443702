import { DataTypesIds, services, ServicesIds } from '@tw/types/module/services';

import axiosInstance from './axiosInstance';
import { getShopData } from './DB';

export const callInitialImport = async (
  service_id: ServicesIds,
  accountIds?: string[],
  resetShopServices?: boolean,
  dataType?: DataTypesIds,
  useNewJobManager?: boolean,
) => {
  try {
    if (!service_id) {
      throw new Error(`No correct service id ${service_id}`);
    }
    if (!services[service_id]) {
      throw new Error(`service id ${service_id} not supported`);
    }
    let url: string = `v2/job-manager/initial-import`;
    if (useNewJobManager) {
      url = `v2/job-manager/integration/initial-import`;
      if (!accountIds?.length) {
        const shop = await getShopData();
        accountIds = services[service_id]?.getAccounts(shop)?.map((account) => account.id);
      }
    }
    let body = {
      serviceId: service_id,
      ...(accountIds?.length && { accountIds }),
      resetShopServices,
      dataType,
    };

    return await axiosInstance.post(url, body);
  } catch (e) {
    console.error(e);
  }
};
