import { AppExtensionMinor, ReportMinor } from '@shopify/polaris-icons';
import { ReactComponent as SettingsIcon } from 'components/Icons/settings-topBar.svg';
import { NavSection } from '../types';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $isFreeShop } from '$stores/willy/$subscription';

export const STORE_SETTINGS_SECTION: NavSection = {
  routes: [
    {
      uiGroupName: 'General Settings',
      label: 'Store',
      isHybridPage: true,
      url: `/store-settings/general`,
      header: () => <></>,
      isSettingsPage: true,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      uiGroupName: 'General Settings',
      label: 'Team',
      isHybridPage: true,
      url: `/store-settings/team`,
      header: () => <></>,
      isSettingsPage: true,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      uiGroupName: 'Plans and Payment',
      label: 'Plans',
      isHybridPage: true,
      url: `/store-settings/plans`,
      header: () => <></>,
      isSettingsPage: true,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      uiGroupName: 'Plans and Payment',
      label: 'Billing',
      isHybridPage: true,
      url: `/store-settings/billing`,
      header: () => <></>,
      isSettingsPage: true,
      icon: () => <SettingsIcon width={30} />,
      dependsOnFFSystemCallback() {
        return !$isFreeShop.get();
      },
    },
    {
      uiGroupName: 'Plans and Payment',
      label: 'Orders and Invoices',
      isHybridPage: true,
      url: `/store-settings/orders-invoices`,
      header: () => <></>,
      isSettingsPage: true,
      icon: () => <SettingsIcon width={30} />,
    },
    {
      uiGroupName: 'Store Configurations',
      label: 'Reports',
      isHybridPage: true,
      url: '/reports',
      header: () => <></>,
      isSettingsPage: true,
      icon: () => <ReportMinor className="flex items-center w-12 h-12 fill-logo" />,
    },
    {
      uiGroupName: 'Store Configurations',
      label: 'Integrations',
      isHybridPage: true,
      roles: ['admin', 'owner'],
      isSettingsPage: true,
      url: '/integrations',
      icon: () => (
        <img
          className="flex items-center w-full h-auto"
          src="/integration/header.svg"
          alt="integration"
        />
      ),
      header: () => <></>,
    },
    {
      uiGroupName: 'Store Configurations',
      label: 'Apps & Extensions',
      isHybridPage: true,
      url: '/apps',
      isSettingsPage: true,
      header: () => <></>,
      icon: () => <AppExtensionMinor className="flex items-center w-12 h-12 fill-logo" />,
    },
    {
      uiGroupName: 'Store Configurations',
      label: 'API Keys',
      isHybridPage: true,
      url: '/api-keys',
      isSettingsPage: true,
      header: () => <></>,
      icon: () => <AppExtensionMinor className="flex items-center w-12 h-12 fill-logo" />,
      dependsOnFFSystem: FeatureFlag.API_KEYS_FF,
      dependsOnFFSystemCallback: (ffComputer, shop) => {
        const { shouldBeHidden } = ffComputer.getConfigById(FeatureFlag.API_KEYS_FF);

        return !shouldBeHidden;
      },
    },
    {
      uiGroupName: 'Store Configurations',
      label: 'Cost Settings',
      isHybridPage: true,
      url: '/cost-settings',
      isSettingsPage: true,
      dependsOnFFSystem: FeatureFlag.COST_SETTINGS_FF,
      icon: () => <SettingsIcon width={30} />,
      header: () => <></>,
    },
    {
      uiGroupName: 'Store Configurations',
      url: '/attribution/settings',
      label: 'Pixel Settings',
      isHybridPage: true,
      isSettingsPage: true,
      header: () => <></>,
    },
    {
      uiGroupName: 'Store Configurations',
      url: '/sonar',
      label: 'Sonar Settings',
      isHybridPage: true,
      isSettingsPage: true,
      header: () => <></>,
    },
    {
      uiGroupName: 'Store Configurations',
      url: '/utm-builder',
      label: 'Tracking Settings',
      isHybridPage: true,
      isSettingsPage: true,
      header: () => <></>,
    },
    {
      uiGroupName: 'Store Configurations',
      url: '/attribution/traffic-rules',
      dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
      isHybridPage: true,
      isSettingsPage: true,
      label: 'Traffic Rules',
      header: () => <></>,
    },
    {
      label: 'Global Filters',
      isHybridPage: true,
      url: `/global-filters`,
      header: () => <></>,
      isSettingsPage: true,
      dependsOnFFSystem: FeatureFlag.GLOBAL_FILTERS_PAGE_FF,
    },
    {
      label: 'MMM Settings',
      isHybridPage: true,
      url: `/mmm-settings`,
      header: () => <></>,
      isSettingsPage: true,
      onlyAdmin: true,
    },
  ],
};
