import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { TextField, ButtonGroup, Button } from '@shopify/polaris';
import DropDown from 'components/ltv/DropDown';

import { MetricTypeOptions } from './Constants';
import { selectAllSections } from 'utils/selectors';
import { CustomMetricsContext } from './CustomMetricsContext';
import { CustomMetricsType } from 'ducks/customMetrics';
import { gradualReleaseFeatures } from 'ducks/shop';
import { FilterProperty } from '@tw/types/module/services/insights';
import PropertyAutoComplete from 'components/Insights/Filters/PropertyAutoComplete';

export const CustomMetricsSettings: React.FC = () => {
  const {
    setMetricTitle,
    setIsMetricTitleError,
    metricTitle,
    isMetricTitleError,
    metricType,
    metricDescription,
    setMetricDescription,
    setMetricType,
    type,
    setSection,
    section,
    setTag,
    tag,
  } = useContext(CustomMetricsContext);
  const { Custom_Tag: custom_tag_ff } = useSelector(gradualReleaseFeatures);
  const rawSections = useSelector(selectAllSections);
  // adding to customMetrics section creates problems with duplicates, so quick fix.
  const sectionOptions = rawSections
    .filter((x) => x.id !== 'customMetrics')
    .map((section) => ({
      label: section.title,
      value: section.id,
    }));

  const onMetricTitleChange = (value: string) => {
    setMetricTitle(value);
    setIsMetricTitleError(value === '');
  };

  return (
    <div className="customMetricsSettings grid grid-cols-[repeat(auto-fill,minmax(240px,1fr))] gap-8 ">
      <ElementWithLabel label="Metric Title" key={'MetricTitle'}>
        <TextField
          minLength={2}
          labelHidden
          label="Metric Title"
          placeholder="Enter title"
          value={metricTitle}
          onChange={onMetricTitleChange}
          error={isMetricTitleError ? 'Metric title is required' : ''}
          autoFocus
          autoComplete="off"
        />
      </ElementWithLabel>
      <ElementWithLabel label="Metric Desc." key={'MetricDesc'}>
        <TextField
          labelHidden
          label="Metric Desc."
          placeholder="Enter description"
          value={metricDescription}
          onChange={setMetricDescription}
          autoComplete="off"
        />
      </ElementWithLabel>

      <ElementWithLabel label="Metric Type" key={'MetricType'}>
        <div className="custom-metric-button-group">
          <ButtonGroup segmented fullWidth>
            {MetricTypeOptions.map((option) => (
              <Button
                textAlign={'center'}
                key={option?.value}
                onClick={() => setMetricType(option?.value)}
                pressed={metricType === option.value}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </ElementWithLabel>

      {type === CustomMetricsType.Summary && (
        <ElementWithLabel label="Add to Section" key={'AddToSection'}>
          <div className="custom-metric-select-section">
            <DropDown
              theme="light"
              options={sectionOptions}
              value={section}
              handleSelect={setSection}
              title={section ? '' : 'Select section: '}
            />
          </div>
        </ElementWithLabel>
      )}
      {type === CustomMetricsType.Attribution && custom_tag_ff && (
        <ElementWithLabel label="Filter Tag" key={`FilterTag${tag}`}>
          <PropertyAutoComplete
            fetchOptionsList={true}
            property={FilterProperty.ORDER_TAG}
            value={tag}
            allowMultiple={false}
            onChanged={(newTag) => {
              if (newTag) setTag(newTag);
            }}
            closeMenuOnSelect={true}
          />
        </ElementWithLabel>
      )}
    </div>
  );
};

const ElementWithLabel = ({ children, label }) => {
  return (
    <div className="flex flex-col element-with-label">
      <div className=" text-[12px] font-bold mb-[4px]">{label}</div>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};
