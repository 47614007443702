const SmsBumpIconBase64 = ({ small }) => (
  <img
    src="
    data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjMiIGhlaWdodD0iNjIiIHZpZXdCb3g9IjAgMCA2MyA2MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTU5LjUgMEgzLjVDMS44NDMxNSAwIDAuNSAxLjM0MzE1IDAuNSAzVjU5QzAuNSA2MC42NTY5IDEuODQzMTUgNjIgMy41IDYySDU5LjVDNjEuMTU2OSA2MiA2Mi41IDYwLjY1NjkgNjIuNSA1OVYzQzYyLjUgMS4zNDMxNSA2MS4xNTY5IDAgNTkuNSAwWiIgZmlsbD0iI0U3RURGNyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQzLjMyIDM3Ljc1MDRMMzguODc0IDM0LjA5TDIwLjQxNiAzNC4wOTAzQzE5LjM1OCAzNC4wOTAzIDE4LjUgMzMuMjMyMyAxOC41IDMyLjE3MzlWMjAuOTE2NEMxOC41IDE5Ljg1OCAxOS4zNTggMTkgMjAuNDE2IDE5SDQxLjQwM0M0Mi40NjIgMTkgNDMuMzIgMTkuODU4IDQzLjMyIDIwLjkxNjRWMzcuNzUwNFoiIGZpbGw9IiMwMDQyRTQiLz4KPHBhdGggZD0iTTIyLjgxOCA0NC45MDcxQzI1LjIwMyA0NC45MDcxIDI3LjEzNyA0Mi45NzM3IDI3LjEzNyA0MC41ODg4QzI3LjEzNyAzOC4yMDM5IDI1LjIwMyAzNi4yNzA1IDIyLjgxOCAzNi4yNzA1QzIwLjQzMyAzNi4yNzA1IDE4LjUgMzguMjAzOSAxOC41IDQwLjU4ODhDMTguNSA0Mi45NzM3IDIwLjQzMyA0NC45MDcxIDIyLjgxOCA0NC45MDcxWiIgZmlsbD0iI0ZGNEU2MSIvPgo8L3N2Zz4=
  "
    style={{ height: small ? 18 : 18 }}
    alt="Yotpo SMS and Email icon"
  />
);

export default SmsBumpIconBase64;
