import { serverTimestamp, userDb } from '../utils/DB';
import { SET_ADD_MODAL_SECTION } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import Cookies from '../utils/Cookies';
import { toggleSaveIndicator } from './actions';
import { amplitudeTracker } from 'utils/AmplitudeTracker';
import { customSectionAdd, customSectionRemove, sectionTilesOrderChanged } from './summary';
import { SummarySectionTypes } from 'types/SummarySection';

const SECTIONS_COLLECTION = 'custom_metric_sections';

export const CUSTOM_SECTION_ON_CREATE = 'CUSTOM_SECTION_ON_CREATE';
export const customSectionOnCreate = (
  title: string,
  tiles: string[],
  sectionType: SummarySectionTypes,
) => {
  return async (dispatch) => {
    const { id } = await userDb().collection(SECTIONS_COLLECTION).add({
      title,
      tiles,
      shopDomain: $currentShopId.get(),
      createdAt: serverTimestamp(),
      type: sectionType,
    });
    dispatch({ type: SET_ADD_MODAL_SECTION, payload: false });
    dispatch(customSectionAdd(id, tiles));
    toggleSaveIndicator();
    amplitudeTracker('SECTION ADDED');
  };
};

export const CUSTOM_SECTION_ON_DELETE = 'CUSTOM_SECTION_ON_DELETE';
export const customSectionOnDelete = (id) => {
  return (dispatch) => {
    userDb().collection(SECTIONS_COLLECTION).doc(id).delete();
    dispatch(customSectionRemove(id));
    toggleSaveIndicator();
  };
};

export const CUSTOM_SECTION_ON_EDIT = 'CUSTOM_SECTION_ON_EDIT';
export const customSectionOnEdit = (id: string, title: string, tiles: string[]) => {
  return (dispatch) => {
    userDb().collection(SECTIONS_COLLECTION).doc(id).update({ title, tiles });
    dispatch(sectionTilesOrderChanged(id, tiles));
    toggleSaveIndicator();
  };
};

export const CUSTOM_SECTIONS_ON_LOAD = 'CUSTOM_SECTIONS_ON_LOAD';
export const loadCustomSections = () => {
  return (dispatch) => {
    return userDb()
      .collection(SECTIONS_COLLECTION)
      .where('shopDomain', '==', $currentShopId.get())
      .onSnapshot((snapshot) => {
        var data = snapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
        dispatch({
          type: CUSTOM_SECTIONS_ON_LOAD,
          data,
        });
      });
  };
};

const customSections = (
  state = JSON.parse(Cookies.get('customSections') || '{}')[$currentShopId.get()!] || [],
  action,
) => {
  switch (action.type) {
    case CUSTOM_SECTIONS_ON_LOAD:
      let cs = JSON.parse(Cookies.get('customSections') || '{}') ?? {};
      if (Array.isArray(cs)) {
        cs = {};
      }
      cs[$currentShopId.get()!] = action.data;
      Cookies.set('customSections', JSON.stringify(cs));
      return action.data;
    default:
      return state;
  }
};

export const reducers = {
  customSections,
};
