import firebase from 'firebase/compat/app';
import { type AppDispatch } from 'index';
import { type RootState } from 'reducers/RootType';
import { combineReducers, Reducer } from 'redux';

import {
  LIGHTHOUSE_ANOMALY_THRESHOLD_CHANGE,
  LIGHTHOUSE_INVENTORY_REVENUE_THRESHOLD_CHANGE,
  USER_NOTIFICATION_SETTINGS_CHANGE,
  INIT_SHOP,
  UPDATE_SHOP,
  SHOP_NOTIFICATION_SETTINGS_CHANGE,
} from 'ducks/constants';
import { DEFAULT_LIGHTHOUSE_NOTIFICATION_SETTINGS } from 'utils/lighthouse';
import { $currentShopId } from '$stores/$shop';
import { lighthouseFetchNotifications } from './lighthouse';
import {
  NotificationPlatformTypes,
  NotificationSettings,
  NotificationTypes,
} from 'components/Lighthouse/types';
import { userDb } from 'utils/DB';
import {
  AccountNotificationPlatorms,
  ShopNotificationPlatorms,
} from 'constants/notificationSettings';

export const notificationTopicSettingsChange = (
  notificationType: NotificationTypes,
  topic,
  platform: NotificationPlatformTypes,
  value,
  notificationLevel,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { user, shop } = getState();
    let newSettings;
    if (notificationLevel === 'user') {
      newSettings = getState().notificationSettings.userNotificationSettings || {};
    } else {
      newSettings = getState().notificationSettings.shopNotificationSettings || {};
    }
    if (!newSettings[notificationType]) {
      newSettings[notificationType] = { topics: {} };
    }
    if (!newSettings?.[notificationType]?.topics?.[topic]) {
      newSettings[notificationType].topics[topic] = {};
    }
    if (!newSettings[notificationType].topics[topic][platform]) {
      newSettings[notificationType].topics[topic][platform] = true;
    }
    newSettings[notificationType].topics[topic][platform] = value;
    if (platform === 'lighthouse' && value === false) {
      Object.keys(newSettings[notificationType].topics[topic]).forEach((platform) => {
        newSettings[notificationType].topics[topic][platform] = false;
      });
    }
    if (notificationLevel === 'user') {
      var doc = userDb().collection(COLLECTION).doc($currentShopId.get()!);
      try {
        await doc.set({ ...newSettings }, { merge: true });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await firebase
          .firestore()
          .collection('shops')
          .doc(shop.shopId)
          .set({ notificationSettings: { ...newSettings } }, { merge: true });
      } catch (error) {
        console.error(error);
      }
    }
    dispatch({
      type:
        notificationLevel === 'user'
          ? USER_NOTIFICATION_SETTINGS_CHANGE
          : SHOP_NOTIFICATION_SETTINGS_CHANGE,
      payload: newSettings,
    });
    if (platform === 'lighthouse') {
      dispatch(lighthouseFetchNotifications(0, shop.id));
    }
  };
};

export const toggleLighthouseNotificationSetting = (
  notificationType: NotificationTypes,
  topic,
  value,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const platform = 'lighthouse';
    const { user, shop } = getState();
    let userSettings;
    userSettings = getState().notificationSettings.userNotificationSettings || {};
    let shopSettings;
    shopSettings = getState().notificationSettings.shopNotificationSettings || {};
    if (!shopSettings[notificationType]) {
      shopSettings[notificationType] = { topics: {} };
    }
    if (!shopSettings?.[notificationType]?.topics?.[topic]) {
      shopSettings[notificationType].topics[topic] = {};
    }
    if (!shopSettings[notificationType].topics[topic][platform]) {
      shopSettings[notificationType].topics[topic][platform] = true;
    }
    shopSettings[notificationType].topics[topic][platform] = value;
    if (value === false) {
      ShopNotificationPlatorms.forEach((platform) => {
        shopSettings[notificationType].topics[topic][platform] = false;
      });
      if (!userSettings[notificationType]) {
        userSettings[notificationType] = { topics: {} };
      }
      if (!userSettings?.[notificationType]?.topics?.[topic]) {
        userSettings[notificationType].topics[topic] = {};
      }
      AccountNotificationPlatorms.forEach((platform) => {
        userSettings[notificationType].topics[topic][platform] = false;
      });
      var doc = userDb().collection(COLLECTION).doc($currentShopId.get()!);
      try {
        await doc.set({ ...userSettings }, { merge: true });
      } catch (error) {
        console.error(error);
      }
      dispatch({
        type: USER_NOTIFICATION_SETTINGS_CHANGE,
        payload: userSettings,
      });
    }
    try {
      await firebase
        .firestore()
        .collection('shops')
        .doc(shop.shopId)
        .set({ notificationSettings: { ...shopSettings } }, { merge: true });
    } catch (error) {
      console.error(error);
    }
    dispatch({
      type: SHOP_NOTIFICATION_SETTINGS_CHANGE,
      payload: shopSettings,
    });
    dispatch(lighthouseFetchNotifications(0, shop.id));
  };
};

export const shopNotificationSettingsChange = (notificationType, field, value, subtopics = []) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { shop } = getState();
    let newSettings;
    newSettings = getState().notificationSettings.shopNotificationSettings || {};
    if (!newSettings[notificationType]) {
      newSettings[notificationType] = { topics: {} };
    }
    newSettings[notificationType][field] = value;
    try {
      await firebase
        .firestore()
        .collection('shops')
        .doc(shop.id)
        .set({ notificationSettings: { ...newSettings } }, { merge: true });
    } catch (error) {
      console.error(error);
    }
    if (field === 'threshold') {
      if (notificationType === 'inventory') {
        dispatch({
          type: LIGHTHOUSE_INVENTORY_REVENUE_THRESHOLD_CHANGE,
          payload: value,
        });
      } else {
        dispatch({
          type: LIGHTHOUSE_ANOMALY_THRESHOLD_CHANGE,
          payload: 70 + value * 5,
        });
      }
    }
    dispatch({
      type: SHOP_NOTIFICATION_SETTINGS_CHANGE,
      payload: newSettings,
    });
  };
};

export const USER_NOTIFICATION_SETTINGS_RECEIVED = 'USER_NOTIFICATION_SETTINGS_RECEIVED';
export const LOADING_NOTIFICATION_SETTINGS = 'LOADING_NOTIFICATION_SETTINGS';

const COLLECTION = 'notificationSettings';

export const loadUserNotificationSettings = (shopId) => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_NOTIFICATION_SETTINGS });
    const settings = userDb()
      .collection(COLLECTION)
      .doc(shopId)
      .onSnapshot((data) => {
        console.log(data.data());
        dispatch({
          type: USER_NOTIFICATION_SETTINGS_RECEIVED,
          data: data.data(),
        });
      });
    return settings;
  };
};

const loadingSettings: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case LOADING_NOTIFICATION_SETTINGS:
      return true;
    case USER_NOTIFICATION_SETTINGS_RECEIVED:
      return false;
    default:
      return state;
  }
};
const userNotificationSettings: Reducer<NotificationSettings> = (state = {}, action) => {
  switch (action.type) {
    case USER_NOTIFICATION_SETTINGS_RECEIVED:
      const { data } = action;
      return data ?? state;
    case USER_NOTIFICATION_SETTINGS_CHANGE:
      return { ...action.payload };
    default:
      return state;
  }
};

const shopNotificationSettings: Reducer<NotificationSettings> = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case UPDATE_SHOP:
      return action.notificationSettings ?? state;
    case SHOP_NOTIFICATION_SETTINGS_CHANGE:
      return { ...action.payload };
    default:
      return state;
  }
};

const lighthouseNotificationSettings = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case UPDATE_SHOP:
      const newSettings = { ...DEFAULT_LIGHTHOUSE_NOTIFICATION_SETTINGS };

      if (action?.notificationSettings) {
        Object.keys(action.notificationSettings).forEach((notificationType, i) => {
          const notificationTypeSettings = action?.notificationSettings?.[notificationType];
          if (!notificationTypeSettings?.topics) return;

          Object.keys(notificationTypeSettings.topics).forEach((topic, j) => {
            const topicsSettings = notificationTypeSettings?.topics?.[topic];
            if (topicsSettings?.['lighthouse'] !== undefined && newSettings[notificationType]) {
              newSettings[notificationType][topic] = topicsSettings?.['lighthouse'];
            }
          });
        });

        Object.keys(newSettings).forEach((notificationType, i) => {
          let remove = true;
          Object.keys(newSettings[notificationType]).forEach((topic, j) => {
            if (newSettings[notificationType][topic] === true) {
              remove = false;
            }
          });
          if (remove) delete newSettings[notificationType];
        });
      }

      return newSettings ?? state;
    // case SHOP_NOTIFICATION_SETTINGS_CHANGE:
    //   return { ...action.payload };
    default:
      return state;
  }
};

export const reducers = combineReducers({
  userNotificationSettings,
  shopNotificationSettings,
  lighthouseNotificationSettings,
  loadingSettings,
});
