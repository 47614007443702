import { useActiveRoute } from '$stores/nav-config-stores';
import { Anchor, Box, Flex, Popover, Size, Text, Icon, useDisclosure } from '@tw/ui-components';
import { NavLink } from 'react-router-dom';
import { cx } from 'utils/cx';
import { $customizeNavModal } from './CustomizeNavigationModal';
import { $moreSection, $moreRoutes } from 'constants/routes/configs/more';
import { IconComponent } from 'constants/routes/components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStoreValue } from '@tw/snipestate';
import { $navSectionCustomization } from './api/NavSectionCustomizer';
import { useMemo } from 'react';

export function MoreSectionPopover() {
  const { activeRoute } = useActiveRoute();
  const moreSection = useStoreValue($moreSection);
  const moreRoutes = useStoreValue($moreRoutes);
  const navSectionCustomization = useStoreValue($navSectionCustomization);
  const mobile = useIsSmall();
  const [opened, { toggle, close: closePopover }] = useDisclosure(false);
  const isRouteInSideNav = !!(activeRoute?.url && navSectionCustomization[activeRoute.url]?.open);
  const selected = useMemo(
    () => opened || !!(!isRouteInSideNav && activeRoute?.url && moreRoutes.has(activeRoute?.url)),
    [opened, activeRoute?.url, isRouteInSideNav, moreRoutes],
  );

  return (
    <Popover
      opened={opened}
      radius="xl"
      position="right-start"
      width={240}
      offset={{ alignmentAxis: -20, mainAxis: 10 }}
      withinPortal={false}
      shadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
      closeOnClickOutside
      onClose={closePopover}
    >
      <Popover.Target>
        <div
          className={cx(!mobile && 'm-auto', 'flex items-center flex-col cursor-pointer mt-2')}
          onClick={toggle}
        >
          <IconComponent
            selected={selected}
            mobile={mobile}
            icon={
              <Icon name="more" color={selected ? 'one.5' : 'gray.5'} size={mobile ? 18 : 20} />
            }
          />
          <Text fz={12} weight={500} ta="center">
            More
          </Text>
        </div>
      </Popover.Target>
      <Popover.Dropdown bg="white" p={0}>
        <Flex direction="column" pt="sm" px="sm" gap="sm">
          {moreSection.map((s, i) => {
            const filteredRoutes = s.routes.filter((r) => !navSectionCustomization[r.url]?.open);
            if (!filteredRoutes.length) return null;

            return (
              <div key={(s.title || '') + i} className="cursor-pointer">
                <Text p={5 as unknown as Size} tt="uppercase" fz={13} c="gray.5">
                  {s.title}
                </Text>
                {filteredRoutes.map((r) => {
                  return (
                    <NavLink
                      key={r.url}
                      onClick={closePopover}
                      to={{ pathname: r.url }}
                      className={cx(
                        'flex p-[5px] gap-4 items-center',
                        'hover:bg-[var(--gray-light-mode-300)] rounded-md no-underline',
                        activeRoute?.url === r.url && 'bg-[var(--gray-light-mode-300)]',
                      )}
                    >
                      {r.rawIconName && (
                        <div
                          className={
                            'flex justify-center items-center rounded-md' +
                            ' bg-[var(--gray-light-mode-200)] shadow-sm w-[24px] h-[24px]'
                          }
                        >
                          <Icon name={r.rawIconName} size={18} />
                        </div>
                      )}
                      <Text fz="sm" c="gray.8">
                        {r.label}
                      </Text>
                    </NavLink>
                  );
                })}
              </div>
            );
          })}
        </Flex>
        <Box mt="sm" p="md" borderTop="1px solid var(--gray-light-mode-300)">
          <Anchor
            textProps={{ fz: 'xs', c: 'one.5' }}
            as="button"
            onClick={() => {
              closePopover();
              $customizeNavModal.set((x) => ({ ...x, opened: true }));
            }}
          >
            Customize navigation bar
          </Anchor>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
}
