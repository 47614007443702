import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Icon, Modal, ChoiceList, Button } from '@shopify/polaris';

import { SearchMinor, PlusMinor } from '@shopify/polaris-icons';

import { selectUniqueSectionsWithMetrics } from 'utils/selectors';
import { useAppDispatch } from '../index';
import { toggleShowingModalAddSaleBySegment } from '../ducks/shopify/salesBySegmentModal';

function SelectMetrics({
  selectedMetrics,
  onSelectMetric,
  showChartsOnly = false,
  allSections,
  pixelMetrics = null,
}) {
  let finalSections = useSelector(selectUniqueSectionsWithMetrics);

  const [searchValue, setSearchValue] = useState<string>();
  const dispatch = useAppDispatch();
  const onSearchValueChange = (value) => setSearchValue(value);
  if (allSections) {
    finalSections = allSections;
  }
  // remove none charts
  finalSections.forEach((section) => {
    section.tiles = (section.tiles || []).filter((tile) => {
      if (showChartsOnly && tile.chart === undefined) return false;
      return true;
    });
  });

  if (pixelMetrics) {
    finalSections = finalSections.concat(pixelMetrics);
  }

  return (
    <>
      <TextField
        label="Search for metric"
        placeholder="Search metrics"
        value={searchValue}
        onChange={onSearchValueChange}
        prefix={<Icon source={SearchMinor} color="base" />}
        inputMode="search"
        autoComplete="off"
      />

      <div className="inline-choice-list">
        {finalSections
          .filter((section) => {
            if (!searchValue) {
              return section;
            } else if (section.id === 'pixel') {
              return section; // TODO filter pixel result, waiting for new design
            } else if (section.tiles) {
              return section.tiles.filter((tile) => {
                return tile.title.toLowerCase().includes(searchValue.toLowerCase());
              }).length;
            }
            return false;
          })
          .map((section) => {
            if (
              section.id === 'pixel' &&
              section.services.every((service) => service.pixelMetrics?.length > 0)
            ) {
              return (
                <Modal.Section key={section.id}>
                  <ChoiceList
                    allowMultiple
                    title={
                      <div className="section-title choice-list-title-with-icons">
                        {(section.icons || []).map((I, i) => (
                          <I key={i} />
                        ))}
                        <b
                          style={{
                            marginLeft: '0.625rem',
                            display: 'inline-block',
                          }}
                        >
                          {section.title} Metrics
                        </b>
                      </div>
                    }
                    choices={section.services
                      .map((service) =>
                        service.pixelMetrics.map((metric) => ({
                          label: <>{`${service.title} ${metric.shortLabel}`}</>,
                          value: `pixel#${service.id}#${metric.key}`,
                        })),
                      )
                      .flat()}
                    onChange={onSelectMetric}
                    selected={selectedMetrics}
                  />
                </Modal.Section>
              );
            } else {
              if (section.tiles.length > 0) {
                return (
                  <Modal.Section key={section.id}>
                    <ChoiceList
                      allowMultiple
                      title={
                        <div className={'flex justify-between'}>
                          <div className="section-title choice-list-title-with-icons">
                            {(section.icons || []).map((I, i) => (
                              <I key={i} />
                            ))}
                            <b
                              style={{
                                marginLeft: '0.625rem',
                                display: 'inline-block',
                              }}
                            >
                              {section.title} Metrics
                            </b>
                          </div>
                          {section.id === 'salesBySegment' && (
                            <div className={'ml-5 salesBySegment-plain'}>
                              <Button
                                onClick={() =>
                                  dispatch(toggleShowingModalAddSaleBySegment(true, false))
                                }
                                plain
                                icon={PlusMinor}
                              >
                                Add Segment
                              </Button>
                            </div>
                          )}
                        </div>
                      }
                      choices={section.tiles
                        .filter((tile) => {
                          if (!searchValue) {
                            return tile;
                          }
                          return tile.title.toLowerCase().includes(searchValue.toLowerCase());
                        })
                        .map((tile) => {
                          return {
                            label: <>{tile.title}</>,
                            value: tile.id,
                          };
                        })}
                      onChange={onSelectMetric}
                      selected={selectedMetrics}
                    />
                  </Modal.Section>
                );
              }
            }
            return null;
          })}
      </div>
    </>
  );
}

export default SelectMetrics;
