import React from 'react';
const EnquirelabsIconBase64 = ({ small }) => (
  <img
    src="
  data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzg2MThfODAyMSkiPgo8cGF0aCBkPSJNMjUuNjQxNyA4LjIwMTM4TDguNjU4NzMgMTEuMzg4NEM3LjI4MTczIDExLjY0ODIgNiAxMC41NzQzIDYgOS4xNjI2OFY2LjA1MzYxQzYgNC42Njc5NiA2Ljk3ODYyIDMuNDY0MTcgOC4zMjk2MyAzLjIzMDM0TDI1LjMwMzkgMC4wNDMzMjg0QzI2LjY4MDkgLTAuMjE2NDgyIDI3Ljk2MjcgMC44NTc0MDEgMjcuOTYyNyAyLjI2OTA0VjUuMzc4MUMyNy45NjI3IDYuNzYzNzYgMjYuOTg0IDcuOTQxNTcgMjUuNjMzIDguMjAxMzhIMjUuNjQxN1oiIGZpbGw9IiMxRTIzM0MiLz4KPHBhdGggZD0iTTI1LjY0MTQgMjAuNTMzOEwxNi45ODk3IDIyLjE2MTlWMTYuMzE2MkMxNi45ODk3IDE0LjkzMDUgMTcuOTY4NCAxMy43MjY3IDE5LjMxOTQgMTMuNDkyOUwyNS4zMTIzIDEyLjM2NzFDMjYuNjg5MyAxMi4xMDcyIDI3Ljk3MTEgMTMuMTgxMSAyNy45NzExIDE0LjU5MjhWMTcuNzAxOEMyNy45NzExIDE5LjA4NzUgMjYuOTkyNSAyMC4yOTEzIDI1LjY0MTQgMjAuNTI1MVYyMC41MzM4WiIgZmlsbD0iIzFFMjMzQyIvPgo8cGF0aCBkPSJNMTQuNjUxNyAzMC44NTY3TDguNjU4NzMgMzEuOTU2NkM3LjI4MTczIDMyLjIxNjQgNiAzMS4xNDI1IDYgMjkuNzMwOVYyNi42MjE4QzYgMjUuMjM2MiA2Ljk3ODYyIDI0LjAzMjQgOC4zMjk2MyAyMy43OTg2TDE2Ljk4MTMgMjIuMTcwNFYyOC4wMTYxQzE2Ljk4MTMgMjkuNDAxOCAxNi4wMDI3IDMwLjYwNTYgMTQuNjUxNyAzMC44NjU0VjMwLjg1NjdaIiBmaWxsPSIjMUUyMzNDIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfODYxOF84MDIxIj4KPHJlY3Qgd2lkdGg9IjIxLjk3MTMiIGhlaWdodD0iMzIiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2KSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo="
    style={{ height: small ? 11 : 20 }}
    alt="Fairing icon"
  />
);

export default EnquirelabsIconBase64;
