export const keywords = [
  'SELECT',
  'FROM',
  'WHERE',
  'GROUP BY',
  'HAVING',
  'ORDER BY',
  'LIMIT',
  'OFFSET',
  'ASC',
  'DESC',
  'AND',
  'OR',
  'NOT',
  'IS NULL',
  'IS NOT NULL',
  'LIKE',
  'NOT LIKE',
  'IN',
  'NOT IN',
  'BETWEEN',
  'NOT BETWEEN',
  'EXISTS',
  'NOT EXISTS',
  'COUNT',
  'SUM',
  'AVG',
  'MIN',
  'MAX',
  'ROUND',
  'FLOOR',
  'CEIL',
  'DATE',
  'DATETIME',
  'TIME',
  'TIMESTAMP',
  'DATE_ADD',
  'DATE_SUB',
  'DATE_DIFF',
  'DATE_TRUNC',
  'CURRENT_DATE()',
  'ROLLUP',
  'PIVOT',
  'DISTINCT',
  'COALESCE',
  'SAFE_DIVIDE()',
  'snippet',
  'start_date',
  'end_date',
  'event_hour',
];
