export const AFFLUENCER_HUB_CHART_OPEN_CHANGED = 'AFFLUENCER_HUB_CHART_OPEN_CHANGED';
export const AFFLUENCER_HUB_IMPORT_MODAL_OPEN = 'AFFLUENCER_HUB_IMPORT_MODAL_OPEN';
export const ADD_AFFLUENCER_MODAL_OPEN_CHANGED = 'ADD_AFFLUENCER_MODAL_OPEN_CHANGED';
export const AFFLUENCER_HUB_FREE_SEARCH_CHANGED = 'AFFLUENCER_HUB_FREE_SEARCH_CHANGED';

export const AffluencerHubChartOpenChanged = (opened: boolean) => ({
  type: AFFLUENCER_HUB_CHART_OPEN_CHANGED,
  opened,
});

export const AddAffluencerModalOpenChanged = (opened: boolean) => ({
  type: ADD_AFFLUENCER_MODAL_OPEN_CHANGED,
  opened,
});

export const AffluencerHubImportModalOpenChanged = (opened: boolean) => ({
  type: AFFLUENCER_HUB_IMPORT_MODAL_OPEN,
  opened,
});

export const AffluencerFreeSearchChanged = (freeSearch: string) => ({
  type: AFFLUENCER_HUB_FREE_SEARCH_CHANGED,
  freeSearch,
});
