import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FILTER_PROPERTY_METADATA,
  FilterProperty,
  FilterPropertyType,
} from '@tw/types/module/services/insights';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import ReactSelect from 'react-select';
import useDebounce from 'utils/useDebounce';
import { getListOption } from './listsOptionsTranslation';

type Props = {
  property: FilterProperty;
  fetchOptionsList: boolean;
  value: string;
  disabled?: boolean;
  allowMultiple: boolean;
  customStyle?: any;
  closeMenuOnSelect?: boolean;
  onChanged: (value: string | string[] | null) => void;
};
const PropertyAutoComplete: React.FC<Props> = (props) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [allowMultiple, setAllowMultiple] = useState<boolean>(props.allowMultiple);
  const [property, setProperty] = useState<string>(props.property);
  const [fetchOptionsList, setFetchOptionsList] = useState<boolean>(props.fetchOptionsList);
  const [initialValue] = useState<string>(props.value);

  const [inputValue, setInputValue] = useState<string>(props.value);
  const [debouncedInputDelayValue, setDebouncedInputDelayValue] = useState<number>(0);
  const debouncedInputValue = useDebounce(inputValue, debouncedInputDelayValue);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);

  useEffect(() => {
    setAllowMultiple(props.allowMultiple);
  }, [props.allowMultiple]);

  useEffect(() => {
    setProperty(props.property);
  }, [props.property]);

  useEffect(() => {
    setFetchOptionsList(props.fetchOptionsList);
  }, [props.fetchOptionsList]);

  useEffect(() => {
    setDebouncedInputDelayValue(fetchOptionsList ? 300 : 100);
  }, [fetchOptionsList]);

  const updateOptions = useCallback(
    async (term) => {
      setLoading(true);
      try {
        if (fetchOptionsList) {
          const url = `/v2/insights/filters/filter-options`;
          const request = {
            shopId: currentShopId,
            filterProperty: property,
            term,
          };
          const response = await axiosInstance.post<null, { data: { options: [] } }>(url, request);
          setOptions(response.data.options);
        } else {
          setOptions([term]);
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [fetchOptionsList, currentShopId, property],
  );

  // set selected-options
  useEffect(() => {
    if (allowMultiple) {
      const values = initialValue
        ? Array.isArray(initialValue)
          ? initialValue
          : [initialValue]
        : [];
      setSelectedOptions(values.map((v) => ({ label: v, value: v })));
    } else {
      setSelectedOptions({ label: initialValue, value: initialValue });
    }
  }, [initialValue, allowMultiple]);

  // trigget onChange
  useEffect(() => {
    if (allowMultiple) {
      if (selectedOptions?.length > 0) {
        setHasValue(true);
        props.onChanged(selectedOptions.map((o) => o.value));
      } else {
        setHasValue(false);
        props.onChanged(null);
      }
    } else {
      props.onChanged(selectedOptions?.value);
      setHasValue(selectedOptions?.value);
    }
  }, [JSON.stringify(selectedOptions)]);

  // update options
  useEffect(() => {
    if (inputValue) {
      updateOptions(inputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    setOptions(FILTER_PROPERTY_METADATA[property].options || []);
  }, [property]);

  return (
    <>
      <ReactSelect
        isDisabled={props.disabled}
        styles={props.customStyle ?? undefined}
        classNames={{
          control: () => 'autocomplete-control',
          valueContainer: () => 'autocomplete-value-container',
          multiValue: () => 'autocomplete-multi-value',
          singleValue: () => 'autocomplete-single-value',
          menuPortal: () => 'autocomplete-menu-portal',
        }}
        isSearchable={
          FILTER_PROPERTY_METADATA[property].type === FilterPropertyType.LIST ? false : true
        }
        menuPortalTarget={document.body}
        options={options.map((o) => ({ label: getListOption(o), value: o }))}
        isMulti={allowMultiple}
        closeMenuOnSelect={!!props.closeMenuOnSelect}
        onInputChange={setInputValue}
        hideSelectedOptions={false}
        onChange={setSelectedOptions}
        value={selectedOptions}
        isLoading={loading}
        className={`autocomplete ${hasValue ? 'has-value' : ''}`}
        isClearable={true}
        noOptionsMessage={() =>
          loading ? 'Loading...' : fetchOptionsList ? 'Could not find any results.' : ''
        }
      />
      <style>{`.autocomplete-menu-portal {z-index: 99999;}`}</style>
    </>
  );
};

export default PropertyAutoComplete;
