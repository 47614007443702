import { FREE_TRIAL_BANNER_HEIGHT } from '$stores/willy/$upgradePopupManager';
import { useComputedValue } from '@tw/snipestate';
import { Navbar } from '@tw/ui-components';
import { $appTransition } from 'Router/Router';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { useShowFreeTrialBanner } from 'components/Willy/UpgradePlan/FreeTrialBanner';

export const DesktopNavWrapper = ({ children }) => {
  const showFreeTrialBanner = useShowFreeTrialBanner();
  const transition = useComputedValue($appTransition, (t) => `width ${t}ms ease`);

  return (
    <Navbar
      bg="named.2"
      borderRight={TOP_BAR_BORDER_VAR}
      transition={transition}
      top={showFreeTrialBanner ? FREE_TRIAL_BANNER_HEIGHT : undefined}
    >
      {children}
    </Navbar>
  );
};
