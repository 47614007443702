import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import 'firebase/compat/firestore';
import { isMobileApp } from 'utils/Device';

import { firebase as config } from '../config';
export const app = firebase.initializeApp(config);

if (isMobileApp) app.firestore().clearPersistence();

app.firestore().settings({
  cacheSizeBytes: 1048576,
  merge: true,
});
