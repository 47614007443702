import {
  type AnalyticsObjectType,
  type CalculatedMetrics,
  type ExpandedCalculatedPixelMetrics,
  type RawMetrics,
  type RawPixelMetrics,
} from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';
import {
  type AttributionBreakdown,
  type AttributionData,
  type AttributionMainResponse,
  type AttributionRealtimeMessageData,
  type AttributionStatsRequest,
} from 'types/attribution';
import { ALL_SOURCES_ID } from 'constants/types';
import axiosInstance from './axiosInstance';
import allServices from 'constants/services';
import moment from '@tw/moment-cached/module/timezone';
import { SourceTypesWithExtra } from 'types/services';
import { $forceSharded } from '../$stores/$shop';

export const mapEntityToBreakdown: Partial<Record<AnalyticsObjectType, AttributionBreakdown>> = {
  campaign: 'campaignId',
  channel: 'source',
  adset: 'adsetId',
  ad: 'adId',
};

export const getSourceExternalLink = (
  sourceId: string,
  entity: AnalyticsObjectType,
  adAccountId: string,
  campaignId?: string,
  adsetId?: string,
  adId?: string,
): string | null => {
  if (sourceId === 'facebook-ads') {
    adAccountId = adAccountId?.replace('act_', '');
  }
  const sourcesMap: Partial<Record<ServicesIds, Partial<Record<AnalyticsObjectType, string>>>> = {
    'facebook-ads': {
      adAccount: `https://www.facebook.com/adsmanager/manage/campaigns?act=${adAccountId}`,
      campaign: `https://www.facebook.com/adsmanager/manage/campaigns?act=${adAccountId}&filter_set=SEARCH_BY_CAMPAIGN_GROUP_ID-STRING%1EEQUAL%1E%22${campaignId}%22&selected_campaign_ids=${campaignId}`,
      adset: `https://www.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E%22${adsetId}%22&selected_adset_ids=${adsetId}`,
      ad: `https://www.facebook.com/adsmanager/manage/ads?act=${adAccountId}&filter_set=SEARCH_BY_ADGROUP_IDS-STRING_SET%1EANY%1E%5B%22${adId}%22%5D&selected_ad_ids=${adId}`,
    },
    'google-ads': {
      adAccount: `https://adwords.google.com/aw/overview?__e=${adAccountId}`,
      campaign: `https://adwords.google.com/aw/overview?__e=${adAccountId}&campaignId=${campaignId}`,
    },
    'tiktok-ads': {
      adAccount: `https://ads.tiktok.com/i18n/perf/campaign?aadvid=${adAccountId}`,
      campaign: `https://ads.tiktok.com/i18n/perf/campaign?aadvid=${adAccountId}&keyword=${campaignId}`,
      adset: `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${adAccountId}&keyword=${adsetId}&search_type=2`,
      ad: `https://ads.tiktok.com/i18n/perf/creative?aadvid=${adAccountId}&keyword=${adId}&search_type=3`,
    },
    'pinterest-ads': {
      adAccount: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/campaigns`,
      campaign: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/adgroups/?campaignIds=[${campaignId}]`,
      adset: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/ads/?adGroupIds=[${adsetId}]`,
      ad: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/ads/?pinPromotionIds=[${adId}]`,
    },
    'snapchat-ads': {
      adAccount: `https://ads.snapchat.com/${adAccountId}`,
      campaign: `https://ads.snapchat.com/${adAccountId}/campaigns/${campaignId}`,
      adset: `https://ads.snapchat.com/${adAccountId}/adsets/${adsetId}`,
      ad: `https://ads.snapchat.com/${adAccountId}/ads/${adId}`,
    },
  };

  if (!sourceId || !entity || !adAccountId) {
    return null;
  }

  if (entity === 'ad' && !adId) {
    return null;
  }

  if (entity === 'adset' && !adsetId) {
    return null;
  }

  if (entity === 'campaign' && !campaignId) {
    return null;
  }

  if (entity === 'adAccount' && !adAccountId) {
    return null;
  }

  return sourcesMap[sourceId]?.[entity];
};

// type ExportFormat = {
//   Day: string;
//   Channel: string;
//   Campaign: string;
//   Adset: string;
//   Ad: string;
// };

// export const exportAttributionToCsv = async (attributions: AttributionData[]) => {
//   const allDataToExport: AttributionData[] = [];
// };

export function safeDivide(numerator: number, denominator: number) {
  if (!numerator || !denominator) {
    return 0;
  }
  if (!isFinite(numerator) || !isFinite(denominator)) {
    return 0;
  }
  return numerator / denominator || 0;
}

export function addCalculatedStats(
  rawStats: RawMetrics & RawPixelMetrics,
): Partial<AttributionData> {
  const {
    spend,
    pixelConversionValue,
    pixelCogs,
    clicks,
    impressions,
    conversionValue,
    pixelNcPurchases,
    pixelNcConversionValue,
    pixelPurchases,
    purchases,
    pixelVisitors,
    pixelUniqueVisitors,
    pixelUniqueAtc,
    pixelEmailSignup,
    pixelNewVisitors,
  } = rawStats;
  const calculatedPixelMetrics: ExpandedCalculatedPixelMetrics = {
    pixelRoas: safeDivide(pixelConversionValue, spend!),
    pixelNcRoas: safeDivide(pixelNcConversionValue, spend!),
    pixelCpa: safeDivide(spend!, pixelPurchases),
    pixelAov: safeDivide(pixelConversionValue, pixelPurchases),
    pixelNcAov: safeDivide(pixelNcConversionValue, pixelNcPurchases),
    pixelNcCpa: safeDivide(spend!, pixelNcPurchases),
    pixelProfit: safeDivide((pixelConversionValue || 0) - (pixelCogs || 0) - (spend || 0), 1),
    pixelConversionRate: safeDivide(pixelPurchases, pixelVisitors),
    pixelNcConversionRate: safeDivide(pixelNcPurchases, pixelNewVisitors),
    pixelCostPerAtc: safeDivide(spend!, pixelUniqueAtc),
    pixelCostPerEmailSignup: safeDivide(spend!, pixelEmailSignup),
    pixelCostPerNewVisitor: safeDivide(spend!, pixelNewVisitors),
    pixelCostPerVisitor: safeDivide(spend!, pixelUniqueVisitors),
    pixelEmailSignupRate: safeDivide(pixelEmailSignup, pixelUniqueVisitors),
    pixelCvDelta: safeDivide((pixelConversionValue || 0) - (conversionValue || 0), 1),
    pixelNewVisitorPerc: safeDivide(pixelNewVisitors, pixelUniqueVisitors),
    pixelNcPurchasesPerc: safeDivide(pixelNcPurchases, pixelPurchases),
  };
  const calculatedMetrics: CalculatedMetrics = {
    roas: safeDivide(conversionValue!, spend!),
    aov: safeDivide(conversionValue!, purchases!),
    cpa: safeDivide(spend!, purchases!),
    cpc: safeDivide(spend!, clicks!),
    cpm: safeDivide(spend!, impressions!),
    ctr: safeDivide(clicks!, impressions!),
  };
  const stats: Partial<AttributionData> = {
    ...rawStats,
    ...calculatedMetrics,
    ...calculatedPixelMetrics,
  };
  return stats;
}

export function addRealtimeData(
  existingEntity: AttributionData,
  realtimeEntity: AttributionRealtimeMessageData,
): AttributionData {
  let newCustomerValues: Partial<AttributionData> = {};
  if (realtimeEntity.isNewCustomer) {
    newCustomerValues = {
      pixelNcPurchases: existingEntity.pixelNcPurchases + 1,
      pixelNcConversionValue: existingEntity.pixelNcConversionValue + realtimeEntity.totalPrice,
      pixelNcCogs: existingEntity.pixelNcCogs + realtimeEntity.cogs,
    };
  }

  const rawStats: AttributionData = {
    ...existingEntity,
    pixelConversionValue: existingEntity.pixelConversionValue + realtimeEntity.totalPrice,
    pixelPurchases: existingEntity.pixelPurchases + 1,
    pixelCogs: existingEntity.pixelCogs + realtimeEntity.cogs,
    ...newCustomerValues,
    shouldHighlight: true,
  };
  return addCalculatedStats(rawStats) as AttributionData;
}

export const getAttributionData = async (params: AttributionStatsRequest, compare = false) => {
  try {
    const statsResponse = axiosInstance.post<
      any,
      { data: AttributionMainResponse },
      AttributionStatsRequest
    >(`/v2/attribution/get-all-stats${compare ? '?compare' : ''}`, params);

    return statsResponse;
  } catch (error) {
    console.error('Error fetching attribution data:', error);
    throw new Error('Failed to fetch attribution data');
  }
};

export const getAttributionCSVData = async (params) => {
  try {
    const csvDataResponse = axiosInstance.post(`/v2/attribution/get-csv-data`, params);

    return csvDataResponse;
  } catch (error) {
    console.error('Error fetching attribution csv data:', error);
    throw new Error('Failed to fetch attribution csv data');
  }
};

export const getParams = (
  attributionState,
  campaignId?: string,
  adsetId?: string,
  parentValues?: any,
) => {
  const { attributionParams, sourceCategory, activeSource, sourcesList } = attributionState;
  if (!attributionParams) {
    return;
  }

  const { startDate, endDate } = attributionParams;
  const start = moment(startDate);
  const end = moment(endDate);
  const isOneDay = moment(start).isSame(moment(end), 'day');

  const params: AttributionStatsRequest = {
    ...(attributionParams as AttributionStatsRequest),
    breakdown:
      (sourceCategory === 'all' && activeSource === ALL_SOURCES_ID) ||
      activeSource === ALL_SOURCES_ID
        ? 'source'
        : 'campaignId',
    attributionFilters: [],
    forceSharded: $forceSharded.get(),
  };

  if (sourceCategory !== 'all' && activeSource === ALL_SOURCES_ID) {
    params.sources = sourcesList as ServicesIds[];
  } else if (activeSource !== ALL_SOURCES_ID) {
    params.sources = [activeSource];
  }

  if (campaignId) {
    params.attributionFilters.push({ key: 'campaignId', value: campaignId });
    params.breakdown = 'adsetId';
  }
  if (adsetId) {
    params.attributionFilters.push({ key: 'adsetId', value: adsetId });
    params.breakdown = 'adId';
  }

  if (params.model === 'ppsViews') {
    params.parentValues = parentValues;
  }

  return {
    period: {
      startDate,
      endDate,
      start,
      end,
      isOneDay,
    },
    params,
  };
};

const emptyArr = [];

export const getSourcesList = (sourceCategory: SourceTypesWithExtra) => {
  if (sourceCategory === 'all') {
    return emptyArr;
  }

  return Object.values(allServices)
    .filter((s) => s.type === sourceCategory && s.serviceId !== 'organic_and_social')
    .map((s) => s.id);
};
