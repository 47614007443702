import { InsightsFilter } from '@tw/types/module/services/insights';
import { createInsightsFilter, renameInsightsFilter } from 'ducks/insights/filterReducers';
import { useAppDispatch } from 'index';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

export default function SaveFilterModal({ onClose, filtersContext }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const { currentFilterToEdit, filterSavedData, renameMode } = useSelector(
    (state: RootState) => state.insightsFilter,
  );
  const [newName, setNewName] = useState(currentFilterToEdit?.['name'] || '');
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const handleSave = () => {
    if (renameMode) {
      dispatch(renameInsightsFilter(newName, (currentFilterToEdit as InsightsFilter)?.id));
    } else {
      dispatch(createInsightsFilter({ ...filterSavedData, name: newName }, filtersContext));
    }
    onClose();
  };

  return (
    <div className="h-[100vh] w-[100vw] fixed top-0 left-0 flex items-center justify-center z-[99] bg-[rgb(0,0,0,.4)]">
      <div className="bg-white h-max w-[400px] rounded p-7 flex flex-col">
        <div className="controles flex items-center justify-between">
          <h1 className="font-bold text-2xl">Save Filter</h1>
          <h1 className="font-bold cursor-pointer hover:text-gray-600" onClick={onClose}>
            ✕
          </h1>
        </div>
        <div className="input">
          <input
            type="text"
            className={`border border-solid ${
              error ? 'border-red-500' : 'border-blue-500'
            }  rounded p-3 pl-4 w-full my-9 `}
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
              setError(false);
            }}
            ref={inputRef}
          />
        </div>
        <div className="actions  flex items-center justify-end gap-4">
          <div
            onClick={onClose}
            className="py-3 px-9 text-blue-500 cursor-pointer rounded hover:bg-blue-500 hover:text-white  font-semibold"
          >
            Cancel
          </div>
          <div
            onClick={
              newName
                ? handleSave
                : () => {
                    setError(true);
                  }
            }
            className={`py-3 px-9 ${
              newName
                ? 'cursor-pointer hover:text-blue-500 bg-blue-500 hover:bg-white '
                : 'cursor-not-allowed bg-gray-400'
            }  rounded  text-white font-bold`}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
}
