import { $forceSharded, $useClickHouseInSummary, $useSummaryWillyWay } from '$stores/$shop';
import { useWritableStore } from '@tw/snipestate';
import { Checkbox, Menu, Text } from '@tw/ui-components';

export const AdminSection = () => {
  const [useClickHouseInSummary, setUseClickHouseInSummary] =
    useWritableStore($useClickHouseInSummary);
  const [useSummaryWillyWay, setUseSummaryWillyWay] = useWritableStore($useSummaryWillyWay);
  const [forceSharded, setForceSharded] = useWritableStore($forceSharded);
  return (
    <>
      <Menu.Label tt="uppercase" fw={500} color="gray.5">
        Admin
      </Menu.Label>
      <Menu.Item>
        <Checkbox
          checked={useClickHouseInSummary}
          label={
            <Text size="sm" weight={500} color="named2.0">
              Ads on CH
            </Text>
          }
          onChange={(checked) => {
            setUseClickHouseInSummary(checked);
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={useSummaryWillyWay}
          label={
            <Text size="sm" weight={500} color="named2.0">
              Fully CH
            </Text>
          }
          onChange={(checked) => {
            setUseSummaryWillyWay(checked);
          }}
        />
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={forceSharded}
          label={
            <Text size="sm" weight={500} color="named2.0">
              Force Sharded
            </Text>
          }
          onChange={(checked) => {
            setForceSharded(checked);
          }}
        />
      </Menu.Item>
    </>
  );
};
