import {
  MetricsFilterOperands,
  MetricsFilterOperator,
  ShopifyMetricsFilterOperands,
} from '@tw/types';

export type MapOperandsToNames = {
  [operand in MetricsFilterOperands]?: string;
};

export type MapShopifyOperandsToNames = {
  [operand in ShopifyMetricsFilterOperands]: string;
};

export type MapOperatorToNames = {
  [operator in MetricsFilterOperator]?: string;
};

export const operandsNames: MapOperandsToNames = {
  campaign_id: 'Campaign ID',
  adset_id: 'Adset ID',
  ad_id: 'Ad ID',
  campaign_name: 'Campaign Name',
  adset_name: 'Adset Name',
  ad_name: 'Ad Name',
};

export const operatorsNames: MapOperatorToNames = {
  equals: 'Equals',
  not_equals: 'Does Not Equal',
  contains: 'Contains',
  not_contains: 'Does Not Contain',
};

export const operands: MetricsFilterOperands[] = [
  'campaign_name',
  'adset_name',
  'ad_name',
  'campaign_id',
  'adset_id',
  'ad_id',
];

export const shopifyOperands: ShopifyMetricsFilterOperands[] = [
  'sources',
  'order_tags',
  // 'customer_tags' //will be added in the NEAREST future, pls don't delete
];

export const bigCommerceOperands: ShopifyMetricsFilterOperands[] = ['sources'];

export const shopifyOperandsNames: MapShopifyOperandsToNames = {
  sources: 'Source Name',
  order_tags: 'Order Tag Name',
  // customer_tags: 'Customer Tags', //will be added in the NEAREST future, pls don't delete
};

export const operatorsEQUALS: MetricsFilterOperator[] = ['equals', 'not_equals'];
export const operatorsCONTAINS: MetricsFilterOperator[] = ['contains', 'not_contains'];
