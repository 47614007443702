import {
  FilterComparator,
  FilterProperty,
  FilterTimeUnit,
} from '@tw/types/module/services/insights';

const FILTER_PROPERTIES_TRANSLATION: { [key in FilterProperty]?: string } = {
  [FilterProperty.ORDER_SOURCE_NAME]: 'source name',
  [FilterProperty.ORDER_TAG]: 'order tag',
  [FilterProperty.ORDER_PRICE]: 'order price',
  [FilterProperty.ORDER_DISCOUNT_CODE]: 'discount code',
  [FilterProperty.ORDER_DESTINATION]: 'order destination',
  [FilterProperty.ORDER_ITEMS]: 'number of items',
  [FilterProperty.ORDER_SUBSCRIPTION_TYPE]: 'order type',
  [FilterProperty.IS_FIRST_ORDER]: 'is first order',
  [FilterProperty.PRODUCT_NAME]: 'product name',
  [FilterProperty.PRODUCT_ID]: 'product id',
  [FilterProperty.PRODUCT_SKU]: 'SKU',
  [FilterProperty.PRODUCT_VARIANT_NAME]: 'variant',
  [FilterProperty.PRODUCT_VARIANT_ID]: 'variant id',
  [FilterProperty.PRODUCT_TYPE]: 'product type',
  [FilterProperty.PRODUCT_CATEGORY]: 'product category',
  [FilterProperty.PRODUCT_VENDOR]: 'vendor',
  [FilterProperty.PRODUCT_TAG]: 'product tag',
  [FilterProperty.PRODUCT_QUANTITY]: 'product quantity',
  [FilterProperty.PRODUCT_COLLECTION]: 'product collection',
  [FilterProperty.CUSTOMER_NAME]: 'name',
  [FilterProperty.CUSTOMER_EMAIL]: 'email',
  [FilterProperty.CUSTOMER_HAS_STORE_ACCOUNT]: 'customer has store account',
  [FilterProperty.CUSTOMER_STORE_ACCOUNT_CREATED_AT]: 'store account creation',
  [FilterProperty.CUSTOMER_STORE_ACCOUNT_STATUS]: 'store account status',
  [FilterProperty.CUSTOMER_TAG]: 'customer tag',
  [FilterProperty.CUSTOMER_ADDRESS_COUNTRY]: 'country',
  [FilterProperty.CUSTOMER_ADDRESS_STATE]: 'state',
  [FilterProperty.CUSTOMER_ADDRESS_CITY]: 'city',
  [FilterProperty.CUSTOMER_PART_OF_SEGMENT]: 'part of segment',
  [FilterProperty.CUSTOMER_TOTAL_ORDERS_NUMBER]: 'total number of orders',
  [FilterProperty.CUSTOMER_TOTAL_SPEND]: 'total spend',
  [FilterProperty.CUSTOMER_AOV]: 'AOV',
  [FilterProperty.CUSTOMER_PREDICTED_LTV]: 'predicted LTV',
  [FilterProperty.CUSTOMER_IS_LIKELY_TO_CHURN]: 'likely to churn',
  [FilterProperty.CUSTOMER_ACTIVE_SUBSCRIPTIONS_NUMBER]: 'number of active subscriptions',
  [FilterProperty.ATTRIBUTION_SOURCE]: 'source',
  [FilterProperty.ATTRIBUTION_CHANNEL]: 'channel',
  [FilterProperty.ATTRIBUTION_REFERRING_SITE]: 'referring site',
  [FilterProperty.ATTRIBUTION_AD_ACCOUNT]: 'ads account id',
  [FilterProperty.ATTRIBUTION_ADS_CAMPAIGN_NAME]: 'ads campaign name',
  [FilterProperty.ATTRIBUTION_ADS_CAMPAIGN_ID]: 'ads campaign id',
  [FilterProperty.ATTRIBUTION_ADS_ADSET_NAME]: 'ads adset name',
  [FilterProperty.ATTRIBUTION_ADS_ADSET_ID]: 'ads adset id',
  [FilterProperty.ATTRIBUTION_ADS_AD_NAME]: 'ads ad name',
  [FilterProperty.ATTRIBUTION_ADS_AD_ID]: 'ads ad id',
  [FilterProperty.ATTRIBUTION_EMAIL_SMS_CAMPAIGN_NAME]: 'email/sms campaign name',
  [FilterProperty.ATTRIBUTION_ROAS]: 'ROAS',
  [FilterProperty.ATTRIBUTION_PIXEL_ROAS]: 'pxel ROAS',
  [FilterProperty.ATTRIBUTION_CPC]: 'CPC',
  [FilterProperty.ATTRIBUTION_CPM]: 'CPM',
  [FilterProperty.ATTRIBUTION_AD_SPEND]: 'ad spend',
  [FilterProperty.ATTRIBUTION_IMPRESSIONS]: 'impressions',
  [FilterProperty.ATTRIBUTION_URL_PATH]: 'URL',
  [FilterProperty.SUBSCRIPTION_STATUS]: 'status',
  [FilterProperty.ORDER_PAYMENT_STATUS]: 'payment status',
  [FilterProperty.CUSTOMER_PHONE]: 'phone',
  [FilterProperty.CUSTOMER_EMAIL_MARKETING_CONSENT]: 'email marketing consent',
  [FilterProperty.CUSTOMER_SMS_MARKETING_CONSENT]: 'sms marketing consent',
  [FilterProperty.CUSTOMER_RFM_RECENCY]: 'RFM Recency',
  [FilterProperty.CUSTOMER_RFM_FREQUENCY]: 'RFM Frequency',
  [FilterProperty.CUSTOMER_RFM_MONETARY]: 'RFM Monetary',
};

const FILTER_TRANSLATION = {
  // comparators
  [FilterComparator.CONTAIN]: 'contains',
  [FilterComparator.NOT_CONTAIN]: 'does not contain',
  [FilterComparator.IS_IN]: 'is in',
  [FilterComparator.IS_NOT_IN]: 'is not in',
  [FilterComparator.START_WITH]: 'begins with',
  [FilterComparator.NOT_START_WITH]: 'does not begin with',
  [FilterComparator.END_WITH]: 'ends with',
  [FilterComparator.NOT_END_WITH]: 'does not end with',
  [FilterComparator.IS_SET]: 'is set',
  [FilterComparator.IS_NOT_SET]: 'is not set',
  [FilterComparator.GREATER_THAN]: 'is greater than',
  [FilterComparator.LESS_THAN]: 'is less than',
  [FilterComparator.EQUAL]: 'equals',
  [FilterComparator.NOT_EQUAL]: 'does not equal',
  [FilterComparator.OVER_ALL_TIME]: 'all time',
  [FilterComparator.UNDER]: 'under',
  [FilterComparator.OVER]: 'over',
  [FilterComparator.WITHIN]: 'within',
  [FilterComparator.BETWEEN]: 'between',
  [FilterComparator.BEFORE]: 'before',
  [FilterComparator.AFTER]: 'after',
  [FilterComparator.IS]: 'is',
  [FilterComparator.IS_NOT]: 'is not',
  [FilterComparator.ARRAY_CONTAINS]: 'contains',
  [FilterComparator.ARRAY_NOT_CONTAINS]: 'does not contain',

  // Time units
  [FilterTimeUnit.DAY]: 'days',
  [FilterTimeUnit.WEEK]: 'weeks',
  [FilterTimeUnit.MONTH]: 'months',
  [FilterTimeUnit.YEAR]: 'years',

  // properties
  ...FILTER_PROPERTIES_TRANSLATION,
};

export function getFilterQueryLabel(option: string) {
  return FILTER_TRANSLATION[option] || option;
}
