import { ReadableStore } from '@tw/snipestate';
import {
  Text,
  HoverCard,
  HoverCardProps,
  HoverCardTargetProps,
  HoverCardDropdownProps,
} from '@tw/ui-components';
import { NoCreditsLeftAlert } from 'components/Willy/dashboardManagment/template-management/NoCreditsLeftAlert';
import { ReactNode, useEffect, useState } from 'react';

export const limitedAccessRanks = {
  'can-access': 0,
  'no-credits': 1,
  'needs-upgrade': 2,
  'no-access': 3,
} as const;

export type LimitedAccess = keyof typeof limitedAccessRanks;

function getStoreValues(stores: ReadableStore<LimitedAccess>[]) {
  return stores.map((s) => s.get());
}

/**
 * Gets the lowest ranked limitation out of them all.
 * Automatically unlocks if one of the deps has access.
 *
 * If strict, returns the most strict limitation. By default returns the least strict limitation.
 */
function combineLimitationsByRank(accessLimits: LimitedAccess[], strict = false): LimitedAccess {
  const ranks = limitedAccessRanks;
  const sortedAccToRank = [...accessLimits].sort((a, b) => ranks[a] - ranks[b]);
  return sortedAccToRank[!strict ? 0 : sortedAccToRank.length - 1];
}

export type LimitedAccessTooltipProps = Omit<BaseLimitTooltipProps, 'children'> & {
  $store?: ReadableStore<LimitedAccess> | ReadableStore<LimitedAccess>[];
  /** Special message or messages that get shown when user can't access - only for upgrade for now */
  message?: ReactNode;
  /** If strict, will determine access limit by the most strict result.  By default uses the most lose result.  */
  strict?: boolean;
};

export function LimitedAccessTooltip({
  accessLimit,
  target,
  $store,
  strict = false,
  ...props
}: LimitedAccessTooltipProps) {
  const [state, setState] = useState(() => {
    if (accessLimit) return accessLimit;
    if (!$store) return 'can-access';
    if (!Array.isArray($store)) return $store?.get();
    return combineLimitationsByRank(getStoreValues($store), strict);
  });

  useEffect(() => {
    if (accessLimit) {
      return setState(accessLimit);
    }

    if (!$store) {
      return setState(strict ? 'no-access' : 'can-access');
    }

    if (!Array.isArray($store)) {
      return $store.subscribe(setState);
    }

    const unsubs = $store.map((s) =>
      s.subscribe(() => setState(combineLimitationsByRank(getStoreValues($store), strict))),
    );
    return () => unsubs.forEach((u) => u());
  }, [accessLimit, $store, strict]);

  switch (state) {
    case 'can-access':
      return <>{typeof target === 'function' ? target(state) : target}</>;
    case 'no-access':
      return <NoAccessTooltip {...props} target={target} />;
    case 'needs-upgrade':
      return <NeedsUpgradeTooltip {...props} target={target} />;
    case 'no-credits':
      return <NoCreditsTooltip {...props} target={target} />;
  }
}

type BaseLimitTooltipProps = {
  accessLimit?: LimitedAccess;
  target: ReactNode | ((accessLimit?: LimitedAccess) => ReactNode);
  children: ReactNode;
  parentProps?: HoverCardProps;
  targetProps?: HoverCardTargetProps;
  dropdownProps?: HoverCardDropdownProps;
};

function BaseLimitTooltip({
  accessLimit,
  target,
  children,
  parentProps,
  targetProps,
  dropdownProps,
}: BaseLimitTooltipProps) {
  return (
    <HoverCard
      position="right"
      width={400}
      shadow="xl"
      styles={{ dropdown: { border: 'none' } }}
      {...parentProps}
    >
      <HoverCard.Target {...targetProps}>
        <span>{typeof target === 'function' ? target(accessLimit) : target}</span>
      </HoverCard.Target>
      <HoverCard.Dropdown {...dropdownProps}>{children}</HoverCard.Dropdown>
    </HoverCard>
  );
}

type NoCreditsTooltipProps = Pick<BaseLimitTooltipProps, 'target'>;
function NoCreditsTooltip({ target }: NoCreditsTooltipProps) {
  return (
    <BaseLimitTooltip target={target} accessLimit="no-credits" dropdownProps={{ p: 0 }}>
      <NoCreditsLeftAlert />
    </BaseLimitTooltip>
  );
}

type NoAccessTooltipProps = Pick<BaseLimitTooltipProps, 'target'>;
function NoAccessTooltip({ target }: NoAccessTooltipProps) {
  return (
    <BaseLimitTooltip target={target} accessLimit="no-access">
      <div>
        <Text fz="lg" fw={500}>
          You don't have permission to access this feature
        </Text>
        <Text fz="sm" c="gray.6">
          Please ask one of your teammates with admin access to give you permission
        </Text>
      </div>
    </BaseLimitTooltip>
  );
}

type UpgradeTooltipProps = Pick<BaseLimitTooltipProps, 'target' | 'parentProps'> &
  Pick<LimitedAccessTooltipProps, 'message'>;
function NeedsUpgradeTooltip({ target, parentProps, message }: UpgradeTooltipProps) {
  return (
    <BaseLimitTooltip target={target} parentProps={parentProps}>
      <div>
        {!!message && (
          <>
            {typeof message !== 'object' ? (
              <Text span fz="sm" fw={500}>
                {message}
              </Text>
            ) : (
              message
            )}
          </>
        )}
      </div>
    </BaseLimitTooltip>
  );
}
