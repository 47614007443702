import { Monaco } from '@monaco-editor/react';
import { BqTable } from './dataStuff/tables';
import { editor } from 'monaco-editor';
import { EditorInstance, SavedQueryType } from 'components/Willy/types/willyTypes';

type createTabProps = {
  index: number;
  language: 'sql' | 'python';
  schema: BqTable[];
  editorRef: editor.IStandaloneCodeEditor;
  monacoRef: Monaco;
  queryId?: string;
  savedQueryType?: SavedQueryType;
  initialCode?: string;
  name?: string;
  active?: boolean;
  jsonData?: string;
};

export function createNewTab(
  props: createTabProps,
  setTabs: React.Dispatch<React.SetStateAction<EditorInstance[]>>,
) {
  const {
    index,
    language,
    schema,
    editorRef,
    monacoRef,
    queryId,
    initialCode = '',
    name = `Untitled tab ${index}`,
    savedQueryType = 'query',
    active = true,
    jsonData = '',
  } = props;
  const newModel: editor.ITextModel = monacoRef.editor?.createModel(initialCode, language);

  const state = editorRef?.saveViewState();

  const exampleCode = language === 'sql' ? '' : 'print(data)';

  const newTab: EditorInstance = {
    model: newModel,
    state: state,
    tabName: name,
    schema: schema,
    filterBuilder: { isPaneOpen: false },
    nameEditable: false,
    nameChanged: false,
    active: active,
    query: initialCode || exampleCode,
    queryId,
    language: language,
    savedQueryType,
    jsonData: jsonData || JSON.stringify([{ hello: 'world' }], null, 2),
  };

  newModel.onDidChangeContent(() => {
    setTabs((prevTabs) => {
      return prevTabs.map((tab) => {
        if (tab.model.id === newTab.model.id) {
          return { ...tab, touched: true };
        }
        return tab;
      });
    });
  });

  editorRef.focus();

  return newTab;
}
