import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { MetricItem } from './MetricItem';

export const ExpressionElementBag: React.FC<any> = (props) => {
  return (
    <Droppable droppableId={props.listId} direction="horizontal" isCombineEnabled={false}>
      {(provided) => (
        <div>
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex flex-wrap min-h-[30px] bg-white  border-[2px]  border-gray-200 pb-[8px]"
          >
            {props.items.length ? (
              props.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MetricItem isSelected={true} {...item}></MetricItem>
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <div className="flex-grow flex flex-col justify-center min-h-[56px] items-center text-center text-gray-400 pt-[8px] ">
                Click or drop elements and operators here.
              </div>
            )}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
