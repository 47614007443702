import { useState, useMemo, useCallback, act } from 'react';
import { Modal, Card } from '@shopify/polaris';
import EmojiPicker from 'emoji-picker-react';
import { Size } from '@shopify/polaris/build/ts/latest/src/components/Badge';
import {
  ActionIcon,
  Icon,
  Button,
  IconName,
  icons,
  Text,
  Tabs,
  Flex,
  Anchor,
  Container,
  UnstyledButton,
} from '@tw/ui-components';
import { visibleLength } from '../utils/willyUtils';
import { Emoji, WillyEmoji, WillyEmojiLetter } from '../types/emojiTypes';
import { emojiIsLetter } from './WillyDashDescription';

type WillyDashAvatarProps = {
  emoji: WillyEmoji;
  setEmoji: (emoji: WillyEmoji) => void;
  dashName: string;
  showSubtext?: boolean;
  canEdit?: boolean;
  size?: Size;
  title: string;
  transparent?: boolean;
};

export const WillyDashAvatar: React.FC<WillyDashAvatarProps> = ({
  emoji,
  setEmoji,
  canEdit = false,
  title = 'Update emoji',
  transparent = false,
  dashName,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  const AvatarIconPicker = useMemo(() => {
    return (
      <div className="grid grid-cols-7 gap-4 justify-between items-center justify-items-center">
        {icons.map((icon) => (
          <ActionIcon
            key={icon}
            size="lg"
            radius="sm"
            variant={emoji === icon ? 'gradient' : 'activator'}
            icon={icon}
            onClick={() => {
              setEmoji(icon);
            }}
          />
        ))}
      </div>
    );
  }, [emoji, setEmoji]);

  const [activeTab, setActiveTab] = useState('emoji');
  const iconTabs = useMemo(
    () => [
      {
        id: 'emoji',
        content: 'Emoji',
        markup: () => (
          <EmojiPicker
            width={'100%'}
            onEmojiClick={(e) => {
              if (!!canEdit) {
                setShowPicker(false);
                setEmoji(e.emoji as Emoji);
              }
            }}
          />
        ),
      },
      {
        id: 'icon',
        content: 'Icon',
        markup: () => AvatarIconPicker,
      },
    ],
    [AvatarIconPicker, canEdit, setEmoji, setShowPicker],
  );

  const removeEmoji = useCallback(() => {
    if (dashName.length) {
      setEmoji(dashName[0].toUpperCase() as WillyEmojiLetter);
    } else {
      setEmoji('Aa');
    }
    setShowPicker(false);
  }, [dashName, setEmoji]);

  const AvatarEmojiPicker = useMemo(() => {
    return (
      <Tabs value={activeTab} onChange={(t) => setActiveTab(t || 'emoji')}>
        <Tabs.List className="border-0" justify="space-between">
          <Flex>
            <Tabs.Tab value="emoji">
              <span className="flex gap-1">
                <Text size="sm">Emoji</Text>
              </span>
            </Tabs.Tab>
            <Tabs.Tab value="icon">
              <Text size="sm">Icon</Text>
            </Tabs.Tab>
          </Flex>
          {!emojiIsLetter(emoji) && (
            <UnstyledButton onClick={() => removeEmoji()}>
              <div className="bg-white hover:bg-gray-50 p-[10px] rounded-[6px]">
                <Text size="sm">Remove</Text>
              </div>
            </UnstyledButton>
          )}
        </Tabs.List>
        <Card.Section>{iconTabs.find((i) => i.id == activeTab)?.markup()}</Card.Section>
      </Tabs>
    );
  }, [activeTab, iconTabs, emoji, removeEmoji]);

  return (
    <>
      <div className="relative flex">
        <Modal open={canEdit && showPicker} onClose={() => setShowPicker(false)} title={title}>
          <Modal.Section>{AvatarEmojiPicker}</Modal.Section>
        </Modal>
        {transparent ? (
          <div
            onClick={() => {
              canEdit && setShowPicker(!showPicker);
            }}
            className={`flex items-center justify-center cursor-pointer ${
              emojiIsLetter(emoji)
                ? 'bg-gray-100 w-[21px] h-[21px] text-gray-400 rounded-sm text-[14px] font-semibold'
                : ''
            }`}
          >
            {visibleLength(emoji) > 1 ? <Icon name={emoji as IconName} size={14} /> : emoji}
          </div>
        ) : (
          <Button
            bg={emojiIsLetter(emoji) ? 'gray.1' : 'white'}
            variant="activator"
            onClick={() => {
              canEdit && setShowPicker(!showPicker);
            }}
            px="sm"
          >
            <div className="flex items-center justify-center">
              {emojiIsLetter(emoji) ? (
                <Text size="sm" fw={400} color="gray.4">
                  {emoji}
                </Text>
              ) : visibleLength(emoji) > 1 ? (
                <Icon name={emoji as IconName} size={14} />
              ) : (
                emoji
              )}
            </div>
          </Button>
        )}
      </div>
    </>
  );
};
