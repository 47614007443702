import './CreativeThumbnail.scss';

import TWImage from 'components/library/TWImage/TWImage';
import { SelectableCreative } from 'types/creativeCockpit';

import { ReactComponent as CreativeText } from '../../../icons/creative-text.svg';

interface CreativeThumbnailProps {
  creative: SelectableCreative;
  onClick: () => any;
  wrapperStyle?: React.CSSProperties;
  creativeBodyIconColor?: string;
}

const CreativeThumbnail = (props: CreativeThumbnailProps) => {
  const { creative, onClick, wrapperStyle, creativeBodyIconColor } = props;

  return (
    <div
      className="creative-thumbnail"
      style={{
        ...wrapperStyle,
        boxShadow: `0 0 0 1px ${creative.color || 'var(--border-light)'}`,
        opacity: creative.active ? '1' : '0.4',
      }}
      onClick={onClick}
    >
      {creative.assetType === 'copy' ? (
        <CreativeText
          style={{
            color: creativeBodyIconColor
              ? creativeBodyIconColor
              : creative.active
                ? 'white'
                : '#ffffff66',
          }}
        />
      ) : (
        <TWImage
          wrapperClass="w-full h-full"
          className="w-full h-full object-cover block"
          alt={creative.name}
          src={creative.thumbnail || creative.image}
        />
      )}
    </div>
  );
};

export default CreativeThumbnail;
