import { useMemo } from 'react';
import { services } from '@tw/types/module/services';
import { EmailSMSCampaignProps } from '@tw/types/module/services/insights';
import { ActivityDetail } from './ActivityDetail';

export const EmailSMSCampaign: React.FC<{
  properties: EmailSMSCampaignProps;
}> = ({ properties }) => {
  const sourceText = useMemo(() => {
    if (!properties.source) return;
    return services[properties.source]?.name || properties.source;
  }, [properties.source]);

  return (
    <>
      {sourceText && <ActivityDetail label="Source">{sourceText}</ActivityDetail>}

      {properties.campaignName && (
        <ActivityDetail label="Campaign Name">{properties.campaignName}</ActivityDetail>
      )}
    </>
  );
};
