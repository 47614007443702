import { isInIframe } from '../config';
import { isMobileApp } from './Device';

export const toggleLauncherVisibility = (show: boolean) => {
  if (!window.Intercom) return;

  if (isInIframe || isMobileApp) {
    window.Intercom('shutdown');
    return;
  }

  window.Intercom('update', { hide_default_launcher: !show });
};

export const killIntercom = () => {
  // @ts-ignore
  if (window.Intercom?.booted) {
    // @ts-ignore
    window.OldIntercom = window.Intercom;
  }
  window.Intercom('shutdown');
  window.Intercom = undefined;
  window.Intercom = () => {};
};

export const reviveIntercom = () => {
  // @ts-ignore
  if (window.Intercom?.booted) {
    window.Intercom('show');
    return;
  }

  // @ts-ignore
  if (window.OldIntercom?.booted) {
    // @ts-ignore
    window.Intercom = window.OldIntercom;
    window.Intercom('boot', {
      app_id: 'ogniecxz',
      hide_default_launcher: false,
    });
    window.Intercom('show');
  }
};
