import { useMemo, useState } from 'react';
import { dataBreakdownContext } from './utils/willyUtils';
import { Loader, Tabs, Text } from '@tw/ui-components';
import { TableTab } from './types/willyTypes';
import { isDefined } from 'utils/isDefined';

type WillyTableBreakdownTabsProps = {
  tabs: TableTab[];
  activeTab: string;
  tabChanged: (id: TableTab['id']) => void;
};

export const WillyTableBreakdownTabs: React.FC<WillyTableBreakdownTabsProps> = ({
  tabs,
  activeTab,
  tabChanged,
}) => {
  return (
    <div className="flex flex-col">
      <Tabs
        value={activeTab}
        onChange={(t) => {
          if (!isDefined(t)) return;
          tabChanged(t as TableTab['id']);
        }}
        fullWidth
      >
        <Tabs.List className="w-full !justify-between">
          {tabs.map((tab) => (
            <Tabs.Tab
              key={tab.id}
              value={tab.id}
              className="group !flex-auto"
              disabled={tab.disabled}
            >
              <span
                className={`flex gap-4 items-center justify-center px-4 py-2 rounded-md ${
                  !tab.disabled && 'cursor-pointer'
                }`}
              >
                {tab.loading && <Loader type="dots" size="xs" />}
                {tab.label}
              </span>
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>
    </div>
  );
};

export const useWillyTableBreakdownTabs = (
  breakdown: ReturnType<typeof dataBreakdownContext> | 'ad_id',
  loadingBreakdownData: Record<'ads' | 'adsets', boolean>,
  expandedCampaigns: string[],
  expandedAdsets: string[],
  activeTab?: 'campaigns' | 'ad_sets' | 'ads' | undefined,
) => {
  const willyTableBreakdownTabs = useMemo(() => {
    const tabs: TableTab[] = [
      {
        label: (
          <Text fw="700" size="sm" c={activeTab === 'campaigns' ? 'one.6' : 'gray.6'}>
            Campaigns
          </Text>
        ),
        id: 'campaigns',
      },
      {
        label: (
          <div className="flex items-center gap-2">
            <Text fw="700" size="sm" c={activeTab === 'ad_sets' ? 'one.6' : 'gray.6'}>
              Ad Sets
            </Text>
            {expandedCampaigns.length > 0 && (
              <Text size="sm" c={activeTab === 'ad_sets' ? 'one.6' : 'gray.6'}>
                (for {expandedCampaigns.length} campaigns)
              </Text>
            )}
          </div>
        ),
        id: 'ad_sets',
        loading: loadingBreakdownData.adsets,
        disabled: breakdown === 'campaign_id' && expandedCampaigns.length === 0,
      },
      {
        label: (
          <div className="flex items-center gap-2">
            <Text fw="700" size="sm" c={activeTab === 'ads' ? 'one.6' : 'gray.6'}>
              Ads
            </Text>
            {expandedAdsets.length > 0 && (
              <Text size="sm" c={activeTab === 'ads' ? 'one.6' : 'gray.6'}>
                (for {expandedAdsets.length} Ad sets)
              </Text>
            )}
          </div>
        ),
        id: 'ads',
        loading: loadingBreakdownData.ads,
        disabled:
          expandedAdsets.length === 0 ||
          (expandedCampaigns.length === 0 && breakdown === 'campaign_id'),
      },
    ];

    if (!breakdown) {
      return [];
    }

    if (breakdown === 'ad_set_id') {
      tabs.shift();
    }

    if (breakdown === 'ad_id') {
      tabs.shift();
      tabs.shift();
    }

    return tabs;
  }, [
    breakdown,
    loadingBreakdownData.ads,
    loadingBreakdownData.adsets,
    expandedAdsets.length,
    expandedCampaigns.length,
    activeTab,
  ]);

  return willyTableBreakdownTabs;
};
