import { $derived } from '@tw/snipestate';
import { $userId } from './$user';
import { io } from 'socket.io-client';
import { isLocalhost, isStaging } from 'config';
import firebaseClient from 'firebase/compat/app';

const baseURL = isLocalhost
  ? 'http://localhost'
  : `https://${isStaging ? 'stg.' : ''}realtime.api.whale3.io`;

function createSocket(userId?: string) {
  return io(baseURL, {
    path: isLocalhost ? '/realtime/ws' : '/ws',
    reconnectionDelayMax: 10000,
    transports: ['websocket'],
    autoConnect: false,
    query: { userId },
    auth: async (cb) => {
      const token = await firebaseClient.auth()?.currentUser?.getIdToken();
      cb({ token });
    },
  });
}

export const $socket = $derived((get) => createSocket(get($userId)));
