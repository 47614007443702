import TWImage from 'components/library/TWImage/TWImage';
import { useContext, useState, useCallback } from 'react';
import { GenerativeAiPreGeneratePopUpData } from 'types/general';
import { loadingProductInfoForGai, openBackgroundDiffusionPopup } from 'ducks/actions';
import { GENERATIVE_AI_POPUP_OPENED } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { useAppDispatch } from 'index';
import { Checkbox, Button, Modal, AccountConnection, Tooltip } from '@shopify/polaris';
import { GenerativeAiPopupData } from 'types/general';
import { triggerReloadContentHubTab } from 'ducks/actions';
import { ContentHubContext } from '../ContentHub';
import { MediaPickerContext } from '../MediaPicker';
import FacebookIconBase64 from 'components/FacebookIconBase64';
import ShopifyIconBase64 from 'components/ShopifyIconBase64';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import SelectProductModal from './SelectProductModal';
import { confirmationModal, confirmationModalClose } from 'ducks/confirmationModal';
import { toast } from 'react-toastify';
import { DeleteMajor, DeleteMinor, ImagesMajor, RefreshMajor } from '@shopify/polaris-icons';
import { uploadMedia } from 'utils/media';
import { creativeHubMedia } from '@tw/helpers/module/creativeHubMedia';
import { isProduction } from '../../../config';
import UploadMediaCards from '../upload/UploadMediaCards';
import { MediaItemType } from '@tw/types';
import { connectToShopify } from 'utils/connectToShopify';

type CardThumbnailProps = {
  media: MediaItemType;
  caption?: JSX.Element;
  product?: any;
  onSaveEdited?: (img: string) => void;
};

const CardThumbnail: React.FC<CardThumbnailProps> = ({ media, caption, product, onSaveEdited }) => {
  const mediaItem = creativeHubMedia(media, !isProduction);
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopifyScopes = useSelector((state: RootState) => state.shopifyScopesFromApi);
  const { isMediaPicker } = useContext(ContentHubContext);
  const { selectedMedia, selectMedia } = useContext(MediaPickerContext);
  const isSelected = !!selectedMedia?.find((m) => {
    return m.id === media.id;
  });

  const needsPermission = useCallback(() => {
    return !['write_products', 'read_products'].every((y) => shopifyScopes?.includes(y));
  }, [shopifyScopes]);

  const [openSelectProduct, setOpenSelectProduct] = useState(false);
  const [openVersions, setOpenVersions] = useState(false);
  const [editedImage, setEditedImage] = useState<any>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadError, setUploadError] = useState('');
  const [syncingMedia, setSyncingMedia] = useState(false);
  const [mediaSynced, setMediaSynced] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const isVersioned = mediaItem.versions && mediaItem.versions?.length > 0 && !openVersions;

  const [openReconnectPopup, setOpenReconnectPopup] = useState(false);

  const openGenerativeAi = useCallback(() => {
    if (mediaItem.service_id === 'shopify') {
      // e.preventDefault();
      // e.stopPropagation();
      if (!product && mediaItem.custom_id) {
        dispatch(
          loadingProductInfoForGai({ id: mediaItem.custom_id, shopId: $currentShopId.get()! }),
        );
      }
      dispatch({
        type: GENERATIVE_AI_POPUP_OPENED,
        payload: {
          serviceId: mediaItem.service_id,
          attemptFrom: 'product',
          entityId: mediaItem.custom_id,
          entityName: product?.title || '',
          imageUrl: mediaItem.url,
          isVideo: mediaItem.media_type === 'video',
          fromProductMedia: mediaItem,
        } as GenerativeAiPopupData,
      });
    } else {
      let ad = {
        media: [creativeHubMedia(mediaItem, !isProduction)],
        linkUrls: [`www.${currentShopId}`],
      };
      dispatch({
        type: GENERATIVE_AI_POPUP_OPENED,
        payload: {
          pregenerated: true,
          ads: [ad],
        } as GenerativeAiPreGeneratePopUpData,
      });
    }
  }, [
    currentShopId,
    dispatch,
    mediaItem.custom_id,
    mediaItem.media_type,
    mediaItem.service_id,
    mediaItem.url,
    product,
  ]);

  // const handleUploadException = (error: { message: string }, fileName: string) => {
  //   setUploadError(error.message);
  //   if (fileName) {
  //     setUploadProgress((prevProgress) => {
  //       let prev = { ...prevProgress };
  //       delete prev[fileName];
  //       return { ...prev };
  //     });
  //   }
  // };

  // const uploadFile = async (base64: any) => {
  //   // setMediaUploadInProgress(true);
  //   try {
  //     let file = await fetch(base64)
  //       .then((res) => res.blob())
  //       .then((blob) => {
  //         return new File([blob], `edited_${mediaItem.name}`, { type: 'image/png' });
  //       });
  //     setUploadProgress((prevProgress) => ({ ...prevProgress, ...{ [file.name]: 0 } }));
  //     let uploadedMedia = await uploadMedia(
  //       file,
  //       currentShopId,
  //       'triplewhale',
  //       'media_library',
  //       (progress, name) => {
  //         setUploadProgress((prevProgress) => {
  //           return { ...prevProgress, ...{ [name]: progress } };
  //         });
  //         if (progress === 100) {
  //           setTimeout(() => {
  //             setUploadProgress((prevProgress) => {
  //               let prev = { ...prevProgress };
  //               delete prev[name];
  //               return { ...prev };
  //             });
  //           }, 3000);
  //         }
  //       }
  //     );
  //     return uploadedMedia.data.url;
  //   } catch (e) {
  //     handleUploadException(e, 'newfile');
  //   }
  // };

  const openEditProductImageOnShopify = () => {
    if (needsPermission()) {
      setOpenReconnectPopup(true);
      return;
    }
    dispatch(
      openBackgroundDiffusionPopup({
        serviceId: 'shopify',
        initImage: { ...mediaItem, media_type: 'image' },
        onSave: (newImage) => {
          saveToMediaToShopify(newImage, product?.handle, product?.id);
        },
        onSaveButtonLabel: 'Post to Shopify',
      }),
    );
  };

  const confirmedSaveMediaToShopify = async (newImage, productHandle, productId) => {
    try {
      await axiosInstance.post('/v2/shopify/upload-media-to-product', {
        shopId: currentShopId,
        media_src: newImage,
        product_handle: productHandle,
        productId: productId,
      });
    } catch (e) {
      toast.error('Product did not update');
    }
  };

  const syncMedia = async () => {
    setSyncingMedia(true);
    try {
      if (product && product.handle && product.id) {
        await axiosInstance.post('/v2/shopify/sync-media-to-product', {
          shopId: currentShopId,
          media_src: mediaItem.url,
          product_handle: product?.handle,
          productId: product?.id,
          pending_img_id: mediaItem.id,
        });
        setMediaSynced(true);
      } else {
        toast.error('Cannot sync media to product');
      }
      setSyncingMedia(false);
    } catch (e) {
      setSyncingMedia(false);
      toast.error('Could not sync media');
    }
  };

  const saveToMediaToShopify = async (newImage, productHandle, productId) => {
    let imageUrl = editedImage ?? newImage.url;
    if (onSaveEdited) {
      onSaveEdited(imageUrl);
    }
    if (productHandle && productId) {
      dispatch(
        confirmationModal({
          onConfirm: () => confirmedSaveMediaToShopify(imageUrl, productHandle, productId),
          onCancel: () => dispatch(confirmationModalClose()),
          text: `Are you sure you want to add this image to the ${productHandle}`,
          confirmButtonText: 'Yes',
          title: 'Confirm Product Change',
        }),
      );
    } else {
      setOpenSelectProduct(true);
      setEditedImage(imageUrl);
    }
  };
  if (!media) {
    return null;
  }

  const deleteImage = () => {
    if (media.service_id !== 'shopify') {
      setConfirmDeleteModal(true);
    } else {
      toast.error('Cannot delete image from shopify');
    }
  };

  const confirmedDeleteImage = async () => {
    if (media.service_id !== 'shopify') {
      try {
        setDeleting(true);
        await axiosInstance.post('/v2/media/delete-media', {
          shopId: currentShopId,
          mediaId: mediaItem.id ?? mediaItem._id,
        });
        toast.success('Media was deleted');
        setConfirmDeleteModal(false);
        dispatch(triggerReloadContentHubTab());
        setDeleting(false);
      } catch (e) {
        toast.error('Media did not delete');
        setDeleting(false);
      }
    }
  };

  return (
    <div
      className={`relative h-full group ${isVersioned ? 'cursor-pointer' : ''}}`}
      onClick={
        isVersioned
          ? () => {
              setOpenVersions(true);
            }
          : () => {}
      }
    >
      {!isMediaPicker && !isVersioned && (
        <div className="absolute top-[0.5rem] left-[0.5rem] z-10 flex gap-4">
          <Button onClick={openGenerativeAi} icon={<FacebookIconBase64 small={false} />} />
          {mediaItem.service_id !== 'shopify-pending' ? (
            <>
              {mediaItem.media_type !== 'video' && (
                <Button
                  onClick={openEditProductImageOnShopify}
                  icon={<ShopifyIconBase64 small={false} />}
                />
              )}
            </>
          ) : (
            <>
              {mediaSynced ? (
                <Button disabled>Synced</Button>
              ) : (
                <Tooltip content="Sync this media with shopify">
                  <Button
                    icon={RefreshMajor}
                    loading={syncingMedia}
                    onClick={() => syncMedia()}
                  ></Button>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}
      {!isMediaPicker &&
        (media.service_id === 'triplewhale' || media.service_id === 'shopify-pending') &&
        !isVersioned && (
          <div className="absolute top-[0.5rem] right-[0.5rem] z-10 flex gap-4 opacity-0 group-hover:opacity-100 transition-opacity">
            <DeleteMinor
              className="w-12 h-12 fill-dark-red cursor-pointer"
              onClick={() => deleteImage()}
            />
          </div>
        )}
      {!isMediaPicker && !product && isVersioned && (
        <div className="absolute top-[0.5rem] right-[0.5rem] z-10 flex gap-4 opacity-100">
          <div className="text-white bg-[#0a4267a6] rounded p-3">
            {mediaItem?.versions?.length} Versions
          </div>
        </div>
      )}
      <TWImage
        className={`w-full h-full object-cover rounded-md ${
          caption ? 'max-h-[95%]' : 'max-h-full'
        }`}
        wrapperClass="w-full h-full"
        src={mediaItem.media_type === 'video' ? mediaItem.thumbnail : mediaItem.url}
        caption={caption}
      />
      {(isMediaPicker || !!selectedMedia) && (
        <div className="absolute top-1 left-1 gallery-image-checkbox">
          <Checkbox
            checked={isSelected}
            id={mediaItem.id}
            onChange={() => {
              selectMedia(mediaItem, isSelected);
            }}
            label=""
            labelHidden
          />
        </div>
      )}
      <SelectProductModal
        open={openSelectProduct}
        onClose={setOpenSelectProduct}
        onSelect={(product) => {
          saveToMediaToShopify(editedImage, product.value, product.prodId);
        }}
        newImage={editedImage}
      />
      {confirmDeleteModal && (
        <Modal
          open={confirmDeleteModal}
          onClose={() => setConfirmDeleteModal(false)}
          title="Delete Media"
        >
          <div className="flex flex-col p-4 items-center gap-8">
            <span>
              {product
                ? `Are you sure you want to delete this media from "${product.title}" product on Shopify?`
                : 'Are you sure you want to delete this media from Creative Hub?'}
            </span>
            <div className="flex p-4 items-center gap-4">
              <Button primary onClick={() => confirmedDeleteImage()} loading={deleting}>
                Delete Media
              </Button>
              <Button onClick={() => setConfirmDeleteModal(false)}>Cancel</Button>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        open={openReconnectPopup}
        title="Reconnect Shopify"
        onClose={() => setOpenReconnectPopup(false)}
      >
        <AccountConnection
          accountName=""
          connected={false}
          action={{
            onAction: () => {
              connectToShopify(currentShopId, true);
            },
            content: 'Connect to Shopify',
          }}
          title={
            <div className="flex gap-6.5 items-center">
              <ShopifyIconBase64 small={true} />
              Please reconnect Shopify in order to use this feature
            </div>
          }
        />
      </Modal>
      <Modal
        open={openVersions}
        onClose={() => setOpenVersions(false)}
        title={'versions'}
        titleHidden
      >
        {mediaItem.versions && (
          <div className="p-12">
            <UploadMediaCards data={mediaItem.versions} />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CardThumbnail;
