import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Attaches a resize observer to an html element.  Exposes a callback that
 * can be used to return updated state or run an effect based on the resize
 * of the observed element.
 * */
export function useResizeObserver<T = any>(
  cb: (entries: ResizeObserverEntry[], ref?: HTMLElement | null) => T,
) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const resizeObserver = useMemo(
    () => new ResizeObserver((entries) => cb(entries, element)),
    [cb, element],
  );

  useEffect(() => {
    if (!element) return;

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, [resizeObserver, element]);

  return useCallback((el: HTMLElement | null) => {
    setElement(el);
  }, []);
}
