import { FC } from 'react';
import { ReactComponent as LockIcon } from 'icons/lock-rounded.svg';

interface Props {
  size?: number;
  borderColor?: string;
  className?: string;
}
const CircleLockIcon: FC<Props> = ({ size = 30, borderColor = '#9DC8FF', className }) => {
  return <LockIcon width={size} stroke={borderColor} className={className} />;
};

export default CircleLockIcon;
