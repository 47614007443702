import { Badge, Text } from '@tw/ui-components';

export const PromptTile = ({ prompt, onSuggestionClick, promptTopics, promptCategories }) => {
  // const promptTopics = useStoreValue($samplePromptTopics);
  const promptCategory = promptCategories.find((cat) => cat.id === prompt.category);
  const promptTopic = promptTopics.find((topic) => topic.id === promptCategory?.topic);

  const labelColor = promptTopic?.labelColor || 'yellow.1';
  const badgeColor = promptTopic?.badgeColor || 'yellow.1';
  return (
    <div
      className="border border-solid border-gray-200 rounded bg-white p-6 flex flex-col justify-between gap-4 cursor-pointer w-full h-full hover:bg-gray-50"
      onClick={() => onSuggestionClick(prompt)}
    >
      <Text lineClamp={2} fz={13} fw={500}>
        {prompt.prompt}
      </Text>
      <Badge radius="sm" uppercase={false} color={labelColor}>
        <Text fz={11} fw={500} color={badgeColor}>
          {promptCategory?.name}
        </Text>
      </Badge>
    </div>
  );
};
