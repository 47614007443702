import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@shopify/polaris';
import { SaveMinor } from '@shopify/polaris-icons';

import { CSVDownload } from 'utils/CSVExport';
import { RootState } from 'reducers/RootType';
import { insightsActions, genericEventLogger } from 'utils/dataLayer';
import { ShareActionsMenu } from './ShareActionsMenu';

const ViewActions: React.FC<{
  viewTitle: string;
  CSVData: string[][];
  isDisabled: boolean;
}> = ({ CSVData, isDisabled, viewTitle }) => {
  const eventContext = useSelector((state: RootState) => state.insights.eventContext);

  const downloadTable = useCallback(() => {
    const csvString = CSVData.map((row) => row.map((col) => `"${col}"`).join(',')).join('\n');
    CSVDownload(csvString, viewTitle);

    genericEventLogger(eventContext!, {
      action: insightsActions.DOWNLOAD_CSV,
    });
  }, [CSVData, viewTitle, eventContext]);

  return (
    <div className="flex flex-wrap gap-[16px]">
      <Button onClick={() => downloadTable()} disabled={isDisabled} icon={SaveMinor} />
      <ShareActionsMenu
        disabaled={isDisabled}
        tableCSVData={CSVData}
        viewTitle={viewTitle}
      ></ShareActionsMenu>
    </div>
  );
};

export default ViewActions;
