import React, { useState, useCallback } from 'react';
import { Modal } from '@shopify/polaris';
import LoomVideo from '.';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { ReactComponent as PlayIcon } from './play.svg';

interface TopBarWithVideoProps {
  videoUrl: string;
  title: string;
}

const InAppVideoBanner: React.FC<TopBarWithVideoProps> = ({ title, videoUrl }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isSmall = useIsSmall();

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  return (
    <>
      <div
        onClick={openModal}
        style={{
          background: 'var(--Primary, #0C70F2)',
          height: '48px',
          padding: '0 30px',
          borderRadius: '8px',
          margin: '30px 30px 0 30px',
          cursor: 'pointer',
        }}
        className="flex items-center justify-center"
      >
        <div className="flex items-center text-white">
          <span>Learn more about {title}</span>
          <PlayIcon className="ml-2 w-5 h-5" />
        </div>
      </div>
      {videoUrl ? (
        <Modal open={modalOpen} onClose={closeModal} large title={`Learn more about ${title}`}>
          <Modal.Section>
            <LoomVideo loomEmbed={videoUrl} mobile={isSmall} />
          </Modal.Section>
        </Modal>
      ) : (
        console.warn("No 'videoUrl' provided for video modal.")
      )}
    </>
  );
};

export default InAppVideoBanner;
