import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { getServicesToShowStatusColumn } from 'constants/attribution';
import { ALL_SOURCES_ID } from 'constants/types';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';

export const useEffectiveSelectedColumns = () => {
  const ffComputer = useFeatureFlagComputer();
  const channelId = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const { selectedColumns } = useSelector((state: RootState) => state.attribution);

  const effectiveSelectedColumns = useMemo(() => {
    const { blockList: ffBlockedColumns } = ffComputer.getConfigById(
      FeatureFlag.LIMIT_PIXEL_COLUMNS_FF,
    );

    return selectedColumns.filter((col) => {
      if (ffBlockedColumns.length && ffBlockedColumns.includes(col.key)) {
        return false;
      }
      if (col.key === 'status' && channelId) {
        return getServicesToShowStatusColumn().includes(channelId as any);
      }
      return (
        !col.showInSources ||
        col.showInSources.includes(channelId) ||
        (sourceCategory === ALL_SOURCES_ID && !col.isForSpecificChannel) ||
        (channelId === ALL_SOURCES_ID && !col.isForSpecificChannel)
      );
    });
  }, [channelId, selectedColumns, sourceCategory, ffComputer]);

  return effectiveSelectedColumns;
};
