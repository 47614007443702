import { BqColumn } from '../dataStuff/columns/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { $tabs } from '../../../$stores/willy/$tables';
import { FilterComparator } from '@tw/types/src/services/insights/filters/FilterComparator';
import { uniqBy } from 'lodash';
import { Flex, Icon, MultiSelect, Select, Text, TextInput, Tooltip } from '@tw/ui-components';
import DatePickerComponent from '../../../routes/allShopsPinnedSections/DatePickerComponent';
import moment from '@tw/moment-cached';
import { TIME_UNIT_OPTIONS } from '../../../components/Insights/Filters/constants';
import { getFilterQueryLabel } from '../../../components/Insights/Filters/queryTranslation';
import useDebounce from 'utils/useDebounce';
import { $currentShopId } from '$stores/$shop';
import { BqTable } from '../dataStuff/tables';
import { searchAds, shopifySegmentSearch } from 'ducks/serviceSegmentations';
import { useSelector } from 'react-redux';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { ServicesIds } from '@tw/types/module/services';
import { FilterGroup, FilterRow } from '@tw/willy-data-dictionary/module/columns/types';

const columnsCanFilter: Record<string, string> = {
  campaign_id: 'campaign',
  ad_set_id: 'adset',
  ad_id: 'ad',
  campaign_name: 'campaign',
  ad_set_name: 'adset',
  ad_name: 'ad',
  source_name: 'sources',
  tags: 'order_tags',
};

type FilterRowCompValueProps = {
  row: FilterRow;
  index: number;
  parentIndex: number;
  columns: BqColumn[];
  table: BqTable;
  filter: FilterGroup[];
  onFilterChanged: (filter: FilterGroup[]) => void;
  relatedProvider?: ServicesIds;
};

export const FilterRowCompValue: React.FC<FilterRowCompValueProps> = ({
  row,
  index,
  parentIndex,
  filter,
  table,
  columns,
  onFilterChanged,
  relatedProvider,
}) => {
  const integrations = useSelector(shopIntegrations);

  const completedRow = useMemo(() => {
    const columnFromData = columns.find((x) => x.id === row?.column?.id) ?? row.column;
    return { ...row, column: columnFromData };
  }, [row, columns]);

  const updateRow = useCallback(
    (index: number, parentIndex: number, updater: (oldRow: FilterRow) => FilterRow) => {
      const filterToSend = ((old) => {
        // Clone the outer array and filter each inner array
        const newFilter = old
          ? old.map((group, i) => {
              if (i === parentIndex) {
                return {
                  ...group,
                  filters: group.filters.map((filterRow, j) => {
                    if (j === index) {
                      return updater(filterRow);
                    }
                    return filterRow;
                  }),
                };
              }
              return group;
            })
          : [];

        // Return the updated state
        return newFilter;
      })(filter);

      onFilterChanged(filterToSend);
    },
    [filter, onFilterChanged],
  );

  const [filteredList, setFilteredList] = useState<{ label: string; value: string }[]>();
  const [isFetchingOptions, setIsFetchingOptions] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    setSearch('');
    setFilteredList([]);
  }, [completedRow.column.id]);

  useEffect(() => {
    const query = debouncedSearch?.toLowerCase();
    (async () => {
      if (!query || !query.length || !relatedProvider) return;
      setIsFetchingOptions(true);
      let options: any = [];
      const nameInQ = columnsCanFilter[completedRow.column.id];
      const shopId = $currentShopId.get();

      if (relatedProvider === 'shopify') {
        const searchRes = ((await shopifySegmentSearch(nameInQ, shopId, query)) || []).filter(
          (x) => x && !x.startsWith(' '),
        );
        let localRes: any[] = [];
        const finalRes = searchRes.concat(localRes);
        options = finalRes
          .filter((o) => !!o)
          .map((res) => ({
            label: res,
            value: res || '',
          }));
      } else {
        const res = await searchAds(query, relatedProvider, nameInQ, integrations);
        options = res.map((r) => ({ label: r.name, value: r.name }));
      }

      setFilteredList(options);
      setIsFetchingOptions(false);
    })();
  }, [completedRow.column.id, debouncedSearch, integrations, relatedProvider]);

  let currentQuery = $tabs.get().find((x) => x.active)?.query;
  if (!row.comparator || !row.column) return null;
  if (
    row.comparator === FilterComparator.IS_SET ||
    row.comparator === FilterComparator.IS_NOT_SET ||
    row.comparator === FilterComparator.OVER_ALL_TIME
  ) {
    return null;
  }
  let options = uniqBy(completedRow.column.options, (a) => a.value);
  if (row.column.getOptionsFunc) {
    options = row.column.getOptionsFunc(currentQuery ?? '');
  }

  if (options.length) {
    if (
      !completedRow.column.multiSelect ||
      [FilterComparator.EQUAL, FilterComparator.NOT_EQUAL].includes(row.comparator)
    ) {
      return (
        <Tooltip label={row.value ?? ''}>
          <Select
            size={'xs'}
            data={options}
            searchable={true}
            value={row.value}
            onChange={(value) => {
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value: value,
              }));
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <MultiSelect
          size={'xs'}
          data={uniqBy(completedRow.column.options, (a) => a.value)}
          placeholder={row.value?.length ? '' : 'Select...'}
          value={row.value}
          onChange={(value) => {
            updateRow(index, parentIndex, (oldRow) => ({
              ...oldRow,
              value: value,
            }));
          }}
        />
      );
    }
  }

  if (completedRow.column.id === 'event_date') {
    return (
      <DatePickerComponent
        className={'filter-date-picker-override'}
        dateRange={{
          start: row.value1 ? moment(row.value1) : undefined,
          end: row.value2 ? moment(row.value2) : undefined,
        }}
        onApply={({ start, end }) =>
          updateRow(index, parentIndex, (oldRow) => ({
            ...oldRow,
            value1: start!.format('YYYY-MM-DD'),
            value2: end!.format('YYYY-MM-DD'),
          }))
        }
      />
    );
  }

  if (completedRow.column.type === 'boolean') {
    return (
      <Tooltip label={row.value ?? ''}>
        <Select
          searchable={true}
          size={'xs'}
          data={[
            { label: 'True', value: 'true' },
            { label: 'False', value: 'false' },
          ]}
          value={String(row.value)}
          // TODO: Check this
          // withinPortal={true}
          onChange={(value) => {
            updateRow(index, parentIndex, (oldRow) => ({
              ...oldRow,
              value: value,
            }));
          }}
        />
      </Tooltip>
    );
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0.375rem',
      minHeight: '30px',
      minWidth: '100px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 6px',
      borderRadius: '7px',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
  };

  // if (completedRow.column.type === 'repeated string') {
  //   return (
  //     <PropertyAutoComplete
  //
  //       value={row.value}
  //       customStyle={customStyles}
  //       allowMultiple={true}
  //       property={completedRow.column.autoCompleteKey!}
  //       onChanged={(value) => (row.value = value)}
  //       fetchOptionsList={true}
  //     />
  //   );
  // }

  if (completedRow.column.type === 'string' || completedRow.column.type === 'repeated string') {
    if (columnsCanFilter[completedRow.column.id] && relatedProvider) {
      return (
        <Tooltip label={row.value ?? ''}>
          <Select
            leftSection={isFetchingOptions ? <Icon name={'loader-1'} /> : null}
            searchable={true}
            size={'xs'}
            data={(filteredList ?? []).concat(row.value ? [row.value] : [])}
            value={row.value}
            onChangeCapture={({ target }) => {
              let value: string = (target as any).value;
              setSearch(value);
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value: value,
              }));
            }}
            onChange={(value) => {
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value: value,
              }));
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip label={row.value ?? ''}>
          <TextInput
            label=""
            size={'xs'}
            type="text"
            placeholder={'value'}
            value={row.value ?? ''}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value: val,
              }))
            }
          />
        </Tooltip>
      );
    }
  }

  if (completedRow.column.type === 'numeric' || completedRow.column.type === 'formula') {
    return (
      <Tooltip label={row.value ?? ''}>
        <TextInput
          label=""
          size={'xs'}
          type="number"
          value={row.value ?? ''}
          onChange={(val) =>
            updateRow(index, parentIndex, (oldRow) => ({
              ...oldRow,
              value: val,
            }))
          }
        />
      </Tooltip>
    );
  }

  if (row.comparator === FilterComparator.BEFORE || row.comparator === FilterComparator.AFTER) {
    return (
      <Tooltip label={row.value ? moment(row.value).utc().format('MMMM D, Y') : ''}>
        <TextInput
          size={'xs'}
          type="date"
          value={!row.value ? '' : moment(row.value).utc().format('YYYY-MM-DD')}
          onChange={(val) =>
            updateRow(index, parentIndex, (oldRow) => ({
              ...oldRow,
              value: val,
            }))
          }
        />
      </Tooltip>
    );
  }

  if (row.comparator === FilterComparator.UNDER || row.comparator === FilterComparator.OVER) {
    return (
      <Flex align={'center'} gap={'xs'}>
        <Tooltip label={row.value ?? ''}>
          <TextInput
            size={'xs'}
            type="number"
            placeholder={'number'}
            value={row.value}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value: val,
              }))
            }
          />
        </Tooltip>
        <Tooltip label={row.unit ?? ''}>
          <Select
            searchable={true}
            size={'xs'}
            data={TIME_UNIT_OPTIONS.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
            value={row.unit}
            onChange={(value) => {
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                unit: value,
              }));
            }}
          />
        </Tooltip>
        <Text size={'xs'}>Ago</Text>
      </Flex>
    );
  }

  if (row.comparator === FilterComparator.BETWEEN) {
    return (
      <Flex align={'center'} gap={'xs'}>
        <Tooltip label={row.value1 ? moment(row.value1).utc().format('MMMM D, Y') : ''}>
          <TextInput
            size={'xs'}
            type="date"
            value={!row.value ? '' : moment(row.value).utc().format('YYYY-MM-DD')}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value1: val,
              }))
            }
          />
        </Tooltip>
        <Text size={'xs'}>And</Text>
        <Tooltip label={row.value2 ? moment(row.value2).utc().format('MMMM D, Y') : ''}>
          <TextInput
            size={'xs'}
            type="date"
            value={!row.value ? '' : moment(row.value).utc().format('YYYY-MM-DD')}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value2: val,
              }))
            }
          />
        </Tooltip>
      </Flex>
    );
  }

  if (row.comparator === FilterComparator.WITHIN) {
    return (
      <Flex align={'center'} gap={'xs'}>
        <Tooltip label={row.value1 ?? ''}>
          <TextInput
            size={'xs'}
            type="number"
            placeholder={'number'}
            // min={0}
            value={row.value1}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value1: val,
              }))
            }
          />
        </Tooltip>
        <Text size={'xs'}>And</Text>
        <Tooltip label={row.value2 ?? ''}>
          <TextInput
            size={'xs'}
            type="number"
            // min={row.value1}
            placeholder={'number'}
            value={row.value2}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                value2: val,
              }))
            }
          />
        </Tooltip>
        <Tooltip label={row.unit ?? ''}>
          <Select
            searchable={true}
            size={'xs'}
            data={TIME_UNIT_OPTIONS.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
            value={row.unit}
            onChange={(value) => {
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                unit: value,
              }));
            }}
          />
        </Tooltip>
        <Text size={'xs'}>Ago</Text>
      </Flex>
    );
  }

  return <div>not supported yet</div>;
};
