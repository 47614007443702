import { SET_ALL_SECTIONS, SET_CURRENT_SECTION, SET_SHOWING_MODAL } from 'ducks/constants';

export const toggleShowingModal = (data) => ({
  type: SET_SHOWING_MODAL,
  payload: data,
});

export const setAllSections = (data) => ({
  type: SET_ALL_SECTIONS,
  payload: data,
});

export const setCurrentSection = (data) => ({
  type: SET_CURRENT_SECTION,
  payload: data,
});
