import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/RootType';
import { Icon as PolarisIcon } from '@shopify/polaris';
import { Button, Card, Text, Icon, Flex } from '@tw/ui-components';
import { connectToShopify } from '../../utils/connectToShopify';
import { CancelMajor } from '@shopify/polaris-icons';

interface ShopifyReconnectBannerProps {
  scopes?: string[];
  bannerText?: string;
  buttonText?: string;
  className?: string;
  isCritical?: boolean;
}

export const ShopifyReconnectBanner: React.FC<ShopifyReconnectBannerProps> = ({
  scopes = ['read_customers'],
  className = '',
  buttonText = 'Reconnect To Shopify',
  bannerText = 'Gain access to deeper customer reporting by giving Triple Whale access to your Shopify customer data - please reconnect to the Shopify app',
  isCritical = false,
}) => {
  const shopifyScopes = useSelector((state: RootState) => state.shopifyScopesFromApi);
  const shopId = useSelector((state: RootState) => state.currentShopId);
  const [isClosedFromSessionStorage, setIsClosedFromSessionStorage] = useState(
    sessionStorage.getItem('isClosedFromSessionStorage') === 'true',
  );
  const needsPermission = () => {
    if (!shopifyScopes) return false;
    return !scopes.every((y) => shopifyScopes.includes(y));
  };
  const closeBanner = useCallback(() => {
    sessionStorage.setItem('isClosedFromSessionStorage', 'true');
    setIsClosedFromSessionStorage(true);
  }, []);

  return needsPermission() && (isCritical || !isClosedFromSessionStorage) ? (
    <div className={className}>
      <Card bg={isCritical ? 'red.1' : 'one.0'}>
        {!isCritical && (
          <div
            className="flex justify-end pt-2 pr-2 absolute right-0 top-0 cursor-pointer"
            onClick={closeBanner}
          >
            <PolarisIcon source={CancelMajor} color="base" />
          </div>
        )}
        <Flex justify="space-between" align="center">
          <Flex gap="md">
            <Icon name="shopify" />
            <Text>{bannerText}</Text>
          </Flex>
          <Button
            {...(isCritical ? { bg: 'red.4', color: 'red.5' } : {})}
            onClick={() => connectToShopify(shopId, false)}
          >
            {buttonText}
          </Button>
        </Flex>
      </Card>
    </div>
  ) : null;
};
