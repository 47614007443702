export const WarningIcon = () => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666504 13.0002L7.99984 0.333496L15.3332 13.0002H0.666504ZM2.39984 12.0002H13.5998L7.99984 2.3335L2.39984 12.0002ZM8.0665 11.0502C8.21095 11.0502 8.33039 11.0029 8.42484 10.9085C8.51928 10.8141 8.5665 10.6946 8.5665 10.5502C8.5665 10.4057 8.51928 10.2863 8.42484 10.1918C8.33039 10.0974 8.21095 10.0502 8.0665 10.0502C7.92206 10.0502 7.80262 10.0974 7.70817 10.1918C7.61373 10.2863 7.5665 10.4057 7.5665 10.5502C7.5665 10.6946 7.61373 10.8141 7.70817 10.9085C7.80262 11.0029 7.92206 11.0502 8.0665 11.0502ZM7.5665 9.20016H8.5665V5.46683H7.5665V9.20016Z"
        fill="#F00044"
      />
    </svg>
  );
};
