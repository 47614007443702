import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { continents, countries } from 'countries-list';
import { Autocomplete } from '@shopify/polaris';
import { Button, Checkbox, Collapse, Flex, Icon, Text, TextInput } from '@tw/ui-components';
import { RadioButton } from '@shopify/polaris';
import { useAppDispatch } from 'index';
import { Zone, saveShippingProfileData } from 'ducks/shipping';

const usTerritories = [
  { code: 'AS', name: 'American Samoa' },
  { code: 'FM', name: 'Federated States of Micronesia' },
  { code: 'GU', name: 'Guam' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'PW', name: 'Palau' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'UM', name: 'US Minor Outlying Islands' },
  { code: 'VI', name: 'US Virgin Islands' },
];

const usStates = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District Of Columbia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
  ...usTerritories,
];
const SearchBar = ({ zones, setZones }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const countryList = Object.keys(countries)
    .filter((c) => !usTerritories.map((x) => x.code).includes(c))
    .map((c) => {
      return {
        label: countries[c].name,
        value: c,
        obj: { name: countries[c].name, code: c, region: countries[c].continent },
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <div className="pb-5">
      <Autocomplete
        id="autocompl"
        options={
          searchTerm === ''
            ? countryList
            : countryList.filter((option) => option.label.match(new RegExp(searchTerm, 'i')))
        }
        preferredPosition="below"
        selected={[]}
        onSelect={(updatedSelection) => {
          const selectedText = updatedSelection.map((selectedItem) => {
            const matchedOption = countryList.filter((option) => {
              return option.value.match(selectedItem);
            });
            return matchedOption[0];
          });
          setSearchTerm(selectedText[0].label);
          if (!zones.some((z) => z.code === selectedText[0].value)) {
            setZones([...zones, selectedText[0].obj]);
          }
        }}
        textField={
          <Autocomplete.TextField
            autoComplete="off"
            onChange={setSearchTerm}
            label={''}
            value={searchTerm}
            prefix={<Icon name="search-major" />}
            placeholder="Search countries"
            clearButton
            onClearButtonClick={async () => {
              setSearchTerm('');
            }}
          />
        }
      />
    </div>
  );
};
const USRow = ({ zones, setZones, states, setStates }) => {
  const [openStatesRow, setOpenStatesRow] = useState<boolean>(false);
  return (
    <>
      <div className="flex gap-5 align-items-center pb-5 pl-10">
        <Checkbox
          size={'xs'}
          checked={zones.some((c) => c.code === 'US')}
          onChange={(checked) => {
            if (checked) {
              setZones([...zones, { name: 'United States', code: 'US', region: 'NA' }]);
              setStates(usStates);
            } else {
              setZones(zones.filter((z) => z.code !== 'US'));
              setStates([]);
            }
          }}
        />
        <div
          className="flex  align-items-center gap-5"
          onClick={() => setOpenStatesRow(!openStatesRow)}
        >
          <Text>United States and Territories</Text>
          <Icon name={openStatesRow ? 'caret-down' : 'chevron-right-minor'} />
        </div>
      </div>
      <Collapse in={openStatesRow}>
        {usStates.map(({ code, name }) => {
          return (
            <div className="flex gap-5 align-items-center pb-5 pl-20" key={code}>
              <Checkbox
                onChange={(checked) => {
                  if (checked) {
                    if (states.length === 0) {
                      setZones([...zones, { name: 'United States', code: 'US', region: 'NA' }]);
                    }
                    setStates([...states, { code, name }]);
                  } else {
                    if (states.length === 1) {
                      setZones(zones.filter((z) => z.code !== 'US'));
                      setStates([]);
                    } else setStates(states.filter((s) => s.code !== code));
                  }
                }}
                checked={states?.some((s) => s.code === code)}
                size={'xs'}
              />
              <Text> {name} </Text>
            </div>
          );
        })}
      </Collapse>
    </>
  );
};

const RegionRow: React.FC<any> = ({
  region,
  rowOpen,
  setRowIsOpen,
  zones,
  setZones,
  states,
  setStates,
  countriesByRegion,
}: any) => {
  const handleZoneChange = useCallback(
    (checked) => {
      if (checked) {
        if (region === 'NA') setStates(usStates);
        setZones([...zones, ...countriesByRegion[region]]);
      } else {
        if (region === 'NA') setStates([]);
        setZones(zones.filter((z) => z.region !== region));
      }
    },
    [countriesByRegion, region, setStates, setZones, zones],
  );
  const handleCountryChange = useCallback(
    (checked, country) => {
      if (checked) {
        setZones([...zones, country]);
      } else {
        setZones(zones.filter((z) => z.code !== country.code));
      }
    },
    [setZones, zones],
  );
  return (
    <div className="flex flex-col gap-5">
      <div className="flex align-items-center">
        <Checkbox
          label={continents[region]}
          checked={zones.some((z) => z.region === region)}
          onChange={handleZoneChange}
        />
        <div className="flex ml-3" onClick={setRowIsOpen}>
          <Icon name={rowOpen ? 'caret-down' : 'chevron-right-minor'} />
        </div>
      </div>
      <Collapse in={rowOpen}>
        {countriesByRegion[region]?.map((country: any) => {
          return country.code === 'US' ? (
            <USRow
              key={'US'}
              zones={zones}
              setZones={setZones}
              states={states}
              setStates={setStates}
            />
          ) : (
            <div className="flex gap-5 align-items-center pb-5 pl-10" key={country.code}>
              <Checkbox
                onChange={(val) => {
                  handleCountryChange(val, country);
                }}
                checked={zones.some((z) => z.code === country.code)}
                size={'xs'}
              />
              <Text> {country.name} </Text>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
};

export const Zones: React.FC<any> = ({ setPage }) => {
  const profile = useSelector((state: RootState) => state.shippingProfileData);
  const shippingProfiles = useSelector((state: RootState) => state.shippingProfiles);
  const [openRows, setOpenRows] = useState<any[]>([]);
  const [zoneName, setZoneName] = useState<string>(profile?.name ?? '');
  const [worldwide, setWorldwide] = useState<boolean>(profile?.worldwide ?? false);
  const [zones, setZones] = useState<Zone[]>(profile?.zones ?? []);
  const [states, setStates] = useState<any[]>(
    profile?.zones?.find((z) => z.code === 'US')?.states ?? [],
  );
  const dispatch = useAppDispatch();
  const sortedContinentArr = ['NA', 'EU', 'AS', 'SA', 'OC', 'AF', 'AN'];
  const checkForDuplicate = useMemo(() => {
    let zoneArray = shippingProfiles
      .filter((p) => p.id !== profile?.id)
      .map((p) => p.zones.map((pz) => pz.name))
      .flat()
      .filter((z) => z !== 'United States' && zones.map((z) => z.name).includes(z));
    let statesArray = shippingProfiles
      .filter((p) => p.id !== profile?.id && p.zones.some((z) => z.code === 'US'))
      .map((p) => p.zones.map((pz) => pz?.states?.map((s) => s.name)))
      .flat(2)
      .filter((s) => states.map((s) => s.name).includes(s));
    return [...zoneArray, ...statesArray];
  }, [profile, shippingProfiles, states, zones]);

  const disabled =
    (!worldwide && !zones.length) ||
    !zoneName ||
    shippingProfiles.some((p) => p.name === zoneName && p.id !== profile?.id) ||
    (worldwide && shippingProfiles.some((p) => p.worldwide && p.id !== profile?.id)) ||
    checkForDuplicate.length > 0;

  const errorMessage = !zoneName
    ? 'Please enter profile name'
    : worldwide && shippingProfiles.some((p) => p.worldwide && p.id !== profile?.id)
      ? 'Please edit the existing worldwide profile'
      : worldwide === false && !zones.length
        ? 'Please select countries/regions'
        : checkForDuplicate.length > 0
          ? `Some of your selected countries/states are included in an existing profile: ${checkForDuplicate.join(
              ', ',
            )}`
          : '';

  useEffect(() => {
    setZoneName(profile?.name ?? '');
    setWorldwide(profile?.worldwide ?? false);
    setZones(profile?.zones ?? []);
    setStates(profile?.zones?.find((z) => z.code === 'US')?.states ?? []);
  }, [profile]);

  const toggleOpen = useCallback(
    (index) => {
      if (openRows.includes(index)) {
        setOpenRows(openRows.filter((i) => i !== index));
      } else {
        setOpenRows([...openRows, index]);
      }
    },
    [openRows],
  );
  const countriesByRegion = useMemo(() => {
    let regions = Object.keys(continents).reduce((acc: any, c: any) => {
      acc[c] = Object.keys(countries)
        .filter(
          (country: any) =>
            countries[country].continent === c &&
            !['US', 'PS', ...usTerritories.map((x) => x.code)].includes(country),
        )
        .map((country: any) => {
          return { name: countries[country].name, code: country, region: c };
        })
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
      return acc;
    }, {});
    regions['NA'].unshift({
      name: 'United States',
      code: 'US',
      region: 'NA',
      states: usStates,
    });
    return regions;
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5 pb-5">
        <TextInput
          label="Profile Name"
          placeholder="Please enter profile name"
          required
          value={zoneName}
          onChange={setZoneName}
        />

        <RadioButton
          label="Worldwide"
          name="countries"
          checked={worldwide}
          onChange={() => {
            setWorldwide(true);
            setZones([]);
            setStates([]);
          }}
        />
        <RadioButton
          label="Specific countries/regions"
          name="countries"
          checked={!worldwide}
          onChange={() => setWorldwide(false)}
        />

        {!worldwide && (
          <>
            <SearchBar zones={zones} setZones={setZones} />
            {sortedContinentArr.map((c, index) => (
              <RegionRow
                region={c}
                rowOpen={openRows.includes(index)}
                setRowIsOpen={() => toggleOpen(index)}
                zones={zones}
                setZones={setZones}
                key={c}
                states={states}
                setStates={setStates}
                countriesByRegion={countriesByRegion}
              />
            ))}
          </>
        )}
      </div>
      <Flex justify={disabled ? 'space-between' : 'end'}>
        <div className="flex flex-wrap overflow-auto">
          {disabled && <Text color="red.5">{errorMessage}</Text>}
        </div>
        <Button
          onClick={() => {
            setPage('rates');
            let tempZones = [...zones];
            let US = tempZones.find((z) => z.code === 'US');
            if (US) {
              US.states = states;
            }
            dispatch(
              saveShippingProfileData({ ...profile, name: zoneName, worldwide, zones: tempZones }),
            );
          }}
          disabled={disabled}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};
