import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { dismissMonitoringIncident, getMonitoringIncidents } from 'ducks/monitoringIncidents';
import { useAppDispatch } from 'index';
import TWAlert from 'components/library/TWAlert/TWAlert';
import './index.scss';

export default function MonitoringIncidents() {
  const dispatch = useAppDispatch();
  const monitoringIncidents = useSelector((state: RootState) => state.monitoringIncidents);
  const dismissedMonitoringIncidents = useSelector(
    (state: RootState) => state.dismissedMonitoringIncidents,
  );

  useEffect(() => {
    dispatch(getMonitoringIncidents());
  }, [dispatch]);

  return monitoringIncidents.length ? (
    <div className="monitoring-incidents-wrapper">
      {monitoringIncidents.map((incident) => {
        return (
          <div
            key={incident.id}
            className={`monitoring-incident ${
              dismissedMonitoringIncidents.includes(incident.id)
                ? 'monitoring-incident-dismissed'
                : ''
            }`}
          >
            <TWAlert
              type={incident.level}
              message={incident.message}
              title={incident.title}
              dismissable
              onDismiss={() => dispatch(dismissMonitoringIncident(incident.id))}
              accent
            />
          </div>
        );
      })}
    </div>
  ) : null;
}
