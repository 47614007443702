import { $observer } from '@tw/snipestate';
import { LS_COLOR_SCHEME, SYSTEM_PREFERS_DARK } from 'dark-mode-control/constants';
import { $preferedColorScheme } from './$preferedColorScheme';

/**
 * Track current user preference. Doesn't reflect actual dark mode, since
 * some pages don't have it - just what the user is trying to do. This store
 * is a type of derived store.  It shouldn't be set directly.  `$preferedColorScheme`
 * store from `dark-mode-control` can be set, and it will affect this store.
 */
export const $prefersDarkMode = $observer<boolean>(
  localStorage.getItem(LS_COLOR_SCHEME) === 'dark',
  (get, set) => {
    const colorScheme = get($preferedColorScheme);
    if (colorScheme !== 'auto') {
      set(colorScheme === 'dark');
      return;
    }

    const mediaQueryList = window.matchMedia(SYSTEM_PREFERS_DARK);
    const handleOSPrefChange = () => set(mediaQueryList.matches);

    // set scheme acc to OS pref
    handleOSPrefChange();

    // continue listening to changes
    mediaQueryList.addEventListener('change', handleOSPrefChange);

    // if user was on auto and changed to light or dark,
    // we "cleanup" and stop listening to the OS settings
    return () => {
      mediaQueryList.removeEventListener('change', handleOSPrefChange);
    };
  },
);
