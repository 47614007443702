import { CurrentDateRange, pushDateToQS } from '$stores/willy/$dateRange';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePathForDateRange = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathsWithSpecificDateRange = getPathsWithSpecificDateRange();
    const pathWithSpecificDateRange = pathsWithSpecificDateRange.find(({ route }) =>
      location.pathname.includes(route),
    );
    if (pathWithSpecificDateRange) {
      const { currentDateRange, prevDateRange } = pathWithSpecificDateRange;
      const { start, end } = currentDateRange || prevDateRange || {};
      const prevIsNone = prevDateRange && !prevDateRange.start && !prevDateRange.end;
      pushDateToQS(navigate, start, end, !!currentDateRange, prevIsNone);
    }
  }, [location.pathname, navigate]);
};

type pathWithSpecificDateRange = {
  route: string;
  currentDateRange?: CurrentDateRange;
  prevDateRange?: {
    start?: moment.Moment;
    end?: moment.Moment;
  };
};

const getPathsWithSpecificDateRange = (): pathWithSpecificDateRange[] => [
  {
    route: '/insights/cohorts',
    currentDateRange: {
      start: moment().subtract(1, 'year').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
    },
  },
  {
    route: '/product-analytics',
    currentDateRange: {
      start: moment().subtract(7, 'days').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
    },
  },
  {
    //mainDatePickerSelectionRange?.id === 'today'
    route: '/insights/cart-analysis',
    currentDateRange: {
      start: moment().subtract(1, 'year').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
    },
  },
  {
    route: '/cdp',
    currentDateRange: {
      start: moment().subtract(90, 'days').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
    },
  },
  {
    route: '/attribution/',
    prevDateRange: {
      start: undefined,
      end: undefined,
    },
  },
];
