import React, { useState } from 'react';
import { Button } from '@shopify/polaris';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as CircleTick } from 'icons/circle-tick.svg';
import { ReactComponent as CircleCancel } from 'icons/circle-cancel.svg';
import { ReactComponent as CircleInfo } from 'icons/circle-info-filled.svg';
import { ReactComponent as Risk } from 'icons/risk.svg';
import { FormattedColor, Text } from '@tw/ui-components';

export type TWAlertType = 'success' | 'error' | 'info' | 'warning';

type TWAlertAction = {
  label: string;
  onClick: () => void;
};

type TWAlertProps = {
  type: TWAlertType;
  title: string;
  message?: string | JSX.Element;
  bulletList?: string[];
  actions?: TWAlertAction[];
  dismissable?: boolean;
  onDismiss?: () => void;
  accent?: boolean;
};

const accentColor = {
  success: '#34D399',
  error: '#F87272',
  info: '#60A5FA',
  warning: '#FBBF24',
};

const textColor = {
  success: '#1C5F46' as FormattedColor,
  error: '#991B1B' as FormattedColor,
  info: '#2440AF' as FormattedColor,
  warning: '#92400E' as FormattedColor,
};

const bgColor = {
  success: '#ECFDF5',
  error: '#FDF2F2',
  info: '#EFF7FF',
  warning: '#FEFBEB',
};

const iconComponents = {
  success: CircleTick,
  error: CircleCancel,
  info: CircleInfo,
  warning: Risk,
};

const TWAlertIcon = ({ type }) => {
  return iconComponents[type]({
    style: {
      color: accentColor[type],
      fill: accentColor[type],
      width: '20px',
      height: '20px',
      marginTop: '2px',
    },
    alt: `${type} icon`,
  });
};

export default function TWAlert({
  type,
  message,
  title,
  actions,
  dismissable,
  onDismiss,
  accent,
}: TWAlertProps) {
  const [dismissed, setDismissed] = useState<boolean>(false);
  // TODO: drop tailwind, extract styles into css file
  return (
    <div
      style={{
        color: textColor[type],
        backgroundColor: bgColor[type],
        flexDirection: 'row',
        alignItems: 'start',
        gap: '.5rem',
        padding: '.5rem',
        paddingLeft: '.5rem',
        display: dismissed ? 'none' : 'flex',
        borderLeftColor: accent ? accentColor[type] : '',
        borderLeftWidth: accent ? '4px' : '0px',
        borderRadius: '6px',
        borderTopLeftRadius: accent ? '0px' : '6px',
        borderBottomLeftRadius: accent ? '0px' : '6px',
        borderLeftStyle: accent ? 'solid' : 'none',
      }}
    >
      <TWAlertIcon type={type} />
      <div className="flex-1">
        <Text c={textColor[type]} weight="bold" pl="xs" size="md">
          {title}
        </Text>
        <Text c={textColor[type]} pl="xs" size="sm">
          {message}
        </Text>
        {actions && (
          <div className="flex gap-3 pt-6">
            {actions.map((action) => (
              <p
                className={`font-bold cursor-pointer py-2 px-3 hover:bg-[${accentColor[type]}]`}
                onClick={action.onClick}
              >
                {action.label}
              </p>
            ))}
          </div>
        )}
      </div>
      {(dismissable || onDismiss) && (
        <div className="self-center">
          <button
            onClick={() => (onDismiss ? onDismiss() : setDismissed(true))}
            className={`border-none cursor-pointer bg-transparent color-[${accentColor[type]}]`}
          >
            <CloseIcon
              style={{
                fill: accentColor[type],
                stroke: accentColor[type],
                strokeWidth: '0.5px',
                width: '20px',
                height: '20px',
              }}
            />
          </button>
        </div>
      )}
    </div>
  );
}
