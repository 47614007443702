import { $categoriesList } from './stores';
import { $store, useStoreValue } from '@tw/snipestate';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  Text,
  TextInput,
} from '@tw/ui-components';
import { isDefined } from 'utils/isDefined';
import { emptyArray } from '@tw/stats/module/generalUtils';
import { useMemo } from 'react';
import type { CategoryListItem } from './types';
import { WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { initCategoryCreatorModal, moveDashboardToCategory } from './api';

type ChangeFolderModalStore = {
  opened: boolean;
  loading: boolean;
  newCategoryId?: CategoryListItem['id'];
  search: string;
  dashboard?: WillyDashboardElement;
};

const $changeFolderModal = $store<ChangeFolderModalStore>({
  opened: false,
  loading: false,
  newCategoryId: '',
  search: '',
});

export function updateChangeFolderModal(args: Partial<ChangeFolderModalStore>) {
  $changeFolderModal.set((x) => ({ ...x, ...args }));
}

export function resetChangeFolderModal() {
  $changeFolderModal.set({
    loading: false,
    opened: false,
    newCategoryId: '',
    search: '',
  });
}

export function ChangeCategoryModal() {
  const modal = useStoreValue($changeFolderModal);
  const { list: categoriesList } = useStoreValue($categoriesList);

  const filteredGroups = useMemo(() => {
    if (!isDefined(modal.search)) return emptyArray as CategoryListItem[];
    return categoriesList.filter(
      (c) => c.type === 'folder' && c.value.toLowerCase().includes(modal.search.toLowerCase()),
    );
  }, [modal.search, categoriesList]);

  return (
    <Modal
      opened={modal.opened}
      onClose={() => updateChangeFolderModal({ opened: false })}
      title={
        <Text fz="xl" fw={500} ta="center">
          Move Dashboard {modal.dashboard?.name}
        </Text>
      }
    >
      <Box>
        <TextInput
          placeholder="Search for a folder"
          leadingIcon={<Icon name="search-major" size={14} />}
          mb="md"
          value={modal.search}
          onChange={(val) => updateChangeFolderModal({ search: val })}
        />
        <Text fz="sm" tt="uppercase" fw={500} c="gray.6">
          Folders
        </Text>
        <Box mah={195} overflow="scroll" p="sm">
          {filteredGroups.map((g) => {
            const selected = g.id === modal.newCategoryId;

            return (
              <Flex
                key={'group-' + g.id}
                gap="sm"
                cursor="pointer"
                onClick={() => {
                  updateChangeFolderModal({ newCategoryId: g.id });
                }}
              >
                <ActionIcon
                  size="sm"
                  radius="round"
                  variant="transparent"
                  icon={selected ? 'checkmark' : 'group-2'}
                  iconColor={selected ? 'one.6' : undefined}
                />
                <Text fz="sm" fw={500} mb="sm">
                  {g.value}
                </Text>
              </Flex>
            );
          })}
        </Box>
        <Box
          borderTop="1px solid var(--mantine-color-gray-3)"
          borderBottom="1px solid var(--mantine-color-gray-3)"
          py="sm"
          mb="md"
        >
          <Anchor
            as="button"
            onClick={initCategoryCreatorModal}
            leftIcon={<Icon name="union" color="one.5" size={12} />}
            textProps={{ c: 'one.5', fz: 'sm' }}
          >
            Create new folder
          </Anchor>
        </Box>
      </Box>
      <Flex gap="xs" justify="flex-start" direction="row-reverse">
        <Button
          disabled={modal.loading}
          loading={modal.loading}
          onClick={() => {
            const dashboardId = modal.dashboard?.id;
            const newCategoryId = modal.newCategoryId;
            if (!dashboardId || !newCategoryId) return;
            moveDashboardToCategory({ dashboardId, newCategoryId });
          }}
        >
          Confirm
        </Button>
        <Button
          disabled={modal.loading}
          variant="white"
          onClick={() => updateChangeFolderModal({ opened: false })}
        >
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
