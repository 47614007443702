import React, { useEffect } from 'react';
import { useAppSelector } from '../../../../reducers/RootType';
import { useAppDispatch } from '../../../../index';
import { setOnboardingStatus } from '../../../../ducks/shop';
import { StepCompletionInfo } from 'types/WizardData';
import { cx } from 'utils/cx';

export type UsePixelInstalledConnectionInfoArgs = {
  isFromPod?: boolean;
  isStepCompleted?: boolean;
  isPixelInstalled?: boolean;
  isPixelAutoInstall?: boolean;
  shopDomain?: string;
};

export function useShopifyPixelInstalledConnectionInfo({
  isFromPod,
  isPixelInstalled,
  isPixelAutoInstall,
  isStepCompleted,
}: UsePixelInstalledConnectionInfoArgs): StepCompletionInfo {
  const pixelInstallStatus = useAppSelector((state) => state.pixelInstallStatus);
  const pixelSettings = useAppSelector((state) => state.pixelSettings);
  const welcomeWizardData = useAppSelector((state) => state.welcomeWizard.welcomeWizardData);

  const _isPixelInstalled = isFromPod ? isPixelInstalled : pixelInstallStatus?.status;

  const _isPixelAutoInstalled = isFromPod ? isPixelAutoInstall : pixelSettings?.allow_auto_install;

  const _isStepCompleted = isFromPod
    ? isStepCompleted
    : welcomeWizardData?.steps?.pixel?.isCompleted;

  const status = (() => {
    if (_isPixelAutoInstalled || _isPixelInstalled) return 'complete';
    if (_isStepCompleted) return 'in-progress';
    return 'not-started';
  })();

  const text = (() => {
    if (status === 'complete') return 'Pixel Installed';
    if (status === 'in-progress') return 'Custom Installation';
    return 'Not Installed';
  })();

  return {
    status,
    text,
  };
}

export const ShopifyPixelInstallationIndication: React.FC<any> = (props) => {
  const welcomeWizardData = useAppSelector((state) => state.welcomeWizard.welcomeWizardData);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const dispatch = useAppDispatch();
  const { status, text } = useShopifyPixelInstalledConnectionInfo({
    isFromPod: props.isFromPod,
    isStepCompleted: props.isStepCompleted,
    isPixelInstalled: props.isPixelInstalled,
    isPixelAutoInstall: props.isPixelAutoInstall,
    shopDomain: props.shopDomain,
  });

  const shopId = props.isFromPod ? props.shopDomain : currentShopId;

  useEffect(() => {
    dispatch(setOnboardingStatus(shopId, 'pixel', status === 'complete'));
  }, [dispatch, welcomeWizardData, shopId, status]);

  return (
    <div>
      <span
        className={cx({
          'text-green': status === 'complete',
          'text-orange-400': status === 'in-progress',
          'text-red-400': status === 'not-started',
        })}
      >
        {text}
      </span>
    </div>
  );
};
