export default function PostscriptIconBase64({ small }) {
  return (
    <img
      style={{ height: small ? 15 : 15 }}
      alt="Postscript icon"
      src="
    data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAxIiBoZWlnaHQ9IjMwMyIgdmlld0JveD0iMCAwIDQwMSAzMDMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMjIuMzEyIDMwM0gwVjE1MS45OTRDMCA1My40MzQ0IDg5LjM1NzMgMCAxNzMuMjQyIDBIMjM0LjE5M0MzMjguMTk1IDAgNDAxIDY2LjM2NTYgNDAxIDE0OC4yMjdDNDAxIDI1OS40NzggMzA3LjcxOCAzMDMgMjIyLjMxMiAzMDNaIiBmaWxsPSIjNTcyNUVBIi8+CjxwYXRoIGQ9Ik0xNDYuODM4IDE3MC4yNDJDMTYzLjM4MSAxNzAuMjQyIDE3Ni43OTIgMTQyLjA4NCAxNzYuNzkyIDEwNy4zNUMxNzYuNzkyIDcyLjYxNTQgMTYzLjM4MSA0NC40NTc1IDE0Ni44MzggNDQuNDU3NUMxMzAuMjk0IDQ0LjQ1NzUgMTE2Ljg4MyA3Mi42MTU0IDExNi44ODMgMTA3LjM1QzExNi44ODMgMTQyLjA4NCAxMzAuMjk0IDE3MC4yNDIgMTQ2LjgzOCAxNzAuMjQyWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTI1My4zNjEgMTcwLjI0MkMyNjkuOTA1IDE3MC4yNDIgMjgzLjMxNiAxNDIuMDg0IDI4My4zMTYgMTA3LjM1QzI4My4zMTYgNzIuNjE1NCAyNjkuOTA1IDQ0LjQ1NzUgMjUzLjM2MSA0NC40NTc1QzIzNi44MTggNDQuNDU3NSAyMjMuNDA3IDcyLjYxNTQgMjIzLjQwNyAxMDcuMzVDMjIzLjQwNyAxNDIuMDg0IDIzNi44MTggMTcwLjI0MiAyNTMuMzYxIDE3MC4yNDJaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K
    "
    />
  );
}
