import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { callInitialImport } from 'utils/callInitialImport';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import axiosInstance from 'utils/axiosInstance';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { setServiceAccountInfo } from '../utils/setServiceAccountInfo';
import { Action } from 'redux';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;

export const PINTEREST_FETCHING_ACCOUNTS = 'PINTEREST_FETCHING_ACCOUNTS';
export const PINTEREST_RECEIVED_ACCOUNTS = 'PINTEREST_RECEIVED_ACCOUNTS';
const PINTEREST_CONNECT_STARTED = 'PINTEREST_CONNECT_STARTED';
const PINTEREST_CONNECT_DONE = 'PINTEREST_CONNECT_DONE';
const PINTEREST_DISCONNECT_STARTED = 'PINTEREST_DISCONNECT_STARTED';
const PINTEREST_ADS_CONFIG_SCREEN_OPEN = 'PINTEREST_ADS_CONFIG_SCREEN_OPEN';
export const PINTEREST_ACCOUNTS_IDS_ON_CHANGE = 'PINTEREST_ACCOUNTS_IDS_ON_CHANGE';
export const pinterestAccountsSelectOnChange = (value) => ({
  type: PINTEREST_ACCOUNTS_IDS_ON_CHANGE,
  value,
});

export const TOGGLE_PINTEREST_ACCOUNTS_CONFIG_SCREEN_OPEN =
  'TOGGLE_PINTEREST_ACCOUNTS_CONFIG_SCREEN_OPEN';
export const togglePinterestAccountsConfigScreenOpen = () => ({
  type: TOGGLE_PINTEREST_ACCOUNTS_CONFIG_SCREEN_OPEN,
});

export function pinterestAccountsConfigSavePress() {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    dispatch({ type: TOGGLE_PINTEREST_ACCOUNTS_CONFIG_SCREEN_OPEN });
    var { pinterestRemoteAccounts, currentShopId } = getState();
    await setServiceAccountInfo(currentShopId, 'pinterest-ads', {
      pinterestAccounts: pinterestRemoteAccounts.filter((acc) => acc.selected),
    });
    dispatch(pinterestAccountsSaveSuccess());
    dispatch(setOptimisticImporting('pinterest-ads'));
    window.scrollTo(0, 0);
  };
}

export const startImportingPinterestData = () => async (dispatch) => {
  await callInitialImport('pinterest-ads');
  dispatch(setOptimisticImporting('pinterest-ads'));
  window.scrollTo(0, 0);
};

export const PINTEREST_ACCOUNTS_CONFIG_SAVE_SUCCESS = 'PINTEREST_ACCOUNTS_CONFIG_SAVE_SUCCESS';
export const pinterestAccountsSaveSuccess = () => {
  return {
    type: PINTEREST_ACCOUNTS_CONFIG_SAVE_SUCCESS,
  };
};

export const pinterestConnectOnPress = (dispatch?: (action: Action) => void) => {
  try {
    if (dispatch) {
      dispatch({
        type: PINTEREST_CONNECT_STARTED,
      });
    }
    const url = `v2/pinterest-ads/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(` ERROR `, e);
    if (dispatch) {
      dispatch({
        type: PINTEREST_CONNECT_DONE,
      });
    }
  }
};

export function pinterestDisconnect() {
  return (dispatch, getState) => {
    dispatch({
      type: PINTEREST_DISCONNECT_STARTED,
    });
    toggleSaveIndicator();
    const url = `v2/pinterest-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('pinterestToken'));
        dispatch(pinterestDisconnectDone());
      });
  };
}

export const PINTEREST_DISCONNECT_DONE = 'PINTEREST_DISCONNECT_DONE';

export function pinterestDisconnectDone() {
  return {
    type: PINTEREST_DISCONNECT_DONE,
  };
}

const pinterestRemoteAccounts = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.pinterestAccounts || state;
    case PINTEREST_RECEIVED_ACCOUNTS:
      return action.accounts || state;
    case PINTEREST_ACCOUNTS_IDS_ON_CHANGE:
      return action.value;
    default:
      return state;
  }
};

const isPinterestConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.pinterestToken;
    case PINTEREST_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'pinterest-ads' || state;
    default:
      return state;
  }
};

const pinterestConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case PINTEREST_RECEIVED_ACCOUNTS:
      return true;
    case TOGGLE_PINTEREST_ACCOUNTS_CONFIG_SCREEN_OPEN:
      return !state;
    case PINTEREST_ACCOUNTS_CONFIG_SAVE_SUCCESS:
      return false;
    case PINTEREST_ADS_CONFIG_SCREEN_OPEN:
      return true;
    default:
      return state;
  }
};

const pinterestAccountSearch = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const isPinterestIntegrationBtnLoading = (state = false, action) => {
  switch (action.type) {
    case PINTEREST_CONNECT_STARTED:
      return true;
    case PINTEREST_CONNECT_DONE:
      return false;
    case PINTEREST_DISCONNECT_STARTED:
      return true;
    case PINTEREST_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};

export const reducers = {
  isPinterestConnected,
  pinterestConfigScreenIsOpen,
  pinterestRemoteAccounts,
  pinterestAccountSearch,
  isPinterestIntegrationBtnLoading,
};
