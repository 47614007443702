import {
  Anchor,
  Button,
  Collapse,
  confirm,
  Flex,
  Group,
  Icon,
  Modal,
  Switch,
  Tabs,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { useDashContext } from '../dashContext';
import { updateMainElement, createNewWidget, addWidgetToDashboard } from '../utils/willyUtils';
import { baseURL } from '../../../config';
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DEFAULT_AXIS_DOMAIN } from '../constants';
import { removeDashImage, updateDashImage } from '../utils/dashImageCrud';
import { toast } from 'react-toastify';
import { WillyEmoji } from '../types/emojiTypes';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import {
  DashboardCurrentDateRange,
  DashboardPrevDateRange,
  DialectWithBoth,
  ProvidersBlockingCombination,
  WillyBaseMainElement,
  WillyDashboardElement,
  WillyWidgetElement,
} from '../types/willyTypes';
import { SalesPlatform, ServicesIds } from '@tw/types/module/services';
import { emojiIsLetter, emptyEmoji, nonSavedEmptyEmoji } from './WillyDashDescription';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { User } from '../../UserProfileManagment/User/constants';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';
import { useNavigate } from 'react-router-dom';
import {
  $dashSettingsModal,
  closeDashSettingsModal,
  DashSettingsDefaultData,
  toggleAdvancedSettings,
} from '$stores/willy/$dashSettingsModal';
import { successAlertWithAction } from '../AlertWithActions';
import { DatePickerTimePeriods } from '../../useDatePickerSelectedOptions';
import moment from '@tw/moment-cached';
import { $shop } from '../../../$stores/$shop';
import { $dialect } from '$stores/$user';
import { WillyCurrentDatePicker } from '../WillyCurrentDatePicker';
import { WillyPrevDatePicker } from '../WillyPrevDatePicker';
import { WillyDashAndSeqBaseSettings } from './WillyDashAndSeqBaseSettings';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Checkbox } from '@tw/ui-components';
import { Timestamp } from 'utils/DB';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import useDashHistory from '../hooks/useDashHistory';
import { $shopDashboards } from '$stores/willy/$shopDashboards';
import { CreditsUseAlert } from './CreditsUseAlert';
import { moveDashboardToCategory } from './category-management/api';

export const DEFAULT_CURRENT_DATE_RANGE: DashboardCurrentDateRange = {
  start: moment(),
  end: moment(),
  id: DatePickerTimePeriods.TODAY,
};
export const DEFAULT_PREV_DATE_RANGE: DashboardPrevDateRange = {
  start: moment().subtract(1, 'days'),
  end: moment().subtract(1, 'days'),
  id: 'previousPeriod',
};

export const WillyDashSettingsModal: React.FC = () => {
  const { opened, newDashboard, newGlobalDashboard, widgetData, defaultData } =
    useStoreValue($dashSettingsModal);
  const { $dashboard, $dashboardId } = useDashContext();
  const dashboardId = useStoreValue($dashboardId);
  const dashboard = useStoreValue($dashboard);

  const modalTitle = useMemo(() => {
    const title = newDashboard ? 'Create Dashboard' : 'Dashboard Settings';
    const widgetDataCount = widgetData?.length || 0;

    return widgetDataCount > 1 ? `${title} from ${widgetDataCount} widgets` : title;
  }, [newDashboard, widgetData?.length]);

  if (!(dashboardId && dashboard) && !newDashboard) return;

  return (
    <Modal
      opened={opened}
      onClose={closeDashSettingsModal}
      title={modalTitle}
      trapFocus
      headerBorder
      size="md"
      zIndex={300}
    >
      <WillyDashSettings
        newDashboard={newDashboard}
        newGlobalDashboard={newGlobalDashboard}
        widgetData={widgetData}
        defaultData={defaultData}
      />
    </Modal>
  );
};

type WillyDashSettingsProps = {
  newGlobalDashboard?: boolean;
  newDashboard?: boolean;
  widgetData?: WillyWidgetElement[];
  defaultData?: DashSettingsDefaultData;
  conversationId?: string;
};
export const WillyDashSettings: React.FC<WillyDashSettingsProps> = ({
  newGlobalDashboard,
  newDashboard,
  widgetData,
  defaultData,
  conversationId,
}) => {
  const {
    $packages,
    $defaultPackages,
    $isGlobal,
    $dashboard,
    $dashboardId,
    $isShopOwner,
    setDashboardDefaultDateToQS,
    $uniqueVisitors,
    $dashCreator,
    $lastUpdated,
    $latestDashboardVersion,
  } = useDashContext();
  const dashCreator = useStoreValue($dashCreator);
  const lastUpdated = useStoreValue($lastUpdated);
  const packages = useStoreValue($packages);
  const defaultPackages = useStoreValue($defaultPackages);
  const [dashboard, setDashboard] = useWritableStore($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const isShopOwner = useStoreValue($isShopOwner);
  const shop = useStoreValue($shop);
  const dashHistory = useDashHistory();
  const shopDashboards = useStoreValue($shopDashboards);
  const isDashGlobal = useStoreValue($isGlobal);

  const viewCount = useMemo(
    () =>
      dashHistory.history
        .filter((h) => h.action === 'view_dashboard')
        .sort((a, b) => a.time - b.time)?.length,
    [dashHistory],
  );

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [isUseDashboardDefaultDate, setIsUseDashboardDefaultDate] = useState<boolean>(false);
  const [currentDateRange, setCurrentDateRange] = useState<DashboardCurrentDateRange>(
    DEFAULT_CURRENT_DATE_RANGE,
  );
  const [prevDateRange, setPrevDateRange] =
    useState<DashboardPrevDateRange>(DEFAULT_PREV_DATE_RANGE);
  const [description, setDescription] = useState<string>('');
  const [reportPrompt, setReportPrompt] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [isDynamic, setIsDynamic] = useState<boolean>(true);
  const [emoji, setEmoji] = useState<WillyEmoji>(nonSavedEmptyEmoji as WillyEmoji);
  const [isBeta, setIsBeta] = useState<boolean>(false);
  const [isDnd, setIsDnd] = useState<boolean>(true);
  const [isSyncCharts, setIsSyncCharts] = useState<boolean>(false);
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<boolean>(false);
  const [dialect, setDialect] = useState<DialectWithBoth>(isDashGlobal ? $dialect.get() : 'both');
  const [providers, setProviders] = useState<ServicesIds[]>([]);
  const [providersBlockingCombination, setProvidersBlockingCombination] =
    useState<ProvidersBlockingCombination>('NONE');
  const [msps, setMsps] = useState<SalesPlatform[]>([]);
  const [credits, setCredits] = useState<number | undefined>();
  const [isSqlTeamReport, setIsSqlTeamReport] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (emojiIsLetter(emoji)) {
      if (name.length > 0) {
        setEmoji(name[0].toUpperCase() as WillyEmoji);
      } else {
        setEmoji(nonSavedEmptyEmoji as WillyEmoji);
      }
    }
  }, [emoji, setEmoji, name]);

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const userEmail = useSelector((state: RootState) => state.userEmail);
  const user = useSelector((state: RootState) => state.user) as User;

  const isGlobal = useMemo(
    () => (newGlobalDashboard ? newGlobalDashboard : newDashboard ? false : isDashGlobal),
    [isDashGlobal, newGlobalDashboard, newDashboard],
  );
  const [featureFlagConfigs, setFeatureFlagConfigs] = useState<any[]>(packages);

  const [featureFlagDefaultCopyConfigs, setFeatureFlagDefaultCopyConfigs] = useState<any[]>(
    defaultPackages ?? [],
  );

  const [activeTab, setActiveTab] = useState<'settings' | 'details'>('settings');
  const { advancedSettingsOpened } = useStoreValue($dashSettingsModal);

  const collapseBodyRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = useCallback(() => {
    toggleAdvancedSettings();
  }, []);

  const scrollInto = useCallback(() => {
    setTimeout(() => {
      if (collapseBodyRef.current && advancedSettingsOpened) {
        collapseBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 100);
  }, [collapseBodyRef, advancedSettingsOpened]);

  const ffConfigsToRemoveDashId = useMemo(() => {
    return packages.filter((p) => !featureFlagConfigs?.includes(p));
  }, [packages, featureFlagConfigs]);

  const ffConfigsDefaultToRemoveDashId = useMemo(() => {
    const packages = defaultPackages ?? [];
    return packages.filter((p) => !featureFlagDefaultCopyConfigs?.includes(p));
  }, [featureFlagDefaultCopyConfigs, defaultPackages]);

  useEffect(() => {
    if (dashboard && !newDashboard) {
      setName(dashboard.name || '');
      setReportPrompt(dashboard.reportPrompt || '');
      setIsUseDashboardDefaultDate(dashboard.isUseDashboardDefaultDate ?? false);
      setCurrentDateRange(dashboard.currentDateRange ?? DEFAULT_CURRENT_DATE_RANGE);
      setPrevDateRange(dashboard.prevDateRange ?? DEFAULT_PREV_DATE_RANGE);
      setDescription(dashboard.description);
      setCategory(dashboard.category ?? '');
      setRoles(dashboard.roles ?? []);
      setIsDynamic(dashboard.isDynamic ?? false);
      setEmoji(dashboard.emoji ?? (nonSavedEmptyEmoji as WillyEmoji));
      setIsBeta(dashboard.isBeta ?? false);
      setIsFullWidth(dashboard.isFullWidth ?? true);
      setIsSyncCharts(dashboard.isSyncCharts ?? false);
      setIsDnd(dashboard.isDnd ?? false);
      setIsHide(dashboard.isHide ?? false);
      setDialect(dashboard.dialect ?? $dialect.get());
      setProviders(dashboard.providers ?? []);
      setProvidersBlockingCombination(dashboard.providersBlockingCombination ?? 'NONE');
      setImageSrc(
        dashboard?.image ? `${baseURL}/v2/media/dashboard-image/${dashboard.image}` : undefined,
      );
      setMsps(dashboard.msps ?? []);
      setCredits(dashboard.credits);
      setIsSqlTeamReport(dashboard.isSqlTeamReport ?? false);
    } else {
      const {
        isUseDashboardDefaultDate: defaultDefaultDate = false,
        currentDateRange: defaultCurrentDateRange = DEFAULT_CURRENT_DATE_RANGE,
        prevDateRange: defaultPrevDateRange = DEFAULT_PREV_DATE_RANGE,
        description: defaultDescription = defaultData?.defaultDescription ?? '',
        category: defaultCategory = '',
        roles: defaultRoles = [],
        isDynamic: defaultIsDynamic = defaultData?.defaultIsDynamic ?? true,
        emoji: defaultEmoji = nonSavedEmptyEmoji as WillyEmoji,
        isBeta: defaultIsBeta = false,
        isDnd: defaultIsDnd = false,
        isFullWidth: defaultIsFullWidth = true,
        isSyncCharts: defaultIsSyncCharts = false,
        isHide: defaultIsHide = false,
        dialect: defaultDialect = dashboard?.isGlobal ? $dialect.get() : 'both',
        providers: defaultProviders = [],
        providersBlockingCombination: defaultProvidersBlockingCombination = 'NONE',
        image: defaultImageSrc = undefined,
        msps: defaultMsps = [],
        credits: defaultCredits = undefined,
        isSqlTeamReport: defaultisSqlTeamReport = false,
      } = {};
      setName(defaultData?.defaultName ?? '');
      setIsUseDashboardDefaultDate(defaultDefaultDate);
      setCurrentDateRange({
        ...defaultCurrentDateRange,
        start: moment(defaultCurrentDateRange.start),
        end: moment(defaultCurrentDateRange.end),
      });
      setPrevDateRange({
        ...defaultPrevDateRange,
        start: moment(defaultPrevDateRange.start),
        end: moment(defaultPrevDateRange.end),
      });
      setDescription(defaultDescription);
      setCategory(defaultCategory);
      setRoles(defaultRoles);
      setFeatureFlagConfigs([]);
      setFeatureFlagDefaultCopyConfigs([]);
      setIsDynamic(defaultIsDynamic);
      setEmoji(defaultEmoji);
      setIsBeta(defaultIsBeta);
      setIsDnd(defaultIsDnd);
      setIsFullWidth(defaultIsFullWidth);
      setIsSyncCharts(defaultIsSyncCharts);
      setIsHide(defaultIsHide);
      setDialect(defaultDialect);
      setProviders(defaultProviders);
      setProvidersBlockingCombination(defaultProvidersBlockingCombination);
      setImageSrc(undefined);
      setFile(defaultData?.defaultFile);
      setMsps(defaultMsps);
      setCredits(1);
      setIsSqlTeamReport(defaultisSqlTeamReport);
    }
  }, [dashboard, defaultData, newDashboard]);

  const saveImage = useCallback(
    async (dashboard: WillyBaseMainElement) => {
      if (file) {
        await updateDashImage(file, dashboard);
      }
    },
    [file],
  );

  const addWidgetToDash = useCallback(
    async (data: WillyDashboardElement, widget) => {
      if (!widget) return;

      const {
        type,
        queryId,
        title,
        metrics,
        parameters,
        stacked,
        incrementedStacked,
        queries,
        wrapText,
        grid,
        gridColumns,
        twoColumnMobile,
        yAxisDomain,
        allowDataOverflow,
        dimension,
        withoutMainQuery,
        codeResult,
        hasGlobalConditionalFormatting,
        globalConditionalFormattingColor,
        dataType,
        dialect,
      } = widget;

      if (type && queryId) {
        await addWidgetToDashboard(
          data,
          {
            queryId,
            title,
            type,
            metrics: metrics || [],
            parameters,
            stacked: stacked ?? false,
            incrementedStacked: incrementedStacked ?? false,
            queries,
            wrapText: wrapText ?? false,
            grid: grid || 'flex',
            gridColumns: gridColumns || 2,
            twoColumnMobile: twoColumnMobile ?? false,
            yAxisDomain: yAxisDomain || DEFAULT_AXIS_DOMAIN,
            allowDataOverflow: allowDataOverflow ?? false,
            dimension: dimension || '',
            withoutMainQuery: withoutMainQuery ?? false,
            codeResult: codeResult,
            hasGlobalConditionalFormatting: hasGlobalConditionalFormatting ?? false,
            globalConditionalFormattingColor: globalConditionalFormattingColor || '',
            dataType: dataType || 'nlq',
            dialect,
          },
          currentShopId,
          conversationId,
        );
      }
    },
    [currentShopId, conversationId],
  );

  const createDashboard = useCallback(async () => {
    try {
      const { data } = await axiosInstance.post('v2/willy/create-dashboard', {
        shopId: currentShopId,
        name,
        reportPrompt,
        isGlobal,
        description,
        widgets: [],
        isDynamic,
        isUseDashboardDefaultDate,
        currentDateRange: {
          ...currentDateRange,
          start: currentDateRange.start.format('YYYY-MM-DD'),
          end: currentDateRange.end.format('YYYY-MM-DD'),
        } as any,
        prevDateRange: {
          ...prevDateRange,
          start: prevDateRange.start.format('YYYY-MM-DD'),
          end: prevDateRange.end.format('YYYY-MM-DD'),
        } as any,
        isBeta,
        isDnd,
        isFullWidth,
        isSyncCharts,
        userEmail,
        user,
        emoji,
        providers,
        providersBlockingCombination,
        category,
        roles,
        dialect,
        updatedAt: Timestamp.now(),
        generalAccess: { type: 'invite', permission: 'none' },
        userPermissions: { [user.uid!]: isShopOwner ? 'owner' : 'editor' },
        msps,
        credits,
        isSqlTeamReport,
      } as Partial<WillyDashboardElement>);

      if (widgetData) {
        await Promise.all(widgetData.map(async (widget) => await addWidgetToDash(data, widget)));
      } else {
        await createNewWidget(currentShopId, data, {
          title: 'Untitled Section',
        });
      }

      await saveImage(data);

      if (isGlobal && isTwGlobalDashboardCreatorClaim) {
        await updateDashboardForFFConfigs(
          {
            configs: featureFlagConfigs,
            dashboardId: data.id,
            mergeStrategy: 'merge',
          },
          FeatureFlag.TEMPLATES_FF,
        );
        await updateDashboardForFFConfigs(
          {
            configs: featureFlagDefaultCopyConfigs,
            dashboardId: data.id,
            mergeStrategy: 'merge',
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        );
      }

      genericEventLogger(analyticsEvents.DASHBOARDS, {
        action: dashboardsActions.CREATE_NEW,
        dashboard_id: data.id,
        dashboard_name: data.name,
        template_id: data.globalDashboardId,
        template_name: data.globalDashboardId ? data.name : null,
      });

      navigate(`/dashboards/${data.id}`);

      successAlertWithAction(
        `${
          widgetData
            ? `Your section has been added to your new ${name} report`
            : `${name} successfully created`
        }`,
        () => {
          navigate({
            pathname: `/dashboards/${data.id}`,
          });
        },
        'View Dashboard',
      );
    } catch (e) {
      toast.error(`Error creating report. ${e.message ?? e.data?.message ?? ''}`);
    }
    setLoading(false);
    closeDashSettingsModal();
  }, [
    currentShopId,
    name,
    reportPrompt,
    isGlobal,
    description,
    isDynamic,
    isUseDashboardDefaultDate,
    currentDateRange,
    prevDateRange,
    isBeta,
    isDnd,
    isFullWidth,
    isSyncCharts,
    userEmail,
    user,
    emoji,
    providers,
    providersBlockingCombination,
    category,
    roles,
    dialect,
    isShopOwner,
    widgetData,
    saveImage,
    isTwGlobalDashboardCreatorClaim,
    navigate,
    addWidgetToDash,
    featureFlagConfigs,
    featureFlagDefaultCopyConfigs,
    msps,
    credits,
    isSqlTeamReport,
  ]);

  const onSave = useCallback(async () => {
    setLoading(true);
    if (newDashboard) {
      await createDashboard();
      return;
    }
    if (!dashboard || !dashboardId) {
      return;
    }
    try {
      const updatedDashboard: WillyDashboardElement = {
        ...dashboard,
        name,
        reportPrompt,

        description,
        isBeta,
        isDnd,
        isFullWidth,
        isHide,
        dialect,
        isDynamic,
        isSyncCharts,
        emoji,
        providers,
        providersBlockingCombination,
        updatedAt: Timestamp.now(),
        isUseDashboardDefaultDate,
        credits,
        isSqlTeamReport,
      };
      if (!!msps.length) {
        updatedDashboard.msps = msps;
      }
      if (category) {
        updatedDashboard.category = category;
      }
      if (roles) {
        updatedDashboard.roles = roles;
      }
      setDashboard(updatedDashboard);
      if (isGlobal) {
        if (
          !(await confirm({
            title: 'Edit Template',
            message: 'Are you sure you want to edit this template?',
          }))
        ) {
          return;
        }
      }
      await updateMainElement(dashboard!, {
        ...updatedDashboard,
        currentDateRange: {
          ...currentDateRange,
          start: currentDateRange.start.format('YYYY-MM-DD'),
          end: currentDateRange.end.format('YYYY-MM-DD'),
        } as any,
        prevDateRange: {
          ...prevDateRange,
          start: prevDateRange.start.format('YYYY-MM-DD'),
          end: prevDateRange.end.format('YYYY-MM-DD'),
        } as any,
      });
      await saveImage(updatedDashboard);

      await setDashboardDefaultDateToQS();

      if (category && dashboard.category !== category) {
        await moveDashboardToCategory({
          dashboardId: dashboard.id,
          newCategoryId: category,
          withToast: false,
        });
      }

      // Update packages that have access to this dashboard
      if (isGlobal && isTwGlobalDashboardCreatorClaim) {
        await Promise.all([
          updateDashboardForFFConfigs(
            {
              configs: featureFlagConfigs,
              mergeStrategy: 'merge',
              dashboardId,
            },
            FeatureFlag.TEMPLATES_FF,
          ),
          updateDashboardForFFConfigs(
            {
              configs: ffConfigsToRemoveDashId,
              mergeStrategy: 'delete',
              dashboardId,
            },
            FeatureFlag.TEMPLATES_FF,
          ),
          updateDashboardForFFConfigs(
            {
              configs: featureFlagDefaultCopyConfigs,
              mergeStrategy: 'merge',
              dashboardId,
            },
            FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
          ),
          updateDashboardForFFConfigs(
            {
              configs: ffConfigsDefaultToRemoveDashId,
              mergeStrategy: 'delete',
              dashboardId,
            },
            FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
          ),
        ]);

        $packages.set(featureFlagConfigs ?? []);
        $defaultPackages.set(featureFlagDefaultCopyConfigs ?? []);
      }
      toast('Dashboard updated successfully', { type: 'success' });
      closeDashSettingsModal();
    } catch (e) {
      toast.error('Error updating report. Please try again.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    newDashboard,
    dashboard,
    dashboardId,
    createDashboard,
    name,
    reportPrompt,
    description,
    isBeta,
    isDnd,
    isFullWidth,
    isHide,
    isSyncCharts,
    dialect,
    isDynamic,
    emoji,
    providers,
    providersBlockingCombination,
    isUseDashboardDefaultDate,
    category,
    roles,
    setDashboard,
    isGlobal,
    currentDateRange,
    prevDateRange,
    saveImage,
    setDashboardDefaultDateToQS,
    isTwGlobalDashboardCreatorClaim,
    featureFlagConfigs,
    ffConfigsToRemoveDashId,
    featureFlagDefaultCopyConfigs,
    ffConfigsDefaultToRemoveDashId,
    $packages,
    $defaultPackages,
    msps,
    credits,
    isSqlTeamReport,
  ]);

  const detailsSectionRows = useMemo(() => {
    const detailsRows = [
      'Views',
      'Owner',
      'Created',
      'Last updated',
      'Updated by',
      'PPS Template Version',
    ];
    return detailsRows.map((row, i) => {
      let value: ReactNode;
      switch (row) {
        case 'Views':
          value = viewCount;
          break;
        case 'Owner':
          value = dashCreator?.user
            ? dashCreator?.user?.firstName + ' ' + dashCreator?.user?.lastName
            : '';
          break;
        case 'Created':
          value = moment(dashCreator?.time).format('MMMM D, YYYY, h:mmA');
          break;
        case 'Last updated':
          value = moment(lastUpdated?.time).format('MMMM D, YYYY, h:mmA');
          break;
        case 'Updated by':
          value = lastUpdated?.user
            ? lastUpdated?.user?.firstName + ' ' + lastUpdated?.user?.lastName
            : '';
          break;
        case 'PPS Template Version':
          value = (
            <Anchor
              onClick={() => {
                navigate(`/dashboards/${dashboard?.globalDashboardId}`);
                closeDashSettingsModal();
              }}
              underline="never"
            >
              {dashboard?.version ?? '0.0.1'}
            </Anchor>
          );
      }
      return (
        <Fragment key={`details-section-${i}`}>
          {row === 'PPS Template Version' ? (
            <>
              {dashboard?.version && (
                <Flex justify="space-between" w="100%" key={`details-section-${i}`}>
                  <Text size="sm" weight={500} color="gray.8">
                    {row}
                  </Text>
                  <Text size="sm" weight={400} color="gray.8">
                    {value}
                  </Text>
                </Flex>
              )}
            </>
          ) : (
            <Flex justify="space-between" w="100%" key={`details-section-${i}`}>
              <Text size="sm" weight={500} color="gray.8">
                {row}
              </Text>
              <Text size="sm" weight={400} color="gray.8">
                {value}
              </Text>
            </Flex>
          )}
        </Fragment>
      );
    });
  }, [
    dashCreator,
    lastUpdated,
    dashboard?.version,
    viewCount,
    navigate,
    dashboard?.globalDashboardId,
  ]);

  return (
    <Flex direction="column" gap="md">
      <Tabs
        defaultValue={activeTab}
        onChange={(v) => {
          if (!v) {
            return;
          }
          setActiveTab(v as 'settings' | 'details');
        }}
      >
        {!newDashboard && (
          <Tabs.List className="border-0">
            <Tabs.Tab value="settings">
              <span className="flex gap-1">
                <Text size="sm">Settings</Text>
              </span>
            </Tabs.Tab>
            <Tabs.Tab value="details">
              <Text size="sm">Details</Text>
            </Tabs.Tab>
          </Tabs.List>
        )}
        <Tabs.Panel value="settings">
          <Flex direction="column" gap="lg" pt={newDashboard ? 'md' : 'lg'}>
            {newDashboard && <CreditsUseAlert creditsNumber={credits} isCustomReport={true} />}
            <WillyDashAndSeqBaseSettings
              id={dashboard?.id}
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
              category={category}
              setCategory={setCategory}
              roles={roles}
              setRoles={setRoles}
              isBeta={isBeta}
              setIsBeta={setIsBeta}
              isHide={isHide}
              setIsHide={setIsHide}
              dialect={dialect}
              setDialect={setDialect}
              isGlobal={isGlobal}
              featureFlagConfigs={featureFlagConfigs}
              featureFlagDefaultCopyConfigs={featureFlagDefaultCopyConfigs}
              setFeatureFlagConfigs={setFeatureFlagConfigs}
              setFeatureFlagDefaultCopyConfigs={setFeatureFlagDefaultCopyConfigs}
              providers={providers}
              setProviders={setProviders}
              providersBlockingCombination={providersBlockingCombination}
              setProvidersBlockingCombination={setProvidersBlockingCombination}
              setFile={setFile}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              emoji={emoji}
              setEmoji={setEmoji}
              image={dashboard?.image}
              removeDashImage={() => removeDashImage(dashboard!)}
              isReportSettings={true}
              msps={msps}
              setMsps={setMsps}
              credits={credits}
              setCredits={setCredits}
            />
            {isTwGlobalDashboardCreatorClaim && (
              <Checkbox
                checked={isSqlTeamReport}
                onChange={setIsSqlTeamReport}
                label="Is Sql Team Dashboard"
              />
            )}
            <Flex direction="column">
              <Flex
                direction="column"
                onClick={() => {
                  handleToggle();
                  scrollInto();
                }}
                cursor="pointer"
              >
                <Flex align="center" gap="xs">
                  <Text size="sm" weight={400} color="gray.8">
                    Advanced Settings
                  </Text>
                  <div
                    className={`flex transition-transform ${
                      advancedSettingsOpened ? 'rotate-180' : ''
                    }`}
                  >
                    <Icon color="gray.5" name={'chevron-down'} size={10} />
                  </div>
                </Flex>
              </Flex>
              <Collapse in={advancedSettingsOpened ?? false} ref={collapseBodyRef}>
                <Flex align="left" gap="xs" direction="column" pt="md">
                  <Checkbox
                    checked={isDynamic}
                    onChange={() => {
                      if (!isDynamic) {
                        setIsDynamic(true);
                      } else {
                        setIsDynamic(false);
                        setIsUseDashboardDefaultDate(false);
                      }
                    }}
                    label={
                      <div>
                        <Text size="sm">Add a global date range </Text>
                        <Text color="gray.6" size={'xs'}>
                          Global Date defines the date range for the entire dashboard
                        </Text>
                      </div>
                    }
                    size="sm"
                  />
                  <div className="pl-[20px]">
                    <Flex direction="column" gap="md" mb="sm">
                      <div className={'flex gap-3 items-center'}>
                        <WillyCurrentDatePicker
                          earliestDate={
                            shop.earliestDate && shop.earliestDate?.toDate
                              ? shop.earliestDate.toDate()
                              : new Date()
                          }
                          timezone={shop.timezone!}
                          selectedOption={currentDateRange.id}
                          onValueChanged={(value) => setCurrentDateRange(value)}
                          start={currentDateRange.start}
                          end={currentDateRange.end}
                          disabled={!isUseDashboardDefaultDate}
                        />
                        <WillyPrevDatePicker
                          earliestDate={
                            shop.earliestDate && shop.earliestDate?.toDate
                              ? shop.earliestDate.toDate()
                              : new Date()
                          }
                          timezone={shop.timezone!}
                          onValueChanged={(value) => setPrevDateRange(value)}
                          selectedOption={prevDateRange.id}
                          start={prevDateRange.start}
                          end={prevDateRange.end}
                          currentDateRange={currentDateRange}
                          disabled={!isUseDashboardDefaultDate}
                        />
                      </div>
                      <Flex align="center" gap="sm">
                        <Group>
                          <Text size="xs" weight={400} color="gray.8">
                            Set date as default
                          </Text>
                          <Tooltip
                            multiline
                            position="bottom-start"
                            offset={{ mainAxis: 0, crossAxis: 25 }}
                            w={305}
                            lightTooltip={true}
                            label={
                              'Your report will reflect the set date each time you load the page'
                            }
                          >
                            <Icon name="info" size={12} color="gray.4" />
                          </Tooltip>
                        </Group>
                        <Switch
                          size="xs"
                          onChange={(event) =>
                            setIsUseDashboardDefaultDate(event.currentTarget.checked)
                          }
                          checked={isUseDashboardDefaultDate}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </Flex>
                <Flex align="left" gap="xs" direction="column" pt="md">
                  <Text size="sm" weight={400} color="gray.8">
                    Set dashboard's default layout settings
                  </Text>
                  <Group>
                    <Checkbox label="Auto Height" checked={isDnd} onChange={setIsDnd} />
                    <Tooltip
                      multiline
                      position="bottom-start"
                      offset={{ mainAxis: 0, crossAxis: 25 }}
                      w={305}
                      lightTooltip={true}
                      label={
                        'Auto-height sets the height of your report sections to hug its content. You can not resize sections with auto-height on.'
                      }
                    >
                      <Icon name="info" size={12} color="gray.4" />
                    </Tooltip>
                  </Group>
                  <Group>
                    <Checkbox label="Full Width" checked={isFullWidth} onChange={setIsFullWidth} />
                    <Tooltip
                      multiline
                      position="bottom-start"
                      offset={{ mainAxis: 0, crossAxis: 25 }}
                      w={305}
                      lightTooltip={true}
                      label={
                        'Full Width expands your report sections to fit the entire width of the report.'
                      }
                    >
                      <Icon name="info" size={12} color="gray.4" />
                    </Tooltip>
                  </Group>
                  <Group>
                    <Checkbox
                      label="Sync charts"
                      checked={isSyncCharts}
                      onChange={setIsSyncCharts}
                    />
                  </Group>
                </Flex>
              </Collapse>
            </Flex>
          </Flex>
        </Tabs.Panel>
        <Tabs.Panel value="details">
          <Flex direction="column" gap="xs" pt="lg" w="100%">
            {detailsSectionRows}
          </Flex>
        </Tabs.Panel>
      </Tabs>

      <Group justify="flex-end">
        <Button variant="white" onClick={closeDashSettingsModal}>
          Cancel
        </Button>
        <Button
          onClick={onSave}
          loading={loading}
          disabled={!name?.trim() || (isDashGlobal && credits === undefined)}
        >
          Save
        </Button>
      </Group>
    </Flex>
  );
};
