import firebaseConfigProd from './firebase.production';
import firebaseConfigStaging from './firebase.staging';

import { projectIdToHostMap } from '@tw/constants';

export const isLocalhost =
  (import.meta.env.VITE_BACKEND_ENV === undefined ||
    import.meta.env.VITE_BACKEND_ENV === 'local') &&
  window.location.hostname === 'localhost';
export const isProduction =
  import.meta.env.VITE_BACKEND_ENV === 'production' ||
  import.meta.env.VITE_LOCAL_PROD === 'true' ||
  (window.location.hostname.endsWith('app.triplewhale.com') &&
    !window.location.hostname.endsWith('stg.app.triplewhale.com')) ||
  window.location.hostname.startsWith('shofifi-');
export const isStaging =
  import.meta.env.VITE_BACKEND_ENV === 'staging' || (!isProduction && !isLocalhost);
export const isLocalFunctions = import.meta.env.VITE_BACKEND_ENV === 'local';
export const isDevFirestore = false;

export const isIgnoreFeatureFlags = isLocalhost;
export const stripeKey = isProduction
  ? 'pk_live_Acye27mpfekyrQl1tQzHiPES00tFwrZmdj'
  : 'pk_test_dTz7sqsv1JTUgdIavq6nLUly00NjkI5wrP';

export const placesApi = isProduction
  ? 'AIzaSyA-YNDgeFHNiQpooKUEMreSzV85n97seyA'
  : 'AIzaSyA-YNDgeFHNiQpooKUEMreSzV85n97seyA';

export const partnerStackKey = isProduction
  ? 'pk_RBMqmw6OHApOJvsHZYszl1YEtW97SZqD'
  : 'pk_A9879UuAnlZtMhLO58n3BeI6Yz4H1mmt';

export const amplitudeKey = 'ac5fc42bdd63f301854df3bf423e9548';
export const posthogKey = isProduction
  ? 'phc_F1GgrjMY2uJIOszAdKzVcugrV5LjeRoHK1BaDvolSHd'
  : 'phc_4sBM2gqy39dw8pjpDMlQ9BnJrLahCXOrn33v8dJ8i8p';
export const firebase = isProduction ? firebaseConfigProd : firebaseConfigStaging;
export const isInChromeExtensionIframe = window.location.href.indexOf('extensioniframe') > 0;
export const isInAllShopsPinnedIframe = window.location.href.indexOf('allshopspinnediframe') > 0;
export const isInIframe = isInChromeExtensionIframe || isInAllShopsPinnedIframe;

export const isFromMobileApp = new URL(window.location.href).searchParams.get('app');

const queryString = window.location.search;
export const urlParams = new URLSearchParams(queryString);
export const isFacebookIframe = isInChromeExtensionIframe && urlParams.get('facebook_account_id');

export const willyDashBucketName = isProduction ? 'willy-dash-tw-prod' : 'willy-dash-tw-staging';

export const baseURL = isLocalhost
  ? 'http://localhost/api'
  : `${
      window.twUser ? window.location.origin : `https://${projectIdToHostMap[firebase.projectId]}`
    }/api`;

export const firebaseMessageKey = isProduction
  ? 'BLdHsIJ_xZTzdwTRwf6KLH-C_Y5y19786vQ1F3F2-KlZ_1-iU1LQwvKSQJZwKIiBHaQm6P3XnFaf6IPAs83C7ZM'
  : 'BEHNWTpDOztSQGtROKGQRb0hOJ5wmODB-yioIJaZQlWoYduIjbOtjxeI-ZIehROg3d6lw_IPg_d53HnL45PTyx4';

export const googleClientId = isProduction
  ? '1072436220726-ngrs8g3jv318j73buq6t7u6busbg8b1v.apps.googleusercontent.com'
  : '89529659540-096trej1hjnt3gqogdbj5o573e463i22.apps.googleusercontent.com';

export const facebookAppId = '221942479096091';

export const TRENDS_STAGING = 'https://trends.triplewhale.com';
export const TRENDS_PRODUCTION = 'https://app.triplewhale.com/trends';
export const TRENDS_URL = isProduction ? TRENDS_PRODUCTION : TRENDS_STAGING;
