import React, { useCallback, useMemo, useState } from 'react';
import { Button, Icon, OptionList, Popover, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { selectUniqueSectionsWithMetrics } from 'utils/selectors';
import { RootState } from 'reducers/RootType';
import { CustomMetricsType } from 'ducks/customMetrics';
import { filterColumns } from 'components/CreativesCockpit/CreativeDashboard/filterColumns';
import { useMetricsForCustomMetricBuilder } from './useMetricsForCustomMetricBuilder';

type Props = { selected; setSelected };

export const ChooseMetricDropdown: React.FC<Props> = ({ selected, setSelected }) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const rawSections = useSelector(selectUniqueSectionsWithMetrics);
  const { customMetricsType } = useSelector((state: RootState) => state.customMetrics);

  const [allPixelColumns, customPixelColumns] = useMetricsForCustomMetricBuilder(
    CustomMetricsType.Attribution,
  );

  const sections = useMemo(() => {
    if (customMetricsType === CustomMetricsType.ProductAnalytics) {
      return [
        {
          options: filterColumns('product', 'triple-whale')
            .filter((c) => c.availableOnCustomMetrics)
            .map((column) => {
              return {
                value: column.key,
                label: column.name,
                metric: column,
              };
            }),
        },
      ];
    }

    // Attribution metrics
    if (customMetricsType === CustomMetricsType.Attribution) {
      return [
        {
          options: allPixelColumns
            .filter((c) => !['status', 'name', 'showInGraph'].includes(c.key))
            .map((column) => {
              return {
                value: column.key,
                label: column.name,
                metric: column,
              };
            }),
        },
        {
          title: 'Custom',
          icons: [],
          options: customPixelColumns.map((column) => {
            return {
              value: column.key,
              label: column.name,
              metric: column,
            };
          }),
        },
      ];
    }

    // default metrics
    return rawSections
      .filter((x) => x.tiles?.length)
      .map((section) => ({
        title: section.title,
        icons: section.icons,
        options: section.tiles.map((metric) => {
          return {
            value: metric.id,
            label: metric.title,
            metric,
          };
        }),
      }));
  }, [customMetricsType, rawSections, allPixelColumns, customPixelColumns]);

  const optionsFlattened = sections.map((x) => x.options).flat();

  const activatePopover = useCallback(() => setPopoverActive(true), []);

  const closePopover = useCallback(() => setPopoverActive(false), []);

  const handleSelect = (selected) => {
    const selectedOption = optionsFlattened.find((x) => x.value === selected[0]);
    setSelected(selectedOption);
    closePopover();
  };

  const optionsFilter = (option) => option.label.toLowerCase().includes(searchValue.toLowerCase());
  const sectionsToShow = sections.filter((section) => section.options.filter(optionsFilter).length);

  const activator = (
    <Button id="choose-metric-button" onClick={activatePopover} disclosure>
      {selected?.label || 'Choose Metric'}
    </Button>
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={closePopover}
        fixed
        // https://github.com/Shopify/polaris-react/issues/4652
        // fluidContent
        sectioned
        preferredPosition="below"
        autofocusTarget="first-node"
      >
        <TextField
          label
          labelHidden
          value={searchValue}
          onChange={setSearchValue}
          placeholder="Search metrics"
          prefix={<Icon source={SearchMinor} color="base" />}
          autoFocus
          autoComplete="off"
        />
        {sectionsToShow.map((section) => (
          <OptionList
            key={`${section.title}_${Math.floor(Math.random() * 100).toString()}`}
            title={
              (
                <span className="section-title choice-list-title-with-icons">
                  {(section.icons || []).map((I, i) => (
                    <I key={i} />
                  ))}
                  <b
                    style={{
                      marginLeft: '0.625rem',
                      display: 'inline-block',
                    }}
                  >
                    {section.title} Metrics
                  </b>
                </span>
              ) as any
            }
            onChange={handleSelect}
            options={section.options.filter(optionsFilter)}
            selected={[selected?.value]}
          />
        ))}
      </Popover>
    </div>
  );
};
