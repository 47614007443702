import { $derived } from '@tw/snipestate';
import { $globalDashboards, $loadingGlobalDashboards } from './$globalDashboards';
import { $globalSequences, $loadingGlobalSequences } from './$sequences';
import { WillyBaseMainElement } from '../../components/Willy/types/willyTypes';

export const $combineDashboardSequence = $derived((get): WillyBaseMainElement[] => [
  ...get($globalDashboards),
  ...get($globalSequences),
]);

export const $loadingGlobals = $derived(
  (get) => get($loadingGlobalSequences) || get($loadingGlobalDashboards),
);
