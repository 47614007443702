import { Monaco } from '@monaco-editor/react';
import { ActionList, Spinner } from '@shopify/polaris';
import { CancelMajor, InfoMinor } from '@shopify/polaris-icons';
import { confirm, Icon, Popover, Tabs } from '@tw/ui-components';
import { editor } from 'monaco-editor';
import { useEffect, useState } from 'react';
import _db from 'utils/DB';
import { BqTable } from './dataStuff/tables';
import { $tables, $tabs } from '../../$stores/willy/$tables';
import { createNewTab } from './createNewTab';
import { EditorInstance } from 'components/Willy/types/willyTypes';
import { useAppSelector } from 'reducers/RootType';
import { useStoreValue, useWritableStore } from '@tw/snipestate';

type SqlEditorTabsProps = {
  editorRef: editor.IStandaloneCodeEditor;
  monacoRef: Monaco;
  customTables: BqTable[];
  tabNameChange: (tabId: string, name: string) => void;
};

export const SqlEditorTabs: React.FC<SqlEditorTabsProps> = ({
  editorRef,
  monacoRef,
  customTables,
  tabNameChange,
}) => {
  const [tabs, setTabs] = useWritableStore($tabs);
  const [newTabOpened, setNewTabOpened] = useState(false);
  const activeTab = tabs.find((t) => t.active)?.model?.id;
  const tables = useStoreValue($tables);
  const userIsShlomo = useAppSelector((state) => state.user.email === 'shlomo@triplewhale.com');

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (window.location.hostname === 'localhost') {
        return;
      }
      if (tabs.some((t) => t.touched)) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [tabs]);

  if (!tabs.length || !activeTab) {
    return null;
  }

  return (
    <Tabs
      value={activeTab}
      onChange={(id) => {
        const index = tabs.findIndex((t) => t.model?.id === id);

        if (index === -1) {
          return;
        }
        setTabs((old) => {
          return old.map((t) => {
            return {
              ...t,
              active: t.model?.id === id,
            };
          });
        });

        if (!!tabs?.[index]?.model) {
          editorRef.setModel(tabs[index].model!);
        }
        editorRef.restoreViewState(tabs[index].state);
        editorRef.focus();
      }}
      // TODO: handle tab reordering

      // onTabSequenceChange={(a) => {
      //   const { newIndex, oldIndex } = a;
      //   const newTabs = [...tabs];
      //   const [removed] = newTabs.splice(oldIndex, 1);
      //   newTabs.splice(newIndex, 0, removed);
      //   setTabs(newTabs);
      //   if (oldIndex === activeTab) {
      //     setActiveTab(newIndex);
      //   } else if (newIndex === activeTab) {
      //     setActiveTab((old) => old + (newIndex > oldIndex ? -1 : 1));
      //   }
      // }}
    >
      <Tabs.List className="!flex-nowrap overflow-auto">
        {tabs.map((t, i) => (
          <Tabs.Tab key={t.model?.id} value={t.model?.id!} className="group">
            <div className="flex items-center gap-2 dark:text-white">
              <Icon name={t.language} />
              {t.loading && <Spinner size="small" accessibilityLabel="Loading tab" />}
              {!!t.error && (
                <InfoMinor className="w-8 h-8 fill-red-500" aria-label="Error loading tab" />
              )}
              <div
                className={`truncate group-hover:text-black`}
                tabIndex={0}
                contentEditable={t.nameEditable}
                suppressContentEditableWarning
                onDoubleClick={(e) => {
                  e.stopPropagation();
                  setTabs(
                    tabs.map((tab) => {
                      if (tab.model?.id === t.model?.id) {
                        return {
                          ...tab,
                          nameEditable: true,
                        };
                      }
                      return tab;
                    }),
                  );

                  setTimeout(() => {
                    const range = document.createRange();
                    range.selectNodeContents(e.target as any);
                    const selection = window.getSelection();
                    selection?.removeAllRanges();
                    selection?.addRange(range);

                    (e.target as any).focus();
                  }, 100);
                }}
                onBlur={(e) => {
                  e.stopPropagation();
                  const content = e.currentTarget?.innerText || 'Untitled tab ' + (i + 1);
                  // tabNameChange(t.model.id, content);
                  setTabs((old) => {
                    return old.map((tab) => {
                      if (tab.model?.id === t.model?.id) {
                        return {
                          ...tab,
                          tabName: content,
                          nameEditable: false,
                          nameChanged: true,
                        };
                      }
                      return tab;
                    });
                  });
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === 'ArrowLeft' ||
                    e.key === 'ArrowRight' ||
                    e.key === 'ArrowUp' ||
                    e.key === 'ArrowDown' ||
                    e.key === ' '
                  ) {
                    e.stopPropagation();
                  }
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.blur();
                  }
                }}
              >
                {t.tabName}
              </div>
              <div
                className="opacity-0 transition-opacity group-hover:opacity-100 cursor-pointer dark:fill-white dark:group-hover:fill-black"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (t.touched) {
                    const confirmed = await confirm({
                      title: 'Close tab',
                      message: 'Are you sure you want to close this tab?',
                    });
                    if (!confirmed) {
                      return;
                    }
                  }
                  const index = tabs.findIndex((x) => x.model?.id === t.model?.id);

                  setTabs(tabs.filter((x) => x.model?.id !== t.model?.id));

                  if (tabs.length === 1) {
                    const tab = createNewTab(
                      {
                        index: 1,
                        language: 'sql',
                        schema: tables,
                        editorRef,
                        monacoRef,
                        savedQueryType: 'query',
                      },
                      setTabs,
                    );
                    const newModel: editor.ITextModel = monacoRef.editor.createModel('', 'sql');

                    setTabs([tab]);
                    editorRef.setModel(newModel);
                    editorRef.restoreViewState(tab.state);
                    editorRef.focus();
                    return;
                  }

                  let nextActiveTabId: string | undefined;
                  if (t.model?.id !== activeTab) {
                    nextActiveTabId = activeTab;
                  } else {
                    // if you in the last tab, go to the previous one, otherwise go to the next one
                    nextActiveTabId =
                      index === tabs.length - 1
                        ? tabs[index - 1].model?.id
                        : tabs[index + 1].model?.id;
                  }

                  const nextActiveTab = tabs.find((x) => x.model?.id === nextActiveTabId);
                  setTabs((old) => {
                    return old.map((t) => {
                      return {
                        ...t,
                        active: t.model?.id === nextActiveTabId,
                      };
                    });
                  });
                  if (!!nextActiveTab?.model) {
                    editorRef.setModel(nextActiveTab.model);
                  }
                  editorRef.restoreViewState(nextActiveTab!.state);
                  editorRef.focus();
                }}
              >
                <CancelMajor className="w-8 h-8 flex" />
              </div>
            </div>
          </Tabs.Tab>
        ))}
        {userIsShlomo ? (
          <Tabs.Tab value="add-tab" className="dark:text-white dark:hover:text-black">
            <Popover opened={newTabOpened} onClose={() => setNewTabOpened(false)} p={0}>
              <Popover.Target>
                <span
                  onClick={() => {
                    setNewTabOpened((x) => !x);
                  }}
                >
                  +
                </span>
              </Popover.Target>
              <Popover.Dropdown p="xs">
                <ActionList
                  onActionAnyItem={() => {
                    setNewTabOpened(false);
                  }}
                  items={[
                    {
                      content: 'SQL',
                      onAction: () => {
                        const tab = createNewTab(
                          {
                            index: tabs.length + 1,
                            language: 'sql',
                            schema: tables,
                            editorRef,
                            monacoRef,
                            savedQueryType: 'query',
                          },
                          setTabs,
                        );
                        const { model, state } = tab;
                        model.onDidChangeContent(() => {
                          setTabs((old) => {
                            return old.map((t) => {
                              if (t.model?.id === model.id) {
                                return {
                                  ...t,
                                  touched: true,
                                };
                              }
                              return t;
                            });
                          });
                        });

                        setTabs((old) => [
                          ...old.map((x) => ({
                            ...x,
                            active: false,
                          })),
                          tab,
                        ]);

                        editorRef.setModel(model);
                        editorRef.restoreViewState(state);
                        editorRef.focus();
                      },
                    },
                    {
                      content: 'Python',
                      onAction: () => {
                        const tab = createNewTab(
                          {
                            index: tabs.length + 1,
                            language: 'python',
                            schema: tables,
                            editorRef,
                            monacoRef,
                            savedQueryType: 'query',
                          },
                          setTabs,
                        );
                        const { model, state } = tab;
                        model.onDidChangeContent(() => {
                          setTabs((old) => {
                            return old.map((t) => {
                              if (t.model?.id === model.id) {
                                return {
                                  ...t,
                                  touched: true,
                                };
                              }
                              return t;
                            });
                          });
                        });

                        setTabs((old) => [
                          ...old.map((x) => ({
                            ...x,
                            active: false,
                          })),
                          tab,
                        ]);

                        editorRef.setModel(model);
                        editorRef.restoreViewState(state);
                        editorRef.focus();
                      },
                    },
                  ]}
                />
              </Popover.Dropdown>
            </Popover>
          </Tabs.Tab>
        ) : (
          <Tabs.Tab
            value="add-tab"
            className="dark:text-white dark:hover:text-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const newModel: editor.ITextModel = monacoRef.editor.createModel(``, 'sql');

              newModel.onDidChangeContent(() => {
                setTabs((old) => {
                  return old.map((t) => {
                    if (t.model?.id === newModel.id) {
                      return {
                        ...t,
                        touched: true,
                      };
                    }
                    return t;
                  });
                });
              });

              const state = editorRef.saveViewState();

              const newTab: EditorInstance = {
                model: newModel,
                state: state,
                tabName: `Untitled tab ${tabs.length + 1}`,
                schema: tables,
                filterBuilder: { isPaneOpen: false },
                nameEditable: false,
                nameChanged: false,
                active: true,
                query: newModel.getValue(),
                language: 'sql',
              };
              setTabs((old) => [
                ...old.map((x) => ({
                  ...x,
                  active: false,
                })),
                newTab,
              ]);

              editorRef.setModel(newModel);
              editorRef.restoreViewState(state);
              editorRef.focus();
            }}
          >
            <span>+</span>
          </Tabs.Tab>
        )}
      </Tabs.List>
    </Tabs>
  );
};
