import { v4 as uuidV4 } from 'uuid';
import { Timestamp } from 'utils/DB';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import moment from 'moment-timezone';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  Menu,
  ActionIcon,
  Button,
  confirm,
  Flex,
  Icon,
  Text,
  Loader,
  MobileDrawer,
  Tooltip,
  Checkbox,
} from '@tw/ui-components';
import DropDown from 'components/ltv/DropDown';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import {
  analyticsEvents,
  chatActions,
  genericEventLogger,
  sequencesActions,
} from 'utils/dataLayer';
import axiosInstance from 'utils/axiosInstance';
import _db, { firestoreRef } from 'utils/DB';
import { $currentShopId } from '$stores/$shop';
import { $globalAndShopSequences, $shopSequences } from '$stores/willy/$sequences';
import { $dialect, $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { WillyEmoji } from './types/emojiTypes';
import { emptyEmoji } from './dashboardManagment//WillyDashDescription';
import { createNewSequence, CreateNewSequenceProps, duplicateSequence } from './utils/sequences';
import { WillyElementType, WillyDataSequence } from './types/willyTypes';
import { WillySelect } from './dashboardManagment/WillySelect';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { deleteSchedule } from './utils/sequenceSchedules';
import { SequenceListItem } from './SequenceListItem';

export type SequencesListProps = {
  sequenceId?: string;
  opened?: boolean;
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  setRunsOpen?: React.Dispatch<React.SetStateAction<string | undefined>>;
  isPage?: boolean;
};

const userSortOptions = [
  { value: 'edited', label: 'Recently Edited' },
  { value: 'created', label: 'Recently Added' },
];

const ITEMS_PER_PAGE = 10;

export const SequencesList: React.FC<SequencesListProps> = ({
  setOpened = () => {},
  setRunsOpen = () => {},
  isPage,
}) => {
  const { search } = useLocation();
  const isSmall = useIsSmall();
  const navigate = useNavigate();
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const [sequences, setSequences] = useWritableStore(
    isTwGlobalDashboardCreatorClaim ? $globalAndShopSequences : $shopSequences,
  );

  const userFilterOptions = useMemo(() => {
    const filters = [
      { value: 'all', label: 'All' },
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
      { value: 'user', label: 'My Workflows' },
    ];
    if (isTwGlobalDashboardCreatorClaim) {
      return [...filters, { value: 'global', label: 'Global' }];
    }
    return filters;
  }, [isTwGlobalDashboardCreatorClaim]);

  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);

  const params = new URLSearchParams(search);
  const defaultFilter = params.get('workflowsFilter') || userFilterOptions[0].value;
  const defaultSort = params.get('workflowsSort') || userSortOptions[0].value;
  const [deleting, setDeleting] = useState(false);
  const [userFilter, setUserFilter] = useState(defaultFilter);
  const [userSort, setUserSort] = useState(defaultSort);
  const [freeSearch, setFreeSearch] = useState('');
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);

  const filteredSequences = useFilteredItems(sequences, freeSearch, ['name', 'id'], userFilter);
  const sortedSequences = useMemo(() => {
    return filteredSequences.sort((a, b) => {
      if (userSort === 'edited') {
        const aTime = a.updatedAt
          ? a.updatedAt.seconds * 1000 + a.updatedAt.nanoseconds / 1000000
          : 0;
        const bTime = b.updatedAt
          ? b.updatedAt.seconds * 1000 + b.updatedAt.nanoseconds / 1000000
          : 0;

        if (aTime === 0) return 1;
        if (bTime === 0) return -1;

        return bTime - aTime;
      } else if (userSort === 'created') {
        const aTime = a.createdAt
          ? a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000
          : 0;
        const bTime = b.createdAt
          ? b.createdAt.seconds * 1000 + b.createdAt.nanoseconds / 1000000
          : 0;

        if (aTime === 0) return 1;
        if (bTime === 0) return -1;

        return bTime - aTime;
      }
      return 0;
    });
  }, [filteredSequences, userSort]);

  const paginatedSequences = useMemo(() => {
    return sortedSequences.slice(0, displayCount);
  }, [sortedSequences, displayCount]);

  const hasMoreItems = useMemo(() => {
    return displayCount < sortedSequences.length;
  }, [displayCount, sortedSequences.length]);

  // Reset display count when filters change
  useEffect(() => {
    setDisplayCount(ITEMS_PER_PAGE);
  }, [freeSearch, userFilter, userSort]);

  const hasSearchFilterOrSort = useMemo(() => {
    return (
      freeSearch !== '' ||
      userFilter !== userFilterOptions[0].value ||
      userSort !== userSortOptions[0].value
    );
  }, [freeSearch, userFilter, userSort]);

  const updateUrlParams = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(search);
      params.set(key, value);

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigate, search],
  );

  const handleFilterChange = (value: string) => {
    setUserFilter(value);
    updateUrlParams('workflowsFilter', value);
  };

  const handleSortChange = (value: string) => {
    setUserSort(value);
    updateUrlParams('workflowsSort', value);
  };

  const toggleSequenceActionMenu = useCallback(
    (id: string, open: boolean) => {
      setSequences((old) => {
        return old.map((x) => {
          return {
            ...x,
            actionsMenuOpen: x.id === id ? open : false,
          };
        });
      });
    },
    [setSequences],
  );

  const deleteSequences = useCallback(
    async (sequences: WillyDataSequence[]) => {
      const promises: Promise<void>[] = [];
      setDeleting(true);
      for (const sequence of sequences) {
        const ref = sequence.isGlobal
          ? firestoreRef().collection('global_data_sequences')
          : _db().collection('data_sequences');

        if (!sequence.isGlobal) {
          const runs = await ref.doc(sequence.id).collection('runs').get();
          runs.forEach((run) => {
            promises.push(run.ref.delete());
          });
        }

        if (sequence.schedule && sequence.schedule.id && currentShopId && sequence.user) {
          promises.push(deleteSchedule(sequence.schedule.id, currentShopId, sequence.user));
        }

        promises.push(ref.doc(sequence.id).set({ deleted: true }, { merge: true }));
        if (sequence.globalDashboardId) {
          promises.push(
            axiosInstance.post('v2/willy/update-stats', {
              shopId: currentShopId,
              sequenceId: sequence.globalDashboardId,
              actionType: 'uninstalled',
            }),
          );
        }
      }
      await Promise.all(promises);
      setDeleting(false);
    },
    [currentShopId],
  );

  const menuItems = useCallback(
    (c) => [
      {
        content: 'View Details',
        onAction: () => {
          navigate({
            pathname: `/workflows/${c.id}`,
          });
          setOpened(false);
        },
      },
      {
        content: 'View Last Run',
        onAction: () => {
          navigate({
            pathname: `/workflows/view/${c.id}`,
          });
          setOpened(false);
        },
      },
      {
        content: 'Edit Workflow',
        // icon: () => <Icon name="edit" />,
        disabled: !c.canEdit,
        onAction: () => {
          navigate({
            pathname: `/workflows/create/${c.id}`,
          });
          setOpened(false);
        },
      },
      {
        content: 'Rename Workflow',
        // icon: () => <Icon name="refresh" />,
        disabled: !c.canEdit,
        onAction: () => {
          setSequences((old) => {
            return old.map((x) => {
              if (x.id === c.id) {
                return {
                  ...x,
                  renameMode: true,
                };
              }
              return x;
            });
          });
        },
      },
      {
        content: 'Run Workflow',
        // icon: () => <Icon name="play-arrow" />,
        onAction: () => {
          const sequence = sequences.find((s) => s.id === c.id);
          if (!sequence) {
            return;
          }

          const params = new URLSearchParams(search);

          params.set('run', 'true');
          navigate({
            pathname: `/workflows/create/${sequence.id}`,
            search: params.toString(),
          });

          setOpened(false);

          genericEventLogger(analyticsEvents.CHAT, {
            action: chatActions.RUN_SEQUENCE,
            id: sequence.id,
            text: sequence.name || '',
            conversationId: sequence.id,
          });
        },
      },
      {
        content: 'Duplicate Workflow',
        onAction: async () => {
          const sequence = sequences.find((s) => s.id === c.id);
          if (!sequence) {
            return;
          }

          const conf = await confirm({
            title: 'Duplicate Workflow?',
            message: 'This will make a copy of the current workflow.',
          });
          if (!conf) return;

          const { error, success, message, conversationData } = await duplicateSequence(sequence);

          if (error) {
            toast.error(error);
          } else if (success) {
            genericEventLogger(analyticsEvents.SEQUENCES, {
              action: sequencesActions.DUPLICATE_SEQUENCE,
              sequence_id: conversationData?.id,
              sequence_name: conversationData?.name,
            });
            toast.success(message);
          }

          toggleSequenceActionMenu(c.id, false);
        },
      },
      // {
      //   content: 'Last Runs',
      //   // icon: () => <Icon name="clock" />,
      //   onAction: () => {
      //     setOpened(false);
      //     setRunsOpen(c.id);
      //   },
      // },
      ...(isTwGlobalDashboardCreatorClaim && !c.isGlobal
        ? [
            {
              content: 'Turn Into Template',
              // icon: () => <Icon name="global" />,
              onAction: async () => {
                const conf = await confirm({
                  title: 'Turn Workflow into template?',
                  message:
                    'This will make a copy in its current state, and publish to the template library.',
                });
                if (!conf) return;

                const newSequenceTemplate: CreateNewSequenceProps = {
                  shopId: currentShopId!,
                  userId: c.user,
                  dialect: c.dialect ?? dialect,
                  conversationId: c.conversationId,
                  messages: c.steps ?? c.messages ?? [],
                  baseMainElement: {
                    id: uuidV4(),
                    type: 'sequence' as WillyElementType,
                    canEdit: false,
                    isGlobal: true,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                    name: c.name ?? '',
                    description: c.description ?? '',
                    emoji: c.emoji ?? (emptyEmoji as WillyEmoji),
                    roles: c.roles ?? [],
                    isBeta: c.isBeta ?? false,
                    isHide: c.isHide ?? false,
                    image: c.image,
                    category: c.category ?? '',
                    dialect: c.dialect ?? dialect ?? 'clickhouse',
                    providers: c.providers ?? [],
                    providersBlockingCombination: c.providersBlockingCombination ?? 'NONE',
                    packages: c.packages ?? [],
                    defaultPackages: c.defaultPackages ?? [],
                    msps: c.msps ?? '',
                  },
                };

                const { error, success, message, conversationData } =
                  await createNewSequence(newSequenceTemplate);

                if (error) {
                  toast.error(error);
                } else if (success) {
                  genericEventLogger(analyticsEvents.SEQUENCES, {
                    action: sequencesActions.CREATE_SEQUENCE,
                    sequence_id: conversationData?.id,
                    sequence_name: conversationData?.title,
                  });
                  toast.success(message);
                }

                toggleSequenceActionMenu(c.id, false);
              },
            },
          ]
        : []),
    ],
    [
      isTwGlobalDashboardCreatorClaim,
      navigate,
      setOpened,
      setSequences,
      sequences,
      search,
      toggleSequenceActionMenu,
      currentShopId,
      dialect,
    ],
  );

  const scheduleText = useCallback((c: WillyDataSequence) => {
    if (!c.schedule) return '-';

    if (c.schedule.status === 'running') return 'Running';
    if (c.schedule.status === 'paused') return 'Paused';
    if (c.schedule.status === 'scheduled') {
      const now = moment();
      const nextRun = moment();
      const daysOfWeek = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ];

      // Find the next scheduled day
      let daysUntilNext = 7;
      for (const day of c.schedule.days) {
        const dayIndex = daysOfWeek.indexOf(day.toLowerCase());
        const daysUntil = (dayIndex - now.day() + 7) % 7;
        if (daysUntil < daysUntilNext) {
          daysUntilNext = daysUntil;
        }
      }

      nextRun.add(daysUntilNext, 'days');

      // Set the hour for the next run
      const scheduledHours = c.schedule.hours.map(Number);
      scheduledHours.sort((a, b) => a - b);

      let nextHour = scheduledHours[0] || 0;
      for (const hour of scheduledHours) {
        if (daysUntilNext === 0 && hour > now.hour()) {
          nextHour = hour;
          break;
        }
      }

      nextRun.hour(nextHour).minute(0).second(0);

      // If nextRun is in the past, find the next available hour
      if (nextRun.isBefore(now)) {
        const nextHourIndex = scheduledHours.find((hour) => hour > now.hour());
        if (nextHourIndex !== undefined) {
          nextRun.hour(nextHourIndex).minute(0).second(0);
        } else {
          // If no future hour today, move to the first hour of the next scheduled day
          nextRun.add(1, 'days').hour(scheduledHours[0]).minute(0).second(0);
        }
      }

      return `${nextRun.format('MMM D, h:mm A')}`;
    }

    return '-';
  }, []);

  const scheduleDate = useCallback((c: WillyDataSequence) => {
    const date = c.updatedAt ?? c.createdAt;
    return moment(date?.seconds * 1000 + date?.nanoseconds / 1000000)?.fromNow();
  }, []);

  const [bulkActionItems, setBulkActionItems] = useState<WillyDataSequence[]>([]);
  const showNewDesign = useMemo(() => (isPage && !isSmall ? true : false), [isPage, isSmall]);

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMoreItems) {
          setDisplayCount((prev) => prev + ITEMS_PER_PAGE);
        }
      },
      {
        root: scrollContainerRef.current,
        threshold: 0.1,
      },
    );

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [hasMoreItems]);

  return (
    <div
      ref={scrollContainerRef}
      className="flex flex-col overflow-hidden h-full xl:max-w-[85%] m-auto"
    >
      <div className={`${showNewDesign ? 'p-8 pb-0' : 'p-4'} flex gap-4 w-full`}>
        {showNewDesign ? (
          <div className="w-full flex gap-6.5">
            <div className="w-full flex items-center gap-4">
              <WillySearchInput
                value={freeSearch}
                onChange={(v) => setFreeSearch(v)}
                placeholder="Search Workflows"
                className="!p-0 overflow-visible w-full"
              />
              {hasSearchFilterOrSort && (
                <div className="flex-shrink-0">
                  <Button
                    variant="white"
                    onClick={() => {
                      setFreeSearch('');
                      handleFilterChange(userFilterOptions[0].value);
                      handleSortChange(userSortOptions[0].value);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between flex-shrink-0 gap-4">
              <WillySelect
                data={userFilterOptions.map((item) => ({ value: item.value, label: item.label }))}
                value={userFilter}
                onChange={handleFilterChange}
                targetClassName="mr-4"
              />
              <WillySelect
                data={userSortOptions.map((item) => ({ value: item.value, label: item.label }))}
                value={userSort}
                onChange={handleSortChange}
              />
            </div>
          </div>
        ) : (
          <>
            <WillySearchInput
              value={freeSearch}
              onChange={(v) => setFreeSearch(v)}
              placeholder="Search Workflows"
              className="!p-0 overflow-visible w-full"
            />
            <DropDown
              handleSelect={(v) => setUserFilter(v)}
              options={userFilterOptions}
              value={userFilter}
            />
          </>
        )}
      </div>
      <div>
        {!sequences.length && !isPage && (
          <div className="p-4">
            <p>There are no workflows yet. Create one to get started</p>
          </div>
        )}
      </div>

      {!isPage && (
        <div className="flex items-center gap-4 w-full justify-start p-4 pt-0 border-b border-gray-100">
          <Button
            onClick={() => {
              navigate({
                pathname: `/workflows/create`,
              });
            }}
            leftSection="plus-1"
            iconSize={20}
          >
            New Workflow
          </Button>
          <Link
            to={{
              pathname: '/workflows',
              search: '?filter=templates',
            }}
            className="no-underline"
          >
            <Button leftSection={<Icon color="white" name="button-play" />}>
              Workflow Library
            </Button>
          </Link>
        </div>
      )}
      <div
        className={`overflow-auto h-full ${!showNewDesign ? 'h-[calc(100vh-312px)] sm:h-full' : ''}`}
      >
        <div className={showNewDesign ? 'w-full min-w-[768px]' : ''}>
          {showNewDesign && (
            <div className="grid grid-cols-[minmax(auto,40%)_1fr] gap-4 w-full pt-14 px-8 ">
              <div className="flex items-center justify-between h-10">
                {bulkActionItems.length > 0 && (
                  <Button
                    loading={deleting}
                    leftSection="trash"
                    variant="white"
                    size="xs"
                    onClick={async () => {
                      const conf = await confirm({
                        title: 'Delete selected workflows?',
                        message: 'This action cannot be undone',
                      });
                      if (!conf) return;
                      await deleteSequences(bulkActionItems);
                      setBulkActionItems([]);
                    }}
                  >
                    Delete Selected
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-4 gap-4">
                <Text color="gray.5" fw="500" size="sm">
                  Active
                </Text>
                <Text color="gray.5" fw="500" size="sm">
                  Scheduled
                </Text>
                <Text color="gray.5" fw="500" size="sm">
                  Last Edited
                </Text>
                <div className="text-right">
                  <Text color="gray.5" fw="500" size="sm">
                    Actions
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className={`${showNewDesign ? 'p-8' : 'p-4'} flex flex-col gap-6 w-full`}>
            {sortedSequences.length === 0 && (
              <div className="p-4">
                <p>No workflows found</p>
              </div>
            )}
            {sortedSequences.length > 0 && (
              <>
                {paginatedSequences.map((sequence, i) => (
                  <SequenceListItem
                    key={i}
                    sequence={sequence}
                    showNewDesign={showNewDesign}
                    isTwGlobalDashboardCreatorClaim={isTwGlobalDashboardCreatorClaim}
                    bulkActionItems={bulkActionItems}
                    setBulkActionItems={setBulkActionItems}
                    setSequences={setSequences}
                    toggleSequenceActionMenu={toggleSequenceActionMenu}
                    deleteSequences={deleteSequences}
                    menuItems={menuItems}
                    scheduleText={scheduleText}
                    scheduleDate={scheduleDate}
                  />
                ))}

                {hasMoreItems && (
                  <div ref={loadMoreRef} className="flex items-center justify-center h-12">
                    <Loader size="sm" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
