import {
  FilterComparator,
  InsightsFilter,
  InsightsFilterQuery,
} from '@tw/types/module/services/insights';

type SingleQuery = InsightsFilterQuery[0];

export const getUniqueId = (): string => {
  return Math.random().toString().split('.')[1];
};

export const isFilterDirty = (filter: SingleQuery) => {
  return (
    filter.value ||
    filter.value === 0 ||
    ((filter.value1 || filter.value1 === 0) && (filter.value2 || filter.value2 === 0)) ||
    [FilterComparator.OVER_ALL_TIME, FilterComparator.IS_SET, FilterComparator.IS_NOT_SET].includes(
      filter.comparator,
    )
  );
};

export const removeDuplicatesFromFiltersArray = (arr: InsightsFilter[]) => {
  return arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
};

export const findAppliedFilterIds = (
  filtersData: InsightsFilter[],
  applied: InsightsFilterQuery[],
) => {
  const appliedJson = applied.map((filter) => JSON.stringify(filter));
  return filtersData
    .filter((filter) => {
      const filterQuery = JSON.stringify(filter.query);
      return appliedJson.includes(filterQuery);
    })
    .map((filter) => filter.id);
};
