import { services, ServicesIds } from '@tw/types/module/services';
import { ShopIntegrationProperties } from '@tw/types/module/types/ShopProviders';

import axiosInstance from './axiosInstance';

export const callUpdateToday = async (data: {
  serviceId: ServicesIds;
  shopDomain: string;
  force: boolean;
  accountIds: ShopIntegrationProperties[] | undefined;
}) => {
  try {
    if (!data.serviceId) {
      throw new Error(`No correct service id ${data.serviceId}`);
    }
    if (!services[data.serviceId]) {
      throw new Error(`service id ${data.serviceId} not supported`);
    }

    let url: string = 'v2/job-manager/update-today';

    if (data.serviceId === 'twitter-ads') {
      url = `v2/job-manager/integration/update-today`;
    }
    return await axiosInstance.post(url, data);
  } catch (e) {
    console.error(e);
  }
};
