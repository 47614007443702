import { ActionIcon, Autocomplete, Icon } from '@tw/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const WillySearch: React.FC<{
  allSections: any[];
  setFilteredSections: (sections: any[]) => void;
}> = ({ allSections, setFilteredSections }) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue('');
  }, [allSections]);

  const sectionsFilterOptions = useMemo(
    () =>
      allSections.map((x) => ({
        value: x?.id ?? x?.name,
        label: x?.title ?? x?.name,
      })),
    [allSections],
  );
  const [sectionsAsArray, setSectionsAsArray] = useState(sectionsFilterOptions);

  const handleInput = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === '' || !value) {
        setSectionsAsArray(sectionsFilterOptions);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = sectionsFilterOptions.filter((option) =>
        option.label.match(filterRegex),
      );
      setSectionsAsArray(resultOptions);
      setFilteredSections(
        allSections.filter((x) => resultOptions.map((r) => r.label).includes(x?.title ?? x?.name)),
      );
    },
    [allSections, sectionsFilterOptions, setFilteredSections],
  );

  const fillSections = () => {
    setSectionsAsArray(
      allSections.map((section) => ({
        value: section?.id ?? section?.name,
        label: section?.title ?? section?.name,
      })),
    );
    setFilteredSections(allSections);
  };

  const updateSelection = useCallback(
    (value) => {
      const label = sectionsAsArray.find((s) => s.value === value)?.label;
      if (!label) return;
      setInputValue(label);
      setFilteredSections(allSections.filter((s) => s?.title ?? s?.name === label));
    },
    [allSections, sectionsAsArray, setFilteredSections],
  );

  return (
    <Autocomplete
      radius="xs"
      limit={15}
      maxDropdownHeight={400}
      data={sectionsAsArray}
      icon={<Icon name="search-major" size={14} />}
      onChange={handleInput}
      onOptionSubmit={updateSelection}
      value={inputValue}
      aria-label="Search..."
      placeholder="Search..."
      autoComplete="off"
      rightSection={
        <ActionIcon
          onClick={() => {
            fillSections();
            setInputValue('');
          }}
          radius="xs"
          icon={<Icon name="close" size={12} />}
          variant="transparent"
        />
      }
    />
  );
};
