import { SortDirection } from '@shopify/polaris';
import {
  AllMetrics,
  AttributionDateModels,
  AttributionModels,
  Influencer,
  MetricsBreakdown,
  MetricsFilterExpression,
} from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';

import { BaseColumn } from '../components/library/TWTable/types';
import { AttributionFilter } from './attribution';
import { MetricsKeys } from './metrics';

export type influencer = {
  id?: string;
  name: string;
  profile_photo?: { name: string; type: string; url: string };
  profile_file?: Array<File>;
  destination_url?: string;
  source?: ServicesIds;
  affluencer_source?: string;
  tracking_url?: string;
  social_handle?: string;
  discount_code?: string;
  discount_value?: number;
  discountCodes?: discountCode[];
  codeStrings?: string[];
  created_at?: string;
  files?: Array<File | any>;
  creatives: Array<creativeType>;
  metrics?: Partial<Record<MetricsKeys, number>>;
  metricsBreakdown?: MetricsBreakdown[];
  color?: string;
  active?: boolean;
  is_auto_created?: boolean;
  shouldDelete?: boolean;
  shareLink?: string;
  expenses: influencerExpense[];
  links?: links[];
  adsets?: Array<any>;
  esKey?: string;
  entity?: string;
  urlParams?: Array<any>;
  hasExpenses?: boolean;
  unmatchedIds?: string[];
  spend?: number;
};

export type discountCode = {
  code: string;
  // campaign: string;
  // platform: string;
  value?: number;
  start_date?: string;
  end_date?: string;
  isNew?: boolean;
  value_type?: 'percentage' | 'fixed_amount';
};

export type links = {
  id: string;
  url?: string;
  campaign: string;
  source: string;
  link: string;
};
export type influencerExpense =
  | {
      id: string;
      type?: 'time_based' | 'fixed';
      spend_type?: 'time_based' | 'fixed';
      amount: number;
      amount_type: 'currency';
      start: string;
      end: string | null;
      recurring?: 'weekly' | 'monthly' | 'one_time' | 'week' | 'month';
      recurring_cadence?: 'one_time' | 'week' | 'month';
      recurring_times?: number;
      recurring_interval_length?: number;
      influencer_id: string;
    }
  | {
      id: string;
      type?: 'order_based' | 'per_order';
      spend_type?: 'order_based' | 'per_order';
      amount: number;
      amount_type: 'percentage' | 'currency';
      start: string;
      end: string;
      influencer_id: string;
    };

export function isTimeBasedExpense(
  expense: influencerExpense,
): expense is influencerExpense & { type: 'time_based' } {
  return expense.type === 'time_based' || expense.spend_type === 'fixed';
}

export interface creativeType {
  url: string;
  type: string;
  name?: string;
  thumbnail?: string;
  downloadLink?: string;
  created_at?: string;
  created_by?: string;
  deleted_at?: string;
}

export interface filesType {
  [key: string]: {};
}

export interface SelectableInfluencer extends ExtendedInfluencer {
  selected?: boolean;
}

type ExtendedInfluencer = influencer & Partial<Record<MetricsKeys, number>>;

export type InfluencersContextType = {
  influencers: SelectableInfluencer[];
  loading: boolean;
  copiedToClipboard: boolean;
  sortBy: MetricsKeys;
  sortDirection: SortDirection;
  columns: BaseColumn<any, any>[];
  uploadProgress: { [key: string]: number };
  profileUploadProgress: { [key: string]: number };
  uploadError: string;
  discountCodeError: string;
  //priceRules: Array<any>;
  tableCreativeFilterBy: string;
  loadedDiscountCodes: Array<any>;
  shopId: string;
  selectedInfluencers?: SelectableInfluencer[];
  currency: string;
  selectedColumns: BaseColumn<any, any>[];
  //selectedTab: number;
  //tabs: TabDescriptor[];
  pageIndex: number;
  totalInfluencers: number;
  displayMode: DisplayMode;
  uploadingInProgress: boolean;
  setDisplayMode: React.Dispatch<React.SetStateAction<DisplayMode>>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setTotalInfluencers: React.Dispatch<React.SetStateAction<number>>;
  setSelectedColumns: (columns: BaseColumn<any, any>[]) => void;
  editInfluencer: (influencer: SelectableInfluencer) => void;
  deleteInfluencer: (influencer: SelectableInfluencer) => void;
  toggleSelected: (influencer: SelectableInfluencer) => void;
  toggleActive: (influencer: SelectableInfluencer) => void;
  handleCreativeUpload: (influencer: SelectableInfluencer, files: Array<File> | undefined) => void;
  handleCreativeDeleted: (
    influencer: SelectableInfluencer,
    creativeName: string | undefined,
    creativeSrc: string,
  ) => void;
  uploadProfilePhoto: (influencer: SelectableInfluencer, file: File[]) => void;
  setSortBy: (a: MetricsKeys) => void;
  setSortDirection: (a: SortDirection) => void;
  toggleCopiedToClipboard: () => void;
  setCopiedToClipboard: (a: boolean) => void;
  showLightbox: (c: creativeType[], index: number) => void;
  setUploadError: (m: string) => void;
  setTableCreativeFilterBy: (m: string) => void;
  setLoading: (m: boolean) => void;
  searchDiscountCodes: (m: string) => void;
  influencersDispatch: (v: any) => void;
  loadDefaultDiscountCodes: () => void;
  loadInfluencers: (pageIndex: any, initial?: boolean) => Promise<void>;

  // handleTabChange: (i: number) => void;
  //filterCreatives: (creatives: Array<creativeType>) => Array<creativeType>;
};

export type DisplayMode = 'table' | 'card';

export const MAX_ACTIVE_INFLUENCERS = 8;

export const INFLUENCERS_COLORS = [
  '#10B981',
  '#EA643D',
  '#B0446A',
  '#3159F3',
  '#10ABA4',
  '#7C5FF8',
  '#ffc107',
  '#9c27b0',
];

export type ChartData = 'influencer' | 'platform';
export type ChartMode = 'line' | 'bar';

export type InfluencerAttributionRequest = {
  shopDomain: string;
  model: AttributionModels;
  startDate: string;
  endDate: string;
  dateModel: AttributionDateModels;
  //currency: string;
  //shopCurrency: string;
  timezone?: string;
  page?: number;
  sort_by?: string;
  sort_direction?: string;
  freeSearch?: string;
  isPixel?: boolean;
  attributionWindow?: string;
  useNewModels?: boolean;
  isAffluencerHub?: boolean;
  breakdown?: string;
  attributionFilters?: AttributionFilter[];
  accountIds?: Partial<Record<ServicesIds, string[]>>;
  currency?: string;
  useNexus?: boolean;
  useClickhouse?: boolean;
  forceSharded?: boolean;
  useRocket?: boolean;
};
