import Cookies from '../utils/Cookies';

export const HIDE_ONBOARDING_CONNECT_SERVICES_SECTION = 'HIDE_ONBOARDING_CONNECT_SERVICES_SECTION';
export const hideOnboardingConnectServicesSection = () => {
  return (dispatch) => {
    Cookies.set('hideOnboardingConnectServicesSection', true as any);
    dispatch({
      type: HIDE_ONBOARDING_CONNECT_SERVICES_SECTION,
    });
  };
};

const onboardingConnectServicesSectionHiddenByUser = (
  state = Cookies.get('hideOnboardingConnectServicesSection') || false,
  action,
) => {
  switch (action.type) {
    case HIDE_ONBOARDING_CONNECT_SERVICES_SECTION:
      return true;
    default:
      return state;
  }
};

export const HIDE_ONBOARDING_CONNECT_APPS_SECTION = 'HIDE_ONBOARDING_CONNECT_APPS_SECTION';
export const hideOnboardingConnectAppsSection = () => {
  return (dispatch) => {
    Cookies.set('hideOnboardingConnectAppsSection', true as any);
    dispatch({
      type: HIDE_ONBOARDING_CONNECT_APPS_SECTION,
    });
  };
};

const onboardingConnectAppsSectionHiddenByUser = (
  state = Cookies.get('hideOnboardingConnectAppsSection') || false,
  action,
) => {
  switch (action.type) {
    case HIDE_ONBOARDING_CONNECT_APPS_SECTION:
      return true;
    default:
      return state;
  }
};

export const reducers = {
  onboardingConnectServicesSectionHiddenByUser,
  onboardingConnectAppsSectionHiddenByUser,
};
