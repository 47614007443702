import { useCallback, useEffect, useState } from 'react';
import {
  ShopServiceData,
  TiktokConfig,
  tiktokPurchasesEvent,
} from '@tw/types/module/types/ShopServiceData';
import { Button, RadioButton, Card, Modal } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import type { RootState } from 'reducers/RootType';
import { toast } from 'react-toastify';
import {
  analyticsEvents,
  genericEventLogger,
  tiktokActions,
  tiktokMessages,
} from 'utils/dataLayer';

import {
  onToggleTiktokPurchaseEventSettingsOpen,
  onTiktokPurchaseEventSettingsClose,
} from 'ducks/tiktok';
import { useAppDispatch } from 'index';
import { useQueryParams } from 'utils/useQueryParams';

export const TikTokSettings = ({ done }) => {
  const dispatch = useAppDispatch();
  const { integrationConnected, ['service-id']: serviceId } = useQueryParams();
  const [tiktokButtonLoading, setTiktokButtonLoading] = useState<boolean>(false);
  const [tiktokPurchaseEvent, setTiktokPurchaseEvent] = useState<tiktokPurchasesEvent>();
  const isTiktokPurchaseEventSettingsOpen = useSelector(
    (state: RootState) => state.isTiktokPurchaseEventSettingsOpen,
  );
  const shopDomain = useSelector((state: RootState) => state.currentShopId);
  const firestore = firebase.firestore;

  const getTiktokPurchaseEvent = async () => {
    return (
      ((await getServiceConfiguration('tiktok-ads')) as TiktokConfig)?.purchasesEvent ||
      tiktokPurchasesEvent.COMPLETE_PAYMENT
    );
  };

  const handleTiktokPurchaseEventChange = useCallback(
    (_checked, newValue) => setTiktokPurchaseEvent(newValue),
    [],
  );

  useEffect(() => {
    if (!tiktokPurchaseEvent && isTiktokPurchaseEventSettingsOpen) {
      getTiktokPurchaseEvent().then((a) => setTiktokPurchaseEvent(a));
    }
  }, [tiktokPurchaseEvent, isTiktokPurchaseEventSettingsOpen]);

  const saveTiktokPurchaseEvent = async () => {
    try {
      setTiktokButtonLoading(true);
      const data: TiktokConfig = { purchasesEvent: tiktokPurchaseEvent };
      let doc = await getServiceCollection('tiktok-ads');
      await doc.set(
        {
          serviceConfiguration: data,
        },
        { merge: true },
      );
      toast.success('TikTok settings saved');
      genericEventLogger(analyticsEvents.TIKTOK, {
        action: tiktokActions.SETTINGS_SAVED,
        shop: shopDomain,
        message: tiktokMessages.SETTINGS_SAVED,
      });
      done();
    } catch (e) {
      toast.error(
        'TikTok settings not saved. Please try again or reach out to the Customer Experience team.',
      );
      genericEventLogger(analyticsEvents.TIKTOK, {
        action: tiktokActions.SETTINGS_ERROR,
        shop: shopDomain,
        message: tiktokMessages.SETTINGS_ERROR(shopDomain),
        error: e,
      });
    } finally {
      setTiktokButtonLoading(false);
    }
  };

  const getServiceCollection = async (serviceId) => {
    return firestore().collection('shops').doc(shopDomain).collection('services').doc(serviceId);
  };

  const getServiceConfiguration = async (serviceId) => {
    return ((await (await getServiceCollection(serviceId)).get()).data() as ShopServiceData)
      ?.serviceConfiguration;
  };

  useEffect(() => {
    if (integrationConnected && serviceId === 'tiktok-ads')
      dispatch(onToggleTiktokPurchaseEventSettingsOpen());
  }, [dispatch, integrationConnected, serviceId]);

  return (
    <Modal
      open={isTiktokPurchaseEventSettingsOpen}
      title="TikTok"
      onClose={() => dispatch(onTiktokPurchaseEventSettingsClose())}
    >
      <Card.Section title="Purchase Event">
        <p className="pb-3 pt-5">
          Choose a TikTok event that will represent Purchases in the Triple Whale platform.
        </p>
        <div>
          <RadioButton
            label="Complete Payment"
            checked={tiktokPurchaseEvent === 'completePayment'}
            id="completePayment"
            onChange={handleTiktokPurchaseEventChange}
          />
        </div>
        <div>
          <RadioButton
            label="Place an Order"
            id="placeAnOrder"
            checked={tiktokPurchaseEvent === 'placeAnOrder'}
            onChange={handleTiktokPurchaseEventChange}
          />
        </div>
        <div className="pt-6">
          <Button loading={tiktokButtonLoading} primary onClick={() => saveTiktokPurchaseEvent()}>
            Save
          </Button>
        </div>
      </Card.Section>
    </Modal>
  );
};
