/** Widest the left nav can be. This includes navigation + stores side bar. */
export const WIDE_NAV = 300;

/** Smallest width the left nav can be.  This includes navigation + stores side bar. */
export const NARROW_NAV = 125;

/** Largest width the main nav for 3.0 can be */
export const ALAN_MAIN_NAV = 75;

/** Max width of Willy and Apps sub menus currently */
export const MAX_SUB_MENU_WIDTH = 230;

/** Key for local storage to save if user wants wide or narrow nav */
export const PREFERS_WIDE_NAV = 'prefers_wide_nav';

/** Key to save the size of the stores nav */
export const LS_STORES_NAV_WIDTH = 'stores_nav_width';
