import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { callInitialImport } from 'utils/callInitialImport';
import { callUpdateToday } from 'utils/callUpdateToday';

import db from '../utils/DB';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import { minimizeSectionOnPress } from './summary';
import { INIT_SHOP, IntegrationSaveErrors } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';

import axiosInstance from 'utils/axiosInstance';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const ENQUIRELABS_SERVICE_ID = 'enquirelabs';

export const ENQUIRELABS_CONNECT_ON_PRESS = 'ENQUIRELABS_CONNECT_ON_PRESS';
export const enquirelabsConnectOnPress = () => {
  return {
    type: ENQUIRELABS_CONNECT_ON_PRESS,
  };
};

export const TOGGLE_ENQUIRELABS_CONFIG_SCREEN_OPEN = 'TOGGLE_ENQUIRELABS_CONFIG_SCREEN_OPEN';
export const toggleEnquirelabsConfigScreenOpen = () => ({
  type: TOGGLE_ENQUIRELABS_CONFIG_SCREEN_OPEN,
});

export const ENQUIRELABS_CONFIG_SAVE = 'ENQUIRELABS_CONFIG_SAVE';
export const enquirelabsConfigSave = () => {
  return {
    type: ENQUIRELABS_CONFIG_SAVE,
  };
};

export const SET_SERVICE_CONNECTED = 'SET_SERVICE_CONNECTED';
export function enquirelabsConfigSavePress({ enquirelabsKey, apiKey }, setSaveError) {
  return (dispatch, getState) => {
    if (enquirelabsKey === apiKey) {
      setSaveError(IntegrationSaveErrors.UNIQUE_ERROR);
      return;
    }
    dispatch(enquirelabsTokenSave(enquirelabsKey));
    return db()
      .update({
        enquirelabsToken: enquirelabsKey,
      })
      .then(async () => {
        const shopId = getState().currentShopId;
        const apiKeyValid = await cheakApiKeyIsValid(shopId, apiKey);
        switch (apiKeyValid.status) {
          case 200:
            dispatch({
              type: SET_SERVICE_CONNECTED,
            });
            toggleSaveIndicator();
            dispatch(enquirelabsConfigSave());
            dispatch(startImportingEnquirelabsData());
            dispatch(minimizeSectionOnPress(ENQUIRELABS_SERVICE_ID, false));
            break;
          case 401:
            setSaveError(IntegrationSaveErrors.ENQUIRELABS_INVALID_API_KEY);
            break;
          default:
            setSaveError(IntegrationSaveErrors.CONNECTION_ERROR);
        }
      });
  };
}

async function cheakApiKeyIsValid(shopId: string, apiKey: string) {
  const url = `/v2/enquirelabs/check-api-key?shopId=${shopId}`;
  try {
    return await axiosInstance.post(url, { api_key: apiKey });
  } catch (e) {
    return e;
  }
}

export const startImportingEnquirelabsData = () => async (dispatch) => {
  await callInitialImport(ENQUIRELABS_SERVICE_ID);
  dispatch(setOptimisticImporting(ENQUIRELABS_SERVICE_ID));
  window.scrollTo(0, 0);
};

export const ENQUIRELABS_DISCONNECT = 'ENQUIRELABS_DISCONNECT';
export function enquirelabsDisconnect() {
  return (dispatch, getState) => {
    toggleSaveIndicator();
    // TODO: would be umcommented later...
    //callRemoveShopMetrics({ service_id: ENQUIRELABS_SERVICE_ID, shop_domain: getState().currentShopId });
    const url = `v2/enquirelabs/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('enquirelabsToken'));
        dispatch(enquirelabsDisconnectDone());
      });
  };
}

export const ENQUIRELABS_DISCONNECT_DONE = 'ENQUIRELABS_DISCONNECT_DONE';
export function enquirelabsDisconnectDone() {
  return {
    type: ENQUIRELABS_DISCONNECT_DONE,
  };
}
export const ENQUIRELABS_TOKEN_SAVE = 'ENQUIRELABS_TOKEN_SAVE';
export function enquirelabsTokenSave(token) {
  return {
    type: ENQUIRELABS_TOKEN_SAVE,
    payload: token,
  };
}

export const ENQUIRELABS_APIKEY_SAVE = 'ENQUIRELABS_APIKEY_SAVE';
export function enquirelabsApiKeySave(apiKey) {
  return {
    type: ENQUIRELABS_APIKEY_SAVE,
    payload: apiKey,
  };
}

const enquirelabsConnectConfigIsOpen = (state = false, action) => {
  switch (action.type) {
    case ENQUIRELABS_CONNECT_ON_PRESS:
      return true;
    case TOGGLE_ENQUIRELABS_CONFIG_SCREEN_OPEN:
      return !state;
    case ENQUIRELABS_CONFIG_SAVE:
      return false;
    default:
      return state;
  }
};

const isEnquirelabsConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.enquirelabsToken;
    case ENQUIRELABS_DISCONNECT_DONE:
      return false;
    case SET_SERVICE_CONNECTED:
      return true;
    default:
      return state;
  }
};

const enquirelabsToken = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.enquirelabsToken || state;
    case ENQUIRELABS_TOKEN_SAVE:
      return action.payload;
    case ENQUIRELABS_DISCONNECT_DONE:
      return '';
    default:
      return state;
  }
};

const enquirelabsApiKey = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.enquirelabsApiKey || state;
    case ENQUIRELABS_APIKEY_SAVE:
      return action.payload;
    case ENQUIRELABS_DISCONNECT_DONE:
      return '';
    default:
      return state;
  }
};

export const reducers = {
  isEnquirelabsConnected,
  enquirelabsConnectConfigIsOpen,
  enquirelabsToken,
  enquirelabsApiKey,
};
