import { ActionIcon, Button, Modal, Text } from '@tw/ui-components';

interface SyncCSVModal {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onClick: () => void;
}

export const SyncCSVModal: React.FC<SyncCSVModal> = ({ showModal, setShowModal, onClick }) => {
  return (
    <div className={'flex justify-end'}>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title={
          <Text size={'xl'} fw={600}>
            Upload CSV
          </Text>
        }
        size="lg"
      >
        <div className="w-full h-[1px] bg-[#E5E7EB] mb-10"></div>
        <Text>
          Your file needs to include a column with email address, order ID or event date in order to
          be able to join it with other data in the SQL editor.
        </Text>
        <div className="w-full h-[1px] bg-[#E5E7EB] my-10"></div>
        <Button onClick={onClick}>Upload CSV</Button>
      </Modal>
    </div>
  );
};
