import { GoogleSheetsExportModal } from 'components/ProductAnalytics/ExportModal';
import { GOOGLE_SHEETS_CSV_SAVED, exportCsvToGoogleSheets } from 'ducks/googleSheets';
import { useAppDispatch } from 'index';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { genericEventLogger, insightsActions } from 'utils/dataLayer';

type ShareProps = {
  tableCSVData?: string[][];
  googleSheetsModalOpen: boolean;
  onClose;
  title: string;
};

export const ShareGoogleSheetModal: React.FC<ShareProps> = ({
  tableCSVData,
  googleSheetsModalOpen,
  onClose,
  title,
}) => {
  const eventContext = useSelector((state: RootState) => state.insights.eventContext);
  const [fileName, setFileName] = useState<string>('');
  const dispatch = useAppDispatch();

  const { isSavingGoogleCSV } = useSelector((state: RootState) => state) || {};
  const [sheetsAccount, setSheetsAccount] = useState<string>('');

  const onCloseExportModal = useCallback(() => {
    onClose();
    dispatch({ type: GOOGLE_SHEETS_CSV_SAVED, payload: '' });
  }, []);

  const exportToGoogleSheets = useCallback(() => {
    const report = {
      values: tableCSVData,
      title: fileName || title,
      sheetsAccount: sheetsAccount,
    };
    dispatch(exportCsvToGoogleSheets(report));

    genericEventLogger(eventContext!, {
      action: insightsActions.SHARE_GOOGLE_SHEET,
    });
  }, [tableCSVData, fileName, title, sheetsAccount, dispatch, eventContext]);

  return (
    <GoogleSheetsExportModal
      showModal={googleSheetsModalOpen}
      title={fileName || title}
      setTitle={setFileName}
      onSave={exportToGoogleSheets}
      loading={isSavingGoogleCSV}
      onClose={onCloseExportModal}
      sheetsAccount={sheetsAccount}
      setSheetsAccount={setSheetsAccount}
    />
  );
};
