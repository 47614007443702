export const WILLY_NAV_ITEMS: {
  id: string;
  label: string;
  link: string;
  withHeader: boolean;
  isWorkflowPage?: boolean;
  hideSidePanel?: boolean;
}[] = [
  {
    id: 'chat',
    label: 'Chat',
    link: '/chat/shop',
    withHeader: false,
  },
  {
    id: 'feed',
    label: 'Feed',
    link: '/workflows/feed',
    withHeader: true,
    isWorkflowPage: true,
    hideSidePanel: true,
  },
  {
    id: 'history',
    label: 'Chat History',
    link: '/chat/history',
    withHeader: false,
  },
  {
    id: 'prompt_library',
    label: 'Template Library',
    link: '/chat/templates',
    withHeader: false,
  },
  {
    id: 'workflows',
    label: "Shop's Workflows",
    link: '/workflows',
    withHeader: true,
    isWorkflowPage: true,
  },
];
