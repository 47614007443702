import shopifyIcon from '../icons/shopify.svg';
import bigCommerceIcon from '../icons/bigCommerce.svg';
import wooCommerceIcon from '../icons/wooCommerce.svg';
import magentoIcon from '../icons/magento.svg';
import customIcon from '../icons/custom-msp.svg';
import stripeIcon from '../icons/stripe.svg';
import { SalesPlatform } from '@tw/types/module/services';

type SalesPlatformItem<T extends SalesPlatform> = {
  id: T;
  title: string;
  icon: string;
  validateShopURL: string;
  isBeta?: boolean;
  betaText?: boolean;
  needTZ?: boolean;
  regexValidator?: RegExp;
  regexErrorMessage?: string;
  shouldNotUseRealUrl?: boolean;
  adminPath?: string;
  domain: string | null;
  backgroundColor: string;
  metricsLink?: string;
};

type SalesPlatformDictionary = {
  [platform in SalesPlatform]: SalesPlatformItem<platform>;
};

const SALES_PLATFORMS: SalesPlatformDictionary = {
  shopify: {
    id: 'shopify',
    title: 'Shopify',
    validateShopURL: '/v2/account-manager/shops/validateAndGetShopifyShop',
    icon: shopifyIcon,
    adminPath: 'admin',
    regexValidator: /.*.\.myshopify.com$/,
    regexErrorMessage: '.myshopify.com',
    shouldNotUseRealUrl: true,
    domain: 'myshopify.com',
    backgroundColor: '#95bf47',
  },

  woocommerce: {
    id: 'woocommerce',
    title: 'WooCommerce',
    betaText: true,
    validateShopURL: '/v2/account-manager/shops/validateAndGetWooShop',
    needTZ: true,
    adminPath: 'wp-login.php',
    icon: wooCommerceIcon,
    domain: null,
    backgroundColor: '#EFD3E2',
    metricsLink:
      'https://dash.readme.com/project/triplewhale/v1.0/docs/mapping-woocommerce-metrics-to-triple-whale',
  },

  magento: {
    id: 'magento',
    title: 'Magento',
    validateShopURL: '/v2/account-manager/shops/validateAndGetMagentoShop',
    isBeta: true,
    icon: magentoIcon,
    domain: null,
    backgroundColor: '#EF43E2',
  },

  bigcommerce: {
    id: 'bigcommerce',
    title: 'BigCommerce',
    betaText: true,
    validateShopURL: '/v2/account-manager/shops/validateAndGetBigShop',
    icon: bigCommerceIcon,
    regexValidator: /^store-[^.]+\.mybigcommerce\.com$/,
    regexErrorMessage: 'store-<store-hash>.mybigcommerce.com',
    shouldNotUseRealUrl: true,
    domain: 'mybigcommerce.com',
    backgroundColor: '#D8D2EB',
    metricsLink:
      'https://kb.triplewhale.com/en/articles/9740255-mapping-bigcommerce-metrics-to-triple-whale',
  },

  stripe: {
    id: 'stripe',
    title: 'Stripe',
    validateShopURL: '/v2/account-manager/shops/validateAndGetStripeShop',
    icon: stripeIcon,
    isBeta: true,
    domain: null,
    backgroundColor: '#F5A623',
  },

  'custom-msp': {
    id: 'custom-msp',
    title: 'Custom MSP',
    validateShopURL: '/v2/account-manager/shops/validateAndGetStripeShop',
    icon: customIcon,
    isBeta: true,
    domain: null,
    needTZ: true,
    betaText: true,
    backgroundColor: '#F5A623',
  },
};

export default SALES_PLATFORMS;
