import {
  TW_LS_ATTRIBUTION_DATE_MODEL_KEY,
  TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
  TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW,
  TW_LS_ATTRIBUTION_MODEL_KEY,
  TW_LS_ATTRIBUTION_SHOW_ACTIVE_CAMPAIGNS_ONLY,
  TW_LS_ATTRIBUTION_SUBSCRIPTION_FILTER,
  TW_LS_ATTRIBUTION_WINDOW,
  TW_LS_ATTRIBUTION_WRAP_TABLE_LINES,
  TW_LS_PPS_LOOK_BACK_WINDOW,
  TW_LS_WORKFLOW_TO_RUN,
} from 'constants/attribution';
import {
  INIT_SHOP,
  INIT_UI,
  MAIN_DATE_PICKER_SELECTION_CHANGE,
  RECEIVE_CUSTOM_SPENDS,
} from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { combineReducers, Reducer } from 'redux';
import moment from '@tw/moment-cached/module/timezone';
import {
  AttributionDateModels,
  AttributionModels,
  AttributionModelsArr,
  AttributionData,
} from '@tw/types';
import {
  ATTRIBUTION_MODEL_CHANGED,
  DATE_MODEL_CHANGED,
  ADD_ONE_DAY_VIEW,
  WRAP_TABLE_LINES,
  SHOW_ACTIVE_CAMPAIGNS_ONLY,
  ATTRIBUTION_WINDOW_CHANGED,
  ATTRIBUTION_LAST_REFRESH,
  ATTRIBUTION_CHART_OPEN_CHANGED,
  SELECTED_COLUMNS_CHANGED,
  ATTRIBUTION_FREE_SEARCH_CHANGED,
  PPS_LOOK_BACK_WINDOW_CHANGED,
  SHOW_TOTAL_IMPACT_MODAL,
  ATTRIBUTION_SUMMARY_EXTRA_SOURCES,
  ATTRIBUTION_SUMMARY_ADD_SOURCE,
  ATTRIBUTION_SUMMARY_REMOVE_SOURCE,
  USE_NEW_MODELS,
  ATTRIBUTION_SUBSCRIPTION_CHANGED,
  ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
  USE_NEXUS,
  INIT_ATTRIBUTION,
  RUN_WORKFLOW,
} from './constants';
import { AttributionPpsLookBackWindow, AttributionWindow, PixelColumn } from 'types/attribution';
import allServices from 'constants/services';
import { SourceTypes } from 'types/services';
import { TagsOptions } from 'components/attribution-new/AttributionMoreSettings';
import { isUsingPixel2 } from 'components/attribution/utils';
import { getQueryStringStartEnd } from 'utils/getQueryStringStartEnd';

export const modelIsAttribution = (model: AttributionModels): model is AttributionModels => {
  return AttributionModelsArr.includes(model);
};

export const modelIsPps = (model: AttributionModels): model is 'ppsViews' => {
  return model === 'ppsViews';
};

export const modelIsNewGeneration = (
  model: AttributionModels,
): model is
  | 'lastPlatformClick-v2'
  | 'fullFirstClick-v2'
  | 'fullLastClick-v2'
  | 'linear-v2'
  | 'linearAll-v2' => {
  return modelIsPps(model) || (modelIsAttribution(model) && model.includes('-v2'));
};

export const modelIsOldGeneration = (
  model: AttributionModels,
): model is
  | 'lastPlatformClick'
  | 'firstClick'
  | 'lastClick'
  | 'linear'
  | 'linearAll'
  | 'fullFirstClick'
  | 'fullLastClick' => {
  return modelIsAttribution(model) && !modelIsPps(model) && !model.includes('-v2');
};

const mapAttributionModelToNewGeneration = (model: AttributionModels): AttributionModels => {
  if (!modelIsAttribution(model)) {
    return 'lastPlatformClick-v2';
  } else if (modelIsNewGeneration(model)) {
    return model;
  } else if (modelIsPps(model)) {
    return model;
  } else if (modelIsOldGeneration(model)) {
    return (model + '-v2') as AttributionModels;
  } else {
    return 'lastPlatformClick-v2';
  }
};

export const reverseAttributionNewGeneration = (model: AttributionModels): AttributionModels => {
  if (!modelIsAttribution(model)) {
    return 'lastPlatformClick';
  } else if (modelIsNewGeneration(model)) {
    return model.replace('-v2', '') as AttributionModels;
  } else if (modelIsPps(model)) {
    return model;
  } else if (modelIsOldGeneration(model)) {
    return model;
  } else {
    return 'lastPlatformClick';
  }
};

const attributionModel = (
  state: AttributionModels = 'lastPlatformClick',
  action: any,
): AttributionModels => {
  switch (action.type) {
    case INIT_SHOP:
      const m =
        (localStorage.getItem(TW_LS_ATTRIBUTION_MODEL_KEY) as AttributionModels) ||
        'lastPlatformClick';
      return mapAttributionModelToNewGeneration(m);
    case ATTRIBUTION_MODEL_CHANGED:
      return mapAttributionModelToNewGeneration(action.model);
    case USE_NEW_MODELS:
      let model = localStorage.getItem(TW_LS_ATTRIBUTION_MODEL_KEY) || 'lastPlatformClick';
      return mapAttributionModelToNewGeneration(model as AttributionModels);
    default:
      return mapAttributionModelToNewGeneration(state);
  }
};

const dateModel = (
  state: AttributionDateModels = 'eventDate',
  action: any,
): AttributionDateModels => {
  switch (action.type) {
    case INIT_SHOP:
      return (
        (localStorage.getItem(TW_LS_ATTRIBUTION_DATE_MODEL_KEY) as AttributionDateModels) ||
        'eventDate'
      );
    case DATE_MODEL_CHANGED:
      return action.model;
    case ATTRIBUTION_MODEL_CHANGED:
      return action.model === 'ppsViews' ? 'eventDate' : state;
    default:
      return state;
  }
};

const includeOneDayView: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW)!) || state;
    case ADD_ONE_DAY_VIEW:
      return action.addOneDayView;
    case ATTRIBUTION_MODEL_CHANGED:
      return (action.model as AttributionModels).includes('lastPlatformClick') ? state : false;
    default:
      return state;
  }
};

const wrapTableLinesMode: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_WRAP_TABLE_LINES)!) || state;
    case WRAP_TABLE_LINES:
      return action.wrap;
    default:
      return state;
  }
};

const showOnlyActiveCampaigns: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return (
        JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_SHOW_ACTIVE_CAMPAIGNS_ONLY)!) || state
      );
    case SHOW_ACTIVE_CAMPAIGNS_ONLY:
      return action.only;
    default:
      return state;
  }
};

const attributionWindow: Reducer<AttributionWindow> = (state = 'lifetime', action) => {
  switch (action.type) {
    case INIT_SHOP:
      if ((localStorage.getItem(TW_LS_ATTRIBUTION_MODEL_KEY) as AttributionModels) === 'ppsViews') {
        return JSON.parse(localStorage.getItem(TW_LS_PPS_LOOK_BACK_WINDOW)!) || '7';
      }
      return JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_WINDOW)!) || state;
    case ATTRIBUTION_MODEL_CHANGED:
      if (action.model === 'ppsViews') {
        return JSON.parse(localStorage.getItem(TW_LS_PPS_LOOK_BACK_WINDOW)!) || '7';
      } else if (!action.model.includes('-v2')) {
        if (action.model === 'lastPlatformClick') {
          const attrWindow =
            JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_WINDOW)!) || 'lifetime';
          return attrWindow;
        } else {
          localStorage.setItem(TW_LS_ATTRIBUTION_WINDOW, JSON.stringify('lifetime'));
          return 'lifetime';
        }
      } else if (action.model.includes('-v2')) {
        const attrWindow =
          JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_WINDOW)!) || 'lifetime';
        return attrWindow;
      } else {
        return state;
      }
    case ATTRIBUTION_WINDOW_CHANGED:
      return action.window;
    case PPS_LOOK_BACK_WINDOW_CHANGED:
      return action.window;
    case DATE_MODEL_CHANGED:
      return action.model === 'clickDate' ? 'lifetime' : state;
    default:
      return state;
  }
};

const attributionSubscription: Reducer<TagsOptions[]> = (
  state = ['oneTime', 'subscriptionFirstOrder', 'subscriptionRecurringOrder'],
  action,
) => {
  switch (action.type) {
    case INIT_SHOP:
      return JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_SUBSCRIPTION_FILTER)!) || state;
    case ATTRIBUTION_SUBSCRIPTION_CHANGED:
      localStorage.setItem(
        TW_LS_ATTRIBUTION_SUBSCRIPTION_FILTER,
        JSON.stringify(action.subscription),
      );
      return action.subscription;
    default:
      return state;
  }
};

const ppsLookBackWindow: Reducer<AttributionPpsLookBackWindow> = (state = '7', action) => {
  if (window.location.search?.includes('yt=1')) {
    return '33';
  }
  return state;
  // For now let’s allow only a 7-day look back window
  switch (action.type) {
    case INIT_SHOP:
      return JSON.parse(localStorage.getItem(TW_LS_PPS_LOOK_BACK_WINDOW)!) || state;
    case PPS_LOOK_BACK_WINDOW_CHANGED:
      return action.window;
    default:
      return state;
  }
};

const lastRefresh: Reducer<moment.Moment> = (state = moment(), action) => {
  switch (action.type) {
    case ATTRIBUTION_LAST_REFRESH:
      return action.timestamp;
    default:
      return state;
  }
};

const chartOpen: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case ATTRIBUTION_CHART_OPEN_CHANGED:
      return action.opened;
    default:
      return state;
  }
};

const selectedColumns: Reducer<PixelColumn[]> = (state = [], action) => {
  switch (action.type) {
    case SELECTED_COLUMNS_CHANGED:
      return action.columns || state;
    default:
      return state;
  }
};

const freeSearch: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case ATTRIBUTION_FREE_SEARCH_CHANGED:
      return action.freeSearch;
    default:
      return state;
  }
};

const totalImpactModal: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case SHOW_TOTAL_IMPACT_MODAL:
      return action.show;
    default:
      return state;
  }
};

const extraSourcesForSummaryPage: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return JSON.parse(localStorage.getItem(ATTRIBUTION_SUMMARY_EXTRA_SOURCES) || '[]') || state;
    case ATTRIBUTION_SUMMARY_ADD_SOURCE:
      return [...state, action.source];
    case ATTRIBUTION_SUMMARY_REMOVE_SOURCE:
      return state.filter((source) => source !== action.source);
    default:
      return state;
  }
};

const pixelVersion: Reducer<string> = (state = '1.0', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.pixel_version || state;
    default:
      return state;
  }
};

const useNewModels: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case INIT_UI:
      const useOldModels = action.shops?.[$currentShopId.get()!]?.useOldModels;
      return !useOldModels;
    case USE_NEW_MODELS:
      return action.useNewAttributionModels;
    default:
      return state;
  }
};

const showNewModels: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_UI:
      return (action.showNewModels || action.shops?.[$currentShopId.get()!]?.useOldModels) ?? state;
    default:
      return state;
  }
};

const includeCustomAdSpend: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      const fromLS = JSON.parse(localStorage.getItem(TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND)!);
      return fromLS ?? state;
    case RECEIVE_CUSTOM_SPENDS:
      const hasCustomAdSpend = action.customSpends?.some?.((spend) => spend.isAdSpend);
      const existsLs = localStorage.getItem(TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND) !== null;
      if (existsLs) {
        return state;
      }
      localStorage.setItem(
        TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND,
        JSON.stringify(hasCustomAdSpend),
      );
      return hasCustomAdSpend ?? state;

    case ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND:
      return action.includeCustomAdSpend ?? state;
    default:
      return state;
  }
};

const attributionData: Reducer<AttributionData[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_ATTRIBUTION:
      return action.attributions;
    default:
      return state;
  }
};

const workflowToRun: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      const fromLS = JSON.parse(localStorage.getItem(TW_LS_WORKFLOW_TO_RUN) || '[]');
      return fromLS;
    case RUN_WORKFLOW:
      return action.workflowId;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  attributionModel,
  dateModel,
  includeOneDayView,
  wrapTableLinesMode,
  showOnlyActiveCampaigns,
  attributionWindow,
  attributionSubscription,
  ppsLookBackWindow,
  lastRefresh,
  chartOpen,
  selectedColumns,
  freeSearch,
  totalImpactModal,
  extraSourcesForSummaryPage,
  useNewModels,
  showNewModels,
  includeCustomAdSpend,
  pixelVersion,
  attributionData,
  workflowToRun,
});
