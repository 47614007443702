import React, { useState } from 'react';
import { Popover, ActionList } from '@shopify/polaris';
import { IconTypes } from 'types/iconTypes';
import { ActionIcon, Button } from '@tw/ui-components';

interface item {
  content: any;
  onAction?: () => void;
  icon?: IconTypes | any;
  accessibilityLabel?: string;
}
interface props {
  items: item[];
  sections?: { title: string; items: item[] }[];
  title?: string;
}
const SettingsButton: React.FC<props> = ({ items, sections, title }) => {
  const [isPopoverActive, setIsPopoverActive] = useState(false);

  const text = title ? title : undefined;
  const activator = !text ? (
    <ActionIcon
      size="lg"
      variant="activator"
      onClick={() => setIsPopoverActive(!isPopoverActive)}
      icon="horizontal-slider"
    />
  ) : (
    <Button
      variant="activator"
      leftSection="horizontal-slider"
      onClick={() => setIsPopoverActive(!isPopoverActive)}
    >
      {text}
    </Button>
  );

  return (
    <Popover
      preventCloseOnChildOverlayClick
      active={isPopoverActive}
      activator={activator}
      onClose={() => setIsPopoverActive(!isPopoverActive)}
    >
      <ActionList items={items} sections={sections} />
    </Popover>
  );
};
export default SettingsButton;
