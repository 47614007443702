import { $observer, $store } from '@tw/snipestate';
import { toArray } from 'utils/DB';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { FormattedColor, IconName } from '@tw/ui-components';
import { $userId } from '$stores/$user';
import { otherColorSets } from 'components/Willy/useSamplePrompts';

export const $loadingGlobalRoles = $store(true);

export type Role = {
  id: string;
  name: string;
  icon: IconName;
  category: RoleCategory;
  order: number;
  color?: FormattedColor;
  labelColor?: FormattedColor;
  badgeColor?: FormattedColor;
};

export const RoleCategoryArr = ['ROLES', 'COMPANY', 'NONE'] as const;
export type RoleCategory = (typeof RoleCategoryArr)[number];

export const $globalDashboardRoles = $observer<Role[]>([], (get, set) => {
  if (!get($userId)) return;

  return firebase
    .firestore()
    .collection('willy_global_dashboard_roles')
    .get()
    .then((querySnapshot) => {
      let arr = toArray(querySnapshot).sort((x, y) => x.order - y.order);
      const workflowCatsWithColors = arr.map((role, i) => {
        const colorSet = otherColorSets[i % otherColorSets.length];
        return {
          ...role,
          color: colorSet.color,
          labelColor: colorSet.labelColor,
          badgeColor: colorSet.badgeColor,
        };
      });
      set(workflowCatsWithColors);
      $loadingGlobalRoles.set(false);
    });
});
