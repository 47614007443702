import { useState } from 'react';
import { Modal, TextContainer, TextField, Button } from '@shopify/polaris';
import { gorgiasConfigSavePress, toggleGorgiasConfigScreenOpen } from '../ducks/gorgias';
import { useAppSelector } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../$stores/willy/$subscription';

const GorgiasConfig = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const [baseApiUrl, setBaseApiUrl] = useState('');

  const isOpen = useAppSelector((state) => state.gorgiasConnectConfigIsOpen);
  const isFreeShop = useStoreValue($isFreeShop);

  const isEmpty = (val) => !val || !val.length;
  const validateBaseApiUrl = () => {
    let flag = true;
    if (isEmpty(baseApiUrl)) {
      flag = false;
    }
    if (baseApiUrl.indexOf('.') < 0) flag = false;
    return flag;
  };

  return (
    <Modal
      title={'Gorgias configuration'}
      open={isOpen}
      onClose={() => dispatch(toggleGorgiasConfigScreenOpen())}
      primaryAction={{
        content: 'Save',
        onAction: () => dispatch(gorgiasConfigSavePress({ baseApiUrl })),
        disabled: !validateBaseApiUrl(),
      }}
      loading={isLoading}
    >
      <Modal.Section>
        <TextContainer>
          <TextField
            label="URL of your Gorgias"
            value={baseApiUrl}
            onChange={setBaseApiUrl}
            placeholder="Something like: https://YOUR_GORGIAS_NAME.gorgias.com/"
            autoComplete="off"
          />
        </TextContainer>
        {!isFreeShop && (
          <div>
            <br />
            Need help?{' '}
            <Button plain monochrome onClick={() => window.Intercom('show')}>
              Click here and chat with us!
            </Button>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default GorgiasConfig;
