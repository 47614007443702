import { RECEIVE_SHOPIFY_SEGMENTS } from './newShopify';
import { ShopifySegmentType } from '../../types/shopify';

const orderTags = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return action.shopifySegments?.[ShopifySegmentType.ORDER_TAGS] ?? [];
    default:
      return state;
  }
};

export const reducers = {
  orderTags,
};
