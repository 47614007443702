import { zipWith } from 'lodash';
import { BreakdownValue, WillyMetric } from './types/willyTypes';
import { useCallback, useMemo } from 'react';
import BaseChart from 'components/library/BaseChart/BaseChart';
import { Line, LineChart, Tooltip, TooltipProps } from 'recharts';
import { useAppSelector } from 'reducers/RootType';
import moment from 'moment-timezone';
import { useWindowSize } from 'utils/useWindowSize';
import { RoundingOptionsMapper } from 'ducks/summary';
import { formatNumber } from 'utils/formatNumber';
import { GradientChartDefs } from 'constants/general';
import { $currentDateRange, $prevDateRange } from '../../$stores/willy/$dateRange';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';

type WillyComparisonChartProps = {
  data: BreakdownValue;
  previousPeriodData: BreakdownValue;
  metrics: WillyMetric[];
  loading?: boolean;
};

export const WillyComparisonChart: React.FC<WillyComparisonChartProps> = ({
  data,
  previousPeriodData,
  metrics,
  loading = false,
}) => {
  const windowSize = useWindowSize();
  const isOneDay = useComputedValue($currentDateRange, (range) => {
    if (!range?.start || !range?.end) {
      return null;
    }
    return range?.start?.isSame(range?.end, 'day');
  });

  const currentDateRange = useStoreValue($currentDateRange);
  const prevDateRange = useStoreValue($prevDateRange);
  let chartData: BreakdownValue = useMemo(() => {
    const d = zipWith(data, previousPeriodData, (current, previous) => {
      return {
        metric: current?.metric,
        date: current?.date,
        datePrev: previous?.date,
        value: current?.value || 0,
        valuePrev: previous?.value,
      };
    });

    return d;
  }, [data, previousPeriodData]);

  const loadingData = useMemo(() => {
    if (!currentDateRange) {
      return [];
    }
    const { start, end } = currentDateRange;
    const len = end.diff(start, 'days');

    return Array.from({ length: len + 1 }).map((_, i) => {
      return {
        date: start.clone().add(i, 'days').format('YYYY-MM-DD'),
        value: Math.random() * 1000,
        valuePrev: !prevDateRange ? undefined : Math.random() * 1000,
      };
    });
  }, [currentDateRange, prevDateRange]);

  return (
    <BaseChart
      ChartType={LineChart}
      data={loading ? loadingData : chartData}
      height={200}
      wrapperStyle={{ padding: '0 2rem 0 0' }}
      margin={{ top: 30, left: 0, bottom: 30, right: 10 }}
      xAxis={
        loading
          ? []
          : [
              {
                tickFormatter: (value: string) => {
                  if (isOneDay) {
                    return moment().hour(+value).minutes(0).format('HH:mm');
                  }
                  return moment(value).format('MMM D');
                },
                dy: 20,
                dataKey: 'date',
              },
            ]
      }
      yAxis={[
        {
          tickFormatter: (value, index) => {
            if (index % 2 !== 0) {
              return '';
            }
            return +value < 1000 ? value : +value / 1000 + 'K';
          },
          dx: 0,
          domain: [0, 'auto'],
        },
      ]}
    >
      <defs>
        <GradientChartDefs metrics={[]} />
      </defs>
      <Line
        stroke={loading ? 'url(#loading)' : `url(#summary-like-chart-color)`}
        dataKey="value"
        strokeWidth={windowSize.isSmall ? 1 : 2}
        activeDot={false}
        dot={false}
      />
      <Line
        stroke={loading ? 'url(#loading)' : `url(#summary-like-chart-color)`}
        dataKey="valuePrev"
        strokeWidth={windowSize.isSmall ? 1 : 2}
        strokeDasharray="3,3"
        activeDot={false}
        dot={false}
      />
      {!loading && <Tooltip content={<ComparisonTooltip metrics={metrics} />} />}
    </BaseChart>
  );
};

const ComparisonTooltip: React.FC<
  TooltipProps<number, 'value' | 'valuePrev'> & { metrics: WillyMetric[] }
> = (props) => {
  const { payload, active, metrics } = props;
  const { metric, date, datePrev }: { metric: string; date: string; datePrev: string } =
    payload?.[0]?.payload || {};
  const metricObj = metrics.find((m) => m.key === metric);
  const { toFixed, format } = metricObj || {};
  const currency = useStoreValue($currency);
  const defaultRoundingOption = useAppSelector((state) => state.defaultRoundingOption);

  const currencyRounding = useCallback(
    (metric?: WillyMetric) => {
      if (metric?.format === 'currency') {
        return RoundingOptionsMapper[defaultRoundingOption];
      }
      return null;
    },
    [defaultRoundingOption],
  );

  if (!active) return null;

  return (
    <div className="bg-white p-4 flex flex-col gap-4 rounded">
      {payload?.map((item, i) => (
        <div className="flex items-center gap-4" key={`${item.name}-${i}`}>
          <span
            className="grid grid-cols-2 grid-rows-2 border border-solid rounded-md overflow-hidden"
            style={{ borderColor: '#1877F2' }}
          >
            <span className="w-2 h-2" style={{ backgroundColor: '#1877F2' }}></span>
            <span
              className="w-2 h-2"
              style={{ backgroundColor: item.name === 'valuePrev' ? '#10F1A7' : '#1877F2' }}
            ></span>
            <span
              className="w-2 h-2"
              style={{ backgroundColor: item.name === 'valuePrev' ? '#10F1A7' : '#1877F2' }}
            ></span>
            <span className="w-2 h-2" style={{ backgroundColor: '#1877F2' }}></span>
          </span>
          <span className="font-bold">{item.name === 'valuePrev' ? datePrev : date}</span>
          {!!item.value && (
            <span>
              {formatNumber(item.value, {
                style: format || 'decimal',
                currency,
                minimumFractionDigits: currencyRounding(metricObj) ?? toFixed,
                maximumFractionDigits: currencyRounding(metricObj) ?? toFixed,
              })}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
