import './steps.scss';
import { WizardContext } from '../WizardContext';
import { Shipping } from '../../Shipping';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { TASK_STATUS } from '@tw/types/module/services/subscription-manager';
import { StepCompletionInfo } from 'types/WizardData';

export const WelcomeShipping: React.FC = () => {
  const { nextStep } = useContext(WizardContext);
  const [nextButton, setNextButton] = useState('Next');

  return (
    <div className="flex flex-col items-center flex-1 mt-[-20px]">
      <p className="step-title">Input Shipping Costs to calculate your Net Profit</p>

      <p className="subtitle text-2xl py-4">
        Tell us about your shop’s shipping costs so your net profit can be calculated throughout the
        app{' '}
      </p>

      <div className="w-full flex justify-center relative">
        <Shipping isWizard={true} setNextButton={setNextButton} />
      </div>
      <button className="step-button" onClick={nextStep}>
        {nextButton}
      </button>
    </div>
  );
};

export function useShippingCostsConnectionInfo(): StepCompletionInfo {
  const { tasks } = useSelector((state: RootState) => state.costSettingsCompletion);
  const isComplete =
    tasks.find((task) => task.name === 'Shipping')?.status === TASK_STATUS.COMPLETE;

  return {
    status: isComplete ? 'complete' : 'not-started',
    text: isComplete ? 'Data is flowing' : 'None Added',
  };
}

export const ShippingIndication: React.FC<any> = () => {
  const { status, text } = useShippingCostsConnectionInfo();

  return (
    <div className={status === 'complete' ? 'text-green' : 'text-red-400'}>
      <span>{text}</span>
    </div>
  );
};
