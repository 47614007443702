import { type OnlyOne, TWSpiceResources } from '@tw/types';
import { $derived, $store, ReadableStore } from '@tw/snipestate';
import {
  checkPermissions,
  type CheckPermissionsParams,
  type PermissionsResponse,
} from 'utils/authorization';

type CreateCharifStoreParams = {
  resourceType: (typeof TWSpiceResources)[number];
  resourceId: string | ReadableStore<string>;
  subjectType?: string;
  subjectId?: string | ReadableStore<string>;
} & OnlyOne<{
  permission: string;
  permissions: [string, ...string[]];
}>;

type CharifStore<K extends string = string> = ReadableStore<
  PermissionsResponse<K> & {
    loading: boolean;
    error: unknown;
  }
>;

/**
 * @description
 * This function creates a store that checks if the current user has the required permissions
 * to access a resource. It returns a store with the following shape:
 *
 * ```ts
 * {
 *   loading: boolean;
 *   error: unknown;
 *   subjectId: string;
 *   resourceId: string;
 *   subjectType: string;
 *   resourceType: string;
 *   [permission1]: boolean;
 *   [permission2]: boolean;
 *   ...
 *   [permissionN]: boolean;
 * }
 * ```
 */
export function createCharifStore<K extends string = string>({
  resourceType,
  resourceId,
  subjectType,
  subjectId,
  permission,
  permissions,
}: CreateCharifStoreParams): CharifStore<K> {
  if (resourceId && typeof resourceId === 'string') {
    resourceId = $store(resourceId);
  }
  if (subjectId && typeof subjectId === 'string') {
    subjectId = $store(subjectId);
  }

  const store = $derived((get) => {
    return checkPermissions<K>({
      resourceType,
      resourceId: typeof resourceId === 'string' ? resourceId : get(resourceId),
      subjectType,
      subjectId:
        typeof subjectId === 'string' || typeof subjectId === 'undefined'
          ? subjectId
          : get(subjectId),
      permission,
      permissions,
    } as CheckPermissionsParams);
  });

  return $derived((get) => {
    const s = get(store);
    return {
      ...(!s.pending && !s.error && { ...s.data }),
      loading: s.pending,
      error: s.error,
    };
  });
}
