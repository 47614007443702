import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WizardContext } from '../../WizardContext';
import { RootState } from '../../../../reducers/RootType';
import { useAppDispatch } from '../../../../index';
import { setOnboardingStatus } from '../../../../ducks/shop';
import { UsePixelInstalledConnectionInfoArgs } from './ShopifyPixelInstallationIndication';
import { StepCompletionInfo } from 'types/WizardData';

// TODO: Need to finish this stuff
export function useWooPixelInstalledConnectionInfo(
  args: UsePixelInstalledConnectionInfoArgs,
): StepCompletionInfo {
  return {
    status: 'complete',
    text: 'To do...',
  };
}

export const WooPixelInstallationIndication: React.FC<any> = (props) => {
  return <div>To do</div>;
};
