import moment from '@tw/moment-cached/module/timezone';
import axiosInstance from './axiosInstance';
import { allChannels } from 'constants/channels';
import { $currentShopId } from '$stores/$shop';
import allServices from 'constants/services';

const descriptionMapper = {
  enquirelabs: 'Customer Answer Fairing Survey',
  kno: 'Customer Answer KnoCommerce Survey',
  triplesurvey: 'Triple Survey Response',
};

export type PostPurchaseSurveyEvent = {
  type: 'ppSurvey';
  timestamp: Date;
  info: {
    platform: string;
    description: string;
    answer: string;
    icon: string;
    title: string;
    question: string;
    free_text: string;
  };
};

export async function fetchExtraEvents(order): Promise<PostPurchaseSurveyEvent[]> {
  try {
    let shopDomain = $currentShopId.get();
    const date = moment(order.created_at).format('YYYY-MM-DD');
    const params = {
      orderId: order.id,
      shopDomain,
      date,
    };
    const { data } = await axiosInstance.post(`/v2/attribution/extra-events`, params);
    let extraEvents: any[] = [];
    for (const { attributedFrom, campaignId, source, eventDate, question, free_text } of data) {
      const description = descriptionMapper[attributedFrom];
      const answer = formattedAnswer(attributedFrom, source, campaignId);
      const channel =
        attributedFrom === 'triplesurvey'
          ? Object.values(allServices).find((s) => s.serviceId === answer)
          : Object.values(allChannels).find((s) =>
              [s.general.prefix, s.general.id].includes(answer),
            )?.general;
      const title = channel?.title || answer;
      const icon = channel?.icon?.({ small: true });
      extraEvents = [
        ...extraEvents,
        {
          type: 'ppSurvey',
          timestamp: moment(eventDate).add(1, 'seconds').valueOf(),
          info: {
            platform: attributedFrom,
            description,
            answer,
            title,
            icon,
            question,
            free_text,
          },
        },
      ];
    }
    return extraEvents;
  } catch (e) {
    return [];
  }
}

const formattedAnswer = (attributedFrom, source, campaignId) => {
  if (attributedFrom !== 'triplesurvey') {
    return source;
  }
  switch (source) {
    case 'facebook':
      return 'facebook-ads';
    case 'google':
      return 'google-ads';
    case 'tiktok':
      return 'tiktok-ads';
    case 'snapchat':
      return 'snapchat-ads';
    case 'pinterest':
      return 'pinterest-ads';
    case 'influencer':
      return campaignId;
    case 'email':
    case 'triplesurvey_email':
      return 'Email';
    case 'text':
    case 'triplesurvey_text':
      return 'Text';
    case 'organic':
      return 'Referred by a friend';
    case 'other':
      return 'None of the above';
  }
  return source;
};
