import { FC, useEffect, useState } from 'react';
import { BaseReactPlayerProps } from 'react-player/base';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';

interface TiktokVideoPlayerProps extends BaseReactPlayerProps {
  videoId: string;
  accountId: string;
  onVideoReady: ({ width, height }) => any;
  coverThumbnail?: string;
}

const minHeight = 260;

export const TiktokVideoPlayer: FC<TiktokVideoPlayerProps> = ({
  videoId,
  accountId,
  coverThumbnail,
  onVideoReady,
}) => {
  const { currentShopId } = useSelector((state: RootState) => state);
  const [height, setHeight] = useState(minHeight);
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    (async function () {
      const params = {
        shop_domain: currentShopId,
        account_ids: [accountId],
        video_ids: [videoId],
      };
      const { videoUrl, height } = await getVideoInfo(params);
      setHeight(height);
      setUrl(videoUrl);
      onVideoReady({ width: 'auto', height });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, accountId]);

  return (
    <div className="player-responsive-wrapper">
      <video src={url} width={'auto'} style={{ maxWidth: 400 }} controls></video>
    </div>
  );
};

async function getVideoInfo(
  params: any,
): Promise<{ videoUrl: string; width: number; height: number }> {
  const url = `/v2/tiktok-ads/get-video-data`;
  const { data } = await axiosInstance.post(url, params);

  if (!data) {
    return { videoUrl: '', width: 0, height: 0 };
  }

  const { url: videoUrl, width, height } = data;

  return { videoUrl, width, height };
}
