import { activeCopyToClipboard } from 'ducks/actions';
import store from 'index';

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
    return successful;
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
    return false;
  } finally {
    try {
      document.body.removeChild(textArea);
    } catch {}
  }
}

export default function copyToClipboard(str?: string | null, skipToast?: boolean) {
  if (!str) {
    return false;
  }
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(str);
  }
  return navigator.clipboard.writeText(str).then(
    function () {
      if (skipToast) {
        return true;
      }
      store.dispatch(activeCopyToClipboard());
      return true;
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
      return false;
    },
  );
}
