import { WindowWidth } from 'constants/windowSizes';

export const TOP_BAR_BG = '--top-bar-bg';
export const TOP_BAR_BORDER = '--top-bar-border';
export const TOP_BAR_HEIGHT = '--top-bar-height';
export const DATE_PICKER_HEIGHT = '--date-picker-height';
/** Top bar and navigation background color - DON'T USE WITHIN TAILWIND CLASS - tw might not be able to read it*/
export const TOP_BAR_BG_VAR = `var(${TOP_BAR_BG})`;
/** App shell borders - header and nav - DON'T USE WITHIN TAILWIND CLASS - tw might not be able to read it*/
export const TOP_BAR_BORDER_VAR = `var(${TOP_BAR_BORDER})`;
/** The height of the top bar - DON'T USE WITHIN TAILWIND CLASS - tw might not be able to read it*/
export const TOP_BAR_HEIGHT_VAR = `var(${TOP_BAR_HEIGHT})`;
/** The height of the date pickers - DON'T USE WITHIN TAILWIND CLASS - tw might not be able to read it*/
export const DATE_PICKER_HEIGHT_VAR = `var(${DATE_PICKER_HEIGHT})`;

export const BASE_HEADER_HEIGHT = 60;

export const MOBILE_SUB_MENU_SIZE = 70;

export const GRID_BREAKPOINTS = {
  mobile: [0, WindowWidth.SMALL],
  tablet: [WindowWidth.SMALL, WindowWidth.MEDIUM],
  desktop: [WindowWidth.MEDIUM],
} as const;
