/**
 * This is new hook that will handle the socket connection for the sequence flow
 * I should replace useSequenceSocket with this in the future
 */

import { useEffect, useCallback } from 'react';
import { SequenceProgressEvent, StepWsStatus, WorkflowWsStatus } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $socket } from '$stores/$socket';
import { cutWorkflowResponse } from '../utils/sequences';

type SequenceFlowSocketProps = {
  sequenceId: string;
  runId?: string;
  setWsWorkflows: React.Dispatch<React.SetStateAction<Record<string, WorkflowWsStatus>>>;
  setWsSteps: React.Dispatch<React.SetStateAction<Record<string, StepWsStatus>>>;
  setMainWorkflowStopped: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useSequenceFlowSocket = (props: SequenceFlowSocketProps) => {
  const { sequenceId, runId, setWsWorkflows, setWsSteps, setMainWorkflowStopped } = props;

  const socket = useStoreValue($socket);

  const onSequenceProgress = useCallback(
    (data: SequenceProgressEvent) => {
      const { sequenceId: seqId, type, text, runId: id } = data;

      const isMainWorkflowEvent = seqId === sequenceId;

      if (!runId || !id || !seqId) {
        return;
      }

      if (runId !== id) {
        return;
      }

      switch (type) {
        case 'sequence-started':
          if (!isMainWorkflowEvent) {
            setWsWorkflows((prev) => ({
              ...prev,
              [seqId]: {
                status: 'running',
                workflowId: seqId,
              },
            }));
            return;
          }

          setWsWorkflows({
            [seqId]: {
              status: 'running',
              workflowId: seqId,
            },
          });
          setWsSteps({});
          setMainWorkflowStopped(false);
          break;
        case 'sequence-error':
          const { shouldRetry, error } = data;
          setWsWorkflows((prev) => ({
            ...prev,
            [seqId]: {
              status: 'error',
              error: `${error || text}${shouldRetry ? ' (retrying...)' : ''}`,
              workflowId: seqId,
            },
          }));

          if (seqId !== sequenceId) {
            return;
          }
          setMainWorkflowStopped(true);
          break;
        case 'sequence-done':
          const { outputFile, outputTitle, outputDescription, outputInsight } = data;
          setWsWorkflows((prev) => ({
            ...prev,
            [seqId]: {
              status: 'done',
              workflowId: seqId,
              outputFile,
              outputTitle,
              outputDescription,
              outputInsight,
            },
          }));

          if (!isMainWorkflowEvent) {
            return;
          }
          setMainWorkflowStopped(true);
          break;
        case 'progress':
          break;
        case 'step-started': {
          const { stepId } = data;
          setWsSteps((prev) => ({
            ...prev,
            [stepId]: {
              response: null,
              status: 'running',
              stepId,
              progress: undefined,
            },
          }));
          break;
        }
        case 'step-done': {
          const { result, stepId } = data;

          setWsSteps((prev) => ({
            ...prev,
            [stepId]: {
              ...prev[stepId],
              response: cutWorkflowResponse(result),
              status: 'done',
              stepId,
              progress: undefined,
            },
          }));
          break;
        }
        case 'step-error': {
          const { error, stepId } = data;
          setWsSteps((prev) => ({
            ...prev,
            [stepId]: {
              ...prev[stepId],
              error,
              status: 'error',
              stepId,
              progress: undefined,
            },
          }));
          setMainWorkflowStopped(true);
          break;
        }
        case 'step-skipped': {
          const { stepId } = data;
          setWsSteps((prev) => ({
            ...prev,
            [stepId]: { ...prev[stepId], status: 'skipped' },
          }));
          break;
        }
        case 'step-progress': {
          const { stepId } = data;
          setWsSteps((prev) => ({
            ...prev,
            [stepId]: {
              ...prev[stepId],
              progress: text,
            },
          }));
          break;
        }
        case 'step-rephrased': {
          const { stepId, newText, language } = data;
          const text = language === 'plain' ? newText : `\`\`\`${language}\n${newText}\n\`\`\``;
          setWsSteps((prev) => ({
            ...prev,
            [stepId]: { ...prev[stepId], rephrasedText: text },
          }));
          break;
        }
        default:
          break;
      }
    },
    [sequenceId, runId, setWsWorkflows, setWsSteps, setMainWorkflowStopped],
  );

  useEffect(() => {
    const func = (msg) => {
      if (msg.eventType !== 'workflow-progress') {
        return;
      }
      onSequenceProgress(msg.data);
    };
    // socket.on('sequence-progress', onSequenceProgress);
    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [socket, onSequenceProgress]);
};
