import _db, { firestoreRef } from 'utils/DB';
import { $derived, $observer } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';
import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import {
  DashboardDataWithSequenceHistory,
  WillyDataSequence,
  WorkflowResponse,
} from 'components/Willy/types/willyTypes';
import firebase from 'firebase/compat/app';

type Snapshot = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;

type SequenceWithRuns = WillyDataSequence & {
  runs?: WorkflowResponse[];
  content?: string;
};

const $reportsWithSequencesSnapshot = $observer(
  { data: null as Snapshot | null, loading: false, error: null as string | null },
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    set({ ...get(), loading: true });

    return firestoreRef()
      .collectionGroup('sequences_dashboard_reports')
      .where('shopId', '==', shopId)
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        set({ ...get(), loading: false, data: querySnapshot });
      });
  },
);

export const $reportsWithSequences = $observer(
  [] as DashboardDataWithSequenceHistory[],
  (get, set) => {
    const reportsSnapshot = get($reportsWithSequencesSnapshot);
    if (!reportsSnapshot.data || reportsSnapshot.loading) return;

    const data = get($combinedDashboard)
      .map((dash) => {
        const seqs = reportsSnapshot.data?.docs
          .reduce((acc, r) => {
            const dashboardId = r.ref.parent.parent?.id;
            const matches = dashboardId === dash.id || dashboardId === dash.globalDashboardId;
            if (matches) acc.push(r.data() as WillyDataSequence);
            return acc;
          }, [] as WillyDataSequence[])
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

        return !!seqs?.length ? { ...dash, history: seqs } : false;
      })
      .filter((d): d is DashboardDataWithSequenceHistory => !!d);

    set([...get(), ...data]);
  },
);

// Create separate snapshot stores for sequence runs
export const $sequenceRunsSnapshot = $observer(
  {} as Record<string, WorkflowResponse[]>,
  async (get, set) => {
    const currentShopId = get($currentShopId);
    if (!currentShopId) return;

    const sequences = get($shopSequences);
    if (!sequences.length) return;

    const unsubs = sequences.map((sequence) => {
      const ref = sequence.isGlobal
        ? firestoreRef().collection('global_data_sequences')
        : _db(currentShopId).collection('data_sequences');

      return ref
        .doc(sequence.id)
        .collection('runs')
        .orderBy('runAt', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => {
          const runsData = snapshot.docs.map((run) => run.data() as WorkflowResponse);

          set({ ...get(), [sequence.id]: runsData });
        });
    });

    return () => unsubs.forEach((u) => u());
  },
);

// Combine sequences with their runs
export const $sequencesWithRuns = $derived<SequenceWithRuns[]>((get) => {
  const sequences = get($shopSequences);
  const runsMap = get($sequenceRunsSnapshot);

  return sequences.map((sequence) => ({
    ...sequence,
    runs: runsMap[sequence.id] || [],
  }));
});

// Final combined items store
export const $combinedSequences = $derived((get) => {
  const reportsWithSequences = get($reportsWithSequences);
  const sequencesWithRuns = get($sequencesWithRuns);

  const reportsItems = reportsWithSequences
    ?.flatMap((report) => {
      return report.history.map((sequence) => ({
        id: sequence.sequenceId ?? report.id,
        title: `${report.name}: ${sequence.title}`,
        content: sequence.content,
        finishedAt: sequence.updatedAt ?? report.updatedAt,
        isReport: true,
        read: sequence.read ?? true,
        category: report.category,
        type: 'Report',
        outputFile: sequence.outputFile,
        outputTitle: sequence.outputTitle,
        outputDescription: sequence.outputDescription,
        outputInsight: sequence.outputInsight,
        user: sequence.user,
      }));
    })
    .reduce<typeof reportsItems>((acc, current) => {
      if (!current) return acc;
      const existingIndex = acc.findIndex((item) => item.id === current.id);
      if (existingIndex === -1) {
        acc.push(current);
      } else if (new Date(current.finishedAt) > new Date(acc[existingIndex].finishedAt)) {
        acc[existingIndex] = current;
      }
      return acc;
    }, []);

  const sequenceItems = sequencesWithRuns.reduce<typeof reportsItems>((acc, sequence) => {
    if (!sequence || sequence.deleted) return acc;

    const mostRecentRunWithOutput =
      sequence.runs?.find((run) => run.outputFile) || sequence.runs?.[0];
    if (!mostRecentRunWithOutput) return acc;

    acc.push({
      id: sequence.id,
      title: sequence.name,
      content: sequence.content || sequence.description,
      finishedAt: mostRecentRunWithOutput.finishedAt ?? sequence.updatedAt ?? sequence.createdAt,
      isReport: false,
      read: mostRecentRunWithOutput.read ?? true,
      category: sequence.category,
      type: 'Workflow',
      outputFile: mostRecentRunWithOutput.outputFile,
      outputTitle: mostRecentRunWithOutput.outputTitle,
      outputDescription: mostRecentRunWithOutput.outputDescription,
      outputInsight: mostRecentRunWithOutput.outputInsight,
      providers: sequence.providers,
      roles: sequence.roles,
      user: sequence.user,
    });

    return acc;
  }, []);

  return [...reportsItems, ...sequenceItems];
});
