import 'firebase/compat/firestore';
import db, { getShopData } from 'utils/DB';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from './constants';
import { $currentShopId } from '$stores/$shop';
import { toggleSaveIndicator } from './actions';
import axiosInstance from 'utils/axiosInstance';
import { AppDispatch } from 'index';
import { Shop } from '@tw/types';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

export const TOGGLE_BING_ACCOUNTS_CONFIG_SCREEN_OPEN = 'TOGGLE_BING_ACCOUNTS_CONFIG_SCREEN_OPEN';
export const TOGGLE_BING_CONFIG_SCREEN_OPEN = 'TOGGLE_BING_CONFIG_SCREEN_OPEN';
const BING_ACCOUNTS_CONFIG_SAVE_SUCCESS = 'BING_ACCOUNTS_CONFIG_SAVE_SUCCESS';
const BING_DISCONNECT_DONE = 'BING_DISCONNECT_DONE';
const ERRORS_MAP = {
  124: 'You must use a Microsoft Work account to connect your account. Please contact your Microsoft account’s Super Admin to get access.',
};

export const connectBing = () => {
  try {
    const url = `v2/bing/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        console.log('on success', res);
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(` ERROR `, e);
  }
};

export const disconnectBing = () => {
  return (dispatch, getState) => {
    toggleSaveIndicator();
    // callRemoveMetrics({
    //   service_id: 'bing',
    //   account_ids: getState().bingAccounts?.map((acc) => acc.id),
    // });
    const url = `v2/bing/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('bing'));
        dispatch({
          type: BING_DISCONNECT_DONE,
        });
      });
  };
};

export function bingPopupConfig() {
  return async (dispatch: AppDispatch) => {
    return getShopData().then((shopData: Shop | any) => {
      const bingAccounts = Object.keys(shopData?.bing?.bingAccounts || {});
      if (bingAccounts?.length) {
        const accountsInConfigProcess = bingAccounts.reduce((acc, account) => {
          acc[account] = { initial: false };
          return acc;
        }, {});
        return db()
          .set(
            {
              bing: {
                accountsInConfigProcess,
              },
            },
            { merge: true },
          )
          .then(() => {
            dispatch(toggleBingConfigScreenOpen());
          });
      }
    });
  };
}

export const toggleBingConfigScreenOpen = () => ({
  type: TOGGLE_BING_CONFIG_SCREEN_OPEN,
});

const bingConnectConfigIsOpen = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.bing?.connectAccountsInProgress || false;
    case TOGGLE_BING_CONFIG_SCREEN_OPEN:
      return !state;
    case BING_ACCOUNTS_CONFIG_SAVE_SUCCESS:
      return false;
    default:
      return state;
  }
};

function processErrors(serverErrors: string[]) {
  const errorMessages: string[] = [];

  serverErrors.forEach((error) => {
    const errorCodeMatch = error.match(/\(error_code: (\d+)\)/);
    if (errorCodeMatch) {
      const errorCode = parseInt(errorCodeMatch[1]);
      if (errorCode in ERRORS_MAP) {
        errorMessages.push(ERRORS_MAP[errorCode]);
        return;
      }
    }
    const serverErrorMessage = error.split('error_message:')[1].trim();
    if (serverErrorMessage) {
      errorMessages.push(serverErrorMessage);
    }
  });

  return errorMessages;
}

export const getUserAccounts = async () => {
  try {
    const url = `v2/bing/get-user-accounts?shopId=${$currentShopId.get()}`;
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (errors) {
    const errorMessages = processErrors(errors?.data || []);
    throw errorMessages || [];
  }
};

export const BingAccountsSaveSuccess = () => {
  return {
    type: BING_ACCOUNTS_CONFIG_SAVE_SUCCESS,
  };
};

export const saveBingAccountConfig = (allAccounts, selectedAccountIds) => {
  return (dispatch) => {
    toggleSaveIndicator();
    dispatch({ type: TOGGLE_BING_ACCOUNTS_CONFIG_SCREEN_OPEN });
    const accountsWithSelectedData = allAccounts.map((account) => ({
      ...account,
      selected: selectedAccountIds.includes(account.Id),
    }));

    const url = `v2/bing/login/update-accounts`;
    axiosInstance
      .post(url, {
        accounts: accountsWithSelectedData,
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(BingAccountsSaveSuccess());
      });
  };
};

export const cancelConnection = () => {
  return (dispatch) => {
    const url = `v2/bing/login/cancel-connect?shopId=${$currentShopId.get()}`;
    axiosInstance.post(url).then(() => {
      dispatch(BingAccountsSaveSuccess());
    });
  };
};

const isBingConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!Object.values(action.bing?.bingAccounts || {}).length;
    case BING_ACCOUNTS_CONFIG_SAVE_SUCCESS:
      return true;
    case BING_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return state;
    default:
      return state;
  }
};

export const reducers = {
  isBingConnected,
  bingConnectConfigIsOpen,
};
