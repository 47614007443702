import {
  FeatureFlagMetaData,
  FeatureFlagPackagesConfig,
  NullableFeatureFlagValueWithMetaDataMap,
  PackageMetaData,
} from '@tw/feature-flag-system/module/types';
import { assertValidNullableFeatureFlagValueWithMetaDataMap } from 'feature-flag-system/services';
import {
  assertValidMetadata,
  assertValidPackageMetaDataList,
  assertValidPackagesConfig,
} from '@tw/feature-flag-system/module/utils';

export type FullShopFFSystemData = {
  shopId: string;
  computedFFValues: NullableFeatureFlagValueWithMetaDataMap;
  metaData: Partial<FeatureFlagMetaData>;
  packagesConfig: Partial<FeatureFlagPackagesConfig>;
  packageMetaData: PackageMetaData[];
};

// TODO: Move stuff below this to ff system
function hasKeys<K extends string>(obj: object, keys: K[]): obj is { [P in K]: unknown } {
  for (const key of keys) {
    if (!(key in obj)) return false;
  }
  return true;
}

export function isFullShopFFSystemData(obj: unknown): obj is FullShopFFSystemData {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    return false;
  }

  if (
    !hasKeys(obj, ['shopId', 'computedFFValues', 'metaData', 'packagesConfig', 'packageMetaData'])
  ) {
    return false;
  }

  if (typeof obj.shopId !== 'string' || !obj.shopId) return false;

  try {
    assertValidNullableFeatureFlagValueWithMetaDataMap(obj.computedFFValues);
    assertValidMetadata(obj.metaData);
    assertValidPackagesConfig(obj.packagesConfig);
    assertValidPackageMetaDataList(obj.packageMetaData);

    return true;
  } catch (err) {
    return false;
  }
}
