import { useEffect } from 'react';
import { customLoadFromAppNotification } from 'ducks/mobileApp';
import { useAppDispatch } from 'index';
import { useLocation } from 'react-router';

/**
 * @description Triggers a custom load if `openParams` exists in url search params.
 */
export const useCustomLoadFromAppNotification = (): void => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const customOpenParams = search.get('openParams');
    if (!customOpenParams) return;

    dispatch(customLoadFromAppNotification(customOpenParams || ''));
  }, [location.search, dispatch]);
};
