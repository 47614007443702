import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { type RootState } from 'reducers/RootType';
import { SortedMediaItem } from 'types/ContentHub';
import { Pagination } from '@shopify/polaris';

import { ContentHubContext } from '../ContentHub';
import ProductMediaCards from './ProductMediaCards';
import ProductMediaTable from './ProductMediaTable';
import SingleProduct from './SingleProduct';
import axiosInstance from '../../../utils/axiosInstance';
import { Loader } from '@tw/ui-components';

type ProductMediaCollectionProps = {};

const RESULTS_PER_PAGE = 12;

const ProductMediaCollection: React.FC<ProductMediaCollectionProps> = () => {
  const params = useParams<{ customId: string }>();

  const { displayMode } = useContext(ContentHubContext);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const [data, setData] = useState<SortedMediaItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openProduct, setOpenProduct] = useState<boolean>(false);

  const loadData = useCallback(
    async (prev = false) => {
      setLoading!(true);
      const url = `/v2/shopify/mongo/get-bestselling-for-shop`;
      const { data } = await axiosInstance.post(url, {
        shopId: currentShopId,
        page: currentPage,
        limit: RESULTS_PER_PAGE,
      });
      setData(data);
      if (data.length < RESULTS_PER_PAGE) {
        setHasNext(false);
      } else {
        setHasNext(true);
      }
      setLoading(false);
    },
    [currentShopId, currentPage],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (params.customId) {
      setOpenProduct(true);
    }
  }, [params.customId]);

  return (
    <div className="h-[73vh] flex flex-col overflow-auto gap-6.5 collection-stack">
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loader />
        </div>
      )}
      {!loading && !openProduct && (
        <>
          {displayMode === 'grid' ? (
            <ProductMediaCards data={data} />
          ) : (
            <ProductMediaTable data={data} setOpenProduct={setOpenProduct} />
          )}
          <div className="flex items-center mt-auto justify-center">
            <Pagination
              onNext={() => {
                setCurrentPage((p) => p + 1);
              }}
              onPrevious={() => {
                setCurrentPage((p) => p - 1);
              }}
              hasPrevious={currentPage > 0}
              hasNext={hasNext}
              label={`Page ${currentPage + 1}`}
            />
          </div>
        </>
      )}

      {!loading && openProduct && (
        <>
          <SingleProduct closeOpenProduct={() => setOpenProduct(false)} />
        </>
      )}
    </div>
  );
};

export default ProductMediaCollection;
