import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FiltersContext, appliedInsightsFiltersNames } from 'ducks/insights/filterReducers';

export const useFiltersText = (filtersContext: FiltersContext) => {
  const { [filtersContext]: filterNames } = useSelector(appliedInsightsFiltersNames);

  const filteredByText: string = useMemo(() => {
    const filters = filterNames?.join(', ');
    return filters ? `Filtered by: ${filters}` : '';
  }, [filterNames]);

  return filteredByText;
};
