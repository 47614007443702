export type InfoTabProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  icon?: React.ReactNode;
};

export const InfoTab: React.FC<InfoTabProps> = ({ title, description, icon }) => {
  return (
    <div className="flex gap-2">
      <div>{!!icon && icon}</div>
      <div className="flex flex-col gap-2">
        <div className="font-medium">{title}</div>
        <div className="text-gray-500">{description}</div>
      </div>
    </div>
  );
};
