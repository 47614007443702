import { Tooltip } from '@shopify/polaris';
import { Button, Flex, Icon, Menu, Text } from '@tw/ui-components';
import { TW_LS_ATTRIBUTION_DATE_MODEL_KEY, TW_LS_ATTRIBUTION_WINDOW } from 'constants/attribution';
import { AttributionWindowArr } from 'constants/types';
import { changeAttributionWindow } from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

export const AttributionWindowItems = AttributionWindowArr.map((item) => ({
  label: item === 'lifetime' ? 'Lifetime' : item === '1' ? '1 day' : item + ' days',
  value: item,
}));

type AttributionWindowPickerProps = {
  theme?: 'light' | 'dark' | 'none';
  withinPortal?: boolean;
};

const AttributionWindowPicker: React.FC<AttributionWindowPickerProps> = ({
  theme,
  withinPortal = true,
}) => {
  const dispatch = useAppDispatch();
  const { attributionWindow, useNewModels, attributionModel } = useSelector(
    (state: RootState) => state.attribution,
  );
  const isClickDateModel = localStorage.getItem(TW_LS_ATTRIBUTION_DATE_MODEL_KEY) === 'clickDate';
  return (
    <Menu
      disabled={
        isClickDateModel ||
        (useNewModels && attributionModel === 'ppsViews') ||
        (!useNewModels && attributionModel !== 'lastPlatformClick-v2')
      }
      withinPortal={withinPortal}
      position={withinPortal ? 'bottom' : 'right-start'}
      offset={withinPortal ? undefined : 16}
    >
      <Menu.Target>
        <div>
          <Tooltip
            content={
              !useNewModels && attributionModel !== 'lastPlatformClick-v2'
                ? 'Attribution Windows are available only for the Triple Attribution model'
                : isClickDateModel
                  ? 'Attribution Windows are not available for Click Date model'
                  : ''
            }
          >
            {withinPortal ? (
              <Button
                size="sm"
                variant="activator"
                //forceColorScheme={theme}
                rightSection="caret-down"
                fullWidth
                fz="sm"
                fw={500}
              >
                Attribution Window
              </Button>
            ) : (
              <Flex align="center" gap="md" justify="space-between" id="subscription-filter">
                <Text size="sm" weight={500} color="named2.0">
                  Attribution Window: {attributionWindow}
                </Text>
                <Icon name="chevron-right-minor" size={12} color="named.8" />
              </Flex>
            )}
          </Tooltip>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {AttributionWindowItems.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={() => {
              dispatch(changeAttributionWindow(option.value));
              localStorage.setItem(TW_LS_ATTRIBUTION_WINDOW, JSON.stringify(option.value));
            }}
            bg={attributionWindow === option.value ? 'named2.4' : undefined}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default AttributionWindowPicker;
