import 'swiper/css';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import SwiperCore, { Keyboard, Mousewheel, Navigation, Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axiosInstance from 'utils/axiosInstance';

import CardThumbnail from '../assets/CardThumbnail';
import { ContentHubContext } from '../ContentHub';
import { Loader } from '@tw/ui-components';

type MediaResultsProps = {};
SwiperCore.use([Keyboard, Mousewheel]);

const LIMIT = 12;

const MediaResults: React.FC<MediaResultsProps> = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const { debounce, searchResultsMedia, searchingMedia } = useContext(ContentHubContext);
  const [mediaData, setMediaData] = useState<any>([]);
  const [mediaPage, setMediaPage] = useState(1);
  const [mediaHasNext, setMediaHasNext] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaSwiper, setMediaSwiper] = useState<SwiperInterface>();
  const [loadingMedia, setLoadingMedia] = useState(false);

  useEffect(() => {
    setMediaIndex(0);
    if (mediaSwiper?.activeIndex) {
      mediaSwiper?.slideTo?.(10000);
    }
    let results = searchResultsMedia?.data?.map((r) => {
      return { ...r, serviceId: r.service_id, customId: r.custom_id };
    });
    setMediaData(results);
    if (searchResultsMedia?.data?.length === LIMIT) {
      setMediaHasNext(true);
    }
  }, [mediaSwiper, searchResultsMedia]);

  const loadMoreMedia = async () => {
    if (!loadingMedia && mediaHasNext) {
      setLoadingMedia(true);
      setMediaPage(mediaPage + 1);
      const { data } = await axiosInstance.post('/v2/media/search-content-hub-media', {
        query: debounce,
        shopId: currentShopId,
        page: mediaPage,
        limit: 12,
      });
      let results = data.data.map((r) => {
        return { ...r, serviceId: r.service_id, customId: r.custom_id };
      });
      setMediaData([...mediaData, ...results]);
      if (!data.data.length) {
        setMediaHasNext(false);
      }
      setLoadingMedia(false);
    }
  };

  const swiperNext = (swiper) => {
    setMediaIndex(swiper.realIndex);
    swiper.slideNext();
    if (swiper.realIndex + 8 >= mediaData.length && mediaHasNext) {
      loadMoreMedia();
    }
  };

  const swiperPrev = (swiper) => {
    swiper.slidePrev();
    setMediaIndex(swiper.realIndex);
  };

  return (
    <div className="py-2">
      <div className="text-3xl	pb-5">Media</div>
      {searchingMedia ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {mediaData?.length ? (
            <Swiper
              spaceBetween={16}
              slidesPerView={4}
              onSwiper={(swiper) => setMediaSwiper(swiper)}
              mousewheel={{ forceToAxis: true }}
              keyboard
              slidesPerGroup={2}
              navigation={true}
              modules={[Navigation]}
              onNavigationNext={(swiper) => swiperNext(swiper)}
              onNavigationPrev={(swiper) => swiperPrev(swiper)}
            >
              {mediaData?.map((m) => {
                return (
                  <SwiperSlide>
                    <div className="flex flex-col justify-center items-center">
                      <CardThumbnail media={m} />
                    </div>
                  </SwiperSlide>
                );
              })}
              {loadingMedia && (
                <SwiperSlide>
                  <div className="flex flex-col justify-center items-center">
                    <Loader />
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          ) : (
            <div>No media found</div>
          )}
        </>
      )}
    </div>
  );
};

export default MediaResults;
