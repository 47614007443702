import { $currentShopId } from '$stores/$shop';
import { $user } from '$stores/$user';
import { $derived, $mutableDerived, $store } from '@tw/snipestate';

export const $customizePromptsPopup = $store<{
  opened: boolean;
  savedNewTags: boolean;
  isInitial: boolean;
}>({
  opened: false,
  savedNewTags: false,
  isInitial: false,
});

export const openCustomizePromptsPopup = (initial = false) => {
  $customizePromptsPopup.set({ opened: true, savedNewTags: false, isInitial: initial });
};

export const closeCustomizePromptsPopup = (withSave = false) => {
  $customizePromptsPopup.set({ opened: false, savedNewTags: withSave, isInitial: false });
};

export const resetSavedNewTags = () => {
  $customizePromptsPopup.set({ opened: false, savedNewTags: false, isInitial: false });
};

//customize moby popup
export const $showCustomizeMobyPopup = $derived((get) => {
  const hasTags = get($userShopCustomMobyTags);
  const popupClosedUser = get($userShopCloseCustomizeMobyPopup);
  const userIsPartOfShop = !!get($user)?.shops?.[$currentShopId.get() ?? ''];
  return !hasTags?.length && !popupClosedUser && userIsPartOfShop;
});

export const $userShopCustomMobyTags = $mutableDerived((get) => {
  const tags = get($user)?.shops?.[$currentShopId.get() ?? '']?.customPromptTags;
  return tags ?? [];
});

export const $userShopCloseCustomizeMobyPopup = $mutableDerived((get) => {
  const closed = get($user)?.shops?.[$currentShopId.get() ?? '']?.customizeMobyPopupClosed;
  return closed;
});
