import { NavSection } from './types';
import { SUMMARY_SECTION } from './configs/summary';
import { PIXEL_SECTION } from './configs/pixel';
import { RETENTION_SECTION } from './configs/retention';
import { OPS_SECTION } from './configs/ops';
import { GET_STARTED_SECTION, WILLY_BUILD_SECTION, WILLY_SECTION } from './configs/willy';
import { STORE_SETTINGS_SECTION } from './configs/store-settings';
import { HIDDEN_SECTION } from './configs/hidden';
import { APPS_SECTION } from './configs/apps';

export const V2_SECTIONS: NavSection[] = [
  SUMMARY_SECTION,
  PIXEL_SECTION,
  RETENTION_SECTION,
  OPS_SECTION,
  HIDDEN_SECTION,
  STORE_SETTINGS_SECTION,
  {
    ...WILLY_SECTION,
    routes: WILLY_SECTION.routes.map((x) => ({ ...x, hidden: true })),
  },
  {
    ...WILLY_BUILD_SECTION,
    routes: WILLY_BUILD_SECTION.routes.map((x) => ({ ...x, hidden: true })),
  },
];

export const V3_SECTIONS: NavSection[] = [
  WILLY_SECTION,
  WILLY_BUILD_SECTION,
  GET_STARTED_SECTION,
  APPS_SECTION,
  STORE_SETTINGS_SECTION,
  HIDDEN_SECTION,
];
