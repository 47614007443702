import { OptionList } from '@shopify/polaris';
import { Text } from '@tw/ui-components';
import { compareOptions } from 'components/useDatePickerCompareOptions';
import {
  DatePickerTimePeriods,
  getDatePickerOptionValueOptions,
} from 'components/useDatePickerSelectedOptions';
import { useMemo } from 'react';

type DateOptionsListProps = {
  selectedOption: string | null;
  previous?: boolean;
  onOptionSelect: (option: string) => void;
};

export const DateOptionsList: React.FC<DateOptionsListProps> = ({
  selectedOption,
  onOptionSelect,
  previous = false,
}) => {
  const datePickerOptionValueOptions = useMemo(() => {
    if (previous) {
      return Object.values(compareOptions).filter((o) => o.id !== 'default' && o.id !== 'custom');
    }
    return getDatePickerOptionValueOptions();
  }, [previous]);

  return (
    <div>
      <OptionList
        onChange={(updated) => {
          onOptionSelect(updated[0] as DatePickerTimePeriods);
        }}
        options={datePickerOptionValueOptions.map((o) => ({
          value: o.id,
          label: <Text size="sm">{o.label}</Text>,
        }))}
        selected={[selectedOption ?? '']}
      />
    </div>
  );
};
