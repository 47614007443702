import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

export const useEarliestDate = (earliestDateProp: Date | string | null) => {
  const { numericLimit: datePickerLimitation } = useFeatureFlag(FeatureFlag.LIMIT_LOOKBACK_FF);
  const earliestDate = useSelector((state: RootState) => state.earliestDate);

  const [earliestDateForDisplay, setEarliestDateForDisplay] = useState(moment(earliestDate));

  useEffect(() => {
    let earliestPropMoment = earliestDateProp ? moment(earliestDateProp) : null;
    let earliestDateMoment = moment(earliestDate);
    let earliest =
      earliestPropMoment && earliestPropMoment.isAfter(earliestDateMoment)
        ? moment(earliestDateProp)
        : moment(earliestDate);
    if (datePickerLimitation) {
      const dateFW = moment().subtract(datePickerLimitation, 'month').startOf('day');
      if (dateFW?.isAfter(earliest)) {
        earliest = dateFW;
      }
    }
    setEarliestDateForDisplay(earliest);
  }, [datePickerLimitation, earliestDate, earliestDateProp]);

  return earliestDateForDisplay;
};
