import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { providers } from 'ducks/shopIntegrations';
import { $socket } from '$stores/$socket';
import { $currentShopId } from '$stores/$shop';
import { useStoreValue } from '@tw/snipestate';

export function useRealTimeRegister() {
  const socket = useStoreValue($socket);
  const sensoryIntegrationsLoaded = useSelector(
    (state: RootState) => state.sensory.sensoryIntegrationsLoaded,
  );
  const providerList = useSelector(providers);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {}, [user]);

  useEffect(() => {
    if (!socket || !sensoryIntegrationsLoaded) return;

    if (socket.connected) {
      socket.disconnect(); // disconnect first to avoid multiple connection in case of switching shop
    }
    socket.on('connect', async () => {
      await subscribeToRealtime();
    });
    socket.connect();

    async function subscribeToRealtime() {
      if (!socket) return;

      const authBody = { shopId: $currentShopId.get() };

      socket.emit('subscribe', { channel: `shopify:${$currentShopId.get()}`, ...authBody });

      socket.emit('subscribe-client-actions', {
        action: `force-refresh`,
        date: window.lastReloadISODate,
        ...authBody,
      });

      // And also, I want to get notify for every interesting data related to my user
      socket.emit('subscribe', { channel: `user:${user.uid}`, ...authBody });

      for (const [serviceId, props] of Object.entries(providerList).filter(
        ([id, v]) => v.isConnected && v.providerType == 'data' && id !== 'shopify',
      )) {
        props?.integrations?.forEach((account) => {
          let serviceToRegister = serviceId;
          if (props?.isSensory) {
            serviceToRegister = 'sensory-integrations';
          }
          socket.emit('subscribe', {
            channel: `${serviceToRegister}:${account.id}`,
            ...authBody,
          });
        });
      }

      // attribution updates
      socket.emit('subscribe', { channel: `attribution:${$currentShopId.get()}`, ...authBody });
    }
  }, [socket, sensoryIntegrationsLoaded, user, providerList]);
}
