import Lottie from 'react-lottie-player';
import segmentationIndicator from 'lotties/segmentation-indicator.json';
import { Tooltip } from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/RootType';

export const MetricsCurrentlyFiltered = (props) => {
  const anyAdSegmentExist = useSelector(
    (state: RootState) => state.adSegmentations.anyAdSegmentExist,
  );
  const darkMode = useDarkMode();

  return (
    (!!props.forceShow || anyAdSegmentExist) && (
      <Tooltip label="Metrics are currently using filtered data">
        <div style={!darkMode ? {} : { filter: 'brightness(3)' }}>
          <Lottie
            loop
            play
            animationData={segmentationIndicator}
            rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
            style={{ width: 35, height: 35 }}
          />
        </div>
      </Tooltip>
    )
  );
};
