import { FeatureFlagPackagesConfig } from '@tw/feature-flag-system/module/types';
import {
  assertValidPackagesConfig,
  deserializeFeatureFlagObject,
} from '@tw/feature-flag-system/module/utils';
import axiosInstance from 'utils/axiosInstance';

export const getFFPackagesConfig = async (): Promise<Partial<FeatureFlagPackagesConfig>> => {
  const { data } = await axiosInstance.get(
    'v2/subscription-manager/features/feature-flag-packages-config',
  );

  const parsedData = deserializeFeatureFlagObject(JSON.stringify(data));
  assertValidPackagesConfig(parsedData);

  return parsedData;
};
