import React from 'react';

import {
  type BaseChannel,
  type DataTypesIds,
  services,
  type ServicesIds,
  WillyTableType,
} from '@tw/types/module/services';
import { getSourceExternalLink } from 'utils/attributions';
import {
  ShopIntegrationProperties,
  ShopProviderStatus,
} from '@tw/types/module/types/ShopProviders';
import { providerDomainEnum } from '@tw/types/module/sensory';

const ExtraServicesIdsArr = [
  'pixel',
  'triple-whale',
  'SHOPIFY',
  'SHOPIFY_PRODUCTS',
  'GOOGLE',
  'klaviyo',
  'attentive',
  'GORGIAS',
  'GOOGLE_ANALYTICS',
  'ENQUIRELABS',
  'kno',
  'instagram',
  'youtube',
  'tw_referrer',
  'organic_and_social',
  'postscript',
  'drip',
  'via',
  'mailchimp',
  'omnisend',
  'sms_bump',
  'triplesurvey',
  'triplesurvey-none',
  'triplesurvey_email', // pps "email" channel
  'triplesurvey_text', // pps "text" channel
  'organic', // pps "referred by a friend" channel
  'meta_shop',
  'Excluded',
  'None of the above',
  'bing-ads',
] as const;

export const SourceTypesArr = ['ads', 'email', 'social', 'survey', 'all', 'shop'] as const;
export type SourceTypes = (typeof SourceTypesArr)[number];
export type SourceTypesWithExtra = SourceTypes | 'creativeCockpit';

export type LottieOptions = {
  loop?: boolean | number | undefined;
  autoplay?: boolean | undefined;
  animationData: any;
  rendererSettings?: any;
};

export type ExtraServicesIds = (typeof ExtraServicesIdsArr)[number];

export type Stream<D extends DataTypesIds> = {
  type: D;
  title: string;
  color: string;
  icon: (...args: any) => JSX.Element;
};

export type StreamRecord = {
  [stream in DataTypesIds]?: Stream<stream>;
};

export type ClientBaseChannel<T extends ServicesIds | ExtraServicesIds> = {
  serviceId: string; // for backward compatibility e.g. SHOPIFY_PRODUCTS / GOOGLE_ADS
  type?: SourceTypes;
  allowReimport?: boolean;
  title?: string;
  color?: string;
  icon?: React.FC<any>;
  accountConnection?: {
    url?: string;
    onAction?: any;
    backgroundImage?: string;
    labelForNotConnected?: string;
    hideConnect?: boolean;
  };
  streams?: StreamRecord;
  useNewApi?: boolean;
  useNewJobManager?: boolean;
  hasCreative?: boolean;
  hasInfluencers?: boolean;
  sourceVariations?: string[];
  iconOptions?: LottieOptions;
  apiUrl?: string;
  externalAdsUrl?: typeof getSourceExternalLink;
} & BaseChannel<T>;

export type ClientServiceRecord = {
  [channel in ServicesIds | ExtraServicesIds]?: ClientBaseChannel<channel>;
};

export type ClientServicesRecord = ClientServiceRecord & {
  [moreChannel: string]: ClientBaseChannel<any>;
};

export type ProviderStatus = {
  [id in ServicesIds]?: ShopProviderStatus;
};

export type ProvidersIntegrations = {
  [id in ServicesIds]?: ShopIntegrationProperties[];
};

export type ProviderConnected = {
  [id in ServicesIds]?: boolean;
};

export type Providers = {
  [id in ServicesIds]?: ProviderProps;
};
export type ProviderProps = {
  isSensory?: boolean;
  forceOldFetch?: boolean;
  integrations: ShopIntegrationProperties[];
  isConnected?: boolean;
  providerStatus?: ShopProviderStatus;
  willyTableType?: WillyTableType[];
  isBeta?: boolean;
  domain?: providerDomainEnum;
  providerType?: 'none' | 'connector' | 'data';
  twVersion?: string;
  msps: string[];
};
