import { type CreativeColumn } from 'components/CreativesCockpit/CreativeTable/columns';

import { SortDirection } from '@shopify/polaris';
import {
  MetricsKeys,
  Creative,
  CreativeSegment,
  CreativeTypes,
  EntityWithMetrics,
} from '@tw/types';
import { type ServicesIds } from '@tw/types/module/services';
import { Moment } from '@tw/moment-cached';
import { ProductEntityType } from './productAnalytics';
import { CustomMetricsType } from 'ducks/customMetrics';

export const FACEBOOK_APP_ID = '';

export type CreativeElement = {
  color?: string;
  formattedName?: string | JSX.Element;
} & Creative;

export type SelectableCreative = {
  selected: boolean;
  active: boolean;
} & CreativeElement;

export type SortingDirection = 'ASC' | 'DESC';

export type SortingDimension = MetricsKeys;

export interface ICreativesCockpitContext {
  showComparisons?: boolean;
  creatives: SelectableCreative[];
  segments?: CreativeSegmentClient[];
  selectedCreatives: SelectableCreative[];
  averages?: EntityWithMetrics;
  maximums?: EntityWithMetrics;
  sortDirection: SortDirection;
  sortBy: SortingDimension;
  serviceId: ServicesIds;
  serviceName: string;
  type: CreativeTypes;
  pageIndex: number;
  itemType?: ProductEntityType;
  loadingColumn?: boolean;
  loadingCreatives: boolean;
  loadingSegments?: boolean;
  totalCreativesNumber: number;
  selectedColumns: CreativeColumn[];
  customMetricsType?: CustomMetricsType;
  currency: string;
  toggleCreativeSelection: (creative: SelectableCreative) => void;
  toggleCreativeActive: (creative: SelectableCreative) => void;
  toggleSegmentActive?: (segment: CreativeSegmentClient) => void;
  setSortBy: (a: SortingDimension) => void;
  setSortDirection: (a: SortDirection) => void;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setCreativeInModal: React.Dispatch<React.SetStateAction<SelectableCreative | null>>;
  setAdsModalProduct?: React.Dispatch<React.SetStateAction<any | null>>;
  setSkusModalProduct?: React.Dispatch<React.SetStateAction<any | null>>;
  setSelectedColumns: (column: CreativeColumn[]) => void; //React.Dispatch<React.SetStateAction<CreativeColumn[]>>;
  chartMode: ChartMode;
  setChartMode: (a: ChartMode) => void;
  chartData: ChartData;
  setChartData: React.Dispatch<React.SetStateAction<ChartData>>;
  primaryMetric;
  setPrimaryMetric;
  secondaryMetric;
  setSecondaryMetric;
  dashboardView;
  setDashboardView;
  isShareMode?;
  shareHasPixelInstalled?;
  pageSize: number;
  setPageSize: (pageSize: number) => void | React.Dispatch<React.SetStateAction<number>>;
  creativeInModal?: SelectableCreative | null;
  adsModalProduct?: any | null;
  skusModalProduct?: any | null;
  product_id?: string;
  start?: Moment;
  end?: Moment;
}

export type CreativeSegmentClient = {
  color: string;
  active: boolean;
} & CreativeSegment;

export type ChartMode = 'line' | 'bar';
export type ChartData = 'creative' | 'segment';
export type DashboardView = 'table' | 'card';
