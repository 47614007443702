import { Icon } from '@shopify/polaris';
import {
  GlobeMajor,
  ProductsMajor,
  CollectionsMajor,
  SearchMajor,
  OrderStatusMinor,
} from '@shopify/polaris-icons';
import { ReactComponent as InfluencersIconSVG } from 'icons/affluencer-hub.svg';
import { getSource } from 'components/attribution/utils';
import GoogleIconBase64 from 'components/GoogleIconBase64';
import { allChannels } from 'constants/channels';

export default function PixelReferrer({
  referral = {},
  url = {},
  affluencerIcon = false,
}: {
  referral?: any;
  url?: any;
  affluencerIcon?: boolean;
}) {
  const { query, path } = url || {};
  const { query: refQuery, path: refPath } = referral || {};
  const unitedQuery = { ...refQuery, ...query };
  const referralPath = refPath?.toLowerCase().trimStart().trimEnd() || undefined;
  const hasUtms =
    !!unitedQuery?.utm_source || !!unitedQuery?.tw_source || !!unitedQuery?.triplesource;
  let googleOrganicIcon: any = null;
  if (referralPath?.includes('google') && !hasUtms) {
    googleOrganicIcon = <GoogleIconBase64 small={false} />;
  }
  const source = getSource({ query: unitedQuery, referral: referralPath });
  let icon =
    googleOrganicIcon ||
    Object.values<any>(allChannels)
      .find((ch) => ch.general.sourceVariations?.includes(source))
      ?.general.icon({ small: false });

  if (!icon) {
    if (path?.includes('/products/')) {
      icon = <Icon source={ProductsMajor} />;
    } else if (path?.includes('/collections/') && !path?.includes('/products')) {
      icon = <Icon source={CollectionsMajor} />;
    } else if (path?.includes('/thank_you')) {
      icon = <Icon source={OrderStatusMinor} />;
    } else if (query?.q) {
      icon = <Icon source={SearchMajor} />;
    } else if (affluencerIcon) {
      icon = <Icon source={InfluencersIconSVG} />;
    } else {
      icon = <Icon source={GlobeMajor} />;
    }
  }

  return icon;
}
