import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, FormLayout, TextField } from '@shopify/polaris';
import {
  toggleShowingModalSection,
  toggleEditModalSection,
} from '../../ducks/actions/modalCustomSectionActions';

import { customSectionOnCreate, customSectionOnEdit } from '../../ducks/customSections';
import SelectMetrics from '../SelectMetrics';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';

export const AddCustomTileSectionModal = () => {
  const { isAddModalSection } = useSelector((state: RootState) => state.modalCustomSection);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [sectionTitle, setSectionTitle] = useState<string>();
  const onSelectMetric = useCallback((value) => setSelectedMetrics(value), []);
  const onSectionTitleChange = (value) => setSectionTitle(value);
  const { title, tiles, id } = useSelector(
    (state: RootState) => state.modalCustomSection.editSection,
  );
  const dispatch = useAppDispatch();
  const submit = () => {
    if (!title) {
      dispatch(customSectionOnCreate(sectionTitle!, selectedMetrics, 'summary'));
    } else {
      dispatch(customSectionOnEdit(id, sectionTitle!, selectedMetrics));
    }
    dispatch(toggleShowingModalSection(false));
    dispatch(toggleEditModalSection({ id: null, title: null, tiles: null }));
    setSectionTitle(undefined as any);
    setSelectedMetrics([]);
  };

  useEffect(() => {
    if (title && tiles) {
      setSectionTitle(title);
      setSelectedMetrics(tiles);
    }
  }, [title, tiles]);
  return (
    <>
      <Modal
        open={isAddModalSection}
        onClose={() => dispatch(toggleShowingModalSection(false))}
        title={title ? 'Edit custom section' : 'Add custom section'}
        secondaryActions={[
          {
            onAction: submit,
            disabled: !sectionTitle || !sectionTitle.length,
            content: 'Submit',
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              label="Title"
              placeholder="Type title here"
              value={sectionTitle}
              onChange={onSectionTitleChange}
              autoComplete="off"
            />
            {
              <SelectMetrics
                selectedMetrics={selectedMetrics}
                onSelectMetric={onSelectMetric}
                allSections={undefined}
              />
            }
          </FormLayout>
        </Modal.Section>
      </Modal>
    </>
  );
};
