import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>(value);

  // Only update the ref if the value has changed based on a deep comparison
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  const currentDependenciesRef = useRef();

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDependenciesRef.current]);
}
