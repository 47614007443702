import { $derived, $mutableDerived, $store } from '@tw/snipestate';
import { WillyDashboardElement } from '../types/willyTypes';
import { $user } from '../../../$stores/$user';
import { TemplateLibraryFilterSettings } from '../../UserProfileManagment/User/constants';

export type customViewModal = {
  isOpen: boolean;
  name?: string;
  isLoading?: boolean;
  dashboard?: WillyDashboardElement;
};

export const $customViewModal = $store<customViewModal>({
  isLoading: false,
  isOpen: false,
  name: '',
});

export const $templateViewFilterSettings = $derived<TemplateLibraryFilterSettings>((get) => {
  const { templateLibraryFilterSettings } = get($user);
  const { integrations = [], sort = 'new', added = 'not' } = templateLibraryFilterSettings ?? {};
  return { integrations, sort, added };
});
