import { $currentShopId, $timezone } from '$stores/$shop';
import {
  $currentDateRange,
  $prevDateRange,
  getPrevDateSelectedOption,
  pushDateToQS,
} from '$stores/willy/$dateRange';
import { DatePicker } from '@shopify/polaris';
import { useStoreValue } from '@tw/snipestate';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import { Button, Flex, Popover, Size } from '@tw/ui-components';
import { DATE_PICKER_HEIGHT_VAR } from 'components/TopBar/constants';
import { buildDatePickerCompareOptions } from 'components/useDatePickerCompareOptions';
import { useEarliestDate } from 'components/useEarliestDate';
import { usePrevPopoverOpenerButtonTitle } from 'components/useGetPopoverOpenerButtonTitle';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePickerPreviousPeriodOption, RangeWithId } from 'types/general';
import { convertDateToMoment } from 'utils/convertDateToMoment';
import {
  analyticsEvents,
  dashboardsActions,
  generalActions,
  genericEventLogger,
} from 'utils/dataLayer';
import { userDb } from 'utils/DB';
import { DateRangeOptionsList } from './DateRangeOptionsList';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type ComparisonDateRangePopoverProps = {
  earliestDateProp?: string | Date | null;
  isWillyDashboard?: boolean;
  buttonSize?: Exclude<Size, 0>;
  disabled?: boolean;
};

export const ComparisonDateRangePopover: React.FC<ComparisonDateRangePopoverProps> = ({
  earliestDateProp = '',
  isWillyDashboard = false,
  buttonSize = 'sm',
  disabled,
}) => {
  const navigate = useNavigate();
  const currentDateRange = useStoreValue($currentDateRange);
  const prevDateRange = useStoreValue($prevDateRange);
  const prevPopoverLabel = usePrevPopoverOpenerButtonTitle();
  const earliestDateForDisplay = useEarliestDate(earliestDateProp);
  const timezone = useStoreValue($timezone);
  const currentShopId = useStoreValue($currentShopId);
  const isSmall = useIsSmall();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [month, setMonth] = useState(prevDateRange?.end.month() || moment().month());
  const [year, setYear] = useState(prevDateRange?.end.year() || moment().year());
  const [value, setValue] = useState<RangeWithId | null>(null);

  const activeOption: PreviousPeriodIds | undefined = useMemo(() => {
    const { start, end } = value || {};
    if (!start || !end) return 'none';
    const prevActiveOption = getPrevDateSelectedOption({
      start: convertDateToMoment(start),
      end: convertDateToMoment(end),
    });
    return prevActiveOption;
  }, [value]);

  const options: Array<DatePickerPreviousPeriodOption> = useMemo(() => {
    if (!currentDateRange) return [];
    const prevDate = value
      ? {
          start: convertDateToMoment(value.start),
          end: convertDateToMoment(value.end),
        }
      : undefined;
    return buildDatePickerCompareOptions(currentDateRange, prevDate);
  }, [currentDateRange, value]);

  useEffect(() => {
    if (!prevDateRange) {
      setValue(null);
      return;
    }
    const { start, end } = prevDateRange;
    setValue({
      start: new Date(start.year(), start.month(), start.date()),
      end: new Date(end.year(), end.month(), end.date()),
    });
  }, [prevDateRange, isPopoverOpen]);

  const onApply = useCallback(
    (newValue: RangeWithId | null) => {
      if (!newValue) return;
      const { start, end, id } = newValue;

      if (id === 'none') {
        pushDateToQS(navigate, undefined, undefined, false, true);
      } else {
        pushDateToQS(navigate, convertDateToMoment(start), convertDateToMoment(end), false);
      }
      genericEventLogger(isWillyDashboard ? analyticsEvents.DASHBOARDS : analyticsEvents.GENERAL, {
        action: isWillyDashboard
          ? generalActions.CHANGE_COMPARE_DATE
          : dashboardsActions.CHANGE_COMPARE_DATE,
        version: isWillyDashboard ? '3.0' : '2.0',
        start: moment(start).toDate(),
        end: moment(end).toDate(),
      });
      setIsPopoverOpen(false);
    },
    [isWillyDashboard, navigate],
  );

  const saveSelectedOption = useCallback(
    async (option: PreviousPeriodIds) => {
      if (!currentShopId) return;
      await userDb().set(
        {
          compareDatePickerSelectedOption: option,
          shops: {
            [currentShopId]: {
              timezone: timezone,
            },
          },
        },
        { merge: true },
      );
    },
    [currentShopId, timezone],
  );

  return (
    <Popover
      shadow="md"
      opened={isPopoverOpen}
      position="bottom"
      onClose={() => setIsPopoverOpen(false)}
    >
      <Popover.Target>
        <span>
          <Button
            id={'date-picker'}
            variant={isWillyDashboard ? 'activatorWithHover' : 'activator'}
            onClick={() => setIsPopoverOpen((x) => !x)}
            disabled={disabled}
            size={buttonSize}
          >
            {prevPopoverLabel}
          </Button>
        </span>
      </Popover.Target>
      <Popover.Dropdown bg="white" fz="sm" p={0} mah={DATE_PICKER_HEIGHT_VAR} overflow="hidden">
        <Flex direction="column">
          <div
            className="flex flex-col sm:flex-row sm:h-[270px] sm:max-w-[650px] max-w-[300px] overflow-y-hidden"
            aria-label="Date Selections"
          >
            <DateRangeOptionsList
              options={options.map((option) => ({
                label: option.labelElement,
                value: option.id,
                selected: option.id === activeOption,
                onClick: () => {
                  const { start, end, id } = option;
                  const newValue = {
                    start: new Date(start.year(), start.month(), start.date()),
                    end: new Date(end.year(), end.month(), end.date()),
                    id,
                  };
                  setValue(newValue);
                  saveSelectedOption(id);
                  onApply(newValue);
                },
              }))}
            />
            <div className="flex-[2] sm:flex-[1] flex flex-col justify-start gap-4 p-6.5">
              <DatePicker
                multiMonth={false}
                month={month}
                year={year}
                onChange={(date) => {
                  setValue(date);
                }}
                onMonthChange={(month, year) => {
                  setMonth(month);
                  setYear(year);
                }}
                selected={value!}
                allowRange
                disableDatesAfter={
                  new Date(
                    moment(currentDateRange?.end)
                      .subtract(1, 'days')
                      .toDate()
                      .toLocaleString('en-US', {
                        timeZone: moment().tz(),
                      }),
                  )
                }
                disableDatesBefore={
                  //prevDateSelectedOption !== 'custom'
                  //? moment.unix(999999999999).toDate() :
                  earliestDateForDisplay.toDate()
                }
              />
            </div>
          </div>
          <Flex
            p="md"
            align="center"
            gap="md"
            justify={isSmall ? 'space-between' : 'flex-end'}
            borderTop={'1px solid var(--mantine-color-gray-2)'}
          >
            <Button
              onClick={() => {
                setIsPopoverOpen(false);
              }}
              variant="activator"
            >
              Cancel
            </Button>
            <Button onClick={() => onApply(value)}>Apply</Button>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
