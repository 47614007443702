import { CreativeSegmentClient, SelectableCreative } from 'types/creativeCockpit';

import { Creative, CreativeSegment } from '@tw/types';
import { CHART_COLORS } from 'constants/general';

type Actions =
  | { type: 'init'; payload: Creative[] }
  | { type: 'add'; payload: Creative[] }
  | { type: 'toggle_select'; payload: SelectableCreative }
  | { type: 'toggle_active'; payload: SelectableCreative };

export const creativeReducer = (creatives: SelectableCreative[], action: Actions) => {
  switch (action.type) {
    // add creatives
    case 'init': {
      const newCreatives: Creative[] = action.payload;
      const creativesToAdd: SelectableCreative[] = newCreatives.map((c, i) => ({
        ...c,
        active: true,
        selected: i < 3, // only 8 creatives can be selected at once
      }));
      return creativesToAdd;
    }

    case 'add': {
      const newCreatives: Creative[] = action.payload;
      const creativesToAdd: SelectableCreative[] = newCreatives.map((c) => ({
        ...c,
        active: true,
        selected: false,
      }));
      return [...creatives, ...creativesToAdd];
    }

    // toggle creative selection
    case 'toggle_select': {
      const creative = action.payload;
      const index = creatives.findIndex((c) => c.id === creative.id);
      if (!creative) {
        throw new Error('Creative not found');
      }

      return [
        ...creatives.slice(0, index),
        {
          ...creative,
          selected: !creative.selected,
        },
        ...creatives.slice(index + 1),
      ];
    }

    case 'toggle_active': {
      const creative = action.payload;
      const index = creatives.findIndex((c) => c.id === creative.id);
      if (!creative) {
        throw new Error('Creative not found');
      }

      return [
        ...creatives.slice(0, index),
        {
          ...creative,
          active: !creative.active,
        },
        ...creatives.slice(index + 1),
      ];
    }
  }
};

type SegmentsAction =
  | { type: 'init'; payload: CreativeSegment[] }
  | { type: 'toggle_active'; payload: CreativeSegmentClient };

export const segmentsReducer = (segments: CreativeSegmentClient[], action: SegmentsAction) => {
  switch (action.type) {
    case 'init': {
      const newCreatives: CreativeSegment[] = action.payload;
      const creativesToAdd: CreativeSegmentClient[] = newCreatives.map((c, i) => ({
        ...c,
        active: true,
        color: CHART_COLORS[i],
      }));
      return creativesToAdd;
    }

    case 'toggle_active': {
      const creative = action.payload;
      const index = segments.findIndex((c) => c.id === creative.id);
      if (!creative) {
        throw new Error('Creative not found');
      }

      return [
        ...segments.slice(0, index),
        {
          ...creative,
          active: !(creative as any).active,
        },
        ...segments.slice(index + 1),
      ];
    }
  }
};
