import 'firebase/compat/firestore';

import { DEFAULT_ERROR_MSG } from 'constants/general';
import firebase from 'firebase/compat/app';
import { callUpdateToday } from 'utils/callUpdateToday';

import db from '../utils/DB';
import { showErrorToast, toggleSaveIndicator } from './actions';
import { GOOGLE_ADS_FETCH_ACCOUNTS_ERROR, INIT_SHOP } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';
import { setServiceAccountInfo } from '../utils/setServiceAccountInfo';
import { Action } from 'redux';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const GOOGLE_ADS_DISCONNECT_DONE = 'GOOGLE_ADS_DISCONNECT_DONE';
const GOOGLE_ADS_CONNECTED = 'GOOGLE_ADS_CONNECTED';
const GOOGLE_CUSTOMERS_LIST_UPDATED = 'GOOGLE_CUSTOMERS_LIST_UPDATED';
const TOGGLE_GOOGLE_ADS_ACCOUNTS_CONFIG_SCREEN_OPEN =
  'TOGGLE_GOOGLE_ADS_ACCOUNTS_CONFIG_SCREEN_OPEN';
const GOOGLE_ADS_DISCONNECT_STARTED = 'GOOGLE_ADS_DISCONNECT_STARTED';
const GOOGLE_ADS_CONNECT_STARTED = 'GOOGLE_ADS_CONNECT_STARTED';
const GOOGLE_ADS_CONNECT_DONE = 'GOOGLE_ADS_CONNECT_DONE';
const GOOGLE_ADS_CONFIG_SCREEN_OPEN = 'GOOGLE_ADS_CONFIG_SCREEN_OPEN';
const errorMessages: { [key: string]: string } = {
  ACCESS_TOKEN_SCOPE_INSUFFICIENT:
    'Looks like we weren’t given the necessary level of permission to your Google Ads account. Please try to connect your account again',
  INVALID_GRANT:
    'You are not currently logged in to Google Ads, so we cannot connect your account. Log back into your account and try again',
  AUTHENTICATION_ERROR_14:
    "You don't seem to have access to any Google Ads accounts. Contact your administrator to get permissions and try again",
  UNKNOWN_ERROR:
    'We were unable to connect your Google Ads accounts. Please try again or contact our support team',
};

export const connectToGoogleAds = () => {
  return (dispatch) => {
    dispatch({
      type: GOOGLE_ADS_CONNECTED,
    });
  };
};

export const googleAdsDisconnect = () => {
  return (dispatch) => {
    dispatch({
      type: GOOGLE_ADS_DISCONNECT_STARTED,
    });
    toggleSaveIndicator();
    const url = `v2/google-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('googleAdsAccessToken'));
        dispatch({
          type: GOOGLE_ADS_DISCONNECT_DONE,
        });
      });
  };
};

export const googleAdsConnectOnPress = (dispatch?: (action: Action) => void) => {
  try {
    if (dispatch) {
      dispatch({
        type: GOOGLE_ADS_CONNECT_STARTED,
      });
    }
    const url = `v2/google-ads/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    toast.error('Error while connecting to Google-Ads');
    if (dispatch) {
      dispatch({
        type: GOOGLE_ADS_CONNECT_DONE,
      });
    }
  }
};

export const getGoogleAdsCustomers = (googleAdsAccessToken) => {
  return async (dispatch, getState) => {
    try {
      const url = `v2/google-ads/fetch/accounts`;
      const { data } = await axiosInstance.get(url);
      if (data?.success && data?.accounts) {
        dispatch({
          type: GOOGLE_CUSTOMERS_LIST_UPDATED,
          payload: data.accounts,
        });
      } else {
        let errorMsg: string = errorMessages[data?.errorCode] || errorMessages.UNKNOWN_ERROR;
        dispatch({
          type: GOOGLE_ADS_FETCH_ACCOUNTS_ERROR,
          payload: errorMsg,
        });
        dispatch(showErrorToast(`${errorMsg}`));
      }
    } catch (e) {
      console.log('Google-Ads get an account list error', e);
      dispatch(showErrorToast(`${errorMessages.UNKNOWN_ERROR}`));
    }
  };
};

export const googleAdsAccountsConfigSavePress = (accounts) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    toggleSaveIndicator();
    await setServiceAccountInfo(currentShopId, 'google-ads', { googleAdsAccounts: accounts });
    dispatch({ type: TOGGLE_GOOGLE_ADS_ACCOUNTS_CONFIG_SCREEN_OPEN });
  };
};

const isGoogleAdsConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.googleAdsAccessToken;
    case GOOGLE_ADS_DISCONNECT_DONE:
      return false;
    case GOOGLE_ADS_CONNECTED:
      return true;
    default:
      return state;
  }
};

const isGoogleAdsIntegrationBtnLoading = (state = false, action) => {
  switch (action.type) {
    case GOOGLE_ADS_CONNECT_STARTED:
      return true;
    case GOOGLE_ADS_CONNECT_DONE:
      return false;
    case GOOGLE_ADS_DISCONNECT_STARTED:
      return true;
    case GOOGLE_ADS_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};

const googleAdsAccounts = (state = {}, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.googleAdsAccounts || {};
    case GOOGLE_CUSTOMERS_LIST_UPDATED:
      return action.payload;
    default:
      return state;
  }
};

export const toggleGoogleAdsConfigScreenOpen = () => ({
  type: TOGGLE_GOOGLE_ADS_ACCOUNTS_CONFIG_SCREEN_OPEN,
});

const googleAdsConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case GOOGLE_CUSTOMERS_LIST_UPDATED:
      return true;
    case TOGGLE_GOOGLE_ADS_ACCOUNTS_CONFIG_SCREEN_OPEN:
      return !state;
    case GOOGLE_ADS_CONFIG_SCREEN_OPEN:
      return true;
    default:
      return state;
  }
};

export const reducers = {
  googleAdsAccounts,
  googleAdsConfigScreenIsOpen,
  isGoogleAdsConnected,
  isGoogleAdsIntegrationBtnLoading,
};
