import { useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { uploadMedia } from 'utils/media';
import { DropZone, Modal, Spinner } from '@shopify/polaris';
import { ReactComponent as FileIcon } from '../../icons/file_image.svg';
import { useAppDispatch } from 'index';
import { closeContentHubUploadMedia } from 'ducks/actions';

type CreativeHubDropzoneProps = {
  afterUpload?: () => void;
  serviceId?: string;
  customId?: string;
  shopId?: string;
  source?: string;
  upload_to_folder?: string;
  //   gotToUploaded: () => void;
};

const CreativeHubDropzone: React.FC<CreativeHubDropzoneProps> = ({
  afterUpload,
  customId,
  shopId,
  source,
  upload_to_folder,
  serviceId,
}) => {
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadError, setUploadError] = useState('');
  const [mediaUploadProgress, setMediaUploadInProgress] = useState(false);
  const user = useSelector((state: RootState) => state.userEmail);

  const handleUploadException = (error: { message: string }, fileName: string) => {
    setUploadError(error.message);
    if (fileName) {
      setUploadProgress((prevProgress) => {
        let prev = { ...prevProgress };
        delete prev[fileName];
        return { ...prev };
      });
    }
  };

  const onFileUpload = async (fileList: Array<File> | undefined) => {
    const files = fileList ? Array.from(fileList) : [];
    let promises: Array<Promise<any>> = [];

    if (files?.length) {
      setMediaUploadInProgress(true);
      files.map(async (file) => {
        setUploadProgress((prevProgress) => ({ ...prevProgress, ...{ [file.name]: 0 } }));
        try {
          let promise = uploadMedia(
            file,
            shopId ?? currentShopId,
            serviceId ?? 'triplewhale',
            source ?? 'media_library',
            (progress, name) => {
              setUploadProgress((prevProgress) => {
                return { ...prevProgress, ...{ [name]: progress } };
              });
              if (progress === 100) {
                setTimeout(() => {
                  setUploadProgress((prevProgress) => {
                    let prev = { ...prevProgress };
                    delete prev[name];
                    return { ...prev };
                  });
                }, 3000);
              }
            },
            user,
            customId,
            undefined,
            upload_to_folder,
            // '640f0ffadf4e288e73ce2046'
          ).then((res) => {});

          promises.push(promise);
        } catch (e) {
          handleUploadException(e, file.name);
        }
      });

      Promise.all(promises).then((values) => {
        setMediaUploadInProgress(false);
        dispatch(closeContentHubUploadMedia());
        afterUpload?.();
      });
      return;
    }
    return;
  };

  return (
    <div style={{ padding: '40px' }} className="upload-media">
      <DropZone
        variableHeight={true}
        onDrop={(_dropFiles, acceptedFiles, rejectedFiles) => onFileUpload(acceptedFiles)}
        allowMultiple
        accept="video/*,image/*"
        dropOnPage
        disabled={mediaUploadProgress || !!isFromMobileApp}
      >
        <div className=" grid justify-center align-middle grid-cols-1 p-80">
          {mediaUploadProgress ? (
            <div className=" text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className=" text-center">
                <FileIcon />
              </div>
              <div className=" text-center">
                Drop files or browse your files (max file size 30MB)
              </div>
            </>
          )}
        </div>
      </DropZone>
    </div>
  );
};

export default CreativeHubDropzone;
