import { SET_ADD_MODAL_SECTION, SET_EDIT_MODAL_SECTION } from 'ducks/constants';

export const toggleShowingModalSection = (data) => ({
  type: SET_ADD_MODAL_SECTION,
  payload: data,
});
export const toggleEditModalSection = (data) => ({
  type: SET_EDIT_MODAL_SECTION,
  payload: data,
});
