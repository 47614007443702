import { WidgetQuery, WillyWidgetElement } from './types/willyTypes';
import { AxisDomain, GridOptions, MessageTypes, TileModes } from './types/willyTypes';
import { WillyChartLayout } from './types/willyTypes';
import { GridColumnOptions } from './types/willyTypes';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { WillyMetric } from './types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { WillyWidget } from './WillyWidget';
import { $activeAccounts, $currency, $currentShopId, $timezone } from '$stores/$shop';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NlqResponse } from './types/willyTypes';
import { WillyParameter } from '@tw/willy-data-dictionary/module/columns/types';
import { useDefaultType } from './hooks/useDefaultType';
import { DEFAULT_AXIS_DOMAIN, MAX_ITEMS_PER_PAGE } from './constants';
import { cohortColor } from 'constants/general';
import { noop } from 'lodash';
import { Dialect } from '@tw/types';
import { WillyEditMetric } from './WillyEditMetric';
import { executeCustomQuery } from './utils/willyUtils';
import axiosInstance from 'utils/axiosInstance';
import { useWillyTitle } from './hooks/useWillyTitle';
import { useWillySocket } from './WillySocket';
import { $shopDashboards } from '$stores/willy/$shopDashboards';
import _db, { toArray } from 'utils/DB';
import { CanceledError } from 'axios';

type InsightsWillyWidgetProps = {
  queryId: string;
  question: string;
  dialect: Dialect;
  dashboardId: string | null;
};

export const InsightsWillyWidget: React.FC<InsightsWillyWidgetProps> = ({
  queryId,
  question,
  dashboardId,
  dialect,
}) => {
  const isInitRef = useRef(true);
  const willySocket = useWillySocket();
  const currency = useStoreValue($currency);
  const timezone = useStoreValue($timezone);
  const activeAccounts = useStoreValue($activeAccounts);
  const currentShopId = useStoreValue($currentShopId);
  const dashboards = useStoreValue($shopDashboards);
  const [codeResultData, setCodeResultData] = useState<NlqResponse>();
  const [data, setData] = useState<NlqResponse>();
  const [loading, setLoading] = useState(true); // true by default to avoid loading delay, talk to me if you want to change this
  const [loadingError, setLoadingError] = useState<string>();
  const [metrics, setMetrics] = useState<WillyMetric[]>([]);
  const [parameters, setParameters] = useState<WillyParameter[]>([]);
  const [builderSetup, setBuilderSetup] = useState<BuilderTable>();

  const defaultType = useDefaultType(
    codeResultData?.data || data?.data,
    codeResultData?.dataColumns || data?.dataColumns,
    question,
    data?.dataType,
    data?.visualizationType,
  );
  const [type, setType] = useState<MessageTypes>(defaultType || 'table');
  const [wrapText, setWrapText] = useState(false);
  const [stacked, setStacked] = useState(false);
  const [chartLayout, setChartLayout] = useState<WillyChartLayout>('horizontal');
  const [grid, setGrid] = useState('flex' as GridOptions);
  const [gridColumns, setGridColumns] = useState(2 as GridColumnOptions);
  const [twoColumnMobile, setTwoColumnMobile] = useState(false);
  const [tileMode, setTileMode] = useState('tile' as TileModes);
  const [skinny, setSkinny] = useState(false);
  const [yAxisDomain, setYAxisDomain] = useState<AxisDomain>(DEFAULT_AXIS_DOMAIN);
  const [allowDataOverflow, setAllowDataOverflow] = useState(false);
  const [dimension, setDimension] = useState('');
  const [incrementedStacked, setIncrementedStacked] = useState(false);
  const [hasConditionalFormatting, setHasConditionalFormatting] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(parameters?.length > 0);
  const [conditionalFormattingColor, setConditionalFormattingColor] = useState(cohortColor);
  const [breakdownMode, setBreakdownMode] = useState(false);
  const [queries, setQueries] = useState<WidgetQuery[]>([]);
  const [widgets, setWidgets] = useState<WillyWidgetElement[]>();
  const [editMetricModalOpen, setEditMetricModalOpen] = useState<{
    open: boolean;
    queryId?: string;
    metricId?: string;
  }>({ open: false });

  const dashboard = useMemo(() => {
    return dashboards?.find((d) => d.id === dashboardId);
  }, [dashboards, dashboardId]);

  const metricsChanged = useCallback(async (id: string, v: WillyMetric[]) => {
    setMetrics(v);
  }, []);

  const builderSetupChanged = useCallback(async (v: BuilderTable) => {
    setBuilderSetup(v);
  }, []);

  const queriesChanged = useCallback(async (v: WidgetQuery[]) => {
    setQueries(v);
  }, []);

  const breakdownModeChanged = useCallback(async (v: boolean) => {
    setBreakdownMode(v);
  }, []);

  const { title, setTitle } = useWillyTitle(queryId);

  const suggestTitle = useCallback(async () => {
    if (title) {
      setTitle(title);
      return;
    }
    if (loading || !question || !currentShopId || !queryId) {
      return;
    }

    isInitRef.current = false;

    willySocket.socket.emit('suggest-title', {
      generatedQuery: data?.generatedQuery ?? '',
      question,
      shopId: currentShopId,
      mode: 'title',
      queryId,
    });
  }, [currentShopId, queryId, title, willySocket, setTitle, loading, question, data]);

  useEffect(() => {
    suggestTitle();
  }, [suggestTitle]);

  useEffect(() => {
    const q = {
      id: queryId,
      query: '',
      question,
    };
    setQueries([q]);
  }, [queryId, question]);

  const fetchData = useCallback(
    async (abortSignal?: AbortSignal) => {
      if (!currentShopId || !timezone || !activeAccounts) {
        return;
      }

      if (!widgets) {
        return;
      }

      setLoading(true);
      setLoadingError(undefined);
      try {
        const { data } = await axiosInstance.post('/v2/willy/get-query-by-id', {
          shopId: currentShopId,
          queryId,
        });

        if (data.error) {
          setLoadingError(data.error);
          return;
        }

        if (!data.generatedQuery) {
          setLoadingError('No query found');
          return;
        }

        const query = data.generatedQuery;

        let queryParams: Record<string, string> = {};

        if (dashboardId) {
          const widget = widgets.find((w) => w.queryId === queryId);
          if (widget?.parameters) {
            queryParams = widget.parameters.reduce(
              (acc, param) => {
                acc[param.column] = Array.isArray(param.value)
                  ? param.value.join(',')
                  : param.value;
                return acc;
              },
              {} as Record<string, string>,
            );
          }
        }

        const nlqResponse = await executeCustomQuery({
          query,
          shopId: currentShopId,
          activeAccounts,
          currency,
          timezone,
          dialect,
          abortSignal,
          page: 1,
          pageSize: MAX_ITEMS_PER_PAGE,
          queryParams,
        });
        const { question = '', generatedQuery = '' } = nlqResponse;
        setData({ ...nlqResponse, queries: [{ id: queryId, query: generatedQuery, question }] });
      } catch (e) {
        if (e instanceof CanceledError) {
          return;
        }
        console.error(e);
        setLoadingError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [currentShopId, timezone, activeAccounts, queryId, dashboardId, currency, dialect, widgets],
  );

  useEffect(() => {
    (async () => {
      if (!dashboard || !currentShopId) {
        setWidgets([]);
        return;
      }

      const widgetsDocs = await _db()
        .collection('willy_dashboards')
        .doc(dashboard.id)
        .collection('widgets')
        .get();
      setWidgets(toArray(widgetsDocs));
    })();
  }, [dashboard, currentShopId]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController.signal);
    return () => abortController.abort();
  }, [fetchData]);

  useEffect(() => {
    setType(defaultType || 'table');
  }, [defaultType]);

  return (
    <>
      <WillyWidget
        queryId={queryId}
        initialRawData={data}
        loadInitialData={loading}
        initialError={loadingError ? { [queryId]: loadingError } : undefined}
        metrics={metrics}
        type={type}
        builderSetup={builderSetup}
        context="dashboard"
        allowDataOverflow={allowDataOverflow}
        stacked={stacked}
        incrementedStacked={incrementedStacked}
        title={title}
        currency={currency}
        dialect={dialect}
        dimension={dimension}
        filtersOpen={filtersOpen}
        typeChanged={setType}
        stackedChanged={setStacked}
        permissionChanged={noop}
        incrementedStackedChanged={setIncrementedStacked}
        titleChanged={setTitle}
        setEditMetricModalOpen={setEditMetricModalOpen}
        isDynamic={false}
        question={question}
        shouldReplaceTablesWithNlq={false}
        suppressMetricClick={true}
        globalConditionalFormattingColor={conditionalFormattingColor}
        grid={grid}
        gridColumns={gridColumns}
        setWrapText={setWrapText}
        wrapText={wrapText}
        parametersChanged={setParameters}
        hasGlobalConditionalFormatting={hasConditionalFormatting}
        isSyncCharts={false}
        metricsChanged={metricsChanged}
        permission={{ providers: [] }}
        builderSetupChanged={builderSetupChanged}
        queriesChanged={queriesChanged}
        queries={queries}
        setGrid={setGrid}
        setGridColumns={setGridColumns}
        twoColumnMobile={twoColumnMobile}
        setTwoColumnMobile={setTwoColumnMobile}
        setAllowDataOverflow={setAllowDataOverflow}
        setYAxisDomain={setYAxisDomain}
        setSkinny={setSkinny}
        tileMode={tileMode}
        setTileMode={setTileMode}
        setDimension={setDimension}
        setLeftYAxisLabel={noop}
        setRightYAxisLabel={noop}
        setXAxisLabel={noop}
        setChartLabel={noop}
        setHasGlobalConditionalFormatting={setHasConditionalFormatting}
        setGlobalConditionalFormattingColor={setConditionalFormattingColor}
        setFiltersOpen={setFiltersOpen}
        breakdownMode={breakdownMode}
        initialShowPreviousPeriod={false}
        skinny={skinny}
        breakdownModeChanged={breakdownModeChanged}
        yAxisDomain={yAxisDomain}
        chartLayout={chartLayout}
        setChartLayout={setChartLayout}
      />

      <WillyEditMetric
        open={editMetricModalOpen.open}
        metric={metrics?.find((m) => m?.key === editMetricModalOpen.metricId) ?? null}
        availableMetrics={metrics}
        parameters={parameters}
        onClose={() => setEditMetricModalOpen({ open: false })}
        onSaved={async (metric) => {
          setMetrics((old) => {
            return old.map((m) => {
              if (m.key === editMetricModalOpen.metricId) {
                return {
                  ...m,
                  ...metric,
                };
              }
              return m;
            });
          });
          setEditMetricModalOpen({ open: false });
        }}
        onRemoved={async (metricToRemove) => {
          setMetrics((old) => {
            return old.filter((m) => m.key !== metricToRemove.key);
          });
          setEditMetricModalOpen({ open: false });
        }}
      />
    </>
  );
};
