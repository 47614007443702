import { AnalyticsObjectType } from '@tw/types';
import { ActionIcon, TextInput } from '@tw/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { toast } from 'react-toastify';
import { updateBudgetOrBid } from '../utils/willyUtils';
import { UpdatableField } from '../types/willyTypes';

type WillyUpdatableFieldProps = {
  entity: AnalyticsObjectType;
  id: string;
  value: string | null;
  field: UpdatableField;
};

export const WillyUpdatableField: React.FC<WillyUpdatableFieldProps> = ({
  entity,
  id,
  value,
  field,
}) => {
  const facebookAdsScopes = useAppSelector<string[]>(
    (state) => state.shop?.facebookAdsScopes || [],
  );
  const canUpdateAd = useAppSelector((state) => state.canUpdateAd);
  const currentShopId = useAppSelector((state) => state.currentShopId);

  const [newValue, setNewValue] = useState(value);
  const [saveLoading, setSaveLoading] = useState(false);

  const hasFacebookAdManagementPermission = useMemo(() => {
    return facebookAdsScopes?.includes('ads_management');
  }, [facebookAdsScopes]);

  const permitted =
    hasFacebookAdManagementPermission &&
    canUpdateAd &&
    currentShopId === 'madisonbraids.myshopify.com';

  const fieldToSend = useMemo(() => {
    return field.split('_').slice(1).join('_');
  }, [field]);

  const fieldIsNotInLevel = useMemo(() => {
    if (field.startsWith('campaign') && entity !== 'campaign') {
      return 'Budget is in campaign level';
    }
    if (field.startsWith('adset') && entity !== 'adset') {
      return 'Budget is in adset level';
    }
    return null;
  }, [entity, field]);

  const formatNoBidAmountText = useMemo(() => {
    if (entity === 'ad') {
      return '';
    }
    if (entity === 'adset') {
      return '-';
    }
    return 'No Bid Amount';
  }, [entity]);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <div className="flex items-start gap-2 px-2">
      <div className="flex flex-col gap-1">
        <TextInput
          size="xs"
          type="number"
          value={newValue ?? '-'}
          disabled={!permitted || saveLoading || !!fieldIsNotInLevel}
          onChange={async (e) => {
            const b = parseInt(e);
            if (b < 0) {
              return;
            }
            setNewValue(e);
          }}
        />
        {fieldIsNotInLevel && (
          <p className="text-sm text-gray-500 leading-none">{fieldIsNotInLevel}</p>
        )}
      </div>
      <ActionIcon
        variant="activator"
        color="one.6"
        size="sm"
        disabled={
          !newValue || saveLoading || !permitted || newValue === value || !!fieldIsNotInLevel
        }
        onClick={async () => {
          setNewValue(value);
        }}
        icon="close"
      />

      <ActionIcon
        variant="activator"
        color="one.6"
        size="sm"
        disabled={
          !newValue || saveLoading || !permitted || newValue === value || !!fieldIsNotInLevel
        }
        onClick={async () => {
          if (!newValue) {
            setNewValue(value);
            toast.error(`Failed to update ${field}`);
            return;
          }
          try {
            const { success, message } = await updateBudgetOrBid(
              currentShopId,
              entity,
              id,
              fieldToSend,
              newValue,
            );
            setSaveLoading(false);
            if (success) {
              toast.success(`${field} updated successfully`);
            } else {
              toast.error(message);
            }
          } catch (e) {
            setSaveLoading(false);
            setNewValue(value);
            toast.error(`Failed to update ${field}`);
          }
          setSaveLoading(true);
        }}
        icon={saveLoading ? 'loader-1' : 'checkmark'}
      />
    </div>
  );
};
