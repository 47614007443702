import { AttributionPageContext } from 'constants/attribution';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { formatNumber } from 'utils/formatNumber';
import { Text, Link, Icon } from '@shopify/polaris';
import { mapEntityToBreakdown } from 'utils/attributions';
import TWTable from 'components/library/TWTable/TWTable';
import { LoadingOverlay, Modal } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $currency, $forceSharded, $usePixelWillyWay } from '../../$stores/$shop';
import { getSubscriptions } from 'ducks/subscription';
import { ExternalSmallMinor } from '@shopify/polaris-icons';

type SubscriptionsProps = {
  item: AttributionData;
  onClose: () => void;
  metricKey?: string;
};

const Subscriptions: React.FC<SubscriptionsProps> = ({ onClose, item, metricKey = '' }) => {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const useNexus = true;
  const isClickhouse = useStoreValue($usePixelWillyWay);
  const forceSharded = useStoreValue($forceSharded);

  let { sourceId } = useContext(AttributionPageContext);

  if (!sourceId && item.serviceId) {
    sourceId = item.serviceId;
  }

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const currency = useStoreValue($currency);
  const sensoryIntegrations = useSelector((state: RootState) => state.sensory.sensoryIntegrations);
  const { id: integrationId } =
    sensoryIntegrations.filter((integration) => integration.provider_id === 'recharge')?.[0] ?? '';
  const { dateModel, attributionModel, attributionWindow, useNewModels } = useSelector(
    (state: RootState) => state.attribution,
  );

  const type = mapEntityToBreakdown[item.entity!];
  const source = item.entity === 'channel' ? item.id : sourceId;

  const _onClose = () => {
    onClose();
  };

  const {
    id,
    unmatchedIds,
    unmatchedAdsets,
    unmatchedCampaigns,
    esKey,
    name,
    campaignId,
    adsetId,
  } = item;

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      if (!mainDatePickerSelectionRange) return;
      const { start, end } = mainDatePickerSelectionRange;
      const results = await getSubscriptions(
        dateModel,
        attributionModel,
        source,
        type,
        id,
        unmatchedIds,
        start.format(),
        end.format(),
        name,
        attributionWindow,
        useNewModels,
        integrationId,
        [...(campaignId ? [campaignId] : []), ...(unmatchedCampaigns || [])],
        [...(adsetId ? [adsetId] : []), ...(unmatchedAdsets || [])],
        esKey || '',
        forceSharded,
      );
      setSubscriptions(results);
      setLoading(false);
    };
    func();
  }, [
    attributionModel,
    dateModel,
    id,
    mainDatePickerSelectionRange,
    sourceId,
    type,
    source,
    attributionWindow,
    name,
    useNewModels,
    integrationId,
    unmatchedIds,
    campaignId,
    adsetId,
    esKey,
    metricKey,
    useNexus,
    unmatchedCampaigns,
    unmatchedAdsets,
    isClickhouse,
    forceSharded,
  ]);

  const totalMrrValue = useMemo(() => {
    return subscriptions.reduce((acc, o) => acc + (Number(o.mrr) || 0), 0);
  }, [subscriptions]);
  const totalArrValue = useMemo(() => {
    return subscriptions.reduce((acc, o) => acc + (Number(o.arr) || 0), 0);
  }, [subscriptions]);

  return (
    <>
      <Modal
        lockScroll={false}
        opened={true}
        onClose={_onClose}
        size="xl"
        title={
          <>
            <p>{item.name}</p>
            <p style={{ fontSize: '10px', color: 'var(--p-text-subdued)' }}>
              {!loading && `(${subscriptions.length} subscriptions)`}
            </p>
          </>
        }
      >
        <LoadingOverlay
          visible={loading}
          zIndex={10000}
          overlayProps={{ blur: '2px', radius: 'sm', bg: 'named.0' }}
        />
        {!loading && (
          <div>
            <TWTable
              id="subscriptions-table"
              height="500px"
              stickyHeader
              data={subscriptions}
              columns={[
                {
                  key: 'subscription',
                  dataType: 'text',
                  name: 'Subscription',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Subscription
                    </Text>
                  ),
                  Value: (row) => {
                    return (
                      <Link
                        external
                        removeUnderline
                        key={row.subscriptionId}
                        url={`https://dashboard.stripe.com/subscriptions/${row.subscriptionId}`}
                      >
                        <p className="flex font-semibold">
                          {row.subscriptionId}
                          {row.subscriptionId && (
                            <Icon color="interactive" source={ExternalSmallMinor} />
                          )}
                        </p>
                      </Link>
                    );
                  },
                },
                {
                  key: 'date',
                  dataType: 'text',
                  name: 'Date',
                  sortable: false,
                  Heading: () => (
                    <div className="w-[170px]">
                      <Text as="p" variant="bodyMd" fontWeight="bold">
                        Date
                      </Text>
                    </div>
                  ),
                  Value: (row) => {
                    return row.eventDate;
                  },
                },
                {
                  key: 'customer',
                  dataType: 'text',
                  name: 'Customer',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      Customer
                    </Text>
                  ),
                  Value: (row) => {
                    return (
                      <div className="w-[200px]">
                        <p className="flex font-normal">{row.customerId}</p>
                      </div>
                    );
                  },
                },
                {
                  key: 'mrr',
                  dataType: 'text',
                  name: 'MRR',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      MRR
                    </Text>
                  ),
                  Value: (row) => {
                    const { mrr } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {formatNumber(mrr, {
                          style: 'currency',
                          currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    );
                  },
                  Total: () => {
                    return formatNumber(totalMrrValue, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    });
                  },
                },
                {
                  key: 'arr',
                  dataType: 'text',
                  name: 'ARR',
                  sortable: false,
                  Heading: () => (
                    <Text as="p" variant="bodyMd" fontWeight="bold">
                      ARR
                    </Text>
                  ),
                  Value: (row) => {
                    const { arr } = row;
                    return (
                      <Text as="p" variant="bodyMd">
                        {formatNumber(arr, {
                          style: 'currency',
                          currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    );
                  },
                  Total: () => {
                    return formatNumber(totalArrValue, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    });
                  },
                },
              ]}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default Subscriptions;
