import { unionBy } from 'lodash';
import { combineReducers, Reducer } from 'redux';
import { ExternalApp, ExternalAppMetric } from 'types/ExternalApp';

export const LOADING_APPS = 'LOADING_APPS';
export const APPS_COLLECTION_RECEIVED = 'APPS_COLLECTION_RECEIVED';
export const APPS_SECTIONS_RECEIVED = 'APPS_SECTIONS_RECEIVED';
export const APPS_METRICS_RECEIVED = 'APPS_METRICS_RECEIVED';

const loadingApps: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case LOADING_APPS:
      return true;
    case APPS_COLLECTION_RECEIVED:
      return false;
    default:
      return state;
  }
};

const apps: Reducer<ExternalApp[]> = (state = [], action) => {
  switch (action.type) {
    case APPS_COLLECTION_RECEIVED:
      return action.apps || state;
    default:
      return state;
  }
};

const externalSections: Reducer<Record<string, ExternalAppMetric<any>>> = (state = {}, action) => {
  switch (action.type) {
    case APPS_SECTIONS_RECEIVED:
      const section = action.section;
      return {
        ...state,
        [section.id]: section,
      };

    default:
      return state;
  }
};

const externalMetrics: Reducer<any[]> = (state = [], action) => {
  switch (action.type) {
    case APPS_METRICS_RECEIVED:
      return unionBy(state.concat(action.metrics || []), (item) => item.id);
    default:
      return state;
  }
};

export const reducers = combineReducers({
  loadingApps,
  apps,
  externalSections,
  externalMetrics,
});
