import './StatusIndicator.scss';

import React, { FC } from 'react';

import { Tooltip } from '@shopify/polaris';
import { AttributeStatus } from '@tw/types';

interface StatusIndicatorProps {
  status: AttributeStatus;
  radius?: string;
}

const StatusIndicator: FC<StatusIndicatorProps> = ({ status, radius }) => {
  const r = radius || '6';
  return (
    <div className="StatusIndicator" data-testid="StatusIndicator">
      <Tooltip content={<span>{status}</span>}>
        <div
          className={`rounded-full w-${r} h-${r} ${
            status === 'ACTIVE' ? 'bg-green' : 'bg-[#999999]'
          }`}
        ></div>
      </Tooltip>
    </div>
  );
};

export default StatusIndicator;
