import { toast } from 'react-toastify';

export const successAlertWithAction = (
  message: string,
  onApply: () => void,
  applyButtonText: string,
  onDismiss?: () => void,
  dismissButtonText?: string,
) => {
  toast.success(message, {
    closeButton: (
      <div className="flex h-full text-[14px] items-center bg-white border-t border-solid border-b-0 border-r-0 border-l-0 border-[#0000001a]">
        <div
          rel="button"
          role="button"
          className="flex select-none cursor-pointer p-6.5 w-1/2 justify-center items-center leading-none border-r border-solid border-t-0 border-b-0 border-l-0 border-[#0000001a]"
          onClick={() => onDismiss?.()}
        >
          {dismissButtonText ?? 'Dismiss'}
        </div>
        <div
          rel="button"
          onClick={onApply}
          role="button"
          className="flex select-none cursor-pointer p-6.5 w-1/2 justify-center items-center leading-none text-[#0C70F2]"
        >
          {applyButtonText}
        </div>
      </div>
    ),
    hideProgressBar: true,
    autoClose: 10000,
    className: 'flex-col mb-0 p-0 bg-[#ECFDF8]',
    bodyClassName: 'p-9 gap-4 text-[#065F46]',
  });
};
