import 'firebase/compat/firestore';
import Api from '../utils/Api';
import _db, { toArray } from 'utils/DB';
import _ from 'lodash';
import { combineReducers } from 'redux';
import { $currentShopId } from '$stores/$shop';

export const STATS_LTV_RECEIVED = 'STATS_LTV_RECEIVED';
export const statsLtvReceived = (data) => ({
  type: STATS_LTV_RECEIVED,
  payload: data,
});

const uniqueCustomerCount = (state = 0, action) => {
  switch (action.type) {
    case STATS_LTV_RECEIVED:
      return action.payload.uniqueCustomerCount;
    default:
      return state;
  }
};

export const LTV_SELECTED_CDP_SEGMENT = 'LTV_SELECTED_CDP_SEGMENT';
export const updateSelectedCDPSegment = (segmentId) => (dispatch) => {
  dispatch({
    type: LTV_SELECTED_CDP_SEGMENT,
    payload: segmentId,
  });
};
const ltvSelectedCDPSegment = (state = null, action) => {
  switch (action.type) {
    case LTV_SELECTED_CDP_SEGMENT:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  uniqueCustomerCount,
  ltvSelectedCDPSegment,
});

export const reimportShopifyNonAdmin = async ({ featureReleaseDate }) => {
  const shopId = $currentShopId.get();
  // This API function does not exist in the backend, But its anyway not used in the frontend.
  const res = null; //await Api('reimportShopifyNonAdmin', { shopId, featureReleaseDate });
  return res;
};
