import { useAppDispatch } from 'index';
import { resetStats } from 'ducks/newStats';
import { switchStore } from 'ducks/auth';
import { useStoresNav } from './useStoresNav';
import { MobileStoresChooser } from '../../blocks/MobileStoresChooser';

export const MobileStoresNav = () => {
  const { shops } = useStoresNav();
  const dispatch = useAppDispatch();

  return (
    <MobileStoresChooser
      shops={shops}
      onStoreClicked={(shopId) => {
        dispatch(resetStats());
        dispatch(switchStore(shopId, true));
      }}
    />
  );
};
