import { Card, DisplayText, Modal, Stack } from '@shopify/polaris';
import { HintMajor } from '@shopify/polaris-icons';
import { mapToNewIds } from 'constants/general';
import { importingNowVisibilityChanged } from 'ducks/summary';
import { useAppDispatch } from 'index';
import React from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import services from '../constants/services';
import BackgroundImporting from './BackgroundImporting';

type ImportingServicesComponentProps = {
  only?: string;
  inModal?: boolean;
};

const ImportingServicesComponent: React.FC<ImportingServicesComponentProps> = ({
  only,
  inModal,
}) => {
  const dispatch = useAppDispatch();
  let importingServices = useSelector((state: RootState) => state.importingServices);
  let importingNewInfraServices = useSelector(
    (state: RootState) => state.importingNewInfraServices,
  );
  const anyImportingNow = useSelector((state: RootState) => state.anyImportingNow);
  const anyImportingNowNewInfra = useSelector((state: RootState) => state.anyImportingNowNewInfra);

  const mapped = Object.entries(mapToNewIds).reduce(
    (acc, [oldId, newId]) => ({ ...acc, [newId]: importingServices[oldId] }),
    {},
  );

  importingServices = { ...mapped, ...importingServices };

  if (!anyImportingNow && !anyImportingNowNewInfra) return null;
  if (only && (!importingServices[only] || !importingNewInfraServices[only])) return null;

  const resElement = (
    <Stack distribution={'fill'}>
      {Object.entries(importingServices).map(
        ([id, service]) =>
          (!only || id === only) &&
          services[id] &&
          service && (
            <Stack.Item key={id}>
              <BackgroundImporting
                key={id}
                service={services[id]}
                count={service.days}
                total={service.totalDays}
              />
            </Stack.Item>
          ),
      )}

      {Object.entries(importingNewInfraServices).map(
        ([id, service]) =>
          (!only || id === only) &&
          services[id] &&
          service && (
            <Stack.Item key={id}>
              <BackgroundImporting
                key={id}
                service={services[id]}
                count={service.currentIndex}
                total={service.total}
                job={importingNewInfraServices[id]}
              />
            </Stack.Item>
          ),
      )}
    </Stack>
  );

  return inModal ? (
    <Modal
      open
      title="Importing"
      onClose={() => dispatch(importingNowVisibilityChanged(false))}
      sectioned
    >
      {resElement}
    </Modal>
  ) : (
    <div className="mb-24">
      <Card
        sectioned
        title={<DisplayText size="small">Importing</DisplayText>}
        primaryFooterAction={{
          content: 'Stuck? Let us know',
          onAction: () => window.Intercom('show'),
          icon: HintMajor,
        }}
      >
        {resElement}
      </Card>
    </div>
  );
};

export default ImportingServicesComponent;
