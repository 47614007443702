import { lightboxOpen } from 'ducks/actions';
import { useAppDispatch } from 'index';

import { PlayCircleMajor } from '@shopify/polaris-icons';

import CardThumbnail from '../assets/CardThumbnail';

type UploadMediaCardsProps = {
  data: any[];
};

const UploadMediaCards: React.FC<UploadMediaCardsProps> = ({ data }) => {
  const dispatch = useAppDispatch();

  const caption = (media) => {
    return (
      <div className="flex gap-4 items-center justify-between text-secondary-text">
        <p className="flex-1 truncate">{media.name}</p>
        {media.media_type === 'video' && (
          <PlayCircleMajor
            width={14}
            height={14}
            className="fill-logo opacity-70"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(
                lightboxOpen({
                  resources: [
                    {
                      url: media.url,
                      type: 'video',
                      thumbnail: media.url,
                    },
                  ],
                }),
              );
            }}
          />
        )}
      </div>
    );
  };
  return (
    <div className="uploaded-media-cards">
      <div
        className={'grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] auto-rows-[290px]'}
      >
        {data &&
          data.map((media, i) => {
            return (
              <div
                key={media.url + '-' + i}
                className="rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white"
              >
                <CardThumbnail media={media} caption={caption(media)} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UploadMediaCards;
