import { CreateEditSegmentModal } from 'components/CreateEditSegmentationModal';
import { useAppDispatch } from 'index';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { CreativeFilterOperator } from '@tw/types';

import {
  createFilter,
  deleteFilter,
  editFilter,
  openModalMetricFilter,
  defaultExpressionMetricSettings,
} from '../../../ducks/creativesCockpit/creativesCockpit';
import { SegmentationPopup } from '../../Segmentation/SegmentationPopup';
import { CreativesCockpitContext } from '../context';
import {
  metricOperands,
  operandsMetricFilter,
  operatorsEQUALS,
  operatorsNAMES,
  operatorsNames,
  operatorsNUMBERS,
} from './types';

export const CreateEditMetricFilterModal = ({ handleNew }) => {
  const {
    modalFilterData: { isOpen, serviceId, filterId },
    currentFilter,
    filters,
  } = useSelector((state: RootState) => state.creativeCockpit);
  const isEdit = !!filterId;
  const dispatch = useAppDispatch();
  const getOperandsByOperator = (operand: string): CreativeFilterOperator[] => {
    return operand.includes('_id')
      ? operatorsEQUALS
      : operand.includes('_name')
        ? operatorsNAMES
        : operatorsNUMBERS;
  };

  return (
    <CreateEditSegmentModal
      defaultExpression={defaultExpressionMetricSettings}
      currentFilter={isEdit ? filters[serviceId][filterId] : currentFilter}
      isFilter={true}
      onClose={() => dispatch(openModalMetricFilter('', ''))}
      isOpen={isOpen}
      type={serviceId}
      isEdit={isEdit}
      id={filterId}
      editFilter={(filter) => {
        dispatch(editFilter(filter, serviceId));
      }}
      serviceId={serviceId}
      createFilter={(filter) => dispatch(createFilter(filter, serviceId))}
      getOperators={(operator) =>
        getOperandsByOperator(operator).map((op) => ({
          label: operatorsNames[op] ? operatorsNames[op] : op,
          value: op,
        }))
      }
      operands={metricOperands.map((op) => ({ label: operandsMetricFilter[op].label, value: op }))}
      search={async (searchStr: string, entity: string) => {
        return [{}];
      }}
      operandsWithNoSearchList={metricOperands}
      handleNew={handleNew}
    />
  );
};

export const AllFilters = ({ plain, onApply }) => {
  const context = useContext(CreativesCockpitContext);
  const serviceId = context.serviceId!;

  const { filters } = useSelector((state: RootState) => state.creativeCockpit);
  const dispatch = useAppDispatch();

  const filtersData = filters[serviceId]!;

  return (
    <SegmentationPopup
      isFilter={true}
      segmentsData={Object.keys(filtersData || {}).map((id) => ({ ...filtersData[id], id }))}
      segmentsApplyFilter={[]}
      updateFilter={(filter) => {
        dispatch(editFilter(filter, serviceId));
      }}
      segmentOnDelete={(id) => {
        dispatch(deleteFilter(id, serviceId));
      }}
      editSegmentOpenModal={(id: string) => dispatch(openModalMetricFilter(serviceId, id))}
      serviceId={serviceId}
      plain={plain}
      onApply={onApply}
      includeAdd={true}
    />
  );
};
