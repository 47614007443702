import { lighthousePopupClose } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import React, { useCallback, useMemo, useState } from 'react';
import { LighthouseClientNotification } from './types';
import { formatNumber } from 'utils/formatNumber';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { ReactComponent as AdsetIcon } from '../Icons/adset-icon.svg';
import { ReactComponent as CampaignIcon } from '../Icons/campaign-icon.svg';
import { ReactComponent as AdIcon } from '../Icons/ad-creative-icon.svg';
import { Tabs, Tooltip } from '@shopify/polaris';
import { ReactComponent as Pixel } from '../../icons/pixel.svg';

export const LighthouseOpportunityReport: React.FC<LighthouseClientNotification> = ({
  extraData,
  Icon,
  startDate,
  endDate,
}) => {
  const { opportunityReport, story } = extraData || {};
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const selectTabHandler = useCallback((active: number) => {
    setActiveTab(active);
  }, []);

  const entityRowsCount = useCallback(
    (entity: string) => {
      return opportunityReport.filter((result) => result.entity === entity).length;
    },
    [opportunityReport],
  );

  const explanation = useMemo(() => {
    return `Between the dates ${startDate.format('LL')} and ${endDate.format(
      'LL',
    )}, here is the top opportunities for your Meta ad accounts, see below 👇`;
  }, [startDate, endDate]);

  const tabs = useMemo(
    () => [
      {
        content: (
          <span className="flex items-center gap-2">
            <CampaignIcon fill={activeTab === 0 ? '#1877F2' : '#6F95B3'} />
            {`Campaigns (${entityRowsCount('campaign')})`}
          </span>
        ),
        id: 'campaign',
      },
      {
        content: (
          <span className="flex items-center gap-2">
            <AdsetIcon fill={activeTab === 1 ? '#1877F2' : '#6F95B3'} />
            {`Ad Sets (${entityRowsCount('adset')})`}
          </span>
        ),
        id: 'adset',
      },
      {
        content: (
          <span className="flex items-center gap-2">
            <AdIcon fill={activeTab === 2 ? '#1877F2' : '#6F95B3'} />
            {`Ad Creatives (${entityRowsCount('ad')})`}
          </span>
        ),
        id: 'ad',
      },
    ],
    [entityRowsCount, activeTab],
  );

  const tableData = useMemo(() => {
    return opportunityReport
      .filter((result) => result.entity === tabs[activeTab].id)
      .sort((a, b) => b.saving - a.saving);
  }, [opportunityReport, activeTab, tabs]);

  return (
    <DraggableModal
      onClose={() => {
        dispatch(lighthousePopupClose());
      }}
      open={true}
      width="medium"
      style={{
        backgroundColor: '#11283E',
        border: '1px solid rgba(192, 214, 234, 0.1)',
      }}
    >
      <div className="w-full flex flex-col gap-6.5 sm:rounded-[8px] bg-primary text-white relative py-6.5">
        <div className="min-w-[unset] flex items-center gap-4 px-6.5 pr-[45px]">
          <div className="border border-solid border-[#2E4E65] rounded-[7px] p-2 flex items-center justify-center bg-[#062940]">
            {Icon(40)}
          </div>
          <div className="text-[24px] leading-[30px] font-medium flex">
            <span>{story}</span>
          </div>
        </div>
        <div className="p-[30px_20px] bg-[#0D1F31]">{explanation}</div>
        <div className="px-6.5">
          <div className="dark-tw-tabs">
            <div className="tw-tabs">
              <Tabs tabs={tabs} selected={activeTab} onSelect={selectTabHandler} />
            </div>
          </div>
          <div className="tw-scroll h-[300px] overflow-auto ">
            <div className="table align-middle w-full">
              <div className="text-[#6F95B3] text-[14px] font-medium table-row h-[40px]">
                <div className="table-cell sticky top-0 z-10 align-middle px-4 bg-[#1a3046] rounded-[4px_0_0_4px]">
                  Name
                </div>
                <div className="table-cell sticky top-0 align-middle px-2 text-end bg-[#1a3046]">
                  ROAS
                </div>
                <div className="table-cell sticky top-0 align-middle px-2 text-end bg-[#1a3046]">
                  <div className="flex items-center gap-2 text-[#6F95B3]">
                    <Pixel className="w-8 h-8" />
                    <span>ROAS</span>
                  </div>
                </div>
                <div className="table-cell sticky top-0 align-middle px-4 text-end bg-[#1a3046] rounded-[0_4px_4px_0]">
                  Delta
                </div>
                <div className="table-cell sticky top-0 align-middle px-4 text-end bg-[#1a3046] rounded-[0_4px_4px_0] whitespace-nowrap">
                  % Delta
                </div>
              </div>
              <div className="table-row-group">
                {tableData.map((ruleResult, index) => {
                  const { entity, name, campaignName, adsetName } = ruleResult;
                  let tooltipLabel = '';
                  if (entity === 'campaign') {
                    tooltipLabel = name;
                  } else if (entity === 'adset') {
                    tooltipLabel = campaignName ? (
                      <>
                        <span className="font-semibold">Campaign:</span> {campaignName}
                      </>
                    ) : (
                      name
                    );
                  } else if (entity === 'ad') {
                    tooltipLabel =
                      adsetName && campaignName ? (
                        <>
                          <span className="font-semibold">Adset:</span>
                          {adsetName}, <span className="font-semibold">Campaign:</span>
                          {campaignName}
                        </>
                      ) : (
                        name
                      );
                  }
                  const delta = ruleResult.roas - ruleResult.pixelRoas;
                  const deltaPercentage = (delta / ruleResult.pixelRoas) * 100;
                  const deltaColor = deltaPercentage < 0 ? 'text-green' : 'text-red-500';

                  return (
                    <div key={ruleResult.id} className={`h-[40px] table-row text-[14px]`}>
                      <div
                        className={`overflow-hidden text-ellipsis whitespace-nowrap table-cell align-middle px-4 rounded-[4px_0_0_4px] ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        <div className="font-semibold max-w-xs text-ellipsis overflow-hidden">
                          <Tooltip content={tooltipLabel}>
                            <span className=" text-xl">{ruleResult.name}</span>
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className={`table-cell align-middle px-2 text-end ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        {formatNumber(ruleResult.roas, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: 'decimal',
                        })}
                      </div>
                      <div
                        className={`table-cell align-middle px-2 text-end ${
                          index % 2 ? 'bg-white/4' : ''
                        }`}
                      >
                        {formatNumber(ruleResult.pixelRoas, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: 'decimal',
                        })}
                      </div>
                      <div
                        className={`table-cell align-middle px-4 text-end rounded-[0_4px_4px_0] ${
                          index % 2 ? 'bg-white/4' : ''
                        } ${deltaColor}`}
                      >
                        {formatNumber(delta, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          style: 'decimal',
                        })}
                      </div>
                      <div
                        className={`table-cell align-middle px-4 text-end rounded-[0_4px_4px_0] ${
                          index % 2 ? 'bg-white/4' : ''
                        } ${deltaColor}`}
                      >
                        {formatNumber(deltaPercentage, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: 'decimal',
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};
