import {
  $currentDateRange,
  $granularity,
  $prevDateRange,
  $prevDateSelectedOption,
  CurrentDateRange,
  getCurrentDateSelectedOption,
  getDefaultGranularity,
  pushDateToQS,
  pushFullDateToQS,
} from '$stores/willy/$dateRange';
import { DatePicker } from '@shopify/polaris';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { Granularity } from '@tw/types';
import { mapFromPeriodIdToTimeUnit } from '@tw/types/module/datePicker/datePicker';
import { Button, Flex, Popover, Select, Size, Text } from '@tw/ui-components';
import { GroupStatsButtons } from 'components/GroupStatsButtons';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { DATE_PICKER_HEIGHT_VAR } from 'components/TopBar/constants';
import {
  DatePickerTimePeriods,
  getDatePickerOptionValueOptions,
} from 'components/useDatePickerSelectedOptions';
import { useEarliestDate } from 'components/useEarliestDate';
import { useCurrentPopoverOpenerButtonTitle } from 'components/useGetPopoverOpenerButtonTitle';
import { isGroupBtnDisabled } from 'ducks/stats';
import { useFeatureFlag } from 'feature-flag-system';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RangeWithId } from 'types/general';
import { convertDateToMoment } from 'utils/convertDateToMoment';
import {
  analyticsEvents,
  dashboardsActions,
  generalActions,
  genericEventLogger,
} from 'utils/dataLayer';
import { getPrevDates } from 'utils/getPreviousDate';
import { DateRangeOptionsList } from './DateRangeOptionsList';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $timezone } from '$stores/$shop';

type CurrentDateRangePopoverProps = {
  showGroupByButton?: boolean;
  earliestDateProp?: string | Date | null;
  isWillyDashboard?: boolean;
  buttonSize?: Exclude<Size, 0>;
  disabled?: boolean;
  unSupportedOptions?: string[];
  showCompareButton?: boolean;
};
export const CurrentDateRangePopover: React.FC<CurrentDateRangePopoverProps> = ({
  showGroupByButton,
  earliestDateProp = '',
  isWillyDashboard = false,
  buttonSize = 'sm',
  disabled,
  unSupportedOptions = [],
  showCompareButton = false,
}) => {
  const navigate = useNavigate();
  const currentDateRange = useStoreValue($currentDateRange);
  const prevDateRange = useStoreValue($prevDateRange);
  const prevDateSelectedOption = useStoreValue($prevDateSelectedOption);
  const currentPopoverLabel = useCurrentPopoverOpenerButtonTitle();
  const earliestDateForDisplay = useEarliestDate(earliestDateProp);
  const [granularity, setGranularity] = useWritableStore($granularity);
  const isSmall = useIsSmall();
  const timezone = useStoreValue($timezone);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [month, setMonth] = useState(currentDateRange?.end.month() || moment().month());
  const [year, setYear] = useState(currentDateRange?.end.year() || moment().year());
  const [value, setValue] = useState<RangeWithId | null>(null);
  const [selectedGranularity, setSelectedGranularity] =
    useState<Exclude<Granularity, 'total'>>(granularity);

  const defaultOptions = getDatePickerOptionValueOptions();
  const { numericLimit: datePickerLimitation } = useFeatureFlag(FeatureFlag.LIMIT_LOOKBACK_FF);

  const convertValueToMoment = (value: RangeWithId): CurrentDateRange => {
    return {
      start: convertDateToMoment(value.start).startOf('day'),
      end: convertDateToMoment(value.end).endOf('day'),
    };
  };

  const activeOption: DatePickerTimePeriods | undefined = useMemo(() => {
    if (!value) return;
    const currentActiveOption = getCurrentDateSelectedOption(convertValueToMoment(value));
    return currentActiveOption;
  }, [value]);

  useEffect(() => {
    if (!currentDateRange) return;
    const { start, end } = currentDateRange;
    setValue({
      start: new Date(start.year(), start.month(), start.date()),
      end: new Date(end.year(), end.month(), end.date()),
    });
  }, [currentDateRange, isPopoverOpen]);

  useEffect(() => {
    setSelectedGranularity(granularity);
  }, [granularity]);

  const displayGranularity = useMemo(() => {
    if (showGroupByButton === false) return false;
    const shouldShowWeek = !isGroupBtnDisabled({
      mainDatePickerSelectionRange: value,
      group: 'week',
    });
    const shouldShowMonth = !isGroupBtnDisabled({
      mainDatePickerSelectionRange: value,
      group: 'month',
    });
    return shouldShowWeek || shouldShowMonth;
  }, [value, showGroupByButton]);

  //REFACTOR: need to test it
  //make sure dates coming from url are not out of range for free users
  useEffect(() => {
    if (currentDateRange && earliestDateForDisplay && datePickerLimitation! > 0) {
      if (earliestDateForDisplay.isAfter(currentDateRange.end)) {
        pushDateToQS(navigate, moment().startOf('day'), moment().endOf('day'), true);
      }
    }
  }, [earliestDateForDisplay, datePickerLimitation, currentDateRange, navigate]);

  const options = useMemo(() => {
    let options = defaultOptions.filter((option) => {
      return !unSupportedOptions.find((o) => o === option?.id);
    });
    if (earliestDateForDisplay && datePickerLimitation) {
      const earliestDateFreeFeature = moment()
        .subtract(datePickerLimitation, 'month')
        .startOf('day');
      options = options.map((e) => {
        return { ...e, disabled: earliestDateFreeFeature?.isAfter(e.start) };
      });
    }
    return options;
  }, [datePickerLimitation, defaultOptions, earliestDateForDisplay, unSupportedOptions]);

  const onApply = useCallback(
    (newValue: RangeWithId | null) => {
      if (!newValue) return;
      const { start, end } = convertValueToMoment(newValue);

      const { start: compareStart, end: compareEnd } = prevDateRange || {};
      let prevStart: moment.Moment | undefined;
      let prevEnd: moment.Moment | undefined;

      const period = prevDateSelectedOption
        ? mapFromPeriodIdToTimeUnit[prevDateSelectedOption]
        : undefined;
      if (prevDateSelectedOption === 'none') {
        prevStart = undefined;
        prevEnd = undefined;
      } else if (prevDateSelectedOption === 'custom' && prevDateRange) {
        prevStart = moment(compareStart);
        prevEnd = moment(compareEnd);
      } else {
        ({ start: prevStart, end: prevEnd } = getPrevDates({ start, end }, period ?? undefined));
      }

      pushFullDateToQS(navigate, start, end, prevStart, prevEnd, prevDateSelectedOption === 'none');

      setGranularity(selectedGranularity);
      genericEventLogger(isWillyDashboard ? analyticsEvents.DASHBOARDS : analyticsEvents.GENERAL, {
        action: isWillyDashboard ? generalActions.CHANGE_DATE : dashboardsActions.CHANGE_DATE,
        version: isWillyDashboard ? '3.0' : '2.0',
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        compareStart: moment(prevStart).toDate(),
        compareEnd: moment(prevEnd).toDate(),
      });

      setIsPopoverOpen(false);
    },
    [
      isWillyDashboard,
      navigate,
      prevDateRange,
      prevDateSelectedOption,
      selectedGranularity,
      setGranularity,
    ],
  );

  return (
    <Popover
      shadow="md"
      opened={isPopoverOpen}
      position="bottom"
      onClose={() => setIsPopoverOpen(false)}
      closeOnClickOutside={false}
    >
      <Popover.Target>
        <span>
          {/* <Flex gap="xs" align='center' px="sm" borderRadius={'6px 0px 0px 6px'} border={'1px solid black'} borderRight={0}>
            <Icon name="calendar-1" size={12} />
            <Text size="sm" color="named2.0" weight={500}>{currentPopoverLabel}</Text>
          </Flex> */}
          <Button
            id={'date-picker'}
            variant={isWillyDashboard ? 'activatorWithHover' : 'activator'}
            onClick={() => setIsPopoverOpen((x) => !x)}
            disabled={disabled}
            leftSection="calendar-1"
            rightSection="caret-down"
            size={buttonSize}
          >
            {currentPopoverLabel}
          </Button>
        </span>
      </Popover.Target>
      <Popover.Dropdown bg="white" fz="sm" p={0} mah={DATE_PICKER_HEIGHT_VAR} overflow="hidden">
        <Flex direction="column" align="center">
          {/* REFACTOR: need to test it */}
          {!!datePickerLimitation && (
            <LockedFeatureIndicator
              featureFlag={FeatureFlag.LIMIT_LOOKBACK_FF}
              layout="vertical"
              extraData={{ targetToUnlock: 12 }}
            />
          )}
          <div
            className="main-date-picker flex flex-col sm:flex-row sm:h-[330px] sm:max-w-[550px] max-w-[300px] overflow-y-hidden"
            aria-label="Date Selections"
          >
            <DateRangeOptionsList
              options={options.map((option) => ({
                value: option.id,
                label: option.label,
                selected: option.id === activeOption,
                onClick: () => {
                  const { start, end, id } = option;
                  const newValue = {
                    start: new Date(start.year(), start.month(), start.date()),
                    end: new Date(end.year(), end.month(), end.date()),
                    id,
                  };
                  setValue(newValue);
                  onApply(newValue);
                },
              }))}
            />
            <div className="flex-[2] flex flex-col justify-start gap-4 p-6.5">
              <div className="flex items-center gap-4 justify-center absolute z-[20] top-[70px] right-0 sm:top-[15px] sm:right-[35px]">
                <Select
                  withBorder={false}
                  size="xs"
                  comboboxProps={{
                    offset: 0,
                    styles: {
                      option: {
                        direction: 'rtl',
                      },
                    },
                    shadow: 'md',
                  }}
                  styles={{
                    root: {
                      direction: 'rtl',
                    },
                    input: {
                      width: '130px',
                      fontWeight: 600,
                      fontSize: '14px',
                      textAlign: 'right',
                      padding: 0,
                    },
                    section: {
                      display: 'none',
                    },
                  }}
                  data={Array.from({ length: 12 }, (_, i) => ({
                    label: new Date(0, i).toLocaleString('default', { month: 'long' }),
                    value: i.toString(),
                  }))}
                  value={month.toString()}
                  onChange={(value) => {
                    setMonth(Number(value));
                  }}
                />
                <Select
                  withBorder={false}
                  size="xs"
                  comboboxProps={{
                    offset: 0,
                    shadow: 'md',
                  }}
                  styles={{
                    input: {
                      width: '130px',
                      fontWeight: 600,
                      fontSize: '14px',
                      padding: 0,
                    },
                    section: {
                      display: 'none',
                    },
                  }}
                  data={Array.from({ length: 20 }, (_, i) => ({
                    label: (new Date().getFullYear() - i).toString(),
                    value: (new Date().getFullYear() - i).toString(),
                  }))}
                  value={year.toString()}
                  onChange={(value) => {
                    setYear(Number(value));
                  }}
                />
              </div>
              <DatePicker
                //multiMonth={!isSmall}
                month={month}
                year={year}
                onChange={(date) => {
                  setValue(date);
                }}
                onMonthChange={(month, year) => {
                  setMonth(month);
                  setYear(year);
                }}
                selected={value!}
                allowRange
                disableDatesAfter={
                  new Date(
                    moment().toDate().toLocaleString('en-US', {
                      timeZone: moment().tz(),
                    }),
                  )
                }
                disableDatesBefore={earliestDateForDisplay.toDate()}
              />
              {timezone && (
                <Text size="xs" color="gray.5">
                  Timezone: {timezone.replace(/_/g, ' ')}
                </Text>
              )}
            </div>
          </div>
          {value && displayGranularity && isSmall && (
            <Flex
              p="md"
              align="center"
              gap="md"
              borderTop={'1px solid var(--mantine-color-gray-2)'}
            >
              <GroupStatsButtons
                currentDateRange={convertValueToMoment(value)}
                granularity={selectedGranularity}
                setGranularity={setSelectedGranularity}
              />
            </Flex>
          )}
          <Flex
            p="md"
            align="center"
            gap="md"
            justify={displayGranularity || isSmall ? 'space-between' : 'flex-end'}
            borderTop={'1px solid var(--mantine-color-gray-2)'}
            w="100%"
          >
            {value && displayGranularity && !isSmall && (
              <GroupStatsButtons
                currentDateRange={convertValueToMoment(value)}
                granularity={selectedGranularity}
                setGranularity={setSelectedGranularity}
              />
            )}
            <Flex
              align="center"
              gap="md"
              w={isSmall ? '100%' : undefined}
              justify={isSmall ? 'space-between' : 'flex-end'}
            >
              <Button
                onClick={() => {
                  setIsPopoverOpen(false);
                }}
                variant="activator"
              >
                Cancel
              </Button>
              <Button onClick={() => onApply(value)}>Apply</Button>
            </Flex>
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
