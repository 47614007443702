import { useEffect, useState } from 'react';

type NotFunction<T> = T extends Function ? never : T;
type LocalStorageStateSetter<T extends NotFunction<{}>> = (
  value: T | ((prevState: T) => T),
) => void;

export function useLocalStorage<T extends {} | any[]>({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue: T;
}): [T, LocalStorageStateSetter<T>] {
  const [state, setState] = useState<T>(() => safelyGetLocalStorage(key) ?? defaultValue);

  const setLocalStorageState: LocalStorageStateSetter<T> = (value) => {
    setState((prev) => {
      const val = typeof value === 'function' ? value(prev) : value;
      try {
        return val;
      } finally {
        safelySetLocalStorage(key, val);
      }
    });
  };

  return [state, setLocalStorageState];
}

function safelySetLocalStorage<T>(key: string, value: T) {
  const valStr = JSON.stringify(value);
  localStorage.setItem(key, valStr);
  return valStr;
}

type JSONParseResult = any & {}; // this type is purely for documentation
function safelyGetLocalStorage(key: string): JSONParseResult | null {
  try {
    const storedState = localStorage.getItem(key);
    return storedState ? JSON.parse(storedState) : null;
  } catch (err) {
    return null;
  }
}
