import { Button, Stack, Tooltip } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import { cloneDeep } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
import { filterAttributes, filterOperators } from './types';

type Props = {
  rule: any;
  setRule: Dispatch<SetStateAction<any>>;
  conditionsLevelsList: any;
  condition: any;
  conditionIndex: number;
  groupIndex: number;
  deleteCondition: (groupIndex: number, conditionIndex: number) => void;
  disabled: boolean;
};

export const FilterCondition: React.FC<Props> = ({
  rule,
  setRule,
  conditionsLevelsList,
  condition,
  conditionIndex,
  groupIndex,
  deleteCondition,
  disabled,
}) => {
  const setStyles = (width) => {
    return {
      container: (provided) => ({
        ...provided,
        width: width,
      }),
    };
  };

  const handleFilterChange = (val, key) => {
    let ruleCopy = cloneDeep(rule);
    let conditionCopy = cloneDeep(ruleCopy.conditions.all[groupIndex].any[conditionIndex]);
    if (key === 'path') {
      const [fact, path] = [...val.split('.')];
      conditionCopy['fact'] = fact;
      conditionCopy['path'] = `$.${path}`;
    } else {
      conditionCopy[key] = val;
    }
    ruleCopy.conditions.all[groupIndex].any[conditionIndex] = conditionCopy;
    setRule(ruleCopy);
  };

  const conditionAttributeValue = `${condition['fact']}.${
    condition['path'].split('.')[1] || condition['path']
  }`;

  return (
    <>
      <Stack distribution="equalSpacing">
        <Select
          className=""
          options={filterAttributes.filter((att) => att.show.includes(rule.level))}
          styles={setStyles(250)}
          placeholder="Choose an attribute"
          onChange={(val) => handleFilterChange(val?.value, 'path')}
          value={filterAttributes.find((option) => option.value === conditionAttributeValue || '')}
        />
        <Select
          options={filterOperators}
          styles={setStyles(250)}
          placeholder="Operator"
          onChange={(val) => handleFilterChange(val?.value, 'operator')}
          value={filterOperators.find((option) => option.value === condition?.operator || '')}
        />
        <div className="inputWrapper">
          <input
            placeholder="Enter keyword, value, etc."
            value={condition.value}
            onChange={(val) => handleFilterChange(val.target.value, 'value')}
          />
        </div>
        <div className={'mt-4'}>
          <Tooltip content={'Remove Condition'}>
            <Button
              plain
              onClick={() => deleteCondition(groupIndex, conditionIndex)}
              icon={DeleteMinor}
            />
          </Tooltip>
        </div>
      </Stack>
    </>
  );
};
