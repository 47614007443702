import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import {
  $combineShopAndCustomViewDashboards,
  $allowedShopCustomViewAndDefaultDashboards,
} from '$stores/willy/$customViews';
import { useStoreValue } from '@tw/snipestate';
import { Select } from '@tw/ui-components';
import { useMemo } from 'react';
import { WillyDashboardElement } from './types/willyTypes';
import { uniqBy } from 'lodash';

type DashboardDropDownProps = {
  label?: string;
  labelHidden?: boolean;
  disabled?: boolean;
  selected?: string;
  hideGlobals?: boolean;
  showStandardDashboards?: boolean;
  withNone?: boolean;
  onSelect: (id: string) => void;
};

export const DashboardsDropDown: React.FC<DashboardDropDownProps> = ({
  label,
  labelHidden,
  selected,
  disabled,
  hideGlobals,
  withNone,
  onSelect,
  showStandardDashboards,
}) => {
  const dashboards = useStoreValue($combinedDashboard);
  const shopAndViewDashboards = useStoreValue($combineShopAndCustomViewDashboards);
  const allowedShopCustomViewAndDefaultDashboards = useStoreValue(
    $allowedShopCustomViewAndDefaultDashboards,
  );

  const dashboardsToShow = useMemo(() => {
    let reports: WillyDashboardElement[] = [];
    if (showStandardDashboards) {
      reports = allowedShopCustomViewAndDefaultDashboards;
    }

    if (hideGlobals) {
      reports = [...reports, ...shopAndViewDashboards];
    } else {
      reports = [...reports, ...dashboards];
    }

    reports = uniqBy(reports, (r) => r.id);

    return [
      ...(withNone ? [{ name: 'None', id: '' } as WillyDashboardElement] : []),
      ...reports.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
    ];
  }, [
    showStandardDashboards,
    allowedShopCustomViewAndDefaultDashboards,
    hideGlobals,
    shopAndViewDashboards,
    dashboards,
    withNone,
  ]);

  return (
    <Select
      searchable
      label={labelHidden ? undefined : label || 'Select a report'}
      disabled={disabled}
      value={selected}
      allowDeselect
      data={dashboardsToShow
        .filter((d) => d.name)
        .map((d) => {
          let label = d.name;
          if (d.isStandardDashboard) {
            label += ' (Standard)';
          } else if (d.isGlobal) {
            label += ' (Global)';
          }
          return {
            label,
            value: d.id,
          };
        })}
      onChange={(v) => {
        if (v === null) {
          return;
        }
        onSelect(v);
      }}
    />
  );
};
