import { useAppDispatch } from 'index';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectedWorkspaceChanged } from '../../../ducks/auth';
import { type RootState } from '../../../reducers/RootType';
import PodFilterActions from '../PodFilterActions/PodFilterActions';
import { userDb } from '../../../utils/DB';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import DropDown from 'components/ltv/DropDown';

const PodFilterBar: FC = () => {
  const dispatch = useAppDispatch();

  const workspaces = useSelector((state: RootState) => state.workspaces);
  const getPodsLoading = useSelector((state: RootState) => state.getPodsLoading);
  const selectedWorkspaceId = useSelector((state: RootState) => state.selectedWorkspaceId);

  const podOptions: OptionDescriptor[] = useMemo(() => {
    return workspaces?.map?.((w) => ({ label: w.name, value: w.id }));
  }, [workspaces]);

  const onSelectedWorkspaceChanged = useCallback(
    async (data) => {
      dispatch(selectedWorkspaceChanged(data));
      try {
        await userDb().set({ selectedWorkspaceId: data }, { merge: true });
      } catch {}
    },
    [dispatch],
  );

  return (
    <div
      className={`flex items-center gap-4 mid-large:flex-nowrap flex-wrap`}
      data-testid="PodFilterBar"
    >
      <DropDown
        loading={getPodsLoading}
        options={podOptions}
        handleSelect={onSelectedWorkspaceChanged}
        value={podOptions?.find((x) => x.value === selectedWorkspaceId)?.value}
      />
      <PodFilterActions />
    </div>
  );
};

export default PodFilterBar;
