import { Allotment } from 'allotment';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MENU_SIZE, userFilterOptions } from './constants';
import { WorkflowInMobyStatus } from './hooks/useChatSocket';
import { Conversation, WillyPrompt } from './types/willyTypes';

import { Message } from './types/willyTypes';
import { WillyPageWrapper } from './WillyPageWrapper';
import { ChatHistory } from './ChatHistory';
import { chatActions, analyticsEvents } from 'utils/dataLayer/constants';
import { genericEventLogger } from 'utils/dataLayer';
import { WillyWorkflowPanel } from './WillyWorkflowPanel';
import { WillyBuilder } from './WillyBuilder';
import { WillySidePanel } from './WillySidePanel';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { Button, Text, useSelectByWindowResize } from '@tw/ui-components';
import { SequencesComponent } from './WillySequences';
import { WillyTemplateLibrary } from './WillyTemplateLibrary/WillyTemplateLibrary';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type WillyChatWrapperProps = {
  children: React.ReactNode;
  asPage: boolean;
  buildMode?: boolean;
  workflowPanelOpen?: boolean;
  setWorkflowPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  messages: Message[];
  conversationId: string;
  setBuildMode?: React.Dispatch<React.SetStateAction<boolean>>;
  clearConversation: () => void;
  currentAnalyticsEvent: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  workflowInMobyStatus: WorkflowInMobyStatus;
  workflowIdToRun?: string;
  runWorkflowOnInit?: boolean;
  runWorkflowIfPossible: boolean;
  setRunWorkflowIfPossible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const WillyMainChatWrapper: React.FC<WillyChatWrapperProps> = ({
  children,
  asPage,
  buildMode,
  setBuildMode,
  runWorkflowIfPossible,
  setRunWorkflowIfPossible,
  workflowPanelOpen,
  setWorkflowPanelOpen,
  workflowIdToRun,
  messages,
  clearConversation,
  conversationId,
  currentAnalyticsEvent,
  workflowInMobyStatus,
  runWorkflowOnInit,
  setValue,
}) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const windowHeight = useSelectByWindowResize(({ height }) => height);
  const isSmall = useIsSmall();

  const currentPage =
    WILLY_NAV_ITEMS.find((item) => {
      return pathname.includes(item.link);
    }) || WILLY_NAV_ITEMS[0];

  return (
    <>
      {asPage ? (
        <WillyPageWrapper
          className="willy-page"
          showResizer={true}
          mainContent={
            <>
              <Allotment
                className="sm:h-dvh sm:max-h-dvh z-0 bg-white"
                vertical
                onDragEnd={(sizes) => {
                  const workflowPanelSize = sizes.at(-1);
                  if (workflowPanelSize === 0) {
                    setWorkflowPanelOpen?.(false);
                  } else if (typeof workflowPanelSize === 'number') {
                    setWorkflowPanelOpen?.(true);
                  }
                }}
              >
                {currentPage.withHeader && (currentPage.id === 'feed' ? !isSmall : true) && (
                  <Allotment.Pane maxSize={MENU_SIZE} minSize={MENU_SIZE}>
                    <div className="willy-dash-header bg-white flex flex-row items-center lg:w-full h-full grow overflow-hidden overflow-x-auto  px-6 py-4 lg:px-8">
                      <div className="flex items-center justify-between flex-grow">
                        <Text size="lg" weight={500}>
                          {currentPage?.label}
                        </Text>
                      </div>
                      <Button
                        onClick={() => {
                          navigate({ pathname: '/workflows/create', search });
                        }}
                        leftSection="plus-1"
                        iconSize={20}
                      >
                        New Workflow
                      </Button>
                    </div>
                  </Allotment.Pane>
                )}
                <Allotment.Pane>
                  {currentPage.isWorkflowPage ? (
                    <SequencesComponent />
                  ) : (
                    <div className="m-auto py-12 h-full">
                      {currentPage.id === 'history' && (
                        <div className="h-full overflow-auto" id="chat-history">
                          <ChatHistory />
                        </div>
                      )}
                      {currentPage.id === 'chat' && children}
                      {currentPage.id === 'prompt_library' && (
                        <WillyTemplateLibrary
                          onSelectPrompt={(prompt) => {
                            genericEventLogger(analyticsEvents.CHAT, {
                              action: chatActions.CHOOSE_PROMPT_LIBRARY_PROMPT,
                              prompt,
                            });
                            const params = new URLSearchParams(search);
                            params.set('prompt', prompt.prompt);
                            navigate({
                              pathname: '/chat',
                              search: params.toString(),
                              hash: '',
                            });
                          }}
                        />
                      )}
                    </div>
                  )}
                </Allotment.Pane>
                {buildMode && <WillyBuilder messages={messages} conversationId={conversationId} />}
                {!!workflowIdToRun && (
                  <Allotment.Pane
                    snap
                    className="transition-[width] duration-300 ease-in-out sm:transition-none h-full"
                    preferredSize={300}
                    maxSize={workflowPanelOpen ? windowHeight / 2 : 10}
                  >
                    <WillyWorkflowPanel
                      workflowIdToRun={workflowIdToRun}
                      workflowInMobyStatus={workflowInMobyStatus}
                      conversationId={conversationId}
                      runOnInit={runWorkflowOnInit}
                      workflowPanelOpen={workflowPanelOpen}
                      setWorkflowPanelOpen={setWorkflowPanelOpen}
                    />
                  </Allotment.Pane>
                )}
              </Allotment>
            </>
          }
          hideSidePanel={currentPage.hideSidePanel || false}
          sidePanelContent={
            currentPage.hideSidePanel ? null : (
              <WillySidePanel
                currentPage={currentPage.id}
                buildMode={buildMode}
                setBuildMode={setBuildMode}
                clearConversation={clearConversation}
                conversationId={conversationId}
                currentAnalyticsEvent={currentAnalyticsEvent}
                runWorkflowIfPossible={runWorkflowIfPossible}
                setRunWorkflowIfPossible={setRunWorkflowIfPossible}
              />
            )
          }
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
