import { services, ServicesIds, ServicesIdsArray } from '@tw/types/module/services';
import {
  ShopIntegrationProperties,
  ShopProviderStatus,
  ShopProviderStatusEnum,
} from '@tw/types/module/types/ShopProviders';
import { ShopWithSensory } from '@tw/types';
import { createSelector } from 'reselect';
import { callUpdateToday } from 'utils/callUpdateToday';
import { receiveFacebookInsights } from './facebook';
import { setOptimisticImporting } from './actions';
import {
  ProviderConnected,
  ProviderProps,
  ProviderStatus,
  Providers,
  ProvidersIntegrations,
} from 'types/services';
import { shopWithSensoryData } from './sensoryShop';
import { isEqual } from 'lodash';
import { combineReducers } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';

const previousValues = {};

export const providersFromShopWithSensory = (ShopWithSensory: ShopWithSensory) => {
  const provider: Providers = ServicesIdsArray.reduce((acc, providerId: string) => {
    const props: ProviderProps = {
      isSensory:
        typeof services[providerId].isSensory === 'function'
          ? services[providerId].isSensory(ShopWithSensory)
          : services[providerId].isSensory || false,
      forceOldFetch: services[providerId].forceOldFetch === true,
      integrations: services[providerId]?.getAccounts(ShopWithSensory),
      isConnected: services[providerId]?.getIsConnected(ShopWithSensory),
      willyTableType: services[providerId]?.willyTableType,
      domain: services[providerId]?.getDomain?.(ShopWithSensory),
      providerStatus: services[providerId]?.getShopProviderStatus
        ? services[providerId]?.getShopProviderStatus(ShopWithSensory)
        : undefined,
      isBeta: services[providerId]?.isBeta || false,
      providerType: services[providerId]?.providerType,
      twVersion: services[providerId]?.twVersion,
      msps: services[providerId]?.msps || [],
    };
    acc[providerId as ServicesIds] = props;

    return acc;
  }, {} as Providers);
  if (!isEqual(previousValues['providers'], provider)) {
    previousValues['providers'] = provider;
  }
  return previousValues['providers'] as Providers;
};

export const providers = createSelector(
  [shopWithSensoryData],
  (shopWithSensoryData: ShopWithSensory) => providersFromShopWithSensory(shopWithSensoryData),
);

export const createShopProvidersStatusFromProviders = (providers: Providers) => {
  return Object.entries(providers).reduce((acc, [serviceId, providerProps]) => {
    acc[serviceId] = providerProps?.providerStatus;
    return acc;
  }, {} as ProviderStatus);
};

export const shopProvidersStatus = createSelector([providers], (providers: Providers) =>
  createShopProvidersStatusFromProviders(providers),
);

export const shopIntegrations = createSelector([providers], (providers: Providers) => {
  return Object.entries(providers).reduce((acc, [serviceId, providerProps]) => {
    acc[serviceId] = providerProps?.integrations.map((integration) => {
      return integration;
    });
    return acc;
  }, {} as ProvidersIntegrations);
});

export const providersConnected = createSelector([providers], (providers: Providers) => {
  return Object.entries(providers).reduce((acc, [serviceId, providerProps]) => {
    acc[serviceId] = providerProps?.isConnected;
    return acc;
  }, {} as ProviderConnected);
});

export const callToIntegrationsUpdateToday = (providerId?: ServicesIds) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    const providerSupported: ServicesIds[] = providerId
      ? [providerId]
      : [
          'google-ads',
          'facebook-ads',
          'tiktok-ads',
          'twitter-ads',
          'enquirelabs',
          'klaviyo',
          'kno',
        ];
    const shopProviderStatus = shopProvidersStatus(getState());
    const integrations = shopIntegrations(getState());
    providerSupported.forEach((providerId) => {
      if (
        integrations &&
        shopProviderStatus[providerId]?.status === 'connected' &&
        integrations[providerId]?.length
      ) {
        const accountIds = integrations[providerId];
        callUpdateToday({
          serviceId: providerId,
          accountIds,
          force: false,
          shopDomain: currentShopId,
        });
      }
      if (providerId === 'facebook-ads') {
        dispatch(receiveFacebookInsights());
      }
      if (providerId === 'enquirelabs' || providerId === 'kno') {
        dispatch(setOptimisticImporting(providerId));
      }
    });
  };
};

export const hasCredentials = (shopProviderStatus?: ShopProviderStatus) => {
  return (
    shopProviderStatus?.status && shopProviderStatus.status !== ShopProviderStatusEnum.disconnected
  );
};

export const oldProviderConnectOnPress = (serviceId: ServicesIds) => async (dispatch, getState) => {
  try {
    dispatch(startConnect(services[serviceId].name));
    const { currentShopId } = getState();
    const response = await axiosInstance.get(
      `v2/${serviceId}/login/get-url?shopId=${currentShopId}`,
    );
    const url = response?.data?.url;
    if (url) {
      const state = getState().integrations;
      if (state.redirectNoticeIsOpen) {
        window.location.replace(url);
      }
    } else {
      dispatch(closeRedirectNotice());
      toast.error(`Error while redirecting to ${services[serviceId].name} authentication page`);
    }
  } catch (e) {
    console.log(`ERROR: `, e);
    dispatch(closeRedirectNotice());
  }
};

export const OPEN_REDIRECT_NOTICE = 'OPEN_REDIRECT_NOTICE';
export const CLOSE_REDIRECT_NOTICE = 'CLOSE_REDIRECT_NOTICE';
export const PROVIDER_NAME_FOR_CONNECT = 'PROVIDER_NAME_FOR_CONNECT';

export const startConnect = (providerName) => (dispatch) => {
  dispatch({ type: OPEN_REDIRECT_NOTICE });
  dispatch({ type: PROVIDER_NAME_FOR_CONNECT, providerName });
};

export const closeRedirectNotice = () => ({
  type: CLOSE_REDIRECT_NOTICE,
});

const redirectNoticeIsOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_REDIRECT_NOTICE:
      return true;
    case CLOSE_REDIRECT_NOTICE:
      return false;
    default:
      return state;
  }
};

const redirectNoticeProviderName = (state = null, action) => {
  switch (action.type) {
    case PROVIDER_NAME_FOR_CONNECT:
      return action.providerName;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  redirectNoticeProviderName,
  redirectNoticeIsOpen,
});
