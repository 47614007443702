import { BaseSummaryMetric } from '@tw/types/module/SummaryMetrics';
import { v4 as uuid } from 'uuid';
import { OPTION_METRICS } from './Constants';
import { ExpressionElement, StatItem, ElementTypes } from './types';
import { metrics as attributionMetric } from '@tw/constants/module/Metrics/allMetrics';
import Mexp from 'math-expression-evaluator';

export const parseExpression = (
  expression: string,
  stats: { [key: string]: any },
  allMetrics: BaseSummaryMetric<any>[],
): [ExpressionElement[], StatItem[]] => {
  const tokens = expression.match(/\d+(\.\d+)?|[\(\)]|[\+\-\*\/]|field_\d+#/g) || [];
  const parsedExpression: ExpressionElement[] = [];
  const statsList: StatItem[] = [];
  for (const token of tokens) {
    if (!isNaN(Number(token))) {
      parsedExpression.push({
        id: uuid(),
        type: ElementTypes.INTEGER,
        value: Number(token),
        title: OPTION_METRICS.find((x) => x.type === ElementTypes.INTEGER)?.title || token,
        isSelected: true,
      });
    } else if (/^[\+\-\*\/]$/.test(token)) {
      parsedExpression.push({
        id: uuid(),
        type: ElementTypes.OPERATOR,
        value: token,
        title:
          OPTION_METRICS.find((x) => x.type === ElementTypes.OPERATOR && x.value == token)?.title ||
          token,
        isSelected: true,
      });
    } else if (/^[\(\)]$/.test(token)) {
      parsedExpression.push({
        id: uuid(),
        type: ElementTypes.PARENTHESES,
        value: token,
        title:
          OPTION_METRICS.find((x) => x.type === ElementTypes.PARENTHESES && x.value == token)
            ?.title || token,
        isSelected: true,
      });
    } else if (/^field_\d+#$/.test(token)) {
      const stat = stats[token];

      let metric: any = allMetrics.find(
        (m) => m.metricId === stat?.value && m.statObjectKey === stat?.statObjectKey,
      );
      if (!metric) {
        metric = Object.values(attributionMetric).find((m) => m.key === stat.value);
        metric = { ...metric, title: metric?.label, metricId: metric?.key };
      }
      if (stat && metric) {
        parsedExpression.push({
          id: uuid(),
          type: stat?.statObjectKey ? ElementTypes.NESTED_STAT : ElementTypes.STAT,
          value: token,
          title: metric?.title,
          isSelected: true,
        });
        statsList.push({ key: token, metric });
      }
    }
  }
  return [parsedExpression, statsList];
};

export const parseDeprecatedCustomMetric = (
  stat: any[],
  allMetrics: BaseSummaryMetric<any>[],
): [ExpressionElement[], StatItem[]] => {
  const parsedExpression: ExpressionElement[] = [];
  const statsList: StatItem[] = [];
  if (stat) {
    const metrics = stat.filter((x) => x.type !== 'operator');
    const [metric1, metric2] = metrics.map((selectedMetric) =>
      allMetrics.find(
        (metric) =>
          metric.metricId === selectedMetric.value &&
          metric.statObjectKey === selectedMetric.statObjectKey,
      ),
    );
    const operator = stat.find((x) => x.type === 'operator');
    const key: number = Math.floor(Math.random() * 100);
    const key1 = `field_${key}#`;
    const key2 = `field_${key + 1}#`;
    statsList.push({ key: key1, metric: metric1 });
    statsList.push({ key: key2, metric: metric2 });
    parsedExpression.push({
      id: uuid(),
      type: metric1?.statObjectKey ? ElementTypes.NESTED_STAT : ElementTypes.STAT,
      value: key1,
      title: metric1?.title || '',
      isSelected: true,
    });
    parsedExpression.push({
      id: uuid(),
      type: ElementTypes.OPERATOR,
      value: operator.value,
      title:
        OPTION_METRICS.find((x) => x.type === ElementTypes.OPERATOR && x.value == operator.value)
          ?.title || operator.value,
      isSelected: true,
    });
    parsedExpression.push({
      id: uuid(),
      type: metric2?.statObjectKey ? ElementTypes.NESTED_STAT : ElementTypes.STAT,
      value: key2,
      title: metric2?.title || '',
      isSelected: true,
    });
  }
  return [parsedExpression, statsList];
};

export const validateExpressionElements = (
  expressionElements,
): [isValid: boolean, error: string] => {
  let isValid = true;
  let error = '';
  const elementGroupSideBySideInvalid = [
    ElementTypes.INTEGER,
    ElementTypes.STAT,
    ElementTypes.NESTED_STAT,
  ];

  const isMissingMetrics =
    !expressionElements ||
    expressionElements.length === 0 ||
    expressionElements.some((x) => !x.value);
  if (isMissingMetrics) {
    isValid = false;
    error += 'Error: Add metric(s) or integer(s) to complete this custom metric\n';
  }

  const isMissingOperator = !expressionElements.some((x) => x.type === ElementTypes.OPERATOR);

  if (isMissingOperator) {
    isValid = false;
    error += 'Error: Add at least one operator to complete this custom metric\n';
  }

  const hasParensElements = expressionElements.some((x) => x.type === ElementTypes.PARENTHESES);

  if (hasParensElements) {
    const filteredItems = expressionElements.filter((x) => x.type === ElementTypes.PARENTHESES);
    if (filteredItems && filteredItems.length > 0 && filteredItems.length % 2 !== 0) {
      isValid = false;
      error += 'Error: Open or close parentheses to complete this custom metric\n';
    }
  }

  const hasOperators = expressionElements.some((x) => x.type === ElementTypes.OPERATOR);
  if (hasOperators) {
    for (let i = 0; i < expressionElements.length; i++) {
      const element = expressionElements[i];
      if (element.type === ElementTypes.OPERATOR) {
        const nextElement = expressionElements[i + 1];
        if (nextElement && nextElement.type === ElementTypes.OPERATOR) {
          isValid = false;
          error +=
            'Error: Add metric or integer between two operators to complete this custom metric\n';
          break; //we found a problem, no need to continue
        }
      }
    }
  }

  const hasMetrics = expressionElements.some(
    (x) => x.type === ElementTypes.STAT || x.type === ElementTypes.NESTED_STAT,
  );
  if (hasMetrics) {
    for (let i = 0; i < expressionElements.length; i++) {
      const element = expressionElements[i];
      if (elementGroupSideBySideInvalid.includes(element.type)) {
        const nextElement = expressionElements[i + 1];
        if (nextElement && elementGroupSideBySideInvalid.includes(nextElement.type)) {
          isValid = false;
          error +=
            'Error: Add operator between two metrics/integers to complete this custom metric\n';
          break; //we found a problem, no need to continue
        }
      }
    }
  }

  let expression = getExpressionByElements(expressionElements);
  if (!isValidExpression(expression)) {
    isValid = false;
    error += 'Error: The expression is not valid\n';
  }

  return [isValid, error];
};

export const getExpressionByElements = (expressionElements: ExpressionElement[]): string => {
  let expression = '';
  expressionElements.forEach((item) => {
    expression += item.value;
  });
  return expression;
};

export const isValidExpression = (expression: string): boolean => {
  const tokens = expression.match(/field_\d+#/g) || [];

  for (const token of tokens) {
    if (/^field_\d+#$/.test(token)) {
      expression = expression.replace(token, ' 1 ');
    }
  }

  try {
    const mexp = new Mexp();
    mexp.eval(expression, [], {});
    return true;
  } catch (e) {
    return false;
  }
};

export const getStatsByExpressionElements = (
  expressionElements: ExpressionElement[],
  stats: StatItem[],
): { [key: string]: any } => {
  const statList: { [key: string]: any } = {};
  expressionElements.forEach((item) => {
    if (item.type === ElementTypes.STAT || item.type === ElementTypes.NESTED_STAT) {
      const stat = stats.find((s) => s.key === item.value);
      if (stat) {
        const { key, metric } = stat;
        statList[key] = {
          value: metric.metricId || metric.key,
          type: item.type,
        };
        if (item.type === ElementTypes.NESTED_STAT) {
          statList[key].statObjectKey = metric.statObjectKey;
          if (metric.specificStat) {
            statList[key].specificStat = metric.specificStat;
          }
        }

        if (metric.chart) {
          statList[key].chart = metric.chart;
        }
      }
    }
  });
  return statList;
};
