import { useAppDispatch } from 'index';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { v4 as uuidv4 } from 'uuid';

import { Button, Form, FormLayout, Modal, TextContainer, TextField } from '@shopify/polaris';

import {
  enquirelabsConfigSavePress,
  toggleEnquirelabsConfigScreenOpen,
} from '../ducks/enquirelabs';
import { CONNECTING_TO_FAIRING } from 'constants/knowledgeBaseLinks';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../$stores/willy/$subscription';

const EnquirelabsConfig = () => {
  const isOpen = useSelector((state: RootState) => state.enquirelabsConnectConfigIsOpen);
  const shopId = useSelector((state: RootState) => state.currentShopId);
  const isFreeShop = useStoreValue($isFreeShop);
  const dispatch = useAppDispatch();
  const toggleIsOpen = () => dispatch(toggleEnquirelabsConfigScreenOpen());
  const save = (settings, saveErrorFunction) =>
    dispatch(enquirelabsConfigSavePress(settings, saveErrorFunction));
  const [saveError, setSaveError] = useState();
  //adapt keys accordingly
  var [enquirelabsKey, setEnquirelabsKey] = useState('');
  var [apiKey, setApiKey] = useState('');
  const handleChangeApiKey = useCallback(setApiKey, [setApiKey]);
  const isEmpty = (val) => !val || !val.length;

  const handleSave = async () => {
    setApiKey(apiKey.trim());
    save({ enquirelabsKey, apiKey }, setSaveError);
  };

  useEffect(() => {
    setApiKey('');
    setSaveError(undefined);
  }, [shopId, isOpen]);

  function generateToken() {
    const getToken = uuidv4();
    setEnquirelabsKey(getToken);
  }

  return (
    <Modal
      title={'Fairing Configuration'}
      open={isOpen}
      onClose={toggleIsOpen}
      primaryAction={{
        content: 'Save',
        onAction: () => handleSave(),
        disabled: !enquirelabsKey || isEmpty(apiKey),
      }}
    >
      <Modal.Section>
        {saveError && (
          <TextContainer>
            <p style={{ color: 'red' }}> {saveError}</p>
          </TextContainer>
        )}
        <div>
          <p>
            <ol className="p-0 px-8 m-0 mb-24">
              <li>Click the "Generate Token" button.</li>
              <li>
                Copy the "Fairing Token" generated and paste it into the "access token" field in the
                settings of the Triple Whale integration in your Fairing account.
              </li>
              <li>
                Navigate to the Account Page in your Fairing app, unhide the Secret Token found in
                the API Credentials section and paste it into the API Key field here in Triple
                Whale.
              </li>
              <li>Click Save.</li>
            </ol>
          </p>
        </div>
        <Form onSubmit={() => setApiKey('')}>
          <FormLayout>
            <p>
              Fairing Token : {enquirelabsKey}{' '}
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(enquirelabsKey);
                }}
              >
                Copy to clipboard
              </Button>
            </p>
            <FormLayout.Group condensed>
              <Button onClick={generateToken}>Generate Token</Button>
            </FormLayout.Group>
            <TextContainer>
              <TextField
                label="Copy+Paste your API Key here:"
                value={apiKey}
                onChange={handleChangeApiKey}
                placeholder="Something like: micAz0BKzFTEHDmF3D5VScGxdP3yy4bo"
                autoComplete="off"
              />
            </TextContainer>
          </FormLayout>
        </Form>
        {!isFreeShop && (
          <div>
            <br />
            Need help?{' '}
            <Button
              plain
              monochrome
              onClick={() => window.open(CONNECTING_TO_FAIRING, '__blank', 'noopener,noreferrer')}
            >
              Click here for step by step directions.
            </Button>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default EnquirelabsConfig;
