import { Stack } from '@shopify/polaris';
import { CDPSegmentFilter } from '@tw/types/module/services/cdp';
import { CDPSegmentRelationFilter } from '@tw/types/module/services/cdp/queryBuilder/SegmentRelationFilter';
import { FilterComparator } from '@tw/types/module/services/insights';
import DropDown from 'components/ltv/DropDown';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

type Props = {
  filter: CDPSegmentRelationFilter;
  onChanged: (filter: CDPSegmentFilter) => void;
};

export const SegmentRelationFilter: React.FC<Props> = (props) => {
  const { segmentsList } = useSelector((state: RootState) => state.CDPSegments);

  const [filter, setFilter] = useState<CDPSegmentRelationFilter>(props.filter);

  useEffect(() => {
    props.onChanged(filter);
  }, [filter]);

  const handleComparatorChanged = useCallback((comparator) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        definition: {
          ...prevValue.definition,
          property: { ...prevValue.definition.property, comparator },
        },
      };
    });
  }, []);

  const handleValueChanged = useCallback((value) => {
    setFilter((prevValue) => {
      return {
        ...prevValue,
        definition: {
          ...prevValue.definition,
          property: { ...prevValue.definition.property, value },
        },
      };
    });
  }, []);

  return (
    <React.Fragment>
      <DropDown
        id="segment-relation-comparator"
        options={[
          { label: 'is', value: FilterComparator.IS },
          { label: 'is not', value: FilterComparator.IS_NOT },
        ]}
        value={filter.definition.property.comparator}
        handleSelect={handleComparatorChanged}
      />

      <Stack alignment="center">
        <DropDown
          id="att-cdp-segments-dropdown"
          options={segmentsList.map((s) => ({ label: s.name, value: s.id }))}
          value={filter.definition.property.value}
          handleSelect={handleValueChanged}
        />
      </Stack>
    </React.Fragment>
  );
};
