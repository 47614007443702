import { ServicesIds } from '@tw/types/module/services';
import { services } from '@tw/types/module/services';
import { Modal, MultiSelect, Text } from '@tw/ui-components';
import { Flex } from '@tw/ui-components';
import { capitalize } from 'lodash';
import { NlqResponse, WidgetPermissions } from '../types/willyTypes';
import { calculateDataSize } from '../utils/willyUtils';
import { useMemo } from 'react';

function formatThousands(value: number | null) {
  return value ? value.toLocaleString() : 'N/A';
}

type WidgetMetaDataProps = {
  open: boolean;
  onClose: () => void;
  permission: WidgetPermissions;
  permissionChanged: (providers: ServicesIds[]) => void;
  data: NlqResponse;
};

export const WidgetMetaData: React.FC<WidgetMetaDataProps> = ({
  open,
  onClose,
  permission,
  permissionChanged,
  data,
}) => {
  const dataSize = useMemo(() => {
    const sizes = calculateDataSize(data);
    return {
      tokens: formatThousands(sizes.tokens),
      bytes: formatThousands(sizes.bytes),
    };
  }, [data]);

  return (
    <div>
      <Modal
        title="Widget settings"
        opened={open}
        onClose={() => {
          onClose();
        }}
      >
        <Flex direction="column" gap="md">
          <Flex gap={'xs'} align={'center'}>
            <Text fz={14} color={'gray.6'}>
              Providers:
            </Text>
            <MultiSelect
              searchable={true}
              size={'xs'}
              data={Object.values(services)
                .filter((x) => x.providerType === 'data')
                .map((x) => ({ label: capitalize(x.name), value: x.id }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              placeholder={permission.providers.length > 0 ? '' : 'Select...'}
              value={permission.providers}
              onChange={(value) => {
                permissionChanged(value as ServicesIds[]);
              }}
            />
          </Flex>

          <Flex gap={0} direction="column">
            <Text fz={14} color={'gray.6'} fw={500}>
              Data size:
            </Text>
            <Text fz={14} color={'gray.6'}>{`${dataSize.tokens} tokens`}</Text>
            <Text fz={14} color={'gray.6'}>{`${dataSize.bytes} bytes`}</Text>
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
};
