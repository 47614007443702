import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { callInitialImport } from 'utils/callInitialImport';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import { INIT_SHOP } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import axiosInstance from 'utils/axiosInstance';
import { setServiceAccountInfo } from '../utils/setServiceAccountInfo';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;

export const SNAPCHAT_FETCHING_ORGANIZATIONS = 'SNAPCHAT_FETCHING_ORGANIZATIONS';
export const SNAPCHAT_RECEIVED_ORGANIZATIONS = 'SNAPCHAT_RECEIVED_ORGANIZATIONS';

const SNAPCHAT_DISCONNECT_STARTED = 'SNAPCHAT_DISCONNECT_STARTED';

export const SNAPCHAT_ORGANIZATIONS_IDS_ON_CHANGE = 'SNAPCHAT_ORGANIZATIONS_IDS_ON_CHANGE';
export const snapchatOrganizationsSelectOnChange = (value) => ({
  type: SNAPCHAT_ORGANIZATIONS_IDS_ON_CHANGE,
  value,
});

export const SNAPCHAT_ACCOUNTS_IDS_ON_CHANGE = 'SNAPCHAT_ACCOUNTS_IDS_ON_CHANGE';
export const snapchatAccountsSelectOnChange = (value) => ({
  type: SNAPCHAT_ACCOUNTS_IDS_ON_CHANGE,
  value,
});

export const TOGGLE_SNAPCHAT_ORGANIZATIONS_CONFIG_SCREEN_OPEN =
  'TOGGLE_SNAPCHAT_ORGANIZATIONS_CONFIG_SCREEN_OPEN';
export const toggleSnapchatOrganizationsConfigScreenOpen = () => ({
  type: TOGGLE_SNAPCHAT_ORGANIZATIONS_CONFIG_SCREEN_OPEN,
});

export function snapchatOrganizationsConfigSavePress() {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    dispatch({ type: TOGGLE_SNAPCHAT_ORGANIZATIONS_CONFIG_SCREEN_OPEN });
    var { snapchatRemoteAccounts, currentShopId } = getState();
    await setServiceAccountInfo(currentShopId, 'snapchat-ads', {
      snapchatAccounts: snapchatRemoteAccounts.filter((acc) => acc.selected),
    });
    dispatch(setOptimisticImporting('snapchat-ads'));
    dispatch(snapchatOrganizationsSaveSuccess());
    window.scrollTo(0, 0);
  };
}

export function startImportingSnapchatData() {
  return (dispatch, getState) => {
    callInitialImport('snapchat-ads');
    dispatch(setOptimisticImporting('snapchat-ads'));
    window.scrollTo(0, 0);
  };
}

export const SNAPCHAT_ORGANIZATIONS_CONFIG_SAVE_SUCCESS =
  'SNAPCHAT_ORGANIZATIONS_CONFIG_SAVE_SUCCESS';
export const snapchatOrganizationsSaveSuccess = () => {
  //return dispatch => {
  return {
    type: SNAPCHAT_ORGANIZATIONS_CONFIG_SAVE_SUCCESS,
  };
  //}
};

export const snapchatConnectOnPress = () => {
  try {
    const url = `v2/snapchat-ads/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(` ERROR `, e);
  }
};

export function snapchatDisconnect() {
  return (dispatch) => {
    dispatch({
      type: SNAPCHAT_DISCONNECT_STARTED,
    });
    toggleSaveIndicator();
    const url = `v2/snapchat-ads/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('snapchatAccessToken'));
        dispatch(snapchatDisconnectDone());
      });
  };
}

export const SNAPCHAT_DISCONNECT_DONE = 'SNAPCHAT_DISCONNECT_DONE';
export function snapchatDisconnectDone() {
  return {
    type: SNAPCHAT_DISCONNECT_DONE,
  };
}

const snapchatRemoteOrganizations = (state = [], action) => {
  switch (action.type) {
    case SNAPCHAT_RECEIVED_ORGANIZATIONS:
      return action.organizations || state;
    case SNAPCHAT_ORGANIZATIONS_IDS_ON_CHANGE:
      return action.value || state;
    default:
      return state;
  }
};

const snapchatRemoteAccounts = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.snapchatAccounts || state;
    case SNAPCHAT_RECEIVED_ORGANIZATIONS:
      return (
        (action.organizations || [])?.reduce(
          (t, org) => (org.ad_accounts ? t.concat(org.ad_accounts) : t),
          [],
        ) || state
      );
    case SNAPCHAT_ACCOUNTS_IDS_ON_CHANGE:
      return action.value || state;
    default:
      return state;
  }
};

const isSnapchatConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.snapchatAccessToken;
    case SNAPCHAT_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};

const isSnapchatIntegrationBtnLoading = (state = false, action) => {
  switch (action.type) {
    case SNAPCHAT_DISCONNECT_STARTED:
      return true;
    case SNAPCHAT_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};

const snapchatConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case SNAPCHAT_RECEIVED_ORGANIZATIONS:
      return true;
    case TOGGLE_SNAPCHAT_ORGANIZATIONS_CONFIG_SCREEN_OPEN:
      return !state;
    case SNAPCHAT_ORGANIZATIONS_CONFIG_SAVE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const snapchatAccountSearch = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const reducers = {
  snapchatRemoteOrganizations,
  isSnapchatConnected,
  snapchatConfigScreenIsOpen,
  snapchatRemoteAccounts,
  snapchatAccountSearch,
  isSnapchatIntegrationBtnLoading,
};
