import { ActionList, Button, Checkbox, Icon, Popover, TextField, Tooltip } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import { SearchMinor } from '@shopify/polaris-icons';
import SERVICES from 'constants/services';
import { ServicesIds, ServicesIdsArray } from '@tw/types/module/services';
import { MetricsKeys } from '@tw/types/module/metrics/metrics';
import { PixelChannel, SelectedMetric } from './types';
import { CHART_COLORS } from 'constants/general';
import { pixelMetrics } from '@tw/constants/module/Metrics/pixelMetrics';
import { useSelector } from 'react-redux';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import { RootState } from 'reducers/RootType';

const PIXEL_METRICS_TO_SHOW = ['pixelRoas', 'pixelNcRoas'];

export const HIDDEN_SERVICES_IN_ACTIVITY_FEED: (ServicesIds | 'finance')[] = [
  'influencers',
  'kno',
  'enquirelabs',
  'finance',
];

type MetricsListProps = {
  isMetricSelected: (metric: MetricsKeys, serviceId: ServicesIds) => boolean;
  isPixelMetricSelected: (metric: MetricsKeys, channel: any) => boolean;
  metricCheckboxChangeHandler: (
    checked: boolean,
    metric: MetricsKeys,
    service: ServicesIds,
  ) => void;
  pixelMetricCheckboxChangeHandler: (checked: boolean, metric: MetricsKeys, channel: any) => void;
  selectedMetrics: SelectedMetric[];
  pixelChannels: PixelChannel[];
  loadingPixelStats: boolean;
};

export const MetricsList: React.FC<MetricsListProps> = ({
  isMetricSelected,
  isPixelMetricSelected,
  metricCheckboxChangeHandler,
  pixelMetricCheckboxChangeHandler,
  selectedMetrics,
  pixelChannels,
  loadingPixelStats,
}) => {
  const { hasPpsData } = useSelector((state: RootState) => state.ppsStatus);
  const hasPixelInActivityFeed = useSelector((state: RootState) => state.hasPixelActivityFeed);
  const [searchMetricValue, setSearchMetricValue] = useState<string>('');
  const [servicesPopoverActive, setServicesPopoverActive] = useState(false);
  const [pixelChannelPopoverActive, setPixelChannelPopoverActive] = useState(false);
  const [activePixelChannel, setActivePixelChannel] = useState<
    PixelChannel | undefined | { id: 'nonVerified'; name: 'Other Sources' }
  >();
  const [activeService, setActiveService] = useState<ServicesIds | undefined>('facebook-ads');

  const hiddenServices = useMemo(() => {
    if (hasPpsData && hasPixelInActivityFeed) {
      return HIDDEN_SERVICES_IN_ACTIVITY_FEED;
    } else {
      return [...HIDDEN_SERVICES_IN_ACTIVITY_FEED, 'pixel'];
    }
  }, [hasPpsData, hasPixelInActivityFeed]);

  const allMetrics = useMemo(() => {
    return Object.values(metrics)
      .filter((metric) => !metric.hideInActivities)
      .map((metric) => {
        return metric.showInServices
          ?.filter((service) => !hiddenServices.includes(service))
          .map((service) => {
            return {
              ...metric,
              service: service,
              customLabel: `${SERVICES[service]?.title ?? SERVICES[service]?.name} ${
                metric.shortLabel
              }`,
            };
          });
      })
      .flat()
      .filter((x) => x);
  }, [hiddenServices]);

  const servicesActionList = useMemo(() => {
    return ServicesIdsArray.filter((service) => !hiddenServices.includes(service))
      .map((service) => {
        return {
          content: SERVICES[service]?.name,
          icon: SERVICES[service]?.icon,
          onAction: () => {
            setActiveService(service);
            setServicesPopoverActive(false);
          },
        };
      })
      .filter((service) => service.content)
      .concat({
        content: 'All',
        icon: () => <>--</>,
        onAction: () => {
          setActiveService(undefined);
          setServicesPopoverActive(false);
        },
      });
  }, [hiddenServices]);

  const verifiedPixelChannelIds = useMemo(() => {
    return [...Object.keys(SERVICES), ...['Direct', 'Non-attributed']];
  }, []);

  const verifiedPixelChannels = useMemo(() => {
    return pixelChannels?.filter((channel) => verifiedPixelChannelIds.includes(channel.id));
  }, [pixelChannels, verifiedPixelChannelIds]);

  const nonVerifiedPixelChannels = useMemo(() => {
    return pixelChannels?.filter((channel) => !verifiedPixelChannelIds.includes(channel.id));
  }, [pixelChannels, verifiedPixelChannelIds]);

  const pixelChannelActionList = useMemo(() => {
    return verifiedPixelChannels
      ?.map((channel) => {
        return {
          content: channel.name,
          icon: SERVICES[channel.id]?.icon,
          onAction: () => {
            setActivePixelChannel(channel);
            setPixelChannelPopoverActive(false);
          },
        };
      })
      .filter((service) => service.content)
      .concat(
        [
          {
            icon: () => {},
            content: 'Other Sources',
            onAction: () => {
              setActivePixelChannel({ id: 'nonVerified', name: 'Other Sources' });
              setPixelChannelPopoverActive(false);
            },
          },
        ],
        [
          {
            icon: () => <>--</>,
            content: 'All',
            onAction: () => {
              setActivePixelChannel(undefined);
              setPixelChannelPopoverActive(false);
            },
          },
        ],
      );
  }, [verifiedPixelChannels]);

  const onSearchMetricValueChange = useCallback(
    (text) => {
      if (activeService) setActiveService(undefined);
      if (activePixelChannel) setActivePixelChannel(undefined);
      setSearchMetricValue(text);
    },
    [activeService, activePixelChannel],
  );

  const filterMetrics = useCallback(
    (metric) => {
      if (!metric) return false;
      if (searchMetricValue === '') return activeService ? metric.service === activeService : true;
      const lowerSearchValue = searchMetricValue.toLowerCase();
      return metric.customLabel.toLowerCase().includes(lowerSearchValue);
    },
    [activeService, searchMetricValue],
  );

  const pixelAllMetrics = useMemo(() => {
    let filteredChannels;
    if (activePixelChannel) {
      if (activePixelChannel.id === 'nonVerified') {
        filteredChannels = nonVerifiedPixelChannels;
      } else {
        filteredChannels = pixelChannels?.filter((channel) => channel.id === activePixelChannel.id);
      }
      return Object.values(pixelMetrics)
        .filter((metric) => {
          return PIXEL_METRICS_TO_SHOW.includes(metric.key);
        })
        .map((metric) => {
          return filteredChannels?.map((channel) => {
            const serviceName = channel.name;
            return {
              ...metric,
              service: 'pixel',
              channel: channel.id,
              customLabel: `Pixel ${serviceName} ${metric.shortLabel}`,
            };
          });
        })
        .flat()
        .filter((x) => x);
    } else {
      return Object.values(pixelMetrics)
        .map((metric) => {
          return pixelChannels?.map((channel) => {
            const serviceName = channel.name;
            return {
              ...metric,
              service: 'pixel',
              channel: channel.id,
              customLabel: `Pixel ${serviceName} ${metric.shortLabel}`,
            };
          });
        })
        .flat()
        .filter((x) => x);
    }
  }, [pixelChannels, activePixelChannel, nonVerifiedPixelChannels]);

  const allAllPMetrics: any = useMemo(() => {
    return [...allMetrics, ...pixelAllMetrics];
  }, [allMetrics, pixelAllMetrics]);

  const serviceListActivator = (
    <Button
      disclosure={servicesPopoverActive ? 'up' : 'down'}
      onClick={() => setServicesPopoverActive((a) => !a)}
      disabled={searchMetricValue !== ''}
    >
      {activeService ? SERVICES[activeService]?.name : '--'}
    </Button>
  );

  const pixelChannelListActivator = (
    <Button
      disclosure={pixelChannelPopoverActive ? 'up' : 'down'}
      onClick={() => setPixelChannelPopoverActive((a) => !a)}
    >
      {activePixelChannel ? activePixelChannel?.name || activePixelChannel?.id : 'All'}
    </Button>
  );

  const disabledMetricCheckbox = useCallback(
    (metric) =>
      selectedMetrics.length >= CHART_COLORS.length &&
      !isMetricSelected(metric.key, metric.service),
    [isMetricSelected, selectedMetrics.length],
  );

  const metricCheckbox = (metric) => (
    <Checkbox
      label=""
      labelHidden
      // disabled={disabledMetricCheckbox(metric)}
      checked={isMetricSelected(metric.key, metric.service)}
      onChange={(checked) => metricCheckboxChangeHandler(checked, metric.key, metric.service)}
    />
  );

  const pixelMetricCheckbox = (metric) => (
    <Checkbox
      label=""
      labelHidden
      // disabled={disabledMetricCheckbox(metric)}
      checked={isPixelMetricSelected(metric.key, metric.channel)}
      onChange={(checked) => pixelMetricCheckboxChangeHandler(checked, metric.key, metric.channel)}
    />
  );

  return (
    <div className="border-1 box-content border-solid rounded border-white shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 gap-6.5 flex flex-col h-[300px] overflow-auto">
      <div className="sm:flex justify-between">
        <div>
          <div className="font-medium mb-2">Metric Library</div>
          <div className="text-[#576B82] text-xl">Select Metrics to plot on the graph above</div>
        </div>
        <div className="flex gap-4 flex-col sm:flex-row sm:items-center z-[1]">
          <TextField
            label=""
            placeholder="Search metrics"
            value={searchMetricValue}
            onChange={onSearchMetricValueChange}
            prefix={<Icon source={SearchMinor} color="base" />}
            inputMode="search"
            autoComplete="off"
            clearButton
            onClearButtonClick={() => onSearchMetricValueChange('')}
          />
          <Popover
            active={servicesPopoverActive}
            fixed
            hideOnPrint
            fluidContent
            activator={serviceListActivator}
            onClose={() => setServicesPopoverActive(false)}
          >
            <ActionList items={servicesActionList} />
          </Popover>
        </div>
      </div>
      <div
        className="grid gap-6.5"
        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))' }}
      >
        <>
          {activeService === 'pixel' && (
            <div style={{ gridColumn: '1/5' }}>
              <Popover
                active={pixelChannelPopoverActive}
                fixed
                hideOnPrint
                fluidContent
                activator={pixelChannelListActivator}
                onClose={() => setPixelChannelPopoverActive(false)}
              >
                <ActionList items={pixelChannelActionList || []} />
              </Popover>
            </div>
          )}
          {allAllPMetrics.filter(filterMetrics).map((metric) => {
            if (!metric) return;
            if (metric.service === 'pixel')
              return (
                <div
                  key={`${metric.key}_${metric?.channel}}`}
                  className="flex items-center gap-2 outline outline-1 outline-gray-300 rounded-md p-4"
                  style={
                    isPixelMetricSelected(metric.key, metric?.channel)
                      ? {
                          backgroundColor: 'rgba(24, 119, 242, 0.1)',
                          outlineColor: 'rgba(24, 119, 242, 0.3)',
                        }
                      : undefined
                  }
                >
                  {pixelMetricCheckbox(metric)}
                  {metric.customLabel}
                </div>
              );
            else
              return (
                <div
                  key={`${metric.key}-${metric.service}`}
                  className="flex items-center gap-2 outline outline-1 outline-gray-300 rounded-md p-4"
                  style={
                    isMetricSelected(metric.key, metric.service)
                      ? {
                          backgroundColor: 'rgba(24, 119, 242, 0.1)',
                          outlineColor: 'rgba(24, 119, 242, 0.3)',
                        }
                      : undefined
                  }
                >
                  {metricCheckbox(metric)}
                  {metric.customLabel}
                </div>
              );
          })}
        </>
        {/* )} */}
      </div>
    </div>
  );
};
