import './TWImage.scss';

import { PLACEHOLDER_IMAGE_URL } from 'constants/creativeCockpit';
import React, { useEffect, useState } from 'react';

import { SkeletonThumbnail } from '@shopify/polaris';

import { genericEventLogger, analyticsEvents, generalActions } from 'utils/dataLayer';

type TWImageProps = React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    wrapperClass?: React.HTMLAttributes<HTMLDivElement>['className'];
    icon?: JSX.Element | null;
    customImgStyle?: object;
    caption?: JSX.Element;
    onLoadingChange?: (loading: boolean) => void;
  }
>;

const TWImage: TWImageProps = (props) => {
  const {
    alt,
    wrapperClass = '',
    icon: Icon = null,
    onClick,
    customImgStyle,
    caption,
    onLoadingChange,
    ...rest
  } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onLoadingChange?.(loading);
  }, [loading, onLoadingChange]);

  return (
    <figure className={`tw-image relative m-0 ${wrapperClass}`} onClick={onClick}>
      {loading && (
        <div className="flex w-full h-full items-center justify-center">
          <SkeletonThumbnail size="large" />
        </div>
      )}
      {
        <>
          <img
            draggable="false"
            {...rest}
            alt={alt}
            style={{
              opacity: loading ? '0' : '1',
              transition: 'opacity 0.3s ease',
              position: loading ? 'absolute' : 'static',
              ...customImgStyle,
            }}
            onError={({ currentTarget }) => {
              setLoading(false);
              if (currentTarget.src && currentTarget.src != PLACEHOLDER_IMAGE_URL) {
                genericEventLogger(analyticsEvents.GENERAL, {
                  action: generalActions.IMAGE_ERROR,
                  src: currentTarget.src,
                });
              }

              currentTarget.onerror = null;
              currentTarget.src = PLACEHOLDER_IMAGE_URL;
            }}
            onLoad={() => {
              setLoading(false);
            }}
          />
          <figcaption>{caption}</figcaption>
        </>
      }
      {Icon && (
        <div className="flex items-center justify-center absolute inset-1/2 -translate-x-1/2 -translate-y-1/2">
          {Icon}
        </div>
      )}
    </figure>
  );
};

export default TWImage;
