import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, OptionList, TextStyle } from '@shopify/polaris';
import {
  twitterAccountsSelectOnChange,
  toggleTwitterAccountsConfigScreenOpen,
  twitterConnectOnPress,
  twitterAccountsConfigSavePress,
} from 'ducks/twitter';
import { RootState } from 'reducers/RootType';
import { useMemo } from 'react';

export const TwitterAccountsConfig: React.FC<any> = () => {
  const twitterConfigScreenIsOpen = useSelector(
    (state: RootState) => state.twitterConfigScreenIsOpen,
  );
  const __twitterRemoteAccounts = useSelector((state: RootState) => state.twitterRemoteAccounts);

  const twitterRemoteAccounts = useMemo(() => {
    return Object.entries(__twitterRemoteAccounts || {}).map((entry) => entry[1] as any);
  }, [__twitterRemoteAccounts]);

  const twitterAccountSearch = useSelector((state: RootState) => state.twitterAccountSearch);

  const dispatch = useDispatch();

  return (
    <Modal
      title={twitterRemoteAccounts?.length ? 'Select twitter Ad Account' : 'Connect to twitter'}
      open={twitterConfigScreenIsOpen}
      onClose={() => dispatch(toggleTwitterAccountsConfigScreenOpen())}
      primaryAction={{
        content: 'Save',
        onAction: () => dispatch(twitterAccountsConfigSavePress() as any),
        disabled: !twitterRemoteAccounts?.filter?.((acc) => acc.selected)?.length,
      }}
      sectioned
    >
      {twitterRemoteAccounts?.length > 0 && (
        <OptionList
          onChange={(selected) => {
            var accounts = twitterRemoteAccounts.slice();
            accounts.forEach((acc) => (acc.selected = selected.includes(acc.id)));
            dispatch(twitterAccountsSelectOnChange(accounts));
          }}
          options={(twitterAccountSearch.length && twitterRemoteAccounts
            ? twitterRemoteAccounts.filter(
                (a) =>
                  a.name.toLowerCase().indexOf(twitterAccountSearch.toLowerCase()) >= 0 ||
                  String(a.id).indexOf(twitterAccountSearch) >= 0,
              )
            : twitterRemoteAccounts
          ).map((account) => {
            return {
              value: account.id,
              label: account.name,
            };
          })}
          selected={twitterRemoteAccounts.filter((acc) => acc.selected).map((acc) => acc.id)}
          allowMultiple
        />
      )}
      {!twitterRemoteAccounts?.length && (
        <>
          <Modal.Section>
            <TextStyle variation="strong">
              No ads accounts found for this twitter account, you can try to connect to different
              account
            </TextStyle>
          </Modal.Section>
          <Modal.Section>
            <Button primary onClick={() => twitterConnectOnPress()}>
              Connect to twitter
            </Button>
          </Modal.Section>
        </>
      )}
    </Modal>
  );
};
