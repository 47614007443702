export const ItemTypes = {
  CARD: 'card',
  SELECTED: 'selected',
  AVAILABLE: 'available',
  OPTION: 'OPTION',
};
export enum ElementTypes {
  OPERATOR = 'operator',
  INTEGER = 'integer',
  STAT = 'stat',
  NESTED_STAT = 'nestedStat',
  PARENTHESES = 'parentheses',
}

export type ExpressionElement = {
  id: string;
  title: string;
  value?: string | number;
  type: ElementTypes;
  isSelected?: boolean;
};

export type StatItem = {
  key: string;
  metric: any;
};

export type CustomMetric = {
  title?: string;
  description?: string;
  stats?: { [key: string]: any };
  metricType: string;
  section?: string;
  id?: string;
  expression?: string;
  metricCollection?: string;
  source?: string;
  tag?: string;
};

export type ExpressionElementsMap = { [key: number]: ExpressionElement[] };
