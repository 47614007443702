import { $user } from '$stores/$user';
import { useWritableStore } from '@tw/snipestate';
import { Button, Flex } from '@tw/ui-components';
import { USER_REMOVE_ADMIN } from 'ducks/constants';
import { useAppDispatch } from 'index';

export const ControlAdminButton = () => {
  const [user, setUser] = useWritableStore($user);
  const dispatch = useAppDispatch();
  return (
    <Flex align="center" justify="center">
      <Button
        variant="activator"
        size="xs"
        onClick={() => {
          setUser({
            ...user,
            isAdmin: false,
            isAdminClaim: false,
            isTwDevClaim: false,
            isTwSuperAdminClaim: false,
            isTwGlobalDashboardCreatorClaim: false,
          });
          dispatch({ type: USER_REMOVE_ADMIN });
        }}
      >
        TW
      </Button>
    </Flex>
  );
};
