import { RootState, useAppSelector } from 'reducers/RootType';
import { useSelector } from 'react-redux';
import { Provider } from '@tw/types/module/services/account-manager';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  LoadingOverlay,
  Select,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { saveIntegrationSettings } from 'ducks/sensory';
import React, { useEffect, useState } from 'react';
import { ProviderSetting } from '@tw/types/module/sensory';

export const IntegrationsSettings = () => {
  const providersIntegrations = useAppSelector((state) => state.sensory.providerAccounts) as any[];
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData as Provider,
  );
  return (
    <Box key="manage-settings">
      {(() => {
        const filteredIntegrations = providersIntegrations.filter(
          (int) => int.provider_id == sensoryProviderConfigData.id && int.integration_id,
        );

        if (filteredIntegrations.length === 0) {
          return (
            <Box>
              <Text>You can't edit settings without a connected account.</Text>
              <Text>Please connect an account and try again.</Text>
            </Box>
          );
        }

        return filteredIntegrations.map((integration) => {
          return sensoryProviderConfigData?.settings ? (
            <Box key={integration.id}>
              <IntegrationIndividualSettings
                integration={integration}
                configData={sensoryProviderConfigData}
              ></IntegrationIndividualSettings>
            </Box>
          ) : (
            <Text>Settings are not available for this provider</Text>
          );
        });
      })()}
    </Box>
  );
};

export const IntegrationIndividualSettings: React.FC<{
  integration: any;
  configData: any;
  showHeader?: boolean;
  onCurrentChanged?: (val) => void;
}> = ({ integration, configData, showHeader = true, onCurrentChanged }) => {
  const dispatch = useAppDispatch();
  const [dynamicSettingsOptions, setDynamicSettingsOptions] = useState({});
  const [dynamicLists, setDynamicLists] = useState({});
  const [currentSettings, setCurrentSettings] = useState<any[]>([]);

  const sensoryProviderSettingLoading = useAppSelector(
    (state) => state.sensory.sensoryProviderSettingLoading,
  );
  const sensoryProviderDynamicSettings = useAppSelector(
    (state) => state.sensory.sensoryProviderDynamicSettings,
  );

  useEffect(() => {
    if (
      sensoryProviderDynamicSettings &&
      sensoryProviderDynamicSettings.providerId == integration.provider_id
    ) {
      setDynamicSettingsOptions(
        sensoryProviderDynamicSettings?.dynamicData?.dynamicFieldValues?.fields,
      );
      setDynamicLists(sensoryProviderDynamicSettings?.dynamicData?.dynamicLists);
    }
  }, [integration.provider_id, sensoryProviderDynamicSettings]);

  useEffect(() => {
    const updateDynamicLists = (dynamicListsSetting, configDynamicLists) => {
      Object.keys(dynamicListsSetting.dynamicLists).forEach((key) => {
        const existingValues = dynamicListsSetting.dynamicLists[key] ?? [];
        const defaultSetting =
          configDynamicLists?.dynamicLists?.find((x) => x.collectionName === key)?.default || [];

        // Filtering out duplicate values from defaultSetting
        const newValues = defaultSetting.filter((item) => !existingValues.includes(item));

        dynamicListsSetting.dynamicLists[key] = [...existingValues, ...newValues];
      });
    };

    const dynamicListsSetting = integration?.settings?.find((s) => s.dynamicLists);
    const configDynamicLists = configData?.settings?.find((s) => s.settingType === 'dynamicList');

    if (dynamicListsSetting) {
      updateDynamicLists(dynamicListsSetting, configDynamicLists);
    } else if (configDynamicLists?.dynamicLists?.length) {
      integration.settings = [
        {
          scope: 'fetcher',
          dynamicLists: Object.fromEntries(
            configDynamicLists.dynamicLists.map((list) => [list.collectionName, list.default]),
          ),
        },
      ];
    }

    if (integration.settings) {
      setCurrentSettings(integration.settings);
      onCurrentChanged?.(integration.settings);
    }
  }, [integration?.settings, onCurrentChanged, configData?.settings]);

  const settingChanged = (field: string, value) => {
    let currentField = currentSettings.find((s) => s[field]);
    if (currentField) {
      currentField[field] = value;
    } else {
      currentField = { [field]: value };
      currentSettings.push(currentField);
    }
    setCurrentSettings([...currentSettings]);
    onCurrentChanged?.([...currentSettings]);
  };

  const dynamicSettingChanged = (collectionName: string, field: string, value) => {
    let currentDynamicSettings = currentSettings.find((s) => s['propertiesMap']);
    if (!currentDynamicSettings) {
      currentDynamicSettings = {
        propertiesMap: {},
      };
      currentSettings.push(currentDynamicSettings);
    }

    if (!currentDynamicSettings.propertiesMap[collectionName]) {
      currentDynamicSettings.propertiesMap[collectionName] = [];
    }

    let fieldSetting = currentDynamicSettings.propertiesMap[collectionName].find(
      (x) => x.fieldName === field,
    );
    if (fieldSetting) {
      fieldSetting.sourceField = value;
    } else {
      currentDynamicSettings.propertiesMap[collectionName].push({
        fieldName: field,
        sourceField: value,
      });
    }
    setCurrentSettings([...currentSettings]);
    onCurrentChanged?.([...currentSettings]);
  };

  const dynamicListChanged = (collectionName: string, id: number, value: boolean) => {
    let dynamicListEntry = currentSettings.find((s) => s['dynamicLists']);

    if (!dynamicListEntry) {
      dynamicListEntry = { dynamicLists: {} };
      currentSettings.push(dynamicListEntry);
    }

    const collectionList = dynamicListEntry.dynamicLists[collectionName] || [];

    dynamicListEntry.dynamicLists[collectionName] = value
      ? [...collectionList.filter((x) => x !== id), id]
      : collectionList.filter((x) => x !== id);

    setCurrentSettings([...currentSettings]);
    onCurrentChanged?.([...currentSettings]);
  };

  return (
    <Box>
      {showHeader && (
        <Flex align="center" mt={'sm'} justify={'space-between'}>
          <Text fz={'lg'}>Account {integration.provider_account_name}</Text>
          <Button onClick={() => dispatch(saveIntegrationSettings(integration, currentSettings))}>
            Save Settings
          </Button>
        </Flex>
      )}
      <LoadingOverlay visible={sensoryProviderSettingLoading} />
      <Box mt={'sm'} overflow={'scroll'}>
        {configData?.settings?.map((setting: ProviderSetting) => (
          <Box key={setting.field}>
            {setting.settingType === 'list' && (
              <Flex align="center" direction="row" my={'sm'}>
                <Text tt="capitalize" mr={'sm'}>
                  {setting.label}:
                </Text>
                <Select
                  data={setting.values!}
                  value={
                    currentSettings.find((s) => s[setting.field])
                      ? currentSettings.find((s) => s[setting.field])[setting.field]
                      : ''
                  }
                  onChange={(data) => settingChanged(setting.field, data)}
                />
              </Flex>
            )}
            {setting.settingType === 'dynamicMapping' &&
              setting.mappingFields
                ?.filter((x) => x?.['fieldNames']?.length)
                .map((mappingField) => (
                  <Card my={'xs'} key={'card' + mappingField.collectionName}>
                    <Box key={mappingField.collectionName}>
                      <Text tt="capitalize" weight={'bold'}>
                        {mappingField.collectionName}
                      </Text>
                      {mappingField['fieldNames']?.map((fieldName) => (
                        <Flex align="center" direction="row" my={'sm'} justify="space-between">
                          <Text tt="capitalize" mr={'sm'} fz={'sm'}>
                            {fieldName}:
                          </Text>
                          <Select
                            w="70%"
                            searchable
                            data={
                              dynamicSettingsOptions[mappingField.collectionName]
                                ?.map((x) => {
                                  return { value: x.id, label: x.label };
                                })
                                .sort((a, b) => a.label.localeCompare(b.label)) ?? []
                            }
                            value={
                              currentSettings
                                .find((s) => s['propertiesMap'])
                                ?.propertiesMap[
                                  mappingField.collectionName
                                ]?.find((x) => x.fieldName === fieldName)?.sourceField ?? ''
                            }
                            onChange={(data) =>
                              dynamicSettingChanged(mappingField.collectionName, fieldName, data)
                            }
                          />
                        </Flex>
                      ))}
                    </Box>
                  </Card>
                ))}
            {setting.settingType === 'dynamicList' &&
              Object.keys(dynamicLists).map((dynamicListKey) => (
                <Card my={'xs'} key={'card' + dynamicListKey}>
                  <Box key={dynamicListKey}>
                    <Text tt="capitalize" weight={'bold'}>
                      {setting.dynamicLists?.find((x) => x.collectionName == dynamicListKey)
                        ?.fieldName ?? dynamicListKey}
                    </Text>

                    {dynamicLists[dynamicListKey]
                      ?.filter((item) =>
                        (
                          setting.dynamicLists?.find((x) => x.collectionName == dynamicListKey)
                            ?.default as any
                        )?.includes(item.id),
                      )
                      .map((item) => (
                        <Checkbox
                          color="named.7"
                          pt={'md'}
                          readOnly={true}
                          label={
                            <Tooltip label={item.description}>
                              <Text size="md" weight={500} color="named2.0">
                                {item.label}
                              </Text>
                            </Tooltip>
                          }
                          checked={currentSettings
                            ?.find((s) => s['dynamicLists'])
                            ?.dynamicLists[dynamicListKey]?.some((x) => x === item.id)}
                          onChange={(data) => dynamicListChanged(dynamicListKey, item.id, data)}
                        />
                      ))}
                    <Text tt="capitalize" weight={'bold'} mt={'lg'}>
                      Check off any additional order statuses you want to be included in your
                      reports:
                    </Text>
                    {dynamicLists[dynamicListKey]
                      ?.filter(
                        (item: any) =>
                          !(
                            setting.dynamicLists?.find((x) => x.collectionName === dynamicListKey)
                              ?.default as any
                          )?.includes(item.id),
                      )
                      .map((item) => (
                        <Checkbox
                          pt={'md'}
                          label={
                            <Tooltip label={item.description}>
                              <Text size="md" weight={500} color="named2.0">
                                {item.label}
                              </Text>
                            </Tooltip>
                          }
                          checked={currentSettings
                            ?.find((s) => s['dynamicLists'])
                            ?.dynamicLists[dynamicListKey]?.some((x) => x === item.id)}
                          onChange={(data) => dynamicListChanged(dynamicListKey, item.id, data)}
                        />
                      ))}
                  </Box>
                </Card>
              ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
