import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Switch from 'components/Insights/Switch';
import { RootState } from 'reducers/RootType';
import { FiltersContext } from 'ducks/insights/filterReducers';
import { CohortTable, TABLE_COLORS, TableViewOptions, getOptionLabel } from './constants';
import ViewActions from '../ViewActions/ViewActions';
import { useFiltersText } from '../Filters/useFiltersText';
import InsightsFilterField from '../InsightFilterField';

const CohortTableViewOptions: React.FC<{
  tableViewOptions: TableViewOptions;
  onChange: (value: TableViewOptions) => void;
  tableRef;
  cohortData?: CohortTable | null;
}> = ({ tableViewOptions, onChange, tableRef, cohortData }) => {
  const [isCumulative, setIsCumulative] = useState<boolean>(tableViewOptions.isCumulative);
  const [secondOrderOnly, setSecondOrderOnly] = useState<boolean>(tableViewOptions.secondOrderOnly);
  const [showNCPAPayback, setShowNCPAPayback] = useState<boolean>(tableViewOptions.showNCPAPayback);
  const filteredByText = useFiltersText(FiltersContext.Cohorts);
  const { ltvSelectedCDPSegment } = useSelector((state: RootState) => state.ltv);
  const { segmentsList, isSegmentsListInitialized } = useSelector(
    (state: RootState) => state.CDPSegments,
  );
  const { dateRange } = useSelector((state: RootState) => state.insights);
  const [tableCSVData, setTableCSVData] = useState<string[][]>([]);

  const handleIsCumulativeChanged = useCallback(
    (value) => {
      setIsCumulative(value);
      onChange({ ...tableViewOptions, isCumulative: value });
    },
    [onChange, tableViewOptions],
  );

  const handleShowNCPAPaybackChanged = useCallback(
    (value) => {
      setShowNCPAPayback(value);
      onChange({ ...tableViewOptions, showNCPAPayback: value });
    },
    [onChange, tableViewOptions],
  );

  const handleSecondOrderOnlyChanged = useCallback(
    (value) => {
      setSecondOrderOnly(value);
      onChange({ ...tableViewOptions, secondOrderOnly: value });
    },
    [onChange, tableViewOptions],
  );

  const tableTitle = useMemo(() => {
    return `${getOptionLabel(tableViewOptions.metric)} Cohorts ${dateRange?.start.format(
      'MMM D YYYY',
    )} - ${dateRange?.end.format('MMM D YYYY')}`;
  }, [dateRange?.start, dateRange?.end, tableViewOptions.metric]);

  const currentSegmentName = useMemo(() => {
    if (!isSegmentsListInitialized) return 'All customers';
    return segmentsList.find((segment) => segment.id === ltvSelectedCDPSegment)?.name;
  }, [isSegmentsListInitialized, ltvSelectedCDPSegment, segmentsList]);

  useEffect(() => {
    setTimeout(() => {
      const table: HTMLElement = tableRef;
      if (!table) return;

      const rows = table.querySelectorAll('tr');
      const tableOptions = [`${currentSegmentName} ${filteredByText}`];
      const tableData = Array.from(rows).map((row) => {
        const cols = row.querySelectorAll('td,th');
        return Array.from(cols).map((col) => col.textContent || '');
      });
      tableData[0][0] = getOptionLabel(cohortData?.cohortBreakdown || '');
      setTableCSVData([tableOptions, ...tableData]);
    }, 0);
  }, [cohortData?.cohortBreakdown, currentSegmentName, filteredByText, tableRef, tableViewOptions]);

  return (
    <div className="border-b border-solid border-[#D1D5DB] border-0 pb-[10px] mb-[40px] flex flex-wrap gap-[24px]">
      <Switch
        checked={showNCPAPayback}
        onChange={handleShowNCPAPaybackChanged}
        label="NCPA Payback"
      />
      <Switch checked={isCumulative} onChange={handleIsCumulativeChanged} label="Cumulative" />
      <InsightsFilterField tooltipContent="This view will only display the count of customers who made a second purchase in the month that second purchase occurred">
        <Switch
          checked={secondOrderOnly}
          onChange={handleSecondOrderOnlyChanged}
          label="2nd Order Only"
        />
      </InsightsFilterField>

      <div className="flex flex-col gap-[4px] ml-auto mr-[15px]">
        <span className="text-[14px] font-medium text-[#374151]">Historical data</span>
        <div className="flex gap-[2px] h-[8px] w-[128px]">
          {TABLE_COLORS.map((c, i) => (
            <div
              key={i}
              className="rounded-[2px] flex-1"
              style={{ backgroundColor: c.background }}
            ></div>
          ))}
        </div>
      </div>

      <ViewActions viewTitle={tableTitle} CSVData={tableCSVData} isDisabled={!tableRef} />
    </div>
  );
};

export default CohortTableViewOptions;
