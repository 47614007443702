import React, { ReactNode } from 'react';
import { TWInfoTooltip } from 'components/library/TWTooltip/TWInfoTooltip';

const InsightsFilterField: React.FC<{
  label?: string;
  tooltipContent?: ReactNode;
  tooltipMaxWidth?: number;
  children;
}> = ({ label, tooltipContent, tooltipMaxWidth, children }) => {
  return (
    <div className="mr-[4px] flex flex-col gap-[6px]">
      {label && (
        <div className="flex flex-wrap gap-[5px] items-center">
          <span className="text-[14px] font-medium text-[#374151]">{label}</span>
          {tooltipContent && (
            <TWInfoTooltip tooltipContent={tooltipContent} tooltipMaxWidth={tooltipMaxWidth} />
          )}
        </div>
      )}

      <div className="flex flex-wrap gap-[5px] items-center">
        <span>{children}</span>
        {!label && tooltipContent && (
          <div className="mt-[-4px]">
            <TWInfoTooltip tooltipContent={tooltipContent} tooltipMaxWidth={tooltipMaxWidth} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsFilterField;
