import { Flex, Skeleton } from '@tw/ui-components';
import { NAVS } from './constants/navs';
import { $activeNav, $navigationLoading } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { MobileNavWrapper } from './components/wrappers/MobileNavWrapper';
import { DesktopNavWrapper } from './components/wrappers/DesktopNavWrapper';
import { StoresNav } from './components/navs/StoresNav/StoresNav';
import { useMemo } from 'react';
import { useStoreValue } from '@tw/snipestate';

export const Nav = () => {
  const navigationLoading = useStoreValue($navigationLoading);
  const activeNav = useStoreValue($activeNav);
  const isSmall = useIsSmall();

  const NavWrapper = isSmall ? MobileNavWrapper : DesktopNavWrapper;
  const InnerNav = useMemo(() => NAVS[activeNav], [activeNav]);
  const _StoresNav = useMemo(
    () => (!isSmall && activeNav !== 'alan' ? <StoresNav /> : null),
    [isSmall, activeNav],
  );

  return (
    <NavWrapper>
      {navigationLoading ? (
        <Flex direction="column" justify="space-between" h="100%" py="sm">
          <Flex direction="column" justify="center" align="center" gap="sm">
            {Array(4)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} width="80%" height="50" />
              ))}
          </Flex>
          <Flex direction="column" justify="center" align="center" gap="sm" mt="sm">
            <Skeleton width="40" height="40" radius="100%" />
            <Skeleton width="50" height="30" radius={30} />
          </Flex>
        </Flex>
      ) : (
        <Flex align="flex-start">
          {_StoresNav}
          {InnerNav}
        </Flex>
      )}
    </NavWrapper>
  );
};
