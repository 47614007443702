import { combineReducers } from 'redux';
import { SET_CHANNEL_TYPE } from './actions';

const channelType = (state = '', action: any) => {
  switch (action.type) {
    case SET_CHANNEL_TYPE:
      return action.channelType || 'all';
    default:
      return state;
  }
};

export const reducers = combineReducers({
  channelType,
});
