import React from 'react';
import DatePicker from '../SummaryDatePicker';

type DatePickerHeaderProps = {
  showGroupByButton?: boolean;
  showCompareButton?: boolean;
  children?: React.ReactNode;
};
export const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
  showGroupByButton,
  showCompareButton,
  children,
}) => {
  return (
    <div className="flex items-center flex-wrap gap-4">
      {children}
      <div className="ml-auto">
        <DatePicker
          showGroupByButton={showGroupByButton ?? false}
          showCompareButton={!!showCompareButton}
        />
      </div>
    </div>
  );
};
