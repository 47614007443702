import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SalesPlatform } from '@tw/types/module/services';
import { RootState } from 'reducers/RootType';
import SALES_PLATFORMS from 'constants/SalesPlatform';

const SalesPlatformIcon: React.FC<{
  mspName?: SalesPlatform;
  small?: boolean;
  customHeight?: number;
}> = (props) => {
  const name: SalesPlatform = useSelector((state: RootState) => props.mspName || state.msp);
  const salesPlatform = SALES_PLATFORMS[name];
  const height = useMemo(() => {
    return props.customHeight ? props.customHeight : props.small ? 11 : 18;
  }, [props.customHeight, props.small]);

  return <img src={salesPlatform.icon} alt={salesPlatform.title} style={{ height: height }} />;
};
export default SalesPlatformIcon;
