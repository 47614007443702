import { $store, $mutableObserver } from '@tw/snipestate';
import { WillyRule } from '@tw/willy-data-dictionary/module/columns/types';
import { $currentShopId } from '$stores/$shop';
import _db, { toArray } from 'utils/DB';
import { SAVED_RULES_COLLECTION } from 'pages/FreeQuery/constants';
import { $userId } from '$stores/$user';

export const defaultWillyRulePopupState = {
  isOpen: false,
  rule: null as WillyRule | null,
  ruleSaved: null as ((rule: WillyRule) => Promise<void>) | null,
  query: null as null | string,
};

export const $willyRulePopup = $store(defaultWillyRulePopupState);

export const $allRules = $mutableObserver<WillyRule[]>([], (get, set) => {
  if (!get($userId)) return;
  const currentShopId = get($currentShopId);
  if (!currentShopId) return;

  return _db()
    .collection(SAVED_RULES_COLLECTION)
    .onSnapshot(async (snapshot) => {
      const rules = toArray(snapshot);
      set(rules);
    });
});
