import { useEffect, useState } from 'react';
import { keyIsSomeDate } from '../utils/willyUtils';

export const usePreviousPeriodValue = (
  metricKey?: string,
  value?: number | string | null,
  previousValue?: number | string | null,
  valueIsNegative?: boolean,
) => {
  const [deltaDirection, setDeltaDirection] = useState<1 | -1 | 0>();
  const [deltaPercentage, setDeltaPercentage] = useState<number>();
  useEffect(() => {
    if (value === undefined || previousValue === undefined || typeof value !== 'number') {
      return;
    }
    if (keyIsSomeDate(metricKey)) {
      return;
    }
    const percentageChange =
      value && previousValue ? (Number(value) - Number(previousValue)) / Number(previousValue) : 0;

    const deltaIsPositive =
      (percentageChange > 0 && valueIsNegative) || (percentageChange < 0 && !valueIsNegative);

    setDeltaDirection(deltaIsPositive ? 1 : -1);
    setDeltaPercentage(percentageChange);
  }, [value, previousValue, valueIsNegative, metricKey]);

  return { deltaDirection, deltaPercentage };
};
