import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@shopify/polaris';

import { useAppDispatch } from 'index';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { updateSelectedCDPSegment } from 'ducks/ltv';
import { type RootState } from 'reducers/RootType';
import DropDown from './DropDown';
import { useNavigate } from 'react-router-dom';

const CREATE_SEGMENT_ACTION = 'create_new_segment_action';

const CDPSegmentDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { segmentsList, isSegmentsListInitialized } = useSelector(
    (state: RootState) => state.CDPSegments,
  );
  const [segmentsOptions, setSegmentsOptions] = useState<any[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<string | null>();

  useEffect(() => {
    if (!isSegmentsListInitialized) {
      dispatch(setCDPSegmentsFromServer());
    }
  }, [dispatch]);

  useEffect(() => {
    const options: { label: string; value: string | null }[] = segmentsList.map((s) => ({
      label: s.name,
      value: s.id,
    }));
    options.push({ label: 'All Customers', value: null });

    if (segmentsList.length === 0) {
      options.push({ label: 'Create New Segment', value: CREATE_SEGMENT_ACTION });
    }

    setSegmentsOptions(options);
    setSelectedSegment(null);
  }, [segmentsList]);

  useEffect(() => {
    if (selectedSegment === CREATE_SEGMENT_ACTION) {
      navigate({ pathname: '/cdp/segment-builder' });
    }

    dispatch(updateSelectedCDPSegment(selectedSegment ?? null));
  }, [selectedSegment, navigate, dispatch]);

  return (
    <Stack alignment="center">
      <DropDown
        id="att-cdp-segments-dropdown"
        options={segmentsOptions}
        value={selectedSegment}
        handleSelect={setSelectedSegment}
      />
    </Stack>
  );
};

export default CDPSegmentDropdown;
