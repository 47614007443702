import { $store } from '@tw/snipestate';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

export const $pathname = $store(window.location.pathname);
export const $search = $store(window.location.search);

/** Hook used to sync react router location with $location store */
export function useConnectLocation$() {
  const location = useLocation();

  useLayoutEffect(() => {
    $pathname.set(location.pathname);
  }, [location.pathname]);

  useLayoutEffect(() => {
    $search.set(location.search);
  }, [location.search]);
}
