import { Flex, Icon, Popover, Text } from '@tw/ui-components';
import { FC } from 'react';

type InfoPopoverProps = {
  description: string;
};

export const InfoPopover: FC<InfoPopoverProps> = ({ description }) => {
  return (
    <Popover position="top">
      <Popover.Target>
        <Flex cursor="pointer">
          <Icon name="info" size={12} color="gray.5" />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="xs" maw={200}>
          {description}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};
