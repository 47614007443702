import { SummaryMetricsIconKeys, SummaryMetricsIcons } from '@tw/types/module/SummaryMetrics';

export type ArrElement<ArrType extends readonly unknown[]> =
  ArrType extends readonly (infer ElementType)[] ? ElementType : never;

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

export function isSummaryMetricsIconKeys(value: any): value is SummaryMetricsIconKeys {
  return SummaryMetricsIcons.includes(value);
}
