import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type RootState } from 'reducers/RootType';
import { CancelMajor } from '@shopify/polaris-icons';

import { Link } from '@shopify/polaris';

type Prop = {
  completeCount: number;
  completePercentage: number;
  taskCount: number;
  fromSummary?: boolean;
};

const ActionsTaken = ({ completeCount, taskCount }) => (
  <span>
    {completeCount}/{taskCount} actions taken
  </span>
);

export default function ProgressBar({
  completePercentage,
  completeCount,
  taskCount,
  fromSummary,
}: Prop) {
  const navigate = useNavigate();
  const [isHidden, setIsHidden] = useState(localStorage.hideOptimization);

  return (
    <>
      {(!isHidden && fromSummary) || !fromSummary ? (
        <div
          className={`relative px-10 py-7 text-2xl bg-white rounded ${
            fromSummary ? 'Polaris-Card' : null
          } ${'mb-20'}`}
        >
          <h1 className="mb-4 text-3xl font-semibold">
            <span className="text-2xl mr-5 dark:text-gray-100">
              Optimization {completePercentage}% Complete
            </span>
            <p className="text-xl inline">
              {fromSummary ? (
                <Link
                  onClick={() => navigate('/optimizations')}
                  id="tr-summary-page-set-optimization"
                >
                  <ActionsTaken completeCount={completeCount} taskCount={taskCount} />
                </Link>
              ) : (
                <ActionsTaken completeCount={completeCount} taskCount={taskCount} />
              )}
            </p>
          </h1>
          <div className="outter-line h-2.5 bg-gray-300 rounded">
            <div
              className="inner-line h-2.5 bg-green rounded"
              style={{ width: `${completePercentage}%` }}
            ></div>
          </div>
          {fromSummary && (
            <div className="absolute top-4 right-4 text-lg md:text-xl">
              <p
                className="inline-block text-red-400 hover:scale-125 cursor-pointer"
                onClick={() => {
                  localStorage.hideOptimization = 'true';
                  setIsHidden(true);
                }}
              >
                <CancelMajor width={14} height={14} fill="red" />
              </p>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
