import { $derived } from '@tw/snipestate';
import { $ffStore } from '../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

export const $canToggleBetweenVersions = $derived((get) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return false;

  const t3Config = ffComputer.getConfigById(FeatureFlag.TW_3_0_FF);
  const t3OnlyConfig = ffComputer.getConfigById(FeatureFlag.TW_ONLY_3_0_FF);
  return t3Config.result !== false && !t3OnlyConfig.result;
});

export const $v3_0_Enabled = $derived((get) => {
  const ffComputer = get($ffStore);
  const t3Config = ffComputer.getConfigById(FeatureFlag.TW_3_0_FF);
  const t3OnlyConfig = ffComputer.getConfigById(FeatureFlag.TW_ONLY_3_0_FF);

  if (t3Config.result === null && t3OnlyConfig.result === null) return null;

  // if either of them are true, user has TW 3.0
  return !!(t3Config.result || t3OnlyConfig.result);
});

export const $v3_0_Only = $derived((get) => {
  const ffComputer = get($ffStore);
  const config = ffComputer.getConfigById(FeatureFlag.TW_ONLY_3_0_FF);
  if (config.result === null) return null;
  return !!config.result;
});
