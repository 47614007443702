export type CircleProgressProps = {
  size: number;
  trackWidth: number;
  indicatorWidth: number;
  completePercentage?: number;
};

export const CircleProgress: React.FC<CircleProgressProps> = ({
  size,
  trackWidth,
  indicatorWidth,
  completePercentage,
}) => {
  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - (completePercentage || 75)) / 100);

  return (
    <svg className="rotate-[-90deg]" style={{ width: size, height: size }}>
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        style={{ stroke: 'var(--primary-200)' }}
        strokeWidth={trackWidth}
      />
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        style={{ stroke: 'var(--primary-600)' }}
        strokeWidth={indicatorWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        strokeLinecap={'round'}
      />
    </svg>
  );
};
