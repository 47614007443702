import React from 'react';

import { Spinner } from '@shopify/polaris';

import { ReactComponent as DownloadIcon } from '../../src/icons/downloadIcon.svg';
import * as utils from '../utils/Lightbox/utils';
import FacebookVideoPlayer from './FacebookVideoPlayer';
import TWImage from './library/TWImage/TWImage';
import { TiktokVideoPlayer } from './TiktokVideoPlayer';
import { windowSize } from 'utils/classes/WindowSizeObserver';

const MIN_SCALE = 1;
const MAX_SCALE = 4;
const SETTLE_RANGE = 0.001;
const ADDITIONAL_LIMIT = 0.2;
const DOUBLE_TAP_THRESHOLD = 300;
const ANIMATION_SPEED = 0.04;
const RESET_ANIMATION_SPEED = 0.08;
const INITIAL_X = 0;
const INITIAL_Y = 0;
const INITIAL_SCALE = 1;
const MOBILE_ICON_SIZE = 35;
const DESKTOP_ICON_SIZE = 50;

export type ImageVideoLightboxResource<T = any> = {
  type: 'photo' | 'video';
  altTag: string;
  url: string;
  extraData?: T;
  title?: string;
  thumbnail?: string;
  name?: string;
  downloadLink?: string;
  width?: number;
  height?: number;
};

interface ImageVideoLightboxProps {
  data: ImageVideoLightboxResource[];
  showResourceCount: boolean;
  startIndex: number;
  onNavigationCallback: (...args: any) => any;
  onCloseCallback: () => any;
  width?: number;
  height?: number;
}

interface ImageVideoLightboxState {
  x: number;
  y: number;
  scale: number;
  width: number;
  height: number;
  index: number;
  swiping: boolean;
  loading: boolean;
  downLoading: boolean;
  iconSize: number;
  imageWrapperWidth: number;
  imageWrapperHeight: number;
}

class ImageVideoLightbox extends React.Component<ImageVideoLightboxProps, ImageVideoLightboxState> {
  width: number;
  height: number;
  onNavigationCallback: (index: number) => any;
  animation: number | undefined;
  swipeStartX: number | undefined;
  swipeStartY: number | undefined;
  lastTouchEnd: number | undefined;
  lastDistance: number | undefined;

  constructor(props: ImageVideoLightboxProps) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      x: INITIAL_X,
      y: INITIAL_Y,
      scale: INITIAL_SCALE,
      width: window.innerWidth,
      height: window.innerHeight,
      index: props.startIndex || 0,
      swiping: false,
      loading: true,
      downLoading: false,
      iconSize: window.innerWidth <= 500 ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE,
      imageWrapperWidth: props.width && !windowSize.isSmall ? props.width : 360,
      imageWrapperHeight: props.height && !windowSize.isSmall ? props.height : 400,
    };

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.onNavigationCallback =
      this.props.onNavigationCallback && typeof this.props.onNavigationCallback === 'function'
        ? this.props.onNavigationCallback
        : () => {};
  }

  override componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    document.body.style.overflow = 'hidden';
  }
  override componentWillUnmount() {
    window.removeEventListener('resize', () => {
      if (window.innerWidth <= 500) {
        this.setState({ iconSize: MOBILE_ICON_SIZE });
      } else {
        this.setState({ iconSize: DESKTOP_ICON_SIZE });
      }
    });
    document.removeEventListener('keydown', this.escFunction, false);
    document.body.style.overflow = 'unset';
  }

  escFunction(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.props.onCloseCallback();
    } else {
      event.preventDefault();
    }
  }

  zoomTo(scale: number) {
    const frame = () => {
      if (this.state.scale === scale) return;

      const distance = scale - this.state.scale;
      const targetScale = this.state.scale + ANIMATION_SPEED * distance;

      this.zoom(utils.settle(targetScale, scale, SETTLE_RANGE));
      this.animation = requestAnimationFrame(frame);
    };

    this.animation = requestAnimationFrame(frame);
  }

  reset() {
    const frame = () => {
      if (
        this.state.scale === INITIAL_SCALE &&
        this.state.x === INITIAL_X &&
        this.state.y === INITIAL_Y
      )
        return;

      const scaleDelta = INITIAL_SCALE - this.state.scale;
      const targetScale = utils.settle(
        this.state.scale + RESET_ANIMATION_SPEED * scaleDelta,
        INITIAL_SCALE,
        SETTLE_RANGE,
      );

      const nextWidth = this.width * targetScale;
      const nextHeight = this.height * targetScale;

      this.setState(
        {
          scale: targetScale,
          width: nextWidth,
          height: nextHeight,
          x: INITIAL_X,
          y: INITIAL_Y,
        },
        () => {
          this.animation = requestAnimationFrame(frame);
        },
      );
    };

    this.animation = requestAnimationFrame(frame);
  }

  handleTouchStart(event) {
    this.animation && cancelAnimationFrame(this.animation);
    if (event.touches.length === 2) this.handlePinchStart(event);
    if (event.touches.length === 1) this.handleTapStart(event);
  }

  handleTouchMove(event) {
    if (event.touches.length === 2) this.handlePinchMove(event);
    if (event.touches.length === 1) this.handlePanMove(event);
  }

  handleTouchEnd(event) {
    if (event.touches.length > 0) return null;

    if (this.state.scale > MAX_SCALE) return this.zoomTo(MAX_SCALE);
    if (this.state.scale < MIN_SCALE) return this.zoomTo(MIN_SCALE);

    if (this.lastTouchEnd && this.lastTouchEnd + DOUBLE_TAP_THRESHOLD > event.timeStamp) {
      this.reset();
    }

    if (this.state.swiping && this.state.scale === 1) {
      this.handleSwipe(event);
    }

    this.lastTouchEnd = event.timeStamp;
  }

  handleSwipe(event: TouchEvent) {
    const swipeDelta = event.changedTouches[0].clientX - (this.swipeStartX || 0);
    if (swipeDelta < -(this.width / 3)) {
      this.swipeRight();
    } else if (swipeDelta > this.width / 3) {
      this.swipeLeft();
    } else {
      this.reset();
    }
  }

  swipeLeft() {
    const currentIndex = this.state.index;
    if (currentIndex > 0) {
      setTimeout(() => {
        this.setState(
          {
            index: currentIndex - 1,
            swiping: false,
            x: INITIAL_X,
            loading: true,
          },
          () => this.onNavigationCallback(currentIndex - 1),
        );
      }, 500);
    } else {
      this.reset();
    }
  }

  swipeRight() {
    const currentIndex = this.state.index;
    if (currentIndex < this.props.data.length - 1) {
      setTimeout(() => {
        this.setState(
          {
            index: currentIndex + 1,
            swiping: false,
            x: INITIAL_X,
            loading: true,
          },
          () => this.onNavigationCallback(currentIndex + 1),
        );
      }, 500);
    } else {
      this.reset();
    }
  }

  handleTapStart(event) {
    this.swipeStartX = event.touches[0].clientX;
    this.swipeStartY = event.touches[0].clientY;
    if (this.state.scale === 1) {
      this.setState({
        swiping: true,
      });
    }
  }

  handlePanMove(event) {
    if (this.state.scale === 1) {
      this.setState({
        x: event.touches[0].clientX - (this.swipeStartX || 0),
      });
    } else {
      event.preventDefault();
      this.setState({
        x: event.touches[0].clientX - (this.swipeStartX || 0),
        y: event.touches[0].clientY - (this.swipeStartY || 0),
      });
    }
  }

  handlePinchStart(event) {
    const pointA = utils.getPointFromTouch(event.touches[0]);
    const pointB = utils.getPointFromTouch(event.touches[1]);
    this.lastDistance = utils.getDistanceBetweenPoints(pointA, pointB);
  }

  handlePinchMove(event) {
    event.preventDefault();
    const pointA = utils.getPointFromTouch(event.touches[0]);
    const pointB = utils.getPointFromTouch(event.touches[1]);
    const distance = utils.getDistanceBetweenPoints(pointA, pointB);
    const scale = utils.between(
      MIN_SCALE - ADDITIONAL_LIMIT,
      MAX_SCALE + ADDITIONAL_LIMIT,
      this.state.scale * (distance / this.lastDistance!),
    );
    this.zoom(scale);
    this.lastDistance = distance;
  }

  zoom(scale: number) {
    const nextWidth = this.width * scale;
    const nextHeight = this.height * scale;

    this.setState({
      width: nextWidth,
      height: nextHeight,
      scale,
    });
  }

  getResources() {
    const items: JSX.Element[] = [];
    const data = this.props.data;
    for (let i = 0; i < data.length; i++) {
      const resource = data[i];
      let fileType = resource.type
        ? resource.type.startsWith('video')
          ? 'video'
          : 'photo'
        : 'video';
      if (fileType === 'photo') {
        items.push(
          <img
            key={i}
            alt={resource.altTag}
            src={resource.url}
            style={{
              pointerEvents: this.state.scale === 1 ? 'auto' : 'none',
              transform: `translate(${this.state.x}px, ${this.state.y}px) scale(${this.state.scale})`,
              transition: 'transform 0.5s ease-out',
              maxWidth: resource.width && !windowSize.isSmall ? `${resource.width}px` : '360px',
            }}
            onLoad={({ currentTarget }) => {
              this.setState({
                loading: false,
                imageWrapperWidth: currentTarget.width,
                imageWrapperHeight: currentTarget.height + 86,
              });
            }}
          />,
        );
      }

      if (fileType === 'video') {
        if (resource?.extraData?.serviceId === 'facebook-ads') {
          items.push(
            <FacebookVideoPlayer
              videoId={resource.extraData.assetId}
              onVideoReady={({ width, height }) => {
                this.setState({
                  loading: false,
                  imageWrapperWidth: width || 260,
                  imageWrapperHeight: height || 260,
                });
              }}
            />,
          );
        } else if (resource?.extraData?.serviceId === 'tiktok-ads') {
          items.push(
            <TiktokVideoPlayer
              serviceId={resource.extraData.serviceId}
              videoId={resource.extraData.assetId}
              accountId={resource.extraData.accountId}
              onVideoReady={({ width, height }) => {
                this.setState({
                  loading: false,
                  imageWrapperWidth: width || 'auto',
                  imageWrapperHeight: height || 260,
                });
              }}
              playerProps={{
                url: resource.url,
                // width: '100%',
                // height: `${modalHeight}px`,
              }}
            />,
          );
        } else {
          items.push(
            <iframe
              key={i}
              width="560"
              height="315"
              src={resource.url}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              title={resource.title}
              allowFullScreen
              style={{
                pointerEvents: this.state.scale === 1 ? 'auto' : 'none',
                // maxWidth: '100%',
                // maxHeight: '100%',
                transform: `translate(${this.state.x}px, ${this.state.y}px)`,
                transition: 'transform 0.5s ease-out',
                maxWidth: '360px',
              }}
              onLoad={() => {
                this.setState({ loading: false });
              }}
            ></iframe>,
          );
        }
      }
    }

    return items;
  }

  override UNSAFE_componentWillMount() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 500) {
        this.setState({ iconSize: MOBILE_ICON_SIZE });
      } else {
        this.setState({ iconSize: DESKTOP_ICON_SIZE });
      }
    });
  }

  getPreviews() {
    const length = this.props.data.length;

    let indexToShow = Array.from({ length }, (v, k) => k);

    return indexToShow.map((i) => {
      return (
        <TWImage
          key={i}
          className={`w-16 object-cover rounded-md cursor-pointer`}
          wrapperClass={`border-2 rounded border-black flex ${
            this.state.index !== i ? 'opacity-50' : 'opacity-100'
          }`}
          src={
            this.props.data[i]?.type === 'video'
              ? this.props.data[i].thumbnail
              : this.props.data[i].url
          }
          onClick={() => {
            this.setState({ index: i });
          }}
        />
      );
    });
  }

  downloadFile = (e) => {
    e.stopPropagation();
    const currentFile = this.props.data[this.state.index];

    if (currentFile.downloadLink) {
      this.setState({
        downLoading: true,
      });

      fetch(currentFile.downloadLink!)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.style.display = 'none';

          //if (filename && filename.length)
          a.download = currentFile.name || '';
          document.body.appendChild(a);
          a.click();
          this.setState({
            downLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            downLoading: false,
          });
          console.error(error);
        });
    }
  };

  override render() {
    const resources = this.getResources();
    return (
      <div
        className="lb-wrapper overflow-hidden fixed flex flex-col items-center justify-center h-screen w-screen left-0 top-0"
        onClick={this.props.onCloseCallback}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        style={{
          backgroundColor: 'rgba(0,0,0,0.67)',
          zIndex: 999999999999,
        }}
      >
        <div className="absolute top-0 p-4 text-white font-bold cursor-pointer right-14">
          <DownloadIcon
            style={{ width: '37px', height: '37px' }}
            onClick={this.downloadFile}
          ></DownloadIcon>
        </div>
        {this.props.showResourceCount && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute top-0 left-0 p-6 text-white font-bold"
          >
            <span>{this.state.index + 1}</span> / <span>{this.props.data.length}</span>
          </div>
        )}

        <div
          className="absolute top-0 right-0 p-4 text-white font-bold cursor-pointer"
          style={{
            fontSize: `${this.state.iconSize * 0.8}px`,
          }}
          onClick={this.props.onCloseCallback}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="36px"
            viewBox="0 0 24 24"
            width="36px"
            fill="#FFFFFF"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </div>
        {this.state.index + 1 !== 1 ? (
          <div
            className="absolute left-0 z-10 text-white cursor-pointer"
            style={{
              fontSize: `${this.state.iconSize}px`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.swipeLeft();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48px"
              viewBox="0 0 24 24"
              width="48px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </div>
        ) : (
          <></>
        )}
        {this.state.index + 1 !== this.props.data.length ? (
          <div
            className="absolute right-0 z-10 text-white cursor-pointer"
            style={{
              fontSize: `${this.state.iconSize}px`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.swipeRight();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48px"
              viewBox="0 0 24 24"
              width="48px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </div>
        ) : (
          <></>
        )}

        <div
          className="flex flex-col"
          onClick={(e) => e.stopPropagation()}
          style={{ width: this.state.imageWrapperWidth, height: this.state.imageWrapperHeight }}
        >
          <div className="lb-active-resource-wrapper relative flex bg-white">
            {this.state.loading && (
              <div className="absolute z-50 w-full h-full flex items-center justify-center bg-white">
                <Spinner size="large" />
              </div>
            )}
            {this.state.downLoading && (
              <div className="absolute z-50 w-full h-full flex items-center justify-center">
                <Spinner size="large" />
              </div>
            )}
            {resources[this.state.index]}
          </div>
          {this.props.data.length > 1 && (
            <div className="w-full p-6.5 flex justify-around items-center bg-white overflow-y-hidden overflow-x-auto">
              <div className="flex gap-6.5 flex-shrink-0 max-w-full">{this.getPreviews()}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ImageVideoLightbox;
