import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import SwiperCore, { Keyboard, Mousewheel, Navigation, Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axiosInstance from 'utils/axiosInstance';

import AdMediaCard from '../ads/AdMediaCard';
import { ContentHubContext } from '../ContentHub';
import { Loader } from '@tw/ui-components';

type AdResultsProps = {};
SwiperCore.use([Keyboard, Mousewheel]);

const AdResults: React.FC<AdResultsProps> = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const { debounce, searchResultsAds, searchingAds } = useContext(ContentHubContext);
  const [adsData, setAdsData] = useState<any>([]);
  const [adsPage, setAdsPage] = useState(1);
  const [adsHasNext, setAdsHasNext] = useState(false);
  const [adsIndex, setAdsIndex] = useState(0);
  const [adsSwiper, setAdsSwiper] = useState<SwiperInterface>();
  const [loadingAds, setLoadingAds] = useState(false);

  useEffect(() => {
    setAdsIndex(0);
    if (adsSwiper?.activeIndex) {
      adsSwiper?.slideTo?.(0);
    }
    setAdsData(searchResultsAds.data);
    if (searchResultsAds?.data?.length >= 12) {
      setAdsHasNext(true);
    }
    // setTotalAds(searchResultsAds?.data?.total?.[0]?.count || 0);
    setAdsIndex(0);
  }, [adsSwiper, searchResultsAds]);

  const loadMoreAds = async () => {
    if (!loadingAds) {
      setLoadingAds(true);
      setAdsPage(adsPage + 1);
      const { data } = await axiosInstance.post('/v2/media/search-content-hub-ads', {
        query: debounce,
        shopId: currentShopId,
        page: adsPage,
        limit: 12,
      });
      setAdsData([...adsData, ...data.data]);
      if (data?.data?.length >= 12) {
        setAdsHasNext(true);
      }
      setLoadingAds(false);
    }
  };

  const swiperNext = (swiper) => {
    setAdsIndex(swiper.realIndex);
    swiper.slideNext();
    if (swiper.realIndex + 8 >= adsData.length && adsHasNext) {
      loadMoreAds();
    }
  };

  const swiperPrev = (swiper) => {
    swiper.slidePrev();
    setAdsIndex(swiper.realIndex);
  };

  return (
    <div className="py-2 ad-results">
      <div className="text-3xl	pb-5">Ads</div>
      {searchingAds ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {adsData?.length ? (
            <Swiper
              spaceBetween={16}
              slidesPerView={4}
              onSwiper={(swiper) => setAdsSwiper(swiper)}
              mousewheel={{ forceToAxis: true }}
              keyboard
              slidesPerGroup={2}
              navigation={true}
              pagination
              modules={[Navigation]}
              onNavigationNext={(swiper) => swiperNext(swiper)}
              onNavigationPrev={(swiper) => swiperPrev(swiper)}
            >
              {adsData?.map((m) => {
                return (
                  <SwiperSlide key={m._id}>
                    <div
                      className="flex flex-col justify-center items-center rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white"
                      style={{ height: '290px' }}
                    >
                      <AdMediaCard media={m} />
                    </div>
                  </SwiperSlide>
                );
              })}
              {loadingAds && (
                <SwiperSlide>
                  <div
                    className="flex flex-col justify-center items-center"
                    style={{ height: '290px' }}
                  >
                    <Loader />
                  </div>
                </SwiperSlide>
              )}
              {/* <AdsSwiperPaginatioon /> */}
            </Swiper>
          ) : (
            <div>No ads found</div>
          )}
        </>
      )}
    </div>
  );
};

export default AdResults;
