import { RootState } from 'reducers/RootType';
import { createSelector } from 'reselect';

export const shopWithSensoryData = createSelector(
  [
    (state: RootState) => state.shop,
    (state: RootState) => state.sensory?.sensoryForShop,
    (state: RootState) => state.currentShopId,
  ],
  (shop, sensory, currentShopId) => {
    return { id: currentShopId, ...shop, sensory: sensory ?? {} };
  },
);
