import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import firebase from 'firebase/compat/app';
import { createSelector } from 'reselect';

import { getUserEmail, getUserId, userDb } from '../utils/DB';

import { type AppDispatch } from 'index';
import { type RootState } from 'reducers/RootType';
import { Reducer } from 'redux';
import { User } from 'components/UserProfileManagment/User/constants';

import {
  GET_CURRENT_USER,
  ON_AUTH_STATE_CHANGED,
  USER_DATA_ON_LOAD,
  USER_REMOVE_ADMIN,
} from './constants';
import axiosInstance from 'utils/axiosInstance';
import { $user } from '$stores/$user';
import { $currentShopId } from '../$stores/$shop';

const firestore = firebase.firestore;

export const getCurrentUser = (userId?) => {
  return async (dispatch: AppDispatch) => {
    const user = $user.get();
    const userOption = userId || user.uid || getUserId();
    if (!userOption) return;
    const currentUser = (await userDb(userId || user.uid).get()).data();

    $user.set({ ...currentUser, email: user?.email ?? '', uid: user.uid! });

    // TODO: The goal is to not have to do GET_CURRENT_USER eventually...
    return dispatch({ type: GET_CURRENT_USER, user: $user.get() });
  };
};

export const saveUserDetails = async (user) => {
  try {
    const { firstName, lastName, phone, url, storeRun } = user;
    await firestore()
      .collection('users')
      .doc(getUserId())
      .set(
        {
          firstName: firstName || '',
          lastName: lastName || '',
          phone: phone || '',
          url: url || '',
          storeRun: storeRun || '',
        },
        { merge: true },
      );
  } catch (e) {
    console.error(e);
  }
};

export const createTrendsUser = async () => {
  const uid = getUserId();
  const firebaseUser = (await firestore().collection('users').doc(uid).get()).data();
  if (!!firebaseUser?.email && !!firebaseUser?.userId) return;
  const googleUser = (firebase.auth().currentUser || {}) as any;
  try {
    await axiosInstance.post(`/v2/account-manager/users/update-new-user-data`, {
      userId: uid,
      lastName: googleUser.displayName.split(' ')?.[1] ?? ' ',
      firstName: googleUser.displayName.split(' ')?.[0] ?? googleUser.displayName,
      phone: googleUser.phoneNumber,
      accountType: 'BRAND',
      isCreatedFromInvitation: false,
      isCreatedFromSignUp: true,
      isCreatedFromQuickSignup: true,
      email: googleUser.email,
      verifyEmail: true,
      signUpProvider: 'google',
    });
  } catch (e) {
    console.log(e);
  }
  await axiosInstance.post('/v2/bi/hubspot-update', {
    email: getUserEmail(),
    event: 'signup-to-app',
  });
};

export const updateHSTrendsUser = (data) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosInstance.post('/v2/bi/hubspot-update', {
        email: getUserEmail(),
        ...data,
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const checkIfUserTwAdmin = createSelector(
  [(state: RootState) => state?.tokenDecoded],
  (tokenDecoded) => {
    return !!tokenDecoded?.admin;
  },
);

export const checkIfUserAdminForCurrShop = createSelector(
  [(state) => state?.user, (state) => state?.currentShop?.id, checkIfUserTwAdmin],
  (user, currentShopId, isTwAdmin) =>
    user?.shops?.[currentShopId]?.roles?.includes('admin') ||
    user?.shops?.[currentShopId]?.roles?.includes('owner') ||
    isTwAdmin,
);

// const user: Reducer<Partial<User>> = () => $user.get();

const user: Reducer<Partial<User>> = (state = {}, action) => {
  switch (action.type) {
    case ON_AUTH_STATE_CHANGED: {
      if (!action.user) return {};
      const { email, uid } = action.user;
      return { ...state, email, uid };
    }
    case GET_CURRENT_USER: {
      return { ...state, ...action.user };
    }
    case USER_DATA_ON_LOAD: {
      const { type, ...rest } = action;
      return { ...state, ...rest };
    }
    case USER_REMOVE_ADMIN: {
      return {
        ...state,
        isAdminClaim: false,
        isTwDevClaim: false,
        isTwGlobalDashboardCreatorClaim: false,
        isTwSuperAdminClaim: false,
        isAdmin: false,
      };
    }
    default:
      return state;
  }
};

export const reducers = {
  user,
};
