import React, { useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { DragDropContext, DraggableLocation } from 'react-beautiful-dnd';
import { OPTION_METRICS } from './Constants';
import { CustomMetricsContext } from './CustomMetricsContext';
import { ExpressionElementBag } from './ExpressionElementsBag';
import { ExpressionOptions } from './ExpressionOptions';
import { ElementTypes, ExpressionElement, ExpressionElementsMap } from './types';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export const ExpressionBuilder: React.FC<any> = () => {
  const isSmall = useIsSmall();
  const { setExpressionElements, expressionElements } = useContext(CustomMetricsContext);

  const [expressionElementsMap, setExpressionElementsMap] = React.useState<ExpressionElementsMap>(
    {},
  );
  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);

  useEffect(() => {
    const resultArray: ExpressionElementsMap = { 0: [] };
    let index: number = 0;
    let rowSize: number = 0;
    const maxElementsSize: number = isFromMobileApp || isSmall ? 5 : 10;
    expressionElements.forEach((element: ExpressionElement) => {
      const elementSize =
        element.type == ElementTypes.INTEGER
          ? 4
          : [ElementTypes.STAT, ElementTypes.NESTED_STAT].includes(element.type) && element.title
            ? element.title.length / 6 + 1
            : 2;
      if (rowSize + elementSize <= maxElementsSize) {
        rowSize += elementSize;
      } else {
        index++;
        resultArray[index] = [];
        rowSize = elementSize;
      }
      resultArray[index].push(element);
    });
    if (expressionElements.length == 0) {
      resultArray[0] = [];
    }
    setExpressionElementsMap(resultArray);
  }, [expressionElements, isFromMobileApp]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const elementMap = reorderExpressionElements(expressionElementsMap, source, destination);
    const res = Object.keys(elementMap).reduce((acc: any, key) => {
      return [...acc, ...elementMap[key]];
    }, []);
    setExpressionElements(res);
  };

  const addMetricItem = (item) => {
    expressionElements.push({ ...item, id: uuid() });
    setExpressionElements([...expressionElements]);
  };

  const reorder = (
    list: ExpressionElement[],
    startIndex: number,
    endIndex: number,
  ): ExpressionElement[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const copy = (
    source: any[],
    destination: ExpressionElement[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ) => {
    const item = source[droppableSource.index];
    destination.splice(droppableDestination.index, 0, { ...item, id: uuid() });
    return destination;
  };

  const reorderExpressionElements = (
    elementsMap: ExpressionElementsMap,
    source: DraggableLocation,
    destination: DraggableLocation,
  ): ExpressionElementsMap => {
    const next = [...elementsMap[destination.droppableId]];

    if (source.droppableId == 'ExpressionOptions') {
      const res = copy(OPTION_METRICS, next, source, destination);
      return {
        ...elementsMap,
        [destination.droppableId]: res,
      };
    }
    const current = [...elementsMap[source.droppableId]];
    const target = current[source.index];
    // moving to same list
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(current, source.index, destination.index);
      return {
        ...elementsMap,
        [source.droppableId]: reordered,
      };
    }
    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    return {
      ...elementsMap,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };
  };

  return (
    <div className="bg-gray-100  pb-[16px] rounded-[4px]">
      <DragDropContext onDragEnd={onDragEnd}>
        <ExpressionOptions items={OPTION_METRICS} addMetricItem={(item) => addMetricItem(item)} />
        <div className=" bg-white border-dashed border-[2px] rounded-[4px] border-gray-200 mt-[8px] mx-[16px]">
          {Object.entries(expressionElementsMap).map(([key, elementChunk]) => (
            <ExpressionElementBag items={elementChunk} listId={key} key={key} />
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};
