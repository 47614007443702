import { CancelMinor } from '@shopify/polaris-icons';
import { DraggableProvider, InitialPositionOption } from './DraggableProvider';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type widthOption = 'wide' | 'medium' | 'narrow' | 'custom';

type DraggableModalProps = {
  width: widthOption;
  customWidth?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
  cancelClassName?: string;
  initialPosition?: InitialPositionOption;
  usingDragger?: boolean;
  title?: string;
  closeButtonStyle?: React.CSSProperties;
};

export const DraggableModal: React.FC<DraggableModalProps> = ({
  className,
  cancelClassName,
  style,
  width,
  children,
  open,
  initialPosition,
  usingDragger,
  title,
  closeButtonStyle = {},
  onClose,
}) => {
  const isSmall = useIsSmall();

  if (!open) return null;

  if (!initialPosition) {
    initialPosition = isSmall ? `0 bottom` : 'center center';
  }
  const widthClassName =
    width === 'custom'
      ? ''
      : width === 'wide'
        ? ''
        : width === 'narrow'
          ? 'sm:max-w-[320px] w-full'
          : 'sm:max-w-[620px] w-full';
  return (
    <DraggableProvider
      initialPosition={initialPosition}
      className={`border-1 border-gray-300 rounded-tl-lg rounded-tr-lg sm:rounded-lg shadow-2xl max-w-[100vw] min-h-fit max-h-[calc(100vh-50px)] overflow-auto ${widthClassName}`}
      usingDragger={usingDragger}
    >
      {/* TODO: deal with the optional title here */}
      <div
        className={`background-diffusion-modal overflow-visible flex flex-row bg-white gap-6.5 h-full relative after:content-[''] after:fixed after:top-0 after:left-0 after:w-full after:h-full after:bg-black/30 after:z-[-1] ${className}`}
        style={style}
      >
        <div className="absolute top-4 right-4 z-10">
          <CancelMinor
            className={`w-12 h-12 fill-[#C0D6EA] cursor-pointer p-1 rounded-md outline outline-1 outline-light-blue/10 hover:outline-light-blue/20 hover:bg-[#c0d6ea]/5 hover:shadow-[1px_6px_9px_0_rgba(0,0,0,0.13)] ${cancelClassName}`}
            onClick={onClose}
            style={closeButtonStyle}
          />
        </div>
        {children}
      </div>
    </DraggableProvider>
  );
};
