import { groupStats } from '@tw/stats/module/groupStats';
import { TW_BENCHMARKS_AOV_SEGMENT, TW_BENCHMARKS_SPEND_SEGMENT } from 'constants/benchmarks';
import { combineReducers, Reducer } from 'redux';
import Cookies from 'utils/Cookies';

import {
  aovSegmentTypes,
  BenchmarksRequest,
  revenueSegmentTypes,
} from '@tw/types/module/services/business-intelligence';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';
import { loadingNewStats, newStatsReceived, statsLoadingError } from './newStats';
import { UserRolesTypes } from '@tw/types';
import {
  INIT_SHOP,
  INIT_UI,
  BENCHMARKS_INDUSTRY_RECEIVED,
  BENCHMARKS_INDUSTRY_CHANGED,
  BENCHMARKS_AOV_SEGMENT_CHANGED,
  BENCHMARKS_GET_ERROR_RESPONSE,
  BENCHMARKS_SPEND_SEGMENT_CHANGED,
} from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { RootState } from 'reducers/RootType';
import moment from '@tw/moment-cached/module/timezone';
import { fetchServiceStats } from './newStatsUtils';

export const getBenchmarksStats = (
  params?: {
    industry?: IndustryTypes;
    aovSegment?: aovSegmentTypes | null;
    spendSegment?: revenueSegmentTypes | null;
  },
  isSilent = false,
) => {
  return async (dispatch, getState: () => RootState) => {
    const { currentShopId, mainDatePickerSelectionRange, groupStatsBy, benchmarks } = getState();
    const { industry, aovSegment, spendSegment } = benchmarks;

    if (!mainDatePickerSelectionRange) {
      return;
    }

    if (!industry && !params?.industry) {
      return;
    }

    let { start, end } = mainDatePickerSelectionRange as any;

    const now = moment();
    const isToday = now.diff(start, 'days') === 0 && start.isSame(end, 'day');

    if (isToday) {
      dispatch(
        getBenchmarksErrorResponse(
          'Benchmarks are not available for today. Please select a different date range.',
        ),
      );
      dispatch(
        newStatsReceived({
          stats: {},
          serviceId: 'benchmarks',
        }),
      );
      return;
    }

    const format = 'YYYY-MM-DD';
    start = start.format(format);
    end = end.format(format);

    const requestParams: any = {
      shop_domain: currentShopId,
      start,
      end,
      category: industry || params?.industry,
    };

    if (aovSegment || params?.aovSegment) {
      requestParams.aov_segment = aovSegment || params?.aovSegment;
    }

    if (spendSegment || params?.spendSegment) {
      requestParams.spend_segment = spendSegment || params?.spendSegment;
    }

    try {
      if (!isSilent) {
        dispatch(
          loadingNewStats({
            benchmarks: true,
          }),
        );
      }
      const { stats, error } = await fetchServiceStats(
        requestParams,
        `/v2/bi/get-benchmarks-avg`,
        'post',
        false,
      );
      const s = Object.values<any>(stats?.[0]?.hours || {})?.[0]?.industry || industry;

      dispatch({
        type: BENCHMARKS_INDUSTRY_RECEIVED,
        industry: s,
      });

      if (error) {
        dispatch(getBenchmarksErrorResponse(error));
      } else {
        dispatch(getBenchmarksErrorResponse(''));
      }
      const groupedStats = groupStats(stats, groupStatsBy, undefined, undefined, true);

      dispatch(
        newStatsReceived({
          stats: groupedStats,
          serviceId: 'benchmarks',
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(
        statsLoadingError({
          serviceId: 'benchmarks',
        }),
      );
    }
  };
};

export const changeIndustry = (industry: IndustryTypes) => {
  return (dispatch) => {
    dispatch({
      type: BENCHMARKS_INDUSTRY_CHANGED,
      industry,
    });
  };
};

export const changeAovSegment = (segment: aovSegmentTypes) => {
  return (dispatch) => {
    if (segment) {
      Cookies.set(TW_BENCHMARKS_AOV_SEGMENT, segment);
    } else {
      Cookies.remove(TW_BENCHMARKS_AOV_SEGMENT);
    }
    dispatch({
      type: BENCHMARKS_AOV_SEGMENT_CHANGED,
      segment,
    });
  };
};

export const changeSpendSegment = (segment: revenueSegmentTypes) => {
  if (segment) {
    Cookies.set(TW_BENCHMARKS_SPEND_SEGMENT, segment);
  } else {
    Cookies.remove(TW_BENCHMARKS_SPEND_SEGMENT);
  }
  return (dispatch) => {
    dispatch({
      type: BENCHMARKS_SPEND_SEGMENT_CHANGED,
      segment,
    });
  };
};

export const getBenchmarksErrorResponse = (error: string) => {
  return (dispatch) => {
    dispatch({
      type: BENCHMARKS_GET_ERROR_RESPONSE,
      error,
    });
  };
};

const userRole: Reducer<UserRolesTypes | null | 'unknown'> = (state = null, action) => {
  switch (action.type) {
    case INIT_UI:
      const { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      const shop = shops[$currentShopId.get()!];
      return shop?.role || state;

    default:
      return state;
  }
};

const shopIndustry: Reducer<IndustryTypes | null | 'unknown'> = (state = null, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.industry || state;
    default:
      return state || state;
  }
};

const industry: Reducer<IndustryTypes> = (state = '' as any, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.industry || state;
    case BENCHMARKS_INDUSTRY_CHANGED:
      return action.industry || state;
    default:
      return state;
  }
};

const aovSegment: Reducer<aovSegmentTypes | null> = (
  state = (Cookies.get(TW_BENCHMARKS_AOV_SEGMENT) as aovSegmentTypes) || null,
  action,
) => {
  switch (action.type) {
    case BENCHMARKS_AOV_SEGMENT_CHANGED:
      return action.segment;
    default:
      return state;
  }
};

const spendSegment: Reducer<revenueSegmentTypes | null> = (
  state = (Cookies.get(TW_BENCHMARKS_SPEND_SEGMENT) as revenueSegmentTypes) || null,
  action,
) => {
  switch (action.type) {
    case BENCHMARKS_SPEND_SEGMENT_CHANGED:
      return action.segment;
    default:
      return state;
  }
};

const benchmarksErrorResponse: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case BENCHMARKS_GET_ERROR_RESPONSE:
      return action.error;
    default:
      return state;
  }
};

const effectiveIndustry: Reducer<IndustryTypes> = (state = '' as any, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.industry || state;
    case BENCHMARKS_INDUSTRY_RECEIVED:
      return action.industry || state;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  industry,
  shopIndustry,
  userRole,
  aovSegment,
  spendSegment,
  benchmarksErrorResponse,
  effectiveIndustry,
});
