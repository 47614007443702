import { $currentShopId } from '$stores/$shop';
import { $userId, $userShop } from '$stores/$user';
import {
  $customizePromptsPopup,
  $userShopCloseCustomizeMobyPopup,
  $userShopCustomMobyTags,
  closeCustomizePromptsPopup,
} from '$stores/willy/$customizePromptPopup';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { Anchor, Button, Icon, Modal, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useEffect, useState } from 'react';
import { userDb } from 'utils/DB';
import customizeStartImg from '../../images/customize_prompts_start.png';
import customizeEndImg from '../../images/customize_prompts_done.png';
import { getPromptCategories, getPromptTopics } from './utils/willyUtils';
import { PromptCategory } from './useSamplePrompts';
import { AlanLoaderGray } from 'components/AlanLoader';

export const CustomizeMobyPopup = () => {
  const { opened, isInitial } = useStoreValue($customizePromptsPopup);
  const isSmall = useIsSmall();
  const currentShopId = useStoreValue($currentShopId);
  const [userShopCustomMobyTags, setUserShopCustomMobyTags] =
    useWritableStore($userShopCustomMobyTags);
  const [userShopCloseCustomizeMobyPopup, setUserShopCloseCustomizeMobyPopup] = useWritableStore(
    $userShopCloseCustomizeMobyPopup,
  );
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [categories, setCategories] = useState<PromptCategory[]>([]);
  const user = useStoreValue($userId);

  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);

  const loadCategories = async () => {
    const categories = await getPromptCategories();
    const filteredCategories = categories.filter(
      (cat) => cat.topic !== 'TVu4b6Gh1Qe1U7KxKEio' && cat.topic !== 'xvGMgtx5VwasoORkDMFE',
    );
    setCategories(filteredCategories);
  };

  useEffect(() => {
    if (categories.length === 0 && user) {
      loadCategories();
    }
  }, [categories, user]);

  useEffect(() => {
    setSelectedTags(userShopCustomMobyTags);
  }, [userShopCustomMobyTags]);

  const toggleTopic = (topic: string) => {
    if (selectedTags.includes(topic)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== topic));
    } else {
      setSelectedTags([...selectedTags, topic]);
    }
  };

  const onClosePopup = async () => {
    closeCustomizePromptsPopup(true);
    setStep(1);
    if (isInitial) {
      const currentShopId = $currentShopId.get();
      if (currentShopId) {
        await userDb().set(
          { shops: { [currentShopId]: { customizeMobyPopupClosed: true } } },
          { merge: true },
        );
        setUserShopCloseCustomizeMobyPopup(true);
      }
    }
  };

  const saveCustomTags = async () => {
    if (currentShopId) {
      await userDb().set(
        { shops: { [currentShopId]: { customPromptTags: selectedTags } } },
        { merge: true },
      );
    }
    setUserShopCustomMobyTags(selectedTags);
    onClosePopup();
  };
  return (
    <Modal.Root
      opened={opened}
      onClose={() => {
        onClosePopup();
      }}
      size={isSmall ? '100%' : '592px'}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px]  gap-12">
              <Text fz={26} fw={700}>
                Meet Moby✨, your AI-powered team member
              </Text>
              <div className="flex flex-col gap-2">
                <Icon name="ai-chat-spark" size={20} color="two.4" />
                <Text fz={18} fw={600}>
                  Ask Away
                </Text>
                <Text fz={16}>
                  From industry trends to company-specific queries, there’s no wrong question for
                  Moby.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Icon name="prompts-library" size={20} color="purple.4" />
                <Text fz={18} fw={600}>
                  Get started with the prompt library
                </Text>
                <Text fz={16}>
                  You don’t need to be a prompt engineer. Moby comes with a prompt library loaded
                  with powerful use cases.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Icon name="star-plus" size={20} color="teal.4" />
                <Text fz={18} fw={600}>
                  Continuous learning
                </Text>
                <Text fz={16}>
                  Moby not only helps you learn more about your data, it also continually learns
                  from interactions, thereby enhancing its capability to deliver relevant and timely
                  business intelligence.
                </Text>
              </div>
              <div className="flex w-full justify-end">
                <Button
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px] gap-12">
              <div className="flex flex-col gap-2">
                <Text fz={26} fw={700}>
                  Customize Your Chat
                </Text>
                <Text>
                  Let's customize your chat to help us show you the most relevant content.
                </Text>
              </div>
              <Button
                onClick={() => {
                  setStep(3);
                }}
              >
                Customize
              </Button>
              <img src={customizeStartImg} alt="Customize Moby" />
              <Anchor onClick={() => setStep(1)}>
                <span className="flex gap-4 items-center">
                  <Icon name="chevron-left-minor" color="one.5" size={12} />
                  <Text fw={500} color="one.5">
                    Back
                  </Text>
                </span>
              </Anchor>
            </div>
          )}
          {step === 3 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px]  gap-12">
              <Text fz={26} fw={700}>
                What type of insights would be most helpful for you?
              </Text>
              <div className="flex flex-wrap gap-4">
                {categories.length === 0 && (
                  <div className="w-full flex items-center justify-center">
                    <AlanLoaderGray />
                  </div>
                )}
                {categories.map((cat) => (
                  <Button
                    key={cat.id}
                    size="xs"
                    onClick={() => toggleTopic(cat.id)}
                    variant={selectedTags.includes(cat.id) ? 'primary' : 'white'}
                  >
                    {cat.name}
                  </Button>
                ))}
              </div>
              <div className="flex justify-between pt-10">
                <Anchor onClick={() => setStep(2)}>
                  <span className="flex gap-4 items-center">
                    <Icon name="chevron-left-minor" color="one.5" size={12} />
                    <Text fw={500} color="one.5">
                      Back
                    </Text>
                  </span>
                </Anchor>
                <Anchor
                  onClick={() => {
                    selectedTags.length === 0 ? {} : setStep(4);
                  }}
                  underline={selectedTags.length === 0 ? 'never' : 'hover'}
                >
                  <span
                    className={`flex gap-4 items-center ${selectedTags.length === 0 ? 'cursor-default' : ''}`}
                  >
                    <Text fw={500} color={selectedTags.length === 0 ? 'gray.4' : 'one.5'}>
                      Next
                    </Text>
                    <Icon
                      name="chevron-right-minor"
                      color={selectedTags.length === 0 ? 'gray.4' : 'one.5'}
                      size={12}
                    />
                  </span>
                </Anchor>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px]  gap-12">
              <div className="flex flex-col gap-2">
                <Text fz={26} fw={700}>
                  Your “For you” category has been personalized based on your choices.
                </Text>
                <Text>You can edit your preferenced anytime by clicking “edit”.</Text>
              </div>
              <Button onClick={() => saveCustomTags()}>Save and Start Chatting</Button>
              <img src={customizeEndImg} alt="Customize Moby" />
              <Anchor onClick={() => setStep(3)} pt="lg">
                <span className="flex gap-4 items-center">
                  <Icon name="chevron-left-minor" color="one.5" size={12} />
                  <Text fw={500} color="one.5">
                    Back
                  </Text>
                </span>
              </Anchor>
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
