import { $derived } from '@tw/snipestate';
import { $pathname } from '$stores/$location';
import { PATHS_WITHOUT_DARKMODE } from '../constants';

/**
 * Some routes don't have dark mode - tracks if current route has dark mode
 */
export const $pathHasDarkMode = $derived((get) => {
  const pathname = get($pathname);
  return !PATHS_WITHOUT_DARKMODE.some((path) => pathname.includes(path));
});
