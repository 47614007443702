import { $createModal } from '$stores/willy/$createModal';
import { useWritableStore } from '@tw/snipestate';
import { Modal } from '@tw/ui-components';
import { useJustMounted } from 'hooks/useJustMounted';
import { useMutableQueryParams } from 'hooks/useMutableQueryParams';
import { useEffect } from 'react';
import { WillyTemplates } from 'components/Willy/dashboardManagment/WillyTemplates';

export function CreateDashboardModal() {
  const [createModal, setCreateModal] = useWritableStore($createModal);
  const [{ openCreateReportModal }, { set, remove }] = useMutableQueryParams();
  const justMounted = useJustMounted();

  useEffect(() => {
    if (openCreateReportModal === '1') setCreateModal({ opened: true });
  }, [openCreateReportModal, setCreateModal]);

  useEffect(() => {
    if (justMounted) return;

    if (createModal.opened) set('openCreateReportModal', '1');
    else remove('openCreateReportModal');
  }, [createModal.opened, set, remove, justMounted]);

  return (
    <Modal.Root
      size="2xl"
      centered
      opened={createModal.opened}
      onClose={() => setCreateModal({ opened: false })}
      p="0"
    >
      <Modal.Overlay />
      <Modal.Content mih={200}>
        <Modal.Body p="0">
          <WillyTemplates onlyInner />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
