import { ReactNode } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import './TWTooltip.scss';
interface Props {
  content?: ReactNode;
  children;
  maxWidth?: number;
}
export const TWTooltip: React.FC<Props> = ({ content, children, maxWidth = 175 }) => {
  return content ? (
    <Tooltip
      trigger={['hover']}
      mouseEnterDelay={1}
      placement="top"
      overlay={content}
      overlayClassName="tw-tooltip"
      overlayInnerStyle={{ maxWidth: `${maxWidth}px` }}
      align={{ offset: [0, -10] }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};
