import { Button, ButtonGroup, TextField } from '@shopify/polaris';
import GoogleSheetsAccountSelector from 'components/GoogleSheetsAccountSelector';
import { FC } from 'react';

interface IExportModalFormProps {
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  sheetsAccount?: string;
  setSheetsAccount?: React.Dispatch<React.SetStateAction<string>>;
  exportType: 'CSV' | 'Google Sheet';
  onSave: () => void;
  onClose: () => void;
  isLoading: boolean;
}
const ExportModalForm: FC<IExportModalFormProps> = ({
  exportType,
  fileName,
  setFileName,
  sheetsAccount = '',
  setSheetsAccount = () => {},
  onSave,
  onClose,
  isLoading,
}) => {
  const isGoogleSheet = exportType === 'Google Sheet';

  return (
    <div>
      <div className="pl-2 pb-4">
        <TextField
          clearButton
          label={`Export ${exportType} as:`}
          type="text"
          autoComplete="off"
          placeholder={`Title`}
          value={fileName}
          onChange={(value) => setFileName(value)}
          onClearButtonClick={() => setFileName('')}
        />
      </div>
      {isGoogleSheet && (
        <div className="pl-2 text-black -mt-12 -mb-6">
          <GoogleSheetsAccountSelector
            onChange={(v: string | null) => {
              if (v) {
                setSheetsAccount(v);
              }
            }}
            value={sheetsAccount}
            label={<></>}
          />
        </div>
      )}
      <div className="pb-4 pl-2">
        <ButtonGroup fullWidth>
          <Button id="att-product-analytics-ads-export-file-cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            id="att-product-analytics-ads-export-file-save"
            onClick={onSave}
            disabled={!fileName || fileName === '' || isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ExportModalForm;
