import { ActionIcon, Icon, IconName, Menu, Popover } from '@tw/ui-components';
import { FC, useMemo } from 'react';
import { ChartType } from '../types/willyTypes';
import { chartTypes } from '../constants';

type ChartTypesDropdownProps = {
  activator?: (icon: IconName) => React.ReactNode;
  chartType: ChartType;
  setChartType: (chartType: ChartType) => void;
};

export const ChartTypesDropdown: FC<ChartTypesDropdownProps> = ({
  chartType,
  setChartType,
  activator,
}) => {
  const chartTypeElement = useMemo(() => chartTypes[chartType], [chartType]);
  return (
    <Menu>
      <Menu.Target>
        {activator ? (
          activator(chartTypeElement.icon)
        ) : (
          <ActionIcon icon={chartTypeElement.icon} variant="transparent" />
        )}
      </Menu.Target>
      <Menu.Dropdown>
        {Object.values(chartTypes).map((type) => (
          <Menu.Item
            leftSection={<Icon name={type.icon} />}
            key={`item-${type.id}`}
            onClick={() => setChartType(type.id)}
            bg={type.id === chartType ? 'gray.2' : undefined}
          >
            {type.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
