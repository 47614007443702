import React from 'react';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { CDPWhaleGPTFilter, CDPSegmentFilter } from '@tw/types/module/services/cdp';
import { Text } from '@tw/ui-components';
import { formatSqlSafely } from 'components/Willy/utils/willyUtils';

type Props = {
  filter: CDPWhaleGPTFilter;
  onChanged: (filter: CDPSegmentFilter) => void;
};
const WhaleGPTFilter: React.FC<Props> = (props) => {
  const formatedQuery = formatSqlSafely(props.filter.definition.query);

  return (
    <React.Fragment>
      <Text weight="bold">“{props.filter.definition.question}”</Text>
      <div className="prose dark:prose-invert contents">
        <ReactSyntaxHighlighter language={'sql'} style={vscDarkPlus} showLineNumbers>
          {formatedQuery}
        </ReactSyntaxHighlighter>
      </div>
    </React.Fragment>
  );
};

export default WhaleGPTFilter;
