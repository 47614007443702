import React from 'react';
import Select, { GroupBase, Props } from 'react-select';

function PMSelectSettings<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <div>
      <Select
        {...props}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          dropdownIndicator: (provided, state) =>
            ({
              ...provided,
              transition: 'all .5s ease',
              transform: state.selectProps.menuIsOpen ? 'rotate(180dg)' : null,
            }) as any,
          option: (styles, state) => ({
            ...styles,
            height: '30px',
            backgroundColor: 'white',
            paddingTop: '5px',
            color: '#000',
            borderBottom: '1px solid #D3D3D3',
          }),

          control: (provided, state) => ({
            ...provided,
            backgroundColor: 'rgba(0,0,0,0)',
            height: '10px',
            border: '1px solid rgba(192, 214, 234, 0.5019607843)',
          }),
          indicatorSeparator: (state) => ({
            display: 'none',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            height: '30px',
            paddingTop: '5px',
            fill: '#1877f2',
          }),
          menuList: (base) => ({
            ...base,
            backgroundColor: '#white',
            borderColor: 'rgba(192, 214, 234, 0.5019607843)',
            borderWidth: '1px',
            borderRadius: '5px',
          }),
        }}
      />
    </div>
  );
}

export default PMSelectSettings;
