import { Image } from '@tw/ui-components';
import { useAppSelector } from 'reducers/RootType';

export type ShopIconProps = { width?: number };

export const ShopIcon: React.FC<ShopIconProps> = ({ width = 24 }) => {
  const shopIcon = useAppSelector((state) => state.shopIcon);

  if (!shopIcon) return <span></span>;

  // TODO: Figure out what to do about placeholders
  // return <Image src={shopIcon} withPlaceholder width={width} radius={4} />;
  return <Image src={shopIcon} width={width} radius={4} />;
};
