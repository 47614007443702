import { RuleActions } from '@tw/types';
import { MetricsKeys } from '@tw/types/module/metrics/metrics';
import {
  campaignStopLoss,
  campaignScaler,
  adsetStopLoss,
  adsetScaler,
  adStopLossAkaSinkingShip,
  adStopLossAkaLeakyBucket,
} from './templates';
export type EntityTypes = 'account' | 'campaign' | 'adset' | 'ad' | '';

export type MetricFields = {
  show: EntityTypes[];
  value: string;
  label: string;
  showCurrency?: boolean;
  showWarning?: boolean;
};
export type Status = 'active' | 'inactive';

export type RuleTime = { period?: number; unit?: 'day' | 'hour'; today?: boolean };

export type RuleExpression = {
  fact: EntityTypes;
  path?: `$.${MetricsKeys}` | '';
  operator: string;
  value:
    | string
    | number
    | {
        fact: EntityTypes | 'campaignBudget' | 'adsetBudget';
        path: string;
        params: RuleTime & { factor?: number; today?: boolean };
      };
  params?: RuleTime;
};

export type EditRuleExpression = RuleExpression & {
  formulaMode?: boolean;
  showError?: string | boolean;
  showMetricError?: string | boolean;
  showFormulaError?: string | boolean;
  showFormulaMetricError?: string | boolean;
  showFormulaTimeError?: string | boolean;
  showTimeError?: string | boolean;
  showOperatorError?: string | boolean;
  showValueError?: string | boolean;
  isFilter?: boolean;
};

export type RuleWrapperOp = 'any' | 'all';
export type Format = 'percentage' | 'dollars';

export type RuleEventParams = {
  value?: string;
  valueType?: Format;
  mode?: 'logOnly';
  level?: EntityTypes;
};

export type RuleActionType = { type: string; params?: RuleEventParams };

export type Rule = {
  _id?: Number;
  name: string;
  status: Status;
  level: EntityTypes;
  conditions: {
    all: Array<AtLeastOneKey<RuleWrapperOp, RuleExpression[]> & { priority?: number }>;
  };
  event: RuleActionType;
  description: string;
  isDeleted?: boolean;
  frequency?: string;
  runAt?: string;
  nextRun?: string;
  lastRun?: string;
  nextRunForClient?: string;
  daysOfWeek?: number[];
};

export type EditRule = Omit<Rule, 'conditions'> & {
  conditions: {
    all: Array<
      AtLeastOneKey<RuleWrapperOp, EditRuleExpression[]> & { priority?: number } & {
        hidden?: boolean;
      }
    >;
  };
} & { showNameError?: string };

export type Version = {
  rules: Rule[];
};

export interface StrategyType {
  shop: string;
  status: Status;
  _id?: Number;
  name: string;
  description: string;
  domain: 'shop' | 'global';
  createdBy?: string;
  updatedBy?: string;
  versions?: Version[];
  tw_rules?: string | { _id: string; [key: string]: any };
}

export type EditVersion = {
  rules: EditRule[];
};
export interface EditStrategy extends StrategyType {
  version?: EditVersion;
}

export interface StrategyPayload extends StrategyType {
  version: Version;
}

type OneKey<Keys extends string, Value> = Keys extends Keys ? { [k in Keys]: Value } : never;
export type AtLeastOneKey<Keys extends string, Value> = { [k in Keys]?: Value } & OneKey<
  Keys,
  Value
>;

export const ruleBudgetMetrics = {
  adsetBudget: {
    showInRules: ['adset'],
    type: 'ads',
    key: 'adsetBudget',
    label: 'Daily Budget',
    shortLabel: 'Daily Budget',
    allowOrderBy: false,
    showInPixelByDefault: false,
    showInCreativeByDefault: false,
    toFixed: 0,
    format: 'currency',
    showInCreativeTable: [],
    showInCreativeCard: [],
  },
  campaignBudget: {
    showInRules: ['campaign'],
    type: 'ads',
    key: 'campaignBudget',
    label: 'Daily Budget',
    shortLabel: 'Daily Budget',
    allowOrderBy: false,
    showInPixelByDefault: false,
    showInCreativeByDefault: false,
    toFixed: 0,
    format: 'currency',
    showInCreativeTable: [],
    showInCreativeCard: [],
  },
};

export const ruleLevels = [
  { value: 'campaign', label: 'Campaign' },
  { value: 'adset', label: 'Adset' },
  { value: 'ad', label: 'Ad' },
];

export const conditionsLevels = {
  account: { show: ['adset', 'campaign', 'account', 'ad'], value: 'account', label: 'Account' },
  campaign: { show: ['adset', 'campaign', 'ad'], value: 'campaign', label: 'Campaign' },
  adset: { show: ['adset', 'ad'], value: 'adset', label: 'Adset' },
  ad: { show: ['ad'], value: 'ad', label: 'Ad' },
};

export const rulesActionDropdown: Record<RuleActions, MetricFields> = {
  increase_budget: {
    show: ['campaign', 'adset'],
    value: 'increase_budget',
    label: 'Increase %entity% budget',
    showWarning: true /* show a warning for increase budget multiple times a day */,
  },
  decrease_budget: {
    show: ['campaign', 'adset'],
    value: 'decrease_budget',
    label: 'Decrease %entity% budget',
    showWarning: true /* show a warning for decrease budget multiple times a day */,
  },
  increase_bid_cap: {
    show: ['adset'],
    value: 'increase_bid_cap',
    label: 'Increase %entity% Bid Cap',
    showWarning: true /* show a warning for decrease budget multiple times a day */,
  },
  decrease_bid_cap: {
    show: ['adset'],
    value: 'decrease_bid_cap',
    label: 'Decrease %entity% Bid Cap',
    showWarning: true /* show a warning for decrease budget multiple times a day */,
  },
  increase_cost_per_goal: {
    show: ['adset'],
    value: 'increase_cost_per_goal',
    label: 'Increase %entity% Cost per Goal',
    showWarning: true /* show a warning for decrease budget multiple times a day */,
  },
  decrease_cost_per_goal: {
    show: ['adset'],
    value: 'decrease_cost_per_goal',
    label: 'Decrease %entity% Cost per Goal',
    showWarning: true /* show a warning for decrease budget multiple times a day */,
  },
  pause: {
    show: ['campaign', 'adset', 'ad'],
    value: 'pause',
    label: 'Pause %entity%',
  },
  start: {
    show: ['campaign', 'adset', 'ad'],
    value: 'start',
    label: 'Start %entity%',
  },
};

export const ruleOperator = [
  { value: 'equal', label: '=', text: 'equal' },
  { value: 'notEqual', label: '≠', text: 'not equal' },
  { value: 'greaterThan', label: '>', text: 'greather than' },
  { value: 'lessThan', label: '<', text: 'less than' },
  { value: 'greaterThanInclusive', label: '≥', text: 'greater than or equal to' },
  { value: 'lessThanInclusive', label: '≤', text: 'less than or equal to' },
];

export const ruleTimes: { [key: string]: { value: RuleTime; label: string } } = {
  Today: {
    value: {
      period: 0,
      unit: 'day',
      today: true,
    },
    label: 'Today',
  },
  Yesterday: {
    value: {
      period: 1,
      unit: 'day',
    },
    label: 'Yesterday',
  },
  'Today + Yesterday': {
    value: {
      period: 1,
      unit: 'day',
      today: true,
    },
    label: 'Today + Yesterday',
  },
  'Last 2 days': {
    value: {
      period: 2,
      unit: 'day',
    },
    label: 'Last 2 days',
  },
  'Last 2 days + Today': {
    value: {
      period: 2,
      unit: 'day',
      today: true,
    },
    label: 'Last 2 days + Today',
  },
  'Last 3 days': {
    value: {
      period: 3,
      unit: 'day',
    },
    label: 'Last 3 days',
  },
  'Last 3 days + Today': {
    value: {
      period: 3,
      unit: 'day',
      today: true,
    },
    label: 'Last 3 days + Today',
  },
  'Last 7 days': {
    value: {
      period: 7,
      unit: 'day',
    },
    label: 'Last 7 days',
  },
  'Last 7 days + Today': {
    value: {
      period: 7,
      unit: 'day',
      today: true,
    },
    label: 'Last 7 days + Today',
  },
  'Last 14 days': {
    value: {
      period: 14,
      unit: 'day',
    },
    label: 'Last 14 days',
  },
  'Last 14 days + Today': {
    value: {
      period: 14,
      unit: 'day',
      today: true,
    },
    label: 'Last 14 days + Today',
  },
  'Last 30 days': {
    value: {
      period: 30,
      unit: 'day',
    },
    label: 'Last 30 days',
  },
  'Last 30 days + Today': {
    value: {
      period: 30,
      unit: 'day',
      today: true,
    },
    label: 'Last 30 days + Today',
  },
  'Last 60 days': {
    value: {
      period: 60,
      unit: 'day',
    },
    label: 'Last 60 days',
  },
  'Last 60 days + Today': {
    value: {
      period: 60,
      unit: 'day',
      today: true,
    },
    label: 'Last 60 days + Today',
  },
  'Last 90 days': {
    value: {
      period: 90,
      unit: 'day',
    },
    label: 'Last 90 days',
  },
  'Last 90 days + Today': {
    value: {
      period: 90,
      unit: 'day',
      today: true,
    },
    label: 'Last 90 days + Today',
  },
};

export const templatesRule = {
  'Campaign Stop Loss (aka Sinking Ship)': {
    value: campaignStopLoss,
    label: 'Campaign Stop Loss (aka Sinking Ship)',
  },
  'Campaign Scaler (aka Set Sail)': {
    label: 'Campaign Scaler (aka Set Sail)',
    value: campaignScaler,
  },
  'Adset Stop Loss (aka Sinking Ship)': {
    label: 'Adset Stop Loss (aka Sinking Ship)',
    value: adsetStopLoss,
  },
  'Adset Scaler (aka Set Sail)': {
    label: 'Adset Scaler (aka Set Sail)',
    value: adsetScaler,
  },
  'Ad Stop Loss (aka Sinking Ship)': {
    label: 'Ad Stop Loss (aka Sinking Ship)',
    value: adStopLossAkaSinkingShip,
  },
  'Ad Stop Loss (aka Leaky Bucket)': {
    label: 'Ad Stop Loss (aka Leaky Bucket)',
    value: adStopLossAkaLeakyBucket,
  },
};

// clone it

export const scheduleOptions = [
  { value: '15 minutes', label: 'Once every 15 minutes', showWarning: true },
  { value: '30 minutes', label: 'Once every half hour', showWarning: true },
  { value: '1 hours', label: 'Once every hour', showWarning: true },
  { value: '12 hours', label: 'Once every 12 hours', showWarning: true },
  { value: '1 days', label: 'Once per day' },
];

export const runTimeOptions = [
  { value: '01:00', label: '1:00 AM' },
  { value: '02:00', label: '2:00 AM' },
  { value: '03:00', label: '3:00 AM' },
  { value: '04:00', label: '4:00 AM' },
  { value: '05:00', label: '5:00 AM' },
  { value: '06:00', label: '6:00 AM' },
  { value: '07:00', label: '7:00 AM' },
  { value: '08:00', label: '8:00 AM' },
  { value: '09:00', label: '9:00 AM' },
  { value: '10:00', label: '10:00 AM' },
  { value: '11:00', label: '11:00 AM' },
  { value: '12:00', label: '12:00 PM' },
  { value: '13:00', label: '1:00 PM' },
  { value: '14:00', label: '2:00 PM' },
  { value: '15:00', label: '3:00 PM' },
  { value: '16:00', label: '4:00 PM' },
  { value: '17:00', label: '5:00 PM' },
  { value: '18:00', label: '6:00 PM' },
  { value: '19:00', label: '7:00 PM' },
  { value: '20:00', label: '8:00 PM' },
  { value: '21:00', label: '9:00 PM' },
  { value: '22:00', label: '10:00 PM' },
  { value: '23:00', label: '11:00 PM' },
  { value: '00:00', label: '12:00 AM' },
];

export const filterAttributes = [
  { value: 'campaign.name', label: 'Campaign Name', show: ['campaign', 'adset', 'ad'] },
  { value: 'campaign.id', label: 'Campaign Id', show: ['campaign', 'adset', 'ad'] },
  { value: 'adset.name', label: 'Adset Name', show: ['adset', 'ad'] },
  { value: 'adset.id', label: 'Adset Id', show: ['adset', 'ad'] },
  { value: 'ad.name', label: 'Ad Name', show: ['ad'] },
  { value: 'ad.id', label: 'Ad Id', show: ['ad'] },
];

export const filterOperators = [
  { value: 'includes', label: 'Contains' },
  { value: 'notIncludes', label: 'Not Contains' },
  { value: 'equal', label: '=' },
  { value: 'notEqual', label: '≠' },
];

export const defaultConditions: EditRuleExpression = {
  fact: '',
  path: '',
  params: {
    period: 0,
    unit: 'day',
  },
  operator: '',
  value: '',
};

export const PRELIMINARY_PRIORITY = 100;

export const weekDays = [
  { value: '-1', label: 'Everyday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '0', label: 'Sunday' },
];
