import React from 'react';
import { NewLoader } from 'components/loaders/NewLoader';

export const FallbackComponent: React.FC = () => {
  return (
    <div
      style={{
        position: 'absolute',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <NewLoader />
    </div>
  );
};
