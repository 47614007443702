import './ActivityFeed.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, FormLayout, TextField, DatePicker, Popover, Label, Button } from '@shopify/polaris';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { closeCreateActivityModal, saveNewActivity, deleteActivity } from 'ducks/activityFeed';
import SERVICES from 'constants/services';
import { ServicesIdsArray } from '@tw/types/module/services';
import moment from '@tw/moment-cached';
import { CalendarMinor } from '@shopify/polaris-icons';
import TimePicker from 'components/TimePicker';
import DropDown from 'components/ltv/DropDown';
import { HIDDEN_SERVICES_IN_ACTIVITY_FEED } from './ActivitiesPortal/MetricsList';
import { selectConnectedServices } from 'utils/selectors';

export const NewActivityModal = () => {
  const { showCreateActivityModal } = useSelector((state: RootState) => state.activityFeed);
  const { currentCustomActivity: activity } = useSelector((state: RootState) => state.activityFeed);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [title, setTitle] = useState<string>(activity?.['title'] ?? '');
  const [description, setDescription] = useState<string>(activity?.['description'] ?? '');
  const [platform, setPlatform] = useState<string>(activity?.['serviceId'] ?? 'na');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const now = moment();
  const [month, setMonth] = useState(now.month());
  const [year, setYear] = useState(now.year());
  const [selectedDate, setSelectedDate] = useState({
    start: activity?.date?.toDate() ?? new Date(),
    end: new Date(),
  });
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.userEmail);
  const connectedServices = useSelector(selectConnectedServices);

  const submit = async () => {
    const platformToSubmit = platform === 'na' ? 'triple-whale' : platform;
    dispatch(
      saveNewActivity({
        platform: platformToSubmit,
        title,
        description: description,
        annotation: true,
        date: moment(selectedDate.start),
        author: user,
      }),
    );
    if (activity.id) {
      dispatch(deleteActivity(activity, currentShopId, false));
    }
    dispatch(closeCreateActivityModal());
  };

  const services: any[] = useMemo(() => {
    return ServicesIdsArray.filter((service) => !HIDDEN_SERVICES_IN_ACTIVITY_FEED.includes(service))
      .filter((service) => connectedServices[service])
      .filter((service) => SERVICES[service]?.name)
      .map((service) => ({
        value: service,
        label: (
          <div className="flex items-center gap-2">
            {<div>{SERVICES[service]?.icon?.({ small: true })}</div>}
            <div>{SERVICES[service]?.name || service}</div>
          </div>
        ),
      }));
  }, [connectedServices]);

  const handleSelectChange = useCallback((value) => setPlatform(value), []);

  const handleDateChange = (val) => {
    const chosenDate = moment()
      .year(val.start.getFullYear())
      .month(val.start.getMonth())
      .date(val.start.getDate())
      .startOf('day');
    const dateString = chosenDate.format('YYYY-MM-DD');
    setSelectedDate({
      start: moment(dateString).toDate(),
      end: new Date(dateString),
    });
    toggleDatePicker();
  };

  const handleTimeChange = (val) => {
    const timeString = moment(val).format('YYYY-MM-DD HH:mm:ss');
    console.log('handle date', moment(timeString).format('YYYY-MM-DD HH:mm:ss'));
    setSelectedDate({
      start: moment(val).toDate(),
      end: new Date(`${timeString}`),
    });
  };

  const toggleDatePicker = useCallback(
    () => setDatePickerOpen((datePickerOpen) => !datePickerOpen),
    [],
  );

  const activator = (
    <>
      <Label id="datePicker">
        <span>Date</span>
      </Label>
      <Button onClick={toggleDatePicker} icon={CalendarMinor}>
        {moment(selectedDate.start)
          // .year(selectedDate.start.getFullYear())
          // .month(selectedDate.start.getMonth())
          // .date(selectedDate.start.getDate())
          // .minute(selectedDate.start.getMinutes())
          // .hour(selectedDate.start.getHours())
          // .second(selectedDate.start.getSeconds())
          .format('MM/DD/YY HH:mm')}
      </Button>
    </>
  );

  return (
    <>
      <Modal
        open={showCreateActivityModal}
        onClose={() => dispatch(closeCreateActivityModal())}
        title={activity?.id ? 'Edit Activity' : 'Create Activity'}
        primaryAction={{
          onAction: submit,
          disabled: !title?.trim(),
          content: activity?.id ? 'Edit Activity' : 'Create Activity',
        }}
      >
        <Modal.Section>
          <FormLayout>
            <div className="flex items-center gap-4 create-new-activity-modal">
              <div className="w-fit">
                <span>Platform</span>
                <DropDown
                  options={[{ value: 'na', label: 'Other' }, ...services]}
                  value={platform}
                  handleSelect={handleSelectChange}
                />
              </div>
              <Popover active={datePickerOpen} activator={activator} onClose={toggleDatePicker}>
                <div className="flex flex-col">
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={handleDateChange}
                    onMonthChange={(month, year) => {
                      setMonth(month);
                      setYear(year);
                    }}
                    disableDatesAfter={moment(now).toDate()}
                    selected={selectedDate}
                  />
                  <div className="flex justify-center new-activity-time-picker">
                    <TimePicker date={selectedDate.start} onChange={handleTimeChange} />
                  </div>
                  <div className="flex justify-center text-sm mb-4">{shopTimezone}</div>
                </div>
              </Popover>
            </div>
            <TextField
              label="Title"
              value={title}
              onChange={setTitle}
              autoComplete="off"
              requiredIndicator
            />
            <TextField
              label="Description"
              value={description}
              onChange={setDescription}
              autoComplete="off"
              multiline={3}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
    </>
  );
};
