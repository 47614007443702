import { NavSection, NavSectionLinkProps, NavSectionProps } from '@tw/ui-components';
import { $navOpen, $openNavSection } from 'components/Nav/stores';
import { NavSection as ClientNavSection } from 'constants/routes/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { NavLink } from 'react-router-dom';
import { useComputedValue } from '@tw/snipestate';

export type ExpandedLinkProps = Pick<NavSectionProps, 'title' | 'icon'> & {
  routes: ClientNavSection['routes'];
};

export const ExpandedLink = (props: ExpandedLinkProps) => {
  const { pathname, search } = useLocation();
  const isOpen = useComputedValue($openNavSection, (s) => s === props.title);

  const routes = useMemo<NavSectionLinkProps[]>(() => {
    return props.routes.map((r) => {
      const isActive = pathname.includes(r.url);

      return {
        as: (props) => (
          <NavLink to={r.url + search} {...props} style={{ ...props.style, fontSize: 14 }} />
        ),
        label: r.label,
        isActive,
        onClick: () => {
          if (isActive) return;
          if (windowSize.isSmall) $navOpen.set(false);
        },
      };
    });
  }, [props.routes, pathname, search]);

  // to keep track of open/close state of section so that when
  // a user goes back from narrow to wide mode after having opened
  // some section, the section stays open.
  const handleClick = () => {
    $openNavSection.set((x) => {
      if (x === props.title) return ''; // close - no section is open
      return props.title ?? x;
    });
  };

  return (
    <NavSection
      title={props.title}
      icon={props.icon}
      routes={routes}
      isOpen={isOpen}
      onClick={handleClick}
    />
  );
};
