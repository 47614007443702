import './InfluencerCard.scss';

import TWImage from 'components/library/TWImage/TWImage';
import { metrics } from 'constants/metrics/metrics';
import allServices from 'constants/services';
import { ReactComponent as InfluencersIconSVG } from 'icons/affluencer-hub.svg';
import React, { FC, useCallback, useContext, useState } from 'react';
import { MAX_ACTIVE_INFLUENCERS, SelectableInfluencer } from 'types/Influencers';
import copyToClipboard from 'utils/copyToClipboard';
import { formatNumber } from 'utils/formatNumber';
import { filterCreatives } from 'utils/influencers';

import { Card, Stack, Tabs, Tooltip, Checkbox } from '@shopify/polaris';
import { DeleteMinor, PlayCircleMajor } from '@shopify/polaris-icons';

import { ReactComponent as Edit } from '../../../icons/influencer_edit.svg';
import { ReactComponent as Link } from '../../../icons/link.svg';
import { DISABLE_SHOW_IN_CHART_MESSAGE } from '../constants';
import { InfluencersContext } from '../context';
import { columns } from '../InfluencersTable/columns';
import { TabDescriptor } from '@shopify/polaris/build/ts/latest/src/components/Tabs/types';
import { Text } from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import { MetricsKeys } from '@tw/types';

interface InfluencerCardProps {
  influencer: SelectableInfluencer;
}

const InfluencerCard: FC<InfluencerCardProps> = ({ influencer }) => {
  const doDarkMode = useDarkMode();
  const {
    copiedToClipboard,
    selectedInfluencers,
    toggleSelected,
    editInfluencer,
    deleteInfluencer,
    toggleCopiedToClipboard,
    setCopiedToClipboard,
    showLightbox,
    selectedColumns,
    currency,
  } = useContext(InfluencersContext);
  const SourceIcon =
    influencer.source && allServices[influencer.source]
      ? allServices[influencer.source]?.icon
      : InfluencersIconSVG;

  const tabs: TabDescriptor[] = [
    {
      id: 'all',
      content: 'All',
    },
    { id: 'photo', content: 'Image' },
    { id: 'video', content: 'Video' },
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  }, []);

  const getFilteredCreatives = () => {
    const currentTab = tabs.find((tab, index) => index === selectedTab);
    const filteredCreatives = filterCreatives(influencer.creatives, currentTab!.id);
    const hasMore = filteredCreatives.length > 5;
    const moreElem = hasMore ? (
      <span className=" font-bold text-white text-3xl cursor-pointer">
        +{filteredCreatives.length - 5}
      </span>
    ) : (
      <></>
    );
    return filteredCreatives.slice(0, 5).map((creative, i) => {
      return (
        <TWImage
          key={i}
          className="w-full h-full object-cover rounded-md cursor-pointer"
          wrapperClass="w-32 h-32"
          src={creative.thumbnail || creative.url}
          onClick={() => showLightbox!(filteredCreatives, i)}
          customImgStyle={i === 4 && hasMore ? { filter: 'brightness(0.7)' } : {}}
          icon={
            creative.type === 'video' ? (
              <div className="fill-white cursor-pointer flex gap-2">
                <PlayCircleMajor className="text-light-blue" width={24} height={24} />
                {i === 4 ? moreElem : <></>}{' '}
              </div>
            ) : i === 4 ? (
              moreElem
            ) : (
              <></>
            )
          }
        />
      );
    });
  };

  const showInChartCheckbox = (
    <Checkbox
      label="Show In Graph"
      checked={influencer.selected}
      onChange={() => toggleSelected!(influencer)}
    />
  );

  const influencerMetrics = influencer.metrics ? influencer.metrics : influencer;
  return (
    <>
      <div className={`InfluencerCard group ${influencer.id}`}>
        <Card>
          <Card.Section>
            <div>
              <div>
                <div className="relative">
                  <div className="flex justify-between items-center absolute z-10 w-full p-6.5">
                    <div className="flex justify-end opacity-0 group-hover:opacity-100 transition-opacity bg-white/50 rounded items-center px-2 py-1">
                      <div className="flex gap-4 items-center">
                        <div className="flex items-center">
                          <Tooltip content={copiedToClipboard ? 'Copied!' : 'Copy to Clipboard'}>
                            <div className="link flex items-center">
                              <Link
                                className="cursor-pointer"
                                onMouseLeave={() => setCopiedToClipboard!(false)}
                                onClick={() => {
                                  copyToClipboard(influencer.tracking_url);
                                  toggleCopiedToClipboard!();
                                }}
                              ></Link>
                            </div>
                          </Tooltip>
                        </div>
                        <div className="flex items-center">
                          <Edit
                            className="cursor-pointer"
                            onClick={() => editInfluencer!(influencer)}
                          />
                        </div>
                        <div className="flex items-center">
                          <DeleteMinor
                            width={18}
                            height={18}
                            className="cursor-pointer"
                            onClick={() => {
                              const res = window.confirm(
                                `Are you sure you want to delete ${influencer.name}?`,
                              );
                              if (res) {
                                deleteInfluencer!(influencer);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`checkboxWrapper`}>
                      {!influencer.selected &&
                        (selectedInfluencers?.length || 0) >= MAX_ACTIVE_INFLUENCERS && (
                          <>
                            <Tooltip content={DISABLE_SHOW_IN_CHART_MESSAGE}>
                              {showInChartCheckbox}
                            </Tooltip>
                          </>
                        )}
                      {(influencer.selected ||
                        (selectedInfluencers?.length || 0) < MAX_ACTIVE_INFLUENCERS) && (
                        <>{showInChartCheckbox}</>
                      )}
                    </div>
                  </div>
                  <TWImage
                    className="w-full h-full rounded object-cover"
                    wrapperClass="h-100"
                    src={influencer.profile_photo ? influencer.profile_photo.url : ''}
                    alt={influencer.name}
                  ></TWImage>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center gap-4 pt-6.5">
                  <Text weight={500}>{influencer.name}</Text>
                  <div className="flex items-center">
                    {SourceIcon && <SourceIcon width={18} height={18} />}
                  </div>
                </div>
              </div>
            </div>
          </Card.Section>
          <Card.Section>
            <div
              className="flex flex-col gap-2 p-4 rounded"
              style={{ backgroundColor: doDarkMode ? '#222935' : '#c1c1c11a' }}
            >
              <div
                className={`metric green flex flex-wrap justify-between text-2xl font-semibold px-2 py-1`}
                style={{ backgroundColor: `rgba(16, 171, 164, ${doDarkMode ? '0.2' : '0.1'})` }}
              >
                <div className="metric-title">
                  <Text weight={500}>ROAS</Text>
                </div>
                <Text>
                  {formatNumber(influencerMetrics?.pixelRoas || 0, {
                    style: 'decimal',
                    currency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </div>
              <div>
                <div
                  className={`metric flex flex-wrap justify-between text-2xl font-semibold px-2 py-1 rounded-md`}
                  style={{ backgroundColor: doDarkMode ? '#2E3746' : 'white' }}
                >
                  <div className="metric-title">
                    <Text weight={500}>Affluencer Spend</Text>
                  </div>
                  <Text>
                    {formatNumber(influencerMetrics.spend || 0, {
                      style: metrics.spend?.format || 'currency',
                      currency,
                      maximumFractionDigits: metrics.spend?.toFixed,
                      minimumFractionDigits: metrics.spend?.toFixed,
                    })}
                  </Text>
                </div>
              </div>
            </div>
          </Card.Section>
          <Card.Section>
            <div className="tw-tabs">
              <Tabs tabs={tabs!} selected={selectedTab!} onSelect={handleTabChange}></Tabs>
            </div>
          </Card.Section>
          <Card.Section>
            <div className="creatives">
              <div className="flex items-center gap-6.5 flex-wrap justify-start">
                {influencer.creatives && getFilteredCreatives()}
                <div
                  className="w-32 h-32 bg-logo/5 text-light-primary font-medium flex flex-col items-center justify-center cursor-pointer"
                  onClick={() => editInfluencer!(influencer)}
                >
                  <Text size="lg">+</Text>
                  <Text align="center">Add Content</Text>
                </div>
              </div>
            </div>
          </Card.Section>
          <Card.Section>
            <Stack vertical>
              {Object.values(metrics)
                .filter((m) => m.showInInfluencersHub)
                .filter((m) => selectedColumns?.map((c) => c.key).includes(m.key))
                .map((metric) => {
                  const metricValue =
                    influencerMetrics && influencerMetrics?.[metric.key]
                      ? influencerMetrics![metric.key] || 0
                      : 0;
                  return (
                    <Stack.Item key={`${metric.key}_${metric.label}`}>
                      <Stack>
                        <Stack.Item fill>
                          <Text weight={500}>{metric.label}</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text>
                            {metric.format
                              ? formatNumber(metricValue, {
                                  style: metric.format,
                                  currency,
                                  maximumFractionDigits: metric.toFixed,
                                  minimumFractionDigits: metric.toFixed,
                                })
                              : metricValue}
                          </Text>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                  );
                })}
              {columns
                .filter((col) => ['discount_code'].includes(col.key))
                .map((column) => {
                  return (
                    <Stack.Item key={column.key}>
                      <Stack>
                        <Stack.Item fill>{column.Heading()}</Stack.Item>
                        <Stack.Item>{column.Value(influencer)}</Stack.Item>
                      </Stack>
                    </Stack.Item>
                  );
                })}
            </Stack>
          </Card.Section>
        </Card>
      </div>
    </>
  );
};

export default InfluencerCard;
