import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { DatePickerPreviousOptionValue, DatePickerPreviousPeriodOption } from 'types/general';
import { getPrevDates } from 'utils/getPreviousDate';
import { $store, useStoreValue, useWritableStore } from '@tw/snipestate';
import { Moment } from '@tw/moment-cached';
import { $currentDateRange, $prevDateRange, PrevDateRange } from '$stores/willy/$dateRange';

export const $compareOptions = $store<DatePickerPreviousPeriodOption[]>([]);

export type CompareOptionsDictionary = {
  [key in PreviousPeriodIds]: {
    id: key;
    label: string;
  };
};

export const compareOptions: CompareOptionsDictionary = {
  none: {
    id: 'none',
    label: 'None',
  },
  default: {
    id: 'default',
    label: 'Previous period',
  },
  previousPeriod: {
    id: 'previousPeriod',
    label: 'Previous period',
  },
  previousWeek: {
    id: 'previousWeek',
    label: 'Previous week',
  },
  previousMonth: {
    id: 'previousMonth',
    label: 'Previous month',
  },
  previousQuarter: {
    id: 'previousQuarter',
    label: 'Previous quarter',
  },
  previousYear: {
    id: 'previousYear',
    label: 'Previous year',
  },
  custom: {
    id: 'custom',
    label: 'Custom',
  },
};

export const getDatePickerCompareOptionsValue = (currentDateRange: {
  start: Moment;
  end: Moment;
}): Record<PreviousPeriodIds, DatePickerPreviousOptionValue> => {
  return {
    [compareOptions.none.id]: {
      start: moment(),
      end: moment(),
      id: compareOptions.none.id,
      label: compareOptions.none.label,
    },
    [compareOptions.previousPeriod.id]: {
      start: getPrevDates(currentDateRange).start,
      end: getPrevDates(currentDateRange).end,
      id: compareOptions.previousPeriod.id,
      label: compareOptions.previousPeriod.label,
    },
    [compareOptions.default.id]: {
      start: getPrevDates(currentDateRange).start,
      end: getPrevDates(currentDateRange).end,
      id: compareOptions.default.id,
      label: compareOptions.default.label,
    },
    [compareOptions.previousWeek.id]: {
      start: getPrevDates(currentDateRange, 'week').start,
      end: getPrevDates(currentDateRange, 'week').end,
      id: compareOptions.previousWeek.id,
      label: compareOptions.previousWeek.label,
    },
    [compareOptions.previousMonth.id]: {
      start: getPrevDates(currentDateRange, 'month').start,
      end: getPrevDates(currentDateRange, 'month').end,
      id: compareOptions.previousMonth.id,
      label: compareOptions.previousMonth.label,
    },
    [compareOptions.previousQuarter.id]: {
      start: getPrevDates(currentDateRange, 'quarter').start,
      end: getPrevDates(currentDateRange, 'quarter').end,
      id: compareOptions.previousQuarter.id,
      label: compareOptions.previousQuarter.label,
    },
    [compareOptions.previousYear.id]: {
      start: getPrevDates(currentDateRange, 'year').start,
      end: getPrevDates(currentDateRange, 'year').end,
      id: compareOptions.previousYear.id,
      label: compareOptions.previousYear.label,
    },
    [compareOptions.custom.id]: {
      start: getPrevDates(currentDateRange).start,
      end: getPrevDates(currentDateRange).end,
      id: compareOptions.custom.id,
      label: compareOptions.custom.label,
    },
  };
};

export const buildDatePickerCompareOptions = (
  currentDateRange: { start: Moment; end: Moment },
  prevDateRange?: PrevDateRange,
): DatePickerPreviousPeriodOption[] => {
  const values = getDatePickerCompareOptionsValue(currentDateRange);
  return [
    {
      ...values[compareOptions.none.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.none.label}</span>
        </div>
      ),
    },
    {
      ...values[compareOptions.default.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.previousPeriod.label}</span>
          <span className="text-secondary-text text-xl">
            {getPrevDates(currentDateRange).start.format('MMM D')} -{' '}
            {getPrevDates(currentDateRange).end.format('MMM D')}
          </span>
        </div>
      ),
    },
    {
      ...values[compareOptions.previousWeek.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.previousWeek.label}</span>
          <span className="text-secondary-text text-xl">
            {getPrevDates(currentDateRange, 'week').start.format('MMM D')} -{' '}
            {getPrevDates(currentDateRange, 'week').end.format('MMM D')}
          </span>
        </div>
      ),
    },
    {
      ...values[compareOptions.previousMonth.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.previousMonth.label}</span>
          <span className="text-secondary-text text-xl">
            {getPrevDates(currentDateRange, 'month').start.format('MMM D')} -{' '}
            {getPrevDates(currentDateRange, 'month').end.format('MMM D')}
          </span>
        </div>
      ),
    },
    {
      ...values[compareOptions.previousQuarter.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.previousQuarter.label}</span>
          <span className="text-secondary-text text-xl">
            {getPrevDates(currentDateRange, 'quarter').start.format('MMM D')} -{' '}
            {getPrevDates(currentDateRange, 'quarter').end.format('MMM D')}
          </span>
        </div>
      ),
    },
    {
      ...values[compareOptions.previousYear.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.previousYear.label}</span>
          <span className="text-secondary-text text-xl">
            {getPrevDates(currentDateRange, 'year').start.format('MMM D, YYYY')} -{' '}
            {getPrevDates(currentDateRange, 'year').end.format('MMM D, YYYY')}
          </span>
        </div>
      ),
    },
    {
      ...values[compareOptions.custom.id],
      labelElement: (
        <div className="flex gap-4 items-center">
          <span>{compareOptions.custom.label}</span>
          {prevDateRange && (
            <span className="text-secondary-text text-xl">
              {prevDateRange.start.format('MMM D') + ' - ' + prevDateRange.end.format('MMM D')}
            </span>
          )}
        </div>
      ),
    },
  ];
};
export const useDatePickerCompareOptions = () => {
  const currentDateRange = useStoreValue($currentDateRange);
  const prevDateRange = useStoreValue($prevDateRange);
  const [compareOptions, setCompareOptions] = useWritableStore($compareOptions);

  useEffect(() => {
    if (!currentDateRange) {
      setCompareOptions([]);
      return;
    }
    const { start, end } = currentDateRange || {};
    const momentObj = { start, end };
    const { start: prevStart, end: prevEnd } = prevDateRange || {};

    const options: DatePickerPreviousPeriodOption[] = buildDatePickerCompareOptions(momentObj, {
      start: moment(prevStart),
      end: moment(prevEnd),
    });

    setCompareOptions(options);
  }, [currentDateRange, prevDateRange, setCompareOptions]);

  return compareOptions;
};
