import { toggleSaveIndicator } from './actions';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';

import { Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

export function slackDisconnect() {
  return (dispatch) => {
    toggleSaveIndicator();
    const url = `v2/summary-page/shops/remove-serviceless-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
        serviceId: 'slack',
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('slackAccessToken'));
        dispatch(slackDisconnectDone());
      });
  };
}

export const SLACK_DISCONNECT_DONE = 'SLACK_DISCONNECT_DONE';

export function slackDisconnectDone() {
  return {
    type: SLACK_DISCONNECT_DONE,
  };
}

export const SLACK_CHANNELS_RECEIVED = 'SLACK_CHANNELS_RECEIVED';
export const SLACK_CURRENT_CHANNEL_RECEIVED = 'SLACK_CURRENT_CHANNEL_RECEIVED';

const isSlackConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.slackAccessToken;
    case SLACK_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'SLACK' || state;
    default:
      return state;
  }
};

export const getAllSlackChannels = () => {
  return async (dispatch, getState) => {
    const { slackChannels } = getState();
    let channels = [];
    if (getState().isSlackConnected && slackChannels.length === 0) {
      ({ data: channels } = await axiosInstance.get(
        `v2/slack/getAllSlackChannels?shopId=${getState().currentShopId}`,
      ));
      dispatch({
        type: SLACK_CHANNELS_RECEIVED,
        channels,
      });
    }
  };
};

export const getSlackChannel = (remoteData) => {
  return {
    type: SLACK_CURRENT_CHANNEL_RECEIVED,
    channelId: remoteData.slackChannelId,
  };
};

export const slackConnectOnPress = () => {
  try {
    const url = `v2/slack/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(` ERROR `, e);
  }
};

export const joinNewChannel = (channelId) => {
  return async (dispatch, getState) => {
    if (getState().isSlackConnected) {
      await axiosInstance.post(`v2/slack/joinNewChannel`, {
        channelId,
        shopId: getState().currentShopId,
      });
    }
  };
};

const slackChannels: Reducer<{ id: string; name: string }[]> = (state = [], action) => {
  switch (action.type) {
    case SLACK_CHANNELS_RECEIVED:
      return action.channels || state;
    default:
      return state;
  }
};

const currentSlackChannel = (state = '', action) => {
  switch (action.type) {
    case SLACK_CURRENT_CHANNEL_RECEIVED:
      return action.channelId || state;
    default:
      return state;
  }
};

export const reducers = {
  isSlackConnected,
  slackChannels,
  currentSlackChannel,
  joinNewChannel,
};
