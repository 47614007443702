import { useCallback, useState } from 'react';

/**
 * @description Escape hatch when all else fails to force a component to rerender
 * @returns A `key` to attach to the component you want to force to rerender and
 * a callback `forceRerender` to trigger the rerender.
 */
export function useForceRerender() {
  const [key, setKey] = useState(1);

  const forceRerender = useCallback(() => {
    setKey((x) => ++x % Number.MAX_SAFE_INTEGER);
  }, []);

  return [key, forceRerender] as const;
}
