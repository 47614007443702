import { useEffect, useState } from 'react';
import { Button, Card, Modal, Stack } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import 'firebase/compat/firestore';
import type { RootState } from 'reducers/RootType';

import { useAppDispatch } from 'index';
import { useQueryParams } from 'utils/useQueryParams';
import {
  onToggleIntegrationConnectionErrorMsgClose,
  onToggleIntegrationConnectionErrorMsgOpen,
} from 'ducks/shopServices';
import { googleAdsConnectOnPress } from 'ducks/googleAds';
import { useNavigate } from 'react-router-dom';

export const IntegrationConnectedErrorMsg = () => {
  const dispatch = useAppDispatch();
  const [integrationConnectionButtonLoading, setIntegrationConnectionButtonLoading] =
    useState<boolean>(false);
  const isIntegrationConnectionErrorMsgOpen = useSelector(
    (state: RootState) => state.shopServices.isIntegrationConnectionErrorMsgOpen,
  );
  const { integrationConnected, ['service-id']: serviceId, reason } = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (integrationConnected === 'false' && reason === 'missingScope' && serviceId === 'google-ads')
      dispatch(onToggleIntegrationConnectionErrorMsgOpen());
  }, [dispatch, integrationConnected, serviceId]);

  return (
    <Modal
      open={isIntegrationConnectionErrorMsgOpen}
      title={'Additional Permissions Required'}
      onClose={() => {
        navigate({
          pathname: window.location.pathname,
          search: '',
        });
        dispatch(onToggleIntegrationConnectionErrorMsgClose());
      }}
    >
      {/* {serviceId === 'google-ads' && ( */}
      <Card.Section>
        <div className="Polaris-TextContainer">
          <p>It seems we are missing permissions in order to connect to Google Ads.</p>
          <p>To ensure seamless integration with Triple Whale, please follow these steps:</p>
          <ol>
            <li>Revisit the Google access prompt as shown in the image below.</li>
            <li>
              Ensure you check the box next to "See, edit, create, and delete your Google Ads
              accounts and data."
            </li>
            <li>Once checked, please proceed to authorize access again.</li>
          </ol>
          <div className="w-full flex justify-center items-center">
            <img src="/access-prompt.png" alt="Google Access Prompt" width="300" />
          </div>
          <p>
            By selecting this option, you grant Triple Whale the necessary permissions to manage and
            integrate with your Google Ads effectively.
          </p>
        </div>
        <br />
        <Stack distribution="trailing" alignment="center">
          <Button
            loading={integrationConnectionButtonLoading}
            primary
            onClick={() => {
              setIntegrationConnectionButtonLoading(true);
              googleAdsConnectOnPress();
            }}
          >
            Reconnect
          </Button>
        </Stack>
      </Card.Section>
      {/* )} */}
    </Modal>
  );
};
