import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'reducers/RootType';
import { boomDataLayer, dataLayerPageView } from 'utils/dataLayer';

/**
 * @description This hook takes care of:
 *  - "garbage collection"
 *  - tracking router change
 *  - clear data layer beforehand
 *  - track pageview & set current shop
 */
export const useDataLayerTracking = (): void => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const user = useAppSelector((state) => state.user);
  const location = useLocation();
  const cachedShopId = useRef('');
  const initialLoad = useRef(false);

  useEffect(() => {
    boomDataLayer();

    if (currentShopId && cachedShopId.current !== currentShopId) {
      cachedShopId.current = currentShopId;
    } else {
      dataLayerPageView(currentShopId, location.pathname, user);
    }

    if (!initialLoad.current && Object.keys(user).length > 0) {
      initialLoad.current = true;
      dataLayerPageView(currentShopId, location.pathname, user);
    }

    // TODO: Ask Mike if these dependencies should be added
  }, [location.pathname, currentShopId, user]);
};
