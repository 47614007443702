import { isDefined } from 'utils/isDefined';
import { $preferedColorScheme } from './stores';
import { ColorScheme } from './types';

/**
 * Updates color scheme.  If boolean is provided, true means 'dark' and false means 'light'.
 * Nothing happens if `newClrScheme` is undefined, and if `newClrScheme` is a valid ColorScheme,
 * we just use that value.
 */
export const updateColorScheme = (newClrScheme: ColorScheme | boolean | null = null) => {
  if (!isDefined(newClrScheme)) return;

  if (typeof newClrScheme === 'boolean') {
    $preferedColorScheme.set(newClrScheme ? 'dark' : 'light');
  } else {
    $preferedColorScheme.set(newClrScheme);
  }
};
