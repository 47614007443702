import { Icon, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';

export default function PixelCheckout({ order }) {
  const { shouldNotBeSeen: cantSeeLinks } = useFeatureFlag(FeatureFlag.PIXEL_PURCHASE_POPUP_FF);
  if (!order) return null;
  return (
    <div>
      <div>
        {order.total_line_items_price && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.625rem',
              alignItems: 'flex-start',
            }}
          >
            <a
              href={cantSeeLinks ? '' : order.order_status_url}
              target="_blank"
              className="order-link flex gap-1"
            >
              <Icon color="interactive" source={ExternalSmallMinor} />
              {(<TextStyle variation="strong">Total Order: {order.total_price}</TextStyle>) as any}
            </a>
            <TextStyle variation="strong">Total Discount: {order.total_discounts}</TextStyle>
            {!!order.discount_codes?.length && (
              <TextStyle variation="strong">
                Discount Code: {order.discount_codes.map((x) => x.code).join(' ')}
              </TextStyle>
            )}
          </div>
        )}
      </div>
      <ResourceList
        resourceName={{ singular: 'product', plural: 'products' }}
        items={
          order.line_items?.map((item) => ({
            id: item.id,
            name: item.name,
            sku: item.sku,
            variantName: item.variant_title,
            price: item.price,
            quantity: item.quantity,
          })) || []
        }
        renderItem={(item: any) => {
          const { id, name, price, quantity, variantName, sku } = item;
          return (
            <ResourceItem
              verticalAlignment="center"
              id={id}
              accessibilityLabel={`View details for ${name}`}
              name={name}
              onClick={() => {}}
            >
              <h3>
                <TextStyle variation="strong">
                  {name} ({sku} - {variantName})
                </TextStyle>
              </h3>
              <div>Price: {price}</div>
              <div>Quantity: {quantity}</div>
            </ResourceItem>
          );
        }}
      />
    </div>
  );
}
