import { CustomerProfileModal } from 'components/Insights/CustomerProfile/Activities/CustomerProfileModal';

export const WILLY_FUNCTION_COMPONENTS = ['get_customer_journey'];
type WillyFunctionComponent = (typeof WILLY_FUNCTION_COMPONENTS)[number];

export function isCustomeComponentMetric(
  functionName?: string,
): functionName is WillyFunctionComponent {
  return functionName ? WILLY_FUNCTION_COMPONENTS.includes(functionName) : false;
}

export const WillyMetricCustomComponent: React.FC<{
  data: Record<string, any>;
  customComponent: WillyFunctionComponent;
  onClose: () => void;
}> = ({ customComponent, data, onClose }) => {
  switch (customComponent) {
    case 'get_customer_journey':
      return (
        <CustomerProfileModal
          onClose={onClose}
          shopifyCustomerId={data.customer_id}
          customerName={data.customer_email} // TODO: Change to customer name
        />
      );
    default:
      return null;
  }
};
