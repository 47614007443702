import React, { FC, useContext, useEffect, useState } from 'react';
import { influencer, SelectableInfluencer } from 'types/Influencers';
import { emptyInfluencer } from 'utils/influencers';

import { Modal, Button, Title, Loader } from '@tw/ui-components';
import { DropZone } from '@shopify/polaris';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { InfluencersContext } from './context';
import { set } from 'lodash';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const InfluencerImportModal: FC<Props> = ({ isOpen, onClose }) => {
  const [influencer, setInfluencer] = useState<SelectableInfluencer>(emptyInfluencer());
  const shopId = useSelector((state: RootState) => state.currentShopId);
  const user = useSelector((state: RootState) => state.userEmail);
  const [success, setSuccess] = useState('');
  const { loadInfluencers } = useContext(InfluencersContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // useEffect(() => {
  //   editMode ? setInfluencer(influencerEdit) : setInfluencer(emptyInfluencer());
  // }, [editMode, influencerEdit]);

  const onFileUpload = async (fileArray) => {
    const file = fileArray[0];
    setError('');
    setLoading(true);
    var reqData = new FormData();
    reqData.append('file', file, file.name);
    reqData.append('shopDomain', shopId);
    reqData.append('user', user);

    try {
      const res = await axiosInstance.post('/v2/attribution/influencers-csv-upload', reqData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000,
      });
      if (res.status === 200) {
        setSuccess(`Successfully imported ${res.data.affluencersCount} influencers`);
        loadInfluencers && loadInfluencers(0, true);
        setTimeout(() => {
          onClose();
          setSuccess('');
        }, 4000);
      } else {
        setError('There was an error importing influencers');
      }
    } catch (e) {
      setError(e?.data?.errors);
    }
    setLoading(false);
  };

  return (
    <Modal
      opened={isOpen}
      title="Import Affluencers"
      onClose={() => {
        onClose();
        setError('');
      }}
    >
      {success && (
        <Title size={'md'} c="green">
          {success}
        </Title>
      )}
      {loading && <Loader size="sm" />}
      {error && (
        <Title size={'md'} c="red">
          {' '}
          {error}{' '}
        </Title>
      )}
      {!success && !loading && (
        <>
          <Button
            variant="primary"
            onClick={() =>
              window.open(
                'https://docs.google.com/spreadsheets/d/1VAr6kO4rBIOqhSZimvwvTOwg_iS_iiiBhHmlzHDTUWw/copy',
                '_blank',
              )
            }
          >
            Download Template
          </Button>
          <DropZone
            label={<span className="font-semibold">Import Affluencers</span>}
            variableHeight={true}
            onDrop={(file) => onFileUpload(file)}
            allowMultiple={false}
          >
            <div className="flex flex-col gap-4 items-center justify-center h-40">
              <div className="text-center">Drop your file or Click here to upload</div>
            </div>
          </DropZone>
        </>
      )}
    </Modal>
  );
};

export default InfluencerImportModal;
