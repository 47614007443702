import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Menu, Text } from '@tw/ui-components';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { toggleShowingModalSection } from 'ducks/actions/modalCustomSectionActions';
import { toggleCustomMetricModal } from 'ducks/customMetrics';
import { useFeatureFlag } from 'feature-flag-system';
import { useAppDispatch } from 'index';
import { FC } from 'react';

type CreateNewSectionProps = {
  closePopover?: () => void;
};

export const CreateNewSection: FC<CreateNewSectionProps> = ({ closePopover }) => {
  const dispatch = useAppDispatch();
  const { shouldNotBeSeen: createCustomMetricsLocked } = useFeatureFlag(
    FeatureFlag.CUSTOM_METRICS_FF,
  );
  return (
    <>
      <Menu.Item
        onClick={() => {
          dispatch(toggleShowingModalSection(true));
          closePopover?.();
        }}
      >
        <Text color="named2.0" size="sm" weight={500}>
          Create Section
        </Text>
      </Menu.Item>
      <Menu.Item
        disabled={createCustomMetricsLocked}
        onClick={() => {
          dispatch(toggleCustomMetricModal({ isModalOpen: true }));
          closePopover?.();
        }}
        leftSection={
          createCustomMetricsLocked && (
            <LockedFeatureIndicator
              iconOnly
              iconSize={20}
              featureFlag={FeatureFlag.CUSTOM_METRICS_FF}
            />
          )
        }
      >
        <Text color="named2.0" size="sm" weight={500}>
          Create Custom Metric
        </Text>
      </Menu.Item>
    </>
  );
};
