import { FC } from 'react';
import { ShopIntegrationStatusEnum } from '@tw/types/module/types/ShopProviders';

// style
import module_styles from '../Integrations.module.scss';

// components
import { Button, Group, Loader } from '@tw/ui-components';

export interface IIntegrationWidgetButton {
  id: string;
  name: string;
  connected: boolean;
  connect: () => any;
  disconnect: () => any;
  configHook?: () => any;
  loading?: boolean;
  status?: ShopIntegrationStatusEnum;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const IntegrationWidgetButtons: FC<IIntegrationWidgetButton> = ({
  connected,
  disconnect,
  connect,
  id,
  name,
  configHook,
  loading,
  status,
  size,
}) => {
  const ariaLabel = (connected ? 'Disconnect' : 'Connect') + ` ${name}`;
  const btnId = 'integrations-' + (connected ? 'disconnect' : 'connect') + `-${id}`;
  const btnReconnectId = 'integrations-' + 'reconnect' + `-${id}`;
  const onClick = connected ? disconnect : connect;
  const text = connected ? 'Disconnect' : 'Connect';

  return (
    <Group gap="xs" wrap="wrap" justify="flex-end">
      {connected && configHook && (
        <Button
          size={size ?? 'xs'}
          display="inline"
          onClick={configHook}
          id="integrations-disconnect-id"
          aria-label="Disconnect"
          variant="white"
        >
          Manage
        </Button>
      )}
      <Button
        size={size ?? 'xs'}
        onClick={onClick}
        id={btnId}
        aria-label={ariaLabel}
        variant={connected ? 'white' : 'primary'}
      >
        {loading ? <Loader size={size ?? 'xs'} /> : text}
      </Button>
    </Group>
  );
};
