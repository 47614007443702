import TWImage from 'components/library/TWImage/TWImage';
import { ReactComponent as ChevronLeft } from 'icons/chevron-left_minor.svg';
import { ReactComponent as ChevronRight } from 'icons/chevron-right_minor.svg';
import { Slide, SlideProps } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { FC } from 'react';
export interface TWSlidesItems {
  src: string;
  type: 'image' | 'ReactNode';
  alt: string;
  id: number;
  Children?: React.ReactNode;
}
interface ITWSlidesProps {
  items: TWSlidesItems[];
  action?: ((arg: any) => any) | React.Dispatch<React.SetStateAction<any>>;
  properties?: Partial<SlideProps>;
}

const defaultProperties: Partial<SlideProps> = {
  defaultIndex: 0,
  arrows: true,
  indicators: true,
  canSwipe: false,
  transitionDuration: 300,
  autoplay: false,
  prevArrow: (
    <span className="w-8 h-8 opacity-50 border-none ml-10 rounded-full">
      <ChevronLeft />
    </span>
  ),
  nextArrow: (
    <span className="w-8 h-8 opacity-50 border-none mr-10 rounded-full">
      <ChevronRight />
    </span>
  ),
};

const TWSlides: FC<ITWSlidesProps> = ({
  items,
  action = () => {},
  properties = {
    defaultIndex: 0,
    arrows: true,
    indicators: true,
    canSwipe: false,
    transitionDuration: 300,
    autoplay: false,
    prevArrow: (
      <span className="w-8 h-8 opacity-50 border-none ml-10 rounded-full">
        <ChevronLeft />
      </span>
    ),
    nextArrow: (
      <span className="w-8 h-8 opacity-50 border-none mr-10 rounded-full">
        <ChevronRight />
      </span>
    ),
  },
}) => {
  return items?.length > 1 ? (
    <Slide {...{ ...defaultProperties, ...properties }}>
      {items?.map((item) => {
        if (item?.type === 'ReactNode') {
          return item?.Children;
        }
        return (
          <TWImage
            className="w-full h-full rounded object-cover"
            onClick={action}
            src={item?.src}
            alt={item?.alt}
            key={item?.id}
          />
        );
      })}
    </Slide>
  ) : (
    (items[0]?.type === 'ReactNode' && (
      <div className="w-full h-full rounded object-cover">
        {items[0]?.type === 'ReactNode' ? items[0]?.Children : null}
      </div>
    )) || (
      <TWImage
        className="w-full h-full rounded object-cover"
        wrapperClass="h-100"
        onClick={action}
        src={items[0]?.src}
        alt={items[0]?.alt}
      />
    )
  );
};

export default TWSlides;
