import { CreativeTypes } from '@tw/types';
import { columns as creativeColumns } from '../CreativeTable/columns';
import { ServicesIds } from '@tw/types/module/services';

export function filterColumns(
  creativeType: CreativeTypes,
  serviceId: ServicesIds,
  columns = creativeColumns,
) {
  return columns.filter(
    (col) =>
      (col.showInServices?.includes(serviceId) || !col.showInServices) &&
      (!col.showInTypes ||
        col.showInTypes.includes(creativeType) ||
        col.showInTypes.includes('all')),
  );
}
