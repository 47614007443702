type CircleIconProps = {
  color: 'red' | 'green' | 'orange' | 'blue' | 'gray' | any;
};

export const CircleIcon: React.FC<CircleIconProps> = ({ color }) => {
  const colorClasses = {
    green: 'bg-green',
    red: 'bg-red-500',
    blue: 'bg-blue-500',
    gray: 'bg-gray-300',
    default: 'bg-orange-400',
  };
  const bgColorClass = colorClasses[color] || colorClasses.default;
  return (
    <div
      className={`w-3 h-3 rounded-full ${bgColorClass}
      }`}
    />
  );
};
