import { Alert, Icon, Text } from '@tw/ui-components';
import { FC } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $creditsLeftInfinity } from '$stores/willy/$credits';

type CreditsUseAlertProps = {
  creditsNumber?: number;
  isCustomReport: boolean;
};

export const CreditsUseAlert: FC<CreditsUseAlertProps> = ({ creditsNumber, isCustomReport }) => {
  const creditsLeftInfinity = useStoreValue($creditsLeftInfinity);
  if (creditsLeftInfinity) return null;

  return creditsNumber ? (
    <Alert icon={<Icon name="info" color="one.4" size={18} />}>
      <Text size="sm" weight={500} color="one.8">
        {`${creditsNumber} Credit${creditsNumber > 1 ? 's' : ''} will be used ${isCustomReport ? 'for custom report' : ''}`}
      </Text>
    </Alert>
  ) : null;
};
