import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { WillyDashboardElement } from '../../types/willyTypes';
import { useMemo } from 'react';
import { isMobileApp } from 'utils/Device';
import { WillyDashOptionsList } from './WillyDashOptionsList';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { $dashSearch } from './stores';
import { Stack } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { closeReportSelectorDrawer } from '$stores/willy/$mobileDrawers';

export type DashboardOptionItemProps = {
  category?: string | null;
  dashboards: WillyDashboardElement[];
};

export const FavoriteDashboardOptionItem: React.FC<DashboardOptionItemProps> = ({
  category,
  dashboards,
}) => {
  const freeSearch = useStoreValue($dashSearch);
  const params = useParams<{ dashboardId: string }>();
  const navigate = useNavigate();
  const { search } = useLocation();

  const optionDashboards = useMemo(() => {
    // because we are filtering before - we are filtering also all custom view,
    // so need to take them back but only for dashboard that filtered above
    const combinedList = dashboards
      .filter((d) => d.isFavorite)
      .reduce((combinedList, dashboard) => {
        combinedList.push(dashboard); // Add the shop dashboard first

        // Find and add custom views for this dashboard
        dashboards.forEach((d) => {
          if (d.isCustomViewOfDashboardId !== dashboard.id) return;
          combinedList.push(d);
        });

        return combinedList;
      }, [] as WillyDashboardElement[]);

    return combinedList;
  }, [dashboards]);

  const computedOptionsList = useMemo(
    () =>
      optionDashboards
        .filter((dashboard) => {
          if (!category?.length || (category === 'general' && !dashboard.category)) return true;
          return dashboard.category?.toLowerCase() === category.toLowerCase();
        })
        .map((dashboard) => ({
          value: dashboard.id,
          label: <WillyDashOptionsList dashboard={dashboard} isFavorites={true} />,
        })),
    [optionDashboards, category],
  );

  if (freeSearch.length && optionDashboards.length === 0) {
    return (
      <div className="px-8 ">
        <p className="text-[var(--gray-light)]">No dashboards found</p>
      </div>
    );
  }

  if (!computedOptionsList.length) {
    return <p className="px-8 text-[var(--gray-light)]">No dashboards added</p>;
  }

  return (
    <Stack>
      {computedOptionsList.map((x) => {
        return (
          <div
            key={'favorite-option-' + x.value}
            className={
              'no-underline text-[var(--mantine-color-gray-7)] font-medium group/dashboard-label gap-2 ' +
              'render-item flex rounded-md items-center ' +
              'cursor-pointer hover:bg-[var(--gray-light-mode-300)] py-1 -my-1 ' +
              (x.value === params.dashboardId ? 'bg-[var(--gray-light-mode-300)]' : '')
            }
            onClick={() => {
              navigate({ pathname: `/dashboards/${x.value}`, search });

              if (windowSize.isSmall || isMobileApp) {
                closeReportSelectorDrawer();
              }
            }}
          >
            <div className="opacity-0 group-hover/report-side-panel:opacity-100 pl-4 min-w-[24px]" />
            {x.label}
          </div>
        );
      })}
    </Stack>
  );
};
