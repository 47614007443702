import { useCallback, useEffect, useMemo, useState } from 'react';
import { getSocket } from '../WillySocket';

export const useWillyTitle = (
  queryId?: string,
  onMessageDone?: (message: string) => Promise<void>,
) => {
  const [title, setTitle] = useState<string>('');

  const willySocket = useMemo(() => getSocket(), []);

  const messageDone = useCallback(
    (msg) => {
      if (!queryId) {
        return;
      }
      if (msg.messageId !== queryId) {
        return;
      }
      onMessageDone?.(title);
    },
    [queryId, onMessageDone, title],
  );

  const insertTitle = useCallback(
    (msg) => {
      if (!queryId) {
        return;
      }
      if (msg.messageId !== queryId) {
        return;
      }
      setTitle((old) => old + msg.title);
    },
    [queryId],
  );

  useEffect(() => {
    const titleSocket = willySocket?.on('title', insertTitle);
    const done = willySocket?.on('done', messageDone);

    return () => {
      titleSocket?.off('title', insertTitle);
      done?.off('done', messageDone);
    };
  }, [messageDone, insertTitle, willySocket]);

  return { title, setTitle };
};
