import { Icon, Loader, Modal, Text } from '@tw/ui-components';
import { WillyFormGroup } from '../WillyFormGroup';
import { useStoreValue } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _db, { toArray } from 'utils/DB';
import { SequenceRun } from '../types/willyTypes';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

type SequenceRunsProps = {
  sequenceId?: string;
  opened?: boolean;
  close?: () => void;
};

export const SequenceRuns: React.FC<SequenceRunsProps> = ({ sequenceId, close }) => {
  const sequences = useStoreValue($shopSequences);
  const location = useLocation();

  const [runs, setRuns] = useState<SequenceRun[]>([]);
  const [loading, setLoading] = useState(false);

  const sequence = useMemo(() => {
    return sequences.find((x) => x.id === sequenceId);
  }, [sequences, sequenceId]);

  const activeRunId = useMemo(() => {
    return new URLSearchParams(location.search).get('runId');
  }, [location.search]);

  const getRunUrl = useCallback(
    (runId: string) => {
      if (!sequence) return;
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('sequenceId', sequence.id);
      searchParams.set('runId', runId);
      searchParams.delete('conversationId');

      return searchParams.toString();
    },
    [sequence, location.search],
  );

  useEffect(() => {
    if (!sequence) return;
    (async () => {
      setLoading(true);
      const runs = await _db()
        .collection('data_sequences')
        .doc(sequence.id)
        .collection('runs')
        .orderBy('runAt', 'desc')
        .limit(10)
        .get();
      const conversations: SequenceRun[] = toArray(runs);
      setRuns(conversations);
      setLoading(false);
    })();
  }, [sequence]);

  return (
    <WillyFormGroup>
      <Text size="sm" color="gray.8">
        Last Runs
      </Text>
      <div className="flex flex-col gap-2">
        {!runs.length && (
          <Text size="sm" color="gray.8">
            No runs yet
          </Text>
        )}
        {loading && <Loader size="sm" />}
        {!loading &&
          runs.map((run) => (
            <div key={run.id} className="flex items-center gap-2">
              <Link
                className={`flex items-center gap-2 ${
                  run.id === activeRunId ? 'no-underline cursor-default' : ''
                }`}
                to={
                  run.outputFile
                    ? `/workflows/view/${sequence?.id ?? sequenceId}?fileName=${btoa(run.outputFile)}`
                    : {
                        pathname: location.pathname,
                        search: getRunUrl(run.id),
                      }
                }
                onClick={() => {
                  if (run.id === activeRunId) {
                    return;
                  }
                  close?.();
                }}
              >
                <Icon name="clock" />
                <Text size="sm" fw={run.id === activeRunId ? 'bold' : 'normal'}>
                  {run.runAt.toDate().toLocaleDateString()}{' '}
                  {run.runAt.toDate().toLocaleTimeString()}
                </Text>
              </Link>
              <Text size="xs">({run.trigger === 'schedule' ? 'Scheduled' : 'Ran manually'})</Text>
            </div>
          ))}
      </div>
    </WillyFormGroup>
  );
};

export const SequenceRunsModal: React.FC<SequenceRunsProps> = ({ sequenceId, opened, close }) => {
  if (!close) {
    close = () => {};
  }
  return (
    <Modal title="Sequence Runs" opened={!!opened} onClose={close}>
      <SequenceRuns sequenceId={sequenceId} close={close} />
    </Modal>
  );
};
