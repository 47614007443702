import { combineReducers } from 'redux';
import DB from '../../utils/DB';
import { getShopifySegments } from './newShopify';
import { sectionTilesOrderChanged } from '../summary';

export const TOGGLE_SHOWING_MODAL_ADD_SALE_BY_SEGMENT = 'TOGGLE_SHOWING_MODAL_ADD_SALE_BY_SEGMENT';
export const MODAL_ADD_SALE_BY_SEGMENT_IS_FROM_SECTION =
  'MODAL_ADD_SALE_BY_SEGMENT_IS_FROM_SECTION';

export const toggleShowingModalAddSaleBySegment =
  (showingModalAddSaleBySegment, isFromSection) => (dispatch) => {
    dispatch({
      type: TOGGLE_SHOWING_MODAL_ADD_SALE_BY_SEGMENT,
      payload: showingModalAddSaleBySegment,
    });
    dispatch({
      type: MODAL_ADD_SALE_BY_SEGMENT_IS_FROM_SECTION,
      payload: isFromSection,
    });
  };

const addItToSection = (dispatch, summarySectionsCustomization, segment) => {
  if (!(summarySectionsCustomization['salesBySegment']?.tiles || []).includes(segment.id)) {
    const tiles = (summarySectionsCustomization['salesBySegment']?.tiles || []).concat([
      segment.id,
    ]);
    dispatch(sectionTilesOrderChanged('salesBySegment', tiles));
  }
};

export const addSalesBySegment = (segment, isFromSection) => async (dispatch, getState) => {
  const { shop, summarySectionsCustomization } = getState();

  const { salesBySegmentIncludeList }: any = shop;

  if (
    segment.id &&
    (!salesBySegmentIncludeList ||
      !salesBySegmentIncludeList.some(
        (item) => item.id === segment.id && item.type === segment.type,
      ))
  ) {
    const salesBySegmentIncludeListToSend = !salesBySegmentIncludeList
      ? [segment]
      : [...salesBySegmentIncludeList, segment];
    await DB().set(
      {
        salesBySegmentIncludeList: salesBySegmentIncludeListToSend,
      },
      { merge: true },
    );
    dispatch(getShopifySegments(salesBySegmentIncludeListToSend));
  }
  if (isFromSection) {
    addItToSection(dispatch, summarySectionsCustomization, segment);
  }
};

const showModalAddSaleBySegment = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SHOWING_MODAL_ADD_SALE_BY_SEGMENT:
      return action.payload;
    default:
      return state;
  }
};

const isFromSection = (state = false, action) => {
  switch (action.type) {
    case MODAL_ADD_SALE_BY_SEGMENT_IS_FROM_SECTION:
      return action.payload ?? state;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  showModalAddSaleBySegment,
  isFromSection,
});
