import { $globalConfig } from '$stores/$globalConfig';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $derived } from '@tw/snipestate';
import { $ffStore } from 'feature-flag-system';
import { $shopDashboards } from './$shopDashboards';
import { $mobyUserIds } from '$stores/$shop';
import { $subscriptionItems, MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW } from './$subscription';
import axiosInstance from 'utils/axiosInstance';
import { toast } from 'react-toastify';

export const $sqlTeamReportCredit = $derived((get) => {
  const globalTW3Config = get($globalConfig).find((item) => item.id === 'tw3');
  return globalTW3Config?.sqlTeamReportCredit ?? 0;
});

export type Credits = {
  credits: number;
  creditsLeft: number;
  creditsUsed: number;
};

export type UserCredit = {
  credits: number;
  userId: string;
};

export const $dashboardCredits = $derived<Credits>((get) => {
  const { numericLimit } = get($ffStore).getConfigById(FeatureFlag.DASHBOARDS_COUNT_FF);
  const sqlTeamReportCredits = get($sqlTeamReportCredit);
  const credits = numericLimit ?? 0;
  const usedCredits = get($shopDashboards).reduce(
    (acc, d) => acc + (d.isSqlTeamReport ? sqlTeamReportCredits : d.credits ?? 0),
    0,
  );

  return {
    credits: credits,
    creditsLeft: Math.max(0, credits - usedCredits),
    creditsUsed: usedCredits,
  };
});

export const $creditsLeft = $derived((get) => get($dashboardCredits).creditsLeft);
export const $creditsLeftInfinity = $derived((get) => get($creditsLeft) === Infinity);

export const $mobySeatsUsersCredits = $derived<UserCredit[]>((get) => {
  const mobyUserIds = get($mobyUserIds) || [];
  const userIdsFromPP = get($subscriptionItems).reduce((acc, x) => {
    if (
      !!x.plan_preview_start_date &&
      x.price_id === MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW &&
      !!x.metadata?.userId
    )
      acc.push(x.metadata.userId as string);

    return acc;
  }, [] as string[]);

  return [...new Set([...mobyUserIds, ...userIdsFromPP])].map((userId) => {
    return {
      userId,
      credits: userIdsFromPP.includes(userId) && !mobyUserIds.includes(userId) ? 0 : 1,
    };
  });
});

export const $mobySeatsCredits = $derived<Credits>((get) => {
  const { numericLimit } = get($ffStore).getConfigById(FeatureFlag.CHAT_DATA_SEATS_COUNT_FF);
  const mobyUserIds = get($mobyUserIds) ?? [];
  const credits = numericLimit ?? 0;
  const usedCredits = mobyUserIds.length;

  return {
    credits: credits,
    creditsLeft: Math.max(0, credits - usedCredits),
    creditsUsed: usedCredits,
  };
});

export const $mobySeatsCreditsExceeded = $derived((get) => {
  if (!get($ffStore).isReady) {
    return false;
  }
  const { credits, creditsUsed } = get($mobySeatsCredits);
  return creditsUsed > credits;
});

export const addUserToMobySeats = async (shopId: string, userId: string) => {
  try {
    const response = await axiosInstance.post(`/v2/willy/add-user-to-shop-moby-seats`, {
      shopId,
      userId,
    });
    toast.success('User has been added to Moby seats.');
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error?.response?.data?.message || 'Error adding user to Moby seats.');
    throw error;
  }
};

export const removeUsersFromMobySeats = async (shopId: string, userIds: string[]) => {
  try {
    const response = await axiosInstance.post(`/v2/willy/remove-users-from-shop-moby-seats`, {
      shopId,
      userIds,
    });
    toast.success(`${userIds.length} user(s) have been removed from Moby seats.`);
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error?.response?.data?.message || 'Error removing user from Moby seats.');
    throw error;
  }
};
