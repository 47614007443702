import { combineReducers, Reducer } from 'redux';
import {
  ADD_AFFLUENCER_MODAL_OPEN_CHANGED,
  AFFLUENCER_HUB_CHART_OPEN_CHANGED,
  AFFLUENCER_HUB_IMPORT_MODAL_OPEN,
  AFFLUENCER_HUB_FREE_SEARCH_CHANGED,
} from './actions';

const affluencerHubChartOpen: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case AFFLUENCER_HUB_CHART_OPEN_CHANGED:
      return action.opened;
    default:
      return state;
  }
};

const addAffluencerHubModalOpen: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case ADD_AFFLUENCER_MODAL_OPEN_CHANGED:
      return action.opened;
    default:
      return state;
  }
};

const freeSearch: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case AFFLUENCER_HUB_FREE_SEARCH_CHANGED:
      return action.freeSearch;
    default:
      return state;
  }
};

const affluencerHubImportModalOpen: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case AFFLUENCER_HUB_IMPORT_MODAL_OPEN:
      return action.opened;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  affluencerHubChartOpen,
  addAffluencerHubModalOpen,
  affluencerHubImportModalOpen,
  freeSearch,
});
