type NestedObject = { [key: string]: any };

export function convertUndefinedToNull<T>(obj: T): T {
  const isObject = (val: any) => val && typeof val === 'object';

  const convert = (val: any): any => {
    if (val === undefined || val === null) {
      return null;
    } else if (Array.isArray(val)) {
      return val.map((item) => (item === undefined ? null : convert(item)));
    } else if (isObject(val)) {
      return Object.entries(val).reduce<NestedObject>((acc, [key, value]) => {
        acc[key] = value === undefined ? null : convert(value);
        return acc;
      }, {});
    }
    return val;
  };

  return convert(obj);
}
