import { TextContainer } from '@shopify/polaris';

const DisconnectFromGeneralExplanation = (
  <div className={'mb-5'}>
    <TextContainer>
      <p>Note: You can reconnect back again later</p>
    </TextContainer>
  </div>
);

export default DisconnectFromGeneralExplanation;
