const optionsTranslation = {
  authorized: 'authorized',
  expired: 'expired',
  paid: 'paid',
  partially_paid: 'partially paid',
  partially_refunded: 'partially refunded',
  pending: 'pending',
  refunded: 'refunded',
  voided: 'voided',
  single_purchase: 'single purchase',
  subscription_first_purchase: 'subscription: first purchase',
  subscription_recurring_purchase: 'subscription: recurring purchase',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  INVALID: 'invalid',
  NOT_SUBSCRIBED: 'not subscribed',
  PENDING: 'pending',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  INVITED: 'invited',
  DECLINED: 'declined',
  REDACTED: 'redacted',
};

export function getListOption(option: string) {
  return optionsTranslation[option] || option;
}
