import { Flex, Text, Title } from '@tw/ui-components';

export const LetsBuild = () => {
  return (
    <Flex direction="column" h="100%" align="center" justify="center" gap="md">
      <Title order={4} fw={500}>
        Let's build! 🚀
      </Title>
      <Text weight={500}>Get started by adding a metric</Text>
    </Flex>
  );
};
