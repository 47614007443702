import { BqColumn } from '../dataStuff/columns/types';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  BOOLEAN_COMPARATOR,
  FilterComparator,
  LIST_COMPARATORS,
  NUMBER_COMPARATORS,
  STRING_COMPARATORS,
  TIME_COMPARATORS,
} from '@tw/types/module/services/insights/filters/FilterComparator';
import { ActionIcon, Flex, Icon, Select, Tooltip } from '@tw/ui-components';
import { getFilterQueryLabel } from '../../../components/Insights/Filters/queryTranslation';
import { FilterRowCompValue } from './FilterRowCompValue';
import { BqTable } from '../dataStuff/tables';
import { FIELD_ARRAY_COMPARATORS } from '@tw/types/src/services/insights/filters/FilterComparator';
import { ServicesIds } from '@tw/types/module/services';
import { SQLType, FilterRow, FilterGroup } from '@tw/willy-data-dictionary/module/columns/types';

export const SQLTypeToFilterComparators: Partial<{
  [key in SQLType]: readonly FilterComparator[];
}> = {
  string: STRING_COMPARATORS,
  numeric: NUMBER_COMPARATORS,
  date: TIME_COMPARATORS,
  timestamp: TIME_COMPARATORS,
  boolean: BOOLEAN_COMPARATOR,
  'repeated string': FIELD_ARRAY_COMPARATORS,
  formula: NUMBER_COMPARATORS,
};

export const SQLTypeDefaultComparators: Partial<{ [key in SQLType]: FilterComparator }> = {
  string: FilterComparator.EQUAL,
  numeric: FilterComparator.GREATER_THAN,
  date: FilterComparator.AFTER,
  timestamp: FilterComparator.AFTER,
  boolean: FilterComparator.EQUAL,
  formula: FilterComparator.GREATER_THAN,
  'repeated string': FilterComparator.ARRAY_CONTAINS,
};

type FilterRowCompProps = {
  row: FilterRow;
  index: number;
  parentIndex: number;
  columns: BqColumn[];
  table: BqTable;
  filter: FilterGroup[];
  onFilterChanged: (filter: FilterGroup[]) => void;
  relatedProvider?: ServicesIds;
};

export const FilterRowComp: React.FC<FilterRowCompProps> = ({
  row,
  index,
  parentIndex,
  columns,
  filter,
  table,
  onFilterChanged,
  relatedProvider,
}) => {
  const completedRow = useMemo(() => {
    const columnFromData = columns.find((x) => x.id === row?.column?.id) ?? row.column;
    return { ...row, column: columnFromData };
  }, [row, columns]);

  const updateRow = useCallback(
    (index: number, parentIndex: number, updater: (oldRow: FilterRow) => FilterRow) => {
      const filterToSend = ((old) => {
        // Clone the outer array and filter each inner array
        const newFilter = old
          ? old.map((group, i) => {
              if (i === parentIndex) {
                return {
                  ...group,
                  filters: group.filters.map((filterRow, j) => {
                    if (j === index) {
                      return updater(filterRow);
                    }
                    return filterRow;
                  }),
                };
              }
              return group;
            })
          : [];

        // Return the updated state
        return newFilter;
      })(filter);

      onFilterChanged(filterToSend);
    },
    [filter, onFilterChanged],
  );

  const removeFilterColumnByIndex = useCallback(
    (index: number, parentIndex: number) => {
      const filterToSend = ((old) => {
        // Clone the outer array and filter each inner array
        const newFilter = old
          ? old.map((group, i) => {
              if (i === parentIndex) {
                return { ...group, filters: group.filters.filter((filterRow, j) => j !== index) };
              }
              return group;
            })
          : [];

        // Remove any empty arrays
        const filteredNewFilter = newFilter.filter((group) => group.filters.length > 0);

        // Return the updated state
        return filteredNewFilter;
      })(filter);

      onFilterChanged(filterToSend);
    },
    [filter, onFilterChanged],
  );

  const availableComparators = useMemo(() => {
    if (!completedRow.column) return [];
    if (completedRow.column.comparators) {
      return completedRow.column.comparators;
    }
    if (completedRow.column?.options) {
      return LIST_COMPARATORS;
    }
    return SQLTypeToFilterComparators[completedRow.column?.type] ?? STRING_COMPARATORS;
  }, [completedRow.column]);

  const defaultComparator = useMemo(() => {
    if (completedRow.column?.comparators) {
      return completedRow.column.comparators[0];
    }
    if (completedRow.column?.options) {
      return FilterComparator.IS_IN;
    }
    if (completedRow.column?.id === 'event_date') {
      return FilterComparator.BETWEEN;
    }
    return SQLTypeDefaultComparators[completedRow.column?.type] ?? FilterComparator.EQUAL;
  }, [completedRow?.column]);

  useEffect(() => {
    if (filter?.[parentIndex]?.filters?.[index].comparator) return;
    updateRow(index, parentIndex, (oldRow) => ({
      ...oldRow,
      comparator: defaultComparator as FilterComparator,
    }));
  }, [defaultComparator, filter, index, parentIndex, updateRow]);

  return (
    <Flex justify={'space-between'} align={'center'}>
      <Flex gap={'xs'} align={'center'}>
        <Tooltip label={completedRow.column?.id ?? ''}>
          <Select
            searchable={true}
            size={'xs'}
            data={columns.map((x) => x.title)}
            value={completedRow.column?.title ?? ''}
            onChange={(val) =>
              updateRow(index, parentIndex, (oldRow) => ({
                ...oldRow,
                column: columns.find((x) => x.title === val)!,
                comparator: undefined,
                value: undefined,
                value1: undefined,
                value2: undefined,
              }))
            }
          />
        </Tooltip>
        {completedRow.column && completedRow.column?.id !== 'event_date' && (
          <Tooltip label={row.comparator ?? defaultComparator ?? ''}>
            <Select
              searchable={true}
              size={'xs'}
              data={availableComparators.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
              value={row.comparator ?? defaultComparator}
              onChange={(val) =>
                updateRow(index, parentIndex, (oldRow) => ({
                  ...oldRow,
                  comparator: val as FilterComparator,
                  value: undefined,
                  value1: undefined,
                  value2: undefined,
                }))
              }
            />
          </Tooltip>
        )}

        <FilterRowCompValue
          row={row}
          index={index}
          columns={columns}
          parentIndex={parentIndex}
          filter={filter}
          table={table}
          onFilterChanged={onFilterChanged}
          relatedProvider={relatedProvider}
        />
      </Flex>

      {filter[parentIndex].filters.length > 1 && (
        <ActionIcon
          variant={'transparent'}
          size="sm"
          onClick={() => removeFilterColumnByIndex(index, parentIndex)}
          icon={<Icon color={'red.6'} name={'delete'} size={20} />}
        />
      )}
    </Flex>
  );
};
