import { Button, TextInput, Menu, Tooltip, Icon } from '@tw/ui-components';
import { useMemo } from 'react';
import {
  AVAILABLE_LLM_MODELS,
  SQL_MODELS,
  LLMModel,
  SQLModel,
  SQL_MODELS_INFO,
  ModelInfo,
} from './constants';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $isAdminClaim } from '$stores/$user';
import { analyticsEvents, genericEventLogger, chatActions } from 'utils/dataLayer';
import { $mainChatStore, setSqlGeneratingModel } from '$stores/willy/$mainChat';

type WillyMainChatModelSelectorsProps = {};

export const WillyMainChatModelSelectors: React.FC<WillyMainChatModelSelectorsProps> = ({}) => {
  const isAdmin = useStoreValue($isAdminClaim);
  const sqlGeneratingModel = useComputedValue($mainChatStore, (r) => r.sqlGeneratingModel);

  const sqlModels = useMemo(() => {
    return SQL_MODELS_INFO.filter((model) => {
      if (!model.roles?.length) {
        return true;
      }
      if (model.roles.includes('admin')) {
        return isAdmin;
      }
    });
  }, [isAdmin]);

  const slqModelsByKeys = useMemo(() => {
    return sqlModels.reduce<ModelInfo<typeof sqlGeneratingModel>>((acc, model) => {
      acc[model.value] = model;
      return acc;
    }, {} as ModelInfo<any>);
  }, [sqlModels]);

  return (
    <Menu withArrow shadow="md" closeOnItemClick={false}>
      <Menu.Target>
        <Button variant="white" size="xs">
          Model: {slqModelsByKeys[sqlGeneratingModel].label}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {sqlModels.map((model) => (
          <Menu.Item
            key={model.value}
            onClick={() => {
              setSqlGeneratingModel(model.value as (typeof SQL_MODELS)[number]);
              genericEventLogger(analyticsEvents.CHAT, {
                action: chatActions.TOGGLE_CHAT_MODEL,
                model: model.value,
              });
            }}
            bg={sqlGeneratingModel === model.value ? 'named2.4' : undefined}
          >
            <div className="flex items-center gap-2">
              <div className="flex-auto">{model.label}</div>
              {!!model.description && (
                <Tooltip label={model.description}>
                  <Icon name="question-mark" size={12} />
                </Tooltip>
              )}
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );

  // return (
  //   <div className="flex flex-col gap-4">
  //     <Menu
  //       opened={menuOpened}
  //       withArrow
  //       shadow="md"
  //       onClose={() => setMenuOpened(false)}
  //       closeOnItemClick={false}
  //     >
  //       <Menu.Target>
  //         <Button variant="white" size="xs" onClick={() => setMenuOpened(!menuOpened)}>
  //           Models
  //         </Button>
  //       </Menu.Target>
  //       <Menu.Dropdown>
  //         {isAdmin && (
  //           <Menu.Item>
  //             <Menu
  //               withinPortal={false}
  //               shadow="md"
  //               position="right-start"
  //               offset={16}
  //               closeOnItemClick={false}
  //             >
  //               <Menu.Target>
  //                 <span>Chat Model: {activeModel} (Admin only)</span>
  //               </Menu.Target>
  //               <Menu.Dropdown>
  //                 {AVAILABLE_LLM_MODELS.map((model) => {
  //                   return (
  //                     <Menu.Item
  //                       key={model}
  //                       onClick={() => {
  //                         setActiveModel(model);
  //                       }}
  //                       bg={activeModel === model ? 'named2.4' : undefined}
  //                     >
  //                       {model}
  //                     </Menu.Item>
  //                   );
  //                 })}
  //               </Menu.Dropdown>
  //             </Menu>
  //           </Menu.Item>
  //         )}

  //         {activeModel === 'custom' && (
  //           <Menu.Item>
  //             <TextInput
  //               placeholder="Enter tools model name"
  //               value={customModel}
  //               onChange={setCustomModel}
  //             />
  //           </Menu.Item>
  //         )}
  //         <Menu.Item>
  //           <Menu
  //             position="right-start"
  //             offset={16}
  //             withinPortal={false}
  //             withArrow
  //             shadow="md"
  //             closeOnItemClick={false}
  //           >
  //             <Menu.Target>
  //               <span>SQL Model: {slqModelsByKeys[sqlGeneratingModel].label}</span>
  //             </Menu.Target>
  //             <Menu.Dropdown>
  //               {sqlModels.map((model) => (
  //                 <Menu.Item
  //                   key={model.value}
  //                   onClick={() => {
  //                     setSqlGeneratingModel(model.value as (typeof SQL_MODELS)[number]);
  //                   }}
  //                   bg={sqlGeneratingModel === model.value ? 'named2.4' : undefined}
  //                 >
  //                   <div className="flex items-center gap-2">
  //                     <div className="flex-auto">{model.label}</div>
  //                     {!!model.description && (
  //                       <Tooltip label={model.description}>
  //                         <Icon name="question-mark" size={12} />
  //                       </Tooltip>
  //                     )}
  //                   </div>
  //                 </Menu.Item>
  //               ))}
  //             </Menu.Dropdown>
  //           </Menu>
  //         </Menu.Item>

  //         {sqlGeneratingModel === 'custom' && (
  //           <Menu.Item>
  //             <TextInput
  //               placeholder="Enter SQL model name"
  //               value={customSqlModel}
  //               onChange={setCustomSqlModel}
  //             />
  //           </Menu.Item>
  //         )}
  //       </Menu.Dropdown>
  //     </Menu>
  //   </div>
  // );
};
