import { ArrowDownMinor } from '@shopify/polaris-icons';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';

type WillyScrollToBottomProps = {
  children: React.ReactNode;
  showArrow: boolean;
  arrowClassName?: string;
};

export type WillyScrollToBottomRef = {
  scrollToBottom: () => void;
  sticky: boolean;
};

export const WillyScrollToBottom = forwardRef<WillyScrollToBottomRef, WillyScrollToBottomProps>(
  ({ children, showArrow, arrowClassName }, ref) => {
    const scroll = useScrollToBottom();
    const [sticky] = useSticky();

    const containerRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = useCallback(() => {
      scroll({
        behavior: 'smooth',
      });
    }, [scroll]);

    useImperativeHandle(ref, () => ({
      scrollToBottom,
      sticky,
    }));

    return (
      <div className="flex flex-col h-full" ref={containerRef}>
        {children}
        <div ref={bottomRef}></div>
        {!sticky && !!showArrow && (
          <div
            className={`w-11 h-11 absolute bottom-[150px] left-1/2 -translate-x-1/2 rounded-full flex bg-[#ffffffab] ${
              arrowClassName ?? ''
            }`}
          >
            <ArrowDownMinor
              className="fill-black/50 dark:!fill-gray-400 cursor-pointer"
              onClick={() => {
                scrollToBottom();
              }}
            />
          </div>
        )}
      </div>
    );
  },
);
