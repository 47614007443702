import { BqColumn } from './columns/types';
import { BqTable, CustomColumn } from './tables';
import { getQueryWithoutPivot } from './utils';
import { initialComments } from '../constants';
import { $tabs } from '$stores/willy/$tables';
import { formatSqlSafely } from 'components/Willy/utils/willyUtils';

export function buildQueryFromClickhouseSchema(
  table: BqTable,
  column: BqColumn,
  parentColumn?: BqColumn,
) {
  const tabId = $tabs.get().find((t) => t.active)?.model?.id as string;

  const isBasicMetric = column.type === 'numeric';
  const isFormula = column.type === 'formula';
  const isMetric = isBasicMetric || isFormula;
  const isParameter = column.type === 'parameter';
  const agg = column.agg || 'sum';

  let currentQuery = $tabs.get().find((t) => t.model?.id === tabId)?.query;
  if (!currentQuery) {
    currentQuery = '';
  }
  currentQuery = currentQuery
    .replace(initialComments, '')
    .replace(/LIMIT\s+(\d+)/i, '')
    .replace(/\*\s*,?/, '');

  const fullQueryLower = currentQuery.toLowerCase();
  // const isBlendedStats = table.id === 'blended_stats_table';
  // if (isBlendedStats) {
  //   // start from after preparedTable
  //   currentQuery = currentQuery.replace(
  //     formatSqlSafely(preparedBlendedStatsTable),
  //     ''
  //   );
  // }
  const isUnnestedField =
    parentColumn?.type === 'record repeated' || column.type === 'repeated string';
  const requiresUnnest = isUnnestedField && !fullQueryLower.includes('join');
  const unnestingColumn = parentColumn || column;
  const isPivoted = fullQueryLower.includes('pivot');
  let pivotString = '';
  if (isPivoted) {
    ({ pivotString, queryWithoutPivot: currentQuery } = getQueryWithoutPivot(currentQuery));
  }
  const alias = (column as CustomColumn).isCustomColumn ? column.id : '';
  const aliasRegex = /\s+(?:as\s+)?(\w+)\s*$/i;
  let query = '';
  let field = column.name + alias;

  if (column.clickhouseFormula) {
    field = formatSqlSafely(column.clickhouseFormula) + alias;
  } else if (isBasicMetric) {
    let columnName = isUnnestedField ? `${unnestingColumn.name}.${column.name}` : column.name;
    field = `${agg}(${columnName}) as ${column.name}`;
  } else if (isParameter) {
    field = `@${column.id} ${column.name}`;
  } else if (column.type === 'repeated string') {
    const columnName = `${column.name.slice(0, -1)}`;
    if (parentColumn?.type === 'record repeated') {
      field = `${unnestingColumn.name}.${column.name} as ${columnName}`;
    } else {
      field = `${column.name} as ${columnName}`;
    }
  } else if (isUnnestedField) {
    field = `${unnestingColumn.name}.${column.name} as ${column.name}`;
  }

  if (!currentQuery) {
    let parameter = '';
    if (isParameter) {
      parameter = `${column.id}=@${column.id}`;
    }
    const table_name = table.type !== 'table_function' ? table.id : `${table.id}(${parameter})`;
    const tableRequiresEventDate = table.requiredFields?.includes('event_date');
    query = `
        select ${field}
        from ${table_name} ${table.alias || ''}

        ${requiresUnnest ? ` array join ${unnestingColumn.name}` : ''}
        ${
          tableRequiresEventDate
            ? `where event_date = DATE_SUB(current_date(), INTERVAL 1 DAY)`
            : 'where true'
        }
        ${
          isMetric || isParameter
            ? ''
            : `group by grouping sets((${field.match(aliasRegex)?.[1] || column.name}), ())`
        }
      `;
  } else {
    const beforeWhere = currentQuery.slice(0, currentQuery.toLowerCase().indexOf('where'));
    const fieldExists = new RegExp(`\\b${field}\\b`, 'i').test(beforeWhere);
    if (fieldExists) {
      // remove field from query
      query = query.trimEnd();
      query = currentQuery.replaceAll(`${field},`, '');
      const beforeWhere = currentQuery.slice(0, currentQuery.toLowerCase().indexOf('where'));
      const fieldExists = beforeWhere.includes(field);
      // if field still exists without comma
      if (fieldExists) {
        query = query.replaceAll(new RegExp(`\\b${field}\\b\\s*,?\\s*`, 'g'), '');
        const fromIndex = query.toLowerCase().indexOf('from\n');
        let beforeFrom = query.slice(0, fromIndex).trimEnd();
        const afterFrom = query.slice(fromIndex);
        if (beforeFrom.endsWith(',')) {
          beforeFrom = beforeFrom.slice(0, -1);
        }
        query = `${beforeFrom} ${afterFrom}`;
      }
      if (query.endsWith(field)) {
        // remove trailing field
        query = query.slice(0, -field.length);
        query = query.trimEnd();
      }

      if (query.endsWith('group by')) {
        query = query.replaceAll('group by', '');
      }
      if (query.endsWith(',')) {
        // remove trailing comma
        query = query.slice(0, -1);
      }
    } else {
      // inject before 'from' using splice
      const fromIndex = currentQuery.toLowerCase().indexOf('from\n');
      const beforeFrom = currentQuery.slice(0, fromIndex);
      let orderByIndex = fullQueryLower.indexOf('order by');
      if (orderByIndex === -1) {
        orderByIndex = fullQueryLower.length;
      }
      const afterOrderBy = currentQuery.slice(orderByIndex);
      const hasGroupBy = currentQuery.toLowerCase().includes('group by');
      const startGroupByString = hasGroupBy ? ',' : 'group by grouping sets(( ';
      const endGroupingSets = '), ())';
      const endGroupingSetsPattern = /\s*\),\s*\(\s*\)\s*\)/;
      let endGroupingSetsIndex = currentQuery.search(endGroupingSetsPattern);
      if (endGroupingSetsIndex === -1) {
        endGroupingSetsIndex = fullQueryLower.length;
      }
      const endOfFromIndex = Math.min(orderByIndex, endGroupingSetsIndex);
      let afterFrom = currentQuery.slice(fromIndex, endOfFromIndex);
      if (requiresUnnest) {
        const tableWithAlias = `${table.id} ${table.alias || ''}`;
        afterFrom = afterFrom.replace(
          tableWithAlias,
          `${tableWithAlias} array join ${unnestingColumn.name}`,
        );
      }
      if (isParameter) {
        const isEmptyFunc = afterFrom.includes(`${table.id} ()`);
        afterFrom = afterFrom.replace(
          `${table.id} (`,
          `${table.id} (${column.id}='${column.options?.[0]?.value}'${isEmptyFunc ? '' : ','}`,
        );
      }
      let groupByString = '';
      if (isMetric || isParameter) {
        if (currentQuery.includes(endGroupingSets)) {
          groupByString = endGroupingSets;
        }
      } else {
        groupByString = `${startGroupByString} ${
          field.match(aliasRegex)?.[1] || column.name
        } ${endGroupingSets}`;
      }
      query = `
          ${beforeFrom}, ${field}
          ${afterFrom} ${groupByString}
          ${afterOrderBy}
        `;
      if (beforeFrom.trim().toUpperCase() === 'SELECT') {
        query = query.replace(/SELECT\s*,/, 'SELECT');
      }
    }
  }

  // if (isBlendedStats) {
  //   query = `${preparedBlendedStatsTable} ${query}`;
  // }
  if (isPivoted) {
    query = `select * from (${query}) ${pivotString}`;
  }

  const formattedQuery = formatSqlSafely(query);

  return formattedQuery;
}
