import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { callUpdateToday } from 'utils/callUpdateToday';
import db from 'utils/DB';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from './constants';
import { $currentShopId } from '$stores/$shop';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import axiosInstance from 'utils/axiosInstance';
import { callInitialImport } from 'utils/callInitialImport';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;

export const TOGGLE_TWITTER_ACCOUNTS_CONFIG_SCREEN_OPEN =
  'TOGGLE_TWITTER_ACCOUNTS_CONFIG_SCREEN_OPEN';
export const TWITTER_ACCOUNTS_CONFIG_SAVE_SUCCESS = 'TWITTER_ACCOUNTS_CONFIG_SAVE_SUCCESS';

export const TWITTER_FETCHING_ACCOUNTS = 'TWITTER_FETCHING_ACCOUNTS';
export const TWITTER_RECEIVED_ACCOUNTS = 'TWITTER_RECEIVED_ACCOUNTS';

export const TWITTER_ACCOUNTS_IDS_ON_CHANGE = 'TWITTER_ACCOUNTS_IDS_ON_CHANGE';
export const twitterAccountsSelectOnChange = (value) => ({
  type: TWITTER_ACCOUNTS_IDS_ON_CHANGE,
  value,
});

export const toggleTwitterAccountsConfigScreenOpen = () => ({
  type: TOGGLE_TWITTER_ACCOUNTS_CONFIG_SCREEN_OPEN,
});

export const twitterConnectOnPress = () => {
  try {
    const url = `v2/twitter-ads/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(` ERROR `, e);
  }
};
//Miri add remove account from the account doc
export function twitterDisconnect() {
  return (dispatch, getState) => {
    toggleSaveIndicator();
    const { twitterRemoteAccounts } = getState();
    const accData = [] as any;
    for (const id in twitterRemoteAccounts) {
      const { accountId } = twitterRemoteAccounts[id];
      accData.push(accountId);
    }
    const data = axiosInstance.post('v2/twitter-ads/login/remove-accounts', {
      twitterAccounts: accData,
      shopDomain: $currentShopId.get(),
    });
    data.then(() => {
      dispatch(callRemoveLocalShopProp('twitterToken'));
      dispatch(callRemoveLocalShopProp('twitterAccounts'));
      dispatch(twitterDisconnectDone());
    });
    return data;
  };
}
export const TWITTER_DISCONNECT_DONE = 'TWITTER_DISCONNECT_DONE';

export function twitterDisconnectDone() {
  return {
    type: TWITTER_DISCONNECT_DONE,
  };
}

export const twitterAccountsSaveSuccess = () => {
  return {
    type: TWITTER_ACCOUNTS_CONFIG_SAVE_SUCCESS,
  };
};

export function twitterAccountsConfigSavePress() {
  return (dispatch, getState) => {
    toggleSaveIndicator();
    dispatch({ type: TOGGLE_TWITTER_ACCOUNTS_CONFIG_SCREEN_OPEN });
    var { twitterRemoteAccounts } = getState();
    var twitterAccounts = twitterRemoteAccounts.filter((acc) => acc.selected);

    const url = `v2/twitter-ads/login/update-accounts`;
    axiosInstance
      .post(url, {
        twitterAccounts: twitterAccounts,
        shopDomain: $currentShopId.get(),
      })
      .then((data) => {
        dispatch(twitterAccountsSaveSuccess());
        dispatch(startImportingTwitterData());
      });
  };
}

export const startImportingTwitterData = () => async (dispatch, getState) => {
  var { twitterRemoteAccounts } = getState();
  var twitterAccounts = twitterRemoteAccounts
    .filter((acc) => acc.selected)
    .map((account) => account.id);
  await callInitialImport('twitter-ads', twitterAccounts, false, undefined, true);
  dispatch(setOptimisticImporting('twitter-ads'));
  window.scrollTo(0, 0);
};

const isTwitterConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.twitterToken;
    case TWITTER_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'twitter-ads' || state;
    default:
      return state;
  }
};

const twitterConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case TWITTER_RECEIVED_ACCOUNTS:
      return true;
    case TOGGLE_TWITTER_ACCOUNTS_CONFIG_SCREEN_OPEN:
      return !state;
    case TWITTER_ACCOUNTS_CONFIG_SAVE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const twitterAccountSearch = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const twitterRemoteAccounts = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.twitterAccounts || state;
    case TWITTER_RECEIVED_ACCOUNTS:
      return action.accounts || state;
    case TWITTER_ACCOUNTS_IDS_ON_CHANGE:
      return action.value;
    default:
      return state;
  }
};

export const reducers = {
  isTwitterConnected,
  twitterConfigScreenIsOpen,
  twitterAccountSearch,
  twitterRemoteAccounts,
};
