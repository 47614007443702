import { $globalSequences } from '$stores/willy/$sequences';
import { Modal } from '@shopify/polaris';
import { useComputedValue } from '@tw/snipestate';
import { InsightsPopupInner } from 'components/InsightsPopupInner';
import { WillyDataSequence } from 'components/Willy/types/willyTypes';
import allServices from 'constants/services';
import { useMemo, useState } from 'react';
import { AttributionData } from 'types/attribution';

type WorkflowInAttributionPageProps = {
  opened: boolean;
  onClose: () => void;
  attribution?: AttributionData;
};

export const WorkflowInAttributionPage: React.FC<WorkflowInAttributionPageProps> = ({
  opened,
  attribution,
  onClose,
}) => {
  const entity = attribution?.entity;
  let service = allServices[attribution?.serviceId!];

  const entityWorkflows = useComputedValue($globalSequences, (gs) => {
    return gs.filter(
      (s) =>
        entity &&
        s.pixelSettings &&
        s.pixelSettings?.[entity] &&
        s.pixelSettings?.[entity]?.use &&
        (s.pixelSettings?.[entity]?.providers?.length === 0 ||
          s.pixelSettings?.[entity]?.providers?.includes(service?.id)),
    );
  });
  const [selectedWorkflow, setSelectedWorkflow] = useState<WillyDataSequence | null>(null);

  const variables = useMemo((): Record<string, string> | undefined => {
    if (entity === 'channel') {
      return { channel: attribution?.id ?? '' }; // Use ?? for null or undefined fallback
    } else if (entity === 'campaign') {
      return {
        channel: attribution?.serviceId ?? '',
        campaign_id: attribution?.id ?? '',
      };
    } else if (entity === 'adset') {
      return {
        channel: attribution?.serviceId ?? '',
        campaign: attribution?.campaignId ?? '',
        adset: attribution?.id ?? '',
      };
    } else if (entity === 'ad') {
      return {
        channel: attribution?.serviceId ?? '',
        campaign: attribution?.campaignId ?? '',
        adset: attribution?.adsetId ?? '',
        ad: attribution?.id ?? '',
      };
    } else {
      return undefined; // If no entity matches, return undefined
    }
  }, [entity, attribution]);

  // Memoize based on 'entity' and 'attribution'

  if (!attribution) {
    return null;
  }

  const SurveyServiceIds = Object.values(allServices)
    .filter((s) => s.type === 'survey')
    .map((s) => s.id);

  const title = () => {
    const { id, entity, name } = attribution;

    const isPpSurvey = entity === 'campaign' && SurveyServiceIds.includes(id as any);
    const nameToDisplay =
      entity === 'channel' || isPpSurvey ? service?.title || name || id : String(name || id);
    return nameToDisplay;
  };

  return (
    <Modal
      open={opened}
      onClose={onClose}
      title={
        <div>
          <div className="flex items-center gap-4">
            {typeof service?.icon === 'function' && (
              <span className="flex items-center">{service?.icon?.({ small: true })}</span>
            )}
            <span>Insights for {title()}</span>
          </div>
        </div>
      }
    >
      <div className="min-h-[400px]">
        <InsightsPopupInner
          workflows={entityWorkflows}
          selectedWorkflow={selectedWorkflow}
          setSelectedWorkflow={setSelectedWorkflow}
          useDatePickers={selectedWorkflow?.pixelSettings?.[entity || 'campaign']?.useDatePickers}
          variables={variables}
          source="pixel"
          sourceDetails={{ entity: entity || 'campaign', id: attribution.id || '' }}
        />
      </div>
    </Modal>
  );
};
