import React, { useState } from 'react';
import moment, { Moment } from '@tw/moment-cached/module/timezone';

type TimePickerProps = {
  date: Date;
  onChange: (date: Moment) => void;
};

const TimePicker: React.FC<TimePickerProps> = ({ date, onChange }) => {
  const [val, setVal] = useState(moment(date));

  const _onChange = (_date) => {
    var h = _date.split(':')[0];
    var m = _date.split(':')[1];
    var d = moment(date).hours(h).minutes(m).seconds(0);
    onChange(d);
    setVal(d);
  };

  return (
    <input
      type="time"
      id="appt"
      name="appt"
      value={val.format('HH:mm')}
      onChange={({ currentTarget }) => {
        _onChange(currentTarget.value);
      }}
    ></input>
  );
};

export default TimePicker;
