import { $store } from '@tw/snipestate';
import { SQLModel } from 'components/Willy/constants';

type MainChatStore = {
  sqlGeneratingModel: SQLModel;
};

export const $mainChatStore = $store<MainChatStore>({
  sqlGeneratingModel: 'default',
});

export const setSqlGeneratingModel = (sqlGeneratingModel: SQLModel) => {
  $mainChatStore.set({ sqlGeneratingModel });
};
