import allServices from 'constants/services';
import { defaultExpressionSettings } from 'ducks/serviceSegmentations';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  FormLayout,
  Icon,
  Modal,
  Stack,
  Subheading,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { DeleteMinor, MobilePlusMajor } from '@shopify/polaris-icons';
import { type MetricsFilterExpression } from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';

import DropDown from './ltv/DropDown';
import { FilterFieldAutocomplete } from './ltv/FilterFieldAutocomplete';
import { Select, Text, TextInput, Icon as UiIcon } from '@tw/ui-components';
import { IconMapper } from 'iconMap';

export interface SegmentType {
  id?: string;
  segmentTitle: string;
  segmentDescription: string;
  expressionList: MetricsFilterExpression[];
  enabled: boolean;
}

type Props = {
  onClose: () => void;
  isOpen: boolean;
  isEdit: boolean;
  editSegment?: (seg: SegmentType) => void;
  createSegment?: (seg: SegmentType) => void;
  editFilter?: (seg: SegmentType) => void;
  createFilter?: (seg: SegmentType) => void;
  currentSegment?: SegmentType;
  operands: { label: string; value: string }[];
  search: (searchStr: string, entity: string) => any;
  type: ServicesIds;
  id?: string;
  getOperators: (operand: string) => { label: string; value: string }[];
  serviceId: ServicesIds;
  operandsWithNoSearchList?: any[];
  handleNew: (serviceId: ServicesIds) => void;
  isFilter?: boolean;
  currentFilter?: SegmentType;
  defaultExpression?: any;
  operatorBetweenExpressions?: 'and' | 'or';
};

export const CreateEditSegmentModal: React.FC<Props> = ({
  isFilter = false,
  onClose,
  isOpen,
  isEdit,
  editSegment,
  createSegment,
  currentSegment,
  editFilter,
  createFilter,
  currentFilter,
  search,
  type,
  operands,
  id,
  getOperators,
  serviceId,
  operandsWithNoSearchList = [],
  handleNew,
  operatorBetweenExpressions = 'and',
  defaultExpression = defaultExpressionSettings,
}) => {
  const name = type == 'shopify' ? 'Store' : allServices[type]?.title || type;
  const icon = type == 'shopify' ? IconMapper[type] : allServices[type]?.icon || <></>;

  const [expressionList, setExpressionList] = useState([defaultExpression]);

  const [segmentTitle, setSegmentTitle] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [pendingSave, setPendingSave] = useState(false);
  const [pendingDelete, setPendingDelete] = useState(false);

  const handleClose = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (!isOpen) return;
    setExpressionList([defaultExpression]);
  }, [isOpen, defaultExpression]);

  const buildExpressionObject = () => {
    let obj: SegmentType = {
      segmentTitle: '',
      segmentDescription: '',
      expressionList: [],
      enabled: true,
    };
    obj.expressionList = expressionList;
    obj.segmentTitle = segmentTitle;
    obj.segmentDescription = segmentDescription || '';
    if (isEdit) {
      obj.id = id;
    }
    return obj;
  };

  const handleSubmit = async (isAlsoApply?) => {
    setPendingSave(true);
    !isFilter
      ? isEdit
        ? await editSegment?.(buildExpressionObject())
        : await createSegment?.(buildExpressionObject())
      : isEdit
        ? await editFilter?.(buildExpressionObject())
        : await createFilter?.(buildExpressionObject());
    if (isAlsoApply) {
      handleNew(serviceId);
    }
    handleClose();
  };

  const handleDelete = async () => {
    setPendingDelete(true);
    // await segmentOnDelete(id);
    handleClose();
  };

  const reset = useCallback(() => {
    setSegmentTitle('');
    setSegmentDescription('');
    setPendingSave(false);
    setExpressionList([defaultExpression]);
    setPendingDelete(false);
  }, []);

  const searchFilter = useCallback(
    (str, operand) => {
      return search(str, operand);
    },
    [search],
  );

  useEffect(() => {
    if (!currentSegment) return;
    const { segmentTitle, segmentDescription, expressionList } = currentSegment;
    setSegmentTitle(segmentTitle);
    setExpressionList(expressionList);
    setSegmentDescription(segmentDescription);
  }, [currentSegment]);

  useEffect(() => {
    if (!currentFilter) return;
    const { segmentTitle, segmentDescription, expressionList } = currentFilter;
    setSegmentTitle(segmentTitle);
    setExpressionList(expressionList);
    setSegmentDescription(segmentDescription);
  }, [currentFilter]);

  type PropValKey = 'value' | 'operator' | 'operand';
  type PropVal = string | { value: string; label: string };
  const setPropVal = (i: number, key: PropValKey, prop: PropVal) => {
    if (!(key === 'value' && typeof prop !== 'string' && prop.label === '')) {
      const list = expressionList.slice();
      const val = typeof prop === 'string' ? prop : prop.value;
      list[i] = { ...list[i], [key]: val };
      if (key === 'operand') {
        list[i].operator = '' as any;
      }
      setExpressionList(list);
    }
  };

  const addNewFilter = useCallback(() => {
    const list = expressionList.slice();
    list.push(defaultExpression);
    setExpressionList(list);
  }, [expressionList]);

  const deleteFilter = useCallback(
    (i) => () => {
      const arr = expressionList.slice();
      arr.splice(i, 1);
      setExpressionList(arr);
    },
    [expressionList],
  );

  return (
    <Modal
      large
      open={isOpen}
      onClose={handleClose}
      title={
        <div className="flex-container flex-children middle-children justify-start items-center gap-6.5">
          <Icon source={icon as any} />
          <>
            {isEdit ? 'Edit' : 'Create'} {name} {'Filter'}
          </>
        </div>
      }
      primaryAction={{
        id: `att-create-edit-${isFilter ? 'filter' : 'segment'}-save-apply`,
        onAction: () => handleSubmit(true),
        disabled:
          !segmentTitle ||
          expressionList.length === 0 ||
          expressionList.some((x) => !x.value || !x.operator),
        content: 'Save and Apply',
        loading: pendingSave,
      }}
      secondaryActions={[
        {
          id: `att-create-edit-${isFilter ? 'filter' : 'segment'}-cancel`,
          onAction: () => handleClose(),
          content: 'Cancel',
        },
      ]}
      // secondaryActions={
      //   isEdit
      //     ? [
      //         {
      //           id: `att-create-edit-${isFilter ? 'filter' : 'segment'}-delete`,
      //           onAction: handleDelete,
      //           content: 'Delete',
      //           loading: pendingDelete,
      //           destructive: true,
      //         },
      //       ]
      //     : []
      // }
    >
      <Modal.Section>
        <div className="flex flex-col gap-8">
          <div className="flex w-full gap-8">
            <div className="w-1/2">
              <TextInput
                label="Title"
                placeholder="Title"
                value={segmentTitle}
                onChange={setSegmentTitle}
              />
            </div>
            <div className="w-1/2">
              <TextInput
                label="Description"
                placeholder="Description"
                value={segmentDescription}
                onChange={setSegmentDescription}
              />
            </div>
          </div>
          {expressionList.map((expression, i) => (
            <Stack vertical key={i}>
              <Stack alignment={'center'} distribution={'equalSpacing'} wrap={false}>
                <Stack>
                  <DropDown
                    options={operands}
                    value={expression.operand}
                    handleSelect={(val) => setPropVal(i, 'operand', val)}
                  />
                  <DropDown
                    options={getOperators(expressionList[i].operand)}
                    value={expression.operator}
                    handleSelect={(val) => setPropVal(i, 'operator', val)}
                    title={!expression.operator?.length ? '-Select-' : ''}
                  />
                  {operandsWithNoSearchList.includes(expression.operand) ? (
                    <TextField
                      label=""
                      value={expression.value as any}
                      onChange={(val) => setPropVal(i, 'value', val)}
                      autoComplete="off"
                    />
                  ) : (
                    <FilterFieldAutocomplete
                      initVal={expression.value}
                      onSelectSearchResult={(val) => setPropVal(i, 'value', val)}
                      searchFunc={(text) => searchFilter(text, expressionList[i].operand)}
                      debounceMsTime={1000}
                    />
                  )}
                </Stack>

                <Tooltip content={'Delete Filter'}>
                  <Button
                    destructive
                    plain
                    onClick={deleteFilter(i)}
                    disabled={expressionList.length <= 1}
                    icon={DeleteMinor}
                  />
                </Tooltip>
              </Stack>
              {expressionList.length > 1 && i < expressionList.length - 1 && (
                <Subheading>{operatorBetweenExpressions}</Subheading>
              )}
            </Stack>
          ))}
          <div className="flex items-center gap-5 cursor-pointer" onClick={addNewFilter}>
            <UiIcon name="union" size={14} color="one.4" />
            <Text fw={500} color="one.4" size="md">
              {'Add Filter'}
            </Text>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};
