import './topbar.scss'; // the only reason we need this file is for the trick in TopBarStoresSwitcher to choose multiple stores in 3.0

import { $derived, useStoreValue } from '@tw/snipestate';
import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import { MainTopBar } from './MainTopBar';
import { PodsViewTopBar } from './PodsViewTopBar';
import { SimpleTopBar } from './SimpleTopBar';
import { AppsTopBar } from './AppsTopBar';

export type TopBarOption = 'apps' | 'pods' | 'simple' | 'main';

const $activeTopBar = $derived<TopBarOption>((get) => {
  const version = get($activeAppVersion);
  const { activeRoute: r } = get($activeRoute);

  if (!r || r.url.startsWith('/all-shops-admin')) return 'simple';
  if (!r.isSettingsPage && r.isHybridPage && version === '3.0') return 'apps';
  if (r.isShoplessPage) return 'pods';
  return 'main';
});

export const TopBar: React.FC = () => {
  const activeTopBar = useStoreValue($activeTopBar);

  switch (activeTopBar) {
    case 'apps':
      return <AppsTopBar />;
    case 'main':
      return <MainTopBar />;
    case 'pods':
      return <PodsViewTopBar />;
    case 'simple':
      return <SimpleTopBar />;
  }
};
