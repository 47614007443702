import { NavbarOption } from '$stores/nav-config-stores';
import { AlanNav } from '../components/navs/AlanNav';
import { MainNav } from '../components/navs/MainNav';
import { PodsViewNav } from '../components/navs/PodsViewNav';
import { SettingsNav } from '../components/navs/SettingsNav';

/** Available side nav - key/val map. */
export const NAVS: Readonly<Record<NavbarOption, JSX.Element>> = {
  alan: <AlanNav />,
  settings: <SettingsNav />,
  main: <MainNav />,
  pods: <PodsViewNav />,
};
