import axiosInstance from './axiosInstance';

/**
 *
 * @param {source currency} su_c
 * @param {target currency} ta_c
 * @param {date rate} date
 * @returns function with the rate just give number to convert
 */

export const fetchCurrenciesRate = async (su_c, ta_c, dateRange) => {
  const { start, end } = dateRange;
  const res = await axiosInstance.post('v2/currency-conversion/get-conversion-rate', {
    start: start.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
    fromCurrency: su_c,
    toCurrency: ta_c,
  });
  const { rates } = res.data;

  return average(Object.values(rates));
};
const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
