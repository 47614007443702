import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { useCallback } from 'react';
import { SummarySection } from 'types/SummarySection';

export const useFeatureFlagSummarySectionFilter = () => {
  const ffComputer = useFeatureFlagComputer();

  const filter = useCallback(
    (section: SummarySection) => {
      const { blockList: blockedSummarySections } = ffComputer.getConfigById(
        FeatureFlag.LIMIT_SUMMARY_SECTIONS_FF,
      );

      if (blockedSummarySections.length) {
        const isBlockedBySummarySectionLimit = blockedSummarySections.includes(section.id);
        if (isBlockedBySummarySectionLimit) return false;
      }

      // NOTE: keep these commented here in case we want to bring back limitting summary sections based on integration/services
      // const { blockList: blockedIntegrations } = ffComputer.getConfigById(
      //   FeatureFlag.LIMIT_INTEGRATIONS_FF
      // );
      // handle free version limit - don't show integrations that are not allowed
      // if (section.id !== 'webAnalytics' && blockedIntegrations.length && section.services?.length) {
      //   const isBlockedByIntegrationsLimit = section.services.some((serviceId) =>
      //     blockedIntegrations.includes(serviceId)
      //   );
      //   if (isBlockedByIntegrationsLimit) return false;
      // }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ffComputer, ffComputer.updateToggled],
  );

  return { summarySectionsFilter: filter };
};
