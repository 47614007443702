import { Button, Modal, TextStyle } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import USER_ROLES from '../../constants/userRoles';
import Select from 'react-select';
import { userDb } from 'utils/DB';
import { $user } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';

const roleOptions = [
  ...[{ label: '- Select role - ', value: -1 }],
  ...Object.values(USER_ROLES).map((u) => ({ label: u.label, value: u.id })),
];

const SelectRolePopUp = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const user = useStoreValue($user);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<{ value: any; label: any }>(roleOptions[0]);

  useEffect(() => {
    if (sessionStorage.getItem(currentShopId) === 'true') {
      setOpenModal(false);
      return;
    }
    if (window.location.pathname.includes('signup')) {
      setOpenModal(false);
      return;
    }
    if (!currentShopId) {
      setOpenModal(false);
      return;
    }
    if (!user || !user?.shops) {
      setOpenModal(false);
      return;
    }

    if (!user?.shops?.[currentShopId]?.role && !user.isAdminClaim) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [user, currentShopId]);

  const saveRole = async () => {
    try {
      if (selectedRole.value === -1) {
        return;
      }
      setOpenModal(false);
      await userDb().set(
        {
          shops: {
            [currentShopId]: {
              role: selectedRole.value,
            },
          },
        },
        { merge: true },
      );
      sessionStorage.setItem(currentShopId, 'true');
    } catch (err) {
      console.log('saveRole', err);
    }
  };

  return (
    <>
      <Modal
        title={'What is your role?'}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          sessionStorage.setItem(currentShopId, 'true');
          console.log('get Item afetr close', sessionStorage.getItem(currentShopId));
        }}
      >
        <div style={{ paddingTop: '1rem' }}>
          <Modal.Section>
            <TextStyle variation="strong">
              Tell us what you do, so we can help you do it better.
            </TextStyle>
            <div style={{ paddingTop: '3rem', paddingBottom: '1rem' }}>
              <Select
                defaultValue={selectedRole}
                options={roleOptions}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                minMenuHeight={170}
                onChange={(val) => {
                  setSelectedRole(val!);
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Modal.Section>
          <Modal.Section>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                primary
                onClick={() => {
                  saveRole();
                }}
              >
                Save
              </Button>
            </div>
          </Modal.Section>
        </div>
      </Modal>
    </>
  );
};

export default SelectRolePopUp;
