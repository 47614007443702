import './InfluencersTable.scss';

import TWTable from 'components/library/TWTable/TWTable';
import React, { FC, useContext } from 'react';
import { MetricsKeys } from 'types/metrics';

import { Card, Pagination, SortDirection, TextStyle } from '@shopify/polaris';

import { InfluencersContext } from '../context';
import { useSelector } from 'react-redux';
import { cx } from 'utils/cx';

export const InfluencersTable: FC = () => {
  const {
    influencers,
    loading,
    currency,
    sortDirection,
    sortBy,
    selectedColumns,
    pageIndex,
    totalInfluencers,
    setPageIndex,
    setSortBy,
    setSortDirection,
  } = useContext(InfluencersContext);

  const setSorting = (headingIndex: number, direction: SortDirection) => {
    setSortDirection!(direction);
    const column = selectedColumns?.[headingIndex];
    setSortBy!(column?.key as MetricsKeys);
  };
  const mainDatePickerSelectionRange = useSelector(
    (state: any) => state.mainDatePickerSelectionRange,
  );
  const PAGE_LIMIT = 20;
  const start = pageIndex! * PAGE_LIMIT;
  const end = pageIndex! * PAGE_LIMIT + PAGE_LIMIT;
  const currentPageInfluencers = influencers?.slice(start, end) || [];
  const metadata = {
    filteredRows: currentPageInfluencers.map((i) => i.metrics),
    mainDatePickerSelectionRange,
    currency,
  };

  return (
    <div className={'triple-table'}>
      <Card sectioned>
        <TWTable
          sortDirection={sortDirection!}
          sortBy={sortBy}
          onSort={setSorting!}
          columns={selectedColumns!}
          data={currentPageInfluencers}
          metadata={metadata}
          height={'82vh'}
          stickyHeader={true}
          stickyColumnIndex={2}
          loading={loading}
        />
      </Card>
      <div className="flex pt-6.5 p-4">
        <div className="flex items-center">
          <TextStyle variation="subdued">
            Page {pageIndex! + 1} of {Math.ceil(totalInfluencers! / PAGE_LIMIT!) || 1}
          </TextStyle>
        </div>
        <div
          className={cx(
            'm-auto',
            '[&_.Polaris-Button]:!border-none',
            'dark:hover:[&_.Polaris-Button]:bg-[var(--gray-dark-mode-600)]',
          )}
        >
          <Pagination
            hasPrevious={pageIndex! >= 1}
            onPrevious={() => setPageIndex?.((page) => page - 1)}
            hasNext={totalInfluencers! > (pageIndex! + 1) * PAGE_LIMIT}
            onNext={() => setPageIndex?.((page) => page + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default InfluencersTable;
