import { Button, Checkbox, Popover } from '@shopify/polaris';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import SaveFilterModal from './SaveFilterModal';
import {
  clearAppliedFilters,
  clearAnonymousFilter,
  enterRenameMode,
  insightsDeleteFilter,
  reloadData,
  setEditFilterData,
  FiltersContext,
  applyInsightsFilters,
  appliedInsightsFiltersIds,
  loadInsightsFilters,
} from 'ducks/insights/filterReducers';
import { useAppDispatch } from 'index';
import { FilterProperty, InsightsFilter } from '@tw/types/module/services/insights';
import FilterModal from './FilterModal';
import { toggleIsNavigationOpen } from 'ducks/actions';
import { TWTooltip } from 'components/library/TWTooltip/TWTooltip';
import { ActionIcon } from '@tw/ui-components';

type Props = {
  filterFields: FilterProperty[];
  filtersContext: FiltersContext;
  filterDescription?: {
    tooltipText: string;
    titleText: string;
  };
};
export default function InsightFilter({ filterFields, filtersContext, filterDescription }: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const [isActionListOpen, setIsActionListOpen] = useState(false);
  const [actionListId, setActionListId] = useState<string | null>();
  const [isFilterModalOpened, setIsFilterModalOpened] = useState<boolean>(false);
  const [isFilterNameModalOpened, setIsFilterNameModalOpened] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { insightsFiltersData, insightsFiltersDataLoaded } = useSelector(
    (state: RootState) => state.insightsFilter,
  );

  const { [filtersContext]: appliedFiltersIds } = useSelector(appliedInsightsFiltersIds);

  useEffect(() => {
    if (insightsFiltersDataLoaded) return;
    dispatch(loadInsightsFilters());
  }, [dispatch, insightsFiltersDataLoaded]);

  useEffect(() => {
    setSelectedFilters(appliedFiltersIds);
  }, [appliedFiltersIds]);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  useEffect(() => {
    setIsOpened(false);
  }, [isFilterModalOpened]);

  const isFilterRelevantForPage = useCallback(
    (filter: InsightsFilter): boolean => {
      const allFilterProps = filter.query.map((filter) => filter.property);
      const allComparators = filter.query.map((filter) => filter.comparator);

      const allPropertiesAllowed = allFilterProps.every((property) =>
        filterFields.includes(property),
      );

      return allPropertiesAllowed;
    },
    [filterFields],
  );

  const editFilter = (segment: InsightsFilter) => {
    dispatch(setEditFilterData(segment));
    setIsFilterModalOpened(true);
    setIsActionListOpen(false);
    setActionListId(null);
  };

  const deleteFilter = (id: string) => {
    insightsDeleteFilter(id);
    dispatch(reloadData());
    setIsActionListOpen(false);
    setActionListId(null);
  };

  const renameFilter = (segment: InsightsFilter) => {
    dispatch(setEditFilterData(segment));
    dispatch(enterRenameMode());
    setIsFilterNameModalOpened(true);
    setIsActionListOpen(false);
    setActionListId(null);
  };

  const clearFilter = () => {
    dispatch(clearAppliedFilters(filtersContext));
    dispatch(clearAnonymousFilter(filtersContext));
    setIsOpened(false);
  };

  const applyFilters = useCallback(() => {
    const newFiltersList = insightsFiltersData.filter((f) => selectedFilters.includes(f.id));

    dispatch(clearAnonymousFilter(filtersContext));
    dispatch(applyInsightsFilters(newFiltersList, filtersContext));

    setIsOpened(false);
  }, [dispatch, filtersContext, insightsFiltersData, selectedFilters]);

  const toggleSelectedFilter = useCallback(
    (filterId) => {
      const isSelected = selectedFilters.find((f) => f === filterId);
      if (isSelected) {
        setSelectedFilters([...selectedFilters.filter((f) => f !== filterId)]);
      } else {
        setSelectedFilters([...selectedFilters, filterId]);
      }
    },
    [selectedFilters],
  );

  return (
    <div>
      <div className="popover relative flex items-center gap-3">
        {appliedFiltersIds.length ? (
          <p className="absolute top-0 right-0 translate-y-[-50%] translate-x-[30%] text-white bg-blue-500 font-bold rounded-full px-2 text-lg w-7 h-7 flex items-center justify-center z-30">
            {appliedFiltersIds.length}
          </p>
        ) : null}
        {
          <Popover
            preventCloseOnChildOverlayClick
            active={isOpened}
            onClose={() => {
              setSelectedFilters([...appliedFiltersIds]);
              setIsOpened(false);
            }}
            activator={
              <>
                <ActionIcon
                  variant="activator"
                  icon="filter-major"
                  size="lg"
                  radius="xs"
                  onClick={() => setIsOpened(!isOpened)}
                />
              </>
            }
          >
            {insightsFiltersData.length ? (
              <Popover.Pane>
                <Popover.Section>
                  <div className="max-h-[190px] min-w-[220px]">
                    {insightsFiltersData.map((s) => (
                      <div key={s.id} className="relative flex items-center justify-between mb-3">
                        <div className="cb truncate">
                          <Checkbox
                            label={
                              <p className="truncate w-60">
                                <TWTooltip
                                  content={
                                    !isFilterRelevantForPage(s)
                                      ? 'Filter includes unsupported categories'
                                      : null
                                  }
                                >
                                  <span>{s.name}</span>
                                </TWTooltip>
                              </p>
                            }
                            checked={selectedFilters.includes(s.id)}
                            onChange={() => {
                              toggleSelectedFilter(s.id);
                            }}
                            disabled={!isFilterRelevantForPage(s)}
                          />
                        </div>
                        <div
                          className="menu-icon cursor-pointer"
                          onClick={() => {
                            if (isActionListOpen && actionListId === s.id) {
                              setIsActionListOpen(false);
                              setActionListId(null);
                            } else if (actionListId !== s.id) {
                              setIsActionListOpen(true);
                              setActionListId(s.id);
                            }
                          }}
                        ></div>
                        <Popover
                          active={isActionListOpen && actionListId === s.id && !isFilterModalOpened}
                          onClose={() => {
                            setIsActionListOpen(false);
                            setActionListId(null);
                          }}
                          activator={
                            <p
                              className="text-blue-600 hover:underline cursor-pointer flex"
                              onClick={() => {
                                if (isActionListOpen && actionListId === s.id) {
                                  setIsActionListOpen(false);
                                  setActionListId(null);
                                } else if (actionListId !== s.id) {
                                  setIsActionListOpen(true);
                                  setActionListId(s.id);
                                }
                              }}
                            >
                              <MobileVerticalDotsMajor className="w-6" />
                            </p>
                          }
                        >
                          <div className="p-4 flex flex-col">
                            <button
                              className="border-none bg-transparent text-left cursor-pointer hover:bg-slate-200 py-2 px-4 rounded"
                              onClick={() => editFilter(s)}
                              disabled={!isFilterRelevantForPage(s)}
                            >
                              Edit
                            </button>
                            <button
                              className="border-none bg-transparent text-left cursor-pointer hover:bg-slate-200 py-2 px-4 rounded"
                              onClick={() => renameFilter(s)}
                            >
                              Rename
                            </button>
                            <button
                              className="border-none bg-transparent text-left cursor-pointer hover:bg-slate-200 py-2 px-4 rounded"
                              onClick={() => deleteFilter(s.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </Popover>
                      </div>
                    ))}
                  </div>
                </Popover.Section>
              </Popover.Pane>
            ) : null}
            <Popover.Pane fixed>
              <p
                className="text-blue-600 hover:underline cursor-pointer py-5 pl-5 min-w-[220px]"
                onClick={() => {
                  dispatch(toggleIsNavigationOpen(false));
                  setIsFilterModalOpened(true);
                }}
              >
                Add/View Custom Filter
              </p>
            </Popover.Pane>
            <Popover.Pane fixed>
              <div className="actions flex items-center justify-end gap-4 py-5 mr-5">
                <Button primary onClick={applyFilters} disabled={!selectedFilters.length}>
                  Apply
                </Button>
                <Button onClick={clearFilter}>Clear</Button>
              </div>
            </Popover.Pane>
          </Popover>
        }
      </div>

      {isFilterNameModalOpened && (
        <SaveFilterModal
          filtersContext={filtersContext}
          onClose={() => {
            setIsFilterNameModalOpened(false);
          }}
        />
      )}

      {isFilterModalOpened && (
        <FilterModal
          filtersContext={filtersContext}
          filterProperties={filterFields}
          onClose={() => {
            setIsFilterModalOpened(false);
          }}
          onSave={() => {
            setIsFilterNameModalOpened(true);
          }}
          filterDescription={filterDescription}
        />
      )}
    </div>
  );
}
