import moment from '@tw/moment-cached/module/timezone';
import { useMemo } from 'react';
import { $currentDateRange, $currentDateSelectedOption } from '../$stores/willy/$dateRange';
import { useStoreValue } from '@tw/snipestate';

export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';

export enum DatePickerTimePeriods {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_90_DAYS = 'last90Days',
  LAST_365_DAYS = 'last365Days',
  LAST_MONTH = 'lastMonth',
  LAST_WEEK = 'lastWeek',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  THIS_QUARTER = 'thisQuarter',
  THIS_YEAR = 'thisYear',
  CUSTOM = 'custom',
}

export type DatePickerOptionValue = {
  start: moment.Moment;
  end: moment.Moment;
  id: DatePickerTimePeriods;
  specialPeriod?: 'week' | 'month' | 'quarter' | 'year';
  label: string;
};

export type DatePickerOption = {
  value: DatePickerOptionValue;
  id?: string;
  label: string | JSX.Element;
  active: boolean;
  disabled?: boolean;
};

// needs to run only after moment timezone is initialized
export function getDatePickerOptionValueOptions(): DatePickerOptionValue[] {
  return [
    {
      start: moment().startOf('day'),
      end: moment().endOf('day'),
      id: DatePickerTimePeriods.TODAY,
      label: TODAY,
    },
    {
      start: moment().subtract(1, 'day').startOf('day'),
      end: moment().subtract(1, 'day').startOf('day'), //.endOf('day') // bug with endOf day
      id: DatePickerTimePeriods.YESTERDAY,
      label: YESTERDAY,
    },
    {
      start: moment().subtract(7, 'days').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
      id: DatePickerTimePeriods.LAST_7_DAYS,
      label: 'Last 7 Days',
    },
    {
      start: moment().subtract(30, 'days').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
      id: DatePickerTimePeriods.LAST_30_DAYS,
      label: 'Last 30 Days',
    },
    {
      start: moment().subtract(90, 'days').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
      id: DatePickerTimePeriods.LAST_90_DAYS,
      label: 'Last 90 Days',
    },
    {
      start: moment().subtract(1, 'year').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
      id: DatePickerTimePeriods.LAST_365_DAYS,
      label: 'Last 365 Days',
    },
    {
      start: moment().subtract(1, 'month').startOf('month'),
      end: moment().subtract(1, 'month').endOf('month'),
      id: DatePickerTimePeriods.LAST_MONTH,
      label: 'Last Month',
    },
    {
      start: moment().startOf('week').subtract(1, 'week'),
      end: moment().startOf('week').subtract(1, 'day'),
      id: DatePickerTimePeriods.LAST_WEEK,
      label: 'Last Week',
    },
    {
      start: moment().startOf('week'),
      end: moment().endOf('day'),
      specialPeriod: 'week',
      id: DatePickerTimePeriods.THIS_WEEK,
      label: 'This Week',
    },
    {
      start: moment().startOf('month'),
      end: moment().endOf('day'),
      specialPeriod: 'month',
      id: DatePickerTimePeriods.THIS_MONTH,
      label: 'This Month',
    },
    {
      start: moment().startOf('quarter'),
      end: moment().endOf('day'),
      specialPeriod: 'quarter',
      id: DatePickerTimePeriods.THIS_QUARTER,
      label: 'This Quarter',
    },
    {
      start: moment().startOf('year'),
      end: moment().endOf('day'),
      specialPeriod: 'year',
      id: DatePickerTimePeriods.THIS_YEAR,
      label: 'This Year',
    },
  ];
}

export function getDatePickerOptionsDictionary(): { [key: string]: DatePickerOptionValue } {
  const options = getDatePickerOptionValueOptions();
  return options.reduce(
    (acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    },
    {} as Record<DatePickerTimePeriods, DatePickerOptionValue>,
  );
}

export const optionsWithActive = (selectedOptionId?: DatePickerTimePeriods) => {
  const datePickerOptionValueOptions = getDatePickerOptionValueOptions();
  let options: DatePickerOption[] = [
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.TODAY)!,
      id: 'summary_date_picker_today',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.TODAY)!.label,
      active: selectedOptionId === DatePickerTimePeriods.TODAY,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.YESTERDAY)!,
      id: 'summary_date_picker_yesterday',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.YESTERDAY)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.YESTERDAY,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_7_DAYS)!,
      id: 'summary_date_picker_last_7_days',
      label: 'Last 7 Days',
      active: selectedOptionId === DatePickerTimePeriods.LAST_7_DAYS,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_30_DAYS)!,
      id: 'summary_date_picker_last_30_days',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_30_DAYS)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.LAST_30_DAYS,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_90_DAYS)!,
      id: 'summary_date_picker_last_90_days',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_90_DAYS)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.LAST_90_DAYS,
    },
    {
      value: datePickerOptionValueOptions.find(
        (x) => x.id === DatePickerTimePeriods.LAST_365_DAYS,
      )!,
      id: 'summary_date_picker_last_365_days',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_365_DAYS)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.LAST_365_DAYS,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_MONTH)!,
      id: 'summary_date_picker_last_month',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_MONTH)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.LAST_MONTH,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_WEEK)!,
      id: 'summary_date_picker_last_week',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.LAST_WEEK)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.LAST_WEEK,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_WEEK)!,
      id: 'summary_date_picker_this_week',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_WEEK)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.THIS_WEEK,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_MONTH)!,
      id: 'summary_date_picker_this_month',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_MONTH)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.THIS_MONTH,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_QUARTER)!,
      id: 'summary_date_picker_this_quarter',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_QUARTER)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.THIS_QUARTER,
    },
    {
      value: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_YEAR)!,
      id: 'summary_date_picker_this_year',
      label: datePickerOptionValueOptions.find((x) => x.id === DatePickerTimePeriods.THIS_YEAR)!
        .label,
      active: selectedOptionId === DatePickerTimePeriods.THIS_YEAR,
    },
  ];

  return options;
};

export const useDatePickerSelectedOptions = () => {
  const currentDateRange = useStoreValue($currentDateRange);
  const currentDateSelectedOption = useStoreValue($currentDateSelectedOption);

  return useMemo(() => {
    if (!currentDateRange) {
      return [];
    }
    return optionsWithActive(currentDateSelectedOption ?? undefined);
  }, [currentDateRange, currentDateSelectedOption]);
};
