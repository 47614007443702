import { ActionList, ActionListItemDescriptor, Listbox, Popover } from '@shopify/polaris';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { Flex } from '@tw/ui-components';

type ItemWithAction = { id: string; name: string; optionsOpen?: boolean };

type ActionListItemDescriptorWithItem<T extends ItemWithAction> = ActionListItemDescriptor & {
  onItemAction?: (item: T) => void;
  disabledItem?: (item: T) => boolean;
  helpTextItem?: (item: T) => React.ReactNode;
};

type ItemsWithActionsProps<T extends ItemWithAction> = {
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  actions: ActionListItemDescriptorWithItem<T>[];
  onItemClicked?: (item: T) => void;
};

export function ItemsWithActions<T extends ItemWithAction>({
  items,
  setItems,
  actions,
  onItemClicked,
}: ItemsWithActionsProps<T>): JSX.Element {
  return (
    <Listbox>
      {items.map((item) => {
        return (
          <Listbox.TextOption key={item.id}>
            <Flex align="center" w="100%">
              <p
                onClick={() => {
                  onItemClicked?.(item);
                }}
              >
                {item.name}
              </p>
              <div className="flex ml-auto flex-shrink-0">
                <Popover
                  activator={
                    <MobileVerticalDotsMajor
                      className="cursor-pointer w-8 flex ml-auto"
                      onClick={(event) => {
                        event.stopPropagation();

                        setItems(
                          items.map((s) => {
                            if (s.id === item.id) {
                              return {
                                ...s,
                                optionsOpen: !s.optionsOpen,
                              };
                            }
                            return s;
                          }),
                        );
                      }}
                    />
                  }
                  active={!!item.optionsOpen}
                  onClose={() => {
                    setItems(
                      items.map((s) => {
                        if (s.id === item.id) {
                          return {
                            ...s,
                            optionsOpen: false,
                          };
                        }
                        return s;
                      }),
                    );
                  }}
                >
                  <ActionList
                    onActionAnyItem={() => {
                      setItems(
                        items.map((s) => {
                          if (s.id === item.id) {
                            return {
                              ...s,
                              optionsOpen: false,
                            };
                          }
                          return s;
                        }),
                      );
                    }}
                    items={actions.map((action) => ({
                      ...action,
                      onAction: () => action.onItemAction?.(item),
                      disabled: action.disabledItem?.(item) || action.disabled,
                      helpText: action.helpTextItem?.(item) || action.helpText,
                    }))}
                  />
                </Popover>
              </div>
            </Flex>
          </Listbox.TextOption>
        );
      })}
    </Listbox>
  );
}
