import {
  $derived,
  $effect,
  $mutableObserver,
  $observer,
  $store,
  useStoreValue,
} from '@tw/snipestate';
import {
  BASE_HEADER_HEIGHT,
  DATE_PICKER_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_BAR_HEIGHT_VAR,
} from 'components/TopBar/constants';
import { getAppConfigManager } from './AppConfigManager';
import { WindowWidth } from 'constants/windowSizes';
import { $windowWidth } from 'components/Nav/stores';

export const $navigationLoading = $derived((get) => get(getAppConfigManager().$config).loading);

export const $activeAppVersion = $derived(
  (get) => get(getAppConfigManager().$config).activeAppVersion,
);

export const $navigationSections = $derived(
  (get) => get(getAppConfigManager().$config).navSections,
);

export const $activeRoute = $derived((get) => get(getAppConfigManager().$config).activeRoute);

export const $activeSection = $derived((get) => get($activeRoute).activeSection);

export const useActiveRoute = () => useStoreValue($activeRoute);

/** Left side navigation option */
export type NavbarOption = 'alan' | 'settings' | 'main' | 'pods';

export const $activeNav = $derived((get): NavbarOption => {
  const version = get($activeAppVersion);
  const r = get($activeRoute).activeRoute;
  if (r?.isSettingsPage) return 'settings';
  if (r?.isShoplessPage) return 'pods';
  if (r?.isWillyPage || (r?.isHybridPage && version === '3.0')) return 'alan';
  return 'main';
});

/* NAV WIDTH PREFERENCE - used to track if user prefers wide or narrow nav */
export const $prefersWideNav = $store<boolean>(
  (() => {
    const pref = localStorage.getItem('prefers_wide_nav');
    return pref === null ? false : pref === 'true';
  })(),
);
$effect((_, get) => {
  const pref = get($prefersWideNav);
  localStorage.setItem('prefers_wide_nav', pref.toString());
});

export const $storesNavWidthPref = $store(Number(localStorage.getItem('stores_nav_width') || 50));

const $storesNavWidth = $derived((get) => {
  const newWidth = get($activeNav) === 'alan' ? 0 : get($storesNavWidthPref);
  localStorage.setItem('stores_nav_width', newWidth.toString());
  return newWidth;
});

const $innerNavWidth = $derived((get) => {
  const pref = get($prefersWideNav);
  const activeNav = get($activeNav);
  const windowWidth = get($windowWidth);

  if (windowWidth < WindowWidth.SMALL || activeNav === 'pods' || activeNav === 'settings')
    return 300 - 50;
  if (activeNav === 'alan') return 75;
  return (pref ? 300 : 125) - 50;
});

/* NAV WIDTH - used during collapsed/collapsing state */
export const $navWidth = $derived((get) => get($storesNavWidth) + get($innerNavWidth));
$effect((_, get) => {
  const navWidth = get($navWidth);
  document.documentElement.style.setProperty('--nav-width', navWidth + 'px');
});

const $isNavWide = $derived((get) => get($navWidth) > 300 / 2);

/** Used to see if nav should switch to wide style - not whether or not nav is of size `WIDE_NAV` (aka 300) */
export const useIsNavWideStyle = () => useStoreValue($isNavWide);

const $innerActiveRoute = $derived((get) => get($activeRoute).activeRoute);

/**
 * Store that keeps track of the height of the top bar.
 * We need this, because the main container of the app needs a fixed height to scroll correctly.
 *
 * **IMPORTANT** - ONLY USE $topBarHeight within the SnipeState hooks IF YOU CAN'T USE `var(--top-bar-height)` directly!!
 * This reduces rerenders.
 */
export const $topBarHeight = $mutableObserver(BASE_HEADER_HEIGHT, (get, set) => {
  const activeRoute = get($innerActiveRoute);
  if (!activeRoute) return;
  set(activeRoute.isWillyPage ? 0 : BASE_HEADER_HEIGHT);
});

$effect((_, get) => {
  const isZeroHeader = $innerActiveRoute.get()?.isWillyPage;
  const topBarHeight = get($topBarHeight);
  const val = isZeroHeader ? 0 : topBarHeight;
  document.documentElement.style.setProperty(TOP_BAR_HEIGHT, val + 'px');
  document.documentElement.style.setProperty(
    DATE_PICKER_HEIGHT,
    `calc(100vh - ${TOP_BAR_HEIGHT_VAR} - 60px)`,
  );
});
