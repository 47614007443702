import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  confirm,
  Flex,
  Icon,
  Modal,
  Text,
  Menu,
  Anchor,
  Tooltip,
  ActionIcon,
  Size,
  Switch,
} from '@tw/ui-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import _db from 'utils/DB';
import { FormLayout } from '@shopify/polaris';
import { RootState } from 'reducers/RootType';
import { useNavigate } from 'react-router';
import { copyDashboard, deleteMainElement } from '../utils/willyUtils';
import { messageTypes } from '../constants';
import { WillyEmojiLetter } from '../types/emojiTypes';
import { triggerLayout, useDashContext } from '../dashContext';
import {
  $isAdminClaim,
  $isShopOwner,
  $isTwGlobalDashboardCreatorClaim,
  $isTwSuperAdmin,
  $user,
} from '$stores/$user';
import { clearLastViewedDashboard } from '../utils/lastViewedDashboard';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { Portal } from 'components/Portal';
import { WillySection } from '../types/willyTypes';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DragHandle } from 'icons/drag-handle.svg';
import { openDashSettingModal } from '$stores/willy/$dashSettingsModal';
import { $customViewModal } from './stores';
import { WillySearch } from './WillyDashSearch';
import { upgradePlanClicked } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { addFavoriteDashboard, removeFavoriteDashboard } from '$stores/willy/$favoriteDashboards';
import axiosInstance from 'utils/axiosInstance';
import { checkIfUserTwAdmin } from 'ducks/user';
import { base64toBlob } from '../utils/willyUtils';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { updateChangeFolderModal } from './category-management/ChangeCategoryModal';
import { $canCreateDashboard, $createDashboardAccess } from '$stores/willy/$shopDashboards';
import { CreditBadge } from './CreditBadge';
import { LimitedAccessTooltip } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { CurrencyButton } from 'components/CurrencyButton';
import { ShareDashboardCTA } from './dashboard-header/ShareDashboardCTA';
import { $forceSharded } from '$stores/$shop';
import { EditDashboardCTA } from './dashboard-header/EditDashboardCTA';
import useDashLayout from '../hooks/useDashLayout';

type WillyDashDescriptionProps = {
  onResizeReorder?: () => void;
};
export const emptyEmoji = '🐳';
export const nonSavedEmptyEmoji = 'Aa';

export const emojiIsLetter = (emoji) => {
  if (emoji === 'Aa') {
    return true;
  }
  const listToCheck: WillyEmojiLetter[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  return listToCheck.includes(emoji);
};

type DashboardMenuOption = {
  content: string | React.ReactNode;
  onClick: () => void;
  leftSection?: React.ReactNode;
  color?: string;
  available: boolean;
  /** Sometimes, we want the user to see the option but just not be able to click it */
  disabled?: boolean;
  key?: string;
};

export const WillyDashDescription: React.FC<WillyDashDescriptionProps> = ({ onResizeReorder }) => {
  const isSmall = useIsSmall();
  const [dashboardSettingsOpen, setDashboardSettingsOpen] = useState<boolean>(false);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const {
    $packages,
    $defaultPackages,
    $dashboard,
    $dashboardId,
    $widgets,
    $orderedSectionsByLayout,
    $fields,
    $layout,
    $isShopDashboardFromGlobal,
    $pinnedSection,
    $standardDashboard,
    $displayDashboardActivity,
    $dashboardDialect,
    updateDashboard,
    updateWidgetFieldsInDb,
    updateFieldsInDb,
  } = useDashContext();
  const canCreateDashboard = useStoreValue($canCreateDashboard);
  const packages = useStoreValue($packages);
  const defaultPackages = useStoreValue($defaultPackages);
  const standardDashboard = useStoreValue($standardDashboard);
  const dashboard = useStoreValue($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const pinnedSection = useStoreValue($pinnedSection);
  const widgets = useStoreValue($widgets);
  const orderedSectionsByLayout = useStoreValue($orderedSectionsByLayout);
  const fields = useStoreValue($fields);
  const isShopDashboardFromGlobal = useStoreValue($isShopDashboardFromGlobal);
  const isTwSuperAdmin = useStoreValue($isTwSuperAdmin);
  const isAdmin = useStoreValue($isAdminClaim);
  const isShopOwner = useStoreValue($isShopOwner);
  const [layout, setLayout] = useWritableStore($layout);
  const [displayDashboardActivity, setDisplayDashboardActivity] =
    useWritableStore($displayDashboardActivity);
  const [forceSharded, setForceSharded] = useWritableStore($forceSharded);
  const { editLayout } = useDashLayout();
  const dashboardDialect = useStoreValue($dashboardDialect);

  const [activeId, setActiveId] = useState<string | null>(null);
  const userEmail = useSelector((state: RootState) => state.userEmail);
  const isUserAdmin = useSelector(checkIfUserTwAdmin);
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, { activationConstraint: { delay: 250, tolerance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [filteredSections, setFilteredSections] = useState(orderedSectionsByLayout);

  useEffect(() => {
    setFilteredSections(orderedSectionsByLayout);
  }, [orderedSectionsByLayout]);

  const [visibilitySectionModalOpen, setVisibilitySectionModalOpen] = useState(false);
  const shareToGlobal = useCallback(async () => {
    const conf = await confirm({
      title: 'Share To Public Templates',
      message: 'Are you sure you want to make this report public?',
    });
    if (conf) {
      await axiosInstance.post('v2/willy/copy-to-global', { dashboardId, shopId: currentShopId });
      toast.success('Dashboard made public!');
    }
  }, [currentShopId, dashboardId]);

  const summaryFullWidth = useSelector((state: RootState) => state.summaryFullWidth);

  const isSubpage = useMemo(
    () => dashboardId === 'share' || dashboardId === 'metrics' || dashboardId === 'new',
    [dashboardId],
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        triggerLayout();
        // update the layout in the db
        const newLayout = [...layout];
        const oldItemIndex = layout.findIndex((x) => x.i === active.id);
        const newItemIndex = layout.findIndex((x) => x.i === over?.id);
        if (oldItemIndex === -1 || newItemIndex === -1) {
          const newLayout: ReactGridLayout.Layout[] = orderedSectionsByLayout.map((section, i) => {
            const minH = section.title.includes('Untitled Section') // brittle, but it works
              ? 32
              : messageTypes[section.type]?.widgetMinHeight || 6;
            const minW = messageTypes[section.type]?.widgetMinWidth || 4;
            const h = minH || 17;

            if (section.sectionType === 'field') {
              const currIndex = fields?.findIndex((f) => f.id === section.id);
              return {
                i: section.id,
                key: section.id,
                x: currIndex % 2 ? 6 : 0,
                y: -1,
                w: 6,
                maxW: 12,
                minW: 3,
                h: 17,
                minH: 4,
              };
            }

            return {
              i: section.id,
              key: section.id,
              x: (i % 2 ? 2 : 0) * (summaryFullWidth ? 6 : 1),
              y: i * 4 * (summaryFullWidth ? 0.5 : 1),
              w: 12,
              maxW: 12,
              minW,
              h: h,
              minH,
            };
          });
          setLayout(newLayout);
          triggerLayout();
          await updateDashboard({ layout: JSON.stringify(newLayout) });
          return;
        }

        const oldItemX = layout[oldItemIndex].x;
        const oldItemY = layout[oldItemIndex].y;
        const newItemX = layout[newItemIndex].x;
        const newItemY = layout[newItemIndex].y;

        newLayout.map((l) =>
          oldItemY - newItemY > 0
            ? l.y >= newItemY && l.y < oldItemY
              ? (l.y += layout[oldItemIndex].h)
              : l.y
            : l.y > oldItemY && l.y <= newItemY
              ? (l.y -= layout[oldItemIndex].h)
              : l.y,
        );
        // move other things on the same Y axis to the new Y
        newLayout.map((l) => (l.y === oldItemY && l.x !== oldItemX ? (l.y = newItemY) : l.y));

        newLayout[oldItemIndex].x = newItemX;
        newLayout[oldItemIndex].y = newItemY;

        setLayout(newLayout);
        triggerLayout();

        await updateDashboard({ layout: JSON.stringify(newLayout) });
      }
      setActiveId(null);
    },
    [layout, setLayout, updateDashboard, summaryFullWidth, orderedSectionsByLayout, fields],
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const duplicateDashboard = useCallback(async () => {
    if (!dashboard || !dashboardId) return;
    const newId = await copyDashboard(
      dashboardId,
      currentShopId,
      userEmail,
      $user.get(),
      true,
      {
        name: dashboard?.name + ' (Copy)',
      },
      !!dashboard.isCustomView,
    );
    toast.success('Dashboard duplicated');
    navigate(`/dashboards/${newId}`);
  }, [currentShopId, dashboard, dashboardId, navigate, userEmail]);

  const menuSections = useMemo(() => {
    if (!dashboard) return [];

    const reportSection: DashboardMenuOption[] = [
      {
        content: (
          <ShareDashboardCTA
            activator={(onClick) => (
              <Flex onClick={onClick}>
                <Text size="sm" color="gray.8">
                  Share
                </Text>
              </Flex>
            )}
          />
        ),
        onClick: () => {},
        leftSection: <Icon name="share" size={16} />,
        available: !dashboard?.isGlobal && !dashboard?.isCustomView && !isSubpage && isSmall,
      },
      {
        content: (
          <EditDashboardCTA
            activator={
              <Flex align="center" justify="space-between" onClick={(e) => e.stopPropagation()}>
                <Text size="sm" color="gray.8">
                  Add Section
                </Text>
                <Icon name="chevron-right-minor" size={12} color="named.8" />
              </Flex>
            }
            dropdownPosition={'right-start'}
            dropDownOffset={45}
            withinPortal={false}
          />
        ),
        leftSection: <Icon name="plus" size={16} />,
        onClick: () => {},
        available: !isSubpage && dashboard.canEdit && isSmall && !editLayout,
      },
      {
        content: 'Dashboard Settings',
        onClick: () => {
          openDashSettingModal();
          setDashboardSettingsOpen(false);
        },
        leftSection: <Icon name="settings-2" size={16} />,
        available: dashboard.canEdit,
      },
      {
        content: 'Adjust Layout',
        onClick: () => {
          onResizeReorder?.();
          setDashboardSettingsOpen(false);
        },
        leftSection: <Icon name="adjust-layout" size={16} />,
        available:
          !!onResizeReorder && !(dashboard.isGlobal && dashboardDialect !== 'both') && !isSmall,
      },
      {
        content: 'Download as PDF',
        onClick: async () => {
          try {
            const params = new URLSearchParams(window.location.search);
            const start = params.get('start') || new Date().toISOString().split('T')[0];
            const end = params.get('end') || start;

            toast.success('PDF being generated; your download will start shortly');

            const { data } = await axiosInstance.post(
              '/v2/summary-page/try-report-send',
              {
                dashboards: [dashboard.id],
                startDate: start,
                endDate: end,
                shopId: currentShopId,
                shopDomain: currentShopId,
                recipients: [userEmail],
                format: 'pdf',
                isSendToEmail: false,
                useExactDates: true,
              },
              {
                timeout: 120000,
              },
            );

            if (!data || data.length <= 0) {
              toast.error('Error downloading PDF');
              return;
            }

            data?.map((file) => {
              try {
                const link = document.createElement('a');
                link.href =
                  file.path ??
                  window.URL.createObjectURL(
                    new Blob([base64toBlob(file.content, 'application/pdf')]),
                  );
                link.target = '_blank';
                link.download = file.filename;
                link.click();
                toast.success('PDF downloaded successfully');
              } catch (err) {
                toast.error('Error downloading PDF');
              }
            });
          } catch (err) {
            toast.error('Error downloading PDF');
          }
        },
        leftSection: <Icon name="pdf-file" size={16} />,
        available: true,
      },
      {
        content: (
          <CurrencyButton
            isShopContext={true}
            activator={(currency) => (
              <Flex align="center" justify="space-between" onClick={(e) => e.stopPropagation()}>
                <Text size="sm" color="named.9">
                  Currency: {currency}
                </Text>
                <Icon name="chevron-right-minor" size={12} color="named.8" />
              </Flex>
            )}
            dropdownPosition={'right-start'}
            dropDownOffset={45}
            withinPortal={false}
          />
        ),
        onClick: () => {},
        leftSection: <Icon name="currency-dollar" size={16} />,
        available: true,
      },
    ];

    const baseSection: DashboardMenuOption[] = [
      {
        content: 'Dashboard Activity',
        onClick: () => {
          setDisplayDashboardActivity(true);
          setDashboardSettingsOpen(false);
        },
        leftSection: <Icon name="clock" size={16} />,
        available:
          dashboard.canEdit &&
          !!setDisplayDashboardActivity &&
          !dashboard.isCustomView &&
          !dashboard.isGlobal &&
          (isShopOwner || isUserAdmin),
      },
      {
        content: dashboard.isFavorite ? 'Remove From Favorites' : 'Add To Favorites',
        onClick: async () => {
          if (dashboard.isFavorite) {
            removeFavoriteDashboard(dashboard.id);
          } else {
            addFavoriteDashboard(dashboard.id);
          }
        },
        leftSection: <Icon name="rating-star" size={14} />,
        available: !dashboard.isGlobal && !dashboard.isCustomView,
      },
      {
        content: (
          <LimitedAccessTooltip
            $store={$createDashboardAccess}
            target={(accessLimit) => (
              <div className={'flex flex-row justify-between gap-3'}>
                <div>Duplicate</div>
                <CreditBadge dashboard={dashboard} />
              </div>
            )}
          />
        ),
        onClick: () => {
          duplicateDashboard();
        },
        leftSection: <Icon name="duplicate" size={14} />,
        available: true,
        disabled: !canCreateDashboard,
      },
      {
        content: 'Move to Folder...',
        onClick: () => {
          updateChangeFolderModal({ opened: true, dashboard });
          setDashboardSettingsOpen(false);
        },
        leftSection: <Icon name="group" size={16} />,
        available: dashboard.canEdit,
      },
      {
        key: 'go-to-shop',
        content: 'Go To Original Dashboard',
        onClick: async () => {
          navigate({
            pathname: `/dashboards/${dashboard.isCustomViewOfDashboardId}`,
            search: window.location.search,
          });
        },
        available: false, //dashboard.isCustomView ?? false,
      },
      {
        content: pinnedSection?.hidden ? 'Show Pinned Section' : 'Hide Pinned Section',
        onClick: async () => {
          if (!pinnedSection) {
            return;
          }
          await updateWidgetFieldsInDb(pinnedSection.queryId, {
            hidden: !pinnedSection?.hidden,
          });
        },
        available: false, //dashboard.isCustomView ?? false,
      },
      {
        content: (
          <Tooltip
            multiline
            position="bottom"
            color="white"
            p="sm"
            style={{ boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25)' }}
            w="50vw"
            label={
              <Text fz="sm">
                A private board is a private, user-level copy of a shop board. Changes made to it
                won't affect the original board, and vice versa.
              </Text>
            }
          >
            <div>Save As a Private Dashboard</div>
          </Tooltip>
        ),
        onClick: () => {
          setDashboardSettingsOpen(false);
          $customViewModal.set({
            isOpen: true,
            dashboard,
          });
        },
        leftSection: <Icon name="line-arrow" size={16} />,
        available: false, //!dashboard.isCustomView && !dashboard.isGlobal,
      },
      {
        content: 'Turn Into Template',
        onClick: () => shareToGlobal(),
        leftSection: <Icon name="global" size={16} />,
        available:
          isTwGlobalDashboardCreatorClaim &&
          !isShopDashboardFromGlobal &&
          widgets?.length > 0 &&
          !dashboard.isGlobal &&
          !dashboard.isCustomView,
      },
      {
        content: 'Section Organizer',
        onClick: () => {
          setVisibilitySectionModalOpen(true);
          setDashboardSettingsOpen(false);
        },
        leftSection: <Icon name="sections-2" size={16} />,
        available: dashboard.canEdit && isTwGlobalDashboardCreatorClaim,
      },
      {
        content: (
          <Flex align="center" gap="xs">
            <Text size="sm" color="named.9">
              Force sharded (only admin)
            </Text>
            <Switch
              checked={forceSharded}
              onChange={() => setForceSharded(!forceSharded)}
              size="sm"
            />
          </Flex>
        ),
        leftSection: <Icon name="flash" />,
        onClick: () => {},
        available: isAdmin,
      },
    ].flat();

    const templatesSection: DashboardMenuOption[] = [
      {
        content: 'Reset Dashboard Layout',
        onClick: async () => {
          const conf = await confirm({
            title: 'Reset Layout',
            message: 'Are you sure you want to reset the layout?',
          });
          if (conf) {
            setLayout([]);
            triggerLayout();
            await updateDashboard({ layout: JSON.stringify([]) });
          }
        },
        leftSection: <Icon name="reset" size={16} />,
        available: dashboard.canEdit && !dashboard.isGlobal,
      },
      {
        content: 'Go To Template',
        leftSection: <Icon name="arrow-up-3" size={16} />,
        onClick: async () => {
          navigate({
            pathname: `/dashboards/${dashboard.globalDashboardId}`,
            search: window.location.search,
          });
        },
        available: !!(!dashboard.isGlobal && dashboard.globalDashboardId),
      },
    ];

    const deleteSection: DashboardMenuOption[] = [
      {
        content: 'Delete Dashboard',
        onClick: async () => {
          const res = await deleteMainElement(dashboard);
          if (res) {
            navigate(`/dashboards/summary`);
            clearLastViewedDashboard();
          }
        },
        color: 'red',
        leftSection: <Icon name="delete" color="red.6" size={20} />,
        available: !dashboard.isGlobal && !dashboard.isCustomView && !!dashboard.canDelete,
      },
      {
        key: 'remove',
        content: 'Remove Template',
        onClick: async () => {
          if (
            !(await confirm({
              title: 'Remove Template',
              message: 'Are you sure you want to remove this template from the public namespace?',
            }))
          )
            return;

          const isRemoved = await deleteMainElement(dashboard, packages, defaultPackages);
          if (isRemoved) {
            navigate({
              pathname: '/templates',
              search: window.location.search,
            });
            toast.success('Template removed from public namespace!');
          }
        },
        color: 'red',
        leftSection: <Icon name="delete" color="red.6" size={20} />,
        available: dashboard.isGlobal && dashboard.canEdit && isTwSuperAdmin,
      },
      {
        key: 'remove',
        content: 'Remove Private Dashboard',
        onClick: async () => {
          const isRemoved = await deleteMainElement(dashboard);
          if (isRemoved) {
            navigate({
              pathname: '/templates',
              search: window.location.search,
            });
            navigate(`/dashboards/${dashboard.isCustomViewOfDashboardId}`);

            toast.success(`Private Dashboard ${dashboard.name} successfully removed!`);
          }
        },
        color: 'red',
        leftSection: <Icon name="delete" color="red.6" size={20} />,
        available: dashboard.isCustomView ?? false,
      },
    ];

    const sections = [{ items: reportSection }, { items: baseSection }, { items: deleteSection }];

    return sections;
  }, [
    canCreateDashboard,
    dashboard,
    isShopOwner,
    isUserAdmin,
    pinnedSection,
    isTwGlobalDashboardCreatorClaim,
    isShopDashboardFromGlobal,
    widgets?.length,
    isTwSuperAdmin,
    currentShopId,
    userEmail,
    navigate,
    packages,
    defaultPackages,
    duplicateDashboard,
    updateWidgetFieldsInDb,
    setLayout,
    updateDashboard,
    shareToGlobal,
    setDisplayDashboardActivity,
    isSmall,
    isSubpage,
    forceSharded,
    setForceSharded,
    isAdmin,
    editLayout,
    dashboardDialect,
    onResizeReorder,
  ]);

  return dashboardId && (dashboard || standardDashboard) ? (
    <>
      <div className="flex items-center gap-4">
        <div className="inline-flex gap-2 items-center">
          <Menu shadow="md" position="bottom-end">
            <Menu.Target>
              <div className="relative">
                {dashboard?.canEdit && (
                  <ActionIcon
                    icon="three-dots"
                    variant="activatorWithHover"
                    size={30 as Size}
                    onClick={() => setDashboardSettingsOpen((x) => !x)}
                  />
                )}
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {menuSections.map((section, i) => (
                <div key={i}>
                  {section.items
                    .filter((option) => option.available)
                    .map((item, index) => {
                      const { available, key, ...rest } = item;
                      return (
                        <Menu.Item key={index} color="#1F2737" {...rest}>
                          {item.content}
                        </Menu.Item>
                      );
                    })}
                  {!!menuSections[i + 1]?.items.filter((opt) => opt.available).length && ( //if there are available items in the next section
                    <Menu.Divider key={i} />
                  )}
                </div>
              ))}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <Modal
        opened={visibilitySectionModalOpen}
        onClose={() => setVisibilitySectionModalOpen(false)}
        title="Sections"
        size="md"
      >
        <div className="flex flex-col gap-4">
          <WillySearch
            allSections={orderedSectionsByLayout}
            setFilteredSections={setFilteredSections}
          />

          <DndContext
            onDragEnd={handleDragEnd}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
          >
            <SortableContext
              items={filteredSections.map((x) => x.id)}
              strategy={rectSortingStrategy}
            >
              <FormLayout>
                <Text>Include</Text>
                {(dashboard?.isGlobal
                  ? filteredSections
                  : filteredSections.filter(
                      (x) => !x.hidden && !x.isProviderNotConnected && !x.isProviderLocked,
                    )
                ).map((section) => {
                  return (
                    <SectionCheckbox
                      key={section.id}
                      w={section}
                      checked={!section.hidden}
                      onChange={(v) => {
                        if (section.sectionType === 'widget') {
                          updateWidgetFieldsInDb(section.id, { hidden: !v });
                        } else {
                          updateFieldsInDb(section.id, { hidden: !v });
                        }
                      }}
                      filteredSections={filteredSections}
                      allSections={orderedSectionsByLayout}
                      widgets={widgets}
                      isGlobalDashboard={dashboard?.isGlobal}
                    />
                  );
                })}
                <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>
              </FormLayout>
            </SortableContext>
            <Portal>
              <DragOverlay>
                {activeId ? (
                  <SectionCheckbox
                    w={orderedSectionsByLayout.find((x) => x.id === activeId)!}
                    onChange={() => {}}
                    checked={!!orderedSectionsByLayout.find((x) => x.id === activeId)?.hidden}
                    filteredSections={filteredSections}
                    allSections={orderedSectionsByLayout}
                    widgets={widgets}
                  />
                ) : null}
              </DragOverlay>
            </Portal>
          </DndContext>
          <Text>More Sections</Text>
          {(dashboard?.isGlobal
            ? []
            : filteredSections.filter(
                (x) => x.hidden || x.isProviderNotConnected || x.isProviderLocked,
              )
          ).map((section) => {
            return (
              <SectionCheckbox
                key={section.id}
                w={section}
                checked={!section.hidden}
                onChange={(v) => {
                  if (section.sectionType === 'widget') {
                    updateWidgetFieldsInDb(section.id, { hidden: !v });
                  } else {
                    updateFieldsInDb(section.id, { hidden: !v });
                  }
                }}
                filteredSections={filteredSections}
                allSections={orderedSectionsByLayout}
                widgets={widgets}
              />
            );
          })}
        </div>
        <Modal.Footer>
          <div className="flex items-center justify-end gap-4">
            <Button
              variant="danger"
              onClick={async () => {
                const conf = await confirm({
                  title: 'Reset Layout',
                  message: 'Are you sure you want to reset the layout?',
                  modalProps: {
                    zIndex: 99999,
                  },
                });
                if (conf) {
                  setLayout([]);
                  triggerLayout();
                  await updateDashboard({ layout: JSON.stringify([]) });
                }
              }}
            >
              Reset Layout
            </Button>
            <Button
              onClick={() => {
                setVisibilitySectionModalOpen(false);
              }}
            >
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  ) : null;
};

const SectionCheckbox: React.FC<{
  w: WillySection;
  checked: boolean;
  onChange: (v: boolean) => void;
  filteredSections: any[];
  allSections: any[];
  widgets: any[];
  isGlobalDashboard?: boolean;
}> = ({ w, onChange, filteredSections, allSections, widgets, isGlobalDashboard }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: w.id,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const includedW = useMemo(() => {
    return !w.hidden && !w.isProviderNotConnected && !w.isProviderLocked;
  }, [w.hidden, w.isProviderLocked, w.isProviderNotConnected]);

  const style: React.CSSProperties = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? '100' : 'auto',
      opacity: isDragging ? 0.3 : 1,
      touchAction: 'manipulation',
    }),
    [isDragging, transform, transition],
  );
  return (
    <div
      ref={setNodeRef}
      style={style}
      className="group/metric flex items-center space-between gap-2 align-items-center"
    >
      <div className={'flex gap-3 items-center align-items-center justify-center'}>
        <div
          className={`${
            w.isProviderNotConnected || w.isProviderLocked ? 'pointer-events-none cursor-auto' : ''
          }`}
        >
          <Anchor
            size="sm"
            onClick={() => {
              onChange(!!w.hidden);
            }}
            disabled={w.isProviderNotConnected || w.isProviderLocked}
          >
            <Icon
              name={includedW ? 'minus' : 'plus'}
              size={includedW ? 15 : 10}
              color={w.isProviderNotConnected || w.isProviderLocked ? 'gray.2' : 'gray.5'}
            />
          </Anchor>
        </div>
        {(widgets.find((x) => x.id === w.id)?.permission?.providers || []).map((prov) => {
          return <Icon name={iconMapper[prov] ?? prov} key={prov} />;
        })}

        <Text size="sm">{w.title || 'Untitled Section'}</Text>
        {isTwGlobalDashboardCreatorClaim && w.dialect && (
          <Text size="sm">{w.dialect == 'bigquery' ? '(BQ)' : '(CH)'}</Text>
        )}
        {w.isProviderNotConnected && !w.isProviderLocked && (
          <a className={'text-[12px]'} href={'/integrations'} target="_blank" rel="noreferrer">
            Connect
          </a>
        )}
        {w.isProviderLocked && (
          <div
            className={'text-[12px] cursor-pointer text-blue-500 hover:text-blue-700 underline'}
            onClick={() => dispatch(upgradePlanClicked(navigate))}
          >
            Upgrade
          </div>
        )}
      </div>
      {(includedW || isGlobalDashboard) && filteredSections.length === allSections.length && (
        <div
          {...listeners}
          {...attributes}
          className="text-logo  flex mr-10 w-6 h-6 mt-[-2px] cursor-grab transition-opacity group-hover/metric:opacity-100"
        >
          <DragHandle />
        </div>
      )}
    </div>
  );
};

const iconMapper = {
  'facebook-ads': 'facebook-circle',
  'tiktok-ads': 'tiktok',
  'snapchat-ads': 'snapchat-circle',
  'pinterest-ads': 'pinterest',
  'shopify-segment': 'shopify',
  enquirelabs: 'fairing',
  'triple-whale': 'triple-whale-logo',
  'twitter-ads': 'twitter',
  bing: 'microsoft',
  GORGIAS: 'gorgias',
};
