import React, { useContext } from 'react';
import { WizardContext } from '../../WizardContext';
import { Flex, Text } from '@tw/ui-components';

export const WelcomeInstallStripePixel: React.FC = () => {
  const { nextStep } = useContext(WizardContext);

  return (
    <div>
      <Flex justify={'center'} align={'center'}>
        <Text align={'center'} weight={'bold'} size={'xl'}>
          Thank you for expressing interest in adding Triple Pixel to analyze your user's behavior!
          This feature is currently in pre-alpha mode, please contact our Customer Support team for
          updates regarding this installation.
        </Text>
      </Flex>
      <button className="step-button" onClick={nextStep}>
        Next
      </button>
    </div>
  );
};
