import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { $navigationSections } from '$stores/nav-config-stores';
import { useStoreValue } from '@tw/snipestate';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

const NavigationLink: React.FC<any> = ({ children, url = '', external, ref, ...rest }) => {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should

  const navigationSections = useStoreValue($navigationSections);
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  if (navigationSections.find((s) => s.routes.find((r) => r.url === url))) {
    // For left side menu
    return (
      <ReactRouterLink to={url} {...rest}>
        {children}
      </ReactRouterLink>
    );
  } else {
    // For /auth links
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }
};

export default NavigationLink;
