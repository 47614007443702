import moment from '@tw/moment-cached/module/timezone';

export function getGeneratedHours(day: string) {
  const hoursArray: string[] = [];
  let currentTime = moment(day).startOf('day');
  const isToday = moment(day).isSame(moment(), 'day');
  const endTime = isToday ? moment() : moment(day).endOf('day');
  while (currentTime <= endTime) {
    hoursArray.push(moment(currentTime).format('YYYY-MM-DDTHH'));
    currentTime = moment(currentTime).add(1, 'hour');
  }
  return hoursArray;
}

export function getGeneratedDates(
  startDate: string,
  endDate: string,
  granularity: 'hour' | 'day' | 'week' | 'month' | 'year' | 'quarter',
) {
  if (granularity === 'hour') return getGeneratedHours(startDate);
  const dateArray: string[] = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);
  const momentGranularity = granularity === 'week' ? 'isoWeek' : granularity;
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).startOf(momentGranularity).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, granularity);
  }
  return dateArray;
}
