import React from 'react';
import { connectToShopify } from '../../../../utils/connectToShopify';
import { GenericUrlConnect } from './GenericUrlConnect';

export const ShopifyConnect = ({ resetContinueWarn }) => {
  return (
    <div>
      <GenericUrlConnect
        resetContinueWarn={resetContinueWarn}
        connectAction={async (shopId) => {
          await connectToShopify(shopId);
        }}
      />
    </div>
  );
};
