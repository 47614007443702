import { $prefersWideNav, useIsNavWideStyle } from '$stores/nav-config-stores';
import { ActionIcon } from '@tw/ui-components';

export const NavResizer = () => {
  const isNavWide = useIsNavWideStyle();

  return (
    <div className="absolute top-0 right-0 h-full w-1">
      <div
        className={`rounded-full bg-white w-[20px] h-[20px] flex justify-center items-center
        shadow-xl absolute top-[calc(20px+var(--top-bar-height))] -translate-x-[7px] z-50`}
      >
        <ActionIcon
          size="sm"
          radius="round"
          variant="activator"
          icon={isNavWide ? 'chevron-left-minor' : 'chevron-right-minor'}
          iconSize={12}
          onClick={() => $prefersWideNav.set((x) => !x)}
        />
      </div>
    </div>
  );
};
