import { TimeFrame } from '@tw/types/module/services/insights';
import moment from '@tw/moment-cached';

export function isTimeFrameEnabled(timeFrame: TimeFrame, start, end): boolean {
  const isOneGroup = moment(start).diff(end, timeFrame) === 0;
  const isMoreThanYear = moment(start).diff(end, 'year') !== 0;
  const isMoreThanMonth = moment(start).diff(end, 'month') !== 0;
  const isOneYear = moment(end).diff(start, 'day') === 364;

  if (isMoreThanMonth && timeFrame === 'day') return false;
  if (isOneGroup && timeFrame === 'day') {
    return true;
  }
  if (isOneYear && timeFrame === 'year') return true;
  if (isOneGroup) {
    return false;
  }
  return true;
}
