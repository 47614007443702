import { SavedQueryType } from 'components/Willy/types/willyTypes';
import { editor } from 'monaco-editor';

import { Monaco } from '@monaco-editor/react';

import {
  SAVED_QUERIES_COLLECTION,
  SAVED_RULES_COLLECTION,
  SAVED_SNIPPETS_COLLECTION,
} from './constants';

export function getCollectionByQueryType(queryType?: SavedQueryType) {
  if (!queryType) {
    return null;
  }
  if (queryType === 'query') {
    return SAVED_QUERIES_COLLECTION;
  }
  if (queryType === 'snippet') {
    return SAVED_SNIPPETS_COLLECTION;
  }
  if (queryType === 'rule') {
    return SAVED_RULES_COLLECTION;
  }
  if (queryType === 'customMetric') {
    return 'custom_metrics';
  }

  return null;
}

export function updateEditorText(
  val: string,
  editorRef?: editor.IStandaloneCodeEditor,
  monacoRef?: Monaco,
) {
  const position = editorRef?.getPosition();
  if (position && monacoRef) {
    const Monaco = monacoRef;

    editorRef?.executeEdits('', [
      {
        range: new Monaco.Range(position.lineNumber, 1, position.lineNumber, 1),
        text: val,
      },
    ]);

    editorRef?.focus();

    const allValueInEditor = editorRef?.getValue();

    return allValueInEditor;
  }

  return '';
}

export const parseCSV = (csv) => {
  const rows = csv.split('\n');
  const headers = rows[0].split(',');

  const data = rows.slice(1).map((row) => {
    const values = row.split(',');
    return headers.reduce((acc, header, index) => {
      acc[header] = values[index];
      return acc;
    }, {});
  });

  return data;
};

export const generateDynamicTableData = (data) => {
  if (!data || data.length === 0) {
    return { headers: [], rows: [], columnContentTypes: [] };
  }

  const headers: any = Object.keys(data[0]);
  const rows: any = data.map((row) => headers.map((header) => row[header]));
  const columnContentTypes: any = headers.map(() => 'text'); // Adjust for numeric, dates, etc.

  return { headers, rows, columnContentTypes };
};
