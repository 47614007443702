import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { AlanLoaderGray } from 'components/AlanLoader';
import { CopyToClipboard } from './CopyToClipboard';
import React, { useMemo } from 'react';
import { Components, ExtraProps } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';

export type CodeAction = {
  onAction: (code: string) => void;
  text: string;
  supportedLanguages?: string[];
};

type WillySimpleTextProps = {
  text: string;
  loading?: boolean;
  loadingText?: boolean;
  classForCodeBlock?: string;
  error?: boolean;
  info?: boolean;
  prose?: boolean;
  smallText?: boolean;
  codeActions?: CodeAction[];
};

export const WillySimpleText: React.FC<WillySimpleTextProps> = ({
  text,
  loading,
  loadingText,
  codeActions,
  classForCodeBlock = '',
  error,
  info,
  smallText,
}) => {
  const components: Components = useMemo(() => {
    return {
      table: ({ node, ...props }) => (
        <table
          {...props}
          className="border-collapse border border-gray-300 dark:border-slate-700 m-0"
        />
      ),
      thead: ({ node, ...props }) => <thead {...props} className="bg-gray-100 dark:bg-slate-900" />,
      tbody: ({ node, ...props }) => <tbody {...props} className="bg-white dark:bg-slate-800" />,
      th: ({ node, ...props }) => (
        <th
          {...props}
          className="border-b dark:border-slate-600 font-medium p-4 !dark:text-slate-200 text-left"
        />
      ),
      td: ({ node, ...props }) => (
        <td
          {...props}
          className="border-b border-slate-100 dark:border-slate-700 p-4 !dark:text-slate-400"
        />
      ),
      p: ({ node, ...props }) => (
        <p {...props} className={`${info ? 'font-medium' : ''} leading-relaxed`} />
      ),
      pre: ({ node, ...props }) => {
        return (
          <CodeBlock
            node={node}
            {...props}
            codeActions={codeActions}
            classForCodeBlock={classForCodeBlock}
          />
        );
      },
      a: ({ node, ...props }) => <a {...props} target="_blank" className="text-[#0C70F2]" />,
      ul: ({ node, ...props }) => (
        <ul {...props} className={`${info ? 'marker:text-inherit' : ''} m-0`} />
      ),
      ol: ({ node, ...props }) => <ol {...props} className="m-0" />,
      li: ({ node, ...props }) => <li {...props} className="m-0" />,
      img: ({ node, ...props }) => (
        <img {...props} className="max-w-96 h-auto m-0 align-text-top rounded-md" />
      ),
    };
  }, [classForCodeBlock, codeActions, info]);

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <AlanLoaderGray />
      </div>
    );
  }

  if (typeof text !== 'string') {
    return null;
  }

  return (
    <div
      className={`w-full flex-auto prose dark:prose-invert max-w-full leading-relaxed text-[${
        smallText ? '14px' : '16px'
      }] md:text-[1rem] translate-x-0 ${
        info
          ? '!text-blue-800 font-medium'
          : error
            ? '!text-red-600'
            : 'text-gray-800 !dark:text-slate-200'
      }`}
    >
      <Markdown
        children={text}
        components={components}
        remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
      />
      {loadingText && <div className="blinking-cursor"></div>}
    </div>
  );
};

type CodeBlockProps = {
  codeActions?: CodeAction[];
  classForCodeBlock?: string;
  node?: ExtraProps['node'];
};

/**
 * CodeBlock component
 * The idea is this:
 * The first node is a `pre` tag, came from the markdown parser with the additional props
 * The first child of the `pre` tag is a `code` tag.
 * If it's not a code tag, we just render a normal `pre` tag with a `code` tag inside
 * If it's a code tag, we get the language from the className, and we use the `react-syntax-highlighter` to render the code
 */
export const CodeBlock: React.FC<CodeBlockProps> = React.memo(
  ({ classForCodeBlock, codeActions, node, ...props }) => {
    const codeElement = node?.children[0];
    if (codeElement?.type !== 'element' || codeElement.tagName !== 'code') {
      // some weird edge case that maybe will be one day, not supposed to happen, but just in case
      return (
        <pre {...props}>
          <code className={classForCodeBlock} />
        </pre>
      );
    }
    const textElement = codeElement.children[0];
    if (textElement?.type !== 'text') {
      // maybe the first child of the code tag is not a text node, also not supposed to happen
      return (
        <pre {...props}>
          <code className={classForCodeBlock} />
        </pre>
      );
    }
    const className = codeElement.properties.className as string;

    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : 'text';

    const code = textElement.value;

    return (
      <pre {...props}>
        <span className="flex flex-col gap-2 relative">
          <span className="flex gap-2 items-center fixed left-0 w-full px-6.5">
            {!!match && (
              <span className="text-gray-400 dark:text-slate-400 text-[12px]">
                {match[1].toUpperCase()}
              </span>
            )}
            <div className="ml-auto flex gap-2 items-center">
              {codeActions
                ?.filter((x) => x.supportedLanguages?.includes(language))
                .map((x, i) => (
                  <span
                    key={`code-action-${i}`}
                    className={`cursor-pointer rounded-md border-gray-400 border border-solid text-gray-400 px-2`}
                    onClick={() => {
                      x.onAction(code);
                    }}
                  >
                    {x.text}
                  </span>
                ))}
            </div>
            <span>
              <CopyToClipboard text={code} className="!fill-gray-400" />
            </span>
          </span>
          {match && (
            <SyntaxHighlighter
              language={match[1]}
              style={vscDarkPlus}
              wrapLongLines
              customStyle={{ background: 'transparent', marginTop: '2rem', paddingTop: '0' }}
            >
              {code}
            </SyntaxHighlighter>
          )}
          {!match && (
            <SyntaxHighlighter
              language="text"
              style={vscDarkPlus}
              // wrapLongLines
              customStyle={{ background: 'transparent', marginTop: '2rem', paddingTop: '0' }}
            >
              {code}
            </SyntaxHighlighter>
          )}
        </span>
      </pre>
    );
  },
);
