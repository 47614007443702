import { Caption, Spinner, Thumbnail } from '@shopify/polaris';
import ShopifyIconBase64 from 'components/ShopifyIconBase64';
import { fetchProduct } from 'ducks/products';
import { useEffect, useState } from 'react';

export default function PixelCartProduct({ productId, quantity, shopId }) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<any>(null);

  useEffect(() => {
    async function getProduct() {
      setLoading(true);
      const productData = (await fetchProduct(productId, false, shopId))?.[0]?.data();
      if (productData) setProduct(productData);
      setLoading(false);
    }
    getProduct();
  }, [productId, shopId]);

  return (
    <div style={{ display: 'flex', gap: '0.625rem', alignItems: 'center' }}>
      {loading && <Spinner />}
      {!loading && product && (
        <>
          <Thumbnail
            alt={product.title}
            source={product.image?.src || ShopifyIconBase64}
            size="small"
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Caption>{product.title}</Caption>
            <Caption>quantity: {quantity}</Caption>
          </div>
        </>
      )}
    </div>
  );
}
