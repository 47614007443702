import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@shopify/polaris';
import CircleLockIcon from './CircleLockIcon';
import { genericEventLogger, analyticsEvents, upgradeActions } from 'utils/dataLayer';
import { upgradePlanClicked } from '../../../ducks/subscription';
import { useAppDispatch } from '../../../index';
import { FeatureFlag, NullableFeatureFlagConfigValue } from '@tw/feature-flag-system/module/types';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { template, unescape } from 'lodash';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../../../$stores/willy/$subscription';

interface ILockedFeatureIndicator {
  featureFlag?: FeatureFlag;
  iconSize?: number;
  iconBorderColor?: string;
  iconOnly?: boolean;
  upgradeButton?: boolean;
  border?: boolean;
  forceShow?: boolean;
  layout?: 'vertical' | 'horizontal';
  useDefaultText?: boolean;
  extraData?: { targetToUnlock: string | number };
  styles?: CSSProperties;
  onlyIndicate?: boolean;
}

type CompileTextParams = {
  text: string;
  prefix?: string;
  suffix?: string;
  currentPlan?: string;
  nextPlan?: string;
  currentValue?: NullableFeatureFlagConfigValue;
};

export const compileLockText = ({
  text,
  prefix = '',
  suffix = '',
  currentPlan = '',
  nextPlan = '',
  currentValue = null,
}: CompileTextParams): string => {
  const compiledText = template(text, { escape: /{{([\s\S]+?)}}/g })({
    currentPlan: `${prefix}${currentPlan}${suffix}`,
    nextPlan: `${prefix}${nextPlan}${suffix}`,
    currentValue: `${prefix}${currentValue}${suffix}`,
  });

  return unescape(compiledText).replace(/to <.*><\/.*> to/g, 'to');
};

const LockedFeatureIndicator: FC<ILockedFeatureIndicator> = ({
  featureFlag,
  iconSize = 30,
  iconBorderColor,
  iconOnly = false,
  upgradeButton = true,
  border = true,
  forceShow = false,
  layout = 'horizontal',
  extraData = {},
  styles = {},
  onlyIndicate = false,
  useDefaultText = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmall = useIsSmall();

  // feature flag stuff
  const ffComputer = useFeatureFlagComputer();
  const { metadata, result } = ffComputer.getConfigById(featureFlag);
  const currentPlan = ffComputer.highestCurrentPackageMetaData?.name;
  const requiredPlan = !onlyIndicate
    ? ffComputer.getRequiredPlanToUnhideFeature(featureFlag, extraData.targetToUnlock)
    : '';
  const requiredPlanName = typeof requiredPlan === 'object' ? requiredPlan?.name || '' : '';
  const isEntityLocked = ffComputer.isEntityLocked({
    featureFlag,
    value: result,
    targetUnlockValue: extraData.targetToUnlock,
  });

  // local state
  const showUpgradeButton = upgradeButton && (!isSmall || layout === 'vertical');
  const [blockText, setBlockText] = useState('');
  const [unblockText, setUnblockText] = useState('');

  const isFreeShop = useStoreValue($isFreeShop);

  useEffect(() => {
    if (useDefaultText) return;

    setBlockText(
      compileLockText({
        text: metadata?.blockingText || '',
        currentPlan,
        nextPlan: requiredPlanName,
        currentValue: result,
      }),
    );
    setUnblockText(
      compileLockText({
        text: metadata?.unblockText || '',
        prefix: '<span class="font-medium text-logo">',
        suffix: '</span>',
        currentPlan,
        nextPlan: requiredPlanName,
        currentValue: result,
      }),
    );
  }, [
    requiredPlanName,
    result,
    currentPlan,
    useDefaultText,
    metadata.blockingText,
    metadata.unblockText,
  ]);

  const upgrade = useCallback(() => {
    genericEventLogger(analyticsEvents.UPGRADE, {
      action: upgradeActions.UPGRADE_CLICKED,
      path: featureFlag,
      ...extraData,
      upgrade_path: featureFlag,
      isFoundersDash: isFreeShop,
    });

    dispatch(upgradePlanClicked(navigate));
  }, [navigate, featureFlag, extraData, isFreeShop, dispatch]);

  const defaultUpgradeText = (
    <p className="text-primary text-[12px] text-center">
      Upgrade to{' '}
      <span onClick={upgrade} className="font-medium text-logo cursor-pointer underline">
        {requiredPlanName}
      </span>
      {requiredPlanName ? ' to ' : ''}
      unlock
    </p>
  );

  // only show LockFeatureIndicator if item is for sure blocked
  if (isEntityLocked !== true && !forceShow) return <></>;

  // we just want to provide and indicator without a click event to upgrade
  if (onlyIndicate) {
    return (
      <div style={styles}>
        <div className="flex shrink-0">
          <CircleLockIcon size={iconSize} borderColor={iconBorderColor} />
        </div>
      </div>
    );
  }

  if (iconOnly) {
    return (
      <div style={styles} onClick={upgrade}>
        <Tooltip content={defaultUpgradeText}>
          <div className="flex cursor-pointer shrink-0">
            <CircleLockIcon size={iconSize} borderColor={iconBorderColor} />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div
      style={styles}
      className={`px-[16px] flex gap-[8px] items-center
        ${border ? 'bg-[#F4F8FE] rounded-[8px] border border-logo border-solid' : ''} 
        ${layout === 'horizontal' ? 'flex-row py-[8px]' : 'flex-col py-[16px] text-center'}`}
    >
      <CircleLockIcon borderColor={iconBorderColor} />

      {useDefaultText ? (
        <div className={`${layout === 'horizontal' ? 'ml-[10px]' : 'max-w-[240px]'}`}>
          {defaultUpgradeText}
        </div>
      ) : (
        // dynamic text by limitation value
        <>
          {(!isSmall || layout === 'vertical') && blockText && (
            <p
              className={`text-primary font-medium text-[12px] ${
                layout === 'vertical' ? 'mt-[5px]' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: blockText }}
            />
          )}

          {unblockText && (
            <p className={`text-primary text-[12px] ${layout === 'horizontal' ? '' : 'mt-[5px]'}`}>
              {!showUpgradeButton ? (
                <span className="underline cursor-pointer text-logo" onClick={upgrade}>
                  Upgrade today
                </span>
              ) : (
                <span className="">Upgrade today</span>
              )}
              <span className="ml-[4px]" dangerouslySetInnerHTML={{ __html: unblockText }} />
            </p>
          )}
        </>
      )}

      {showUpgradeButton && (
        <button
          className={`bg-logo text-white font-medium text-[12px] border-none outline-none px-[17px] py-[9px] rounded-[4px] cursor-pointer
          ${layout === 'horizontal' ? 'ml-[auto]' : 'w-full max-w-[320px] mt-[30px]'}`}
          onClick={upgrade}
        >
          Upgrade
        </button>
      )}
    </div>
  );
};

export default LockedFeatureIndicator;
