import React, { useEffect, useState } from 'react';

export type Tab = {
  id: string;
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  component: JSX.Element;
  className?: string;
  order?: number;
};

type Props = {
  tabs: Tab[];
  selected?: string;
  onSelect?: (selectedTabIndex: string) => void;
};
const VerticalTabs: React.FC<Props> = ({ tabs, selected, onSelect }) => {
  const [selectedTab, setSelectedTab] = useState<string>(selected || '');

  useEffect(() => {
    setSelectedTab(selected || '');
  }, [selected]);

  useEffect(() => {
    onSelect?.(selectedTab);
  }, [selectedTab, onSelect]);

  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="flex flex-col gap-[34px] pr-[40px] mb-[20px] pb-[20px] lg:pb-[40px] border-b lg:border-r lg:border-b-0 border-0 border-solid border-[#E4E7EC] lg:mb-auto">
        <div className="text-[16px] bolder">Recommended installation for your shop:</div>
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            onClick={() => {
              setSelectedTab(tab.id);
            }}
            className={`tab-item cursor-pointer pl-[16px] border-0 border-solid border-l-[4px] whitespace-nowrap 
            ${tab.className} 
            ${
              tab.id === selectedTab
                ? 'text-logo border-logo'
                : 'text-secondary-text border-transparent'
            }`}
          >
            <span className={`text-[14px] bolder ${tab.id === selectedTab ? '' : ''}`}>
              {tab.title}
            </span>
            <span
              className={`text-[14px] regular ${
                tab.id === selectedTab ? 'text-secondary-text' : ''
              }`}
            >
              {tab.subTitle}
            </span>
          </div>
        ))}
        <div
          className="text-[16px] bolder pt-[20px] border-b lg:border-t lg:border-b-0 border-0 border-solid border-[#4B5363]"
          style={{ order: 2 }}
        >
          Additional installation options:
        </div>
      </div>
      <div className="pl-[19px] lg:pl-[40px] overflow-hidden">
        {tabs.find((t) => t.id == selectedTab)?.component}
      </div>
    </div>
  );
};

export default VerticalTabs;
