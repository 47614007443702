import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { chartOpenChanged } from 'ducks/cdp/segments';
import { useAppDispatch } from 'index';
import { type RootState } from 'reducers/RootType';
import { ActionIcon, Flex, Icon, Text, Tooltip } from '@tw/ui-components';
import SummaryDatePicker from 'components/SummaryDatePicker';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

const CDPHeader = () => {
  const shopCustomersCount: number = useSelector(
    (state: RootState) => state.customers.shopCustomersCount,
  );
  const { chartOpened, currentSegment } = useSelector((state: RootState) => state.CDPSegments);
  const dispatch = useAppDispatch();
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  const customersCount = useMemo(() => {
    if (currentSegment) {
      return `${currentSegment.size?.toLocaleString()} customers`;
    } else if (shopCustomersCount) {
      return `${shopCustomersCount.toLocaleString()} customers`;
    } else return '';
  }, [currentSegment, shopCustomersCount]);

  return (
    <Flex align="center" wrap="wrap" gap="md">
      {isThreePointOh && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="segmentation" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Customer Segments
          </Text>
        </Flex>
      )}
      <Tooltip label={`${chartOpened ? 'Hide' : 'Show'} Graph`}>
        <div>
          <ActionIcon
            size="lg"
            radius="sm"
            onClick={() => dispatch(chartOpenChanged(!chartOpened))}
            variant="activator"
            icon="line-chart"
            iconSize={20}
          />
        </div>
      </Tooltip>
      {customersCount && <Text> {customersCount}</Text>}
      <SummaryDatePicker disabled={!chartOpened} showGroupByButton={false} />
    </Flex>
  );
};

export default CDPHeader;
