import { Center, Loader } from '@tw/ui-components';
import { useState } from 'react';
import { cx } from 'utils/cx';

export type VideoIframeProps = {
  videoEmbed?: string;
  width?: number;
  height?: number;
};

export const VideoIframe: React.FC<VideoIframeProps> = ({
  videoEmbed,
  width = 350,
  height = 177,
}) => {
  const [loading, setLoading] = useState(true);

  if (!videoEmbed) return null;

  return (
    <div className="w-full h-full">
      {loading && (
        <Center mih={height}>
          <Loader />
        </Center>
      )}
      <div className={cx(loading && 'hidden')}>
        <iframe
          onLoad={() => setLoading(false)}
          width={width}
          height={height}
          src={videoEmbed}
          style={{ border: '0px' }}
        />
      </div>
    </div>
  );
};
