const InstagramIconBase64 = ({ small }) => (
  <img
    src="
  data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAABEJJREFUOE+NlGuIVVUUx3/7PO88U/sgQWhZ2UOYGaccRx0Rs4fYFyEt+5CVgVhqgaCDpiYq1RQllDFiD4rSIpMmjZBK0qIxdeZOM2aQoSI9zEnNybl37r1n771inytBINSGdfY5h7N/+/9f6+yluDRmLjya9YXxnlg8a/EQAmPJGENkLLGR8qyFwEUCoQY/Ac+obGvnlFsdSrnLnPuy4iMoMfhYfOsAhgoxVBhLmAItkRZC7cCCb8ATt9xt7cLnsYPTlXrkzs6sjx3vpzBDIJZKo8lYB9PE1hJZSyhCIIIvCg+FUgocUDkpHuJmFWTV0pa9EjiYaCIxbPj6Hjw/Ff6fQ4ywa+LOFGg95y1AtU7dLREJsdWs3D8nhW2fsQ2VKxBKWZkvgmcVvngocYny8MOAGQfn46CfNb2fWjYqQK2fskMC0VRLiaWd89lx+1t4uSFCZxMX7lPBE/CdZvFo6V5Kcm6IgzPfo+XwAvY3vu0yj3XAtsnvSLXVVEmJh75dyMcTXibGEIoL4cqmUYxrvz+1f/LpTzm7+ygTssvT50P1m2nqXcKBhtew6pLl9ubXpUoSKmyJOYee4Ivb2oisg1km9ay+bB77xj/zTyEassvpqnsVq8KywnebNotTl7ElZna10tm4LoUNm3ANN2xdwPf1q1K7rqquvjf1buC3tR1c2NWXVnZcz1P01m3CEpVz2DGxTWqCPJV+nub9z3N0+uI0d2O/bE/VHW9aDEmAmAAlHtcdeSF9f6x+TZrdG3vX8UPDc2ibJgr11R3LpSrIkfEL3PLJG5ya/UD6P17d8UG68PTd85BSgC1G2GLMqO7yRifqWlOb1/du5MeGjSlM2wzqu9mPSkWYJw5KjN7+IWfmz8IXS3RzPbWr2jg/dxZS8svAQszIfds5t2Ybgx1dKfDavuc5Vr8uvTcqRP304L2SCQuEfpGRWz/nwuNT3W+KslCz5ZvLFuV088PoXFWat9FHNnG8bjXaC7AqRv2y6C6JIgcsMeylA+RWNKYnU1kBC2rMJOIlZZtD7W0U9+xLYclgNUm+hlF9r3CibmWqzqoI1b+sRaKwSBBpqtZnKbY1o0oXUSJgPKQEUvSRYoAtRJh8jM5Voger0YURXNW5hZN1KzCuyl6E+nPtRAljgx9pMsu6wPPRb06DZAgMSEmQvIVBix1U2FyMHqzElIYx/KOdiLGcaliB8TJod5ZzL04SP2PxK32IQ4J5e9MO8r+GMfw6eRFJvhbjxWgiVH7rtO6gJmz0K+MUSBiiPK/cKo0giUYKGnIJ8lcJey5B9wvJ2QA9UEvp4hUUB4ajqSBRYU/ap8yeuaIqMhBHEISp7XS4wiQJUkiQfBG5WMSeL2D6Nfq0kJzJUByopTgwgkRXM7bvWdclL63NPtlNFDf+C2gs6ASKRWSoAIND2PNDmP4Sye+a0s8exT9qKFwY3jPm8OZGR/obH1YonG5xS7EAAAAASUVORK5CYII=
  "
    style={{ height: small ? 11 : 16 }}
    alt="Instagram icon"
  />
);

export default InstagramIconBase64;
