import { useMemo } from 'react';

export class Debouncer {
  private running = false;
  private curr: Function | null = null;
  private next: Function | null = null;
  private timeoutId?: NodeJS.Timeout;

  public constructor(private readonly ms = 0) {
    this.handle = this.handle.bind(this);
  }

  /**
   * Sets the provided callback as the "next" function to be called.
   * Starts a new timeout to execute this function when the currently
   * running function finishes and the timeout is over.
   */
  public handle(cb: Function) {
    this.next = cb;

    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      this.flush();
    }, this.ms);
  }

  /**
   * Does nothing if a function is currently getting execute,
   * Otherwise, calls the last saved function.
   */
  private async flush() {
    if (this.running) return;

    if (!this.curr) {
      this.curr = this.next;
      this.next = null;
    }
    const func = this.curr;
    if (!func) return;

    this.running = true;

    try {
      const res = func();
      if (res instanceof Promise) await res;
    } catch (err) {
      console.error('Error caught in debouncer:>>', err);
    } finally {
    }

    this.curr = null;
    this.running = false;
    this.flush();
  }
}

/**
 * @description This hook returns a debounce function that debounces
 * a function every `ms` but also makes sure that async functions are
 * run in order.
 *
 * @example
 * ```ts
 * const debounce = useDebouncer(3000);
 *
 * const someCallback = () => {
 *    setSomeState("something")
 *
 *    // debounces the expensive operation every 3 seconds
 *    debounce(() => someExpensiveOperation())
 * };
 * ```
 */
export function useDebouncer(ms: number = 0) {
  const debouncer = useMemo(() => new Debouncer(ms), [ms]);

  return debouncer.handle;
}
