export const saveLastViewedDashboard = (id: string) => {
  if (id.length > 0) {
    localStorage.setItem('lastViewedDashboard', id);
  }
};

export const clearLastViewedDashboard = () => {
  localStorage.removeItem('lastViewedDashboard');
};

export const getLastViewedDashboard = () => {
  return localStorage.getItem('lastViewedDashboard');
};
