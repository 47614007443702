import { BaseColumn } from 'components/library/TWTable/types';

export const getDefaultColumns = <D extends {} = any, COLUMN extends BaseColumn<D, any> = any>(
  storageKey: string,
  allColumns: COLUMN[],
  orderBy?: Parameters<Array<COLUMN>['sort']>[0],
  columnsFromDb?: string[],
): COLUMN[] => {
  let defaultColumns: COLUMN[] = [];
  const columnsFromLocalStorage = localStorage.getItem(storageKey);

  if (columnsFromLocalStorage || columnsFromDb) {
    const columnIds: string[] = columnsFromDb
      ? columnsFromDb
      : JSON.parse(columnsFromLocalStorage || '[]');

    defaultColumns = columnIds
      .map((x) => allColumns.find((column) => column.key === x)!)
      .filter((x) => x);

    // in case we have a fixed column that for some reason is not in local storage or in DB, we still want to show it
    const allFixedColumns = allColumns.filter((x) => x.isFixed);
    allFixedColumns.forEach((column, i) => {
      if (!defaultColumns.find((x) => x.key === column.key)) {
        defaultColumns.splice(i, 0, column);
      }
    });
  } else {
    defaultColumns = allColumns.filter((x) => x.isDefault);
    if (orderBy) {
      defaultColumns = defaultColumns.sort(orderBy);
    }
  }
  return defaultColumns;
};
