import { Box, Flex, Text, Tooltip } from '@tw/ui-components';
import { useNavigate } from 'react-router-dom';
import { CircleProgress } from '../blocks/CircleProgress';
import { useAppSelector } from 'reducers/RootType';
import { useMemo } from 'react';
import { useIsNavWideStyle } from '$stores/nav-config-stores';

export const OptimizationsSection = () => {
  const navigate = useNavigate();
  const isWide = useIsNavWideStyle();
  const completePercentage = useAppSelector((state) => state.onboarding.completePercentage);

  const progress = useMemo(() => {
    const formattedPercentage = completePercentage + '%';
    const label = isWide ? formattedPercentage : `Optimizations - ${formattedPercentage}`;
    return (
      <Tooltip label={label}>
        <Flex>
          <CircleProgress
            size={20}
            trackWidth={2}
            indicatorWidth={2}
            completePercentage={completePercentage}
          />
        </Flex>
      </Tooltip>
    );
  }, [completePercentage, isWide]);

  return (
    <Box fw={500} cursor="pointer" onClick={() => navigate('/optimizations')}>
      {isWide ? (
        <Flex gap="xs" align="center">
          {progress}
          <Text fw={500} fz="sm">
            Optimizations
          </Text>
        </Flex>
      ) : (
        <Flex justify="center" w="100%">
          {progress}
        </Flex>
      )}
    </Box>
  );
};
