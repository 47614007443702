const CURRENCIES = {
  USD: '$',
  JPY: '¥',
  GBP: '£',
  CNY: '¥',
  EUR: '€',
  ILS: '₪',
  RUB: '₽',
  SGD: '$',
  CAD: 'CAD',
  BRL: 'R$',
  AUD: 'A$',
  INR: '₹',
  MYR: 'RM',
  NZD: '$',
  AED: 'د.إ',
  ARS: 'ARS ',
  BDT: '৳',
  BOB: '$b',
  BGN: 'лв',
  CHF: 'CHF ',
  CLP: 'CLP$ ',
  COP: 'COP$ ',
  CRC: '₡',
  CZK: 'Kč ',
  DKK: 'Kr. ',
  DZD: 'DZD ',
  EGP: '£',
  GTQ: 'Q',
  HKD: 'HK$ ',
  HNL: 'L',
  HUF: 'Ft',
  IDR: 'Rp',
  ISK: 'Íkr ',
  KES: 'Ksh ',
  KRW: '₩',
  KWD: 'KD',
  MOP: 'MOP ',
  MXN: 'MXN$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  PEN: 'S/.',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RON: 'lei ',
  SAR: 'SAR ',
  SEK: 'SEK',
  THB: '฿',
  TRY: '₺',
  TWD: 'NT$',
  UAH: '₴',
  UYU: '$U',
  VND: '₫',
  ZAR: 'R',
  HRK: 'kn',
  OMR: 'ر.ع.',
};

export default CURRENCIES;
