import './InfluencersDashboard.scss';

import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import { ColumnsSelector } from 'components/library/TWTable/ColumnSelector';
import DropDown from 'components/ltv/DropDown';
import { metrics } from 'constants/metrics/metrics';
import React, { FC, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MetricsKeys } from 'types/metrics';

import { SortDirection, Spinner } from '@shopify/polaris';
import { SettingsMinor } from '@shopify/polaris-icons';

import { TW_INFLUENCER_COLUMNS } from '../constants';
import { InfluencersContext } from '../context';
import InfluencerCard from '../InfluencerCard/InfluencerCard';
import { columns } from '../InfluencersTable/columns';
import InfluencersTable from '../InfluencersTable/InfluencersTable';
import { Icon } from '@tw/ui-components';

const InfluencersDashboard: FC = () => {
  const {
    influencers,
    sortBy,
    setSortBy,
    selectedColumns,
    setSelectedColumns,
    sortDirection,
    setSortDirection,
    setPageIndex,
    totalInfluencers,
    displayMode,
    setDisplayMode,
  } = useContext(InfluencersContext);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center flex-wrap gap-6.5">
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-4 p-4">
            <ColumnsSelector
              columns={columns}
              selectedColumns={selectedColumns!}
              setSelectedColumns={setSelectedColumns as any}
              icon={<Icon name="columns-3-major" />}
              storageKey={TW_INFLUENCER_COLUMNS}
              title={
                <span className="flex items-center gap-2">
                  <SettingsMinor width={20} height={20} />
                  <span>Select Metrics</span>
                </span>
              }
            />
          </div>

          <div className="flex items-center gap-4">
            <p>Sort By</p>
            <DropDown
              showFilter
              options={Object.values(metrics)
                .filter((m) => m.showInInfluencersHub)
                .map((metric) => ({
                  label: metric.label,
                  value: metric.key,
                }))}
              value={sortBy || 'roas'}
              handleSelect={(val: MetricsKeys) => setSortBy!(val)}
            />
            <DropDown
              options={[
                { label: 'Ascending', value: 'ascending' },
                { label: 'Descending', value: 'descending' },
              ]}
              value={sortDirection || 'ascending'}
              handleSelect={(val: SortDirection) => setSortDirection!(val)}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <BubbleToggleButton
            onClick={() => setDisplayMode!('table')}
            active={displayMode === 'table'}
            icon="table-view"
          />
          <BubbleToggleButton
            onClick={() => setDisplayMode!('card')}
            active={displayMode === 'card'}
            icon="tiles-view"
          />
        </div>
      </div>

      {displayMode === 'table' && <InfluencersTable />}
      {displayMode === 'card' && (
        <InfiniteScroll
          dataLength={influencers?.length || 0}
          next={() => setPageIndex?.((index) => index + 1)}
          loader={
            <div className="flex justify-center items-center">
              <Spinner size="small" />
            </div>
          }
          hasMore={influencers!.length > 0 && totalInfluencers! > influencers!.length}
          scrollThreshold={0.9}
          endMessage={
            <p className="flex items-center justify-center p-4 no-more-orders-msg">
              We didn't find any {totalInfluencers! > 0 ? 'more ' : ''} affluencers
            </p>
          }
        >
          <div className="grid gap-6.5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 justify-items-stretch">
            {(influencers || []).map((influencer, i) => {
              return (
                <div className="influencers-card" key={i}>
                  <InfluencerCard influencer={{ ...influencer }} />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default InfluencersDashboard;
