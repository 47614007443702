import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SequenceFlowStep } from './SequenceFlowStep';
import {
  Dialect,
  RunSequenceRequest,
  StepWsStatus,
  WillyDataSequence,
  WorkflowStep,
  WorkflowWsStatus,
} from '../types/willyTypes';
import { TreeVerticalLine, TreeVerticalLineFull } from './TreeVerticalLine';
import { TreeHorizontalLine } from './TreeHorizontalLine';
import { ActionIcon, Button, confirm, Icon, Loader, Text, Tooltip } from '@tw/ui-components';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';
import { $dialect, $userEmail } from '$stores/$user';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';

import { v4 as uuidV4 } from 'uuid';
import { StepDrawer } from './StepDrawer';
import { $activeAccounts, $currency, $forceSharded, $industry } from '$stores/$shop';
import { useAppSelector } from 'reducers/RootType';
import { AlanLoaderGray } from 'components/AlanLoader';
import _db, { Timestamp } from 'utils/DB';
import { noop } from 'lodash';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { toast } from 'react-toastify';
import {
  EditNewSequenceProps,
  createNewSequence,
  createNewStep,
  editSequence,
  editStep,
  getParentRuleId,
  isStepInvalid,
} from '../utils/sequences';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { Link } from 'react-router-dom';
import { useSequenceFlowSocket } from './useSequenceFlowSocket';
import { useWillySocket } from '../WillySocket';
import { sleep } from 'utils/sleep';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { SequenceFlowRuleStep } from './SequenceFlowRuleStep';
import axiosInstance from 'utils/axiosInstance';
import { $socket } from '$stores/$socket';
import { useTimer } from './useTimer';
import { emptyEmoji } from '../dashboardManagment/WillyDashDescription';
import { $sequenceBuilderSequences } from '$stores/willy/$seqBuilder';

const space = 50;
type SequenceFlowProps = {
  sequence: WillyDataSequence;
  setSequence: React.Dispatch<React.SetStateAction<WillyDataSequence>>;
  depth: number;
  isFirstSibling: boolean;
  isLastSibling: boolean;
  disabledFlow?: boolean;
  noEdit: boolean;
  fromRule?: boolean;
  parentRunId?: string;
  variables?: Record<string, string>;
  runOnInit?: boolean;
  canAddNewStep?: boolean;
  rephraseStepsBasedOnConversation?: string;
  shelfIsOpen?: boolean;
  isNew?: boolean;
};

export const SequenceFlow: React.FC<SequenceFlowProps> = ({
  sequence,
  setSequence,
  depth,
  isFirstSibling,
  isLastSibling,
  disabledFlow,
  noEdit,
  fromRule,
  parentRunId,
  variables,
  runOnInit,
  canAddNewStep = true,
  rephraseStepsBasedOnConversation,
  shelfIsOpen,
  isNew,
}) => {
  const { socket } = useWillySocket();
  const realtimeSocket = useStoreValue($socket);
  const isSmall = useIsSmall();
  const isOnlySibling = isFirstSibling && isLastSibling;
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const user = useAppSelector((state) => state.user);
  const allSequences = useStoreValue($shopSequences);
  const [count, setCount] = useState(false);
  const defaultDialect = useStoreValue($dialect);

  const timer = useTimer({ from: 0, keepCount: count });

  const ranOnInitRef = useRef(false);
  const conversationId = useComputedValue($sequenceBuilderSequences, (x) => x.conversationId);

  const userEmail = useStoreValue($userEmail);

  const steps = useComputedValue($sequenceBuilderSequences, (x) => x.steps);

  const [errorRunSequence, setErrorRunSequence] = useState<string>();
  const [collapsedSteps, setCollapsedSteps] = useState<string[]>([]);
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [promptsWereChanged, setPromptsWereChanged] = useState(false);
  const [currentRunId, setCurrentRunId] = useState<string>();
  const [activeWorkflowId, setActiveWorkflowId] = useState<string>();
  const [openStepSettingDrawer, setOpenStepSettingDrawer] = useState(false);
  const [openRuleStepSettingsDrawer, setOpenRuleStepSettingsDrawer] = useState(false);
  const [wsWorkflows, setWsWorkflows] = useState<Record<string, WorkflowWsStatus>>({});
  const [wsSteps, setWsSteps] = useState<Record<string, StepWsStatus>>({});
  const [mainWorkflowStopped, setMainWorkflowStopped] = useState(false);
  const [indexToAddNewStep, setIndexToAddNewStep] = useState(0);

  const memoizedVariables = useDeepCompareMemoize(variables);

  const sequenceId = sequence?.id;

  const [ruleStepSettingDrawerRef, setRuleStepSettingDrawerRef] = useState<{
    stepId: string;
    type: 'passed' | 'failed';
  }>({ stepId: '', type: 'passed' });

  useSequenceFlowSocket({
    sequenceId: sequenceId,
    runId: parentRunId || currentRunId,
    setWsWorkflows,
    setWsSteps,
    setMainWorkflowStopped,
  });

  const workflowRunningOrLoading = wsWorkflows[sequenceId]?.status === 'running' || loadingAnswers;

  const allowRegenerate =
    !workflowRunningOrLoading && !Object.values(wsSteps).some((step) => step.status === 'running');

  const stepsWithoutParentRuleId = useMemo(() => {
    return sequence?.steps.filter((s) => !s.parentRuleId);
  }, [sequence?.steps]);

  const allStepsValid = useMemo(() => {
    return !stepsWithoutParentRuleId?.some((step) => isStepInvalid(step));
  }, [stepsWithoutParentRuleId]);

  const emailStepLink = useMemo(() => {
    // first check for outputFile
    const outputResponse =
      wsWorkflows && wsWorkflows[sequenceId]?.status === 'done' && wsWorkflows[sequenceId];

    if (outputResponse && outputResponse.outputFile) {
      const path = outputResponse.outputFile;
      return path && btoa(path);
    }

    // if no output, fall back to check if sendToEmail exists
    const foundStep = Object.keys(wsSteps).find((step) =>
      Object.keys(wsSteps[step])
        .map((key) => wsSteps[step]?.[key]?.stepType)
        .includes('sendToEmail'),
    );

    if (!foundStep || !wsSteps[foundStep]) {
      return [];
    }

    const stepResponse = wsSteps[foundStep].response;

    if (stepResponse && 'attachments' in stepResponse) {
      const path = stepResponse.attachments?.find((a) => a.format === 'JSON')?.path;
      return path && btoa(path);
    }

    return null;
  }, [sequenceId, wsSteps, wsWorkflows]);

  const readOnly = useMemo(() => {
    return (sequence?.isGlobal && !isTwGlobalDashboardCreatorClaim) || noEdit;
  }, [noEdit, sequence?.isGlobal, isTwGlobalDashboardCreatorClaim]);

  const firstStepStarted = useMemo(() => {
    const someStepStarted = Object.values(wsSteps).some((step) => !!step.status);
    return someStepStarted;
  }, [wsSteps]);

  const currency = useStoreValue($currency);
  const activeAccounts = useStoreValue($activeAccounts);
  const industry = useStoreValue($industry);
  const timezone = useAppSelector((state) => state.shopTimezone);
  const currentShopId = useAppSelector((state) => state.currentShopId);

  const toggleCollapse = useCallback(
    (stepId: string) => {
      if (collapsedSteps.includes(stepId)) {
        setCollapsedSteps(collapsedSteps.filter((id) => id !== stepId));
      } else {
        setCollapsedSteps([...collapsedSteps, stepId]);
      }
    },
    [collapsedSteps],
  );

  useEffect(() => {
    if (steps && depth === 0) {
      setSequence((s) => {
        return { ...s, steps };
      });
    }
  }, [steps, depth, setSequence]);

  const addNewStep = useCallback(
    (step: Partial<WorkflowStep>, ruleDescendant?: boolean) => {
      const newStep = createNewStep(step, userEmail);
      if (!newStep) {
        return;
      }

      if (ruleDescendant) {
        const stepId = ruleStepSettingDrawerRef.stepId;
        const type = ruleStepSettingDrawerRef.type;
        const parentRuleId = getParentRuleId(stepId, type);
        newStep.parentRuleId = parentRuleId;
      }

      setSequence((s) => {
        return {
          ...s,
          steps: [
            ...s.steps.slice(0, indexToAddNewStep),
            newStep,
            ...s.steps.slice(indexToAddNewStep),
          ],
        };
      });
    },
    [
      setSequence,
      indexToAddNewStep,
      userEmail,
      ruleStepSettingDrawerRef.stepId,
      ruleStepSettingDrawerRef.type,
    ],
  );

  const stepChange = useCallback(
    (newStep: WorkflowStep) => {
      const allSteps = sequence?.steps.map((step) => {
        if (step.id === newStep.id) {
          return editStep(step, newStep);
        }
        return step;
      }) || [newStep];
      setSequence((s) => {
        return { ...s, steps: allSteps };
      });

      setPromptsWereChanged(true);
    },
    [sequence?.steps, setSequence],
  );

  const deleteStep = useCallback(
    async (stepId: string) => {
      const confirmed = await confirm({ title: 'Delete step', message: 'Are you sure?' });
      if (!confirmed) {
        return;
      }
      setSequence((s) => {
        return {
          ...s,
          steps: s.steps.filter((step) => step.id !== stepId),
        };
      });

      setPromptsWereChanged(true);
    },
    [setSequence],
  );

  const saveSequenceIdsToStep = useCallback(
    (sequenceIds: string[], stepId: string) => {
      setSequence((s) => {
        return {
          ...s,
          steps: s.steps.map<WorkflowStep>((step) => {
            if (step.id === stepId && step.stepType === 'subSequence') {
              return { ...step, sequenceIds };
            }
            return step;
          }),
        };
      });
    },
    [setSequence],
  );

  const runSequence = useCallback(
    async (sequenceId: string, upToStepId?: string, onlyStepId?: string) => {
      if (!activeAccounts) {
        return;
      }
      let currentMessageId = uuidV4();
      const newRunId = uuidV4();
      setCurrentRunId(newRunId);
      const requestParams: RunSequenceRequest = {
        source: 'sequence',
        sequenceId,
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts ?? [],
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: false,
        currency,
        timezone: timezone,
        dialect: (sequence?.dialect as Dialect) || defaultDialect,
        forceSharded: $forceSharded.get(),
        industry: industry || 'other',
        upToStepId,
        onlyStepId,
        asDraft: isNew,
        conversationLink: window.location.href,
        variables: memoizedVariables,
        rephraseStepsBasedOnConversation,
      };
      try {
        setErrorRunSequence(undefined);
        // const response = await axiosInstance.post<RunSequenceResponse>(
        //   '/v2/willy/run-sequence',
        //   requestParams,
        // );

        await sleep(1000);

        // reconnect before emitting
        // socket.disconnect();
        // socket.connect();
        // socket.emit('run-sequence', requestParams);
        const response = await axiosInstance.post('/v2/sequences/workflows/run', requestParams);
        setCount(true);
        setActiveWorkflowId(response.data.workflowId);

        // const steps = response.data?.allSteps || [];
        // const allAnswersToReturn = steps.reduce<Record<string, WorkflowResponse>>((acc, step) => {
        //   acc[step.stepId] = step;
        //   return acc;
        // }, {});

        // setGeneratedAnswers(allAnswersToReturn);
      } catch (error: any) {
        const errorMessage = error.message || 'Error running sequence';
        console.error(errorMessage);
        setErrorRunSequence(errorMessage);
        setLoadingAnswers(false);
      }
    },
    [
      activeAccounts,
      currentShopId,
      currency,
      sequence?.dialect,
      defaultDialect,
      industry,
      timezone,
      memoizedVariables,
      rephraseStepsBasedOnConversation,
      isNew,
    ],
  );

  const onEditSequence = useCallback(
    async (seqId: string) => {
      if (sequence?.isGlobal && !isTwGlobalDashboardCreatorClaim) {
        toast.error('You are not allowed to edit global sequences');
      }
      try {
        const newSeqObject: EditNewSequenceProps = {
          dialect: sequence?.dialect || defaultDialect,
          messages: sequence?.steps || [],
          additionalShopIds: sequence?.additionalShopIds || [],
          baseMainElement: {
            id: seqId,
            type: 'sequence',
            canEdit: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            name: sequence?.name || '',
            description: sequence?.description || '',
            emoji: sequence?.emoji || emptyEmoji,
            isGlobal: sequence?.isGlobal || false,
            roles: sequence?.roles || [],
            isBeta: sequence?.isBeta || false,
            isHide: sequence?.isHide || false,
            category: sequence?.category || '',
            dialect: sequence?.dialect || defaultDialect,
            providers: sequence?.providers || [],
            providersBlockingCombination: sequence?.providersBlockingCombination || 'NONE',
            packages: sequence?.packages || [],
            defaultPackages: sequence?.defaultPackages || [],
            msps: sequence?.msps || [],
          },
        };
        await editSequence(newSeqObject, seqId);
      } catch (e) {
        console.error(e);
        toast.error('Could not save sequence');
      }
    },
    [
      sequence?.isGlobal,
      sequence?.description,
      sequence?.name,
      sequence?.emoji,
      sequence?.roles,
      sequence?.isBeta,
      sequence?.isHide,
      sequence?.category,
      sequence?.dialect,
      sequence?.providers,
      sequence?.providersBlockingCombination,
      sequence?.msps,
      isTwGlobalDashboardCreatorClaim,
      sequence?.packages,
      sequence?.defaultPackages,
      sequence?.additionalShopIds,
      sequence?.steps,
      defaultDialect,
    ],
  );

  const createSequence = useCallback(async () => {
    if (!currentShopId || !user.uid) {
      toast.error('Shop ID is required');
      return;
    }
    // const newDraftId = uuidV4();
    // setDraftId(newDraftId);
    const { error, success, conversationData } = await createNewSequence({
      shopId: currentShopId,
      userId: user.uid,
      dialect: sequence?.dialect || defaultDialect,
      conversationId,
      messages: sequence?.steps || [],
      additionalShopIds: sequence?.additionalShopIds || [],
      baseMainElement: {
        id: sequenceId,
        type: 'sequence',
        canEdit: false,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        name: sequence?.name || '',
        description: sequence?.description || '',
        emoji: sequence?.emoji || emptyEmoji,
        isGlobal: sequence?.isGlobal || false,
        roles: sequence?.roles || [],
        isBeta: sequence?.isBeta || false,
        isHide: sequence?.isHide || false,
        category: sequence?.category || '',
        dialect: sequence?.dialect || defaultDialect,
        providers: sequence?.providers || [],
        providersBlockingCombination: sequence?.providersBlockingCombination || 'NONE',
        packages: sequence?.packages || [],
        defaultPackages: sequence?.defaultPackages || [],
        msps: sequence?.msps || [],
      },
    });
    if (error) {
      toast.error(error);
    } else if (success) {
      if (sequence?.isGlobal && isTwGlobalDashboardCreatorClaim && conversationData) {
        await updateDashboardForFFConfigs(
          {
            configs: sequence?.packages || [],
            dashboardId: conversationData.id,
            mergeStrategy: 'merge',
          },
          FeatureFlag.TEMPLATES_FF,
        );
        await updateDashboardForFFConfigs(
          {
            configs: sequence?.defaultPackages || [],
            dashboardId: conversationData.id,
            mergeStrategy: 'merge',
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        );
      }
      return sequenceId;
    }
  }, [
    sequence?.category,
    sequence?.description,
    sequence?.dialect,
    sequence?.emoji,
    sequence?.packages,
    sequence?.defaultPackages,
    sequence?.isBeta,
    sequence?.isGlobal,
    sequence?.isHide,
    sequence?.msps,
    sequence?.name,
    sequence?.providers,
    sequence?.providersBlockingCombination,
    sequence?.roles,
    sequence?.steps,
    sequence?.additionalShopIds,
    conversationId,
    currentShopId,
    user.uid,
    isTwGlobalDashboardCreatorClaim,
    sequenceId,
    defaultDialect,
  ]);

  const onGenerateAnswer = useCallback(
    async (upToStepId?: string, onlyStepId?: string) => {
      setLoadingAnswers(true);
      if (promptsWereChanged || isNew) {
        if (isNew) {
          await createSequence();
        } else {
          await onEditSequence(sequenceId);
        }
      }
      await runSequence(sequenceId, upToStepId, onlyStepId);
    },
    [promptsWereChanged, runSequence, sequenceId, isNew, createSequence, onEditSequence],
  );

  const onTestWorkflow = useCallback(async () => {
    await onGenerateAnswer();
  }, [onGenerateAnswer]);

  const stopWorkflow = useCallback(async () => {
    setWsWorkflows({});
    setWsSteps({});
    setMainWorkflowStopped(true);
    setLoadingAnswers(false);
    socket.emit('stop-sequence', {
      sequenceId: sequenceId,
      shopId: currentShopId,
      messageId: currentRunId || '',
    });
    if (activeWorkflowId) {
      await axiosInstance.post('/v2/sequences/workflows/cancel', {
        shopId: currentShopId,
        workflowId: activeWorkflowId,
      });
    }
  }, [currentShopId, socket, sequenceId, currentRunId, activeWorkflowId]);

  useEffect(() => {
    if (mainWorkflowStopped) {
      setLoadingAnswers(false);
      setCount(false);
    }
  }, [mainWorkflowStopped]);

  useEffect(() => {
    setErrorRunSequence(
      wsWorkflows[sequenceId]?.status === 'error'
        ? wsWorkflows[sequenceId]?.error || 'Error running sequence'
        : undefined,
    );
  }, [wsWorkflows, sequenceId]);

  useEffect(() => {
    setIndexToAddNewStep(sequence?.steps?.length || 0);
  }, [sequence?.steps]);

  useEffect(() => {
    if (depth === 0 && runOnInit && !ranOnInitRef.current) {
      ranOnInitRef.current = true;
      onTestWorkflow();
    }
  }, [onTestWorkflow, depth, runOnInit, sequenceId]);

  return (
    <>
      {!firstStepStarted && loadingAnswers && (
        <div className="flex items-center justify-center gap-2 mb-4">
          <Loader size="xs" />
          <Text size="sm" fw={400} color={'gray.4'}>
            Starting workflow...
          </Text>
        </div>
      )}
      <div key={sequenceId}>
        <div className={`flex flex-col items-center ${depth === 0 ? '' : 'h-full'}`}>
          {!fromRule && (
            <TreeHorizontalLine
              isOnlySibling={isOnlySibling}
              isFirstSibling={isFirstSibling}
              isLastSibling={isLastSibling}
              depth={depth}
            />
          )}
          {depth !== 0 && (
            <>
              <TreeVerticalLine />
              <div className="flex w-full justify-center">
                <div
                  className={`group flex p-5 rounded items-center gap-3 justify-between cursor-pointer`}
                  style={{
                    backgroundColor: `${disabledFlow ? 'var(--mantine-color-gray-3)' : 'var(--mantine-color-orange-0)'}`,
                    width: `calc(700px - ${depth * space}px)`,
                  }}
                >
                  <div className="flex items-start gap-2">
                    {Object.values(wsWorkflows).some((w) => w.status === 'running') &&
                      wsWorkflows[sequenceId]?.status !== 'running' &&
                      wsWorkflows[sequenceId]?.status !== 'error' &&
                      wsWorkflows[sequenceId]?.status !== 'done' && (
                        <Icon name="clock" color="gray.4" size={16} />
                      )}
                    {wsWorkflows[sequenceId]?.status === 'running' && <Loader size="xs" />}
                    {wsWorkflows[sequenceId]?.status === 'error' && (
                      <Tooltip label={wsWorkflows[sequenceId]?.error}>
                        <span>
                          <Icon name="info" color="red.4" size={16} />
                        </span>
                      </Tooltip>
                    )}
                    {wsWorkflows[sequenceId]?.status === 'done' && (
                      <Icon name="check-thin" color="green.4" size={16} />
                    )}
                    <Text color="gray.8" size="sm">
                      <Link
                        to={{
                          pathname: `/workflows/create/${sequenceId}`,
                          search: window.location.search,
                        }}
                        target="_blank"
                        className="flex items-center gap-2 no-underline text-inherit"
                      >
                        <span>{sequence?.name}</span>
                        <Icon name="external-minor" color="gray.4" size={16} />
                      </Link>
                    </Text>
                  </div>
                </div>
              </div>
            </>
          )}
          {!!stepsWithoutParentRuleId?.length && !disabledFlow && (
            <div className="flex flex-col items-center">
              {stepsWithoutParentRuleId.map((step, stepNumber, arr) => {
                const generatedAnswer = wsSteps[step.id];
                const subSequences =
                  step?.stepType === 'subSequence'
                    ? step?.sequenceIds
                        ?.map((subseq) => {
                          return allSequences.find((seq) => seq.id === subseq);
                        })
                        .filter((seq) => !!seq)
                    : [];
                const lastStepInSequence =
                  stepsWithoutParentRuleId[stepsWithoutParentRuleId.length - 1].id === step.id;
                return (
                  <Fragment key={step.id}>
                    <SequenceFlowStep
                      dialect={sequence?.dialect || defaultDialect}
                      sequenceId={sequenceId}
                      step={step}
                      stepNumber={stepNumber}
                      onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                      stepChange={stepChange}
                      deleteStep={deleteStep}
                      toggleCollapse={toggleCollapse}
                      isCollapsed={collapsedSteps.includes(step.id)}
                      loadingAnswers={workflowRunningOrLoading}
                      allowRegenerateAnswers={allowRegenerate}
                      stepRunInfo={generatedAnswer}
                      depth={depth}
                      saveSequences={saveSequenceIdsToStep}
                      readOnly={readOnly}
                      noMoreStepsAllowed={fromRule && lastStepInSequence}
                      isLastStep={lastStepInSequence}
                    />
                    {step?.stepType === 'subSequence' && subSequences && !!subSequences.length && (
                      <div>
                        <div className="flex">
                          {subSequences?.map((subseq, i) => {
                            const sequence = allSequences.find((s) => s.id === subseq.id);
                            if (!sequence) return null;
                            return (
                              <SequenceFlow
                                disabledFlow={depth !== 0}
                                key={subseq.id}
                                sequence={sequence}
                                setSequence={setSequence}
                                depth={depth + 1}
                                isFirstSibling={i === 0}
                                isLastSibling={subSequences ? i === subSequences?.length - 1 : true}
                                noEdit={true}
                                parentRunId={currentRunId}
                                rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {depth === 0 && stepNumber !== arr.length - 1 && canAddNewStep && (
                      <div className="flex items-center">
                        <ActionIcon
                          icon="plus-1"
                          iconSize={20}
                          size="sm"
                          onClick={() => {
                            setIndexToAddNewStep(stepNumber + 1);
                            setOpenRuleStepSettingsDrawer(false);
                            setOpenStepSettingDrawer(true);
                          }}
                        />
                      </div>
                    )}
                    {(step?.stepType === 'condition' || step?.stepType === 'rule') && (
                      <SequenceFlowRuleStep
                        step={step}
                        sequenceId={sequenceId}
                        depth={depth + 1}
                        steps={sequence?.steps || []}
                        wsSteps={wsSteps}
                        space={space}
                        onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                        stepChange={stepChange}
                        deleteStep={deleteStep}
                        toggleCollapse={toggleCollapse}
                        loadingAnswers={workflowRunningOrLoading}
                        allowRegenerate={allowRegenerate}
                        saveSequenceIdsToStep={saveSequenceIdsToStep}
                        readOnly={readOnly}
                        collapsedSteps={collapsedSteps}
                        currentRunId={currentRunId}
                        shelfIsOpen={shelfIsOpen}
                        setOpenRuleStepSettingsDrawer={setOpenRuleStepSettingsDrawer}
                        setRuleStepSettingDrawerRef={setRuleStepSettingDrawerRef}
                        rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
                        dialect={sequence?.dialect || defaultDialect}
                        setSequence={setSequence}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
          {!sequence?.steps?.length && depth === 0 && canAddNewStep && (
            <div
              className={`w-[500px] border border-solid border-gray-300 rounded-lg p-5 cursor-pointer`}
              onClick={() => {
                setOpenStepSettingDrawer(true);
              }}
            >
              <Text fz={14} fw={500} color={'gray.4'}>
                Add step
              </Text>
            </div>
          )}
          <TreeVerticalLineFull />

          <TreeHorizontalLine
            isOnlySibling={isOnlySibling}
            isFirstSibling={isFirstSibling}
            isLastSibling={isLastSibling}
            depth={depth}
          />
        </div>
        {depth === 0 && (
          <div className="flex flex-col items-center gap-4">
            {canAddNewStep && (
              <>
                <TreeVerticalLine />
                <div
                  className={`open-step-drawer-trigger flex justify-center ${shelfIsOpen ? '' : 'cursor-pointer'}`}
                  onClick={() => {
                    setIndexToAddNewStep(sequence?.steps?.length || 0);
                    setOpenStepSettingDrawer(true);
                  }}
                >
                  <Icon name="plus-1" color={shelfIsOpen ? 'gray.3' : 'named.6'} size={45} />
                </div>
              </>
            )}
            <div>
              {workflowRunningOrLoading && (
                <div className="flex items-center w-full justify-center">
                  <span className="scale-50">
                    <AlanLoaderGray />
                  </span>
                </div>
              )}
              {!workflowRunningOrLoading && (
                <div>
                  {(Object.values(wsSteps).at(-1)?.response?.status === 'error' ||
                    !!errorRunSequence) && (
                    <div className="flex flex-col items-center">
                      <Text size="sm" fw={500} color={'red.4'}>
                        Error
                      </Text>
                      <Text size="sm" fw={400} color={'gray.4'}>
                        <Tooltip
                          label={
                            <div className="max-w-[300px] whitespace-pre-wrap">
                              {errorRunSequence || 'Error running sequence'}
                            </div>
                          }
                        >
                          <div className="max-w-[300px] overflow-hidden">
                            <span className="line-clamp-3">
                              {errorRunSequence ||
                                Object.values(wsSteps)
                                  .map((answer) => answer.error)
                                  .filter((error) => !!error)
                                  .join('\n')}
                            </span>
                          </div>
                        </Tooltip>
                      </Text>
                    </div>
                  )}
                  {Object.values(wsSteps).at(-1)?.response?.status === 'ok' && (
                    <div className="flex flex-col items-center">
                      <Text size="sm" fw={500} color={'green.4'}>
                        Success
                      </Text>
                      <Text size="sm" fw={400} color={'gray.4'}>
                        Workflow ran successfully
                      </Text>
                      {emailStepLink && (
                        <Link
                          target={isSmall ? '' : '_blank'}
                          to={`/workflows/view/${sequenceId}?fileName=${emailStepLink}`}
                        >
                          View Output
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {depth === 0 && (
              <div className="flex flex-col justify-end gap-4">
                <Button
                  disabled={sequence?.steps?.length === 0 || !allStepsValid}
                  onClick={() => {
                    if (workflowRunningOrLoading) {
                      stopWorkflow();
                    } else {
                      onTestWorkflow();
                    }
                  }}
                >
                  {workflowRunningOrLoading ? 'Stop' : errorRunSequence ? 'Retry' : 'Test Workflow'}
                </Button>
                {count && (
                  <div className="flex items-center gap-2 justify-center">
                    <Text size="sm" fw={400} color={'gray.4'}>
                      {timer}
                    </Text>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <StepDrawer
        addNewStep={addNewStep}
        open={openStepSettingDrawer}
        onClose={() => setOpenStepSettingDrawer(false)}
        isFirstStep={depth === 0 && sequence?.steps?.length === 0}
        sequenceId={sequenceId ?? undefined}
      />
      <StepDrawer
        addNewStep={addNewStep}
        open={openRuleStepSettingsDrawer}
        onClose={() => setOpenRuleStepSettingsDrawer(false)}
        isFirstStep={depth === 0 && sequence?.steps?.length === 0}
        fromRule={true}
      />
    </>
  );
};
