type WillyFormGroupProps = {
  children: React.ReactNode;
};

export const WillyFormGroup: React.FC<WillyFormGroupProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-4 rounded-md border border-solid border-gray-200 p-6.5">
      {children}
    </div>
  );
};
