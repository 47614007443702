import { Icon, Kbd, Text, Textarea, Tooltip } from '@tw/ui-components';
import { stepActionType, WorkflowStep, WorkflowStepVariable } from '../types/willyTypes';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { RichTextarea } from 'rich-textarea';

type FlowStepWithTextProps = {
  text: string;
  variables: WorkflowStepVariable[];
  readOnly: boolean;
  toolConfig?: stepActionType;
  depth: number;
  textChange: (text: string) => void;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowStepWithText = ({
  text,
  variables,
  readOnly,
  toolConfig,
  depth,
  textChange,
  setIsPristine,
}: FlowStepWithTextProps) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [placeholderHeight, setPlaceholderHeight] = useState<number>(0);
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);

  const placeholder = useMemo(() => {
    if (!toolConfig?.examplePrompts) {
      return '';
    }
    const index =
      ((placeholderIndex % toolConfig?.examplePrompts.length) + toolConfig?.examplePrompts.length) %
      toolConfig?.examplePrompts.length;
    return toolConfig?.examplePrompts[index];
  }, [placeholderIndex, toolConfig]);

  const saveDraft = useCallback(
    (promptText: string) => {
      textChange(promptText);
    },
    [textChange],
  );

  const keyHit = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Tab') {
        e.preventDefault(); // Prevent default tab behavior
        textChange(placeholder);
        setIsPristine(false);
      }
    },
    [placeholder, textChange, setIsPristine],
  );

  useLayoutEffect(() => {
    if (placeholderRef.current) {
      setPlaceholderHeight(placeholderRef.current.offsetHeight);
    }
  }, [placeholderRef.current?.offsetHeight]);

  return (
    <>
      {readOnly ? (
        <Text fw={500} fz={14} py="5.5px" px="12px">
          {text}
        </Text>
      ) : (
        <>
          <div className="w-full z-30 relative" style={{ minHeight: `${placeholderHeight}px` }}>
            <RichTextarea
              // fw={500}
              value={text}
              onChange={(e) => saveDraft(e.target.value)}
              onKeyDown={(e) => {
                setIsPristine(false);
                keyHit(e);
              }}
              autoHeight
              spellCheck={false}
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              style={{
                fontSize: '14px',
                fontWeight: '500',
                border: 'none',
                position: 'relative',
                width: `calc(700px - ${depth === 0 ? 25 : depth * 50}px)`,
                // maxWidth: '100%',
                // maxHeight: '300px',
                zIndex: 30,
                outline: 'none',
                cursor: 'text',
                resize: 'none',
                // height: 'auto',
                // marginLeft: '5px',
              }}

              // autosize
              // minRows={1}
              // maxRows={7}
              // noBorder
              // noBackground
            >
              {(text) => {
                const vars = text.match(/{{[^}]+}}/g);
                const textParts = text.split(/{{[^}]+}}/g);
                return (
                  <>
                    {textParts.map((part, index) => {
                      const v = vars?.[index];
                      const stepVar = variables?.find(
                        (v2) =>
                          v2.key ===
                          v?.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, p1) => p1.replace(/\s+/g, '')),
                      );

                      return (
                        <span key={index}>
                          {part}
                          {!!v && (
                            <Tooltip label={stepVar?.value || 'Variable not found'}>
                              <span
                                className={`underline decoration-dotted ${!!stepVar?.value ? `text-blue-500` : `text-red-500`}`}
                              >
                                {v}
                              </span>
                            </Tooltip>
                          )}
                        </span>
                      );
                    })}
                  </>
                );
              }}
            </RichTextarea>
          </div>
          {!text && (
            <div className="flex flex-col gap-5 absolute top-[5px]" ref={placeholderRef}>
              <span className="px-[13px] ">
                <span className="text-[14px] font-semibold text-gray-400 mr-2">{placeholder}</span>
                <Kbd>Tab</Kbd>
              </span>
              <div
                className="ml-5 cursor-pointer z-30"
                onClick={() => {
                  setPlaceholderIndex(placeholderIndex + 1);
                }}
              >
                <Icon name="refresh" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
