import { SegmentType } from 'components/CreateEditSegmentationModal';
import moment from '@tw/moment-cached';
import { combineReducers, Reducer } from 'redux';
import _db from 'utils/DB';

import {
  FilterExpressions,
  MetricsFilterExpression,
  MetricsFilterOperands,
  MetricsFilterOperator,
  ShopifyMetricsFilterOperands,
} from '@tw/types';
import { DataTypesRoles, ServicesIds } from '@tw/types/module/services';
import { AUDIT_SEGMENT_INIT } from 'ducks/constants';

export const defaultExpressionSettings: MetricsFilterExpression = {
  operand: 'campaign_name',
  operator: 'contains',
  value: '',
};

const MODAL_DATA = 'AUDIT_MODAL_DATA';
export const openModalSegmentation = (serviceId: ServicesIds | '', segmentId?: string) => {
  return {
    type: MODAL_DATA,
    payload: {
      serviceId,
      segmentId,
      isOpen: serviceId.length > 0,
      isEdit: !!segmentId,
    },
  };
};

const SEGMENT_UPDATED = 'AUDIT_SEGMENT_UPDATED';
const SEGMENT_UPDATING = 'AUDIT_SEGMENT_UPDATING';

export const createSegment = (segment, service) => {
  return async (dispatch, getState) => {
    try {
      const { segments } = getState().auditSegments;
      const { id: segmentId }: any = await _db()
        .collection('rule-engine')
        .doc(service)
        .collection('audit-segments')
        .add(segment);
      segments[service] = { ...segments[service], [segmentId]: segment };

      dispatch({
        type: SEGMENT_UPDATED,
        auditSegments: segments,
      });
    } catch (e) {
      throw e;
    }
  };
};

export const editSegment = (segment: SegmentType, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().auditSegments;
    const newSegments = {
      ...segments,
      [service]: { ...segments[service], [segment.id!]: segment },
    };
    dispatch({
      type: SEGMENT_UPDATING,
    });
    const { id, ...rest } = segment;
    await _db()
      .collection('rule-engine')
      .doc(service)
      .collection('audit-segments')
      .doc(id)
      .set(rest);
    dispatch({
      type: SEGMENT_UPDATED,
      auditSegments: newSegments,
    });
  };
};

export const deleteSegment = (segmentId: string, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().auditSegments;
    delete segments[service][segmentId];
    dispatch({
      type: SEGMENT_UPDATING,
    });
    await _db()
      .collection('rule-engine')
      .doc(service)
      .collection('audit-segments')
      .doc(segmentId)
      .delete();
    await _db().update({ auditSegments: segments });
    dispatch({
      type: SEGMENT_UPDATED,
      auditSegments: segments,
    });
  };
};

const modalData = (state = {}, action) => {
  switch (action.type) {
    case MODAL_DATA:
      return action.payload;
    default:
      return state;
  }
};

const initialValue: FilterExpressions<
  MetricsFilterOperands | ShopifyMetricsFilterOperands,
  MetricsFilterOperator
> = {
  segmentTitle: '',
  segmentDescription: '',
  expressionList: [defaultExpressionSettings],
  enabled: true,
  id: '',
};

const currentSegment: Reducer<
  FilterExpressions<MetricsFilterOperands | ShopifyMetricsFilterOperands, MetricsFilterOperator>
> = (state = initialValue, action) => {
  switch (action.type) {
    case MODAL_DATA:
      return state;
    default:
      return state;
  }
};

const segments: Reducer<
  Record<ServicesIds, FilterExpressions<MetricsFilterOperands, MetricsFilterOperator>>
> = (state = {} as any, action) => {
  switch (action.type) {
    case AUDIT_SEGMENT_INIT:
    case SEGMENT_UPDATED:
      return action.auditSegments || state;
    default:
      return state;
  }
};

const now = moment();

const lastUpdate = (state = now, action) => {
  switch (action.type) {
    case SEGMENT_UPDATED:
      return moment();
    default:
      return state;
  }
};

const segmentsUpdating = (state = false, action) => {
  switch (action.type) {
    case SEGMENT_UPDATING:
      return true;
    case SEGMENT_UPDATED:
      return false;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  modalData,
  currentSegment,
  segments,
  lastUpdate,
  segmentsUpdating,
});
