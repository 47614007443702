type ImageProps = {
  className?: string;
  onClick?: () => void;
  width?: number;
};

const LighthouseImage: React.FC<ImageProps> = ({ className, onClick = () => null, width }) => {
  return (
    <img
      src="/lighthouse-48.png"
      className={className}
      onClick={onClick}
      alt="Lighthouse"
      width={width}
    />
  );
};

export default LighthouseImage;
