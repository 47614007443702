import { useDebouncer } from 'hooks/useDebouncer';
import { useCallback, useMemo, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import copyToClipboard from 'utils/copyToClipboard';

export const useDashCopyLink = (dashboardId?: string, isGlobal = false) => {
  const currentShopId = useStoreValue($currentShopId);
  const debounce4s = useDebouncer(4000);
  const [justCopied, setJustCopied] = useState(false);

  const dashboardLink = useMemo(() => {
    if (!dashboardId) return '';
    const origin = window.location.origin;
    if (isGlobal) return `${origin}/dashboards/${dashboardId}`;
    return `${origin}/share?hostShopId=${currentShopId}&hostDashboardId=${dashboardId}`;
  }, [currentShopId, isGlobal, dashboardId]);

  const handleCopyLink = useCallback(() => {
    if (!currentShopId) return;
    copyToClipboard(dashboardLink, true);
    setJustCopied(true);
    debounce4s(() => setJustCopied(false));
  }, [currentShopId, debounce4s, dashboardLink]);

  return {
    justCopied,
    dashboardLink,
    handleCopyLink,
  };
};
