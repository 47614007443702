import { SubscriptionFeature } from '@tw/types';
import { OPEN_UPGRADE_PLAN_MODAL } from 'ducks/subscription';
import { useAppDispatch } from 'index';
import { useEffect } from 'react';
import { useUpgradePackagesAndAddons } from './useUpgradePackagesAndAddons';

export const useEnterpriseUpgradeCampaign = () => {
  const dispatch = useAppDispatch();
  const { packages } = useUpgradePackagesAndAddons();

  useEffect(() => {
    if (!Array.isArray(packages)) return;

    const searchParams = new URLSearchParams(window.location.search);
    const isSpecialOfferEnterpriseUpgrade = searchParams.get('enterprise-upgrade');
    if (isSpecialOfferEnterpriseUpgrade !== 'true') return;

    const enterpriseProduct = packages.find((p) => p.product_flag === SubscriptionFeature.PROD_C);
    if (!enterpriseProduct) return;

    dispatch({
      type: OPEN_UPGRADE_PLAN_MODAL,
      payload: enterpriseProduct.product_id,
    });
  }, [dispatch, packages]);
};
