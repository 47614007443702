import { ActionIcon, Button, Modal, Select, Text, Textarea, TextInput } from '@tw/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Timestamp } from 'utils/DB';
import { WillyFormGroup } from '../WillyFormGroup';
import { defaultWillyRulePopupState, $willyRulePopup } from './$stores';
import { v4 as uuidV4 } from 'uuid';
import { createBqColumn, executeCustomQuery, fetchQueryBuilderData } from '../utils/willyUtils';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { $activeAccounts, $currency } from '$stores/$shop';
import {
  extractAliasesFromExpressions,
  extractQueryFromRule,
  translateExpressionsToSql,
  wrapQueryWithOuterExpression,
} from './utils';
import { $dialect } from '$stores/$user';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { $tables } from '$stores/willy/$tables';
import { WillyDynamicFieldOperator } from '../WillyDynamicFieldOperator';
import { BqColumn } from 'pages/FreeQuery/dataStuff/columns/types';
import { WillyDynamicFieldValue } from '../WillyDynamicFieldValue';
import { WillyDynamicFieldType } from '../WillyDynamicFieldType';
import { WillyRule, WillyRuleExpression } from '@tw/willy-data-dictionary/module/columns/types';

type WillyRulePopupProps = {};

export const WillyRulePopup: React.FC<WillyRulePopupProps> = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const currency = useStoreValue($currency);

  const activeAccounts = useStoreValue($activeAccounts);
  const allTables = useStoreValue($tables);
  const dialect = useStoreValue($dialect);
  const [willyRulePopup, setWillyRulePopupState] = useWritableStore($willyRulePopup);

  const { rule, isOpen, query, ruleSaved } = willyRulePopup;

  const [runQueryLoading, setRunQueryLoading] = useState(false);
  const [queryAliases, setQueryAliases] = useState<string[]>([]);
  const [runQueryError, setRunQueryError] = useState<string>();
  const [dryRunLoading, setDryRunLoading] = useState(false);
  const [dryRunError, setDryRunError] = useState<string>();
  const [dryRunResult, setDryRunResult] = useState<string>();
  const [savingRule, setSavingRule] = useState(false);
  const [queryVisible, setQueryVisible] = useState(!rule);
  const [newRule, setNewRule] = useState<WillyRule>({
    id: rule ? rule.id : uuidV4(),
    name: rule ? rule.name : '',
    description: rule ? rule.description : '',
    query: rule ? rule.query : query || '',
    fullQuery: rule ? rule.fullQuery : '',
    created_at: rule ? rule.created_at : Timestamp.now().toDate().toISOString(),
    updated_at: rule ? rule.updated_at : Timestamp.now().toDate().toISOString(),
    expressions: rule ? JSON.parse((rule.expressions as unknown as string) || '[]') : [],
    mode: rule ? rule.mode || 'sql' : undefined,
    tableId: rule ? rule.tableId : undefined,
  });

  const table = useMemo(() => {
    if (newRule.tableId) {
      return allTables.find((x) => x.id === newRule.tableId);
    } else {
      return allTables.find((x) => newRule.query.includes(x.id));
    }
  }, [allTables, newRule.tableId, newRule.query]);

  const mode = useMemo(() => {
    return newRule.mode;
  }, [newRule.mode]);

  const ruleHasEventDate = useMemo(() => {
    if (mode === 'sql') {
      return true;
    }
    return newRule.expressions.some((x) => x.some((y) => y.column === 'event_date'));
  }, [mode, newRule.expressions]);

  const saveDisabled = useMemo(() => {
    if (
      newRule.name.trim() === rule?.name &&
      newRule.description.trim() === rule?.description &&
      newRule.query === rule?.query &&
      JSON.stringify(newRule.expressions) === JSON.stringify(rule?.expressions)
    ) {
      return true;
    }

    if (!newRule.name || !newRule.query) {
      return true;
    }

    if (!newRule.expressions?.length) {
      return true;
    }

    if (newRule.expressions.some((x) => !x.length)) {
      return true;
    }

    if (newRule.expressions.some((x) => x.some((y) => !y.value || !y.column || !y.operator))) {
      return true;
    }

    if (!ruleHasEventDate) {
      return true;
    }

    return false;
  }, [
    newRule.description,
    newRule.name,
    newRule.query,
    rule?.description,
    rule?.name,
    rule?.query,
    rule?.expressions,
    newRule.expressions,
    ruleHasEventDate,
  ]);

  const closeModal = useCallback(() => {
    setWillyRulePopupState(() => defaultWillyRulePopupState);
    setNewRule({
      id: uuidV4(),
      name: '',
      description: '',
      query: '',
      fullQuery: '',
      created_at: Timestamp.now().toDate().toISOString(),
      updated_at: Timestamp.now().toDate().toISOString(),
      expressions: [],
      tableId: undefined,
      mode: query ? 'sql' : 'builder',
    });
    setQueryAliases([]);
    setRunQueryLoading(false);
    setRunQueryError(undefined);
    setDryRunLoading(false);
    setDryRunError(undefined);
    setDryRunResult(undefined);
    setSavingRule(false);
  }, [setWillyRulePopupState, query]);

  const updateExpressions = useCallback((expressions: WillyRuleExpression[][]) => {
    setNewRule((old) => ({
      ...old,
      expressions,
      query: extractQueryFromRule({ ...old, expressions }),
    }));
  }, []);

  useEffect(() => {
    if (isOpen) {
      setNewRule({
        id: rule ? rule.id : uuidV4(),
        name: rule ? rule.name : '',
        description: rule ? rule.description : '',
        query: rule ? rule.query : query || '',
        fullQuery: rule ? rule.fullQuery : '',
        mode: rule ? rule.mode || 'sql' : undefined,
        tableId: rule ? rule.tableId : undefined,
        created_at: rule ? rule.created_at : Timestamp.now().toDate().toISOString(),
        updated_at: rule ? rule.updated_at : Timestamp.now().toDate().toISOString(),
        expressions: rule ? JSON.parse((rule.expressions as unknown as string) || '[]') : [],
      });
      const expressions = rule ? JSON.parse((rule.expressions as unknown as string) || '[]') : [];

      setQueryVisible(!rule);

      if (rule && (rule.mode === 'sql' || !rule.mode)) {
        setQueryAliases(extractAliasesFromExpressions(expressions));
      } else if (rule && rule.mode === 'builder') {
        const table = allTables.find((x) => x.id === rule.tableId);
        if (!table) {
          return;
        }
        setQueryAliases(table.columns.map((x) => x.id));
      }
    }
  }, [isOpen, query, rule, allTables]);

  useEffect(() => {
    if (!newRule.expressions.length) {
      return;
    }

    if (
      newRule.expressions.every((x) =>
        x.every((y) => y.column !== 'event_date' || y.operator === '='),
      )
    ) {
      return;
    }

    updateExpressions(
      newRule.expressions.map((x, j) => {
        return x.map((y, k) => {
          if (y.column === 'event_date') {
            return { ...y, operator: '=', value: '' };
          }
          return y;
        });
      }),
    );
  }, [newRule.expressions, updateExpressions]);

  return (
    <div>
      <Modal
        closeOnClickOutside={false}
        opened={isOpen}
        onClose={() => {
          closeModal();
        }}
        title={rule ? 'Edit Rule' : 'Create Rule'}
        size="lg"
        portalProps={{ style: { zIndex: 201, position: 'relative' } }}
      >
        <Modal.Body>
          {!newRule.mode && (
            <div className="flex flex-col gap-6.5">
              <Text fw="600">How would you like to create the rule?</Text>
              <div className="flex gap-6.5">
                <Button onClick={() => setNewRule({ ...newRule, mode: 'sql' })}>
                  With SQL (advanced)
                </Button>
                <Button onClick={() => setNewRule({ ...newRule, mode: 'builder' })}>
                  With Builder
                </Button>
              </div>
            </div>
          )}
          {!!newRule.mode && (
            <div className="flex flex-col gap-6.5">
              <Text fw="600">General</Text>
              <WillyFormGroup>
                <TextInput
                  label="Name"
                  required
                  value={newRule.name}
                  onChange={(e) => setNewRule({ ...newRule, name: e })}
                />
                <Textarea
                  label="Description"
                  value={newRule.description}
                  onChange={(e) => setNewRule({ ...newRule, description: e.target.value })}
                />
              </WillyFormGroup>

              {mode === 'sql' && (
                <>
                  <div className="flex items-center gap-2">
                    <Button
                      size="sm"
                      onClick={() => setQueryVisible(!queryVisible)}
                      variant="activatorWithHover"
                    >
                      {queryVisible ? 'Hide Query' : 'Show Query'}
                    </Button>
                  </div>
                  <div
                    className={`${
                      queryVisible ? 'h-96' : 'h-0 overflow-hidden'
                    } transition-[height]`}
                  >
                    <WillyFormGroup>
                      <Textarea
                        maxRows={7}
                        minRows={7}
                        disabled={runQueryLoading}
                        autosize
                        value={newRule.query}
                        onChange={(e) => setNewRule({ ...newRule, query: e.target.value })}
                      />
                      <Button
                        loading={runQueryLoading}
                        disabled={!newRule.query || !activeAccounts}
                        onClick={async () => {
                          if (!newRule.query || !activeAccounts || !dialect) {
                            return;
                          }

                          setRunQueryLoading(true);
                          setQueryAliases([]);
                          setRunQueryError(undefined);
                          setDryRunError(undefined);
                          setDryRunResult(undefined);
                          const res = await executeCustomQuery({
                            query: newRule.query,
                            shopId: currentShopId,
                            timezone: shopTimezone,
                            currency,
                            activeAccounts,
                            dialect,
                          });

                          const { data, error } = res;
                          setRunQueryLoading(false);
                          if (error) {
                            setRunQueryError(error);
                          } else {
                            setQueryAliases(data.map((x) => x.name));
                            updateExpressions(
                              data.map((x) => [{ column: x.name, value: '', operator: '=' }]),
                            );
                          }
                        }}
                      >
                        Create Conditions from Query
                      </Button>
                    </WillyFormGroup>
                  </div>
                </>
              )}

              {mode === 'builder' && (
                <>
                  <Text fw="600">Collection</Text>
                  <WillyFormGroup>
                    <Select
                      allowDeselect
                      searchable
                      value={newRule.tableId}
                      data={allTables.map((x) => ({ label: x.name, value: x.id }))}
                      onChange={(e) => {
                        if (!e) {
                          return;
                        }
                        const table = allTables.find((x) => x.id === e);
                        if (!table) {
                          return;
                        }
                        const [column] = table.columns;
                        const newExp: WillyRuleExpression[][] = [
                          [{ column: column.id, value: '', operator: '=' }],
                        ];
                        setQueryAliases(table.columns.map((x) => x.id));
                        setNewRule({
                          ...newRule,
                          tableId: e,
                          expressions: newExp,
                          query: extractQueryFromRule({ ...newRule, tableId: e }),
                        });
                      }}
                    />
                  </WillyFormGroup>
                </>
              )}

              {!!runQueryError && !runQueryLoading && (
                <WillyFormGroup>
                  <Text fw="600">Error</Text>
                  <Text color="red.5">{runQueryError}</Text>
                </WillyFormGroup>
              )}

              <WillyFormGroup>
                <div className="flex items-center gap-4">
                  <Text fw="600">Conditions</Text>
                  {(mode !== 'builder' || !newRule.expressions?.length) && (
                    <div className="ml-auto">
                      <Button
                        variant="primary"
                        leftSection="plus"
                        disabled={!queryAliases.length}
                        onClick={() => {
                          updateExpressions([
                            ...newRule.expressions,
                            [{ column: queryAliases[0], value: '', operator: '=' }],
                          ]);
                        }}
                      >
                        Add Condition
                      </Button>
                    </div>
                  )}
                </div>
                {newRule.expressions?.map((expression, groupIndex) => (
                  <div className="flex flex-col gap-6.5" key={`group-${groupIndex}`}>
                    {groupIndex !== 0 && (
                      <Text size="xs" fw="500">
                        OR
                      </Text>
                    )}
                    <WillyFormGroup>
                      {expression?.map((exp, expressionIndex, arr) => {
                        const c = createBqColumn({
                          id: exp.column,
                          name: exp.column,
                        });
                        const columnFromTable = table?.columns.find((x) => x.id === exp.column);
                        let column: BqColumn = columnFromTable || c;

                        return (
                          <div
                            className="flex flex-col gap-4"
                            key={`expression-${expressionIndex}`}
                          >
                            <div className="flex items-center gap-4 flex-wrap sm:flex-nowrap">
                              <Select
                                value={exp.column}
                                searchable
                                data={queryAliases.map((x) => ({ label: x, value: x }))}
                                onChange={(e) => {
                                  if (!e) {
                                    return;
                                  }

                                  updateExpressions(
                                    newRule.expressions.map((x, j) => {
                                      if (j === groupIndex) {
                                        return x.map((y, k) =>
                                          k === expressionIndex
                                            ? { ...y, column: e, value: '' }
                                            : y,
                                        );
                                      } else {
                                        return x;
                                      }
                                    }),
                                  );
                                }}
                              />
                              {/* {!columnFromTable && (
                                <WillyDynamicFieldType selectedType={column.type} onChange={() => {}} />
                              )} */}
                              <WillyDynamicFieldOperator
                                operator={exp.operator}
                                column={column}
                                disabled={column.id === 'event_date'}
                                allowMultiple={column.multiSelect}
                                hasOptions={!!column.options?.length}
                                onChange={(operator) => {
                                  updateExpressions(
                                    newRule.expressions.map((x, j) => {
                                      if (j === groupIndex) {
                                        return x.map((y, k) =>
                                          k === expressionIndex ? { ...y, operator } : y,
                                        );
                                      } else {
                                        return x;
                                      }
                                    }),
                                  );
                                }}
                              />
                              <WillyDynamicFieldValue
                                operator={exp.operator}
                                options={column.options || []}
                                value={exp.value}
                                column={column}
                                updateOnInput
                                datePickerMode="options"
                                valueChange={(value) => {
                                  updateExpressions(
                                    newRule.expressions.map((x, j) => {
                                      if (j === groupIndex) {
                                        return x.map((y, k) =>
                                          k === expressionIndex ? { ...y, value } : y,
                                        );
                                      } else {
                                        return x;
                                      }
                                    }),
                                  );
                                }}
                              />
                              <ActionIcon
                                size="lg"
                                variant="activator"
                                icon="delete"
                                onClick={() => {
                                  if (newRule.expressions[groupIndex].length === 1) {
                                    updateExpressions(
                                      newRule.expressions.filter((_, k) => k !== groupIndex),
                                    );
                                  } else {
                                    updateExpressions(
                                      newRule.expressions.map((x, j) => {
                                        if (j === groupIndex) {
                                          return x.filter((_, k) => k !== expressionIndex);
                                        } else {
                                          return x;
                                        }
                                      }),
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div>
                              {expressionIndex !== arr.length - 1 && (
                                <Text fw="500" size="xs">
                                  And
                                </Text>
                              )}
                              {expressionIndex === arr.length - 1 && (
                                <div className="flex items-center gap-4">
                                  <Button
                                    size="xs"
                                    variant="white"
                                    rightSection="plus"
                                    onClick={() => {
                                      updateExpressions(
                                        newRule.expressions.map((x, j) => {
                                          if (j === groupIndex) {
                                            return x.concat({
                                              column: queryAliases[0],
                                              value: '',
                                              operator: '=',
                                            });
                                          } else {
                                            return x;
                                          }
                                        }),
                                      );
                                    }}
                                  >
                                    And
                                  </Button>
                                  {mode !== 'builder' && (
                                    <div className="ml-auto">
                                      <Button
                                        size="xs"
                                        variant="white"
                                        rightSection="minus"
                                        onClick={() => {
                                          updateExpressions(
                                            newRule.expressions.filter((_, k) => k !== groupIndex),
                                          );
                                        }}
                                      >
                                        Remove Condition
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {!ruleHasEventDate && (
                              <Text color="red.5" size="xs" fw="500">
                                Event Date is required
                              </Text>
                            )}
                          </div>
                        );
                      })}
                    </WillyFormGroup>
                  </div>
                ))}
              </WillyFormGroup>

              <Modal.Footer>
                <div className="flex gap-4">
                  <div className="mr-auto flex gap-4">
                    <Button
                      size="xs"
                      variant="white"
                      className="btn btn-secondary"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                    <div className="flex flex-col gap-4">
                      {dryRunResult && (
                        <div className="flex items-center gap-4">
                          <Text
                            fw="600"
                            color={dryRunResult === 'Rule will pass' ? 'green.5' : 'red.5'}
                          >
                            {dryRunResult}
                          </Text>
                        </div>
                      )}
                      {dryRunError && <Text color="red.5">{dryRunError}</Text>}
                    </div>
                  </div>
                  <Button
                    size="xs"
                    variant="white"
                    loading={dryRunLoading}
                    disabled={saveDisabled || savingRule || runQueryLoading}
                    onClick={async () => {
                      if (
                        (mode === 'sql' && !newRule.query) ||
                        (mode === 'builder' && !newRule.tableId) ||
                        !activeAccounts ||
                        !queryAliases.length ||
                        !newRule.expressions?.length ||
                        !ruleHasEventDate ||
                        !dialect
                      ) {
                        return;
                      }

                      const table = allTables.find((x) => x.id === newRule.tableId);
                      if (!table && mode === 'builder') {
                        return;
                      }

                      setDryRunLoading(true);
                      setDryRunError(undefined);
                      setDryRunResult(undefined);
                      try {
                        if (mode === 'sql') {
                          const { whereClause, havingClause } = translateExpressionsToSql(
                            newRule.expressions,
                          );
                          const aliasesToWrap = extractAliasesFromExpressions(newRule.expressions);
                          const { query } = wrapQueryWithOuterExpression(
                            newRule.query,
                            aliasesToWrap,
                            whereClause,
                            havingClause,
                          );
                          const res = await executeCustomQuery({
                            query,
                            shopId: currentShopId,
                            timezone: shopTimezone,
                            currency,
                            activeAccounts,
                            dialect,
                          });
                          if (res.error || res.errorForInterface) {
                            setDryRunError(res.error || res.errorForInterface);
                            setDryRunResult(undefined);
                            return;
                          }
                          setDryRunResult(
                            res.data?.length ? 'Rule will pass' : 'Rule will not pass',
                          );
                        } else {
                          const res = await fetchQueryBuilderData(
                            {
                              table: newRule.tableId || '',
                              columns: [
                                {
                                  columnId: 'event_date',
                                  aggFunction: 'SUM',
                                },
                              ],
                              filters: newRule.expressions.flatMap((x) => {
                                return x.map((y) => ({
                                  column: y.column,
                                  operator: y.operator,
                                  value: y.value,
                                }));
                              }),
                              queryId: uuidV4(),
                              limit: 1,
                            },
                            { shopId: currentShopId, additionalShopIds: activeAccounts },
                          );
                          if (res.error) {
                            setDryRunError(res.error);
                            setDryRunResult(undefined);
                            return;
                          }
                          setDryRunResult(
                            res.data?.length ? 'Rule will pass' : 'Rule will not pass',
                          );
                        }
                      } catch (e) {
                        setDryRunError(e.message || e);
                        setDryRunResult(undefined);
                      } finally {
                        setDryRunLoading(false);
                      }
                    }}
                  >
                    Try Now
                  </Button>
                  <Button
                    variant="primary"
                    size="xs"
                    disabled={saveDisabled || !ruleSaved}
                    loading={savingRule}
                    onClick={async () => {
                      if (saveDisabled || !ruleSaved) {
                        return;
                      }
                      setSavingRule(true);
                      await ruleSaved({
                        ...newRule,
                        updated_at: Timestamp.now().toDate().toISOString(),
                      });
                      setSavingRule(false);
                      closeModal();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Footer>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
