import { LS_COLOR_SCHEME } from './constants';
import { ColorScheme } from './types';

export const isClrScheme = (str: unknown): str is ColorScheme => {
  return str === 'light' || str === 'dark' || str === 'auto';
};

export const getLSColorScheme = (): ColorScheme => {
  const savedClrScheme = window.localStorage.getItem(LS_COLOR_SCHEME);

  if (!isClrScheme(savedClrScheme)) {
    if ('matchMedia' in window) return 'auto';
    return 'light';
  }

  return savedClrScheme;
};
