import 'swiper/css';

import { useContext } from 'react';

import { ContentHubContext } from '../ContentHub';
import AdResults from './AdResults';
import MediaResults from './MediaResults';
import ProductResults from './ProductResults';

type SearchResultsProps = {};

const SearchResults: React.FC<SearchResultsProps> = () => {
  const { debounce } = useContext(ContentHubContext);
  return (
    <>
      {debounce ? (
        <div className="flex flex-col gap-10">
          <AdResults />
          <ProductResults />
          <MediaResults />
        </div>
      ) : (
        <div className="text-[16px] p-4">
          Use the Search bar to surface creative assets from your Products, Collections, and
          connected Ad Accounts.
        </div>
      )}
    </>
  );
};

export default SearchResults;
