import { selectedWorkspaceSelector } from 'ducks/auth';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SignUpStep } from 'routes/auth/SignUp/signUpStep.enum';
import { SubSettingsPopover } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { setAddToWorkspaceInfo, setIsAgencyPod, setIsFromPodView } from 'ducks/signupJourney';

export interface IStoreAdder {
  withIcon?: boolean;
}

export const StoreAdder: React.FC<IStoreAdder> = ({ withIcon = true }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedWorkspace = useSelector(selectedWorkspaceSelector);

  const addShopClick = useCallback(
    (isFree: boolean) => {
      if (isFree) {
        dispatch(setIsFromPodView(true));
        dispatch(setIsAgencyPod(!!selectedWorkspace.isAgency));
        dispatch(
          setAddToWorkspaceInfo({
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
          }),
        );
      }
      const state = isFree
        ? {}
        : {
            currentIndex: SignUpStep.ConnectStore,
            workspaceId: selectedWorkspace.id,
            isAdminOnWorkspace: selectedWorkspace?.roles?.includes('admin'),
            isFromAddStore: true,
            isAgency: !!selectedWorkspace.isAgency,
          };

      navigate({ pathname: `/signup${isFree ? '-free' : ''}` }, { state });
    },
    [
      dispatch,
      navigate,
      selectedWorkspace.id,
      selectedWorkspace.isAgency,
      selectedWorkspace?.roles,
    ],
  );

  const options = useMemo(
    () => [
      {
        label: 'Free Shop',
        onClick: () => addShopClick(true),
      },
      {
        label: 'Paid Shop',
        onClick: () => addShopClick(false),
      },
    ],
    [addShopClick],
  );

  return (
    <SubSettingsPopover
      options={options}
      targetLabel="Add Shop"
      textProps={{ fz: 'sm' }}
      {...(withIcon && { targetIcon: 'plus-circle' })}
    />
  );
};
