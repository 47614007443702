import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const useHandleVisitorsFromBadEmailUrl = (): void => {
  const location = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    //temp fix for pricing page wrong email, you can remove it in 3 month from now
    if (
      window.twShop?.id &&
      window.twShop.id !== 'madisonbraids.myshopify.com' &&
      search.get('shopId') === 'madisonbraids.myshopify.com'
    ) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('shopId', window.twShop?.id);
      window.location.href = currentUrl.toString();
    }
  }, [location.search]);
};
