import { useCallback, useEffect, useMemo, useState } from 'react';
import _db from 'utils/DB';
import { startCase } from 'lodash';
import { Button, Modal, Select, Text, Textarea, TextInput } from '@tw/ui-components';
import { BqTable, CustomColumn, CustomColumnExpressionPart } from './dataStuff/tables';
import { $customTablesAndViews, $tablesWithBeta } from '$stores/willy/$tables';
import { SAVED_QUERIES_COLLECTION } from './constants';
import { SQLType, SQLTypeArray } from '@tw/willy-data-dictionary/module/columns/types';
import { useStoreValue } from '@tw/snipestate';
import { $dialect } from '$stores/$user';
import { DEFAULT_DIALECT } from 'components/Willy/constants';
import { WillyCustomMetricBuilder } from 'components/Willy/CustomMetrics/WillyCustomMetricBuilder';

export const CreateCustomDerivedColumnModal: React.FC<{
  opened: boolean;
  customDerivedColumn?: CustomColumn;
  onClose: () => void;
}> = ({ opened, customDerivedColumn, onClose }) => {
  const dialect = useStoreValue($dialect);
  const allTables = useStoreValue($tablesWithBeta);
  const allCustomTablesAndViews = useStoreValue($customTablesAndViews);

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [activeTable, setActiveTable] = useState<BqTable | null>(null);
  const [formula, setFormula] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [type, setType] = useState<SQLType>('formula');
  const [expression, setExpression] = useState<CustomColumnExpressionPart[]>([]);

  useEffect(() => {
    if (!customDerivedColumn?.tableId) {
      return;
    }
    const table = [...allTables, ...allCustomTablesAndViews].find(
      (table) => table.id === customDerivedColumn.tableId,
    );
    setActiveTable(table || null);
  }, [customDerivedColumn?.tableId, allTables, allCustomTablesAndViews]);

  useEffect(() => {
    if (!customDerivedColumn) {
      return;
    }

    setId(customDerivedColumn.id);
    setTitle(customDerivedColumn.title || '');
    setFormula(
      dialect === 'clickhouse'
        ? customDerivedColumn.clickhouseFormula || ''
        : customDerivedColumn.name || '',
    );
    setType(customDerivedColumn.type);
  }, [customDerivedColumn, dialect]);

  const close = useCallback(() => {
    setId('');
    setFormula('');
    setError(null);
    setSaving(false);
    onClose();
  }, [onClose]);

  const save = useCallback(async () => {
    if (!activeTable?.id) {
      return;
    }
    if (type === 'formula' && !formula?.trim() && activeTable?.type !== 'custom_view') {
      setError('Formula is required');
      return;
    }
    if (!type) {
      setError('Type is required');
      return;
    }
    setSaving(true);

    // if (activeTable.type === 'custom_view' && !!activeTable?.docId) {
    //   // this is case when we are editing column in custom view, where user can change the type
    //   try {
    //     await _db()
    //       .collection(SAVED_QUERIES_COLLECTION)
    //       .doc(activeTable.docId)
    //       .set(
    //         {
    //           columns: activeTable.columns.map((col) => {
    //             if (col.id === customDerivedColumn?.id) {
    //               return {
    //                 ...col,
    //                 type,
    //               };
    //             }
    //             return col;
    //           }),
    //         } as BqTable,
    //         { merge: true }
    //       );
    //     close();
    //   } catch (e) {
    //     console.error(e);
    //     setError('Error saving column');
    //   }
    //   setSaving(false);
    //   return;
    // }

    const documentId =
      customDerivedColumn?.docId || _db().collection('willy_custom_metrics_sql').doc().id;
    const newDoc: CustomColumn = {
      id: id,
      title,
      clickhouseFormula: formula,
      tableId: activeTable.id,
      type,
      dialect: dialect || DEFAULT_DIALECT,
      isCustomColumn: true,
      // deprecated
      name: formula,
    };

    try {
      await _db()
        .collection('willy_custom_metrics_sql')
        .doc(documentId)
        .set(newDoc, { merge: true });
      setSaving(false);
      close();
    } catch (e) {
      console.error(e);
      setError('Error saving column');
    }
    setSaving(false);
  }, [
    id,
    title,
    activeTable?.id,
    activeTable?.type,
    type,
    formula,
    customDerivedColumn?.docId,
    close,
    dialect,
  ]);

  const options = useMemo(() => {
    if (!activeTable) {
      return [];
    }
    const options: { label: string; value: SQLType }[] = [];

    if (activeTable?.type === 'custom_view') {
      SQLTypeArray.forEach((type) => {
        options.push({ label: startCase(type), value: type });
      });
    } else {
      options.push({ label: 'Formula', value: 'formula' });
    }

    return options;
  }, [activeTable]);

  return (
    <Modal
      opened={opened}
      title={`${customDerivedColumn ? 'Edit' : 'Add'} Custom Derived Column`}
      onClose={() => {
        close();
      }}
    >
      <div>
        <Select
          label="Table"
          data={allTables.map((table) => ({
            label: table.name,
            value: table.id,
          }))}
          placeholder="Select table"
          value={activeTable?.id}
          onChange={(val) => {
            setActiveTable(allTables.find((table) => table.id === val) || null);
          }}
        />
        <TextInput
          placeholder="e.g. TW Pixel CPA"
          label="Title"
          type="text"
          value={title}
          onChange={setTitle}
        />
        <TextInput
          placeholder="e.g. pixel_cpa"
          label="Column alias"
          type="text"
          value={id}
          onChange={setId}
        />
        <Select
          label="Type"
          disabled={activeTable?.type !== 'custom_view'}
          data={options}
          value={type}
          onChange={(value) => {
            setType(value as SQLType);
          }}
        />
        {type === 'formula' && activeTable?.type !== 'custom_view' && (
          <Textarea
            minRows={3}
            label="Formula (without alias)"
            placeholder="e.g. safe_divide(SUM(spend), SUM(orders_quantity))"
            value={formula}
            onChange={(e) => setFormula(e.target.value)}
          />
        )}
        {/* {type === 'formula' && activeTable?.type !== 'custom_view' && !!activeTable && (
          <WillyCustomMetricBuilder
            tableId={activeTable.id}
            expression={expression}
            onExpressionChanged={(expressions) => {
              setExpression(expressions);
            }}
          />
        )} */}
        {error && <Text c="red.8">{error}</Text>}
      </div>
      <Modal.Footer>
        <div className="flex gap-6.5 items-center justify-between w-full">
          <Button
            loading={saving}
            disabled={!id || !type}
            onClick={async () => {
              await save();
            }}
          >
            Save
          </Button>
          <Button variant="white" onClick={close}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
