import React from 'react';
const FacebookIconBase64 = ({ small }) => (
  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAQCAMAAAAyEe/dAAABLFBMVEUAAAAAfvgBePEAf/oCbukBgfQAgPoAf/gAgPoFZuAAf/kAf/kAfvkAgPoCaOIAcuwDcuoEZd8AgPoAgPoBePIAf/kEaeMAffgAf/gCcusAf/oDZt8AgPoBY98Af/kAf/oAe/QDa+QAZeADZeAAf/oBdvAAYt8AgPoAgPoAffgDZN8AgPoDZ+EAf/kAf/oAYt4BZN8CcuwGa+MAgfkAffMBZeAAY94Af/kDZeAAePEAfvgAbuoCdewDcOkAc+0AePAAgPoBYt4AffYIZNwAYt4BefIAY98Ga+IAgvwBaeMAY98AYt4AgfsGaOEAd/ABh/8AgPoEbuYAgPoIY9oAf/kAc+0Af/kAgPsGZ+AAYt8CZuEAY98Bd/EEbeYFZd8AffcAe/MEaeMAc+0AbuoA2Bl+AAAAV3RSTlMABgT+BQm8Gvfq5YB0UhYO/vvUy6+tY1dLSDU1EvTw6OHf39rZzsnFt7OrpKSWhHhwbVxGREItLCEg9/Tx8O3i3dLKysK+uq+enZiVjoR9bmxrYUs7OSOKh74mAAABQUlEQVQY00WQ5XLDQAyElZjiJI7DDTMzl5mZK0Owff93qHyup9/9OEl7szsnsAn0L2p3aQAeMv563SeBG2yomJ3qumlmBwBfInIcnnz8qztlU69dZU1jIESwPVZE9G6DQ0vX+6o6LRlyFbtWSAObjuk0pN8DkZaXm0sBXAKk8qgwYx46RmkGLjpxY9miGVVdLmanBoqbW+qJm2BIGwFPTYLz7oKbiuT+T5wuHrYwL2vlgJUkebkEMxt+H32SBFIFn+Oa9shczrgXJj7Nj8nCBQ8YzgjnSzkNvBuanI+JvnVBAgHGHnwDeA9p1wINY2iL/pX15UwERZ7SOtrilYYi+pmoeLAHUgw9Kbat4uIwCZMcp7BsNYweMYz01G39erQIHkQLq4oKjEQOiZ6zsGFwPl/vbcEfqXakQY1DMlqNToD4BaH0LJA3HBSEAAAAAElFTkSuQmCC"
    style={{ height: small ? 15 : 15 }}
    alt="Meta icon"
  />
);

export default FacebookIconBase64;
