export const properCase = (stringToProperCase: string) => {
  const newCaseStr = stringToProperCase?.replace(/(?:^|\s)\S/g, function (a) {
    return a?.toUpperCase();
  });

  if (newCaseStr?.includes('EBay')) return newCaseStr?.replace('EBay', 'eBay');
  if (newCaseStr?.includes('Ebay')) return newCaseStr?.replace('Ebay', 'eBay');
  if (newCaseStr?.includes('PAYPAL')) return newCaseStr?.replace('PAYPAL', 'PayPal');
  return newCaseStr;
};
