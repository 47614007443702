import { valueFormats } from '@tw/types/module/types';
import { SegmentInstance, SegmentMetric } from '@tw/types/module/services/cdp';

type SEGMENT_METRICS_OBJ = {
  key: SegmentMetric;
  label: string;
  shortLabel?: string;
  format?: valueFormats;
  tooltip?: string;
};

export const SEGMENT_METRICS: SEGMENT_METRICS_OBJ[] = [
  {
    key: SegmentMetric.CUSTOMERS_COUNT,
    label: 'Segment Size',
    format: 'decimal',
    shortLabel: 'Userbase',
    tooltip:
      'Represents all customers that match your invividual segment criteria. The date picker will only affect the numbers displayed on the graph.',
  },
  {
    key: SegmentMetric.ORDERS_COUNT,
    label: 'Orders',
    format: 'decimal',
    shortLabel: 'Orders',
    tooltip: 'All orders from customers in this segment all-time',
  },
  {
    key: SegmentMetric.REVENUE,
    label: 'Revenue',
    tooltip: 'The total revenue from customers in this segment all-time.',
  },
  {
    key: SegmentMetric.HISTORICAL_LTV_30_DAYS,
    label: 'LTV after 30d',
  },
  {
    key: SegmentMetric.HISTORICAL_LTV_60_DAYS,
    label: 'LTV after 60d',
  },
  {
    key: SegmentMetric.HISTORICAL_LTV_365_DAYS,
    label: 'LTV after 365d',
    format: 'currency',
    shortLabel: 'Lifetime Value',
  },
];

export const SEGMENT_METRICS_MAP = SEGMENT_METRICS.reduce(
  (acc, x) => {
    acc[x.key] = x;
    return acc;
  },
  {} as Record<string, SEGMENT_METRICS_OBJ>,
);

export const DEFAULT_METRIC = SegmentMetric.REVENUE;

export function getMetricLabel(metric: keyof SegmentInstance): string {
  // return SEGMENT_METRICS.find((m) => m.key === metric)?.label || metric;
  return SEGMENT_METRICS_MAP[metric]?.label || metric;
}

export function getMetricTooltip(metric: keyof SegmentInstance): string | undefined {
  // return SEGMENT_METRICS.find((m) => m.key === metric)?.tooltip;
  return SEGMENT_METRICS_MAP[metric]?.tooltip;
}
