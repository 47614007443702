import { BqColumn } from '../dataStuff/columns/types';
import React, { useCallback } from 'react';
import { ActionIcon, Button, Flex, Icon, Text, Tooltip } from '@tw/ui-components';
import { FilterRowComp } from './FilterRowComp';
import { BqTable } from '../dataStuff/tables';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import { ServicesIds } from '@tw/types/module/services';
import { FilterGroup, FilterRow } from '@tw/willy-data-dictionary/module/columns/types';
const emptyTitle = 'Click to set title';

type FilterGroupCompProps = {
  group: FilterGroup;
  index: number;
  columns: BqColumn[];
  table: BqTable;
  filter: FilterGroup[];
  onFilterChanged: (filter: FilterGroup[]) => void;
  relatedProvider?: ServicesIds;
};

export const FilterGroupComp: React.FC<FilterGroupCompProps> = ({
  group,
  index,
  columns,
  table,
  filter,
  onFilterChanged,
  relatedProvider,
}) => {
  const addAndStatement = useCallback(
    (column: BqColumn[], index: number) => {
      const old = filter;

      const filterChanged = ((filter) => {
        if (index < 0 || index >= (old?.length ?? -1)) {
          console.error('Invalid index for addAndStatement');
          return old;
        }

        // Clone the outer array
        const newFilter = old ? [...old] : [];

        // Create a new FilterRow
        const newFilterRow: FilterRow = {
          column: column[0],
          // Initialize other properties as needed
        };

        // Add the new FilterRow to the specified inner array
        newFilter[index] = {
          ...newFilter[index],
          filters: [...newFilter[index].filters, newFilterRow],
        };

        // Return the updated state
        return newFilter;
      })(filter);

      onFilterChanged(filterChanged);
    },
    [filter, onFilterChanged],
  );

  return (
    <div className={'mb-5'}>
      <div className={'flex justify-between items-center'}>
        <div className={'flex gap-4 items-center'}>
          <ToggleSwitch
            width={40}
            height={20}
            checkedDirection={'right'}
            handleDiameter={17}
            color={group.enabled ? '#1877F2' : '#a7c8ee'}
            checked={group.enabled}
            onChange={(val) =>
              onFilterChanged(
                filter.map((group, i) =>
                  i === index ? { ...group, enabled: !group.enabled } : group,
                ),
              )
            }
          />

          <div
            contentEditable={true}
            suppressContentEditableWarning
            className={`mr-auto text-ellipsis overflow-hidden whitespace-nowrap underline`}
            onClick={(e) => {
              if (!group.name) {
                const range = document.createRange();
                range.selectNodeContents(e.currentTarget);
                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(range);
              }
              e.currentTarget.focus();
            }}
            onBlur={async (e) => {
              const newTitle = e.currentTarget.innerText;
              if (newTitle === emptyTitle) {
                return;
              }
              if (!newTitle?.trim()) {
                e.currentTarget.innerText = (group.name as string) || emptyTitle;
                return;
              }
              onFilterChanged(
                filter.map((group, i) => (i === index ? { ...group, name: newTitle } : group)),
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
          >
            <span className="dark:text-gray-300">{group.name || emptyTitle}</span>
          </div>
        </div>
        <div className={'flex items-center gap-2'}>
          <div>
            <ActionIcon
              icon={<Icon color={'red.6'} name={'delete'} size={20} />}
              onClick={() => onFilterChanged(filter.filter((_, i) => i !== index))}
            />
          </div>
          <div
            onClick={() =>
              onFilterChanged(
                filter.map((group, i) =>
                  i === index ? { ...group, opened: !group.opened } : group,
                ),
              )
            }
            className={`flex text-gray-500 w-5 h-5 items-center cursor-pointer transition-transform ${
              group.opened === false ? 'rotate-180' : ''
            }`}
          >
            <Icon name={'chevron-down'} />
          </div>
        </div>
      </div>

      {group.opened !== false && (
        <div className={'my-5'}>
          {group.filters.map((row, i) => (
            <div key={`${i}-child`}>
              <FilterRowComp
                onFilterChanged={onFilterChanged}
                filter={filter}
                key={`${i}-child`}
                row={row}
                index={i}
                parentIndex={index}
                columns={columns}
                table={table}
                relatedProvider={relatedProvider}
              />
              {group?.filters.length !== i + 1 && (
                <div className={'my-5'}>
                  <Text mt={5} fw={500} size={'xs'}>
                    And
                  </Text>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {group.opened !== false && (
        <div className={'my-5 mb-12 flex start'}>
          <Button
            size={'xs'}
            variant={'secondary'}
            onClick={() => addAndStatement(columns, index)}
            rightSection={'plus'}
          >
            Add
          </Button>
        </div>
      )}
    </div>
  );
};
