// imports
import { InsightsFilter, InsightsFilterQuery } from '@tw/types/module/services/insights';
import { removeDuplicatesFromFiltersArray } from 'components/Insights/Filters/utils';
import _db, { serverTimestamp, toArray } from 'utils/DB';
import { AnyAction, combineReducers, Dispatch, Reducer } from 'redux';
import { mapValues } from 'lodash';
import { createSelector } from 'reselect';

// global vars
const INSIGHTS_FILTERS_COLLECTION = 'insights_segments';

export enum FiltersContext {
  Cohorts = 'cohorts',
  CartAnalysis = 'cart_analysis',
  ProductJourney = 'product_journey',
  ProductAnalytics = 'product_analytics',
  LTV = 'ltv',
  AOV = 'aov',
  SALES_CYCLE = 'sales_cycle',
}

// actions
const INSIGHTS_FILTER_BUILDER_MODAL_CLOSE = 'INSIGHTS_FILTER_BUILDER_MODAL_CLOSE';
const INSIGHTS_FILTERS_LOAD = 'INSIGHTS_FILTERS_LOAD';
const INSIGHTS_FILTERS_REPLACE_FILTER = 'INSIGHTS_FILTERS_REPLACE_FILTER';
const INSIGHTS_FILTER_BUILDER_DATA_SAVE = 'INSIGHTS_FILTER_BUILDER_DATA_SAVE';
const INSIGHTS_FILTER_BUILDER_CLEAR_DATA = 'INSIGHTS_FILTER_BUILDER_CLEAR_DATA';
const INSIGHTS_FILTER_RENAME_MODE = 'INSIGHTS_FILTER_RENAME_MODE';
const INSIGHTS_FILTERS_CLEAR_APPLIED = 'INSIGHTS_FILTERS_CLEAR_APPLIED';
const INSIGHTS_FILTERS_CHANGE_APPLIED = 'INSIGHTS_FILTERS_CHANGE_APPLIED';
const INSIGHTS_FILTERS_ANONYMOUS_APPLY = 'INSIGHTS_FILTERS_ANONYMOUS_APPLY';
const INSIGHTS_FILTERS_APPLY_CLEAR = 'INSIGHTS_FILTERS_APPLY_CLEAR';
const EDIT_INSIGHTS_FILTER = 'EDIT_INSIGHTS_FILTER';
const REMOVE_INSIGHTS_CLEAR_DATA_BEFORE_RELOAD = 'REMOVE_INSIGHTS_CLEAR_DATA_BEFORE_RELOAD';

const emptyFilter: InsightsFilter = { id: '', name: '', query: [], relationOperator: 'OR' };
// action creators
export const clearAnonymousFilter =
  (filtersContext: FiltersContext) => async (dispatch: Dispatch) => {
    dispatch({
      type: INSIGHTS_FILTERS_APPLY_CLEAR,
      filtersContext,
    });
  };

export const applyAnonymousFilter =
  (query: InsightsFilterQuery, filtersContext: FiltersContext) => async (dispatch: Dispatch) => {
    dispatch({
      type: INSIGHTS_FILTERS_CHANGE_APPLIED,
      payload: [{ ...emptyFilter, query }],
      filtersContext,
    });

    dispatch({
      type: INSIGHTS_FILTERS_ANONYMOUS_APPLY,
      payload: query,
      filtersContext,
    });
  };

export const clearFilterBuilderData = () => async (dispatch: Dispatch) => {
  dispatch({
    type: INSIGHTS_FILTER_BUILDER_CLEAR_DATA,
  });
};

export const applyInsightsFilters =
  (filters: InsightsFilter[], filtersContext: FiltersContext) => async (dispatch: Dispatch) => {
    dispatch({
      type: INSIGHTS_FILTERS_CHANGE_APPLIED,
      payload: filters,
      filtersContext,
    });
  };

export const clearAppliedFilters =
  (filtersContext: FiltersContext) => async (dispatch: Dispatch) => {
    dispatch({
      type: INSIGHTS_FILTERS_CLEAR_APPLIED,
      filtersContext,
    });
  };

export const enterRenameMode = () => async (dispatch: Dispatch) => {
  dispatch({
    type: INSIGHTS_FILTER_RENAME_MODE,
  });
};

export const storeBuilderDataToSave = (data: InsightsFilter) => async (dispatch: Dispatch) => {
  dispatch({
    type: INSIGHTS_FILTER_BUILDER_DATA_SAVE,
    payload: data,
  });
};

export const setEditFilterData = (filter) => async (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_INSIGHTS_FILTER,
    payload: filter,
  });
};

const currentFilterToEdit: Reducer<InsightsFilter> = (state = emptyFilter, action: AnyAction) => {
  switch (action.type) {
    case EDIT_INSIGHTS_FILTER:
      return action.payload;
    case INSIGHTS_FILTER_BUILDER_CLEAR_DATA:
      return emptyFilter;
    default:
      return state;
  }
};

export const createInsightsFilter =
  (data, filtersContext: FiltersContext) => async (dispatch: Dispatch) => {
    const idRef = await _db()
      .collection(INSIGHTS_FILTERS_COLLECTION)
      .add({
        ...data,
        createdAt: serverTimestamp(),
      });
    dispatch({
      type: INSIGHTS_FILTERS_APPLY_CLEAR,
      filtersContext,
    });
    dispatch({
      type: INSIGHTS_FILTERS_CHANGE_APPLIED,
      payload: [{ ...data, id: idRef.id }],
      filtersContext,
    });
  };

export const updateAndApplyInsightsFilter =
  (data, filtersContext: FiltersContext) => async (dispatch) => {
    await _db()
      .collection(INSIGHTS_FILTERS_COLLECTION)
      .doc(data.id)
      .set(
        {
          ...data,
          updatedAt: serverTimestamp(),
        },
        { merge: true },
      );

    dispatch({
      type: INSIGHTS_FILTER_BUILDER_CLEAR_DATA,
    });
    dispatch({
      type: INSIGHTS_FILTERS_REPLACE_FILTER,
      payload: [data.id],
      filtersContext,
    });
    dispatch({
      type: INSIGHTS_FILTERS_CHANGE_APPLIED,
      payload: [data],
      filtersContext,
    });
    return;
  };

export const reloadData = () => async (dispatch) => {
  dispatch({
    type: REMOVE_INSIGHTS_CLEAR_DATA_BEFORE_RELOAD,
  });
  dispatch(loadInsightsFilters());
};

export const renameInsightsFilter = (name, id) => async (dispatch) => {
  try {
    await _db().collection(INSIGHTS_FILTERS_COLLECTION).doc(id).set(
      {
        name,
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );
    dispatch(reloadData());
  } catch (err) {
    console.log(err);
  }
  return (dispatch) => {
    dispatch({
      type: INSIGHTS_FILTER_BUILDER_CLEAR_DATA,
    });
  };
};

export const insightsDeleteFilter = async (id) => {
  await _db().collection(INSIGHTS_FILTERS_COLLECTION).doc(id).delete();
};

export const loadInsightsFilters = () => {
  return (dispatch) => {
    return _db()
      .collection(INSIGHTS_FILTERS_COLLECTION)
      .onSnapshot((snapshot) => {
        let data = toArray(snapshot).map((x) => {
          // sometimes includes ref. no time to investigate (copy from old cohorts page)
          const { ref, ...rest } = x;
          return rest;
        });

        dispatch({
          type: INSIGHTS_FILTERS_LOAD,
          payload: { data },
        });

        dispatch({
          type: INSIGHTS_FILTER_BUILDER_MODAL_CLOSE,
        });
      });
  };
};

// reducers

const insightsFiltersData: Reducer<InsightsFilter[]> = (state = [], action: AnyAction) => {
  switch (action.type) {
    case INSIGHTS_FILTERS_LOAD:
      return removeDuplicatesFromFiltersArray([...state, ...action.payload.data]);
    case REMOVE_INSIGHTS_CLEAR_DATA_BEFORE_RELOAD:
      return [];
    default:
      return state;
  }
};

const insightsFiltersDataLoaded: Reducer<boolean> = (state = false, action: AnyAction) => {
  switch (action.type) {
    case INSIGHTS_FILTERS_LOAD:
      return true;
    default:
      return state;
  }
};

const renameMode: Reducer<boolean> = (state = false, action: AnyAction) => {
  switch (action.type) {
    case INSIGHTS_FILTER_RENAME_MODE:
      return true;
    case INSIGHTS_FILTER_BUILDER_CLEAR_DATA:
      return false;
    default:
      return state;
  }
};

const appliedFilters: Reducer<{ [key: string]: InsightsFilter[] }> = (
  state = {},
  action: AnyAction,
) => {
  switch (action.type) {
    case INSIGHTS_FILTERS_CHANGE_APPLIED:
      return { ...state, [action.filtersContext]: action.payload };
    case INSIGHTS_FILTERS_CLEAR_APPLIED:
      return { ...state, [action.filtersContext]: [] };
    default:
      return state;
  }
};

const filterSavedData: Reducer<InsightsFilter> = (state = emptyFilter, action: AnyAction) => {
  switch (action.type) {
    case INSIGHTS_FILTER_BUILDER_DATA_SAVE:
      return action.payload;
    case INSIGHTS_FILTER_BUILDER_CLEAR_DATA:
      return emptyFilter;
    default:
      return state;
  }
};

const appliedAnonymousFilter: Reducer<{ [key: string]: InsightsFilterQuery }> = (
  state = {},
  action: AnyAction,
) => {
  switch (action.type) {
    case INSIGHTS_FILTERS_ANONYMOUS_APPLY:
      return { ...state, [action.filtersContext]: action.payload };
    case INSIGHTS_FILTERS_CLEAR_APPLIED:
    case INSIGHTS_FILTERS_APPLY_CLEAR:
      return { ...state, [action.filtersContext]: null };
    default:
      return state;
  }
};

// selectors

const appliedFiltersForAllContexts = createSelector(
  [(state) => state?.insightsFilter.appliedFilters],
  (filters) => {
    return Object.values(FiltersContext).reduce((result, contextKey) => {
      return { ...result, [contextKey]: filters[contextKey] || [] };
    }, {});
  },
);

export const appliedInsightsFiltersQueries = createSelector(
  [appliedFiltersForAllContexts],
  (filters: { [key: string]: InsightsFilter[] }) => {
    return mapValues(filters, (contextFilters) => {
      return contextFilters.map((f) => f.query);
    });
  },
);

export const appliedInsightsFiltersNames = createSelector(
  [appliedFiltersForAllContexts],
  (filters: { [key: string]: InsightsFilter[] }) => {
    return mapValues(filters, (contextFilters) => {
      return contextFilters.map((f) => f.name);
    });
  },
);

export const appliedInsightsFiltersIds = createSelector(
  [appliedFiltersForAllContexts],
  (filters: { [key: string]: InsightsFilter[] }) => {
    return mapValues(filters, (contextFilters) => {
      return contextFilters.map((f) => f.id);
    });
  },
);

// exports

export const reducers = combineReducers({
  currentFilterToEdit,
  insightsFiltersData,
  filterSavedData,
  renameMode,
  appliedFilters,
  appliedAnonymousFilter,
  insightsFiltersDataLoaded,
});
