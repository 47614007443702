import React, { useContext, useEffect, useMemo } from 'react';
import { useAppSelector } from '../../../../reducers/RootType';
import { useAppDispatch } from '../../../../index';
import { setOnboardingStatus } from '../../../../ducks/shop';
import { SalesPlatform } from '@tw/types/module/services';
import { WelcomeInstallShopifyPixel } from './WelcomeInstallShopifyPixel';
import { WelcomeInstallWooPixel } from './WelcomeInstallWooPixel';
import {
  StripePixelInstallationIndication,
  useStripePixelInstalledConnectionInfo,
} from './StripePixelInstallationIndication';
import {
  ShopifyPixelInstallationIndication,
  UsePixelInstalledConnectionInfoArgs,
  useShopifyPixelInstalledConnectionInfo,
} from './ShopifyPixelInstallationIndication';
import {
  WooPixelInstallationIndication,
  useWooPixelInstalledConnectionInfo,
} from './WooPixelInstallationIndication';
import { StepCompletionInfo } from 'types/WizardData';

export function usePixelConnectionInfo(
  args: UsePixelInstalledConnectionInfoArgs,
): StepCompletionInfo {
  const msp: SalesPlatform = useAppSelector((state) => state.msp);
  const shopifyPixelConnectionInfo = useShopifyPixelInstalledConnectionInfo(args);
  const wooPixelConnectionInfo = useWooPixelInstalledConnectionInfo(args);
  const stripePixelConnectionInfo = useStripePixelInstalledConnectionInfo(args);

  const mspConnectionInfoMap: Partial<Record<SalesPlatform, StepCompletionInfo>> = {
    shopify: shopifyPixelConnectionInfo,
    woocommerce: wooPixelConnectionInfo,
    stripe: stripePixelConnectionInfo,
  };

  return mspConnectionInfoMap[msp] || { status: 'not-started', text: '' };
}

export const PixelInstallationIndication: React.FC<any> = (props) => {
  const msp: SalesPlatform = useAppSelector((state) => state.msp);
  return (
    <>
      {msp === 'shopify' && <ShopifyPixelInstallationIndication {...props} />}
      {msp === 'woocommerce' && <WooPixelInstallationIndication {...props} />}
      {msp === 'stripe' && <StripePixelInstallationIndication {...props} />}
    </>
  );
};
