import React from 'react';
import { PixelSnippet } from '../../attribution/PixelSnippet';
import { Icon, Spinner } from '@shopify/polaris';
import { PIXEL_MANUAL_INSTALL } from '../../../constants/knowledgeBaseLinks';
import { ExternalMinor } from '@shopify/polaris-icons';
import { useAppSelector } from 'reducers/RootType';

export const ManualInstall: React.FC<{ isHeadless?: boolean; snippet: any }> = ({
  isHeadless,
  snippet,
}) => {
  const shopMsp = useAppSelector((state) => state.msp);
  return (
    <>
      <p className="text-[15px] bolder mb-[5px]">Install the Main Snippet</p>
      <p className="text-[14px] regular">
        You can install the pixel manually by inserting the Triple Whale javascript snippet in the{' '}
        <span>{'<head>'}</span> of all pages.
      </p>

      <div className="my-[20px]" style={{ height: '220px' }}>
        {snippet ? (
          <PixelSnippet snippetCode={isHeadless ? snippet.headless : snippet.layout} lang="html" />
        ) : (
          <div className="text-center pt-[70px] pb-[10px]">
            <Spinner size="small" />
          </div>
        )}
      </div>

      {!isHeadless && (
        <>
          {' '}
          <p className="text-[15px] bolder mt-[20px] mb-[5px]">
            Install the Snippet on the Order Confirmation Page
          </p>
          <p className="text-[14px] regular">
            On your "Thank You" pages, install the following snippet:
          </p>
          <div className="mt-[20px]" style={{ height: '60px' }}>
            {snippet ? (
              <PixelSnippet snippetCode={snippet.thankYou} lang="html" />
            ) : (
              <div className="text-center pt-[30px] pb-[10px]">
                <Spinner size="small" />
              </div>
            )}
          </div>
        </>
      )}

      {shopMsp !== 'bigcommerce' && (
        <>
          <p className="text-[15px] bolder mt-[20px] mb-[5px]">
            Install the Snippet on Your Post-Purchase Upsell Page
          </p>
          <p className="text-[14px] regular">
            If your shop leverages Shopify’s Post-Purchase Upsell Page, install the main snippet on
            this page as well. This page can be found in the Shopify Admin by going to Settings{' '}
            {'>'} Checkout and navigating to the "Post-Purchase Page" section.
          </p>
          <div className="mt-[20px]" style={{ height: '300px' }}>
            {snippet ? (
              <PixelSnippet snippetCode={snippet.thirdPartyCheckout} lang="html" />
            ) : (
              <div className="text-center pt-[30px] pb-[10px]">
                <Spinner size="small" />
              </div>
            )}
          </div>
        </>
      )}

      <p className="text-[13px] regular mt-[30px] mb-[30px]">
        <span>For more information about installing manually, check out our</span>
        <a href={PIXEL_MANUAL_INSTALL} target="_blank" className="text-secondary-text ml-[5px]">
          knowledge base article.
          <span className="text-icon w-[13px]">
            <Icon source={ExternalMinor} />
          </span>
        </a>
      </p>
    </>
  );
};
