import { combineReducers } from 'redux';

const actionBannerConfig = (state: { title: string; onApplay: () => {} } | null = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const reducers = combineReducers({
  actionBannerConfig,
});
