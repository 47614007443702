import './CreativeCard.scss';

import amplitude from 'amplitude-js';
import StatusIndicator from 'components/library/StatusIndicator/StatusIndicator';
import TWImage from 'components/library/TWImage/TWImage';
import {
  CREATIVES_NO_COPY_MESSAGE,
  DISABLE_SHOW_IN_CHART_MESSAGE,
  MAX_ACTIVE_CREATIVES,
  PLACEHOLDER_IMAGE_URL,
} from 'constants/creativeCockpit';
import PixelIconSVG from 'components/PixelIconSVG';
import { metrics as METRICS } from 'constants/metrics/metrics';
import allServices from 'constants/services';
import { Property } from 'csstype';
import React, { FC, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { SelectableCreative } from 'types/creativeCockpit';
import { formatNumber } from 'utils/formatNumber';

import { Card, Checkbox, Spinner, Stack, Tooltip } from '@shopify/polaris';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { MetricsKeys } from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';

import { CreativesCockpitContext } from '../context';
import { loadingAdInfoForGai } from 'ducks/actions';
import { GENERATIVE_AI_POPUP_OPENED } from 'ducks/constants';
import { useAppDispatch } from 'index';
import { GenerativeAiPopupData } from 'types/general';
import { gradualReleaseFeatures } from 'ducks/shop';
import TWSlides from 'components/library/TWSlides/TWSlides';
import PicturesGallery from 'components/ProductAnalytics/PicturesGallery';
import { columnsToAddOnlyInProductAnalytics } from '../CreativeTable/columns';
import { isCreativeVideo } from 'utils/FileChecks';
import { Text, Icon } from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../../$stores/$shop';

interface CreativeCardProps {
  creative: SelectableCreative;
}

const CreativeCard: FC<CreativeCardProps> = ({ creative }) => {
  const dispatch = useAppDispatch();
  const hasPixelInstalled = useSelector((state: RootState) => state.hasPixelInstalled);
  const currency = useStoreValue($currency);
  const adImgs = useSelector((state: RootState) => state.productAnalytics.adImgs);
  const creativeAdImgs = adImgs?.filter((ad) => ad.productId === creative.id) || [];

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const format = 'YYYY-MM-DD';
  const mainDateStart = mainDatePickerSelectionRange?.start?.format(format);
  const mainDateEnd = mainDatePickerSelectionRange?.end?.format(format);

  // const { generative_tooling: hasContentHub } = useSelector(gradualReleaseFeatures);
  // const { generative_tooling: hasContentHub } = useSelector(gradualReleaseFeatures);
  const hasContentHub = true;
  let {
    averages,
    maximums,
    serviceId,
    selectedCreatives,
    loadingCreatives,
    selectedColumns,
    toggleCreativeSelection,
    setCreativeInModal,
    creativeInModal,
    shareHasPixelInstalled,
    setAdsModalProduct,
    skusModalProduct,
    isShareMode,
  } = useContext(CreativesCockpitContext);

  let topSection: JSX.Element | string;
  const PAGE_NAME = creative.assetType === 'product' ? 'Product Analytics' : 'Creative Cockpit';

  switch (creative.assetType) {
    case 'image':
    case 'video':
    case 'ad':
    case 'adName':
      topSection = (
        <div
          className={`${
            creativeInModal ? 'cursor-grab active:cursor-grabbing' : 'cursor-pointer'
          } top-wrapper mb-6.5 overflow-hidden`}
          onClick={() => setCreativeInModal?.(creative!)}
        >
          <TWImage
            className="w-full h-full rounded object-cover"
            wrapperClass="h-100"
            src={creative.thumbnail || creative.image}
            alt={creative.name || 'creative-asset'}
            icon={
              isCreativeVideo(creative) ? (
                <div className="fill-logo cursor-pointer">
                  <PlayCircleMajor className="text-light-blue" width={48} height={48} />
                </div>
              ) : null
            }
          />
        </div>
      );
      break;
    case 'product':
      const { images = [{ src: PLACEHOLDER_IMAGE_URL, alt: 'image', id: 0, type: 'image' }] } =
        creative;
      topSection = (
        <div
          className={`${
            creativeInModal ? 'cursor-grab active:cursor-grabbing' : 'cursor-pointer'
          } top-wrapper mb-6.5 overflow-hidden`}
        >
          <TWSlides items={images} action={() => setCreativeInModal?.(creative!)} />
        </div>
      );
      break;
    case 'copy':
      topSection = (
        <div className="top-wrapper overflow-auto h-60">
          <div
            className="top-body rounded p-4 mt-20 break-words"
            style={{ whiteSpace: 'break-spaces' }}
          >
            <Text>{creative.body || CREATIVES_NO_COPY_MESSAGE}</Text>
          </div>
        </div>
      );
      break;

    default:
      topSection = '';
      break;
  }

  const showInChartCheckbox = (
    <Checkbox
      label="Show In Graph"
      disabled={!creative.selected && (selectedCreatives?.length || 0) >= MAX_ACTIVE_CREATIVES}
      checked={creative.selected}
      onChange={() => {
        let postChangeCount = (selectedCreatives?.length || 0) + (creative.selected ? -1 : 1);
        amplitude.getInstance().logEvent(`${PAGE_NAME}: Chart Creatives Changed`, {
          creatives_count: postChangeCount,
        });
        toggleCreativeSelection!(creative);
      }}
    />
  );

  const openGenerativeAi = useCallback(
    async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      let asset;
      switch (creative.assetType) {
        case 'ad':
        case 'video':
          asset = creative.id;
          break;
        case 'adName':
          asset = creative.name;
          break;
        case 'copy':
          asset = creative.body;
          break;
        default:
          asset = creative[creative.assetType];
          break;
      }
      dispatch(
        loadingAdInfoForGai({
          accountId: creative.account_id,
          asset: asset,
          assetType: creative.assetType,
        }),
      );
      dispatch({
        type: GENERATIVE_AI_POPUP_OPENED,
        payload: {
          serviceId: serviceId,
          accountId: creative.account_id,
          entityId: creative.id,
          entityName: creative.adsetName,
          attemptFrom: 'ad',
          isVideo: false,
          imageUrl: creative.thumbnail,
          adIds: [creative.id],
        } as GenerativeAiPopupData,
      });
    },
    [dispatch, creative, serviceId],
  );

  return (
    <div className="CreativeCard group">
      <Card title={''}>
        {loadingCreatives && (
          <div className="loading-creatives-wrapper">
            <div className="loading-creatives">
              <Spinner size="large" />
            </div>
          </div>
        )}
        <Card.Section>
          <div className={`showInGraphCheckbox checkboxWrapper ${creative.assetType}`}>
            {!creative.selected && (selectedCreatives?.length || 0) >= MAX_ACTIVE_CREATIVES && (
              <>
                <Tooltip content={DISABLE_SHOW_IN_CHART_MESSAGE}>{showInChartCheckbox}</Tooltip>
              </>
            )}
            {(creative.selected || (selectedCreatives?.length || 0) < MAX_ACTIVE_CREATIVES) &&
              !skusModalProduct && <>{showInChartCheckbox}</>}
          </div>
          <Stack vertical>{topSection}</Stack>
          <Stack distribution="fillEvenly" wrap={true}>
            {creative.assetType !== 'product' && (
              <Stack.Item>
                <TopMetric
                  currency={currency}
                  metrics={['roas', 'conversionValue']}
                  serviceId={serviceId!}
                  creative={creative}
                  middleMetrics={[averages?.roas || 0, averages?.conversionValue || 0]}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              {(hasPixelInstalled || shareHasPixelInstalled) && (
                <TopMetric
                  currency={currency}
                  metrics={['pixelRoas', 'pixelConversionValue']}
                  serviceId={'pixel'}
                  creative={creative}
                  middleMetrics={[averages?.pixelRoas || 0, averages?.pixelConversionValue || 0]}
                />
              )}
            </Stack.Item>
          </Stack>
        </Card.Section>
        {creative.assetType !== 'product' && (
          <Card.Section>
            <div className="flex flex-col gap-10">
              <Bar
                metric="cpm"
                value={creative?.metrics?.cpm || 0}
                average={averages?.cpm || 0}
                maximum={maximums?.cpm || 0}
                direction="left"
                currency={currency}
              />
              <Bar
                metric="ctr"
                value={creative?.metrics?.ctr || 0}
                average={averages?.ctr || 0}
                maximum={maximums?.ctr || 0}
                direction="right"
                currency={currency}
              />
            </div>
          </Card.Section>
        )}
        <Card.Section>
          <Stack vertical>
            {creative.name && (
              <Stack.Item>
                <Stack wrap={false}>
                  <Stack.Item>
                    <div className="flex items-center gap-4">
                      {creative.assetType === 'ad' && <StatusIndicator status={creative.status} />}
                      <Text fw={500}>Name</Text>
                    </div>
                  </Stack.Item>
                  <Stack.Item fill>
                    <div className="break-all">
                      <Text ta="right">{creative.name}</Text>
                    </div>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            )}
            {['product', 'sku', 'video', 'image', 'copy', 'adName'].includes(creative.assetType) &&
              creative.numberOfAds && (
                <>
                  <Stack.Item>
                    <Stack wrap={false}>
                      <Stack.Item>
                        <Text fw={500}># Ads</Text>
                      </Stack.Item>
                      <Stack.Item fill>
                        {['product', 'sku'].includes(creative.assetType) && (
                          <div
                            className="cursor-pointer text-blue-700 hover:text-blue-900"
                            onClick={() => setAdsModalProduct?.(creative)}
                          >
                            <Text ta="right">{creative.numberOfAds}</Text>
                          </div>
                        )}
                        {['video', 'image', 'copy', 'adName'].includes(creative.assetType) && (
                          <Text ta="right">{creative.numberOfAds}</Text>
                        )}
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                  <Stack vertical>
                    <Stack.Item>
                      <div
                        className="cursor-pointer pt-4 gap-4 flex items-center justify-center"
                        onClick={() => setAdsModalProduct?.(creative)}
                      >
                        <PicturesGallery
                          images={creativeAdImgs}
                          w={100}
                          h={100}
                          numImgsToDisplay={6}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>
                </>
              )}

            {creative.assetType === 'ad' && (
              <Stack vertical>
                {selectedColumns?.find((c) => c.key === 'campaign') && (
                  <Stack.Item>
                    <Stack wrap={false}>
                      <Stack.Item>
                        <Text fw={500}>Campaign</Text>
                      </Stack.Item>
                      <Stack.Item fill>
                        <div className="break-all">
                          <Text ta="right">{creative.campaignName}</Text>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                )}
                {selectedColumns?.find((c) => c.key === 'adset') && (
                  <Stack.Item>
                    <Stack wrap={false}>
                      <Stack.Item>
                        <Text fw={500}>Ad set</Text>
                      </Stack.Item>
                      <Stack.Item fill>
                        <div className="break-all">
                          <Text ta="right">{creative.adsetName}</Text>
                        </div>
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                )}
              </Stack>
            )}
          </Stack>
        </Card.Section>

        <Card.Section>
          <Stack vertical>
            {Object.values(METRICS)
              .filter(
                (m) =>
                  selectedColumns?.map((col) => col.key)?.includes(m.key) &&
                  (m.showInCreativeCard.includes(creative.assetType) ||
                    m.showInCreativeCard.includes('all')) &&
                  (!m.showInServices ||
                    m.showInServices.includes(serviceId!) ||
                    (columnsToAddOnlyInProductAnalytics.includes(m.key) &&
                      creative.assetType === 'product')),
              )
              .map((m) => (
                <Stack.Item key={`${m.key}_${creative.id}`}>
                  <Stack>
                    <Stack.Item fill>
                      <Text fw={500}>
                        {m.type === 'pixel' ? (
                          <div className="flex items-center gap-2">
                            {/* <span>
                              <PixelIconSVG small={true} />
                            </span> */}
                            <Icon name="pixel" size={12} />
                            <Text>{m.shortLabel}</Text>
                          </div>
                        ) : (
                          <>{m.label}</>
                        )}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text>
                        {formatNumber(creative.metrics?.[m.key] || 0, {
                          style: m?.format || 'decimal',
                          currency,
                          maximumFractionDigits: m?.toFixed || 0,
                          minimumFractionDigits: m?.toFixed || 0,
                        })}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              ))}
            {creative.assetType === 'ad' && selectedColumns?.find((c) => c.key === 'link') && (
              <Stack.Item>
                <Stack>
                  <Stack.Item fill>
                    <Text fw={500}>Link</Text>
                  </Stack.Item>
                  <Stack.Item>
                    <a
                      target="_blank"
                      href={
                        allServices?.[serviceId!]?.externalAdsUrl?.(
                          serviceId!,
                          'ad',
                          creative.account_id,
                          undefined,
                          undefined,
                          creative.id,
                        ) || undefined
                      }
                      onClick={() =>
                        amplitude
                          .getInstance()
                          .logEvent('Creative Cockpit: Ads Manager Link Clicked')
                      }
                      rel="noreferrer"
                    >
                      <Icon name="external-minor" size={20} />
                    </a>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            )}
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};

export default CreativeCard;

const Bar: FC<{
  value: number;
  average: number;
  maximum: number;
  metric: MetricsKeys;
  currency: string;
  direction: 'left' | 'right';
}> = ({ value = 0, average = 0, maximum = 0, metric, direction, currency }) => {
  const doDarkMode = useDarkMode();
  const calcPercentage = useCallback((value, average) => {
    return (value / average) * 100;
  }, []);

  const percent = useCallback(
    () => calcPercentage(value, average * 2),
    [calcPercentage, average, value],
  );

  return (
    <Tooltip
      content={`Average ${METRICS?.[metric]?.label} is ${formatNumber(average, {
        style: METRICS?.[metric]?.format || 'decimal',
        currency,
        minimumFractionDigits: METRICS?.[metric]?.toFixed,
        maximumFractionDigits: METRICS?.[metric]?.toFixed,
      })}`}
    >
      <div className="barWrapper">
        <Text fw={500} pr="lg">
          {metric.toUpperCase()}
        </Text>
        <div
          className="progressBar"
          style={{
            backgroundImage: `linear-gradient(to ${direction}, #ea643d, #ea643d80 50%, #10b981)`,
          }}
        >
          <span
            className="point"
            style={{ width: `${percent()}%`, maxWidth: '100%', minWidth: '4%' }}
          >
            {/* <span className="point-number">{formatValue(value.toFixed(2))}</span> */}
            <span className="point-number">
              <Text>
                {formatNumber(value, {
                  style: METRICS?.[metric]?.format || 'decimal',
                  currency,
                  minimumFractionDigits: METRICS?.[metric]?.toFixed,
                  maximumFractionDigits: METRICS?.[metric]?.toFixed,
                })}
              </Text>
            </span>
            <span
              className="circle"
              style={{
                backgroundColor: doDarkMode ? 'white' : 'black',
                boxShadow: `0px 0px 0px 2px ${doDarkMode ? '#000' : '#fff'}`,
              }}
            ></span>
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

const TopMetric: FC<{
  creative: SelectableCreative;
  metrics: MetricsKeys[];
  middleMetrics: number[];
  serviceId: ServicesIds | 'pixel';
  currency: string;
}> = (props) => {
  const { serviceId, creative, currency, metrics = [], middleMetrics = [] } = props;
  const doDarkMode = useDarkMode();

  if (!metrics?.length || !middleMetrics?.length) {
    return null;
  }

  const [firstMetric, ...restMetrics] = metrics;

  const [firstMiddleMetric, ...restMiddleMetrics] = middleMetrics;

  const backgroundColorA = doDarkMode ? 0.2 : 0.1;
  const backgroundColor: Property.Color =
    firstMiddleMetric > (creative?.metrics?.[firstMetric] || 0)
      ? `rgba(176, 68, 106, ${backgroundColorA})`
      : `rgba(16, 171, 164, ${backgroundColorA})`;
  const borderColor: Property.Color =
    firstMiddleMetric > (creative?.metrics?.[firstMetric] || 0) ? '#8f3856' : '#10aba4';

  const { title, icon: Icon } = allServices?.[serviceId] || {};

  return (
    <div
      className="topMetric p-4"
      style={{ backgroundColor: doDarkMode ? '#222935' : '#c1c1c11a' }}
    >
      <Stack vertical spacing="tight">
        <Stack.Item>
          <Stack spacing="extraTight">
            <Stack.Item>{Icon && <Icon />}</Stack.Item>
            <Stack.Item>
              <Text fw={600}>{title}</Text>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Tooltip
            content={`Average ${METRICS?.[firstMetric]?.label} is ${formatNumber(
              firstMiddleMetric,
              {
                style: METRICS?.[firstMetric]?.format || 'decimal',
                currency,
                minimumFractionDigits: METRICS?.[firstMetric]?.toFixed,
                maximumFractionDigits: METRICS?.[firstMetric]?.toFixed,
              },
            )}`}
          >
            <div className="metric" style={{ backgroundColor, borderColor }}>
              <span className="metric-title">
                <Text>{METRICS?.[firstMetric]?.shortLabel}</Text>
              </span>
              <Text>
                {formatNumber(creative.metrics?.[firstMetric]!, {
                  style: METRICS?.[firstMetric]?.format || 'decimal',
                  currency,
                  minimumFractionDigits: METRICS?.[firstMetric]?.toFixed,
                  maximumFractionDigits: METRICS?.[firstMetric]?.toFixed,
                })}
              </Text>
            </div>
          </Tooltip>
        </Stack.Item>
      </Stack>
      {restMetrics.map((m, i) => (
        <Tooltip
          key={m}
          content={`Average ${METRICS?.[m]?.label} is ${formatNumber(restMiddleMetrics?.[i], {
            style: METRICS?.[m]?.format || 'decimal',
            currency,
            minimumFractionDigits: METRICS?.[m]?.toFixed,
            maximumFractionDigits: METRICS?.[m]?.toFixed,
          })}`}
        >
          <div
            className="metric white"
            style={{ backgroundColor: doDarkMode ? '#2E3746' : 'white' }}
          >
            <span className="metric-title">
              <Text>{METRICS?.[m]?.shortLabel}</Text>
            </span>
            <Text>
              {formatNumber(creative.metrics?.[m]!, {
                style: METRICS?.[m]?.format || 'decimal',
                currency,
                minimumFractionDigits: METRICS?.[m]?.toFixed,
                maximumFractionDigits: METRICS?.[m]?.toFixed,
              })}
            </Text>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
