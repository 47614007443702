import React from 'react';
import { Button as ShopifyButton, ButtonProps as ShopifyButtonProps } from '@shopify/polaris';

import './button.scss';

type ButtonProps = {
  loading?: boolean;
  white?: boolean;
  autoWidth?: boolean;
} & ShopifyButtonProps;

export const Button: React.FC<ButtonProps> = (props) => {
  let classes = `wrapped-button ${props.loading ? 'loading' : ''} ${props.white ? 'white' : ''} ${
    props.autoWidth ? 'auto-width' : ''
  }`;

  return (
    <span className={classes}>
      <ShopifyButton {...props} />
    </span>
  );
};
