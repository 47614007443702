import { useEffect } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { useMutableQueryParams } from 'hooks/useMutableQueryParams';

export const useShopDomainSync = () => {
  const [{ 'shop-id': shopId, 'shop-domain': shopDomain }, { set }] = useMutableQueryParams();
  const currentShopId = useStoreValue($currentShopId);

  // update url if $currentShopId is different
  useEffect(() => {
    const searchShopId = shopDomain || shopId || '';
    if (currentShopId && searchShopId !== currentShopId) {
      set('shop-id', currentShopId);
    }
  }, [shopId, shopDomain, set, currentShopId]);
};
