import { Card } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { CSSProperties, useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';

type LoomVideoPlayerProps = {
  source: string;
  inCard?: boolean;
  rounded?: boolean;
  inCardSection?: boolean;
};

export const VideoPlayer: React.FC<LoomVideoPlayerProps> = ({
  source,
  inCard,
  rounded,
  inCardSection,
}) => {
  const isLoomVideo = source.includes('loom.com');
  const isVimeoVideo = source.includes('player.vimeo.com');

  return (
    <>
      {inCard ? (
        <div className="w-full">
          <Card withBorder radius="xl">
            {isLoomVideo || isVimeoVideo ? (
              <div
                className="relative pt-[56.25%]"
                dangerouslySetInnerHTML={{
                  __html: `<iframe style="border-radius:0.375rem;position: absolute;top: 0;left: 0;bottom: 0;right: 0;width: 100%;height: 100%;" src="${source}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`,
                }}
              />
            ) : (
              <span className="relative pt-[56.25%]">
                <ReactPlayer
                  controls={true}
                  url={source}
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  width="100%"
                  height="100%"
                />
              </span>
            )}
          </Card>
        </div>
      ) : isLoomVideo || isVimeoVideo ? (
        <div className="w-full">
          <div
            className="relative pt-[56.25%]"
            dangerouslySetInnerHTML={{
              __html: `<iframe style="border-radius:0.375rem;position: absolute;top: 0;left: 0;bottom: 0;right: 0;width: 100%;height: 100%;" src="${source}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`,
            }}
          />
        </div>
      ) : (
        <div className="w-full">
          <div className={`relative pt-[56.25%]`}>
            <ReactPlayer
              controls={true}
              config={{
                youtube: {
                  playerVars: { controls: 1 },
                },
              }}
              url={source}
              style={
                {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  overflow: 'hidden',
                  borderRadius: inCardSection ? '0.375rem 0.375rem 0 0' : rounded ? '0.375rem' : '',
                } as CSSProperties
              }
              width="100%"
              height="100%"
            />
          </div>
        </div>
      )}
    </>
  );
};
