import React, { useEffect, useState } from 'react';

import DropDown from 'components/ltv/DropDown';
import { TIME_UNIT_OPTIONS } from './constants';
import { getFilterQueryLabel } from './queryTranslation';
import { FilterTimeUnit } from '@tw/types/module/services/insights';

type Props = {
  value: FilterTimeUnit;
  handleSelect: (value: FilterTimeUnit) => void;
};
const TimeUnitDropDown: React.FC<Props> = (props) => {
  const options = TIME_UNIT_OPTIONS;

  const [value, setValue] = useState<FilterTimeUnit>(props.value);

  useEffect(() => {
    props.handleSelect(value);
  }, [value]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <DropDown
      options={TIME_UNIT_OPTIONS.map((o) => ({ label: getFilterQueryLabel(o), value: o }))}
      value={value}
      handleSelect={setValue}
    />
  );
};

export default TimeUnitDropDown;
