import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import VerticalTabs from '../WizardWalkthrough/steps/VerticalTabs';
import { AutomaticInstall } from '../pixel/pixelInstallation/AutomaticInstall';
import { ManualInstall } from '../pixel/pixelInstallation/ManualInstall';
import { HeadlessInstall } from '../pixel/pixelInstallation/HeadlessInstall';
import { ThirdPartyCheckoutInstall } from '../pixel/pixelInstallation/ThirdPartyCheckoutInstall';
import moment from '@tw/moment-cached/module/timezone';
import { getSnippetCode } from './PixelSnippet';
import type { RootState } from '../../reducers/RootType';

export const PixelInstallation: React.FC<{}> = ({}) => {
  const pixelInstallStatus = useSelector((state: any) => state.pixelInstallStatus);
  let { allow_auto_install } = useSelector((state: any) => state.pixelSettings);
  const shop_setup = useSelector((state: any) => state.shopPixelSetup);
  const [snippet, setSnippet] = useState<any>({});
  const msp = useSelector((state: RootState) => state.msp);

  const [siteConfig, setSiteConfig] = useState<any>(shop_setup);
  const [selectedTab, setSelectedTab] = useState<string>('automatic-install');
  const _getSnippet = useCallback(async () => {
    setSnippet(await getSnippetCode());
  }, []);

  useEffect(() => {
    const newValue = { ...shop_setup };
    setSiteConfig(newValue);
    setSelectedTab(shop_setup?.shopify == false ? 'headless-install' : 'automatic-install');
  }, [shop_setup]);

  useEffect(() => {
    setSnippet(null);
    _getSnippet();
  }, [_getSnippet]);

  const renderDate = (date) => moment(date.toDate()).local().format('LLL');
  const lastInstalled = pixelInstallStatus?.last_installed
    ? renderDate(pixelInstallStatus?.last_installed)
    : '';

  return (
    <>
      <div id="pixel-installation">
        <div className="content flex flex-col w-full h-full pt-[40px]">
          <div className="lg:w-[100%] flex-grow max-w-[100%]">
            <VerticalTabs
              selected={selectedTab}
              tabs={[
                {
                  id: 'automatic-install',
                  title: <>Standard Installation</>,
                  subTitle: ['shopify', 'bigcommerce'].includes(msp) ? (
                    <div>
                      <b>Status:</b>{' '}
                      {allow_auto_install ? (
                        <span style={{ color: '#3acf00' }}>Enabled</span>
                      ) : (
                        <span style={{ color: '#9D9D9DFD' }}>Disabled</span>
                      )}
                    </div>
                  ) : (
                    ''
                  ),
                  className: siteConfig['shopify'] == false ? 'not-recommended' : '',
                  component: <AutomaticInstall />,
                },
                {
                  id: 'manual-install',
                  title: 'Manual Installation',
                  className: 'not-recommended',
                  component: <ManualInstall isHeadless={false} snippet={snippet} />,
                },
                {
                  id: 'headless-install',
                  title: 'Headless Installation',
                  className: siteConfig['shopify'] == false ? '' : 'not-recommended',
                  component: <HeadlessInstall snippet={snippet} />,
                },
                {
                  id: 'thirdparty-install',
                  title: 'Third-Party Landing Pages',
                  className:
                    siteConfig['headless'] || siteConfig['additional_pages']
                      ? ''
                      : 'not-recommended',
                  component: <HeadlessInstall isThirdParty={true} snippet={snippet} />,
                },
                {
                  id: 'thirdparty-checkout',
                  title: 'Third-Party/Custom Checkout',
                  className: siteConfig['third_party_checkout'] ? '' : 'not-recommended',
                  component: <ThirdPartyCheckoutInstall snippet={snippet} />,
                },
              ]}
            ></VerticalTabs>
          </div>
        </div>
      </div>
    </>
  );
};
