import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { SqlSnippet } from 'components/Willy/types/willyTypes';
import { v4 as uuidV4 } from 'uuid';
import { Button, Checkbox, Modal, Text, Textarea, TextInput } from '@tw/ui-components';
import _db, { firestoreRef } from 'utils/DB';
import { $allSnippets } from '$stores/willy/$tables';
import { SAVED_SNIPPETS_COLLECTION } from './constants';
import { $dialect } from '$stores/$user';
import { DEFAULT_DIALECT } from 'components/Willy/constants';
import { useStoreValue } from '@tw/snipestate';

type SaveSnippetProps = {
  snippetToEdit?: SqlSnippet;
  snippet?: string;
  open: boolean;
  onClose: () => void;
};

export const SaveSnippet: React.FC<SaveSnippetProps> = ({
  snippetToEdit,
  snippet,
  open,
  onClose,
}) => {
  const snippets = useStoreValue($allSnippets);
  const dialect = useStoreValue($dialect);

  const [snippetText, setSnippetText] = useState('');
  const [snippetName, setSnippetName] = useState('');
  const [isGlobalSnippet, setIsGlobalSnippet] = useState(false);
  const [savingSnippet, setSavingSnippet] = useState(false);
  const isAdmin = useAppSelector((state) => !!state.user.isAdminClaim);

  const nameIsTaken = useMemo(() => {
    const nameToCheck = snippetName.trimStart().trimEnd();
    return (
      snippets.some((s) => s.name.trim() === nameToCheck) && snippetToEdit?.name !== nameToCheck
    );
  }, [snippets, snippetName, snippetToEdit?.name]);

  useEffect(() => {
    if (snippet) {
      setSnippetText(snippet);
    } else if (snippetToEdit) {
      setSnippetText(snippetToEdit.snippet);
      setSnippetName(snippetToEdit.name);
      setIsGlobalSnippet(!!snippetToEdit.isGlobal);
    }
  }, [snippet, snippetToEdit]);

  const saveSnippet = useCallback(
    async (snippet: string, name: string, isGlobal?: boolean) => {
      try {
        const docId = snippetToEdit?.id || uuidV4();
        const doc: SqlSnippet = {
          snippet,
          name: name.trimStart().trimEnd(),
          id: docId,
          isGlobal: !!isGlobal,
          dialect: dialect || DEFAULT_DIALECT,
        };
        if (isGlobal) {
          await firestoreRef()
            .collection('global_sql_snippets')
            .doc(docId)
            .set(doc, { merge: true });
        } else {
          await _db().collection(SAVED_SNIPPETS_COLLECTION).doc(docId).set(doc, { merge: true });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [snippetToEdit, dialect],
  );

  return (
    <Modal
      opened={open}
      onClose={() => {
        onClose();
      }}
      title="Save as snippet"
      size="lg"
    >
      <div className="p-4">
        <div className="flex flex-col gap-6.5">
          {isAdmin && (
            <Checkbox
              checked={isGlobalSnippet}
              onChange={setIsGlobalSnippet}
              label="Save as global snippet"
              disabled={!!snippetToEdit}
            />
          )}
          {isAdmin && (
            <Text size="xs" tt="uppercase">
              ({snippetToEdit?.dialect || dialect})
            </Text>
          )}
          <TextInput
            required
            value={snippetName}
            onChange={setSnippetName}
            label="Name"
            error={nameIsTaken ? 'This name is already taken' : false}
          />

          <Textarea
            required
            value={snippetText}
            onChange={(e) => setSnippetText(e.target.value)}
            label="Snippet"
            minRows={4}
          />
        </div>
      </div>
      <Modal.Footer>
        <div className="p-4 flex gap-4">
          <Button
            variant="white"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!snippetName?.trim() || !snippetText?.trim() || nameIsTaken}
            loading={savingSnippet}
            onClick={async () => {
              if (!snippetText.trim() || !snippetName.trim()) {
                return;
              }
              setSavingSnippet(true);
              await saveSnippet(snippetText, snippetName, isGlobalSnippet);
              setSavingSnippet(false);
              onClose();
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
