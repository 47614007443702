import {
  CDPActionFilterActions,
  ACTION_FILTER_RELATED_PROPERTIES,
  CDPSegmentFilterType,
  CDPActionProperty,
  CDPSegmentFilter,
  CDPSegmentQuery,
  FEATURES_RELATED_ACTIONS,
  USER_PROPERTIES,
  UN_SUPPORTED_PROPERTIES,
  UN_SUPPORTED_ACTIONS,
} from '@tw/types/module/services/cdp';
import {
  FilterPropertyType,
  FilterComparator,
  FilterProperty,
} from '@tw/types/module/services/insights';
import { cloneDeep, isNull, omitBy } from 'lodash';
import { SegmentRelationFilter } from './SegmentRelationFilter';

import UserActionFilter from './UserActionFilter';
import UserPropertyFilter from './UserPropertyFilter';
import { DEFAULT_COMPARATORS, getPropertyType } from 'components/Insights/Filters/constants';
import RFMFilter from './RFMFilter';
import WhaleGPTFilter from './WhaleGPTFilter';

// ---- OPTIONS
export const FILTER_TYPE_OPTIONS = {
  [CDPSegmentFilterType.ACTION]: UserActionFilter,
  [CDPSegmentFilterType.USER_PROPERTY]: UserPropertyFilter,
  [CDPSegmentFilterType.SEGMENT_RELATION]: SegmentRelationFilter,
  [CDPSegmentFilterType.RFM_SEGMENT]: RFMFilter,
  [CDPSegmentFilterType.WHALE_GPT]: WhaleGPTFilter,
};

export const FILTER_TYPES_OPTIONS = [
  CDPSegmentFilterType.ACTION,
  CDPSegmentFilterType.USER_PROPERTY,
  CDPSegmentFilterType.SEGMENT_RELATION,
];
export const USER_ACTION_OPTIONS = Object.values(CDPActionFilterActions);
export const USER_PROPERTIES_OPTIONS = USER_PROPERTIES;

// ---- DEFAULTS
const DEFAULT_FILTER_TYPE = CDPSegmentFilterType.ACTION;
const DEFAULT_USER_PROPERTY = FilterProperty.CUSTOMER_NAME;
const DEFAULT_ACTION_TYPE = CDPActionFilterActions.MADE_PURCHASE;

export const DEFAULT_ACTION_PREPERTIES = {
  [CDPActionFilterActions.VISITED_URL]: FilterProperty.ATTRIBUTION_URL_PATH,
};

export function isActionAvailable(action: CDPActionFilterActions, features: string[]): boolean {
  if (FEATURES_RELATED_ACTIONS[action]) {
    const missingFeatures = FEATURES_RELATED_ACTIONS[action]?.some(
      (actionFeature) => !features.includes(actionFeature),
    );
    return !missingFeatures;
  } else {
    return true;
  }
}

export function getActionPropsByAction(action: CDPActionFilterActions): CDPActionProperty[] {
  return ACTION_FILTER_RELATED_PROPERTIES[action] || [];
}

export function buildEmptyFilterByType(type?: CDPSegmentFilterType): CDPSegmentFilter {
  type = type || DEFAULT_FILTER_TYPE;

  switch (type) {
    case CDPSegmentFilterType.ACTION:
      return {
        type,
        definition: {
          action: {
            type: DEFAULT_ACTION_TYPE,
            comparator: DEFAULT_COMPARATORS[FilterPropertyType.NUMBER],
          },
          time: {
            comparator: DEFAULT_COMPARATORS[FilterPropertyType.TIME],
          },
        },
      } as CDPSegmentFilter;
    case CDPSegmentFilterType.USER_PROPERTY:
      return {
        type,
        definition: {
          property: {
            property: DEFAULT_USER_PROPERTY,
            comparator: DEFAULT_COMPARATORS[getPropertyType(DEFAULT_USER_PROPERTY)],
          },
        },
      } as CDPSegmentFilter;
    case CDPSegmentFilterType.SEGMENT_RELATION:
      return {
        type,
        definition: {
          property: {
            comparator: FilterComparator.IS,
          },
        },
      } as CDPSegmentFilter;
    default:
      return {
        type,
        definition: {},
      } as CDPSegmentFilter;
  }
}

export function buildEmptySegmentQuery(): CDPSegmentQuery {
  return {
    type: 'AND',
    filters: [
      {
        type: 'OR',
        filters: [buildEmptyFilterByType() as CDPSegmentFilter],
      },
    ],
  };
}

export function buildWhaleGPTSegmentQuery(
  id: string,
  question: string,
  query: string,
): CDPSegmentQuery {
  return {
    type: 'AND',
    filters: [
      {
        type: 'OR',
        filters: [
          {
            type: CDPSegmentFilterType.WHALE_GPT,
            definition: {
              id,
              question,
              query,
            },
          },
        ],
      },
    ],
  };
}

export function getDefaultPropertyForAction(action: CDPActionFilterActions) {
  const defaultProperty = DEFAULT_ACTION_PREPERTIES[action];
  if (defaultProperty) {
    return {
      property: defaultProperty,
      comparator: DEFAULT_COMPARATORS[getPropertyType(defaultProperty)],
    };
  } else {
    return undefined;
  }
}

export function getBeautifyQuery(query: CDPSegmentQuery): CDPSegmentQuery {
  const beautifyQuery = cloneDeep(query);

  beautifyQuery.filters.forEach((filtersGroup) => {
    delete filtersGroup['react-key'];

    filtersGroup.filters.forEach((filter) => {
      delete filter['react-key'];

      if (filter.definition?.['property']) {
        filter.definition['property'] = omitBy(filter.definition['property'], isNull);
      }

      if (filter.definition?.['action']) {
        filter.definition['action'] = omitBy(filter.definition['action'], isNull);
      }

      if (filter.definition?.['time']) {
        filter.definition['time'] = omitBy(filter.definition['time'], isNull);
      }
    });
  });

  return beautifyQuery;
}

export function filterUnSupportedProperties(
  properties: FilterProperty[],
  currentVal?: FilterProperty,
): FilterProperty[] {
  return properties.filter(
    (option) =>
      option === currentVal || !UN_SUPPORTED_PROPERTIES.find((property) => property === option),
  );
}

export function filterUnSupportedActions(
  actions: CDPActionFilterActions[],
  features: string[],
  currentVal?: CDPActionFilterActions,
): CDPActionFilterActions[] {
  return actions.filter(
    (option) =>
      option === currentVal ||
      (!UN_SUPPORTED_ACTIONS.includes(option) && isActionAvailable(option, features)),
  );
}
