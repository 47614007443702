import firebase from 'firebase/compat/app';
import { toast } from 'react-toastify';
import { callUpdateToday } from '../utils/callUpdateToday';
import db from '../utils/DB';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { minimizeSectionOnPress } from './summary';
import { shopServiceDisconnect } from './shopServices';
import axiosInstance from 'utils/axiosInstance';
import { setServiceAccountInfo } from '../utils/setServiceAccountInfo';
import { Action } from 'redux';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;

const TIKTOK_CONNECT_STARTED = 'TIKTOK_CONNECT_STARTED';
const TIKTOK_CONNECT_DONE = 'TIKTOK_CONNECT_DONE';
const TIKTOK_DISCONNECT_STARTED = 'TIKTOK_DISCONNECT_STARTED';
const TIKTOK_ADS_CONFIG_SCREEN_OPEN = 'TIKTOK_ADS_CONFIG_SCREEN_OPEN';
// can't just JSON parse and get advertiser_ids because of Tiktok very long integers,
// like 6828191280686694406 turns to 6828191280686690000
const advertiserIds = (t) =>
  t
    .split('advertiser_ids": [')[1]
    .split(']')[0]
    .split(',')
    .map((id) => ({ id: id.trim() }));

export const TIKTOK_ACCOUNTS_ARE_NOT_SELECTED = 'TIKTOK_ACCOUNTS_ARE_NOT_SELECTED';
export const tiktokAccountsAreNotSelected = (tiktokAccessToken) => {
  const advertiser_ids = advertiserIds(tiktokAccessToken);

  return (dispatch) => {
    if (!advertiser_ids || !advertiser_ids.length) {
      alert('Something went wrong with your Tiktok account. Please try re-connecting');
    } else {
      dispatch({ type: TIKTOK_ACCOUNTS_ARE_NOT_SELECTED, advertiser_ids });
      dispatch(toggleTiktokConfigScreenOpen());
    }
  };
};

export const TIKTOK_CONNECT_ON_PRESS = 'TIKTOK_CONNECT_ON_PRESS';
export const TOGGLE_TIKTOK_CONFIG_SCREEN_OPEN = 'TOGGLE_TIKTOK_CONFIG_SCREEN_OPEN';
export const toggleTiktokConfigScreenOpen = () => ({
  type: TOGGLE_TIKTOK_CONFIG_SCREEN_OPEN,
});

export const TIKTOK_CONFIG_SAVE = 'TIKTOK_CONFIG_SAVE';
export const tiktokConfigSave = () => {
  return {
    type: TIKTOK_CONFIG_SAVE,
  };
};

export const tiktokConnectOnPress = (dispatch?: (action: Action) => void) => {
  try {
    if (dispatch) {
      dispatch({
        type: TIKTOK_CONNECT_STARTED,
      });
    }
    const url = `v2/tiktok-ads/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    console.log(`ERROR: `, e);
    if (dispatch) {
      dispatch({
        type: TIKTOK_CONNECT_DONE,
      });
    }
  }
};

export function tiktokSaveAccountsAndStartImporting() {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    dispatch(tiktokConfigSave());
    const { tiktokAccounts, currentShopId } = getState();
    await setServiceAccountInfo(currentShopId, 'tiktok-ads', {
      tiktokAccounts: tiktokAccounts.filter((acc) => acc.selected),
    });
    dispatch(minimizeSectionOnPress('tiktok', false));
  };
}

export function tiktokDisconnect() {
  return async (dispatch, getState) => {
    dispatch({
      type: TIKTOK_DISCONNECT_STARTED,
    });
    toggleSaveIndicator();
    const url = `v2/tiktok-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('tiktokAccessToken'));
        dispatch(tiktokDisconnectDone());
      });
  };
}

export const TIKTOK_DISCONNECT_DONE = 'TIKTOK_DISCONNECT_DONE';

export function tiktokDisconnectDone() {
  return {
    type: TIKTOK_DISCONNECT_DONE,
  };
}

export const TIKTOK_ACCOUNTS_IDS_ON_CHANGE = 'TIKTOK_ACCOUNTS_IDS_ON_CHANGE';
export const tiktokAccountsSelectOnChange = (value) => ({
  type: TIKTOK_ACCOUNTS_IDS_ON_CHANGE,
  value,
});

const TOGGLE_TIKTOK_PURCHASE_EVENT_SETTINGS_OPEN = 'TOGGLE_TIKTOK_PURCHASE_EVENT_SETTINGS_OPEN';
export const onToggleTiktokPurchaseEventSettingsOpen = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_TIKTOK_PURCHASE_EVENT_SETTINGS_OPEN });
};
const TIKTOK_PURCHASE_EVENT_SETTINGS_CLOSE = 'TIKTOK_PURCHASE_EVENT_SETTINGS_CLOSE';
export const onTiktokPurchaseEventSettingsClose = () => (dispatch, getState) => {
  dispatch({ type: TIKTOK_PURCHASE_EVENT_SETTINGS_CLOSE });
};

const tiktokAccounts = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.tiktokAccounts || state;
    case TIKTOK_ACCOUNTS_ARE_NOT_SELECTED:
      return action.advertiser_ids;
    case TIKTOK_ACCOUNTS_IDS_ON_CHANGE:
      return action.value;
    default:
      return state;
  }
};

const tiktokConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case TIKTOK_CONNECT_ON_PRESS:
      return true;
    case TOGGLE_TIKTOK_CONFIG_SCREEN_OPEN:
      return !state;
    case TIKTOK_CONFIG_SAVE:
      return false;
    case TIKTOK_ADS_CONFIG_SCREEN_OPEN:
      return true;
    default:
      return state;
  }
};

const isTiktokPurchaseEventSettingsOpen = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_TIKTOK_PURCHASE_EVENT_SETTINGS_OPEN:
      return !state;
    case TIKTOK_PURCHASE_EVENT_SETTINGS_CLOSE:
      return false;
    default:
      return state;
  }
};

const isTiktokConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.tiktokAccessToken;
    case TIKTOK_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'tiktok-ads' || state;
    default:
      return state;
  }
};

const isTiktokIntegrationBtnLoading = (state = false, action) => {
  switch (action.type) {
    case TIKTOK_CONNECT_STARTED:
      return true;
    case TIKTOK_CONNECT_DONE:
      return false;
    case TIKTOK_DISCONNECT_STARTED:
      return true;
    case TIKTOK_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};

export const reducers = {
  isTiktokConnected,
  tiktokConfigScreenIsOpen,
  tiktokAccounts,
  isTiktokPurchaseEventSettingsOpen,
  isTiktokIntegrationBtnLoading,
};
