import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAppSelector } from 'reducers/RootType';
import { Stripe } from '@stripe/stripe-js';

export const useStripePromise = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const stripePublisherKey = useAppSelector((state) => state.signupJourney.stripePublisherKey);

  useEffect(() => {
    if (stripePublisherKey && !isLoaded) {
      setIsLoaded(true);
      let { data } = stripePublisherKey;
      if (!data || data.statusCode >= 400) {
        data = 'pk_live_Acye27mpfekyrQl1tQzHiPES00tFwrZmdj'; // fallback should not happen;
      }
      setStripePromise(loadStripe(data));
    }
  }, [stripePublisherKey, isLoaded]);

  return { stripePromise };
};
