import { Collapse, Flex, Icon, Size, Text } from '@tw/ui-components';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';

type EditorCollapseProps = {
  title: string;
  description?: string;
  children: ReactNode;
  initialOpened?: boolean;
  px?: Size;
};

export const EditorCollapse: FC<EditorCollapseProps> = ({
  title,
  children,
  description,
  initialOpened,
  px,
}) => {
  const [opened, setOpened] = useState<boolean>(initialOpened ?? false);
  const collapseBodyRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  const scrollInto = useCallback(() => {
    setTimeout(() => {
      if (collapseBodyRef.current && opened) {
        collapseBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 100);
  }, [collapseBodyRef, opened]);

  return (
    <Flex direction="column" px={px}>
      <Flex
        direction="column"
        onClick={() => {
          handleToggle();
          scrollInto();
        }}
        cursor="pointer"
      >
        <Flex align="center" gap="sm">
          <div className={`flex transition-transform ${opened ? 'rotate-90' : ''}`}>
            <Icon name={'chevron-right-minor'} size={12} />
          </div>
          <Text size="sm" weight={500} color="gray.8">
            {title}
          </Text>
        </Flex>
        {description && (
          <Text size="xs" weight={500} color="gray.6">
            {description}
          </Text>
        )}
      </Flex>
      <Collapse in={opened} ref={collapseBodyRef}>
        {children}
      </Collapse>
    </Flex>
  );
};
