import { Fragment, useContext, useEffect, useState } from 'react';
import { WizardContext } from '../WizardContext';
import { MeetingPlanner } from './MeetingPlanner';
import MobileQRCode from './MobileQRCode';

import './steps.scss';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { RootState, useAppSelector } from 'reducers/RootType';
import { REVENUE_IDS_LT_1MIL } from 'constants/revenueIdSets';
import { Anchor, Text } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $currentRevenue, $isFreeShop } from '$stores/willy/$subscription';
import { toast } from 'react-toastify';

const router = (isFreeShop, revenue, primary_support_team) => {
  //welcome wizard
  if (isFreeShop) {
    if (revenue >= 10) {
      return 'app-router';
    } else {
      return null;
    }
  } else {
    if (primary_support_team === 'Customer Success Office Hours') {
      return 'Customer Success Office Hours';
    }
    if (primary_support_team === 'Customer Success') {
      return 'csm-2024';
    } else if (primary_support_team === 'Implementation') {
      return 'implementation-2024';
    } else if (primary_support_team === 'L1 Support') {
      return 'Please reach out to us via the chat icon below!';
    }
  }
};

export const WelcomeSummary: React.FC = () => {
  const { completeWizard } = useContext(WizardContext);
  const { shouldNotBeSeen: isHubspotMeetingLocked } = useFeatureFlag(
    FeatureFlag.HUBSPOT_MEETING_FF,
  );

  const revenue = useStoreValue($currentRevenue);
  const isFreeShop = useStoreValue($isFreeShop);

  const { hubSpotCompany } = useAppSelector((state: RootState) => state.hubspot);
  const { primary_support_team } = hubSpotCompany.properties || {};

  const routerId = router(isFreeShop, revenue, primary_support_team);

  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      {!isHubspotMeetingLocked ? (
        <Fragment>
          <p className="step-title">All done! Need further assistance?</p>
          {routerId === 'Customer Success Office Hours' && (
            <Text>
              Explore our{' '}
              <Anchor href="https://try.triplewhale.com/customer-success-office-hours">
                office hours
              </Anchor>
            </Text>
          )}{' '}
          {(routerId === 'Please reach out to us via the chat icon below!' || !routerId) && (
            <Text>Please reach out to us via the chat icon below!</Text>
          )}{' '}
          {routerId &&
            routerId != 'Customer Success Office Hours' &&
            routerId != 'Please reach out to us via the chat icon below!' && (
              <>
                <p className="step-description mb-[15px]">
                  Book time with our team to review your setup and prepare you to get the most out
                  of Triple Whale.
                </p>

                <div className="w-full">
                  <MeetingPlanner routerId={routerId} />
                </div>
              </>
            )}
        </Fragment>
      ) : (
        <MobileQRCode />
      )}

      <button className="step-button" onClick={completeWizard}>
        Let's get started!
      </button>
    </div>
  );
};

export const WelcomeSummarySideComponent: React.FC = () => {
  const { shouldNotBeSeen: isHubspotMeetingLocked } = useFeatureFlag(
    FeatureFlag.HUBSPOT_MEETING_FF,
  );

  return !isHubspotMeetingLocked ? <MobileQRCode /> : null;
};
