import React from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'reducers/RootType';

export default () => {
  const googleSheetsAccounts = useSelector((state: RootState) => state.googleSheetsAccounts);
  return (
    <div>
      <h2>Google Sheets Connection Details</h2>
      <ul>
        {googleSheetsAccounts.map((account) => (
          <li key={account.id}>
            <p>
              {account.name} {account.email && `(${account.email})`}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
