import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectConnectedServices } from 'utils/selectors';
import amplitude from 'amplitude-js';
import { type RootState } from 'reducers/RootType';

export default function Amplitude() {
  const allServices = useSelector(selectConnectedServices);

  const customSections = useSelector((state: RootState) => state.customSections);

  //adding tracker how many/which connected services
  useEffect(() => {
    const connectedServices = Object.keys(allServices).filter((k) => allServices[k]);
    const numServices = connectedServices.length;
    // shopify is always connected so checking if other services are connected
    if (numServices > 1) {
      const numConnectedServices = new amplitude.Identify()
        .set('Number of Connected Services', numServices)
        .set('Connected Services', connectedServices);
      amplitude.getInstance().identify(numConnectedServices);
    }
  }, [allServices]);
  //adding tracker how many custom sections
  useEffect(() => {
    const sectionNames = customSections.map((x) => x.title);
    const allCustomSections = new amplitude.Identify()
      .set('Number of Custom Sections', customSections.length)
      .set('Custom Sections', sectionNames);
    amplitude.getInstance().identify(allCustomSections);
  }, [customSections]);

  return null;
}
