import { PixelSnippet } from '../../attribution/PixelSnippet';
import { Icon, Spinner } from '@shopify/polaris';
import { PIXEL_MANUAL_INSTALL } from '../../../constants/knowledgeBaseLinks';
import { ExternalMinor } from '@shopify/polaris-icons';

export const HeadlessInstall: React.FC<{ isThirdParty?: boolean; snippet: any }> = ({
  isThirdParty,
  snippet,
}) => {
  return (
    <>
      <p className="text-[15px] bolder mb-[5px]">Install the Main Snippet</p>
      <p className="text-[14px] regular">
        You can install the pixel manually by inserting the Triple Whale "headless" javascript
        snippet in the <span>{'<head>'}</span> of all pages.
      </p>
      <div className="" style={{ height: '230px' }}>
        {snippet ? (
          <PixelSnippet snippetCode={snippet.headless} lang="html" />
        ) : (
          <div className="text-center pt-[70px] pb-[10px]">
            <Spinner size="small" />
          </div>
        )}
      </div>

      <p className="text-[15px] bolder mb-[5px]">Tracking Add to Carts</p>
      {isThirdParty && (
        <p className="text-[14px] regular">
          If customers can add items to their cart on your landing page, you should register an
          'AddToCart' event each time an item is added by firing the following javascript event and
          passing in the item ID and quantity.
        </p>
      )}
      {!isThirdParty && (
        <p className="text-[14px] regular">
          To track when a customer adds an item to their cart, you can fire an ‘AddToCart’
          javascript event, dynamically inserting both the item ID and quantity (these values are
          required).
        </p>
      )}
      <PixelSnippet
        snippetCode={`TriplePixel('AddToCart', {item: 4428522618989, q: 10});`}
        lang="javascript"
      />

      <p className="text-[14px] regular">
        Optionally, if your system generates unique cart tokens, you can also set the token
        parameter by passing it into your ‘AddToCart’ event.
      </p>
      <PixelSnippet
        snippetCode={`TriplePixel('AddToCart', {item: 4428522618989, q: 10, token: 'a4707c79e1816cbe80ac995be636b693'});`}
        lang="javascript"
      />

      <p className="text-[15px] bolder mt-[20px] mb-[5px]">Capturing Visitor Contact Information</p>
      {isThirdParty && (
        <p className="text-[14px] regular">
          If your landing page collects email or phone contact info, you can pass that info back to
          Triple Whale by firing a 'Contact' javascript event and passing in the contact
          information.
        </p>
      )}
      {!isThirdParty && (
        <p className="text-[14px] regular">
          To help Triple Whale connect events for a user, any time you collect phone or email
          information from a visitor, it should be passed along by firing a 'Contact' event that
          includes the phone or email value.
        </p>
      )}
      {isThirdParty && (
        <p className="text-[14px] bolder mt-[10px]">
          <b>
            It is strongly recommended that you add a contact event anywhere you capture customer
            contact details to ensure that the Triple Pixel is able to connect all events from the
            customer’s journey.
          </b>
        </p>
      )}
      {!isThirdParty && (
        <p className="text-[14px] bolder mt-[10px]">
          <b>
            It is strongly recommended that you add a contact event on your order confirmation page
            to ensure that the order gets connected to the journey for proper attribution.
          </b>
        </p>
      )}

      <div className="" style={{ height: '200px' }}>
        {snippet ? (
          <PixelSnippet snippetCode={snippet.thirdParty} lang="html" />
        ) : (
          <div className="text-center pt-[70px] pb-[10px]">
            <Spinner size="small" />
          </div>
        )}
      </div>

      <p className="text-[14px] regular">
        The above script can be modified to send just email or both email and phone by updating the
        ‘Contact’ event properties.
      </p>

      <p className="text-[15px] bolder mt-[20px] mb-[5px]">Email</p>
      <PixelSnippet
        snippetCode={`TriplePixel('Contact', {email: 'madison@gmail.com'});`}
        lang="javascript"
      />

      <p className="text-[15px] bolder mb-[5px]">Phone</p>
      <PixelSnippet
        snippetCode={`TriplePixel('Contact', {phone: '123-456-7891'});`}
        lang="javascript"
      />

      <p className="text-[15px] bolder mb-[5px]">Both</p>
      <PixelSnippet
        lang="javascript"
        snippetCode={`TriplePixel('Contact', {email: 'madison@gmail.com', phone: '123-456-7891'});`}
      />

      <p className="text-[13px] regular mt-[20px] mb-[30px]">
        <span>For more information about installation in a headless environment check out our</span>
        <a href={PIXEL_MANUAL_INSTALL} target="_blank" className="text-secondary-text ml-[5px]">
          knowledge base article.
          <span className="text-icon w-[13px]">
            <Icon source={ExternalMinor} />
          </span>
        </a>
      </p>
    </>
  );
};
