import { refreshDateRanges } from '$stores/willy/$dateRange';
import { Tooltip } from '@shopify/polaris';
import { useStoreValue } from '@tw/snipestate';
import { ActionIcon, Size, useLocalStorage } from '@tw/ui-components';
import { useDashContext } from 'components/Willy/dashContext';
import moment from 'moment';
import { useEffect } from 'react';

export const RefreshDashboardButton = () => {
  const { $dashboardId } = useDashContext();
  const dashboardId = useStoreValue($dashboardId);
  const [lastRefresh, setLastRefresh] = useLocalStorage({
    key: 'last_refresh_dashboard',
    defaultValue: new Date().getTime(),
  });

  useEffect(() => {
    setLastRefresh(moment().valueOf());
    //eslint-disable-next-line
  }, [dashboardId]);

  return (
    <Tooltip content={`Last updated ${moment(lastRefresh).local().format('hh:mm A')}`}>
      <ActionIcon
        icon="refresh"
        variant="activatorWithHover"
        size={30 as Size}
        onClick={async () => {
          refreshDateRanges();
          setLastRefresh(moment().valueOf());
        }}
      />
    </Tooltip>
  );
};
