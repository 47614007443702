import { Flex, Icon, Text } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { goToStore } from 'components/Nav/utils';
import { SettingsAvatar } from '../../blocks/SettingsAvatar';

export const TopSection = () => {
  const dispatch = useAppDispatch();

  return (
    <Flex justify="space-between" align="center" cursor="pointer">
      <Flex gap="xs" align="center" onClick={() => goToStore(dispatch)}>
        <Icon name="caret-left" height={13} width={8} />
        <Text fz="md" fw={500}>
          Pods
        </Text>
      </Flex>
      <SettingsAvatar />
    </Flex>
  );
};
