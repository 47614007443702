import { useEffect, useRef } from 'react';

export function useJustMounted() {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = false;
  }, []);

  return mounted.current;
}
