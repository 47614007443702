import { Flex, Size, Switch, Text, Tooltip } from '@tw/ui-components';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { $activeAppVersion, $navWidth } from '$stores/nav-config-stores';
import { genericEventLogger, analyticsEvents } from 'utils/dataLayer';
import { $navOpen } from 'components/Nav/stores';
import { NARROW_NAV } from 'components/Nav/constants/sizes';
import { useDarkMode } from 'dark-mode-control';
import { getAppConfigManager } from '$stores/AppConfigManager';
import { useComputedValue, useStoreValue } from '@tw/snipestate';

export type ToggleTW3SectionProps = {
  hideLabel?: boolean;
  labelColor?: 'light' | 'dark';
  switchSize?: Exclude<Size, 0>;
};

export const ToggleTW3Section: React.FC<ToggleTW3SectionProps> = ({
  hideLabel = false,
  switchSize = 'sm',
}) => {
  const darkMode = useDarkMode();
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isNarrow = useComputedValue($navWidth, (w) => w <= NARROW_NAV);

  const handleChange = async () => {
    $navOpen.set(false);

    getAppConfigManager().toggleAppVersion();

    genericEventLogger(analyticsEvents.TOGGLE_VERSION, {
      version: activeAppVersion === '2.0' ? '3.0' : '2.0',
      prevVersion: activeAppVersion,
    });
  };

  return (
    <Flex justify={hideLabel || isNarrow ? 'center' : 'space-between'}>
      {!hideLabel && !isNarrow && (
        <Text fz="sm" fw={500}>
          TW 3.0
        </Text>
      )}
      <ConditionalWrapper
        condition={hideLabel || isNarrow}
        wrapper={(x) => <Tooltip label="TW 3.0">{x}</Tooltip>}
      >
        <div className="p-2">
          <Switch
            size={switchSize}
            offColor={darkMode ? 'gray.5' : 'gray.2'}
            onColor={darkMode ? 'gray.3' : 'gray.4'}
            checked={activeAppVersion === '3.0'}
            onChange={handleChange}
            withOutline
          />
        </div>
      </ConditionalWrapper>
    </Flex>
  );
};
