import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { setOptimisticImporting, toggleSaveIndicator } from './actions';
import { INIT_SHOP, STATS_RECEIVED, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { minimizeSectionOnPress } from './summary';
import axiosInstance from 'utils/axiosInstance';
import QueryString from 'qs';
import { shopServiceDisconnect } from './shopServices';
import { toast } from 'react-toastify';
import { setServiceAccountInfo } from '../utils/setServiceAccountInfo';
import { Action } from 'redux';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;

export const FACEBOOK_ADS_ACCOUNTS_NOT_SELECTED = 'FACEBOOK_ADS_ACCOUNTS_NOT_SELECTED';

export const FACEBOOK_FETCHING_ADS_ACCOUNTS = 'FACEBOOK_FETCHING_ADS_ACCOUNTS';

const FACEBOOK_ADS_CONNECT_STARTED = 'FACEBOOK_ADS_CONNECT_STARTED';
const FACEBOOK_ADS_CONNECT_DONE = 'FACEBOOK_ADS_CONNECT_DONE';
const FACEBOOK_ADS_DISCONNECT_STARTED = 'FACEBOOK_ADS_DISCONNECT_STARTED';

export const loadFacebookPages = async (account: string, shopId: string) => {
  if (!account) {
    return { facebookPages: [], instagramPages: [] };
  }
  const params = {
    shop_id: shopId,
    account_id: account,
  };

  const [{ data: facebookPages }, { data: instagramPages }] = await Promise.all([
    axiosInstance.get(
      '/v2/facebook-ads/get-pages' + QueryString.stringify(params, { addQueryPrefix: true }),
    ),
    axiosInstance.get(
      '/v2/facebook-ads/get-instagram-accounts' +
        QueryString.stringify(params, { addQueryPrefix: true }),
    ),
  ]);

  return { facebookPages, instagramPages };
};

// export const startImportingFacebookData = () => async (dispatch) => {
//   await callInitialImport('facebook-ads', [], true);
//   dispatch(setOptimisticImporting('facebook-ads'));
//   window.scrollTo(0, 0);
// };

export const facebookSetAccountsInfo = (shopId, data) => async (dispatch) => {
  await setServiceAccountInfo(shopId, 'facebook-ads', data);
  dispatch(setOptimisticImporting('facebook-ads'));
  window.scrollTo(0, 0);
};

export const RECEIVE_FACEBOOK_INSIGHTS = 'RECEIVE_FACEBOOK_INSIGHTS';
export const receiveFacebookInsights = () => ({
  type: RECEIVE_FACEBOOK_INSIGHTS,
});

export const FACEBOOK_ERROR = 'FACEBOOK_ERROR';
export const facebookError = ({
  code,
  login_url,
  accounts,
}: {
  code: string;
  login_url?: string;
  accounts?;
}) => ({
  type: FACEBOOK_ERROR,
  code,
  accounts,
});

export const LOADING_FACEBOOK_INSIGHTS = 'LOADING_FACEBOOK_INSIGHTS';
export const FACEBOOK_ADS_CONFIG_SCREEN_OPEN = 'FACEBOOK_ADS_CONFIG_SCREEN_OPEN';
export const TOGGLE_FACEBOOK_CONFIG_SCREEN_OPEN = 'TOGGLE_FACEBOOK_CONFIG_SCREEN_OPEN';
export const toggleFacebookConfigScreenOpen = () => ({
  type: TOGGLE_FACEBOOK_CONFIG_SCREEN_OPEN,
});

export const FACEBOOK_ACCOUNTS_IDS_ON_CHANGE = 'FACEBOOK_ACCOUNTS_IDS_ON_CHANGE';
export const facebookAccountsSelectOnChange = (value) => ({
  type: FACEBOOK_ACCOUNTS_IDS_ON_CHANGE,
  value,
});

export const FACEBOOK_ATTRIBUTION_WINDOW_ON_CHANGE = 'FACEBOOK_ATTRIBUTION_WINDOW_ON_CHANGE';
export const facebookAttributionWindowOnChange = (value) => ({
  type: FACEBOOK_ATTRIBUTION_WINDOW_ON_CHANGE,
  value,
});

export const FACEBOOK_CONFIG_SAVE = 'FACEBOOK_CONFIG_SAVE';
export const facebookConfigSave = () => ({
  type: FACEBOOK_CONFIG_SAVE,
});

export const FACEBOOK_CONFIG_SAVE_SUCCESS = 'FACEBOOK_CONFIG_SAVE_SUCCESS';
export const facebookConfigSaveSuccess = () => {
  //return dispatch => {
  return {
    type: FACEBOOK_CONFIG_SAVE_SUCCESS,
  };
  //}
};

export const facebookConnectOnPress = (dispatch?: (action: Action) => void) => {
  try {
    if (dispatch) {
      dispatch({
        type: FACEBOOK_ADS_CONNECT_STARTED,
      });
    }
    const url = `v2/facebook-ads/login/get-url?shopId=${$currentShopId.get()}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    toast.error('Error while connecting to Meta');
    if (dispatch) {
      dispatch({
        type: FACEBOOK_ADS_CONNECT_DONE,
      });
    }
  }
};

export function facebookDisconnect() {
  return async (dispatch, getState) => {
    dispatch({
      type: FACEBOOK_ADS_DISCONNECT_STARTED,
    });
    toggleSaveIndicator();
    const url = `v2/facebook-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(facebookError({ code: 'FACEBOOK_ADS_CONFIG_NOT_EXIST' }));
        dispatch(callRemoveLocalShopProp('facebookAccessToken'));
        dispatch(facebookDisconnectDone());
        setTimeout(() => dispatch(facebookConfigSaveSuccess()));
      });
  };
}

export const FACEBOOK_ADS_DISCONNECT_DONE = 'FACEBOOK_ADS_DISCONNECT_DONE';

export function facebookDisconnectDone() {
  return {
    type: FACEBOOK_ADS_DISCONNECT_DONE,
  };
}

export function facebookConfigSavePress() {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    dispatch(facebookConfigSave());
    const { facebookAdsAccounts, facebookAttributionWindows, currentShopId } = getState();
    const facebookAccounts = facebookAdsAccounts.filter((acc) => acc.selected);
    dispatch(
      facebookSetAccountsInfo(currentShopId, {
        facebookAccounts,
        facebookAttributionWindows,
      }),
    );
    dispatch(facebookConfigSaveSuccess());
    dispatch(minimizeSectionOnPress('facebookAds', false));
  };
}

export const FACEBOOK_ACCOUNTS_SEARCH_ON_CHANGE = 'FACEBOOK_ACCOUNTS_SEARCH_ON_CHANGE';
export const facebookAccountsSearchOnChange = (val) => {
  return {
    type: FACEBOOK_ACCOUNTS_SEARCH_ON_CHANGE,
    val,
  };
};

const loadingFacebookInsights = (state = false, action) => {
  switch (action.type) {
    case LOADING_FACEBOOK_INSIGHTS:
      return true;
    case RECEIVE_FACEBOOK_INSIGHTS:
      return false;
    case FACEBOOK_ERROR:
      return false;
    default:
      return state;
  }
};

const facebookConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case FACEBOOK_ERROR:
      //if (action.accounts)
      return true;
    case TOGGLE_FACEBOOK_CONFIG_SCREEN_OPEN:
      return !state;
    case FACEBOOK_CONFIG_SAVE_SUCCESS:
      return false;
    case FACEBOOK_FETCHING_ADS_ACCOUNTS:
      return true;
    case FACEBOOK_ADS_CONFIG_SCREEN_OPEN:
      return true;
    default:
      return state;
  }
};

const facebookErrorCode = (state = null, action) => {
  switch (action.type) {
    case FACEBOOK_ERROR:
      return action.code;
    case RECEIVE_FACEBOOK_INSIGHTS:
      return null;
    default:
      return state;
  }
};

const facebookInsights = (state = [], action) => {
  switch (action.type) {
    case STATS_RECEIVED:
      return action.stats;
    default:
      return state;
  }
};

const facebookAdsAccounts = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.facebookAccounts || state;
    case FACEBOOK_ERROR:
      if (action.accounts) {
        if (action.code === 'FACEBOOK_ADS_ACCOUNTS_NOT_SELECTED')
          return action.accounts ? action.accounts : null;
        return action.accounts;
      } else return state;
    case FACEBOOK_ACCOUNTS_IDS_ON_CHANGE:
      return action.value;
    default:
      return state;
  }
};
const facebookAttributionWindows = (state = '7d_click,1d_view', action) => {
  switch (action.type) {
    case FACEBOOK_ATTRIBUTION_WINDOW_ON_CHANGE:
      return action.value;
    default:
      return state;
  }
};

const isFacebookConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.facebookAccessToken;
    case FACEBOOK_ADS_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'facebook-ads' || state;
    default:
      return state;
  }
};

const facebookAdsScopes = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.facebookAdsScopes ?? null;
    default:
      return state;
  }
};

const isFacebookIntegrationBtnLoading = (state = false, action) => {
  switch (action.type) {
    case FACEBOOK_ADS_CONNECT_STARTED:
      return true;
    case FACEBOOK_ADS_CONNECT_DONE:
      return false;
    case FACEBOOK_ADS_DISCONNECT_STARTED:
      return true;
    case FACEBOOK_ADS_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};

const facebookConfigInProgress = (state = false, action) => {
  switch (action.type) {
    case FACEBOOK_CONFIG_SAVE:
      return true;
    case FACEBOOK_CONFIG_SAVE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const facebookAccountSearch = (state = '', action) => {
  switch (action.type) {
    case FACEBOOK_ACCOUNTS_SEARCH_ON_CHANGE:
      return action.val;
    default:
      return state;
  }
};

export const reducers = {
  loadingFacebookInsights,
  facebookConfigScreenIsOpen,
  facebookErrorCode,
  facebookInsights,
  facebookAdsScopes,
  facebookAdsAccounts,
  isFacebookConnected,
  facebookConfigInProgress,
  facebookAccountSearch,
  facebookAttributionWindows,
  isFacebookIntegrationBtnLoading,
};
