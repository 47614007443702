import { $derived } from '@tw/snipestate';
import { $redux } from './$redux';
import { $shop } from './$shop';
import { $currentShopId } from '$stores/$shop';
import { ShopWithSensory } from '@tw/types';

const $sensory = $derived((get) => get($redux)?.sensory);

const $sensoryForShop = $derived((get) => get($sensory)?.sensoryForShop);

export const $shopWithSensory = $derived(
  (get) =>
    ({
      id: get($currentShopId),
      ...get($shop),
      sensory: get($sensoryForShop) ?? {},
    }) as ShopWithSensory,
);
