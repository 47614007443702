import { BottomSectionLinkProps } from '../types';

export const BOTTOM_SECTION_LINKS: BottomSectionLinkProps[] = [
  {
    label: 'Download the App',
    href: 'https://apps.apple.com/il/app/triplewhale/id1511861727',
    icon: 'phone-1',
  },
  { label: 'Learn More', href: 'https://kb.triplewhale.com/en/', icon: 'knowledge-base' },
  {
    label: 'Access Community',
    href: 'https://join.slack.com/t/narwhalnationgroup/shared_invite/zt-1g24f7pc3-efGZOXEgCPaev6BnEBmIew',
    icon: 'slack-2',
  },
  { label: 'Refer a Friend', href: 'https://www.triplewhale.com/refer', icon: 'money-bag' },
];
