import { useContext } from 'react';
import { AdContent } from 'types/general';

import { ContentHubContext } from '../ContentHub';
import AdMediaCard from './AdMediaCard';

type AdMediaCardsProps = {
  media: AdContent[];
};

const AdMediaCards: React.FC<AdMediaCardsProps> = ({ media }) => {
  const { mediaType } = useContext(ContentHubContext);

  const grid = mediaType === 'title' ? 'auto-rows-[140px]' : 'auto-rows-[290px]';
  return (
    <div>
      <div className={'grid gap-6.5 grid-cols-[repeat(auto-fill,minmax(240px,1fr))] ' + grid}>
        {media.map((m) => {
          const { _id } = m;
          return (
            <div key={_id} className="rounded shadow-[rgb(0,0,0,8%)_0px_0px_8px] p-6.5 bg-white">
              <AdMediaCard media={m} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdMediaCards;
