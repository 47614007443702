import { createNewWidget, emptyArray } from 'components/Willy/utils/willyUtils';
import { WillyEditDashboardMenu } from '../WillyEditDashboardMenu';
import { toast } from 'react-toastify';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import { useDashContext } from 'components/Willy/dashContext';
import { $currentShopId } from '$stores/$shop';
import {
  WillyFieldElement,
  WillyFieldTypes,
  WillyWidgetElement,
} from 'components/Willy/types/willyTypes';
import { $user, $userEmail } from '$stores/$user';
import { FC, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import useDashLayout from 'components/Willy/hooks/useDashLayout';
import useDashHistory from 'components/Willy/hooks/useDashHistory';

type EditDashboardCTAProps = {
  closeChat?: () => void;
  activator?: React.ReactNode;
  dropdownPosition?: any;
  dropDownOffset?: number;
  withinPortal?: boolean;
};

export const EditDashboardCTA: FC<EditDashboardCTAProps> = ({
  closeChat,
  activator,
  dropdownPosition,
  dropDownOffset,
  withinPortal,
}) => {
  const { $dashboard, $dashboardId, $fields, $widgets, updateFieldsInDb } = useDashContext();
  const dashboard = useStoreValue($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const currentShopId = useStoreValue($currentShopId);
  const user = useStoreValue($user);
  const userEmail = useStoreValue($userEmail);
  const { updateHistory } = useDashHistory();
  const [fields, setFields] = useWritableStore($fields);
  const { triggerLayout } = useDashLayout();

  const widgets = useComputedValue($widgets, (w) => {
    return Array.isArray(w) ? w : emptyArray<WillyWidgetElement>();
  });

  const fieldDefaultOptions: Record<WillyFieldTypes, WillyFieldElement> = useMemo(() => {
    return {
      text: {
        id: 'text',
        title: 'Text',
        type: 'text',
        author: user.uid || userEmail || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        content:
          '<h1><!--block--><strong>Heading</strong></h1><div><!--block-->Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>',
      },
      image: {
        id: 'image',
        title: 'Image',
        type: 'image',
        author: user?.uid || userEmail || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        content:
          'https://images.unsplash.com/photo-1568430462989-44163eb1752f?auto=format&fit=crop&q=80&w=2673&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      video: {
        id: 'video',
        title: 'Video',
        type: 'video',
        author: user?.uid || userEmail || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        content: '',
      },
      dataSection: {
        id: 'dataSection',
        title: 'New Metrics Section',
        type: 'dataSection',
        author: user?.uid || userEmail || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        content: 'You can add metrics here',
      },
    };
  }, [userEmail, user.uid]);

  if (!currentShopId) return null;

  return (
    <WillyEditDashboardMenu
      addNewWidget={async () => {
        if (!dashboardId) {
          return;
        }
        try {
          closeChat?.();
          const newWidget = await createNewWidget(currentShopId, dashboard!, {
            title: 'Untitled Section',
          });
          $widgets.set([newWidget, ...widgets]);
          triggerLayout();
        } catch {
          toast.error('Error adding image field');
        }
      }}
      onClickImage={() => {
        try {
          closeChat?.();
          const newField = { ...fieldDefaultOptions.image, id: uuidV4() };
          setFields((old) => [newField, ...old]);
          updateFieldsInDb(newField.id, newField);
          updateHistory('add_image_field', newField);
          toast.success('Image field added');
          triggerLayout();
          genericEventLogger(analyticsEvents.DASHBOARDS, {
            action: dashboardsActions.ADD_CONTENT,
            content_type: 'image',
          });
        } catch {
          toast.error('Error adding image field');
        }
      }}
      onClickText={() => {
        try {
          closeChat?.();
          const newField = { ...fieldDefaultOptions.text, id: uuidV4() };
          setFields((old) => [newField, ...old]);
          updateFieldsInDb(newField.id, newField);
          updateHistory('add_text_field', newField);
          toast.success('Text field added');
          triggerLayout();
          genericEventLogger(analyticsEvents.DASHBOARDS, {
            action: dashboardsActions.ADD_CONTENT,
            content_type: 'text',
          });
        } catch {
          toast.error('Error adding text field');
        }
      }}
      onClickVideo={() => {
        try {
          closeChat?.();
          const newField = { ...fieldDefaultOptions.video, id: uuidV4() };
          setFields((old) => [newField, ...old]);
          updateFieldsInDb(newField.id, newField);
          updateHistory('add_video_field', newField);
          toast.success('Video field added');
          triggerLayout();
          genericEventLogger(analyticsEvents.DASHBOARDS, {
            action: dashboardsActions.ADD_CONTENT,
            content_type: 'video',
          });
        } catch {
          toast.error('Error adding video field');
        }
      }}
      activator={activator}
      dropdownPosition={dropdownPosition}
      dropDownOffset={dropDownOffset}
      withinPortal={withinPortal}
    />
  );
};
