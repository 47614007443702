import {
  COMPARATOR_BY_TYPE,
  FILTER_PROPERTY_METADATA,
  FilterComparator,
  FilterProperty,
  FilterPropertyCategory,
  FilterPropertyType,
  FilterTimeUnit,
} from '@tw/types/module/services/insights';

const AUTOCOMPLETE_COMPARATORS = [
  FilterComparator.EQUAL,
  FilterComparator.NOT_EQUAL,
  FilterComparator.IS_IN,
  FilterComparator.IS_NOT_IN,
];

const MULTI_VALUES_COMPARATORS = [FilterComparator.IS_IN, FilterComparator.IS_NOT_IN];

export const TIME_UNIT_OPTIONS = Object.values(FilterTimeUnit);

export function isAutoComplete(comparator: FilterComparator, property: string): boolean {
  return (
    AUTOCOMPLETE_COMPARATORS.includes(comparator) &&
    FILTER_PROPERTY_METADATA[property].isAutoComplete
  );
}

export function isMultipleValuesComparator(comparator: FilterComparator) {
  return MULTI_VALUES_COMPARATORS.includes(comparator);
}

export function getComparatorsByType(type: FilterPropertyType): readonly FilterComparator[] {
  return COMPARATOR_BY_TYPE[type] || [];
}

export function getPropertyType(property: FilterProperty): FilterPropertyType {
  return FILTER_PROPERTY_METADATA[property].type;
}

export function getPropertyCategory(property: FilterProperty): FilterPropertyCategory {
  return FILTER_PROPERTY_METADATA[property].category;
}

export const DEFAULT_COMPARATORS = {
  [FilterPropertyType.STRING]: FilterComparator.EQUAL,
  [FilterPropertyType.NUMBER]: FilterComparator.GREATER_THAN,
  [FilterPropertyType.TIME]: FilterComparator.OVER_ALL_TIME,
  [FilterPropertyType.LIST]: FilterComparator.IS_IN,
};

export const DEFAULT_FILTERS_LIST: FilterProperty[] = [
  FilterProperty.ORDER_SOURCE_NAME,
  FilterProperty.ORDER_PAYMENT_STATUS,
  FilterProperty.ORDER_TAG,
  FilterProperty.ORDER_PRICE,
  FilterProperty.ORDER_DISCOUNT_CODE,
  FilterProperty.ORDER_DESTINATION,
  FilterProperty.ORDER_ITEMS,
  FilterProperty.IS_FIRST_ORDER,
  FilterProperty.PRODUCT_NAME,
  FilterProperty.PRODUCT_ID,
  FilterProperty.PRODUCT_SKU,
  FilterProperty.PRODUCT_VARIANT_NAME,
  FilterProperty.PRODUCT_VARIANT_ID,
  FilterProperty.PRODUCT_TYPE,
  FilterProperty.PRODUCT_TAG,
  FilterProperty.PRODUCT_VENDOR,
  FilterProperty.CUSTOMER_NAME,
  FilterProperty.CUSTOMER_EMAIL,
  FilterProperty.CUSTOMER_PHONE,
  FilterProperty.CUSTOMER_STORE_ACCOUNT_CREATED_AT,
  FilterProperty.CUSTOMER_STORE_ACCOUNT_STATUS,
  FilterProperty.CUSTOMER_TAG,
  FilterProperty.CUSTOMER_ADDRESS_COUNTRY,
  FilterProperty.CUSTOMER_ADDRESS_STATE,
  FilterProperty.CUSTOMER_ADDRESS_CITY,
  FilterProperty.CUSTOMER_EMAIL_MARKETING_CONSENT,
  FilterProperty.CUSTOMER_SMS_MARKETING_CONSENT,
  FilterProperty.CUSTOMER_TOTAL_ORDERS_NUMBER,
  FilterProperty.CUSTOMER_TOTAL_SPEND,
  FilterProperty.CUSTOMER_AOV,
  FilterProperty.ATTRIBUTION_SOURCE,
  FilterProperty.ATTRIBUTION_REFERRING_SITE,
  FilterProperty.ATTRIBUTION_AD_ACCOUNT,
  FilterProperty.ATTRIBUTION_ADS_CAMPAIGN_NAME,
  FilterProperty.ATTRIBUTION_ADS_CAMPAIGN_ID,
  FilterProperty.ATTRIBUTION_ADS_ADSET_NAME,
  FilterProperty.ATTRIBUTION_ADS_ADSET_ID,
  FilterProperty.ATTRIBUTION_ADS_AD_NAME,
  FilterProperty.ATTRIBUTION_ADS_AD_ID,
];
