import moment from '@tw/moment-cached/module/timezone';

export const getQueryStringStartEnd = () => {
  const search = new URLSearchParams(window.location.search);
  let start = search.get('start');
  let end = search.get('end');
  if (typeof start === 'string' && typeof end === 'string') {
    start =
      moment(end).diff(start, 'days') > 365
        ? moment(end).subtract(364, 'days').format('YYYY-MM-DD')
        : start;
    return { start, end };
  }
  return {};
};

export const getQueryPrevStringStartEnd = () => {
  const search = new URLSearchParams(window.location.search);
  let start = search.get('prev-start');
  let end = search.get('prev-end');
  if (typeof start === 'string' && typeof end === 'string') {
    start =
      moment(end).diff(start, 'days') > 365
        ? moment(end).subtract(364, 'days').format('YYYY-MM-DD')
        : start;
    return { start, end };
  }
  return {};
};
