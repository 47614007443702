import axiosInstance from 'utils/axiosInstance';
import {
  FeatureFlag,
  NullableFeatureFlagValueWithMetaDataMap,
} from '@tw/feature-flag-system/module/types';
import {
  assertValidFeatureFlagValueWithMetaData,
  deserializeFeatureFlagObject,
} from '@tw/feature-flag-system/module/utils';

export function assertValidNullableFeatureFlagValueWithMetaDataMap(
  map: unknown,
): asserts map is NullableFeatureFlagValueWithMetaDataMap {
  if (typeof map !== 'object' || !map || Array.isArray(map)) {
    throw new TypeError('Invalid FeatureFlagValueMap: must be non-null/non-array object');
  }

  for (const key in map) {
    if (!(key in FeatureFlag)) {
      console.warn(`Invalid FeatureFlag - ${key} isn't a valid FeatureFlag`);
      continue;
    }

    assertValidFeatureFlagValueWithMetaData(map[key], 'partial');
  }
}

export const getComputedFFValues = async (
  shopId: string,
): Promise<NullableFeatureFlagValueWithMetaDataMap> => {
  const { data } = await axiosInstance.post(
    'v2/subscription-manager/features/computed-feature-flag-values-by-shop',
    { shopId },
  );

  const parsedData = deserializeFeatureFlagObject(JSON.stringify(data));

  assertValidNullableFeatureFlagValueWithMetaDataMap(parsedData);

  return parsedData;
};
