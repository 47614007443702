import { FormattedColor, Icon, IconName } from '@tw/ui-components';
import { FC } from 'react';
import { ToastContainer, TypeOptions } from 'react-toastify';
import { ToastIcon } from 'react-toastify/dist/types';

export type TWToastContainerProps = {};

type ContextStyle = Record<
  TypeOptions,
  {
    toast: string;
    progress: string;
    icon?: IconName;
    iconColor?: FormattedColor;
    closeColor: FormattedColor;
  }
>;

const contextStyle: ContextStyle = {
  success: {
    toast: ' toast-success',
    progress: ' bg-[#34D3A6]',
    icon: 'green-check',
    closeColor: 'green.5',
  },
  error: {
    toast: ' toast-error',
    progress: ' bg-[#F87171]',
    icon: 'circle-x',
    iconColor: 'red.4',
    closeColor: 'red.5',
  },
  info: {
    toast: ' toast-info',
    progress: ' bg-[#0A5ECC]',
    icon: 'info',
    closeColor: 'one.5',
    iconColor: 'one.4',
  },
  default: {
    toast: ' toast-default',
    progress: ' bg-[#FBBF24]',
    closeColor: 'named.9',
  },
  warning: {
    toast: ' toast-warning',
    progress: ' bg-[#FBBF24]',
    closeColor: 'yellow.5',
    icon: 'exclamation',
    iconColor: 'yellow.4',
  },
};

export const TWToastContainer: FC<TWToastContainerProps> = ({}) => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={({ type }) => (
        <Icon name="close" color={contextStyle[type].closeColor} size={12} />
      )}
      className={(context) => `${context?.defaultClassName} toast-container`}
      toastClassName={(context) => {
        const className = `${context?.defaultClassName} toast`;
        return className.concat(contextStyle[context?.type ?? 'default'].toast);
      }}
      progressClassName={(context) => {
        let className = `${context?.defaultClassName}`;
        return className.concat(contextStyle[context?.type ?? 'default'].progress);
      }}
      icon={({ type }) => (
        <Icon name={contextStyle[type].icon ?? 'info'} color={contextStyle[type].iconColor} />
      )}
    />
  );
};
