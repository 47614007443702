const GoogleAnalyticsIconBase64 = ({ small }) => (
  <img
    src="
  data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MTcuNDYgNDE3LjQ2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmYzEwNzt9LmNscy0ye2ZpbGw6I2ZmYTAwMDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9Imdvb2dsZSI+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjc0LDEzMC40NUgxNDMuNWExMy4wNiwxMy4wNiwwLDAsMC0xMy4wNSwxMy4wNVY0MDQuNDFhMTMuMDYsMTMuMDYsMCwwLDAsMTMuMDUsMTNIMjc0YTEzLjA1LDEzLjA1LDAsMCwwLDEzLTEzVjE0My41QTEzLjA1LDEzLjA1LDAsMCwwLDI3NCwxMzAuNDVaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTQzLjUsMjYwLjkxSDEzLjA1QTEzLDEzLDAsMCwwLDAsMjc0VjQwNC40MWExMy4wNSwxMy4wNSwwLDAsMCwxMy4wNSwxM0gxNDMuNWExMy4wNiwxMy4wNiwwLDAsMCwxMy4wNS0xM1YyNzRBMTMuMDUsMTMuMDUsMCwwLDAsMTQzLjUsMjYwLjkxWiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTQwNC40MSwwSDI3NGExMywxMywwLDAsMC0xMywxMy4wNVY0MDQuNDFhMTMuMDUsMTMuMDUsMCwwLDAsMTMsMTNINDA0LjQxYTEzLjA2LDEzLjA2LDAsMCwwLDEzLTEzVjEzLjA1QTEzLjA1LDEzLjA1LDAsMCwwLDQwNC40MSwwWiIvPjwvZz48L2c+PC9zdmc+
  "
    style={{ height: small ? 11 : 16 }}
    alt="Google Analytics icon"
  />
);

export default GoogleAnalyticsIconBase64;
