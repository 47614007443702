import ReactSwitch from 'react-switch';

const Switch: React.FC<{
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
}> = ({ checked, onChange, label, disabled }) => {
  return (
    <div className="flex gap-[8px]">
      <ReactSwitch
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#1877F2"
        offColor="#E5E7EB"
        handleDiameter={20}
        boxShadow="inset 0 0 0 1px #E5E7EB"
        activeBoxShadow="inset 0 0 0 1px #E5E7EB"
        height={16}
        width={36}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="text-[14px] font-medium text-[#374151]">{label}</span>
    </div>
  );
};

export default Switch;
