import { useMemo } from 'react';
import { keyIsGeo } from '../utils/willyUtils';
import {
  ChartType,
  ChartTypeArr,
  ChartTypeItem,
  MessageTypeElement,
  MessageTypes,
  RawNlqData,
} from '../types/willyTypes';
import { chartTypes, messageTypes } from '../constants';

export const useAvailableTypes = (
  data?: RawNlqData,
): {
  chart: {
    label: string;
    items: ChartTypeItem<ChartType>[];
  };
  other: {
    label: string;
    items: MessageTypeElement<MessageTypes>[];
  };
} => {
  const typesGroups = useMemo(() => {
    let types: MessageTypes[] = ['table', 'funnel', 'pie', 'tile', 'card'];

    // tile is only available if event_date or event_hour is present
    // if (data && data.some((d) => d?.name === 'event_date' || d?.name === 'event_hour')) {
    //   types.push();
    // }

    const isGeo = !!data && Array.isArray(data) && data.some((d) => keyIsGeo(d.name));
    if (isGeo) {
      types.push('map');
    }

    let options = types.map((type) => messageTypes[type]);

    const tooManyRowsForChart = data && data.some((x) => x?.value?.length > 10000);

    if (tooManyRowsForChart) {
      options = options.map((option) => {
        if (option.id === 'chart') {
          return {
            ...option,
            disabled: true,
            tooltip: 'Too many rows to display in chart',
          };
        }
        return option;
      });
    }

    const chartOptions = ChartTypeArr.map((type) => chartTypes[type]);

    const items = {
      chart: { label: 'CHART', items: chartOptions },
      other: { label: 'OTHER', items: options },
    };
    return items;
  }, [data]);

  return typesGroups;
};
