import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, FormLayout, TextField } from '@shopify/polaris';
import { useAppDispatch } from 'index';
import { RootState } from 'reducers/RootType';
import { editWillySummaryTitle, willyCloseEditSummaryBox } from '../../ducks/willy';

export const EditWillySummaryTitle = () => {
  const { isEditSummaryTitleOpen, editSummaryId } = useSelector((state: RootState) => state.willy);
  const list = useSelector((state: RootState) => state.willySummaryBox);
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    setTitle(list.find((item) => item.id === editSummaryId)?.title ?? '');
  }, [list, editSummaryId]);

  const handleClose = useCallback(() => {
    dispatch(willyCloseEditSummaryBox());
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(editWillySummaryTitle(editSummaryId, title));
    dispatch(willyCloseEditSummaryBox());
  }, [editSummaryId, title]);

  return (
    <Modal
      open={isEditSummaryTitleOpen}
      onClose={handleClose}
      title={`Edit Title`}
      primaryAction={{
        onAction: handleSubmit,
        content: 'Apply',
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField label={'Title'} autoComplete={''} value={title} onChange={setTitle} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};
