import { SummarySectionsCustomization } from '../types/SummarySection';
import { ShopifySegmentItemMatch, ShopifySegmentType } from '../types/shopify';

export const shopifySegmentSources = ['web', 'pos', 'shopify_draft_order', 'iphone', 'android'];

const emptyArray = [];

export const allActiveOrderSegmentMetrics = (
  msp,
  customMetrics,
  summarySectionsCustomization: SummarySectionsCustomization['ui']['summary'],
  orderTags,
  shopifySources,
  loadingSegments,
  loadCustomMetrics,
) => {
  if (msp === 'shopify') {
    let allActiveMetrics: string[] = [];
    let activeOrderSegment: ShopifySegmentItemMatch[] = [];
    if (customMetrics && summarySectionsCustomization && !loadingSegments && !loadCustomMetrics) {
      customMetrics.metrics?.forEach((cm) => {
        const stats = cm.stats ? Object.values(cm.stats) : cm.stat;
        if (stats) {
          stats.forEach((s) => {
            if (
              s.value === ShopifySegmentType.ORDER_TAGS ||
              s.value === ShopifySegmentType.SOURCES
            ) {
              allActiveMetrics.push(s.statObjectKey);
            }
          });
        }
      });
      Object.values(summarySectionsCustomization).forEach((ss) => {
        ss.tiles?.forEach((m) => {
          allActiveMetrics.push(m);
        });
      });
      allActiveMetrics = [...new Set(allActiveMetrics)];

      // Order tags
      const tags = orderTags
        .filter((ot) => allActiveMetrics.includes(ot.id) || allActiveMetrics.includes(ot.original))
        .map((x) => ({ id: x.id, type: ShopifySegmentType.ORDER_TAGS }));

      const sources = shopifySources
        .filter((ot) => allActiveMetrics.includes(ot.id) || allActiveMetrics.includes(ot.original))
        .map((x) => ({ id: x.id, type: ShopifySegmentType.SOURCES }));

      activeOrderSegment = activeOrderSegment.concat(tags).concat(sources);
      activeOrderSegment = activeOrderSegment.length > 0 ? activeOrderSegment : emptyArray;
      return { activeOrderSegment, isReady: true, msp };
    }
  } else {
    return { activeOrderSegment: emptyArray, isReady: true, msp };
  }

  return { activeOrderSegment: emptyArray, isReady: false, msp };
};
