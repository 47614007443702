import amplitude from 'amplitude-js';
import BaseChart from 'components/library/BaseChart/BaseChart';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import TWImage from 'components/library/TWImage/TWImage';
import DropDown from 'components/ltv/DropDown';
import {
  ATTRIBUTION_COLORS,
  AttributionPageContext,
  ChartData,
  ChartMode,
} from 'constants/attribution';
import {
  TW_CREATIVE_TRENDS_PRIMARY_METRIC,
  TW_CREATIVE_TRENDS_SECONDARY_METRIC,
} from 'constants/creativeCockpit';
import allServices from 'constants/services';
import { cloneDeep, sortBy } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  TooltipProps,
} from 'recharts';
import { type RootState } from 'reducers/RootType';
import { AttributionData } from 'types/attribution';
import { formatNumber } from 'utils/formatNumber';

import { Checkbox } from '@shopify/polaris';

import { metrics } from '../../constants/metrics/metrics';
import { SettingsMinor } from '@shopify/polaris-icons';
import { useShowTheRightAxis } from 'utils/useShowTheRightAxis';
import { MetricsKeys } from 'types/metrics';
import { AFFLUENCER } from 'constants/types';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { BaseLegend } from 'components/library/BaseChart/BaseLegend';
import { NewLoader } from 'components/loaders/NewLoader';
import { Flex, Text } from '@tw/ui-components';

interface AttributionChartProps {}

const AttributionChart: FC<AttributionChartProps> = () => {
  const {
    selectedAttributions,
    loadingAttributionComparisons,
    loading,
    currency,
    setSelectedAttributions,
    sourceId,
    dateToCompare,
  } = useContext(AttributionPageContext);

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const [lineData, setLineData] = useState<any>([]);
  const [barData, setBarData] = useState<any>([]);
  const [hoveredItems, setHoveredItems] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData>('metrics');
  const [chartMode, setChartMode] = useState<ChartMode>('line');
  const [allCurrentPeriod, setAllCurrentPeriod] = useState('');
  const [allPreviousPeriod, setAllPreviousPeriod] = useState('');
  const [showRightAxis, setShowRightAxis] = useState(true);

  const defaultPrimaryMetric =
    (localStorage.getItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC) as MetricsKeys) ||
    ('spend' as MetricsKeys);
  const defaultSecondaryMetric =
    (localStorage.getItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC) as MetricsKeys) ||
    ('pixelConversionValue' as MetricsKeys);

  const [primaryMetric, setPrimaryMetric] = useState<MetricsKeys>(defaultPrimaryMetric);
  const [secondaryMetric, setSecondaryMetric] = useState<MetricsKeys>(defaultSecondaryMetric);

  const useRightAxis = useShowTheRightAxis([primaryMetric, secondaryMetric]);
  const isSmall = useIsSmall();

  useEffect(() => {
    if (chartData === 'metrics') {
      setShowRightAxis(useRightAxis);
    } else {
      setShowRightAxis(false);
    }
  }, [useRightAxis, chartData]);

  const toggleActive = useCallback(
    (item: AttributionData) => {
      setSelectedAttributions!((oldCampaigns) => {
        return oldCampaigns.map((campaign) => ({
          ...campaign,
          active: campaign.id === item.id ? !campaign.active : campaign.active,
          adsets: campaign.adsets?.map((adset) => ({
            ...adset,
            active: adset.id === item.id ? !adset.active : adset.active,
            ads: adset.ads?.map((ad) => ({
              ...ad,
              active: ad.id === item.id ? !ad.active : ad.active,
            })),
          })),
        }));
      });
    },
    [setSelectedAttributions],
  );

  const lineHovered = useCallback(
    (item: AttributionData) => {
      if (item.active) {
        return;
      }
      setHoveredItems((items) => [...items, item!.id!]);
      toggleActive?.(item);
    },
    [toggleActive],
  );

  const lineLeft = useCallback(
    (attribution: AttributionData) => {
      const copy = cloneDeep(hoveredItems);
      const index = copy.findIndex((item) => attribution.id === item);
      if (index === -1) {
        return;
      }
      toggleActive?.(attribution);
      copy.splice(index, 1);
      setHoveredItems(copy);
    },
    [hoveredItems, toggleActive],
  );

  const handlePrimaryMetricChange = useCallback((metric: MetricsKeys) => {
    amplitude
      .getInstance()
      .logEvent('Attribution: Chart Metric Changed', { yAxis: 'left', metric: metric });
    setPrimaryMetric(metric);
    localStorage.setItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC, metric);
  }, []);

  const handleSecondaryMetricChange = useCallback((metric: MetricsKeys) => {
    setSecondaryMetric(metric);
    amplitude
      .getInstance()
      .logEvent('Attribution: Chart Metric Changed', { yAxis: 'right', metric: metric });
    localStorage.setItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC, metric);
  }, []);

  const getData = useCallback(() => {
    if (!mainDatePickerSelectionRange) {
      return;
    }

    let linesData: any = [];
    let barsData: any = [];

    if (chartData === 'metrics') {
      linesData =
        selectedAttributions?.[0]?.metricsBreakdown?.map?.((d) => ({
          date: d.date,
        })) || [];
      selectedAttributions?.forEach((item) => {
        item.metricsBreakdown?.forEach((bd) => {
          let date = linesData?.find((date) => date.date === bd.date);
          if (!date) {
            date = { date: bd.date };
            linesData?.push(date);
          }
          date[item.id!] = {
            ...bd.metrics,
            id: item.id,
            color: item.color,
          };
        });
      });

      selectedAttributions!.forEach((data) => {
        barsData.push({
          [primaryMetric]: data[primaryMetric] ? Number(data[primaryMetric]!)?.toFixed(2) || 0 : 0,
          [secondaryMetric]:
            (data[secondaryMetric] ? Number(data[secondaryMetric])?.toFixed(2) : 0) || 0,
          fill: data.color,
          opacity: data.active ? 1 : 0.1,
        });
      });

      const bbb = selectedAttributions.map((data) => ({
        [primaryMetric]: Number(data[primaryMetric] || 0)?.toFixed(2),
        [secondaryMetric]: Number(data[secondaryMetric] || 0)?.toFixed(2),
        fill: data.color,
        opacity: data.active ? 1 : 0.1,
      }));

      linesData = sortBy(linesData, 'date');
      setLineData(linesData);
      setBarData(bbb);
    } else {
      linesData = selectedAttributions?.[0]?.metricsBreakdown?.map?.((d) => ({ date: d.date }))!;
      selectedAttributions?.forEach((item) => {
        item.metricsBreakdown?.forEach((bd, i) => {
          let date = linesData?.find((date) => date.date === bd.date);
          const prevMetrics = item.comparisons?.metricsBreakdown?.[i]?.metrics || {};
          const prevDate = item.comparisons?.metricsBreakdown?.[i]?.date;
          if (!date) {
            date = { date: bd.date };
            linesData.push(date);
          }
          date[item.id!] = {
            ...bd.metrics,
            id: item.id,
            color: item.color,
            prevPeriod: prevMetrics,
            currDate: date.date,
            prevDate,
          };
        });
      });

      const barsData = selectedAttributions.map((data) => {
        const currentPeriod = `${mainDatePickerSelectionRange.start.format(
          'll',
        )} - ${mainDatePickerSelectionRange.end.format('ll')}`;
        const previousPeriod = `${moment(dateToCompare.start).format('ll')} - ${moment(
          dateToCompare.end,
        ).format('ll')}`;

        setAllCurrentPeriod(currentPeriod);
        setAllPreviousPeriod(previousPeriod);

        return {
          [primaryMetric]: Number(data[primaryMetric] || 0)?.toFixed(2),
          prevPeriod: {
            [primaryMetric]: Number(data.comparisons?.[primaryMetric] || 0)?.toFixed(2),
          },
          fill: data.color,
          opacity: data.active ? 1 : 0.1,
          id: data.id,
        };
      });

      setLineData(linesData);
      setBarData(barsData);
    }
  }, [
    primaryMetric,
    secondaryMetric,
    selectedAttributions,
    chartData,
    mainDatePickerSelectionRange,
    dateToCompare,
  ]);

  const getDropdownOptions = useCallback(() => {
    const metricOptions = Object.values(metrics).filter(
      (m) => !m.hideInPixel && m.key !== 'adsInventory',
    );
    return metricOptions.map((value) => {
      return {
        label: value.label,
        value: value.key,
      };
    });
  }, []);

  const tickFormatter = useCallback(
    (value: number, index: number, metric: MetricsKeys) => {
      if (index % 2 !== 0) {
        return '';
      }
      const { format, toFixed, minimumFractionDigits } = metrics[metric] || {};
      return formatNumber(value, {
        style: format || 'decimal',
        currency,
        maximumFractionDigits: toFixed || 2,
        minimumFractionDigits: minimumFractionDigits || 0,
        notation: 'compact',
      });
    },
    [currency],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="blue-chart-wrapper">
      <div className="blue-chart">
        {((loading && chartData === 'metrics') ||
          (loadingAttributionComparisons && chartData === 'periods')) && (
          <div className="loading-creatives-wrapper">
            <div className="loading-creatives">
              <NewLoader />
            </div>
          </div>
        )}
        <div className="blue-chart-top-section gap-4">
          <DropDown
            value={chartData}
            handleSelect={setChartData}
            options={[
              { label: 'Metrics', value: 'metrics' },
              { label: 'Periods', value: 'periods' },
            ]}
          />
          <DropDown
            showFilter
            value={metrics[primaryMetric]?.key}
            handleSelect={handlePrimaryMetricChange}
            options={getDropdownOptions()}
          />
          <div className="blue-chart-top-title">
            <span>Triple Pixel</span>
            <div style={{ width: '44px', height: '28px' }}>
              <BubbleToggleButton
                onClick={() => {
                  setChartMode('line');
                  amplitude
                    .getInstance()
                    .logEvent('Attribution: Chart Type Changed', { chartType: 'line' });
                }}
                active={chartMode === 'line'}
                icon="line-chart"
              />
            </div>
            <div style={{ width: '44px', height: '28px' }}>
              <BubbleToggleButton
                onClick={() => {
                  setChartMode('bar');
                  amplitude
                    .getInstance()
                    .logEvent('Attribution: Chart Type Changed', { charType: 'bar' });
                }}
                active={chartMode === 'bar'}
                icon="bar-chart"
              />
            </div>
            <DropDown
              options={[]}
              value={null}
              handleSelect={() => {}}
              customActivator={
                <div className="flex items-center cursor-pointer p-1">
                  <SettingsMinor className="w-8 flex items-center fill-gray-300" />
                </div>
              }
              extraElements={[
                <div className="flex items-center">
                  <Checkbox
                    label="Show the right axis"
                    checked={showRightAxis}
                    onChange={() => setShowRightAxis(!showRightAxis)}
                    helpText={
                      <p className="text-xl text-gray-600">
                        The right axis is used for showing the secondary metric. Enable this option
                        to compare different types of metrics like CTR (%) vs CPA ($). Use one axis
                        to compare similar metrics (ROAS vs NC ROAS).
                      </p>
                    }
                  />
                </div>,
              ]}
            />
          </div>
          <div className="min-w-[100px] flex justify-end">
            {chartData === 'metrics' && (
              <DropDown
                showFilter
                value={metrics[secondaryMetric]?.key}
                handleSelect={handleSecondaryMetricChange}
                options={getDropdownOptions()}
              />
            )}
          </div>
        </div>
        {chartMode === 'line' && (
          <BaseChart
            data={lineData}
            showTooltip={true}
            ChartType={LineChart}
            margin={{ right: showRightAxis ? -30 : 30 }}
            xAxis={[
              {
                tickFormatter: (value: string) => {
                  if (!value || !moment(value).isValid()) {
                    return '';
                  }
                  if (value.includes('T')) {
                    return moment(value).format('HH:mm');
                  }
                  return moment(value).format('MMM D');
                },
                dataKey: 'date',
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, primaryMetric);
                },
              },
              {
                yAxisId: showRightAxis ? 'right' : 'left',
                orientation: showRightAxis ? 'right' : 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, secondaryMetric);
                },
              },
            ]}
          >
            {selectedAttributions &&
              selectedAttributions.map((sel) => {
                return (
                  <Fragment key={sel.id}>
                    <Line
                      yAxisId={'left'}
                      type="monotone"
                      name={metrics[primaryMetric]?.label}
                      dataKey={(dataPoint) => {
                        return dataPoint[sel.id!] && dataPoint[sel.id!][primaryMetric]
                          ? dataPoint[sel.id!][primaryMetric]
                          : 0;
                      }}
                      stroke={sel.color}
                      strokeWidth={3}
                      strokeOpacity={sel.active ? 1 : 0}
                      dot={false}
                      activeDot={{
                        onClick: () => toggleActive?.(sel),
                        style: {
                          cursor: 'pointer',
                          fill: sel.active ? 'white' : 'transparent',
                          opacity: sel.active ? 1 : 0,
                        },
                      }}
                      onMouseOver={() => lineHovered(sel)}
                      onMouseLeave={() => lineLeft(sel)}
                      onClick={() => toggleActive?.(sel)}
                      style={{ cursor: 'pointer' }}
                    />
                    {chartData === 'metrics' && (
                      <Line
                        yAxisId={showRightAxis ? 'right' : 'left'}
                        type="monotone"
                        name={metrics[secondaryMetric]?.label}
                        dataKey={(dataPoint) => {
                          return dataPoint[sel.id!] && dataPoint[sel.id!][secondaryMetric]
                            ? dataPoint[sel.id!][secondaryMetric]
                            : 0;
                        }}
                        stroke={sel.color}
                        strokeWidth={3}
                        strokeOpacity={sel.active ? 1 : 0}
                        dot={false}
                        activeDot={{
                          style: { display: 'none' },
                          strokeOpacity: 0,
                        }}
                        strokeDasharray="3,3"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {chartData === 'periods' && (
                      <Line
                        yAxisId={showRightAxis ? 'right' : 'left'}
                        type="monotone"
                        name={'Previous Period'}
                        dataKey={(dataPoint) => {
                          return dataPoint[sel.id!] &&
                            dataPoint[sel.id!]?.prevPeriod?.[primaryMetric]
                            ? dataPoint[sel.id!]?.prevPeriod?.[primaryMetric]
                            : 0;
                        }}
                        stroke={sel.color}
                        strokeWidth={3}
                        strokeOpacity={sel.active ? 1 : 0}
                        dot={false}
                        activeDot={{
                          style: { display: 'none' },
                          strokeOpacity: 0,
                        }}
                        strokeDasharray="3,3"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <ChartTooltip
                      content={
                        <CustomLineTooltip
                          data={selectedAttributions}
                          primaryMetric={primaryMetric}
                          secondaryMetric={secondaryMetric}
                          chartData={chartData}
                        />
                      }
                      cursor={{ fill: 'none' }}
                      labelStyle={{ fontWeight: 'bold' }}
                      contentStyle={{ fontSize: '10px' }}
                      formatter={(value: number, metricLabel: string) => {
                        const metric = Object.values(metrics).find(
                          (m) => m.label === metricLabel,
                        )?.key;
                        if (!metric) {
                          return value;
                        }
                        if (!isNaN(value)) {
                          return value;
                        }
                        return value;
                      }}
                      labelFormatter={(value) => {
                        if (value && moment(value).isValid()) {
                          value = '' + value;
                          return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                        }
                        return value;
                      }}
                    />
                  </Fragment>
                );
              })}
          </BaseChart>
        )}
        {chartMode === 'bar' && (
          <BaseChart
            data={barData}
            showTooltip={true}
            margin={{ right: showRightAxis ? -30 : 30 }}
            ChartType={BarChart}
            xAxis={[
              {
                dataKey: 'date',
                tickFormatter: (value) => {
                  return !!value && moment(value).isValid() ? moment(value).format('MMM D') : '';
                },
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, primaryMetric);
                },
              },
              {
                yAxisId: showRightAxis ? 'right' : 'left',
                orientation: showRightAxis ? 'right' : 'left',
                tickFormatter: (value, index) => {
                  return tickFormatter(value, index, secondaryMetric);
                },
              },
            ]}
          >
            <defs>
              {ATTRIBUTION_COLORS.map((color, index) => (
                <linearGradient
                  key={'color-' + index + '-' + color}
                  id={`color-creative-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0" stopColor={color} stopOpacity={1} />
                  <stop offset="1" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <Bar
              barSize={22}
              yAxisId={'left'}
              dataKey={primaryMetric}
              name={
                (chartData === 'metrics' ? metrics[primaryMetric]?.label : allCurrentPeriod) ||
                primaryMetric ||
                ''
              }
            ></Bar>
            <Bar
              barSize={22}
              yAxisId={showRightAxis ? 'right' : 'left'}
              dataKey={(dataPoint) => {
                return chartData === 'metrics'
                  ? dataPoint[secondaryMetric]
                  : dataPoint.prevPeriod?.[primaryMetric];
              }}
              name={
                (chartData === 'metrics' ? metrics[secondaryMetric]?.label : allPreviousPeriod) ||
                secondaryMetric ||
                ''
              }
            >
              {selectedAttributions?.map((entry, index) => (
                <Cell
                  key={`cell-gradient-${entry.accountId}-${index}`}
                  fill={`url(#color-creative-${index % ATTRIBUTION_COLORS.length})`}
                  opacity={entry.active ? 1 : 0.1}
                />
              ))}
            </Bar>
            <ChartTooltip
              cursor={{ fill: 'none' }}
              label={metrics[primaryMetric]?.label || primaryMetric || ''}
              labelStyle={{ fontWeight: 'bold' }}
              labelFormatter={(label) => {
                if (chartData === 'periods') {
                  return metrics[primaryMetric]?.label || primaryMetric || '';
                }
                return label;
              }}
              contentStyle={{ fontSize: '10px' }}
              formatter={(value: number, metricLabel: string, payload) => {
                // if (chartData === 'comparison') {
                //   console.log({ payload });
                //   return payload.payload?.prevDate;
                // }
                const metric = Object.values(metrics).find((m) => m.label === metricLabel)?.key;
                if (!metric) {
                  return value;
                }
                if (!isNaN(value)) {
                  return formatNumber(value, {
                    style: metrics[metric]?.format || 'decimal',
                    currency,
                    minimumFractionDigits: metrics[metric]?.toFixed,
                    maximumFractionDigits: metrics[metric]?.toFixed,
                  });
                }
                return value;
              }}
              // labelFormatter={(value) => {
              //   if (moment(value).isValid()) {
              //     value = '' + value;
              //     return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
              //   }
              //   return value;
              // }}
            />
          </BaseChart>
        )}
        <div
          className="flex overflow-auto gap-4 p-[var(--padding)]"
          style={{
            paddingInline: chartMode === 'bar' && !isSmall ? '3.75rem 2.5rem' : '',
            justifyContent: chartMode === 'bar' ? 'space-around' : 'flex-end',
            transition: 'all 0.3s ease',
          }}
        >
          {selectedAttributions?.map((dataObj) => (
            <BaseLegend
              color={dataObj.color ?? '#000'}
              key={`legend-${dataObj.id}`}
              tooltipText={dataObj.name || allServices[dataObj.id!]?.title}
              label={dataObj.entity}
              onClick={sourceId !== 'all' ? () => toggleActive?.(dataObj) : undefined}
              active={dataObj.active}
              circle
              content={
                dataObj.imageUrl ? (
                  <TWImage
                    src={dataObj.imageUrl}
                    className="w-full h-full object-cover rounded-md"
                    wrapperClass="w-16 h-16"
                  />
                ) : sourceId === 'all' ? (
                  allServices[dataObj.id!]?.icon?.({ small: false }) ?? <></>
                ) : (
                  allServices[sourceId]?.icon?.({
                    small: false,
                  }) ?? <></>
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttributionChart;

const CustomLineTooltip = (
  props: TooltipProps<any, any> & {
    data: AttributionData[];
    primaryMetric: string;
    secondaryMetric: string;
    chartData: ChartData;
  },
) => {
  const { active, payload, label, data, primaryMetric, secondaryMetric, chartData } = props;
  const { currency, sourceId } = useContext(AttributionPageContext);

  const labelFormatter = useCallback(
    (value) => {
      if (value && moment(value).isValid()) {
        return (
          <Flex direction="column" gap="xs">
            <Text span>{moment(value).format(value.includes('T') ? 'LT' : 'MMM D')}</Text>
            {chartData === 'periods' && <Text span>{metrics[primaryMetric].label}</Text>}
          </Flex>
        );
      }
      return value;
    },
    [chartData, primaryMetric],
  );

  if (!active) {
    return null;
  }

  const allItemsInChart: any[] = Object.values(payload?.[0]?.payload || {});
  if (!allItemsInChart?.length) {
    return null;
  }

  return (
    <div className="bg-white dark:bg-[var(--gray-dark-mode-600)] rounded p-4 custom-line-chart-tooltip shadow-md">
      <div className="font-bold mb-4">{labelFormatter(label)}</div>
      <div className="list-none p-0 m-0 flex flex-col gap-4">
        {allItemsInChart.map((c, i) => {
          const item = data?.find((el) => el?.id === c?.id);
          if (!item || !c.id) return null;
          return (
            <div className="flex gap-4 items-center justify-center text-base" key={c.id}>
              {item.imageUrl && (
                <div className="flex flex-col items-center justify-center gap-1 flex-auto">
                  <p className="m-0 text-sm text-primary capitalize">{item.entity}</p>
                  <TWImage
                    src={item.imageUrl}
                    className="w-full h-full object-cover rounded-md cursor-pointer"
                    wrapperClass="w-16 h-16"
                    onClick={() => {}}
                  />
                </div>
              )}
              {!item.imageUrl && (
                <div className="flex flex-col items-center justify-center gap-1 flex-auto">
                  <p className="m-0 text-sm text-primary capitalize">{item.entity}</p>
                  {sourceId === 'all'
                    ? allServices[item.id!]?.icon?.({ small: false })
                    : allServices[sourceId]?.icon?.({ small: false })}
                </div>
              )}

              <div className={`flex flex-col ${!item.active && 'opacity-50'}`}>
                <div className="flex gap-2 items-center">
                  <div
                    className="grid grid-cols-1 grid-rows-1 border border-solid rounded-md h-4 w-4 box-content"
                    style={{ borderColor: item.color, backgroundColor: item.color }}
                  />
                  <Text span>
                    {chartData === 'metrics'
                      ? metrics[primaryMetric].label
                      : moment(c.currDate).format('ll')}
                    :
                  </Text>
                  <Text span>
                    {formatNumber(c[primaryMetric], {
                      currency,
                      style: metrics[primaryMetric]?.format || 'decimal',
                      minimumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                      maximumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                    })}
                  </Text>
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className="grid grid-cols-2 grid-rows-2 border border-solid rounded-md overflow-hidden"
                    style={{ borderColor: item.color }}
                  >
                    <div className="w-2 h-2" style={{ backgroundColor: item.color }}></div>
                    <div className="w-2 h-2" style={{ backgroundColor: 'white' }}></div>
                    <div className="w-2 h-2" style={{ backgroundColor: 'white' }}></div>
                    <div className="w-2 h-2" style={{ backgroundColor: item.color }}></div>
                  </div>
                  <Text span>
                    {chartData === 'metrics'
                      ? metrics[secondaryMetric]?.label
                      : moment(c.prevDate).format('ll')}
                    :
                  </Text>
                  <Text span>
                    {chartData === 'metrics' &&
                      formatNumber(c[secondaryMetric], {
                        currency,
                        style: metrics[secondaryMetric]?.format || 'decimal',
                        minimumFractionDigits: metrics[secondaryMetric]?.toFixed || 0,
                        maximumFractionDigits: metrics[secondaryMetric]?.toFixed || 0,
                      })}
                    {chartData === 'periods' &&
                      formatNumber(c.prevPeriod[primaryMetric], {
                        currency,
                        style: metrics[primaryMetric]?.format || 'decimal',
                        minimumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                        maximumFractionDigits: metrics[primaryMetric]?.toFixed || 0,
                      })}
                  </Text>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
