import { $store } from '@tw/snipestate';

type CreateModalStore = {
  opened: boolean;
};

export const $createModal = $store<CreateModalStore>({
  opened: false,
});

export const closeCreateModal = () => {
  $createModal.set({ opened: false });
};
