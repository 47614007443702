import './ConfirmationModal.scss';

import { useAppDispatch } from 'index';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, TextContainer } from '@shopify/polaris';

import {
  confirmationModalClose,
  ConfirmationModalData,
  ConfirmationModalInternalData,
} from '../../ducks/confirmationModal';
import { type RootState } from '../../reducers/RootType';

const ConfirmationModal: FC = () => {
  const confirmationModalData: ConfirmationModalInternalData = useSelector(
    (state: RootState) => state.confirmationModalData,
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const onClose = async () => {
    if (confirmationModalData.onCancel) await confirmationModalData.onCancel();
    dispatch(confirmationModalClose());
  };
  const onAction = async () => {
    setIsLoading(true);
    await confirmationModalData.onConfirm();
    dispatch(confirmationModalClose());
    setIsLoading(false);
  };

  return (
    <Modal
      title={confirmationModalData.title || 'Action Confirmation'}
      open={confirmationModalData.isOpen}
      onClose={onClose}
      primaryAction={{
        loading: isLoading,
        content: confirmationModalData.confirmButtonText || 'Confirm',
        onAction,
      }}
    >
      <Modal.Section>
        <div className="confirmation-modal">
          <TextContainer>
            <p>{confirmationModalData.text}</p>
          </TextContainer>
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default ConfirmationModal;
