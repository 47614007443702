import { useMemo } from 'react';
import { ActionIcon, Tooltip } from '@tw/ui-components';
import { $openNavSection } from 'components/Nav/stores';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import classes from './NarrowLink.module.scss';
import { $activeRoute, $prefersWideNav } from '$stores/nav-config-stores';
import { useComputedValue } from '@tw/snipestate';

export const NarrowLink = ({ title, icon: Icon }) => {
  const activeTitle = useComputedValue($activeRoute, (r) => r.activeSection?.title);

  const linkIcon = useMemo(
    () => (
      <ConditionalWrapper
        condition={activeTitle === title}
        wrapper={(x) => <div className={classes.selectedSectionHighlight}>{x}</div>}
      >
        <div onClick={() => $openNavSection.set(title)}>
          <Icon width={28} height={28} />
        </div>
      </ConditionalWrapper>
    ),
    [Icon, title, activeTitle],
  );

  return (
    <Tooltip label={title}>
      <ActionIcon
        radius="xs"
        mb="md"
        icon={Icon && linkIcon}
        onClick={() => $prefersWideNav.set(true)}
      />
    </Tooltip>
  );
};
