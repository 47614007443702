import { FC, useCallback } from 'react';
import { ActionIcon, Flex, Text, TextInput } from '@tw/ui-components';

type QuantityInputProps = {
  unitLabel?: string;
  value: number;
  onChange: (value: number) => void;
};

export const QuantityInput: FC<QuantityInputProps> = ({ value, onChange, unitLabel }) => {
  const handleClick = useCallback(
    (newValue: number) => {
      if (newValue >= 0 && newValue < 120) {
        onChange(newValue);
      }
    },
    [onChange],
  );
  return (
    <Flex align="center" gap="xs">
      <ActionIcon
        icon="minus"
        iconSize={24}
        variant="activator"
        onClick={() => handleClick(value - 1)}
      />

      <TextInput
        value={value}
        trailingIcon={
          unitLabel ? (
            <Text color="gray.5" pr="sm">
              {`${unitLabel}`}
            </Text>
          ) : undefined
        }
        maw={120}
      />
      <ActionIcon
        icon="union"
        iconSize={16}
        variant="activator"
        onClick={() => handleClick(value + 1)}
      />
    </Flex>
  );
};
