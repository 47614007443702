import './unpaidModal.scss';

import { ActivePMSection } from 'components/UserProfileManagment/Shop/ActivePMSection';
import { loadStripeConfig } from 'ducks/signupJourney';
import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type RootState } from 'reducers/RootType';
import { useStripePromise } from 'routes/auth/SignUp/components/useStripePromise';

import { Button, Modal } from '@shopify/polaris';
import { Elements } from '@stripe/react-stripe-js';

import { goToPodsView } from 'utils/goToPodsView';

const UnpaidModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [displayPopup, setDisplayPopup] = useState(false);
  const [lastInvoiceDate, setLastInvoiceDate] = useState('');
  const [billingEmail, setBillingEmail] = useState<string>('');
  const [isContinue, setIsContinue] = useState<Boolean>(false);
  const { stripePromise } = useStripePromise();
  const subscription = useSelector((state: RootState) => state.subscription);

  const changePaymentMethod = () => {
    setIsContinue(true);
  };

  useEffect(() => {
    dispatch(loadStripeConfig());
  }, [dispatch]);

  useEffect(() => {
    if (subscription?.status === 'unpaid') {
      setDisplayPopup(true);
    } else {
      setDisplayPopup(false);
    }
    if (subscription?.email) setBillingEmail(subscription.email);
    if (subscription?.last_invoice_date)
      setLastInvoiceDate(moment(subscription.last_invoice_date).format('LL'));
  }, [subscription]);

  const backToPodsView = () => {
    goToPodsView(navigate, dispatch);
    setDisplayPopup(false);
  };

  return (
    <>
      <Modal
        title={'Reactivate Your Shop'}
        titleHidden={false}
        open={displayPopup}
        onClose={() => {
          backToPodsView();
        }}
      >
        <Modal.Section>
          <div className="add_user_popup" style={{ textAlign: 'center' }}>
            {!isContinue && (
              <div>
                <h4 style={{ paddingTop: '1.5rem' }}>{`Your invoice which was issued on ${
                  lastInvoiceDate || '?'
                } has not been paid.
                We have not been able to charge your credit card since then.`}</h4>
                <h4>{`Please enter a new payment method to access your shop.`}</h4>
                <h4
                  style={{ marginBottom: '20px', marginTop: '1rem' }}
                >{`Your account is associated with the billing email address of: ${
                  billingEmail || ''
                }`}</h4>
                <div className="flex space-between" style={{ paddingTop: '1.5rem' }}>
                  <Button
                    onClick={() => {
                      backToPodsView();
                    }}
                  >
                    Back to Pods View
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      changePaymentMethod();
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            )}
            {isContinue && (
              <div>
                <h4 style={{ marginBottom: '20px' }}>
                  Update the credit card with which you are paying for this store's subscriptions.
                  Invoices will continue to be sent to the billing user on file. Your new payment
                  method will be charged immediately for the issued invoice, and your billing cycle
                  will be reset.
                </h4>
                <div className="change-payment">
                  {stripePromise && (
                    <Elements stripe={stripePromise}>
                      <ActivePMSection isUnpaid={true} />
                    </Elements>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default UnpaidModal;
