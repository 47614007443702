import { $store, useWritableStore, useStoreValue } from '@tw/snipestate';
import { Box, Checkbox, Flex, Icon, Modal, Size, Text } from '@tw/ui-components';
import { $moreSection } from 'constants/routes/configs/more';
import { $navSectionCustomization, getNavSectionCustomizer } from './api/NavSectionCustomizer';

export const $customizeNavModal = $store<{ opened: boolean }>({ opened: false });

export function CustomizeNavigationModal() {
  const moreSection = useStoreValue($moreSection);
  const [modalProps, setModalProps] = useWritableStore($customizeNavModal);
  const navSectionCustomization = useStoreValue($navSectionCustomization);

  return (
    <Modal.Root
      centered
      size={500}
      opened={modalProps.opened}
      onClose={() => setModalProps((x) => ({ ...x, opened: !x.opened }))}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text c="gray.8" fw={600} fz={20}>
              Customize Navigation
            </Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body p={14} pb="lg">
          <Flex direction="column" gap={14 as unknown as Size}>
            <Box>
              <Text c="gray.8" fz="md" fw={500} lh="24px">
                Show these tabs in the main navigation bar:
              </Text>
              {/* <Text c="gray.7" fz="sm" lh="20px">
                At smaller window sizes, not all selected tabs may appear.
              </Text> */}
            </Box>
            {moreSection.map((section, i) => {
              return (
                <Box key={(section.title || 'title') + i}>
                  <Text c="gray.6" fz="sm" lh="20px" tt="uppercase">
                    {section.title}
                  </Text>
                  {section.routes.map(({ url, label, rawIconName }) => {
                    return (
                      <Checkbox
                        mt={5 as unknown as Size}
                        key={url + i}
                        size="sm"
                        checked={navSectionCustomization[url]?.open}
                        onClick={(e) => {
                          navSectionCustomization[url].open = e.target['checked'];
                          getNavSectionCustomizer().customizeNavSections({
                            ...navSectionCustomization,
                          });
                        }}
                        label={
                          <Flex gap="xs" align="center">
                            {rawIconName && <Icon name={rawIconName} size={18} color="gray.5" />}
                            <Text fz="sm" c="gray.8">
                              {label}
                            </Text>
                          </Flex>
                        }
                      />
                    );
                  })}
                </Box>
              );
            })}
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
