import { useState } from 'react';
import { Checkbox, MultiSelect, Flex } from '@tw/ui-components';

type FlowSendToSlackStepProps = {
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
  formats: string[];
  setFormats: (formats: string[]) => void;
};

export const FlowSendToSlackStep = ({
  readOnly,
  setIsPristine,
  formats = [],
  setFormats,
}: FlowSendToSlackStepProps) => {
  const formatOptions = ['PDF', 'JSON'];
  const [addAttachment, setAddAttachment] = useState(formats?.length > 0);

  const toggleSetAddAttachment = () => {
    setIsPristine(false);
    setAddAttachment(!addAttachment);
    if (!addAttachment) {
      setFormats(['PDF']);
    } else {
      setFormats([]);
    }
  };

  return (
    <Flex direction="column" gap="sm">
      <Flex direction="column" gap="sm">
        <Checkbox
          label="Add Attachment"
          checked={addAttachment}
          onChange={toggleSetAddAttachment}
          disabled={readOnly}
        />
        {addAttachment && (
          <MultiSelect
            value={formats}
            onChange={(formats) => {
              setIsPristine(false);
              setFormats(formats);
            }}
            disabled={readOnly}
            data={formatOptions}
          />
        )}
      </Flex>
    </Flex>
  );
};
