import { useDarkMode } from 'dark-mode-control';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  NlqResponse,
  RawNlqData,
  TableTab,
  WillyDataColumn,
  WillyMetric,
  ChatSources,
  EditMetricModalPayload,
  updatableFieldsArray,
  UpdatableField,
  WillyParameter,
  WillySort,
} from '../types/willyTypes';
import {
  MAX_ITEMS_PER_PAGE,
  columnsForAds,
  columnsForAdsets,
  columnsForCampaigns,
  entityIdColumns,
  groupAutoColumnId,
} from '../constants';
import allServices from 'constants/services';
import {
  keyIsService,
  arrayMove,
  dataBreakdownContext,
  keyIsEntityName,
  keyIsEntityId,
  keyIsTotal,
  formatDateField,
  dataBreakdownIsAd,
  formatSingleValueCondition,
  generateColorScale,
  compileUrl,
} from '../utils/willyUtils';
import { formatNumber } from 'utils/formatNumber';
import { useAppSelector } from 'reducers/RootType';
import {
  ColDef,
  ColumnMovedEvent,
  Column,
  DomLayoutType,
  IRowNode,
  RowDataUpdatedEvent,
  CellClassParams,
  GetMainMenuItemsParams,
} from 'ag-grid-community';
import {
  AgGridReact,
  CustomHeaderProps,
  CustomMenuItemProps,
  useGridMenuItem,
} from 'ag-grid-react';

import '../styles/WillyTable.scss';
import {
  Flex,
  Icon,
  IconName,
  Image,
  Modal,
  Pagination,
  Skeleton,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { WillyComparisonValue } from '../WillyComparisonValue';
import { usePreviousPeriodValue } from '../hooks/usePreviousPeriodValue';
import { PlayMajor } from '@shopify/polaris-icons';
import { WillyTableBreakdownTabs, useWillyTableBreakdownTabs } from '../WillyTableBreakdownTabs';
import axiosInstance from 'utils/axiosInstance';
import TWImage from 'components/library/TWImage/TWImage';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { WillyGenericVideoPlayer } from '../WillyGenericVideoPlayer';
import ToggleStatus from 'components/attribution-new/toggleStatus';
import { AttributionData } from 'types/attribution';
import { WillyUpdatableField } from './WillyUpdatableField';
import { GRADIENT_CHART_COLORS } from 'constants/general';
import { isBoolean } from 'lodash';
import { $prevDateRange, $currentDateRange } from '../../../$stores/willy/$dateRange';
import { getDashPermsManager } from '../dashboardManagment/permissions-management/DashboardPermissionsManager';
import { useStoreValue } from '@tw/snipestate';
import { Dialect } from '@tw/types';
import { $forceSharded } from '../../../$stores/$shop';

type CellValuePayload = {
  currentValue: string | number | null;
  previousValue?: string | number | null;
  channel?: string;
  name?: string;
};

type TableData = {
  [key: string]: CellValuePayload;
} & {
  name?: string;
  entity?: 'campaign_id' | 'ad_set_id' | 'ad_id';
  source?: string;
  id?: string;
};

type ModalAssetPayload = {
  imageUrl?: string;
  videoUrl?: string;
  type: 'image' | 'video';
};

type HeaderComponentProps = {
  metrics: WillyMetric[];
  queryId: string;
  metric: WillyMetric;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  orderBy: { column: string; sort: WillySort };
  setOrderBy: (orderBy: { column: string; sort: WillySort }) => void;
};

type WillyTableProps = {
  rawData: RawNlqData;
  queryId: string;
  errorInQuery: Record<string, string>;
  query?: string;
  dataColumns?: WillyDataColumn;
  previousPeriodData?: RawNlqData;
  loading?: boolean;
  loadingPreviousPeriod?: boolean;
  metrics: WillyMetric[];
  currency: string;
  onMetricClicked: (metric: WillyMetric, metricValues: Record<string, string | number>) => void;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  setEditMetricModalOpen?: EditMetricModalPayload;
  wrapText?: boolean;
  context: ChatSources;
  hasGlobalConditionalFormatting: boolean;
  globalConditionalFormattingColor: string;
  breakdownMode?: boolean;
  widgetDialect: Dialect;
  parameters: WillyParameter[];
  page?: number;
  setPage?: (page: number) => void;
  totalCount?: number;
  orderBy?: { column: string; sort: WillySort };
  setOrderBy?: (orderBy: { column: string; sort: WillySort }) => void;
};

type LoadingData = {
  data: TableData[];
  totalRow: any[];
};

export const WillyTable: React.FC<WillyTableProps> = ({
  queryId,
  query,
  rawData,
  previousPeriodData,
  dataColumns,
  loading,
  loadingPreviousPeriod,
  metrics,
  wrapText,
  currency,
  metricsChanged,
  setEditMetricModalOpen,
  onMetricClicked,
  context,
  errorInQuery,
  page,
  setPage,
  hasGlobalConditionalFormatting,
  globalConditionalFormattingColor,
  breakdownMode,
  widgetDialect,
  parameters,
  totalCount,
  orderBy,
  setOrderBy,
}) => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const prevDateRange = useStoreValue($prevDateRange);
  const currentDateRange = useStoreValue($currentDateRange);
  const isSmall = useIsSmall();

  const lastRequestRef = useRef<number>();

  const datesToCompareIsNone = useMemo(() => {
    return !prevDateRange;
  }, [prevDateRange]);

  const [lastGeneratedQuery, setLastGeneratedQuery] = useState<string>(query || '');
  const [tableMetrics, setTableMetrics] = useState<WillyMetric[]>(metrics);
  const [expandedCampaigns, setExpandedCampaigns] = useState<string[]>([]);
  const [expandedAdsets, setExpandedAdsets] = useState<string[]>([]);
  const [updatedRawData, setUpdatedRawData] = useState<RawNlqData>(rawData);
  const [activeTab, setActiveTab] = useState<TableTab['id']>();
  const [breakdownData, setBreakdownData] = useState<
    Record<'adsets' | 'ads', { data?: RawNlqData; columns?: WillyDataColumn }>
  >({
    adsets: {},
    ads: {},
  });
  const [loadingBreakdownData, setLoadingBreakdownData] = useState<
    Record<'adsets' | 'ads', boolean>
  >({
    adsets: false,
    ads: false,
  });

  const breakdownContext = useMemo(() => {
    if (!breakdownMode) {
      return null;
    }
    const breakdown = dataBreakdownContext(dataColumns);
    return breakdown;
  }, [dataColumns, breakdownMode]);

  const adContext = useMemo(() => {
    const breakdown = dataBreakdownIsAd(dataColumns);

    return breakdown;
  }, [dataColumns]);

  const tabs = useWillyTableBreakdownTabs(
    breakdownContext || adContext,
    loadingBreakdownData,
    expandedCampaigns,
    expandedAdsets,
    activeTab,
  );

  const firstTabId = useMemo(() => {
    return tabs[0]?.id;
  }, [tabs]);

  useEffect(() => {
    if (!metrics) {
      return;
    }
    setTableMetrics(metrics);
  }, [metrics]);

  useEffect(() => {
    setBreakdownData({
      adsets: {},
      ads: {},
    });

    setActiveTab((old) => {
      return old || firstTabId;
    });

    setExpandedCampaigns((old) => {
      const newCampaignIds = updatedRawData?.find((x) => x.name === 'campaign_id')?.value || [];
      return old.filter((x) => newCampaignIds.includes(x));
    });
  }, [updatedRawData, firstTabId]);

  useEffect(() => {
    if (firstTabId === 'ad_sets' && query) {
      setLastGeneratedQuery(query);
    }
  }, [query, firstTabId]);

  useEffect(() => {
    (async () => {
      let extraColumns: WillyMetric[] = [];

      if (!tableMetrics?.length) {
        return;
      }

      if (breakdownData.adsets.columns?.x?.length && activeTab === 'ad_sets') {
        if (tableMetrics.some((m) => m.key === 'ad_set_id')) {
          return;
        }
        extraColumns = breakdownData.adsets.columns.x.map((c) => {
          return {
            key: c,
            isDimension: true,
            format: 'string',
            toFixed: 0,
            metricKey: c,
            name: c,
            icon: 'blended-metrics',
            description: '',
            color: '',
            onClickAction: 'none',
          };
        });
      }

      if (breakdownData.ads.columns?.x?.length && activeTab === 'ads') {
        if (tableMetrics.some((m) => m.key === 'ad_id')) {
          return;
        }
        const adsColumns = breakdownData.ads.columns.x.map((c) => {
          return {
            key: c,
            isDimension: true,
            format: 'string',
            toFixed: 0,
            metricKey: c,
            icon: 'blended-metrics',
            name: c,
            description: '',
            color: '',
            onClickAction: 'none',
          } as WillyMetric;
        });
        extraColumns = [...adsColumns, ...extraColumns];
      }

      if (extraColumns.length === 0) {
        return;
      }

      const newMetrics = [...extraColumns, ...tableMetrics];
      const uniqueMetrics = newMetrics.filter(
        (v, i, a) => a.findIndex((t) => t.key === v.key) === i,
      );

      const uniqueMetricsKeys = uniqueMetrics.map((m) => m.key);

      if (
        tableMetrics.length === uniqueMetrics.length &&
        tableMetrics.every((m) => uniqueMetricsKeys.includes(m.key))
      ) {
        return;
      }

      await metricsChanged(queryId, uniqueMetrics);
    })();
    // Don't include 'metrics' in the dependencies array because it will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // test
  }, [
    breakdownData.ads.columns,
    breakdownData.adsets.columns,
    metricsChanged,
    queryId,
    activeTab,
    tableMetrics,
  ]);

  const valueFormatter = useCallback(
    ({ value, column, metric }: { value: any; column: Column; metric?: WillyMetric }) => {
      const {
        key = column?.getColId(),
        toFixed = 2,
        minimumFractionDigits = 0,
        format = 'decimal',
        dateFormat,
        isDimension = false,
        isDate = false,
      } = metric || {};
      const isXColumn = dataColumns?.x?.find((x) => x === column?.getColId());
      const isDateColumn = isDate;
      let formattedValue: string;

      const rowData = updatedRawData?.find((x) => x.name === key)?.value || [];

      if (value === null) {
        formattedValue = isDimension || isXColumn ? 'null' : '0';
      } else if (isXColumn && isDateColumn) {
        formattedValue = keyIsTotal(value)
          ? 'Total'
          : formatDateField(key, rowData, value, dateFormat);
      } else if (isXColumn) {
        formattedValue = value;
      } else if (typeof value === 'object') {
        formattedValue = JSON.stringify(value);
      } else if (typeof value === 'number') {
        formattedValue = formatNumber(value, {
          style: format || 'decimal',
          currency,
          maximumFractionDigits: Math.max(minimumFractionDigits, toFixed ?? 0) || 2,
          minimumFractionDigits: minimumFractionDigits || 0,
        });
      } else {
        formattedValue = value;
      }

      return formattedValue;
    },
    [currency, dataColumns, updatedRawData],
  );

  const columns = useMemo(() => {
    if (!tableMetrics) {
      return [];
    }
    let metricsToColumns = tableMetrics;
    if (activeTab === 'campaigns' || loading) {
      metricsToColumns = metricsToColumns.filter(
        (m) => !columnsForAds.includes(m.key) && !columnsForAdsets.includes(m.key),
      );
    } else if (activeTab === 'ad_sets') {
      metricsToColumns = metricsToColumns.filter((m) => !columnsForAds.includes(m.key));
    }

    // if (breakdownContext || adContext || loading) {
    //   metricsToColumns = metricsToColumns.filter((m) => !keyIsService(m.key));
    // }

    const columns: ColDef[] = metricsToColumns.map((metric) => {
      const {
        key = metric.key,
        name = metric.name || metric.key,
        columnWidth,
        mobileColumnWidth,
        pinned,
        hiddenBecauseHasNoData,
        sort,
        active,
        groupByColumn,
        isDimension,
        conditionalStyleType,
        conditionalStyleValue,
        conditionalStyleColor = GRADIENT_CHART_COLORS[0].start,
        isBlocked,
      } = metric || {};

      let isActive = active;
      isActive = makeColumnActiveByContext(key, activeTab, isActive, breakdownMode);
      if (isActive === undefined) {
        isActive = true;
      }

      if (hiddenBecauseHasNoData) {
        isActive = false;
      }

      const tableCellRendererProps: TableCellRendererProps = {
        metric: metric!,
        metricKey: key,
        parameters,
        loadingPreviousPeriod,
        setActiveTab,
        activeTab,
        breakdownContext,
        adContext,
        onMetricClicked,
        setUpdatedRawData,
        setBreakdownData,
      };

      let tableColors: Record<number, string> | null = null;
      const getTableColors = (params: CellClassParams<TableData>) => {
        if (tableColors) {
          return tableColors;
        }
        // get all values from the table
        const allTableValues = params.api?.getRenderedNodes().map((n) => n.data);
        // const allRowValues = params.api?.getDisplayedRowAtIndex(rowIndex)?.data || {};
        const allTableValuesArray = allTableValues
          .map((allRowValues) => {
            return Object.entries(allRowValues || {})
              .filter(([key, v]) => {
                const metric = tableMetrics.find((m) => m.key === key);
                return metric?.applyOnGlobalConditionalFormatting && !metric.isDimension;
              })
              .map(([, v]) => {
                if (typeof v === 'object') {
                  return v.currentValue === null || v.currentValue === undefined
                    ? 0
                    : +v.currentValue;
                }
                return v === null || v === undefined ? 0 : +v;
              });
          })
          .flat();
        if (allTableValuesArray.length === 0) {
          return {};
        }
        const colors = generateColorScale(globalConditionalFormattingColor, allTableValuesArray);
        tableColors = colors;
        return colors;
      };

      const c: ColDef = {
        colId: key,
        field: key,
        headerName: name,
        suppressMovable: isSmall,
        checkboxSelection: (params) => {
          if (!breakdownContext) {
            return false;
          }
          const displayedColumns = params.api.getAllDisplayedColumns();
          return displayedColumns[0] === params.column;
        },
        headerCheckboxSelection: !breakdownContext
          ? undefined
          : (params) => {
              if (activeTab === 'ads') {
                return false;
              }

              if (activeTab === 'campaigns' || activeTab === 'ad_sets') {
                const displayedColumns = params.api.getAllDisplayedColumns();
                return displayedColumns[0] === params.column;
              }

              return false;
            },
        headerCheckboxSelectionFilteredOnly:
          !!breakdownContext && (activeTab === 'campaigns' || activeTab === 'ad_sets'),
        sort: orderBy?.column === key ? orderBy.sort : sort || null,
        wrapText: isDimension && wrapText,
        wrapHeaderText: wrapText,
        autoHeaderHeight: true,
        // autoHeight: false, //typeof firstValue === 'string' && wrapText,
        autoHeight: isDimension && wrapText, //typeof firstValue === 'string' && wrapText,
        sortable: false,
        hide: !isActive || !!isBlocked,
        enableRowGroup: !isSmall,
        keyCreator: (params) => {
          return params.value?.currentValue || params.value;
        },
        comparator: (valueA: CellValuePayload, valueB: CellValuePayload, nodeA, nodeB) => {
          const a = valueA?.currentValue ?? -1;
          const b = valueB?.currentValue ?? -1;
          if (typeof a === 'number' && typeof b === 'number') {
            return a - b;
          } else if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
          } else {
            return 0;
          }
        },
        resizable: true,
        rowGroup: groupByColumn,
        headerComponentParams: {
          metrics: tableMetrics,
          queryId,
          metric,
          metricsChanged,
          orderBy,
          setOrderBy,
        } as HeaderComponentProps,
        pinned: pinned,
        width: (isSmall ? mobileColumnWidth : columnWidth) ?? 150,
        cellDataType: 'text',
        menuTabs: isSmall ? [] : ['generalMenuTab'],
        valueFormatter: (params) => {
          return valueFormatter({ ...params, metric });
        },
        cellRenderer: TableCellRenderer,
        cellRendererParams: tableCellRendererProps,
        cellClass: (params) => {
          const colId = params.column.getColId();
          const isStatus = ['campaign_status', 'ad_set_status', 'ad_status'].includes(colId);
          if (isStatus) {
            return 'willy-table-status-cell';
          }
          return '';
        },
        cellStyle: (params: CellClassParams<TableData>) => {
          const obj = {};
          if (hasGlobalConditionalFormatting) {
            const colors = getTableColors(params);
            return {
              ...obj,
              backgroundColor: colors[+params.value?.currentValue],
            };
          }
          // get all values for this column
          if (conditionalStyleType === 'none' || !conditionalStyleType) {
            return obj;
          }

          const value = params.value?.currentValue;
          const conditionValue = conditionalStyleValue;
          if (value === undefined) {
            return obj;
          }

          if (conditionalStyleType === 'singleValue') {
            const conditionalStyle = formatSingleValueCondition(metric, value, conditionValue);
            return {
              ...obj,
              ...conditionalStyle,
            };
          } else if (isNaN(value) || !isFinite(value)) {
            return obj;
          } else if (conditionalStyleType === 'scale' && !isDimension) {
            const allValuesInColumn = params.api
              .getRenderedNodes()
              .map((n) => n.data?.[key]?.currentValue)
              .filter((v) => v !== undefined) as number[];

            const colors = generateColorScale(conditionalStyleColor, allValuesInColumn);
            return {
              ...obj,
              backgroundColor: colors[value],
            };
          }

          return obj;
        },
      };

      return c;
    });

    return columns;
  }, [
    activeTab,
    loadingPreviousPeriod,
    tableMetrics,
    metricsChanged,
    queryId,
    valueFormatter,
    onMetricClicked,
    isSmall,
    wrapText,
    breakdownContext,
    adContext,
    loading,
    hasGlobalConditionalFormatting,
    globalConditionalFormattingColor,
    breakdownMode,
    orderBy,
    parameters,
    setOrderBy,
  ]);

  const hasTotalRow = useMemo(() => {
    const hasTotalRow =
      updatedRawData?.some((x) => x.value.some(keyIsTotal)) &&
      updatedRawData.some((x) => x.value.length > 1);
    return hasTotalRow;
  }, [updatedRawData]);

  const formatRawData = useCallback(
    (raw: RawNlqData, breakdown?: 'campaign_id' | 'ad_set_id' | 'ad_id') => {
      if (!raw?.length) {
        return [];
      }

      const lineWithMaxValues = raw.reduce((acc, x) => {
        return x.value.length > acc.value?.length ? x : acc;
      }, raw[0]);

      let tableData = lineWithMaxValues?.value.map((d, i) => {
        const row: TableData = {};

        raw.forEach((x) => {
          let shouldShowPreviousValue = false;
          if (!breakdownContext) {
            shouldShowPreviousValue = true;
          } else if (
            !datesToCompareIsNone &&
            ((breakdownContext === 'campaign_id' && activeTab === 'campaigns') ||
              (breakdownContext === 'ad_set_id' && activeTab === 'ad_sets'))
          ) {
            shouldShowPreviousValue = true;
          }
          row[x.name] = {
            currentValue: isBoolean(x.value[i]) ? (x.value[i] as any).toString() : x.value[i],
            previousValue: shouldShowPreviousValue
              ? previousPeriodData?.find((d) => d.name === x.name)?.value[i]
              : undefined,
          };
          if (breakdownContext || adContext) {
            row.entity = breakdown || undefined;
            row.id = raw.find((d) => keyIsEntityId(d.name, breakdown))?.value[i] as string;
            row.name = raw.find((d) => keyIsEntityName(activeTab, d.name))?.value[i] as string;
            row.source = raw.find((d) => keyIsService(d.name))?.value[i] as string;
          }
        });

        return row;
      });

      return tableData;
    },
    [breakdownContext, adContext, previousPeriodData, activeTab, datesToCompareIsNone],
  );

  const data = useMemo(() => {
    const data = formatRawData(updatedRawData, breakdownContext || adContext || undefined);
    return data;
  }, [formatRawData, updatedRawData, breakdownContext, adContext]);

  const fetchData = useCallback(
    async (query: string, entity: 'adset' | 'ad', ids: string[]) => {
      const queryParams = (parameters || [])
        .filter((p) => p.isQueryParameter)
        .reduce((acc, parameter) => {
          return {
            ...acc,
            [parameter.column]: parameter.value,
          };
        }, {});

      const { data } = await axiosInstance.post<NlqResponse>(
        '/v2/willy/breakdown-query-by-entity',
        {
          query,
          shopId: currentShopId,
          entity,
          ids,
          changedParameters: parameters.filter(
            (p) => !p.isQueryParameter && (p.disabled || p.visibleInDashboard),
          ),
          dialect: widgetDialect,
          forceSharded: $forceSharded.get(),
          ...(currentDateRange
            ? {
                queryParams: {
                  start_date: currentDateRange.start.format('YYYY-MM-DD'),
                  end_date: currentDateRange.end.format('YYYY-MM-DD'),
                  ...queryParams,
                },
              }
            : {}),
        },
      );

      return data;
    },
    [currentShopId, widgetDialect, currentDateRange, parameters],
  );

  useEffect(() => {
    setUpdatedRawData(rawData);
  }, [rawData]);

  useEffect(() => {
    (async () => {
      if (!expandedCampaigns.length || !query) {
        return;
      }

      setLoadingBreakdownData((old) => {
        return {
          ...old,
          adsets: true,
        };
      });

      const lastRequest = Date.now();
      lastRequestRef.current = lastRequest;

      const response = await fetchData(query, 'adset', expandedCampaigns);

      if (lastRequestRef.current !== lastRequest) {
        return;
      }
      const { data, dataColumns: dc, generatedQuery = '', queryId } = response || {};
      if (!data || !dc) {
        return;
      }
      setLastGeneratedQuery(generatedQuery);
      setBreakdownData((old) => {
        return {
          ...old,
          adsets: {
            data,
            columns: dc,
          },
          ads: old.ads,
        };
      });

      setExpandedAdsets((old) => {
        const newAdsetIds = data.find((x) => x.name === 'ad_set_id')?.value || [];
        return old.filter((x) => newAdsetIds.includes(x));
      });

      setLoadingBreakdownData((old) => {
        return {
          ...old,
          adsets: false,
        };
      });
      // TODO: need to implement prev period data for adset and ad level
      // const res = (await loadPreviousPeriodData?.(queryId, true)) as NlqResponse;
      // console.log('res', res);
    })();
  }, [expandedCampaigns, fetchData, query]);

  useEffect(() => {
    (async () => {
      if (!expandedAdsets.length || !lastGeneratedQuery) {
        return;
      }

      setLoadingBreakdownData((old) => {
        return {
          ...old,
          ads: true,
        };
      });

      const lastRequest = Date.now();
      lastRequestRef.current = lastRequest;

      const response = await fetchData(lastGeneratedQuery, 'ad', expandedAdsets);

      if (lastRequestRef.current !== lastRequest) {
        return;
      }
      const { data, dataColumns: dc } = response || {};
      if (!data || !dc) {
        return;
      }
      setBreakdownData((old) => {
        return {
          ...old,
          ads: {
            data,
            columns: dc,
          },
          adsets: old.adsets,
        };
      });

      setLoadingBreakdownData((old) => {
        return {
          ...old,
          ads: false,
        };
      });
    })();
  }, [expandedAdsets, fetchData, lastGeneratedQuery]);

  const effectiveData = useMemo(() => {
    if (hasTotalRow) {
      return data.slice(0, -1);
    }
    return data;
  }, [data, hasTotalRow]);

  const totalRow = useMemo(() => {
    if (!hasTotalRow) {
      return [];
    }
    return data.slice(-1);
  }, [data, hasTotalRow]);

  const loadingAnyData = useMemo(() => {
    return !!loading;
  }, [loading]);

  return (
    <div
      className={`willy-table w-full sm:rounded h-full flex flex-col ${
        !!breakdownContext && !!activeTab ? 'overflow-hidden' : 'overflow-auto'
      }`}
    >
      {!!breakdownContext && !!activeTab && (
        <WillyTableBreakdownTabs tabs={tabs} activeTab={activeTab} tabChanged={setActiveTab} />
      )}
      <div className="h-full w-full relative">
        <Table
          data={effectiveData}
          columns={columns}
          queryId={queryId}
          totalRow={totalRow}
          metrics={tableMetrics}
          metricsChanged={metricsChanged}
          domLayout="autoHeight"
          breakdownContext={breakdownContext}
          breakdownData={breakdownData}
          expandedCampaigns={expandedCampaigns}
          expandedAdsets={expandedAdsets}
          setExpandedCampaigns={setExpandedCampaigns}
          setExpandedAdsets={setExpandedAdsets}
          formatRawData={formatRawData}
          activeTab={activeTab}
          context={context}
          isLoading={loadingAnyData}
          errorInQuery={errorInQuery}
          setEditMetricModalOpen={setEditMetricModalOpen}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
        />
      </div>
    </div>
  );
};

type TableProps = {
  data: TableData[];
  columns: ColDef[];
  queryId: string;
  totalRow: TableData[];
  metrics: WillyMetric[];
  domLayout: DomLayoutType;
  breakdownContext: ReturnType<typeof dataBreakdownContext>;
  activeTab?: TableTab['id'];
  expandedCampaigns?: string[];
  expandedAdsets?: string[];
  breakdownData?: Record<'adsets' | 'ads', { data?: RawNlqData; columns?: WillyDataColumn }>;
  setExpandedCampaigns?: React.Dispatch<React.SetStateAction<string[]>>;
  setExpandedAdsets?: React.Dispatch<React.SetStateAction<string[]>>;
  metricsChanged: (id: string, v: WillyMetric[]) => void;
  formatRawData: (raw: RawNlqData, breakdown: 'campaign_id' | 'ad_set_id' | 'ad_id') => TableData[];
  context?: ChatSources;
  isLoading: boolean;
  errorInQuery: Record<string, string>;
  setEditMetricModalOpen?: EditMetricModalPayload;
  page?: number;
  setPage?: (page: number) => void;
  totalCount?: number;
};

const Table: React.FC<TableProps> = ({
  data,
  columns,
  totalRow,
  metrics,
  domLayout,
  queryId,
  breakdownData,
  breakdownContext,
  activeTab,
  expandedCampaigns = [],
  expandedAdsets = [],
  setExpandedAdsets,
  setExpandedCampaigns,
  metricsChanged,
  formatRawData,
  context,
  isLoading = false,
  errorInQuery,
  setEditMetricModalOpen,
  page,
  setPage,
  totalCount,
}) => {
  const doDarkMode = useDarkMode();
  const gridRef = useRef<AgGridReact>(null);
  const isSmall = useIsSmall();

  const loadingData: LoadingData = useMemo(() => {
    if (!metrics) {
      return {
        data: [],
        totalRow: [],
      };
    }
    return {
      data: Array(10).fill(
        metrics.reduce((acc, m) => {
          return {
            ...acc,
            [m.key]: {
              currentValue: 'loading',
            },
          };
        }, {} as TableData),
      ),
      totalRow: [],
    };
  }, [metrics]);

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableTotalRow, setTableTotalRow] = useState<TableData[]>(totalRow);
  const [imageModalOpened, setImageModalOpened] = useState<ModalAssetPayload>();

  useEffect(() => {
    setTableTotalRow(totalRow);
  }, [totalRow]);

  const breakdownHasTotalRow = useMemo(() => {
    if (!breakdownContext) {
      return false;
    }

    if (!breakdownData?.adsets.data && !breakdownData?.ads.data) {
      return false;
    }

    const hasTotalRow =
      breakdownData.adsets.data?.some((x) => x.value.some(keyIsTotal)) ||
      breakdownData.ads.data?.some((x) => x.value.some(keyIsTotal));

    const hasMoreThanOneRow =
      breakdownData.adsets.data?.some((x) => x.value.length > 1) ||
      breakdownData.ads.data?.some((x) => x.value.length > 1);

    return hasTotalRow && hasMoreThanOneRow;
  }, [breakdownContext, breakdownData?.ads.data, breakdownData?.adsets.data]);

  const handleDragEnd = useCallback(
    async (event: ColumnMovedEvent) => {
      const { finished, column, toIndex } = event;

      if (!finished || !column || toIndex === undefined) {
        return;
      }
      const colId = column?.getColId();

      const oldIndex = metrics?.findIndex((c) => c.key === colId);

      const newIndex = toIndex;
      const newMetrics = arrayMove(metrics, oldIndex, newIndex);
      metricsChanged(queryId, newMetrics);
    },
    [metrics, metricsChanged, queryId],
  );

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);

  const onCellDoubleClicked = useCallback((params) => {
    if (params.colDef.showRowGroup) {
      params.node.setExpanded(!params.node.expanded);
    }
  }, []);

  const onCellKeyDown = useCallback((params) => {
    if (!('colDef' in params)) {
      return;
    }
    if (!(params.event instanceof KeyboardEvent)) {
      return;
    }
    if (params.event.code !== 'Enter') {
      return;
    }
    if (params.colDef.showRowGroup) {
      params.node.setExpanded(!params.node.expanded);
    }
  }, []);

  const onRowDataUpdated = useCallback(
    (params: RowDataUpdatedEvent<any>) => {
      if (!params.api || (params.api.getDisplayedRowCount() <= 0 && !activeTab)) return;

      params.api.forEachNode((node) => {
        if (!node.data?.entity) {
          return;
        }
        if (node.data.entity === 'campaign_id' && activeTab === 'campaigns') {
          const id = node.data?.campaign_id?.currentValue;
          if (!id) {
            return;
          }
          if (expandedCampaigns.includes(id) && !node.isSelected()) {
            // 'api' is a workaround to prevent infinite loop
            node.setSelected(true, undefined, 'api');
          }
        } else if (node.data.entity === 'ad_set_id' && activeTab === 'ad_sets') {
          const id = node.data?.ad_set_id?.currentValue;
          if (!id) {
            return;
          }
          if (expandedAdsets.includes(id) && !node.isSelected()) {
            node.setSelected(true, undefined, 'api');
          }
        }
      });
    },
    [activeTab, expandedCampaigns, expandedAdsets],
  );

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      let items: any = [...params.defaultItems];
      const { canEdit } = getDashPermsManager().computeDashPerms();
      if (setEditMetricModalOpen && canEdit) {
        items = [
          {
            name: 'Edit Metric',
            menuItem: CustomMainMenuItem,
            iconName: 'edit',
            menuItemParams: {
              onClick: () =>
                setEditMetricModalOpen({ open: true, metricId: params.column.getId(), queryId }),
            },
          },
          {
            name: 'Hide Column',
            menuItem: CustomMainMenuItem,
            iconName: 'eye',
            menuItemParams: {
              onClick: () => {
                const newMetrics = metrics?.map((m) => {
                  return {
                    ...m,
                    active: m.key === params.column.getId() ? false : m.active,
                  };
                });
                metricsChanged(queryId, newMetrics);
              },
            },
          },
          ...params.defaultItems,
        ];
      }
      return items;
    },
    [queryId, setEditMetricModalOpen, metrics, metricsChanged],
  );

  // effects
  // in campaigns tab, with breakdownContext === 'campaigns', show the data from props
  // in ad sets tab, with breakdownContext === 'ad_sets', show the data from props
  useEffect(() => {
    if (!breakdownContext) {
      setTableData(data);
      return;
    }

    if (
      (activeTab === 'campaigns' && breakdownContext === 'campaign_id') ||
      (activeTab === 'ad_sets' && breakdownContext === 'ad_set_id')
    ) {
      setTableData(data);
      // gridRef.current.api?.setRowData(data);
    }
  }, [activeTab, breakdownContext, data]);

  // in ad sets tab, with breakdownContext of campaigns, show the data from breakdownData.adsets.data
  useEffect(() => {
    if (activeTab !== 'ad_sets' || breakdownContext !== 'campaign_id') {
      return;
    }

    const data = formatRawData(breakdownData?.adsets.data || [], 'ad_set_id');
    const dataToShow = data?.filter((d) => {
      return (
        expandedCampaigns.includes(d.campaign_id?.currentValue?.toString() || '') ||
        keyIsTotal(d.ad_set_id?.currentValue)
      );
    });

    // gridRef.current.api?.setRowData(dataToShow);
    if (breakdownHasTotalRow) {
      setTableData(dataToShow?.slice(0, -1));
      setTableTotalRow(dataToShow?.slice(-1));
    } else {
      setTableData(dataToShow);
      setTableTotalRow([]);
    }
  }, [
    activeTab,
    breakdownContext,
    breakdownData?.adsets.data,
    formatRawData,
    expandedCampaigns,
    breakdownHasTotalRow,
  ]);

  // in ads tab, with breakdownContext of campaigns, show the data from breakdownData.ads.data
  useEffect(() => {
    if (activeTab !== 'ads' || !breakdownContext) {
      return;
    }

    const data = formatRawData(breakdownData?.ads.data || [], 'ad_id');
    const dataToShow = data?.filter(
      (d) =>
        expandedAdsets.includes(d.ad_set_id?.currentValue?.toString() || '') ||
        keyIsTotal(d.ad_set_id?.currentValue),
    );

    if (breakdownHasTotalRow) {
      setTableData(dataToShow?.slice(0, -1));
      setTableTotalRow(dataToShow?.slice(-1));
    } else {
      setTableData(dataToShow);
      setTableTotalRow([]);
    }
  }, [
    activeTab,
    breakdownContext,
    breakdownData?.ads.data,
    formatRawData,
    expandedAdsets,
    breakdownHasTotalRow,
  ]);

  return (
    <div
      className={`flex flex-col h-full ${
        !!breakdownContext && !!activeTab
          ? tableTotalRow.length > 0
            ? '!h-[calc(100%-55px)] overflow-hidden'
            : 'overflow-hidden'
          : ''
      }`}
    >
      <div
        className={`${doDarkMode ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}`}
        style={{
          height: `calc(100% - ${!!totalCount ? '40px' : '0px'})`,
          width: '100%',
        }}
      >
        {!!errorInQuery[queryId] && !isLoading && (
          <div className="flex items-center justify-center h-full text-red-800">
            {errorInQuery[queryId]}
          </div>
        )}
        {!errorInQuery[queryId] && (
          <AgGridReact
            ref={gridRef}
            rowData={isLoading ? loadingData.data : tableData}
            columnDefs={columns}
            onGridReady={(params) => {
              // params.api.getToolPanelInstance('filters')?.expandFilters();
            }}
            rowHeight={40}
            headerHeight={40}
            suppressColumnMoveAnimation={isSmall}
            onRowDataUpdated={onRowDataUpdated}
            getMainMenuItems={getMainMenuItems}
            onCellDoubleClicked={onCellDoubleClicked}
            onCellClicked={(params) => {
              if (params.event?.defaultPrevented || isLoading) {
                return;
              }
              if (params.colDef.colId === 'ad_image_url') {
                setImageModalOpened({
                  imageUrl: params.value.currentValue,
                  type: 'image',
                });
              } else if (params.colDef.colId === 'video_url') {
                setImageModalOpened({
                  videoUrl: params.value.currentValue,
                  type: 'video',
                });
              }
            }}
            noRowsOverlayComponent={CustomNoRowsOverlay}
            showOpenedGroup={true}
            onColumnRowGroupChanged={(e) => {
              if (isLoading) {
                return;
              }
              // gridOptionsUpdated
              if (e.source !== 'contextMenu') {
                return;
              }

              const { columns } = e;

              const newMetrics = metrics?.map((m) => {
                return {
                  ...m,
                  groupByColumn: !!columns?.some((c) => c?.getColId() === m.key),
                };
              });

              metricsChanged(queryId, newMetrics);
            }}
            groupDisplayType="groupRows"
            noRowsOverlayComponentParams={{
              noRowsMessageFunc: () => {
                return ' ';
              },
            }}
            enableCellTextSelection
            ensureDomOrder={!breakdownContext}
            rowSelection={
              activeTab === 'campaigns' || activeTab === 'ad_sets' ? 'multiple' : undefined
            }
            suppressRowClickSelection
            rowMultiSelectWithClick={!!breakdownContext}
            isRowSelectable={(node) => {
              if (!breakdownContext) {
                return false;
              }

              if (activeTab === 'campaigns' || activeTab === 'ad_sets') {
                return true;
              }
              return false;
            }}
            onRowSelected={(node) => {
              // only do this logic if the selection triggered by the the ui through the checkbox, and not by the api
              // this is a workaround to prevent infinite loop, because the api selects rows on changing tabs (see "node.setSelected(true, undefined, 'api')")
              if (!['checkboxSelected', 'uiSelectAllFiltered'].some((x) => x === node.source)) {
                return;
              }
              const { entity } = node.data || {};
              if (!entity) {
                return;
              }

              const isSelected = node.node.isSelected();

              if (entity === 'campaign_id') {
                const id = node.data?.campaign_id?.currentValue as string;
                if (!id) {
                  return;
                }
                // if (!isSelected && breakdownData?.adsets.data?.length) {
                //   const campaignField = breakdownData.adsets.data.find(
                //     (x) => x.name === 'campaign_id'
                //   );
                //   const adsetField = breakdownData.adsets.data.find((x) => x.name === 'ad_set_id');

                //   const adsetsFromAllCampaigns = campaignField?.value?.map((cId, i) => {
                //     return {
                //       campaignId: cId as string,
                //       adsetId: adsetField?.value?.[i] as string,
                //     };
                //   });

                //   const adsetsFromThisCampaign = adsetsFromAllCampaigns?.filter(
                //     (x) => x.campaignId === id
                //   );

                //   setVisibleAdsets?.((old) => {
                //     const oldAdsetsFromThisCampaign = old.filter((adsetId) => {
                //       return adsetsFromThisCampaign?.some((x) => x.adsetId === adsetId);
                //     });
                //     return old.filter((adsetId) => {
                //       return !oldAdsetsFromThisCampaign.includes(adsetId);
                //     });
                //   });
                // }
                setExpandedCampaigns?.((old) => {
                  if (isSelected) {
                    const unique = [...new Set([...old, id])];
                    return unique;
                  }
                  return old.filter((c) => c !== id);
                });
                // if (!expandedCampaigns.includes(id) && isSelected) {
                //   setExpandedCampaigns?.((old) => {
                //     const unique = [...new Set([...old, id])];
                //     return unique;
                //   });
                // }
              } else if (entity === 'ad_set_id') {
                const id = node.data?.ad_set_id?.currentValue as string;
                const channel = node.data?.channel?.currentValue;
                const shouldLoadEmptyAdsets = channel === 'taboola' || channel === 'outbrain';
                if (!id && !shouldLoadEmptyAdsets) {
                  return;
                }
                setExpandedAdsets?.((old) => {
                  if (isSelected) {
                    const unique = [...new Set([...old, id])];
                    return unique;
                  }
                  return old.filter((c) => c !== id);
                });
                // if (!expandedAdsets.includes(id) && isSelected) {
                //   setExpandedAdsets?.((old) => {
                //     const unique = [...new Set([...old, id])];
                //     return unique;
                //   });
                // }
              }
            }}
            onCellKeyDown={onCellKeyDown}
            // groupDefaultExpanded={-1}
            // rowGroupPanelShow="always"
            onColumnPinned={(e) => {
              const { column, pinned } = e;
              const colId = column?.getColId();
              const newMetrics = metrics?.map((m) => {
                if (m.key === colId) {
                  return {
                    ...m,
                    pinned: pinned,
                  };
                }
                return m;
              });
              metricsChanged(queryId, newMetrics);
            }}
            onColumnVisible={(e) => {
              const { column, visible, source } = e;
              // gridOptionsUpdated is a new source since v31.0.0 but not yet in the types
              if (source === 'gridOptionsChanged' || source === ('gridOptionsUpdated' as any)) {
                return;
              }

              const colId = column?.getColId();
              const newMetrics = metrics?.map((m) => {
                if (m.key === colId) {
                  return {
                    ...m,
                    active: !!visible,
                  };
                }
                return m;
              });
              metricsChanged(queryId, newMetrics);
            }}
            // sideBar={{
            //   hiddenByDefault: false,
            //   toolPanels: [
            //     {
            //       id: 'columns',
            //       labelDefault: 'Columns',
            //       labelKey: 'columns',
            //       iconKey: 'columns',
            //       toolPanel: 'agColumnsToolPanel',
            //       toolPanelParams: {
            //         suppressRowGroups: true,
            //         suppressValues: true,
            //         suppressPivots: true,
            //       },
            //     },
            //   ],
            // }}
            // pagination={paginationType === 'client'}
            // paginationPageSize={paginationType === 'client' ? MAX_ITEMS_PER_PAGE : undefined}
            // paginationPageSizeSelector={false}
            // suppressPaginationPanel
            onColumnMoved={isSmall ? undefined : handleDragEnd}
            processCellForClipboard={(params) => {
              const { value, column } = params;
              const { currentValue } = value;
              if (typeof currentValue === 'number') {
                return JSON.stringify(value);
              }
              return JSON.stringify(currentValue);
            }}
            domLayout={domLayout}
            // animateRows={!isSmall && !isLoading}
            // defaultColDef={defaultColDef}
            onColumnResized={(e) => {
              const { finished, column, source } = e;
              if (!finished || !column || source !== 'uiColumnResized') {
                return;
              }

              let colId = column?.getColId();
              if (colId === groupAutoColumnId) {
                colId = column?.getColDef().field || colId;
              }
              const width = column?.getActualWidth();

              const widthProp = isSmall ? 'mobileColumnWidth' : 'columnWidth';
              const newMetrics = metrics?.map((m) => {
                if (m.key === colId) {
                  return {
                    ...m,
                    [widthProp]: width,
                  };
                }
                return m;
              });
              metricsChanged(queryId, newMetrics);
            }}
            components={components}
            pinnedBottomRowData={isLoading ? undefined : tableTotalRow}
            // scrollbarWidth={8}
            alwaysShowVerticalScroll
            suppressHorizontalScroll={false} // --> this is not good if you have windows, leave it as false (the default value)
            suppressScrollOnNewData // I think this is good when user is update status/budget, but I didn't really test it :/
            suppressHeaderFocus // this is needed to allow user to click on the arrow keys when editing a metric name on the header
            reactiveCustomComponents
            suppressColumnVirtualisation={!isSmall && columns?.length < 15} // we need it for good conditional formatting and for better ux, also, we have pagination anyway
            suppressRowVirtualisation={!isSmall} // same as above
            suppressFieldDotNotation // for products_table -> variants.variant_id for example. that means we can't use dot notation for nested fields so the backend can't send data like: { name: { first: 'John', last: 'Doe' }, data: [1, 2] }
          />
        )}
        {!!totalCount && (
          <div className="flex gap-4 w-full justify-end px-4 h-16 items-center border-t border-b-0 border-l-0 border-r-0 border-solid border-[var(--border-color,#e0e0e0)]">
            <Text size="sm">Total Rows: {totalCount}</Text>
            <Pagination
              total={Math.ceil((totalCount || 0) / MAX_ITEMS_PER_PAGE)}
              value={page}
              onChange={setPage}
              size="xs"
              disabled={!!totalCount && totalCount <= MAX_ITEMS_PER_PAGE}
            />
          </div>
        )}
      </div>
      <Modal.Root
        size="xs"
        centered
        opened={!!imageModalOpened}
        onClose={() => {
          setImageModalOpened(undefined);
        }}
        p="0"
      >
        <Modal.Overlay />
        <Modal.Content mih={200}>
          <Modal.Body p="0">
            <Modal.CloseButton className="absolute top-0 right-0 z-50 bg-transparent border-none cursor-pointer" />
            <div className="flex items-center justify-center object-contain h">
              {imageModalOpened?.imageUrl && <Image miw="100%" src={imageModalOpened.imageUrl} />}
              {imageModalOpened?.videoUrl && (
                <div className="flex items-center gap-4 h-full">
                  <WillyGenericVideoPlayer
                    source={imageModalOpened.videoUrl}
                    className="w-full h-full"
                    provider="facebook" // TODO: get provider from data
                    dimensionsChanged={(width, height) => {
                      // setAssetModalWidth(width);
                      // setAssetModalHeight(height);
                    }}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

const CustomMainMenuItem = ({
  name,
  subMenu,
  onClick,
  iconName,
}: CustomMenuItemProps & { onClick: () => void; iconName: IconName }) => {
  useGridMenuItem({
    configureDefaults: () => true,
  });

  return (
    <div onClick={onClick}>
      <span className="ag-menu-option-part ag-menu-option-icon" role="presentation">
        <Icon name={iconName} />
      </span>
      <span className="ag-menu-option-part ag-menu-option-text">{name}</span>
      <span className="ag-menu-option-part ag-menu-option-shortcut"></span>
      <span className="ag-menu-option-part ag-menu-option-popup-pointer">
        {subMenu && (
          <span
            className="ag-icon ag-icon-small-right"
            unselectable="on"
            role="presentation"
          ></span>
        )}
      </span>
    </div>
  );
};

const CustomHeader: React.FC<HeaderComponentProps & CustomHeaderProps<TableData>> = (props) => {
  const { metrics, metricsChanged, queryId, metric, setOrderBy, orderBy, column } = props;
  const columnId = column?.getColId();

  const [ascSort, setAscSort] = useState('hidden');
  const [descSort, setDescSort] = useState('hidden');
  const [noSort, setNoSort] = useState('hidden');
  // const [filterActive, setFilterActive] = useState(false);
  const refButton = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   setFilterActive(props.column.isFilterActive());
  //   // props.api.onFilterChanged();
  // }, [props.column]);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current!);
  };

  const onSortChanged = useCallback(
    (order: WillySort, colId: string) => {
      setAscSort(order === 'asc' && colId === columnId ? 'flex' : 'hidden');
      setDescSort(order === 'desc' && colId === columnId ? 'flex' : 'hidden');
      setNoSort(order === undefined && colId === columnId ? 'flex' : 'hidden');
    },
    [columnId],
  );

  const onSortRequested = useCallback(
    (order: WillySort) => {
      setOrderBy({ sort: order, column: columnId });
      onSortChanged(order, columnId);
      // props.setSort(order, event.shiftKey);
      // const { column } = props;
      // const colId = column?.getColId();
      // const sort = column?.getSort();

      const newMetrics = metrics?.map((m) => {
        if (m.key === columnId) {
          return {
            ...m,
            sort: order ?? (null as any),
          };
        }
        return {
          ...m,
          sort: null as any,
        };
      });

      metricsChanged(queryId, newMetrics);
    },
    [columnId, metrics, metricsChanged, queryId, onSortChanged, setOrderBy],
  );

  useEffect(() => {
    const sort = column.getSort();
    onSortChanged(sort as WillySort, columnId);
  }, [orderBy, columnId, onSortChanged, column]);

  // useEffect(() => {
  //   props.column.addEventListener('sortChanged', onSortChanged);
  //   onSortChanged();

  //   return () => {
  //     props.column.removeEventListener('sortChanged', onSortChanged);
  //   };
  // }, [onSortChanged, props.column]);

  // useEffect(() => {
  //   function onFilterChanged() {
  //     setFilterActive(props.column.isFilterActive());
  //   }

  //   props.column.addEventListener('filterChanged', onFilterChanged);
  //   return () => {
  //     props.column.removeEventListener('filterChanged', onFilterChanged);
  //   };
  // }, [props.column]);

  let menu: React.ReactNode = null;
  let sort: React.ReactNode = null;

  const icon = (
    <div className="flex items-center gap-1">
      {!!metric?.icon && <Icon name={metric?.icon as IconName} size={12} />}
    </div>
  );

  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onMenuClicked();
        }}
      >
        <i className="ag-icon ag-icon-menu"></i>
      </div>
    );
  }

  // if (props.enableSorting) {
  sort = (
    <div className="flex items-center gap-2">
      <div className={`customSortDownLabel ${ascSort}`}>
        <Tooltip label="Ascending">
          <i className="ag-icon ag-icon-asc"></i>
        </Tooltip>
      </div>
      <div className={`customSortUpLabel ${descSort}`}>
        <Tooltip label="Descending">
          <i className="ag-icon ag-icon-desc"></i>
        </Tooltip>
      </div>
      <div className={`customSortRemoveLabel ${noSort}`}>
        <i className=""></i>
      </div>
    </div>
  );
  // }

  return (
    <div
      onClick={() => {
        if (!props.column.isSortAscending() && !props.column.isSortDescending()) {
          onSortRequested('asc');
        } else if (props.column.isSortAscending()) {
          onSortRequested('desc');
        } else if (props.column.isSortDescending()) {
          onSortRequested(null);
        }
      }}
      onTouchEnd={() => {
        if (!props.column.isSortAscending() && !props.column.isSortDescending()) {
          onSortRequested('asc');
        } else if (props.column.isSortAscending()) {
          onSortRequested('desc');
        } else if (props.column.isSortDescending()) {
          onSortRequested(null);
        }
      }}
      className="flex items-center gap-2 w-full group/table group/tile overflow-hidden"
    >
      {/* {filterActive && <i className="ag-icon ag-icon-filter"></i>} */}
      {icon}
      <Tooltip
        label={
          <Flex direction="column">
            <Text weight={500} size="sm" color="gray.8">
              {props.displayName}
            </Text>
            <Text weight={500} size="sm" color="gray.6">
              {metric?.description}
            </Text>
          </Flex>
        }
        bg="white"
        className="border border-solid border-gray-200 shadow-md"
      >
        <div
          className="flex justify-start !font-medium"
          contentEditable
          suppressContentEditableWarning
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
          onBlur={(e) => {
            const newMetrics = metrics?.map((m) => {
              return {
                ...m,
                name: m.key === columnId ? e.currentTarget.innerText : m.name,
              };
            });
            metricsChanged(queryId, newMetrics);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
        >
          {props.displayName}
        </div>
      </Tooltip>
      {sort}
      <div className="absolute right-7 flex gap-2 items-center bg-[var(--background-color)] z-10 opacity-0 transition-opacity group-hover/table:opacity-100">
        {menu}
      </div>
    </div>
  );
};

type TableCellRendererProps = {
  metric?: WillyMetric;
  metricKey: string;
  parameters: WillyParameter[];
  loadingPreviousPeriod?: boolean;
  breakdownContext: ReturnType<typeof dataBreakdownContext>;
  adContext: ReturnType<typeof dataBreakdownIsAd>;
  setActiveTab: (toActive: TableTab['id']) => void;
  activeTab?: TableTab['id'];
  onMetricClicked: (metric: WillyMetric, metricValues: Record<string, string | number>) => void;
  setUpdatedRawData: React.Dispatch<React.SetStateAction<RawNlqData>>;
  setBreakdownData: React.Dispatch<
    React.SetStateAction<Record<'adsets' | 'ads', { data?: RawNlqData; columns?: WillyDataColumn }>>
  >;
};

const TableCellRenderer: React.FC<
  TableCellRendererProps & {
    node: IRowNode;
    getValue: () => CellValuePayload;
    formatValue: (value: string | number) => string;
  }
> = (props) => {
  const {
    metric,
    metricKey,
    parameters,
    loadingPreviousPeriod,
    getValue,
    formatValue,
    node,
    setActiveTab,
    activeTab,
    breakdownContext,
    adContext,
    onMetricClicked,
    setUpdatedRawData,
    setBreakdownData,
  } = props;

  const [expanded, setExpanded] = useState(node.expanded);

  const currentDateRange = useStoreValue($currentDateRange);

  const allValue = useMemo(() => {
    return getValue();
  }, [getValue]);

  useEffect(() => {
    const expandListener = (event) => setExpanded(event.node.expanded);
    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, [node]);

  const onExpandedChange = useCallback(() => node.setExpanded(!node.expanded), [node]);

  const cellUrl = useMemo(() => {
    if (!metric) {
      return undefined;
    }
    const urls = metric.onClickActionUrls;

    if (metric.onClickAction === 'externalUrl') {
      return urls?.[0];
    }

    const valueUrl = urls?.find((x) => x.value === allValue?.currentValue);
    return valueUrl;
  }, [metric, allValue?.currentValue]);

  const isCellClickable = useMemo(() => {
    const isTotalRow = Object.values<CellValuePayload>(node.data).some(
      (value) => value?.currentValue === 'total',
    );

    if (isTotalRow) {
      return false;
    }

    return (
      !!metric &&
      metric.onClickAction !== 'none' &&
      allValue?.currentValue !== undefined &&
      allValue?.currentValue !== null
    );
  }, [metric, allValue?.currentValue, node.data]);

  const onCellClicked = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.defaultPrevented) {
        return;
      }

      const isTotalRow = Object.values<CellValuePayload>(node.data).some(
        (value) => value?.currentValue === 'total',
      );

      if (isTotalRow) {
        return;
      }

      if (!metric || metric.onClickAction === 'none' || !isCellClickable) {
        return;
      }

      if (metric.onClickAction === 'url') {
        const valueUrl = cellUrl;
        const url = valueUrl?.url;
        if (!url) {
          return;
        }
        if (valueUrl.openInNewTab) {
          window
            .open(url, 'popUpWindow', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
            ?.focus();
          return;
        } else {
          window.open(url, '_blank');
          return;
        }
      }

      const { start, end } = currentDateRange || {};

      const queryVars = parameters
        .filter((x) => x.isQueryParameter)
        .reduce((acc, x) => {
          let value = x.value;
          if (x.column === 'start_date') {
            value = start?.format('YYYY-MM-DD') || '';
          } else if (x.column === 'end_date') {
            value = end?.format('YYYY-MM-DD') || '';
          }
          return {
            ...acc,
            [x.column]: value,
          };
        }, {});

      const queryVariables = Object.entries<any>(node.data).reduce((acc, [key, value]) => {
        if (!value?.currentValue) {
          return acc;
        }

        return {
          ...acc,
          [key]: value?.currentValue,
        };
      }, queryVars);

      if (metric.onClickAction === 'externalUrl') {
        const url = compileUrl(cellUrl?.url || '', queryVariables);
        window.open(url, '_blank');
        return;
      }

      onMetricClicked(metric, queryVariables);
    },
    [node, onMetricClicked, metric, cellUrl, isCellClickable, parameters, currentDateRange],
  );

  const onNameClicked = useCallback(() => {
    const entity = node.data?.entity;
    if (!entity || !setActiveTab || entity === 'ad_id') {
      return;
    }

    if (!node.isSelected()) {
      node.setSelected(true, undefined, 'checkboxSelected');
      const toActive = entity === 'campaign_id' ? 'ad_sets' : 'ads';
      setTimeout(() => {
        setActiveTab(toActive);
      }, 100);
    } else {
      node.setSelected(false, undefined, 'checkboxSelected');
    }
  }, [node, setActiveTab]);

  const { valueIsNegative } = metric || {};
  const { currentValue, previousValue } = allValue || {};
  const valueIsString = typeof currentValue === 'string';
  const isService = keyIsService(metricKey);
  const isUpdatableField = updatableFieldsArray.includes(metricKey as UpdatableField);
  const isImage = metricKey.includes('image');
  const isVideo = metricKey.includes('video_url');

  const isStatus = useMemo(() => {
    return (
      metricKey.includes('_status') &&
      (currentValue?.toString() === 'ACTIVE' || currentValue?.toString() === 'PAUSED')
    );
  }, [currentValue, metricKey]);

  const fakeAttributionElement = useMemo(() => {
    let campaignName: string | undefined;
    let adsetName: string | undefined;
    let campaignId: string | undefined;
    let adsetId: string | undefined;

    const { entity, id, name, source, ...rest } = node.data as TableData;
    if (!entity || !id || !name || !source) {
      return null;
    }
    let attEntity: AttributionData['entity'];
    if (entity === 'campaign_id') {
      attEntity = 'campaign';
      campaignName = name;
      campaignId = id;
    } else if (entity === 'ad_set_id') {
      attEntity = 'adset';
      adsetName = name;
      adsetId = id;
    } else if (entity === 'ad_id') {
      attEntity = 'ad';
    }

    const nodeData = node.data;
    if (!nodeData) {
      return null;
    }
    const element: AttributionData = {
      id: id,
      entity: attEntity,
      name,
      serviceId: source,
      campaignName,
      adsetName,
      campaignId,
      adsetId,
    } as AttributionData;

    return element;
  }, [node.data]);

  const isBreakdown =
    metricKey === 'campaign_name' || metricKey === 'ad_set_name' || metricKey === 'ad_name';

  const { deltaDirection, deltaPercentage } = usePreviousPeriodValue(
    metric?.key,
    currentValue,
    previousValue,
    valueIsNegative,
  );

  if (currentValue === 'loading') {
    return (
      <div className="flex items-center h-full">
        <Skeleton width={100} height={20} />
      </div>
    );
  }
  if (currentValue === 'total' && metric?.isDimension) {
    return '';
  }

  const isMainName =
    (activeTab === 'campaigns' && metricKey === 'campaign_name') ||
    (activeTab === 'ad_sets' && metricKey === 'ad_set_name');

  if (isBreakdown && !!breakdownContext && isMainName) {
    const { entity, id, name, source } = node.data as TableData;
    return (
      <div className="flex items-center gap-4">
        <div
          className={`flex flex-col gap-1 ${
            entity === 'campaign_id' || entity === 'ad_set_id' ? 'cursor-pointer' : ''
          }`}
          onClick={onNameClicked}
        >
          <Text size="sm" color="one.5">
            {name}
          </Text>
        </div>
      </div>
    );
  }

  if (
    isUpdatableField &&
    !!currentValue &&
    (breakdownContext || adContext) &&
    fakeAttributionElement?.entity &&
    fakeAttributionElement.id
  ) {
    return (
      <WillyUpdatableField
        value={currentValue?.toString()}
        entity={fakeAttributionElement.entity}
        field={metricKey as UpdatableField}
        id={fakeAttributionElement.id}
      />
    );
  }

  if (isService && valueIsString) {
    const service = allServices[currentValue];
    return (
      <div
        className={`flex items-center gap-4 leading-relaxed ${
          cellUrl ? 'cursor-pointer text-[#0C70F2]' : ''
        }`}
        key={metricKey}
        onClick={onCellClicked}
      >
        {service && service.icon && (
          <span className="not-prose flex">{service.icon({ small: false })}</span>
        )}
        <span className="leading-relaxed">{service?.name || currentValue}</span>
      </div>
    );
  }

  if (isImage) {
    if (!currentValue) {
      return '-';
    }
    return (
      <div className="flex items-center gap-4">
        <TWImage
          src={currentValue?.toString()}
          className="w-full h-full"
          wrapperClass="flex items-center justify-center object-cover max-w-[32px] max-h-[32px] cursor-pointer w-full h-full sm:rounded-md overflow-hidden"
        />
      </div>
    );
  }

  if (isVideo) {
    if (!currentValue) {
      return '-';
    }
    return (
      <div className="inline-flex items-center justify-center p-2 h-full rounded-md bg-slate-200 cursor-pointer">
        <PlayMajor className="fill-logo w-6 flex" />
      </div>
    );
  }

  if (isStatus && fakeAttributionElement) {
    return (
      <div className="flex flex-col gap-1">
        <ToggleStatus
          // disabled
          attribution={{
            ...fakeAttributionElement,
            status: currentValue?.toString() as AttributionData['status'],
          }}
          onStatusChange={(newStatus) => {
            if (
              fakeAttributionElement.entity === 'campaign' ||
              (fakeAttributionElement.entity === 'adset' && breakdownContext === 'ad_set_id') ||
              (fakeAttributionElement.entity === 'ad' && adContext === 'ad_id')
            ) {
              setUpdatedRawData((old) => {
                const indexOfTheEntity = old
                  .find((row) => {
                    if (breakdownContext) {
                      return row.name === breakdownContext;
                    } else if (adContext) {
                      return row.name === adContext;
                    } else {
                      return false;
                    }
                  })
                  ?.value?.findIndex((v) => v === fakeAttributionElement?.id);
                if (indexOfTheEntity === undefined || indexOfTheEntity === -1) {
                  return old;
                }
                const field =
                  breakdownContext === 'campaign_id'
                    ? 'campaign_status'
                    : breakdownContext === 'ad_set_id'
                      ? 'ad_set_status'
                      : 'ad_status';
                const newRawData = old.map((row) => {
                  if (row.name !== field) {
                    return row;
                  }
                  return {
                    ...row,
                    value: row.value.map((v, i) => {
                      if (i === indexOfTheEntity) {
                        return newStatus;
                      }
                      return v;
                    }),
                  };
                });
                return newRawData;
              });
            } else if (
              breakdownContext === 'campaign_id' &&
              fakeAttributionElement.entity === 'adset'
            ) {
              setBreakdownData((old) => {
                if (!old.adsets.data) {
                  return old;
                }
                const indexOfTheEntity = old.adsets.data
                  .find((row) => row.name === 'ad_set_id')
                  ?.value?.findIndex((v) => v === fakeAttributionElement?.id);
                if (indexOfTheEntity === undefined || indexOfTheEntity === -1) {
                  return old;
                }
                const newRawData = old.adsets.data.map((row) => {
                  if (row.name !== 'ad_set_status') {
                    return row;
                  }
                  return {
                    ...row,
                    value: row.value.map((v, i) => {
                      if (i === indexOfTheEntity) {
                        return newStatus;
                      }
                      return v;
                    }),
                  };
                });
                return {
                  ...old,
                  adsets: {
                    ...old.adsets,
                    data: newRawData,
                  },
                };
              });
            } else if (
              (breakdownContext === 'campaign_id' || breakdownContext === 'ad_set_id') &&
              fakeAttributionElement.entity === 'ad'
            ) {
              setBreakdownData((old) => {
                if (!old.ads.data) {
                  return old;
                }
                const indexOfTheEntity = old.ads.data
                  .find((row) => row.name === 'ad_id')
                  ?.value?.findIndex((v) => v === fakeAttributionElement?.id);
                if (indexOfTheEntity === undefined || indexOfTheEntity === -1) {
                  return old;
                }
                const newRawData = old.ads.data.map((row) => {
                  if (row.name !== 'ad_status') {
                    return row;
                  }
                  return {
                    ...row,
                    value: row.value.map((v, i) => {
                      if (i === indexOfTheEntity) {
                        return newStatus;
                      }
                      return v;
                    }),
                  };
                });
                return {
                  ...old,
                  ads: {
                    ...old.ads,
                    data: newRawData,
                  },
                };
              });
            }
          }}
        />
        {/* {fakeAttributionElement.serviceId === 'facebook-ads' && <Text size="xs">Coming soon</Text>} */}
      </div>
    );
  }

  return (
    <>
      <div
        className="flex items-center gap-2 leading-relaxed"
        key={metricKey}
        onClick={onCellClicked}
      >
        <div className={`whitespace-pre ${isCellClickable ? 'cursor-pointer text-[#0C70F2]' : ''}`}>
          {currentValue !== null && formatValue(currentValue)}
          {currentValue === null && <Text size="sm">-</Text>}
        </div>
        {!valueIsString &&
          deltaDirection !== undefined &&
          deltaPercentage !== undefined &&
          !!previousValue && (
            <WillyComparisonValue
              deltaDirection={deltaDirection}
              deltaPercentage={deltaPercentage}
              previousValue={formatValue(previousValue)}
              loading={loadingPreviousPeriod}
            />
          )}
      </div>
    </>
  );
};

const CustomNoRowsOverlay = (props) => {
  const message = 'No data to show';
  return (
    <div className="ag-overlay-loading-center" style={{ height: '9%' }}>
      <i className="fa fa-frown">{message}</i>
    </div>
  );
};

function makeColumnActiveByContext(
  key: string,
  activeTab?: TableTab['id'],
  defaultValue?: boolean,
  breakdownMode?: boolean,
) {
  if (!breakdownMode) {
    return defaultValue;
  }
  if (!activeTab) {
    return defaultValue;
  }

  const campaignColumns = columnsForCampaigns.filter((x) => !entityIdColumns.includes(x)); // ['campaign_name', 'campaign_status']
  const adsetColumns = columnsForAdsets.filter((x) => !entityIdColumns.includes(x)); // ['ad_set_name', 'ad_set_status']
  const adsColumns = columnsForAds.filter((x) => !entityIdColumns.includes(x)); // ['ad_name', 'ad_status', 'ad_image_url', 'video_url', ...]
  let isActive = defaultValue;

  if (isActive === false) {
    return isActive;
  }
  if (adsColumns.includes(key)) {
    isActive = activeTab === 'ads';
  } else if (adsetColumns.includes(key)) {
    isActive = activeTab === 'ad_sets';
  } else if (campaignColumns.includes(key)) {
    isActive = activeTab === 'campaigns';
  }

  return isActive;
}
