import { AdAssetTypeObject } from 'types/general';

export const AdAssetTypes: AdAssetTypeObject = {
  image: {
    key: 'image',
    label: 'Image',
  },
  video: {
    key: 'video',
    label: 'Video',
  },
  body: {
    key: 'body',
    label: 'Body',
  },
  title: {
    key: 'title',
    label: 'Headline',
  },
};
