import db, { FieldValue } from '../utils/DB';
import Api from '../utils/Api';
import { toggleSaveIndicator, setOptimisticImporting } from './actions';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';

import { minimizeSectionOnPress } from './summary';
import axiosInstance from 'utils/axiosInstance';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

export const GORGIAS_CONNECT_ON_PRESS = 'GORGIAS_CONNECT_ON_PRESS';
export const gorgiasConnectOnPress = () => {
  return {
    type: GORGIAS_CONNECT_ON_PRESS,
  };
};

export const TOGGLE_GORGIAS_CONFIG_SCREEN_OPEN = 'TOGGLE_GORGIAS_CONFIG_SCREEN_OPEN';
export const toggleGorgiasConfigScreenOpen = () => ({
  type: TOGGLE_GORGIAS_CONFIG_SCREEN_OPEN,
});

export const GORGIAS_CONFIG_SAVE = 'GORGIAS_CONFIG_SAVE';
export const gorgiasConfigSave = () => {
  return {
    type: GORGIAS_CONFIG_SAVE,
  };
};

export function gorgiasConfigSavePress({ baseApiUrl }) {
  return (dispatch, getState) => {
    const currentShopId = getState().currentShopId;
    toggleSaveIndicator();
    dispatch(gorgiasConfigSave());
    try {
      const url = `v2/gorgias/oauth/login?shopId=${currentShopId}&gorgiasAccountUrl=${baseApiUrl}`;
      axiosInstance
        .get(url)
        .then((res) => {
          window.location.replace(res.data?.url);
        })
        .catch((e) => {
          throw e;
        });
    } catch (e) {
      console.log(` ERROR `, e);
      dispatch(minimizeSectionOnPress('gorgias', false));
    }
  };
}

export function startImportingGorgiasData() {
  return (dispatch) => {
    Api('importGorgiasData');

    return db()
      .update({
        gorgiasInitialized: FieldValue.delete(),
      })
      .then(() => {
        dispatch(setOptimisticImporting('GORGIAS'));
        window.scrollTo(0, 0);
      });
  };
}

export function gorgiasDisconnect() {
  return (dispatch, getState) => {
    toggleSaveIndicator();
    const url = `v2/gorgias/oauth/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('gorgiasAccessToken'));
        dispatch(callRemoveLocalShopProp('gorgiasUsername'));
        dispatch(gorgiasDisconnectDone());
      });
  };
}

export const GORGIAS_DISCONNECT_DONE = 'GORGIAS_DISCONNECT_DONE';
export function gorgiasDisconnectDone() {
  return {
    type: GORGIAS_DISCONNECT_DONE,
  };
}

const gorgiasConnectConfigIsOpen = (state = false, action) => {
  switch (action.type) {
    case GORGIAS_CONNECT_ON_PRESS:
      return true;
    case TOGGLE_GORGIAS_CONFIG_SCREEN_OPEN:
      return !state;
    case GORGIAS_CONFIG_SAVE:
      return false;
    default:
      return state;
  }
};

const isGorgiasConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.gorgiasUsername || !!action.gorgiasAccessToken;
    case GORGIAS_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'GORGIAS' || state;
    default:
      return state;
  }
};

export const reducers = {
  isGorgiasConnected,
  gorgiasConnectConfigIsOpen,
};
