import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const CountdownToast = ({ sec }) => {
  const [countdown, setCountdown] = useState(sec);

  useEffect(() => {
    if (countdown === 0) {
      window.location.reload();
      return;
    }

    const timer = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [countdown]);

  return (
    <div>
      A new version was just deployed!
      <br /> Page will refresh in {countdown} seconds...
    </div>
  );
};

export const showCountdownToast = (sec: number) => {
  toast.warning(<CountdownToast sec={sec} />, {
    position: 'top-center',
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
  });
};
