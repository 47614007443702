import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { lighthousePopupClose } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import React, { useEffect } from 'react';
import { LighthouseClientNotification } from './types';
import SERVICES from '../../constants/services';
import { formatNumber } from 'utils/formatNumber';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import moment from '@tw/moment-cached/module/timezone';
import { useAttributionParams } from 'utils/useAttributionParam';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import TWImage from 'components/library/TWImage/TWImage';
import { BaseColumn } from 'components/library/TWTable/types';
import TWTable from 'components/library/TWTable/TWTable';
import ToggleStatus from 'components/attribution-new/toggleStatus';
import DropDown from 'components/ltv/DropDown';
import { Button, Tooltip } from '@shopify/polaris';
import _db, { Timestamp } from 'utils/DB';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../$stores/$shop';

const dateOptions = [
  { value: 7, label: 'Last 7 days' },
  { value: 14, label: 'Last 14 days' },
  { value: 30, label: 'Last 30 days' },
];

const columns: BaseColumn<
  any,
  'ad' | 'spend' | 'productSpend' | 'roas' | 'pixelRoasWithoutProduct'
>[] = [
  {
    key: 'ad',
    name: 'Ad',
    Heading: () => <div className="text-[#97B2D0]">Ad</div>,
    Value: (ad, metadata) => {
      const { name, serviceId, adId, accountId, status, imageUrl, campaignName, adsetName } = ad;

      const { setData } = metadata || {};
      return (
        <div className="flex items-center gap-3 w-105 p-4 overflow-hidden">
          <span>
            <ToggleStatus
              attribution={
                {
                  entity: 'ad',
                  id: adId,
                  serviceId: serviceId,
                  status: status,
                  accountId: accountId,
                } as any
              }
              setData={setData}
            />
          </span>
          <span className="flex items-center">{SERVICES[serviceId]?.icon?.(true)}</span>
          {imageUrl && (
            <span>
              <TWImage
                src={imageUrl}
                width={20}
                height={20}
                className="rounded-md flex items-center"
              />
            </span>
          )}
          <Tooltip
            content={`${campaignName ? 'Campaign: ' + campaignName + ', ' : ''}${
              adsetName ? 'Adset: ' + adsetName : name
            }`}
          >
            <span>{name}</span>
          </Tooltip>
        </div>
      );
    },
    sortable: false,
    dataType: 'text',
  },
  {
    key: 'spend',
    name: 'Ad Spend',
    Heading: () => <div className="text-[#97B2D0]">Ad Spend</div>,
    Value: (ad, { currency }) => {
      return (
        <div className="p-4">
          {formatNumber(ad.fullAdSpend, {
            currency: currency,
            minimumFractionDigits: metrics.spend?.minimumFractionDigits || 0,
            maximumFractionDigits: metrics.spend?.toFixed || 2,
            style: 'currency',
          })}
        </div>
      );
    },
    sortable: false,
    dataType: 'numeric',
  },
  {
    key: 'productSpend',
    name: 'Product Spend',
    Heading: () => <div className="text-[#97B2D0]">Product Spend</div>,
    Value: (ad, { currency }) => {
      return (
        <div className="p-4">
          {formatNumber(ad.productSpend, {
            currency: currency,
            minimumFractionDigits: metrics.spend?.minimumFractionDigits || 0,
            maximumFractionDigits: metrics.spend?.toFixed || 2,
            style: 'currency',
          })}
        </div>
      );
    },
    sortable: false,
    dataType: 'numeric',
  },
  {
    key: 'roas',
    name: 'Ad ROAS',
    Heading: () => <div className="text-[#97B2D0]">Ad ROAS</div>,
    Value: (ad) => {
      return (
        <div className="p-4">
          {formatNumber(ad.fullAdPixelRoas, {
            minimumFractionDigits: metrics.pixelRoas?.minimumFractionDigits || 0,
            maximumFractionDigits: metrics.pixelRoas?.toFixed || 2,
            style: 'decimal',
          })}
        </div>
      );
    },
    sortable: false,
    dataType: 'numeric',
  },
  {
    key: 'pixelRoasWithoutProduct',
    name: 'ROAS without Product',
    Heading: () => <div className="text-[#97B2D0]">ROAS without Product</div>,
    Value: (ad) => {
      return (
        <div className="p-4">
          {formatNumber(ad.pixelRoasWithoutProduct, {
            minimumFractionDigits: metrics.pixelRoas?.minimumFractionDigits || 0,
            maximumFractionDigits: metrics.pixelRoas?.toFixed || 2,
            style: 'decimal',
          })}
        </div>
      );
    },
    sortable: false,
    dataType: 'numeric',
  },
];

export const LighthouseInventoryAlert: React.FC<LighthouseClientNotification> = ({
  extraData,
  Icon,
}) => {
  const dispatch = useAppDispatch();
  const { title, productTitle, id, inventoryItem, sales, daysInStock = 0 } = extraData || {};

  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const [window, setWindow] = React.useState(7);
  const [ads, setAds] = React.useState<any[]>([]);
  const [loadingAds, setLoadingAds] = React.useState(false);

  const attributionParams = useAttributionParams();

  useEffect(() => {
    (async () => {
      if (!attributionParams) return;
      const idKey = inventoryItem === 'variant' ? 'variantIds' : 'productIds';

      const statsParams = {
        ...attributionParams,
        model: 'lastPlatformClick-v2',
        dateModel: 'eventDate',
        breakdown: 'adId',
        startDate: moment().subtract(window, 'days').format(),
        endDate: moment().format(),
        granularity: 'day',
        [idKey]: [id],
      };

      setLoadingAds(true);
      try {
        const { data: adsResponse } = await axiosInstance.post(
          '/v2/attribution/get-product-stats',
          statsParams,
        );
        setAds((adsResponse.productStats?.[0]?.ads || []).map((x) => ({ ...x, id: x.adId })));
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingAds(false);
      }
    })();
  }, [currentShopId, id, inventoryItem, window, attributionParams]);

  const currency = useStoreValue($shopCurrency);

  return (
    <DraggableModal
      onClose={() => {
        dispatch(lighthousePopupClose());
      }}
      open={true}
      width="medium"
      style={{
        backgroundColor: '#11283E',
        border: '1px solid rgba(192, 214, 234, 0.1)',
      }}
      className="min-h-[510px]"
    >
      <div className="flex flex-col gap-6.5 py-6.5 sm:rounded-[8px] text-white w-full">
        <div className="flex items-center gap-4 px-6.5">
          <div className="w-16 h-16">{Icon(40)}</div>
          <div className="flex flex-col text-3xl">
            {productTitle} - {title}
            <span className=" text-xl text-[#97B2D0]">
              <span>A low inventory event is on the horizon</span>
              <span className="text-white font-bold"> ({daysInStock?.toFixed?.(0)} days left)</span>
            </span>
          </div>
        </div>
        {/* <div className="h-[160px] tw-scroll border border-x-0 border-solid border-[#2E4E65] bg-[#0D1F31] px-6.5">

        </div> */}
        <div className="flex flex-col px-6.5">
          <div className="my-8">
            Without the contribution of{' '}
            <span className="bg-logo/15 text-logo font-medium px-2 py-1 align-middle rounded-sm">
              {productTitle} - {title}
            </span>{' '}
            you can expect your revenue to drop by{' '}
            <span className="bg-logo/15 text-logo font-medium px-2 py-1 align-middle rounded-sm">
              {formatNumber(sales, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                currency,
                style: 'currency',
              })}
            </span>{' '}
            trailing 30 days.
          </div>
          <div className="flex justify-between gap-8">
            <div className="flex flex-col gap-2">
              <span className="text-xl">Linked Ads</span>
              <span className=" text-lg text-[#97B2D0]">
                Ads that should be turned off when out of stock
              </span>
            </div>
            <DropDown
              options={dateOptions}
              value={window}
              handleSelect={(value) => setWindow(value)}
              theme="dark"
            />
          </div>
        </div>
        <div className="px-6.5 flex flex-col gap-4 flex-auto">
          {/*  max-h-[300px] overflow-y-auto tw-scroll */}
          <TWTable
            loading={loadingAds}
            columns={columns}
            data={ads}
            metadata={{ currency, setData: setAds }}
            stickyColumnIndex={0}
            stickyHeader
            padding="0px"
            dark
            height={300}
          />
          <div className="flex mt-auto">
            <Button
              fullWidth={false}
              onClick={() => {
                dispatch(lighthousePopupClose());
                if (!id) return;
                _db().collection('lighthouse_ignored_inventory').doc(id?.toString()).set(
                  {
                    updatedAt: Timestamp.now(),
                  },
                  {
                    merge: true,
                  },
                );
              }}
            >
              Ignore this item in the future
            </Button>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};
