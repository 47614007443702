import moment from '@tw/moment-cached/module/timezone';
import { Flex, Icon, Text } from '@tw/ui-components';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useActiveRoute } from '$stores/nav-config-stores';
import { forwardRef } from 'react';

const TopBreadcrumbs = forwardRef<HTMLDivElement, any>((_props, ref) => {
  const { activeSection, activeRoute } = useActiveRoute();
  const summaryLastRefresh = useSelector((state: RootState) => state.summaryLastRefresh);

  // there's parent and child routes, let's render both
  const sectionWithChildren = !!activeSection?.title;

  const { title } = activeSection || {};
  const { label, lastUpdate: LastUpdate } = activeRoute || {};

  return (
    <Flex ref={ref} direction="column" justify="space-between" miw="max-content">
      <Flex align="center">
        <Text span fz="sm" lh={1}>
          {title || label}
        </Text>
        {sectionWithChildren && (
          <>
            <Icon name="caret-right" />
            <Text span fz="sm">
              {label}
            </Text>
          </>
        )}
      </Flex>
      <Text fz="xs">
        Last updated:
        {LastUpdate ? <LastUpdate /> : moment(summaryLastRefresh).local().format('LT')}
      </Text>
    </Flex>
  );
});

export default TopBreadcrumbs;
