import { Reducer, combineReducers } from 'redux';
import { getUserEmail } from 'utils/DB';
import axiosInstance from 'utils/axiosInstance';

const GET_HUBSPOT_COMPANY = 'GET_HUBSPOT_COMPANY';
export const loadHsCompany = (currentShopId: string) => {
  return async (dispatch) => {
    const url = `/v2/hubspot-internal/hubspot-integration/get-company-by-shop/${currentShopId}`;
    const res = (await axiosInstance.get(url, { headers: { email: getUserEmail() } })) || {};
    dispatch({
      type: GET_HUBSPOT_COMPANY,
      payload: res.data,
    });
  };
};

const hubSpotCompany: Reducer<any> = (state = {}, action) => {
  switch (action.type) {
    case GET_HUBSPOT_COMPANY:
      return action.payload || state;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  hubSpotCompany,
});
