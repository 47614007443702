import Attribution from 'pages/Attribution/Attribution';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { SettableAttributionPage } from './types';
import LockedPageTemplate from 'pages/Attribution/LockedPageTemplate';
import firstImage from './assets/reports.svg';
import secondSectionImage from './assets/dropdown.png';
import quateImage from './assets/quote.png';
import logo from './assets/logo.png';
const AttributionAllPageComponent = ({ setDashboardPixelData }: SettableAttributionPage) => {
  return <Attribution sourceCategory="all" setDashboardPixelData={setDashboardPixelData} />;
};

const AttributionAllPage = computeFeatureFlags(
  FeatureFlag.PIXEL_ALL_FF,
  AttributionAllPageComponent,
  () => (
    <LockedPageTemplate
      badgeText="Pixel"
      bannerPageName="attribution"
      mainTitle="First-party data you can trust"
      mainDescription="Harness the power of first-party data with the Triple Pixel — providing more accurate attribution to fuel profitable growth."
      descriptionMaxWidth="400px"
      firstImage={firstImage}
      secondTitle="Models for every strategy"
      secondSectionImage={secondSectionImage}
      sections={[
        {
          subtitle: 'End-to-end customer journey tracking',
          description:
            'Capture every customer interaction with our advanced tracking technology, ensuring no opportunity is missed.',
        },
        {
          subtitle: 'Recognize new vs returning customers',
          description:
            'Triple Whale’s pixel makes it simple to identify new versus returning visitors and optimize campaigns for each.',
        },
        {
          subtitle: 'Understand the Total Impact of your marketing',
          description:
            'Combine multi-touch attribution and post-purchase survey data using our Total Impact attribution model — for a holistic understanding of your marketing mix.',
        },
      ]}
      secondSectionTitleMargin="40px"
      quote="At MaryRuth’s, we are big advocates of Total Impact. Not just because it made the numbers look better, but because it really aligned - I can’t say that enough - with everything we were intuitively and subconsciously knowing. We knew things the data wasn’t showing. We knew the reason it wasn’t showing is because the data is so complex and the way we measured was too simple. It really helped us."
      quoteAuthor="Wyatt Lowe"
      quoteAuthorTitle="Director of Digital Growth, MaryRuth’s"
      logo={logo}
      quateImage={quateImage}
    />

    // <UpgradePageFallBack
    // InAppContextBannerEnabled={true}
    // InAppContextBannerPage={'attribution'}
    // // featureFlag={FeatureFlag.PIXEL_ALL_FF}
    // />
  ),
);

export default AttributionAllPage;
