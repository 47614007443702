import { TextField, Text, DatePicker, Popover } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import { Timeline, Modal, Button, Flex } from '@tw/ui-components';
import { costOnChangeHistory } from 'ducks/products';
import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import { saveShippingProfileData } from 'ducks/shipping';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../$stores/$shop';

const CogsHistory = ({
  selectedRow,
  getCurrencySymbol,
  defaultCurrency,
  isOpen,
  onClose,
  onSave = (par?: any) => {},
  service,
  type,
  costType = '',
  title = 'Cost History',
}) => {
  const [costs, setCosts] = useState(
    costType ? selectedRow?.[costType] : selectedRow?.[type] ?? [],
  );
  const earliestDate = useSelector((state: RootState) => state.earliestDate);
  const currency = useStoreValue($shopCurrency);
  const dispatch = useAppDispatch();
  const [diff, setDiff] = useState([]);

  useEffect(() => {
    setCosts(costType ? selectedRow?.[costType] : selectedRow?.[type] ?? []);
  }, [costType, selectedRow, type, isOpen]);

  const cogType = {
    shopify: { cogName: 'value', cogObj: { value: 0 } },
    amazon: { cogName: 'Amount', cogObj: { Amount: 0, CurrencyCode: defaultCurrency } },
  };

  const deleteCost = (cost) => {
    setCosts(costs.filter((c) => c !== cost));
    const diffArr: any = diff ? [...diff] : [];
    diffArr.push({ cost, type: 'delete' });
    setDiff(diffArr);
  };
  const handleChange = useCallback(
    (val, i, type) => {
      const newCosts = [...costs];
      if (type !== 'date') {
        if (+val < 0) return;
        newCosts[i][type] = +val;
      } else {
        newCosts[i][type] = val;
        const diffArr: any = diff ? [...diff] : [];
        diffArr.push({ cost: newCosts[i], type: 'add' });
        setDiff(diffArr);
      }
      setCosts(newCosts);
    },
    [costs, diff],
  );
  const addNewCost = () => {
    const newCosts = costs ? [...costs] : [];
    newCosts.unshift(cogType[service].cogObj);
    setCosts(newCosts);
  };

  const saveCostHistory = async () => {
    try {
      if (service === 'amazon') {
        const obj = {
          marketplaceId: selectedRow.marketplaceId,
          selling_partner_id: selectedRow.selling_partner_id,
          sku: selectedRow.sku,
          [type]: costs,
        };
        await axiosInstance.post(`/v2/amazon/client/products`, {
          items: [obj],
          type: 'history',
        });
        await onSave();
      } else if (service === 'shopify') {
        if (type === 'shipping_profile') {
          onSave({ costs });
        } else {
          dispatch(costOnChangeHistory(selectedRow, costs, type, costType, diff));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const NewCostRow = ({ index }) => {
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const today = new Date();
    const [{ month, year }, setDate] = useState({
      month: today.getMonth(),
      year: today.getFullYear(),
    });

    function handleMonthChange(month, year) {
      setDate({ month, year });
    }

    const isSameDay = (dateToCheck, actualDate) =>
      dateToCheck.getDate() === actualDate.getDate() &&
      dateToCheck.getMonth() === actualDate.getMonth() &&
      dateToCheck.getFullYear() === actualDate.getFullYear();

    const isSameMinute = (dateToCheck, actualDate) =>
      isSameDay(dateToCheck, actualDate) &&
      dateToCheck.getHours() === actualDate.getHours() &&
      dateToCheck.getMinutes() === actualDate.getMinutes();

    function handleDateSelection({ end: newSelectedDate }, index) {
      const today = new Date();
      if (isSameDay(newSelectedDate, today)) newSelectedDate = today;
      if (
        costs.some((cost) => isSameMinute(newSelectedDate, new Date(cost.date))) ||
        newSelectedDate < new Date('01/01/2000')
      )
        return;
      setSelectedDate(newSelectedDate);
      setDatePickerOpen(false);
      handleChange(newSelectedDate, index, 'date');
    }

    const activator = (
      <div className="w-[100px]">
        <TextField
          role="combobox"
          label=""
          placeholder="MM/DD/YY"
          onFocus={() => setDatePickerOpen(true)}
          autoComplete="off"
          value={selectedDate && moment(selectedDate).format('MM/DD/YY')}
        />
      </div>
    );
    return (
      <Popover
        active={isDatePickerOpen}
        activator={activator}
        onClose={() => setDatePickerOpen(false)}
      >
        <div className="w-[250px] p-[12px]">
          <DatePicker
            month={month}
            year={year}
            onMonthChange={(month, year) => {
              handleMonthChange(month, year);
            }}
            onChange={(val) => {
              handleDateSelection(val, index);
            }}
            disableDatesAfter={moment().toDate()}
            selected={today}
          />
        </div>
      </Popover>
    );
  };

  const costValueElement = (costHistory, i) => (
    <div className="flex flex-col">
      <div className="h-[25px] w-0 border-l-0 border border-dashed border-gray-300 ml-[44px] mb-2"></div>
      <div className="flex justify-between">
        <div className="w-[100px]">
          <TextField
            label=""
            value={costHistory?.[cogType[service].cogName]}
            onChange={(val) => handleChange(Number(val), i, cogType[service].cogName)}
            onBlur={() => {
              let newCosts = [...costs];
              const diffArr: any = diff ? [...diff] : [];
              diffArr.push({ cost: newCosts[i], type: 'update' });
              setDiff(diffArr);
              newCosts.sort((a, b) => (Date.parse(a.date) > Date.parse(b.date) ? -1 : 1));
              setCosts(newCosts);
            }}
            prefix={
              (costType === 'costs' && type != 'shipping_profile') || costType === 'cogs_percent'
                ? '%'
                : getCurrencySymbol(costHistory?.CurrencyCode ?? currency)
            }
            autoComplete="false"
            type="number"
          />
        </div>
        <Button
          px="sm"
          onClick={() => {
            deleteCost(costHistory);
          }}
          variant="white"
        >
          <DeleteMinor className="!fill-red-600" width={20} />
        </Button>
      </div>
      <div className="h-[25px] w-0 border-l-0 border border-dashed border-gray-300 ml-[44px] mt-2 -mb-[20px]"></div>
    </div>
  );

  return (
    <Modal
      opened={isOpen}
      title={title}
      onClose={() => {
        onClose();
        setCosts([]);
        setDiff([]);
      }}
    >
      <div className="mb-[14px]">
        <Text as="span" variant="bodySm" truncate={true} fontWeight="medium">
          {selectedRow?.product_name}
          {type === 'tw' &&
            'Please note it will take up to 24 hours to update your orders with these changes.'}
        </Text>
      </div>
      <Timeline active={costs?.length + 1} color="one.5" bulletSize={14}>
        <Timeline.Item title={<Button onClick={() => addNewCost()}>+ Add Cost</Button>}>
          {costValueElement(costs?.[0], 0)}
        </Timeline.Item>
        {costs
          ?.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
          ?.map((costHistory, i) => {
            const isLastItem = i === costs.length - 1;
            return (
              <Timeline.Item
                key={`${costHistory?.date}_${i}`}
                title={
                  <div key={i}>
                    <div>
                      {costHistory?.date ? (
                        <div className="leading-[18px]">
                          <div className="font-medium">
                            {moment(costHistory.date).format('MMM DD, YYYY')}
                          </div>
                          <div className=" text-lg">
                            {moment(costHistory.date).format('hh:mm A')}
                            {isLastItem && (
                              <span className="text-gray-400 pl-3">
                                {`This value applies to all historical orders (since ${
                                  earliestDate
                                    ? moment(earliestDate).format('MMM DD, YYYY')
                                    : 'first date in TW'
                                })`}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <NewCostRow index={i} />
                      )}
                    </div>
                  </div>
                }
                bullet={<div className="w-[14px] h-[14px] rounded-full bg-[#0C70F2]"></div>}
              >
                {!isLastItem && costValueElement(costs[i + 1] ?? costHistory, i + 1)}
              </Timeline.Item>
            );
          })}
      </Timeline>
      <div className="w-full h-[1px] bg-[#E5E7EB] my-5"></div>
      <Flex justify="end">
        <div className="flex gap-4">
          <Button
            variant="white"
            onClick={() => {
              onClose();
              setCosts([]);
              setDiff([]);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveCostHistory();
              setDiff([]);
              onClose();
            }}
            disabled={costs?.some((cost) => !cost.date)}
          >
            {type === 'shipping_profile' ? 'Continue' : 'Save'}
          </Button>
        </div>
      </Flex>
    </Modal>
  );
};

export default CogsHistory;
