import { $observer, $store } from '@tw/snipestate';

export const $windowWidth = $observer<number>(window.innerWidth, (_, set) => {
  const handleResize = () => set(window.innerWidth);
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
});

/* NAV OPEN STATE - used when switching between desktop and mobile navs */
export const $navOpen = $store(false);

/* OPEN NAV SECTION - holds title of current open nav section */
export const $openNavSection = $store('');

/* LAST SETTINGS ROUTE */
export const $lastSettingsRoute = $store('/store-settings');
