import { useAppSelector } from '../../../../reducers/RootType';
import { useEffect, useMemo } from 'react';
import { getSocket, openAiStopSequence } from '../../../../components/Willy/WillySocket';
import { sqlBuilderAi, WillySocket } from '../../../../components/Willy/types/willyTypes';
import { v4 as uuidv4 } from 'uuid';
import { $tabs } from '$stores/willy/$tables';

export const setSqlMessageId = (messageId: string) => {
  $tabs.set((old) => {
    return old.map((t) => {
      if (t.active) {
        return {
          ...t,
          sqlBuilderAI: {
            ...(t.sqlBuilderAI ?? {}),
            messageId,
          },
        };
      }
      return t;
    });
  });
};

export const setSqlStatusByMessageId = (messageId: any, status: Partial<sqlBuilderAi>) => {
  $tabs.set((old) => {
    return old.map((t) => {
      if (t.sqlBuilderAI?.messageId === messageId) {
        return {
          ...t,
          sqlBuilderAI: {
            ...(t.sqlBuilderAI ?? {}),
            ...status,
          },
        };
      }
      return t;
    });
  });
};

export const resetSqlStatusByMessageId = (messageId: any) => {
  $tabs.set((old) => {
    return old.map((t) => {
      if (t.sqlBuilderAI?.messageId === messageId) {
        return {
          ...t,
          sqlBuilderAI: undefined,
        };
      }
      return t;
    });
  });
};

export const createSqlFromAI = (shopId: string, willySocket: WillySocket) => {
  const messageId = uuidv4();
  setSqlMessageId(messageId);
  const activeTab = $tabs.get().find((t) => t.active);
  if (!activeTab) {
    return;
  }
  const activeEditorTab = activeTab.model?.id as string;
  const tabs = $tabs.get();
  const existingQuery = tabs.find((t) => t.model?.id === activeEditorTab)?.query;
  if (!existingQuery) {
    return;
  }
  const schema = activeTab.schema;
  const filterBuilder = activeTab.filterBuilder;
  setSqlStatusByMessageId(messageId, { loading: true, existingQuery });
  willySocket.emit('build-sql', {
    shopId,
    existingQuery,
    table: schema.find((t) => t.columns.some((c) => c.selected))!,
    filterBuilder,
    id: messageId,
  });
};

export const useAISqlBuilderForFilter = () => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const willySocket = useMemo(() => getSocket(), []);

  useEffect(() => {
    const handleInsight = (data) => {
      const { messageId, shopId, text } = data;
      const selectedTab = $tabs.get().find((t) => t.sqlBuilderAI?.messageId === messageId);
      if (selectedTab && shopId === currentShopId) {
        // if (text === openAiStopSequence) {
        //   setSqlStatusByMessageId(messageId, { streaming: false });
        //   return;
        // }
        // setSqlStatusByMessageId(messageId, { streaming: true });
        if (text.includes('error:')) {
          setSqlStatusByMessageId(messageId, { error: text.replace('error:', '').trim() });
        } else {
          setSqlStatusByMessageId(messageId, { text });
        }
        setSqlStatusByMessageId(messageId, { loading: false });
      }
    };

    const handleError = (error, messageId) => {
      setSqlStatusByMessageId(messageId, { error });
    };

    willySocket.on('error', handleError);
    willySocket.on('insight', handleInsight);

    return () => {
      willySocket.off('insight', handleInsight);
    };
  }, [currentShopId, willySocket]);
};
