import { ReactNode } from 'react';
import { TWTooltip } from './TWTooltip';
import { ReactComponent as InfoIcon } from '../../../icons/info.svg';

interface Props {
  tooltipContent?: ReactNode;
  iconSize?: number;
  tooltipMaxWidth?: number;
}
export const TWInfoTooltip: React.FC<Props> = ({
  tooltipContent,
  tooltipMaxWidth,
  iconSize = 10,
}) => {
  return (
    <TWTooltip content={tooltipContent} maxWidth={tooltipMaxWidth}>
      <InfoIcon width={iconSize} fill="#374151" />
    </TWTooltip>
  );
};
