import { useEffect, useState } from 'react';
import { MetricsKeys } from 'types/metrics';
import { metrics as metricsDictionary } from 'constants/metrics/metrics';

export const useShowTheRightAxis = (metrics: MetricsKeys[]) => {
  const [showRightAxis, setShowRightAxis] = useState(false);

  useEffect(() => {
    const someOfTheMetricsHasTwoTypes = metrics.reduce<boolean | string>((acc, metric) => {
      const metricData = metricsDictionary[metric];

      if (!metricData) {
        return false;
      }

      const { format } = metricData;

      if (format === acc) {
        return false;
      }

      return format;
    }, false);

    setShowRightAxis(!!someOfTheMetricsHasTwoTypes);
  }, [metrics]);

  return showRightAxis;
};
