import { isLocal } from '@tw/constants';
import { type OnlyOne, TWSpiceResources } from '@tw/types';
import axiosInstance from 'utils/axiosInstance';
import { datadogLogEvent } from 'utils/dataLayer';

const permissionsService = 'v2/charif/permission';
type CheckPermissionsMatrixParams = {
  resourceType: (typeof TWSpiceResources)[number];
  subjectType?: string;
  subjectId?: string;
  subjectIds?: [string, ...string[]];
} & OnlyOne<{
  permission: string;
  permissions: [string, ...string[]];
}> &
  OnlyOne<{
    resourceId: string;
    resourceIds: [string, ...string[]];
  }>;

type PermissionsMatrixResponse<K extends string> = PermissionsResponse<K>[];

export async function checkPermissionsMatrix<K extends string = string>(
  params: CheckPermissionsMatrixParams,
): Promise<PermissionsMatrixResponse<K>> {
  if (!params.resourceId && !params.resourceIds) {
    throw new Error('resourceId or resourceIds must be provided');
  }
  if (!params.permission && !params.permissions) {
    throw new Error('permission or permissions must be provided');
  }

  try {
    const res = await axiosInstance.post(`${permissionsService}/multi`, params);
    return res.data;
  } catch (error) {
    if (error.response?.status === 403) {
      return [
        {
          [params.permission || params.permissions![0]]: false,
          resourceId: params.resourceId || params.resourceIds![0],
          subjectId: params.subjectId || params.subjectIds![0],
        },
      ] as PermissionsMatrixResponse<K>;
    }
    if (error.response?.data) {
      return error.response.data;
    }
    throw error;
  }
}

export type CheckPermissionsParams = {
  resourceType: (typeof TWSpiceResources)[number];
  resourceId: string;
  subjectType?: string;
  subjectId?: string;
} & OnlyOne<{
  permission: string;
  permissions: [string, ...string[]];
}>;

export type PermissionsResponse<K extends string> = {
  resourceType: string;
  resourceId: string;
  subjectType: string;
  subjectId: string;
} & { [key in K]: boolean };

export async function checkPermissions<K extends string = string>(
  params: CheckPermissionsParams,
): Promise<PermissionsResponse<K>> {
  if (!params.resourceId) {
    throw new Error('resourceId must be provided');
  }
  if (!params.permission && !params.permissions) {
    throw new Error('permission or permissions must be provided');
  }
  try {
    const res = await axiosInstance.post(`${permissionsService}/multi`, params);
    return res.data[0];
  } catch (error) {
    if (error.response?.status === 403) {
      return {
        [params.permission || params.permissions![0]]: false,
        resourceId: params.resourceId,
        subjectId: params.subjectId || 'current user id',
      } as PermissionsResponse<K>;
    }
    if (error.response?.data) {
      return error.response.data;
    }
    throw error;
  }
}

type CheckPermissionParams = {
  resourceType: (typeof TWSpiceResources)[number];
  resourceId: string;
  subjectType?: string;
  subjectId?: string;
  permission: string;
};

export async function checkPermission(params: CheckPermissionParams): Promise<boolean> {
  if (!params.resourceId) {
    throw new Error('resourceId must be provided');
  }
  if (!params.permission) {
    throw new Error('permission must be provided');
  }
  try {
    const res = await axiosInstance.post(permissionsService, params);
    return res.status === 200;
  } catch (error) {
    if (error.response?.status === 403) {
      return false;
    }
    throw error;
  }
}

export function assertPermissions(expected: any, actual: any, logFrequency: number = 0) {
  if (actual.loading || actual.error) {
    return;
  }
  const mismatch = Object.keys(expected).some((key) => {
    return expected[key] !== actual[key];
  });
  if (mismatch) {
    if (isLocal) {
      console.log('Permissions mismatch', { expected, actual });
    }
    const payload = Math.random() < logFrequency ? { expected, actual } : {};
    datadogLogEvent('permissionsMismatch', payload);
  }
}
