import { Checkbox, Collapse, Flex, Box, SegmentedControl, Text } from '@tw/ui-components';
import { GridColumnOptions, GridOptions, TileModes } from '../types/willyTypes';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { EditorCollapse } from './EditorCollapse';

type TileConfigurationProps = {
  grid: GridOptions;
  setGrid: (grid: GridOptions) => void;
  gridColumns?: GridColumnOptions;
  setGridColumns?: (gridColumns: GridColumnOptions) => void;
  tileMode?: TileModes;
  setTileMode?: (tileMode: TileModes) => void;
  twoColumnMobile?: boolean;
  setTwoColumnMobile?: (twoColumnMobile: boolean) => void;
};
export const TileConfiguration: React.FC<TileConfigurationProps> = ({
  grid,
  setGrid,
  gridColumns,
  setGridColumns,
  tileMode,
  setTileMode,
  twoColumnMobile,
  setTwoColumnMobile,
}) => {
  const isSmall = useIsSmall();

  return (
    <EditorCollapse title="Tile Configuration" px="md">
      {!isSmall ? (
        <Flex direction="column" gap="md" pt="sm">
          <Flex justify="space-between" align="center">
            <Text size="sm">Tiles Layout</Text>
            <Box w="50%">
              <SegmentedControl
                size="sm"
                data={[
                  { label: 'Flex', value: 'flex' },
                  { label: 'Grid', value: 'grid' },
                ]}
                value={grid}
                onChange={(v) => setGrid(v as GridOptions)}
                fullWidth
                withItemsBorders={false}
              />
            </Box>
          </Flex>
          <Collapse in={grid === 'grid'}>
            <Flex align="center" justify="space-between">
              <Text size="xs">Columns</Text>
              <Box w="50%">
                <SegmentedControl
                  size="xs"
                  data={[
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                  ]}
                  value={`${gridColumns}` ?? '2'}
                  onChange={(v) => setGridColumns?.(+v as GridColumnOptions)}
                  fullWidth
                  withItemsBorders={false}
                />
              </Box>
            </Flex>
          </Collapse>
          <Flex justify="space-between" align="center">
            <Text size="sm">Tiles Mode</Text>
            <Box w="50%">
              <SegmentedControl
                size="sm"
                data={[
                  { label: 'Cards', value: 'tile' },
                  { label: 'Table', value: 'table' },
                ]}
                value={tileMode}
                onChange={(v) => setTileMode?.(v as TileModes)}
                fullWidth
                withItemsBorders={false}
              />
            </Box>
          </Flex>
        </Flex>
      ) : (
        <Checkbox
          label="Two Columns"
          checked={twoColumnMobile}
          onChange={(checked) => setTwoColumnMobile?.(checked)}
        />
      )}
    </EditorCollapse>
  );
};
