import { ActionIcon, Flex, Icon, Image, Popover, Text, TextInput } from '@tw/ui-components';
import { getFormattedShopName, goToStore } from 'components/Nav/utils';
import { useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import classes from './SearchStorePopover.module.scss';
import { useAppDispatch } from 'index';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { ShopNavItem } from '../../navs/StoresNav/types';

export function SearchStorePopover() {
  const textRef = useRef<HTMLInputElement>(null);
  const shopsAsArray = useAppSelector((state) => state.shopsAsArray);

  const [input, setInput] = useState('');
  const controlledShopArray = useMemo(
    () =>
      !input
        ? shopsAsArray
        : shopsAsArray.filter(
            (shop) => shop.shopName?.includes(input) || shop.shopId.includes(input),
          ),
    [input, shopsAsArray],
  );

  return (
    <Popover
      withinPortal
      keepMounted
      position="left-start"
      width={300}
      onOpen={() => {
        setInput('');
        setTimeout(() => textRef.current?.focus(), 50);
      }}
    >
      <Popover.Target>
        <div>
          <ActionIcon radius="sm" size="lg" icon="search-major" variant="activator" />
        </div>
      </Popover.Target>
      <Popover.Dropdown
        //@ts-ignore
        className="dark:bg-[--gray-dark-mode-700] bg-white"
        style={{
          border: TOP_BAR_BORDER_VAR,
          padding: 6,
        }}
      >
        <Flex direction="column" gap="xs">
          <TextInput
            ref={textRef}
            w="100%"
            value={input}
            onChange={setInput}
            placeholder="Search store"
            leadingIcon={<Icon name="search-major" size={14} />}
          />
          <Shops shops={controlledShopArray} />
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
}

const Shop: React.FC<ShopNavItem> = ({ shopId, shopName, shopImage }) => {
  const dispatch = useAppDispatch();

  const formattedShopName = useMemo(
    () => getFormattedShopName(shopName || shopId || '', { capitalize: false, length: 30 }),
    [shopId, shopName],
  );

  return (
    <Flex gap="xs" onClick={() => goToStore(dispatch, shopId)}>
      <Image src={shopImage} width={25} radius="sm" />
      <Text>{formattedShopName}</Text>
    </Flex>
  );
};

const Shops: React.FC<{ shops: ShopNavItem[] }> = ({ shops }) => {
  return (
    <div className={classes.shopsWrapper}>
      {shops.map((shop) => (
        <Shop key={shop.shopId} {...shop} />
      ))}
    </div>
  );
};
