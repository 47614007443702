import CSVExport from 'utils/CSVExport';
import { formatToFixed } from 'utils/formatNumber';
import allServices from 'constants/services';

interface IFormatAndExportCsvProps {
  data: any[];
  columns: any[];
  keyMap?: any;
  fileName: string;
  isExportCsv?: boolean;
}

export const formatAndExportCsv = ({
  data,
  columns,
  keyMap = {},
  fileName,
  isExportCsv = true,
}: IFormatAndExportCsvProps) => {
  const csvData = data?.map((item) => {
    let obj = {};
    const { id, body, segmentTitle, assetType, metrics } = item || {};
    columns?.forEach((column) => {
      const { key: originalKey, name } = column;
      let key = originalKey;

      if (typeof keyMap[originalKey] === 'string') {
        key = keyMap[originalKey];
      } else if (typeof keyMap[originalKey] === 'function') {
        obj[name] = keyMap[originalKey](item);
        return;
      }

      if (metrics) {
        if (key === 'showInGraph') return;
        if (
          key.toLowerCase() === 'product' ||
          key.toLowerCase() === 'preview' ||
          key.toLowerCase() === 'segmenttitle' ||
          key.toLowerCase() === 'copy'
        ) {
          const colTitle = key.toLowerCase() === 'preview' ? 'Item' : name;
          const colValue = item?.name ?? id ?? body ?? segmentTitle ?? assetType;
          obj[colTitle] = colValue;
          return;
        }
        if (key.toLowerCase() === 'link') {
          obj[name] = item?.productUrl || 'n/a';
          return;
        }
        if (Object.keys(item).includes(key)) {
          obj[name] = item[key];
          return;
        }
        const metric = metrics[key];
        const value = formatToFixed(metric ?? item[key]);
        obj[name] = value || 0;
      } else {
        const { key, heading } = column;
        if (key === 'showInGraph') return;
        if (key.toLowerCase() === 'link') {
          obj[heading] =
            allServices[item?.serviceId]?.externalAdsUrl?.(
              item?.serviceId,
              item?.serviceId === 'google-ads' ? 'campaign' : 'ad',
              item?.accountId,
              undefined,
              undefined,
              item?.adId,
            ) || 'n/a';
          return;
        }
        const value = formatToFixed(item[key], 2);
        if (value === undefined) return;
        obj[heading] = value;
      }
    });

    return obj;
  });

  isExportCsv && CSVExport(csvData, fileName);
  const headers = Object.keys(csvData[0]);
  const csv2DArray = [headers, ...csvData.map((row) => Object.values(row))];
  return csv2DArray;
};
