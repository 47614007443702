import { FeatureFlagMetaData } from '@tw/feature-flag-system/module/types';
import { assertValidMetadata } from '@tw/feature-flag-system/module/utils';
import axiosInstance from 'utils/axiosInstance';

export const getFFMetaData = async (): Promise<Partial<FeatureFlagMetaData>> => {
  const { data } = await axiosInstance.get(
    'v2/subscription-manager/features/feature-flag-metadata',
  );

  assertValidMetadata(data);

  return data;
};
