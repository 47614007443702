import { ServicesIds } from '@tw/types/module/services';

export const buildCreativeVideoUrl = (
  serviceId: ServicesIds,
  videoId?: string,
  videoUrl?: string,
) => {
  switch (serviceId) {
    case 'facebook-ads':
      return `https://www.facebook.com/facebook/videos/${videoId}`;
    case 'google-ads':
      return `https://www.youtube.com/embed/${videoId}`;
    case 'tiktok-ads':
      return `https://www.tiktok.com/embed/${videoId}`;
    default:
      return videoUrl || '';
  }
};
