import React, { MutableRefObject, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import module_styles from './ToggleSwitch.module.scss';
import { cx } from 'utils/cx';

export interface IToggleSwitch {
  onChange: React.ChangeEventHandler;
  checked: boolean;
  /** Determines whether the thumb being on the right or left is considered the "checked" state. Default is left. */
  checkedDirection?: 'right' | 'left';
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  handleDiameter?: number;
  color?: string;
  rightColor?: string;
  leftColor?: string;
  handleColor?: string;
  handleRightColor?: string;
  handleLeftColor?: string;
}

export const ToggleSwitch: React.FC<IToggleSwitch> = ({
  checked,
  onChange,
  className,
  disabled = false,
  style,
  width = 56,
  height = 28,
  handleDiameter = 28,
  color = 'blue',
  rightColor,
  leftColor,
  handleColor = 'white',
  handleRightColor,
  handleLeftColor,
  checkedDirection = 'left',
}) => {
  const uniqueId: MutableRefObject<string> = useRef<string>(`t3w-toggle-switch-${uuidv4()}`);
  const cssVars: React.CSSProperties = {
    ['--t3w-toggle-switch-width' as any]: width + 'px',
    ['--t3w-toggle-switch-height' as any]: height + 'px',
    ['--t3w-toggle-switch-handle-diameter' as any]: handleDiameter + 'px',
    ['--t3w-toggle-right-color' as any]: rightColor || color,
    ['--t3w-toggle-left-color' as any]: leftColor || color,
    ['--t3w-toggle-handle-right-color' as any]: handleRightColor || handleColor,
    ['--t3w-toggle-handle-left-color' as any]: handleLeftColor || handleColor,
  };

  return (
    <div
      style={cssVars}
      className={cx(
        module_styles['t3w-toggle-switch'],
        checkedDirection === 'right' && 'check-oriented-right',
        disabled && 'disabled',
      )}
    >
      <input
        id={uniqueId.current}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={uniqueId.current} className={className} style={style}></label>
    </div>
  );
};

export default ToggleSwitch;
