import allPixelColumns from 'components/attribution-new/columns';
import { createSelector } from 'reselect';
import { CustomMetricsType } from 'ducks/customMetrics';
import { selectCustomMetricsColumns } from 'utils/customMetrics';
import { selectConnectedServices } from 'utils/selectors';

export const selectPixelColumnsForCustomMetrics = createSelector(
  [selectCustomMetricsColumns(CustomMetricsType.Attribution)],
  (columns) => columns,
);

export const allMetrics = createSelector<any, any>(
  [selectConnectedServices],
  (connectedServices) => {
    return allPixelColumns().filter((metric: any) => {
      if (!metric.dependOnActiveIntegrations) {
        return true;
      }
      return metric.dependOnActiveIntegrations.some(
        (service: string) => connectedServices[service],
      );
    });
  },
);

export const selectAllPixelColumnsIncludingCustom = createSelector<any, any>(
  [selectPixelColumnsForCustomMetrics, allMetrics],
  (customMetricsColumns, allMetrics) => {
    return allMetrics.concat(customMetricsColumns);
  },
);
