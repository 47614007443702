import { Icon, Text, Button, Tooltip } from '@tw/ui-components';
import { WILLY_SEQUENCE_STEP_CATEGORIES, WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { SequenceBuilderDrawer } from './SequenceBuilderDrawer';
import { WorkflowStep, shelfCategoryType } from '../types/willyTypes';
import { useMemo, useState } from 'react';
import { StepSelector } from './StepSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

type StepDrawerProps = {
  addNewStep: (step: Partial<WorkflowStep>, ruleDescendant?: boolean) => void;
  open: boolean;
  onClose: () => void;
  isFirstStep: boolean;
  fromRule?: boolean;
  sequenceId?: string | undefined;
};

export const StepDrawer: React.FC<StepDrawerProps> = ({
  addNewStep,
  open,
  onClose,
  isFirstStep,
  fromRule,
  sequenceId,
}) => {
  const currentSlackChannel = useSelector((state: RootState) => state.currentSlackChannel);
  const [activeCategory, setActiveCategory] = useState<shelfCategoryType | null>(null);

  const onClickCategory = (category: shelfCategoryType) => {
    const stepsInCategory = WILLY_SEQUENCE_STEP_OPTIONS.filter((step) => {
      return step.category === category.id;
    });
    if (stepsInCategory.length === 1) {
      const action = stepsInCategory[0];
      addNewStep({ createdAt: new Date().toISOString(), stepType: action.type }, fromRule);
    } else {
      setActiveCategory(category);
    }
  };

  const closeCategoryDrawer = () => {
    setActiveCategory(null);
  };

  const categorySequenceSteps = useMemo(() => {
    if (!activeCategory) return [];
    return WILLY_SEQUENCE_STEP_OPTIONS.filter((step) => step.category === activeCategory?.id);
  }, [activeCategory]);

  return (
    <>
      <SequenceBuilderDrawer open={open} title="Add Step" onClose={() => onClose()}>
        <div className="p-5 flex flex-col gap-5">
          <Text fz={14}>
            These are the events that start a sequence of actions, like date or data changes.
          </Text>
          {WILLY_SEQUENCE_STEP_CATEGORIES.map((category) => {
            return (
              <Tooltip withArrow w={250} multiline label={category.tooltip} key={category.id}>
                <Button
                  variant="white"
                  justify="start"
                  size="md"
                  onClick={() => onClickCategory(category)}
                  leftSection={<Icon name={category.icon} />}
                >
                  {category.name}
                </Button>
              </Tooltip>
            );
          })}
        </div>
      </SequenceBuilderDrawer>
      <SequenceBuilderDrawer
        open={!!activeCategory && !!open}
        title={activeCategory?.name || ''}
        onClose={() => closeCategoryDrawer()}
      >
        <div className="p-5 flex flex-col gap-5">
          {categorySequenceSteps.map((action) => {
            const shouldBeDisabled =
              (isFirstStep &&
                (action.type === 'sendToDashboard' ||
                  action.type === 'sendToEmail' ||
                  action.type === 'sendToSlack' ||
                  action.type === 'sendToGoogleSheet' ||
                  action.type === 'sendToWebhook' ||
                  action.type === 'rule')) ||
              (!sequenceId && action.type === 'preloadRuns') ||
              (action.type === 'sendToSlack' && !currentSlackChannel);
            return (
              <StepSelector
                key={action.type === 'tool' ? action.toolName : action.type}
                action={action}
                shouldBeDisabled={shouldBeDisabled}
                fromRule={fromRule}
                addNewStep={addNewStep}
                closeCategoryDrawer={closeCategoryDrawer}
                onClose={onClose}
              />
            );
          })}
        </div>
      </SequenceBuilderDrawer>
    </>
  );
};
