import { TickMinor } from '@shopify/polaris-icons';
import copyToClipboard from 'utils/copyToClipboard';
import { useState, useMemo } from 'react';
import { genericEventLogger, analyticsEvents, chatActions, sqwhaleActions } from 'utils/dataLayer';
import { dashboardsActions } from '../../utils/dataLayer/constants';
import { ReactComponent as Clipboard } from 'components/Icons/Clipboard.svg';
import { Tooltip } from '@shopify/polaris';
import { WillyDashboardElement } from './types/willyTypes';

type CopyToClipboardProps = {
  text: string;
  className?: string;
  context?: string;
  dashboard?: WillyDashboardElement;
};

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  text,
  className,
  context,
  dashboard,
}) => {
  const [copied, setCopied] = useState(false);

  const currentAnalyticsEvent = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? analyticsEvents.CHAT
      : context === 'editor'
        ? analyticsEvents.SQWHALE
        : analyticsEvents.DASHBOARDS;
  }, [context]);

  const currentAnalyticsAction = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? chatActions.COPY_QUERY
      : context === 'editor'
        ? sqwhaleActions.COPY_QUERY_FROM_CHAT
        : dashboardsActions.COPY_QUERY;
  }, [context]);

  return (
    <>
      {!copied && (
        <Tooltip content={'Copy to clipboard'}>
          <div>
            <Clipboard
              className={`w-9 flex items-center justify-center fill-primary dark:fill-gray-400 cursor-pointer ${className}`}
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                const res = await copyToClipboard(text, true);
                setCopied(res);
                genericEventLogger(currentAnalyticsEvent, {
                  action: currentAnalyticsAction,
                  text,
                  dashboard_name: dashboard?.name,
                  dashboard_id: dashboard?.id,
                  template_id: dashboard?.globalDashboardId,
                  template_name: dashboard?.globalDashboardId && dashboard?.name,
                });

                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            />
          </div>
        </Tooltip>
      )}
      {copied && (
        <TickMinor
          className={`w-9 flex items-center justify-center fill-primary dark:fill-gray-400 ${className}`}
        />
      )}
    </>
  );
};
