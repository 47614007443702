// @ts-nocheck
import React, { useEffect } from 'react';

interface props {
  email: string;
  authToken: string;
  shouldNotLoad: boolean;
}

const Profitwell: React.FC<props> = ({ email, authToken, shouldNotLoad }) => {
  const [hasInit, setHasInit] = React.useState(false);

  useEffect(() => {
    // @ts-ignore
    const { profitwell } = window;
    if (!profitwell || hasInit) return;
    if (email) {
      profitwell('user_email', email);
      setHasInit(true);
    }
  }, [email, hasInit]);

  useEffect(() => {
    if (shouldNotLoad) return;
    if (!authToken) {
      console.error('ERROR: property authToken is required');
      return;
    }
    // Beginning of Profitwell Awesomeness Code (which loads totally asynchronously)
    (function (i, s, o, g, r, a, m) {
      i.ProfitWellObject = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      'https://dna8twue3dlxq.cloudfront.net/js/profitwell.js',
      'profitwell',
    );
    if (window.profitwell) window.profitwell('auth_token', authToken);
  }, [authToken, shouldNotLoad]);

  return null;
};

export default Profitwell;
