import './billing.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import Stripe from 'stripe';
import axiosInstance from 'utils/axiosInstance';
import { Icon } from '@shopify/polaris';
import { Anchor, Flex, Text } from '@tw/ui-components';
import { setPaymentMethod } from '../../../ducks/signupJourney';
import ChangePaymentMethodModal from './changePaymentMethodModal';
import { BankMajor } from '@shopify/polaris-icons';
import { useStripePromise } from 'routes/auth/SignUp/components/useStripePromise';
import { Elements } from '@stripe/react-stripe-js';

type AppProps = {
  isUnpaid?: boolean;
  wrapperClass?: string;
  updateButtonPlain?: boolean;
};

export const ActivePMSection: React.FC<AppProps> = ({
  isUnpaid,
  wrapperClass,
  updateButtonPlain,
}) => {
  const dispatch = useDispatch();
  const { stripePromise } = useStripePromise();
  const [customerPaymentsMethod, setCustomerPaymentsMethod] = useState<
    Stripe.PaymentMethod | undefined
  >(undefined);
  const currentShop = useSelector((state: RootState) => state.currentShop);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<any>('');

  useEffect(() => {
    const createSetUpIntent = async () => {
      const { data } = await axiosInstance.post(
        'v2/subscription-manager/subscriptions/create-setupIntent',
      );
      setOptions({ clientSecret: data });
    };
    createSetUpIntent();
  }, [isUpdateModalOpen]);

  useEffect(() => {
    (async () => {
      try {
        if (currentShop?.payment_method) {
          const url = `v2/subscription-manager/payments/paymentMethod/${currentShop.payment_method}`;
          const response = await axiosInstance.get<Stripe.PaymentMethod>(url);
          if (response.status < 400 && response.data) {
            setCustomerPaymentsMethod(response.data);
            dispatch(setPaymentMethod(response.data));
          }
        }
      } catch (e) {
        console.error(`error getting payment method`);
      }
    })();
  }, [currentShop, dispatch]);

  return (
    <div className={`billing-settings ${wrapperClass}`}>
      {customerPaymentsMethod && (
        <div className={'flex-container middle card-select-settings'}>
          <div className={'flex-1'}>
            <div className={'flex-container middle '}>
              {customerPaymentsMethod.card && (
                <Flex gap="md">
                  <img
                    className={'card-icon'}
                    src={`/signupJourney/cardIcons/${customerPaymentsMethod.card?.brand}.svg`}
                    alt=""
                  />
                  <Text color="gray.9" weight={500}>
                    {` **** ${customerPaymentsMethod.card?.last4}`}
                  </Text>
                  <Text color="gray.9" weight={500}>
                    {`EXP. ${customerPaymentsMethod.card?.exp_month}/ ${customerPaymentsMethod.card?.exp_year}`}
                  </Text>
                </Flex>
              )}
              {customerPaymentsMethod.us_bank_account && (
                <Flex gap="md">
                  <div className={'card-icon'}>
                    <Icon source={BankMajor} color="subdued" />
                  </div>
                  <Text color="gray.9" weight={500}>
                    {` **** ${customerPaymentsMethod.us_bank_account?.last4}`}
                  </Text>
                  <Text color="gray.9" weight={500}>
                    {`${customerPaymentsMethod.us_bank_account?.bank_name}`}
                  </Text>
                </Flex>
              )}
            </div>
          </div>
          <Anchor
            onClick={() => {
              setIsUpdateModalOpen(true);
            }}
            underline="never"
            fw={600}
          >
            Change
          </Anchor>
        </div>
      )}
      {isUpdateModalOpen && (
        <>
          {stripePromise && Object.keys(options).length && (
            <Elements stripe={stripePromise} options={options}>
              <ChangePaymentMethodModal
                setIsModalOpen={setIsUpdateModalOpen}
                isOpen={isUpdateModalOpen}
                isUnpaid={isUnpaid}
              />
            </Elements>
          )}
        </>
      )}
    </div>
  );
};
