import { OptionList } from '@shopify/polaris';
import { DropdownSection, ExpandableDropdownOption } from './types';
import { useMemo, useRef } from 'react';
import classes from './ExpandableOptionList.module.scss';
import { useDelegatedHover } from 'hooks/useDelegatedHover';
import { OPTION_ITEM_CLASS, WANTS_TO_SEE_DESCRIPTIONS } from './constants';
import { Box, Flex, Switch, Text } from '@tw/ui-components';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMediaQuery } from 'hooks/useDefaultWindowSizes';
import { $store, useStoreValue, WritableStore } from '@tw/snipestate';

export type ExpandableOptionListProps = {
  allowMultiple?: boolean;
  handleChange: (val: string[]) => any;
  options?: ExpandableDropdownOption[];
  value: string;
  sections?: DropdownSection[];
};

export const ExpandableOptionList: React.FC<ExpandableOptionListProps> = ({
  allowMultiple = false,
  handleChange,
  options = [],
  value,
  sections = [],
}) => {
  const prevHoveredEl = useRef<HTMLElement | null>(null);

  const $hoveredValue = useMemo(
    () => $store<string | undefined>(options.find((o) => o.value === value)?.value),
    [options, value],
  );
  const isSmall = useMediaQuery('(max-width: 600px)');
  const [wantsDescriptions, setWantsDescriptions] = useLocalStorage({
    key: WANTS_TO_SEE_DESCRIPTIONS,
    defaultValue: true,
  });

  const setParentElement = useDelegatedHover({
    selector: OPTION_ITEM_CLASS,
    onHover(element) {
      const hoveredVal = options.find((o) => o.id === element.id)?.value;
      $hoveredValue.set(hoveredVal);

      prevHoveredEl.current?.classList.remove(classes.hoveredOption);
      element.classList.add(classes.hoveredOption);
      prevHoveredEl.current = element;
    },
  });

  return (
    <Flex>
      <div
        className="w-[390px] border-[0px] border-solid border-r border-r-[#F1F3F6]"
        ref={setParentElement}
      >
        <OptionList
          allowMultiple={allowMultiple}
          options={options}
          selected={[value]}
          onChange={handleChange}
          sections={sections}
        />
        <Box p="xs">
          <Switch
            size="sm"
            disabled={isSmall}
            checked={wantsDescriptions}
            offColor="gray.2"
            onChange={() => setWantsDescriptions((x) => !x)}
            label={
              <Text fz="sm" fw={500} color="gray.7">
                Model Descriptions
              </Text>
            }
          />
        </Box>
      </div>
      <ExpandedOptionMap
        isSmall={isSmall}
        options={options}
        $hoveredValue={$hoveredValue}
        wantsDescriptions={wantsDescriptions}
      />
    </Flex>
  );
};

function ExpandedOptionMap({
  isSmall,
  options = [],
  $hoveredValue,
  wantsDescriptions,
}: {
  isSmall: boolean;
  options?: ExpandableDropdownOption[];
  $hoveredValue: WritableStore<string | undefined>;
  wantsDescriptions: boolean;
}) {
  const hoveredValue = useStoreValue($hoveredValue);

  const expandedOptionMap = useMemo<Partial<Record<string, React.ReactNode>>>(() => {
    return options.reduce((acc, o) => ((acc[o.value] = o.infoPanel), acc), {});
  }, [options]);

  if (!wantsDescriptions || isSmall) return null;

  return <Box w={390}>{expandedOptionMap[hoveredValue || '']}</Box>;
}
