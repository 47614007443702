import { Flex, Icon, Menu, Text } from '@tw/ui-components';
import allServices from 'constants/services';
import { ALL_SOURCES_ID } from 'constants/types';
import { snakeCase } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AllServicesIds } from 'types/attribution';
import { getSourcesList } from 'utils/attributions';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';

const emptyArray = [];

type AttributionSelectChannelProps = {
  activator?: (label: string) => React.ReactNode;
  withinPortal?: boolean;
  dropdownPosition?: any;
  dropDownOffset?: number;
};

export const AttributionSelectChannel: FC<AttributionSelectChannelProps> = ({
  activator,
  withinPortal = true,
  dropDownOffset,
  dropdownPosition,
}) => {
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const navigate = useNavigate();
  const location = useLocation();

  const disabled = sourceCategory === 'all';

  const sourcesList = useMemo(() => {
    if (sourceCategory === 'all') {
      return emptyArray;
    }
    return getSourcesList(sourceCategory || 'all');
  }, [sourceCategory]);

  const serviceOptions = useMemo(
    () => [
      { label: 'All', value: ALL_SOURCES_ID },
      ...(sourcesList?.map((s) => {
        const source = allServices[s];
        return {
          label: source?.title!,
          value: source?.id!,
          id: snakeCase('attribution_header_' + source?.title!),
        };
      }) || []),
    ],
    [sourcesList],
  );

  const handleChannelChange = useCallback(
    (id: AllServicesIds) => {
      navigate({
        pathname: `${
          location.pathname.includes('dashboards') ? '/dashboards' : ''
        }/attribution/${sourceCategory}/${id}`,
        search: location.search,
      });
    },
    [location, navigate, sourceCategory],
  );

  const activeOption = useMemo(
    () => serviceOptions.find((s) => s.value === activeSource)?.label || 'All',
    [activeSource, serviceOptions],
  );

  return (
    <Menu
      withinPortal={withinPortal}
      position={dropdownPosition}
      offset={dropDownOffset}
      id="att-header-channel-dropdown"
      disabled={disabled}
    >
      <Menu.Target>
        {activator ? (
          <div>{activator(activeOption)}</div>
        ) : (
          <Flex align="center" gap="md" justify="space-between">
            <Text size="sm" weight={500} color="named2.0">
              Channel: {activeOption}
            </Text>
            <Icon name="chevron-right-minor" size={12} color={disabled ? 'named2.3' : 'named.8'} />
          </Flex>
        )}
      </Menu.Target>
      <Menu.Dropdown>
        {serviceOptions.map((item) => (
          <Menu.Item
            key={item.value}
            bg={activeSource === item.value ? 'named.1' : undefined}
            onClick={() => handleChannelChange(item.value as AllServicesIds)}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
