import { useState } from 'react';
import {
  ActivityProperties,
  AdProps,
  CartActionProps,
  CustomerActivity,
  CustomerActivityType,
  EmailSMSCampaignProps,
  OrderProps,
  PPSProps,
  SubscriptionProps,
  ViewdPageProps,
} from '@tw/types/module/services/insights';
import { Tooltip, Text, Collapse, Anchor, Flex, Container, Icon } from '@tw/ui-components';
import { PlacedOrderContent, PlacedOrderTitle } from './PlacedOrder';
import { PPSInfo } from './PPSInfo';
import { ProductInfo } from './ProductInfo';
import { ViewedPage } from './ViewedPage';
import { AdInfo } from './AdInfo';
import { EmailSMSCampaign } from './EmailSMSCampaign';
import { ProviderIcon } from 'components/library/ProviderIcon';

type ActivityFunction<T extends CustomerActivityType> = (
  properties: ActivityProperties[T],
) => JSX.Element | string;

const ACTIVITY_ELEMENTS: {
  [key in CustomerActivityType]: {
    title: ActivityFunction<key>;
    content: ActivityFunction<key>;
    isEmptyContent?: (properties: ActivityProperties[key]) => boolean;
    icon: ActivityFunction<key>;
  };
} = {
  [CustomerActivityType.PlacedOrder]: {
    title: (properties: OrderProps): JSX.Element => {
      return <PlacedOrderTitle properties={properties} />;
    },
    content: (properties: OrderProps): JSX.Element => {
      return <PlacedOrderContent properties={properties} />;
    },
    icon: (properties: OrderProps): JSX.Element => {
      return <ProviderIcon provider={properties.providerId} size={18} />;
    },
  },
  [CustomerActivityType.AddedToCart]: {
    title: (properties: CartActionProps): string => {
      // TODO: should be a link to the product
      return `Added to cart “${properties.productTitle}”`;
    },
    content: (properties: CartActionProps): JSX.Element => {
      return <ProductInfo properties={properties} showVariantInfo={false} />;
    },
    icon: (properties: CartActionProps): JSX.Element => {
      return <ProviderIcon provider={properties.providerId} />;
    },
  },
  [CustomerActivityType.RemovedFromCart]: {
    title: (properties: CartActionProps): string => {
      // TODO: should be a link to the product
      return `Removed from cart “${properties.productTitle}”`;
    },
    content: (properties: CartActionProps): JSX.Element => {
      return <ProductInfo properties={properties} showVariantInfo={false} />;
    },
    icon: (properties: CartActionProps): JSX.Element => {
      return <ProviderIcon provider={properties.providerId} />;
    },
  },
  [CustomerActivityType.ClickedOnAd]: {
    title: (properties: AdProps): string => {
      return `Clicked ad ${properties.adName ? `“${properties.adName}”` : ''}`;
    },
    content: (properties: AdProps): JSX.Element => {
      return <AdInfo properties={properties} />;
    },
    icon: (properties: AdProps): JSX.Element => {
      return <ProviderIcon provider={properties.source} />;
    },
  },
  [CustomerActivityType.ClickedEmailSMSCampaign]: {
    title: function (properties: EmailSMSCampaignProps): string {
      return `Clicked campaign ${properties.campaignName ? `“${properties.campaignName}”` : ''}`;
    },
    content: function (properties: EmailSMSCampaignProps): JSX.Element {
      return <EmailSMSCampaign properties={properties} />;
    },
    icon: (properties: EmailSMSCampaignProps): JSX.Element => {
      return <ProviderIcon provider={properties.source} />;
    },
  },
  [CustomerActivityType.ViewedPage]: {
    title: (properties: ViewdPageProps): JSX.Element => {
      let pageTypeString = 'page';
      let pageName = properties.title;
      switch (properties.pageType) {
        case 'collection':
          pageName = pageName || properties.url?.match(/\/collections\/([^/]+)/)?.[1];
          pageTypeString = properties.pageType;
          break;
        case 'product':
          if (properties.productTitle) {
            pageName = pageName || properties.productTitle;
            pageTypeString = properties.pageType;
          }
          break;
        case 'cart':
          pageTypeString = properties.pageType;
          break;
        case 'confirmation':
          pageTypeString = '';
          pageName = 'Order Confirmation Page';
      }
      pageName = pageName || properties.url;

      return (
        <>
          Viewed {pageTypeString}{' '}
          <Tooltip
            openDelay={1000}
            multiline
            zIndex={9999}
            label={properties.fullUrl || properties.url}
          >
            <Anchor href={properties.fullUrl || `https://${properties.url}`} target="_blank">
              {pageName}
            </Anchor>
          </Tooltip>
        </>
      );
    },
    content: (properties: ViewdPageProps): JSX.Element => {
      return <ViewedPage properties={properties} />;
    },
    isEmptyContent: (properties: ViewdPageProps): boolean => {
      return (
        Object.entries(properties).filter(
          ([k, v]) => v && !['pageType', 'url', 'fullUrl', 'title'].includes(k),
        ).length === 0
      );
    },
    icon: (properties: ViewdPageProps): JSX.Element => {
      return <Icon name="globe" size={18} />;
    },
  },
  [CustomerActivityType.PostPurchaseSurveySubmitted]: {
    title: (): string => {
      return 'Post purchase survey submitted';
    },
    content: (properties: PPSProps): JSX.Element => {
      return <PPSInfo properties={properties} />;
    },
    icon: (properties: PPSProps): JSX.Element => {
      return <ProviderIcon provider="triple-whale" size={18} />;
    },
  },
  [CustomerActivityType.StartedSubscription]: {
    title: (properties: SubscriptionProps): string => {
      return `Started subscription for ${properties.productName}`;
    },
    content: (properties: SubscriptionProps): JSX.Element => {
      return <pre>{JSON.stringify(properties, null, 2)}</pre>;
    },
    icon: (properties: SubscriptionProps): JSX.Element => {
      return <></>;
    },
  },
};

function activityTitle<T extends CustomerActivityType>(
  type: T,
  properties: ActivityProperties[T],
): JSX.Element | string {
  return ACTIVITY_ELEMENTS[type]?.title(properties);
}

function isEmptyContent<T extends CustomerActivityType>(
  type: T,
  properties: ActivityProperties[T],
): boolean {
  return !!ACTIVITY_ELEMENTS[type]?.isEmptyContent?.(properties);
}

function activityContent<T extends CustomerActivityType>(
  type: T,
  properties: ActivityProperties[T],
): JSX.Element | string {
  return ACTIVITY_ELEMENTS[type]?.content(properties);
}

function activityIcon<T extends CustomerActivityType>(
  type: T,
  properties: ActivityProperties[T],
): JSX.Element | string {
  return ACTIVITY_ELEMENTS[type]?.icon(properties);
}

export const ActivityTitle: React.FC<{ activity: CustomerActivity }> = ({ activity }) => {
  return (
    <Text span fw={500}>
      {activityTitle(activity.type, activity.properties)}
    </Text>
  );
};

export const ActivityContent: React.FC<{ activity: CustomerActivity }> = ({ activity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const emptyContent = isEmptyContent(activity.type, activity.properties);

  if (emptyContent) return null;

  return (
    <Flex direction="column" gap="sm">
      <Collapse in={isOpen}>{activityContent(activity.type, activity.properties)}</Collapse>
      <Anchor
        size="xs"
        underline="never"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        See {isOpen ? 'less' : 'more'}
      </Anchor>
    </Flex>
  );
};

export const ActivityIcon: React.FC<{ activity: CustomerActivity }> = ({ activity }) => {
  return (
    <Container display="flex" bg="white.1" h={30} w={30} p="unset" style={{ alignItems: 'center' }}>
      {activityIcon(activity.type, activity.properties)}
    </Container>
  );
};
