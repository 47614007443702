import { useEffect } from 'react';
import { Header, Box, useWindowSize, Flex } from '@tw/ui-components';
import { $canToggleBetweenVersions } from '$stores/$v3_0_Enabled';
import { $topBarHeight, useActiveRoute } from '$stores/nav-config-stores';
import { useResizeObserver } from 'hooks/useResizeObserver';
import MonitoringIncidents from 'components/MonitoringIncidents';
import TopBreadcrumbs from 'components/TopBreadcrumbs';
import { ToggleTW3Section } from 'components/Nav/components/common/ToggleTW3Section';
import { MobileTopSection } from './MobileTopSection';
import {
  BASE_HEADER_HEIGHT,
  GRID_BREAKPOINTS,
  TOP_BAR_BG_VAR,
  TOP_BAR_BORDER_VAR,
  TOP_BAR_HEIGHT_VAR,
} from './constants';
import { useStoreValue } from '@tw/snipestate';

export const MainTopBar: React.FC = () => {
  const { activeRoute, isLocked } = useActiveRoute();
  const InnerHeader = !isLocked ? activeRoute?.header ?? null : null;
  const size = useWindowSize('width', GRID_BREAKPOINTS);
  const canToggle = useStoreValue($canToggleBetweenVersions);

  // calculate top bar height based on window width
  useEffect(() => {
    if (size !== 'mobile') {
      $topBarHeight.set(BASE_HEADER_HEIGHT);
      return;
    }

    // in desktop size, all overflow is scrollable, not wrapped, so height is static
    if (size === 'mobile' && !InnerHeader) {
      $topBarHeight.set(BASE_HEADER_HEIGHT * 2);
    }
  }, [size, InnerHeader]);

  // calculate height of top bar based on the size of contents of InnerHeader
  const setInnerHeaderRef = useResizeObserver((entries) => {
    if (!entries[0]) return;
    const height = entries[0].contentBoxSize[0].blockSize;
    const numberOfRows = size === 'mobile' ? 2 : 1;
    const padding = !height ? 0 : 24;
    $topBarHeight.set(BASE_HEADER_HEIGHT * numberOfRows + height + padding);
  });

  return (
    <Header bg={TOP_BAR_BG_VAR} h={TOP_BAR_HEIGHT_VAR} borderBottom={TOP_BAR_BORDER_VAR}>
      {size === 'mobile' && (
        <Box
          p="xs"
          mih={BASE_HEADER_HEIGHT}
          mah={BASE_HEADER_HEIGHT}
          borderBottom={TOP_BAR_BORDER_VAR}
        >
          <MobileTopSection />
        </Box>
      )}
      <Flex justify="space-between" direction="column">
        <Flex
          p="sm"
          h={BASE_HEADER_HEIGHT}
          mah={BASE_HEADER_HEIGHT}
          gap="sm"
          justify="space-between"
          align="center"
          borderBottom={TOP_BAR_BORDER_VAR}
        >
          <TopBreadcrumbs />
          <div
            style={{
              padding: '0 2px 0 2px',
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            <Flex gap="xs" align="center">
              {size === 'desktop' && InnerHeader && <InnerHeader />}
              {size === 'mobile' && canToggle && !activeRoute?.isSettingsPage && (
                <ToggleTW3Section hideLabel />
              )}
            </Flex>
          </div>
        </Flex>

        {size !== 'desktop' && InnerHeader && (
          <Flex ref={setInnerHeaderRef} p="sm" justify="flex-start">
            <InnerHeader />
          </Flex>
        )}
      </Flex>
      <MonitoringIncidents />
    </Header>
  );
};
