import _db, { FieldValue } from 'utils/DB';
import { HistoryItem } from '../types/willyTypes';
import { convertUndefinedToNull } from './convertUndefinedToNull';

export async function addHistoryItemToConversation(
  shopId: string,
  conversationId: string,
  historyItem: HistoryItem,
) {
  const withNulls = convertUndefinedToNull(historyItem);
  const conversationRef = _db(shopId).collection('conversations').doc(conversationId);
  await conversationRef.set(
    {
      history: FieldValue.arrayUnion(withNulls),
    },
    { merge: true },
  );
}
