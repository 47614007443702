import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// hooks
import { useConnectedAccounts } from '../hooks/useConnectedAccounts';

// types
import type { RootState } from 'reducers/RootType';

// components
import { Modal, Spinner } from '@shopify/polaris';
import { Button } from '@tw/ui-components';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { ShopIntegrationStatusEnum } from '@tw/types/module/types/ShopProviders';
import { CircleIcon } from 'components/library/CircleIcon';
import { Flex, Grow, Progress, Text } from '@tw/ui-components';
import { Integration } from '@tw/types/module/services/account-manager';

interface IConnectionDetailsModal {
  open: boolean;
  platformName: string;
  onClose: (...args: any[]) => any;
  connected?: boolean;
  serviceId: string;
  configHook?: () => Promise<any> | void;
  customConnectionDetails?: React.FC;
}

export const ConnectionDetailsModal: React.FC<IConnectionDetailsModal> = ({
  connected,
  open,
  platformName,
  onClose,
  serviceId,
  configHook,
  customConnectionDetails: CustomConnectionDetails,
}) => {
  // STATES
  const [loading, setLoading] = useState<boolean>(false);
  const shopDomain = useSelector((state: RootState) => state.currentShopId);
  const { adAccounts, attributionWindow, shopServices } = useConnectedAccounts(
    shopDomain,
    serviceId,
  );
  const integrationsByService = useSelector(shopIntegrations);
  const integrationsStatus = useSelector((state: RootState) => state.sensory.integrationsStatus);

  // COMPUTED
  const integrations: Integration[] = useMemo(() => {
    let ints: Integration[] = integrationsByService[serviceId] ?? [];
    ints =
      ints.map(
        (x) =>
          ({
            ...x,
            status: integrationsStatus[x.id]?.status ?? x.status,
            workflowStatus: integrationsStatus[x.id]?.workflowStatus ?? x.workflowStatus,
          }) as Integration,
      ) || [];
    return ints;
  }, [integrationsByService, serviceId, integrationsStatus]);

  // CALLBACKS
  const showConfig = useCallback(async () => {
    if (!configHook) return;

    try {
      setLoading(true);

      const res = configHook();
      if (res instanceof Promise) await res;
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [configHook, onClose]);

  const getStatusColor = (status: ShopIntegrationStatusEnum) => {
    switch (status) {
      case ShopIntegrationStatusEnum.ready:
        return 'green';
      case ShopIntegrationStatusEnum.error:
        return 'red';
      case ShopIntegrationStatusEnum.pending:
        return 'orange';
      case ShopIntegrationStatusEnum.backfill:
        return 'blue';
      case ShopIntegrationStatusEnum.disconnected:
        return 'gray';
      default:
        return 'green';
    }
  };

  const getStatusText = (status: ShopIntegrationStatusEnum) => {
    switch (status) {
      case ShopIntegrationStatusEnum.ready:
        return 'Ready';
      case ShopIntegrationStatusEnum.error:
        return 'Error';
      case ShopIntegrationStatusEnum.pending:
        return 'initializing';
      case ShopIntegrationStatusEnum.backfill:
        return 'Refreshing Data...';
      case ShopIntegrationStatusEnum.disconnected:
        return 'Paused';
      default:
        return 'Unknown';
    }
  };

  // VIEW
  return (
    <Modal open={open} title={`Connection Details: ${platformName}`} onClose={onClose}>
      <div className="p-6 ">
        {CustomConnectionDetails ? (
          <CustomConnectionDetails />
        ) : integrations.length === 0 ? (
          <div className="mx-8 my-5">No account information available for this service.</div>
        ) : (
          <>
            {serviceId === 'facebook-ads' && (
              <div className={'p-2 ' + borderClasses + ' m-2'}>
                <b>Attribution Window: </b>
                {attributionWindow.label}
                {shopServices?.facebookAttributionWindows_old && (
                  <span className="ml-2">
                    (old was {shopServices?.facebookAttributionWindows_old})
                  </span>
                )}
              </div>
            )}
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              {integrations.map((x, i) => (
                <li key={x.id} className="mb-5">
                  <div className="flex">
                    <div className="flex-none w-7 mt-2">
                      <CircleIcon color={getStatusColor(x.status)} />
                    </div>
                    <div className="flex-none">
                      <div>
                        <strong>ID:</strong> {x.id}
                      </div>
                      {x.name && (
                        <div>
                          <strong>Name:</strong> {x.name}
                        </div>
                      )}
                      {x.status && (
                        <div>
                          <strong>Status:</strong> {getStatusText(x.status)}
                        </div>
                      )}

                      {x.workflowStatus?.length > 0 &&
                        x.workflowStatus.map((wf) => (
                          <Flex align={'center'} key={wf.assetType}>
                            {x.workflowStatus?.length > 1 && (
                              <Text mr={'xs'} size={'xs'}>
                                {wf.assetType}:
                              </Text>
                            )}
                            <Grow>
                              {/* TODO: Fix this ts-ignore */}
                              <Progress
                                //@ts-ignore
                                animate={wf.total === undefined}
                                value={
                                  wf.total && wf.currentIndex
                                    ? (wf.currentIndex / wf.total) * 100
                                    : 100
                                }
                              />
                            </Grow>
                            {wf.total && (
                              <Text
                                ml={'xs'}
                                size={'xs'}
                              >{`${wf.currentIndex} / ${wf.total}`}</Text>
                            )}
                          </Flex>
                        ))}
                      {x.status == ShopIntegrationStatusEnum.error && !!x.errorMessage && (
                        <div className="max-w-prose">
                          <strong>Error Message:</strong> {x.errorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        {connected && configHook && (integrations.length > 0 || CustomConnectionDetails) && (
          <div className="text-right">
            {loading ? (
              <Spinner size="small" />
            ) : (
              <Button variant="white" forceColorScheme="light" onClick={showConfig}>
                Manage
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

// CONSTANTS
const borderClasses: string = 'border-solid border-x-0 border-t-0 border-b border-slate-200';
