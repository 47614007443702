import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { getFireBaseMessagingToken, onMessageListener } from 'utils/firebaseMessaging';
import { toast } from 'react-toastify';
import { ReactComponent as TimingIcon } from 'icons/Ai-timing-icon.svg';
import { ReactComponent as RulesReportIcon } from 'icons/lighthouse-rules-report-icon.svg';
import { ReactComponent as InventoryIcon } from 'icons/lighthouse-Inventory-Icon.svg';
import { ReactComponent as GenerativeIcon } from 'icons/Ai-generative-icon.svg';
import { ReactComponent as MetricsReportIcon } from 'icons/lighthouse-metric-report-icon.svg';
import { ReactComponent as LighthouseIcon } from 'icons/lighthouse.svg';
import { useAppDispatch } from 'index';

const PushNotification: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const [isTokenFound, setTokenFound] = useState(false);
  const shopInitiated = useSelector((state: RootState) => state.shopInitiated);
  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);

  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  useEffect(() => {
    if (user && shopInitiated) {
      getFireBaseMessagingToken(setTokenFound, user, isFromMobileApp);
    }
  }, [user, shopInitiated, isFromMobileApp]);

  useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
        // console.log('message received');
        // console.log(payload);
        if (isTokenFound) {
          let icon;
          switch (payload.data.type) {
            case 'rules-report':
              icon = <RulesReportIcon width={24} height={24} />;
              break;
            case 'inventory':
              icon = <InventoryIcon width={20} height={20} />;
              break;
            case 'timing':
              icon = <TimingIcon width={24} height={24} />;
              break;
            case 'generative':
              icon = <GenerativeIcon width={24} height={24} />;
              break;
            case 'metrics-report':
              icon = <MetricsReportIcon width={24} height={24} />;
              break;
            default:
              icon = <LighthouseIcon width={24} height={24} />;
          }
          // console.log(payload.data);
          const notification = new Notification(payload?.data?.title, {
            body: payload?.data?.body,
            icon: payload?.data?.icon,
            data: {
              url: `https://app.triplewhale.com/?fromNotification=1&openParams=${payload.data.openParams}}`,
              status: 'open',
            },
          });
          notification.onclick = function (event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(
              `https://app.triplewhale.com/?fromNotification=1&openParams=${payload.data.openParams}}`,
              '_blank',
            );
          };
          // toast.info(`${payload?.data?.title} - ${payload?.data?.body}`, {
          //   icon: icon,
          //   autoClose: 15000,
          //   onClick: () => {
          //     if (payload?.data?.shopId && currentShopId !== payload?.data?.shopId) {
          //       const base = isLocalhost
          //         ? 'http://localhost:3000'
          //         : isProd
          //         ? 'https://app.triplewhale.com'
          //         : 'https://triple-whale-staging.web.app';
          //       window.open(
          //         `${base}/?shop-id=${payload?.data?.shopId}&fromNotification=1&openParams=${payload.data.openParams}`,
          //         '_blank'
          //       );
          //     } else {
          //       dispatch(customLoadFroAppNotification(payload.data.openParams));
          //     }
          //   },
          // });

          // console.log(payload);
        }
      })
      .catch((err) => console.log('failed: ', err));
  }, [isTokenFound, currentShopId, dispatch]);

  return <div></div>;
};

export default PushNotification;
