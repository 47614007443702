import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, OptionList } from '@shopify/polaris';
import {
  toggleTiktokConfigScreenOpen,
  tiktokAccountsSelectOnChange,
  tiktokSaveAccountsAndStartImporting,
  tiktokConnectOnPress,
} from '../ducks/tiktok';

const TiktokAccountsConfig = ({
  isOpen,
  toggleIsOpen,
  accountsSelectOnChange,
  accounts,
  isLoading,
  save,
}) => (
  <Modal
    title={accounts.length ? 'Select TikTok account' : 'Connect to TikTok'}
    open={isOpen}
    onClose={toggleIsOpen}
    primaryAction={{
      content: 'Save',
      onAction: save,
      disabled: !accounts.filter((acc) => acc.selected).length || isLoading,
    }}
    loading={isLoading}
  >
    {accounts.length > 0 && (
      <OptionList
        //title="Select relevant accounts"
        onChange={(selected) => {
          var accs = accounts.slice();
          accs.forEach((acc) => (acc.selected = selected.includes(acc.id)));
          accountsSelectOnChange(accs);
        }}
        options={accounts.map((account) => {
          return {
            value: account.id,
            label: account.id,
          };
        })}
        selected={accounts.filter((acc) => acc.selected).map((acc) => acc.id)}
        allowMultiple={true}
      />
    )}
    {accounts.length === 0 && (
      <Modal.Section>
        <Button primary onClick={tiktokConnectOnPress}>
          Connect to TikTok
        </Button>
      </Modal.Section>
    )}
  </Modal>
);

const mapStateToProps = ({ tiktokConfigScreenIsOpen, tiktokConfigInProgress, tiktokAccounts }) => ({
  isOpen: tiktokConfigScreenIsOpen,
  isLoading: tiktokConfigInProgress,
  accounts: tiktokAccounts || [],
});

const mapDispatchToProps = (dispatch) => ({
  toggleIsOpen: () => dispatch(toggleTiktokConfigScreenOpen()),
  accountsSelectOnChange: (value) => dispatch(tiktokAccountsSelectOnChange(value)),
  save: () => dispatch(tiktokSaveAccountsAndStartImporting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TiktokAccountsConfig);
