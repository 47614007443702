import { ServicesIds } from '@tw/types/module/services';
import { colors } from '@tw/ui-components';
import { WillyMetric } from 'components/Willy/types/willyTypes';
import { adjustColor } from 'components/Willy/utils/willyUtils';
import { uniqBy } from 'lodash';
import { formatNumber } from 'utils/formatNumber';

export const DEFAULT_ERROR_MSG = 'An error occurred, please contact the support team';

export const mapToOldIds: Partial<Record<ServicesIds, string>> = {
  'google-ads': 'GOOGLE_ADS',
  'facebook-ads': 'FACEBOOK',
  'tiktok-ads': 'TIKTOK',
  'pinterest-ads': 'PINTEREST',
  'snapchat-ads': 'SNAPCHAT',
  shopify: 'SHOPIFY',
};

export const mapToNewIds: Record<string, ServicesIds> = {
  GOOGLE_ADS: 'google-ads',
  FACEBOOK: 'facebook-ads',
  TIKTOK: 'tiktok-ads',
  PINTEREST: 'pinterest-ads',
  SNAPCHAT: 'snapchat-ads',
  SHOPIFY: 'shopify',
};

export const convertOldToNewId = (oldId: string) => mapToNewIds[oldId];
export const convertNewToOldId = (newId: ServicesIds) => mapToOldIds[newId];
export const addNewIdsToOldIds = (oldIds: string[]) => [
  ...oldIds,
  ...oldIds.map((id) => mapToNewIds[id]),
];

type Color = {
  light: string;
  dark: string;
  name: string;
};

const colorsOrder = ['two', 'teal', 'cyan', 'pink', 'green', 'one'];

export const COLORS = Object.entries(colors)
  .filter(
    (color) =>
      color[0] !== 'red' && color[0] !== 'named' && color[0] !== 'named2' && color[0] !== 'gray',
  )
  .sort((a, b) => {
    const aIndex = colorsOrder.indexOf(a[0]);
    const bIndex = colorsOrder.indexOf(b[0]);
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  })
  .map((color) => {
    const list = color[1];
    return list
      .slice(2)
      .slice(0, -1)
      .filter((c, i) => i % 2);
  })
  .flat();

// export const COLOR_PALETTE: Color[] = [
//   { light: '#EA643D', dark: '#a14429', name: 'sienna' },
//   { light: '#B0446A', dark: '#8a2e54', name: 'maroon' },
//   { light: '#3159F3', dark: '#2e4a8e', name: 'cobalt' },
//   { light: '#10ABA4', dark: '#0a5b57', name: 'sandy' },
//   { light: '#7C5FF8', dark: '#5a3e9e', name: 'indigo' },
//   { light: '#ffc107', dark: '#b37d00', name: 'bronze' },
//   { light: '#10B981', dark: '#0a6a64', name: 'teal' },
//   { light: '#9c27b0', dark: '#6a0a64', name: 'plum' },
//   { light: '#9acfff', dark: '#6a8e9e', name: 'slate' },
//   { light: '#ea9999', dark: '#52b788', name: 'mint' },
//   { light: '#ffc515', dark: '#d9ed92', name: 'lime' },
//   { light: '#b09efe', dark: '#9e6a8e', name: 'mauve' },
//   { light: '#8a7400', dark: '#685500', name: 'olive' },
//   { light: '#5db9f9', dark: '#33abff', name: 'cohort' },
// ];

export const CHART_COLORS = COLORS; // COLOR_PALETTE.map((color) => color.light);

export const cohortColor = '#5db9f9';
//COLOR_PALETTE.find((color) => color.name === 'cohort')?.light || '#5db9f9';

export const GRADIENT_CHART_COLORS = COLORS.map((color) => ({
  start: color,
  stop: color,
  name: color,
}));

export const RANDOM_COLOR =
  GRADIENT_CHART_COLORS[Math.floor(Math.random() * GRADIENT_CHART_COLORS.length)];

export const loadingColor = '#888888';

export const GradientChartDefs: React.FC<{ metrics: WillyMetric[] }> = ({ metrics }) => {
  if (!metrics) {
    return null;
  }
  const allColors = uniqBy(
    metrics
      .map((m) => {
        return {
          name: m.colorName,
          start: m.color,
          stop: adjustColor(m.color as `#${string}`, -50),
        };
      })
      .concat(GRADIENT_CHART_COLORS),
    'name',
  );

  return (
    <>
      <linearGradient id="loading" x1="0" y1="0" x2="0" y2="1" key="loading">
        <stop offset="0.2" stopColor={loadingColor} stopOpacity={1}>
          <animate
            attributeName="stop-color"
            values={`${loadingColor}; ${adjustColor(loadingColor, 50)}; ${loadingColor}`}
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="1" stopColor="#dddddd" stopOpacity={1}>
          <animate
            attributeName="stop-color"
            values="#dddddd; #eeeeee; #dddddd"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
      <linearGradient id={`summary-like-chart-color`} x1="0" y1="0" x2="0" y2="1">
        <stop offset="0.2" stopColor={'#10F1A7'} stopOpacity={1} />
        <stop offset="1" stopColor={'#1877F2'} stopOpacity={1} />
      </linearGradient>
      {allColors.map((color, i) => (
        <linearGradient id={color.name} x1="0" y1="0" x2="0" y2="1" key={color.name + '_' + i}>
          <stop offset="0.2" stopColor={color.start} stopOpacity={1} />
          <stop offset="1" stopColor={color.stop} stopOpacity={1} />
        </linearGradient>
      ))}
    </>
  );
};
