import { combineReducers, Reducer } from 'redux';
import firebase from 'firebase/compat/app';
import { WizardData } from 'types/WizardData';
import { INIT_SHOP } from 'ducks/constants';

export const updateWelcomeWizardData = (payload) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    const { welcomeWizardData } = getState().welcomeWizard;

    const newData = { ...welcomeWizardData, ...payload };
    await firebase
      .firestore()
      .collection('shops')
      .doc(currentShopId)
      .set({ welcomeWizardData: newData }, { merge: true });

    dispatch({
      type: WELCOME_WIZARD_DATA,
      payload: newData,
    });
  };
};

export const WELCOME_WIZARD_DATA = 'WELCOME_WIZARD_DATA';
const welcomeWizardData: Reducer<WizardData | null> = (state = null, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.welcomeWizardData || {};
    case WELCOME_WIZARD_DATA:
      return action.payload || {};
    default:
      return state;
  }
};

export const reducers = combineReducers({
  welcomeWizardData,
});
