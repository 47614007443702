import sonarAddon from './Assets/sonar-addon.svg';
import mobyAddon from './Assets/moby-addon.svg';
import { $mobySeatsCredits } from '$stores/willy/$credits';
import { AddonMetaData } from './AddonTypes';
import { Icon, Image } from '@tw/ui-components';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';

/**
 * Map of different addons to their product ids.
 * Making sure this map is partial, since we can
 * add new addons in db without updating this
 * constant.
 */
export const AddonsMetaData: Partial<Record<string, AddonMetaData>> = {
  //sonar
  prod_QJe2ZDqWNMNzpq: {
    icon: <Image src={sonarAddon} alt={''} width={30} height={30} />,
  },
  //moby
  prod_QrKei7qdLvglgN: {
    icon: <Image src={mobyAddon} alt={''} width={30} height={30} />,
    postPurchaseLink: '/store-settings/team',
    isQuantity: true,
    unitLabel: 'seat',
    get unitLabelText() {
      const { creditsUsed, credits } = $mobySeatsCredits.get();
      return `${creditsUsed}/${credits} Assigned`;
    },
    onClickUnitLabel: (navigate) => navigate('/store-settings/team'),
    logUpgradeEvent: (quantity: number) => {
      genericEventLogger(analyticsEvents.UPGRADE, {
        action: upgradeCtaActions.COMPLETE_UPGRADE,
        source: 'chat_page',
        plan_name: 'moby_seats',
        quantity: quantity,
      });
    },
  },
  //creative-cockpit
  prod_LsE1g6fgaIlAYu: {
    icon: <Image src={sonarAddon} alt={''} width={30} height={30} />,
  },
  //data wharehouse
  prod_Qzsl9q7AOSm6uF: {
    icon: <Icon name="db" color="one.5" size={20} />,
  },
};
