import { DataTypesIds, services, ServicesIds } from '@tw/types/module/services';

import axiosInstance from './axiosInstance';
import { getShopData } from './DB';

export const setServiceAccountInfo = async (shopId, serviceId: ServicesIds, accountInfo: any) => {
  try {
    const url = `v2/${serviceId}/set-account-info`;

    return await axiosInstance.post(url, { shopId, accountInfo });
  } catch (e) {
    console.error(e);
  }
};
