import { Fragment, useMemo } from 'react';
import { FooterConfig } from './config';
import { Anchor, Box, Flex } from '@tw/ui-components';
import { useLocation } from 'react-router';
import { useDarkMode } from 'dark-mode-control';
import { BoxProps } from '@tw/ui-components';

export type HyperlinkTextParams = {
  template: string;
  links: string[];
};

export type MatchObject = {
  start: number;
  end: number;
  text: string;
  link?: string;
};

export const hyperlinkText = ({ template, links }: HyperlinkTextParams): MatchObject[] => {
  if (!links.length) return [];

  const regex = /{{([\s\S]+?)}}/g;

  const regexMatches = [...template.matchAll(regex)];

  const sections = regexMatches.reduce((acc, rm, index) => {
    const matchText = rm[0];
    const matchedGroupText = rm[1];
    const start = rm.index ?? 0;
    const end = matchText.length + start;
    const lastObj = acc[acc.length - 1];

    if (!acc.length) {
      acc.push({
        start: 0,
        end: start,
        text: template.slice(0, start),
      });
    } else if (start - lastObj.end > 0) {
      const gapStart = lastObj.end;
      const gapEnd = start;

      acc.push({
        start: gapStart,
        end: gapEnd,
        text: template.slice(gapStart, gapEnd),
      });
    }

    acc.push({ start, end, text: matchedGroupText, link: links[index] });

    return acc;
  }, new Array<MatchObject>());

  // need to still get the last section after
  // the last match if there were any matches
  const lastObj = sections[sections.length - 1];
  if (lastObj && template.length - lastObj.end > 0) {
    const gapStart = lastObj.end;
    const gapEnd = template.length;

    sections.push({
      start: gapStart,
      end: gapEnd,
      text: template.slice(gapStart, gapEnd),
    });
  }

  return sections;
};

export const EducationFooter: React.FC = () => {
  const darkMode = useDarkMode();
  const location = useLocation();

  const config = FooterConfig[location.pathname];
  const matchObjects = useMemo(() => (config ? hyperlinkText(config) : []), [config]);

  if (!matchObjects.length) return null;

  const boxStyles: BoxProps = {
    c: darkMode ? 'white' : 'gray.9',
    py: 'md',
  };

  return (
    <Box {...boxStyles}>
      <Flex justify="center" align="center">
        <HyperlinkedTexts matchObjects={matchObjects} />
      </Flex>
    </Box>
  );
};

interface IHyperlinkedTexts {
  matchObjects: MatchObject[];
}
const HyperlinkedTexts: React.FC<IHyperlinkedTexts> = ({ matchObjects }) => {
  const acc = new Array<JSX.Element | string>();

  for (let i = 0; i < matchObjects.length; i++) {
    const { text, link } = matchObjects[i];

    const Child = (
      <Fragment key={text + link + i}>
        {link ? (
          <Anchor href={link} target="_blank" fz="sm">
            {text}
          </Anchor>
        ) : (
          text
        )}
      </Fragment>
    );

    acc.push(Child);
  }

  // wrapping in Box or div creates automatic spacing between each section
  return <div>{acc}</div>;
};
