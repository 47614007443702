import { SegmentType } from 'components/CreateEditSegmentationModal';
import moment from '@tw/moment-cached';
import { combineReducers, Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import _db from 'utils/DB';

import {
  FilterExpressions,
  MetricsFilterExpression,
  MetricsFilterOperands,
  MetricsFilterOperator,
  ShopifyMetricsFilterOperands,
} from '@tw/types';
import { DataTypesRoles, ServicesIds } from '@tw/types/module/services';

import { INIT_SHOP, SEGMENT_INIT } from 'ducks/constants';
import { ProvidersIntegrations } from 'types/services';

export const defaultExpressionSettings: MetricsFilterExpression = {
  operand: 'campaign_name',
  operator: 'contains',
  value: '',
};

const MODAL_DATA = 'MODAL_DATA';
export const openModalSegmentation = (serviceId: ServicesIds | '', segmentId?: string) => ({
  type: MODAL_DATA,
  payload: {
    serviceId,
    segmentId,
    isOpen: serviceId.length > 0,
    isEdit: !!segmentId,
  },
});

const SEGMENT_UPDATED = 'SEGMENT_UPDATED';
const SEGMENT_UPDATING = 'SEGMENT_UPDATING';

export const createSegment = (segment, service) => {
  return async (dispatch, getState) => {
    const { segments } = getState().adSegmentations;
    const { id: segmentId }: any = await _db()
      .collection('ads-segments')
      .doc(service)
      .collection('segments')
      .add(segment);
    segments[service] = { ...segments[service], [segmentId]: segment };

    dispatch({
      type: SEGMENT_UPDATED,
      ads_segments: segments,
    });
  };
};

export const editSegment = (segment: SegmentType, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().adSegmentations;
    segments[service] = { ...segments[service], [segment.id!]: segment };
    dispatch({
      type: SEGMENT_UPDATING,
    });
    const { id, ...rest } = segment;
    await _db().collection('ads-segments').doc(service).collection('segments').doc(id).set(rest);
    dispatch({
      type: SEGMENT_UPDATED,
      ads_segments: segments,
    });
  };
};

export const deleteSegment = (segmentId: string, service: ServicesIds) => {
  return async (dispatch, getState) => {
    const { segments } = getState().adSegmentations;
    delete segments[service][segmentId];
    dispatch({
      type: SEGMENT_UPDATING,
    });
    await _db()
      .collection('ads-segments')
      .doc(service)
      .collection('segments')
      .doc(segmentId)
      .delete();
    await _db().update({ ads_segments: segments });
    dispatch({
      type: SEGMENT_UPDATED,
      ads_segments: segments,
    });
  };
};

const modalData = (state = {}, action) => {
  switch (action.type) {
    case MODAL_DATA:
      return action.payload;
    default:
      return state;
  }
};

const initialValue: FilterExpressions<
  MetricsFilterOperands | ShopifyMetricsFilterOperands,
  MetricsFilterOperator
> = {
  segmentTitle: '',
  segmentDescription: '',
  expressionList: [defaultExpressionSettings],
  enabled: true,
  id: '',
};

export const searchAds = async (
  str: string,
  serviceId: ServicesIds,
  entity: string,
  integrations: ProvidersIntegrations,
) => {
  const body = {
    service_id: serviceId,
    data_type: DataTypesRoles['ads-metrics'],
    entity,
    account_ids: integrations[serviceId]?.map((acc) => acc.id),
    name: str,
  };
  const url = '/v2/metrics-table/get-attributes-by-name';
  const { data } = await axiosInstance.post(url, body);
  return data.data;
};

const currentSegment: Reducer<
  FilterExpressions<MetricsFilterOperands | ShopifyMetricsFilterOperands, MetricsFilterOperator>
> = (state = initialValue, action) => {
  switch (action.type) {
    case MODAL_DATA:
      return state;
    default:
      return state;
  }
};

const segments: Reducer<
  Record<ServicesIds, FilterExpressions<MetricsFilterOperands, MetricsFilterOperator>>
> = (state = {} as any, action) => {
  switch (action.type) {
    case SEGMENT_INIT:
    case SEGMENT_UPDATED:
      return action.ads_segments || state;
    default:
      return state;
  }
};

const anyAdSegmentExist = (state = false, action) => {
  switch (action.type) {
    case SEGMENT_INIT:
    case SEGMENT_UPDATED:
      return (
        Object.entries(
          (action.ads_segments ?? {}) as Record<
            ServicesIds,
            FilterExpressions<MetricsFilterOperands, MetricsFilterOperator>
          >,
        ).filter(([_, seg]) => Object.values(seg).some((s) => s.enabled)).length > 0
      );
    default:
      return state;
  }
};

const now = moment();

const lastUpdate = (state = now, action) => {
  switch (action.type) {
    case INIT_SHOP:
    case SEGMENT_UPDATED:
      return moment();
    default:
      return state;
  }
};

const segmentsUpdating = (state = false, action) => {
  switch (action.type) {
    case SEGMENT_UPDATING:
      return true;
    case SEGMENT_UPDATED:
      return false;
    default:
      return state;
  }
};

export const shopifySegmentSearch = async (type: string, shopId, query): Promise<any[]> => {
  const body = {
    type,
    shopId,
    query,
  };
  const url = '/v2/shopify/mongo/search-segment';
  const { data } = await axiosInstance.post<any>(url, body);
  return data;
};

export const reducers = combineReducers({
  modalData,
  currentSegment,
  segments,
  lastUpdate,
  anyAdSegmentExist,
  segmentsUpdating,
});
