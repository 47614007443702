import { Button, Modal } from '@shopify/polaris';
import TWImage from 'components/library/TWImage/TWImage';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { type RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import useDebounce from 'utils/useDebounce';

import AsyncSelect from 'react-select/async';
import { filter } from 'lodash';

type SelectProductModalProps = {
  open: boolean;
  onClose: React.Dispatch<any>;
  onSelect: (product) => void;
  newImage: string;
};

const SelectProductModal: FC<SelectProductModalProps> = ({ open, onSelect, onClose, newImage }) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [products, setProducts] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [input, setInput] = useState<string>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const debounce = useDebounce(input!, 1000);

  useEffect(() => {
    const search = async () => {
      if (!debounce?.trim()) {
        setProducts(topProducts);
        return;
      }
      const data = await axiosInstance
        .post('v2/shopify/mongo/get-products-by-name', {
          shopId: currentShopId,
          name: input,
          page: 0,
          limit: 10,
        })
        .then((r) => {
          let formattedData = r.data.map((d: any) => ({
            label: d.title,
            value: d.handle,
            prodId: d.id,
            prodName: d.title,
            thumbnail: d.imageUrl,
            link: `www.${currentShopId}/products/${d.handle}`,
          }));
          return formattedData;
        });
      setProducts(data);
    };
    search();
  }, [currentShopId, debounce]);

  const loadProducts = useCallback(async () => {
    setLoadingProducts(true);
    const data = await axiosInstance
      .post('v2/shopify/mongo/get-bestselling-for-shop', {
        shopId: currentShopId,
        page: 0,
        limit: 10,
      })
      .then((r) => {
        let formattedData = r.data.map((d: any) => ({
          label: d.title,
          value: d.handle,
          prodId: d.id,
          prodName: d.title,
          thumbnail: d.imageUrl,
          link: `www.${currentShopId}/products/${d.handle}`,
        }));
        return formattedData;
      });
    setProducts(data);
    setTopProducts(data);
    setLoadingProducts(false);
  }, [currentShopId]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const filterColors = (data, inputValue: string) => {
    return data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    axiosInstance
      .post('v2/shopify/mongo/get-products-by-name', {
        shopId: currentShopId,
        name: inputValue,
        page: 0,
        limit: 10,
      })
      .then((r) => {
        let formattedData = r.data.map((d: any) => ({
          label: d.title,
          value: d.handle,
          prodId: d.id,
          prodName: d.title,
          thumbnail: d.imageUrl,
          link: `www.${currentShopId}/products/${d.handle}`,
        }));
        callback(filterColors(formattedData, inputValue));
      });
  };

  return (
    <Modal open={open} title="Select a product to add image to" onClose={() => onClose(false)}>
      <div className="flex flex-col p-4 items-center gap-8">
        <div className="h-[300px] w-[300px] overflow-hidden">
          <TWImage className={`w-full h-full object-cover cursor-pointer`} src={newImage || ''} />
        </div>
        <div className="relative z-20 flex gap-4 items-center">
          <AsyncSelect
            menuPlacement="top"
            styles={{
              container: (provided) => ({
                ...provided,
                width: '300px',
              }),
            }}
            className="flex-shrink-0"
            loadOptions={loadOptions}
            defaultOptions={products}
            onChange={setSelectedProduct}
          />
        </div>
        <Button
          primary
          onClick={() => {
            onSelect(selectedProduct);
            onClose(false);
          }}
        >
          Select
        </Button>
      </div>
    </Modal>
  );
};

export default SelectProductModal;
