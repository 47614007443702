import './CreativeTable.scss';

import TWTable from 'components/library/TWTable/TWTable';

import { FC, useContext } from 'react';

import { Pagination, SortDirection, Text } from '@shopify/polaris';
import { FilterMajor } from '@shopify/polaris-icons';

import { MetricsKeys } from '@tw/types';

import { CreativesCockpitContext } from '../context';
import { metrics } from 'constants/metrics/metrics';
import { formatNumber } from 'utils/formatNumber';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import moment from '@tw/moment-cached/module/timezone';
import { filterColumns } from '../CreativeDashboard/filterColumns';
import { cx } from 'utils/cx';

const CreativeTable: FC = () => {
  const {
    creatives,
    segments,
    setSortBy,
    setSortDirection,
    setPageIndex,
    sortDirection,
    sortBy,
    pageIndex,
    totalCreativesNumber,
    type,
    itemType,
    selectedColumns,
    serviceId,
    isShareMode,
    pageSize = 12,
    loadingCreatives,
    loadingSegments,
    toggleCreativeSelection,
    selectedCreatives,
    setCreativeInModal,
    currency,
    showComparisons,
    loadingColumn,
    setAdsModalProduct,
    setSkusModalProduct,
  } = useContext(CreativesCockpitContext);
  const adThumbs = useSelector((state: RootState) => state.productAnalytics.adImgs) || {};
  const adImgs = type === 'product' ? adThumbs : [];
  const { start, end } =
    useSelector((state: RootState) => state.mainDatePickerSelectionRange) || {};
  const numDays = moment(end).diff(moment(start), 'days') + 1;
  const ID_TAG = type === 'product' ? 'Product-Analytics' : 'Creative-Cockpit';
  const PRODUCT_NAME = type === 'product' ? 'Product Analytics' : 'Creative Cockpit';
  let relevantColumnsSegment, setSorting;
  const relevantColumns = filterColumns(type!, serviceId!, selectedColumns || []);

  if (type === 'Segments') {
    relevantColumnsSegment =
      Object.values({ ...relevantColumns })?.map((relevantColumnSegment) => {
        const obj = Object.assign({}, relevantColumnSegment);
        const metric = metrics[obj['key']];
        obj['Value'] =
          obj['key'] === 'preview'
            ? (segments) => <p style={{ wordBreak: 'break-word' }}>{segments?.segmentTitle}</p>
            : obj['key'] === 'type'
              ? (segments) => <p style={{ wordBreak: 'break-word' }}>Segment</p>
              : (segments) => {
                  const valueStr = formatNumber(segments?.metrics?.[obj['key']] || 0, {
                    style: metric?.format,
                    currency,
                    maximumFractionDigits: metric?.toFixed,
                    minimumFractionDigits: metric?.toFixed,
                  });
                  return <p style={{ wordBreak: 'break-word' }}>{valueStr}</p>;
                };
        return obj;
      }) || [];
    setSorting = (headingIndex: number, direction: SortDirection) => {
      setSortDirection!(direction);
      const column = relevantColumnsSegment[headingIndex];
      setSortBy!(column.key as MetricsKeys);
    };
  } else
    setSorting = (headingIndex: number, direction: SortDirection) => {
      setSortDirection!(direction);
      const column = relevantColumns[headingIndex];
      setSortBy!(column.key as MetricsKeys);
    };

  const startPage = pageIndex! * pageSize;
  const endPage = pageIndex! * pageSize + pageSize;
  const currentPageCreatives =
    type === 'Segments'
      ? segments?.slice(startPage, endPage)
      : creatives?.slice(startPage, endPage) || [];
  const noDataNode =
    type === 'Segments'
      ? [
          [
            <div className="flex items-center justify-center p-4">
              Select Segments to compare. Click the icon at the top of the page.
              <div>
                <FilterMajor width={20} height={20} className={'disabled'} />
              </div>
            </div>,
          ],
        ]
      : [[<div className="flex items-center justify-center p-4">No Data</div>]];
  return (
    <div className="CreativeTable" data-testid="CreativeTable" id={`att-${ID_TAG}-table`}>
      <TWTable
        id="creative-table"
        sortDirection={sortDirection!}
        sortBy={sortBy}
        onSort={setSorting!}
        columns={type === 'Segments' ? relevantColumnsSegment : relevantColumns}
        data={currentPageCreatives}
        stickyHeader={true}
        padding="0.5rem"
        stickyColumnIndex={2}
        loading={type === 'Segments' ? loadingSegments : loadingCreatives}
        noDataNode={noDataNode}
        type={type}
        totalsName={type === 'product' ? { singular: 'Summary', plural: 'Summary' } : undefined}
        totals={type === 'product' ? currentPageCreatives : []}
        metadata={{
          serviceId,
          loadingCreatives,
          type,
          PRODUCT_NAME,
          adImgs,
          itemType,
          toggleCreativeSelection,
          selectedCreatives,
          setCreativeInModal,
          currency,
          showComparisons,
          loadingColumn,
          setAdsModalProduct,
          setSkusModalProduct,
          currentPageCreatives,
          numDays,
          pageSize,
        }}
      />
      <div className="flex pt-6.5">
        <div className="flex items-center">
          {totalCreativesNumber! / pageSize! > 1 && (
            <Text as={'p'} variant={'headingXs'} color={'subdued'}>
              Page {pageIndex! + 1} of {Math.ceil(totalCreativesNumber! / pageSize!)}
            </Text>
          )}
        </div>
        <div
          className={cx(
            isShareMode ? 'm-auto share-mode' : 'm-auto',
            '[&_.Polaris-Button]:!border-none',
            'dark:hover:[&_.Polaris-Button]:bg-[var(--gray-dark-mode-600)]',
          )}
        >
          <Pagination
            hasPrevious={pageIndex! >= 1}
            onPrevious={() => setPageIndex?.((page) => page - 1)}
            hasNext={totalCreativesNumber! > (pageIndex! + 1) * pageSize}
            onNext={() => setPageIndex?.((page) => page + 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeTable;
