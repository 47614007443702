import { useSelector } from 'react-redux';
import { type RootState } from '../../reducers/RootType';
import {
  addSalesBySegment,
  toggleShowingModalAddSaleBySegment,
} from '../../ducks/shopify/salesBySegmentModal';
import { Modal, Stack } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { useAppDispatch } from '../../index';
import DropDown from '../ltv/DropDown';
import { FilterFieldAutocomplete } from '../ltv/FilterFieldAutocomplete';
import { shopifyOperands, shopifyOperandsNames } from '../Segmentation/types';
import { shopifySegmentSearch } from '../../ducks/serviceSegmentations';

export const ShopifySalesBySegmentModal = () => {
  const { showModalAddSaleBySegment, isFromSection } = useSelector(
    (state: RootState) => state.shopifySalesBySegmentModal,
  );

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopifySourcesDictionaryNameToId = useSelector(
    (state: RootState) => state.shopifySourcesDictionaryNameToId,
  );
  const shopifySourcesDictionaryAsArray = useSelector(
    (state: RootState) => state.shopifySourcesDictionaryAsArray as any[],
  );

  const [selectedSegment, setSelectedSegment] = useState<string>(shopifyOperands[0]);
  const [segmentValue, setSegmentValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useAppDispatch();

  const saveSalesSegment = useCallback(() => {
    dispatch(
      addSalesBySegment(
        {
          type: selectedSegment,
          id:
            selectedSegment === 'sources'
              ? shopifySourcesDictionaryNameToId[segmentValue] ?? segmentValue
              : segmentValue,
        },
        isFromSection,
      ),
    );
    onClose();
  }, [dispatch, selectedSegment, shopifySourcesDictionaryNameToId, segmentValue, isFromSection]);
  const onClose = useCallback(() => {
    dispatch(toggleShowingModalAddSaleBySegment(false, false));
  }, []);

  const searchFilter = async (searchStr: string, operand: string) => {
    const searchRes = (
      (await shopifySegmentSearch(operand, currentShopId, searchStr)) || []
    ).filter((x) => x && !x.startsWith(' '));
    let localRes: any[] = [];
    if (operand === 'sources') {
      localRes = shopifySourcesDictionaryAsArray
        .filter((x) => x.name.toLowerCase().includes(searchStr.toLowerCase()))
        .map((x) => x.name);
    }
    const finalRes = searchRes.concat(localRes);
    return finalRes.map((res) => ({
      label: res,
      value: res,
    }));
  };

  return (
    <Modal
      open={showModalAddSaleBySegment}
      onClose={onClose}
      title={'Add Sales segment'}
      primaryAction={{
        disabled: !segmentValue || searchValue !== segmentValue,
        content: 'Save',
        onAction: saveSalesSegment,
      }}
    >
      <Modal.Section>
        <Stack alignment={'center'} distribution={'equalSpacing'} wrap={false}>
          <Stack>
            <DropDown
              options={shopifyOperands.map((op) => ({
                label: shopifyOperandsNames[op],
                value: op,
              }))}
              value={selectedSegment}
              handleSelect={(val) => {
                setSelectedSegment(val);
                setSegmentValue('');
              }}
            />

            <FilterFieldAutocomplete
              initVal={searchValue}
              onSearchValueChanges={setSearchValue}
              onSelectSearchResult={({ value }) => {
                setSegmentValue(value);
                setSearchValue(value);
              }}
              searchFunc={(text) => searchFilter(text, selectedSegment)}
              placeholder={undefined}
              debounceMsTime={300}
            />
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
