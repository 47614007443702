import React, { useCallback } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { MetricItem } from './MetricItem';

// This method is needed for rendering clones of draggables
const getRenderItem = (items, className) => (provided, snapshot, rubric) => {
  const item = items[rubric.source.index];
  return (
    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
      <MetricItem {...item}></MetricItem>
    </div>
  );
};

export const ExpressionOptions: React.FC<any> = (props) => {
  return (
    <Droppable
      renderClone={getRenderItem(props.items, props.className)}
      droppableId="ExpressionOptions"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="flex flex-wrap p-[8px]">
          {props.items.map((item, index) => {
            const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
            return (
              <div key={item.id}>
                {shouldRenderClone ? (
                  <div>
                    {' '}
                    <MetricItem {...item}></MetricItem>
                  </div>
                ) : (
                  <Draggable draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <MetricItem
                          {...item}
                          onClick={() => props.addMetricItem(item)}
                        ></MetricItem>
                      </div>
                    )}
                  </Draggable>
                )}
              </div>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
