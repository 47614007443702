import { useMemo, useState, useEffect } from 'react';
import { Allotment } from 'allotment';
import { useSelectByWindowResize, Button, Text } from '@tw/ui-components';
import {
  Message,
  CodeInterpreterResponse,
  MessageData,
  GridOptions,
  GridColumnOptions,
  NlqResponse,
} from './types/willyTypes';
import {
  convertCodeResponseToMessageCodeInterpreterResponse,
  convertNlqResponseToMessageData,
} from './utils/willyUtils';
import { WillyMessageWidget } from './WillyMessageWidget';
import { MENU_SIZE } from './constants';
import { openDashSettingModal } from '$stores/willy/$dashSettingsModal';
import { guessDefaultType } from './hooks/useDefaultType';
import { DEFAULT_AXIS_DOMAIN } from './constants';
import { cohortColor } from 'constants/general';
import { useStoreValue } from '@tw/snipestate';
import { $dialect } from '$stores/$user';

export const WillyBuilder: React.FC<{
  messages: Message[];
  conversationId: string;
}> = ({ messages, conversationId }) => {
  const windowWidth = useSelectByWindowResize(({ width }) => width);
  const dialect = useStoreValue($dialect);
  const [builderData, setBuilderData] = useState({});

  const messagesWithCodeAndData = useMemo(() => {
    return messages
      .filter(
        (m) =>
          m.toolResults?.name === 'TextToSQL' ||
          m.toolResults?.name === 'Forecasting' ||
          m.toolResults?.name === 'MarketingMixModel' ||
          m.toolResults?.name === 'TextToPython',
      )
      .filter((m) => {
        if (m.toolResults?.name === 'TextToSQL') {
          return !!m.toolResults?.nlqResponse;
        } else if (
          m.toolResults?.name === 'Forecasting' ||
          m.toolResults?.name === 'MarketingMixModel'
        ) {
          return !!m.toolResults?.message;
        } else if (m.toolResults?.name === 'TextToPython') {
          return !!m.toolResults;
        }
        return false;
      })
      .map<
        Message & {
          messageCode?: CodeInterpreterResponse;
          messageData?: MessageData;
        }
      >((m) => {
        let nlqResponse: NlqResponse | undefined;
        let codeInterpreterResponse: CodeInterpreterResponse | undefined;

        if (m.toolResults?.name === 'TextToSQL') {
          nlqResponse = m.toolResults?.nlqResponse;
        } else if (m.toolResults?.name === 'Forecasting') {
          nlqResponse = m.toolResults?.message;
        } else if (m.toolResults?.name === 'MarketingMixModel') {
          nlqResponse = m.toolResults?.message;
        } else if (m.toolResults?.name === 'TextToPython') {
          codeInterpreterResponse = m.toolResults;
        }

        return {
          ...m,
          messageCode: codeInterpreterResponse
            ? convertCodeResponseToMessageCodeInterpreterResponse(codeInterpreterResponse)
            : undefined,
          messageData: nlqResponse ? convertNlqResponseToMessageData(nlqResponse) : undefined,
        };
      });
  }, [messages]);

  const openReportDashboard = () => {
    const formattedWidgetData = messagesWithCodeAndData.map((message) => {
      const messageData = message.messageData;
      const messageCode = message.messageCode;
      const type = guessDefaultType(
        messageData?.data,
        messageData?.dataColumns,
        message.originalQuestion,
        messageData?.dataType,
        messageData?.visualizationType,
      );

      const foundBuilderData =
        builderData[messageData?.queryId || messageCode?.queries?.[0]?.id || ''];

      return {
        type,
        title: message.title || messageCode?.data?.question || '',
        queryId: messageData?.queryId || messageCode?.queries?.[0]?.id || '',
        metrics: messageData?.metrics || [],
        parameters: messageData?.parameters,
        codeResult: messageCode,
        dataType: messageData?.dataType,
        dialect: messageData?.dialect || dialect,
        queries: [
          {
            id: messageData?.queryId || messageCode?.queries?.[0]?.id || '',
            query: messageData?.query || messageCode?.queries?.[0]?.query || '',
            question: messageData?.question || messageCode?.queries?.[0]?.file_name || '',
          },
        ],
        stacked: false,
        incrementedStacked: false,
        breakdownMode: false,
        wrapText: false,
        grid: 'flex' as GridOptions,
        gridColumns: 2 as GridColumnOptions,
        twoColumnMobile: false,
        yAxisDomain: DEFAULT_AXIS_DOMAIN,
        allowDataOverflow: false,
        dimension: '',
        withoutMainQuery: false,
        hasGlobalConditionalFormatting: false,
        globalConditionalFormattingColor: cohortColor,
        // spread available builder data from WillyMessageWidget
        ...(foundBuilderData || {}),
      };
    });

    openDashSettingModal(true, false, undefined, formattedWidgetData, undefined, conversationId);
  };

  return (
    <Allotment.Pane
      snap
      className="transition-[width] duration-300 ease-in-out sm:transition-none h-full"
      preferredSize={windowWidth / 3}
    >
      {messagesWithCodeAndData.length > 0 ? (
        <Allotment vertical>
          <Allotment.Pane maxSize={MENU_SIZE} minSize={MENU_SIZE} className="flex justify-between">
            <div className="willy-dash-header flex gap-4 justify-between flex-row items-center lg:w-full grow overflow-hidden overflow-x-auto px-6 py-4 lg:px-[24px]">
              <Text fw="bold">Builder</Text>
              <Button onClick={openReportDashboard}>Create Dashboard</Button>
            </div>
          </Allotment.Pane>

          <Allotment>
            <Allotment.Pane className="dash-pane-wrapper !overflow-auto willy-main-pane relative @container w-full">
              <div className="flex flex-col gap-6 p-6 w-full">
                {messagesWithCodeAndData.map((message, index) => {
                  return (
                    message && (
                      <div
                        key={index}
                        className="min-h-[20px] flex flex-col items-start w-full overflow-auto shadow rounded"
                      >
                        <div className="group/message flex items-start gap-2 w-full">
                          <div className="flex-auto flex flex-col gap-4 w-full h-full">
                            <WillyMessageWidget
                              codeResult={message.messageCode}
                              data={message.messageData}
                              message={message}
                              context="dashboard"
                              builderData={builderData}
                              setBuilderData={setBuilderData}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </Allotment.Pane>
          </Allotment>
        </Allotment>
      ) : (
        <div className="flex flex-col items-center justify-center h-full text-center">
          <p className="text-gray-400 p-4">
            {messages.length > 0
              ? 'No widgets found, yet..'
              : 'Start chatting with Moby, or select an existing conversation to view widgets'}
          </p>
        </div>
      )}
    </Allotment.Pane>
  );
};
