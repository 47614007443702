import { Drawer, Icon, Text } from '@tw/ui-components';
import { useShowFreeTrialBanner } from '../UpgradePlan/FreeTrialBanner';
import { MENU_SIZE } from '../constants';
import { FREE_TRIAL_BANNER_HEIGHT } from '$stores/willy/$upgradePopupManager';

type SequenceBuilderDrawerProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
};

export const SequenceBuilderDrawer: React.FC<SequenceBuilderDrawerProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  const showFreeTrialBanner = useShowFreeTrialBanner();
  const headerSize = showFreeTrialBanner ? MENU_SIZE + FREE_TRIAL_BANNER_HEIGHT : MENU_SIZE;
  return (
    <Drawer
      opened={!!open}
      onClose={() => onClose()}
      position="right"
      padding={0}
      withCloseButton={false}
      topOffset={headerSize}
      closeOnClickOutside={true}
      withOverlay={false}
      withinPortal={true}
    >
      <Drawer.Body m={0} p={0}>
        <Drawer.Title>
          <div className="flex justify-between w-full items-center border-0 border-b border-solid border-gray-300 p-5">
            <Text fz={18} fw={500}>
              {title}
            </Text>
            <div
              className="cursor-pointer"
              onClick={() => {
                onClose();
              }}
            >
              <Icon name="close" />
            </div>
          </div>
        </Drawer.Title>
        {children}
      </Drawer.Body>
    </Drawer>
  );
};
