import { FeatureFlagRankedControlListValue } from '@tw/feature-flag-system/module/types';
import { isFeatureFlagRankedControlListValue } from '@tw/feature-flag-system/module/utils';

export const isFeatureFlagRankedControlList = (
  list: unknown,
): list is FeatureFlagRankedControlListValue[] => {
  if (!Array.isArray(list)) return false;

  for (const item of list) {
    if (!isFeatureFlagRankedControlListValue(item)) return false;
  }

  return true;
};
