import { useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { uploadMedia } from 'utils/media';
import { DropZone, Modal, Spinner } from '@shopify/polaris';
import { ReactComponent as FileIcon } from '../../icons/file_image.svg';
import { useAppDispatch } from 'index';
import { closeContentHubUploadMedia } from 'ducks/actions';
import CreativeHubDropzone from './CreativeHubDropzone';

type UploadMediaProps = {
  gotToUploaded: () => void;
};

const UploadMedia: React.FC<UploadMediaProps> = ({ gotToUploaded }) => {
  const dispatch = useAppDispatch();
  const uploadMediaOpen = useSelector((state: RootState) => state.contentHubUploadMediaOpen);
  const upload_to_folder = useSelector((state: RootState) => state.contentHubUploadMediaToFolder);

  return (
    <Modal
      title="Upload"
      titleHidden
      large
      open={uploadMediaOpen}
      onClose={() => dispatch(closeContentHubUploadMedia())}
    >
      <div style={{ padding: '40px' }} className="upload-media">
        <CreativeHubDropzone afterUpload={gotToUploaded} upload_to_folder={upload_to_folder} />
      </div>
    </Modal>
  );
};

export default UploadMedia;
