import { SET_ALL_SECTIONS, SET_CURRENT_SECTION, SET_SHOWING_MODAL } from 'ducks/constants';
import { SummarySection } from 'types/SummarySection';

type InitialState = {
  currentSection: SummarySection | null;
  allSections: any;
  isShowingModal: boolean;
};
type Action =
  | { type: typeof SET_SHOWING_MODAL; payload: boolean }
  | { type: typeof SET_ALL_SECTIONS; payload: any }
  | { type: typeof SET_CURRENT_SECTION; payload: SummarySection };

const initialState: InitialState = {
  isShowingModal: false,
  allSections: null,
  currentSection: null,
};

export const reducer = (state = initialState, action: Action): InitialState => {
  switch (action.type) {
    case SET_SHOWING_MODAL:
      return {
        ...state,
        isShowingModal: action.payload,
      };
    case SET_ALL_SECTIONS:
      return {
        ...state,
        allSections: action.payload,
      };
    case SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload,
      };

    default:
      return state;
  }
};
