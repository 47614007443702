import { RECEIVE_SHOPIFY_SEGMENTS } from './newShopify';
import { ShopifySegmentType } from '../../types/shopify';
import { getShopifySourcesDictionary } from '../../utils/DB';

const RECEIVE_SHOPIFY_SOURCES_DICTIONARY = 'RECEIVE_SHOPIFY_SOURCES_DICTIONARY';

export const loadShopifySourcesDictionary = () => async (dispatch) => {
  const dic = await getShopifySourcesDictionary();
  dispatch(receiveShopifySourcesDictionary(dic));
};

const receiveShopifySourcesDictionary = (shopifySourcesDictionary) => ({
  type: RECEIVE_SHOPIFY_SOURCES_DICTIONARY,
  shopifySourcesDictionary,
});

const shopifySegmentSources = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return action.shopifySegments?.[ShopifySegmentType.SOURCES] ?? [];
    default:
      return state;
  }
};

const loadingShopifySourcesDictionary = (state = true, action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SOURCES_DICTIONARY:
      return false;
    default:
      return state;
  }
};

const shopifySourcesDictionaryIdToName = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SOURCES_DICTIONARY:
      return action.shopifySourcesDictionary;
    default:
      return state;
  }
};

const shopifySourcesDictionaryNameToId = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SOURCES_DICTIONARY:
      return Object.entries(action.shopifySourcesDictionary as any[]).reduce(
        (acc, [id, name]) => ({ ...acc, [name]: id }),
        {},
      );
    default:
      return state;
  }
};

const shopifySourcesDictionaryAsArray = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SOURCES_DICTIONARY:
      return Object.entries(action.shopifySourcesDictionary).map(([id, name]) => ({ id, name }));
    default:
      return state;
  }
};

export const reducers = {
  shopifySegmentSources,
  loadingShopifySourcesDictionary,
  shopifySourcesDictionaryAsArray,
  shopifySourcesDictionaryIdToName,
  shopifySourcesDictionaryNameToId,
};
