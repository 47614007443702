import React from 'react';
import { summaryRefreshOnPress } from '../ducks/actions';
import { Text, Tooltip } from '@shopify/polaris';
import { useAppDispatch } from 'index';
import { type RootState } from 'reducers/RootType';
import { useSelector } from 'react-redux';
import { ActionIcon, Flex, Size } from '@tw/ui-components';

type SummaryRefreshButtonProps = {
  hideLabel?: boolean;
  customOnPress?: () => void;
  buttonSize?: Exclude<Size, 0>;
};

const SummaryRefreshButton: React.FC<SummaryRefreshButtonProps> = (props) => {
  const { customOnPress, hideLabel, buttonSize = 'lg' } = props;
  const summaryLastRefresh = useSelector((state: RootState) => state.summaryLastRefresh);
  const dispatch = useAppDispatch();

  const format = 'LT';
  const label = summaryLastRefresh.local().format(format);

  return (
    <Flex gap="xs" align="center">
      {!hideLabel && (
        <Text as="p" variant="bodyMd">
          Last Update: {label}
        </Text>
      )}
      {
        <Tooltip content={'Refresh Data'}>
          <ActionIcon
            size={buttonSize}
            variant="activator"
            radius="sm"
            onClick={() => {
              dispatch(summaryRefreshOnPress());
              customOnPress && customOnPress();
            }}
            icon="refresh"
            iconSize={buttonSize === 'md' ? 16 : 20}
          />
        </Tooltip>
      }
    </Flex>
  );
};

export default SummaryRefreshButton;
