import { useCallback, useEffect, useMemo, useState } from 'react';
import { Toast } from '@shopify/polaris';

import { useQueryParams } from 'utils/useQueryParams';

enum ErrorMessages {
  INSTALL_FROM_APP_ATTEMPTED = 'install_from_app_attempted',
  INSTALL_SLACK_ERROR = 'install_slack_error',
}

const GeneralErrorAlert = () => {
  const {
    error_message: errorMessage,
    warn_message: warnMessage,
    shop: shopName,
    channelId,
  } = useQueryParams();

  const [active, setActive] = useState(true);
  const [alertText, setAlertText] = useState('');

  const alertType = useMemo(() => {
    if (errorMessage) return 'error';
    else if (warnMessage) return 'warn';
  }, [errorMessage, warnMessage]);

  useEffect(() => {
    const message = errorMessage || warnMessage;
    switch (message) {
      case ErrorMessages.INSTALL_FROM_APP_ATTEMPTED:
        setAlertText(
          `In order complete the Shopify app installation,
          you must log in or sign up for Triple Whale and add your shop${
            shopName ? `, ${shopName},` : ''
          } to your account.`,
        );
        break;
      case ErrorMessages.INSTALL_SLACK_ERROR:
        setAlertText(
          `Cannot connect to Slack (for channel-id ${channelId}), permission error, please choose a different channel.`,
        );
    }
  }, [channelId, errorMessage, shopName, warnMessage]);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  return (
    <>
      {alertText && active && (
        <Toast
          content={alertText}
          error={alertType === 'error'}
          onDismiss={toggleActive}
          duration={9000}
        />
      )}
    </>
  );
};

export default GeneralErrorAlert;
