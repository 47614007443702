import { toggleShowingModalSection } from 'ducks/actions/modalCustomSectionActions';
import { useAppDispatch } from 'index';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import SummaryDatePicker from 'components/SummaryDatePicker';
import SummaryRefreshButton from 'components/SummaryRefreshButton';
import { selectAllSections } from 'utils/selectors';
import { SummarySection } from 'types/SummarySection';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $activeAppVersion, useActiveRoute } from '$stores/nav-config-stores';
import { StatsFilterBar } from 'components/StatsFilterBar';
import { useFeatureFlagSummarySectionFilter } from 'hooks/useFeatureFlagSummarySectionFilter';
import { ActionIcon, Button as TWButton, Flex, Icon, Menu, Text } from '@tw/ui-components';
import { WillyMainChatRef } from 'components/Willy/types/willyTypes';
import { SummarySectionPicker } from 'components/SummarySectionPicker/SummarySectionPicker';
import { useSummaryScroll } from 'components/SummarySectionPicker';
import { useStoreValue } from '@tw/snipestate';
import { MetricsCurrentlyFiltered } from 'components/MetricsCurrentlyFiltered';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshDateRanges } from '$stores/willy/$dateRange';
import { CurrencySection } from './CurrencySection';
import { LayoutSection } from './LayoutSection';
import { CreateNewSection } from './CreateNewSection';
import { AdminSection } from './AdminSection';
import { ChatWithQueryButton } from '../../Willy/ChatWithQueryButton';

type SummaryHeaderProps = {
  chatOpen?: boolean;
  setChatOpen?: (val: boolean) => void;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
};

const SummaryHeader: React.FC<SummaryHeaderProps> = ({ chatOpen, setChatOpen, mainChatRef }) => {
  const dispatch = useAppDispatch();
  const { shouldNotBeSeen: isChatBlocked } = useFeatureFlag(FeatureFlag.CHAT_FF);
  const { summarySectionsFilter } = useFeatureFlagSummarySectionFilter();

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { isAddModalSection } = useSelector((state: RootState) => state.modalCustomSection);

  useEffect(() => {
    const openModalSection = searchParams.get('openModalSection');
    if (openModalSection && openModalSection === '1') {
      dispatch(toggleShowingModalSection(true));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (isAddModalSection) {
      searchParams.set('openModalSection', '1');
    } else {
      searchParams.delete('openModalSection');
    }

    navigate(
      { pathname: window.location.pathname, search: searchParams.toString() },
      { replace: true },
    );
  }, [navigate, isAddModalSection, searchParams]);

  const isSmall = useIsSmall();

  const isAdmin = useSelector((state: RootState) => state.user.isAdminClaim);
  const { activeRoute } = useActiveRoute();
  const isBenchmarks = activeRoute?.label === 'Benchmarks';
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  let sections: SummarySection[] = useSelector(selectAllSections);
  sections = sections.filter(summarySectionsFilter);

  const [isPopoverActive, setIsPopoverActive] = useState(false);

  const shouldShowRefreshButton = !mainDatePickerSelectionRange || isBenchmarks ? false : true;

  const isAmazonConnected: boolean = useSelector(
    (state: RootState) =>
      state.isAmazonConnectedNA || state.isAmazonConnectedEU || state.isAmazonConnectedFE,
  );

  const clickOnRefresh = () => {
    refreshDateRanges();
  };

  const includeAmazonSection = useMemo(
    () => (
      <Menu.Item>
        <StatsFilterBar />
      </Menu.Item>
    ),
    [],
  );

  useSummaryScroll(sections);

  return (
    <Flex w="100%" align="center" gap="xs" wrap={`${isThreePointOh ? 'nowrap' : 'wrap'}`}>
      {isThreePointOh && !isSmall && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="house" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Summary
          </Text>
        </Flex>
      )}
      <MetricsCurrentlyFiltered />
      {shouldShowRefreshButton && !isThreePointOh && (
        <SummaryRefreshButton hideLabel={true} customOnPress={clickOnRefresh} />
      )}
      {!isBenchmarks && !isThreePointOh && <SummarySectionPicker sections={sections} />}
      <SummaryDatePicker
        showGroupByButton={true}
        compareSeparately={!!isThreePointOh}
        buttonSize={isThreePointOh ? 'xs' : undefined}
        showCompareButton={true}
      />
      <div className={`dash-menu-right-area flex gap-4 ${isThreePointOh ? 'ml-auto' : ''}`}>
        {(!isSmall || !isThreePointOh) && (
          <Menu
            opened={isPopoverActive}
            onClose={() => setIsPopoverActive(!isPopoverActive)}
            closeOnItemClick={false}
          >
            <Menu.Target>
              {!isThreePointOh ? (
                <div>
                  <ActionIcon
                    id="summary-settings"
                    size="lg"
                    radius="sm"
                    onClick={() => setIsPopoverActive(!isPopoverActive)}
                    variant="activator"
                    icon="horizontal-slider"
                  />
                </div>
              ) : (
                <TWButton
                  id="summary-settings"
                  size="xs"
                  onClick={() => setIsPopoverActive(!isPopoverActive)}
                >
                  Edit Dashboard
                </TWButton>
              )}
            </Menu.Target>
            <Menu.Dropdown>
              {!isBenchmarks && (
                <>
                  <CreateNewSection closePopover={() => setIsPopoverActive(false)} />
                  <Menu.Divider />
                </>
              )}
              {isAmazonConnected && !isThreePointOh ? (
                <>
                  {includeAmazonSection} <Menu.Divider />
                </>
              ) : null}
              <LayoutSection closePopover={() => setIsPopoverActive(false)} />
              {!!isAdmin && (
                <>
                  <Menu.Divider />
                  <AdminSection />
                </>
              )}
              {!isThreePointOh && (
                <>
                  <Menu.Divider />
                  <CurrencySection />
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
        {isThreePointOh && !isChatBlocked && (
          <ChatWithQueryButton
            chatOpen={chatOpen}
            setChatOpen={setChatOpen}
            mainChatRef={mainChatRef}
            onlyIcon={isSmall}
            source="summary"
          />
        )}
      </div>
      {!isBenchmarks && isThreePointOh && (
        <SummarySectionPicker sections={sections} buttonSize="xs" />
      )}
      {shouldShowRefreshButton && isThreePointOh && !isSmall && (
        <SummaryRefreshButton hideLabel={true} customOnPress={clickOnRefresh} buttonSize="md" />
      )}
      {isThreePointOh && !isSmall && (
        <Menu closeOnItemClick={false}>
          <Menu.Target>
            <div>
              <ActionIcon icon="three-dots" variant="activator" />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {isAmazonConnected && (
              <>
                {includeAmazonSection} <Menu.Divider />
              </>
            )}
            <CurrencySection />
          </Menu.Dropdown>
        </Menu>
      )}
    </Flex>
  );
};

export default SummaryHeader;
