import { Box, Button, Checkbox, Group, Modal, Text } from '@tw/ui-components';
import { useState } from 'react';
import { $statusConfirmModal } from './store';
import { useStoreValue } from '@tw/snipestate';

//
// COMPONENTS
//
export const StatusChangeConfirmationModal = () => {
  const [loading, setLoading] = useState(false);
  const { opened, message, action } = useStoreValue($statusConfirmModal);

  const handleClick = async () => {
    try {
      setLoading(true);
      await action?.();
    } finally {
      setLoading(false);
      $statusConfirmModal.set((x) => ({ ...x, opened: !x.opened }));
    }
  };

  const handleClose = () => {
    if (loading) return;
    $statusConfirmModal.set((x) => ({ ...x, opened: false }));
  };

  return (
    <Modal
      title={
        <Text fz="xl" fw={600}>
          Confirm Action
        </Text>
      }
      opened={opened}
      onClose={handleClose}
    >
      <Box mb="lg">{message}</Box>
      <Group justify="right">
        <Button loading={loading} onClick={handleClick}>
          Confirm
        </Button>
        <Button variant="white" loading={loading} onClick={handleClose}>
          Cancel
        </Button>
      </Group>
      <PopupDisabler />
    </Modal>
  );
};

//
// UTILS
//
const PopupDisabler = () => {
  const { disabled = false } = useStoreValue($statusConfirmModal);

  const handleChange = () => $statusConfirmModal.set((x) => ({ ...x, disabled: !x.disabled }));

  return (
    <>
      <Checkbox
        checked={disabled}
        onChange={handleChange}
        size="sm"
        label={<Text span>Don't ask me again</Text>}
        labelPosition="right"
      />
      <Text span fz="xs">
        This will disable the ads management assistant until your next session.
      </Text>
    </>
  );
};
