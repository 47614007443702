import moment from '@tw/moment-cached/module/timezone';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, DatePicker, Range } from '@shopify/polaris';
import { Flex, Button as TwButton, Popover as TwPopover } from '@tw/ui-components';

import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

import { RangeWithId } from 'types/general';

import { Moment } from '@tw/moment-cached';
import { DatePickerTimePeriods } from '../useDatePickerSelectedOptions';
import { useEarliestDate } from '../useEarliestDate';
import LockedFeatureIndicator from '../library/LockedFeatures/LockedFeatureIndicator';
import { getPopoverOpenerButtonTitle } from '../useGetPopoverOpenerButtonTitle';
import { CurrentDateRange } from '../../$stores/willy/$dateRange';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import { buildDatePickerCompareOptions } from '../useDatePickerCompareOptions';
import { DashboardPrevDateRange } from './types/willyTypes';
import { DateRangeOptionsList } from 'components/MainDatePicker/DateRangeOptionsList';
import { DATE_PICKER_HEIGHT_VAR } from 'components/TopBar/constants';

type WillyCurrentDatePickerProps = {
  earliestDate: Date;
  timezone: string;
  selectedOption?: PreviousPeriodIds | 'custom';
  currentDateRange: CurrentDateRange;
  onValueChanged: (option: DashboardPrevDateRange) => void;
  start: Moment;
  end: Moment;
  disabled?: boolean;
};

export const WillyPrevDatePicker: React.FC<WillyCurrentDatePickerProps> = ({
  earliestDate,
  currentDateRange,
  timezone,
  selectedOption,
  onValueChanged,
  start,
  end,
  disabled,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [year, setYear] = useState<number>(moment().year());
  const [month, setMonth] = useState<number>(moment().month());
  const [datePickerValue, setDatePickerValue] = useState<RangeWithId | null>(null);

  useEffect(() => {
    setDatePickerValue({ start: start.toDate(), end: end.toDate() });
  }, [start, end]);

  const earliestDateForDisplay = useEarliestDate(earliestDate);

  const { numericLimit: datePickerLimitation } = useFeatureFlag(FeatureFlag.LIMIT_LOOKBACK_FF);

  const options = useMemo(() => {
    let options = buildDatePickerCompareOptions(currentDateRange, {
      start,
      end,
      //id: selectedOption ?? 'custom',
    });
    if (earliestDateForDisplay && datePickerLimitation) {
      const earliestDateFreeFeature = moment()
        .subtract(datePickerLimitation, 'month')
        .startOf('day');
      options = options.map((e) => {
        return { ...e, disabled: earliestDateFreeFeature?.isAfter(e.start) };
      });
    }
    return options;
  }, [currentDateRange, datePickerLimitation, earliestDateForDisplay, end, start]);

  const currentPopoverLabel = useMemo(() => {
    const option = options.find((o) => {
      return o.id === selectedOption;
    });
    return option?.id === 'custom'
      ? option?.labelElement
      : option?.label || getPopoverOpenerButtonTitle({ start, end });
  }, [end, options, selectedOption, start]);

  const _onSelectionChange = useCallback((_val: Range) => {
    setDatePickerValue(_val);
  }, []);

  const _onApply = useCallback(() => {
    let { start, end } = datePickerValue || {};
    start = moment()
      .year(start!.getFullYear())
      .month(start!.getMonth())
      .date(start!.getDate())
      .startOf('day') as any;
    end = moment()
      .year(end!.getFullYear())
      .month(end!.getMonth())
      .date(end!.getDate())
      .endOf('day') as any;

    // highlight the relevant option if current selection much one of them
    const e = moment(end);
    const option = options.find((o) => {
      return (
        (o.start.isSame(start) && o.end.isSame(end)) ||
        (o.start.isSame(start) && o.end.isSame(e.startOf('day')))
      );
    });

    onValueChanged({
      id: option?.id ?? DatePickerTimePeriods.CUSTOM,
      start: moment(start),
      end: moment(end),
    });

    setIsPopoverOpen((x) => !x);
  }, [datePickerValue, onValueChanged, options]);

  const onOptionSelect = useCallback(
    (val: PreviousPeriodIds) => {
      const { start, end, id } = options.find((o) => o.id === val) ?? {};
      onValueChanged({
        id: id ?? DatePickerTimePeriods.CUSTOM,
        start: start ?? moment(),
        end: end ?? moment(),
      });
      setIsPopoverOpen((x) => !x);
    },
    [onValueChanged, options],
  );

  return (
    <div className={`flex gap-4 items-center`}>
      <TwPopover
        shadow="sm"
        opened={isPopoverOpen}
        position="bottom-start"
        onClose={() => setIsPopoverOpen(false)}
      >
        <TwPopover.Target>
          <span>
            <TwButton
              variant="activator"
              onClick={() => setIsPopoverOpen((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
              disabled={disabled}
            >
              {currentPopoverLabel}
            </TwButton>
          </span>
        </TwPopover.Target>
        <TwPopover.Dropdown bg="white" fz="sm" p={0} mah={DATE_PICKER_HEIGHT_VAR} overflow="hidden">
          <Flex direction="column">
            <div
              className="flex flex-col sm:flex-row sm:h-[270px] sm:max-w-[650px] max-w-[300px] overflow-y-hidden"
              aria-label="Date Selections"
            >
              <DateRangeOptionsList
                options={options.map((o) => ({
                  value: o.id,
                  label: o.labelElement,
                  selected: selectedOption === o.id,
                  onClick: () => onOptionSelect(o.id),
                }))}
              />
              {!!datePickerLimitation && (
                <LockedFeatureIndicator
                  featureFlag={FeatureFlag.LIMIT_LOOKBACK_FF}
                  layout="vertical"
                  extraData={{ targetToUnlock: 12 }}
                />
              )}
              <div className="flex-[2] sm:flex-[1] flex flex-col justify-start gap-4 p-6.5">
                <DatePicker
                  multiMonth={false}
                  month={month}
                  year={year}
                  onChange={(date) => {
                    _onSelectionChange(date);
                  }}
                  onMonthChange={(month, year) => {
                    setMonth(month);
                    setYear(year);
                  }}
                  selected={datePickerValue!}
                  allowRange
                  disableDatesAfter={
                    new Date(
                      moment().toDate().toLocaleString('en-US', {
                        timeZone: moment().tz(),
                      }),
                    )
                  }
                  disableDatesBefore={earliestDateForDisplay.toDate()}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between items-end p-6.5">
              <div>
                <span
                  style={{ display: 'block', color: 'var(--p-text-subdued)', fontSize: '10px' }}
                >
                  Timezone: {timezone?.replace(/_/g, ' ')}
                </span>
              </div>
              <div className="flex items-center gap-4">
                <Button primary onClick={() => _onApply()} id="tr-date-picker-apply">
                  Apply
                </Button>
              </div>
            </div>
          </Flex>
        </TwPopover.Dropdown>
      </TwPopover>
    </div>
  );
};
