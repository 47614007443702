import { WillyParameter } from './types/willyTypes';
import { WillyDynamicField } from './WillyDynamicField';
import { useMemo, useState } from 'react';
import { $tables, $tablesWithBeta } from '$stores/willy/$tables';
import { useStoreValue } from '@tw/snipestate';
import { DynamicFieldsPopup } from './DynamicFieldsPopup';
import { BqTable } from '@tw/willy-data-dictionary';

type WillyDynamicFieldsProps = {
  parameters: WillyParameter[];
  visibleParameters: string[];
  parametersChanged: (parameters: WillyParameter[]) => Promise<void>;
  dashboardFilters?: boolean;
  query?: string;
  hideDateFields?: boolean;
  wrapperClassName?: string;
};
export const WillyDynamicFields: React.FC<WillyDynamicFieldsProps> = ({
  parameters = [],
  visibleParameters = [],
  parametersChanged,
  query,
  dashboardFilters,
  hideDateFields,
  wrapperClassName = '',
}) => {
  const tablesWithBeta = useStoreValue($tablesWithBeta);
  const tables = useStoreValue($tables);
  const [visibleParametersModalOpen, setVisibleParametersModalOpen] = useState(false);

  const visibleParams = useMemo(() => {
    return parameters
      .filter((p) => visibleParameters.includes(p.column))
      .filter((p) => {
        const isDateColumn = ['start_date', 'end_date', 'event_date'].includes(p.column);
        if (isDateColumn && hideDateFields) {
          return false;
        }
        return true;
      });
  }, [parameters, visibleParameters, hideDateFields]);

  const effectiveTables = useMemo(() => {
    const pixelJoinedTable = tablesWithBeta.find((t) => t.id === 'pixel_joined_tvf');

    const tablesWithDeprecated: BqTable[] = [
      {
        id: 'pixel_joined_table',
        name: pixelJoinedTable?.name || '',
        columns: pixelJoinedTable?.columns || [],
      },
      ...tablesWithBeta,
    ];
    if (!query) {
      return tablesWithDeprecated;
    }
    return tablesWithDeprecated.filter((t) => query.includes(t.id));
  }, [query, tablesWithBeta]);

  return (
    <div className="max-w-full h-full flex">
      <div className={`flex items-center px-4 overflow-x-auto gap-4 ${wrapperClassName}`}>
        {visibleParams?.map((parameter, i) => {
          return (
            <WillyDynamicField
              key={parameter.column + i}
              effectiveTables={effectiveTables}
              parameter={parameter}
              parameters={visibleParams}
              parametersChanged={parametersChanged}
              inPopover
            />
          );
        })}

        {!!dashboardFilters && (
          <DynamicFieldsPopup
            context="dashboard"
            open={visibleParametersModalOpen}
            setOpen={setVisibleParametersModalOpen}
            parameters={parameters.map((p) => {
              return {
                ...p,
                visible: visibleParameters.includes(p.column),
              };
            })}
            tables={tables}
            onChanged={async (parameters) => {
              await parametersChanged(parameters);
              // setVisibleParametersModalOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
