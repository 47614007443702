import { ActionIcon, Flex, Size } from '@tw/ui-components';
import { SettingsAvatar } from '../../blocks/SettingsAvatar';
import { $navOpen } from 'components/Nav/stores';

export const TopSection = () => {
  return (
    // TODO: Fix coercion here
    <Flex px={5 as unknown as Size} justify="space-between" align="center">
      <SettingsAvatar />
      <ActionIcon
        variant="transparent"
        icon="close"
        iconSize={12}
        onClick={() => $navOpen.set(false)}
      />
    </Flex>
  );
};
