import moment from '@tw/moment-cached/module/timezone';
import { Reducer } from 'redux';
import { getQueryStringStartEnd } from 'utils/getQueryStringStartEnd';

import { Granularity } from '@tw/types';

import {
  ALL_SHOPS_STATS_RECEIVED,
  LOADING_STATS,
  LOADING_STATS_COMPARISONS,
  LOADING_STATS_LTV,
  RECEIVED_PREVIOUS_PERIOD_STATS,
  RECEIVED_STATS_COMPARISONS,
  SET_CONVERSION_RATE,
  STATS_RECEIVED,
  INIT_SHOP,
  SELECTED_STATS_SERVCIES_CHANGED,
} from 'ducks/constants';
import { STATS_LTV_RECEIVED } from './ltv';

export const GRANULARITY_CHANGED = 'GRANULARITY_CHANGED';
export const REGROUPING_STATS_START = 'REGROUPING_STATS_START';
export const REGROUPING_STATS_END = 'REGROUPING_STATS_END';
export const REGROUPING_PREVIOUS_STATS_START = 'REGROUPING_PREVIOUS_STATS_START';
export const REGROUPING_PREVIOUS_STATS_END = 'REGROUPING_PREVIOUS_STATS_END';

const groupStatsBy = (
  state: Exclude<Granularity, 'total'> = 'hour',
  action,
): Exclude<Granularity, 'total'> => {
  switch (action.type) {
    case GRANULARITY_CHANGED:
      return action.payload;
    default:
      return state;
  }
};

export const groupBtnPressed = (group) => {
  return (dispatch, getState) => {
    dispatch({ type: REGROUPING_STATS_START });
    dispatch({
      type: GRANULARITY_CHANGED,
      payload: group,
    });
    //dispatch(getNewStatsForAllServices(true));
    //dispatch(getStatsSamePeriodComparison(allActiveOrderSegmentMetrics(customMetrics,summarySectionsCustomization,orderTags,false)));
  };
};

export const isGroupBtnDisabled = ({ mainDatePickerSelectionRange, group }) => {
  const { start, end } = Object(mainDatePickerSelectionRange);
  const isOneDay = moment(start).diff(end, 'days') === 0;
  const isOneGroup = moment(start).diff(end, group) === 0;

  if (!isOneDay && group === 'hour') {
    return true;
  }

  if (isOneGroup && isOneDay && group === 'day') {
    return false;
  }

  if (isOneGroup) {
    return true;
  }
  return false;
};

const regroupingStats = (state = false, action) => {
  switch (action.type) {
    case REGROUPING_STATS_START:
      return true;
    case REGROUPING_STATS_END:
      return false;
    default:
      return state;
  }
};

const regroupingPreviousPeriodStats = (state = false, action) => {
  switch (action.type) {
    case REGROUPING_PREVIOUS_STATS_START:
      return true;
    case REGROUPING_PREVIOUS_STATS_END:
      return false;
    default:
      return state;
  }
};

const loadingStats = (state = false, action) => {
  switch (action.type) {
    case LOADING_STATS:
      return true;
    case STATS_RECEIVED:
    case ALL_SHOPS_STATS_RECEIVED:
      // case NEW_STATS_RECEIVED:
      return false;
    default:
      return state;
  }
};

const loadingStatsLtv = (state = false, action) => {
  switch (action.type) {
    case LOADING_STATS_LTV:
      return true;
    case STATS_LTV_RECEIVED:
      return false;
    default:
      return state;
  }
};

const previousPeriodStats = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.previousPeriodRawStats || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const currentPeriodStats = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.currentPeriodRawStats || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const previousPeriodCalculatedStats = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.previousPeriodCalculatedStats || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const calculatedStats = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.calculatedStats || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const previousPeriodCalculatedCharts = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.previousPeriodCalculatedCharts || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const calculatedCharts = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.calculatedCharts || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const currentPeriodCalculatedStats = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return action.currentPeriodCalculatedStats || state;
    case LOADING_STATS_COMPARISONS:
      return [];
    default:
      return state;
  }
};

const previousPeriodDates: Reducer<{ start?: moment.Moment; end?: moment.Moment }> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case RECEIVED_PREVIOUS_PERIOD_STATS:
      return { start: action.start, end: action.end };
    default:
      return state;
  }
};

const statsComparisons = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_STATS_COMPARISONS:
      return action.comparisons?.[0] || {};
    case LOADING_STATS_COMPARISONS:
      return {};
    default:
      return state;
  }
};

// const calculatedStats = (state = {}, action) => {
//   switch ( action.type) {
//     case RECEIVED_STATS_COMPARISONS:
//       return action.calculatedStats;
//     case LOADING_STATS_COMPARISONS:
//       return {};
//     default:
//       return state;
//   }
// }

const loadingStatsComparisons = (state = true, action) => {
  switch (action.type) {
    case RECEIVED_STATS_COMPARISONS:
      return false;
    case LOADING_STATS_COMPARISONS:
      return true;
    default:
      return state;
  }
};
const currencyConversionRate = (state = '1', action) => {
  switch (action.type) {
    case SET_CONVERSION_RATE:
      return action.payload;
    default:
      return state;
  }
};

const selectedStatsServcies = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.selectedStatsServcies || state;
    case SELECTED_STATS_SERVCIES_CHANGED:
      return action?.services || state;
    default:
      return state;
  }
};

export const reducers = {
  previousPeriodStats,
  previousPeriodCalculatedStats,
  currentPeriodStats,
  currentPeriodCalculatedStats,
  loadingStats,
  previousPeriodCalculatedCharts,
  calculatedStats,
  calculatedCharts,
  statsComparisons,
  //calculatedStats,
  loadingStatsComparisons,
  previousPeriodDates,
  loadingStatsLtv,
  groupStatsBy,
  regroupingStats,
  regroupingPreviousPeriodStats,
  currencyConversionRate,
  selectedStatsServcies,
};
