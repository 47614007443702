import 'firebase/compat/firestore';
import { merge } from 'lodash';
import firebase from 'firebase/compat/app';
import React from 'react';
import { type RootState } from 'reducers/RootType';
import { Dispatch, Reducer } from 'redux';
import STATS from '@tw/stats';

import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { AbstractChart } from '@tw/types';
import {
  aovSegmentTypes,
  revenueSegmentTypes,
} from '@tw/types/module/services/business-intelligence';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';

import Cookies from '../utils/Cookies';
import { userDb } from '../utils/DB';
import {
  USER_DATA_ON_LOAD,
  INIT_SHOP,
  INIT_UI,
  MAIN_DATE_PICKER_SELECTION_CHANGE,
} from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { BaseColumn } from 'components/library/TWTable/types';
import { type AppDispatch } from 'index';
import {
  SummarySectionIds,
  SummarySectionMode,
  SummarySectionsCustomization,
} from 'types/SummarySection';
import { AffluencerColumn, PixelColumn } from 'types/attribution';
import { createSelector } from 'reselect';
import {
  genericEventLogger,
  analyticsEvents,
  summaryActions,
  summaryMessages,
} from 'utils/dataLayer';
import { emptyArray, simpleChart } from '@tw/stats/module/generalUtils';
import { cumulateAbstractChart } from '../utils/cumulateAbstractChart';
import moment from '@tw/moment-cached/module/timezone';
import { $isFreeShop } from '../$stores/willy/$subscription';

const firestore = firebase.firestore;

export const SUMMARY_DESIGN_MOBILE_COLUMNS_CHANGED = 'SUMMARY_DESIGN_MOBILE_COLUMNS_CHANGED';
export const applyTwoColumnMobile = (twoColumnMobile: boolean) => {
  return (dispatch, getState) => {
    let { currentShopId } = getState();
    const ui = { twoColumnMobile: !twoColumnMobile };

    dispatch({
      type: SUMMARY_DESIGN_MOBILE_COLUMNS_CHANGED,
      payload: !twoColumnMobile,
    });

    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
  };
};

export const SUMMARY_DESIGN_DESKTOP_DISPLAY_CHANGED = 'SUMMARY_DESIGN_DESKTOP_DISPLAY_CHANGED';
export const applyGridDesktop = (gridDesktop: boolean) => {
  return (dispatch, getState) => {
    let { currentShopId } = getState();
    const ui = { gridDesktop: !gridDesktop };

    dispatch({
      type: SUMMARY_DESIGN_DESKTOP_DISPLAY_CHANGED,
      payload: !gridDesktop,
    });

    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
  };
};

export const SUMMARY_DESIGN_DESKTOP_COLUMNS_CHANGED = 'SUMMARY_DESIGN_DESKTOP_COLUMNS_CHANGED';
export const applyGridDesktopColumns = (gridDesktopColumns: number) => {
  return (dispatch, getState) => {
    let { currentShopId } = getState();
    const ui = { gridDesktopColumns: gridDesktopColumns };

    dispatch({
      type: SUMMARY_DESIGN_DESKTOP_COLUMNS_CHANGED,
      payload: gridDesktopColumns,
    });

    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
  };
};

export const SUMMARY_DESIGN_FULL_WIDTH = 'SUMMARY_DESIGN_FULL_WIDTH';
export const applySummaryFullWidth = (summaryFullWidth: boolean) => {
  return (dispatch, getState) => {
    let { currentShopId } = getState();
    const ui = { summaryFullWidth: !summaryFullWidth };

    dispatch({
      type: SUMMARY_DESIGN_FULL_WIDTH,
      payload: !summaryFullWidth,
    });

    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
  };
};

export const SUMMARY_SECTIONS_ORDER_CHANGED = 'SUMMARY_SECTIONS_ORDER_CHANGED';
export const summarySectionsOrderChanged = (sections) => {
  return (dispatch, getState) => {
    var { currentShopId } = getState();

    var ui = {
      summary: {
        ...getState().summarySectionsCustomization,
        ...sections.reduce((t, section) => {
          t[section.id] = {
            ...getState().summarySectionsCustomization[section.id],
            index: sections.findIndex((s) => s.id === section.id),
          };
          return t;
        }, {}),
      },
    };

    // eager update
    // assume userDB works below
    Cookies.set('ui', JSON.stringify(ui));
    dispatch({
      type: SUMMARY_SECTIONS_ORDER_CHANGED,
      ui,
    });

    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
  };
};

export const SECTION_TILES_ORDER_CHANGED = 'SECTION_TILES_ORDER_CHANGED';

export const sectionTilesOrderChanged = (sectionId, tiles) => {
  return (dispatch, getState) => {
    var { currentShopId, summarySectionsCustomization } = getState();
    const tilesIds = tiles.map((element) => element.id || element);

    var ui = {
      summary: {
        ...summarySectionsCustomization,
        [sectionId]: {
          ...summarySectionsCustomization[sectionId],
          tiles: tilesIds,
        },
      },
    };
    if (sectionId !== 'invoices') {
      userDb().set(
        {
          shops: {
            [currentShopId]: { ui },
          },
        },
        { merge: true },
      );
    }
    Cookies.set('ui', JSON.stringify(ui));
    dispatch({
      type: SECTION_TILES_ORDER_CHANGED,
      ui,
    });
  };
};

const SET_SUMMARY_METRIC_CHART_CONFIG = 'SET_SUMMARY_METRIC_CHART_CONFIG';
export const setSummaryMetricChartConfig = (metricId, chartConfig) => {
  return (dispatch, getState) => {
    userDb().set(
      {
        summaryMetricsChartConfig: {
          [metricId]: chartConfig,
        },
      },
      { merge: true },
    );

    dispatch({
      type: SET_SUMMARY_METRIC_CHART_CONFIG,
      payload: { [metricId]: chartConfig },
    });
  };
};

export const addTileToSection = (sectionId, tileId, defaultTiles) => {
  return (dispatch, getState) => {
    var { currentShopId, summarySectionsCustomization } = getState();
    const sectionObj = summarySectionsCustomization[sectionId];
    const tilesIds =
      !sectionObj || !sectionObj?.tiles
        ? defaultTiles.map((element) => element.id || element)
        : sectionObj.tiles || [];
    const newSectionTiles = tilesIds.filter((t) => t !== tileId).concat(tileId);

    var ui = {
      summary: {
        ...summarySectionsCustomization,
        [sectionId]: {
          ...summarySectionsCustomization[sectionId],
          tiles: newSectionTiles,
        },
      },
    };
    if (sectionId !== 'invoices') {
      userDb().set(
        {
          shops: {
            [currentShopId]: { ui },
          },
        },
        { merge: true },
      );
    }
    Cookies.set('ui', JSON.stringify(ui));
    dispatch({
      type: SECTION_TILES_ORDER_CHANGED,
      ui,
    });
  };
};

export const CHANGE_SECTION_LAYOUT = 'CHANGE_SECTION_LAYOUT';
export const changeSectionLayout = (sectionId, mode: SummarySectionMode) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId, summarySectionsCustomization } = getState();
    const ui: SummarySectionsCustomization['ui'] = {
      summary: {
        ...summarySectionsCustomization,
        [sectionId]: {
          ...summarySectionsCustomization[sectionId],
          mode: mode,
        },
      },
    };
    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
    Cookies.set('ui', JSON.stringify(ui));
    dispatch({
      type: CHANGE_SECTION_LAYOUT,
      ui,
    });
  };
};

export const CUSTOM_SECTION_REMOVE = 'CUSTOM_SECTION_REMOVE';
export const customSectionRemove = (sectionId) => {
  return (dispatch, getState) => {
    let { currentShopId, summarySectionsCustomization: allSections } = getState();
    delete allSections[sectionId];
    var ui = {
      summary: {
        ...allSections,
      },
    };
    userDb().set(
      {
        shops: {
          [currentShopId]: {
            ui: {
              summary: {
                ...allSections,
                [sectionId]: firestore.FieldValue.delete(),
              },
            },
          },
        },
      },
      { merge: true },
    );
    Cookies.set('ui', JSON.stringify(ui));

    dispatch({
      type: CUSTOM_SECTION_REMOVE,
      ui,
    });
  };
};
export const CUSTOM_SECTION_ADD = 'CUSTOM_SECTION_ADD';
export const customSectionAdd = (sectionId, tiles) => {
  return (dispatch, getState) => {
    let { currentShopId, summarySectionsCustomization } = getState();

    var ui = {
      summary: {
        ...summarySectionsCustomization,
        [sectionId]: { tiles },
      },
    };
    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
    Cookies.set('ui', JSON.stringify(ui));

    dispatch({
      type: CUSTOM_SECTION_ADD,
      ui,
    });
  };
};

export const SECTION_TILES_VISIBILITY_CHANGED = 'SECTION_TILES_VISIBILITY_CHANGED';
export const minimizeSectionOnPress = (sectionId, flag) => {
  return (dispatch, getState) => {
    const { currentShopId, summarySectionsCustomization } = getState();

    let ui = {
      summary: {
        ...getState().summarySectionsCustomization,
        [sectionId]: {
          ...getState().summarySectionsCustomization[sectionId],
          hidden: flag,
        },
      },
    };

    // this is only relevant to this ticket https://triplewhale.atlassian.net/browse/TW-6187 we moved stats section to custom metrics,
    // so we need to show custom metrics section anyway if the flag of hide custom metric is not set
    // I think we can remove this code after 1.1.24
    let isForceHideCustomMetricAfterMargeWithStatsChanged = false;
    if (
      sectionId === 'customMetrics' &&
      !summarySectionsCustomization[sectionId]?.isForceHideCustomMetricAfterMargeWithStats
    ) {
      isForceHideCustomMetricAfterMargeWithStatsChanged = true;
      ui.summary[sectionId].isForceHideCustomMetricAfterMargeWithStats = true;
    }

    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );

    Cookies.set('ui', JSON.stringify(ui));
    dispatch({
      type: SECTION_TILES_VISIBILITY_CHANGED,
      sectionId,
      flag,
      // this is only relevant to this ticket https://triplewhale.atlassian.net/browse/TW-6187 we moved stats section to custom metrics,
      // so we need to show custom metrics section anyway if the flag of hide custom metric is not set
      // I think we can remove this code after 1.1.24
      isForceHideCustomMetricAfterMargeWithStats:
        !!summarySectionsCustomization[sectionId]?.isForceHideCustomMetricAfterMargeWithStats ||
        isForceHideCustomMetricAfterMargeWithStatsChanged,
    });
  };
};

export const SUMMARY_BOX_PIN_ON_PRESS = 'SUMMARY_BOX_PIN_ON_PRESS';
export const summaryBoxPinOnPress = (boxId, flag) => {
  return (dispatch, getState: () => RootState) => {
    const state = getState();
    const { summarySectionsCustomization, currentShopId } = state;
    const { pinned: defaultPinned } = defaultSummarySectionsLayout();
    const pinnedId: SummarySectionIds = 'pinned';

    const newPinnedTiles = (() => {
      const { pinned } = summarySectionsCustomization;

      // this just takes care of the first time free user pins
      if (defaultPinned?.tiles && !pinned && typeof boxId === 'string') {
        defaultPinned.tiles.push(boxId as SummaryMetricIdsTypes);
        return defaultPinned.tiles;
      }

      if (!pinned?.tiles?.length && typeof boxId === 'string') return [boxId];

      if (!pinned?.tiles?.length) return emptyArray;

      if (flag) return pinned.tiles.concat(boxId);

      return pinned.tiles.filter((t) => t !== boxId);
    })();

    const ui = {
      summary: {
        ...summarySectionsCustomization,
        [pinnedId]: {
          ...summarySectionsCustomization[pinnedId],
          tiles: newPinnedTiles,
        },
      },
    };

    userDb().set({ shops: { [currentShopId]: { ui } } }, { merge: true });
    Cookies.set('ui', JSON.stringify(ui));

    dispatch({
      type: SUMMARY_BOX_PIN_ON_PRESS,
      boxId,
      flag,
      tiles: newPinnedTiles,
      sectionId: pinnedId,
    });
  };
};

export function setCurrentCustomUiIfNotExist(shops) {
  return (dispatch, getState) => {
    if (!shops || !Object.keys(shops).length) return;
    var shop = shops[getState().currentShopId];
    if (!shop) return;
    var { ui } = shop;
    if (!ui) return;
    //var currentUi = Cookies.get('ui');
    //if (currentUi) {
    //dispatch(setCurrentShopCustomUi(JSON.parse(currentUi) ));
    //} else {
    Cookies.set('ui', JSON.stringify(ui));
    if (ui.summary) dispatch(setCurrentShopCustomUi(ui.summary));
    //}
  };
}

export const SET_CURRENT_SHOP_CUSTOM_UI = 'SET_CURRENT_SHOP_CUSTOM_UI';
export const setCurrentShopCustomUi = (ui) => ({
  type: SET_CURRENT_SHOP_CUSTOM_UI,
  ui,
});

const SUMMARY_SETTINGS_BUTTON_ON_PRESS = 'SUMMARY_SETTINGS_BUTTON_ON_PRESS';
export const summarySettingsButtonOnPress = () => ({
  type: SUMMARY_SETTINGS_BUTTON_ON_PRESS,
});

const SUMMARY_SETTINGS_CLOSE_BUTTON_ON_PRESS = 'SUMMARY_SETTINGS_CLOSE_BUTTON_ON_PRESS';
export const summarySettingsCloseButtonOnPress = () => ({
  type: SUMMARY_SETTINGS_CLOSE_BUTTON_ON_PRESS,
});

/**** ************** ******** ** ** * * ** ** * * ** * * */
/**** ************** ******** ** ** * * ** ** * * ** * * */
/**** ************** ******** ** ** * * ** ** * * ** * * */
/**** ************** ******** ** ** * * ** ** * * ** * * */

const twoColumnMobile: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      const { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      const shop = shops[$currentShopId.get()!];
      const { ui } = Object(shop);
      if (!ui) return state;
      return !!shop.ui.twoColumnMobile;

    case SUMMARY_DESIGN_MOBILE_COLUMNS_CHANGED:
      return action.payload;

    default:
      return state;
  }
};

const gridDesktop: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      const { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      const shop = shops[$currentShopId.get()!];
      const { ui } = Object(shop);
      if (!ui) return state;
      return !!shop.ui.gridDesktop;

    case SUMMARY_DESIGN_DESKTOP_DISPLAY_CHANGED:
      return action.payload;

    default:
      return state;
  }
};

const gridDesktopColumns: Reducer<number> = (state = 2, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      const { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      const shop = shops[$currentShopId.get()!];
      const { ui } = Object(shop);
      if (!ui) return state;
      return shop.ui?.gridDesktopColumns ?? state;

    case SUMMARY_DESIGN_DESKTOP_COLUMNS_CHANGED:
      return action.payload;

    default:
      return state;
  }
};

const isShowGoogleAnalytics: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case MAIN_DATE_PICKER_SELECTION_CHANGE:
      const targetDate = moment('2023-07-01');
      return !moment(action.range.start).isAfter(targetDate);
    default:
      return state;
  }
};

const summaryFullWidth: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      const { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      const shop = shops[$currentShopId.get()!];
      const { ui } = Object(shop);
      if (!ui) return state;
      return !!shop.ui.summaryFullWidth;

    case SUMMARY_DESIGN_FULL_WIDTH:
      return action.payload;

    default:
      return state;
  }
};

const isSummarySettingsOpen = (state = false, action) => {
  switch (action.type) {
    case SUMMARY_SETTINGS_BUTTON_ON_PRESS:
      return true;
    case SUMMARY_SETTINGS_CLOSE_BUTTON_ON_PRESS:
      return false;
    default:
      return state;
  }
};

const defaultSummarySectionsLayout = () => {
  if (!$isFreeShop.get()) return {};

  return {
    webAnalytics: { index: 4 },
    pinned: { index: 0, tiles: ['sales', 'roas', 'blendedAds'] },
    customMetrics: { index: 2 },
    pixel: { index: 3 },
    store: { index: 1 },
  };
};

const summarySectionsCustomization: Reducer<SummarySectionsCustomization['ui']['summary']> = (
  state = JSON.parse(Cookies.get('ui') || '{}').summary || {},
  action,
) => {
  switch (action.type) {
    case SET_CURRENT_SHOP_CUSTOM_UI:
      return action.ui;
    case SECTION_TILES_VISIBILITY_CHANGED:
      return {
        ...state,
        [action.sectionId]: {
          ...state[action.sectionId],
          hidden: action.flag,
          // this is only relevant to this ticket https://triplewhale.atlassian.net/browse/TW-6187 we moved stats section to custom metrics,
          // so we need to show custom metrics section anyway if the flag of hide custom metric is not set
          // I think we can remove this code after 1.1.24
          isForceHideCustomMetricAfterMargeWithStats:
            action.isForceHideCustomMetricAfterMargeWithStats,
        },
      };
    case SECTION_TILES_ORDER_CHANGED:
      return { ...action.ui.summary };
    case SUMMARY_SECTIONS_ORDER_CHANGED:
      return { ...state, ...action.ui.summary };
    case INIT_UI:
      var { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      var shop = shops[$currentShopId.get()!];

      var { ui } = Object(shop);
      if (!ui) return state;

      return { ...shop.ui.summary };
    case SUMMARY_BOX_PIN_ON_PRESS:
      return {
        ...state,
        [action.sectionId]: { ...state.pinned, tiles: action.tiles },
      };
    case CUSTOM_SECTION_ADD:
      return { ...action.ui.summary };
    case CUSTOM_SECTION_REMOVE:
      return { ...action.ui.summary };
    case CHANGE_SECTION_LAYOUT:
      return { ...action.ui.summary };
    default:
      return state;
  }
};

export const selectSummarySectionsLayout = createSelector(
  [(state: RootState) => state.summarySectionsCustomization],
  (summarySectionsCustomization): SummarySectionsCustomization['ui']['summary'] => {
    const defaultLayout = defaultSummarySectionsLayout();

    return { ...merge(defaultLayout, summarySectionsCustomization) };
  },
);

const uiCustomizationLoaded = (state = false, action) => {
  switch (action.type) {
    case INIT_UI:
      return true;
    default:
      return state;
  }
};

export const getCalculatedValue = (val: any, originalMetric: BaseSummaryMetric<any>, isChart) => {
  let value = val ?? (isChart ? [] : 0);
  if (originalMetric?.statObjectKey) {
    value = value[originalMetric.statObjectKey];
    if (value && originalMetric.specificStat) {
      value = value[originalMetric.specificStat];
    }
  }
  return value;
};

export const getTileValue = (state: Partial<RootState>, metric: BaseSummaryMetric<any>) => {
  let value = 0;
  if (!metric) return value;
  try {
    const { providerId, metric: metricKey } = metric! ?? {};
    value =
      state && metric && typeof STATS[metric.metricId] === 'function'
        ? STATS[metric.metricId](state)
        : 0;
    if (metric?.statObjectKey) {
      value = value[metric.statObjectKey];
      if (value && metric.specificStat) {
        value = value[metric.specificStat];
      }
    }
  } catch (e) {
    genericEventLogger(analyticsEvents.SUMMARY, {
      action: summaryActions.GET_TILE_VALUE_FAILED,
      shop: state.currentShopId,
      metricId: metric.metricId,
      error: e,
    });
  }
  return value;
};

export const getTileChart = (
  state: Partial<RootState>,
  metric: BaseSummaryMetric<any>,
  chartValue?: any[],
  isMetricChartCumulative = false,
) => {
  let chart: AbstractChart[] = emptyArray as any;
  if (!metric) return chart;
  try {
    if (chartValue) {
      chart = chartValue;
    } else {
      const { providerId, metric: metricKey } = metric! ?? {};
      chart =
        metric && typeof STATS[metric.chart!] === 'function'
          ? STATS[metric.chart!]?.(state)
          : emptyArray;
      if (metric?.statObjectKey) {
        chart = chart[metric.statObjectKey] || emptyArray;
        if (chart && metric.specificStat) {
          chart = chart[metric.specificStat] || emptyArray;
        }
      }
    }

    if (metric?.isCumulativeMetric || isMetricChartCumulative) {
      chart = cumulateAbstractChart(chart);
    }
  } catch (e) {
    genericEventLogger(analyticsEvents.SUMMARY, {
      action: summaryActions.GET_TILE_CHART_FAILED,
      shop: state.currentShopId,
      metricId: metric.metricId,
      error: e,
    });
  }
  return chart;
};

export const getTarget = async (id) => {
  var currentShopId = $currentShopId.get()!;
  var userData = await userDb().get();
  const targetsData = userData!.data?.()?.shops?.[currentShopId];

  const targetValues = targetsData && targetsData.targets ? targetsData.targets : {};
  return targetValues;
};

export const isSectionHide = (summarySectionsCustomization, connectedServices, action) => {
  const sectionId = action.sectionId;
  let allSections = action.sections;
  const selectedSection = allSections.find((s) => s.id === sectionId);

  // only relevant summary page, other places like reports will send here false
  if (typeof summarySectionsCustomization === 'object') {
    // this is only relevant to this ticket https://triplewhale.atlassian.net/browse/TW-6187 we moved stats section to custom metrics,
    // so we need to show custom metrics section anyway if the flag of hide custom metric is not set
    // I think we can remove this code after 1.1.24
    if (
      sectionId === 'customMetrics' &&
      !summarySectionsCustomization[sectionId]?.isForceHideCustomMetricAfterMargeWithStats
    ) {
      return false;
    }

    if (summarySectionsCustomization[sectionId]?.hidden) {
      return true;
    }

    if (
      typeof summarySectionsCustomization[sectionId] === 'undefined' &&
      selectedSection?.isHideByDefault
    ) {
      return true;
    }

    if (summarySectionsCustomization[sectionId]?.hidden === false) {
      return false;
    }
  }

  if (selectedSection === undefined) {
    return false;
  }

  if (selectedSection.services === undefined || selectedSection.services.length === 0) {
    return false;
  }

  return !connectedServices[selectedSection.services[0]];
};

export type SummaryContextProps = {
  industry: IndustryTypes;
  aovSegment: aovSegmentTypes | null;
  spendSegment: revenueSegmentTypes | null;
  pixelColumns: (PixelColumn | AffluencerColumn)[];
  currency: string;
  setPixelColumns: React.Dispatch<React.SetStateAction<BaseColumn<any, any>[]>>;
};

export const SummaryContext = React.createContext<Partial<SummaryContextProps>>({});

export const IMPORTING_NOW_VISIBILITY_CHANGED = 'IMPORTING_NOW_VISIBILITY_CHANGED';
export const importingNowVisibilityChanged = (show: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: IMPORTING_NOW_VISIBILITY_CHANGED,
      show,
    });
  };
};

const showSummaryImportingNow: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return false;

    case IMPORTING_NOW_VISIBILITY_CHANGED:
      return action.show;

    default:
      return state;
  }
};

const summaryMetricsChartConfig: Reducer<any> = (state = {}, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      const { summaryMetricsChartConfig } = action;
      return summaryMetricsChartConfig ?? state;
    case SET_SUMMARY_METRIC_CHART_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const UPDATE_DEFAULT_ROUNDING_OPTION = 'UPDATE_DEFAULT_ROUNDING_OPTION';

export const updateDefaultRoundingOption = (currencyDisplay) => {
  return (dispatch, getState) => {
    let { currentShopId } = getState();
    const ui = { defaultRoundingOption: currencyDisplay };

    dispatch({
      type: UPDATE_DEFAULT_ROUNDING_OPTION,
      currencyDisplay,
    });
    userDb().set(
      {
        shops: {
          [currentShopId]: { ui },
        },
      },
      { merge: true },
    );
  };
};

const defaultRoundingOption = (state = null, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      const { shops } = action;
      if (!shops || !Object.keys(shops).length) return state;
      const shop = shops[$currentShopId.get()!];
      const { ui } = Object(shop);
      if (!ui) return state;
      return ui?.defaultRoundingOption ?? state;
    case UPDATE_DEFAULT_ROUNDING_OPTION:
      return action.currencyDisplay;
    default:
      return state;
  }
};

export const USE_SUMMARY_NEXUS = 'USE_SUMMARY_NEXUS';

export enum RoundingOptions {
  ROUND = 'ROUND',
  ONE_DECIMAL = 'ONE_DECIMAL',
  TWO_DECIMALS = 'TWO_DECIMALS',
}

type RoundingOptionsMapper = {
  [K in keyof typeof RoundingOptions]: number;
};

export const RoundingOptionsMapper: RoundingOptionsMapper = {
  ROUND: 0,
  ONE_DECIMAL: 1,
  TWO_DECIMALS: 2,
};

export const reducers = {
  summarySectionsCustomization,
  uiCustomizationLoaded,
  isSummarySettingsOpen,
  twoColumnMobile,
  gridDesktop,
  gridDesktopColumns,
  isShowGoogleAnalytics,
  summaryFullWidth,
  showSummaryImportingNow,
  defaultRoundingOption,
  summaryMetricsChartConfig,
};
