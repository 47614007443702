import { $currentShopId } from '$stores/$shop';
import { COG_ON_CHANGE_PRODUCT_COST, INIT_SHOP, SET_BIDIRECTIONAL_COGS } from 'ducks/constants';
import { update } from 'lodash';
import { createSelector } from 'reselect';
import { ShopifySegmentType } from 'types/shopify';

import moment from '@tw/moment-cached/module/timezone';

import axiosInstance from '../utils/axiosInstance';
import { toggleSaveIndicator } from './actions';
import { calculateInventory } from './inventory';
import { updatePaymentGatewayCost } from './paymentGateways';
import { RECEIVE_SHOPIFY_SEGMENTS } from './shopify/newShopify';

const SORT_BY_PROPS = ['title'];

export const fetchProduct = async (id, includeVariants = false, shopId = $currentShopId.get()) => {
  const url = `/v2/attribution/get-product`;

  let body = {
    shopId,
    id,
    limit: PRODUCTS_PER_PAGE,
  } as any;
  if (includeVariants) {
    body.extend = ['variants'];
  }

  try {
    const { data } = await axiosInstance.post(url, body);
    return Array.isArray(data)
      ? data.map((doc) => ({ id: doc.id, data: () => doc }))
      : { id: data.id, data: () => data };
  } catch (error) {
    return [];
  }
};

const getProductsQueryUrl = (msp) => {
  switch (msp) {
    case 'woocommerce':
    case 'bigcommerce':
      return `/v2/summary-page/get-products`;
    case 'shopify':
    default:
      return `/v2/shopify/mongo/get-products`;
  }
};

export const queryProducts = async (query) => {
  const { msp } = query;
  const url = getProductsQueryUrl(msp);
  const { data } = await axiosInstance.post(url, {
    shopId: $currentShopId.get(),
    ...query,
    limit: PRODUCTS_PER_PAGE,
    fields: [
      'id',
      'title',
      'variant_ids',
      'variants.id',
      'variants.inventory_item_id',
      'variants.sku',
      'variants.title',
      'variants.price',
      'variants.option_values',
      'variants.attributes',
    ],
    extend: ['variants'],
  });
  return data;
};

export const searchProductsMongo = async (shopId, query) => {
  const url = `/v2/shopify/mongo/search-products`;
  const { data } = await axiosInstance.post(url, { query, shopId });
  return data; //data.map(doc => ({ id: doc.id, data: () => doc }));
};

export const RECEIVED_PRODUCTS = 'RECEIVED_PRODUCTS';
export const loadProducts = () => {
  return (dispatch) => {
    dispatch(productsOnClickNextPageButton());
  };
};

function generateProductsQuery(getState) {
  var orderBy = SORT_BY_PROPS[getState()['productsSortBy']];
  var order = getState()['productsSortOrder'] === 'ascending' ? 1 : -1;
  var search = getState()['productsSearch'];
  var msp = getState()['msp'] ?? 'shopify';
  return { orderBy, order, search, page, msp };
}

function resetPaginationAndReload() {
  return (dispatch) => {
    productZero = null;
    pageFirstProduct = null;
    pageLastProduct = null;
    loading = false;
    page = -1;
    dispatch(productsOnClickNextPageButton());
  };
}

var PRODUCTS_PER_PAGE = 50;
var productZero: any = null;
var pageFirstProduct: any = null;
var pageLastProduct: any = null;
var loading = false;
var page = 0;
export const loadProductsPage = (query) => {
  return async (dispatch) => {
    if (loading) return;
    loading = true;
    var data = await queryProducts(query);
    pageFirstProduct = data[0];
    if (!productZero) productZero = pageFirstProduct;
    pageLastProduct = data[data.length - 1];
    const products = data;

    dispatch({
      type: RECEIVED_PRODUCTS,
      products,
      isPreviousPageEnabled:
        !!productZero && !!pageFirstProduct && productZero.id !== pageFirstProduct.id,
      isNextPageEnabled: data.length === PRODUCTS_PER_PAGE,
    });
    loading = false;
  };
};

export const RECEIVED_COGS = 'RECEIVED_COGS';
export const RECEIVED_RELEVANT_COGS = 'RECEIVED_RELEVANT_COGS';
export const LOADING_COGS = 'LOADING_COGS';
// export const loadCogs = (cogIds) => {
//   return async (dispatch) => {
//     dispatch({ type: LOADING_COGS });
//     var cogs = [];
//     var chunks = splitArrayToChunks(cogIds, 10); // where 'in' limited to 10
//     for (var chunk of chunks) {
//       var data = (await db()
//         .collection('shopify_product_variants')
//         .where(FieldPath.documentId(), 'in', chunk)
//         .get()) as any;
//       cogs = cogs.concat(toArray(data) as never);
//     }
//     cogs = toHash(cogs) as never[];
//     dispatch({ type: RECEIVED_COGS, cogs });
//   };
// };

// export const loadRelevantCogs = (cogIds) => {
//   return async (dispatch) => {
//     dispatch({ type: LOADING_COGS });
//     var cogs = [] as never[];
//     var chunks = splitArrayToChunks(cogIds, 10); // where 'in' limited to 10
//     for (var chunk of chunks) {
//       var data = (await db()
//         .collection('shopify_product_variants')
//         .where(FieldPath.documentId(), 'in', chunk)
//         .get()) as any;
//       cogs = cogs.concat(toArray(data) as never);
//     }
//     cogs = toHash(cogs) as never[];
//     dispatch({ type: RECEIVED_RELEVANT_COGS, cogs });
//   };
// };

export const productsOnClickNextPageButton = () => {
  return (dispatch, getState) => {
    page++;
    var query = generateProductsQuery(getState) as any;
    if (pageLastProduct) {
      query.startAfter = pageLastProduct.id;
    }
    query.limit = PRODUCTS_PER_PAGE;

    dispatch(loadProductsPage(query));
  };
};

export const productsReload = () => {
  return (dispatch, getState) => {
    var query = generateProductsQuery(getState) as any;
    query.limit = PRODUCTS_PER_PAGE;
    dispatch(loadProductsPage(query));
  };
};

export const productsOnClickPreviousPageButton = () => {
  return (dispatch, getState) => {
    page--;
    var query = generateProductsQuery(getState) as any;
    //if (pageLastProduct) {
    query.endBefore = pageFirstProduct.id;
    query.limitToLast = PRODUCTS_PER_PAGE;
    //}

    dispatch(loadProductsPage(query));
  };
};

export const CALCULATING_COGS = 'CALCULATING_COGS';
export const CALCULATING_COGS_DONE = 'CALCULATING_COGS_DONE';

const updateCogsUrl = (msp) => {
  switch (msp) {
    case 'bigcommerce':
    case 'woocommerce':
      return `/v2/summary-page/update-product-costs`;
    case 'shopify':
    default:
      return `/v2/shopify/update-product-variants`;
  }
};

export const cogOnChangeProductCost = (cog, product_cost, isSilent = false) => {
  return async (dispatch, getState) => {
    const { bidirectionalCogs, currentShopId, msp } = getState();
    toggleSaveIndicator();

    let cost = +product_cost < 0 ? +product_cost * -1 : +product_cost;
    var cogs = Array.isArray(cog) ? cog : [cog];
    await Promise.all(
      cogs.map(async (miniCog) => {
        const { id: variantId, inventory_item_id } = miniCog;
        if (bidirectionalCogs) {
          const url = `/v2/shopify/update-inventory-cost`;
          await axiosInstance.post(url, {
            shopId: currentShopId,
            id: inventory_item_id,
            data: { inventory_item: { id: '' + inventory_item_id, cost } },
          });
        }
        const url = updateCogsUrl(msp);
        await axiosInstance.post(url, {
          attributes: {
            shopId: currentShopId,
            dataType: 'shopify_product_variants',
            msp,
          },
          data: {
            id: variantId,
            product_cost: cost,
            ...(miniCog.product_id && { product_id: miniCog.product_id }),
          },
        });
      }),
    );
    dispatch(productsReload());
  };
};

export const costOnChangeHistory = (item, history, type, costType, _diff) => {
  return async (dispatch, getState) => {
    const { bidirectionalCogs, currentShopId, msp } = getState();
    let url;
    const costHistory =
      history?.length > 0
        ? history
            .map((item) => {
              return {
                value: +item.value,
                date: moment(item.date).toDate(),
                type: item?.type ?? 'Client',
              };
            })
            .sort((objA, objB) => Number(objB.date) - Number(objA.date))
        : [{ value: 0, date: new Date() }];
    switch (type) {
      case 'costs':
      case 'handling_fees':
        const { id: variantId, inventory_item_id } = item;
        url = updateCogsUrl(msp);
        await axiosInstance.post(url, {
          attributes: {
            shopId: currentShopId,
            dataType: 'shopify_product_variants',
            msp,
          },
          data: {
            id: variantId,
            [`${type}_history`]: costHistory,
            ...(item.product_id && { product_id: item.product_id }),
          },
        });
        dispatch(productsReload());

        if (type === 'costs' && bidirectionalCogs) {
          const url = `/v2/shopify/update-inventory-cost`;
          await axiosInstance.post(url, {
            shopId: currentShopId,
            id: inventory_item_id,
            data: { inventory_item: { id: '' + inventory_item_id, cost: costHistory[0].value } },
          });
        }
        break;
      case 'payment_gateway_costs':
      case 'tw':
        url = `/v2/shopify/update-segment`;
        const diff = _diff
          ?.map((item) => {
            return {
              value: +item.cost.value,
              date: moment(item.cost.date).toDate(),
              type: item.type,
            };
          })
          .sort((objA, objB) => Number(objB.date) - Number(objA.date));
        const data = {
          id: item.id,
          [costType]: costHistory,
          diff,
        };
        await axiosInstance.post(url, {
          attributes: {
            shopId: currentShopId,
            dataType: type,
            msp,
          },
          data,
        });
        type === 'payment_gateway_costs' &&
          dispatch(
            updatePaymentGatewayCost({
              id: item.id,
              [costType.slice(0, -1)]: costHistory[0].value,
              [costType]: costHistory,
            }),
          );
        costType === 'handling_fees' &&
          dispatch(customFixedHandlingFeePerOrderOnChange({ [costType]: costHistory }));
        costType === 'cogs_percent' &&
          dispatch(customCogsPercentOnChange({ [costType]: costHistory }));
        break;
    }
  };
};
export const fixedHandlingFeeOnChange = (fee, history) => {
  return async (dispatch, getState) => {
    const { currentShopId, msp } = getState();
    let url;
    url = `/v2/shopify/update-segment`;
    const data = {
      id: 'fixed_handling_fee',
      fee,
    };
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'tw',
        msp,
      },
      data,
    });
    dispatch(customFixedHandlingFeePerOrderOnChange({ handling_fees: history }));
  };
};
export const cogsPercentOnChange = (percent, history) => {
  return async (dispatch, getState) => {
    const { currentShopId, msp } = getState();
    let url;
    url = `/v2/shopify/update-segment`;
    const data = {
      id: 'custom_cogs_percent',
      percent,
    };
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'tw',
        msp,
      },
      data,
    });
    dispatch(customCogsPercentOnChange({ cogs_percent: history }));
  };
};

export const cogOnChangeHandlingFees = (cog, handling_fees, isSilent = false) => {
  return async (dispatch, getState) => {
    const { currentShopId, msp } = getState();

    toggleSaveIndicator();

    let handlingFee = +handling_fees < 0 ? +handling_fees * -1 : +handling_fees;
    var ids = Array.isArray(cog.id) ? cog.id : [cog.id];
    await Promise.all(
      ids.map(async (variantId) => {
        const url = updateCogsUrl(msp);
        await axiosInstance.post(url, {
          attributes: {
            shopId: currentShopId,
            dataType: 'shopify_product_variants',
            msp,
          },
          data: {
            id: variantId,
            handling_fees: handlingFee,
            ...(cog.product_id && { product_id: cog.product_id }),
          },
        });
      }),
    );
    dispatch(productsReload());
  };
};

export const variantOnChangeInStats = (cog, flag) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    var ids = Array.isArray(cog.id) ? cog.id : [cog.id];
    const url = `/v2/shopify/update-product-variants`;
    await Promise.all(
      ids.map(async (variantId) => {
        await axiosInstance.post(url, {
          attributes: {
            shopId: currentShopId,
            dataType: 'shopify_product_variants',
          },
          data: {
            id: variantId,
            inStats: flag,
          },
        });
      }),
    );
    dispatch(calculateInventory());
  };
};

// export const clearHandlingFeesOnPress = (cog, flag) => {
//   return async (dispatch, getState) => {
//     toggleSaveIndicator();
//
//     var { cogs } = getState();
//
//     var ref,
//       b = batch();
//     var i = 0;
//
//     for (var [id] of Object.entries(cogs)) {
//       ref = db()
//         .collection('shopify_product_variants')
//         .doc('' + id);
//       b.set(
//         ref,
//         {
//           handling_fees: 0,
//         },
//         { merge: true }
//       );
//       i++;
//       if (i && i % 50 === 0) {
//         await b.commit();
//         b = batch();
//       }
//     }
//     await b.commit();
//     dispatch(loadCogs([]));
//   };
// };

// export const clearProductCostsOnPress = (cog, flag) => {
//   return async (dispatch, getState) => {
//     toggleSaveIndicator();
//
//     var { cogs } = getState();
//     var ref,
//       b = batch();
//     var i = 0;
//
//     for (var [id] of Object.entries(cogs)) {
//       ref = db()
//         .collection('shopify_product_variants')
//         .doc('' + id);
//       b.set(
//         ref,
//         {
//           product_cost: 0,
//         },
//         { merge: true }
//       );
//       i++;
//       if (i && i % 50 === 0) {
//         await b.commit();
//         b = batch();
//       }
//     }
//     await b.commit();
//     dispatch(loadCogs([]));
//   };
// };

export const PRODUCTS_TABLE_ON_SORT = 'PRODUCTS_TABLE_ON_SORT';
export const productsTableOnSort = (column, order, resetAndReload = true) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTS_TABLE_ON_SORT,
      column,
      order,
    });
    if (resetAndReload) {
      dispatch(resetPaginationAndReload());
    }
  };
};

export const PRODUCTS_SEARCH_ON_CHANGE = 'PRODUCTS_SEARCH_ON_CHANGE';
export const productsSearchOnChange = (val) => {
  return (dispatch) => {
    dispatch({
      type: PRODUCTS_SEARCH_ON_CHANGE,
      val,
    });
    dispatch(productsTableOnSort(0, 'ascending', false));
    dispatch(resetPaginationAndReload());
  };
};

// export const RECEIVED_PRODUCTS_AND_COGS = 'RECEIVED_PRODUCTS_AND_COGS';
// export const getCogsAndProducts = () => {
//   return (dispatch) => {
//     return db().collection('products').get().then(data => {
//       const products = toArray(data).sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
//       return db().collection('shopify_product_variants').onSnapshot(data => {
//         const cogs = toArray(data);
//         dispatch({ type: RECEIVED_PRODUCTS_AND_COGS, productsAndCogs: { products, cogs } });
//         // return dispatch(receiveProductsAndCogs({products, cogs}));
//       })
//     })
//   }
// }

// export const receiveProductsAndCogs = (productsAndCogs) => ({
//   type: RECEIVED_PRODUCTS_AND_COGS,
//   productsAndCogs
// });

// const cogsAndProducts = (state = { products: [], cogs: [] }, action) => {
//   switch (action.type) {
//     case RECEIVED_PRODUCTS_AND_COGS:
//       return action.productsAndCogs || state;
//     default:
//       return state;
//   }
// };

/** ****** */
/** ****** */
/** ****** */

const _products = (state) => state.products;
const _productsSearch = (state) => state.productsSearch;

export const productsFilteredBySearch = createSelector(
  [_products, _productsSearch],
  (products, search) =>
    products.filter((a) => a.title.toLowerCase().indexOf(search.toLowerCase()) >= 0),
);

/*
export const productsSorted = createSelector(
  [_products, _cogs, _productsSortBy, _productsSortOrder],
  (products, cogs, productsSortBy, productsSortOrder) => {
    var sorted = products.slice();
    var order = productsSortOrder == 'ascending' ? 1 : -1;
    switch (productsSortBy) {
      case 1: // by price
        var variantProp = 'sku';
        sorted.sort((a, b) => {
          var n1 = !!a.variants.find(v => cogs[v.id] && !!cogs[v.id][variantProp] && cogs[v.id][variantProp].length);
          var n2 = !!b.variants.find(v => cogs[v.id] && !!cogs[v.id][variantProp] && cogs[v.id][variantProp].length);
          return (n1 > n2) ? order : ((n2 > n1) ? -order : 0);
        });
        break;
      case 2: // by price
        var variantProp = 'price';
        sorted.sort((a, b) => {
          var n1 = a.variants.reduce((t,v) => t + Number(v[variantProp]), 0) / a.variants.length;
          var n2 = b.variants.reduce((t,v) => t + Number(v[variantProp]), 0) / b.variants.length;
          return (n1 > n2) ? order : ((n2 > n1) ? -order : 0);
        });
        break;
      case 3: // by handling_fees
        var cogProp = 'handling_fees';
        sorted.sort((a, b) => {
          var n1 = a.variants.reduce((t,v) => t + Number(cogs[v.id][cogProp] || 0), 0) / a.variants.length;
          var n2 = b.variants.reduce((t,v) => t + Number(cogs[v.id][cogProp] || 0), 0) / b.variants.length;
          return (n1 > n2) ? order : ((n2 > n1) ? -order : 0);
        });
        break;
      case 4: // by product_cost
        var cogProp = 'product_cost';
        sorted.sort((a, b) => {
          var n1 = a.variants.reduce((t,v) => t + Number(cogs[v.id][cogProp] || 0), 0) / a.variants.length;
          var n2 = b.variants.reduce((t,v) => t + Number(cogs[v.id][cogProp] || 0), 0) / b.variants.length;
          return (n1 > n2) ? order : ((n2 > n1) ? -order : 0);
        });
        break;
      case 5:
        var variantProp = 'inventory_quantity';
        sorted.sort((a, b) => {
          var n1 = a.variants.reduce((t,v) => t + Number(v[variantProp]), 0) ;
          var n2 = b.variants.reduce((t,v) => t + Number(v[variantProp]), 0) ;
          return (n1 > n2) ? order : ((n2 > n1) ? -order : 0);
        });
        break;
      case 6:
        var variantProp = 'inStats';
        sorted.sort((a, b) => {
          var n1 = !!a.variants.find(v=>cogs[v.id]['inStats']!=false) ;
          var n2 = !!b.variants.find(v=>cogs[v.id]['inStats']!=false) ;
          return (n1 > n2) ? order : ((n2 > n1) ? -order : 0);
        });
        break;
      default: // by title
        sorted.sort((a,b) => (a.title > b.title) ? order : ((b.title > a.title) ? -order : 0) );
    }
    //var arr =  sorted.map(a => a.variants.reduce((t,v) => t + Number(cogs[v.id].handling_fees || 0), 0) / a.variants.length);
    //console.log(productsSortBy, productsSortOrder, arr );
    return sorted;
  }
)
*/

export const CUSTOM_COGS_PERCENT_ON_CHANGE = 'CUSTOM_COGS_PERCENT_ON_CHANGE';
export const customCogsPercentOnChange = (data) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_COGS_PERCENT_ON_CHANGE,
      data,
    });
  };
};

export const UPDATE_CUSTOM_COGS_PERCENT_IS_ACTIVE = 'UPDATE_CUSTOM_COGS_PERCENT_IS_ACTIVE';
export const updateCustomCogsPercentisActive = (bool) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'UPDATE_CUSTOM_COGS_PERCENT_IS_ACTIVE', isActive: bool });
    const { currentShopId, customCogsPercent, msp } = getState();
    const url = `/v2/shopify/update-segment`;
    const data = {
      ...customCogsPercent,
      id: 'custom_cogs_percent',
      isActive: bool,
    };
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'tw',
        msp,
      },
      data,
    });
  };
};

export const updateInitialCustomCogsPercent = (val) => async (dispatch, getState) => {
  const { currentShopId, msp } = getState();
  dispatch({ type: 'UPDATE_CUSTOM_COGS_PERCENT_IS_ACTIVE', isActive: true });
  dispatch(
    customCogsPercentOnChange({
      cogs_percent: [
        {
          value: +val,
          date: new Date(),
        },
      ],
    }),
  );
  const url = `/v2/shopify/update-segment`;
  const data = {
    percent: val,
    id: 'custom_cogs_percent',
    isActive: true,
  };
  await axiosInstance.post(url, {
    attributes: {
      shopId: currentShopId,
      dataType: 'tw',
      msp,
    },
    data,
  });
};
export const CUSTOM_FIXED_HANDLING_FEE_ORDER_ON_CHANGE =
  'CUSTOM_FIXED_HANDLING_FEE_ORDER_ON_CHANGE';
export const customFixedHandlingFeePerOrderOnChange = (data) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_FIXED_HANDLING_FEE_ORDER_ON_CHANGE,
      data,
    });
  };
};

export const UPDATE_FIXED_HANDLING_FEE_IS_ACTIVE = 'UPDATE_FIXED_HANDLING_FEE_IS_ACTIVE';
export const updateFixedHandlingFeeisActive = (bool) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'UPDATE_FIXED_HANDLING_FEE_IS_ACTIVE', isActive: bool });
    const { currentShopId, customFixedHandlingFeePerOrder, msp } = getState();
    const url = `/v2/shopify/update-segment`;
    const data = {
      ...customFixedHandlingFeePerOrder,
      id: 'fixed_handling_fee',
      isActive: bool,
    };
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'tw',
        msp,
      },
      data,
    });
  };
};

export const updateInitialFixedHandlingFee = (val) => {
  return async (dispatch, getState) => {
    const { currentShopId, msp } = getState();
    dispatch({ type: 'UPDATE_FIXED_HANDLING_FEE_IS_ACTIVE', isActive: true });
    dispatch(
      customFixedHandlingFeePerOrderOnChange({
        handling_fees: [
          {
            value: +val,
            date: new Date(),
          },
        ],
      }),
    );
    const url = `/v2/shopify/update-segment`;
    const data = {
      fee: val,
      id: 'fixed_handling_fee',
      isActive: true,
    };
    await axiosInstance.post(url, {
      attributes: {
        shopId: currentShopId,
        dataType: 'tw',
        msp,
      },
      data,
    });
  };
};

export const COGS_EXPORT_CSV = 'COGS_EXPORT_CSV';
export const COGS_EXPORTING_CSV = 'COGS_EXPORTING_CSV';
export const COGS_EXPORTING_CSV_DONE = 'COGS_EXPORTING_CSV_DONE';
export const cogsExportCsvOnPress = () => {
  return async (dispatch, getState) => {
    // Function not in use
  };
};

export const COGS_IMPORTING_CSV = 'COGS_IMPORTING_CSV';
export const COGS_IMPORTING_CSV_DONE = 'COGS_IMPORTING_CSV_DONE';
// export const cogsOnImport = (cogs) => {
//   return async (dispatch, getState) => {
//     toggleSaveIndicator();
//     dispatch({ type: COGS_IMPORTING_CSV });
//     var ref,
//       b = batch();
//     var i = 0;
//     for (var { id, product_cost, handling_fees } of cogs) {
//       ref = db()
//         .collection('shopify_product_variants')
//         .doc('' + id);
//       b.set(
//         ref,
//         {
//           product_cost,
//           handling_fees,
//         },
//         { merge: true }
//       );
//       i++;
//       if (i && i % 50 === 0) {
//         await b.commit();
//         b = batch();
//       }
//     }
//     await b.commit();
//     dispatch({ type: COGS_IMPORTING_CSV_DONE });
//     dispatch(resetPaginationAndReload());
//     dispatch(calculateInventory());
//     //dispatch(getCogsAndProducts());
//   };
// };

/********* */
/********* */
/********* */

const productsSearch = (state = '', action) => {
  switch (action.type) {
    case PRODUCTS_SEARCH_ON_CHANGE:
      return action.val;
    default:
      return state;
  }
};

const productsSortBy = (state = 0, action) => {
  switch (action.type) {
    case PRODUCTS_TABLE_ON_SORT:
      return action.column;
    default:
      return state;
  }
};

const productsSortOrder = (state = 'ascending', action) => {
  switch (action.type) {
    case PRODUCTS_TABLE_ON_SORT:
      return action.order;
    default:
      return state;
  }
};

const products = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PRODUCTS:
      return action.products;
    default:
      return state;
  }
};

const cogs = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_COGS:
      return action.cogs;
    case COG_ON_CHANGE_PRODUCT_COST:
      return {
        ...state,
        [action.variantId]: { ...state[action.variantId], product_cost: action.product_cost },
      };
    default:
      return state;
  }
};

const relevantCogs = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_RELEVANT_COGS:
      return action.cogs;
    default:
      return state;
  }
};

const loadingProducts = (state = true, action) => {
  switch (action.type) {
    case RECEIVED_PRODUCTS:
      return false;
    default:
      return state;
  }
};

const loadingCogs = (state = true, action) => {
  switch (action.type) {
    case LOADING_COGS:
      return true;
    case RECEIVED_COGS:
      return false;
    default:
      return state;
  }
};

const exportingCogs = (state = false, action) => {
  switch (action.type) {
    case COGS_EXPORTING_CSV:
      return true;
    case COGS_EXPORTING_CSV_DONE:
      return false;
    default:
      return state;
  }
};

const importingCogs = (state = false, action) => {
  switch (action.type) {
    case COGS_IMPORTING_CSV:
      return true;
    case COGS_IMPORTING_CSV_DONE:
      return false;
    default:
      return state;
  }
};

const transformTWCosts = (costs) => {
  return {
    handlingFee: costs?.filter((x) => x.id === 'fixed_handling_fee')[0],
    cogsPercent: costs?.filter((x) => x.id === 'custom_cogs_percent')[0],
  };
};

const customCogsPercent = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return transformTWCosts(action.shopifySegments?.[ShopifySegmentType.TW])?.cogsPercent ?? {};
    case UPDATE_CUSTOM_COGS_PERCENT_IS_ACTIVE:
      return { ...state, isActive: action.isActive };
    case CUSTOM_COGS_PERCENT_ON_CHANGE:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

const customFixedHandlingFeePerOrder = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SHOPIFY_SEGMENTS:
      return transformTWCosts(action.shopifySegments?.[ShopifySegmentType.TW])?.handlingFee || {};
    case UPDATE_FIXED_HANDLING_FEE_IS_ACTIVE:
      return { ...state, isActive: action.isActive };
    case CUSTOM_FIXED_HANDLING_FEE_ORDER_ON_CHANGE:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

const productsDisabled = (state = null, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.specialProps ? action.specialProps.productsDisabled : false;
    default:
      return state;
  }
};

const isProductsPreviousPageEnabled = (state = false, action) => {
  switch (action.type) {
    case RECEIVED_PRODUCTS:
      return action.isPreviousPageEnabled;
    default:
      return state;
  }
};

const isProductsNextPageEnabled = (state = true, action) => {
  switch (action.type) {
    case RECEIVED_PRODUCTS:
      return action.isNextPageEnabled;
    default:
      return state;
  }
};

const bidirectionalCogs = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.bidirectionalCogs || state;
    case SET_BIDIRECTIONAL_COGS:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = {
  cogs,
  products,
  productsSearch,
  productsSortBy,
  productsSortOrder,
  loadingProducts,
  loadingCogs,
  customCogsPercent,
  customFixedHandlingFeePerOrder,
  productsDisabled,
  isProductsPreviousPageEnabled,
  isProductsNextPageEnabled,
  exportingCogs,
  importingCogs,
  //cogsAndProducts,
  relevantCogs,
  bidirectionalCogs,
};
