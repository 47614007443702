import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { useNavigate } from 'react-router-dom';

export const TotalImpactModal = (props) => {
  const { ppsStatus, isOpen, setIsOpen } = props;
  const navigate = useNavigate();

  const getContent = () => {
    switch (ppsStatus) {
      case 'ok':
        return {
          headline: 'You’ve unlocked our Total Impact model',
          subHeadline:
            'We just launched a brand new attribution model using first-party data, post-purchase survey data + artificial intelligence.',
          body: (
            <>
              <p className=" p-2">
                We’re excited to announce the release of our newest attribution model, Total Impact.{' '}
              </p>
              <p className=" p-2">
                Triple Whale’s Total Impact model uses a blend of Pixel data, Post-Purchase Survey
                data + Artificial Intelligence to provide you with clear picture of which channels
                are delivering the most impact to your bottom line.
              </p>
              <p className=" p-2">
                If you’ve ever hypothesized that your prospecting channels are being under-reported,
                this model was built to give you a more confident view of their total impact.
              </p>
              <p className=" p-2">
                Click the button to learn more about the model or exit this pop-up to start digging
                into your data.
              </p>
            </>
          ),
          cta: {
            onClick: () => {
              window.open(
                'https://kb.triplewhale.com/en/articles/7128379-the-total-impact-attribution-model',
              );
            },
            text: 'Learn more',
          },
          dismiss: {
            onClick: () => {
              setIsOpen(false);
            },
            text: 'I’m not interested',
          },
        };
      case 'notEnoughData':
        return {
          headline: 'Improve Total Impact w/ more survey responses',
          subHeadline:
            'Increase the number of post-purchase survey respondents to improve the output of your Total Impact model',
          body: (
            <>
              <p className=" p-2">
                Don’t worry, Total Impact is enabled for your store, but improvements could be made
                with more post-purchase survey data.
              </p>
              <p className=" p-2">
                Please know that the results displayed in this model could change day to day if
                there are large variances in post-purchase survey response types.
              </p>
              <p className=" p-2">
                Click the button below to learn more about how to increase your post-purchase survey
                responses.
              </p>
            </>
          ),
          cta: {
            onClick: () => {
              window.open(
                'https://kb.triplewhale.com/en/articles/7128379-the-total-impact-attribution-model#h_91843b2bc6',
              );
            },
            text: 'Learn More',
          },
          dismiss: {
            onClick: () => {
              setIsOpen(false);
            },
            text: 'I’m not interested',
          },
        };
      case 'noData':
      default:
        return {
          headline: 'Unlock Total Impact w/ a post-purchase survey',
          subHeadline: 'Install or connect your post-purchase survey to unlock',
          body: (
            <>
              <p className="p-2">
                We’re excited to announce the release of our newest attribution model, Total Impact.
                To date, Triple Whale has been able to help you identify your best channels based on
                first-party, click attribution.
              </p>
              <p className="p-2">
                Now, with Triple Whale’s Total Impact model, we guarantee you’ll unlock new
                learnings about your marketing mix using a mix of click-based attribution,
                post-purchase survey data, and artificial intelligence.
              </p>
              <p className="p-2">
                To unlock Total Impact, install our free Post-Purchase Survey, or connect your
                Fairing or Kno Commerce account.
              </p>
              <p className="p-2">
                Click the button below to start your journey into discovering the impact your
                marketing mix is having on your business.
              </p>
            </>
          ),
          secondaryLink: {
            onClick: () => {
              window.open(
                'https://kb.triplewhale.com/en/articles/7128379-the-total-impact-attribution-model',
              );
            },
            text: 'Learn more',
          },
          cta: {
            onClick: () => {
              navigate('/post-purchase-survey/report');
            },
            text: 'Install Post-Purchase Survey',
          },
          dismiss: {
            onClick: () => {
              setIsOpen(false);
            },
            text: 'I’m not interested',
          },
        };
    }
  };

  const content = getContent();
  return (
    <DraggableModal
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      width="medium"
      style={{ backgroundColor: 'var(--dark-bg)' }}
      className="p-6.5"
      initialPosition={'center 50'}
    >
      <div className="w-full sm:rounded-[8px]">
        <div className="flex flex-col  gap-[20px] text-white  relative ">
          <div className="text-[24px] text-white font-medium pt-[30px]">{content.headline}</div>
          <div className=" flex flex-col gap-3  p-[20px] border-solid border-x-0 border-b-0 border-[#2E4E65]  ">
            <span className="text-[16px] font-medium">{content.subHeadline}</span>
            <div className=" pt-[10px]">{content.body}</div>
          </div>
        </div>

        <div className=" flex items-center">
          {content.secondaryLink && (
            <div className="w-full flex justify-start gap-5 pb-[16px]">
              <div
                className=" underline text-[#1877F2] cursor-pointer p-3 flex items-center font-medium"
                onClick={content.secondaryLink.onClick}
              >
                {content.secondaryLink.text}
              </div>
            </div>
          )}
          <div className="w-full flex justify-end gap-5 pb-[16px]">
            <div
              id="ti-popup-not-interested-button"
              className="rounded-[4px] w-[207px] text-white cursor-pointer border-solid border border-[#1877F2]  bg-[#062940] p-3 flex items-center justify-center font-medium hover:font-semibold"
              onClick={content.dismiss.onClick}
            >
              {content.dismiss.text}
            </div>
            <div
              id="ti-popup-unlock-total-impact-button"
              className="rounded-[4px] w-[210px] text-white cursor-pointer bg-[#1877F2] p-3 flex items-center justify-center font-medium hover:font-semibold"
              onClick={content.cta.onClick}
            >
              {content.cta.text}
            </div>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};
