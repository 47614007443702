import FacebookIconBase64 from 'components/FacebookIconBase64';
import InstagramIconBase64 from 'components/InstagramIconBase64';
import SnapchatIconBase64 from 'components/SnapchatIconBase64';
import TiktokIconBase64 from 'components/TiktokIconBase64';
import YoutubeIconBase64 from 'components/YoutubeIconBase64';

export const DISABLE_SHOW_IN_CHART_MESSAGE =
  'You can only compare data from up to 8 influencers at a time';

export const influencerCreativePrefix = `influencer-creatives/`;
export const influencerProfilePicturePrefix = `influencer-profiles/`;
export const TW_INFLUENCER_COLUMNS = 'TW_INFLUENCER_COLUMNS';
