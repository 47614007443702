import { useStoreValue } from '@tw/snipestate';
import { SubSettingsPopover } from '@tw/ui-components';
import { updateColorScheme } from 'dark-mode-control';
import { $pathHasDarkMode, $preferedColorScheme } from 'dark-mode-control/stores';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

export const ThemeChanger = () => {
  const colorScheme = useStoreValue($preferedColorScheme);

  const options = useMemo(() => {
    const options = [
      {
        value: 'light',
        label: 'Light',
        onClick: () => {
          updateColorScheme('light');
        },
      },
      {
        value: 'dark',
        label: 'Dark',
        onClick: () => {
          // TODO: Temporary solution until all pages have dark mode
          if (!$pathHasDarkMode.get()) {
            toast.info('No dark mode available for this page');
            return;
          }
          updateColorScheme('dark');
        },
      },
    ];

    if ('matchMedia' in window) {
      options.push({
        value: 'auto',
        label: 'Auto',
        onClick: () => {
          updateColorScheme('auto');
        },
      });
    }

    return options;
  }, []);

  return (
    <SubSettingsPopover
      options={options}
      value={colorScheme}
      textProps={{ fz: 'sm' }}
      targetLabel="Theme"
      targetIcon="theme-toggle"
    />
  );
};
