import firebase from 'firebase/compat/app';
import _db, { userDb } from 'utils/DB';
import { toast } from 'react-toastify';
import { baseURL, willyDashBucketName } from 'config';
import {
  WillyBaseMainElement,
  WillyDashboardElement,
  WillyDataSequence,
} from '../types/willyTypes';
import { getMainElementDbRef, updateMainElement } from './willyUtils';
import axiosInstance from '../../../utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';

const storage = firebase.storage;

// dash main image

export const removeDashImage = async (dashboard: WillyBaseMainElement) => {
  try {
    await updateMainElement(dashboard, { image: '' });
    await axiosInstance.post(`/v2/media/delete-dashboard-image`, {
      shopId: $currentShopId.get()!,
      id: dashboard.id,
    });
    toast.success('Dashboard image removed');
  } catch (err) {
    console.error(err);
    toast.error('Could not remove the image');
  }
};

export const updateDashImage = async (file: File, dashboard: WillyBaseMainElement) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', dashboard.id);
    formData.append('shopId', $currentShopId.get()!);

    await axiosInstance.post(`/v2/media/dashboard-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await updateMainElement(dashboard, { image: dashboard.id });

    toast.success('Dashboard image updated');
  } catch (err) {
    console.error(err);
    toast.error('Could not upload image');
  }
};

export const uploadSequenceImage = async (file: File, id: string) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    formData.append('shopId', $currentShopId.get()!);

    await axiosInstance.post(`/v2/media/dashboard-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return id;
  } catch (err) {
    console.error(err);
    toast.error('Could not upload image');
  }
};

export const getFieldImage = async (fieldContent: string) => {
  return await storage()
    .ref(fieldContent)
    .getDownloadURL()
    .then((url) => {
      return url;
    })
    .catch(() => {
      return '';
    });
};

export const removeFieldImage = async (dashboard: WillyDashboardElement, fieldId: string) => {
  try {
    const ref = storage().ref(`${willyDashBucketName}/${fieldId}`);
    await ref.delete();

    await getMainElementDbRef(dashboard).collection('fields').doc(fieldId).set(
      {
        content: '',
      },
      {
        merge: true,
      },
    );
    toast.success('Field image removed');
  } catch (err) {
    console.error(err);
    toast.error('Could not remove image');
  }
};

export const updateFieldImage = async (
  file: File,
  dashboard: WillyDashboardElement,
  fieldId: string,
) => {
  try {
    const url = `${baseURL}/v2/media/dashboard-image/${fieldId}`;
    const dbRef = getMainElementDbRef(dashboard);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', fieldId);
    formData.append('shopId', $currentShopId.get()!);

    await axiosInstance.post(`/v2/media/dashboard-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await dbRef.collection('fields').doc(fieldId).set(
      {
        content: url,
        createdAt: new Date().toISOString(),
        updateAt: new Date().toISOString(),
      },
      {
        merge: true,
      },
    );

    toast.success('Field image updated');
    return url;
  } catch (err) {
    console.error(err);
    toast.error('Could not upload image');
  }
};
