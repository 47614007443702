import axiosInstance from 'utils/axiosInstance';
import { $observer } from '@tw/snipestate';
import { $userId } from '../$user';
import { $currentShopId } from '$stores/$shop';

type GlobalDashboardStatisticsMap = Record<string, { installed: number; views?: number }>;

export const $globalDashboardsStatistics = $observer<GlobalDashboardStatisticsMap>(
  {},
  async (get, set) => {
    const uid = get($userId);
    const shopId = get($currentShopId);
    if (!uid || !shopId) return;

    const { data } = await axiosInstance.get('/v2/willy/get-installs-counter');
    const resultDict: Record<string, { installed: number; views: number }> = data?.reduce(
      (acc, { dashboardId, installed, uninstalled, views }) => {
        acc[dashboardId] = {
          installed,
          views,
        };
        return acc;
      },
      {},
    );

    set(resultDict);
  },
);
