import React, { Children } from 'react';

const InsightsSection: React.FC<{ title?: string; children }> = ({ title, children }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <>
      {title && (
        <p className="uppercase text-[#374151] tracking-wide text-[14px] font-medium mb-[16px]">
          {title}
        </p>
      )}
      <div className="flex w-full gap-[24px] mb-[20px]">
        {arrayChildren.map((c: any, i) => {
          return (
            <div
              key={i}
              className="insights-section rounded-[8px] border border-solid border-1 border-[#D1D5DB] p-[24px] grow bg-white overflow-x-auto"
            >
              {c}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default InsightsSection;
