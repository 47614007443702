import { AppShell, Box, Stack } from '@tw/ui-components';
import { BASE_HEADER_HEIGHT, TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { TopSection } from './TopSectionMobile';
import { MainLinksSection } from '../MainNav/MainLinksSection';
import { BottomSection } from '../../common/BottomSection/BottomSection';
import { SettingsTogglerSection } from '../../common/SettingsTogglerSection';
import { OptimizationsSection } from '../../common/OptimizationsSection';
import { ToggleTW3Section } from '../../common/ToggleTW3Section';
import { $canToggleBetweenVersions } from '$stores/$v3_0_Enabled';
import { useStoreValue } from '@tw/snipestate';

export const MainNavMobile = () => {
  const canToggleBetweenVersions = useStoreValue($canToggleBetweenVersions);

  return (
    <Stack w="100%" justify="space-between" pb="md" mih={`calc(100vh - ${BASE_HEADER_HEIGHT}px)`}>
      <AppShell.Section mah={BASE_HEADER_HEIGHT}>
        <TopSection />
      </AppShell.Section>

      <AppShell.Section grow>
        <MainLinksSection />
      </AppShell.Section>

      <AppShell.Section>
        <BottomSection />
      </AppShell.Section>

      <AppShell.Section pt="md" borderTop={TOP_BAR_BORDER_VAR}>
        <OptimizationsSection />
      </AppShell.Section>

      <AppShell.Section pt="md" borderTop={TOP_BAR_BORDER_VAR}>
        <SettingsTogglerSection />
      </AppShell.Section>

      {canToggleBetweenVersions ? (
        <AppShell.Section pt="md" borderTop={TOP_BAR_BORDER_VAR}>
          <ToggleTW3Section />
        </AppShell.Section>
      ) : (
        <Box />
      )}
    </Stack>
  );
};
