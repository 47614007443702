import { PackageMetaData } from '@tw/feature-flag-system/module/types';
import { assertValidPackageMetaDataList } from '@tw/feature-flag-system/module/utils';
import axiosInstance from 'utils/axiosInstance';

export const getPackageMetaData = async (): Promise<PackageMetaData[]> => {
  const { data } = await axiosInstance.get('v2/subscription-manager/features/conf');

  assertValidPackageMetaDataList(data);

  return data;
};
