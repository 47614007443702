import db, { FieldValue } from '../utils/DB';
import { toggleSaveIndicator, setOptimisticImporting } from './actions';
import { INIT_SHOP, SET_OPTIMISTIC_IMPORTING_FOR_SERVICE } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { minimizeSectionOnPress } from './summary';
import { callInitialImport } from 'utils/callInitialImport';
import axiosInstance from 'utils/axiosInstance';
import { callUpdateToday } from 'utils/callUpdateToday';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

export const KLAVIYO_CONNECT_ON_PRESS = 'KLAVIYO_CONNECT_ON_PRESS';
export const klaviyoConnectOnPress = () => {
  return {
    type: KLAVIYO_CONNECT_ON_PRESS,
  };
};

export const TOGGLE_KLAVIYO_CONFIG_SCREEN_OPEN = 'TOGGLE_KLAVIYO_CONFIG_SCREEN_OPEN';
export const toggleKlaviyoConfigScreenOpen = () => ({
  type: TOGGLE_KLAVIYO_CONFIG_SCREEN_OPEN,
});

export const KLAVIYO_CONFIG_SAVE = 'KLAVIYO_CONFIG_SAVE';
export const klaviyoConfigSave = () => {
  return {
    type: KLAVIYO_CONFIG_SAVE,
  };
};

export function klaviyoConfigSavePress({ klaviyoKey }) {
  return (dispatch) => {
    toggleSaveIndicator();
    dispatch(klaviyoConfigSave());
    return db()
      .update({
        klaviyoKey: klaviyoKey.trim(),
      })
      .then(() => {
        dispatch(startImportingKlaviyoData());
        dispatch(minimizeSectionOnPress('klaviyo', false));
      });
  };
}

export const startImportingKlaviyoData = () => (dispatch) => {
  const url = `/v2/klaviyo/connect`;
  axiosInstance
    .post(url)
    .then((res) => {
      if (res) {
        callInitialImport('klaviyo').then(() => {
          dispatch(setOptimisticImporting('klaviyo'));
        });
      }
    })
    .catch((e) => {
      console.error(`failed to connect to klaviyo`);
      console.log(e.message);
      dispatch(klaviyoDisconnect());
    });
  window.scrollTo(0, 0);
};

export function klaviyoDisconnect() {
  return (dispatch) => {
    toggleSaveIndicator();
    const url = `v2/klaviyo/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: $currentShopId.get(),
      })
      .then(() => {
        dispatch(callRemoveLocalShopProp('klaviyoKey'));
        dispatch(klaviyoDisconnectDone());
      });
  };
}

export const KLAVIYO_DISCONNECT_DONE = 'KLAVIYO_DISCONNECT_DONE';
export function klaviyoDisconnectDone() {
  return {
    type: KLAVIYO_DISCONNECT_DONE,
  };
}

const klaviyoConnectConfigIsOpen = (state = false, action) => {
  switch (action.type) {
    case KLAVIYO_CONNECT_ON_PRESS:
      return true;
    case TOGGLE_KLAVIYO_CONFIG_SCREEN_OPEN:
      return !state;
    case KLAVIYO_CONFIG_SAVE:
      return false;
    default:
      return state;
  }
};

const isKlaviyoConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return !!action.klaviyoKey;
    case KLAVIYO_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === 'klaviyo' || state;
    default:
      return state;
  }
};

export const reducers = {
  isKlaviyoConnected,
  klaviyoConnectConfigIsOpen,
};
