import { Checkbox } from '@shopify/polaris';
import { CreativesCockpitContext } from 'components/CreativesCockpit/context';
import { getObjToSave } from 'components/CreativesCockpit/CreativeTable/CreativeTextField';
import { saveProductAnalyticsForecast } from 'ducks/productAnalytics';
import { useAppDispatch } from 'index';
import React, { useContext, useEffect } from 'react';

export const SnoozeProduct: React.FC<{ product }> = ({ product }) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    setChecked(product?.metrics?.leadTime === -1);
  }, [product]);

  const { itemType } = useContext(CreativesCockpitContext);

  return (
    <div className="flex justify-center align-center">
      {' '}
      <Checkbox
        label={undefined}
        checked={checked}
        onChange={(val) => {
          const objToSave = getObjToSave(itemType, product, val === true ? -1 : 0);
          dispatch(saveProductAnalyticsForecast(objToSave));
        }}
      />{' '}
    </div>
  );
};
