import { $observer } from '@tw/snipestate';
import { userDb } from '../../utils/DB';
import { $user } from '../$user';
import { $currentShopId } from '$stores/$shop';
import { isEqual } from 'lodash';
import { emptyArray } from '@tw/stats/module/generalUtils';
import { $userId } from '$stores/$user';
import firebase from 'firebase/compat';
import { Prettify } from '@tw/ui-components';

type UserWillyCollShopDocDataStore = {
  id: string;
  data?: firebase.firestore.DocumentData;
};

/** Snapshot data + id for changes in user collection -> willy sub collection -> doc (which is shop doc id) */
const $userWillyCollShopDocData = $observer<Prettify<UserWillyCollShopDocDataStore>>(
  { id: '' },
  (get, set) => {
    const shopId = get($currentShopId);
    const uid = get($userId);
    if (!shopId || !uid) return;

    return userDb(uid)
      .collection('willy')
      .doc(shopId)
      ?.onSnapshot((snapshot) => {
        set({ id: snapshot.id, data: snapshot.data() });
      });
  },
);

export const $favoriteDashboards = $observer<string[]>([], (get, set) => {
  const { data: shop } = get($userWillyCollShopDocData);
  const favoriteDashboards = shop?.favoriteDashboards || emptyArray;
  if (!isEqual(favoriteDashboards, get())) set(favoriteDashboards);
});

export const addFavoriteDashboard = async (dashboardId?: string) => {
  const shopId = $currentShopId.get();
  const user = $user.get();

  if (!shopId || !user.uid || !dashboardId) return;

  const favoriteDashboards = $favoriteDashboards.get();
  const newFavoriteDashboards = [...new Set([...favoriteDashboards, dashboardId])];

  await userDb()
    .collection('willy')
    .doc(shopId)
    .set({ favoriteDashboards: newFavoriteDashboards }, { merge: true });
};

export const removeFavoriteDashboard = async (dashboardId?: string) => {
  const shopId = $currentShopId.get();
  const user = $user.get();

  if (!shopId || !user.uid || !dashboardId) return;

  const favoriteDashboards = $favoriteDashboards.get();
  const newFavoriteDashboards = favoriteDashboards.filter((id) => id !== dashboardId);

  await userDb()
    .collection('willy')
    .doc(shopId)
    .set({ favoriteDashboards: newFavoriteDashboards }, { merge: true });
};
