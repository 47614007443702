import { Checkbox, CheckboxProps } from '@shopify/polaris';
import { FC } from 'react';
import './ColoredCheckbox.scss';

type ColoredCheckboxProps = {
  color?: string;
} & CheckboxProps;

export const ColoredCheckbox: FC<ColoredCheckboxProps> = ({ color = '#1878f3', ...props }) => {
  return (
    <>
      <div
        className="tw-colored-checkbox rounded flex"
        style={
          {
            '--custom-color': color || 'var(--dark-bg)',
          } as React.CSSProperties
        }
      >
        <Checkbox {...props} />
      </div>
      <style>{`

      `}</style>
    </>
  );
};
