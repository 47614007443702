import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { userDb } from 'utils/DB';
import { ShopNavItem } from './types';

export function useStoresNav() {
  const [shops, setShops] = useState<ShopNavItem[]>([]);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const getPodsLoading = useAppSelector((state) => state.getPodsLoading);
  const shopsNavShopsOrder = useAppSelector((state) => state.user.shopsNavShopsOrder);
  const shopsAsArray = useAppSelector((state) => state.shopsAsArray);

  const handleShopsOrderChanged = useCallback(
    ({ source, destination }) => {
      if (destination?.index === undefined) return;

      const dup = [...shops];
      const [a] = dup.splice(source.index, 1);
      dup.splice(destination.index, 0, a);

      setShops(dup);
      userDb().set({ shopsNavShopsOrder: dup.map(({ shopId }) => shopId) }, { merge: true });
    },
    [shops],
  );

  useEffect(() => {
    if (getPodsLoading) return;

    setShops(sortShopsAccToCustomOrder(currentShopId, shopsAsArray, shopsNavShopsOrder));
  }, [getPodsLoading, shopsAsArray, shopsNavShopsOrder, currentShopId]);

  return {
    shops,
    handleShopsOrderChanged,
  };
}

//
// UTILS
//
const sortShopsAlphabetically = (shops: ShopNavItem[]) => {
  return [...shops].sort((a, b) => {
    if (a.shopId < b.shopId) return -1;
    if (a.shopId > b.shopId) return 1;
    return 0;
  });
};

const sortShopsAccToCustomOrder = (
  currentShopId: string,
  shops: ShopNavItem[],
  existingShopsOrder?: string[],
) => {
  shops = [...shops];
  const currentShopIndex = shops.findIndex((s) => s.shopId === currentShopId);
  const [currentShop] = shops.splice(currentShopIndex, 1);
  if (!existingShopsOrder?.length) {
    const sortedShops = sortShopsAlphabetically(shops);
    if (currentShop) sortedShops.unshift(currentShop);
    return sortedShops;
  }

  const { existingShops, nonexistingShops } = shops.reduce(
    (acc, x) => {
      const key = !existingShopsOrder.includes(x.shopId) ? 'nonexistingShops' : 'existingShops';
      acc[key].push(x);
      return acc;
    },
    {
      existingShops: [] as ShopNavItem[],
      nonexistingShops: [] as ShopNavItem[],
    },
  );

  const tracker: Record<ShopNavItem['shopId'], number> = {};
  for (let i = 0; i < existingShopsOrder.length; i++) {
    const shopId = existingShopsOrder[i];
    tracker[shopId] = i;
  }

  const sortedShops = existingShops
    .sort((a, b) => tracker[a.shopId] - tracker[b.shopId])
    .concat(sortShopsAlphabetically(nonexistingShops));

  if (currentShop) sortedShops.unshift(currentShop);
  return sortedShops;
};
