import { Button, ButtonGroup, Tooltip } from '@shopify/polaris';
import { SegmentedControl } from '@tw/ui-components';

const FILTER_DATA = [3, 6, 12].map((numOfMonths) => {
  return {
    value: numOfMonths.toString(),
    label: (
      <Tooltip
        content={`View results for ${numOfMonths} months`}
        dismissOnMouseOut
        key={numOfMonths}
      >
        {`Last ${numOfMonths} Months`}
      </Tooltip>
    ),
  };
});

const DurationFilters = ({ duration, handleDurationChange }) => (
  <SegmentedControl
    value={duration.toString()}
    data={FILTER_DATA}
    onChange={(val) => {
      handleDurationChange(Number(val));
    }}
  />
);

export default DurationFilters;
