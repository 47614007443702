import axiosInstance from '../utils/axiosInstance';

export const CALCULATING_INVENTORY = 'CALCULATING_INVENTORY';
export const CALCULATING_INVENTORY_DONE = 'CALCULATING_INVENTORY_DONE';
export const CURRENT_INVENTORY = 'CURRENT_INVENTORY';

export const calculateInventory = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CALCULATING_INVENTORY });
    var calculated;
    try {
      const url = `/v2/summary-page/calculate-inventory`;
      const { currentShopId } = getState();
      const { data } = await axiosInstance.post(url, { shopDomain: currentShopId });
      calculated = data;
    } catch (e) {
      calculated = {};
    }
    dispatch({
      type: CALCULATING_INVENTORY_DONE,
      ...calculated,
    });
  };
};

export const getCurrentInventory = (shopId) => {
  return async (dispatch, getState) => {
    let currentInventoryData = {};
    try {
      const { currentShopId } = getState();
      const url = `/v2/shopify/mongo/get-current-inventory?shopId=${currentShopId}`;
      const { data } = await axiosInstance.get(url);
      currentInventoryData = data;
    } catch (e) {
      console.log('Get Current Inventory', e);
    }
    dispatch({
      type: CURRENT_INVENTORY,
      currentInventory: currentInventoryData,
    });
  };
};

const calculatingInventory = (state = false, action) => {
  switch (action.type) {
    case CALCULATING_INVENTORY:
      return true;
    case CALCULATING_INVENTORY_DONE:
      return false;
    default:
      return state;
  }
};

const totalInventoryItems = (state = 0, action) => {
  switch (action.type) {
    case CALCULATING_INVENTORY:
      return 0;
    case CALCULATING_INVENTORY_DONE:
      return action.totalInventoryItems || state;
    default:
      return state;
  }
};

const totalInventoryItemsMissing = (state = 0, action) => {
  switch (action.type) {
    case CALCULATING_INVENTORY:
      return 0;
    case CALCULATING_INVENTORY_DONE:
      return action.totalInventoryItemsMissing || state;
    default:
      return state;
  }
};

const totalInventoryCost = (state = 0, action) => {
  switch (action.type) {
    case CALCULATING_INVENTORY:
      return 0;
    case CALCULATING_INVENTORY_DONE:
      return action.totalInventoryCost || state;
    default:
      return state;
  }
};

const totalInventoryValue = (state = 0, action) => {
  switch (action.type) {
    case CALCULATING_INVENTORY:
      return 0;
    case CALCULATING_INVENTORY_DONE:
      return action.totalInventoryValue || state;
    default:
      return state;
  }
};

const currentInventory = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_INVENTORY:
      return action.currentInventory || state;
    default:
      return state;
  }
};

export const reducers = {
  calculatingInventory,
  totalInventoryItems,
  totalInventoryItemsMissing,
  totalInventoryCost,
  totalInventoryValue,
  currentInventory,
};
