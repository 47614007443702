import { type AppDispatch } from 'index';
import { RootState } from 'reducers/RootType';
import { Reducer, combineReducers } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import { LighthouseTypesDictionary } from 'utils/lighthouse';
import { lighthousePopupOpen } from './lighthouse';
import { LOADED_FROM_NOTIFICATION } from './constants';
import { updateCurrentShopId } from './auth';

export const setIsMobileApp = () => {
  return async (dispatch: AppDispatch) => {
    const search = new URLSearchParams(location.search);
    const expoToken = search.get('token');
    const hasPushNotificationsPermissions = search.get('notificationsAllowed');
    localStorage.setItem('IS_FROM_MOBILE_APP', 'true');
    if (expoToken && hasPushNotificationsPermissions == '1') {
      // alert('setting token');
      localStorage.setItem('EXPO_TOKEN', expoToken);
    } else if (hasPushNotificationsPermissions === '0') {
      // alert('removing token');
      localStorage.setItem('EXPO_TOKEN', '');
    } else {
      // alert('doing nothing');
    }
    dispatch({
      type: SET_IS_MOBILE_APP,
      payload: {},
    });
  };
};

export const SET_IS_MOBILE_APP = 'SET_IS_MOBILE_APP';
export const isFromMobileApp: Reducer = (state: Boolean, action: any = {}) => {
  switch (action.type) {
    case SET_IS_MOBILE_APP: {
      return true;
    }
    default: {
      return state || false;
    }
  }
};

export const customLoadFromAppNotification = (openParams: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const params = new URLSearchParams(openParams);
    const serviceId = params.get('serviceId');
    const notificationId = params.get('notificationId');
    const shopId = params.get('shopId');
    const sourceId = params.get('sourceId');
    const platform = params.get('platform');
    try {
      //mark notification as opened
      if (platform === 'email' || platform === 'slack') {
        await axiosInstance.post('/v2/notifications/notification-opened', {
          notificationId,
        });
      } else {
        await axiosInstance.post('/v2/push-notifications/notification-opened', {
          notificationId,
        });
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(updateCurrentShopId(shopId));
    switch (serviceId) {
      case 'lighthouse':
        const { data } = await axiosInstance.post('/v2/lighthouse/get-notification-by-id', {
          notificationId: sourceId,
          shopId: shopId,
        });
        const notification = LighthouseTypesDictionary[data.type].transformer(data);
        dispatch(lighthousePopupOpen(notification));
        break;
      case 'activities':
        if (sourceId?.includes('integration')) {
          window.location.replace(`integrations`);
        } else {
          window.location.replace(`activity-feed`);
        }
        break;
      case 'forecasting':
        if (sourceId === 'set_monthly_goals') {
          window.location.replace(`forecast?set-goals=true`);
        } else {
          window.location.replace(`forecast`);
        }

        break;
      default:
        break;
    }
    dispatch({
      type: LOADED_FROM_NOTIFICATION,
      payload: notificationId,
    });
  };
};

const isOpenedFromAppNotification = (state = false, action) => {
  switch (action.type) {
    case LOADED_FROM_NOTIFICATION:
      return true;
    default:
      return false;
  }
};

const fromNotificationId = (state = '', action) => {
  switch (action.type) {
    case LOADED_FROM_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  isFromMobileApp,
  isOpenedFromAppNotification,
  fromNotificationId,
});
