import { Tooltip } from '@shopify/polaris';
import { WillyMetric } from './types/willyTypes';
import { ReactComponent as QuestionMark } from 'icons/question-mark.svg';

type WillyDescriptionTooltipProps = {
  metric: WillyMetric;
};

export const WillyDescriptionTooltip: React.FC<WillyDescriptionTooltipProps> = ({ metric }) => {
  const tip = metric?.description;

  if (!tip) {
    return null;
  }

  return (
    <div>
      <Tooltip content={tip}>
        <div className="opacity-0 group-hover/tile:opacity-100 transition-opacity">
          <QuestionMark width={16} height={16} className="text-logo flex cursor-pointer" />
        </div>
      </Tooltip>
    </div>
  );
};
