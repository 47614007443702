import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionList } from '@shopify/polaris';
import { goToPodsView } from 'utils/goToPodsView';
import { useAppSelector } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { useNavigate } from 'react-router-dom';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { switchStore, updateActiveAccounts } from 'ducks/auth';
import {
  Loader,
  Checkbox,
  Flex,
  Text,
  Icon,
  Popover,
  useDisclosure,
  Anchor,
  TextInput,
} from '@tw/ui-components';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useStoreValue } from '@tw/snipestate';
import { $activeAccounts } from '$stores/$shop';
import { $currentShopId } from '$stores/$shop';
import { resetStats } from 'ducks/newStats';
import { ShopSelectItem } from './ShopSelectItem';
import { ShopDetails } from './ShopDetails';
import { AddNewShopButton } from './AddNewShopButton';
import { PlanUpgradeCTA } from 'components/Willy/dashboardManagment/PlanUpgradeCTA';
import { $isFreeShop } from '$stores/willy/$subscription';

export type TopSectionProps = {
  iconOnly?: boolean;
  showArrows?: boolean;
};
export const TopSection = ({ iconOnly = false, showArrows = true }: TopSectionProps) => {
  return (
    <Flex justify="center" align="center" w="100%">
      <TopBarStoresSwitcher iconOnly={iconOnly} showArrows={showArrows} />
    </Flex>
  );
};

const TopBarStoresSwitcher = ({
  iconOnly = false,
  showArrows = true,
}: {
  iconOnly: boolean;
  showArrows: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchShopsValue, setSearchShopsValue] = useState('');
  const [textInput, setTextInput] = useState<HTMLInputElement | null>(null);
  const [popoverActive, { close: closePopover, toggle }] = useDisclosure(false);
  const isFreeShop = useStoreValue($isFreeShop);

  const currentShopId = useStoreValue($currentShopId) || '';
  const activeAccounts = useStoreValue($activeAccounts);
  const activeAppVersion = useStoreValue($activeAppVersion);
  const shopsAsArray = useAppSelector((state) => state.shopsAsArray);
  const { shouldNotBeSeen: isMultiShopBlocked } = useFeatureFlag(FeatureFlag.MULTI_GEO_FF);
  const filtered = useMemo(() => {
    if (!searchShopsValue) return shopsAsArray;
    return shopsAsArray.filter((i) =>
      (i.shopName || i.shopId.split('.')[0]).toLowerCase().includes(searchShopsValue.toLowerCase()),
    );
  }, [searchShopsValue, shopsAsArray]);

  useEffect(() => {
    textInput?.focus();
  }, [textInput]);

  const handleChange = useCallback(
    (shops: string[]) => {
      closePopover();
      setSearchShopsValue('');
      dispatch(resetStats());
      dispatch(switchStore(shops[0]));
    },
    [closePopover, dispatch],
  );

  const activator = useMemo(() => {
    return (
      <div className="stores-switcher-activator flex flex-auto cursor-pointer" onClick={toggle}>
        <ShopSelectItem iconOnly={iconOnly} showArrows={showArrows} />
      </div>
    );
  }, [toggle, iconOnly, showArrows]);

  return (
    <Popover
      opened={popoverActive}
      onClose={closePopover}
      position="bottom-end"
      offset={{ crossAxis: 100, mainAxis: 10 }}
      shadow="0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)"
    >
      <Popover.Target>
        <div>{activator}</div>
      </Popover.Target>
      <Popover.Dropdown maw={350}>
        <div className={`${activeAppVersion === '3.0' ? 'v3' : 'v2'}`}>
          <ShopDetails />
          {isFreeShop && (
            <div className="mb-4">
              <PlanUpgradeCTA />
            </div>
          )}
          <Flex mih="1px" maw="100%" bg="gray.2" />
          {!activeAccounts && (
            <div className="flex justify-center items-center h-[200px] w-[190px]">
              <Loader />
            </div>
          )}
          {activeAccounts && (
            <>
              {shopsAsArray.length > 1 && (
                <>
                  <TextInput
                    ref={setTextInput}
                    leadingIcon={<Icon name="search-major" color="gray.5" size={14} />}
                    value={searchShopsValue}
                    onChange={setSearchShopsValue}
                    placeholder="Search stores"
                  />
                  {activeAppVersion === '3.0' && !isMultiShopBlocked ? (
                    <div className="flex flex-col py-4 max-h-[225px] overflow-y-auto">
                      <Flex gap="xs" justify="flex-start" align="center" pb="xs">
                        <Checkbox
                          size="xs"
                          checked={filtered.every((s) => activeAccounts.includes(s.shopId))}
                          indeterminate={
                            activeAccounts.length > 0 && activeAccounts.length < filtered.length
                          }
                          onChange={(checked) => {
                            const newList = checked
                              ? filtered.map((store) => store.shopId)
                              : [currentShopId];
                            dispatch(updateActiveAccounts(newList));
                          }}
                        />
                        <Text weight={500}>Select all</Text>
                      </Flex>

                      <Flex mih="1px" maw="100%" bg="gray.2" />

                      <Flex direction="column" gap="xs" mt="xs">
                        {filtered.map(({ shopId, shopName }) => (
                          <Flex key={shopId} gap="xs" justify="flex-start" align="center">
                            <Checkbox
                              size="xs"
                              disabled={currentShopId === shopId}
                              checked={activeAccounts.includes(shopId)}
                              onClick={(e) => {
                                const checked = e.currentTarget.checked;
                                dispatch(
                                  updateActiveAccounts(
                                    checked
                                      ? [...activeAccounts, shopId]
                                      : activeAccounts.filter((a) => a !== shopId),
                                  ),
                                );
                              }}
                            />
                            <Anchor
                              as="button"
                              fz="md"
                              fw={500}
                              onClick={() => handleChange([shopId])}
                            >
                              {shopName}
                            </Anchor>
                          </Flex>
                        ))}
                      </Flex>
                    </div>
                  ) : (
                    <div
                      className={`current-shop-option-list max-h-[150px] overflow-y-auto flex ${
                        isMultiShopBlocked ? 'pl-5' : ''
                      }`}
                    >
                      <OptionList
                        options={filtered.map((s) => ({ label: s.shopName, value: s.shopId }))}
                        selected={[currentShopId]}
                        onChange={handleChange}
                      />
                    </div>
                  )}

                  <Flex mih="1px" maw="100%" bg="gray.2" />
                </>
              )}
              <Flex direction="column" gap="xs" pt="md">
                <Anchor
                  as="button"
                  textProps={{ fz: 'sm', fw: 500 }}
                  leftIcon={<Icon name="pods-view" />}
                  onClick={() => {
                    closePopover();
                    goToPodsView(navigate, dispatch);
                  }}
                >
                  Pods View
                </Anchor>
                <AddNewShopButton />
              </Flex>
            </>
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
