export default function DripIconBase64({ small }) {
  return (
    <img
      style={{ height: small ? 15 : 15 }}
      alt="Drip icon"
      src="
      data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iODAuMDAwMDAwcHQiIGhlaWdodD0iMTA2LjAwMDAwMHB0IiB2aWV3Qm94PSIwIDAgODAuMDAwMDAwIDEwNi4wMDAwMDAiCiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KCjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLDEwNi4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik0zMjQgOTc3IGMtNzYgLTc5IC0xNDQgLTE2MCAtMTQ0IC0xNzEgMCAtMyAzMSAtNiA2OSAtNiBsNjkgMCA0MiA1Ngo0MiA1NiA0MSAtNTYgNDEgLTU2IDc0IDAgNzQgMCAtNDMgNTMgYy01MCA2MSAtMTgxIDE5NyAtMTkwIDE5NyAtNCAwIC0zNyAtMzMKLTc1IC03M3oiLz4KPHBhdGggZD0iTTExOSA2NjcgYy02MyAtNDggLTM2IC0xMzcgNDIgLTEzNyA3MiAwIDEwMyA4NiA0OCAxMzMgLTI0IDIwIC02NwoyMiAtOTAgNHoiLz4KPHBhdGggZD0iTTM1OSA2NjcgYy02MyAtNDggLTM2IC0xMzcgNDIgLTEzNyA3MiAwIDEwMyA4NiA0OCAxMzMgLTI0IDIwIC02NwoyMiAtOTAgNHoiLz4KPHBhdGggZD0iTTU5OSA2NjcgYy02MyAtNDggLTM2IC0xMzcgNDIgLTEzNyA3MiAwIDEwMyA4NiA0OCAxMzMgLTI0IDIwIC02NwoyMiAtOTAgNHoiLz4KPHBhdGggZD0iTTAgMzk1IGMwIC03IDUgLTM4IDExIC02NyA1MSAtMjM4IDMwNiAtMzg2IDUyMiAtMzAxIDE0NiA1NyAyNDMgMTc5CjI2MyAzMzIgbDcgNTEgLTYyIDAgLTYxIDAgMCAtMzcgYy0xIC01MCAtMzMgLTExOSAtNzggLTE2NyAtMTE1IC0xMjAgLTMwMQotMTE1IC00MTEgMTAgLTQxIDQ3IC03MSAxMTcgLTcxIDE2NiAwIDI4IC0xIDI4IC02MCAyOCAtNDYgMCAtNjAgLTMgLTYwIC0xNXoiLz4KPC9nPgo8L3N2Zz4K
      "
    />
  );
}
