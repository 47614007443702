export type Actions =
  | { type: 'add'; rules: Array<any> }
  | { type: 'replace_all'; rules: Array<any> }
  | { type: 'replace'; rule: any }
  | { type: 'add_new'; rules: Array<any> }
  | { type: 'edit'; rules: Array<any> }
  | { type: 'delete_rule'; rule: any };

export const rulesReducer = (rules, action: Actions) => {
  switch (action.type) {
    case 'add':
      // const addRules = action.rules.map((rule) => ({
      //   ...rule,
      // }));
      return [...rules, ...action.rules];
    case 'replace_all':
      return [...action.rules];
    case 'replace':
      const ruleIndex = rules.findIndex((rule) => action.rule._id === rule._id);
      return [...rules.slice(0, ruleIndex), { ...action.rule }, ...rules.slice(ruleIndex + 1)];
    case 'add_new':
      return [...rules, ...action.rules];
    case 'edit':
      return [...rules, ...action.rules];
    case 'delete_rule':
      const i = rules.findIndex((rule) => action.rule._id === rule._id);
      let newArr = [...rules];
      newArr.splice(i, 1);
      return newArr;
  }
};
