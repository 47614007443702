import React from 'react';
const ApplovinIconBase64 = ({ small }) => (
  <img
    style={{ height: small ? 15 : 20 }}
    alt="Applovin icon"
    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJsb2dvc2FuZHR5cGVzX2NvbSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTUwIDE1MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwNTk5YzU7fS5jbHMtMntmaWxsOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTAsMEgxNTBWMTUwSDBWMFoiLz48ZyBpZD0iTGF5ZXJfMiI+PGcgaWQ9IkxheWVyXzEtMiI+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTQyLjcsMTA2LjUzYy00LjM2LTQuNi0xMi4yMi02LjI1LTE4LjE4LTMuNTZoMEw4OC4xOSwzNy41MWwtLjExLS4yNGM4LjEzLTEwLjc4LC4zMy0yNi41Ni0xMy4zLTI2LjUzLTEzLjQ1LS4xNC0yMS4zOSwxNS42OC0xMy40MiwyNi4zNi0uMTcsLjI3LTM2LjMxLDY1LjQ3LTM2LjUsNjUuOC0xMC41NC00LjU4LTIzLjEzLDMuNzktMjIuODcsMTUuMzUsMCw5LjE2LDcuNDMsMTYuNTksMTYuNTksMTYuNTksMTMuNjYsLjE0LDIxLjU2LTE2LjEzLDEzLjEyLTI2LjcyLDIyLjM4LTYuMDQsNjIuNjQtNi4xMyw4Ni4wNiwuMDgtOC4zMSwxMC42Mi0uNDEsMjYuNzgsMTMuMiwyNi42NCwxNC43NSwuMDQsMjIuMS0xOC4wOSwxMS43My0yOC4zMlptLTEyNC4wMSwyMS41OGMtNS40MiwuMDItOS44My00LjM1LTkuODUtOS43NywuNS0xMy4wMywxOC45Mi0xMy4wNywxOS41OC0uMDhoLjA1Yy4wMiw1LjQyLTQuMzUsOS44My05Ljc3LDkuODVaTTc0Ljc4LDE3LjUzYzUuNDEsLjAzLDkuNzgsNC40LDkuODEsOS44MS0uNTEsMTMuMDItMTkuMTEsMTMuMDEtMTkuNjMsMCwwLTUuNDIsNC4zOS05LjgxLDkuODEtOS44MVpNMzQuMzUsMTAwLjMybDMyLjM4LTU4LjQzdi0uMDNjNC42NSwyLjcyLDExLjI5LDIuNzUsMTUuOTYsLjA4bDMyLjQyLDU4LjM4Yy0yNC43Mi01LjQyLTU4LjM1LTUuMTgtODAuNzYsMFptOTYuNjcsMjcuNzljLTUuNDIsLjAyLTkuODMtNC4zNS05Ljg1LTkuNzctLjAyLTUuNDIsNC4zNS05LjgzLDkuNzctOS44NSw1LjQsMCw5LjgsNC4zNyw5Ljg1LDkuNzcsLjAyLDUuNDItNC4zNSw5LjgzLTkuNzcsOS44NVoiLz48L2c+PC9nPjwvc3ZnPg=="
  />
);

export default ApplovinIconBase64;
