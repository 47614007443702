export default function GoogleSheetsIconBase64({ small }) {
  return (
    <img
      src="
      data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjM2IiB2aWV3Qm94PSIwIDAgMzYgMzYiIHdpZHRoPSIzNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0wIDBoMzZ2MzZoLTM2eiIvPjxnIGZpbGwtcnVsZT0ibm9uemVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNiAxLjMzMzMzMykiPjxwYXRoIGQ9Im0xNS43NSAwIDguMjUgOC4yNS00LjEyNS43NS00LjEyNS0uNzUtLjc1LTQuMTI1eiIgZmlsbD0iIzE4ODAzOCIvPjxwYXRoIGQ9Im0xNS43NSA4LjI1di04LjI1aC0xMy41Yy0xLjI0MzEyNSAwLTIuMjUgMS4wMDY4NzUtMi4yNSAyLjI1djI4LjVjMCAxLjI0MzEyNSAxLjAwNjg3NSAyLjI1IDIuMjUgMi4yNWgxOS41YzEuMjQzMTI1IDAgMi4yNS0xLjAwNjg3NSAyLjI1LTIuMjV2LTIyLjV6IiBmaWxsPSIjMzRhODUzIi8+PHBhdGggZD0ibTQuNSAxMi43NXYxMC44NzVoMTV2LTEwLjg3NXptNi41NjI1IDloLTQuNjg3NXYtMi42MjVoNC42ODc1em0wLTQuNWgtNC42ODc1di0yLjYyNWg0LjY4NzV6bTYuNTYyNSA0LjVoLTQuNjg3NXYtMi42MjVoNC42ODc1em0wLTQuNWgtNC42ODc1di0yLjYyNWg0LjY4NzV6IiBmaWxsPSIjZmZmIi8+PC9nPjwvZz48L3N2Zz4=
      "
      style={{ height: small ? 11 : 20 }}
      alt="Google Sheets Icon"
    />
  );
}
