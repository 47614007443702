import { creativeHubMedia } from '@tw/helpers/module/creativeHubMedia';
import TWImage from 'components/library/TWImage/TWImage';
import { isProduction } from 'config';

type ProductMediaCardProps = {
  prod: any;
  openItem: () => void;
};

const ProductMediaCard: React.FC<ProductMediaCardProps> = ({ prod, openItem }) => {
  const openProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openItem();
  };
  const coverImages = prod.media?.filter((m) => m.service_id === 'shopify');
  return (
    <span className="flex cursor-pointer flex-col" onClick={(e) => openProduct(e)}>
      {coverImages?.length > 0 ? (
        <div className="imageWrapper flex">
          <div className="bigImage flex-auto">
            <TWImage
              wrapperClass="h-full"
              className="w-full h-full object-cover"
              src={creativeHubMedia(coverImages[0], !isProduction)?.url || ''}
            />
          </div>
          {prod?.media?.length > 1 && (
            <div className="smallImages flex flex-col">
              {!!coverImages?.[1] && (
                <div className="image flex-auto">
                  <TWImage
                    wrapperClass="h-full"
                    className="w-full h-full object-cover"
                    src={creativeHubMedia(coverImages[1], !isProduction)?.url || ''}
                  />
                </div>
              )}
              {!!coverImages?.[2] && (
                <div className="image">
                  <TWImage
                    wrapperClass="h-full w-full"
                    className="w-full h-full object-cover"
                    src={creativeHubMedia(coverImages[2], !isProduction)?.url || ''}
                  />
                </div>
              )}
              {!!coverImages?.[3] && (
                <div className="image">
                  <TWImage
                    wrapperClass="h-full"
                    className="w-full h-full object-cover"
                    src={creativeHubMedia(coverImages[3], !isProduction)?.url || ''}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="no-media">No Media</div>
      )}
      <div className="flex items-center">
        <div className="font-bold p-4 whitespace-nowrap overflow-hidden text-ellipsis">
          {prod.title}
        </div>
      </div>
    </span>
  );
};

export default ProductMediaCard;
