import { useMemo, useState } from 'react';
import {
  Anchor,
  Button,
  Combobox,
  Icon,
  Image,
  StoresNavProps,
  Text,
  extractCSSColor,
  useCombobox,
} from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import { cx } from 'utils/cx';
import classes from './MobileStoresChooser.module.scss';
import { $currentShopId } from '$stores/$shop';
import { truncate } from 'lodash';
import { ShopNavItem } from '../navs/StoresNav/types';
import { updateCurrentShopId } from '../../../../ducks/auth';
import { useAppDispatch } from '../../../../index';
import { useStoreValue } from '@tw/snipestate';
import { getShopName } from '@tw/constants/module/utils';

export function ShopSelectItem({ shopImage, shopId, shopName }: ShopNavItem) {
  return (
    <div className="flex items-center justify-start gap-4">
      <Image src={shopImage} w={20} />
      <Text size="sm" fw={500}>
        {getShopName(shopName || shopId.replace('.myshopify.com', ''))}
      </Text>
    </div>
  );
}

interface IMobileStoresChooser {
  shops: StoresNavProps['shops'];
  onStoreClicked: (shopId: string) => void;
}

export function MobileStoresChooser({ shops, onStoreClicked }: IMobileStoresChooser) {
  const [search, setSearch] = useState('');
  const darkMode = useDarkMode();
  const dispatch = useAppDispatch();
  const currentShopId = useStoreValue($currentShopId);

  const combobox = useCombobox({
    onDropdownOpen() {
      combobox.focusSearchInput();
    },
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
  });

  const selectedOption = useMemo(
    () => shops.find(({ shopId }) => shopId === currentShopId),
    [shops, currentShopId],
  );

  const truncSelectedShopId = useMemo(
    () => truncate(selectedOption?.shopId, { length: 30 }),
    [selectedOption],
  );

  const comboboxOptions = useMemo(
    () =>
      shops
        .filter((shopItem) => shopItem.shopId.toLowerCase().includes(search.toLowerCase()))
        .map((shopItem) => (
          <Combobox.Option
            value={getShopName(shopItem.shopId)!}
            key={shopItem.shopId}
            className={cx(
              'data-[combobox-selected=true]:!bg-[var(--mantine-color-named2-3)]',
              ':hover:!bg-[var(--mantine-color-named2-3)]',
              classes.selectItem,
            )}
          >
            <ShopSelectItem {...shopItem} />
          </Combobox.Option>
        )),
    [shops, search],
  );

  return (
    <Combobox
      store={combobox}
      width={300}
      position="bottom-start"
      styles={{ options: { maxHeight: 400, overflowY: 'scroll' } }}
      onOptionSubmit={(shopId) => {
        if (!shopId) return;
        onStoreClicked(shopId);
      }}
    >
      <Combobox.Target>
        <div>
          <Button
            id="summary-section-picker"
            rightSection={<Icon name="caret-down" width={12} />}
            onClick={() => combobox.toggleDropdown()}
            variant="activator"
          >
            {selectedOption && (
              <ShopSelectItem {...{ ...selectedOption, shopId: truncSelectedShopId }} />
            )}
          </Button>
        </div>
      </Combobox.Target>

      <Combobox.Dropdown
        bg={darkMode ? 'gray.6' : 'white'}
        style={{ border: 'none', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
      >
        <Combobox.Search
          classNames={{ input: classes.search }}
          styles={{
            input: {
              backgroundColor: extractCSSColor(darkMode ? 'gray.6' : 'white'),
              borderBottom: `0.5px solid ${extractCSSColor(darkMode ? 'gray.3' : 'gray.4')}`,
              fontWeight: 500,
            },
          }}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search shops"
        />
        <Combobox.Options>
          <Combobox.Group label="Pods">
            <Combobox.Option value="pods-view">
              <Anchor
                as="button"
                onClick={() => {
                  dispatch(updateCurrentShopId());
                  window.location.replace('/pods-view');
                }}
              >
                {/* TODO: Fix Anchor, so it properly accepts text props */}
                <Text fz="sm">Pods View</Text>
              </Anchor>
            </Combobox.Option>
          </Combobox.Group>
          <Combobox.Group label="Shops">{comboboxOptions}</Combobox.Group>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
