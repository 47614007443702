import { AbstractChart } from '@tw/types';
import { reduce } from 'lodash';

export const cumulateAbstractChart = (chartData: AbstractChart[]) => {
  return reduce(
    chartData,
    (acc, curr) => {
      acc.push({ x: curr.x, y: (acc.length > 0 ? acc[acc.length - 1].y : 0) + curr.y });
      return acc;
    },
    [] as AbstractChart[],
  );
};
