import React from 'react';
import {
  Checkbox,
  Tooltip,
  Icon,
  Loader,
  Menu,
  ActionIcon,
  Flex,
  confirm,
} from '@tw/ui-components';
import { WillyDataSequence } from './types/willyTypes';
import { useNavigate } from 'react-router';
import { firestoreRef } from 'utils/DB';
import _db from 'utils/DB';
import { toast } from 'react-toastify';

interface SequenceListItemProps {
  sequence: WillyDataSequence;
  showNewDesign: boolean;
  isTwGlobalDashboardCreatorClaim: boolean;
  bulkActionItems: WillyDataSequence[];
  setBulkActionItems: React.Dispatch<React.SetStateAction<WillyDataSequence[]>>;
  setSequences: (callback: (old: WillyDataSequence[]) => WillyDataSequence[]) => void;
  toggleSequenceActionMenu: (id: string, open: boolean) => void;
  deleteSequences: (sequences: WillyDataSequence[]) => Promise<void>;
  menuItems: (sequence: WillyDataSequence) => any[];
  scheduleText: (sequence: WillyDataSequence) => string;
  scheduleDate: (sequence: WillyDataSequence) => string;
}

export const SequenceListItem: React.FC<SequenceListItemProps> = ({
  sequence: c,
  showNewDesign,
  isTwGlobalDashboardCreatorClaim,
  bulkActionItems,
  setBulkActionItems,
  setSequences,
  toggleSequenceActionMenu,
  deleteSequences,
  menuItems,
  scheduleText,
  scheduleDate,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`justify-between items-center gap-4 overflow-hidden group/conversation-item w-full ${
        showNewDesign ? 'grid grid-cols-[minmax(auto,40%)_1fr] p-4 shadow-sm' : 'flex'
      } ${showNewDesign ? 'rounded-lg border-[1px] border-solid border-gray-200 bg-gray-50' : ''}`}
      onClick={(e) => {
        if (c.renameMode) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
      }}
      onDoubleClick={(e) => {
        if (c.renameMode) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        setSequences((old) => {
          return old.map((x) => ({
            ...x,
            renameMode: x.id === c.id,
          }));
        });
      }}
      onKeyDown={(e) => {
        if (c.renameMode) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onKeyUp={(e) => {
        if (c.renameMode) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      <div className="flex items-center gap-4 text-ellipsis whitespace-nowrap w-full max-w-[90%]">
        <Checkbox
          checked={bulkActionItems.some((x) => x.id === c.id)}
          onChange={(checked) => {
            if (checked) {
              setBulkActionItems((old) => [...old, c]);
            } else {
              setBulkActionItems((old) => old.filter((x) => x.id !== c.id));
            }
          }}
        />
        {!showNewDesign && (
          <>
            {!c.schedule && (
              <div className="flex-shrink-0 flex">
                <Tooltip label="No schedule for this sequence yet">
                  <Icon name="minus" color="gray.5" />
                </Tooltip>
              </div>
            )}
            {!!c.schedule && (
              <div className="flex items-center">
                {c.schedule.status === 'running' && (
                  <Tooltip label="Scheduled sequence is running">
                    <Loader size="xs" />
                  </Tooltip>
                )}
                {c.schedule.status === 'error' && (
                  <Tooltip
                    label={`Scheduled sequence has an error: ${
                      c.schedule.error || 'Unknown error'
                    }`}
                  >
                    <Icon name="info" color="red.5" />
                  </Tooltip>
                )}
                {(c.schedule.status === 'scheduled' || c.schedule.status === 'skipped') && (
                  <Tooltip
                    label={`Waiting for the next scheduled run ${
                      c.schedule.status === 'skipped' ? '(last run skipped due to rules)' : ''
                    }`}
                  >
                    <Icon name="clock" />
                  </Tooltip>
                )}
                {c.schedule.status === 'paused' && (
                  <Tooltip label="Scheduled sequence is paused">
                    <Icon name="play-arrow" />
                  </Tooltip>
                )}
              </div>
            )}
          </>
        )}
        <Tooltip label={c.name || 'Untitled'}>
          <div
            className={`focus:outline ${
              c.renameMode
                ? 'overflow-auto text-clip'
                : 'overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer'
            } ${showNewDesign ? 'p-4 font-[500]' : ''}`}
            contentEditable={c.renameMode}
            suppressContentEditableWarning
            ref={(el) => {
              if (!el) return;
              if (c.renameMode) {
                const range = document.createRange();
                range.selectNodeContents(el);
                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(range);

                el.focus();
              } else {
                el.blur();
              }
            }}
            onClick={(e) => {
              if (e.detail === 2) {
                return;
              }
              if (c.renameMode) {
                e.stopPropagation();
                e.preventDefault();
                return;
              }
              navigate({
                pathname: `/workflows/${c.id}`,
              });
            }}
            onBlur={(e) => {
              const ref = c.isGlobal
                ? firestoreRef().collection('global_data_sequences')
                : _db().collection('data_sequences');
              ref.doc(c.id).set({ title: e.target.textContent }, { merge: true });

              setSequences((old) => {
                return old.map((x) => {
                  return {
                    ...x,
                    renameMode: false,
                  };
                });
              });
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              } else if (e.key === 'Escape') {
                e.preventDefault();
                e.currentTarget.textContent = c.name || 'Untitled';
                e.currentTarget.blur();
              }
            }}
          >
            {`${c?.isGlobal && isTwGlobalDashboardCreatorClaim ? 'GLOBAL - ' : ''} ${c.name}` ||
              'Untitled'}
          </div>
        </Tooltip>
      </div>

      <div className={`${showNewDesign ? 'grid grid-cols-4 gap-4 items-center' : ''}`}>
        {showNewDesign && (
          <>
            <div className="p-4 !pointer-events-none flex items-center">
              <span
                className={`w-4 h-4 rounded-full flex-shrink-0 ml-2 ${
                  !!c.schedule &&
                  (c.schedule.status === 'scheduled' ||
                    c.schedule.status === 'skipped' ||
                    c.schedule.status === 'running')
                    ? 'bg-[#3acf00]'
                    : 'bg-gray-400'
                }`}
              >
                <Tooltip label={c.schedule?.status ?? 'Disabled'}>
                  <div className="w-full h-full"></div>
                </Tooltip>
              </span>
            </div>
            <div className="whitespace-nowrap font-[500] text-ellipsis overflow-hidden">
              {scheduleText(c!)}
            </div>
            <div className="whitespace-nowrap font-[500] text-ellipsis overflow-hidden">
              {scheduleDate(c!)}
            </div>
          </>
        )}

        <div
          className="ml-auto"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Menu
            shadow="md"
            bg="white"
            opened={c.actionsMenuOpen}
            onClose={() => toggleSequenceActionMenu(c.id, false)}
            withinPortal={false}
          >
            <Menu.Target>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleSequenceActionMenu(c.id, !c.actionsMenuOpen);
                }}
              >
                <ActionIcon icon="three-dots" iconSize={18} p={0} />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              {menuItems(c).map((item) => (
                <Menu.Item
                  key={item.content}
                  onClick={() => {
                    item.onAction();
                    toggleSequenceActionMenu(c.id, false);
                  }}
                  disabled={item.disabled}
                >
                  <Flex gap="sm" align="center">
                    {/* {item.icon()} */}
                    {item.content}
                  </Flex>
                </Menu.Item>
              ))}
              <Menu.Divider />
              <Menu.Item
                key="Delete Workflow"
                onClick={async () => {
                  const conf = await confirm({
                    title: 'Delete workflows?',
                    message: 'This action cannot be undone',
                  });
                  if (!conf) return;
                  await deleteSequences([c]);
                  toggleSequenceActionMenu(c.id, false);

                  toast.success('Workflow deleted successfully');
                }}
                disabled={!c.canEdit}
              >
                <Flex gap="sm" align="center">
                  Delete Workflow
                </Flex>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>
    </div>
  );
};
