import BaseChart from 'components/library/BaseChart/BaseChart';
import { AttributionPageContext, useAllAttributionModels } from 'constants/attribution';
import { upperCase } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { Bar, BarChart, Tooltip } from 'recharts';
import { AttributionData, ModelComparisonsResponse } from 'types/attribution';
import { mapEntityToBreakdown } from 'utils/attributions';
import axiosInstance from 'utils/axiosInstance';
import { formatNumber } from 'utils/formatNumber';
import { useAttributionParams } from 'utils/useAttributionParam';

import { Modal } from '@shopify/polaris';
import { AttributionModels } from '@tw/types';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import moment from '@tw/moment-cached/module/timezone';
import { AFFLUENCER } from 'constants/types';

type AttributionAllRoasProps = {
  item: AttributionData;
  onClose: () => void;
  metricKey?: string;
};
const AttributionAllRoas: React.FC<AttributionAllRoasProps> = ({
  item,
  onClose,
  metricKey = '',
}) => {
  const [modelComparison, setModelComparison] =
    useState<ModelComparisonsResponse['data']['modelComparison']>();
  const [loading, setLoading] = useState(false);
  const params = useAttributionParams();
  const { hasAllValidSampleSize, missingDates } = useSelector(
    (state: RootState) => state.ppsStatus,
  );

  const { useNewModels } = useSelector((state: RootState) => state.attribution);

  const allAttributionModels = useAllAttributionModels();

  const { sourceId } = useContext(AttributionPageContext);

  const fetchModelComparison = useCallback(async () => {
    if (!params) {
      return;
    }

    const start = params.startDate;
    const end = params.endDate;
    const stat = metricKey.includes('pixelNc') ? 'newCustomerOrder' : 'order';
    if (item.entity !== 'channel') {
      params.sources = [sourceId];
      const filterKey = mapEntityToBreakdown[item.entity!] as 'campaignId' | 'adsetId';
      params.attributionFilters = [{ key: filterKey, value: item.id! }];
    } else {
      params.sources = [item.id!];
    }

    const missingDatesBetweenStartAndEnd = missingDates.some((date) => {
      return moment(date).isBetween(start, end, 'day', '[]');
    });

    setLoading(true);
    const { data } = await axiosInstance.post<ModelComparisonsResponse>(
      '/v2/attribution/get-model-comparison',
      {
        ...params,
        stat,
        includePpsViews: hasAllValidSampleSize && !missingDatesBetweenStartAndEnd,
      },
    );
    let models = data.data.modelComparison;
    const max = models.reduce(
      (acc, curr) => (curr.pixelRoas > acc.pixelRoas ? curr : acc),
      models[0],
    );

    // const useNewModelsFilter = [
    //   'fullLastClick-v2',
    //   'fullFirstClick-v2',
    //   'lastPlatformClick-v2',
    //   'linear-v2',
    //   'linearAll-v2',
    //   'ppsViews',
    // ];

    const defaultFilter = [
      'lastClick',
      'firstClick',
      'lastPlatformClick',
      'linear',
      'linearAll',
      'ppsViews',
    ];

    // const filterArray = useNewModels ? useNewModelsFilter : defaultFilter;

    models = models.filter((m) => defaultFilter.includes(m.model));
    models = models.map((m) => ({
      ...m,
      fill:
        max.model === m.model || max.model === `${m.model}-v2`
          ? 'url(#roas-comparison-blue)'
          : 'url(#roas-comparison-white)',
      model: m.model,
    }));
    setModelComparison(models);

    setLoading(false);
  }, [item, params, sourceId, missingDates]);

  useEffect(() => {
    fetchModelComparison();
  }, [fetchModelComparison]);

  const _onClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        open={true}
        loading={loading}
        onClose={_onClose}
        title={
          <div className="flex items-center roas-modal-title">
            <span>
              <Lottie
                loop
                play
                path={'/lotties/roas-cube-modal.json'}
                rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
                style={{ width: 40, height: 40 }}
              />
            </span>
            <div>{item.name}</div>
          </div>
        }
      >
        <BaseChart
          data={modelComparison}
          height={320}
          wrapperStyle={{ borderRadius: '0 0 8px 8px' }}
          margin={{ right: 30 }}
          ChartType={BarChart}
          xAxis={[
            {
              dataKey: 'model',
              tickFormatter: (value) => {
                const val = allAttributionModels.find((m) => m.value === value || m.aka === value);
                return upperCase(val?.shortLabel || value);
              },
            },
          ]}
          yAxis={[
            {
              label: {
                value: 'ROAS',
                fill: 'white',
                angle: -90,
              },
              dataKey: 'pixelRoas',
              tickSize: 5,
              tickMargin: 15,
              style: {
                fontSize: '9px',
              },
              stroke: 'var(--text-color)',
              tickFormatter: (value, index) => {
                if (index % 2 !== 0) {
                  return '';
                }
                return value;
              },
            },
          ]}
        >
          <defs>
            <linearGradient id="roas-comparison-white" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0" stopColor="#c0d6ea" stopOpacity={1} />
              <stop offset="1" stopColor="#000000" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="roas-comparison-blue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0" stopColor="#1877f2" stopOpacity={1} />
              <stop offset="1" stopColor="#000000" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Bar
            name=" "
            animationDuration={2000}
            dataKey="pixelRoas"
            barSize={44}
            label={{
              value: '',
              fontSize: 9,
              position: 'insideTop',
              fill: 'var(--dark-bg)',
              formatter: (value) => {
                return formatNumber(value, {
                  style: 'decimal',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                });
              },
            }}
          />
          <Tooltip
            cursor={{ fill: 'none' }}
            labelFormatter={(value: AttributionModels) => (
              <div className="font-bold">
                {allAttributionModels.find((m) => m.value === value)?.shortLabel}
              </div>
            )}
            separator={''}
            formatter={(value: number, label) => {
              return (
                <div>
                  {formatNumber(value, {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </div>
              );
            }}
          />
        </BaseChart>
      </Modal>
    </>
  );
};

export default AttributionAllRoas;
