import { AdContent } from 'types/general';

type AdMediaTableProps = {
  media: AdContent[];
};

const AdMediaTable: React.FC<AdMediaTableProps> = ({ media }) => {
  return (
    <div>
      <h1>Ad Media Table</h1>
    </div>
  );
};

export default AdMediaTable;
