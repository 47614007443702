import { REMOVE_LOCAL_SHOP_PROP } from 'ducks/constants';

export const callRemoveLocalShopProp = (key: string) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_LOCAL_SHOP_PROP,
      payload: key,
    });
  };
};
