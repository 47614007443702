import Welcome from './steps/Welcome';
import {
  ConnectSalesPlatformCTAText,
  ConnectSalesPlatformTitle,
  SalesPlatformConnectionIndication,
  WelcomeConnectMainSalesPlatform,
} from './steps/mspConnect/WelcomeConnectMainSalesPlatfrom';
import {
  IntegrationsConnectionsIndication,
  WelcomeIntegrations,
} from './steps/WelcomeIntegrations';
import { WelcomeInstallPixel } from './steps/pixel/WelcomeInstallPixel';
import { UtmIndication, WelcomeUtms } from './steps/WelcomeUtms';
import { WelcomeSummarySideComponent, WelcomeSummary } from './steps/WelcomeSummary';
import { WizardStep } from 'types/WizardData';
import { PPSInstallationIndication, WelcomeInstallPPS } from './steps/WelcomeInstallPPS';
import { PixelInstallationIndication } from './steps/pixel/PixelInstallationIndication';
import { WelcomeShipping, ShippingIndication } from './steps/WelcomeShipping';
import {
  SalesPlatformSettingsIndication,
  SetupSalesPlatformTitle,
  WelcomeSetupMSP,
} from './steps/WelcomeSetupMSP';
import { ServicesIds } from '@tw/types/module/services';

export const WELCOME_WIZARD_BASE_PATH = '/welcome';

export type StepType = {
  id: string;
  title: React.ReactNode;
  url: string;
  allowRevisit: boolean;
  component: React.ReactNode;
  sideComponent?: React.ReactNode;
  isSkipable: boolean;
  avgTime: number;
  completedTextIndication: React.ReactNode;
  showOnPod: boolean;
  isMsp?: boolean;
  isSkipFree?: boolean;
  msp?: ServicesIds[];
  podsCTAPath?: { path: string; external?: boolean };
  skipOnMsp?: ServicesIds[];
  podsCTATitle: React.ReactNode;
};

// made this a separate const to be used internally in this file, so that we could use explicit types like WelcomeStepId
const READONLY_WELCOME_STEPS: StepType[] = [
  {
    id: 'welcome',
    title: <>Welcome!</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/get-start`,
    allowRevisit: true,
    component: <Welcome />,
    isSkipable: false,
    avgTime: 1,
    completedTextIndication: <>Let’s go!</>,
    showOnPod: false,
    podsCTATitle: undefined,
  },
  {
    id: 'connectShopify',
    title: <ConnectSalesPlatformTitle />,
    url: `${WELCOME_WIZARD_BASE_PATH}/connect`,
    allowRevisit: true,
    component: <WelcomeConnectMainSalesPlatform />,
    isSkipable: false,
    avgTime: 1,
    completedTextIndication: <SalesPlatformConnectionIndication />,
    showOnPod: true,
    isMsp: true,
    podsCTATitle: <ConnectSalesPlatformCTAText />,
  },
  {
    id: 'setupMSP',
    title: <SetupSalesPlatformTitle />,
    url: `${WELCOME_WIZARD_BASE_PATH}/setup-msp`,
    allowRevisit: true,
    component: <WelcomeSetupMSP />,
    isSkipable: false,
    avgTime: 1,
    msp: ['bigcommerce', 'woocommerce'],
    completedTextIndication: <SalesPlatformSettingsIndication />,
    showOnPod: true,
    podsCTAPath: { path: '/integrations' },
    podsCTATitle: <>View Integrations</>,
  },
  {
    id: 'integrations',
    title: <>Integrations</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/integrations`,
    allowRevisit: true,
    component: <WelcomeIntegrations />,
    isSkipable: true,
    avgTime: 1,
    completedTextIndication: <IntegrationsConnectionsIndication />,
    showOnPod: true,
    podsCTAPath: { path: '/integrations' },
    podsCTATitle: <>View Integrations</>,
  },
  {
    id: 'pixel',
    title: <>Install Pixel</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/pixel`,
    allowRevisit: true,
    component: <WelcomeInstallPixel />,
    isSkipable: true,
    avgTime: 1,
    completedTextIndication: <PixelInstallationIndication />,
    showOnPod: true,
    podsCTAPath: { path: '/attribution/settings' },
    podsCTATitle: <>Attribution Settings</>,
  },
  {
    id: 'utms',
    title: <>UTMs</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/utms`,
    allowRevisit: true,
    component: <WelcomeUtms />,
    isSkipable: true,
    isSkipFree: true,
    avgTime: 1,
    completedTextIndication: <UtmIndication />,
    showOnPod: true,
    podsCTAPath: {
      path: 'https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel',
      external: true,
    },
    podsCTATitle: <>View Instructions</>,
  },
  {
    id: 'pps',
    title: <>Install PPS</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/post-purchase-survey`,
    allowRevisit: true,
    component: <WelcomeInstallPPS />,
    isSkipable: true,
    avgTime: 1,
    completedTextIndication: <PPSInstallationIndication />,
    showOnPod: true,
    msp: ['shopify'],
    podsCTAPath: { path: '/post-purchase-survey/builder. ' },
    podsCTATitle: <>PPS Builder</>,
  },
  {
    id: 'shippingCosts',
    title: <>Shipping Costs</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/shippingCosts`,
    allowRevisit: true,
    component: <WelcomeShipping />,
    isSkipable: true,
    avgTime: 1,
    skipOnMsp: ['stripe'],
    completedTextIndication: <ShippingIndication />,
    showOnPod: true,
    podsCTAPath: { path: '/shippingCosts' },
    podsCTATitle: <>View Shipping Costs</>,
  },
  {
    id: 'summary',
    title: <>Summary</>,
    url: `${WELCOME_WIZARD_BASE_PATH}/summary`,
    allowRevisit: true,
    component: <WelcomeSummary />,
    sideComponent: <WelcomeSummarySideComponent />,
    isSkipable: false,
    avgTime: 1,
    completedTextIndication: undefined,
    showOnPod: false,
    podsCTATitle: undefined,
  },
] as const;

export type WelcomeStepId = (typeof READONLY_WELCOME_STEPS)[number]['id'];

export const WELCOME_STEPS = READONLY_WELCOME_STEPS as ReadonlyArray<WizardStep>;

export const utmServices = ['facebookAds', 'googleAds', 'other'] as const;
