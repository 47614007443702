//import { RuleType } from 'components/RulesEngine/CreateRuleCard';
import { combineReducers } from 'redux';

const INIT_RULES = 'init-rules-engine';
const RULES_UPDATED = 'update-rules-engine';
const RE_MODAL_DATA = 'rule-engine-modal-data';

export const saveStrategies = (strategiesRulesEngine) => ({
  type: INIT_RULES,
  payload: strategiesRulesEngine,
});

export const strategiesRulesEngine = (state = {}, action) => {
  switch (action.type) {
    case INIT_RULES:
      return action.payload;
    default:
      return state;
  }
};

const modalRuleEngineData = (state = {}, action) => {
  switch (action.type) {
    case RE_MODAL_DATA:
      return action.payload;
    default:
      return state;
  }
};
export const openCreateEditRuleEngineModal = (status: any | false) => {
  return {
    type: RE_MODAL_DATA,
    payload: {
      isOpen: status,
    },
  };
};

export const createRule = (rule: any, service: string) => {
  return async (dispatch, getState) => {
    const { strategiesRulesEngine } = getState().rulesEngine;
    console.log(strategiesRulesEngine);
    console.log(rule);

    //Add the new rule to strategiesRulesEngine

    dispatch({
      type: RULES_UPDATED,
    });
  };
};

export const reducers = combineReducers({
  strategiesRulesEngine,
  modalRuleEngineData,
  createRule,
});
