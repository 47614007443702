import { useEffect } from 'react';
import { useAppDispatch } from 'index';
import { useAppSelector } from 'reducers/RootType';

import { isInIframe } from 'config';

import { initShopData } from 'ducks/auth';
import { getOnboarding, getShopFeatures } from 'ducks/shop';
import { getShopifyScopes } from 'ducks/shopify/shopify';

/**
 * @description Loads data for iframe components
 * // TODO: Need to understand why this isn't just in a custom hook.
 * // TODO: Does this need to be rendered only after parent component finishes rendering for some reason?
 */
export const IframeComponents = () => {
  const user = useAppSelector((state) => state.user);
  const loadingUserData = useAppSelector((state) => state.loadingUserData);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const msp = useAppSelector((state) => state.msp);
  const shopsAsArray = useAppSelector((state) => state.shopsAsArray);
  const hasShops = !!shopsAsArray.length;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((hasShops && isInIframe && currentShopId) || (currentShopId && !loadingUserData)) {
      dispatch(initShopData());
    }
  }, [hasShops, dispatch, currentShopId, loadingUserData]);

  useEffect(() => {
    if (currentShopId && user.uid && msp) {
      dispatch(getShopFeatures(currentShopId));
      dispatch(getOnboarding(currentShopId));
      if (msp === 'shopify') {
        dispatch(getShopifyScopes(currentShopId));
      }
    }
  }, [dispatch, currentShopId, user.uid, msp]);

  return null;
};
