import { ActionIcon, Flex } from '@tw/ui-components';
import { MobileStoresNav } from 'components/Nav/components/navs/StoresNav/MobileStoresNav';
import { $navOpen } from 'components/Nav/stores';

export const MobileTopSection = () => {
  return (
    <Flex justify="space-between" align="center">
      <ActionIcon
        id="mobile-menu-toggler"
        size="lg"
        radius="sm"
        onClick={() => $navOpen.set(true)}
        variant="activator"
        icon="menu-burger"
      />
      <MobileStoresNav />
    </Flex>
  );
};
