import {
  $fromPremiumPlusToMobyModal,
  closeFromPremiumPlusToMobyModal,
} from '$stores/willy/$upgradePopupManager';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Button, Card, Flex, Icon, Modal, Text } from '@tw/ui-components';
import {
  $availablePlans,
  $premiumPlusDayLeft,
  MOBY_PRODUCT_ID,
} from '../../$stores/willy/$subscription';
import { AddonOrderModal } from 'components/UserProfileManagment/Shop/Addons/AddonOrderModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type FromPremiumPlusToMobyModalPopupType = {};

export const FromPremiumPlusToMobyModalPopup: React.FC<
  FromPremiumPlusToMobyModalPopupType
> = ({}) => {
  const fromPremiumPlusToMobyModal = useStoreValue($fromPremiumPlusToMobyModal);
  const premiumPlusDayLeft = useStoreValue($premiumPlusDayLeft);
  const addons = useComputedValue($availablePlans, (x) => x.data?.addons || []);
  const mobyAddon = addons.find((addon) => addon.product_id === MOBY_PRODUCT_ID);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <Modal.Root
      size="90vw"
      maw="1140px"
      centered
      opened={fromPremiumPlusToMobyModal.show}
      onClose={() => closeFromPremiumPlusToMobyModal()}
      p={0}
    >
      <Modal.Overlay />
      <Modal.Content mah="95vh" p={0}>
        <Modal.Body p={0}>
          <div className="relative flex justify-around pt-[30px] px-[90px] pb-[90px] lg:pb-0 flex-col items-center gap-5 unlock-moby">
            <div className="absolute top-[5px] right-[5px]">
              <Modal.CloseButton />
            </div>

            <Text fz={28} fw={700} color="gray.8" align="center">
              {premiumPlusDayLeft === null
                ? 'Your Premium+ trial has ended'
                : `Your Premium+ trial is ending soon. ${premiumPlusDayLeft} days left`}
            </Text>
            <div className="flex flex-col-reverse lg:flex-row w-full mt-10 gap-8 mb-20">
              <div className="w-full lg:w-1/2">
                <Card withBorder w="100%">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-3">
                      <Text fw="700" fz={24} maw={600} color="gray.9">
                        Here’s what you’ll lose if you don’t upgrade
                      </Text>
                      <Text fw="600" fz={18} maw={600} color="gray.9" mt={19}>
                        Unlimited Custom dashboards{' '}
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6">
                        Build fully customized dashboards, so you can easily track amd share the
                        metrics that matter to you{' '}
                      </Text>

                      <Text fw="600" fz={18} maw={600} color="gray.9" mt={5}>
                        Your AI Data Scientist{' '}
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6">
                        With Moby, you can chat with your data - ask questions about customer LTV
                        and campaign performance, historical trends, and more. Premium+ comes with 3
                        Moby seats included.{' '}
                      </Text>

                      <Text fw="600" fz={18} maw={600} color="gray.9" mt={5}>
                        Sonar: Your automatic revenue engine{' '}
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6">
                        Sonar leverages Triple Whale’s pixel technology to send enriched data back
                        to Klaviyo and Meta to boost your marketing revenue. Losing this means you
                        leave money on the table.{' '}
                      </Text>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate('/store-settings/plans');
                        closeFromPremiumPlusToMobyModal();
                      }}
                    >
                      Upgrade to Premium Plus
                    </Button>
                  </div>
                </Card>
              </div>

              <div className="w-full lg:w-1/2">
                <Card withBorder w="100%">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-3">
                      <Text fw="700" fz={24} maw={600} color="gray.9">
                        Only interested in Moby?
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6">
                        Now you can purchase Moby separately from the Premium+ plan. Add Moby seats
                        to empower your team with quick insights.
                      </Text>
                      <Text fw="600" fz={18} maw={600} color="gray.9" mt={5}>
                        Get the answers you need
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6">
                        Moby understands your data. Want to see your LTV for customers coming from
                        Meta? Just ask Moby.
                      </Text>
                      <Text fw="600" fz={18} maw={600} color="gray.9" mt={5}>
                        Build visualizations with Moby
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6">
                        Moby can build data visuals via natural language prompts, that you can
                        customize and add to any dashboard.
                      </Text>
                      <Text fw="600" fz={18} maw={600} color="gray.9" mt={5}>
                        Chat with your dashboards
                      </Text>
                      <Text fw="400" fz={16} maw={600} color="gray.6" mb={6}>
                        Access Moby within any dashboard to get analysis, insights, and actionable
                        recommendations.
                      </Text>
                    </div>
                    <Button
                      variant="secondary"
                      fullWidth
                      onClick={() => {
                        setModalOpen(true);
                        closeFromPremiumPlusToMobyModal();
                      }}
                    >
                      Get Moby Seats for $99 {/* {Math.floor(Number(mobyAddon?.price))} */}
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
      {mobyAddon && (
        <AddonOrderModal
          addon={mobyAddon}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          contractTypes={[]}
          selectedType={{
            contractType: mobyAddon.contract_type!,
            billingInterval: mobyAddon.billing_interval!,
          }}
          setSelectedType={() => {}}
        />
      )}
    </Modal.Root>
  );
};
