import { Image, Text } from '@tw/ui-components';
import { WebSources } from './types/willyTypes';

import { Message } from './types/willyTypes';

type WillyMessageResourcesProps = {
  resources: WebSources;
  message: Message;
};

export const WillyMessageResources: React.FC<WillyMessageResourcesProps> = ({
  resources,
  message,
}) => {
  return (
    <div className="flex flex-col gap-10">
      {!!resources.sources.length && (
        <div className="flex flex-col gap-2">
          <Text size="sm" fw="bold">
            Sources used in this message:
          </Text>
          {resources.sources.slice(0, 5).map((source, i) => (
            <div key={`${source.url}_${i}`}>
              <a href={source.url} target="_blank" rel="noreferrer">
                {source.header}
              </a>
            </div>
          ))}
        </div>
      )}

      {!!resources.imgs.length && (
        <div className="flex flex-col gap-2">
          <Text size="sm" fw="bold">
            Images:
          </Text>
          <div className="flex flex-row gap-2">
            {resources.imgs.slice(0, 5).map((img, i) => (
              <div key={`${img}_${i}`}>
                <a href={img} target="_blank" rel="noreferrer">
                  <Image shadowBorderSize="md" src={img} width="80px" height="80px" />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}

      {!!resources.videos.length && (
        <div className="flex flex-col gap-2">
          <Text size="sm" fw="bold">
            Videos:
          </Text>
          {resources.videos.slice(0, 5).map((video, i) => (
            <div key={`${video}_${i}`}>
              <a href={video} target="_blank" rel="noreferrer">
                {video}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
