import { Tooltip } from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
import { PixelEventSource } from '@tw/types';
import React from 'react';

export default function OrderLowResolutionMark({ order, journey }) {
  const MINUTE = 1000 * 60;
  const isLowResolution =
    order &&
    journey &&
    new Date(order?.created_at).getTime() > new Date().getTime() - MINUTE * 10 &&
    journey?.find(
      (j) => j.tripleId == order?.tripleCoo && j.source == PixelEventSource.SHOPIFY_WEBHOOK,
    );

  return (
    <>
      {isLowResolution && (
        <Tooltip content="Journey data is still being collected">
          <span>
            <InfoMinor width={22} height={22} className="!fill-yellow-500" />
          </span>
        </Tooltip>
      )}
    </>
  );
}
