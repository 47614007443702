import { $addTemplateAccess } from '$stores/willy/$shopDashboards';
import { useStoreValue } from '@tw/snipestate';
import { useMemo } from 'react';

export function useCanAddTemplate(credits?: number) {
  const addTemplateAccess = useStoreValue($addTemplateAccess);

  return useMemo(() => {
    if (addTemplateAccess !== 'no-credits') return addTemplateAccess;
    // if user has no credits, but template is free, we still say 'can-access'
    // otherwise default to 'no-credits'
    return !credits ? 'can-access' : addTemplateAccess;
  }, [credits, addTemplateAccess]);
}
