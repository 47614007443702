import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import { AdAssetTypes } from 'constants/media';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AdContent } from 'types/general';
import axiosInstance from 'utils/axiosInstance';
import { ContentHubContext } from '../ContentHub';
import AdMediaCollection from './AdMediaCollection';
import { AdAssetTypeObject } from 'types/general';
import { shopIntegrations } from 'ducks/shopIntegrations';

const ITEMS_PER_PAGE = 12;

type AdMediaCollectionWrapperProps = {
  serviceId: string;
};

const allowedMediaTypes = {
  'facebook-ads': ['image', 'video', 'body', 'title'],
  'tiktok-ads': ['video', 'body'],
  'google-ads': ['image', 'video', 'body', 'title'],
};

const AdMediaCollectionWrapper: React.FC<AdMediaCollectionWrapperProps> = ({ serviceId }) => {
  const { mediaType, setMediaType } = useContext(ContentHubContext);
  const integrations = useSelector(shopIntegrations);

  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState<AdContent[]>([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [noData, setNoData] = useState(false);
  const [serviceMediaTypes, setServiceMediaTypes] = useState<AdAssetTypeObject>(AdAssetTypes);

  const fetchAds = useCallback(async () => {
    setMedia([]);
    const url = '/v2/metrics-table/get-ads-content';
    const acc: { id: string }[] = integrations[serviceId]?.map((acc) => acc.id);
    if (!acc?.length) {
      setNoData(true);
      return;
    }
    setLoading(true);
    const { data } = await axiosInstance.post<{ data: AdContent[] }>(url, {
      serviceId,
      accounts: acc,
      contentType: mediaType,
      page,
    });
    const media = data?.data || [];

    setHasNextPage(media?.length >= ITEMS_PER_PAGE);

    setMedia(media);
    setLoading(false);
    setNoData(media?.length === 0);
  }, [integrations, page, serviceId, mediaType]);

  useEffect(() => {
    fetchAds();
  }, [fetchAds]);

  useEffect(() => {
    setPage(1);
  }, [serviceId, mediaType]);

  useEffect(() => {
    if (mediaType && !serviceMediaTypes?.[mediaType]) {
      setMediaType?.(Object.values(serviceMediaTypes)[0].key);
    }
  }, [serviceMediaTypes, mediaType]);

  useEffect(() => {
    let allowed = allowedMediaTypes[serviceId].reduce(function (obj, itm) {
      obj[itm] = AdAssetTypes[itm];
      return obj;
    }, {});
    setServiceMediaTypes(allowed);
  }, [serviceId]);

  return (
    <>
      <div className="h-[73vh] flex flex-col overflow-auto gap-6.5">
        <div className="flex items-center gap-6.5">
          {Object.values(serviceMediaTypes).map((type) => {
            return (
              <div key={type.key}>
                <BubbleToggleButton
                  onClick={() => {
                    setMediaType?.(type.key);
                  }}
                  active={mediaType === type.key}
                  label={type.label}
                  id="tr-create-cockpit-creative-highlights"
                />
              </div>
            );
          })}
        </div>
        <AdMediaCollection
          page={page}
          setPage={(p) => {
            setPage(p);
          }}
          hasNextPage={hasNextPage}
          noData={noData}
          loading={loading}
          media={media}
        />
      </div>
    </>
  );
};

export default AdMediaCollectionWrapper;
