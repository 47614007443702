import { GradientChartDefs } from 'constants/general';
import { useAppSelector } from 'reducers/RootType';
import { WillyMetric, WillyDataColumn, RawNlqData } from './types/willyTypes';
import React, { useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Legend } from 'recharts';
import { formatValue } from './utils/formatters';
import { Text } from '@tw/ui-components';
import { generateColorScale } from './utils/willyUtils';
import { formatNumber } from 'utils/formatNumber';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';

type WillyPieChartProps = {
  metrics: WillyMetric[];
  rawData: RawNlqData;
  loading?: boolean;
  dataColumns: WillyDataColumn;
  previousPeriodData?: any[];
};

type PieData = {
  key: string;
  name: string;
  color: string;
  fill: string;
  value: number;
};

export const WillyPieChart: React.FC<WillyPieChartProps> = ({ metrics, rawData, loading }) => {
  const currency = useStoreValue($currency);

  const [activeIndex, setActiveIndex] = useState(0);

  const hasOneNumericMetric = useMemo(() => {
    return (
      metrics.filter(
        (m) => !m.isDimension && !m.hidden && m.active !== false && m.isBlocked !== true,
      ).length === 1
    );
  }, [metrics]);

  const pieData = useMemo(() => {
    const forMultipleMetrics = metrics
      .filter((m) => !m.isDimension && !m.hidden && m.active !== false && m.isBlocked !== true)
      .map<PieData>((metric) => {
        return {
          key: metric.key,
          name: metric.name,
          color: loading ? '#eee' : metric.color,
          fill: loading ? `url(#loading)` : `${metric.color}`,
          value: loading ? 1 : Number(rawData.find((d) => d.name === metric.key)?.value?.[0] || 0),
        };
      })
      .sort((a, b) => +b.value - +a.value);

    if (hasOneNumericMetric) {
      const metric = metrics.find(
        (m) => !m.isDimension && !m.hidden && m.active !== false && m.isBlocked !== true,
      );
      const dimension = metrics.find((m) => m.isDimension && !m.hiddenBecauseHasNoData);
      if (!metric || !dimension) {
        return forMultipleMetrics;
      }

      const allValues = rawData.find((d) => d.name === metric.key)?.value;

      if (!allValues) {
        return forMultipleMetrics;
      }

      const colors = generateColorScale(
        metric.color,
        allValues.filter((x) => x !== null).map((x) => +x),
      );

      const defaultColor = Object.values(colors)[0];

      return allValues.map((v, i) => {
        return {
          key: metric.key,
          name: rawData.find((d) => d.name === dimension.key)?.value?.[i] || '[no name]',
          color: loading ? '#eee' : v !== null ? colors[v] : defaultColor,
          fill: loading ? `url(#loading)` : v !== null ? colors[v] : defaultColor,
          active: true,
          value: loading ? 1 : Number(v),
        };
      });
    }

    return forMultipleMetrics;
  }, [rawData, loading, metrics, hasOneNumericMetric]);

  const renderColorfulLegendText = (text: string, entry) => {
    return !loading ? (
      <Text as="span" weight={500} size="sm" p={8}>
        <span className="whitespace-nowrap">
          {entry.name}
          {': '}
        </span>
        {formatValue(entry?.value, entry?.id, metrics, currency)}
      </Text>
    ) : (
      <></>
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return percent > 0.15 && !loading ? (
      <text x={x} y={y} fill="white" textAnchor="middle">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : null;
  };

  const RADIAN = Math.PI / 180;
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      name,
      payload,
      percent,
      value,
      color,
      fill,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={color} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={color} stroke="none" />
        {!loading && (
          <>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              textAnchor={textAnchor}
              fill={'var(--gray-light-mode-800)'}
            >
              {name}
            </text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey + 20}
              textAnchor={textAnchor}
              fill={'var(--gray-light-mode-800)'}
            >
              {formatValue(value, payload.key, metrics, currency)}
            </text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey + 40}
              textAnchor={textAnchor}
              fill={'var(--gray-light-mode-800)'}
            >
              {`(${formatNumber(percent, {
                style: 'percent',
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })})`}
            </text>
          </>
        )}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="98.1%" minHeight={'300px'}>
      <PieChart margin={{ left: 10, right: 10 }}>
        <defs>
          <GradientChartDefs metrics={metrics} />
        </defs>
        <Legend
          align="left"
          verticalAlign="middle"
          layout="vertical"
          iconSize={10}
          formatter={renderColorfulLegendText}
          onMouseEnter={(e, i) => {
            const index = pieData.findIndex((entry) => entry.key === e.id);
            setActiveIndex(index ?? 0);
          }}
          payload={pieData?.map((entry) => ({
            id: entry.key,
            value: entry.value,
            color: entry.fill,
            name: entry.name,
            legendIcon: (
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="-0.000244141" width="30" height="30" rx="15" fill={entry.fill || '#eee'} />
              </svg>

              // <svg
              //   width="30"
              //   height="30"
              //   viewBox="0 0 30 30"
              //   fill="none"
              //   xmlns="http://www.w3.org/2000/svg"
              // >
              //   <rect x="0.34082" width="30" height="30" rx={6} fill={entry.fill} />
              // </svg>
            ),
          }))}
          wrapperStyle={{
            height: '80%',
            width: '35%',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={pieData}
          cx="50%"
          cy="50%"
          outerRadius={80}
          dataKey="value"
          onMouseEnter={(e, i) => {
            setActiveIndex(i ?? 0);
          }}
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {pieData.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.fill} stroke="none" />;
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
