import 'swiper/css';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import SwiperCore, { Keyboard, Mousewheel, Navigation, Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axiosInstance from 'utils/axiosInstance';
import { ContentHubContext } from '../ContentHub';
import ProductMediaCard from '../products/ProductMediaCard';
import { Loader } from '@tw/ui-components';

SwiperCore.use([Keyboard, Mousewheel]);

type ProductResultsProps = {};
const LIMIT = 10;

const ProductResults: React.FC<ProductResultsProps> = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const { debounce, searchResultsProducts, searchingProducts, openTabSingleItem } =
    useContext(ContentHubContext);
  const [productsData, setProductsData] = useState<any>([]);
  const [productLast, setProductLast] = useState<any>('');
  const [productsHasNext, setProductsHasNext] = useState(true);
  const [productsIndex, setProductsIndex] = useState(0);
  const [productsSwiper, setProductsSwiper] = useState<SwiperInterface>();
  const [loadingProducts, setLoadingProducts] = useState(false);

  useEffect(() => {
    setProductLast(null);
    setProductsIndex(0);
    if (productsSwiper?.activeIndex) {
      productsSwiper?.slideTo?.(0);
    }
    setProductsData(searchResultsProducts.data);
    setProductLast(searchResultsProducts?.data?.[searchResultsProducts?.data?.length - 1]);
    if (searchResultsProducts?.data?.length < LIMIT) {
      setProductsHasNext(true);
    }
  }, [productsSwiper, searchResultsProducts]);

  const loadMoreProducts = async () => {
    if (!loadingProducts && productsHasNext) {
      setLoadingProducts(true);
      const { data } = await axiosInstance.post('/v2/media/search-content-hub-products', {
        query: debounce,
        shopId: currentShopId,
        last_id: productLast?.id,
        limit: 10,
      });
      setProductsData([...productsData, ...data.data]);
      if (data?.data?.length < LIMIT) {
        setProductsHasNext(false);
      }
      setProductLast(data.data[data.data.length - 1]);
      setLoadingProducts(false);
    }
  };

  const swiperNext = (swiper) => {
    setProductsIndex(swiper.realIndex);
    swiper.slideNext();
    if (swiper.realIndex + 8 >= productsData.length && productsHasNext) {
      loadMoreProducts();
    }
  };

  const swiperPrev = (swiper) => {
    swiper.slidePrev();
    setProductsIndex(swiper.realIndex);
  };

  return (
    <div className="py-2 product-results">
      <div className="text-3xl	pb-5">Products</div>
      {searchingProducts ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          {productsData?.length ? (
            <Swiper
              spaceBetween={16}
              slidesPerView={4}
              onSwiper={(swiper) => setProductsSwiper(swiper)}
              mousewheel={{ forceToAxis: true }}
              keyboard
              slidesPerGroup={2}
              navigation={true}
              modules={[Navigation]}
              onNavigationNext={(swiper) => swiperNext(swiper)}
              onNavigationPrev={(swiper) => swiperPrev(swiper)}
            >
              {productsData?.map((m, i) => {
                return (
                  <SwiperSlide key={`m.id-${i}`}>
                    <div
                      key={m._id}
                      className="rounded overflow-hidden shadow-[rgb(0,0,0,8%)_0px_0px_8px] bg-white product-cards cursor-pointer"
                      onClick={() => openTabSingleItem?.('products', m?.handle)}
                    >
                      <ProductMediaCard
                        prod={m}
                        openItem={() => openTabSingleItem?.('products', m?.handle)}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
              {loadingProducts && (
                <SwiperSlide>
                  <div className="flex flex-col justify-center items-center">
                    <Loader />
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          ) : (
            <div>No products found</div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductResults;
