import { useState } from 'react';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { QUESTIONS, categories } from './constants';
import { WillySearchInput } from './WillySearchInput';
import { Accordion, Icon, Text } from '@tw/ui-components';
import { isTruthy } from './utils/willyUtils';

type VerifiedQuestionsProps = {
  withSearch?: boolean;
  onClick?: (value: string) => void;
};

const VerifiedQuestionsComponent: React.FC<VerifiedQuestionsProps> = ({
  withSearch = true,
  onClick,
}) => {
  const [freeSearch, setFreeSearch] = useState<string>('');
  const [openedSections, setOpenedSections] = useState<string[]>([]);

  return (
    <div className="willy-verified-questions h-full">
      {withSearch && (
        <WillySearchInput
          value={freeSearch}
          onChange={setFreeSearch}
          placeholder="Search for a question"
        />
      )}
      <Accordion mt="sm" multiple chevron={null}>
        {Object.entries(QUESTIONS)
          .filter(([, options]) =>
            options.some((option) => option.toLowerCase().includes(freeSearch.toLowerCase())),
          )
          .map(([title, options]) => {
            const category = categories.find((c) => c.id === title)?.title;
            if (!category) return null;

            return (
              <Accordion.Item px="xs" value={category} key={category}>
                <Accordion.Control
                  onClick={() => {
                    setOpenedSections((s) => {
                      if (s.includes(title)) return s.filter((o) => o !== title);
                      return [...s, title];
                    });
                  }}
                  icon={
                    <span
                      style={{
                        display: 'inline-block',
                        transform: !openedSections.includes(title) ? 'rotate(270deg)' : undefined,
                      }}
                    >
                      <Icon size={10} name="arrow-down-3" />
                    </span>
                  }
                >
                  <Text fz="sm" truncate>
                    {category}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel fz="xs">
                  {options
                    .filter((o) => o.toLowerCase().includes(freeSearch.toLowerCase()))
                    .map((option) => (
                      <div
                        key={option}
                        className="hover:bg-gray-200 transition duration-300 ease-in-out cursor-pointer rounded-md"
                        onClick={() => onClick?.(option)}
                      >
                        <Text fz="sm" p="xs" pl="md" truncate>
                          {option}
                        </Text>
                      </div>
                    ))}
                </Accordion.Panel>
              </Accordion.Item>
            );
          })
          .filter(isTruthy)}
      </Accordion>
    </div>
  );
};

export const VerifiedQuestions = computeFeatureFlags(
  FeatureFlag.TW_3_0_FF,
  VerifiedQuestionsComponent,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="Willy Verified Questions"
      description="Chat, analyze and manage with TW 3.0, the AI assistant that helps you effortlessly interact with your eCommerce business."
      featureFlag={FeatureFlag.TW_3_0_FF}
      showButtons={false}
    />
  ),
);
