export function reactNativePostMessage(
  type: 'saveData' | 'clearData' | 'widgetReload' | 'googleLogin',
  data: any,
) {
  try {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
      if (type !== 'widgetReload' && type !== 'googleLogin') {
        reactNativePostMessage('widgetReload', '');
      }
    }
  } catch (e) {
    console.error('reactNativePostMessage error', e);
  }
}

export function reactNativeSaveData(key: 'session' | 'currentShopId', value: any) {
  reactNativePostMessage('saveData', { key, value });
}
