import { WindowWidth } from 'constants/windowSizes';
import { useEffect, useRef, useState } from 'react';

export function useMediaQuery(query: string) {
  const media = useRef(window.matchMedia);
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const handler = (ev: MediaQueryListEvent) => setMatches(ev.matches);
    const matcher = media.current;
    matcher(query).addEventListener('change', handler);
    return () => matcher(query).removeEventListener('change', handler);
  }, [query]);

  return matches;
}

export function useIsSmall() {
  return useMediaQuery(`(max-width: ${WindowWidth.SMALL - 1}px)`);
}

export function useIsMedium() {
  return useMediaQuery(`(max-width: ${WindowWidth.MEDIUM}px)`);
}

export function useIsLarge() {
  return useMediaQuery(`(min-width: ${WindowWidth.MEDIUM}px)`);
}
