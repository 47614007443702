import { $activeAppVersion, useActiveRoute } from '$stores/nav-config-stores';
import { $currentDateRange, refreshDateRanges } from '$stores/willy/$dateRange';
import { useStoreValue } from '@tw/snipestate';
import { ActionIcon, Flex, Menu } from '@tw/ui-components';
import SummaryRefreshButton from 'components/SummaryRefreshButton';
import { FC } from 'react';
import { ChatWithQueryButton } from '../../Willy/ChatWithQueryButton';
import { WillyMainChatRef } from 'components/Willy/types/willyTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { CurrencySection } from './CurrencySection';
import { StatsFilterBar } from 'components/StatsFilterBar';
import { CreateNewSection } from './CreateNewSection';
import { LayoutSection } from './LayoutSection';

type SummaryMobileTopHeaderProps = {
  chatOpen?: boolean;
  setChatOpen?: (val: boolean) => void;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
};

export const SummaryMobileTopHeader: FC<SummaryMobileTopHeaderProps> = ({
  chatOpen,
  setChatOpen,
  mainChatRef,
}) => {
  const currentDateRange = useStoreValue($currentDateRange);
  const { activeRoute } = useActiveRoute();
  const isBenchmarks = activeRoute?.label === 'Benchmarks';
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';
  const shouldShowRefreshButton = !currentDateRange || isBenchmarks ? false : true;

  const isAmazonConnected: boolean = useSelector(
    (state: RootState) =>
      state.isAmazonConnectedNA || state.isAmazonConnectedEU || state.isAmazonConnectedFE,
  );

  return (
    <Flex gap="sm">
      {shouldShowRefreshButton && (
        <SummaryRefreshButton hideLabel={true} customOnPress={refreshDateRanges} buttonSize="md" />
      )}
      {/* <ChatWithQueryButton chatOpen={chatOpen} setChatOpen={setChatOpen} mainChatRef={mainChatRef} onlyIcon /> */}
      <Menu closeOnItemClick={false}>
        <Menu.Target>
          <div>
            <ActionIcon icon="three-dots" variant="activator" />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label tt="uppercase" fw={500} color="gray.5">
            create
          </Menu.Label>
          <CreateNewSection />
          <Menu.Divider />
          <LayoutSection />
          <Menu.Divider />
          <Menu.Label tt="uppercase" fw={500} color="gray.5">
            settings
          </Menu.Label>
          {isAmazonConnected && (
            <>
              <Menu.Item>
                <StatsFilterBar />
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <CurrencySection />
        </Menu.Dropdown>
      </Menu>
      <div className="h-[30px] w-[1px] bg-[var(--mantine-color-named2-3)]" />
    </Flex>
  );
};
