import { $store } from '@tw/snipestate';
import { baseURL } from 'config';
import { useCallback, useEffect, useSyncExternalStore } from 'react';
import axiosInstance from 'utils/axiosInstance';

export type AvatarImageCache = Partial<Record<string, string | null>>;

const $avatarImageCache = $store<AvatarImageCache>({});

export function useAvatarImage(userId?: string) {
  const avatarImage = useSyncExternalStore(
    $avatarImageCache.subscribe,
    // specifically defaulting to undefined, so this can be used directly in
    // components (which generally expect undefined if it doesn't exist)
    useCallback(() => $avatarImageCache.get()[userId!] ?? undefined, [userId]),
  );

  useEffect(() => {
    if (!avatarImage && userId) {
      fetchAndSaveImage(userId);
    }
  }, [avatarImage, userId]);

  return avatarImage;
}

type FetchAndSaveImageOptions = {
  /**
   * If set to true, function makes a new attempt to retrieve the image
   * from the server even if it is already saved in the cache. This means
   * that if the request for the image doesn't return anything, the image
   * is reset to null.
   */
  bustCache?: boolean;
};
export async function fetchAndSaveImage(
  userId: string,
  options: FetchAndSaveImageOptions = { bustCache: false },
) {
  const existingUrl = $avatarImageCache.get()[userId];
  if (!options.bustCache && existingUrl !== undefined) return existingUrl;

  try {
    const imageUrl = `${baseURL}/v2/media/profile-image/${userId}`;
    const response = await axiosInstance.get(imageUrl, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      const url = URL.createObjectURL(response.data);
      $avatarImageCache.set((x) => ({ ...x, [userId]: url }));
    }
  } catch (err) {
    $avatarImageCache.set((x) => ({ ...x, [userId]: null }));
  }
}

export function removeAvatarImageFromCache(userId: string) {
  $avatarImageCache.set((x) => ({ ...x, [userId]: undefined }));
}
