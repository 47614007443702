import { useStoreValue } from '@tw/snipestate';
import { Modal } from '@tw/ui-components';
import { MeetingPlanner } from 'components/WizardWalkthrough/steps/MeetingPlanner';
import { $askQuestionModal, closeAskQuestionModal } from '$stores/willy/$upgradePopupManager';
import { $currentRevenue, $isFreeShop } from '$stores/willy/$subscription';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { useEffect, useState } from 'react';

export const AskQuestionModal = () => {
  const askQuestionModal = useStoreValue($askQuestionModal);
  const isFreeShop = useStoreValue($isFreeShop);
  const revenue = useStoreValue($currentRevenue);
  const { hubSpotCompany }: any = useSelector((state: RootState) => state.hubspot);
  const { primary_sales_team } = hubSpotCompany.properties || {};
  const [routerId, setRouterId] = useState<string>('');

  useEffect(() => {
    if (askQuestionModal.opened) {
      if (revenue > 1000000) {
        if (isFreeShop) {
          setRouterId('app-router');
        } else if (primary_sales_team === 'Expansion Sales') {
          setRouterId('expansion-ae-round-robin');
        } else if (primary_sales_team === 'Brand Sales' || !primary_sales_team) {
          setRouterId('app-router');
        }
      } else {
        setRouterId('');
        closeAskQuestionModal();
      }
    }
  }, [primary_sales_team, isFreeShop, revenue, askQuestionModal.opened]);

  return (
    <>
      {routerId && (
        <Modal
          opened={askQuestionModal.opened}
          onClose={() => {
            closeAskQuestionModal();
          }}
          zIndex={10000}
        >
          <MeetingPlanner routerId={routerId} />
        </Modal>
      )}
    </>
  );
};
