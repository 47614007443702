const YoutubeIconBase64 = ({ small }) => (
  <img
    src="
    data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAzIiBoZWlnaHQ9IjI4MiIgdmlld0JveD0iMCAwIDQwMyAyODIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zOTQuNDE1IDQ0LjAzNzdDMzg5Ljc3MyAyNi42OTQ1IDM3Ni4xNDMgMTMuMDY1NCAzNTguOCA4LjQyMjg2QzMyNy4zOTggMi41MjEyOGUtMDYgMjAxLjQxOSAwIDIwMS40MTkgMEMyMDEuNDE5IDAgNzUuNDQwOSAyLjUyMTI4ZS0wNiA0NC4wMzc1IDguNDIyODZDMjYuNjk0NSAxMy4wNjU0IDEzLjA2NTQgMjYuNjk0NSA4LjQyMjg2IDQ0LjAzNzdDMi41MjEyOGUtMDYgNzUuNDQwOSAwIDE0MSAwIDE0MUMwIDE0MSAyLjUyMTI4ZS0wNiAyMDYuNTU5IDguNDIyODYgMjM3Ljk2M0MxMy4wNjU0IDI1NS4zMDYgMjYuNjk0NSAyNjguOTM1IDQ0LjAzNzUgMjczLjU3N0M3NS40NDA5IDI4MiAyMDEuNDE5IDI4MiAyMDEuNDE5IDI4MkMyMDEuNDE5IDI4MiAzMjcuMzk4IDI4MiAzNTguOCAyNzMuNTc3QzM3Ni4xNDMgMjY4LjkzNSAzODkuNzczIDI1NS4zMDYgMzk0LjQxNSAyMzcuOTYzQzQwMi44MzggMjA2LjU1OSA0MDIuODM4IDE0MSA0MDIuODM4IDE0MUM0MDIuODM4IDE0MSA0MDIuODA1IDc1LjQ0MDkgMzk0LjQxNSA0NC4wMzc3WiIgZmlsbD0iI0ZGMDAwMCIvPgo8cGF0aCBkPSJNMTYxLjA5NyAyMDEuNDI0TDI2NS43NTMgMTQxLjAwNUwxNjEuMDk3IDgwLjU4NTlWMjAxLjQyNFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=
  "
    style={{ height: small ? 11 : 16 }}
    alt="Youtube icon"
  />
);

export default YoutubeIconBase64;
