import { cx } from 'utils/cx';

export type IconComponentProps = {
  icon: React.FC<any> | JSX.Element;
  selected?: boolean;
  mobile?: boolean;
};

export const IconComponent = ({
  icon: Icon,
  selected = false,
  mobile = false,
}: IconComponentProps) => {
  return (
    <div
      className={cx(
        'flex justify-center items-center rounded-md',
        'h-[30px] w-[30px]',
        selected
          ? 'bg-[var(--gray-light-mode-300)] dark:bg-[var(--gray-dark-mode-600)] shadow-sm'
          : 'bg-transparent',
        'p-2',
        mobile ? '' : 'mb-2',
      )}
    >
      {typeof Icon === 'function' ? <Icon className={`w-[19px] h-[19px]`} /> : Icon}
    </div>
  );
};
