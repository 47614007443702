import { valueFormats } from '@tw/types';
import { BaseColumn } from 'components/library/TWTable/types';
import { ProductAdKeys } from './types';
import { getValue, renderLinkColumn, renderNameColumn, renderStatusColumn } from './utils';
import { adColumns } from './constants';

const headingCss = 'text-[#97B2D0] w-full pl-2 flex items-left justify-left';
const columnCss = 'w-full pl-6 flex items-left justify-left';

export const columns: BaseColumn<any, ProductAdKeys>[] = adColumns.map(
  (col: { key: string; heading: string; style: valueFormats }) => {
    const { key, heading, style } = col;
    return {
      ...col,
      Heading: () => <div className={headingCss}>{heading}</div>,
      Value: (ad, metadata = {}) => {
        switch (key) {
          case 'status':
            return renderStatusColumn(ad, metadata);
          case 'name':
            return renderNameColumn(ad);
          case 'link':
            return renderLinkColumn(ad, metadata);
          default:
            return <div className={columnCss}>{getValue(ad, key, metadata, style)}</div>;
        }
      },
    };
  },
);
