import { Popover, Text, Flex, Icon } from '@tw/ui-components';
import { FC } from 'react';

type ColumnTooltipProps = {
  title: string;
  subTitle: string;
  description: string;
  children: React.ReactNode;
  tooltipOpened: boolean;
  readmeUrl?: string;
  withinPortal?: boolean;
  offset?: number;
  tracking?: () => void;
};

export const ColumnTooltip: FC<ColumnTooltipProps> = ({
  title,
  subTitle,
  description,
  children,
  tooltipOpened,
  readmeUrl = '',
  withinPortal = false,
  offset = 20,
  tracking,
}) => {
  return (
    <Popover
      opened={tooltipOpened}
      position="right-start"
      offset={offset}
      shadow="md"
      withinPortal={withinPortal}
    >
      <Popover.Target>
        <div>{children}</div>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction="column" gap="xs" maw={300}>
          <Flex direction="column">
            <Text fz="sm" fw={500} c="gray.8">
              {title}
            </Text>
            {subTitle && (
              <Text fz="sm" c="gray.5">
                {subTitle}
              </Text>
            )}
          </Flex>
          <Text fz="sm" c="gray.9">
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Text>
          <Flex
            onClick={(e) => {
              e.stopPropagation();
              window.open(`https://triplewhale.readme.io/reference/${readmeUrl}`, '_blank');
              tracking?.();
            }}
            cursor="pointer"
            align="center"
            gap="xs"
          >
            <Text color="one.5" size="sm" weight={500}>
              Go to data dictionary
            </Text>
            <Icon name="external-minor" color="one.5" />
          </Flex>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
