import { Icon, IconName, RingProgress, Text } from '@tw/ui-components';
import { ToolProgress } from './types/willyTypes';
import { useMemo } from 'react';
import { willyToolMap } from './constants';
import Markdown from 'react-markdown';
import { Components } from 'react-markdown';

type WillyToolProgressProps = {
  progress: ToolProgress;
  question?: string;
};

export const WillyToolProgress: React.FC<WillyToolProgressProps> = ({ progress, question }) => {
  const title = useMemo(() => {
    const step = progress.step;

    if (!step) {
      return `Finished analyzing${question ? ` for "${question}"` : ''}`;
    }

    return step;
  }, [progress.step, question]);

  const components: Components = useMemo(() => {
    return {
      a: ({ node, ...props }) => <a {...props} target="_blank" className="text-[#0C70F2]" />,
      ul: ({ node, ...props }) => <ul {...props} className="m-0" />,
      ol: ({ node, ...props }) => <ol {...props} className="m-0" />,
      li: ({ node, ...props }) => <li {...props} className="m-0" />,
    };
  }, []);

  const color = useMemo(
    () => (progress.error ? 'red.5' : willyToolMap[progress.name]?.color || 'one.5'),
    [progress.name, progress.error],
  );
  const icon = useMemo<IconName>(
    () => willyToolMap[progress.name]?.icon || 'triple-whale-logo',
    [progress.name],
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="inline-flex gap-2">
        <RingProgress
          sections={[
            {
              value: progress.progress ?? 100,
              color,
            },
          ]}
          size={30}
          thickness={1}
          label={
            <span className="flex justify-center">
              <Icon name={icon} color={color} size={12} />
            </span>
          }
        />

        {!!title && (
          <Text color={color} size="sm">
            <div className="mt-2 line-clamp-2">
              <Markdown children={title} components={components} />
            </div>
          </Text>
        )}
      </div>
    </div>
  );
};
