function CSVToArray(strData, strDelimiter?) {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = strDelimiter || ',';

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    // Delimiters.
    '(\\' +
      strDelimiter +
      '|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      '\\r\\n]*))',
    'gi',
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData: any[][] = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches: any = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  // eslint-disable-next-line no-cond-assign
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    var strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue.trim());
  }
  // Return the parsed data.
  return arrData.filter((row) => row.some((val) => val !== ''));
}

const NUMERAL_PROPS = ['handling_fees', 'product_cost', 'shipping_cost'];

const CSVImport = (data) => {
  var rows = CSVToArray(data);

  var propNames = rows[0];
  rows = rows.slice(1);
  var cogs = rows.map((rawRow) => {
    var cog: any = {};
    propNames.forEach((prop, i) => {
      prop = prop.toLowerCase();
      var val = rawRow[i];
      if (!!val) {
        var isNumeral = !isNaN(val);
        cog[prop] = isNumeral ? +val : val;
      } else {
        // firebase doesn't like undefined values
        if (NUMERAL_PROPS.includes(prop)) {
          cog[prop] = 0;
        } else {
          cog[prop] = '';
        }
      }
    });
    return cog;
  });
  cogs = cogs.filter((cog) => cog.id !== '');
  return cogs;
};

export default CSVImport;
