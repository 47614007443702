export const projectId = 'triple-whale-staging';

export default {
  apiKey: 'AIzaSyCebcxwupJdqKICfXhpErs5L5euGgpp2W8',
  authDomain: projectId + '.firebaseapp.com',
  databaseURL: 'https://triple-whale-staging.firebaseio.com',
  projectId,
  storageBucket: projectId + '.appspot.com',
  messagingSenderId: '89529659540',
  appId: '1:89529659540:web:7ec70450c9557fdddf0481',
  measurementId: 'G-SBCM18SR29',
};
