import { Allotment } from 'allotment';
import { useSelectByWindowResize } from 'hooks/useSelectByWindowResize';
import { WorkflowBuilderWrapper } from './sequenceBuilder/WorkflowBuilderWrapper';
import { useEffect, useRef, useState } from 'react';
import { WorkflowInMobyStatus } from './hooks/useChatSocket';
import { ActionIcon, Loader, Text } from '@tw/ui-components';
import { noop } from 'lodash';
import { $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';

type WillyWorkflowPanelProps = {
  workflowIdToRun?: string;
  conversationId: string;
  workflowInMobyStatus: WorkflowInMobyStatus;
  runOnInit?: boolean;
  workflowPanelOpen?: boolean;
  setWorkflowPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WillyWorkflowPanel: React.FC<WillyWorkflowPanelProps> = ({
  workflowIdToRun,
  workflowInMobyStatus,
  conversationId,
  runOnInit = false,
  workflowPanelOpen,
  setWorkflowPanelOpen,
}) => {
  const allWorkflows = useStoreValue($shopSequences);
  const windowWidth = useSelectByWindowResize(({ width }) => width);
  const [initialPositionX, setInitialPositionX] = useState(0);
  const [shouldRunOnInit, setShouldRunOnInit] = useState(runOnInit);

  const workflow = allWorkflows.find((workflow) => workflow.id === workflowIdToRun);

  useEffect(() => {
    if (workflowInMobyStatus === 'done') {
      setShouldRunOnInit(false);
    }
  }, [workflowInMobyStatus]);

  return (
    <div className="h-full">
      <div className="absolute top-2 right-2 z-50">
        {!!workflowPanelOpen && (
          <ActionIcon icon="close" onClick={() => setWorkflowPanelOpen?.(false)} />
        )}
        {!workflowPanelOpen && (
          <div className="flex items-center gap-4">
            <Text fw="medium" color="gray.5">
              See Active Workflow
            </Text>
            <div className="rotate-180">
              <ActionIcon icon="chevron-down" onClick={() => setWorkflowPanelOpen?.(true)} />
            </div>
          </div>
        )}
      </div>
      {!workflowIdToRun &&
        (workflowInMobyStatus === 'idle' ||
          workflowInMobyStatus === 'started' ||
          workflowInMobyStatus === 'editing') && (
          <div className="h-full flex flex-col p-6.5">
            <div className="flex flex-col items-center justify-center m-auto gap-4">
              {(workflowInMobyStatus === 'started' || workflowInMobyStatus === 'editing') && (
                <Loader size="lg" />
              )}
              {workflowInMobyStatus === 'idle' && (
                <div className="text-gray-5 text-lg">Select a workflow to run</div>
              )}

              {workflowInMobyStatus === 'started' && (
                <div className="text-gray-5 text-lg">Workflow will start soon...</div>
              )}

              {workflowInMobyStatus === 'editing' && (
                <div className="text-gray-5 text-lg">Workflow is being edited...</div>
              )}
            </div>
          </div>
        )}
      {!!workflow && (
        <WorkflowBuilderWrapper
          sequence={workflow}
          setSequence={noop}
          runOnInit={shouldRunOnInit}
          withMiniMap={false}
          canAddNewStep={false}
          // navBarWidth={0}
          canvasSize={windowWidth.toString()}
          zoom={0.8}
          initialPositionY={100}
          initialPositionX={initialPositionX}
          readOnly
          rephraseStepsBasedOnConversation={conversationId}
        />
      )}
    </div>
  );
};
