import { useCallback, useState } from 'react';
import { WillyFieldTypes } from './types/willyTypes';
import { WillySectionHeader } from './WillySectionHeader';
import { TrixEditor } from 'react-trix';
import { useDashContext } from './dashContext';
import { WillySimpleText } from './WillySimpleText';
import WillyDropZone from './WillyDropZone';
import { TextInput } from '@tw/ui-components';
import { useComputedValue, useStoreValue } from '@tw/snipestate';

type WillySectionProps = {
  id: string;
  title: string;
  type: WillyFieldTypes;
  editingFields: string[];
  content: string;
  localFiles: File[];
  titleChanged: (id: string, title: string) => Promise<void>;
  localFilesChanged: (files: File[]) => Promise<void>;
  filesLoaded: (fieldId: string, files: File[]) => Promise<void>;
  contentChanged: (id: string, content: string, updatedAt) => Promise<void>;
  editingFieldsChanged: (fields: string[]) => Promise<void>;
  fieldRemoved: (id: string) => Promise<void>;
  isDnd: boolean;
  isMarkdown?: boolean;
  secondaryToolbarOpen?: boolean;
  editLayout?: boolean;
  inMobileDrawer?: boolean;
  forceContent?: boolean;
};

export const WillySection: React.FC<WillySectionProps> = ({
  id,
  editingFields,
  type,
  title,
  content,
  localFiles,
  localFilesChanged,
  contentChanged,
  titleChanged,
  editingFieldsChanged,
  fieldRemoved,
  filesLoaded,
  isDnd,
  isMarkdown,
  secondaryToolbarOpen,
  editLayout,
  inMobileDrawer,
  forceContent,
}) => {
  const [localContent, setLocalContent] = useState(content);
  const { $images, $dashboard } = useDashContext();
  const images = useStoreValue($images);
  // NOTE: Using these derived stores instead of the $canEdit and $isDnd stores
  // that are in the context already, because those stores represent the permissions
  // of the original dashboard in the combinedDashboards map instead of the current dashboard
  const canEdit = useComputedValue($dashboard, (d) => !!d?.canEdit);
  const isDashboardDnd = useComputedValue($dashboard, (d) => !!d?.isDnd);

  const extractYoutubeId = useCallback((url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }, []);

  const generateUrl = useCallback(
    (url: string) => {
      const youtubeId = extractYoutubeId(url);
      return youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : url;
    },
    [extractYoutubeId],
  );

  const onSaveContent = useCallback(() => {
    contentChanged(id, localContent, new Date().toISOString());
  }, [contentChanged, id, localContent]);

  return (
    <>
      <WillySectionHeader
        id={id}
        editingFields={editingFields}
        type={type}
        canEdit={canEdit}
        onSaveContent={type === 'image' ? undefined : onSaveContent}
        title={title}
        titleChanged={titleChanged}
        editingFieldChanged={editingFieldsChanged}
        secondaryToolbarOpen={secondaryToolbarOpen}
        fieldRemoved={fieldRemoved}
        isDnd={isDnd}
        editLayout={editLayout}
        inMobileDrawer={inMobileDrawer}
      />
      {type === 'text' && (
        <div className="w-full h-auto p-4 overflow-x-hidden bg-white dark:bg-slate-800 flex flex-col flex-grow">
          {editingFields.includes(id) ? (
            <TrixEditor
              key={id}
              className="w-full h-full"
              autoFocus
              value={localContent}
              onChange={(val) => {
                const v = val.trim();
                if (v.length > 0) {
                  setLocalContent(v);
                }
              }}
              mergeTags={[]}
            />
          ) : isMarkdown ? (
            <WillySimpleText text={localContent} />
          ) : (
            <div className="prose dark:prose-invert max-w-full">
              <div dangerouslySetInnerHTML={{ __html: forceContent ? content : localContent }} />
            </div>
          )}
        </div>
      )}
      {type === 'image' && (
        <div className="relative w-full h-full">
          {editingFields.includes(id) && canEdit && (
            <div className="w-full p-4 bg-white dark:bg-slate-800 absolute">
              <div className="w-full">
                <h3>{content?.length > 0 ? 'Update' : 'Add'} image</h3>
                <WillyDropZone
                  files={localFiles}
                  setFiles={async (files) => {
                    await localFilesChanged(files);
                    // we have to do this to force the image to change, since the URL doesn't
                    setLocalContent(URL.createObjectURL(files[0]));
                  }}
                  onDrop={(files) => filesLoaded(id, files)}
                />
              </div>
            </div>
          )}
          {(content?.length > 0 && (
            <div className="w-full h-full overflow-x-hidden bg-white dark:bg-slate-800 flex flex-col">
              <div
                style={{
                  background: `url(${localContent ?? images[id]}) no-repeat`,
                }}
                className={`block w-full h-full !bg-center pb-[100%] ${
                  isDashboardDnd ? '!bg-contain sm:pb-[30vh]' : '!bg-cover sm:pb-0'
                }`}
              ></div>
            </div>
          )) || (
            <div className="w-full h-full overflow-hidden bg-white dark:bg-slate-800 p-4">
              No Image, yet..
            </div>
          )}
        </div>
      )}
      {type === 'video' && (
        <div className="w-full h-full overflow-x-hidden bg-white dark:bg-slate-800 flex flex-col">
          {editingFields.includes(id) && (
            <div className="p-4">
              <TextInput
                value={localContent}
                label="Embed Link"
                description="Paste a video embed link here"
                onChange={(val) => setLocalContent(val)}
              />
            </div>
          )}
          {content?.length > 0 && (
            <div
              className={`w-full h-full relative ${isDashboardDnd ? 'absolute sm:pb-[30vh]' : 'sm:pb-0'}`}
            >
              <iframe
                className={`block w-full h-full absolute`}
                src={generateUrl(localContent)}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}
    </>
  );
};
