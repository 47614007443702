import { useMemo } from 'react';
import { CustomMetricsType } from 'ducks/customMetrics';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useSelector } from 'react-redux';
import { selectCustomMetricsColumns } from 'utils/customMetrics';
import allPixelColumns from 'components/attribution-new/columns';
import { ALL_SOURCES_ID } from 'constants/types';
import { PixelColumn } from 'types/attribution';

export const useMetricsForCustomMetricBuilder = (customMetricsType: CustomMetricsType) => {
  const attributionActiveSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const customMetricsColumns: PixelColumn[] = useSelector(
    selectCustomMetricsColumns(customMetricsType),
  );

  const columns = useMemo(() => {
    const allColumns = allPixelColumns().filter((col) => {
      const isAvailableForSourceAndCategory =
        !col.showInSources ||
        col.showInSources.includes(attributionActiveSource) ||
        (!col.excludeFromAllLevel &&
          (attributionActiveSource === ALL_SOURCES_ID || sourceCategory === 'all'));

      return isAvailableForSourceAndCategory;
    });

    return allColumns;
  }, [attributionActiveSource, sourceCategory]);

  return [columns, customMetricsColumns];
};
