import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useCallback, useState } from 'react';
import { WillyDashboardElement, WillyMetric } from '../types/willyTypes';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';

type WillyDndMetrics = {
  metrics: WillyMetric[];
  queryId?: string;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  dashboard?: WillyDashboardElement;
};

export const useWillyDndMetrics = ({
  metrics,
  metricsChanged,
  queryId,
  dashboard,
}: WillyDndMetrics) => {
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, { activationConstraint: { delay: 250, tolerance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      if (!queryId) {
        return;
      }
      if (active.id !== over?.id) {
        const metric = metrics.find((x) => x.key === active.id);
        const oldIndex = metrics.findIndex((x) => x.key === active.id);
        const newIndex = metrics.findIndex((x) => x.key === over?.id);

        const newMetrics = [...metrics];
        newMetrics.splice(newIndex, 0, newMetrics.splice(oldIndex, 1)[0]);
        await metricsChanged(queryId, newMetrics);

        genericEventLogger(analyticsEvents.DASHBOARDS, {
          action:
            active.data.current?.sortable.containerId === 'left'
              ? dashboardsActions.HIDE_COLUMN
              : dashboardsActions.SHOW_COLUMN,
          metric_name: metric?.name,
          metric_key: metric?.key,
          dashboard_id: dashboard?.id,
          dashboard_name: dashboard?.name,
          template_id: dashboard?.globalDashboardId,
          template_name: dashboard?.globalDashboardId && dashboard?.name,
        });
      }
      setActiveId(null);
    },
    [
      dashboard?.globalDashboardId,
      dashboard?.id,
      dashboard?.name,
      metrics,
      metricsChanged,
      queryId,
    ],
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      const overId = over?.id;

      if (!overId) {
        return;
      }

      const activeContainer = active.data.current?.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;

      if (activeContainer && overContainer && activeContainer !== overContainer) {
        const newMetrics = metrics.map((x) => {
          if (x.key === active.id) {
            return { ...x, yAxisId: overContainer as 'left' | 'right' };
          }
          return x;
        });
        metricsChanged(queryId!, newMetrics);
      }
    },
    [metrics, metricsChanged, queryId],
  );

  return {
    activeId,
    sensors,
    handleDragEnd,
    handleDragStart,
    handleDragOver,
  };
};
