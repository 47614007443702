import { useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { InfoTab, InfoTabProps } from './InfoTab';
import { Button } from '@tw/ui-components';
import { VideoIframe } from './VideoIframe';

const DEFAULT_LEARN_MORE_LINK =
  'https://kb.triplewhale.com/en/articles/5960333-attribution-models-in-triple-pixel';

export type InfoPanelProps = {
  topElement?: React.FC | React.ReactNode;
  modelValues: InfoTabProps[];
  videoEmbed?: string;
  learnMoreLink?: string;
};

export const InfoPanel: React.FC<InfoPanelProps> = ({
  modelValues,
  topElement,
  videoEmbed,
  learnMoreLink = DEFAULT_LEARN_MORE_LINK,
}) => {
  const uniqueId = useRef(uuidv4());

  const TopElement = useMemo(() => {
    const T = topElement;
    return typeof T === 'function' ? <T /> : T;
  }, [topElement]);

  return (
    <div className="flex flex-col gap-5 py-10 px-5 min-h-full">
      {TopElement}
      {modelValues.map((props, i) => (
        <InfoTab key={uniqueId.current + ' ' + i} {...props} />
      ))}
      <VideoIframe videoEmbed={videoEmbed} />
      <div style={{ marginTop: 'auto' }} className="self-end">
        <Button onClick={() => window.open(learnMoreLink, '_blank')} variant="secondary">
          Learn More
        </Button>
      </div>
    </div>
  );
};
