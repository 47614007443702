import { Tooltip } from '@shopify/polaris';
import { Icon, Flex, Menu, Switch, Text } from '@tw/ui-components';
import { changeSubscriptios } from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { analyticsEvents, attributionActions, genericEventLogger } from 'utils/dataLayer';
import { SubscriptionItems, TagsOptions } from './AttributionMoreSettings';

export const AttributionSubscriptionFilter: FC = () => {
  const { attributionSubscription } = useSelector((state: RootState) => state.attribution);
  const dispatch = useAppDispatch();

  const tagsToCheck: TagsOptions[] = useMemo<TagsOptions[]>(
    () => ['oneTime', 'subscriptionFirstOrder', 'subscriptionRecurringOrder'],
    [],
  );

  const subscriptionFilterOn = useMemo<boolean>(() => {
    const allTagsPresent = tagsToCheck.every((tag) => attributionSubscription?.includes(tag));
    return !allTagsPresent && !!attributionSubscription.length;
  }, [attributionSubscription, tagsToCheck]);

  const updateAttributionSubscription = (value: TagsOptions) => {
    if (attributionSubscription.includes(value)) {
      const newAttributionSubscription = attributionSubscription.filter((item) => item !== value);
      dispatch(changeSubscriptios(newAttributionSubscription));
    } else {
      dispatch(changeSubscriptios([value, ...attributionSubscription]));
    }
    genericEventLogger(analyticsEvents.ATTRIBUTION, {
      action: attributionActions.FILTER_ATTRIBUTION_BY_SUBSCRIPTION,
      value,
    });
  };

  const dropdownContent = SubscriptionItems.map((item) => {
    return {
      label: (
        <div key={item.value} className="subscription-filter">
          <Switch
            size={'sm'}
            variant={'short'}
            // There's no dark mode for this component yet, so forcing color here
            label={
              <Text size="sm" weight={500} color="named2.0">
                {item.label}
              </Text>
            }
            checked={attributionSubscription.includes(item.value)}
            onChange={() => updateAttributionSubscription(item.value)}
          />
        </div>
      ),
      value: item.value,
    };
  });

  return (
    <div style={{ position: 'relative' }}>
      <Menu withinPortal={false} position={'right-start'} offset={16} closeOnItemClick={false}>
        <Menu.Target>
          <div>
            <Tooltip
              content={
                subscriptionFilterOn
                  ? 'Table is currently displaying subscription filtered data'
                  : ''
              }
            >
              <Flex align="center" gap="md" justify="space-between" id="subscription-filter">
                <Text size="sm" weight={500} color="named2.0">
                  Subscription filter: {attributionSubscription.length} active
                </Text>
                <Icon name="chevron-right-minor" size={12} color="named.8" />
              </Flex>
            </Tooltip>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {dropdownContent.map((item) => (
            <Menu.Item key={item.value}>{item.label}</Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
