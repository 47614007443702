import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { RawNlqData } from './types/willyTypes';
import { convertDataToJson } from './utils/willyUtils';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import moment from '@tw/moment-cached/module/timezone';
import { useDarkMode } from 'dark-mode-control';
import { Loader } from '@tw/ui-components';

type WillyRawTableProps = {
  data: RawNlqData;
  loading?: boolean;
  orderBy?: string;
  order?: 'asc' | 'desc';
};

export const WillyRawTable = forwardRef<{ table: AgGridReact | null }, WillyRawTableProps>(
  ({ data, loading, order, orderBy }, ref) => {
    const doDarkMode = useDarkMode();
    const tableRef = useRef<AgGridReact>(null);

    const tableData = useMemo(() => {
      return convertDataToJson(data);
    }, [data]);

    const columns = useMemo(() => {
      if (!data) {
        return [];
      }

      const columns: ColDef[] = data.map((d) => {
        const key = d.name;

        const firstValue = d.value[0];

        let filter: string | React.FC = 'agTextColumnFilter';
        if (typeof firstValue === 'string' && moment(new Date(firstValue)).isValid()) {
          filter = 'agDateColumnFilter';
        } else if (typeof firstValue === 'string') {
          filter = 'agTextColumnFilter';
        } else if (typeof firstValue === 'object') {
          filter = 'agTextColumnFilter';
        } else if (typeof firstValue === 'number') {
          filter = 'agNumberColumnFilter';
        } else {
          filter = 'agTextColumnFilter';
        }

        const c: ColDef = {
          colId: key,
          field: key,
          sort: orderBy === key ? order : undefined,
          wrapText: true,
          autoHeight: true,
          sortable: true,
          suppressHeaderMenuButton: true,
          // suppressSorting: true,
          enableRowGroup: true,
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const a = valueA?.currentValue || valueA;
            const b = valueB?.currentValue || valueB;
            if (typeof a === 'number' && typeof b === 'number') {
              return a - b;
            } else if (typeof a === 'string' && typeof b === 'string') {
              return a.localeCompare(b);
            } else {
              return 0;
            }
          },
          resizable: true,
          filter: filter,
          width: 150,
          cellDataType: 'text',
        };

        return c;
      });

      return columns;
    }, [data, order, orderBy]);

    useImperativeHandle(ref, () => {
      return {
        table: tableRef.current,
      };
    }, [tableRef]);

    return (
      <div
        className={`w-full h-full overflow-auto ${
          doDarkMode ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'
        }`}
      >
        {loading && (
          <div className="absolute inset-0 bg-white bg-opacity-50 z-10">
            <div className="absolute inset-0 flex items-center justify-center">
              <Loader size="md" />
            </div>
          </div>
        )}
        <AgGridReact
          rowData={tableData}
          ref={tableRef}
          columnDefs={columns}
          onGridReady={(params) => {
            // params.api.getToolPanelInstance('filters')?.expandFilters();
          }}
          showOpenedGroup={true}
          groupDisplayType="groupRows"
          enableCellTextSelection
          ensureDomOrder
          pagination
          paginationPageSizeSelector={false}
          processCellForClipboard={(params) => {
            const { value, column } = params;
            const { currentValue } = value;
            if (typeof currentValue === 'number') {
              return JSON.stringify(value);
            }
            return JSON.stringify(currentValue);
          }}
          domLayout="normal"
        />
      </div>
    );
  },
);
