import { DatePicker } from '@shopify/polaris';
import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { convertDateToMoment } from 'utils/convertDateToMoment';
import { Button } from '@tw/ui-components';

type DatePickerSimpleProps = {
  selected?: string[];
  allowRange?: boolean;
  onChange: (range: { start: string; end: string }) => void;
  onClose: () => void;
};

export const DatePickerSimple: React.FC<DatePickerSimpleProps> = ({
  selected,
  allowRange,
  onChange,
  onClose,
}) => {
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const selectedRange = useMemo(() => {
    if (typeof selected === 'undefined' || selected.length === 0) {
      return undefined;
    }

    if (allowRange) {
      return {
        start: moment(selected[0]).toDate(),
        end: moment(selected[1] || selected[0]).toDate(),
      };
    } else {
      return {
        start: moment(selected[0]).toDate(),
        end: moment(selected[0]).toDate(),
      };
    }
  }, [selected, allowRange]);

  return (
    <div className="flex flex-col gap-4">
      <DatePicker
        multiMonth={false}
        month={month}
        year={year}
        allowRange={allowRange}
        onChange={(date) => {
          const startIsValid = !isNaN(date.start.valueOf());
          const endIsValid = !isNaN(date.end.valueOf());
          const start = convertDateToMoment(startIsValid ? date.start : new Date()).format(
            'YYYY-MM-DD',
          );
          const end = convertDateToMoment(endIsValid ? date.end : new Date()).format('YYYY-MM-DD');
          onChange({ start, end });
        }}
        selected={selectedRange}
        onMonthChange={(m, y) => {
          setDate((old) => ({ ...old, month: m, year: y }));
        }}
      />
      <div className="flex items-center justify-end gap-4">
        <Button onClick={() => onClose()} variant="primary">
          Done
        </Button>
      </div>
    </div>
  );
};
