import db, { FieldValue, toArray } from '../utils/DB';
import moment from '@tw/moment-cached';
import { SummaryMetrics } from '@tw/types/module/SummaryMetrics';
import { toggleSaveIndicator } from './actions';
import { CUSTOM_SPEND_GOOGLE_SHEET_RECEIVED } from 'ducks/constants';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';
import { $currentShopId } from '$stores/$shop';

const COLLECTION = 'reports';

export const REPORTS_METRICS = Object.values(SummaryMetrics);

export const REPORTS_RECEIVED = 'REPORTS_RECEIVED';
export const LOADING_REPORTS = 'LOADING_REPORTS';
export const loadReports = () => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_REPORTS });
    return db()
      .collection(COLLECTION)
      .orderBy('createdAt', 'desc')
      .onSnapshot((data) => {
        dispatch({
          type: REPORTS_RECEIVED,
          reports: toArray(data).filter((r) => r.type !== 'googleSheets'),
          tz: getState().shopTimezone,
        });
        dispatch({
          type: CUSTOM_SPEND_GOOGLE_SHEET_RECEIVED,
          sheet: toArray(data).find((r) => r.reportType === 'customSpends'),
        });
      });
  };
};

const hoursToUtc = (obj, tz) => obj.hours.map((h) => moment.utc(moment().tz(tz).hour(h)).hour());
const clearEmptyEmails = (obj) => obj.recipients.filter((r) => !!r.length);

export const SAVING_NEW_REPORT = 'SAVING_REPORT';
export const NEW_REPORT_SAVED = 'NEW_REPORT_SAVED';
export const NEW_REPORT_ERROR = 'NEW_REPORT_ERROR';
export const createReport = (obj) => {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    dispatch({ type: SAVING_NEW_REPORT, report: obj });
    var newDoc = db().collection(COLLECTION).doc();
    var id = newDoc.id;
    var hours = hoursToUtc(obj, getState().shopTimezone);
    var recipients = clearEmptyEmails(obj);
    await newDoc.set({
      id,
      ...obj,
      hours,
      recipients,
      shopDomain: $currentShopId.get(),
      createdAt: FieldValue.serverTimestamp(),
    });
    dispatch(loadReports());
    dispatch({ type: NEW_REPORT_SAVED, ...obj, id });
  };
};
export const resetCreatingReport = () => {
  return async (dispatch) => {
    dispatch({ type: NEW_REPORT_SAVED, report: null });
  };
};

export const reportOnEditSubmit = (obj) => {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    var { id } = obj;
    var doc = db().collection(COLLECTION).doc(id);
    var hours = hoursToUtc(obj, getState().shopTimezone);
    var recipients = clearEmptyEmails(obj);
    await doc.update({
      id,
      ...obj,
      hours,
      recipients,
    });
    toast.success('Report edited successfully');
  };
};

export const removeReport = (obj) => {
  return async (dispatch) => {
    //dispatch({type: SAVING_NEW_REPORT});
    var doc = db().collection(COLLECTION).doc(obj.id);
    await doc.delete();
    toast.success('Report deleted successfully');
  };
};

export const tryItNow = (report) => {
  return async (dispatch, getState) => {
    try {
      const shopDomain = getState().currentShopId;
      await axiosInstance.post(
        '/v2/summary-page/try-report-send',
        { ...report, shopDomain },
        { timeout: 120000 },
      );
      toast.success('Report sent successfully');
    } catch (err) {
      console.log(err);
    }
  };
};

export const SHARE_REPORT_DONE = 'SHARE_REPORT_DONE';
export const shareReport = (report, delivery) => {
  return async (dispatch, getState) => {
    const shopId = getState().currentShopId;
    const response = await axiosInstance.post('/v2/summary-page/share-report-snapshot', {
      ...report,
      shopId,
      delivery,
    });
    return dispatch({ type: SHARE_REPORT_DONE, ...delivery, ...response?.data });
  };
};

const reports = (state = [], action) => {
  switch (action.type) {
    case REPORTS_RECEIVED:
      var _r = action.reports.map((r) => ({
        ...r,
        hours: r.hours.map((h) => moment(moment.utc().hour(h)).tz(action.tz).hour()),
      }));
      return _r;
    default:
      return state;
  }
};

const loadingReports = (state = true, action) => {
  switch (action.type) {
    case REPORTS_RECEIVED:
      return false;
    default:
      return state;
  }
};

const creatingReport = (state = { creating: false }, action) => {
  switch (action.type) {
    case SAVING_NEW_REPORT:
      return {
        ...action.report,
        creating: true,
      };
    case NEW_REPORT_SAVED:
      return {
        creating: false,
      };
    case NEW_REPORT_ERROR:
      return {
        creating: true,
        error: action.error,
        ...action.report,
      };
    default:
      return state;
  }
};

// const statsForChangelog = (state = {}, action) => {
//   switch (action.type) {
//     case STATS_FOR_CHANGELOG_CHART_RECEIVED:
//       return action.data;
//     default:
//       return state;
//   }
// }

export const reducers = {
  reports,
  //statsForChangelog,
  creatingReport,
  loadingReports,
};
