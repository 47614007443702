import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { combineReducers } from 'redux';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';

const GET_POD_DATA = 'GET_POD_DATA';

const GET_EMAIL = 'GET_EMAIL';

const fetchOwnerEmail = (podId: string) => {
  return async (dispatch) => {
    const ownerEmail = (await axiosInstance.get(`/v2/account-manager/pods/getPodOwner/${podId}`))
      .data;

    ownerEmail &&
      dispatch({
        type: GET_EMAIL,
        payload: ownerEmail,
      });
  };
};

export const getPodData = (podId: string) => {
  return async (dispatch) => {
    const podRef = firebase.firestore().collection('workspaces').doc(podId).get();
    const res = (await podRef).data();
    if (!res?.ownerEmail) {
      dispatch(fetchOwnerEmail(podId));
    }
    dispatch({
      type: GET_POD_DATA,
      payload: res,
    });
  };
};

export const updatePodData = async (
  podId: string,
  objToUpdate: { name?: string; ownerEmail?: string; podCurrency?: string },
) => {
  const podRef = firebase.firestore().collection('workspaces').doc(podId);
  await podRef.update(objToUpdate);
  toast.success('Saved!');
};

export const getPodStores = async (shops: string[]) => {
  const stores = Array.isArray(shops) ? shops : Object.keys(shops);
  const promises = stores.map(async (shopId) => {
    const data = (await firebase.firestore().collection('shops').doc(shopId).get()).data() || {};
    // const { result } = await fetchCurrentShopPlan(shopId);
    return { ...data, shopId };
    // return { ...data, plan: result, shopId };
  });
  const res = await Promise.all(promises);
  return res;
};

const currentPod = (state = {}, action) => {
  switch (action.type) {
    case GET_EMAIL: {
      const ownerEmail = action.payload;
      return { ...state, ownerEmail };
    }
    case GET_POD_DATA: {
      return action.payload || state;
    }
    default: {
      return state;
    }
  }
};

export const reducers = combineReducers({
  currentPod,
});
