import { useMemo } from 'react';
import { ActionIcon, Box, PopoverLink, StyledPopover, Text } from '@tw/ui-components';
import { StoreAdder } from '../../blocks/SettingsAvatar/StoreAdder';
import { useNavigate } from 'react-router-dom';

export const AddShopPopover = () => {
  const navigate = useNavigate();

  const config = useMemo<PopoverLink[]>(() => {
    return [
      {
        children: <StoreAdder withIcon={false} />,
      },
      {
        children: (
          <Box cursor="pointer" onClick={() => navigate('/pod-settings')}>
            <Text>Invite a User to Pod</Text>
          </Box>
        ),
      },
    ];
  }, [navigate]);

  return (
    <StyledPopover
      position="left-start"
      target={<ActionIcon variant="activator" radius="sm" size="lg" icon="union" />}
      popoverLinks={config}
    />
  );
};
