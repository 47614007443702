import TWImage from 'components/library/TWImage/TWImage';
import { lightboxOpen } from 'ducks/actions';
import { GENERATIVE_AI_POPUP_OPENED } from 'ducks/constants';
import { useAppDispatch } from 'index';
import { useCallback } from 'react';
import { AdContent, GenerativeAiPopupData } from 'types/general';
import { PlayCircleMajor } from '@shopify/polaris-icons';

type AdMediaCardProps = {
  media: AdContent;
};

const AdMediaCard: React.FC<AdMediaCardProps> = ({ media }) => {
  const dispatch = useAppDispatch();
  const { _id, count, contentType, thumbnail, ads } = media;
  const [ad] = ads || [];

  const caption = (
    <div className="flex gap-4 items-center justify-between text-secondary-text">
      <p>
        <span>
          Appears in {count} ad{count > 1 ? 's' : ''}
        </span>
      </p>
      {!!ad && !!ad.adId && (
        <>
          {contentType === 'video' && (
            <PlayCircleMajor
              width={20}
              height={20}
              className="fill-logo opacity-70"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                  lightboxOpen({
                    resources: [
                      {
                        url: thumbnail,
                        type: 'video',
                        altTag: ad.name,
                        thumbnail: thumbnail,
                        extraData: {
                          serviceId: ad.serviceId,
                          assetId: Array.isArray(ad.videoIds) ? ad.videoIds[0] : ad.videoIds,
                          accountId: ad.accountId,
                        },
                      },
                    ],
                  }),
                );
              }}
            />
          )}
        </>
      )}
    </div>
  );

  const openGenerativeAi = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch({
        type: GENERATIVE_AI_POPUP_OPENED,
        payload: {
          serviceId: ad.serviceId,
          accountId: ad.accountId,
          entityId: ad.adId,
          entityName: ad.name,
          attemptFrom: 'ad',
          typeToAttempt: contentType,
          isVideo: contentType === 'video',
          adIds: ads?.map((ad) => ad.adId),
        } as GenerativeAiPopupData,
      });
    },
    [ad, dispatch, contentType, ads],
  );

  return (
    <div className="relative h-full cursor-pointer" onClick={openGenerativeAi}>
      {(contentType === 'image' || contentType === 'video') && (
        <TWImage
          className="w-full h-full object-cover rounded-md"
          wrapperClass="w-full h-96"
          src={thumbnail || _id}
          alt={'ad media'}
          caption={caption}
        />
      )}
      {contentType === 'body' && (
        <div className="flex flex-col gap-4 h-full">
          <div className="w-full h-full overflow-auto break-words">
            <span>{_id}</span>
          </div>
          <div className="mt-auto">{caption}</div>
        </div>
      )}
      {contentType === 'title' && (
        <div className="w-full h-full flex flex-col gap-4">
          <p>{_id}</p>
          <div className="mt-auto">{caption}</div>
        </div>
      )}
    </div>
  );
};

export default AdMediaCard;
