const CSVExport = (data: any[], filename: string, camelCase: boolean = true) => {
  let csvContent = '';
  data = camelCase
    ? [convertCamelCaseToTitleCase(Object.keys(data[0] || []))].concat(data)
    : [Object.keys(data[0] || [])].concat(data);

  data.forEach(function (rowArray, index) {
    rowArray = Object.values(rowArray);
    rowArray = rowArray.map((val) => {
      if (val && isNaN(val)) {
        val = val.replace(/"/g, "''");
        if (val.indexOf(',') >= 0) {
          val = '"' + val + '"';
        }
      }

      return val;
    });
    let row = rowArray.join(',');
    csvContent += row;
    if (index < data.length - 1) {
      csvContent += '\r\n';
    }
  });
  CSVDownload(csvContent, filename);
};

const convertCamelCaseToTitleCase = (keys: string[]) => {
  try {
    return keys.map((key) =>
      key
        .replace(/_/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b\w/g, function (char) {
          return char.toUpperCase();
        }),
    );
  } catch (e) {
    console.error('Failed to convert camel case to title case: ', e.message);
    return keys;
  }
};

export const CSVDownload = (csvString: string, filename: string) => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  try {
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.error('Failed to download CSV: ', e.message);
  }
};

export default CSVExport;
