import { Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';

type Incident = {
  id: string;
  title: string;
  message: string;
  level: 'error' | 'warning' | 'info' | 'success';
};

export const getMonitoringIncidents = () => async (dispatch, getState) => {
  const { data } = await axiosInstance.get('v2/internal/monitoring/incidents');
  const incidents: Incident[] = data.map((incident) => ({
    id: incident.id,
    title: incident.title,
    message: incident.body,
    level: incident.level,
  }));
  dispatch({ type: 'SET_AUTO_ERROR_MESSAGES', payload: incidents });
};

export const dismissMonitoringIncident = (id: string) => (dispatch, getState) => {
  dispatch({ type: 'DISMISS_MONITORING_INCIDENT', payload: id });
};

const dismissedMonitoringIncidents: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case 'DISMISS_MONITORING_INCIDENT':
      return [...state, action.payload];
    default:
      return state;
  }
};

const monitoringIncidents: Reducer<Incident[]> = (state = [], action) => {
  switch (action.type) {
    case 'SET_AUTO_ERROR_MESSAGES':
      return action.payload ?? state;
    default:
      return state;
  }
};

export const reducers = {
  monitoringIncidents,
  dismissedMonitoringIncidents,
};
