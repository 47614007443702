import { metrics } from 'constants/metrics/metrics';

import {
  CreativeFilterOperands,
  CreativeFilterOperator,
  CreativeMetricFilterOperands,
} from '@tw/types';

export const operatorsEQUALS: CreativeFilterOperator[] = ['equals', 'not_equals'];
export const operatorsNAMES: CreativeFilterOperator[] = [
  'equals',
  'not_equals',
  'contains',
  'not_contains',
];
export const operatorsNUMBERS: CreativeFilterOperator[] = [
  'equals',
  'not_equals',
  'greater_than',
  'greater_or_equal_than',
  'less_than',
  'less_or_equal_than',
];

export const operands: CreativeFilterOperands[] = [
  'campaign_name',
  'adset_name',
  'ad_name',
  'campaign_id',
  'adset_id',
  'ad_id',
  // 'roas',
  // 'cpc',
  // 'cpm',
  // 'spend',
  // 'ctr',
  // 'cpm',
  // 'cpa',
  // 'conversionValue',
  // 'allConversionValue',
  // 'purchases',
  // 'allConversions',
  // 'clicks',
  // 'impressions',
  // 'outboundClicks',
  // 'oneDayViewConversionValue',
  // 'totalVideoView',
  // 'threeSecondVideoView',
];

export const operandsNames: Partial<Record<CreativeFilterOperands, string>> = {
  campaign_id: 'Campaign ID',
  adset_id: 'Adset ID',
  ad_id: 'Ad ID',
  campaign_name: 'Campaign Name',
  adset_name: 'Adset Name',
  ad_name: 'Ad Name',
};

export const metricOperands: CreativeMetricFilterOperands[] = [
  'roas',
  'cpc',
  'cpm',
  'spend',
  'impressions',
  'pixelRoas',
];

export const metricFilterOperandsNames: Partial<Record<CreativeMetricFilterOperands, string>> = {
  roas: 'ROAS',
  cpc: 'CPC',
  cpm: 'CPM',
  spend: 'Ad Spend',
  impressions: 'Impressions',
  pixelRoas: 'Pixel ROAS',
};

// clone it
let operandsMetric = JSON.parse(JSON.stringify(metrics));

for (let key in operandsNames) {
  if (!operandsMetric[key]) {
    operandsMetric[key] = { label: operandsNames[key], value: key };
  }
}
export { operandsMetric };

let operandsMetricFilter = JSON.parse(JSON.stringify(metrics));

for (let key in metricFilterOperandsNames) {
  if (!operandsMetricFilter[key]) {
    operandsMetricFilter[key] = { label: metricFilterOperandsNames[key], value: key };
  }
}
export { operandsMetricFilter };

export const operatorsNames: Record<CreativeFilterOperator, string> = {
  equals: '=',
  not_equals: '≠',
  greater_than: '>',
  greater_or_equal_than: '≥',
  less_than: '<',
  less_or_equal_than: '≤',
  contains: 'Contains',
  not_contains: 'Not Contains',
};
