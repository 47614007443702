import { Size, UserSettingsPopover } from '@tw/ui-components';
import { useUserInitials } from 'hooks/useUserInitials';
import { useSettingsConfig } from './useSettingsConfig';
import { $userId } from '../../../../../$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { useAvatarImage } from '$stores/$avatars';

export interface ISettingsAvatar {
  size?: Size | number;
}

export const SettingsAvatar: React.FC<ISettingsAvatar> = ({ size }) => {
  const initials = useUserInitials();
  const config = useSettingsConfig();
  const userId = useStoreValue($userId);
  const avatarSource = useAvatarImage(userId) || '';

  return (
    <div id="user-settings-popover">
      <UserSettingsPopover
        targetSize={size}
        avatarSource={avatarSource}
        initials={initials}
        popoverLinks={config}
      />
    </div>
  );
};
