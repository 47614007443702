import { Flex, Icon, NavSectionLink, Size } from '@tw/ui-components';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import PodFilterBar from 'routes/allShopsPinnedSections/PodFilterBar/PodFilterBar';

const podsRoutes = [
  {
    url: '/pods-view',
    label: 'Pods View',
    icon: () => (
      <div className="flex">
        <img src={'/pod-icon-wrapper.svg'} width={30} alt={''} />
      </div>
    ),
    header: () => <PodFilterBar />,
    hidden: true,
  },
  {
    label: 'Pod Settings',
    icon: () => <Icon name="settings" />,
    url: '/pod-settings',
    header: null,
  },
];

export const MainLinksSection = () => {
  const mainLinks = useMemo(
    () =>
      podsRoutes.map(({ url, label }, i) => (
        <WrappedLink key={`nav-section-link-${label}-${i}`} url={url} label={label} />
      )),
    [],
  );

  return <Flex direction="column">{mainLinks}</Flex>;
};

const WrappedLink = ({ url, label }) => {
  const { pathname } = useLocation();
  const isActive = useMemo(() => pathname.includes(url), [pathname, url]);

  return (
    <NavSectionLink
      label={label}
      isActive={isActive}
      style={{
        pl: 'xs',
        // TODO: This should be one of the acceptable parameters
        py: 5 as unknown as Size,
      }}
      as={(props) => <NavLink to={url} {...props} />}
    />
  );
};
