import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { DropdownOption, DropdownSection } from '../types';

export type UseDropdownLabelArgs = {
  options?: DropdownOption[];
  sections?: DropdownSection[];
  value: string;
};

/** Extracts the label for the dropdown based on the dropdown's options and sections */
export function useDropdownLabel({
  options = [],
  sections = [],
  value,
}: UseDropdownLabelArgs): React.ReactNode | string {
  const combinedOptionValues = useMemo(
    () => uniqBy(options.concat(sections.map((x) => x.options).flat()), 'value').filter(Boolean),
    [options, sections],
  );

  return useMemo(() => {
    const activeOption = combinedOptionValues.find((o) => o.value === value);
    return activeOption?.shortLabel || activeOption?.label || '';
  }, [combinedOptionValues, value]);
}
