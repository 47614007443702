import { AllMetricsAndPixelMetricsKeys } from '@tw/types';
import moment from '@tw/moment-cached/module/timezone';
import { LimitedGranularity } from 'types/general';

export function getGeneratedHours(day: string) {
  const hoursArray: string[] = [];
  let currentTime = moment(day).startOf('day');
  const isToday = moment(day).isSame(moment(), 'day');
  const endTime = isToday ? moment() : moment(day).endOf('day');
  while (currentTime <= endTime) {
    hoursArray.push(moment(currentTime).format('YYYY-MM-DDTHH'));
    currentTime = moment(currentTime).add(1, 'hour');
  }
  return hoursArray;
}

export function getGeneratedDates(
  startDate: string,
  endDate: string,
  granularity: 'hour' | 'day' | 'week' | 'month',
  format: string = 'YYYY-MM-DD',
) {
  if (granularity === 'hour') return getGeneratedHours(startDate);
  const dateArray: string[] = [];
  const momentGranularity = granularity === 'week' ? 'isoWeek' : granularity;
  let currentDate = moment(startDate).startOf(momentGranularity);
  const stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(format));
    currentDate = moment(currentDate).add(1, granularity);
  }
  return dateArray;
}

export type fillInBlanksOptions = {
  start: string;
  end: string;
  granularity: LimitedGranularity;
};

const emptyMetrics: Partial<AllMetricsAndPixelMetricsKeys> = {
  clicks: 0,
  conversionValue: 0,
  cpa: 0,
  cpc: 0,
  cpm: 0,
  ctr: 0,
  impressions: 0,
  purchases: 0,
  roas: 0,
  spend: 0,
  threeSecondVideoView: 0,
  pixelAov: 0,
  pixelConversionValue: 0,
  pixelCpa: 0,
  pixelNcAov: 0,
  pixelNcConversionValue: 0,
  pixelNcCpa: 0,
  pixelNcPurchases: 0,
  pixelPurchases: 0,
  pixelRoas: 0,
  aov: 0,
  pixelAtc: 0,
  pixelVisitors: 0,
  allConversionValue: 0,
  allConversions: 0,
  oneDayViewConversionValue: 0,
  outboundClicks: 0,
  thumbStopRatio: 0,
  totalVideoView: 0,
};

type ChartData = {
  metricsBreakdown: { date: string }[];
};

export function fillInBlanks<T extends Required<ChartData>>(
  original: T[] = [],
  params: fillInBlanksOptions,
  emptyObj: Record<string, any> = { metrics: emptyMetrics },
): any {
  const dateArray = getGeneratedDates(params.start, params.end, params.granularity);

  const withBlanks = original.map((creative) => {
    const metricsBreakdown: any[] = dateArray.map((date) => {
      let breakdown = creative.metricsBreakdown?.find((x) => x.date === date);
      if (!breakdown) {
        breakdown = { date, ...emptyObj };
      }
      return breakdown;
    });
    return {
      ...creative,
      metricsBreakdown,
    };
  });
  return withBlanks;
}
