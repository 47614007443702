import { reactNativePostMessage, reactNativeSaveData } from './reactNativePostMessage';

const ls = window.localStorage;

type CookiesWrapperObject = {
  get: (...set: Parameters<Storage['getItem']>) => ReturnType<Storage['getItem']>;
  set: (...set: Parameters<Storage['setItem']>) => ReturnType<Storage['setItem']>;
  remove: (...set: Parameters<Storage['removeItem']>) => ReturnType<Storage['setItem']>;
  setCookie: (name: string, value: string, days: number) => void;
  getCookie: (name: string) => string | null;
  eraseCookie: (name: string) => void;
};

let Cookies: CookiesWrapperObject = {} as any;
Cookies.get = (key: string) => {
  return ls.getItem(key);
};

Cookies.set = (key: string, val: string) => {
  if (key === 'currentShopId') {
    try {
      Cookies.setCookie(key, val, 365);
      reactNativeSaveData(key, val);
    } catch {}
  }
  return ls.setItem(key, val);
};

Cookies.remove = (key: string) => {
  if (['currentShopId', 'session'].includes(key)) {
    try {
      Cookies.eraseCookie(key);
      reactNativePostMessage('clearData', key);
    } catch {}
  }
  return ls.removeItem(key);
};

Cookies.setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

Cookies.getCookie = (name: string) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

Cookies.eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export default Cookies;
