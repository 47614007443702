import { $currentShopId, $mobyUserIds } from '$stores/$shop';
import { $mobySeatsCreditsExceeded, removeUsersFromMobySeats } from '$stores/willy/$credits';
import { useStoreValue } from '@tw/snipestate';
import { getDashPermsManager } from 'components/Willy/dashboardManagment/permissions-management/DashboardPermissionsManager';
import { useCallback, useMemo, useState, useSyncExternalStore } from 'react';
import { DowngradeCredits } from './DowngradeCredits';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $ffStore } from 'feature-flag-system';
import { Button, Flex, Modal, Text } from '@tw/ui-components';
import { toast } from 'react-toastify';

export const DowngradeMobyNotificationModal = () => {
  const mobyUserIds = useStoreValue($mobyUserIds);
  const { data: users } = useStoreValue(getDashPermsManager().$shopUsers);
  const [selectedMobyUsers, setSelectedMobyUSers] = useState<string[]>([]);
  const shopId = useStoreValue($currentShopId);
  const [loading, setLoading] = useState(false);

  const creditsToDowngrade = useSyncExternalStore(
    (cb) => $ffStore.get().addUpdateListener(cb),
    () => $ffStore.get().getConfigById(FeatureFlag.CHAT_DATA_SEATS_COUNT_FF).numericLimit || 0,
  );

  const mobySeatsCreditsExceededValue = useStoreValue($mobySeatsCreditsExceeded);

  const removeMobyUsers = useCallback(async () => {
    try {
      setLoading(true);
      const mobyUsersToRemove = mobyUserIds?.filter(
        (mobyUser) => !selectedMobyUsers.includes(mobyUser),
      );
      if (!mobyUsersToRemove || !shopId) return;
      await removeUsersFromMobySeats(shopId, mobyUsersToRemove);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      toast.error('Failed to remove moby users');
    }
  }, [mobyUserIds, selectedMobyUsers, shopId]);

  const onConfirm = useCallback(async () => {
    await removeMobyUsers();
    // onClose();
  }, [removeMobyUsers]);

  const title = useMemo(() => {
    if (creditsToDowngrade === 0) {
      return `You will no longer have seats for moby, those users will not have full Moby access`;
    }

    return (
      `Only ${creditsToDowngrade} seats are available. Please choose which users will be assigned seats.` +
      ` The remaining users will not have full Moby access.`
    );
  }, [creditsToDowngrade]);

  return (
    <Modal.Root opened={mobySeatsCreditsExceededValue} onClose={() => {}} size="lg" centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text fz="lg" fw={500}>
              Assign Moby Seats
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DowngradeCredits
            title={title}
            creditsToDowngrade={creditsToDowngrade}
            list={
              users
                ?.filter((user) => mobyUserIds?.includes(user.id))
                .map((user) => ({
                  id: user.id,
                  name: user.name,
                })) ?? []
            }
            selectedList={selectedMobyUsers}
            setSelectedList={setSelectedMobyUSers}
          />
        </Modal.Body>
        <Modal.Body bg="gray.1" p="md">
          <Flex justify="flex-end" gap="lg">
            <Button loading={loading} onClick={onConfirm}>
              Save
            </Button>
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
