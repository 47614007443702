const getExtension = (filename: string) => {
  var parts = filename.toString().split('.');
  return parts[parts.length - 1];
};

export const isVideo = (filename: string) => {
  let extention = getExtension(filename);
  if (extention.includes(' - Copy')) {
    extention = extention.replace(' - Copy', '');
  }
  switch (extention.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
  }
  return false;
};

export const isCreativeVideo = ({ name, assetType }) => {
  return isVideo(name || '') || assetType === 'video';
};
