import {
  ActionIcon,
  Anchor,
  Checkbox,
  Collapse,
  Flex,
  Grow,
  Icon,
  IconName,
  NumberInput,
  Popover,
  Select,
  Size,
  Text,
  TextInput,
  isDefined,
} from '@tw/ui-components';
import { MessageTypes, WillyMetric } from '../types/willyTypes';
import { useSortable } from '@dnd-kit/sortable';
import { useMemo, useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { valueFormats, valueFormatsArr } from '@tw/types';
import { capitalize } from 'lodash';
import { InfoPopover } from './InfoPopover';
import { ColorPickerPopover } from '../ColorPicker';
import { GRADIENT_CHART_COLORS } from 'constants/general';
import { IconPicker } from '../IconPicker';
import { WillySelect } from '../dashboardManagment/WillySelect';
import { onClickMetricActionsOptions } from './MetricsEditor';
import { ChartTypesDropdown } from '../widgetVisualizationTypes/ChartTypesDropdown';

type MetricEditorProps = {
  metric: WillyMetric;
  updateMetric: (metric: WillyMetric) => void;
  customize?: boolean;
  setAddActionToMetric: (metric: WillyMetric | null) => void;
  widgetType?: MessageTypes;
  stacked?: boolean;
  setStacked?: (val: boolean) => void;
};

const plotLineOptions = [
  { label: 'Mean', value: 'showMean' },
  { label: 'Mode', value: 'showMode' },
  { label: 'Median', value: 'showMedian' },
];

export const MetricEditor: React.FC<MetricEditorProps> = ({
  metric,
  customize,
  updateMetric,
  setAddActionToMetric,
  widgetType,
  stacked,
  setStacked,
}) => {
  const [openSettings, setOpenSettings] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: metric.key,
  });
  const [conditionalStyleValueType, setConditionalStyleValueType] = useState<
    'number' | 'boolean' | 'null'
  >('number');

  const style: React.CSSProperties = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? '100' : 'auto',
      opacity: isDragging ? 0.3 : 1,
      touchAction: 'manipulation',
    }),
    [isDragging, transform, transition],
  );

  return (
    <div style={style} className="flex items-center w-full gap-[8px]" ref={setNodeRef}>
      {!customize && (
        <div {...listeners} {...attributes} className="h-full flex items-center cursor-grab">
          <Icon name="dragger-handle-1" size={15} color="one.2" />
        </div>
      )}
      <Grow>
        <Flex
          direction="column"
          px="sm"
          py={'8px' as Size}
          bg="gray.1"
          borderRadius="20px"
          pos="relative"
        >
          <Flex justify="space-between" align="center">
            <Flex
              align="center"
              gap="xs"
              onClick={() => {
                if (!customize) return;
                setOpenSettings(!openSettings);
              }}
              cursor={customize ? 'pointer' : 'default'}
            >
              {customize && (
                <Icon name={openSettings ? 'chevron-down' : 'chevron-right-minor'} size={12} />
              )}
              <Text
                size="sm"
                weight={500}
                //contentEditable={!disableContentEditable}
                //suppressContentEditableWarning
              >
                {metric.name}
              </Text>
            </Flex>
            {!customize && (
              <ActionIcon
                icon="close"
                size="xs"
                iconSize={10}
                color="gray.4"
                variant="transparent"
                onClick={() => updateMetric({ ...metric, active: false })}
              />
            )}
          </Flex>
          <Collapse in={openSettings}>
            <Flex direction="column" gap="md" pt="sm">
              <TextInput
                label="Tooltip (Metric Description)"
                placeholder="Enter Description"
                value={metric.description}
                onChange={(e) => updateMetric({ ...metric, description: e })}
              />
              <Select
                allowDeselect={false}
                label="Format"
                value={metric.format}
                data={valueFormatsArr
                  .filter((f) =>
                    ['string', 'decimal', 'percent', 'currency', 'duration'].includes(f),
                  )
                  .map((f) => ({ label: capitalize(f), value: f }))}
                onChange={(v) => {
                  if (!isDefined(v)) return;
                  updateMetric({ ...metric, format: v as valueFormats });
                }}
              />
              {!metric.isDimension && (
                <Flex gap="md">
                  <Grow>
                    <TextInput
                      label={
                        <Flex align="center" gap="sm">
                          <Text weight={500} size="sm">
                            Min decimal places
                          </Text>
                          <InfoPopover description="Cannot be greater than maximum decimal places" />
                        </Flex>
                      }
                      type="number"
                      value={metric.minimumFractionDigits?.toString() || '0'}
                      onChange={(v) =>
                        updateMetric({
                          ...metric,
                          minimumFractionDigits: Math.min(+v, metric.toFixed ?? 0),
                        })
                      }
                    />
                  </Grow>
                  <Grow>
                    <TextInput
                      label={
                        <Flex align="center" gap="sm">
                          <Text weight={500} size="sm">
                            Max decimal places
                          </Text>
                          <InfoPopover description="Cannot be less than minimum decimal places" />
                        </Flex>
                      }
                      type="number"
                      value={metric.toFixed?.toString()}
                      onChange={(v) =>
                        updateMetric({
                          ...metric,
                          toFixed: Math.max(+v, metric.minimumFractionDigits ?? 0),
                        })
                      }
                    />
                  </Grow>
                </Flex>
              )}
              <Flex align="center" gap="xs">
                <Checkbox
                  label="Display as absolute value"
                  checked={metric.valueIsNegative}
                  onChange={(v) => {
                    updateMetric({ ...metric, valueIsNegative: v });
                  }}
                />
                <InfoPopover description="For negative metrics, the metric will be displayed as an absolute value without negative sign" />
              </Flex>
              <ColorPickerPopover
                label="Metric Color"
                labelSize="sm"
                labelWeight="500"
                activatorClassName="w-[20px] h-[20px] !rounded-[4px]"
                className="justify-between gap-[12px]"
                color={metric.color || GRADIENT_CHART_COLORS[0].start}
                onChange={(color) => {
                  updateMetric({
                    ...metric,
                    color,
                    colorName: `color_${color.slice(1)}`,
                  });
                }}
                showColorValue
              />
              <NumberInput
                label="Opacity"
                size="sm"
                value={metric.opacity?.toString() || ''}
                onChange={(v) => updateMetric({ ...metric, opacity: +v })}
                step={0.01}
                min={0}
                max={1}
              />
              <Flex justify="space-between">
                <Text size="sm" weight={500}>
                  Icon
                </Text>
                <IconPicker
                  selectedIcon={metric.icon}
                  onChange={(icon) => {
                    updateMetric({ ...metric, icon });
                  }}
                  activatorSize="sm"
                />
              </Flex>
              {widgetType === 'chart' && (
                <Flex justify="space-between" align="center" cursor="pointer">
                  <Text size="sm" weight={500}>
                    Plot Line
                  </Text>
                  <Popover position="bottom-end">
                    <Popover.Target>
                      <Flex align="center" gap="xs">
                        <Text size="sm" weight={500}>
                          {plotLineOptions
                            .filter((o) => metric[o.value])
                            .map((o) => o.label)
                            .join(', ') || 'None'}
                        </Text>
                        <Icon name="chevron-down" size={12} />
                      </Flex>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Flex direction="column" gap="sm">
                        {plotLineOptions.map((o) => (
                          <Checkbox
                            key={o.value}
                            label={o.label}
                            checked={metric[o.value]}
                            onChange={() => {
                              updateMetric({
                                ...metric,
                                [o.value]: !metric[o.value],
                              });
                            }}
                          />
                        ))}
                      </Flex>
                    </Popover.Dropdown>
                  </Popover>
                </Flex>
              )}
              {widgetType === 'chart' && metric.chartType === 'line' && (
                <Flex justify="space-between">
                  <Text size="sm" weight={500}>
                    Line Style
                  </Text>
                  <WillySelect
                    targetClassName="text-[14px]"
                    value={metric.lineStyle || 'solid'}
                    onChange={(v) =>
                      updateMetric({ ...metric, lineStyle: v as WillyMetric['lineStyle'] })
                    }
                    data={[
                      { label: 'Solid', value: 'solid' },
                      { label: 'Dashed', value: 'dashed' },
                      { label: 'Dotted', value: 'dotted' },
                    ]}
                  />
                </Flex>
              )}
              <Flex justify="space-between">
                <Text size="sm" weight={500}>
                  Conditional Style
                </Text>
                <WillySelect
                  targetClassName="text-[14px]"
                  value={metric.conditionalStyleType || 'none'}
                  onChange={(e) => {
                    updateMetric({
                      ...metric,
                      conditionalStyleType: e as 'none' | 'singleValue' | 'scale',
                    });
                  }}
                  data={[
                    { label: 'None', value: 'none' },
                    { label: 'Single Value', value: 'singleValue' },
                    { label: 'Heat Map', value: 'scale' },
                  ]}
                />
              </Flex>
              {metric.conditionalStyleType === 'singleValue' && (
                <Flex gap="md">
                  <Select
                    label="Condition"
                    size="xs"
                    data={[
                      {
                        label: `Is ${metric.valueIsNegative ? 'less' : 'greater'} than`,
                        value: 'number',
                      },
                      {
                        label: 'Is true',
                        value: 'boolean',
                      },
                      {
                        label: 'Is not empty',
                        value: 'null',
                      },
                    ]}
                    allowDeselect={false}
                    value={conditionalStyleValueType}
                    onChange={(v) => {
                      if (v === null) {
                        return;
                      }
                      setConditionalStyleValueType(v as 'number' | 'boolean' | 'null');
                      if (v === 'boolean') {
                        updateMetric({
                          ...metric,
                          conditionalStyleValue: 'true',
                        });
                      } else if (v === 'null') {
                        updateMetric({
                          ...metric,
                          conditionalStyleValue: 'null',
                        });
                      } else {
                        updateMetric({
                          ...metric,
                          conditionalStyleValue: '0',
                        });
                      }
                    }}
                  />
                  {conditionalStyleValueType === 'number' && (
                    <TextInput
                      label="Value"
                      size="xs"
                      type="text"
                      value={metric.conditionalStyleValue?.toString() || '0'}
                      onChange={(v) => {
                        updateMetric({
                          ...metric,
                          conditionalStyleValue: v,
                        });
                      }}
                    />
                  )}
                </Flex>
              )}
              {metric.conditionalStyleType === 'scale' && (
                <ColorPickerPopover
                  label="Metric Color"
                  labelSize="sm"
                  activatorClassName="w-[18px] h-[18px] !rounded-[4px]"
                  className="justify-between gap-[12px]"
                  color={metric.conditionalStyleColor || GRADIENT_CHART_COLORS[0].start}
                  onChange={(color) => {
                    updateMetric({
                      ...metric,
                      conditionalStyleColor: color,
                    });
                  }}
                  showColorValue
                />
              )}
              <Flex justify="space-between">
                <Text size="sm" weight={500}>
                  Action
                </Text>
                <Anchor
                  as={'button'}
                  underline="always"
                  size="sm"
                  onClick={() => setAddActionToMetric(metric)}
                  leftIcon={
                    !metric.onClickAction || metric.onClickAction === 'none' ? (
                      <Icon name="plus" width={10} color="one.5" />
                    ) : undefined
                  }
                  c="one.5"
                >
                  {metric.onClickAction && metric.onClickAction !== 'none'
                    ? onClickMetricActionsOptions.find((m) => m.value === metric.onClickAction)
                        ?.label
                    : 'Add new Action'}
                </Anchor>
              </Flex>
            </Flex>
          </Collapse>
        </Flex>
      </Grow>
      {widgetType === 'chart' && !customize && metric.chartType && (
        <ChartTypesDropdown
          activator={(icon: IconName) => (
            <Flex
              bg="gray.1"
              borderRadius="100px"
              px="sm"
              py={'10px' as Size}
              cursor="pointer"
              justify="space-between"
              align="center"
              gap="xs"
            >
              <Icon name={icon} />
              <Icon name="chevron-down" color="gray.4" size={10} />
            </Flex>
          )}
          chartType={metric.chartType === 'bar' && stacked ? 'stacked-bar' : metric.chartType}
          setChartType={(chartType) => {
            if (chartType === 'bar' && stacked) {
              setStacked?.(false);
            }
            if (chartType === 'stacked-bar') {
              setStacked?.(true);
              chartType = 'bar';
            }
            updateMetric({ ...metric, chartType });
          }}
        />
      )}
    </div>
  );
};
