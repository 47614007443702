import { Button, ButtonGroup, Modal, TextField } from '@shopify/polaris';
import { applySetLeadTime } from 'ducks/productAnalytics';
import { useAppDispatch } from 'index';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

export const DefaultLeadTimeModal: React.FC<{ showModal: boolean; onClose }> = ({
  showModal,
  onClose,
}) => {
  const [value, setValue] = useState('');
  const defaultValue = useSelector(
    (state: RootState) => state.productAnalytics.forecastMetadata.defaultValue,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue(defaultValue?.toString());
  }, [defaultValue]);

  const handleSave = () => {
    dispatch(applySetLeadTime(value));
    onClose();
  };

  return (
    <Modal small open={showModal} onClose={onClose} title="Set Default Lead Time">
      <Modal.Section>
        <div className="creative-cell flex flex-col space-y-3">
          <p className="flex justify-left align-left ">Set default lead time for all products</p>
          <p className="flex justify-left align-left ">
            You can always change lead time for individual product sku's
          </p>
          <div className="flex justify-center align-center p-4">
            <TextField
              label=""
              type="number"
              suffix="Days"
              autoComplete={'off'}
              min={0}
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
      </Modal.Section>
      <Modal.Section>
        <div className="creative-cell flex flex-col">
          <ButtonGroup>
            <Button id="att-product-analytics-default-led-time-cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              primary
              id="att-product-analytics-default-led-time-save"
              onClick={handleSave}
              disabled={!value || value === ''}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </Modal.Section>
    </Modal>
  );
};
