import axiosInstance from 'utils/axiosInstance';
import {
  DayOfWeek,
  DialectWithBoth,
  ScheduleItem,
  ScheduleSequenceParams,
} from '../types/willyTypes';
import { getScheduleCollection } from './willyUtils';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';
import firebase from 'firebase/compat/app';

type CreateScheduleParams = {
  sequenceId: string;
  name: string;
  days: DayOfWeek[];
  hours: number[];
  interval: string | null;
  userId: string;
  shopId: string;
  currency: string;
  timezone: string;
  scheduleIdToUpdate?: string;
  additionalShopIds: string[];
  dialect: DialectWithBoth;
  industry: IndustryTypes;
};

export async function createSchedule({
  sequenceId,
  name,
  days,
  hours,
  interval,
  userId,
  shopId,
  currency,
  timezone,
  scheduleIdToUpdate,
  additionalShopIds,
  dialect,
  industry,
}: CreateScheduleParams) {
  const scheduleId = scheduleIdToUpdate || getScheduleCollection(userId, shopId).doc().id;
  let originalSchedule: firebase.firestore.DocumentSnapshot<ScheduleItem> | null = null;
  if (scheduleIdToUpdate) {
    originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleIdToUpdate).get();
  }

  const item: ScheduleItem = {
    id: scheduleId,
    sequenceId,
    userId,
    shopId,
    name,
    days,
    hours,
    interval,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  await getScheduleCollection(userId, shopId).doc(scheduleId).set(item, {
    merge: true,
  });

  try {
    const method = scheduleIdToUpdate ? axiosInstance.put : axiosInstance.post;
    const { data } = await method<any, any, ScheduleSequenceParams>(
      `/v2/sequences/schedules${scheduleIdToUpdate ? `/${scheduleIdToUpdate}` : ''}`,
      {
        shopId,
        sequenceId,
        scheduleId,
        currency,
        schedule: [
          {
            dayOfWeek: days,
            hour: hours,
          },
        ],
        interval,
        timezone,
        userId,
        additionalShopIds,
        dialect,
        industry,
      },
    );
  } catch (e) {
    const data = originalSchedule?.data();
    if (originalSchedule?.exists && !!data) {
      await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    }

    throw e;
  }

  return scheduleId;
}

export async function resumeSchedule(scheduleId: string, shopId: string, userId: string) {
  const originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleId).get();
  if (!originalSchedule.exists) {
    return;
  }
  try {
    await getScheduleCollection(userId, shopId)
      .doc(scheduleId)
      .set({ status: 'scheduled' }, { merge: true });

    await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/resume`, {
      shopId,
      userId,
    });
  } catch (e) {
    const data = originalSchedule.data();
    if (originalSchedule.exists && !!data) {
      await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    }

    throw e;
  }
}

export async function pauseSchedule(scheduleId: string, shopId: string, userId: string) {
  const originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleId).get();
  if (!originalSchedule.exists) {
    return;
  }

  try {
    await getScheduleCollection(userId, shopId)
      .doc(scheduleId)
      .set({ status: 'paused' }, { merge: true });
    await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/pause`, {
      shopId,
      userId,
    });
  } catch (e) {
    const data = originalSchedule.data();
    if (originalSchedule.exists && !!data) {
      await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    }

    throw e;
  }
}

export async function deleteSchedule(scheduleId: string, shopId: string, userId: string) {
  const originalSchedule = await getScheduleCollection(userId, shopId).doc(scheduleId).get();
  if (!originalSchedule.exists) {
    return;
  }

  try {
    await getScheduleCollection(userId, shopId).doc(scheduleId).delete();
    await axiosInstance.delete(`/v2/sequences/schedules/${scheduleId}`, {
      data: {
        shopId,
        userId,
      },
    });
  } catch (e) {
    // we still want to remove the document from firestore even if the request to the server fails
    // because maybe it failed because the schedule was already deleted from temporal
    // (I couldn't find a way to check if a schedule exists in temporal without making the server to throw an error)
    // const data = originalSchedule.data();
    // if (originalSchedule.exists && !!data) {
    //   await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    // }
    // throw e;
  }
}

export async function executeSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/execute`, {
    shopId,
    userId,
  });
}

export async function getScheduleInfo(scheduleId: string, shopId: string, userId: string) {
  const { data } = await axiosInstance.post(`/v2/sequences/schedules/get-schedule/${scheduleId}`, {
    shopId,
    userId,
  });
  return data;
}
