import { AppShell, Box, Button, Flex, Popover, SegmentedControl, Stack } from '@tw/ui-components';
import { TopSection as StoreSwitcher } from '../../blocks/NewSearchStorePopover';
import { MainLinksSection } from './MainLinksSection';
import { SettingsAvatar } from '../../blocks/SettingsAvatar';
import { SettingsTogglerSection } from '../../common/SettingsTogglerSection';
import { ToggleTW3Section } from '../../common/ToggleTW3Section';
import { $canToggleBetweenVersions } from '$stores/$v3_0_Enabled';
import { TOP_BAR_BORDER_VAR } from 'components/TopBar/constants';
import { GetHelp } from '../../blocks/GetHelp';
import { $changingDialect, $dialect, $isAdminClaim, changeDialect } from '$stores/$user';
import { Dialect } from 'components/Willy/types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { ControlAdminButton } from 'components/UserProfileManagment/User/controlAdminButton';
import { CreateDashSection } from './CreateDashboardButton';
import { FREE_TRIAL_BANNER_HEIGHT } from '$stores/willy/$upgradePopupManager';
import { useShowFreeTrialBanner } from 'components/Willy/UpgradePlan/FreeTrialBanner';
import { useIsNavWideStyle } from '$stores/nav-config-stores';

export const AlanNav = () => {
  const canToggleBetweenVersions = useStoreValue($canToggleBetweenVersions);
  const isWide = useIsNavWideStyle();
  const isAdminClaim = useStoreValue($isAdminClaim);
  const dialect = useStoreValue($dialect);
  const changingDialect = useStoreValue($changingDialect);
  const showFreeTrialBanner = useShowFreeTrialBanner();

  return (
    <Flex w="100%" p={0} overflow="hidden">
      <Box w={75} maw={75} miw={75} overflow="auto" withScrollbar={false}>
        <Stack
          w="100%"
          h={!showFreeTrialBanner ? '100vh' : `calc(100vh - ${FREE_TRIAL_BANNER_HEIGHT}px)`}
        >
          <AppShell.Section px="lg" pt="xs" borderBottom={TOP_BAR_BORDER_VAR}>
            <Flex justify="center" align="center" mih={55}>
              <StoreSwitcher iconOnly showArrows={false} />
            </Flex>
          </AppShell.Section>
          <AppShell.Section>
            <CreateDashSection />
          </AppShell.Section>

          <AppShell.Section grow px="md" pt="md">
            <MainLinksSection />
          </AppShell.Section>

          <AppShell.Section px={isWide ? 'md' : undefined} pt="md">
            <SettingsTogglerSection showTooltip={false} mode="narrow" />
          </AppShell.Section>

          <AppShell.Section px={isWide ? 'md' : undefined} py="xs">
            <GetHelp />
          </AppShell.Section>

          <AppShell.Section px={isWide ? 'md' : undefined} pb="xs">
            <Flex justify="center" align="center">
              <SettingsAvatar size={isWide ? undefined : 28} />
            </Flex>
          </AppShell.Section>

          {isAdminClaim && (
            <AppShell.Section px={isWide ? 'md' : undefined} pb="xs">
              <ControlAdminButton />
            </AppShell.Section>
          )}

          {isAdminClaim && (
            <AppShell.Section px={isWide ? 'md' : undefined} pb="lg">
              <Popover>
                <Popover.Target>
                  <Flex justify="center" align="center">
                    <Button
                      loading={changingDialect}
                      variant="white"
                      size="xs"
                      className="flex-shrink-0"
                    >
                      {dialect === 'clickhouse' ? 'CH' : 'BQ'}
                    </Button>
                  </Flex>
                </Popover.Target>
                <Popover.Dropdown>
                  <Flex direction="column" gap="sm" p="md">
                    <SegmentedControl
                      disabled={changingDialect}
                      data={[
                        { label: 'BigQuery', value: 'bigquery' },
                        { label: 'ClickHouse', value: 'clickhouse' },
                      ]}
                      value={dialect || 'bigquery'}
                      onChange={async (value) => await changeDialect(value as Dialect)}
                    />
                  </Flex>
                </Popover.Dropdown>
              </Popover>
            </AppShell.Section>
          )}

          {canToggleBetweenVersions ? (
            <AppShell.Section px={isWide ? 'md' : undefined} pb="lg">
              <ToggleTW3Section hideLabel />
            </AppShell.Section>
          ) : (
            <Box />
          )}
        </Stack>
      </Box>
    </Flex>
  );
};
