/**
 * @fileoverview The point of this file is to manage the state of the status change confirmation modal,
 * but it also needs to manage the session of the user.  If the user chooses to stop seeing the confirmation
 * modal, this will only last for 1/2 an hour.  After 1/2 an hour they'll see the modal if they try turning
 * on or off an ad, campaign, etc.  File also does this tracking after user reloads the page.  We start
 * counting from last update time saved in local storage before setting to false if disabled is true.
 */

import { $store } from '@tw/snipestate';
import { StatusConfirmModalStore } from './types';

//
// CONSTANTS
//
const LS_AD_STATUS_CHANGE_MODAL_DISABLED = 'pixel_ad_status_change_modal_disabled';
const LS_LAST_UPDATE = 'pixel_ads_status_change_modal_last_update';
const THIRTY_MINUTES = 1000 * 60 * 30;
const INITIAL_LAST_UPDATE = Number(localStorage.getItem(LS_LAST_UPDATE));

//
// UTILS
//
const getInitialDisabled = () => {
  const disabled = localStorage.getItem(LS_AD_STATUS_CHANGE_MODAL_DISABLED) === 'true';
  if (INITIAL_LAST_UPDATE + THIRTY_MINUTES < Date.now()) return false;
  return disabled;
};

//
// STORE + utils to use store and subscriptions to it
//
export const $statusConfirmModal = $store<StatusConfirmModalStore>({
  opened: false,
  message: '',
  action: undefined,
  disabled: getInitialDisabled(),
});

const enableModal = () => {
  $statusConfirmModal.set((x) => ({ ...x, disabled: false }));
};

//
// ACTIONS
//
export const setConfirmModal = (message: React.ReactNode, action: () => any) => {
  if ($statusConfirmModal.get().disabled) {
    action();
    return;
  }

  $statusConfirmModal.set((x) => ({
    opened: true,
    message: message || x.message,
    action,
  }));
};

//
// SUBSCRIPTIONS
//
/** keep track in localStorage */
$statusConfirmModal.subscribe(({ disabled = false }) => {
  localStorage.setItem(LS_AD_STATUS_CHANGE_MODAL_DISABLED, disabled.toString());
  localStorage.setItem(LS_LAST_UPDATE, Date.now().toString());
});

/** keep track of session */
let timeout = setTimeout(
  () => {
    if ($statusConfirmModal.get().disabled !== true) {
      clearTimeout(timeout);
    } else {
      enableModal();
    }
  },
  INITIAL_LAST_UPDATE + THIRTY_MINUTES - Date.now(),
);

$statusConfirmModal.subscribe(({ disabled }) => {
  // The whole point of doing this is to re-enable the modal after the
  // session finishes,so don't do anything if the modal is already enabled.
  if (!disabled) return;

  clearTimeout(timeout);

  timeout = setTimeout(enableModal, THIRTY_MINUTES);
});
