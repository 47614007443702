import { Checkbox, Flex, Icon, Popover, Select, Text, TextInput } from '@tw/ui-components';
import { MessageTypes, NlqResponse, WillyMetric } from '../types/willyTypes';
import { useCallback, useMemo, useState } from 'react';
import { MetricsEditor } from './MetricsEditor';
import { InfoPopover } from './InfoPopover';

type WidgetDataEditorProps = {
  metrics: WillyMetric[];
  updateMetrics: (id: string, data: WillyMetric[]) => void;
  queryId?: string;
  widgetType: MessageTypes;
  dimension?: string;
  setDimension?: (val: string) => void;
  rawData?: NlqResponse;
  stacked?: boolean;
  setStacked?: (val: boolean) => void;
};

export const WidgetDataEditor: React.FC<WidgetDataEditorProps> = ({
  metrics,
  updateMetrics,
  queryId,
  widgetType,
  dimension,
  setDimension,
  rawData,
  stacked = false,
  setStacked,
}) => {
  const [search, setSearch] = useState('');
  const selectedMetricsNumber = useMemo(() => metrics.filter((m) => m.active).length, [metrics]);
  const filteredMetrics = useMemo(
    () => metrics.filter((m) => m.name.toLowerCase().includes(search.toLowerCase())),
    [metrics, search],
  );

  const handleSelectChange = useCallback(
    (metric: WillyMetric) => {
      if (!queryId) {
        return;
      }
      const newMetrics = metrics.map((m) => {
        if (m.key === metric.key) {
          return {
            ...m,
            active: !m.active,
          };
        }
        return m;
      });
      updateMetrics(queryId, newMetrics);
    },
    [queryId, metrics, updateMetrics],
  );

  return (
    <Flex direction="column" gap="lg" px="lg" pt="md">
      <Popover width="target">
        <Popover.Target>
          <TextInput
            label={
              <Flex align="center" gap="sm" pb="xs">
                <Text size="md" weight={500} color="gray.6">
                  Metrics Selector
                </Text>
                <InfoPopover description="Select which metrics to display" />
              </Flex>
            }
            value={`${selectedMetricsNumber} out of ${metrics.length} selected`}
            trailingIcon={<Icon name="chevron-down" size={12} />}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <Flex direction="column" gap="md">
            <TextInput
              placeholder="Search"
              leadingIcon="search-major"
              value={search}
              onChange={setSearch}
            />
            <Flex direction="column" gap="xs" mah={250} overflow="scroll">
              {filteredMetrics.map((metric) => (
                <Checkbox
                  key={metric.key}
                  checked={metric.active}
                  label={metric.name}
                  onChange={() => handleSelectChange(metric)}
                />
              ))}
            </Flex>
          </Flex>
        </Popover.Dropdown>
      </Popover>
      {widgetType === 'chart' && (
        <Select
          label="X Axis"
          allowDeselect={false}
          value={dimension}
          onChange={(v) => {
            if (typeof v !== 'string') {
              return;
            }
            setDimension?.(v);
          }}
          data={[
            {
              value: '',
              label: 'Auto',
            },
          ].concat((rawData?.dataColumns?.x || []).map((x) => ({ value: x, label: x })))}
        />
      )}
      <MetricsEditor
        metrics={metrics}
        updateMetrics={updateMetrics}
        queryId={queryId}
        widgetType={widgetType}
        stacked={stacked}
        setStacked={setStacked}
      />
    </Flex>
  );
};
