import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { SourceTypesArr, SourceTypesWithExtra } from 'types/services';

export const useAttributionActivePage = () => {
  const match1 = useMatch('/attribution/:sourceId/:channelId');
  const match2 = useMatch('/dashboards/attribution/:sourceId/:channelId');
  const match = match1 || match2;

  const attributionPage: SourceTypesWithExtra | '' = useMemo(() => {
    if (!match) {
      return '';
    }

    const { pathname } = match;
    const pathParts = pathname.split('/');
    const page = pathParts[pathParts.length - 2] as SourceTypesWithExtra;

    if (!SourceTypesArr.includes(page as any)) {
      return '';
    }

    return page;
  }, [match]);

  return attributionPage;
};
