import { useContext } from 'react';
import { Pagination } from '@shopify/polaris';
import { ContentHubContext } from '../ContentHub';
import AdMediaCards from './AdMediaCards';
import AdMediaTable from './AdMediaTable';
import { Loader } from '@tw/ui-components';

type AdMediaCollectionProps = {
  loading: boolean;
  media: any[];
  page: number;
  hasNextPage: boolean;
  noData: boolean;
  setPage: (p) => void;
};

const AdMediaCollection: React.FC<AdMediaCollectionProps> = ({
  loading,
  media,
  page,
  hasNextPage,
  noData,
  setPage,
}) => {
  const { displayMode } = useContext(ContentHubContext);
  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      )}
      {!loading && (noData || !media.length) && (
        <div className="flex justify-center items-center h-full">
          <p className="text-center">No data available</p>
        </div>
      )}
      {!loading && displayMode === 'grid' && <AdMediaCards media={media} />}
      {!loading && displayMode === 'table' && <AdMediaTable media={media} />}
      <div className="flex items-center mt-auto justify-center">
        <Pagination
          onNext={() => setPage((p) => p + 1)}
          onPrevious={() => setPage((p) => p - 1)}
          hasPrevious={page > 1}
          hasNext={hasNextPage}
          label={`Page ${page}`}
        />
      </div>
    </>
  );
};

export default AdMediaCollection;
