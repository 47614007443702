import { useEffect, useState } from 'react';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { IntegrationServiceId } from '../types/IntegrationServiceId';

let shopRef: firebase.firestore.DocumentData | undefined;
let shop: string = '';

export const useConnectedAccounts = (shopDomain: string, serviceId: IntegrationServiceId) => {
  const [adAccounts, setAdAccounts] = useState<any>({});
  const [shopServices, setShopServices] = useState<any>();
  const [attributionWindow, setAttributionWindow] = useState(fbAttributionWindows[0]);

  useEffect(() => {
    if (!shopDomain || shop === shopDomain) return;
    shop = shopDomain;

    firebase
      .firestore()
      .collection('shops')
      .doc(shopDomain)
      .get()
      .then((sr) => (shopRef = sr));
  }, [shopDomain]);

  useEffect(() => {
    if (!shopRef) return;

    return shopRef.ref.onSnapshot(async (shopDoc) => {
      const data = shopDoc?.data();
      if (!data) return;

      if (!_.isEqual(shopServices, data)) setShopServices(data);
      setAdAccounts(getAccountDataPerService(serviceId, data));

      if (serviceId === 'facebook-ads') {
        const newSelectedAttrWindows =
          fbAttributionWindows.find((x) => x.value === data.facebookAttributionWindows) ??
          fbAttributionWindows[0];
        setAttributionWindow(newSelectedAttrWindows);
      }
    });
  }, [shopRef, serviceId]);

  return { adAccounts, attributionWindow, shopServices };
};

const fbAttributionWindows = [
  { value: '7d_click,1d_view', label: '7-Day Click,1-Day View' },
  {
    value: '1d_click',
    label: '1-Day Click',
  },
  {
    value: '1d_click,1d_view',
    label: '1-Day Click,1-Day View',
  },
  { value: '7d_click', label: '7-Day Click' },
];

const getAccountDataPerService = (serviceId: string, data: any): any[] | null => {
  const accountsMap: { [key in IntegrationServiceId]: () => any[] } = {
    amazon: () => {
      const amazonData: any = [];
      const info = data.amazon?.sellerAccounts;
      if (!info) return amazonData;

      for (const id in info) {
        const { marketplaceIds } = info[id];
        amazonData.push({ id, marketplaceIds });
      }
      return amazonData;
    },
    'twitter-ads': () => {
      const twitterData: any = [];
      const twitterAccounts = data.twitterAccounts;
      if (!twitterAccounts) return twitterData;
      for (const id in twitterAccounts) {
        twitterData.push(twitterAccounts[id]);
      }
      return twitterData;
    },
    'facebook-ads': () => data.facebookAccounts,
    'google-ads': () => data.googleAdsAccounts,
    'google-analytics': () => data.googleAnalyticsAccounts,
    'pinterest-ads': () => data.pinterestAccounts,
    'tiktok-ads': () => data.tiktokAccounts,
    'snapchat-ads': () => data.snapchatAccounts,
  };
  if (!accountsMap[serviceId]) return null;
  return accountsMap[serviceId]();
};
