import { BillingInterval, subscriptionType } from '@tw/types/module/services/subscription-manager';
import { Flex, Switch, Text } from '@tw/ui-components';
import { FC } from 'react';

export type ContractTypeAndBillingInterval = {
  contractType: subscriptionType;
  billingInterval: BillingInterval;
};

const contractTypeLabelMap: Partial<Record<subscriptionType, string | JSX.Element>> = {
  '12 Month Contract': 'Annual, billed monthly',
  Annual: (
    <Text>
      Annual, prepaid <Text color="one.5" as="span">{`(-15%)`}</Text>
    </Text>
  ),
};

type BillingIntervalSwitcherProps = {
  contractTypes: ContractTypeAndBillingInterval[];
  selectedType: ContractTypeAndBillingInterval;
  setSelectedType: (selectedType: ContractTypeAndBillingInterval) => void;
};
export const BillingIntervalSwitcher: FC<BillingIntervalSwitcherProps> = ({
  contractTypes,
  selectedType,
  setSelectedType,
}) => {
  const label = (contractType: subscriptionType) =>
    contractTypeLabelMap[contractType] ? contractTypeLabelMap[contractType] : contractType;

  return contractTypes.length === 2 ? (
    <Flex align="center" gap="sm">
      <Text>{label(contractTypes[0].contractType)}</Text>
      <Switch
        onChange={() =>
          setSelectedType(selectedType === contractTypes[0] ? contractTypes[1] : contractTypes[0])
        }
        checked={selectedType === contractTypes[1]}
        offColor="one.5"
        onColor="one.5"
      />
      <Text>{label(contractTypes[1].contractType)}</Text>
    </Flex>
  ) : null;
};
