import allServices from 'constants/services';
import { startCase, toLower } from 'lodash';
import moment from '@tw/moment-cached';
import { ClientActivity } from './Types';
import { Activity } from '@tw/types/module/types/ActivityFeed';
import { formatNumber } from 'utils/formatNumber';
import { allActivities } from '@tw/constants/module/activityFeed';

export const transformActivityToClientActivity = (
  activity: Activity,
  currency: string,
): ClientActivity => {
  const {
    field,
    id,
    serviceId,
    date,
    entity,
    entityId,
    entityName,
    type,
    from,
    to,
    annotation,
    title,
    description,
    activityKey,
    author,
    shopId,
  } = activity;
  const activityFromList = allActivities.find((x) => x.id === activityKey);
  const activityDescription = activityFromList?.description
    ? activityFromList.description(from, to, entityName ?? entityId, currency)
    : description
      ? description
      : type === 'update'
        ? `${entityName ?? entityId}'s ${field
            ?.split('_')
            .join(' ')} changed from ${formatChangeValue(from, currency)} to ${formatChangeValue(
            to,
            currency,
          )}`
        : `${entityName ?? entityId} ${type}d`;
  return {
    id: id,
    serviceId: serviceId,
    Icon: allServices[serviceId]?.icon,
    date: moment(date),
    entity: entity,
    title: title ? title : startCase(toLower(`${entity} ${type}d`)),
    description: activityDescription,
    field: field,
    annotation: annotation ?? false,
    type: type,
    author: author,
    shopId: shopId,
    entityName: entityName,
  };
};

const formatChangeValue = (change: any, currency: string) => {
  if (!isNaN(change) && !isNaN(parseFloat(change))) {
    return formatNumber(+change, {
      currency: currency,
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else return change;
};
