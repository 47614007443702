import { services } from '@tw/types/module/services';
import { shuffle, take } from 'lodash';
import { useMemo, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { useAppSelector } from 'reducers/RootType';
import { providers } from 'ducks/shopIntegrations';
import { QUESTIONS, INPUT_SEARCH_MAX_LENGTH } from '../constants';

export type Suggestion = {
  title: string;
  description?: string;
  loading?: boolean;
  width?: string;
};

export function useSuggestions({ question = '' }: { question: string }) {
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [suggestedByAi, setSuggestedByAi] = useState<Suggestion[]>([]);
  const [cachedValue, setCachedValue] = useState<string>('');

  const hasPixelInstalled = useAppSelector((state) => state.hasPixelInstalled);
  const providersStatus = useAppSelector(providers);

  const disconnectedDataProviders = useMemo(() => {
    return (
      Object.entries(providersStatus)
        ?.filter(([key, val]: [string, any]) => {
          const isConnected = val?.isConnected;
          const isDataType = val?.providerType === 'data';
          const isNotBeta = !val?.isBeta;
          const hasTokenFuncOrSensory = services[key].getAccessToken || val?.isSensory;

          return !isConnected && isDataType && isNotBeta && hasTokenFuncOrSensory;
        })
        ?.map(([key]) => key) ?? []
    );
  }, [providersStatus]);

  const recommendedQuestions = useMemo(
    () =>
      shuffle(
        Object.keys(QUESTIONS)
          .map((k) => !disconnectedDataProviders.includes(k) && QUESTIONS[k])
          .flat(1)
          .filter((q) => !!q),
      ),
    [disconnectedDataProviders],
  );

  const connectionQuestions = useMemo(() => {
    const suggestions: Suggestion[] = [];

    if (!hasPixelInstalled) {
      suggestions.push({
        title: 'Install pixel on my website',
        description: 'Install pixel to track your website',
      });
    }

    for (const provider of disconnectedDataProviders) {
      const name = services[provider].name;
      suggestions.push({
        title: `Connect ${name}`,
        description: `Integrate ${name} with your account`,
      });
    }

    return suggestions;
  }, [hasPixelInstalled, disconnectedDataProviders]);

  const connectedServices = useMemo(() => {
    return Object.keys(providersStatus)?.filter(
      (key) => providersStatus[key]?.isConnected && providersStatus[key]?.providerType === 'data',
    );
  }, [providersStatus]);

  const dataQuestions = useMemo(() => {
    const suggestions: Suggestion[] = [];

    if (hasPixelInstalled) {
      suggestions.push({
        title: 'Give me my Blended ROAS yesterday',
        description: 'We combine your advertising data with your sales data to give you a ROAS',
      });

      suggestions.push({
        title: 'What is my NC-ROAS for last week?',
        description: 'Monitor your data with our first party attribution data',
      });
    }

    const provider = take(connectedServices, 1)[0];
    const name = services[provider]?.name;

    if (!!provider) {
      suggestions.push({
        title: `Show ${name} NC-AOV in the last month daily?`,
        description: 'Get your average order value for new customers',
      });
      suggestions.push({
        title: 'Create me a daily report called "Daily performance"',
        description: 'We will help you to create a report straight from your dashboards',
      });
    }

    return suggestions;
  }, [connectedServices, hasPixelInstalled]);

  const suggestions = useMemo(() => {
    return (
      suggestedByAi.length > 0
        ? suggestedByAi
        : [
            ...recommendedQuestions,
            ...dataQuestions,
            // ...connectionQuestions
          ]
    )
      .map((v) => ({
        ...{ title: v.title ?? v },
        ...(v.description && { description: v.description }),
      }))
      .filter((v) => v.title);
  }, [dataQuestions, recommendedQuestions, suggestedByAi]);

  const getRelevantQuestions = async (inputValue: string) => {
    function resetValues() {
      setCachedValue('');
    }

    if (inputValue.length <= 0) {
      resetValues();
      return;
    }

    if (
      inputValue.split(' ').length >= INPUT_SEARCH_MAX_LENGTH ||
      cachedValue?.trim() === inputValue.trim() ||
      suggestions.find((s) => s.title === inputValue.trim())
    ) {
      return;
    }

    try {
      setLoadingSuggestions(true);
      const { data } = await axiosInstance.post<{ questions: string[] }>(
        '/v2/willy/get-relevant-questions',
        {
          question: inputValue,
        },
      );
      const { questions } = data;
      if (questions.length === 0) {
        resetValues();
        return;
      }

      setSuggestedByAi(questions.filter((v) => v !== 'first order.').map((q) => ({ title: q })));
      setCachedValue(inputValue.trim());
    } catch (e) {
      // console.error(e);
      resetValues();
    } finally {
      setLoadingSuggestions(false);
    }
  };

  return { suggestions, loadingSuggestions, getRelevantQuestions };
}
