import { MobileCancelMajor } from '@shopify/polaris-icons';
import CreativeCard from 'components/CreativesCockpit/CreativeCard/CreativeCard';
import { DraggableProvider } from 'components/DraggableProvider/DraggableProvider';
import React, { FC } from 'react';
import { SelectableCreative } from 'types/creativeCockpit';

interface ProductCardPopupProps {
  product: SelectableCreative;
  action: ((arg: any) => any) | React.Dispatch<React.SetStateAction<SelectableCreative | null>>;
}
const ProductCardPopup: FC<ProductCardPopupProps> = ({ product, action }) => {
  return (
    <>
      <DraggableProvider
        usingDragger={false}
        initialPosition="center center"
        className="draggable-card scroll-smooth 
              cursor-grab active:cursor-grabbing overflow-scroll shadow-slate-500 shadow-lg bg-white rounded-lg sm:w-[25vw] w-[30vw] h-[80vh] max-w-[40vw]"
      >
        <div
          className="absolute right-0 top-0 p-4 z-10 bg-white/40 rounded flex cursor-pointer"
          onClick={() => action(null)}
        >
          <MobileCancelMajor className="w-8 h-8 fill-primary" />
        </div>
        <CreativeCard creative={product} />
      </DraggableProvider>
    </>
  );
};

export default ProductCardPopup;
