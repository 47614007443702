import { ExportMinor } from '@shopify/polaris-icons';
import GoogleSheetsIconBase64 from 'components/GoogleSheetsIconBase64';

interface ISettingsItems {
  setExportTitle: React.Dispatch<React.SetStateAction<string>>;
  setShowExport: React.Dispatch<React.SetStateAction<boolean>>;
  isGoogleSheetsConnected: boolean;
}
export const getSettingsItems = ({
  setExportTitle,
  setShowExport,
  isGoogleSheetsConnected,
}: ISettingsItems) => {
  const settingsItems = [
    {
      content: 'Export CSV',
      icon: ExportMinor,
      onAction: () => {
        setExportTitle('Export CSV');
        setShowExport(true);
      },
      accessibilityLabel: 'Export CSV',
    },
    ...(isGoogleSheetsConnected
      ? [
          {
            content: 'Google Sheets',
            icon: GoogleSheetsIconBase64,
            onAction: () => {
              setExportTitle('Export Google Sheets');
              setShowExport(true);
            },
            accessibilityLabel: 'Google Sheets',
          },
        ]
      : []),
  ];
  return settingsItems;
};
