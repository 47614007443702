import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { Button, Modal, OptionList } from '@shopify/polaris';
import {
  cancelConnection,
  saveBingAccountConfig,
  getUserAccounts,
  toggleBingConfigScreenOpen,
} from 'ducks/bing';
import { type AppDispatch } from 'index';
import { error } from 'console';
import { setErrorMessage } from 'ducks/signupJourney';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../$stores/willy/$subscription';

const BingConfig = () => {
  const dispatch: AppDispatch = useDispatch();
  const bingConnectConfigIsOpen = useSelector((state: RootState) => state.bingConnectConfigIsOpen);
  const isFreeShop = useStoreValue($isFreeShop);

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [selectedAccountsIds, setSelectedAccountsIds] = useState<string[]>([]);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const getAccountIds = useCallback(async () => {
    setIsLoading(true);
    try {
      setIsOpen(bingConnectConfigIsOpen);

      if (bingConnectConfigIsOpen) {
        const userAccounts = await getUserAccounts();
        setAccounts(userAccounts);
        setSelectedAccountsIds(userAccounts.flatMap((acc: any) => (acc.selected ? [acc.Id] : [])));
      }
    } catch (errors) {
      console.log(errors);
      setErrorMessages(errors);
    }
    setIsLoading(false);
  }, [bingConnectConfigIsOpen]);

  useEffect(() => {
    getAccountIds();
  }, [getAccountIds]);

  const actions = errorMessages?.length
    ? {}
    : {
        primaryAction: {
          content: 'Save',
          onAction: () => {
            setIsLoadingBtn(true);
            dispatch(saveBingAccountConfig(accounts, selectedAccountsIds));
          },
          disabled: !selectedAccountsIds?.length,
          loading: isLoadingBtn,
        },
        secondaryActions: [
          {
            content: 'Cancel',
            onAction: () => {
              setIsLoadingBtn(false);
              dispatch(cancelConnection());
            },
          },
        ],
      };

  return (
    <Modal
      title="Microsoft Ads Configuration"
      open={isOpen}
      onClose={() => {
        setIsLoadingBtn(false);
        dispatch(toggleBingConfigScreenOpen());
      }}
      {...actions}
      loading={isLoading}
    >
      <Modal.Section>
        {errorMessages?.length ? (
          <ul className={errorMessages.length == 1 ? 'list-none pl-0' : ''}>
            {errorMessages.map((errorMessage) => (
              <li>{errorMessage}</li>
            ))}
          </ul>
        ) : (
          <OptionList
            onChange={setSelectedAccountsIds}
            options={accounts?.map((x) => ({ value: x.Id, label: x.Name })) || []}
            selected={selectedAccountsIds}
            allowMultiple
          />
        )}
        {!isFreeShop && (
          <div>
            <br />
            Need help?{' '}
            <Button plain monochrome onClick={() => window.Intercom('show')}>
              Click here and chat with us!
            </Button>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default BingConfig;
