import { Text } from '@tw/ui-components';

export const ActivityDetail: React.FC<{
  label: string;
  children;
}> = ({ label, children }) => {
  return (
    <Text truncate size="sm">
      <Text span c="gray.6">
        {label}
        {': '}
      </Text>
      <Text truncate span weight={500} c="gray.7">
        {children}
      </Text>
    </Text>
  );
};
