import { useEffect, useState } from 'react';

type UseTimerProps = {
  keepCount: boolean;
  from?: number;
};

type StrRes = `${string}:${string}:${string}`;

export const useTimer = ({ from = 0, keepCount }: UseTimerProps): StrRes => {
  const [time, setTime] = useState(from);

  useEffect(() => {
    const interval = setInterval(() => setTime((t) => t + 1), 1000);

    if (!keepCount) {
      clearInterval(interval);
      setTime(from);
    }

    return () => clearInterval(interval);
  }, [keepCount, from]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};
