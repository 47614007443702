import { RoundingOptionsMapper, getCalculatedValue, getTileValue } from 'ducks/summary';
import { IconMapper, TWIconProps } from 'iconMap';
import moment from '@tw/moment-cached/module/timezone';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  Bar,
  BarChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
} from 'recharts';
import { type RootState } from 'reducers/RootType';
import { SummaryPopupMetadata } from 'types/general';
import { formatNumber } from 'utils/formatNumber';
import { selectAllMetrics } from 'utils/selectors';

import { Modal, Tabs } from '@shopify/polaris';
import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { AbstractChart } from '@tw/types';

import SERVICES from '../constants/services';
import { summaryChartPopupIsOpenToggle } from '../ducks/actions';
import BaseChart from './library/BaseChart/BaseChart';
import SummaryChartComparison from './SummaryChartComparison';
import { KlaviyoCampaignsList } from 'routes/KlaviyoCampaigns';
import { TabDescriptor } from '@shopify/polaris/build/ts/latest/src/components/Tabs/types';
import { cumulateAbstractChart } from 'utils/cumulateAbstractChart';
import { ServicesIds } from '@tw/types/module/services';
import { MetricBreakdown } from './MetricBreakdown/MetricBreakdown';
import { MetricsBreakdownsDictionary } from './MetricBreakdown/constants';
import { isSummaryMetricsIconKeys } from '../types/utils';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $currency } from '../$stores/$shop';
import { TWSkeletonItem } from './library/TWSkeleton/TWSkeletonItem';
import { $globalSequences } from '$stores/willy/$sequences';
import { SummaryInsights } from './SummaryInsights';
import { WillyDataSequence } from './Willy/types/willyTypes';
import { gradualReleaseFeatures } from 'ducks/shop';
import { analyticsEvents, genericEventLogger, summaryActions } from 'utils/dataLayer';

type MetricChartProps = {
  summaryCurrentPopupChart: { data: AbstractChart[] } & SummaryPopupMetadata;
  metric: BaseSummaryMetric<any>;
  workflows: WillyDataSequence[];
};
const truncateString = (str) => (str && str.length > 30 ? str.substring(0, 27) + '...' : str || '');

//TODO: remove this after fix custom spend bug
export const metricsRelatedToCustomSpend = [
  'totalCustomAdSpends',
  'totalCustomNonAdSpends',
  'blendedAds',
  'mer',
  'totalRoas',
  'shopifyCpa',
  'totalCpa',
  'poas',
  'newCustomersCpa',
  'newCustomersRoas',
  'blendedAttributedRoas',
  'pixelCostPerAtc',
  'pixelCostPerSession',
  'totalNetProfit',
  'totalNetMargin',
  'cashTurnover',
  'totalCustomSpends',
];

const MetricChart: React.FC<MetricChartProps> = ({
  summaryCurrentPopupChart,
  metric,
  workflows,
}) => {
  const { insights_summary_pixel: insightsSummaryPixelFF } = useSelector(gradualReleaseFeatures);

  const tabs: TabDescriptor[] = useMemo(() => {
    const insightsTab = { content: 'Insights', id: 'insights' };
    const baseTabs = [
      { content: 'Bars', id: 'bars' },
      { content: 'Comparison', id: 'comparison' },
    ];
    const breakdownTab = { content: 'Breakdown', id: 'breakdown' };
    let tabs =
      workflows.length > 0 && insightsSummaryPixelFF ? [insightsTab, ...baseTabs] : baseTabs;
    if (MetricsBreakdownsDictionary[metric.id]) {
      tabs.push(breakdownTab);
    }
    return tabs;
  }, [workflows, metric.id, insightsSummaryPixelFF]);

  const isSmall = useIsSmall();
  const [chartType, setChartType] = useState<string>(tabs?.[0]?.id);

  const [value, setValue] = useState<string>();
  const [previousValue, setPreviousValue] = useState<string>();
  const loadingStatsComparisons = useSelector((state: RootState) => state.loadingStatsComparisons);
  const [currentPeriodDate, setCurrentPeriodDate] = useState<string>();
  const [previousPeriodDate, setPreviousPeriodDate] = useState<string>();
  const currentPeriodChartData: AbstractChart[] = (summaryCurrentPopupChart || {}).data;

  const typeHandleChange = (index: number) => {
    if (tabs[index].id === 'related-metrics' && !metric?.relatedMetrics?.length) {
      return;
    }
    setChartType(tabs[index].id as 'bars' | 'comparison' | 'breakdown' | 'insights');
  };

  useEffect(() => {
    if (chartType === 'insights') {
      genericEventLogger(analyticsEvents.SUMMARY, {
        action: summaryActions.OPEN_METRIC_POPUP_WORKFLOW_TAB,
        source: 'summary',
        sourceDetails: metric?.metricId,
      });
    }
  }, [chartType, metric]);

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );

  const isGetChartDataFromClient = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('isGraphComingFromClient') === 'true';
  }, []);

  const previousPeriodDates = useSelector((state: RootState) => state.previousPeriodDates);
  const previousPeriodStats = useSelector((state: RootState) => state.previousPeriodStats);
  const isProd = useSelector((state: RootState) => state.isProd);
  const isOneDay = useSelector((state: RootState) => state.isOneDay);
  const isOneYear = useSelector((state: RootState) => state.isOneYear);
  const state = useSelector((state: RootState) => state);
  const currency = useStoreValue($currency);
  const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
  const customMetrics = useSelector((state: RootState) => state.customMetrics);
  const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);
  const {
    cumulative: isMetricChartCumulative = false,
    yAxisStartAuto: isMetricChartYAxisStartAuto = false,
  } = useSelector((state: RootState) => state.summaryMetricsChartConfig[metric.id]) ?? {};
  const previousPeriodCalculatedChartTemp: AbstractChart[] = useSelector(
    (state: RootState) => state.previousPeriodCalculatedCharts,
  )?.[metric.metricId];
  const previousPeriodCalculatedChart = getCalculatedValue(
    isMetricChartCumulative || metric.isCumulativeMetric
      ? cumulateAbstractChart(previousPeriodCalculatedChartTemp || [])
      : previousPeriodCalculatedChartTemp,
    metric,
    true,
  );

  const currentPeriodCalculatedChartTemp: AbstractChart[] = useSelector(
    (state: RootState) => state.calculatedCharts,
  )?.[metric.metricId];
  const currentPeriodCalculatedChart = getCalculatedValue(
    isMetricChartCumulative || metric.isCumulativeMetric
      ? cumulateAbstractChart(currentPeriodCalculatedChartTemp || [])
      : currentPeriodCalculatedChartTemp,
    metric,
    true,
  );

  const previousPeriodCalculatedStatTemp: any = useSelector(
    (state: RootState) => state.previousPeriodCalculatedStats,
  )?.[metric.metricId];
  const previousPeriodCalculatedStat = getCalculatedValue(
    previousPeriodCalculatedStatTemp,
    metric,
    false,
  );
  const selectedStatsServcies: ServicesIds[] = useSelector(
    (state: RootState) => state.selectedStatsServcies,
  );

  const currencyRounding = useCallback(
    (metric) => {
      if (metric?.type === 'currency') {
        return RoundingOptionsMapper[defaultRoundingOption];
      }
      return null;
    },
    [defaultRoundingOption],
  );

  const { start, end } = mainDatePickerSelectionRange || { start: null, end: null };
  const { start: prevPeriodStart, end: prevPeriodEnd } = previousPeriodDates;
  const year = mainDatePickerSelectionRange?.start?.year() ?? moment().year();

  useEffect(() => {
    let value: number = getTileValue(state, metric!);
    let prevValue: number = previousPeriodCalculatedStat;
    value = metric?.type === 'percent' ? value / 100 : value;
    prevValue = metric?.type === 'percent' ? prevValue / 100 : prevValue;
    const valueStr = formatNumber(value * currencyConversionRate, {
      style: metric?.type || 'decimal',
      currency,
      minimumFractionDigits: currencyRounding(metric) ?? metric?.valueToFixed ?? 0,
      maximumFractionDigits: currencyRounding(metric) ?? metric?.valueToFixed ?? 0,
    });
    setValue(valueStr);

    const prevValueStr = formatNumber(prevValue * currencyConversionRate, {
      style: metric?.type || 'decimal',
      currency,
      minimumFractionDigits: currencyRounding(metric) ?? metric?.valueToFixed ?? 0,
      maximumFractionDigits: currencyRounding(metric) ?? metric?.valueToFixed ?? 0,
    });
    setPreviousValue(prevValueStr);
  }, [
    currency,
    metric,
    state,
    previousPeriodStats,
    isProd,
    currencyConversionRate,
    customMetrics,
    previousPeriodCalculatedStat,
    currencyRounding,
  ]);

  useEffect(() => {
    if (!start || !end || !prevPeriodStart || !prevPeriodEnd) {
      return;
    }
    const isCurrentAndPreviousTheSameYear = isOneYear && prevPeriodEnd?.isSame?.(start, 'year');
    let prevPeriodDate = '';
    let currPeriodDate = '';
    if (isOneDay) {
      currPeriodDate = moment(start).format('MMM DD');
      prevPeriodDate = moment(prevPeriodStart).format('MMM DD');
    } else {
      currPeriodDate = `${moment(start).format(
        isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
      )} - ${moment(end).format(isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY')}`;
      prevPeriodDate = `${moment(prevPeriodStart).format(
        isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
      )} - ${moment(prevPeriodEnd).format(
        isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
      )}`;
    }

    setCurrentPeriodDate(currPeriodDate);
    setPreviousPeriodDate(prevPeriodDate);
  }, [end, isOneDay, isOneYear, prevPeriodEnd, prevPeriodStart, start]);

  return (
    <div className="overflow-y-hidden">
      <div className="tw-tabs px-8 flex items-center justify-between">
        <Tabs
          tabs={tabs}
          selected={tabs.findIndex((tab) => tab.id === chartType)}
          onSelect={(index) => typeHandleChange(index)}
        />
      </div>
      {chartType !== 'insights' && (
        <div className="flex justify-between p-6.5 bg-gray-100">
          <div>
            <p>{currentPeriodDate}</p>
            <p className="font-bold text-3xl">{value}</p>
          </div>
          <div className=" text-right">
            <p className="text-secondary-text text-opacity-90">{previousPeriodDate}</p>
            <p className="font-bold text-3xl text-secondary-text text-opacity-90">
              {!loadingStatsComparisons && <>{previousValue}</>}
              {loadingStatsComparisons && <TWSkeletonItem />}
            </p>
          </div>
        </div>
      )}
      {chartType === 'bars' && (
        <BaseChart
          ChartType={BarChart}
          data={
            currentPeriodCalculatedChart?.length > 0 && !isGetChartDataFromClient
              ? currentPeriodCalculatedChart
              : currentPeriodChartData
          }
          height={200}
          wrapperStyle={{ padding: '0 2rem 0 0', borderRadius: '0 0 8px 8px' }}
          margin={{ top: 30, left: 0, bottom: 30, right: 0 }}
          xAxis={[
            {
              tickFormatter: (value: string) => {
                if (isOneDay) {
                  return moment().hour(+value).minutes(0).format('HH:mm');
                }
                return moment().year(year).dayOfYear(+value).format('MMM D');
              },
              dy: 20,
              dataKey: 'x',
            },
          ]}
          yAxis={[
            {
              tickFormatter: (value, index) => {
                if (index % 2 !== 0) {
                  return '';
                }
                return +value < 1000 ? value : +value / 1000 + 'K';
              },
              dx: 0,
              domain: [
                isMetricChartYAxisStartAuto || metric.isCumulativeMetric ? 'auto' : 0,
                'auto',
              ],
            },
          ]}
        >
          <defs>
            <linearGradient id={`base-color-for-bars`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0.2" stopColor={'#10F1A7'} stopOpacity={1} />
              <stop offset="1" stopColor={'#1877F2'} stopOpacity={1} />
            </linearGradient>
          </defs>
          <Bar
            // name={
            //   //TODO: remove this condition after fix custom spend bug
            //   metricsRelatedToCustomSpend.includes(metric?.metricId) && !isOneDay
            //     ? ' '
            //     : metric?.title || metric?.shortTitle
            // }
            name={truncateString(metric?.title || metric?.shortTitle)}
            barSize={isSmall ? 3 : 4}
            fill={`url(#base-color-for-bars)`}
            dataKey="y"
          />
          <ChartTooltip
            cursor={{ fill: 'none' }}
            formatter={(value: number) => {
              const { type, valueToFixed } = metric || {};
              return formatNumber(
                (metric?.type === 'percent' ? value / 100 : value) * currencyConversionRate,
                {
                  style: type || 'decimal',
                  currency,
                  minimumFractionDigits: currencyRounding(metric) ?? valueToFixed ?? 0,
                  maximumFractionDigits: currencyRounding(metric) ?? valueToFixed ?? 0,
                },
              );
            }}
            labelFormatter={(value) => {
              if (isOneDay) {
                return moment().hour(+value).minutes(0).format('HH:mm');
              }
              return moment().year(year).dayOfYear(+value).format('MMM D');
            }}
            // formatter={(value: number) => {
            //   const { type, valueToFixed } = metric || {};
            //   //TODO: remove this condition after fix custom spend bug
            //   if (metricsRelatedToCustomSpend.includes(metric?.metricId) && !isOneDay) return '';
            //   return formatNumber(
            //     (metric?.type === 'percent' ? value / 100 : value) * currencyConversionRate,
            //     {
            //       style: type || 'decimal',
            //       currency,
            //       minimumFractionDigits: currencyRounding(metric) ?? valueToFixed ?? 0,
            //       maximumFractionDigits: currencyRounding(metric) ?? valueToFixed ?? 0,
            //     }
            //   );
            // }}
            // labelFormatter={(value) => {
            //   if (isOneDay) {
            //     return moment().hour(+value).minutes(0).format('HH:mm');
            //   }
            //   return moment().dayOfYear(+value).format('MMM D');
            // }}
            // //TODO: remove this after fix custom spend bug
            // separator={
            //   metricsRelatedToCustomSpend.includes(metric?.metricId) && !isOneDay ? '' : ':'
            // }
          />
        </BaseChart>
      )}
      {chartType === 'comparison' && (
        <Fragment>
          <SummaryChartComparison
            data={
              currentPeriodCalculatedChart?.length > 0 && !isGetChartDataFromClient
                ? currentPeriodCalculatedChart
                : currentPeriodChartData
            }
            previousPeriodData={previousPeriodCalculatedChart || []}
            metric={metric}
          />
        </Fragment>
      )}
      {chartType === 'breakdown' && <MetricBreakdown metricId={metric.id} />}
      {chartType === 'insights' && (
        <SummaryInsights workflows={workflows} metricId={metric.metricId} />
      )}
    </div>
  );
};

const MetricPopup = () => {
  const dispatch = useDispatch();

  const summaryChartPopupIsOpen = useSelector((state: RootState) => state.summaryChartPopupIsOpen);
  const summaryCurrentPopupChart = useSelector(
    (state: RootState) => state.summaryCurrentPopupChart,
  );
  const state = useSelector((state: RootState) => state);
  const allMetrics = useSelector(selectAllMetrics);
  const allGlobalWorkflows = useStoreValue($globalSequences);

  const closePopup = () => dispatch(summaryChartPopupIsOpenToggle());
  const { id } = summaryCurrentPopupChart || {};

  const metric = allMetrics.find((m) => m.id === id);

  const workflowsForMetric = useMemo(() => {
    return allGlobalWorkflows.filter((seq) => {
      return seq.taggedMetrics?.some((tm) => tm.metric === metric?.metricId);
    });
  }, [allGlobalWorkflows, metric]);

  const Icon = useMemo(() => {
    let icon;
    if (typeof metric?.icon === 'string') {
      icon = IconMapper[metric?.icon]?.({ small: true });
    }
    if (typeof metric?.icon === 'function') {
      const res = metric?.icon(state);
      if (isSummaryMetricsIconKeys(res)) {
        icon = IconMapper[res]?.({ small: true });
      } else {
        icon = res as any;
      }
    }
    return <span className="flex items-center">{icon}</span>;
  }, [metric, state]);

  if (!summaryChartPopupIsOpen) return null;
  const largePopUp: boolean =
    metric?.id === 'klaviyoPlacedOrderTotalPriceCampaigns' ||
    metric?.id === 'klaviyoPlacedOrderTotalPriceFlows';

  return (
    <Modal
      noScroll
      large={largePopUp}
      open={summaryChartPopupIsOpen}
      onClose={closePopup}
      title={
        <div>
          {metric && (
            <div className="flex items-center gap-4">
              {Icon}
              <span>{metric.title}</span>
            </div>
          )}
        </div>
      }
    >
      {summaryCurrentPopupChart?.data?.length ? (
        <MetricChart
          summaryCurrentPopupChart={summaryCurrentPopupChart!}
          metric={metric!}
          workflows={workflowsForMetric}
        />
      ) : null}
    </Modal>
  );
};

type RelatedMetricsProps = {
  data: SummaryMetricIdsTypes[];
  showAggregationBar: boolean;
};

const RelatedMetrics: React.FC<RelatedMetricsProps> = ({ data, showAggregationBar }) => {
  const state = useSelector((state: RootState) => state);
  const allMetrics = useSelector(selectAllMetrics);
  const currency = useStoreValue($currency);
  const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
  const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);

  const currencyRounding = useCallback(
    (metric) => {
      if (metric?.type === 'currency') {
        return RoundingOptionsMapper[defaultRoundingOption];
      }
      return null;
    },
    [defaultRoundingOption],
  );

  if (!data || !data.length) return null;

  return (
    <Fragment>
      <div
        className="flex flex-wrap p-6.5 gap-6.5"
        style={{ backgroundColor: 'var(--summary-bg)' }}
      >
        {data.map((id) => {
          const metric = allMetrics.find((_m) => _m.id === id)!;
          const { title, valueToFixed, icon, type } = metric;
          const value = getTileValue(state, metric);
          return (
            <div
              key={`${id}-${title}-${value}`}
              className="bg-white rounded sm:p-4 sm:pt-4 p-4 pt-6 px-6 sm:px-4 flex flex-col gap-4 flex-auto"
              style={{ boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 8px' }}
            >
              <div className="flex items-center gap-4">
                {typeof icon === 'string' &&
                  typeof IconMapper[icon] === 'function' &&
                  IconMapper[icon]({ small: true })}
                {typeof icon === 'function' && icon()}
                <p>{title}</p>
              </div>
              <p className="font-bold">
                {formatNumber(value * currencyConversionRate, {
                  style: type,
                  currency,
                  minimumFractionDigits: currencyRounding(metric) ?? valueToFixed,
                  maximumFractionDigits: currencyRounding(metric) ?? valueToFixed,
                })}
              </p>
            </div>
          );
        })}
        <div className="" style={{ flex: '1 1 100%' }}>
          {showAggregationBar && <RelatedMetricsAggregationPie data={data} />}
          {/* <RelatedMetricsChart data={data} stats={stats} /> */}
        </div>
      </div>
    </Fragment>
  );
};

type RelatedMetricsChartProps = {
  data: SummaryMetricIdsTypes[];
  stats: any;
};

// const RelatedMetricsChart: React.FC<RelatedMetricsChartProps> = ({ data, stats }) => {
//   const normalized = {};
//   console.log({ data });

//   data.forEach(
//     (id) =>
//     (normalized[METRICS.find((_m) => _m.id === id)?.chart!] = STATS[
//       METRICS.find((_m) => _m.id === id)?.chart
//     ]?.({ stats }))
//   );
//   return (
//     <ChangelogChart
//       selectedMetrics={data.map((id) => METRICS.find((_m) => _m.id === id)!)}
//       normalizedChartsData={normalized}
//       data={normalized}
//       customEvents={[]}
//       curvedIsOn={true}
//     // values={undefined}
//     // chartWidth={isSmall ? deviceWidth - 88 : 530}
//     // chartHeight={160}
//     // margin={{ left: 0, right: 0 }}
//     // useServiceColors
//     // titleX={undefined}
//     // titleY={undefined}
//     // COLORS={undefined}
//     />
//   );
// };

type RelatedMetricsAggregationPieProps = {
  data: SummaryMetricIdsTypes[];
};
const RelatedMetricsAggregationPie: React.FC<RelatedMetricsAggregationPieProps> = ({ data }) => {
  const currency = useStoreValue($currency);
  const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
  const allMetrics = useSelector(selectAllMetrics);
  const state = useSelector((state: RootState) => state);
  const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);

  const currencyRounding = useCallback(
    (metric) => {
      if (metric?.type === 'currency') {
        return RoundingOptionsMapper[defaultRoundingOption];
      }
      return null;
    },
    [defaultRoundingOption],
  );

  const values = data.map((id) => {
    const metric = allMetrics.find((_m) => _m.id === id);
    const { services, title, type, valueToFixed } = metric || {};
    const color = SERVICES[services?.[0]!]?.color;
    const value = getTileValue(state, metric!);
    return {
      value,
      id,
      name: title,
      fill: color,
      stroke: color,
      type,
      valueToFixed,
    };
  });
  return (
    <ResponsiveContainer width={'100%'} height={300}>
      <PieChart>
        <Pie data={values} dataKey="value" />
        <ChartTooltip
          formatter={(value: number, name: string, data: any) => {
            const { payload } = data || {};
            const { type, valueToFixed, id } = payload?.payload || {};

            return formatNumber(
              (type === 'percent' ? value / 100 : value) * currencyConversionRate,
              {
                style: type || 'decimal',
                currency,
                minimumFractionDigits: currencyRounding({ id, type }) ?? valueToFixed ?? 0,
                maximumFractionDigits: currencyRounding({ id, type }) ?? valueToFixed ?? 0,
              },
            );
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MetricPopup;
