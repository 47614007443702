import ReactDOM from 'react-dom/client';

import App from './App';

import { Provider } from 'react-redux';
import store from './index';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@tw/ui-components';

window.lastReloadISODate = new Date().toISOString();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    {/*
        Should find a better solution/library
        https://stackoverflow.com/questions/60029734/react-beautiful-dnd-i-get-unable-to-find-draggable-with-id-1
      */}
    {/* <React.StrictMode> */}
    <ThemeProvider>
      <App />
    </ThemeProvider>

    {/* </React.StrictMode> */}
  </Provider>,
);

serviceWorker.register();
