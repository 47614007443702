import CDPSegmentDropdown from 'components/ltv/CDPSegmentDropdown';
import DurationFilters from 'components/ltv/DurationFilters';
import { durationFiltersValueChange } from 'ducks/actions';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

export const DurationFiltersHeader = () => {
  const dispatch = useAppDispatch();
  const duration = useSelector((state: RootState) => state.durationFiltersValue);

  return (
    <div className="flex items-center gap-4 flex-wrap">
      <DurationFilters
        duration={duration}
        handleDurationChange={(value) => {
          dispatch(durationFiltersValueChange(value));
        }}
      />
    </div>
  );
};
