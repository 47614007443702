import { Flex } from '@tw/ui-components';
import AttributionSettings from 'components/attribution-new/AttributionSettings';
import { FC, useState } from 'react';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { AttributionHeaderProps } from './AttributionHeader';

export const AttributionMobileTopHeader: FC<AttributionHeaderProps> = ({}) => {
  const [attributionSettingsOpen, setAttributionSettingsOpen] = useState(false);
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();

  return (
    <Flex gap="sm">
      <AttributionSettings
        isOpen={attributionSettingsOpen}
        onOpen={() => setAttributionSettingsOpen(!attributionSettingsOpen)}
        type="ads"
        hideAttributionModel={true}
        hideDateModel={true}
        hideOneDayConversionValue={activeSource !== 'facebook-ads' && sourceCategory !== 'ads'}
      />
      <div className="h-[30px] w-[1px] bg-[var(--mantine-color-named2-3)]" />
    </Flex>
  );
};
