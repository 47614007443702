const PixelIconSVG = (props) => {
  const { small = false, ...others } = props;

  return (
    <svg
      width="18"
      height={small ? '11' : '18'}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className="darkmode-white-filter"
      {...others}
    >
      <rect x="8.10052" width="2.7" height="5.4" rx="0.2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 0C2.61046 0 2.7 0.0895429 2.7 0.2V5.4C2.7 5.89705 3.10294 6.3 3.6 6.3H10.8C11.2971 6.3 11.7 5.89705 11.7 5.4V0.2C11.7 0.089543 11.7895 0 11.9 0H13.1401C13.5133 0 13.8698 0.154451 14.125 0.426679L16.7349 3.21053C16.9694 3.46075 17.1 3.79087 17.1 4.13385V16.65C17.1 17.3956 16.4956 18 15.75 18H14.4002V9.99992C14.4002 9.44763 13.9525 8.99992 13.4002 8.99992H3.70022C3.14793 8.99992 2.70022 9.44764 2.70022 9.99992V18H1.35C0.604415 18 0 17.3956 0 16.65V1.35C0 0.604415 0.604415 0 1.35 0H2.5Z"
      />
      <rect x="4.49905" y="15.3" width="2.7" height="2.7" />
      <rect x="7.20013" y="12.5999" width="2.7" height="2.7" />
      <rect x="9.89984" y="15.3" width="2.7" height="2.7" />
      <rect x="9.89984" y="9.89984" width="2.7" height="2.7" />
    </svg>
  );
};

export default PixelIconSVG;

// data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iOSIgd2lkdGg9IjMiIGhlaWdodD0iNiIgcng9IjAuMiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjggMEMyLjkxMDQ2IDAgMyAwLjA4OTU0MjkgMyAwLjJWNkMzIDYuNTUyMjggMy40NDc3MiA3IDQgN0gxMkMxMi41NTIzIDcgMTMgNi41NTIyOCAxMyA2VjAuMkMxMyAwLjA4OTU0MzEgMTMuMDg5NSAwIDEzLjIgMEgxNC42MDAyQzE1LjAxNDggMCAxNS40MTA5IDAuMTcxNjEzIDE1LjY5NDUgMC40NzQwODhMMTguNTk0MyAzLjU2NzI2QzE4Ljg1NDkgMy44NDUyOCAxOSA0LjIxMjA4IDE5IDQuNTkzMTdWMTguNUMxOSAxOS4zMjg0IDE4LjMyODQgMjAgMTcuNSAyMEgxNlYxMUMxNiAxMC40NDc3IDE1LjU1MjMgMTAgMTUgMTBINEMzLjQ0NzcyIDEwIDMgMTAuNDQ3NyAzIDExVjIwSDEuNUMwLjY3MTU3MyAyMCAwIDE5LjMyODQgMCAxOC41VjEuNUMwIDAuNjcxNTczIDAuNjcxNTczIDAgMS41IDBIMi44WiIgZmlsbD0iYmxhY2siLz4KPHJlY3QgeD0iNSIgeT0iMTciIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjgiIHk9IjE0IiB3aWR0aD0iMyIgaGVpZ2h0PSIzIiBmaWxsPSJibGFjayIvPgo8cmVjdCB4PSIxMSIgeT0iMTciIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjExIiB5PSIxMSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==
