import _db, { serverTimestamp, toArray } from '../utils/DB';
import Cookies from '../utils/Cookies';
import _ from 'lodash';
import { SummaryMetrics } from '@tw/types/module/SummaryMetrics';
import { Dispatch } from 'redux';
import firebase from 'firebase/compat/app';
import { RootState } from 'reducers/RootType';
import { createSelector } from 'reselect';
import { FFObserver } from 'feature-flag-system';

const firestore = firebase.firestore;

type SummaryMetricsKey = keyof typeof SummaryMetrics;
type MetricId = (typeof SummaryMetrics)[SummaryMetricsKey]['metricId'];
type MetricsMap = {
  [K in SummaryMetricsKey as (typeof SummaryMetrics)[K]['metricId']]: (typeof SummaryMetrics)[K];
};
const METRICS_MAP = Object.values(SummaryMetrics).reduce(
  (acc, m) => ((acc[m.metricId] = m), acc),
  {} as MetricsMap,
);

export enum CustomMetricsType {
  Summary = 'summary',
  Attribution = 'attribution',
  ProductAnalytics = 'product_analytics',
}

const CUSTOM_METRIC_METADATA: { [key in CustomMetricsType]: any } = {
  [CustomMetricsType.Summary]: {
    collection: 'custom_metrics',
    cookieName: 'customMetrics',
    stateList: 'metrics',
  },
  [CustomMetricsType.ProductAnalytics]: {
    collection: 'custom_metrics_product_analytics',
    cookieName: 'customMetricsProductAnalytics',
    stateList: 'metricsProductAnalytics',
  },
  [CustomMetricsType.Attribution]: {
    collection: 'custom_metrics_attribution',
    cookieName: 'customMetricsAttribution',
    stateList: 'metricsAttribution',
  },
};

const TOGGLE_CUSTOM_METRIC_MODAL = 'TOGGLE_CUSTOM_METRIC_MODAL';
export const toggleCustomMetricModal = (data) => ({
  type: TOGGLE_CUSTOM_METRIC_MODAL,
  payload: data,
});

const INITIALIZE_CUSTOM_METRIC_MODAL = 'INITIALIZE_CUSTOM_METRIC_MODAL';
export const initializeCustomMetricModal =
  (customMetricsType: CustomMetricsType, customMetricId) => (dispatch, getState) => {
    const { customMetrics } = getState();
    const list = customMetrics[CUSTOM_METRIC_METADATA[customMetricsType].stateList];
    const initialData = list.find((x) => x.id === customMetricId);
    dispatch({
      type: INITIALIZE_CUSTOM_METRIC_MODAL,
      payload: initialData,
      customMetricsType,
    });
  };

const CUSTOM_METRIC_ON_CREATE = 'CUSTOM_METRIC_ON_CREATE';
export const customMetricOnCreate = (data) => {
  return async (dispatch, getState) => {
    const { customMetrics } = getState();

    const idRef = await _db()
      .collection(customMetrics.collection)
      .add({
        ...data,
        createdAt: serverTimestamp(),
      });
    dispatch({ type: CUSTOM_METRIC_ON_CREATE });
    const id = idRef.id;
    return id;
  };
};

const CUSTOM_METRIC_ON_EDIT = 'CUSTOM_METRIC_ON_EDIT';
export const customMetricOnEdit = (data: any) => {
  return async (dispatch: Dispatch, getState) => {
    const { customMetrics } = getState();

    await _db()
      .collection(customMetrics.collection)
      .doc(data.id)
      .set(
        {
          ...data,
          stat: firestore.FieldValue.delete(),
          updatedAt: serverTimestamp(),
        },
        { merge: true },
      );
    dispatch({ type: CUSTOM_METRIC_ON_EDIT });
    return data.id;
  };
};

const CUSTOM_METRIC_ON_DELETE = 'CUSTOM_METRIC_ON_DELETE';
export const customMetricOnDelete = (id) => {
  return async (dispatch, getState) => {
    const { customMetrics } = getState();

    await _db().collection(customMetrics.collection).doc(id).delete();
    dispatch({ type: CUSTOM_METRIC_ON_DELETE });

    return id;
  };
};

const CUSTOM_METRICS_ON_LOAD = 'CUSTOM_METRICS_ON_LOAD';
const isValidStat = (stat: unknown): stat is { value: keyof typeof METRICS_MAP } => {
  return (
    typeof stat === 'object' &&
    !!stat &&
    'value' in stat &&
    typeof stat.value === 'string' &&
    stat.value in METRICS_MAP
  );
};
const loadCustomMetricsByType = (customMetricsType: CustomMetricsType) => {
  const loadCustomMetricsFFObserver = new FFObserver();

  return (dispatch) => {
    const collection = CUSTOM_METRIC_METADATA[customMetricsType].collection;

    _db()
      .collection(collection)
      .onSnapshot((snapshot) => {
        const data = toArray(snapshot).map((x) => {
          // sometimes includes ref. no time to investigate
          const { ref, ...rest } = x;
          return rest;
        });

        loadCustomMetricsFFObserver.fireAndSetFFListener((ffComputer) => {
          const isLockedByFeature = (stat: unknown): boolean =>
            isValidStat(stat) &&
            !!METRICS_MAP[stat.value as MetricId]?.features?.some((featureFlag) =>
              ffComputer.isEntityLocked({ featureFlag, targetUnlockValue: stat.value }),
            );

          const filterableMetrics = data.map((metric) => ({
            ...metric,
            shouldBeFiltered:
              (metric.stats && Object.values(metric.stats)?.some(isLockedByFeature)) ||
              metric.stat?.some(isLockedByFeature),
          }));

          const sortedFilterableMetrics = _.sortBy(filterableMetrics, (x) => x.createdAt?.seconds);

          dispatch({
            type: CUSTOM_METRICS_ON_LOAD,
            data: sortedFilterableMetrics,
            customMetricsType,
          });
        });
      });
  };
};

export const loadCustomMetrics = () => {
  return (dispatch) => {
    for (const [customMetricsType, _] of Object.entries(CUSTOM_METRIC_METADATA)) {
      dispatch(loadCustomMetricsByType(customMetricsType as CustomMetricsType));
    }
  };
};

const initialState = {
  metrics: JSON.parse(
    Cookies.get(CUSTOM_METRIC_METADATA[CustomMetricsType.Summary].cookieName) || '[]',
  ),
  metricsAttribution: JSON.parse(
    Cookies.get(CUSTOM_METRIC_METADATA[CustomMetricsType.Attribution].cookieName) || '[]',
  ),
  isModalOpen: false,
  initialData: {},
  customMetricsType: CustomMetricsType.Summary,
};

export const loadingCustomMetrics = (state = true, action) => {
  switch (action.type) {
    case CUSTOM_METRICS_ON_LOAD:
      return false;
    default:
      return state;
  }
};

export const customMetrics = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_METRICS_ON_LOAD:
      const customMetricsTypeMetadata = CUSTOM_METRIC_METADATA[action.customMetricsType];
      Cookies.set(customMetricsTypeMetadata.cookieName, JSON.stringify(action.data));
      return {
        ...state,
        [customMetricsTypeMetadata.stateList]: action.data,
      };
    case CUSTOM_METRIC_ON_CREATE:
      return state;
    case TOGGLE_CUSTOM_METRIC_MODAL:
      const customMetricsType = action.payload?.customMetricsType || CustomMetricsType.Summary;
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
        customMetricsType,
        collection: CUSTOM_METRIC_METADATA[customMetricsType].collection,
        initialData: {},
      };
    case INITIALIZE_CUSTOM_METRIC_MODAL:
      return {
        ...state,
        customMetricsType: action.customMetricsType,
        collection: CUSTOM_METRIC_METADATA[action.customMetricsType].collection,
        isModalOpen: true,
        initialData: action.payload,
      };
    default:
      return state;
  }
};

export const selectCustomMetricsForType = (customMetricsType: CustomMetricsType) => {
  return createSelector(
    [(state: RootState) => state.customMetrics],
    (customMetrics) => customMetrics[CUSTOM_METRIC_METADATA[customMetricsType]?.stateList] || [],
  );
};
