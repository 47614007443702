import { $tabs } from '$stores/willy/$tables';
import { FilterRow } from '@tw/willy-data-dictionary/module/columns/types';
import { formatSqlSafely } from 'components/Willy/utils/willyUtils';

export function getFilterDiff(filters: FilterRow[][], lastFilters: FilterRow[][]): FilterRow[][] {
  // Function to get diff between two FilterRow arrays
  function getFilterDiffIn(filters: FilterRow[], lastFilters: FilterRow[]): FilterRow[] {
    const filtersMap = new Map(filters.map((f) => [f.column.name, f]));
    const result: FilterRow[] = [];

    // Add or update filters based on the lastFilters
    lastFilters.forEach((lastFilter) => {
      const currentFilter = filtersMap.get(lastFilter.column.name);
      if (currentFilter) {
        // Check if the filter has been updated
        if (JSON.stringify(currentFilter) !== JSON.stringify(lastFilter)) {
          // Mark the old filter as deleted
          result.push({ ...lastFilter, isDeleted: true });
          // Add the updated filter
          result.push(currentFilter);
        } else {
          // Add the unchanged filter
          // result.push(lastFilter);
        }
        filtersMap.delete(lastFilter.column.name);
      } else {
        // The filter is deleted
        result.push({ ...lastFilter, isDeleted: true });
      }
    });

    // Add any new filters that were not in lastFilters
    filtersMap.forEach((filter) => result.push(filter));

    return result;
  }

  // Ensure that both arrays have the same length
  const maxLength = Math.max(filters.length, lastFilters.length);
  const diffResult: FilterRow[][] = [];

  for (let i = 0; i < maxLength; i++) {
    const currentFilters = filters[i] || [];
    const currentLastFilters = lastFilters[i] || [];
    diffResult.push(getFilterDiffIn(currentFilters, currentLastFilters));
  }

  return diffResult;
}

const operatorMap = {
  equal: '=',
  not_equals: '!=',
  greater_than: '>',
  less_than: '<',
  greater_than_or_equals: '>=',
  less_than_or_equals: '<=',
  is_in: 'in',
  is_not_in: 'not in',
  contain: 'like',
  does_not_contain: 'not contains',
  starts_with: 'starts with',
  ends_with: 'ends with',
  is_null: 'is null',
  is_not_null: 'is not null',
  between: 'between',
};

const ex = [
  [
    {
      column: {
        name: 'channel',
        type: 'string',
        title: 'Channel',
        options: [],
        multiSelect: true,
        id: 'channel',
      },
      comparator: 'is_in',
      value: ['facebook-ads'],
    },
    {
      column: {
        name: 'spend',
        type: 'numeric',
        title: 'Spend',
        id: 'spend',
      },
      comparator: 'greater_than',
      value: '100',
    },
  ],
];

export function applyFilters(currentFilter: FilterRow[][], lastFilter: FilterRow[][]) {
  const orFilters = getFilterDiff(currentFilter, lastFilter);
  const tabId = $tabs.get().find((t) => t.active)?.model?.id;
  const tabs = $tabs.get();
  let currentQuery = tabs.find((t) => t.model?.id === tabId)?.query;
  if (!currentQuery) {
    return;
  }
  // find where clause case insensitive
  const whereIndex = currentQuery.toLowerCase().indexOf('where');
  const groupByIndex = currentQuery.toLowerCase().includes('group by')
    ? currentQuery.toLowerCase().indexOf('group by')
    : currentQuery.length;
  const beforeWhere = currentQuery.slice(0, whereIndex);
  const entireWhereClause = currentQuery.slice(whereIndex + 'where'.length, groupByIndex).trim();
  const afterWhere = currentQuery.slice(groupByIndex);

  const formattedOrFilters = orFilters.map((andFilters) => {
    const formattedAndFilters = andFilters.map((filter: any) => {
      const { column, comparator, value, value1, value2, isDeleted } = filter;
      const operator = operatorMap[comparator];

      let formattedValue = Array.isArray(value)
        ? `(${value.map((x) => `'${x}'`).join(', ')})`
        : `'${value}'`;
      if (operator === 'like') {
        formattedValue = `'%${value}%'`;
      }
      if (operator === 'between') {
        formattedValue = `'${value1}' and '${value2}'`;
      }
      if (['in', 'not in'].includes(operator) && !Array.isArray(value)) {
        formattedValue = `('${value}')`;
      }
      return {
        column: column.name,
        comparator,
        value,
        operator,
        formattedValue,
        isDeleted,
      };
    });
    return formattedAndFilters;
  });

  const splitWhereClauses = entireWhereClause.split(/ or /i);
  const whereClauses = formattedOrFilters.map((formattedFilters, i) => {
    let whereClause = splitWhereClauses[i] || '';
    const deletedFilters = formattedFilters.filter((f) => f.isDeleted);

    deletedFilters.forEach((filter) => {
      const { column, operator, formattedValue } = filter;
      const clause = `${column} ${operator} ${formattedValue}`;
      let newWhereClause = whereClause.replace(`and ${clause}`, '');
      newWhereClause = newWhereClause.replace(`${clause}`, '');
      newWhereClause = newWhereClause.trimStart();
      // remove and if it's the first clause
      if (newWhereClause.startsWith('and')) {
        newWhereClause = newWhereClause.replace('and', '');
      }
      whereClause = newWhereClause;
    });

    const existingFilters = formattedFilters.filter(
      (f) => !f.isDeleted && whereClause.includes(f.column),
    );

    existingFilters.forEach((filter) => {
      const { column, operator, formattedValue } = filter;
      // replace column till end of line
      const columnIndex = whereClause.indexOf(column);
      let lineBreakIndex = whereClause.indexOf('\n', columnIndex);
      if (lineBreakIndex === -1) {
        lineBreakIndex = whereClause.length;
      }
      const beforeColumn = whereClause.slice(0, columnIndex);
      const afterColumn = whereClause.slice(lineBreakIndex);
      const newWhereClause = `${beforeColumn} ${column} ${operator} ${formattedValue} ${afterColumn}`;
      whereClause = newWhereClause;
    });
    const newFilters = formattedFilters.filter(
      (f) => !f.isDeleted && !whereClause.includes(f.column),
    );

    newFilters.forEach((filter) => {
      const { column, operator, formattedValue } = filter;
      const newWhereClause = `${whereClause} ${
        whereClause ? 'and' : ''
      } ${column} ${operator} ${formattedValue}`;
      whereClause = newWhereClause;
    });
    return whereClause;
  });

  const whereClause = whereClauses.filter((x) => x).join(' or ');
  const query = `
      ${beforeWhere} where ${whereClause}
      ${afterWhere}
    `;
  const formattedQuery = formatSqlSafely(query);
  $tabs.set((old) => {
    return old.map((t) => {
      if (t.model?.id === tabId) {
        return {
          ...t,
          query: formattedQuery,
        };
      }
      return t;
    });
  });
}
