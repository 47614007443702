import React, { useState, useCallback } from 'react';
import { Button, Modal, TextContainer } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';

export default function DeleteRowButton({
  title = 'Are you sure?',
  description,
  primaryActionTitle = 'OK',
  primaryAction,
  icon = DeleteMinor,
  style = { height: '500px' },
  plain = false,
}) {
  const [active, setActive] = useState(false);

  const handleChange = useCallback(() => setActive(!active), [active]);
  return (
    <div style={style}>
      <Button icon={icon} onClick={handleChange} plain={plain} />
      <Modal
        open={active}
        onClose={handleChange}
        title={title}
        primaryAction={{
          content: primaryActionTitle,
          onAction: () => {
            handleChange();
            primaryAction && primaryAction();
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleChange,
          },
        ]}
      >
        {description && (
          <Modal.Section>
            <TextContainer>
              <p>{description}</p>
            </TextContainer>
          </Modal.Section>
        )}
      </Modal>
    </div>
  );
}
