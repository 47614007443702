import { Button, Modal, Stack } from '@shopify/polaris';

type Props = {
  showPopup: boolean;
  handlePopupSelect: (value: boolean) => void;
};

export const ApplyPopup: React.FC<Props> = ({ showPopup, handlePopupSelect }) => {
  return (
    <div className="items-center">
      <Modal
        title={
          <div className="flex-container flex-children middle-children font-bold">
            Apply Template
          </div>
        }
        titleHidden={false}
        open={showPopup}
        onClose={() => handlePopupSelect(false)}
        primaryAction={{
          onAction: () => handlePopupSelect(false),
          content: 'No, Keep current template',
        }}
        secondaryActions={[
          {
            onAction: () => handlePopupSelect(true),
            content: 'Yes, apply template',
          },
        ]}
      >
        <Modal.Section>
          <div className="text-center items-center gap-4">
            <div>
              <span>You are about to apply a template.</span>
            </div>
            <span className="text-center">
              This will reset all of your conditions and actions. Do you want to continue?
            </span>
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};
export default ApplyPopup;
