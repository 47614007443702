import moment from 'moment';
import { getDatePickerOptionValueOptions, TODAY, YESTERDAY } from './useDatePickerSelectedOptions';
import {
  $currentDateRange,
  $currentDateSelectedOption,
  $prevDateRange,
  $prevDateSelectedOption,
} from '../$stores/willy/$dateRange';
import { buildDatePickerCompareOptions } from './useDatePickerCompareOptions';
import { useMemo } from 'react';
import { useStoreValue } from '@tw/snipestate';

export const getPopoverOpenerButtonTitle = (
  selected: {
    start: moment.Moment;
    end: moment.Moment;
  } | null,
  labelOfDateRange?: string,
) => {
  const { start, end } = selected || {};
  if (!start || !end) {
    return 'No Comparison';
  }
  if (labelOfDateRange) {
    return labelOfDateRange;
  }
  const midnight = moment().startOf('day');
  if (start.isSame(midnight, 'day')) {
    return TODAY;
  }

  const midnightYesterday = moment().subtract(1, 'days').startOf('day');
  if (start.isSame(midnightYesterday, 'day') && end.isSame(midnightYesterday, 'day')) {
    return YESTERDAY;
  }

  const format = 'MMM D, YYYY';

  const isOneDay = start.isSame(end, 'day');
  if (isOneDay) {
    return start.format(format);
  }

  const isSameYear = start.isSame(end, 'year');
  const startFormat = isSameYear ? 'MMM D' : format;

  return start.format(startFormat) + ' - ' + end.format(format);
};

export const useCurrentPopoverOpenerButtonTitle = () => {
  const currentDateSelectedOption = useStoreValue($currentDateSelectedOption);
  const { start, end } = useStoreValue($currentDateRange) || {};
  const datePickerOptionValueOptions = useMemo(() => getDatePickerOptionValueOptions(), []);
  if (!start || !end) {
    return 'No Date Range';
  }
  let labelOfCurrentDateRange;
  if (
    // id &&
    // id !== 'custom' &&
    currentDateSelectedOption &&
    datePickerOptionValueOptions.find((option) => option.id === currentDateSelectedOption)
  ) {
    labelOfCurrentDateRange = datePickerOptionValueOptions.find(
      (option) => option.id === currentDateSelectedOption,
    )?.label;
  }

  return getPopoverOpenerButtonTitle({ start: start, end: end }, labelOfCurrentDateRange);
};

export const usePrevPopoverOpenerButtonTitle = () => {
  const prevDateRange = useStoreValue($prevDateRange);
  const currentDateRange = useStoreValue($currentDateRange);
  const prevDateSelectedOption = useStoreValue($prevDateSelectedOption);

  if (!prevDateRange || !currentDateRange) {
    return 'No Comparison';
  }

  const compareOptions = buildDatePickerCompareOptions(currentDateRange, prevDateRange);
  let labelOfPrevDateRange;

  if (
    prevDateSelectedOption &&
    compareOptions.find((option) => option.id === prevDateSelectedOption)
  ) {
    labelOfPrevDateRange =
      prevDateSelectedOption === 'custom'
        ? compareOptions.find((option) => option.id === prevDateSelectedOption)!.labelElement
        : compareOptions.find((option) => option.id === prevDateSelectedOption)!.label;
  }

  return getPopoverOpenerButtonTitle(
    prevDateRange
      ? {
          start: prevDateRange.start,
          end: prevDateRange.end,
        }
      : null,
    labelOfPrevDateRange,
  );
};
