import { WillyParameter } from './types/willyTypes';
import { useEffect, useMemo, useState } from 'react';
import { BqColumn, OptionsObj } from 'pages/FreeQuery/dataStuff/columns/types';
import { Popover, Text } from '@tw/ui-components';
import { BqTable } from 'pages/FreeQuery/dataStuff/tables';
import { emptyArray, executeCustomQuery } from './utils/willyUtils';
import { FORECAST_FAKE_TABLE, willyOperators } from './constants';
import { useAppSelector } from 'reducers/RootType';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { capitalize, noop } from 'lodash';
import { $dialect } from '$stores/$user';
import { $activeAccounts, $currency, $shopCurrency } from '$stores/$shop';
import { useStoreValue } from '@tw/snipestate';
import { EditParameter } from './EditParameter';
import { WillyDynamicFieldFilter } from './WillyDynamicFieldFilter';

type WillyDynamicFieldProps = {
  effectiveTables: BqTable[];
  parameter: WillyParameter;
  parameters: WillyParameter[];
  inPopover?: boolean;
  parametersChanged: (parameters: WillyParameter[]) => Promise<void>;
};

export const WillyDynamicField: React.FC<WillyDynamicFieldProps> = ({
  effectiveTables,
  parameter,
  parameters,
  inPopover,
  parametersChanged,
}) => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const currency = useStoreValue($currency);
  const shopTimezone = useAppSelector((state) => state.shopTimezone);
  const mainDatePickerSelectionRange = useAppSelector(
    (state) => state.mainDatePickerSelectionRange,
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [column, setColumn] = useState<BqColumn>();
  const [editedParameterModalOpen, setEditedParameterModalOpen] = useState<boolean>();
  const [options, setOptions] = useState<OptionsObj[]>([]);
  const [optionsFromQuery, setOptionsFromQuery] = useState<OptionsObj[]>([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

  const activeAccounts = useStoreValue($activeAccounts);
  const dialect = useStoreValue($dialect);

  const columnWithOptions = useDisabledOptions(column, parameters);

  const memoizedOptionsFromQuery = useDeepCompareMemoize(optionsFromQuery);

  useEffect(() => {
    (async () => {
      if (!mainDatePickerSelectionRange) {
        return;
      }
      const { start, end } = mainDatePickerSelectionRange;
      let optionsFromQuery: OptionsObj[] = [];
      if (!activeAccounts || !dialect) {
        return;
      }
      if (!parameter.query) {
        return;
      }
      try {
        setLoadingOptions(true);
        // find limit from query
        let query = parameter.query;
        const limit = query.match(/LIMIT\s+(\d+)/i);
        if (limit) {
          // set max limit to 100
          const limitValue = parseInt(limit[1]);
          if (limitValue > 100) {
            query = query.replace(/LIMIT\s+(\d+)/i, 'LIMIT 100');
          }
        } else {
          query = `${query} LIMIT 100`;
        }
        const data = await executeCustomQuery({
          query,
          shopId: currentShopId,
          activeAccounts,
          currency,
          timezone: shopTimezone,
          dialect,
          queryParams: {
            start_date: start.format('YYYY-MM-DD'),
            end_date: end.format('YYYY-MM-DD'),
          },
        });

        const options: OptionsObj[] =
          data?.data?.[0]?.value?.map((o) => {
            return {
              label: o?.toString() || '-',
              value: o?.toString() || '',
            };
          }) || [];
        optionsFromQuery = options;
        setOptionsFromQuery(optionsFromQuery);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingOptions(false);
      }
    })();
  }, [
    currency,
    currentShopId,
    parameter.query,
    shopTimezone,
    mainDatePickerSelectionRange,
    dialect,
    activeAccounts,
  ]);

  useEffect(() => {
    if (columnWithOptions?.options) {
      let options: { label: string; value: string }[] = columnWithOptions?.options || [];

      options = [{ label: '-', value: '' }, ...options];

      setOptions(options.concat(memoizedOptionsFromQuery));
      return;
    } else {
      const optionsFormParameter = parameter.options;
      if (!optionsFormParameter?.length) {
        setOptions(emptyArray<OptionsObj>().concat(memoizedOptionsFromQuery));
        return;
      }

      const fromParam = optionsFormParameter.map((o) => {
        return {
          label: o,
          value: o,
        };
      });

      setOptions(
        [
          {
            label: 'All',
            value: '',
          },
        ]
          .concat(fromParam)
          .concat(memoizedOptionsFromQuery),
      );
    }
  }, [
    columnWithOptions?.hasAllOption,
    columnWithOptions?.options,
    memoizedOptionsFromQuery,
    parameter.options,
  ]);

  useEffect(() => {
    const tablesToUse =
      parameter.sourceType === 'forecast' ? [FORECAST_FAKE_TABLE] : effectiveTables;

    let column = tablesToUse
      .map((t) => t.columns)
      .flat()
      .find((c) => c.id === parameter.column);

    if (!column && parameter.column) {
      column = {
        id: parameter.column,
        name: parameter.column,
        title: parameter.column.replace(/_/g, ' ').replace(/\w+/g, capitalize),
        type: 'string',
      };
    }

    if (column?.options && parameter?.options && parameter?.options?.length > 1) {
      const options = column.options.filter((o) => parameter.options?.includes(o.value));
      column = {
        ...column,
        options,
      };
    }
    setColumn(column);
  }, [effectiveTables, parameter]);

  const humanReadableValue = useMemo(() => {
    if (!parameter.value) {
      return '';
    }
    if (Array.isArray(parameter.value) && !parameter.value[0]) {
      return '';
    }
    if (parameter.operator === 'BETWEEN') {
      return `${parameter.value[0]} and ${parameter.value[1]}`;
    } else if (!column?.options) {
      return Array.isArray(parameter.value) ? parameter.value.join(', ') : parameter.value;
    }
    let val = parameter.value;
    if (!Array.isArray(val)) {
      val = [val];
    }

    const label = val.map((v) => {
      const option = column.options!.find((o) => o.value === v);
      return option?.label || v;
    });

    return label.join(', ');
  }, [parameter.value, column?.options, parameter.operator]);

  if (!inPopover) {
    return (
      <div className="flex items-center gap-2">
        <WillyDynamicFieldFilter
          parameters={parameters}
          parameter={parameter}
          parametersChanged={parametersChanged}
          options={options}
          column={column}
          loadingOptions={loadingOptions}
          setEditedParameterModalOpen={setEditedParameterModalOpen}
          close={noop}
        />
      </div>
    );
  }

  return (
    <>
      <Popover opened={isPopoverOpen} onClose={() => setIsPopoverOpen(false)}>
        <Popover.Target>
          <div
            className="rounded-lg border border-solid p-3 pt-0 relative overflow-visible min-w-44 max-w-115 group/handle cursor-pointer"
            style={{ borderColor: 'var(--primary-300)' }}
            onClick={() => {
              setIsPopoverOpen((x) => !x);
            }}
          >
            <div className="relative -left-2 px-2 -top-3 z-10 flex gap-1 truncate">
              <Text size="10px" weight="500" color="gray.5" bg="white" px="5px">
                {column?.title ||
                  parameter?.column?.replace(/_/g, ' ')?.replace(/\w+/g, capitalize)}{' '}
              </Text>
            </div>

            <div className="flex items-center gap-2">
              {!!parameter.operator && (
                <Text size="xs" color="gray.7" tt="lowercase">
                  {willyOperators[parameter.operator]?.label || parameter.operator}
                </Text>
              )}
              <Text size="xs" truncate color="gray.7">
                {humanReadableValue}
              </Text>
            </div>
          </div>
        </Popover.Target>

        <Popover.Dropdown>
          <div>
            <WillyDynamicFieldFilter
              parameters={parameters}
              parameter={parameter}
              parametersChanged={parametersChanged}
              close={() => {
                setIsPopoverOpen(false);
              }}
              options={options}
              column={column}
              loadingOptions={loadingOptions}
              setEditedParameterModalOpen={setEditedParameterModalOpen}
            />
          </div>
        </Popover.Dropdown>
      </Popover>

      <EditParameter
        open={!!editedParameterModalOpen}
        parameter={parameter}
        column={column}
        options={options}
        onClose={() => {
          setEditedParameterModalOpen(false);
        }}
        parameterChanged={async (parameter) => {
          const newParameters = parameters.map((p) => {
            if (p.column === parameter.column) {
              return parameter;
            }
            return p;
          });
          parametersChanged(newParameters);
        }}
      />
    </>
  );
};

export const useDisabledOptions = (column?: BqColumn, parameters?: WillyParameter[]) => {
  const disabledOptions = useMemo(() => {
    if (!column?.options) {
      return column;
    }
    if (column?.id === 'attribution_window') {
      return {
        ...column,
        options: column?.options?.map((o) => {
          if (o.value === '7_days') {
            return {
              ...o,
              disabled: false,
            };
          }
          return {
            ...o,
            disabled: parameters?.some((p) => p.column === 'model' && p.value === 'Total Impact'),
          };
        }),
      };
    }
    return column;
  }, [column, parameters]);

  return disabledOptions;
};
