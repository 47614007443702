import { ActionIcon, Flex, FormattedColor, Size, Text } from '@tw/ui-components';
import { FC, useMemo, useState } from 'react';

type BuilderBadgeProps = {
  text: string;
  type: 'table' | 'metric' | 'dimension' | 'filter';
  onRemove?: () => void;
  onClick?: () => void;
  recommended?: boolean;
  onHideChange?: () => void;
};

export const BuilderBadge: FC<BuilderBadgeProps> = ({
  text,
  type,
  onRemove,
  onClick,
  recommended,
  onHideChange,
}) => {
  const [hover, setHover] = useState(false);
  const bgColors: { bg: FormattedColor; hoverBg: FormattedColor } = useMemo(() => {
    switch (type) {
      case 'table':
        return { bg: 'purple.1', hoverBg: 'purple.2' };
      case 'metric':
        return { bg: 'two.1', hoverBg: 'two.2' };
      case 'dimension':
        return { bg: 'teal.1', hoverBg: 'teal.2' };
      case 'filter':
        return { bg: 'pink.1', hoverBg: 'pink.2' };
    }
  }, [type]);
  return (
    <Flex
      justify="space-between"
      align="center"
      mr="auto"
      p="xs"
      borderRadius="12px"
      bg={hover ? bgColors.hoverBg : bgColors.bg}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      w={200}
    >
      <Text size="sm" weight={500} color="gray.8" lh="16px" truncate>
        {text}
      </Text>
      {onRemove && hover && (
        <Flex gap="xs">
          {onHideChange && (
            <ActionIcon
              variant="transparent"
              icon="eye"
              iconSize={16}
              size={16 as Size}
              onClick={() => onHideChange()}
            />
          )}
          <ActionIcon
            variant="transparent"
            icon="close"
            iconSize={10}
            size={16 as Size}
            onClick={(e) => {
              onRemove();
              e.stopPropagation();
            }}
          />
        </Flex>
      )}
      {recommended && (
        <Text size="xs" weight={500} color="purple.8" lh="16px">
          Recommended
        </Text>
      )}
    </Flex>
  );
};
