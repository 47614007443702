import {
  ActionList,
  ActionListItemDescriptor,
  Button,
  IconSource,
  Popover,
} from '@shopify/polaris';
import { ShareMinor } from '@shopify/polaris-icons';
import GoogleSheetsIconBase64 from 'components/GoogleSheetsIconBase64';
import { useState } from 'react';
import { ShareGoogleSheetModal } from './ShareGoogleSheetModal';

export const ShareActionsMenu: React.FC<{
  disabaled: boolean;
  tableCSVData: string[][];
  viewTitle: string;
}> = ({ disabaled, tableCSVData, viewTitle }) => {
  const [googleSheetsModalOpen, setGoogleSheetsModalOpen] = useState(false);
  const [shareOptionsEnabled, setShareOptionsEnabled] = useState(false);

  const shareItems: ActionListItemDescriptor[] = [
    {
      content: 'Google Sheets',
      icon: GoogleSheetsIconBase64 as IconSource,
      onAction: () => {
        setShareOptionsEnabled(!shareOptionsEnabled);
        setGoogleSheetsModalOpen(true);
      },
    },
  ];
  return (
    <>
      <Popover
        active={shareOptionsEnabled}
        activator={
          <Button
            onClick={() => {
              setShareOptionsEnabled(!shareOptionsEnabled);
            }}
            disabled={disabaled}
            icon={ShareMinor}
          />
        }
        onClose={() => setShareOptionsEnabled(!shareOptionsEnabled)}
      >
        <ActionList items={shareItems} />
      </Popover>
      <ShareGoogleSheetModal
        title={viewTitle}
        tableCSVData={tableCSVData}
        googleSheetsModalOpen={googleSheetsModalOpen}
        onClose={() => setGoogleSheetsModalOpen(false)}
      />
    </>
  );
};
