import { CreativeTypes } from '@tw/types';
import { SuggestionAdProperties } from 'types/general';

export const MAX_ACTIVE_CREATIVES = 8;
export const CREATIVES_PER_PAGE = 12;
export const CREATIVES_NO_COPY_MESSAGE = '(No Copy)';

export const DISABLE_SHOW_IN_CHART_MESSAGE =
  'You can only compare data from up to 8 creatives at a time';

export const PLACEHOLDER_IMAGE_URL = '/image-placeholder.png';

export const TW_CREATIVE_TRENDS_PRIMARY_METRIC = 'TW_CREATIVE_TRENDS_PRIMARY_METRIC';

export const TW_CREATIVE_TRENDS_SECONDARY_METRIC = 'TW_CREATIVE_TRENDS_SECONDARY_METRIC';

export const TW_CREATIVE_COLUMNS = 'TW_CREATIVE_COLUMNS';
export const TW_CREATIVE_SORT_BY = 'TW_CREATIVE_SORT_BY';
export const TW_CREATIVE_PAGE_SIZE = 'TW_CREATIVE_PAGE_SIZE';

export type CreativeTypeElement<T> = {
  key: T;
  label: string;
  index?: number;
  hideInCategories?: boolean;
};

export type CreativeTypesDictionary = {
  [type in CreativeTypes]: CreativeTypeElement<type>;
};

export type BackgroundDiffusionThemeType = {
  title: string;
  img: string;
  prompt: string;
};

export const BackgroundDiffusionThemes: BackgroundDiffusionThemeType[] = [
  {
    title: 'Pebbles',
    img: 'pebbles',
    prompt: 'Natural hill and rocks surrounded by trees and fog in the background',
  },
  {
    title: 'Beach',
    img: 'beach',
    prompt:
      'Ripping water snowy mountains with cloudy sunny blue skies in the background, ice blocks in the foreground with snow falling, hyper realistic, zoom lense, bokeh, depth of field, very detailed, glossy',
  },
  { title: 'Flowers', img: 'flowers', prompt: 'Cherry blossoms' },
  { title: 'Coffee Shop', img: 'coffee_shop', prompt: 'A coffee shop' },
  { title: 'Flat', img: 'flat', prompt: 'flat background' },
  { title: 'Lightbox', img: 'lightbox', prompt: 'lightbox background' },
  { title: 'Dining Room Table', img: 'dining_room_table', prompt: 'dining room table' },
  { title: 'Forest', img: 'forest', prompt: 'forest' },
  { title: 'Garden', img: 'garden', prompt: 'garden' },
];

export const CreativeTypesElements: CreativeTypesDictionary = {
  ad: {
    key: 'ad',
    label: 'Ad',
    index: 0,
  },
  adName: {
    key: 'adName',
    label: 'Ad Name',
    index: 1,
  },
  image: {
    key: 'image',
    label: 'Image',
    index: 2,
  },
  video: {
    key: 'video',
    label: 'Video',
    index: 3,
  },
  copy: {
    key: 'copy',
    label: 'Copy',
    index: 4,
  },
  Segments: {
    key: 'Segments',
    label: 'Segments',
    index: 5,
  },
  product: {
    key: 'product',
    label: 'Product',
    index: 6,
    hideInCategories: true,
  },
  sku: {
    key: 'sku',
    label: 'SKU',
    index: 7,
    hideInCategories: true,
  },
};

export type BuiltAdType = {
  image: string | null;
  video?: SuggestionAdProperties | null;
  body: string | null;
  link?: string | null;
  title?: string | null;
  cta?: any;
  description?: string | null;
};

export interface NewFacebookCreative {
  titles: string[];
  bodies: string[];
  callToActionTypes: string[];
  pageId: string;
  imageUrls: string[];
  link: string;
  type: 'video' | 'image';
  linkDescription: string;
}

export interface NewFacebookCreativePreview extends NewFacebookCreative {
  accountId: string;
  page: string;
  ad_format: string;
  instagramAccountId?: string;
}

export const AD_CTA_OPTIONS = [
  { label: '(No button)', value: 'NO_BUTTON' },
  { label: 'Shop now', value: 'SHOP_NOW' },
  { label: 'Learn more', value: 'LEARN_MORE' },
];

export interface BodyCreativeAssetType extends CreativeAssetType {
  body: string;
}

export interface ImageCreativeAssetType extends CreativeAssetType {
  image: string;
}

export interface TitleCreativeAssetType extends CreativeAssetType {
  title: string;
}

export interface VideoCreativeAssetType extends CreativeAssetType {
  thumbnail: string;
  video_source?: string;
  account_id?: string;
  id?: string;
}

export type CreativeAssetType = {
  serviceId?: string;
  adId?: string;
  accountId: string;
  adName?: string;
  imageUrl?: string;
};
