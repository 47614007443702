import { TextField } from '@shopify/polaris';
import { WillyMetric } from './types/willyTypes';
import { useMemo } from 'react';
import { ActionIcon, Button, Checkbox, Select, TextInput } from '@tw/ui-components';
import { $tables } from '$stores/willy/$tables';
import { $combinedDashboard } from '$stores/willy/$combinedDashboards';
import { useStoreValue } from '@tw/snipestate';

type WillyMetricUrlsProps = {
  metric: WillyMetric;
  onChange: (metric: WillyMetric) => void;
};

export const WillyMetricUrls: React.FC<WillyMetricUrlsProps> = ({ metric, onChange }) => {
  const tables = useStoreValue($tables);
  const combinedDashboard = useStoreValue($combinedDashboard);

  const possibleColumn = useMemo(() => {
    const table = tables.find((t) => t.columns.some((c) => c.id === metric.key));
    return table?.columns.find((c) => c.id === metric.key);
  }, [tables, metric.key]);

  return (
    <div className="flex flex-col gap-4">
      {metric.onClickActionUrls?.map((url, index) => {
        return (
          <div className="flex items-center gap-4" key={index}>
            {metric.onClickAction !== 'externalUrl' && (
              <ActionIcon
                icon="close"
                color="red.7"
                onClick={() => {
                  const urls = metric.onClickActionUrls || [];
                  urls.splice(index, 1);
                  onChange({
                    ...metric,
                    onClickActionUrls: urls,
                  });
                }}
              />
            )}

            {!possibleColumn?.options && metric.onClickAction !== 'externalUrl' && (
              <TextField
                label="Value"
                placeholder='e.g. "Add to cart" or "Buy now" or "facebook-ads"'
                labelHidden
                autoComplete="off"
                value={url.value}
                onChange={(v) => {
                  const urls = metric.onClickActionUrls || [];
                  onChange({
                    ...metric,
                    onClickActionUrls: urls.map((u, i) => {
                      if (i === index) {
                        return {
                          value: v,
                          url: u.url,
                          openInNewTab: !!u.openInNewTab,
                        };
                      }
                      return u;
                    }),
                  });
                }}
              />
            )}
            {possibleColumn?.options && metric.onClickAction !== 'externalUrl' && (
              <Select
                allowDeselect={false}
                value={url.value}
                data={possibleColumn.options.map((o) => ({
                  label: o.label,
                  value: o.value,
                }))}
                onChange={(v) => {
                  if (!v) {
                    return;
                  }
                  const urls = metric.onClickActionUrls || [];

                  onChange({
                    ...metric,
                    onClickActionUrls: urls.map((u, i) => {
                      if (i === index) {
                        return {
                          value: v,
                          url: u.url,
                          openInNewTab: !!u.openInNewTab,
                        };
                      }
                      return u;
                    }),
                  });
                }}
              />
            )}
            {metric.onClickAction === 'externalUrl' && (
              <TextInput
                placeholder="e.g. https://www.example.com/{variable}?query={variable}"
                value={url.url}
                w="100%"
                onChange={(v) => {
                  const urls = metric.onClickActionUrls || [];
                  onChange({
                    ...metric,
                    onClickActionUrls: urls.map((u, i) => {
                      if (i === index) {
                        return { ...u, url: v };
                      }
                      return u;
                    }),
                  });
                }}
              />
            )}
            {metric.onClickAction === 'url' && (
              <Select
                allowDeselect={false}
                value={url.url}
                data={combinedDashboard.map((d) => ({
                  label: d.name || d.id,
                  value: `${location.origin}/dashboards/${d.id}`,
                }))}
                onChange={(v) => {
                  if (!v) {
                    return;
                  }
                  const urls = metric.onClickActionUrls || [];
                  onChange({
                    ...metric,
                    onClickActionUrls: urls.map((u, i) => {
                      if (i === index) {
                        return {
                          value: u.value,
                          url: v,
                          openInNewTab: !!u.openInNewTab,
                        };
                      }
                      return u;
                    }),
                  });
                }}
              />
            )}
            {/* <TextField
              label="URL"
              placeholder="e.g. https://www.facebook.com/ads"
              labelHidden
              autoComplete="off"
              value={url.url}
              onChange={(v) => {
                const urls = metric.onClickActionUrls || [];
                onChange({
                  ...metric,
                  onClickActionUrls: urls.map((u, i) => {
                    if (i === index) {
                      return {
                        value: u.value,
                        url: v,
                        openInNewTab: u.openInNewTab,
                      };
                    }
                    return u;
                  }),
                });
              }}
            /> */}
            {metric.onClickAction === 'url' && (
              <div className="flex-shrink-0">
                <Checkbox
                  label="New Window"
                  checked={!!url.openInNewTab}
                  onChange={(v) => {
                    const urls = metric.onClickActionUrls || [];
                    onChange({
                      ...metric,
                      onClickActionUrls: urls.map((u, i) => {
                        if (i === index) {
                          return {
                            value: u.value,
                            url: u.url,
                            openInNewTab: v,
                          };
                        }
                        return u;
                      }),
                    });
                  }}
                />
              </div>
            )}
          </div>
        );
      })}

      {metric.onClickAction !== 'externalUrl' && (
        <div>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              const urls = metric.onClickActionUrls || [];
              onChange({
                ...metric,
                onClickActionUrls: [
                  ...urls,
                  {
                    value: '',
                    url: '',
                    openInNewTab: false,
                  },
                ],
              });
            }}
          >
            Add URL
          </Button>
        </div>
      )}
    </div>
  );
};
