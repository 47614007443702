import { connect } from 'react-redux';
import { Button, Modal, OptionList } from '@shopify/polaris';
import {
  snapchatAccountsSelectOnChange,
  toggleSnapchatOrganizationsConfigScreenOpen,
  snapchatOrganizationsConfigSavePress,
  snapchatConnectOnPress,
} from '../ducks/snapchat';
import { $currentShopId } from '$stores/$shop';

const SnapchatOrganizationsConfig = ({
  isOpen,
  toggleIsOpen,
  accountsSelectOnChange,
  accounts,
  isLoading,
  save,
  snapchatAccountSearch,
}) => (
  <Modal
    title={accounts.length ? 'Select Snapchat ad accounts' : 'Connect to Snapchat'}
    open={isOpen}
    onClose={toggleIsOpen}
    primaryAction={{
      content: 'Save',
      onAction: save,
      disabled: !accounts.filter((acc) => acc.selected).length || isLoading,
    }}
    loading={isLoading}
    sectioned
  >
    {/*accounts.length > 1 &&
    <Fragment>
    <Stack>
      <Stack.Item fill>
        <Button
          disabled={!!snapchatAccountSearch.length}
          onClick={()=>{
            var accs = accounts.slice();
            accs.forEach(acc=>acc.selected = !allSelected );
            accountsSelectOnChange(accs)
          }}
        >{allSelected ? 'Unselect All' : 'Select All'}</Button>
      </Stack.Item>
      <Stack.Item>
        <TextField
          value={snapchatAccountSearch}
          placeholder="Search"
          clearButton
          onChange={searchOnChange}
          onClearButtonClick={()=>searchOnChange('')}
        />
      </Stack.Item>
    </Stack>
    <br />
    </Fragment>
        */}
    <OptionList
      //title="Select relevant accounts"
      onChange={(selected) => {
        var accs = accounts.slice();
        accs.forEach((acc) => (acc.selected = selected.includes(acc.id)));
        accountsSelectOnChange(accs);
      }}
      options={(snapchatAccountSearch.length
        ? accounts.filter(
            (a) =>
              a.name.toLowerCase().indexOf(snapchatAccountSearch.toLowerCase()) >= 0 ||
              String(a.id).indexOf(snapchatAccountSearch) >= 0,
          )
        : accounts
      ).map((account) => {
        return {
          value: account.id,
          label: account.name, // + ' (' + account.id + ')',
        };
      })}
      selected={accounts.filter((acc) => acc.selected).map((acc) => acc.id)}
      allowMultiple
    />
    {accounts.length === 0 && (
      <Modal.Section>
        <Button primary onClick={snapchatConnectOnPress}>
          Connect to Snapchat
        </Button>
      </Modal.Section>
    )}
  </Modal>
);

const mapStateToProps = ({
  snapchatConfigScreenIsOpen,
  facebookConfigInProgress,
  snapchatRemoteAccounts,
  snapchatAccountSearch,
}) => ({
  isOpen: snapchatConfigScreenIsOpen,
  //isLoading: facebookConfigInProgress || (!facebookAdsAccounts || !facebookAdsAccounts.length),
  accounts: snapchatRemoteAccounts || [],
  allSelected: snapchatRemoteAccounts
    ? snapchatRemoteAccounts.filter((acc) => acc.selected).length === snapchatRemoteAccounts.length
    : false,
  snapchatAccountSearch,
});

const mapDispatchToProps = (dispatch) => ({
  toggleIsOpen: () => dispatch(toggleSnapchatOrganizationsConfigScreenOpen()),
  accountsSelectOnChange: (value) => dispatch(snapchatAccountsSelectOnChange(value)),
  save: () => dispatch(snapchatOrganizationsConfigSavePress()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapchatOrganizationsConfig);
