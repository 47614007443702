import { $derived, $store } from '@tw/snipestate';
import { getShopImageUrl } from 'utils/getShopImageUrl';
import { $currentShopId } from '$stores/$shop';

const $currentShopImageURL = $derived((get) => getShopImageUrl(get($currentShopId) || ''));

/** This allows us to force realtime rerenders for the shop icon in store settings and the stores nav */
export const $currentShopImageId = $store(0);

export const $currentShopImage = $derived(
  (get) => get($currentShopImageURL) + '?refreshId=' + get($currentShopImageId),
);
