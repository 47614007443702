import { MouseEvent } from 'react';

type ChatIconProps = {
  className?: string;
  onClick?: (e: MouseEvent) => void;
};

export const ChatIcon: React.FC<ChatIconProps> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 390.78 291.48"
    >
      <defs>
        <style>
          {`.cls-1,.cls-2{fill:none;}.cls-3{fill:#0c70f2;fill-rule:evenodd;}.cls-2{clip-path:url(#clippath);}`}
        </style>
        <clipPath id="clippath">
          <rect className="cls-1" width="390.78" height="291.48" />
        </clipPath>
      </defs>
      <g className="cls-2">
        <path
          className="cls-3"
          d="m268.05,283.21c.51,4.5-3.13,8.26-7.63,8.26h-114.07c-4.4,0-7.83-3.43-7.83-7.83V124.63c0-4.07-3.1-7.5-7.17-8.13C107.33,112.76,30.23,93.87.43,10.36-1.3,5.53,2.34.13,7.47,0,32.33,0,92.84,0,93.78,0c71.31,3.13,124.82,48.75,146.35,110.73l.13-.13c17.92,51.66,25.75,144.52,27.79,172.61Z"
        />
        <path
          onClick={onClick}
          className="cls-3"
          style={{ cursor: 'pointer' }}
          d="m390.38,9.02c-28.09,80.82-85.85,99.58-119.23,106.57-3.56.56-6.99-1.6-7.93-5.03-10.63-37.62-25.42-58.22-44.62-75.99-3.25-3-3.13-8.26.64-10.63C241.71,9.08,269.45.08,304.07.05v-.05h79.77c4.7,0,8.13,4.63,6.53,9.02Z"
        />
      </g>
    </svg>
  );
};
