import { Segment, SegmentMetric } from '@tw/types/module/services/cdp';
import { DraggableModal } from 'components/DraggableProvider/DraggableModal';
import { lighthousePopupClose } from 'ducks/lighthouse';
import { useAppDispatch } from 'index';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { formatNumber } from 'utils/formatNumber';
import { LighthouseClientNotification } from './types';
import { ReactComponent as SyncIcon } from 'icons/sync.svg';
import { Loader } from '@tw/ui-components';
import { SEGMENT_METRICS } from 'components/CDP/segmentMetrics';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { useNavigate } from 'react-router-dom';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../$stores/$shop';

const segmentMetrics: SegmentMetric[] = [
  SegmentMetric.HISTORICAL_LTV_365_DAYS,
  SegmentMetric.ORDERS_COUNT,
  SegmentMetric.CUSTOMERS_COUNT,
];

export const LighthouseCDPAudience: React.FC<LighthouseClientNotification> = ({
  extraData,
  Icon,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { segmentsList } = useSelector((state: RootState) => state.CDPSegments);
  const [segmentData, setSegmentData] = useState<Segment>();
  const currency = useStoreValue($shopCurrency);
  const [loading, setLoading] = useState(false);
  const { isSegmentsListInitialized } = useSelector((state: RootState) => state.CDPSegments);

  const sync = useCallback(async () => {
    navigate('/cdp');
    dispatch(lighthousePopupClose());
  }, [dispatch, navigate]);

  const fetchSegmentData = useCallback(async () => {
    setSegmentData(segmentsList.find((s) => s.id === extraData?.segmentId));
    setLoading(false);
  }, [extraData?.segmentId, segmentsList]);

  useEffect(() => {
    fetchSegmentData();
  }, [fetchSegmentData]);

  useEffect(() => {
    if (!isSegmentsListInitialized) {
      dispatch(setCDPSegmentsFromServer());
    }
  }, [dispatch, isSegmentsListInitialized]);

  return (
    <DraggableModal
      onClose={() => {
        dispatch(lighthousePopupClose());
      }}
      open={true}
      width="medium"
      style={{ backgroundColor: '#11283E' }}
      className="p-6.5 min-h-[460px]"
    >
      <div className="w-full flex flex-col gap-6.5 bg-no-repeat text-white relative ">
        <div className="text-[24px] flex items-center gap-4">
          <div className="border border-solid border-[#2E4E65] rounded-[7px] p-2 flex items-center justify-center bg-primary">
            {Icon(40)}
          </div>
          "{segmentData?.name}" - AI Audience Generation
        </div>
        {loading && (
          <div className="flex items-center justify-center w-full h-full rounded-lg">
            <Loader />
          </div>
        )}
        {!loading && (
          <div
            className="text-center flex flex-col gap-9 items-center rounded-[4px] p-4 py-14 bg-cover bg-no-repeat"
            style={{
              backgroundImage: 'url(/World-Map.svg)',
            }}
          >
            {Object.entries(segmentData?.lastInstance || {})
              .filter(([key]) => segmentMetrics.includes(key as any))
              .map(([key, val], i) => {
                const metric = SEGMENT_METRICS.find((metric) => metric.key === key);
                const barWidth = 70 - i * 10;
                if (val === undefined) return null;

                return (
                  <div
                    key={key}
                    className={`flex flex-col border border-solid border-logo rounded-lg gap-2 py-4`}
                    style={{
                      background:
                        'radial-gradient(106.02% 106.02% at 49.69% 5.88%, rgba(24, 119, 242, 0.38) 0%, rgba(6, 41, 64, 0) 100%)',
                      backdropFilter: 'blur(6.5px)',
                      transform: 'matrix(1, 0, -0.01, 1, 0, 0)',
                      width: `${barWidth}%`,
                    }}
                  >
                    <span className="text-4xl">
                      {formatNumber(Number(val), {
                        style: metric?.format || 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                        currency,
                      })}
                    </span>
                    <span className="text-xl">{metric?.shortLabel}</span>
                  </div>
                );
              })}
            {segmentData && !segmentData.integrationsSyncDetails?.length && (
              <div className="flex justify-center pt-6.5">
                <div
                  className="rounded-md w-[180px] text-white cursor-pointer bg-logo p-3 flex items-center gap-4 justify-center font-semibold hover:font-semibold"
                  onClick={sync}
                >
                  <SyncIcon fill="white" width={14} />
                  Sync
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* </div> */}
    </DraggableModal>
  );
};
