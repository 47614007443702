import React from 'react';

const MobileQRCode: React.FC = () => {
  return (
    <div className="text-white text-center">
      <p className="font-medium text-[20px] mb-[8px]">Download the mobile app!</p>
      <p className="text-[15px] text-[#6a869c]">
        Don’t forget to download the Triple Whale mobile app to access your data from anywhere!
      </p>

      <div className="my-[24px] flex gap-[30px] justify-center">
        <img width="115" src="/qr/qr-code-apple.svg" alt="IOS QR code" />
        <img width="115" src="/qr/qr-code-google.svg" alt="ANDROID QR code" />
      </div>

      <p className="text-[13px] text-[#6a869c]">Scan the QR code to get the app.</p>
    </div>
  );
};

export default MobileQRCode;
