import { RootState, useAppSelector } from 'reducers/RootType';
import { useSelector } from 'react-redux';
import { Provider } from '@tw/types/module/services/account-manager';
import { IntegrationItem } from './IntegrationItem';
import { CredentialItem } from './CredentialItem';
import { confirm, Button, Text, Card, Box, Title, Flex, Grid, Stack } from '@tw/ui-components';
import DisconnectFromGeneralExplanation from 'components/DisconnectFromGeneralExplanation';
import { sensoryAccountDisconnect } from 'ducks/sensory';
import { useAppDispatch } from 'index';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export const ConnectionDetails = () => {
  const dispatch = useAppDispatch();
  const isSmall = useIsSmall();
  const providersIntegrations = useAppSelector((state) => state.sensory.providerAccounts);
  const sensoryProviderConfigData = useSelector(
    (state: RootState) => state.sensory.sensoryProviderConfigData as Provider,
  );
  const sensoryProviderConfigLoading = useAppSelector(
    (state) => state.sensory.sensoryProviderConfigLoading,
  );
  const disconnect = async () => {
    try {
      if (
        await confirm({
          title: `Disconnect from ${sensoryProviderConfigData.name}`,
          message: DisconnectFromGeneralExplanation,
        })
      ) {
        const credId: string = sensoryProviderConfigData?.credentials?.[0]?.id || '';
        await dispatch(sensoryAccountDisconnect(credId));
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Stack align="stretch">
      <Box m="sm">
        <Card shadow="sm">
          <Title>
            <Text fz="sm" weight={500}>
              Token
            </Text>
          </Title>
          {sensoryProviderConfigData?.credentials?.map((cred) => {
            const integrations = providersIntegrations.filter((int) =>
              int.credentials_id
                ? int.credentials_id == cred.id
                : int.provider_id == cred.provider_id,
            );

            return (
              !sensoryProviderConfigLoading && (
                <Box key={cred?.id}>
                  <CredentialItem credential={cred} integrations={integrations}></CredentialItem>
                </Box>
              )
            );
          })}
        </Card>

        <br />
        <Card shadow="sm">
          <Title>
            <Text fz="sm" weight={500}>
              Accounts
            </Text>
          </Title>

          {providersIntegrations
            .filter((int) => int.provider_id == sensoryProviderConfigData.id)
            .map((integration) => {
              return (
                <Box key={integration?.provider_account_id}>
                  <IntegrationItem integration={integration}></IntegrationItem>{' '}
                </Box>
              );
            })}
        </Card>

        <Text fz="sm" pt="lg">
          {`Want to connect an account not on this list? Disconnect above and select it when
          reconnecting. `}
          <a
            target="_blank"
            href={'https://kb.triplewhale.com/en/articles/8999221-integrations-troubleshooting'}
          >
            Read more here
          </a>
        </Text>
      </Box>
      <Flex justify="flex-end" mr="sm">
        <Button onClick={disconnect} mb="sm" aria-label={'Disconnect'} variant="white">
          Disconnect
        </Button>
      </Flex>
    </Stack>
  );
};
