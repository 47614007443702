import { BqColumn } from './columns/types';
import { getQueryWithoutPivot } from './utils';
import { $tabs } from '$stores/willy/$tables';
import { formatSqlSafely } from 'components/Willy/utils/willyUtils';

const exColumn = {
  name: 'platform',
  title: 'Platform',
  type: 'string',
  options: [
    {
      value: 'shopify',
      label: 'Shopify',
    },
    {
      value: 'amazon',
      label: 'Amazon',
    },
  ],
  multiSelect: true,
  id: 'platform',
};

const exOriginalQuery = `
select
  sum(gross_sales) gross_sales,
  platform,
from
  orders_table
where
  event_date = DATE_SUB(current_date(), INTERVAL 1 DAY)
group by
  platform
`;
const exResult = `
select * from (select
  sum(gross_sales) gross_sales,
  platform,
from
  orders_table
where
  event_date = DATE_SUB(current_date(), INTERVAL 1 DAY)
group by
  platform
) 
  PIVOT(SUM(gross_sales) FOR platform IN ('shopify', 'amazon'))
`;

export function applyPivot(tableId: string, column: BqColumn) {
  if (!column.options) return;
  const tabId = $tabs.get().find((t) => t.active)?.model?.id as string;
  let currentQuery = $tabs.get().find((t) => t.model?.id === tabId)?.query;
  if (!currentQuery) {
    return;
  }
  // if pivot already applied, remove it
  if (currentQuery.toLowerCase().includes('pivot')) {
    const { queryWithoutPivot } = getQueryWithoutPivot(currentQuery);
    const formattedQuery = formatSqlSafely(queryWithoutPivot);
    $tabs.set((old) => {
      return old.map((t) => {
        if (t.model?.id === tabId) {
          return {
            ...t,
            query: formattedQuery,
          };
        }
        return t;
      });
    });
    return;
  }
  // const extract aggregated columns from query
  const aggColumns = currentQuery
    .slice(0, currentQuery.toLowerCase().indexOf('from'))
    .split('\n')
    .filter((l) => {
      const lower = l.toLowerCase();
      return (
        lower.includes('sum') ||
        lower.includes('count') ||
        lower.includes('avg') ||
        lower.includes('max') ||
        lower.includes('min')
      );
    })
    .map((l) => {
      // extract alias by finding last ')'
      const lastParen = l.lastIndexOf(')');
      let alias = l.slice(lastParen + 1).trim();
      if (alias.toLowerCase().startsWith('as')) {
        alias = alias.slice(2).trim();
      }
      // remove trailing comma
      if (alias.endsWith(',')) {
        alias = alias.slice(0, -1);
      }
      return `sum(${alias}) ${alias}`;
    });

  // convert query to pivot using options from exColumn. the original query is exOriginalQuery, the result is exResult
  const pivotedQuery = `
    select * from (${currentQuery})
    PIVOT(${aggColumns.join(',')} FOR ${column.name} IN (${column.options
      .map((o) => `'${o.value}'`)
      .join(',')}))
  `;
  const formattedQuery = formatSqlSafely(pivotedQuery);
  $tabs.set((old) => {
    return old.map((t) => {
      if (t.model?.id === tabId) {
        return {
          ...t,
          query: formattedQuery,
        };
      }
      return t;
    });
  });
}
