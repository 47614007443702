import { Anchor, Loader, Text } from '@tw/ui-components';
import { Fragment } from 'react/jsx-runtime';
import { WillyDataSequence, WillyPrompt } from '../types/willyTypes';
import { PromptCategory, Topic } from '../useSamplePrompts';
import { Role } from '$stores/willy/$globalDashboardRoles';
import { PromptTile } from '../PromptTile';
import { WorkflowTemplateTile } from './WorkflowTemplateTile';
import { useNavigate } from 'react-router-dom';

type MixTemplatesProps = {
  onSelectPrompt: (value: WillyPrompt) => void;
  promptsToShow: WillyPrompt[];
  hasMore: boolean;
  loadNextPage: () => void;
  promptTopics: Topic[];
  loadingPrompts: boolean;
  loadingGlobalWorkflows: boolean;
  filteredWorkflows: WillyDataSequence[];
  workflowCategories: Role[];
  promptCategories: PromptCategory[];
};

export const MixTemplates: React.FC<MixTemplatesProps> = ({
  onSelectPrompt,
  promptsToShow,
  hasMore,
  loadNextPage,
  promptTopics,
  loadingPrompts,
  loadingGlobalWorkflows,
  filteredWorkflows,
  workflowCategories,
  promptCategories,
}) => {
  const navigate = useNavigate();
  const sections = [
    {
      id: 'prompt_library',
      title: 'Prompts',
      description: 'Prompts for quick insights and actionable analysis.',
      content: promptsToShow,
      tile: ({ item, onSelectPrompt, promptTopics, promptCategories }) => (
        <PromptTile
          prompt={item}
          onSuggestionClick={onSelectPrompt}
          promptTopics={promptTopics}
          promptCategories={promptCategories}
        />
      ),
      loading: loadingPrompts,
    },
    {
      id: 'workflows',
      title: 'Workflows',
      description:
        'Automated workflows for deeper analysis to save time and get powerful recommendations.',
      content: filteredWorkflows,
      tile: ({ item, workflowCategories }) => (
        <WorkflowTemplateTile workflow={item} workflowCategories={workflowCategories} />
      ),
      loading: loadingGlobalWorkflows,
    },
  ];

  return (
    <div className="flex flex-col gap-10">
      {sections.map((section, i) => {
        return (
          <div className="flex flex-col gap-8" key={`${section.id}-${i}`}>
            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <Text fw={600} fz={18}>
                  {section.title}
                </Text>
                <Anchor
                  onClick={() => {
                    navigate(
                      { pathname: '/chat/templates', search: `?tab=${section.id}` },
                      { replace: true },
                    );
                  }}
                >
                  <Text fw={500} fz={14} color="one.6">
                    See all
                  </Text>
                </Anchor>
              </div>
              <Text>{section.description}</Text>
            </div>
            {section.loading ? (
              <div className="flex justify-center py-10">
                <Loader />
              </div>
            ) : (
              <div
                key={section.id}
                className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-[30px] @3xl:!px-40 "
              >
                {section?.content?.slice(0, 4)?.map((item, i) => {
                  return (
                    <Fragment key={`${item.id}-${i}`}>
                      {section.tile({
                        item,
                        onSelectPrompt,
                        promptTopics,
                        workflowCategories,
                        promptCategories,
                      })}
                    </Fragment>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
