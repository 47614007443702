export const adColumns: any = [
  {
    key: 'status',
    heading: 'Status',
    sortable: true,
  },
  {
    key: 'name',
    heading: 'Name',
    sortable: true,
  },
  {
    key: 'link',
    heading: 'Link',
    sortable: false,
  },
  {
    key: 'fullAdSpend',
    heading: 'Total Ad Spend',
    sortable: true,
    style: 'currency',
  },
  {
    key: 'productSpend',
    heading: 'Product Ad Spend',
    sortable: true,
    style: 'currency',
  },
  {
    key: 'fullAdPixelRoas',
    heading: 'Ad ROAS',
    sortable: true,
    style: 'decimal',
  },
  {
    key: 'productPixelConversionValue',
    heading: 'Product CV',
    sortable: true,
    style: 'currency',
  },
  {
    key: 'pixelRoasWithoutProduct',
    heading: 'ROAS w/o Product',
    sortable: true,
    style: 'decimal',
  },
];
