import { useState, useMemo } from 'react';
import { FormLayout, TextField, Button, Spinner } from '@shopify/polaris';
import { Anchor, Button as TWButton, Flex, Modal, Text } from '@tw/ui-components';
import { ReactComponent as DatabaseSync } from 'components/Icons/database-sync.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/RootType';
import { User } from 'components/UserProfileManagment/User/constants';
import axiosInstance from 'utils/axiosInstance';

interface ISyncModal {
  showSyncModal: boolean;
  setShowSyncModal: (show: boolean) => void;
}

export const SyncModal: React.FC<ISyncModal> = ({ showSyncModal, setShowSyncModal }) => {
  const isAdmin = useSelector((state: RootState) => state.tokenDecoded?.admin);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const {
    email = '',
    firstName = '',
    lastName = '',
  } = useSelector((state: RootState) => state.user) as User;

  const [customBigqueryProject, setCustomBigqueryProject] = useState('');
  const [customBigqueryDataset, setCustomBigqueryDataset] = useState('');
  const [customBigqueryTable, setCustomBigqueryTable] = useState('');
  const [connectingCustomBigquery, setConnectingCustomBigquery] = useState(false);

  const googleFormUrl = useMemo(
    () =>
      `https://docs.google.com/forms/d/e/1FAIpQLSdSmzqACH0WpC61isF5Zp6Q-315Y0at9Vxmq_pDqf_zc80I5w/viewform?usp=pp_url&entry.91738528=${firstName}&entry.1636481006=${lastName}&entry.650310218=${email}&entry.1051815820=${currentShopId}`,
    [currentShopId, email, firstName, lastName],
  );

  const connectCustomBigquery = async () => {
    setConnectingCustomBigquery(true);
    const { data } = await axiosInstance.post('/v2/willy/connect-to-custom-bigquery', {
      shopId: currentShopId,
      projectId: customBigqueryProject,
      datasetId: customBigqueryDataset,
      tableId: customBigqueryTable,
    });
    setConnectingCustomBigquery(false);
    setShowSyncModal(false);
  };

  return isAdmin ? (
    <div className={'flex justify-end'}>
      <Modal
        opened={showSyncModal}
        title="Connect BigQuery"
        onClose={() => setShowSyncModal(false)}
        size="lg"
      >
        <FormLayout>
          <Text>
            In order to connect to your BigQuery project, you need to share the table with the
            following service account:
            <b> srv-willy@shofifi.iam.gserviceaccount.com</b>
          </Text>
          <TextField
            value={customBigqueryProject}
            label="Project ID"
            type="text"
            onChange={setCustomBigqueryProject}
            autoComplete="off"
            helpText=""
          ></TextField>
          <TextField
            value={customBigqueryDataset}
            label="Dataset"
            type="text"
            onChange={setCustomBigqueryDataset}
            autoComplete="off"
            helpText=""
          ></TextField>
          <TextField
            value={customBigqueryTable}
            label="Table"
            type="text"
            onChange={setCustomBigqueryTable}
            autoComplete="off"
            helpText=""
          ></TextField>
          <Button
            disabled={!customBigqueryProject || !customBigqueryDataset || !customBigqueryTable}
            onClick={connectCustomBigquery}
          >
            Send
          </Button>
          {connectingCustomBigquery && <Spinner />}
        </FormLayout>
      </Modal>
    </div>
  ) : (
    <div className={'flex justify-end'}>
      <Modal
        size="md"
        padding="lg"
        opened={showSyncModal}
        title={
          <Flex align="center" justify="start">
            <div className="bg-[#E0F7FF] text-white w-[40px] h-[40px] flex items-center justify-center rounded-full mr-4 shrink-0">
              <DatabaseSync width="23" height="23" fill="#0B9ED4" />
            </div>
            <Text fz="xl" fw={600}>
              Sync Your Warehouse
            </Text>
          </Flex>
        }
        onClose={() => setShowSyncModal(false)}
      >
        <Text mb="lg">
          Already have a data warehouse? Get in touch with our team about syncing it with Triple
          Whale.
        </Text>
        <Flex align="start" justify="start">
          <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
            <Text size="sm" color="white">
              1
            </Text>
          </div>
          <Text mb="lg">
            You will be redirected to a Google Form where you can describe your use case.
          </Text>
        </Flex>
        <Flex align="start" justify="start">
          <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
            <Text size="sm" color="white">
              2
            </Text>
          </div>
          <Text mb="lg">
            We will review your request within 24h, and give you a time estimate for completing the
            sync.
          </Text>
        </Flex>
        <Flex align="start" justify="start">
          <div className="bg-[#10AEE8] w-[27px] h-[27px] flex items-center justify-center rounded-full mr-4 mb-4 shrink-0">
            <Text size="sm" color="white">
              3
            </Text>
          </div>
          <Text mb="lg">There is no charge for this service.</Text>
        </Flex>
        <Flex gap="sm" justify="flex-end" mt="xl">
          <TWButton variant="white" onClick={() => setShowSyncModal(false)}>
            Cancel
          </TWButton>
          <Anchor href={googleFormUrl} target="_blank">
            <TWButton
              onClick={() => {
                setShowSyncModal(false);
              }}
            >
              Go to Google Form
            </TWButton>
          </Anchor>
        </Flex>
      </Modal>
    </div>
  );
};
