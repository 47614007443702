import { chain, meanBy, sumBy, take } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { CohortFrameHeader, CohortTile, constTiles } from './CohortTableTiles';
import { TWInfoTooltip } from 'components/library/TWTooltip/TWInfoTooltip';
import {
  CohortTable as CohortTableType,
  CohortsMetric,
  TABLE_COLORS,
  CohortRow,
  TableViewOptions,
  CohortMetricsObj,
} from './constants';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

const CohortsTable: React.FC<{
  tableData: CohortTableType;
  tableViewOptions: TableViewOptions;
  setTableRef;
}> = ({ tableData, tableViewOptions, setTableRef }) => {
  const metricsList = Object.values(CohortsMetric);
  const { ltvSelectedCDPSegment } = useSelector((state: RootState) => state.ltv);
  const { appliedFilters } = useSelector((state: RootState) => state.insightsFilter);

  const summarizeFramesMetrics = useCallback(
    (frames, isAverage = false): CohortMetricsObj => {
      const func = isAverage ? meanBy : sumBy;
      return chain(metricsList)
        .keyBy()
        .mapValues((key) =>
          func(
            frames.map((f) => f.metrics),
            key,
          ),
        )
        .value() as CohortMetricsObj;
    },
    [metricsList],
  );

  const cohortsWithMetrics: CohortRow[] = useMemo(() => {
    if (!tableData.cohorts) return [];

    return tableData.cohorts.map((cohort) => {
      return {
        ...cohort,
        frames: cohort.frames.map((frame) => {
          const metrics = tableViewOptions.secondOrderOnly
            ? frame.secondOrderOnlyMetrics || frame.metrics
            : frame.metrics;
          return {
            ...frame,
            metrics,
          };
        }),
      };
    });
  }, [tableData.cohorts, tableViewOptions.secondOrderOnly]);

  const framesKeys = useMemo(() => {
    if (!cohortsWithMetrics) return [];

    return chain(cohortsWithMetrics)
      .flatMap((cohort) => cohort.frames)
      .map((f) => f.key)
      .uniq()
      .sortBy()
      .value();
  }, [cohortsWithMetrics]);

  const cumulativeCohorts: CohortRow[] = useMemo(() => {
    return cohortsWithMetrics.map((cohort) => {
      return {
        ...cohort,
        frames: cohort.frames.map((frame, i) => {
          return {
            ...frame,
            metrics: summarizeFramesMetrics(take(cohort.frames, i + 1)),
          };
        }),
      };
    }) as CohortRow[];
  }, [cohortsWithMetrics, summarizeFramesMetrics]);

  const cohorts: CohortRow[] = useMemo(() => {
    return tableViewOptions.isCumulative ? cumulativeCohorts : cohortsWithMetrics;
  }, [cohortsWithMetrics, tableViewOptions.isCumulative, cumulativeCohorts]);

  const tableValuesRange: { [key in CohortsMetric]: { min: number; max: number } } = useMemo(() => {
    const allFrames = cohorts.flatMap((cohort) => cohort.frames).map((frame) => frame.metrics);

    const metricTableValues = metricsList.reduce((metrics, metric) => {
      const metricValues = allFrames.map((frame) => frame[metric]);
      const min = Math.min(...metricValues);
      const max = Math.max(...metricValues);
      metrics[metric] = { min, max };
      return metrics;
    }, {});

    return metricTableValues as { [key in CohortsMetric]: { min: number; max: number } };
  }, [cohorts, metricsList]);

  const cohortsAverages = useMemo((): CohortRow => {
    return {
      key: 'average',
      size: undefined,
      NCPA: undefined,
      RPR: undefined,
      frames: framesKeys.map((key) => ({
        key: key,
        metrics: summarizeFramesMetrics(
          cohorts
            .map((c) => c.frames)
            .flat()
            .filter((f) => f.key === key),
          true,
        ),
      })),
    } as unknown as CohortRow;
  }, [cohorts, framesKeys, summarizeFramesMetrics]);

  const cohortsTotals = useMemo((): CohortRow => {
    const totalSize = sumBy(cohortsWithMetrics, CohortsMetric.SIZE);
    const totalSpend = sumBy(cohortsWithMetrics, 'totalSpend');

    const totalReturnCustomers = sumBy(
      cohortsWithMetrics
        .map((c) => c.frames)
        .flat()
        .filter((f) => f.key > -1)
        .map((f) => f.secondOrderOnlyMetrics),
      CohortsMetric.SIZE,
    );

    return {
      key: 'totals',
      size: totalSize,
      totalSpend,
      NCPA: totalSpend / totalSize,
      RPR: (totalReturnCustomers / totalSize) * 100,
      frames: [],
    };
  }, [cohortsWithMetrics]);

  const cohortNCPAPaybackIndexes = useMemo((): number[] => {
    return cumulativeCohorts.map((cohort) => {
      return cohort.frames.findIndex(
        (frame) => frame.metrics.total_sales / cohort.size >= cohort.NCPA || 0,
      );
    });
  }, [cumulativeCohorts]);

  const getColorGroupForValue = useCallback(
    (value: number, metric: CohortsMetric) => {
      const ranges = tableValuesRange[metric];

      const groupsCount = TABLE_COLORS.length;
      const groupSize = Math.ceil((ranges.max + 0.1 - ranges.min) / groupsCount);
      const groupIndex = Math.floor((value - ranges.min) / groupSize) || 0;

      return groupIndex;
    },
    [tableValuesRange],
  );

  if (!tableData) return <></>;

  return (
    <table className="w-full" ref={setTableRef}>
      <thead className="text-[#0C3B78] text-[10px] font-medium">
        <tr>
          {constTiles.map((col, index) => (
            /* Const cols */
            <th
              className={`${col.headerStyle} ${
                index === 0 ? 'sticky left-0 bg-white dark:bg-primary z-10' : ''
              }`}
              key={index}
            >
              <div className="min-w-[62px] mb-[10px]">
                {col.tooltipContent ? (
                  <div className="flex justify-end gap-[5px] items-center">
                    {col.title}
                    <TWInfoTooltip tooltipContent={col.tooltipContent} tooltipMaxWidth={278} />
                  </div>
                ) : (
                  <span>{col.title}</span>
                )}
              </div>
            </th>
          ))}

          {framesKeys.map((key) => (
            /* Dynamic frames cols */
            <th key={key}>
              <CohortFrameHeader
                key={key}
                frameKey={key}
                frameBreakDown={tableData.frameBreakDown}
              />
            </th>
          ))}
        </tr>
      </thead>

      <tbody className="font-medium text-[12px] text-[#0C3B78] text-center">
        {cohorts.concat(...[cohortsTotals, cohortsAverages]).map((cohort, cohortIndex) => (
          <tr key={cohortIndex}>
            {constTiles.map((col, index) => (
              /* Const cols */
              <td
                key={index}
                className={index === 0 ? 'sticky left-0 bg-white dark:bg-primary z-10' : ''}
              >
                {cohort[col.metric] !== undefined &&
                  React.createElement(col.component, {
                    breakdown: tableData.cohortBreakdown,
                    timeFrame: tableData.cohortBreakdownTimeFrame,
                    value: cohort[col.metric],
                  })}
              </td>
            ))}

            {framesKeys.map((key, frameIndex) => {
              /* Dynamic frames cols */
              const frame = cohort.frames.find((f) => f.key === key);

              return (
                <td key={frameIndex}>
                  {frame && (
                    <CohortTile
                      metrics={frame.metrics}
                      metric={tableViewOptions.metric}
                      colorGroup={
                        cohort.key === 'average'
                          ? undefined
                          : getColorGroupForValue(
                              frame.metrics[tableViewOptions.metric],
                              tableViewOptions.metric,
                            )
                      }
                      showNCPAPaybackIndication={
                        !ltvSelectedCDPSegment &&
                        !appliedFilters?.length &&
                        tableViewOptions.showNCPAPayback &&
                        cohortNCPAPaybackIndexes[cohortIndex] === frameIndex
                      }
                    />
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CohortsTable;
