import { ServicesIds } from '@tw/types/module/services';
import { ServiceAccountData } from '@tw/types/module/types/ShopServiceData';
import { combineReducers } from 'redux';
import { shopServicesDb } from '../utils/DB';
import firebase from 'firebase/compat/app';
import { GOOGLE_ADS_FETCH_ACCOUNTS_ERROR } from './constants';

export const SHOP_SERVICES_RECEIVED = 'SHOP_SERVICES_RECEIVED';
export const SHOP_SERVICES_IS_LOADING = 'SHOP_SERVICES_IS_LOADING';
const firestore = firebase.firestore;

export const loadShopServices = () => {
  return (dispatch, getState) => {
    dispatch({ type: SHOP_SERVICES_IS_LOADING });
    return shopServicesDb().onSnapshot((snapshot) => {
      var data = snapshot.docs.reduce((result, service) => {
        result[service.id] = service.data();
        return result;
      }, {});
      dispatch({
        type: SHOP_SERVICES_RECEIVED,
        shopServices: data,
      });
    });
  };
};

export const shopServiceDisconnect = async (serviceId: ServicesIds) => {
  try {
    if (serviceId && isSupportShopServices(serviceId)) {
      await shopServicesDb().doc(serviceId).update({
        accounts: firestore.FieldValue.delete(),
      });
    }
  } catch (e) {
    console.log('error', JSON.stringify(e));
  }
};

export const isInvalidServiceConnected = (
  serviceId: ServicesIds | undefined,
  data: any,
  defaultInvalidConnection: boolean,
  defaultConnectionErrorReason: string = '',
): [boolean, string] => {
  if (serviceId && isSupportShopServices(serviceId)) {
    try {
      if (data) {
        let accounts = data[serviceId]?.accounts as { [accountId: string]: ServiceAccountData };
        const invalidAccount =
          accounts && Object.values(accounts).find((account) => account?.invalidConnection);
        return [!!invalidAccount, invalidAccount?.invalidConnection?.invalidStateReason ?? ''];
      }
      return [false, ''];
    } catch (e) {
      return [false, ''];
    }
  } else return [defaultInvalidConnection, defaultConnectionErrorReason];
};

const loadingShopServices = (state = true, action) => {
  switch (action.type) {
    case SHOP_SERVICES_RECEIVED:
      return false;
    default:
      return state;
  }
};

function isSupportShopServices(serviceId: ServicesIds): boolean {
  const supported: ServicesIds[] = ['tiktok-ads', 'facebook-ads'];
  return supported.includes(serviceId);
}

const shopServices = (state = [], action) => {
  switch (action.type) {
    case SHOP_SERVICES_RECEIVED:
      return action.shopServices || state;
    default:
      return state;
  }
};

const TOGGLE_INTEGRATION_CONNECTION_ERROR_OPEN = 'TOGGLE_INTEGRATION_CONNECTION_ERROR_OPEN';
export const onToggleIntegrationConnectionErrorMsgOpen = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_INTEGRATION_CONNECTION_ERROR_OPEN });
};
const TOGGLE_INTEGRATION_CONNECTION_ERROR_CLOSE = 'TOGGLE_INTEGRATION_CONNECTION_ERROR_CLOSE';
export const onToggleIntegrationConnectionErrorMsgClose = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_INTEGRATION_CONNECTION_ERROR_CLOSE });
};

const isIntegrationConnectionErrorMsgOpen = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_INTEGRATION_CONNECTION_ERROR_OPEN:
      return !state;
    case TOGGLE_INTEGRATION_CONNECTION_ERROR_CLOSE:
      return false;
    default:
      return state;
  }
};

type AccountsErrorState = {
  [id in ServicesIds]?: string;
};

const fetchAccountsError = (state: AccountsErrorState = {}, action): AccountsErrorState => {
  switch (action.type) {
    case GOOGLE_ADS_FETCH_ACCOUNTS_ERROR:
      return {
        ...state,
        'google-ads': action.payload,
      };
    default:
      return state;
  }
};

export const reducers = combineReducers({
  shopServices,
  loadingShopServices,
  fetchAccountsError,
  isIntegrationConnectionErrorMsgOpen,
});
