import { openCustomizePromptsPopup } from '$stores/willy/$customizePromptPopup';
import { useStoreValue } from '@tw/snipestate';
import { Container, Group, Icon, IconName, Menu, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { Fragment, useState } from 'react';
import { analyticsEvents, chatActions, genericEventLogger } from 'utils/dataLayer';
import { PromptTopic } from './WillyMainChat';
import { PromptCategory, Topic } from './useSamplePrompts';

type PromptCategorySelectProps = {
  activePromptCategory: string | null;
  setActivePromptCategory: (value: string | null) => void;
  mainPage?: boolean;
  promptTopics: Topic[];
  categories: PromptCategory[];
};

export const PromptCategorySelect: React.FC<PromptCategorySelectProps> = ({
  activePromptCategory,
  setActivePromptCategory,
  mainPage,
  promptTopics,
  categories,
}) => {
  const isSmall = useIsSmall();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const activeCategory =
    categories.length > 0
      ? categories.find((cat) => cat.id === activePromptCategory) ?? {
          id: null,
          name: 'All Prompts',
          icon: 'grid-all',
          topic: '',
        }
      : undefined;

  const activeTopic = promptTopics.find((topic) => topic.id === activeCategory?.topic);

  const openPopover = () => {
    setPopoverOpen((o) => !o);
    if (mainPage) {
      genericEventLogger(analyticsEvents.CHAT, {
        action: chatActions.OPEN_PROMPT_CATEGORY_SELECT,
      });
    } else {
      genericEventLogger(analyticsEvents.PROMPT_LIBRARY, {
        action: chatActions.OPEN_PROMPT_CATEGORY_SELECT,
      });
    }
  };

  const _setActivePromptCategory = (value: string | null) => {
    setActivePromptCategory(value);
  };

  return (
    <Menu
      offset={mainPage && !isSmall ? 0 : 0}
      width="target"
      position="bottom"
      opened={popoverOpen}
      onChange={setPopoverOpen}
    >
      <Menu.Target>
        {mainPage ? (
          <div
            className={`rounded-xl bg-gray-50 border border-solid border-gray-200 p-2 md:p-4 w-[330px] flex justify-center gap-4 items-center text-[16px] cursor-pointer ${popoverOpen ? 'rounded-b-none' : ''}`}
            onClick={() => openPopover()}
          >
            <span className={`flex justify-center gap-4 items-center `}>
              <span className={`flex items-center ${activeCategory ? 'opacity-100' : 'opacity-0'}`}>
                <Icon
                  name={activeCategory?.icon || 'grid-all'}
                  size={18}
                  color={activeTopic?.color || 'one.5'}
                />
              </span>
              <Text fz="inherit" fw={500} lineClamp={1}>
                {activeCategory?.name || ''}
              </Text>
              <span
                className={`flex items-center ${popoverOpen ? 'rotate-180' : ''} ${activeCategory ? 'opacity-100' : 'opacity-0'}`}
              >
                <Icon name="caret-down" size={13} color="gray.4" />
              </span>
            </span>
          </div>
        ) : (
          <div
            className={`cursor-pointer rounded-lg border border-solid border-gray-200 flex gap-4 py-3 md:py-0 px-6 items-center text-[14px] justify-between w-full ${popoverOpen ? 'rounded-b-none' : ''}`}
            onClick={() => setPopoverOpen((o) => !o)}
          >
            <span className="flex gap-3 items-center">
              <Icon
                name={activeCategory?.icon || 'grid-all'}
                size={18}
                color={activeTopic?.color || 'one.5'}
              />
              <Text fz="inherit" fw={500} lineClamp={1}>
                {activeCategory?.name || 'All Prompts'}
              </Text>
            </span>
            <span className={`flex items-center ${popoverOpen ? 'rotate-180' : ''}`}>
              <Icon name="caret-down" size={13} color="gray.4" />
            </span>
          </div>
        )}
      </Menu.Target>
      <Menu.Dropdown
        mah={mainPage ? 300 : 500}
        style={{
          overflowY: 'auto',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: 'none',
        }}
      >
        <Menu.Item
          leftSection={<Icon name={'grid-all'} size={18} color={'one.5'} />}
          px={mainPage ? 'lg' : 'lg'}
          py="xs"
          pb={0}
          onClick={() => _setActivePromptCategory(null)}
        >
          <Text fw={500} fz={mainPage ? 16 : 14}>
            All Prompts
          </Text>
        </Menu.Item>

        {promptTopics.map((topic) => {
          const topicCategories = categories.filter((cat) => cat.topic === topic.id);
          return (
            <Fragment key={topic.id}>
              <Menu.Label px={mainPage ? 'md' : 'xs'} pt={mainPage ? 'sm' : 'xs'}>
                <Text fz={mainPage ? 16 : 14} fw={500}>
                  {topic.name}
                </Text>
              </Menu.Label>
              {topicCategories?.map((cat) => {
                return (
                  <Menu.Item
                    key={cat.id}
                    onClick={() => {
                      _setActivePromptCategory(cat.id);
                    }}
                    leftSection={
                      cat?.icon && <Icon name={cat.icon} size={18} color={topic?.color} />
                    }
                    px={mainPage ? 'lg' : 'lg'}
                    py="xs"
                  >
                    <span className="flex justify-between group items-center">
                      <Text fw={500} fz={mainPage ? 16 : 14}>
                        {cat.name}
                      </Text>
                      {cat.name === 'For You' && (
                        <div
                          className="md:opacity-0 group-hover:opacity-100 transition-opacity flex items-center cursor-pointer"
                          onClick={() => openCustomizePromptsPopup()}
                        >
                          <Icon name="filled-pencil" size={18} color="gray.4" />
                        </div>
                      )}
                    </span>
                  </Menu.Item>
                );
              })}
            </Fragment>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
