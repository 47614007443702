//get optional plans
//get current plan for store
import { ActionCodeOperation } from 'firebase/auth';
import axiosInstance from 'utils/axiosInstance';

export const GET_CURRENT_SHOP_PLAN = 'GET_CURRENT_SHOP_PLAN';

const currentPlan = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_SHOP_PLAN: {
      return action.currentPlan || state;
    }
    default: {
      return state;
    }
  }
};

export const reducers = {
  currentPlan,
};
