import { FeatureFlag, FeatureFlagResult } from '@tw/feature-flag-system/module/types';
import { useFeatureFlagComputer } from './featureFlagComputer';
import { useEffect, useMemo, useState } from 'react';
import { $unblockBlockedByPlan } from '../$stores/$user';
import { useStoreValue } from '@tw/snipestate';

// To be flexible, just giving all wrapped components access to metadata and result for feature flag
type FeatureFlagProps = {
  featureFlagConfigs: FeatureFlagResult[];
};
type WithFeatureFlagConfig<T> = T & FeatureFlagProps;

/**
 * @description Higher order function used to automatically hide wrapped component based on feature flag
 * id provided. Also provides metadata and result value for feature flag config based on id provided.
 * @param featureFlag FeatureFlag - Depending on the config for this feature flag, we show/hide component
 * as well as pass it different config params unique to that flag.
 * @param Component Component to show if feature flag config says user can access this feature.
 * @param FallbackComponent Component to show if user can't access feature.
 * @returns Enhanced component with config for provided feature flag.
 */
export function computeFeatureFlags<T = any>(
  featureFlag: FeatureFlag | FeatureFlag[],
  Component: React.FC<WithFeatureFlagConfig<T>>,
  FallbackComponent?: React.FC<WithFeatureFlagConfig<T>>,
): React.FC<T> {
  const features = Array.isArray(featureFlag) ? featureFlag : [featureFlag];

  return function (props: T) {
    const ffComputer = useFeatureFlagComputer();
    const [ffConfigs, setFFConfigs] = useState(() =>
      features.map((f) => ffComputer.getConfigById(f)),
    );
    const shouldNotBeSeen = useMemo(
      () => ffConfigs.reduce((acc, f) => acc && f.shouldNotBeSeen, true),
      [ffConfigs],
    );
    const unblockBlockedByPlan = useStoreValue($unblockBlockedByPlan);

    useEffect(() => {
      const updateFFConfigs = () => {
        const results = features.map((f) => ffComputer.getConfigById(f));
        setFFConfigs(results);
      };
      ffComputer.addUpdateListener(updateFFConfigs);
      return () => {
        ffComputer.removeUpdateListener(updateFFConfigs);
      };
    }, [ffComputer]);

    const enhancedProps: WithFeatureFlagConfig<T> = {
      ...props,
      featureFlagConfigs: ffConfigs,
    };

    if (shouldNotBeSeen && !unblockBlockedByPlan) {
      return FallbackComponent ? <FallbackComponent {...enhancedProps} /> : null;
    }

    return <Component {...enhancedProps} />;
  };
}
