import { Button, TextContainer } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

const DisconnectFromEnquirelabsExplanation = () => {
  const token = useSelector((state: RootState) => state.enquirelabsToken);
  const apiKey = useSelector((state: RootState) => state.enquirelabsApiKey);

  return token ? (
    <div className={'mb-5'}>
      <TextContainer>
        <p>Note: You can reconnect back again later</p>
        <p>
          EnquireLabs Token : {token}{' '}
          <Button
            onClick={() => {
              navigator.clipboard.writeText(token);
            }}
          >
            Copy to clipboard
          </Button>
        </p>
        <p>API Key : {apiKey}</p>
      </TextContainer>
    </div>
  ) : null;
};

export default DisconnectFromEnquirelabsExplanation;
