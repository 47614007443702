import { Modal } from '@shopify/polaris';
import { hidePrompt } from '../ducks/actions';
import { FC } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { useAppDispatch } from 'index';

const Prompt: FC = () => {
  const dispatch = useAppDispatch();
  const promptSettings = useAppSelector((state) => state.promptSettings);

  const {
    title = null,
    children = null,
    primaryAction = undefined,
    secondaryAction = undefined,
  } = promptSettings || {};

  return (
    <Modal
      open={!!promptSettings}
      onClose={() => dispatch(hidePrompt())}
      title={title}
      primaryAction={primaryAction}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            dispatch(hidePrompt());
            secondaryAction && secondaryAction();
          },
        },
      ]}
    >
      <Modal.Section>{children}</Modal.Section>
    </Modal>
  );
};

export default Prompt;
