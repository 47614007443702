import { useCallback, useMemo } from 'react';
import { TimeFrame } from '@tw/types/module/services/insights';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { getOptionLabel } from './constants';
import DropDown from 'components/ltv/DropDown';
import { setInsightsTimeframe } from 'ducks/insights/actions';
import { analyticsEvents, insightsActions, genericEventLogger } from 'utils/dataLayer';
import InsightsFilterField from '../InsightFilterField';
import { isTimeFrameEnabled } from '../isTimeFrameEnabled';

export const TimeframeFilter = () => {
  const dispatch = useAppDispatch();
  const TIME_FRAME_OPTIONS = Object.values(TimeFrame);
  const timeFrameOptions = TIME_FRAME_OPTIONS.map((o) => ({ label: getOptionLabel(o), value: o }));
  const timeframe = useSelector((state: RootState) => state.insights.timeframe);
  const dateRange = useSelector((state: RootState) => state.insights.dateRange);

  const filteredOptions = useMemo(() => {
    return timeFrameOptions.filter((i) =>
      isTimeFrameEnabled(i.value, dateRange?.start, dateRange?.end),
    );
  }, [timeFrameOptions, dateRange?.start, dateRange?.end]);

  const handleTimeframeChange = useCallback(
    (value: TimeFrame) => {
      genericEventLogger(analyticsEvents.COHORTS, {
        action: insightsActions.FILTER_CHANGED,
        filterType: 'timeframe',
        value: value,
      });
      dispatch(setInsightsTimeframe(value));
    },
    [dispatch],
  );

  return (
    <>
      <InsightsFilterField label="Timeframe">
        <DropDown
          handleSelect={handleTimeframeChange}
          options={filteredOptions}
          value={timeframe}
        ></DropDown>
      </InsightsFilterField>
    </>
  );
};
