import db from '../utils/DB';
import { fetchProduct } from './products';
import axiosInstance from '../utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';

const getCogs = async (order) => {
  let cogsValue = 0;
  const promise = order.line_items.map(async (item) => {
    const cogsData: any = await db()
      .collection('shopify_product_variants')
      .doc('' + item.variant_id)
      .get();
    return (cogsValue +=
      typeof cogsData.data() != 'undefined' ? item.quantity * cogsData.data().product_cost : 0);
  });
  await Promise.all(promise);
  return cogsValue;
};

const getCogsMongo = async (order) => {
  const promises = order.line_items.map(async (item) => {
    const productRes = await fetchProduct(item.product_id, true);
    const productData = productRes[0].data();
    return (
      (productData.variants.filter((variant) => variant.id === item.variant_id)[0]?.costs?.[0]
        ?.value || 0) * item.quantity
    );
  });
  const cogs = order.line_items.map((item) => item.total_cost);
  return cogs.reduce((a, b) => a + b, 0);
};

export const loadOrdersWithPaginationMongo = async (start, end, limit, page) => {
  const url = `/v2/shopify/mongo/get-orders`;
  const { data } = await axiosInstance.post(url, {
    shopId: $currentShopId.get(),
    page,
    limit,
    order: -1, // desc
    start,
    end,
  });

  const promise = data.map(async (order) => {
    let cogsValue = await getCogsMongo(order);
    return { ...order, cogsValue };
  });

  return await Promise.all(promise);
};

export const loadOrder = async (id, shopId = $currentShopId.get()) => {
  let orderData;
  const url = `/v2/attribution/get-order`;
  const { data } = await axiosInstance.post(url, {
    shopId,
    id,
  });
  orderData = data[0];
  const updatedLineItems = await getItemData(orderData, shopId);
  return {
    ...orderData,
    line_items: updatedLineItems,
    total_cogs: updatedLineItems.map((x) => x.itemCogs).reduce((a, b) => a + b, 0),
  };
};

const getItemData = async (order, shopId = $currentShopId.get()) => {
  if (!order || !order.line_items) return [];

  const promise = order.line_items.map(async (item) => {
    const productRes = await fetchProduct(item.product_id, true, shopId);
    const productData = productRes?.[0]?.data();
    let productImg = productData?.image?.src || '';
    let itemCogs = item?.total_cost ?? 0;
    return { ...item, productImg, itemCogs };
  });
  return await Promise.all(promise);
};

// REDUCERS
// REDUCERS
// REDUCERS

// const shopOrders = (state = [], action) => {
//   switch (action.type) {
//     case ORDERS_RECEIVED:
//       return action.orders;
//     default:
//       return state;
//   }
// }

// const loadingOrders = (state = true, action) => {
//   switch (action.type)  {
//     case ORDERS_RECEIVED:
//       return false;
//     default:
//       return state;
//   }
// }

// export const reducers = {
//   shopOrders,
//   loadingOrders,
// }
