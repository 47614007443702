import { Icon, Tooltip } from '@shopify/polaris';
import { ExternalMinor } from '@shopify/polaris-icons';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import type { AttributionData } from '@tw/types/module/types/Attribution';
import type { valueFormats } from '@tw/types/module/types/ValueFormat';
import ToggleStatus from 'components/attribution-new/toggleStatus';
import TWImage from 'components/library/TWImage/TWImage';
import { formatNumber } from 'utils/formatNumber';
import allServices from '../../constants/services';
import SERVICES from '../../constants/services';

export const getValue = (
  ad: { [x: string]: any },
  metric: string,
  metadata: any,
  style: valueFormats,
) => {
  const { currency } = metadata || {};
  return formatNumber(ad[metric] || 0, {
    currency,
    maximumFractionDigits: metrics[metric]?.toFixed,
    minimumFractionDigits: metrics[metric]?.toFixed,
    style,
  });
};

export const filterAdsData = (adsData: AttributionData[], searchTerm: string) => {
  if (searchTerm === '') return adsData;
  return adsData?.filter((ad: any) => {
    const adName = ad?.name?.toLowerCase() || '';
    const adId = ad?.adId?.toLowerCase() || '';
    const platform = ad?.serviceId?.toLowerCase() || '';
    const searchTermLower = searchTerm.toLowerCase();
    return (
      adName.includes(searchTermLower) ||
      adId.includes(searchTermLower) ||
      platform.includes(searchTermLower)
    );
  });
};

export const renderStatusColumn = (ad, metadata) => {
  const { attributions, setData } = metadata;
  const attr = attributions?.find((att) => att.id === ad.adId) || {};
  return (
    <div className="flex justify-center p-4">
      <span>
        <ToggleStatus attribution={attr} setData={setData} />
      </span>
    </div>
  );
};

export const renderNameColumn = (ad) => {
  const { serviceId, name, imageUrl } = ad;
  const serviceIcon = SERVICES[serviceId]?.icon?.(true);
  return (
    <div className="flex items-center gap-3 w-105 p-4">
      <span className="flex items-center">{serviceIcon}</span>
      {imageUrl && (
        <span>
          <TWImage className="rounded-md flex items-center" src={imageUrl} width={20} height={20} />
        </span>
      )}
      <Tooltip content={name} preferredPosition="above">
        <div className="w-100 whitespace-nowrap overflow-hidden text-ellipsis">{name}</div>
      </Tooltip>
    </div>
  );
};
export const renderLinkColumn = (ad, metadata) => {
  const { isDarkMode } = metadata || {};
  const { serviceId, accountId, adId } = ad || {};
  return (
    <a
      target="_blank"
      href={
        allServices[serviceId]?.externalAdsUrl?.(
          serviceId,
          serviceId === 'google-ads' ? 'campaign' : 'ad',
          accountId,
          serviceId === 'google-ads' && ad?.campaignId ? ad.campaignId : undefined,
          undefined,
          adId,
        ) || undefined
      }
      rel="noreferrer"
    >
      <Icon source={ExternalMinor} color={isDarkMode ? 'highlight' : undefined} />
    </a>
  );
};
