import { $allSnippets, $tabs } from '$stores/willy/$tables';
import { confirm } from '@tw/ui-components';
import { WillySearchInput } from 'components/Willy/WillySearchInput';
import { useState } from 'react';
import _db from 'utils/DB';
import { SaveSnippet } from './SaveSnippet';
import { SqlSnippet } from 'components/Willy/types/willyTypes';
import { editor } from 'monaco-editor';
import { Monaco } from '@monaco-editor/react';
import { useFilteredItems } from 'components/Willy/hooks/useFilteredItems';
import { ItemsWithActions } from './ItemsWithActions';
import { SAVED_SNIPPETS_COLLECTION } from './constants';
import { updateEditorText } from './utils';
import { useWritableStore } from '@tw/snipestate';

type SnippetsProps = {
  editorRef?: editor.IStandaloneCodeEditor;
  monacoRef?: Monaco;
};

export const Snippets: React.FC<SnippetsProps> = ({ editorRef, monacoRef }) => {
  const [snippets, setSnippets] = useWritableStore($allSnippets);

  const [freeSearch, setFreeSearch] = useState('');
  const [editSnippetModalOpen, setEditSnippetModalOpen] = useState<SqlSnippet>();

  const filteredSnippets = useFilteredItems(snippets, freeSearch, ['name']);

  return (
    <div>
      <WillySearchInput
        value={freeSearch}
        onChange={(v) => setFreeSearch(v)}
        placeholder="Search snippets"
      />

      <ItemsWithActions
        items={filteredSnippets}
        setItems={setSnippets}
        actions={[
          {
            content: 'Insert',
            helpText: 'Into cursor position in the editor',
            onItemAction: (snippet) => {
              const val = updateEditorText(snippet.snippet, editorRef, monacoRef);
              $tabs.set((old) => {
                return old.map((tab) => {
                  if (tab.active) {
                    return {
                      ...tab,
                      query: val || tab.query,
                    };
                  }
                  return tab;
                });
              });
            },
          },
          {
            content: 'Edit',
            helpText: 'Edit this snippet',
            onItemAction: (snippet) => {
              setEditSnippetModalOpen(snippet);
            },
          },
          {
            content: 'Delete',
            disabledItem: (snippet) => !!snippet.isGlobal,
            helpTextItem: (snippet) =>
              snippet.isGlobal ? 'Global snippets cannot be deleted' : '',
            onItemAction: async (snippet) => {
              const confirmed = await confirm({
                message: 'Are you sure you want to delete this snippet?',
              });
              if (confirmed) {
                await _db().collection(SAVED_SNIPPETS_COLLECTION).doc(snippet.id).delete();
              }
            },
          },
        ]}
      />

      <SaveSnippet
        snippetToEdit={editSnippetModalOpen}
        open={!!editSnippetModalOpen}
        onClose={() => setEditSnippetModalOpen(undefined)}
      />
    </div>
  );
};
