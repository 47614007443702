import { Anchor, Button, Checkbox, Icon, Popover, Text } from '@tw/ui-components';
import { WillyParameter } from './types/willyTypes';
import { BqTable } from 'pages/FreeQuery/dataStuff/tables';

type DynamicFieldsPopupProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parameters: WillyParameter[];
  tables: BqTable[];
  onChanged: (parameters: WillyParameter[]) => Promise<void>;
  context: 'widget' | 'dashboard';
};

export const DynamicFieldsPopup: React.FC<DynamicFieldsPopupProps> = ({
  open,
  setOpen,
  parameters,
  tables,
  onChanged,
  context,
}) => {
  return (
    <Popover opened={open} onClose={() => setOpen(false)}>
      <Popover.Target>
        <div className="flex-shrink-0">
          <Anchor
            underline="never"
            onClick={() => {
              setOpen((x) => !x);
            }}
          >
            <span className="flex items-center gap-3">
              <Icon name="plus" size={12} />
              <Text size="sm" weight={500} color="gray.7">
                Add Filter
              </Text>
            </span>
          </Anchor>
        </div>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Text tt="capitalize" p="lg" color="gray.5" size="sm" weight={500} as="h3">
          Preset Filters
        </Text>
        <div className="flex flex-col gap-6.5 px-8">
          {parameters.map((parameter, i) => {
            let column = tables.flatMap((t) => t.columns).find((c) => c.id === parameter.column);

            if (!column) {
              column = {
                id: parameter.column,
                title: parameter.column,
                name: parameter.column,
                type: 'string',
              };
            }
            return (
              <Checkbox
                key={column.id + i}
                checked={context === 'dashboard' ? parameter.visibleInDashboard : parameter.visible}
                label={
                  <Text weight={500} color="gray.7" size="sm">
                    {column.title}
                  </Text>
                }
                onChange={async (checked) => {
                  const newParameters = parameters.map((p) => {
                    if (p.column === parameter.column) {
                      return {
                        ...p,
                        visibleInDashboard: checked,
                      };
                    }
                    return p;
                  });
                  await onChanged(newParameters);
                }}
              />
            );
          })}
        </div>
        <div className="w-full h-[1px] bg-[#F1F3F6] my-8" />
        <div className="px-8 pb-8 flex justify-between gap-4">
          <Button
            variant="activator"
            onClick={async () => {
              const newParameters = parameters.map((p) => {
                return {
                  ...p,
                  visibleInDashboard: false,
                };
              });
              await onChanged(newParameters);
              setOpen(false);
            }}
            fullWidth
          >
            Clear All
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
