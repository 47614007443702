import axios from 'axios';
import { baseURL } from 'config';
import { $currentShopId } from '$stores/$shop';
import firebase from 'firebase/compat/app';

import { TW_SHOP_ID_HEADER } from '@tw/constants';
import { type ApiErrorResponse } from '@tw/types';

import { getUserId } from './DB';

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'x-tw-git-sha': process.env.COMMIT_SHA || 'undefined',
    // "x-tw-shop-id": $currentShopId.get(),
  },
  timeout: 200000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let originalRequest = config;
    const token = await firebase.auth().currentUser?.getIdToken()!;
    if (!originalRequest.headers) {
      originalRequest.headers = {} as any;
    }
    if (token) {
      originalRequest.headers['Authorization'] = 'Bearer ' + token;
    }
    originalRequest.headers[TW_SHOP_ID_HEADER] =
      originalRequest.params?.shop || $currentShopId.get();
    originalRequest.headers['shop_domain'] = originalRequest.params?.shop || $currentShopId.get();
    originalRequest.headers['user'] = getUserId() || '';

    const serviceId = config.url?.split('/').filter(Boolean)[1]; // first part of the url is the version (v1, v2, etc), second part is the service id
    const stackHeader = extractStackFromUrl(serviceId!);

    if (stackHeader) {
      originalRequest.headers.set('x-tw-stack', stackHeader);
    }

    return originalRequest;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (e: { response: ApiErrorResponse }) => {
    if (e.response) {
      throw e.response;
    } else {
      throw e;
    }
  },
);

function extractStackFromUrl(serviceId: string) {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const env = params.get('stack');
  if (!env) {
    return;
  }

  const parsedEnv = JSON.parse(env);

  if (!parsedEnv[serviceId]) {
    return;
  }

  return parsedEnv[serviceId] as string;
}

export default axiosInstance;
