import TWImage from 'components/library/TWImage/TWImage';
import { FC } from 'react';
import { useDraggable } from 'components/DraggableProvider/DraggableProvider';
import { Tooltip } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

interface ProductAdsPopupHeaderProps {
  name: string;
  thumbnail: string;
}

const ProductAdsPopupHeader: FC<ProductAdsPopupHeaderProps> = ({ name, thumbnail }) => {
  const { startDrag } = useDraggable();
  const { start, end } = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange || { start: null, end: null },
  );

  return (
    <div
      className="flex flex-row justify-between cursor-grab active:cursor-grabbing"
      onMouseDown={startDrag}
    >
      <div className="flex w-1/2 p-4 items-center">
        <div className="p-2 bg-white/40 rounded">
          <TWImage
            className="rounded-md flex items-center w-"
            src={thumbnail}
            width={70}
            height={70}
            wrapperClass={'w-28 h-28 rounded'}
          />
        </div>
        <div className="pl-4">
          <Tooltip content={name} preferredPosition="above">
            <span className="text-white text-4xl font-bold pb-2 cursor-text flex items-left">
              {name?.length > 50 ? name?.slice(0, 50) + '...' : name}
            </span>
          </Tooltip>
          <span className="text-white text-1xl font-bold cursor-text">
            {start?.format('MMM DD, YYYY')} - {end?.format('MMM DD, YYYY')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductAdsPopupHeader;
