import React from 'react';
import { WillyDashboardElement } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { Badge } from '@tw/ui-components';
import { $creditsLeftInfinity } from '$stores/willy/$credits';

export const CreditBadge: React.FC<{ dashboard: WillyDashboardElement }> = ({ dashboard }) => {
  const creditsLeftInfinity = useStoreValue($creditsLeftInfinity);
  if (!dashboard.credits || dashboard.credits === 0 || creditsLeftInfinity) return null;
  return (
    <Badge color="one.1" size={'xs'}>
      <div
        className={'text-[#0A5ECC]'}
      >{`${dashboard.credits} credit${dashboard.credits > 1 ? 's' : ''}`}</div>
    </Badge>
  );
};
