import { CreateEditSegmentModal } from 'components/CreateEditSegmentationModal';
import { useAppDispatch } from 'index';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { CreativeFilterOperator } from '@tw/types';

import {
  createSegment,
  deleteSegment,
  editSegment,
  openModalSegmentation,
} from '../../../ducks/creativesCockpit/creativesCockpit';
import { SegmentationPopup } from '../../Segmentation/SegmentationPopup';
import { CreativesCockpitContext } from '../context';
import {
  operands,
  operandsMetric,
  operatorsEQUALS,
  operatorsNAMES,
  operatorsNames,
  operatorsNUMBERS,
} from './types';

export const CreateEditCKSegmentModal = ({ handleNew }) => {
  const {
    modalData: { isOpen, serviceId, segmentId },
    currentSegment,
    segments,
  } = useSelector((state: RootState) => state.creativeCockpit);
  const isEdit = !!segmentId;

  const dispatch = useAppDispatch();

  const getOperandsByOperator = (operand: string): CreativeFilterOperator[] => {
    return operand.includes('_id')
      ? operatorsEQUALS
      : operand.includes('_name')
        ? operatorsNAMES
        : operatorsNUMBERS;
  };

  return (
    <CreateEditSegmentModal
      onClose={() => dispatch(openModalSegmentation('', ''))}
      isOpen={isOpen}
      type={serviceId}
      isEdit={isEdit}
      id={segmentId}
      editSegment={(segment) => {
        dispatch(editSegment(segment, serviceId));
      }}
      serviceId={serviceId}
      createSegment={(segment) => dispatch(createSegment(segment, serviceId))}
      currentSegment={isEdit ? segments[serviceId][segmentId] : currentSegment}
      getOperators={(operator) =>
        getOperandsByOperator(operator).map((op) => ({
          label: operatorsNames[op] ? operatorsNames[op] : op,
          value: op,
        }))
      }
      operands={operands.map((op) => ({ label: operandsMetric[op].label, value: op }))}
      search={async (searchStr: string, entity: string) => {
        return [{}];
      }}
      operandsWithNoSearchList={operands}
      handleNew={handleNew}
    />
  );
};

export const AllSegments = ({ plain, onApply, isShareMode }) => {
  const context = useContext(CreativesCockpitContext);
  const serviceId = context.serviceId!;

  const { segments } = useSelector((state: RootState) => state.creativeCockpit);
  const dispatch = useAppDispatch();

  const segmentsData = segments[serviceId]!;

  return (
    <SegmentationPopup
      isShareMode={isShareMode}
      segmentsData={Object.keys(segmentsData || {}).map((id) => ({ ...segmentsData[id], id }))}
      segmentsApplyFilter={[]}
      updateSegment={(segment) => {
        dispatch(editSegment(segment, serviceId));
      }}
      segmentOnDelete={(id) => {
        dispatch(deleteSegment(id, serviceId));
      }}
      editSegmentOpenModal={(id: string) => dispatch(openModalSegmentation(serviceId, id))}
      serviceId={serviceId}
      plain={plain}
      onApply={onApply}
      includeAdd={true}
    />
  );
};
