import { MetricsClientDictionary } from 'types/metrics';
import { metrics as allMetrics } from '@tw/constants/module/Metrics/allMetrics';

export const shopMetrics: MetricsClientDictionary = {
  orders: {
    ...allMetrics.orders!,
    Tip: () => null,
  },
  sales: {
    ...allMetrics.sales!,
    Tip: () => null,
  },
  refunds: {
    ...allMetrics.refunds!,
    Tip: () => null,
  },
  netSales: {
    ...allMetrics.netSales!,
    Tip: () => null,
  },
  itemsSold: {
    ...allMetrics.itemsSold!,
    Tip: () => null,
  },
  returningCustomerOrders: {
    ...allMetrics.returningCustomerOrders!,
    Tip: () => null,
  },
  returningCustomerSales: {
    ...allMetrics.returningCustomerSales!,
    Tip: () => null,
  },
  taxes: {
    ...allMetrics.taxes!,
    Tip: () => null,
  },
  shipping: {
    ...allMetrics.shipping!,
    Tip: () => null,
  },
  discounts: {
    ...allMetrics.discounts!,
    Tip: () => null,
  },
  newCustomerOrders: {
    ...allMetrics.newCustomerOrders!,
    Tip: () => null,
  },
  newCustomerSales: {
    ...allMetrics.newCustomerSales!,
    Tip: () => null,
  },
  refundsRate: {
    ...allMetrics.refundsRate!,
    Tip: () => null,
  },
};
