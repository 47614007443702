import React, { useContext } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Form, Icon, TextField } from '@shopify/polaris';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import firebase from 'firebase/compat/app';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import { OnboardingEmailContext } from '@tw/types/module/services/account-manager';
import axiosInstance from 'utils/axiosInstance';
import { type RootState } from 'reducers/RootType';
import { WizardContext } from '../WizardContext';
import { updateWelcomeWizardData } from 'ducks/welcomeWizard';
import { useAppDispatch } from 'index';

import './steps.scss';
import { SalesPlatformName } from 'components/SalesPlatform/SalesPlatformName';
import { SalesPlatform } from '@tw/types/module/services';

const auth = firebase.auth;

const INVITATION_CONTEXT_DATA: {
  [key in OnboardingEmailContext]?: {
    title: JSX.Element | string;
    description?: JSX.Element | string;
    inputLabel?: JSX.Element | string;
    action: string;
  };
} = {
  [OnboardingEmailContext.CONNECT_SHOPIFY_INVITATION]: {
    title: (
      <>
        Invite Your <SalesPlatformName /> Admin
      </>
    ),
    description: (
      <>
        Invite your <SalesPlatformName /> admin to Triple Whale. We will send them an invitation and
        show them how to connect your <SalesPlatformName /> account.
      </>
    ),
    inputLabel: (
      <>
        <SalesPlatformName /> admin’s email address:
      </>
    ),
    action: 'Send Invitation',
  },
  [OnboardingEmailContext.CONNECT_AD_ACCOUNT_INVITATION]: {
    title: 'Invite Another User to Connect Integration(s)',
    description:
      'Provide the email address for your company’s admin for the service(s) you’d like to connect. We will send them an invitation and instructions for connecting integrations with Triple Whale.',
    inputLabel: 'Your admin’s email address:',
    action: 'Send Invitation',
  },
  [OnboardingEmailContext.INSTALL_PIXEL_INSTRUCTIONS]: {
    title: 'Email instructions to developer',
    action: 'Send Instructions',
  },
  [OnboardingEmailContext.UTM_INSTRTUCTIONS]: {
    title: 'Send UTM Instructions',
    description:
      'Provide the email address for the person that manages your marketing campaigns and we will send them the step-by-step instructions for properly installing Triple Whale UTMs.',
    inputLabel: 'Marketer’s email address:',
    action: 'Send Instructions',
  },
};

type Props = {
  wizardStep: string;
  onClose?: () => void;
  msp: SalesPlatform;
};

const SendEmailModal: React.FC<Props> = ({ wizardStep, onClose, msp }) => {
  const dispatch = useAppDispatch();

  const user = auth().currentUser;
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const { wizardShopData } = useContext(WizardContext);

  const [context, setContext] = useState<OnboardingEmailContext>();
  const [email, setEmail] = useState<string>();
  const [inviteUserDisabled, setinviteUserDisabled] = useState<boolean>(false);

  useEffect(() => {
    switch (wizardStep) {
      case 'connectShopify':
        setContext(OnboardingEmailContext.CONNECT_SHOPIFY_INVITATION);
        break;
      case 'integrations':
        setContext(OnboardingEmailContext.CONNECT_AD_ACCOUNT_INVITATION);
        break;
      case 'pixel':
        setContext(OnboardingEmailContext.INSTALL_PIXEL_INSTRUCTIONS);
        break;
      case 'utms':
        setContext(OnboardingEmailContext.UTM_INSTRTUCTIONS);
        break;
    }
  }, [wizardStep]);

  const saveInvitation = useCallback(async () => {
    const newData = { steps: {}, ...wizardShopData };
    if (!newData.steps[wizardStep]) {
      newData.steps[wizardStep] = {};
    }

    const invitations = newData.steps[wizardStep].invitations || [];
    invitations.push({
      email: email!,
      date: firebase.firestore.Timestamp.fromDate(new Date()),
      inviterId: user!.uid,
    });

    newData.steps[wizardStep].invitations = invitations;
    await dispatch(updateWelcomeWizardData({ ...newData }));
  }, [wizardStep, wizardShopData, email, dispatch, user]);

  const sendInvitation = useCallback(async () => {
    if (!context) return;

    setinviteUserDisabled(true);
    let url = ``;
    try {
      switch (context) {
        case OnboardingEmailContext.CONNECT_SHOPIFY_INVITATION:
        case OnboardingEmailContext.CONNECT_AD_ACCOUNT_INVITATION:
          url = `/v2/account-manager/onboarding/send-invitation`;
          break;
        case OnboardingEmailContext.INSTALL_PIXEL_INSTRUCTIONS:
        case OnboardingEmailContext.UTM_INSTRTUCTIONS:
          url = `/v2/account-manager/onboarding/send-instructions-email`;
          break;
        default:
          break;
      }

      await axiosInstance.post(url, {
        email: email,
        emailContext: context,
        msp,
        shopId: currentShopId,
        senderId: user?.uid,
      });
      saveInvitation();

      toast.success(`Invitation sent to ${email}`);
    } catch (err) {
      toast.error('Error sending invitation');
    } finally {
      setinviteUserDisabled(false);
    }
  }, [email, currentShopId, user, context, saveInvitation]);

  return (
    <React.Fragment>
      {context && (
        <div className="rounded-[5px] bg-[#F5F7FA] py-[25px] px-[34px] flex flex-col items-center gap-[16px] max-w-[440px] relative">
          {onClose && (
            <span
              className="cursor-pointer absolute w-[14px] top-[15px] right-[15px]"
              onClick={() => onClose?.()}
            >
              <Icon source={MobileCancelMajor}></Icon>
            </span>
          )}
          <div className="text-center">
            <p className="text-[#052A42] text-[20px] font-medium">
              {INVITATION_CONTEXT_DATA[context]!.title}
            </p>
            {INVITATION_CONTEXT_DATA[context]!.description && (
              <p className="text-[14px] text-[#536B84] mt-[16px]">
                {INVITATION_CONTEXT_DATA[context]!.description}
              </p>
            )}
          </div>

          <div className="flex flex-col w-full px-[30px]">
            <Form onSubmit={sendInvitation}>
              <TextField
                type="email"
                value={email}
                onChange={setEmail}
                label={INVITATION_CONTEXT_DATA[context]!.inputLabel}
                placeholder="Email address"
                autoComplete="off"
              />

              <button
                className="step-action-button mt-[12px] w-full"
                type="submit"
                disabled={inviteUserDisabled || !email}
              >
                {INVITATION_CONTEXT_DATA[context]!.action}
              </button>
            </Form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SendEmailModal;
