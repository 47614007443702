import { WindowWidth } from 'constants/windowSizes';

class WindowSizeObserver {
  private _width: number = window.innerWidth;
  private _height: number = window.innerHeight;

  public constructor() {
    window.addEventListener('resize', () => {
      this._width = window.innerWidth;
      this._height = window.innerHeight;
    });
  }

  public get width() {
    return this._width;
  }

  public get height() {
    return this._height;
  }

  public get isSmall() {
    return this.width < WindowWidth.SMALL;
  }

  public get isMedium() {
    return this.width < WindowWidth.MEDIUM;
  }

  public get isLarge() {
    return this.width >= WindowWidth.MEDIUM;
  }
}

/**
 * This object provides a centralized way to get the updated dimension when called.
 * It constantly listens for updates, so it's not like the original `isSmall` global
 * that once called never changes - meaning that even if the window resizes, the
 * original `isSmall` would still be it's original value.  This can be misleading.
 * By using this object instead with its convenient getters, each time anyone attempts
 * to access its values, they will be up to date.
 *
 * @example This is how we used to define `isSmall`.
 * ```ts
 * export const isSmall = window.innerWidth < 640;
 * ```
 * If `window.innerWidth` starts off greater than 640 and user makes the screen smaller, it still stays as false!
 * By using this instance, we don't have that issue.
 */
export const windowSize = new WindowSizeObserver();
